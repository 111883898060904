import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Translate, getTranslate} from 'react-localize-redux';
import classNames from 'classnames';
import {toastr} from 'react-redux-toastr';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle, faUser, faCalendarAlt, faStar, faCogs, faShareAlt} from '@fortawesome/free-solid-svg-icons'

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import ChangeInterfaceLanguage from '../../Modals/ChangeInterfaceLanguage/index';
import ChangeTranslationLanguage from '../../Modals/ChangeTranslationLanguage/index';
import ChangeAudioLanguage from '../../Modals/ChangeAudioLanguage/index';
import ChangeFontSize from '../../Modals/ChangeFontSize/index';
import ChangeVocabularyLevel from '../../Modals/ChangeVocabularyLevel/index';
import ChangeListSize from '../../Modals/ChangeListSize/index';
import UserProfile from '../../Modals/UserProfile/index';
import ChangeUserProfile from '../../Modals/ChangeUserProfile/index';
import ChangePassword from '../../Modals/ChangePassword/index';
import Notifications from '../../Modals/Notifications/index';
import ReportBug from '../../Modals/ReportBug/index';
import SubscriptionAlert from '../../Modals/SubscriptionAlert/index';
import ReviewModal from '../../Modals/ReviewModal/index';
import TrainingsSet from '../../Modals/TrainingsSet/index';
import ShareButtons from '../../Modals/ShareButtons/index';

import Logo from "../../../assets/images/logo-boy-prepared-50.png";

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import {resetHomeLists, resetUserLists, resetCurrentList} from '../../../actions/lists';
import {setCurrentCourseSlug, resetCourseCache} from '../../../actions/courseActions';
import {cleanAppCache, resetCachedLists, resetVisitedCachedLists} from '../../../actions/cacheActions';
import {setFontSize} from '../../../actions/settingsActions';
import {setCurrentArticle} from '../../../actions/newsActions';
import {resetUserData, toggleReviewModal} from '../../../actions/userActions';

import colors from '../../../lib/colors';

class UserNavbar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      interfaceLanguageModal: false,
      translationLanguageModal: false,
      audioModalVisible: false,
      fontModalVisible: false,
      levelModalVisible: false,
      listSizeModalVisible: false,
      profileModalVisible: false,
      changeProfileModalVisible: false,
      changePasswordModalVisible: false,
      notificationsModalVisible: false,
      bugModalVisible: false,
      subscriptionAlertVisible: false,
      trainingsSetModal: false,
      shareModalVisible: false,
      subscriptionMessage: '',
    };
  }

  componentDidMount() {
    localStorage.removeItem('tour');
  }

  _logOut = (e) => {
    const {locale} = this.props.settings;

    e.preventDefault();

    const {history} = this.props;

    this._clearStorage();
    this.props.resetUserData();

    this.props.resetHomeLists();
    this.props.resetUserLists();
    this.props.resetCurrentList();
    this.props.resetCourseCache();
    this.props.resetCachedLists();
    this.props.resetVisitedCachedLists();

    history.replace(`/${locale}/login`)
  };

  _toggleBugModal = (status) => {
    this.setState({
      bugModalVisible: status
    });
  };

  _clearStorage = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('originalUserId');

    //Development
    // localStorage.removeItem('wizard');

    // localStorage.removeItem(`errors_alert_1_${userId}`)
    // localStorage.removeItem('firstTutorial');
    // localStorage.removeItem('notNow');
    // localStorage.removeItem('notNowCourse');
    // localStorage.removeItem('leftReview');
    // localStorage.removeItem(`hidePromo${userId}`);
    // localStorage.removeItem(`glossariesLevel${userId}`);
    // localStorage.removeItem(`alert_no_lists${userId}`);
    // localStorage.removeItem(`alert_swipe_to_manage_list${userId}`);
    // localStorage.removeItem(`alert_empty_list${userId}`);
    // localStorage.removeItem(`alert_swipe_to_manage_list_word${userId}`);
    // localStorage.removeItem(`show_alert_where_to_go${userId}`);
    // localStorage.removeItem(`alert_select_glossary_words_or_train${userId}`);
    // localStorage.removeItem(`alert_set_goal${userId}`);
    // localStorage.removeItem(`alert_show_favorites${userId}`);
    // localStorage.removeItem(`alert_drag_word${userId}`);
    // localStorage.removeItem(`alert_click_to_see_word${userId}`);
    // localStorage.removeItem(`neverShowWordAdded${userId}`);
  };

  resetSettings = () => {
    const {userId} = this.props.user;
    const {translate} = this.props;

    this.props.setFontSize(1);

    localStorage.setItem(`filterLists${userId}`, 'by_list_name');
    localStorage.setItem(`orderLists${userId}`, 'asc');
    localStorage.setItem(`fontSize${userId}`, "1");

    localStorage.removeItem(`showCourseSentences${userId}`);

    localStorage.removeItem(`neverShowWordAdded${userId}`);
    localStorage.removeItem('wizard');
    localStorage.removeItem(`hidePromo${userId}`);

    localStorage.removeItem('notNow');

    localStorage.removeItem('notNowCourse');
    localStorage.removeItem('leftReview');
    localStorage.removeItem(`alert_no_lists${userId}`);
    localStorage.removeItem(`alert_swipe_to_manage_list${userId}`);
    localStorage.removeItem(`alert_empty_list${userId}`);
    localStorage.removeItem(`alert_swipe_to_manage_list_word${userId}`);
    localStorage.removeItem(`show_alert_where_to_go${userId}`);
    localStorage.removeItem(`alert_select_glossary_words_or_train${userId}`);
    localStorage.removeItem(`alert_set_goal${userId}`);
    localStorage.removeItem(`alert_show_favorites${userId}`);
    localStorage.removeItem(`alert_drag_word${userId}`);
    localStorage.removeItem(`alert_click_to_see_word${userId}`);
    localStorage.removeItem(`neverShowWordAdded${userId}`);

    // training 1
    localStorage.setItem(`autoplay_delay_1_${userId}`, '2');
    localStorage.setItem(`autosound_word_1_${userId}`, '1');
    localStorage.setItem(`autosound_translation_1_${userId}`, '0');

    // training 2
    localStorage.setItem(`autoslide_2_${userId}`, '1');
    localStorage.setItem(`autoslide_delay_2_${userId}`, '2');
    localStorage.setItem(`autosound_word_2_${userId}`, '1');
    localStorage.setItem(`image_or_word_2_${userId}`, 'word');

    // training 3
    localStorage.setItem(`autoslide_3_${userId}`, '1');
    localStorage.setItem(`autoslide_delay_3_${userId}`, '2');
    localStorage.setItem(`autosound_word_3_${userId}`, '1');
    localStorage.setItem(`image_or_word_3_${userId}`, 'image');

    // training 4
    localStorage.setItem(`autoslide_4_${userId}`, '1');
    localStorage.setItem(`autoslide_delay_4_${userId}`, '2');
    localStorage.setItem(`autosound_word_4_${userId}`, '1');
    localStorage.setItem(`autosound_translation_4_${userId}`, '1');
    localStorage.setItem(`image_or_word_4_${userId}`, 'image');

    // training 5
    localStorage.setItem(`autoslide_5_${userId}`, '1');
    localStorage.setItem(`autoslide_delay_5_${userId}`, '2');
    localStorage.setItem(`autosound_word_5_${userId}`, '1');
    localStorage.setItem(`image_or_word_5_${userId}`, 'image');

    // training 6
    localStorage.setItem(`autoslide_6_${userId}`, '1');
    localStorage.setItem(`autoslide_delay_6_${userId}`, '2');
    localStorage.setItem(`autosound_word_6_${userId}`, '1');
    localStorage.setItem(`autosound_translation_6_${userId}`, '1');

    // training 7
    localStorage.setItem(`autoslide_7_${userId}`, '1');
    localStorage.setItem(`autoslide_delay_7_${userId}`, '2');
    localStorage.setItem(`autosound_word_7_${userId}`, '1');
    localStorage.setItem(`image_or_word_7_${userId}`, 'image');

    // training 8
    localStorage.setItem(`autoslide_8_${userId}`, '1');
    localStorage.setItem(`autoslide_delay_8_${userId}`, '2');

    // training 9
    localStorage.setItem(`autoslide_9_${userId}`, '1');
    localStorage.setItem(`autoslide_delay_9_${userId}`, '2');
    localStorage.setItem(`autosound_word_9_${userId}`, '1');

    // training 10
    localStorage.setItem(`autoslide_10_${userId}`, '1');
    localStorage.setItem(`autoslide_delay_10_${userId}`, '2');
    localStorage.setItem(`autosound_word_10_${userId}`, '1');

    // training 12
    localStorage.setItem(`autoslide_12_${userId}`, '1');
    localStorage.setItem(`autoslide_delay_12_${userId}`, '2');
    localStorage.setItem(`autosound_word_12_${userId}`, '1');
    localStorage.setItem(`image_or_word_12_${userId}`, 'word');

    // training 14
    localStorage.setItem(`autoslide_delay_14_${userId}`, '1');
    localStorage.setItem(`autosound_word_14_${userId}`, '1');
    localStorage.setItem(`autosound_translation_14_${userId}`, '1');
    localStorage.setItem(`autosound_example_translation_14_${userId}`, '1');
    localStorage.setItem(`loop_14_${userId}`, '0');
    localStorage.setItem(`autosound_sentence_14_${userId}`, '1');

    toastr.success("", translate('settings_reset'));
    this.props.logActivity({activityId: 299});

  };

  _courseSelected = (name, slug, e) => {
    const {history} = this.props;
    const {locale} = this.props.settings;

    e.preventDefault();
    this.props.setCurrentCourseSlug(name, slug);
    history.replace(`/${locale}/course/${slug}`, {slug, name})
  };

  _toggleAudioModal = (status) => {
    this.setState({
      audioModalVisible: status
    })
  };

  _toggleModal = (modal, status) => {
    this.setState({
      [modal]: status
    })
  };

  _toArticle = (article, e) => {
    e.preventDefault();
    const {locale} = this.props.settings;

    this.props.setCurrentArticle(article);
    this.props.history.push(`/${locale}/article/${article.id}`);
  };

  _changeProfile = () => {
    this._toggleModal('profileModalVisible', false);
    this._toggleModal('changeProfileModalVisible', true);
  };

  _getGamesLimit = () => {
    const {subscriptionActive, gamesLimit, todayGamesLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;

    const {userData} = this.props.user;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }
    
    let active = false;

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayGamesLimit < gamesLimit;
    }

    return active;
  };

  _toGame = (segue, e) => {
    e.preventDefault();
    
    if(this._getGamesLimit()) {
      this.props.history.push(segue)
    } else {
      this.setState({
        subscriptionMessage: 'subscription_games'
      });
      this._toggleSubscriptionAlert(true);
    }
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toSubscription = (productId) => {
    const {locale} = this.props.settings;

    this._toggleSubscriptionAlert(false);

    this.props.history.push(`/${locale}/products`, {back: true, product: productId});
  };

  toggleReviewModal = () => {
    this.props.toggleReviewModal(false)
  };

  render() {
    const {translate, history} = this.props;
    const {userData, reviewModalVisible} = this.props.user;
    const {unreadNews, news} = this.props.news;
    const {
      interfaceLanguageModal,
      translationLanguageModal,
      audioModalVisible,
      fontModalVisible,
      levelModalVisible,
      listSizeModalVisible,
      profileModalVisible,
      changeProfileModalVisible,
      changePasswordModalVisible,
      notificationsModalVisible,
      bugModalVisible,
      subscriptionAlertVisible,
      subscriptionMessage,
      trainingsSetModal,
      shareModalVisible
    } = this.state;
    const {locale} = this.props.settings;

    const userId = localStorage.getItem('userId');
    const path = history.location.pathname;

    const glossaryLink = classNames({
      'nav-link': true,
      'active': path == `/${locale}/glossaries` || path.indexOf('glossary') !== -1
    });

    const listsLink = classNames({
      'nav-link': true,
      'active': path == `/${locale}/lists` || path.indexOf('/list/') !== -1
    });

    const courseLink = classNames({
      'active': path.indexOf('course') !== -1
    });

    const otherLink = classNames({
      'active': path == `/${locale}/vocabulary` || path == `/${locale}/phrasebooks` || path.indexOf('favorite') !== -1
    });

    const paymentsLink = classNames({
      'active': path == `/${locale}/purchases` || path == `/${locale}/products`
    });

    const newsLinks = classNames({
      'active': path.indexOf('article') !== -1,
      'text-danger': unreadNews > 0,
      'dropdown-toggle nav-link': true
    });

    const gamesLink = classNames({
      'active': path == `/${locale}/hangman` || path == `/${locale}/wordmaker` || path == `/${locale}/wordextractor`
    });

    // let title = userData && userData.avatar ? userData.avatar

    return (
      <Navbar bg="dark" className="mnemonic" variant="dark" fixed="top" expand="lg">
        <Navbar.Brand href={`/${locale}/home`} style={{padding: 0}}>
          {path !== '/wizard' ? <Image style={{width: 35, height: 35}} src={Logo}/> : 'Mnemonic Words'}
        </Navbar.Brand>
        {
          userId && <React.Fragment>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                {
                  path !== `/${locale}/wizard` && (
                    <React.Fragment>
                      <Link to={`/${locale}/glossaries`} className={glossaryLink}>
                        {translate('glossaries')}
                      </Link>
                      <Link to={`/${locale}/lists`} className={listsLink}>
                        {translate('lists')}
                      </Link>

                      <NavDropdown title={translate('courses')} id="courses" className={courseLink}>
                        <a href="#" onClick={(e)=>{this._courseSelected('Course 1000', 1000, e)}}
                           className='dropdown-item'>
                          {translate('course')} Beginners
                        </a>
                        <a href="#" onClick={(e)=>{this._courseSelected('Course 2000',2000, e)}} className='dropdown-item'>
                          {translate('course')} Elementary
                        </a>
                        <a href="#" onClick={(e)=>{this._courseSelected('Course 3000',3000, e)}} className='dropdown-item'>
                          {translate('course')} Intermediate
                        </a>
                        <a href="#" onClick={(e)=>{this._courseSelected('Course 4000',4000, e)}} className='dropdown-item'>
                          Oxford 3000
                        </a>
                      </NavDropdown>

                      <Link to={`/${locale}/dictionary`} className={path == `/${locale}/dictionary` ? 'nav-link active' : 'nav-link'}>
                        {translate('dictionary')}
                      </Link>

                      <NavDropdown title={translate('games')} id="games" className={gamesLink}>
                        <a href="#" onClick={(e)=>this._toGame(`/${locale}/wordtest`, e)} className={'dropdown-item'}>
                          {translate('vocabulary_test')}
                        </a>

                        <NavDropdown.Divider />

                        <a href="" onClick={(e)=>this._toGame(`/${locale}/hangman`, e)} className={'dropdown-item'}>
                          {translate('hangman')}
                        </a>
                        <a href="#" onClick={(e)=>this._toGame(`/${locale}/wordextractor`, e)} className={'dropdown-item'}>
                          {translate('word_extractor')}
                        </a>
                        <a href="#" onClick={(e)=>this._toGame(`/${locale}/wordmaker`, e)} className={'dropdown-item'}>
                          {translate('word_maker')}
                        </a>

                        {/*<NavDropdown.Divider />

                        <a href="#" onClick={(e)=>this._toGame(`/${locale}/contest-wordmaker`, e)} className={'dropdown-item'}>
                          {translate('word_maker_contest')}
                        </a>*/}
                        
                      </NavDropdown>

                      <NavDropdown title={translate('other')} className={otherLink}>
                        <Link to={`/${locale}/vocabulary`} className={'dropdown-item'}>
                          {translate('vocabulary')}
                        </Link>

                        <NavDropdown.Divider />

                        <Link to={`/${locale}/phrasebooks`} className={'dropdown-item'}>
                          {translate('phrasebooks')}
                        </Link>

                        <NavDropdown.Divider />

                        <Dropdown.Header>{translate('favorites')}</Dropdown.Header>

                        <Link to={`/${locale}/favorite/glossaries`} className={'dropdown-item'}>
                          {translate('glossaries')}
                        </Link>
                        <Link to={`/${locale}/favorite/lists`} className={'dropdown-item'}>
                          {translate('lists')}
                        </Link>
                        <Link to={`/${locale}/favorite/words`} className={'dropdown-item'}>
                          {translate('words')}
                        </Link>
                        <Link to={`/${locale}/favorite/phrases`} className={'dropdown-item'}>
                          {translate('phrases')}
                        </Link>
                      </NavDropdown>

                      {/*<NavDropdown
                        title={<OverlayTrigger
                        delay={{ show: 500, hide: 400 }}
                        placement='bottom'
                        overlay={<Tooltip>{translate('favorites')}</Tooltip>}
                      ><FontAwesomeIcon style={{fontSize: 16, color: 'rgba(255, 255, 255, 0.5)'}}
                       icon={faStar}/></OverlayTrigger>} id="favorites" className={favoriteLink}>
                        <Link to="/favorite/glossaries" className={'dropdown-item'}>
                          {translate('glossaries')}
                        </Link>
                        <Link to="/favorite/lists" className={'dropdown-item'}>
                          {translate('lists')}
                        </Link>
                        <Link to="/favorite/words" className={'dropdown-item'}>
                          {translate('words')}
                        </Link>
                        <Link to="/favorite/phrases" className={'dropdown-item'}>
                          {translate('phrases')}
                        </Link>
                      </NavDropdown>*/}

                      <OverlayTrigger
                        delay={{ show: 1000, hide: 400 }}
                        placement='bottom'
                        overlay={<Tooltip>{translate('calendar')}</Tooltip>}
                      >
                        <Link
                          to={{
                          pathname: `/${locale}/calendar`,
                          state: {type: 'general', back: true}
                        }}
                          className={path == `/${locale}/calendar` ? 'nav-link active' : 'nav-link'}
                        >
                          <FontAwesomeIcon style={{fontSize: 16}} icon={faCalendarAlt}/>
                        </Link>
                      </OverlayTrigger>
                    </React.Fragment>
                  )
                }

              </Nav>

              <Nav>
                <a href="#" className='nav-link mr-2' onClick={(e)=>{
                        e.preventDefault();
                        this._toggleModal('shareModalVisible',true)
                      }}>
                  <FontAwesomeIcon
                    style={{fontSize: 16, color: 'rgba(255, 255, 255, 0.5)'}}
                    icon={faShareAlt}/>
                </a>

                {
                  path !== `/${locale}/wizard` && userData && (
                    <Navbar.Text>{translate('scoresUC')}: <span style={{color: colors.tealish}}>{userData.scores}</span></Navbar.Text>
                  )
                }
                {
                  path !== `/${locale}/wizard` && (
                    <NavDropdown title={translate('purchases')} id="purchases" className={paymentsLink}>
                      <Link to={`/${locale}/products`} className='dropdown-item'>
                        {translate('all_products')}
                      </Link>
                      <Link to={`/${locale}/purchases`} className='dropdown-item'>
                        {translate('your_purchases')}
                      </Link>

                    </NavDropdown>
                  )
                }

                {
                  path !== `/${locale}/wizard` && (
                    <Dropdown className="nav-item" alignRight>
                      <Dropdown.Toggle as="a" role="button" id="news" className={newsLinks}>
                        {translate('news')}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {
                          news.map((article, index) => {
                            let linkClass = classNames({
                              'dropdown-item': true,
                              'text-muted': article.seen,
                              'font-weight-bold': !article.seen
                            });
                            return (
                              <a href="#"
                                 key={index}
                                 onClick={(e)=>this._toArticle(article, e)}
                                 className={linkClass}>
                                {article.title}
                              <span className="d-block font-weight-normal text-muted" style={{fontSize: 11}}>
                                {moment(article.created_at).format('DD/MM/YYYY')}
                              </span>
                              </a>
                            )
                          })
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  )
                }
                {
                  path !== `/${locale}/wizard` && (
                    <NavDropdown
                      alignRight
                      title={<OverlayTrigger
                        delay={{ show: 500, hide: 400 }}
                        placement='bottom'
                        overlay={<Tooltip>{translate('app_settings')}</Tooltip>}
                      >
                      <FontAwesomeIcon
                        style={{fontSize: 16, color: 'rgba(255, 255, 255, 0.5)'}}
                       icon={faCogs}/></OverlayTrigger>}
                      id="settings"
                    >

                      <a className='dropdown-item' href="#" onClick={(e)=>{
                        e.preventDefault();
                        this._toggleModal('notificationsModalVisible',true)
                      }}>
                        {translate('notification_time')}
                      </a>

                      <NavDropdown.Divider />

                      <a className='dropdown-item' href="#" onClick={(e)=>{
                        e.preventDefault();
                        this._toggleModal('levelModalVisible',true)
                      }}>
                        {translate('change_vocabulary_level')}
                      </a>

                      <a className='dropdown-item' href="#" onClick={(e)=>{
                        e.preventDefault();
                        this._toggleModal('listSizeModalVisible',true)
                      }}>
                        {translate('set_list_capacity')}
                      </a>

                      <NavDropdown.Divider />

                      <a className='dropdown-item' href="#" onClick={(e)=>{
                        e.preventDefault();
                        this._toggleModal('trainingsSetModal',true);
                      }}>
                        {translate('change_trainings_set')}
                      </a>

                      <a className='dropdown-item' href="#" onClick={(e)=>{
                        e.preventDefault();
                        this._toggleModal('translationLanguageModal',true);
                      }}>
                        {translate('change_translation_language')}
                      </a>

                      <a className='dropdown-item' href="#" onClick={(e)=>{
                        e.preventDefault();
                        this._toggleModal('interfaceLanguageModal',true);
                      }}>
                        {translate('interface_language_change')}
                      </a>

                      <a className='dropdown-item' href="#" onClick={(e)=>{
                          e.preventDefault();
                          this._toggleAudioModal(true);
                        }}>
                        {translate('change_audio_language')}
                      </a>

                      <a className='dropdown-item' href="#" onClick={(e)=>{
                          e.preventDefault();
                          this._toggleModal('fontModalVisible',true);
                        }}>
                        {translate('change_font_size')}
                      </a>

                      <NavDropdown.Divider />

                      <a className='dropdown-item text-danger' href="#" onClick={(e)=>{
                          e.preventDefault();
                          this.props.cleanAppCache();
                          toastr.success("", translate('app_cache_cleaned'));
                          this.props.logActivity({activityId: 298});
                        }}>
                        {translate('clean_app_cache')}
                      </a>
                      <a className='dropdown-item text-danger' href="#" onClick={(e)=>{
                        e.preventDefault();
                        this.resetSettings();
                      }}>
                        {translate('restore_settings')}
                      </a>
                    </NavDropdown>
                  )
                }
                <NavDropdown
                  alignRight
                  title={userData && userData.first_name ? userData.first_name : <FontAwesomeIcon style={{color: 'rgba(255, 255, 255, 0.5)'}} icon={faUser}/>}
                             id="account">

                  {
                    path !== `/${locale}/wizard` && (
                      <React.Fragment>

                        <Link to={`/${locale}/family`} className={'dropdown-item'}>
                          {translate('family_access')}
                        </Link>

                        <NavDropdown.Divider />

                        <a className='dropdown-item' href="#" onClick={(e)=> {
                          e.preventDefault();
                          this._toggleModal('profileModalVisible', true)
                        }}>
                          {translate('profile')}
                        </a>
                        <a className='dropdown-item' href="#" onClick={(e)=> {
                          e.preventDefault();
                          this._toggleModal('changePasswordModalVisible', true)
                        }}>
                          {translate('change_password')}
                        </a>

                        <NavDropdown.Divider />

                        <a className='dropdown-item' href="#" onClick={(e)=> {
                          e.preventDefault();
                           this.props.history.push(`/${locale}/badges`, {
                            back: true,
                            course: false,
                            from: 'home'
                          });
                        }}>
                          {translate('user_badges')}
                        </a>

                        <NavDropdown.Divider />

                        <a className='dropdown-item' href="#" onClick={(e)=> {
                          e.preventDefault();
                          this._toggleModal('bugModalVisible', true)
                        }}>
                          {translate('contact_us')}
                        </a>
                        <NavDropdown.Divider />
                      </React.Fragment>
                    )
                  }

                  <a className='dropdown-item' href="#" onClick={this._logOut}>
                    {translate('logout')}
                  </a>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </React.Fragment>
        }

        {
          interfaceLanguageModal && (
            <ChangeInterfaceLanguage
              isVisible={interfaceLanguageModal}
              onClose={this._toggleModal}
              history={history}
            />
          )
        }
        {
          translationLanguageModal && (
            <ChangeTranslationLanguage
              isVisible={translationLanguageModal}
              onClose={this._toggleModal}
            />
          )
        }

        {
          audioModalVisible && (
            <ChangeAudioLanguage
              onClose={this._toggleAudioModal}
              isVisible={audioModalVisible}
              toRegister={this._toggleRegisterModal}

            />
          )
        }

        {
          fontModalVisible && (
            <ChangeFontSize
              onClose={this._toggleModal}
              isVisible={fontModalVisible}
            />
          )
        }

        {
          levelModalVisible && (
            <ChangeVocabularyLevel
              onClose={this._toggleModal}
              isVisible={levelModalVisible}
            />
          )
        }
        {
          listSizeModalVisible && (
            <ChangeListSize
              onClose={this._toggleModal}
              isVisible={listSizeModalVisible}
            />
          )
        }
        {
          profileModalVisible && (
            <UserProfile
              onClose={this._toggleModal}
              isVisible={profileModalVisible}
              changeProfile={this._changeProfile}
            />
          )
        }
        {
          changeProfileModalVisible && (
            <ChangeUserProfile
              history={this.props.history}
              onClose={this._toggleModal}
              isVisible={changeProfileModalVisible}
            />
          )
        }
        {
          changePasswordModalVisible && (
            <ChangePassword
              onClose={this._toggleModal}
              isVisible={changePasswordModalVisible}
            />
          )
        }
        {
          notificationsModalVisible && (
            <Notifications
              onClose={this._toggleModal}
              isVisible={notificationsModalVisible}
            />
          )
        }
        
        {
          trainingsSetModal && (
            <TrainingsSet
              onClose={this._toggleModal}
              isVisible={trainingsSetModal}
            />
          )
        }

        {
          bugModalVisible && (
            <ReportBug
              onClose={this._toggleBugModal}
              isVisible={bugModalVisible}
              type="contact"
              training="false"
              extra={false}
            />
          )
        }

        {
          subscriptionAlertVisible && (
            <SubscriptionAlert
              onClose={this._toggleSubscriptionAlert}
              isVisible={subscriptionAlertVisible}
              toSubscription={this._toSubscription}
              title={'subscription_title'}
              description={subscriptionMessage}
              translate={translate}
            />
          )
        }

        {
          reviewModalVisible && (
            <ReviewModal
              onClose={this.toggleReviewModal}
              isVisible={reviewModalVisible}
              translate={translate}
            />
          )
        }
        
        {
          shareModalVisible && (
            <ShareButtons
              onClose={this._toggleModal}
              isVisible={shareModalVisible}
              translate={translate}
            />
          )
        }

      </Navbar>
    )
  }
}

function bindAction(dispatch) {
  return {
    fetchingData: (status) => dispatch(fetchingData(status)),
    logActivity: (data) => dispatch(logActivity(data)),
    setCurrentCourseSlug: (name, slug) => dispatch(setCurrentCourseSlug(name, slug)),
    cleanAppCache: () => dispatch(cleanAppCache()),
    resetUserData: () => dispatch(resetUserData()),
    setFontSize: (size) => dispatch(setFontSize(size)),
    setCurrentArticle: (article) => dispatch(setCurrentArticle(article)),
    toggleReviewModal: (status) => dispatch(toggleReviewModal(status)),

    resetHomeLists: () => dispatch(resetHomeLists()),
    resetUserLists: () => dispatch(resetUserLists()),
    resetCurrentList: () => dispatch(resetCurrentList()),
    resetCourseCache: () => dispatch(resetCourseCache()),
    resetCachedLists: () => dispatch(resetCachedLists()),
    resetVisitedCachedLists: () => dispatch(resetVisitedCachedLists()),

  };
}

const mapStateToProps = state => ({
  // token: state.auth.token,
  user: state.user,
  news: state.news,
  activity: state.activity,
  purchases: state.purchases,
  settings: state.settings,
  translate: getTranslate(state.localize),

});

export default connect(
  mapStateToProps,
  bindAction,
)(withRouter(UserNavbar));
