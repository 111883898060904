import React, {Component} from 'react';
import {Translate} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircle, faCogs, faCheckCircle,
  faTrashAlt,
  faCamera,
  faVolumeUp,
  faStar,
  faArchive,
  faTimes,
  faSearch,
  faPlus,
  faBars
} from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';

import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';

import colors from '../../../lib/colors';
import * as constants from '../../../lib/constants';

class ListCardCell extends Component {

  render() {
    const {
      list,
      ratio,
      onItemPressed,
      repeat,
      onRepeatPressed,
      index,
      onInfoPressed,
      onCalendarPressed,
      tablet,
      testProgress,
      dots,
      newList,
      onLongPressed,
      toDelete,
      showRepetitionDays,
      openTagModal,
      translate,
      deviceHeight,
      deviceWidth,
      favorites,
      onFavorite
    } = this.props;

    const imageUrl = list && list.filename ? list.filename : false;

    let correctColors = colors.greyishBrown;

    let type = 'list';

    if (repeat) {
      type = 'repeat'
    }
    if (newList) {
      type = 'newList'
    }

    const imageWidth = 200;

    const s3image = `${constants.S3MarkedImages}-${imageWidth}/${imageUrl}`;

    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h4">
          <Translate id="repetition_intervals"/>
        </Popover.Title>
        <Popover.Content>
          <div className="repetitionItems">
            {list && list.intervals && list.intervals.map((item, i) => {
              return <p key={i} className="description">
                {i + 1}. {item.repetition_date}
              </p>
            })}
          </div>
        </Popover.Content>
      </Popover>
    );

    return (
      <Card
        style={{
            width: 200,
            height: 320,
            margin: '10px 10px'}}
        className="listCard"
        onClick={(e) => onItemPressed(list, index, type, e)}
      >
        <div
          className="listWrapper ml-0 mr-0"

        >
          <div className="listContainer">
            {toDelete && <div className="toDelete"/>}
            {list.archive && !toDelete && (
              <OverlayTrigger
                delay={{ show: 500, hide: 500 }}
                placement="auto" overlay={<Tooltip>{translate('list_in_archive')}</Tooltip>}>
                <FontAwesomeIcon icon={faArchive} className="archive"/>
              </OverlayTrigger>) || null
            }
            {
              !favorites && <a href="#" onClick={(e)=>onLongPressed(list,index, type, e)}>
                <FontAwesomeIcon icon={faBars} className="bars"/>
              </a> || null
            }

            <span className="listName" style={{
            minHeight: !tablet ? 40 : deviceWidth > 600 ? 70 : 50,
            paddingHorizontal: 25, fontSize: ratio * (!tablet ? 14 : 16)}}
            >
              {list.list_name && list.list_name.length <= 40 ? list.list_name : `${list.list_name.substr(0, 40)}...`}
            </span>
            {
              imageUrl && (
                <Image
                  draggable="false"
                  onContextMenu={(e)=>e.preventDefault()}
                  className="wordImage"
                  style={{height: !tablet ? (deviceWidth > 350 ? 80 : 70) : 120, width: !tablet ? (deviceWidth > 350 ? 110 : 96) : 165}}
                  src={s3image}
                />
              ) || <div className="imagePlaceholder" style={{height: !tablet ? 80 : 120, width: !tablet ? 110 : 165}}/>
            }


            <span
              className="createdAt"
              style={{fontSize: ratio * (!tablet ? 12 : 14),marginTop: 0, marginBottom: !tablet ? 5 : 10}}>
              {moment(list.created_at).format('YYYY-MM-DD')}
            </span>

            <div className="flexH">
              <span className="scores" style={{fontSize: ratio * (!tablet ? 12 : 14)}}>
                {translate('wordss')}:
              </span>
              <span className="scoresNum" style={{fontSize: ratio * (!tablet ? 12 : 14)}}>
                {list.words}
              </span>
            </div>

            <div className="flexH">
              <span className="scores" style={{fontSize: ratio * (!tablet ? 12 : 14)}}>
                {translate('trainings')}:
              </span>
              <span className="scoresNum" style={{fontSize: ratio * (!tablet ? 12 : 14)}}>
                {list.trainings}
              </span>
            </div>

            {
              dots && (
                <OverlayTrigger
                  trigger="click" placement="auto" overlay={popover}>
                  <a href="#" style={{width: '100%'}} onClick={(e)=> dots ? showRepetitionDays(list.intervals, e) : null}>
                    <div
                      className="starsContainer"
                      style={{ height: !tablet ? 20 : (deviceWidth > 600 ? 30 : 40),
                  marginTop: !tablet ? 15 : 25, marginBottom: !tablet ? 15 : 25,
                  }}>
                      {dots}
                    </div>
                  </a>
                </OverlayTrigger>
              ) ||
              (
                <div
                  className="starsContainer"
                  style={{ height: !tablet ? 20 : (deviceWidth > 600 ? 30 : 40),
                  marginTop: !tablet ? 15 : 25, marginBottom: !tablet ? 15 : 25,
                  }}/>
              )
            }

            {
              list.words > 0 && (
                <Button
                  variant="outline-success"
                  className="dFlex flexCenter flex-column"
                  style={{minWidth: !tablet ? 120 : 150, height: !tablet ? (deviceWidth > 360 ? 50 : 45) : 75}}
                  onClick={(e) => onRepeatPressed(list, repeat, e)}

                >
                  <span style={{fontSize: ratio * (!tablet ? 14 : 16)}}>
                    {translate(repeat ? 'repeat' : 'start')}
                  </span>

                  {
                    repeat && list.repetition != 0 && (
                      <span className="d-block" style={{fontSize: ratio * (!tablet ? 12 : 14)}}>
                        {translate('day')} {list.repetition} / 7
                      </span>
                    )
                  }

                </Button>
              ) || null
            }
          </div>
          {list.favourite && !favorites && (
            <OverlayTrigger
              delay={{ show: 500, hide: 500 }}
              placement="auto" overlay={<Tooltip>{translate('in_favorites')}</Tooltip>}>
              <FontAwesomeIcon icon={faStar} className="favorite" style={{top: 5, left:5}}/>
            </OverlayTrigger>
          ) || null}

          {
            list.favourite && favorites && (
              <a
                href="#" onClick={(e)=>{
                  e.preventDefault();
                  e.stopPropagation();
                  
                  onFavorite(list, index)
                }}>
                <OverlayTrigger
                  delay={{ show: 500, hide: 500 }}
                  placement="auto" overlay={<Tooltip>{translate('delete_from_favorites')}</Tooltip>}>
                  <FontAwesomeIcon icon={faTrashAlt} className="delete" style={{top: 5, left:5}}/>
                </OverlayTrigger>
              </a>
            ) || null
          }

          <div className="tagPlank flexH flexCenter"
               style={{height: !tablet ? 12 : 18, backgroundColor: list.tag ? list.tag.color : colors.white}}
          >
            {list.tag &&
            <span className="tagName" style={{height: !tablet ? 12 : 18, fontSize: !tablet ? 10 : 14}}>
              {list.tag.tag_name}</span> || null}
          </div>
        </div>
      </Card>
    );
  }
}

export default ListCardCell;
