import React, {Component} from 'react';

import Pagination from "react-js-pagination";

class MyPagination extends Component {

  renderPages = () => {
    const {onClick, pages, currentPage, tablet, ratio, fake, range} = this.props;

    return [...Array(pages).keys()].map((item)=> {
      let p = item + 1;
      return (
      <Pagination.Item
        onClick={()=>onClick(p)}
        key={p} active={p === currentPage}>
        {p}
      </Pagination.Item>
      )
    })
  };

  handlePageChange(pageNumber) {
    this.props.onClick(pageNumber)
    // console.log(`active page is ${pageNumber}`);
    // this.setState({activePage: pageNumber});
  }

  render() {
    const {pages, tablet, style, currentPage, range} = this.props;

    return pages && pages > 1 && (
        <div className="custom-pagination">
          <Pagination
            className="mb-0"
            itemClass="page-item"
            linkClass="page-link"
            activePage={currentPage}
            itemsCountPerPage={1}
            totalItemsCount={pages}
            pageRangeDisplayed={range}
            onChange={this.handlePageChange.bind(this)}
          />
        </div>
      ) || null;
  }
}

export default MyPagination;
