import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircle, faCogs, faCheckCircle,
  faTrashAlt,
  faCamera,
  faVolumeUp,
  faTimes, faSearch, faPlus
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import {Translate, getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';
import moment from 'moment';

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import Loader from "../../../components/Custom/Loader/index";

import WordInfo from '../../../components/Modals/WordInfo/index';
import ListWord from '../../../components/Cells/ListWord/index';
import TrainingButtons from '../../../components/Modals/TrainingButtons/index';
import ListToolbar from '../../../components/Toolbars/ListToolbar/index';
import GlossariesForList from '../../../components/Modals/GlossariesForList/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
import CopyPasteWords from '../../../components/Modals/CopyPasteWords/index';
// import AddListModal from '../../../components/Modals/AddListModal/index';
import ListsToFill from '../../../components/Modals/ListsToFill/index';
import TagsModal from '../../../components/Modals/TagsModal/index';
import TrainingsSet from '../../../components/Modals/TrainingsSet/index';

import {getFavoriteWords, getAllFavoriteNumbers, deleteFromFavoriteWords} from '../../../actions/favoriteActions';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import {getTags, connectTag} from '../../../actions/tagActions';

import {
  resetListWords,
  getListWords,
  deleteListFromList,
  editList,
  setCurrentList,
  deleteListWord,
  toggleInVocabulary,
  toggleFavoriteWord,
  toggleFavouriteList,
  addUserWords,
  removeFromCalendar,
  postponeInCalendar,
  toggleListTrainingButtonsModal,
  setCachedListWords,
  resetCurrentList,
  toggleArchiveList,
  cleanListCache,
  performBulkAction,
  moveToOtherLists,
  getCurrentListData,
}
  from '../../../actions/lists.js';

import colors from '../../../lib/colors';
import * as constants from '../../../lib/constants';

class FavoriteWords extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searchBarVisible: false,
      trainingsModalVisible: false,
      listId: false,
      refreshing: false,
      subscriptionAlertVisible: false,
      tablet: false,
      page: 1,

      bulkMode: false,
      bulkModeType: false,
      listsModalIsVisible: false,
      selectedWords: [],
      wordsSettingsVisible: false,
      scrolled: 0,
      trainingsSetModal: false,

    };

    this.track = null;
    this.timeOut = null;
  }

  componentDidMount() {
    this._getGlossariesData();
    window.addEventListener('scroll', this.handleScroll.bind(this));
    this.props.logActivity({activityId: 184});
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._getGlossariesData();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const scrollY = window.scrollY;

    this.setState({
      scrolled: scrollY
    });
  }

  _getGlossariesData = () => {
    const {isConnected} = this.props.settings;
    const {page} = this.state;

    if (isConnected) {
      this.props.fetchingData(true);

      this.props.getFavoriteWords(page);
    }
  };

  handleBackPress = async() => {
    this.props.navigation.goBack();
    return true;
  };

  favoriteToggle = (id) => {
    this.props.fetchingData(true);
    this.props.deleteFromFavoriteWords(id)
  };

  toggleTrainingButtons = (status) => {
    this.setState({
      trainingsModalVisible: status
    })
  };

  trainingSelected = (training) => {
    const {todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus} = this.props.purchases;
    const {locale} = this.props.settings;

    let active = false;
    const {userData} = this.props.user;
    // const {currentList} = this.props.lists;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    if (tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    this.setState({
      trainingsModalVisible: false
    });

    this.props.toggleListTrainingButtonsModal(false);

    if (active) {
      let activityId = '';

      let path = '';

      if (training == 'Preview') {
        activityId = 39;
        path = 'preview'
      } else if (training == 'DragImage') {
        path = 'image';

        activityId = 40;
      } else if (training == 'SelectTranslation') {
        path = 'translation';
        activityId = 41;
      } else if (training == 'SelectWord') {
        path = 'word';
        activityId = 42;
      } else if (training == 'CollectWord') {
        path = 'collect';
        activityId = 43;
      } else if (training == 'CheckSpelling') {
        path = 'spelling';
        activityId = 44;
      } else if (training == 'CollectSentence') {
        activityId = 114;
        path = 'sentence';
      } else if (training == 'CollectSentenceTap') {
        activityId = 335;
        path = 'sentence';
      } else if (training == 'MemoryCards') {
        activityId = 131;
        path = 'memory'
      } else if (training == 'WordDefinition') {
        path = 'definition';
        activityId = 148;
      } else if (training == 'SpeakOut') {
        activityId = 95;
      } else if (training == 'MissingLetter') {
        path = 'missing';
        activityId = 315;
      } else if (training == 'Listening') {
        path = 'listening';
        activityId = 345;
      } else if (training == 'TranslateSentence') {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut = setTimeout(()=> {
        this.props.history.push(`/${locale}/${path}/-2`, {type: 'listId', from: 'listWords', listId: -2});
        this.props.logActivity({activityId, model: 'list', modelId: -2, comment: 'from fav words'});
      }, 100);
    } else {
      this._toggleSubscriptionAlert(true);
    }
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toSubscription = (productId) => {
    this._toggleSubscriptionAlert(false);
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId});
  };

  playTrack = (url) => {
    const {isConnected} = this.props.settings;

    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    if (isConnected) {
      this.track = new Audio(url);
      this.track.play();
    }
  };

  vocabularyToggle = (id, index) => {
    const {words} = this.props.favorites;

    let word = words[index];
    this.props.fetchingData(true);

    this.props.toggleInVocabulary(id, index, true);

    this.props.logActivity({
      activityId: word.in_vocabulary == true ? 49 : 48,
      model: 'word',
      modelId: id,
      comment: "from favorite"
    });
  };

  _renderItem = (item, index) => {
    const {fontSizeRation, version} = this.props.settings;
    const {tablet, bulkMode, bulkModeType, selectedWords} = this.state;
    const imageUrl = item.image && item.image.filename ? item.image.filename : false;
    const {userData, userId} = this.props.user;
    const {translate} = this.props;
    const imageUserId = item && item.image && item.image.user_id ? item.image.user_id : false;

    let selected = selectedWords.indexOf(item.id);

    let circleColor = colors.lightGrey;
    if (bulkMode) {
      if (bulkModeType == 'delete') {
        circleColor = colors.coral;
      } else if (bulkModeType == 'vocabulary') {
        circleColor = colors.waterBlue;
      } else if (bulkModeType == 'favorite') {
        circleColor = colors.golden;
      } else if (bulkModeType == 'move') {
        circleColor = colors.greyishBrown;
      }
    }

    const translation = item && item.main_translation && item.main_translation.translation ? item.main_translation.translation : "";

    let audio = item && item.audio ? item.audio : false;
    let audioSource = constants.BACKEND_URL;

    if (userData && userData.audio_language && item[userData.audio_language]) {
      audio = item[userData.audio_language];
      audioSource = constants.S3
    }

    const imageWidth = !tablet ? 150 : 200;
    const wordName = item && item.word ? item.word : "";
    const transcription = item && item.transcription ? item.transcription : "";


    return (
      <ListWord
        key={index}

        wordName={wordName}
        transcription={transcription}
        imageWidth={imageWidth}
        translation={translation}
        audio={audio}
        audioSource={audioSource}
        userData={userData}
        word={item}
        index={index}
        imageUrl={imageUrl}
        imageUserId={imageUserId}
        userId={userId}
        inVocabulary={item.in_vocabulary}
        inFavorite={true}
        tablet={tablet}
        version={version}
        ratio={fontSizeRation}
        onPlay={this.playTrack}
        onPressWord={this._onPressWord}
        bulkMode={bulkMode}

        favoriteWords={true}

        circleColor={circleColor}
        selected={selected !== -1}
        translate={translate}
        favoriteWordToggle={this.favoriteToggle}
        vocabularyToggle={this.vocabularyToggle}
        bulkMode={bulkMode}
      />
    )
  };

  _onPressWord = (word) => {
    const {bulkMode, bulkModeType, selectedWords} = this.state;
    const {translate} = this.props;

    if (bulkMode) {
      let index = selectedWords.indexOf(word.id);

      if (index === -1) {
        if (bulkModeType == 'delete' || bulkModeType == 'move') {
          this.setState({
            selectedWords: [...selectedWords, word.id]
          })
        } else {
          if (bulkModeType == 'vocabulary') {
            if (!word.in_vocabulary) {
              this.setState({
                selectedWords: [...selectedWords, word.id]
              })
            } else {
              toastr.warning("", translate('already_there'))
            }
          } else if (bulkModeType == 'favorite') {
            if (!word.is_favourite) {
              this.setState({
                selectedWords: [...selectedWords, word.id]
              })
            } else {
              toastr.warning("", translate('already_there'))
            }
          }
        }
      } else {
        selectedWords.splice(index, 1);

        this.setState({
          selectedWords
        })
      }
    }
  };

  pageClicked = (page) => {
    const {isConnected} = this.props.settings;

    this.setState({
      page
    });

    if (isConnected) {
      this.props.fetchingData(true);

      this.props.getFavoriteWords(page);
    }
  };

  _toggleWordsSettingsModal = (status) => {
    this.setState({
      wordsSettingsVisible: status
    })
  };

  _toggleBulkMode = (type) => {
    this._toggleWordsSettingsModal(false);

    this.setState({
      bulkMode: true,
      bulkModeType: type
    })

  };

  _performBulkAction = () => {
    const {currentList} = this.props.lists;
    const {translate} = this.props;

    const {selectedWords, bulkModeType} = this.state;

    if (selectedWords.length == 0) {
      toastr.alert("", translate('no_words_selected'));
    } else {
      if (bulkModeType != 'move') {
        this.setState({
          bulkMode: false
        });
        this.props.fetchingData(true);

        this.props.performBulkAction(-2, selectedWords, bulkModeType, 'favorite');
        this.props.logActivity({activityId: 338, comment: `${bulkModeType}`});

        this._disableBulkMode();
      } else {
        this.toggleListsToFillModal(true);
      }
    }
  };

  toggleSelectAll = (e) => {
    e.preventDefault();
    
    const {selectedWords, bulkModeType} = this.state;
    const {words} = this.props.favorites;

    if (selectedWords.length > 0) {
      this.setState({
        selectedWords: []
      })
    } else {
      let selected = [];

      words.forEach(item => {
        if (bulkModeType == 'delete' || bulkModeType == 'move') {
          selected.push(item.id)
        } else {
          if (bulkModeType == 'vocabulary') {
            if (!item.in_vocabulary) {
              selected.push(item.id)
            }
          } else if (bulkModeType == 'favorite') {
            if (!item.is_favourite) {
              selected.push(item.id)
            }
          }
        }
      });

      this.setState({
        selectedWords: selected
      })
    }
  };

  _disableBulkMode = () => {
    this.setState({
      selectedWords: [],
      bulkMode: false,
      bulkModeType: false
    })
  };

  toggleListsToFillModal = (status) => {
    // this.props.logActivity({activityId: 18});

    this.setState({
      listsModalIsVisible: status
    })
  };

  fillLists = (id) => {
    const {selectedWords} = this.state;

    this.toggleListsToFillModal(false);

    this.props.fetchingData(true);

    this.props.moveToOtherLists(-2, id, selectedWords, 'favorite');

    this._disableBulkMode();
  };

  _toggleModal = (type, status) => {
    this.setState({
      [type]: status
    })
  };

  _customizeButtons = (e) => {
    e.preventDefault();
    this.toggleTrainingButtons(false);
    this._toggleModal('trainingsSetModal', true)
  };

  render() {
    const {words, fetchedFavoriteWords, totalPages, totalWords} = this.props.favorites;
    const {
      subscriptionAlertVisible,
      trainingsModalVisible, tablet, page,
      wordsSettingsVisible,
      listsModalIsVisible,
      selectedWords,
      bulkMode,
      bulkModeType,
      scrolled,
      trainingsSetModal
    } = this.state;
    const {fetchingData} = this.props.activity;
    const {fontSizeRation, locale, deviceHeight, deviceWidth} = this.props.settings;
    const {userData, approvedDictionaryTranslations} = this.props.user;
    const {translate} = this.props;
    const {lists, showListTrainingButtons} = this.props.lists;
    let level = userData && userData.level ? userData.level : 0;
    const subtitle = `${translate('wordss')}: ${totalWords}`;
    const {listCards, products, lifetimeStatus, subscriptionActive, webSubscriptionStatus} = this.props.purchases;

    const listSize = userData && userData.list_size ? userData.list_size : 10;
    const toolbar = classNames({
      'fixed-toolbar': scrolled >= 50,
      'justify-content-between listToolbar justify-content-center mb-4': true
    });

    let active = false;

    if (subscriptionActive || webSubscriptionStatus) {
      active = true;
    }

    return (
      <div className="favoriteWords">
        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate('favourite_words')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <ListToolbar
          translate={translate}
          ratio={fontSizeRation}
          tablet={tablet}
          toolbar={toolbar}
          active={active}
          deviceHeight={deviceHeight}
          deviceWidth={deviceWidth}
          listSize={listSize}
          favoriteWords={true}
          title={translate('favorite_words')}

          fromDictionary={false}
          fromGlossaries={false}
          paste={this.copyPasteWords}
          toggleFavorite={this.toggleFavorite}
          disabled={true}
          updateList={false}
          deleteList={false}
          postpone={false}
          reallist={true}
          removeFromCalendar={false}
          list={{}}
          favorite={false}
          studyLanguage={'en'}
          buyListCards={false}
          listCards={listCards}
          generateCards={false}
          products={products}
          toVideo={this._toVideoInstructions}
          lifetimeStatus={lifetimeStatus}
          toggleArchive={this._toggleArchive}
          cleanListCache={this._cleanListCache}
          translationLanguage={userData.translation_language}
          approvedDictionaryTranslations={approvedDictionaryTranslations}
          words={fetchedFavoriteWords ? words.length : 0}
          openTagsModal={false}

          paste={this.copyPasteWords}
          toBulkMode={this._toggleBulkMode}
          fromDictionary={false}
          fromGlossaries={false}
          showDictionary={false}
          listWords={words}
          bulkMode={bulkMode}
          toggleTrainingButtons={this.toggleTrainingButtons}
          page={page}
          totalPages={totalPages}
          pageClicked={this.pageClicked}
        />

        <p className="text-secondary text-center">
          <span className="font-weight-bold">
            {translate('favourite_words')}
          </span>
          <span className="font-weight-light d-block">
            {subtitle}
          </span>
        </p>

        <Container className="pageWrapper">

          {
            fetchedFavoriteWords && words.length > 0 && bulkMode && bulkModeType && (
              <Card className="deleteListsTextWrapper">
                  <span className="bulkModeText font-weight-bold" style={{fontSize: fontSizeRation * 16}}>
                    {translate(`list_words_to_${bulkModeType}_action`)}
                  </span>

                <div className="bulkModeWrapper flex-md-row flex-sm-column flex-column" style={{marginTop: 10}}>

                  <a href="#"
                     className="mr-3 mb-0 mb-sm-2 mb-md-0"
                     onClick={this.toggleSelectAll}>
                    <FontAwesomeIcon
                      className="selectedIcon"
                      icon={selectedWords.length > 0 ? faCheckCircle : faCircle}
                      style={{marginRight: !tablet ? 10 : 20, fontSize: !tablet ? 20 : 30}}/>
                    <span
                      className="selectAllWords pv4"
                      style={{marginRight: 10, color: colors.greyishBrown, fontSize: fontSizeRation * 16}}>
                      {selectedWords.length > 0 ? translate('deselect_words') : translate('select_all_words')}
                    </span>
                  </a>

                  <Button
                    size="sm"
                    className="mr-3 mb-0 mb-sm-2 mb-md-0"
                    disabled={selectedWords.length === 0}
                    variant={bulkModeType == 'delete' ? 'outline-danger' : 'outline-success'}

                    onClick={this._performBulkAction}
                  >
                    {translate(`to_${bulkModeType}`)} - {selectedWords.length}
                  </Button>

                  <Button
                    size="sm"
                    className="mr-3"
                    variant="outline-secondary"
                    onClick={this._disableBulkMode}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </Card>
            )
          }

          <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
            {
              words.map((list, index) => {
                return this._renderItem(list, index);
              })
            }
          </div>


          {(trainingsModalVisible || showListTrainingButtons) && words.length > 0 && (
            <TrainingButtons
              tablet={tablet}
              level={level}
              translate={translate}
              modalVisible={trainingsModalVisible || showListTrainingButtons}
              training={this.trainingSelected}
              onClose={() => this.toggleTrainingButtons(false)}
              ratio={fontSizeRation}
              translationLanguage={userData.translation_language}
              title="select_training_to_repeat"
              customiseButtons={this._customizeButtons}

            />
          )}

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={'subscription_trainings'}
                translate={translate}
              />
            )
          }

          {listsModalIsVisible && (
            <ListsToFill
              isVisible={listsModalIsVisible}
              lists={lists}
              fillLists={this.fillLists}
              listSize={listSize}
              currentListId={-2}
              ratio={fontSizeRation}
              selectedWords={selectedWords.length}
              onClose={this.toggleListsToFillModal}
              translate={translate}
            />
          )}

        </Container>

        {
          trainingsSetModal && (
            <TrainingsSet
              onClose={this._toggleModal}
              isVisible={trainingsSetModal}
            />
          )
        }
        <Loader fetchingData={fetchingData}/>

      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  lists: state.lists,
  favorites: state.favorites,
  purchases: state.purchases,
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    resetListWords: () => dispatch(resetListWords()),
    resetCurrentList: () => dispatch(resetCurrentList()),
    getListWords: (id) => dispatch(getListWords(id)),
    deleteListWord: (list_id, word_id, index) => dispatch(deleteListWord(list_id, word_id, index)),
    toggleInVocabulary: (word_id, index, fav) => dispatch(toggleInVocabulary(word_id, index, fav)),
    toggleFavoriteWord: (list_id, word_id, index) => dispatch(toggleFavoriteWord(list_id, word_id, index)),
    toggleFavouriteList: (list_id, index) => dispatch(toggleFavouriteList(list_id, index)),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    deleteListFromList: (listId, back) => dispatch(deleteListFromList(listId, back)),
    addUserWords: (text, allWords, listId) => dispatch(addUserWords(text, allWords, listId)),
    editList: (listName, listId, filter, order) => dispatch(editList(listName, listId, filter, order)),
    setCurrentList: (list) => dispatch(setCurrentList(list)),
    removeFromCalendar: (listId, filter, order) => dispatch(removeFromCalendar(listId, filter, order)),
    postponeInCalendar: (listId) => dispatch(postponeInCalendar(listId)),
    toggleListTrainingButtonsModal: (status) => dispatch(toggleListTrainingButtonsModal(status)),
    setCachedListWords: (data) => dispatch(setCachedListWords(data)),
    toggleArchiveList: (id) => dispatch(toggleArchiveList(id)),
    cleanListCache: (id) => dispatch(cleanListCache(id)),
    performBulkAction: (listId, words, type, source) => dispatch(performBulkAction(listId, words, type, source)),
    moveToOtherLists: (fromListId, toListId, words, source) => dispatch(moveToOtherLists(fromListId, toListId, words, source)),
    getTags: () => dispatch(getTags()),
    connectTag: (listId, tagId) => dispatch(connectTag(listId, tagId)),
    getCurrentListData: (listId) => dispatch(getCurrentListData(listId)),
    deleteFromFavoriteWords: (id) => dispatch(deleteFromFavoriteWords(id)),
    getFavoriteWords: (page) => dispatch(getFavoriteWords(page)),

  };
}

export default connect(mapStateToProps, bindAction)(FavoriteWords);
