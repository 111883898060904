import request from 'superagent';
import {select, put, delay} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logApiError} from './logSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* getActiveMultiWordMakerGames(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getActiveMultiWordMakerGames')
    .query({
      user_id: userId,
      // page: data.payload
    });

    // console.log("getActiveMultiWordMakerGames", result.body)

    yield put({
      type: types.SET_MULTI_WORD_MAKER_GAMES,
      payload: result.body
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getActiveMultiWordMakerGames',
        payload: "",
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getCurrentActiveWordMakerGame(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getCurrentActiveWordMakerGame')
    .query({
      user_id: userId,
      game_id: data.payload
    });

    // console.log("getCurrentActiveWordMakerGame", result.body)

    yield put({
      type: types.SET_CURRENT_ACTIVE_MULTI_WORD_MAKER_GAME,
      payload: result.body
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getCurrentActiveWordMakerGame',
        payload: "",
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


export function* createWordMakerMultiGame(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'createWordMakerMultiGame')
    .send({
      user_id: userId,
      by_invitation: 0
    });

    // console.log("createWordMakerMultiGame", result.body)

    if (result.body.result) {
      data.payload.history.push(`/contest-wordmaker/${result.body.result.game_id}/waiting`, {game: result.body.result})
      // yield getActiveMultiWordMakerGames();
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'createWordMakerMultiGame',
        payload: "",
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    /*yield put({
      type: types.FETCHING_DATA,
      payload: false
    });*/
  }
}

export function* cancelMultiWordMakerGame(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'cancelMultiWordMakerGame')
    .send({
      user_id: userId,
      game_id: data.payload.gameId
    });

    // console.log("cancelMultiWordMakerGame", result.body)

    if (result.body.result) {
      data.payload.history.goBack();
      // yield getActiveMultiWordMakerGames();
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'cancelMultiWordMakerGame',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    /*yield put({
      type: types.FETCHING_DATA,
      payload: false
    });*/
  }
}

export function* joinMultiWordMakerGame(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'joinMultiWordMakerGame')
    .send({
      user_id: userId,
      game_id: data.payload.gameId
    });

    // console.log("joinMultiWordMakerGame", result.body)

    if (result.body.result) {
      data.payload.history.push(`/contest-wordmaker/${result.body.result.game_id}/waiting`, {game: result.body.result})

      // yield getActiveMultiWordMakerGames();
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'joinMultiWordMakerGame',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    /*yield put({
      type: types.FETCHING_DATA,
      payload: false
    });*/
  }
}

export function* exitMultiWordMakerGame(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'exitMultiWordMakerGame')
    .send({
      user_id: userId,
      game_id: data.payload.gameId
    });

    // console.log("exitMultiWordMakerGame", result.body)

    if (result.body.result) {
      yield getActiveMultiWordMakerGames();
      data.payload.history.goBack();

    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'exitMultiWordMakerGame',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    /*yield put({
      type: types.FETCHING_DATA,
      payload: false
    });*/
  }
}

export function* startMultiWordMakerGame(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'startMultiWordMakerGame')
    .send({
      user_id: userId,
      game_id: data.payload.gameId
    });

    // console.log("startMultiWordMakerGame", result.body)

    if (result.body.result) {
      yield put({
        type: types.SHOULD_START_MULTI_MAKER,
        payload: true
      })
      // data.payload.history.push(`/contest-wordmaker/${data.payload.gameId}`);

      yield delay(3000);
      yield makeMultiWordMakerGameInactive({payload: data.payload.gameId});
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'startMultiWordMakerGame',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    /*yield put({
      type: types.FETCHING_DATA,
      payload: false
    });*/
  }
}

export function* makeMultiWordMakerGameInactive(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'makeMultiWordMakerGameInactive')
    .send({
      user_id: userId,
      game_id: data.payload
    });

    // console.log("makeMultiWordMakerGameInactive", result.body)
    
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'makeMultiWordMakerGameInactive',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* inviteUsersToMultiMaker(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'inviteUsersToMultiMaker')
    .send({
      user_id: userId,
      users: data.payload
    });

    // console.log("inviteUsersToMultiMaker", result.body)
    if (result.body.result) {
      yield getActiveMultiWordMakerGames();
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'inviteUsersToMultiMaker',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* logMultiWordMakerResults(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'logMultiWordMakerResults')
    .send({
      user_id: userId,
      game_id: data.payload.gameId,
      words: data.payload.words
    });

    // console.log("logMultiWordMakerResults", result.body)

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'logMultiWordMakerResults',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


export function* getMultiWordMakerResults(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getMultiWordMakerResults')
    .query({
      user_id: userId,
      game_id: data.payload
    });

    // console.log("getMultiWordMakerResults", result.body)

    yield put({
      type: types.SET_MULTI_WORD_MAKER_GAME_RESULTS,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getMultiWordMakerResults',
        payload: "",
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


export function* getTotalRating() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getTotalRating')
    .query({
      user_id: userId,
    });

    // console.log("getTotalRating", result.body)

    yield put({
      type: types.SET_MULTI_WORD_MAKER_GAME_RATING,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getTotalRating',
        payload: "",
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}