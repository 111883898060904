import * as types from '../actions/types';
import _ from 'lodash';
import {createListWithIntervals} from '../lib/trainingFunctions';

const INITIAL_STATE = {
  lists: [],
  listsToFill: [],
  totalLists: 0,
  totalWords: 0,
  filter: "by_list_name",
  order: "asc",
  currentList: {},
  currentListInLists: {},
  listWords: [],
  fetchedListWords: false,
  listsToRepeat: [],
  newLists: [],
  showNewLists: false,
  sections: [],
  relaxPlan: false,
  listsNumber: 0,
  listsFetched: false,
  showListTrainingButtons: false,
  showArchive: "0",
  viewType: 'card',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
    case types.SET_USER_LISTS:
      return Object.assign({}, state, {
        lists: action.payload.data,
        totalLists: action.payload.total_lists,
        totalWords: action.payload.total_words,
        listsFetched: true
      });

    case types.TOGGLE_SHOW_ARCHIVED_LIST:
      return Object.assign({}, state, {
        showArchive: action.payload,
      });

    case types.CHANGE_LIST_VIEW_TYPE:
      return Object.assign({}, state, {
        viewType: action.payload,
      });
    
    case types.CLEAR_SCANNED_TEXT:
      return Object.assign({}, state, {
        showArchive: action.payload,
      });

    case types.ADD_TO_USER_LISTS: {
      // console.log("Previous", state.lists)

      let newLists = _.union(action.payload.lists, state.lists);

      // console.log("newLists", newLists)

      return Object.assign({}, state, {
        lists: newLists,
        totalLists: state.lists.length + action.payload.lists.length,
        totalWords: state.totalWords + action.payload.words,
        listsFetched: true
      });
    }
    
    case types.RESET_USER_LISTS:
      return Object.assign({}, state, {
        lists: [],
        totalLists: 0,
        totalWords: 0,
        listsFetched: false
      });
    
    case types.RESET_CURRENT_LIST:
      return Object.assign({}, state, {
        currentList: {}
      });

    case types.SET_USER_LISTS_FROM_CACHE:
      return Object.assign({}, state, {
        lists: action.payload.data,
        totalLists: action.payload.total_lists,
        totalWords: action.payload.total_words,
        listsFetched: true
      });

    case types.SET_USER_LISTS_TO_FILL:
      return Object.assign({}, state, {
        listsToFill: action.payload,
      });

    case types.APPEND_NEW_LIST:{
      let newLists = state.listsToFill.unshift(action.payload);
      
      return Object.assign({}, state, {
        listsToFill: newLists,
      });
    }

    case types.SET_NEWLY_CREATED_LISTS:
      return Object.assign({}, state, {
        newLists: action.payload,
        showNewLists: true,
      });

    case types.SET_SHOW_LIST_TRAINING_BUTTONS_MODAL:
      return Object.assign({}, state, {
        showListTrainingButtons: action.payload,
      });

    case types.SET_SHOW_NEWLY_CREATED_LISTS:
      return Object.assign({}, state, {
        showNewLists: action.payload,
      });

    case types.SET_LISTS_NUMBER:
      return Object.assign({}, state, {
        listsNumber: action.payload,
      });

    case types.ADD_TO_HOME_NEW_LISTS: {
      let sectionFound = false;

      let sections = state.sections.map(section => {
        
        if(section.title == 'new_lists') {
          sectionFound = true;
          section.data = _.union(action.payload.lists, section.data);
        }
        
        return section;
      });

      if(!sectionFound) {
        sections.push({
          title: 'new_lists',
          data: action.payload.lists
        })
      }

      sections = sections.filter(section => {
        return section.title !== 'relax';
      });

      return Object.assign({}, state, {
        sections,
        relaxPlan: false,
      });
    }

    case types.SET_LISTS_TO_REPEAT:
    {
      let sections = [];
      if (action.payload.mistakes.length > 0 && action.payload.mistakes[0].words > 0) {
        sections.push({title: 'with_mistakes', data: action.payload.mistakes})
      }
      if (action.payload.repeat.length > 0) {
        sections.push({title: 'time_to_repeat', data: action.payload.repeat})
      }
      if (action.payload.new.length > 0) {
        sections.push({title: 'new_lists', data: action.payload.new})
      }

      let relaxPlan = sections.length === 0;

      // console.log("SET_LISTS_TO_REPEAT", relaxPlan, sections)
      
      return Object.assign({}, state, {
        sections,
        relaxPlan,
        listsToRepeat: action.payload.data
      });
    }

    case types.UPDATE_LISTS_TO_REPEAT:
    {
      let sections = state.sections;

      if(action.payload.type == 'deleteList') {
        sections = sections.filter(section => {
          section.data = section.data.filter(list => {
            return list.id !== action.payload.listId
          })
          return section.data.length > 0;
        })
      } else if(action.payload.type == 'deleteLists') {
        sections = sections.filter(section => {
          section.data = section.data.filter(list => {
            let toDelete = false;

            action.payload.listId.forEach(listik => {

              if (list.id === listik) {
                toDelete = true;
              }
            });

            return !toDelete;
          })
          return section.data.length > 0;
        })
      }
      
      let relaxPlan = sections.length === 0;

      if (relaxPlan) {
        /*sections.push({
          title: 'relax', data: [
            {list_name: translate('look_in_glossaries'), segue: 'Glossaries'},
            {list_name: translate('try_word_maker'), segue: 'WordMakerWords'},
            {list_name: translate('try_test'), segue: 'WordTest'},
            {list_name: translate('try_extractor'), segue: 'WordExtractor'},
          ]
        });*/
      }

      return Object.assign({}, state, {
        sections,
        relaxPlan,
      });
    }
    
    case types.MOVE_NEW_LIST_TO_REPEAT:
    {
      let sections = state.sections;
      let repeatSection = false;
      let noNew = false;
      let newSection = false;

      // console.log("MOVE_NEW_LIST_TO_REPEAT", action.payload.list)
      
      let newList = createListWithIntervals(action.payload.list)

      sections = sections.filter(section => {
        if(section.title == 'new_lists') {
          newSection = true;
          
          section.data = section.data.filter(list => {
            return action.payload.list.id !== list.id;
          })

          if(section.data.length == 0) {
            noNew = true;
          }
        }
        
        if(section.title == 'time_to_repeat') {
          repeatSection = true;
          let already = false;

          section.data.forEach(list => {
            if(list.id === action.payload.list.id) {
              already = true;
            }
          })
          if(!already) {
            section.data.push(newList)
          }
        }
       
        return section.data.length > 0;
      });
      
      if(!repeatSection) {
        if(noNew && sections.length == 1) {
          sections.push({title: 'time_to_repeat', data: [newList]})
        } else if(sections.length == 2) {
          sections.splice(1, 0, {title: 'time_to_repeat', data: [newList]})
        } else if(newSection && !noNew && sections.length == 1) {
          sections.unshift({title: 'time_to_repeat', data: [newList]})
        } else {
          sections.push({title: 'time_to_repeat', data: [newList]})
        }
      }

      let relaxPlan = sections.length === 0;

      if (relaxPlan) {
        /*sections.push({
          title: 'relax', data: [
            {list_name: translate('look_in_glossaries'), segue: 'Glossaries'},
            {list_name: translate('try_word_maker'), segue: 'WordMakerWords'},
            {list_name: translate('try_test'), segue: 'WordTest'},
            {list_name: translate('try_extractor'), segue: 'WordExtractor'},
          ]
        });*/
      } else {
        sections = sections.filter(section => {
          return section.title != 'relax'
        })
      }
      
      return Object.assign({}, state, {
        sections,
        relaxPlan,
      });
    }

    case types.SET_ONLY_MISTAKES:
    {
      let sections = state.sections;

      let found = false;

      sections = sections.filter(section => {
        if(section.title == 'with_mistakes') {
          found = true;
          section.data = action.payload.words > 0 ? [action.payload] : []
        }

        return section.data.length != 0;
      });

      if(!found && action.payload.words > 0) {
        sections.unshift({
          title: 'with_mistakes',
          data: [action.payload]
        })
      }

      let relaxPlan = sections.length === 0;

      if (relaxPlan) {
        /*sections.push({
          title: 'relax', data: [
            {list_name: translate('look_in_glossaries'), segue: 'Glossaries'},
            {list_name: translate('try_word_maker'), segue: 'WordMakerWords'},
            {list_name: translate('try_test'), segue: 'WordTest'},
            {list_name: translate('try_extractor'), segue: 'WordExtractor'},
          ]
        });*/
      } else {
        sections = sections.filter(section => {
          return section.title != 'relax'
        })
      }

      // console.log("Mistakes sections", sections)

      return Object.assign({}, state, {
        sections,
        relaxPlan,
      });
    }
    
    case types.UPDATE_LIST_TRAININGS:
    {
      let updatedLists = state.lists.map(list => {
        if(list.id === action.payload.listId) {
          // console.log("UPDATE_LIST_TRAININGS 1", list.trainings)
          list.trainings = list.trainings + 1;
          // console.log("UPDATE_LIST_TRAININGS 2", list.trainings)

          list.intervals && list.intervals.map(day => {
            day.trainings++;
            return day;
          })
        }

        return list;
      })

      return Object.assign({}, state, {
        lists: updatedLists
      });
    }
    
    case types.UPDATE_TRAINING_LIST_REPEAT_SECTION:
    {

      let sections = state.sections;

      sections = sections.map(section => {
        if(section.title == 'time_to_repeat') {
          section.data.map(list => {
            if(list.id == action.payload.listId) {
              // console.log("UPDATE_REPEAT_SECTION 1", list.trainings)
              list.trainings = list.trainings + 1;

              list.intervals && list.intervals.map(day => {
                day.trainings++;
                return day;
              })
              // console.log("UPDATE__REPEAT_SECTION 2", list.trainings)
            }
          })
        }
       
        return section;
      });
      
      return Object.assign({}, state, {
        sections,
      });
    }

    case types.REMOVE_LIST_FROM_REPEAT_SECTION:
    {
      let sections = state.sections;

      // console.log("REMOVE_LIST_FROM_REPEAT_SECTION")
      sections = sections.filter(section => {
        if(section.title == 'time_to_repeat') {
          section.data = section.data.filter(list => {
            return action.payload.listId !== list.id;
          })
        }

        return section.data.length > 0;
      });

      let relaxPlan = sections.length === 0;

      if (relaxPlan) {
        /*sections.push({
          title: 'relax', data: [
            {list_name: translate('look_in_glossaries'), segue: 'Glossaries'},
            {list_name: translate('try_word_maker'), segue: 'WordMakerWords'},
            {list_name: translate('try_test'), segue: 'WordTest'},
            {list_name: translate('try_extractor'), segue: 'WordExtractor'},
          ]
        });*/
      } else {
        sections = sections.filter(section => {
          return section.title != 'relax'
        })
      }

      return Object.assign({}, state, {
        sections,
        relaxPlan,
      });
    }

    case types.UPDATE_HOME_LISTS_ARCHIVE:
    {
      let sections = state.sections;
      // let repeatSection = false;
      // let noNew = false;
      // let newSection = false;

      sections = sections.filter(section => {
        if(section.title == 'new_lists') {
          section.data = section.data.filter(list => {
            return action.payload !== list.id;
          })
        }

        if(section.title == 'time_to_repeat') {
          section.data = section.data.filter(list => {
            return action.payload !== list.id;
          })
        }

        return section.data.length > 0;
      });

      let relaxPlan = sections.length === 0;

      if (relaxPlan) {
        /*sections.push({
          title: 'relax', data: [
            {list_name: translate('look_in_glossaries'), segue: 'Glossaries'},
            {list_name: translate('try_word_maker'), segue: 'WordMakerWords'},
            {list_name: translate('try_test'), segue: 'WordTest'},
            {list_name: translate('try_extractor'), segue: 'WordExtractor'},
          ]
        });*/
      } else {
        sections = sections.filter(section => {
          return section.title != 'relax'
        })
      }

      return Object.assign({}, state, {
        sections,
        relaxPlan,
      });
    }

    case types.SET_LIST_WORDS:
      return Object.assign({}, state, {
        listWords: action.payload.data,
        fetchedListWords: true
      }); 
    
    case types.ADD_IMAGE_TO_LIST_WORDS:{
      let listWords = state.listWords;
      
      if(listWords && listWords.length > 0 && action.payload.word_id && action.payload.filename) {
        listWords = listWords.map(word => {
          if(word.word_id == action.payload.word_id) {
            word.image = action.payload;
          }

          return word;
        })
      }

      return Object.assign({}, state, {
        listWords,
      });
    }
    
    case types.REMOVE_USER_IMAGE_FROM_LIST_WORDS:{
      let listWords = state.listWords;

      if(listWords && listWords.length > 0 && action.payload) {
        listWords = listWords.map(word => {
          if(word.word_id == action.payload) {
            word.image = false
          }

          return word;
        })
      }

      return Object.assign({}, state, {
        listWords,
      });
    }
    
    case types.RESET_LISTS_TO_REPEAT:
      return Object.assign({}, state, {
        sections: [],
        relaxPlan: false
      });

    case types.RESET_LIST_WORDS:
    {
      return Object.assign({}, state, {
        listWords: [],
        fetchedListWords: false
      });
    }

    case types.SEARCH_USER_LISTS:
    {
      let _totalLists = 0;
      let _totalWords = 0;

      let newLists = state.lists.map((item, i) => {
        item.hide = item.list_name.toLowerCase().indexOf(action.payload) === -1;
        if (!item.hide) {
          _totalLists += 1;
          _totalWords += item.words;
        }
        return item;
      });

      return Object.assign({}, state, {
        lists: newLists,
        totalLists: _totalLists,
        totalWords: _totalWords,
      });
    }

    case types.DELETE_FROM_USER_LISTS:
    {
      let _totalWords = 0;

      let newLists = state.lists.filter((item, i) => {

        if (item.id === action.payload) {
          _totalWords += item.words;
        }

        return item.id !== action.payload;
      });

      return Object.assign({}, state, {
        lists: newLists,
        totalLists: newLists.length,
        totalWords: state.totalWords - _totalWords,
      });
    }
    
    case types.REMOVE_REPETITIONS_FROM_LIST:
    {
      let newLists = state.lists.map((item, i) => {
        
        if(item.id == action.payload) {
          item.intervals = []
        }
        
        return item;
      });

      return Object.assign({}, state, {
        lists: newLists,
      });
    }

    case types.UPDATE_LIST_INTERVALS:
    {
      let newLists = state.lists.map((item, i) => {

        if(item.id == action.payload.listId) {
          item.intervals = action.payload.intervals
        }

        return item;
      });

      return Object.assign({}, state, {
        lists: newLists,
      });
    }
      
    case types.DELETE_LISTS_FROM_USER_LISTS:
    {
      let _totalWords = 0;

      let newLists = state.lists.filter((item, i) => {
        let toDelete = false;

        action.payload.forEach(listId => {

          if (item.id === listId) {
            _totalWords += item.words;
            toDelete = true;
          }
        });

        return !toDelete;
      })

      return Object.assign({}, state, {
        lists: newLists,
        totalLists: newLists.length,
        totalWords: state.totalWords - _totalWords,
      });
    }

    case types.SET_CURRENT_LIST:
    {
      return Object.assign({}, state, {
        currentList: action.payload,
        currentListInLists: action.payload,
      });
    }

    case types.REMOVE_TAG_FROM_CURRENT_LIST:
    {
      let currentList = state.currentList;
      currentList.tag = false;

      let currentListInLists = state.currentListInLists;
      currentListInLists.tag = false;

      return Object.assign({}, state, {
        currentList,
        currentListInLists,
      });
    }

    case types.UPDATE_CURRENT_LIST_FAVORITE:
    {
      let currentList = state.currentList;
      currentList.favourite = currentList.favourite == 0 ? 1 : 0;
      
      let currentListInLists = state.currentListInLists;
      currentListInLists.favourite = currentList.favourite;

      return Object.assign({}, state, {
        currentList,
        currentListInLists,
      });
    }

    case types.UPDATE_LIST_TAG:
    {
      let currentList = state.currentList;
      currentList.tag = action.payload;

      let currentListInLists = state.currentListInLists;
      currentListInLists.tag = action.payload;;

      return Object.assign({}, state, {
        currentList,
        currentListInLists,
      });
    }

    case types.UPDATE_LIST_NAME:
    {
      let newLists = state.lists.map((item, i) => {
        if (item.id == action.payload.listId) {
          item.list_name = action.payload.listName
        }
        return item;
      });

      let newList = state.currentList;

      newList.list_name = action.payload.listName;

      return Object.assign({}, state, {
        lists: newLists,
        currentList: newList
      });
    }

    case types.UPDATE_CURRENT_LIST_WORDS:
    {
      let list = state.currentList;
      list.words = action.payload;
      
      return Object.assign({}, state, {
        currentList: list,
      });
    }



    case types.UPDATE_CURRENT_LIST_TRAININGS:
    {
      let list = state.currentList;
      list.trainings = list.trainings + 1;
      
      return Object.assign({}, state, {
        currentList: list,
      });
    }

    case types.UPDATE_CURRENT_LIST_ONE_WORDS:
    {
      let list = state.currentList;

      list.words = state.currentList.words + 1;

      return Object.assign({}, state, {
        currentList: list,
      });
    }

    case types.TOGGLED_WORD_IN_CURRENT_LIST:
    {
      let list = state.currentList;

      if(action.payload === true) {
        list.words--;
      } else {
        list.words++;
      }

      return Object.assign({}, state, {
        currentList: list,
      });
    }

    case types.LIST_TOGGLED_IN_FAVORITE:
    {
      let list = state.currentList;
      list.favourite = list.favourite === 1 ? 0 : 1;

      return Object.assign({}, state, {
        currentList: list,
      });
    }

    case types.LIST_WORD_DELETED:
    {
      let newLists = state.lists.map((item, i) => {
        if (item.id == action.payload.listId) {
          item.words--;
        }
        return item;
      });

      return Object.assign({}, state, {
        listWords: state.listWords.filter((item, i) => i !== action.payload.index),
        lists: newLists,
        totalWords: state.totalWords - 1
      });
    }

    case types.LIST_WORD_DELETED_BY_ID:
    {
      let newLists = state.lists.map((item, i) => {
        if (item.id == action.payload.listId) {
          item.words--;
        }
        return item;
      });

      // let currentList = state.currentList;
      // currentList.words = currentList.words - 1;

      // console.log("LIST_WORD_DELETED_BY_ID", state.currentList.words)

      return Object.assign({}, state, {
        listWords: state.listWords.filter((item, i) => item.word_id != action.payload.wordId),
        lists: newLists,
        totalWords: state.totalWords - 1,
        // currentList
      });
    }

    case types.UPDATE_CURRENT_LIST_WORDS_DATA:
    {
      let list = state.currentList;

      list.words = list.words - action.payload;

      return Object.assign({}, state, {
        currentList: list,
      });
    }

    case types.WORD_TOGGLED_IN_FAVORITE:
    {
      return Object.assign({}, state, {
        listWords: state.listWords.map((item, i) => {
          if (i === action.payload) {
            item.is_favourite = !item.is_favourite
          }

          return item;
        })
      });
    }

    case types.WORD_TOGGLED_IN_VOCABULARY:
    {
      return Object.assign({}, state, {
        listWords: state.listWords.map((item, i) => {
          if (i === action.payload) {
            item.in_vocabulary = !item.in_vocabulary
          }

          return item;
        })
      });
    }

    case types.INSERT_NEW_LIST:
    {
      let newLists = state.lists;
      newLists.unshift(action.payload)

      return Object.assign({}, state, {
        lists: newLists,
      });
    }

    case types.UPDATE_CURRENT_LIST_ARCHIVE_STATUS:
    {
      let list = state.currentList;
      list.archive = !list.archive;

      return Object.assign({}, state, {
        currentList: list,
      });
    }

    case types.REMOVE_ARCHIVED_LIST:
    {

      let newLists = state.lists.filter((item, i) => {
        return item.id != action.payload;
      });

      return Object.assign({}, state, {
        lists: newLists,
      });
    }

    default:
      return state;
  }
}