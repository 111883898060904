import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faCircle, faVolumeUp, faLightbulb,faStar, faList} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import * as helpers from '../../../lib/validators';
import colors from '../../../lib/colors';

import * as constants from '../../../lib/constants';

class AddWordModal extends Component {
  constructor(props) {
    super(props);

  }

  playSound = (e) => {
    e.preventDefault();

    const {foundWord} = this.props;
    const {userData} = this.props;
    let audioSource = constants.BACKEND_URL;

    if (foundWord && foundWord.audio) {

      let audio = foundWord.audio;

      if (userData && userData.audio_language && foundWord[userData.audio_language]) {
        audio = foundWord[userData.audio_language];
        audioSource = constants.S3;
      }

      if (this.track != null) {
        this.track.pause();
        this.track = null;
      }

        this.track = new Audio(`${audioSource}${audio}`);
        this.track.play();
    }
  };

  render() {
    const {isVisible, onClose, word, ratio, foundWord, fetchedWord, toggleListsToFill, translate} = this.props;
    const tablet = false;

    let wordI = word.replace('.', '');
    wordI = wordI.replace(',', '');
    wordI = wordI.replace('!', '');
    wordI = wordI.replace('?', '');
    wordI = wordI.replace(':', '');
    wordI = wordI.replace(';', '');
    wordI = wordI.replace('-', '');
    wordI = wordI.replace("\"", '');
    wordI = wordI.replace("'ll", '');
    wordI = wordI.replace("'s", '');
    wordI = wordI.replace("'d", '');
    wordI = wordI.replace("'ve", '');
    wordI = wordI.replace("’ll", '');
    wordI = wordI.replace("’s", '');
    wordI = wordI.replace("’d", '');
    wordI = wordI.replace("’ve", '');
    wordI = wordI.replace("’", '');
    wordI = wordI.replace("'", '');
    wordI = wordI.replace("`", '');
    wordI = wordI.replace("<", '');
    wordI = wordI.replace(">", '');
    wordI = wordI.replace("$", '');
    wordI = wordI.replace("(", '');
    wordI = wordI.replace(")", '');
    wordI = wordI.replace("[", '');
    wordI = wordI.replace("]", '');
    wordI = wordI.replace("{", '');
    wordI = wordI.replace("}", '');

    const translation = foundWord && foundWord.main_translation && foundWord.main_translation.translation ? foundWord.main_translation.translation : "";
    const otherTranslations = foundWord && foundWord.otherTranslationsString ? foundWord.otherTranslationsString : "";
    const transcription = foundWord && foundWord.transcription ? foundWord.transcription : "";

    return (
      <Modal
        className="addwordmodal"
        show={isVisible}
        onHide={()=>onClose(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h4" style={{color: colors.tealish}}>
            {!foundWord ? wordI : foundWord.word}
            {!foundWord && fetchedWord && (translate('word_not_found'))}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">
          <div style={{width: '100%', marginBottom: 20}}>
            <div className="flexV flexStart" style={{width: '100%',paddingHorizontal: 5}}>
              {
                foundWord && foundWord.audio && (
                  <a href="#"
                     style={{fontSize: 25}}
                     className="d-block flexCenter pt-2 pb-3"
                    onClick={this.playSound}>
                    <FontAwesomeIcon icon={faVolumeUp}/>
                  </a>
                )
              }

              <p className="transcription" style={{fontSize: ratio * (!tablet ? 14 : 16)}}>
                {transcription}
              </p>

              <p
                className="mainTranslation textCenter"
                style={{marginTop: !tablet ? 10 : 15, fontSize: ratio * (!tablet ? 14 : 18)}}>
                {translation}
              </p>

              <div className="ph2" style={{marginTop: !tablet? 5 : 10, marginBottom: !tablet ? 10 : 20}}>
                {
                  otherTranslations && (
                    <span className="textCenter" style={{fontSize: ratio * 14}}>
                      {otherTranslations}
                    </span>
                  ) || <span style={{width: 100}}> </span>
                }
              </div>

              {foundWord && foundWord.in_vocabulary && (
                <div
                  className="flexH"
                  style={{justifyContent: 'center',alignItems: 'center'}}
                >
                  <FontAwesomeIcon
                    style={{marginRight: 10,color: colors.tealish, fontSize: !tablet ? 20 : 30}}
                               icon={faLightbulb}/>
                  <span
                    className="inSection"
                    style={{fontSize: ratio * (!tablet ? 12 : 14)}}>
                    {translate('in_vocabulary')}
                  </span>
                </div>
              ) || null}
              {foundWord && foundWord.is_favourite && (
                <div
                  className="flexH"
                  style={{
                  marginBottom: 5,
                  justifyContent: 'center',
                  alignItems: 'center'}}
                >
                  <FontAwesomeIcon style={{marginRight: 10,color: colors.golden, fontSize: !tablet ? 20 : 30}}
                               icon={faStar}/>
                  <span
                    className="inSection"
                    style={{fontSize: ratio * (!tablet ? 12 : 14)}}>
                    {translate('is_favourite')}
                  </span>
                </div>) || null}
              {foundWord && foundWord.in_user_lists && foundWord.in_user_lists > 0 && (
                <div
                  className="flexH"
                  style={{
                  marginBottom: 5,
                  justifyContent: 'center',
                  alignItems: 'center'}}>
                  <FontAwesomeIcon style={{marginRight: 10,color: colors.greyishBrown, fontSize: !tablet ? 20 : 30}}
                               icon={faList}/>
                  <span className="inSection" style={{fontSize: ratio * (!tablet ? 12 : 14)}}>
                    {translate('in_user_lists')}
                  </span>
                </div>) || null}

              {
                foundWord && (
                  <Button
                    className="trainingButtonContainer"
                    variant="outline-success"
                    style={{marginTop: !tablet ? 15 : 25}}
                                    onClick={()=>{
                                    toggleListsToFill(true)
                                    }}>

                        {translate('add_words_to_list')}
                  </Button>
                ) || null
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(null, null)(AddWordModal);
