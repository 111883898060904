import * as types from './types';

export const getUserContacts = () => (dispatch) => {
    dispatch({ type: types.GET_USER_CONTACTS });
};

export const createUserContact = (name, email) => (dispatch) => {
    dispatch({ type: types.ADD_USER_CONTACT, payload: {name, email} });
};

export const deleteUserContact = (id) => (dispatch) => {
    dispatch({ type: types.DELETE_USER_CONTACT, payload: id });
};

export const updateUserContact = (id, username) => (dispatch) => {
    dispatch({ type: types.UPDATE_USER_CONTACT, payload: {id,username} });
};

