import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import { LiqPayPay, LiqPaySubscribe } from "react-liqpay";
import {Translate, getTranslate} from 'react-localize-redux';

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';

import {setProducts, setSubscriptions, checkForHappyDay} from '../../../actions/purchasesActions';


class Liqpay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tablet: false

    }
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {
  }
  
  componentWillUnmount() {
  }

  render() {
    const {fontSizeRation} = this.props.settings;
    const {tablet} = this.state;
    const {translate} = this.props;
    const {userId} = this.props.user;
    const {happyDay, happyEndDay, graceStatus, graceDaysLeft} = this.props.purchases;

    return (
      <div className="products">
        <Container>

          <LiqPaySubscribe
            publicKey={process.env.REACT_APP_PUBLIC_KEY}
            privateKey={process.env.REACT_APP_PRIVATE_KEY}
            amount="0.99"
            subscribePeriodicity="month"
            description="Payment for monthly subscription"
            currency="USD"
            orderId={Math.floor(1 + Math.random() * 900000000)}
            result_url="https://mnemonicwords.com/paymentresults"
            server_url="https://mnemonicwords.app/api/liqpay"
            product_description="Online courses"
            style={{ margin: "8px" }}
            disabled={false}
          />

          <LiqPaySubscribe
            publicKey={process.env.REACT_APP_PUBLIC_KEY}
            privateKey={process.env.REACT_APP_PRIVATE_KEY}
            amount="9.99"
            subscribePeriodicity="year"
            description="Payment for yearly subscription"
            currency="USD"
            orderId={Math.floor(1 + Math.random() * 900000000)}
            result_url="https://mnemonicwords.com/paymentresults"
            server_url="https://mnemonicwords.app/api/liqpay"
            product_description="Online courses"
            product_name="Name"
            product_url="https://web.mnemonicwords.app/api/liqpay"
            style={{ margin: "8px" }}
            disabled={false}
          />

          <LiqPayPay
            publicKey={process.env.REACT_APP_PUBLIC_KEY}
            privateKey={process.env.REACT_APP_PRIVATE_KEY}
            amount="0.99"
            description={translate('lifetime_access')}
            currency="USD"
            orderId={`${moment().unix()}-lifetime_access-${userId}`}
            result_url="https://mnemonicwords.com/paymentresults"
            server_url="https://mnemonicwords.app/api/liqpay"
            product_description="Online courses"
            product_name="Name"
            product_url="https://web.mnemonicwords.app/api/liqpay"
            style={{ margin: "8px" }}
          />
          
          <LiqPayPay
            publicKey={process.env.REACT_APP_PUBLIC_KEY}
            privateKey={process.env.REACT_APP_PRIVATE_KEY}
            description="Payment for product"
            orderId={Math.floor(1 + Math.random() * 900000000)}
            result_url="https://mnemonicwords.com/paymentresults"
            server_url="https://mnemonicwords.app/api/liqpay"
            product_description="Online courses"
            style={{ margin: "8px" }}
            extra={[<button style={{
              backgroundColor: '#337ab7',
              color: '#fff',
              borderColor: '#2e6da4',
              border: '1px solid transparent',
              borderRadius: '4px',
              padding: '6px 12px',
              cursor: 'pointer'
            }}>
      1000$
    </button>]}
          />
        </Container>

      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  purchases: state.purchases,
  settings: state.settings,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    setProducts: (data) => dispatch(setProducts(data)),
    setSubscriptions: (data) => dispatch(setSubscriptions(data)),
    checkForHappyDay: () => dispatch(checkForHappyDay()),
  };
}

export default connect(mapStateToProps, bindAction)(Liqpay);
