import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import Loader from "../../../components/Custom/Loader/index";

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import {change_password} from '../../../actions/userActions';

import colors from '../../../lib/colors';
import * as constants from '../../../lib/constants';

class ChangePassword extends Component {

  constructor(props) {
    super(props);

    this.curPass = React.createRef();
    this.pass = React.createRef();
    this.passConf = React.createRef();


    this.state = {
      currentPassword: "",
      password: "",
      confirmPassword: "",
      currentPasswordVisible: false,
      passwordVisible: false,
      confirmPasswordVisible: false,
      tablet: false

    }
  }

  componentDidMount() {
    
  }

  changePassword = () => {
    const {currentPassword, password, confirmPassword} = this.state;
    const {translate} = this.props;

    let error = "";

    if (currentPassword.length === 0) {
      error = 'empty_current_password';
    } else if (password.length == 0) {
      error = "empty_new_password";
    } else if (confirmPassword.length === 0) {
      error = "empty_confirm_password";
    } else if (password !== confirmPassword) {
      error = "passwords_dismatch";
    }
    
    if(currentPassword.length < 6 || password.length < 6 || confirmPassword.length < 6) {
      error = "short_password"
    }

    if(error) {
      toastr.error('', translate(error));
    } else {
      this.props.fetchingData(true);
      this.props.change_password(currentPassword, password);
      this.props.logActivity({activityId: 79, comment: password});
      this.props.onClose('changePasswordModalVisible',false);
    }
  };

  _togglePassword = (type) => {
    this.setState({
      [type]: !this.state[type]
    })
  };

  handleChange(field, value) {
    this.setState({[field]: value});
  }

  _handleKeyPress = (type, event) => {
    if (event.key === 'Enter' || event.key == 'Tab') {
      if (type == 'currentPassword') {
        this.pass.current.focus();
      } else if (type == 'password') {
        this.passConf.current.focus();
      } else if (type == 'confirmPassword') {
        this.changePassword();
      }
    }
  };
  
  render() {
    const {fetchingData} = this.props.activity;
    const {translate, onClose, isVisible, changeProfile} = this.props;
    const {userData} = this.props.user;
    const {fontSizeRation} = this.props.settings;
    const {currentPasswordVisible, passwordVisible, confirmPasswordVisible,
      password,
      currentPassword,
      confirmPassword,
    } = this.state;
    

    return (
      <Modal
        onShow={() => this.curPass.current.focus()}
        size="sm"
        className="changepassword"
        show={isVisible}
        onHide={()=>onClose('changePasswordModalVisible',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="change_password"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="p-2 d-flex flex-column justify-content-center">

            <Form.Label>{translate('current_password')}</Form.Label>

            <InputGroup className="mb-2">
              <Form.Control
                ref={this.curPass}
                tabIndex="1"
                onKeyPress={(e)=>this._handleKeyPress('currentPassword', e)}
                value={currentPassword}
                type={currentPasswordVisible ? 'text' : 'password'}
                placeholder={translate('current_password')}
                            onChange={(e)=>this.handleChange('currentPassword',e.target.value)}
              />
              <InputGroup.Append>
                <Button variant="outline-secondary"
                  onClick={()=> this._togglePassword('currentPasswordVisible')}
                >
                  <FontAwesomeIcon icon={currentPasswordVisible ? faEye : faEyeSlash} />
                </Button>
              </InputGroup.Append>
            </InputGroup>

            <Form.Label>{translate('new_password')}</Form.Label>

            <InputGroup className="mb-2">
              <Form.Control
                ref={this.pass}
                value={password}
                onKeyPress={(e)=>this._handleKeyPress('password', e)}
                tabIndex="2"

                type={passwordVisible ? 'text' : 'password'}
                placeholder={translate('new_password')}
                onChange={(e)=>this.handleChange('password',e.target.value)}
              />
              <InputGroup.Append>
                <Button variant="outline-secondary"
                        onClick={()=> this._togglePassword('passwordVisible')}
                >
                  <FontAwesomeIcon icon={passwordVisible ? faEye : faEyeSlash} />
                </Button>
              </InputGroup.Append>
            </InputGroup>

            <Form.Label>{translate('confirm_new_password')}</Form.Label>

            <InputGroup>
              <Form.Control
                ref={this.passConf}
                value={confirmPassword}
                onKeyPress={(e)=>this._handleKeyPress('confirmPassword', e)}
                tabIndex="3"

                type={confirmPasswordVisible ? 'text' : 'password'}
                placeholder={translate('confirm_new_password')}
                onChange={(e)=>this.handleChange('confirmPassword',e.target.value)}
              />
              <InputGroup.Append>
                <Button variant="outline-secondary"
                        onClick={()=> this._togglePassword('confirmPasswordVisible')}
                >
                  <FontAwesomeIcon icon={passwordVisible ? faEye : faEyeSlash} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
            
          </div>

          <Button
            disabled={password.length === 0 || confirmPassword.length === 0 || currentPassword.length === 0}
            className="mt-3"
            variant="outline-info"
            block
            onClick={this.changePassword}
          >
            <Translate id="change"/>
          </Button>
        </Modal.Body>

        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    change_password: (currentPassword, newPassword) => dispatch(change_password(currentPassword, newPassword)),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
  };
}

export default connect(mapStateToProps, bindAction)(ChangePassword);
