import React, {Component} from 'react';
import {connect} from "react-redux";

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import Modal from 'react-bootstrap/Modal';
import {logActivity} from '../../../actions/logActions';

class CourseLockedList extends Component {

  render() {
    const {translate, onClose, isVisible} = this.props;
    const {fontSizeRation} = this.props.settings;

    return (
      <Modal
        className="courselocked"
        show={isVisible}
        size="sm"
        onHide={()=>onClose('lockedCourseModal',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="course"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p style={{fontSize: fontSizeRation * 18, whiteSpace: "pre-wrap"}}>
            {translate('locked_description')}
          </p>

        </Modal.Body>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
  };
}

export default connect(mapStateToProps, bindAction)(CourseLockedList);
