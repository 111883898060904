import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import moment from 'moment';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';

import Loader from "../../../components/Custom/Loader/index";

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import {createUserContact} from '../../../actions/userContactsActions';

import {
  createChildAccount, closePasswordModal, checkPasswordToConnect,
  setAccountConnected
}
  from '../../../actions/accountsActions';
import * as helpers from '../../../lib/validators';

class AddUserContact extends Component {

  constructor(props) {
    super(props);

    this.fullName = React.createRef();
    this.email = React.createRef();

    this.state = {
      fullName: "",
      email: "",
      tablet: false

    }
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }


  handleChange(field, value) {
    this.setState({[field]: value});
  }

  _handleKeyPress = (type, event) => {
    if (event.key === 'Enter' || event.key == 'Tab') {
      if (type == 'fullName') {
        this.email.current.focus();
      } else if (type == 'email') {
        this._connectAccount();
      }
    }
  };

  _addContact = () => {
    const {fullName, email} = this.state;
    const {translate} = this.props;

    let error = "";

    if (fullName.length === 0) {
      error = 'empty_name';
    } else if (email.length == 0) {
      error = "empty_email";
    } else if (!helpers.checkEmail(email)) {
      error = 'valid_email';
    }

    if (error) {
      toastr('', translate(error));
    } else {
      this.props.logActivity({activityId: 212});
      this.props.fetchingData(true);
      this.props.createUserContact(fullName, email);
      this.props.onClose('addContactModalVisible', false);
    }
  };

  render() {
    const {fetchingData} = this.props.activity;
    const {translate, onClose, isVisible} = this.props;
    const {userData} = this.props.user;
    const {fontSizeRation} = this.props.settings;
    const {fullName, email, password} = this.state;

    return (
      <Modal
        onShow={() => this.fullName.current.focus()}
        className="addusercontact"
        show={isVisible}
        onHide={()=>onClose('addContactModalVisible', false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="add_contact"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <React.Fragment>
            <Alert variant="secondary">
                <span className="d-block font-weight-light font-italic text-center">
                  {translate('connect_contact_description')}
                </span>
            </Alert>

            <div className="d-flex flex-column justify-content-center">
              <Form.Group controlId="full_name">
                <Form.Label>{translate('full_name')}</Form.Label>

                <Form.Control
                  ref={this.fullName}
                  tabIndex="1"
                  onKeyPress={(e)=>this._handleKeyPress('fullName', e)}
                  value={fullName}
                  placeholder={translate('full_name')}
                  onChange={(e)=>this.handleChange('fullName',e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label>{translate('email')}</Form.Label>
                <Form.Control
                  ref={this.email}
                  tabIndex="1"
                  onKeyPress={(e)=>this._handleKeyPress('email', e)}
                  value={email}
                  type="email"
                  placeholder={translate('email')}
                  onChange={(e)=>this.handleChange('email',e.target.value)}
                />
              </Form.Group>
            </div>

            <Button
              disabled={fullName.length === 0 || email.length === 0}
              className="mt-3"
              variant="outline-success"
              block
              onClick={this._addContact}
            >
              <Translate id={"create"}/>
            </Button>
          </React.Fragment>


        </Modal.Body>

        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  purchases: state.purchases,
  translate: getTranslate(state.localize),
  accounts: state.accounts,
  userContacts: state.userContacts

});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    createUserContact: (name, email) => dispatch(createUserContact(name, email)),
  };
}

export default connect(mapStateToProps, bindAction)(AddUserContact);
