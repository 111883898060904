import * as types from '../actions/types';

const INITIAL_STATE = {
  irregularVerbs: [],
  result: false,
  extractorWords: [],
  extractorScores: 0,
  hangmanScores: 0,
  hangmanWord: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_IRREGULAR_VERBS:
      return Object.assign({}, state, {
        irregularVerbs: action.payload
      });

    case types.RESET_IRREGULAR_VERBS:
      return Object.assign({}, state, {
        irregularVerbs: []
      });

    default:
      return state;
  }
}