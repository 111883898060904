import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faCircle} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import * as helpers from '../../../lib/validators';

class CopyPasteWords extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      allWords: 0
    };

    this.inputRef = React.createRef();
  }

  setText = (text) => {
    this.setState({
      text: helpers.sanitizeString(text, 1000)
    })
  };

  changeValue = (e) => {
    e.preventDefault();
    this.setState({
      allWords: this.state.allWords == 0 ? 1 : 0
    })
  };

  render() {
    const {
      translate,
      tablet,
      ratio,
      isVisible,
      onClose,
      fetchingData,
      addUserWords
    } = this.props;
    const {text, allWords} = this.state;

    return (
      <Modal
        onShow={() => this.inputRef.current.focus()}
        className="copypaste"
        show={isVisible}
        onHide={()=>onClose(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="enter_paste_text"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">
          <div className="position-relative">
            <InputGroup className="ph1 mt-2">
              <FormControl
                as="textarea"
                rows="5"
                ref={this.inputRef}
                style={{fontSize: 12}}
                value={text}
                onChange={(e)=> this.setText(e.target.value)}
              />
              <InputGroup.Append>
                <Button
                  style={{fontSize: 12}}
                  disabled={text.length == 0}
                  variant="outline-secondary"
                  onClick={()=>this.setText("")}>
                  <FontAwesomeIcon icon={faTimes}/>
                </Button>
              </InputGroup.Append>
            </InputGroup>
            <p className="chars" style={{fontSize: ratio * 12}}>{text.length} / 1000</p>

            <a href="#"
               className="text-secondary d-block text-left"
               onClick={this.changeValue}>
              <FontAwesomeIcon
                className="selectedIcon text-muted"
                icon={allWords == 1? faCheckCircle : faCircle}
                style={{marginRight: !tablet ? 10 : 20, fontSize: !tablet ? 20 : 30}}/>
                    <span
                      className="selectAllWords pv4"
                      style={{marginRight: 10, fontSize: ratio * 16}}>
                      {translate('all_words')}
                    </span>
            </a>

            <Button
              className="mt-3"
              block
              disabled={text.length == 0}
              variant="outline-secondary"
              onClick={()=>addUserWords(text, allWords)}>
              {translate('add')}
            </Button>

          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(null, null)(CopyPasteWords);
