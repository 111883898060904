import * as types from '../actions/types';

const INITIAL_STATE = {
  wordMakerWords: [],
  wordMakerWordParts: [],
  currentWord: false,
  currentSplitWord: false,
  totalWords: 0,
  totalGuessed: 0,
  totalGuessedWords: 0,
  totalScores: 0,
  levelScore: 0,
  fetched: false,
  fetching: false,
  byLength: [],
  place: false,
  currentLevel: 1,
  openedLevels: [],
  freeHints: 0,
  totalPlayers: 0,
  wordMakerData:false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_FREE_HINTS:
      return Object.assign({}, state, {
        freeHints: action.payload,
      });

    case types.SET_WORD_MAKER_DATA:
      return Object.assign({}, state, {
        wordMakerData: action.payload,
      });

    case types.SUBTRACT_FROM_FREE_HINTS:
      return Object.assign({}, state, {
        freeHints: state.freeHints - 1,
      });
    
    case types.ADD_WORD_MAKER_HINTS:
      return Object.assign({}, state, {
        freeHints: state.freeHints + 10,
      });

    case types.SET_WORD_MAKER_WORDS:
      return Object.assign({}, state, {
        wordMakerWords: action.payload.data,
        totalScores: action.payload.total_scores,
        fetched: true,
        currentLevel: action.payload.current_level,
        fetching: false,
        place: action.payload.position,
        totalPlayers: action.payload.totalPlayers,
        openedLevels: action.payload.opened_levels,
        freeHints: action.payload.free_hints
      });
    
    case types.SET_WORD_MAKER_PARTS:

      let total = 0;
      let guessed = 0;
      
      action.payload.byLength.forEach((item, i) => {
        total += item.total;
        guessed += item.guessed;
      });
      
      return Object.assign({}, state, {
        wordMakerWordParts: action.payload.data,
        byLength: action.payload.byLength,
        fetched: true,
        levelScore: action.payload.level_scores,
        totalWords: total,
        totalGuessed: guessed,
        fetching: false,
      });

    case types.RESET_WORD_MAKER_PARTS:
      return Object.assign({}, state, {
        wordMakerWordParts: [],
        levelScore: 0,
        byLength: [],
        totalWords: 0,
        totalGuessed: 0,
        fetched: true,
        fetching: false,
      });
    
    case types.RESET_WORD_MAKER_WORDS:
      return Object.assign({}, state, {
        wordMakerWords: [],
        openedLevels: [],
        fetched: false,
        fetching: false,
      });

    case types.SET_FETCHING_WORD_MAKER_WORDS:
      return Object.assign({}, state, {
        fetching: true,
      });
    
    case types.SET_CURRENT_WORD_MAKER_WORD:
      return Object.assign({}, state, {
        currentWord: action.payload,
        currentSplitWord: action.payload.word.split(''),
      });

    case types.SET_WORD_MAKER_LEVEL_SCORE:
      return Object.assign({}, state, {
        levelScore: action.payload,
      });

    default:
      return state;
  }
}