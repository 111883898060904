const colors = {
  white: '#ffffff',
  black: '#000000',
  lightGrey: '#EFEFEF',
  lightGrey5: 'rgba(239,239,239,0.5)',
  lightGrey25: 'rgba(239,239,239,0.25)',
  darkGreyBlue: '#24535A',
  lightGreyTwo: '#E4E4E4',
  lightGreyThree: '#EBE9EB',
  greyishBrown: '#3E3D3D',
  greyishBrown63: 'rgba(62,61,61,0.63)',
  greyishBrown5: 'rgba(62,61,61,0.5)',
  greyishBrown35: 'rgba(239,239,239,0.35)',
  greyishBrown2: 'rgba(62,61,61,0.2)',
  tealish: '#26C1CD',
  tealishTwo: '#23B3CC',
  tealishThree: '#26C1CD',
  greenish: '#70A56E',
  dustyGreen: '#70A56E',
  greyishTeal: '#6EA5A5',
  pine: '#295A24',
  waterBlue: '#1A89C9',
  headerBlue: '#1e9bca',
  coral: '#F35757',
  darkCoral: '#D04B43',
  dustyOrange: '#F3724B',
  dustyOrangeTwo: '#F36A4E',
  melon: '#FF7B53',
  transparent: 'transparent',
  golden: '#f5dc34',
  silver: '#C0C0C0',
  bronze: '#a97142',

};

export default colors;