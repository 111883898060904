import React, {Component} from 'react';
import moment from 'moment';
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircle, faCogs, faCheckCircle,
  faTrashAlt,
  faCamera,
  faVolumeUp,
  faTimes, faSearch, faPlus
} from '@fortawesome/free-solid-svg-icons';

import {Translate, getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import TrainingSubHeader from '../../../components/Headers/TrainingSubHeader/index';
import Loader from "../../../components/Custom/Loader/index";

import {getHangmanScores, getHangmanWord, resetHangmanWord, logHangmanWord} from '../../../actions/testActions';
import {logGamesLimits} from '../../../actions/purchasesActions';
import {fetchingData} from '../../../actions/activity';
import {getUserScores} from '../../../actions/userActions';
import {logActivity} from '../../../actions/logActions';

import colors from '../../../lib/colors';

class Hangman extends Component {

  constructor(props) {
    super(props);

    this.state = {
      token: moment().format('YYYY-MM-DD HH:mm:ss'),
      trainingInfoVisible: false,
      attempts: 10,
      openedLetters: 0,
      hints: 2,
      win: false,
      level: 0,
      result: 0,
      hangmanScores: 0,
      wordScores: 0,
      charScore: 5,
      gameOver: false,
      pressedButtons: [],
      wordLetters: [],
      allLetters: {
        'en': ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P", "A", "S", "D", "F", "G", "H", "J", "K", "L", "Z", "X", "C", "V", "B", "N", "M"],
        'ru': ["Ц", "У", "К", "Е", "Н", "Г", "Ш", "Щ", "З", "Х", "Ф", "Ы", "В", "А", "П", "Р", "О", "Л", "Д", "Ж", "Э", "Я", "Ч", "С", "М", "И", "Т", "Ь", "Б", "Ю"],
        'de': ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P", "A", "S", "D", "F", "G", "H", "J", "K", "L", "Z", "X", "C", "V", "B", "N", "M"],
        'id': ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P", "A", "S", "D", "F", "G", "H", "J", "K", "L", "Z", "X", "C", "V", "B", "N", "M"]
      },
      letters: [],
      studyLanguage: 'en',
      tablet: false

    };

    this.timeOut = null;
  }

  componentDidMount() {
    this._setStudyLanguage();

    const {isConnected} = this.props.settings;

    this._getHangmanData();

    this.timeOut = setTimeout(()=> {
      this._prepareKeyboard()
    }, 250);

    if (isConnected) {
      this.props.logGamesLimits();
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._refresh();
    }
  }

  componentWillUnmount() {
    const {userId} = this.props.user;
    const {isConnected} = this.props.settings;

    clearTimeout(this.timeOut);
    
    if (isConnected) {
      this.props.getUserScores(userId);
    }
  }

  _getHangmanData = () => {
    const {isConnected} = this.props.settings;

    if (isConnected) {
      this.props.fetchingData(true);

      this.props.getHangmanScores();
      this.props.getHangmanWord();
    }
  };

  _setStudyLanguage = async() => {
    const {userId} = this.props.user;

    const studyLanguage = localStorage.getItem(`studyLanguage${userId}`);

    if (studyLanguage !== null && studyLanguage != undefined) {
      this.setState({
        studyLanguage
      });
    }
  };

  _prepareKeyboard = () => {
    const {allLetters, studyLanguage} = this.state;
    const keyboard = allLetters[studyLanguage];

    this.setState({
      letters: keyboard
    })
  };

  _toVideoInstructions = (url) => {
    this.toggleInfoModal(false);
    // const url = 'https://youtu.be/yqU8tXYrVUM';

    this.props.logActivity({activityId: 159, comment: `Hangman: ${url}`});
  };

  _resetWord = () => {
    this.setState((prevState) => ({
      gameOver: false,
      pressedButtons: [],
      wordLetters: []
    }));
  };

  _buttonPressed = (letter) => {
    const {wordLetters, pressedButtons, attempts, charScore, openedLetters} = this.state;
    const {word, split, word_id, level} = this.props.test.hangmanWord;
    const {isConnected} = this.props.settings;

    let leftAttempts = attempts;
    let guessedLetters = wordLetters;
    let opened = openedLetters;

    if (word.indexOf(letter.toLowerCase()) !== -1) {
      guessedLetters.push({letter: letter, guessed: true});

      let times = 0;

      split.map(item => {
        if (item == letter.toLowerCase()) {
          times++;
        }
      });
      opened += times;
    } else {
      leftAttempts -= 1;
    }

    let buttons = pressedButtons;

    if (buttons.indexOf(letter) === -1) {
      buttons.push(letter);
    }

    let over = leftAttempts === 0 || opened === word.length;
    let result = opened === word.length;

    this.setState({
      pressedButtons: buttons,
      wordLetters: guessedLetters,
      attempts: leftAttempts,
      gameOver: over,
      win: result,
      openedLetters: opened
    });

    if (leftAttempts === 0 && !result) {
      this._openAllLetters();
    }

    if (over && isConnected) {
      this.props.fetchingData(true);
      if (result) {
        this.props.logHangmanWord({word_id, result: 1, level, scores: word.length * charScore})
      } else {
        this.props.logHangmanWord({word_id, result: 0, level, scores: 0})
      }
    }
  };

  _onHint = () => {
    const {hints, attempts, wordLetters} = this.state;
    if (!this.props.test.hangmanWord) {
      return;
    }
    const {split} = this.props.test.hangmanWord;

    let guessedLetters = wordLetters;
    let done = false;

    if (hints > 0 && attempts > 0) {

      this.setState({
        hints: hints - 1,
        attempts: attempts - 1
      });

      if (guessedLetters.length === 0) {
        this._buttonPressed(split[0].toUpperCase())
      } else {


        split.map(char => {
          let foundLetter = false;

          guessedLetters.map((guessed) => {
            if (char === guessed.letter.toLowerCase()) {
              foundLetter = true;
            }
          });
          if (!foundLetter && !done) {
            done = true;
            this._buttonPressed(char.toUpperCase());
          }
        });
      }
    }
  };

  _surrender = () => {
    this._openAllLetters();

    this.setState({
      gameOver: true,
      attempts: 0,
      win: false,
    });
  };

  _openAllLetters = () => {
    if (!this.props.test.hangmanWord) {
      return;
    }
    const {split} = this.props.test.hangmanWord;

    let guessedLetters = [];

    split.map(char => {
      guessedLetters.push({letter: char, guessed: false})
    });

    this.setState({
      wordLetters: guessedLetters,
    });
  };

  _refresh = () => {
    this.props.resetHangmanWord();

    this.setState({
      pressedButtons: [],
      wordLetters: [],
      gameOver: false,
      win: false,
      attempts: 10,
      hints: 2,
      openedLetters: 0
    });

    this._getHangmanData();
  };

  _goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const {wordLetters, pressedButtons, gameOver, hints, attempts, win, letters, tablet} = this.state;
    const {hangmanWord, hangmanScores} = this.props.test;
    const {fetchingData} = this.props.activity;
    const {fontSizeRation, deviceWidth, deviceHeight} = this.props.settings;
    const {translate} = this.props;

    let overlayHeight = !tablet ? 150 : 250;

    let bottom = overlayHeight - (overlayHeight / 10 * attempts);
    let progress = attempts / 10;

    if (win) {
      bottom = overlayHeight
    }

    let wordButtons = hangmanWord.split && hangmanWord.split.length > 0 ? hangmanWord.split.map((char, i) => {
      let opened = false;
      let guessed = false;

      wordLetters.map((item, i) => {
        if (item.letter.toLowerCase() === char) {
          opened = true;
          guessed = item.guessed;
        }
      });
      return (
        <div
          className="wordLettersView"
          style={{width: !tablet ? 20 : 30,borderBottomColor: colors.greyishBrown}} key={i}
        >
          <span
            className="wordLetter"
            style={{fontSize: !tablet ? 18 : 28,color: !opened ? colors.white : guessed ? colors.pine : colors.coral}}
          >
            {char}
          </span>
        </div>
      )
    }) : <div />;


    let buttons = letters.map((item, i) => {
      let pressed = false;

      pressedButtons.map((typed) => {
        if (typed === item) {
          pressed = true;
        }
      });

      return (
        <a href="#"
           disabled={pressed}
           key={i} onClick={(e) => {

          e.preventDefault();
          if(!pressed) {
           this._buttonPressed(item)
          }
        }}>
          <div
            className="letterView"
            style={{
          width: (deviceWidth - 20) / 10, height: !tablet ? 40 : 60, borderRadius: 5, marginTop: !tablet ? 5 : 7.5,marginBottom: !tablet ? 5 : 7.5,
          borderColor: pressed ? colors.white : colors.lightGreyThree,
          }}>
            <span
              className="letter"
              style={{fontSize: !tablet ? 16 : 24,color: pressed ? colors.white : colors.greyishBrown}}>
              {item}
            </span>
          </div>
        </a>
      )
    });

    return (
      <div className="hangman">
        <Container className="pageWrapper">

          <TrainingSubHeader
            trainingId={-1}
            freeze={false}
            key={hints}
            ratio={fontSizeRation}
            scores={hangmanScores}
            progress={progress}
            changeWord={this.changeWord}

            deviceHeight={deviceHeight}
            deviceWidth={deviceWidth}
            translate={translate}
            tablet={tablet}
            exit={this._goBack}
            history={this.props.history}
            trainingName="hangman"
            subtitle=""
            hideSettings

            hangman

            title={'hangman'}
            text="hangman_tip"
            url="https://youtu.be/l10-2xPTujs"
            toggleSettings={this.toggleWordSettingsModal}

            onHint={this._onHint}
            hints={hints}
            surrender={this._surrender}
            refresh={this._refresh}
            attempts={attempts}
            gameOver={gameOver}
            win={win}
          />

          {
            hangmanWord && (
              <Card style={{minHeight: deviceHeight - 150}}>
                <div className="mummyWrapper">
                  <div className="hangmanImageView" style={{width: !tablet ? 150 : 250, height: !tablet ? 150 : 250}}>
                    <div className="mummyOverlay" style={{bottom: bottom}}/>
                    <Image
                      className="hangmanImage"
                      src={!gameOver ? require('../../../assets/hangman/mummy.png') : win ? require('../../../assets/images/winner.png') : require('../../../assets/hangman/mummy.png')}
                      style={{width: !tablet ? 150 : 250, height: !tablet ? 150 : 250}}
                    />
                  </div>
                </div>

                <div className="wordLettersViewWrapper"
                     style={{paddingHorizontal: !tablet ? 10 : 20,marginVertical: !tablet ? 15 : 25}}>
                  {wordButtons}
                </div>
                {!gameOver && (
                  <div className="lettersWrapper" style={{marginTop: !tablet ? 10 : 20}}>
                    {buttons}
                  </div>
                )}

                {
                  gameOver && hangmanWord.translation && (
                    <span className="font-weight-bold text-center mt-3"
                          style={{fontSize: fontSizeRation * (!tablet ? 16 : 24)}}>
                    {hangmanWord.translation}
                  </span>
                  ) || null
                }
              </Card>
            )
          }

        </Container>
        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  test: state.test,
  user: state.user,
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    getHangmanScores: () => dispatch(getHangmanScores()),
    getHangmanWord: () => dispatch(getHangmanWord()),
    resetHangmanWord: () => dispatch(resetHangmanWord()),
    logHangmanWord: (data) => dispatch(logHangmanWord(data)),
    logGamesLimits: () => dispatch(logGamesLimits()),
    fetchingData: (status) => dispatch(fetchingData(status)),
    getUserScores: (userId) => dispatch(getUserScores(userId)),
    logActivity: (data) => dispatch(logActivity(data)),

  };
}

export default connect(mapStateToProps, bindAction)(Hangman);
