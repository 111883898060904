import React, {Component} from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import {Translate} from 'react-localize-redux';

class FinishTrainingButtons extends Component {

  render() {
    const {
      isVisible,
      anotherTraining,
      glossaryId,
      continueTraining,
      ratio,
      onClose,
      toResults,
      withMistakes,
      returnBack,
      from,
      translate,
      tablet
    } = this.props;

    return (
      <Modal
        className="trainingButtonsModal"
        show={isVisible}
        onHide={()=>onClose(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="introText">
            {translate('whatNext')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="trainingButtons">

          {
            from && from == 'listWords' && (
              <Button
                block
                variant="outline-info"
                onClick={anotherTraining}
              >
                <Translate className="buttonText" id="another_training"/>
              </Button>
            )
          }

          <Button
            block
            variant="outline-info"
            onClick={continueTraining}
          >
            <Translate className="buttonText" id="continue_this"/>
          </Button>


          <Button
            block
            variant="outline-info"
            onClick={returnBack}
          >
            <Translate className="buttonText" id="return_back"/>
          </Button>


          {
            glossaryId && withMistakes.length > 0 && (
              <Button
                block
                variant="outline-info"
                onClick={toResults}
              >
                <Translate className="buttonText" id="with_mistakes"/>
              </Button>
            )
          }
        </Modal.Body>
      </Modal>
    );
  }
}

export default FinishTrainingButtons;