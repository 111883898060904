import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withLocalize} from 'react-localize-redux';
import {Helmet} from "react-helmet";
import {Translate, getTranslate} from 'react-localize-redux';

// import setInterceptors from 'common/utils/interceptorUtils';
import {renderToStaticMarkup} from 'react-dom/server';
import en from '../translations/locales/en.json';
import ru from '../translations/locales/ru.json';
import uk from '../translations/locales/uk.json';

import AlertNews from '../components/Modals/AlertNews/index'

import {
  setUserId,
  setOriginalUserId,
  getUserScores,
  toggleReviewModal,
  getUserData,
  navigateToCourse,
  updateUserToken,
  getAssetsSource
} from '../actions/userActions';
import {
  getListsToRepeat,
  postponeInCalendar,
  setCurrentList,
  getListsByLang,
  setShowArchive,
  toggleListTrainingButtonsModal,
  cleanListCache,
  removeFromCalendar,
  deleteListFromList
} from '../actions/lists';

import {logActivity, logApiError} from '../actions/logActions';
import {
  getEverydayPhrase,
  getEverydayWord,
  setGettingEverydayPhrase,
  setGettingEverydayWord,
  addEverydayToList
} from '../actions/everydayActions';
import {setHideKnown, setHideKnownCourse} from '../actions/trainingActions';
import {
  getRegistrationDate, getUserLimits, setProducts, setSubscriptions, setListCardsPurchaseData,
  setSubscriptionData, setCardsPurchaseData, checkWebSubscriptionStatus,
  checkForHappyDay
} from '../actions/purchasesActions';
import {fetchingData} from '../actions/activity';
import {setFontSize, setDeviceVersion, setDimensions, setLocale} from '../actions/settingsActions';
import {checkForCourseDebts, getCoursesMistakesTestsRepeats, setCourseStatus} from '../actions/courseActions';
import {getUnreadNews, getUserNews, getAlertNews} from '../actions/newsActions';
import LogoBoy from "../assets/images/logo-boy-prepared.png";
import {getLocale} from "../lib/constants"

class Main extends Component {
  constructor(props) {
    super(props);
    const locale = getLocale();

    // console.log("locale", locale, process.env.REACT_APP_LANGUAGE)

    this.props.setLocale(locale)

    this.props.initialize({
      languages: [
        {name: 'Russian', code: 'ru'},
        {name: 'Ukrainian', code: 'uk'},
        {name: 'English', code: 'en'},
      ],
      options: {
        renderToStaticMarkup,
        defaultLanguage: locale,
      }
    });

    this.state = {
      visibleAlertNews: false,
      locale
    };

    this.props.addTranslationForLanguage(en, "en");
    this.props.addTranslationForLanguage(ru, "ru");
    this.props.addTranslationForLanguage(uk, "uk");
    // setInterceptors();

    this._uniqueToken();
    this._checkUser();
    this.timeOut = null;
    this.timeOut1 = null;
    this.timeOut2 = null;
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));

    this._getData();
  }

  componentDidUpdate(prevProps, prevState) {
    const {fetchedAlertNews, alertNews} = this.props.news;

    if (fetchedAlertNews != prevProps.news.fetchedAlertNews && fetchedAlertNews === true && alertNews.length > 0) {
      this.setState({
        visibleAlertNews: true
      })
    }
  }

  _uniqueToken = () => {
    const uniqueToken = localStorage.getItem('uniqueToken');

    if (uniqueToken === null || uniqueToken == undefined) {
      const token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      localStorage.setItem("uniqueToken", `${token}`);
    }
  };


  _checkUser = () => {
    const {locale} = this.props.settings;
    const userId = localStorage.getItem('userId');
    const tour = localStorage.getItem('tour');

    const path = window.location.pathname;

    const guestRoutes = [
      '/login',
      '/register',
      '/forgot',
      '/link',
      '/links',
      '/',
      '/password/reset/',
      '/glossaries',

      '/glossary/1498',
      '/glossary/1499',
      '/glossary/1500',
      '/glossary/1501',
      '/glossary/1502',
      '/glossary/1503',
      '/glossary/1505',
      '/glossary/1506',
      '/glossary/1507',
      '/glossary/1509',
      '/glossary/1510',
      '/glossary/1511',
      '/glossary/1512',
      '/glossary/1629',
      '/glossary/1630',
      '/glossary/1646',
      '/glossary/1647',
      '/glossary/1648',
      '/glossary/1649',


      '/lists',
      '/list/293886',
      '/list/293887',
      '/list/293888',
      '/list/293889',
      '/list/293890',
      '/list/293891'
      ,
      '/courses',
      '/course/1000',
      '/course/2000',
      '/courselist/150570',
      '/courselist/150571',
      '/courselist/368180',
      '/dictionary',
      '/products',
      '/install'
    ];

    if (userId !== null && userId != undefined) {
      this.props.setUserId(userId);
    }

    if (!userId && !tour) {
      if (guestRoutes.indexOf(path) === -1 && path.indexOf('/password/reset/') === -1) {
        window.location.replace(`/${locale}/login`);
      }
    }
  };

  _getData = () => {
    const path = window.location.pathname;
    const guestRoutes = ['/login', '/register', '/forgot'];

    const tour = localStorage.getItem('tour');

    let userId = localStorage.getItem('userId');
    const originalUserId = localStorage.getItem('originalUserId');

    const studyLanguage = localStorage.getItem(`studyLanguage${userId}`);
    const fontSize = localStorage.getItem(`fontSize${userId}`);
    const courseStatus = localStorage.getItem(`courseStatus${userId}`);

    const token = localStorage.getItem(`token`);

    if (tour && !userId) {
      this.getLimitedNecessaryData(1);
    }

    if (userId !== null && userId != undefined) {
      this.props.setUserId(userId);

      if (studyLanguage != null && studyLanguage != undefined) {
        this.setState({
          studyLanguage
        })
      }
      const showArchive = localStorage.getItem(`showArchive${userId}`);

      if (fontSize != null && fontSize != undefined) {
        this.props.setFontSize(fontSize);
      }

      if (showArchive != null && showArchive != undefined) {
        this.props.setShowArchive(showArchive);
      } else {
        this.props.setShowArchive("0");
        localStorage.setItem(`showArchive${userId}`, "0")
      }

      this.getNecessaryData(userId);

      // this.props.checkForHappyDay();
    }

    if (originalUserId !== null && originalUserId != undefined) {
      this.props.setOriginalUserId(originalUserId);
    } else {
      if (userId !== null && userId != undefined) {
        this.props.setOriginalUserId(userId);
      } else {

      }
    }
  };

  getNecessaryData = (userId) => {
    this.props.fetchingData(true);

    this.props.getUserData(userId);
    this.props.getUserLimits();
    this.props.getListsToRepeat(5, userId);
    this.props.getCoursesMistakesTestsRepeats();
    this.props.checkForCourseDebts(userId);
    this.props.checkForHappyDay();
    this.props.getEverydayPhrase();
    this.props.getUserNews();
    this.props.getUnreadNews();
    this.props.getAlertNews();
    this.props.checkWebSubscriptionStatus(userId);
    this.props.getEverydayWord();

    this.timeOut = setTimeout(() => {
      this.props.getListsByLang("by_list_name", "asc");
    }, 1000);

    const hidePromo = localStorage.getItem(`hidePromo${userId}`);
  };

  _checkCourse = (slug) => {
    const {cachedCourseData} = this.props.cache;

    let found = false;

    cachedCourseData.forEach((course, index) => {
      if (course.slug == slug) {
        if (course.lists && course.lists.length !== 0) {
          found = course;
        }
      }
    });

    return found;
  };

  getLimitedNecessaryData = (userId) => {
    this.props.getUserData(userId);
    this.props.getListsByLang("by_list_name", "asc");
  };

  updateWindowDimensions() {
    this.props.setDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut1);
    clearTimeout(this.timeOut2);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  _toggleAlertNews = (status) => {
    this.setState({
      visibleAlertNews: status
    })
  };

  render() {
    const {children, translate} = this.props;
    const {alertNews} = this.props.news;
    const {locale} = this.props.settings;

    const {visibleAlertNews} = this.state;

    return (
      <Fragment>
        <Helmet htmlAttributes={{ lang: locale }}>
          <meta charSet="utf-8"/>
          <title>{translate('app_title_unlocalized')}</title>
          <link rel="canonical" href={window.location.href}/>
          <meta name="description" content={translate('app_description_unlocalized')}/>
          <meta property="og:url" content={window.location.href}/>
          <meta property="og:type" content="website"/>
          <meta property="fb:app_id" content="348052396059271"/>
          <meta
            property="og:image"
            content={`${window.location.origin}${LogoBoy}`}
          />
          <meta name="og:description" content={translate('app_description_unlocalized')}/>
          <meta property="og:title" content={translate('app_title_unlocalized')}/>
        </Helmet>
        {children}

        {
          visibleAlertNews && (
            <AlertNews
              isVisible={visibleAlertNews}
              onClose={this._toggleAlertNews}
              translate={translate}
              news={alertNews}
            />
          )
        }
      </Fragment>
    );
  }
}

function bindAction(dispatch) {
  return {
    setUserId: (id) => dispatch(setUserId(id)),
    setOriginalUserId: (id) => dispatch(setOriginalUserId(id)),
    getListsToRepeat: (limit, userId) => dispatch(getListsToRepeat(limit, userId)),
    setCurrentList: (list) => dispatch(setCurrentList(list)),
    postponeInCalendar: (listId) => dispatch(postponeInCalendar(listId)),
    getUserScores: (userId) => dispatch(getUserScores(userId)),
    logActivity: (data) => dispatch(logActivity(data)),
    getRegistrationDate: () => dispatch(getRegistrationDate()),
    getAssetsSource: () => dispatch(getAssetsSource()),
    getUserLimits: () => dispatch(getUserLimits()),
    setProducts: (data) => dispatch(setProducts(data)),
    logApiError: (data) => dispatch(logApiError(data)),
    toggleReviewModal: (status) => dispatch(toggleReviewModal(status)),
    setSubscriptions: (data) => dispatch(setSubscriptions(data)),
    checkWebSubscriptionStatus: (userId) => dispatch(checkWebSubscriptionStatus(userId)),
    setCardsPurchaseData: (status, date) => dispatch(setCardsPurchaseData(status, date)),
    setListCardsPurchaseData: (status, date) => dispatch(setListCardsPurchaseData(status, date)),
    setSubscriptionData: (data) => dispatch(setSubscriptionData(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    setFontSize: (size) => dispatch(setFontSize(size)),
    setDeviceVersion: (version) => dispatch(setDeviceVersion(version)),
    getUserData: (userId) => dispatch(getUserData(userId)),
    checkForCourseDebts: (userId) => dispatch(checkForCourseDebts(userId)),
    navigateToCourse: (status) => dispatch(navigateToCourse(status)),
    setCourseStatus: (status) => dispatch(setCourseStatus(status)),
    setHideKnown: (status) => dispatch(setHideKnown(status)),
    setShowArchive: (status) => dispatch(setShowArchive(status)),
    setHideKnownCourse: (status) => dispatch(setHideKnownCourse(status)),
    getListsByLang: (filter, order) => dispatch(getListsByLang(filter, order)),
    checkForHappyDay: () => dispatch(checkForHappyDay()),
    updateUserToken: (token, userId) => dispatch(updateUserToken(token, userId)),
    toggleListTrainingButtonsModal: (status) => dispatch(toggleListTrainingButtonsModal(status)),
    cleanListCache: (id) => dispatch(cleanListCache(id)),
    removeFromCalendar: (listId, filter, order) => dispatch(removeFromCalendar(listId, filter, order)),
    deleteListFromList: (listId, back) => dispatch(deleteListFromList(listId, back)),
    getEverydayPhrase: () => dispatch(getEverydayPhrase()),
    getEverydayWord: () => dispatch(getEverydayWord()),
    setGettingEverydayWord: (status) => dispatch(setGettingEverydayWord(status)),
    setGettingEverydayPhrase: (status) => dispatch(setGettingEverydayPhrase(status)),
    addEverydayToList: (listId, wordId, type) => dispatch(addEverydayToList(listId, wordId, type)),
    getUnreadNews: () => dispatch(getUnreadNews()),
    getUserNews: () => dispatch(getUserNews()),
    getAlertNews: () => dispatch(getAlertNews()),
    setLocale: (locale) => dispatch(setLocale(locale)),
    setDimensions: (dimensions) => dispatch(setDimensions(dimensions)),
    getCoursesMistakesTestsRepeats: () => dispatch(getCoursesMistakesTestsRepeats()),

  };
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  news: state.news,
  course: state.course,
  cache: state.courseCache,
  settings: state.settings,
});


export default connect(
  mapStateToProps,
  bindAction,
)(withLocalize(Main));
