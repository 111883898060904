import React, {Component} from 'react';
import {connect} from "react-redux";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRedo,faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {toastr} from 'react-redux-toastr';
import moment from 'moment';

import colors from '../../lib/colors';
import Loader from "../../components/Custom/Loader/index";

class Everyday extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tablet: false
    };
  }

  render() {
    const {fontSizeRation, isConnected, reconnect} = this.props.settings;
    const {tablet} = this.state;
    const {everyday, getNew, type, addToList, fetchingData, deviceHeight, deviceWidth, translate} = this.props;

    return (
      <div 
        className="everyday flexH flexCenter mb2"
        style={{position:'relative', paddingHorizontal: !tablet ? 10 : 15}}>
        <a href="#"
          className="flexH flexCenter p2"
          onClick={(e)=> {
            e.preventDefault();
            getNew(type);
          }}
        >
          <FontAwesomeIcon
            className="addEveryday"
            icon={faRedo} style={{fontSize: 20}}
          />
        </a>

        <div className="flexV flexCenter" style={{width: deviceWidth - 120}}>
          <span
            className="everydayheader"
            style={{marginBottom: !tablet ? 5 : 10, fontSize: fontSizeRation * (!tablet ? 14 : 18)}}>
            {translate(`everyday_${type}`)}
          </span>
          <span
            className="everydayword"
            style={{fontSize: fontSizeRation * (!tablet ? 14 : 16)}}
          >
            {everyday.word}
          </span>
          <span className="everydaytranslation" style={{fontSize: fontSizeRation * (!tablet ? 14 : 16)}}>
            {everyday.translation}
          </span>
        </div>

        <a
          href="#"
          className="flexH flexCenter p2"
          onClick={(e)=> {
            e.preventDefault();
            e.stopPropagation();

            addToList(everyday, type)
          }}>
          <FontAwesomeIcon
            icon={faPlusCircle}
            className="addEveryday"
            style={{fontSize: 20}}/>
        </a>

        <Loader fetchingData={fetchingData}/>
      </div>
    )
  }
}

const mapStateToProps = (state, dispatch) => ({
  settings: state.settings,
  everydays: state.everydays
});

export default connect(mapStateToProps, null)(Everyday);
