import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faPlusCircle, faCheckCircle, faCircle} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';

class WordInLists extends Component {

  render() {
    const {
      isVisible,
      lists,
      onClose,
    } = this.props;

    return (
      <Modal
        className="wordinlists"
        show={isVisible}
        onHide={()=>onClose(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="word_in_lists"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{padding: '5px 0'}} className="text-center">
          <ListGroup variant="flush" style={{borderBottom: '1px solid rgba(0, 0, 0, 0.125)'}}>
            {
              lists.map((list, i) => {
                  return (
                    <ListGroup.Item
                      key={i}
                      className="text-center"
                    >
                      {list.list_name}
                    </ListGroup.Item>
                  )
              })
            }
          </ListGroup>
        </Modal.Body>

      </Modal>
    );
  }
}

export default connect(null, null)(WordInLists);
