import React, {Component} from 'react';
import {connect} from "react-redux";

import moment from 'moment';
import {LiqPayPay, LiqPaySubscribe} from "react-liqpay";
import {Translate, getTranslate} from 'react-localize-redux';
import {withRouter, Link} from 'react-router-dom';
import {toastr} from 'react-redux-toastr';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faCheckCircle, faCircle, faTimes, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {PayPalButton} from "react-paypal-button-v2";

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import classNames from 'classnames';

import {
  setProducts,
  setSubscriptions,
  checkForHappyDay,
  checkDiscountCode,
  getCurrencyExchangeRate,
  setDiscountCode
} from '../../../actions/purchasesActions';
import {getWordMakerData} from '../../../actions/wordMakerActions';
import GenerateListCards from '../../../components/Modals/GenerateListCards/index';
import RegisterAlert from '../../../components/Modals/RegisterAlert/index';

import * as helpers from '../../../lib/validators';
import {fetchingData} from '../../../actions/activity';
import {logActivity} from '../../../actions/logActions';

import Loader from "../../../components/Custom/Loader/index";

import colors from '../../../lib/colors';

import {
  faInstagram,
  faFacebook,
  faGooglePlay,
  faAppStore,
  faAndroid,
  faApple,
  faYoutubeSquare
} from '@fortawesome/free-brands-svg-icons'


class AllProducts extends Component {
  constructor(props) {
    super(props);
    const userId = localStorage.getItem('userId');
    this.props.getCurrencyExchangeRate();
    if (userId) {
      this.props.getWordMakerData();
    }

    this.state = {
      tablet: false,
      generateListCardsModal: false,
      selectedProduct: false,
      back: false,
      tour: localStorage.getItem('tour'),
      registerModalVisible: false,
      code: '',
      currentCurrency: 'UAH',
      currentCurrencySign: '$',
      exchangeRate: 28,
      products: [
        {
          id: 'monthly_subscription',
          orderId: `${moment().unix()}-monthly_subscription-${userId}`,
          price: '1.19',
          discountPrice: '0.89',
          currency: 'USD',
          name: 'monthly_subscription_title',
          description: 'subscription_description_short',
          buttonText: 'buy_for_price',
          buttonText2: 'monthly_subscription_no_price',
          info: 'subscription_description',
          type: 'subscribe',
          periodicity: "month"
        },

        {
          id: 'yearly_subscription',
          orderId: `${moment().unix()}-yearly_subscription-${userId}`,
          price: '11.69',
          discountPrice: '8.99',
          currency: 'USD',
          name: 'yearly_subscription_title',
          description: 'subscription_description_short',
          buttonText: 'buy_for_price',
          buttonText2: 'yearly_subscription_no_price',
          info: 'subscription_description',
          type: 'subscribe',
          periodicity: "year",
        },

        {
          id: 'lifetime_access',
          orderId: `${moment().unix()}-lifetime_access-${userId}`,
          price: '35.99',
          discountPrice: '32.39',
          currency: 'USD',
          name: 'lifetime_access',
          description: 'lifetime_access_description',
          buttonText: 'buy_for_price',
          type: 'buy',
          info: 'lifetime_subscription_terms'
        },
        {
          id: 'glossary_cards',
          orderId: `${moment().unix()}-glossary_cards-${userId}`,
          price: '4.49',
          discountPrice: '3.99',
          currency: 'USD',
          name: 'glossary_flash_cards',
          description: 'glossary_flash_cards_description',
          buttonText: 'buy_for_price',
          type: 'buy',
          info: 'glossary_cards_generation_description'

        },
        {
          id: 'list_cards',
          orderId: `${moment().unix()}-list_cards-${userId}`,
          price: '2.69',
          discountPrice: '2.39',
          currency: 'USD',
          name: 'list_flash_cards',
          description: 'list_flash_cards_description',
          buttonText: 'buy_for_price',
          type: 'buy',
          info: 'list_cards_generation_description'

        },

        {
          id: 'word_maker',
          orderId: `${moment().unix()}-word_maker-${userId}`,
          price: '0.30',
          discountPrice: '0.25',
          currency: 'USD',
          name: 'word_maker_hints',
          description: 'word_maker_hints_description',
          buttonText: 'buy_for_price',
          type: 'buy'
        },
      ]
    };

    this.code = React.createRef();
  }

  componentDidMount() {
    if (this.props.history.location && this.props.history.location.state) {
      this.setState({
        back: this.props.history.location.state.back,
        selectedProduct: this.props.history.location.state.product
      })
    }

    this._getData();

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.purchases.currencyRates !== prevProps.purchases.currencyRates && this.props.purchases.currencyRates.length > 0) {
      this._changeCurrency('UAH');
    }
  }

  componentWillUnmount() {

  }

  _toggleModal = (type, status) => {
    this.setState({
      [type]: status
    })
  };

  _getData = () => {
    const {isConnected} = this.props.settings;
    const {products, subscriptions} = this.props.purchases;

    if (isConnected) {
      this.props.checkForHappyDay();

      if (products.length == 0 || subscriptions.length == 0) {
      }
    }
  };

  submitLiqpay = (orderId, e) => {
    localStorage.setItem("orderId", orderId);
    this.props.logActivity({activityId: 359, comment: orderId});
    // this.props.setDiscountCode();
    this.setState({
      code: ''
    })
  };

  tryCards = (e) => {
    e.preventDefault();
    this._toggleModal('generateListCardsModal', true);
  };

  _toggleRegisterModal = (status) => {
    this.setState({
      registerModalVisible: status
    })
  };

  _toRegister = () => {
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/register`)
  };

  _checkCode = () => {
    const {code} = this.state;
    const {translate} = this.props;

    this.props.logActivity({activityId: 361, comment: code});

    if (!helpers.checkDiscountCodeValidity(code)) {
      toastr.error('', translate('wrong_discount_format'));
    } else {
      this.props.fetchingData(true);
      this.props.checkDiscountCode(code)
    }
  };

  handleChange(field, value) {
    this.setState({[field]: value});
  }

  _changeCurrency = (currency) => {
    const {currencyRates} = this.props.purchases;
    let sign = this.state.currentCurrencySign;

    // this.props.logActivity({activityId: 360, comment: currency});

    let exchangeRate = 1;

    if (currency == 'USD') {
      sign = '$';
      exchangeRate = 1;
    } else if (currency == 'EUR') {
      sign = '$';
      let USD = 1;
      let EUR = 1.13;
      currencyRates.forEach(rate => {
        if (rate.ccy == 'USD') {
          USD = rate.sale
        } else if (rate.ccy == 'EUR') {
          EUR = rate.sale
        }
      });
      exchangeRate = USD / EUR;
    } else if (currency == 'UAH') {
      sign = '$';
      let USD = 1;
      let UAH = 1.13;
      currencyRates.forEach(rate => {
        if (rate.ccy == 'USD') {
          UAH = rate.sale
        }
      });
      exchangeRate = UAH;
    } else if (currency == 'RUR') {
      sign = '$';
      let USD = 1;
      let RUR = 1.13;
      currencyRates.forEach(rate => {
        if (rate.ccy == 'USD') {
          USD = rate.sale
        } else if (rate.ccy == 'RUR') {
          RUR = rate.sale
        }
      });
      exchangeRate = USD / RUR;
    }

    currencyRates.forEach(rate => {
      if (rate.ccy == currency) {
        // console.log(rate)
      }
    });

    this.setState({
      currentCurrency: currency,
      currentCurrencySign: sign,
      exchangeRate
    });
  };

  render() {
    const {userData} = this.props.user;
    const {
      happyDay,
      happyEndDay,
      graceStatus,
      graceDaysLeft,
      webSubscriptionStatus,
      discountCode,
      currencyRates
    } = this.props.purchases;
    const {fetchingData} = this.props.activity;
    const {wordMakerData} = this.props.wordMaker;

    const {translate} = this.props;
    const {locale} = this.props.settings;
    const {
      generateListCardsModal,
      back,
      registerModalVisible,
      tour,
      code,
      currentCurrency
    } = this.state;

    let promo = false;
    let promoDays = 0;
    let daysName = 'days';

    if (happyDay || graceStatus || tour) {
      promo = true;

      promoDays = graceDaysLeft > happyEndDay ? graceDaysLeft : happyEndDay;

      if (promoDays == 1) {
        daysName = 'days1'
      } else if (promoDays >= 2 && promoDays <= 4) {
        daysName = 'days2'
      }
    }

    return (
      <div className="allproducts">
        <Breadcrumb style={{marginTop: 50}}>
          {!back ? (
            <React.Fragment>

              <li className="breadcrumb-item">
                <Link to={`/${locale}/home`}>
                  {translate('home')}
                </Link>
              </li>
              <Breadcrumb.Item active>
                {translate('all_products')}
              </Breadcrumb.Item>
            </React.Fragment>
          ) : (
            <li className="breadcrumb-item">
              <a href="#" onClick={(e)=>{
                  e.preventDefault();
                  this.props.history.goBack()
                }}>
                {translate('cancel_back')}
              </a>
            </li>
          )}
        </Breadcrumb>

        <Container style={{marginTop: 20, maxWidth: 840}} className="pageWrapper">

          <h4 style={{marginTop: 30, textAlign: 'center'}}>
            {translate('payment_via_app')}
          </h4>

          <div className="d-flex justify-content-center align-items-center mt-5">
            <a style={{marginRight: 50}} href="http://play.google.com/store/apps/details?id=com.mnemonicrn"
               target="_blank">
              <FontAwesomeIcon className="text-secondary" style={{fontSize: 50}} icon={faGooglePlay}/>
            </a>

            <a href="https://itunes.apple.com/app/id1438980777" target="_blank">
              <FontAwesomeIcon className="text-secondary" style={{fontSize: 50}} icon={faAppStore}/>
            </a>
          </div>

          <div>
            {/*<PayPalButton
             amount="0.01"
             shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
             onSuccess={(details, data) => {
             console.log(details,data)

             }}
             options={{
             clientId: "ASsyUMfTGerJ-aE-e757hwVVSia-zeXrvPKmuysikQBabU-cjiwAQuj3KZqnRRdGoxjzt-s3yJlpNIh6"
             }}
             />*/}
          </div>

          {/*<div>
           <PayPalButton
           options={{
           vault: true,
           clientId: "ASsyUMfTGerJ-aE-e757hwVVSia-zeXrvPKmuysikQBabU-cjiwAQuj3KZqnRRdGoxjzt-s3yJlpNIh6"
           }}
           createSubscription={(data, actions) => {
           return actions.subscription.create({
           plan_id: 'P-2AE660323W334430WL5NRTWQ'
           // plan_id: 'PROD-3AU3259377991780N'
           });
           }}

           catchError={(error)=> {
           console.log(error)

           }}

           onCancel={(error)=> {
           console.log("Cancel",error)

           }}
           onError={error => {
           console.log(error)
           }}
           onApprove={(data, actions) => {
           // Capture the funds from the transaction
           return actions.subscription.get().then(function(details) {
           console.log(details)
           // Show a success message to your buyer


           });
           }}
           />
           </div>*/}

          {
            promo && false && (
              <h5 className="promoDays">
                {promoDays >= 1 ? `${translate('happy_days_left')}${promoDays} ${translate(daysName)}` : `${translate('promo_days_left_zero')}`}
              </h5>
            ) || null
          }

          { !tour && false && <div>
            <InputGroup className="mb-3 discount" style={{maxWidth: 350}}>
              <Form.Control
                ref={this.code}
                value={code}
                type="text"
                maxLength="8"
                onChange={(e)=>this.handleChange('code',e.target.value)}
                placeholder={translate('enter_discount_code')}
              />
              <InputGroup.Append>
                <Button variant="outline-secondary" onClick={this._checkCode} disabled={code.length !== 8}>
                  {translate('check')}
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </div>}

          {
            discountCode && false && (
              <h5 className="text-center mb-3 text-success">
                {translate(`${discountCode.product_identifier}_discount`)}: {parseInt(discountCode.discount, 10)}%
              </h5>
            )
          }
          {
            currencyRates.length > 0 && false && (
              <div className="d-flex justify-content-center mb-3">
                <ButtonGroup>
                  <Button variant={currentCurrency == 'USD' ? 'secondary' : 'outline-secondary'}
                          onClick={()=>this._changeCurrency('USD')}>USD</Button>
                  <Button variant={currentCurrency == 'EUR' ? 'secondary' : 'outline-secondary'}
                          onClick={()=>this._changeCurrency('EUR')}>EUR</Button>
                  <Button variant={currentCurrency == 'UAH' ? 'secondary' : 'outline-secondary'}
                          onClick={()=>this._changeCurrency('UAH')}>UAH</Button>
                  <Button variant={currentCurrency == 'RUR' ? 'secondary' : 'outline-secondary'}
                          onClick={()=>this._changeCurrency('RUR')}>RUR</Button>
                </ButtonGroup>
              </div>
            )
          }

          {/*<div className="d-flex flex-wrap justify-content-around align-items-center" >

           {
           userData && userData.id ? (
           products.map((product, index) => {
           let price = product.price;
           let dollarPrice = product.price;
           let totalDollarPrice = product.price;
           let totalPrice = product.price;
           let orderId = product.orderId;
           let discountPromo = false;

           if (graceStatus || happyDay || tour) {
           price = product.discountPrice;
           dollarPrice = product.discountPrice;
           }

           if (discountCode && (discountCode.product_identifier == product.id)) {
           price = price - (price / 100 * parseInt(discountCode.discount, 10));
           price = price.toFixed(2);

           dollarPrice = dollarPrice - (dollarPrice / 100 * parseInt(discountCode.discount, 10));
           dollarPrice = dollarPrice.toFixed(2);

           discountPromo = true;
           }

           if (!isNaN(exchangeRate)) {
           price = price * exchangeRate;
           price = price.toFixed(2);
           totalPrice *= exchangeRate;
           totalPrice = totalPrice.toFixed(2);
           }

           if (discountCode) {
           orderId = `${orderId}-${discountCode.id}`
           }

           let cardClass = classNames({
           'overflow-hidden': true,
           'border-success': (product.id == 'glossary_cards' || product.id == 'list_cards' || product.id == 'lifetime_access') && userData[product.id] == 1,
           'border-info': (product.id == 'yearly_subscription' || product.id == 'monthly_subscription') && webSubscriptionStatus
           });

           return (
           <Card
           className={cardClass}
           bg={selectedProduct == product.id ? 'info' : 'light'}
           text={selectedProduct == product.id ? 'white' : 'dark'}

           key={index} style={{ width: '24rem', marginBottom: 20, minHeight: 300}}>
           <Card.Body className="position-relative d-flex flex-column justify-content-between">
           {
           discountCode && (discountCode.product_identifier == product.id) && (
           <div className="discount-label d-block justify-content-center align-items-center">
           <p className="mb-0 text-center text-light font-weight-bold">{translate('discount_')}</p>
           <p className="mb-0 text-center text-light">-{discountCode.discount}%</p>
           </div>
           )
           }

           {
           product.info ? (
           <OverlayTrigger
           trigger={['hover', 'focus']}
           delay={{ show: 250, hide: 500 }}
           placement="auto"
           overlay={<Popover id="popover-basic">
           <Popover.Content className="nl2br">
           {translate(product.info)}
           </Popover.Content>
           </Popover>}
           >
           <FontAwesomeIcon
           className="position-absolute hover"
           icon={faInfoCircle}
           style={{top: 5, right: 5, fontSize: 20}}
           />
           </OverlayTrigger>
           ) : null
           }
           <div>
           <Card.Title>
           {translate(product.name)}
           </Card.Title>
           <Card.Subtitle>
           {translate(product.description)}
           </Card.Subtitle>

           {
           (product.id == 'yearly_subscription' || product.id == 'monthly_subscription') && (
           <React.Fragment>
           <Card.Text className="mt-1 mb-1" style={{fontSize: 12, fontStyle: 'italic'}}>
           {translate('about_renewal')}
           </Card.Text>
           <Card.Text className="mt-1 mb-1" style={{fontSize: 12, fontStyle: 'italic'}}>
           {translate('about_verification_payment')}
           </Card.Text>
           </React.Fragment>
           ) || null
           }

           {
           (product.id == 'glossary_cards' || product.id == 'list_cards') && userData[product.id] == 0 && (
           <a
           className="mt-2 d-block"
           style={{color: selectedProduct == product.id ? colors.greyishBrown : colors.waterBlue}}
           href="#"
           onClick={this.tryCards}
           >
           {translate('try_free_print')}
           </a>
           )
           }

           {
           product.id == 'word_maker' ? (
           <span className="mt-2 d-block text-info">
           {translate('hints')} {!wordMakerData ? 10 : wordMakerData.hints}
           </span>
           ) : null
           }
           </div>


           {
           product.type == 'buy' && (
           <React.Fragment>
           {(userData[product.id] == 0 || userData[product.id] == undefined) ?

           <LiqPayPay
           publicKey={process.env.REACT_APP_PUBLIC_KEY}
           privateKey={process.env.REACT_APP_PRIVATE_KEY}
           orderId={orderId}
           amount={price}
           // currency={product.currency}
           currency={currentCurrency}
           result_url="https://mnemonicwords.com/purchases"
           server_url="https://mnemonicwords.app/api/liqpay"
           description={translate(product.name)}
           product_description={translate(product.description)}
           style={{ marginTop: 10 }}
           extra={
           <Button
           className="buy-button"
           variant={selectedProduct == product.id ? "outline-light" :"outline-success"}
           type="submit"
           onClick={(e) =>{
           if(!tour) {
           this.submitLiqpay(orderId, e)
           } else {
           e.preventDefault();
           this._toggleRegisterModal(true);
           }
           }}
           >
           <span className="d-block">
           {translate(product.buttonText)} {price} {currentCurrency} <span style={{fontSize: 12}}>({dollarPrice}$)</span>
           </span>

           {(promo || discountPromo) ? (
           <div className="d-flex justify-content-center">
           <span style={{textDecoration: 'line-through'}} className="promo-line">
           {translate('no_discount')} {totalPrice} {currentCurrency} <span style={{fontSize: 12}}>({totalDollarPrice}$)</span>
           </span>
           </div>
           ) : null}
           </Button>
           }
           />
           :
           (
           <Card.Text
           style={{color: selectedProduct == product.id ? colors.white : colors.greenish}}
           className="bought">{translate('already_bought')}</Card.Text>
           )
           }
           </React.Fragment>
           ) || null
           }

           {
           product.type == 'subscribe' && (
           <React.Fragment>
           {
           (!webSubscriptionStatus && userData['lifetime_access'] == 0) || tour ? (
           <LiqPaySubscribe
           publicKey={process.env.REACT_APP_PUBLIC_KEY}
           privateKey={process.env.REACT_APP_PRIVATE_KEY}
           orderId={orderId}
           amount={price}
           // currency={product.currency}
           currency={currentCurrency}
           subscribePeriodicity={product.periodicity}
           result_url="https://mnemonicwords.com/purchases"
           server_url="https://mnemonicwords.app/api/liqpay"
           description={translate(product.name)}
           product_description={translate(product.description)}
           style={{ marginTop: 10 }}
           extra={
           <Button
           className="buy-button"
           variant={selectedProduct == product.id ? "outline-light" :"outline-success"}
           type="submit"
           onClick={(e) =>{
           if(!tour) {
           this.submitLiqpay(orderId, e)
           } else {
           e.preventDefault();
           this._toggleRegisterModal(true);
           }
           }}
           >

           <span className="d-block">
           {translate(product.buttonText)} {price} {currentCurrency} {translate(product.buttonText2)} <span style={{fontSize: 12}}>({dollarPrice}$)</span>
           </span>
           {(promo || discountPromo) ? (
           <div className="d-flex justify-content-center">
           <span style={{textDecoration: 'line-through'}} className="promo-line">
           {translate('no_discount')} {totalPrice} {currentCurrency} {translate(product.buttonText2)} <span style={{fontSize: 12}}>({totalDollarPrice}$)</span>
           </span>
           </div>
           ) : null}
           </Button>
           }
           />
           )
           :
           (<Card.Text
           style={{color: selectedProduct == product.id ? colors.white : colors.greenish}}
           className="bought">
           {translate('you_have_active_subscription')}
           </Card.Text>)
           }
           </React.Fragment>
           )
           }

           </Card.Body>
           </Card>
           )
           })
           ) :
           <Loader fetchingData={fetchingData}/>
           }
           </div>*/}

          {
            generateListCardsModal && (
              <GenerateListCards
                isVisible={generateListCardsModal}
                onClose={this._toggleModal}
                sample={true}
                tour={tour}
                toRegister={this._toggleRegisterModal}
              />
            )
          }

          {registerModalVisible && (
            <RegisterAlert
              onClose={this._toggleRegisterModal}
              isVisible={registerModalVisible}
              translate={translate}
              toRegister={this._toRegister}
            />
          )}

        </Container>
        <Loader fetchingData={fetchingData}/>

      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  purchases: state.purchases,
  settings: state.settings,
  translate: getTranslate(state.localize),
  activity: state.activity,
  wordMaker: state.wordMaker,

});

function bindAction(dispatch) {
  return {
    setProducts: (data) => dispatch(setProducts(data)),
    setSubscriptions: (data) => dispatch(setSubscriptions(data)),
    checkForHappyDay: () => dispatch(checkForHappyDay()),
    getWordMakerData: () => dispatch(getWordMakerData()),
    getCurrencyExchangeRate: () => dispatch(getCurrencyExchangeRate()),
    checkDiscountCode: (code) => dispatch(checkDiscountCode(code)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    logActivity: (data) => dispatch(logActivity(data)),
    setDiscountCode: () => dispatch(setDiscountCode()),

  };
}

export default connect(mapStateToProps, bindAction)(AllProducts);
