// export const BACKEND_URL = hostname == 'localhost' || hostname == '10.0.2.2' ? 'http://127.0.0.1:8000/api/' : 'https://mnemonicwords.app/api/';
export const API_URL = 'https://mnemonicwords.app/api/';
export const WEB_URL = 'https://mnemonicwords.app/';
export const IMAGE_URL = 'https://mnemonicwords.app/img/';
export const BACKEND_URL = 'https://mnemonicwords.app';
export const IMAGE_URL_GLIDE = 'https://mnemonicwords.app/g/';
export const S3Images = 'https://mnemonicwords.s3.eu-central-1.amazonaws.com/images';
export const S3MarkedImages = 'https://mnemonicwords.s3.eu-central-1.amazonaws.com/r';
export const S3 = 'https://mnemonicwords.s3.eu-central-1.amazonaws.com';
// export const S3 = 'https://mnemonicwords.app';

export const APPLE_STORE = 'itms://itunes.apple.com/app/apple-store/id1438980777?mt=8';
export const APPLE_STORE_WEB = 'https://itunes.apple.com/app/id1438980777';
export const GOOGLE_PLAY = 'market://details?id=com.mnemonicrn';
export const GOOGLE_PLAY_WEB = 'http://play.google.com/store/apps/details?id=com.mnemonicrn';


export const getLocale = () => {
  // const userLang = navigator.language || navigator.userLanguage;

  /*if (userLang) {
   let ar = userLang.split('-');

   if (ar.length === 2 && ['ru', 'uk', 'en'].indexOf(ar[0]) !== -1 || userLang === 'uk' || userLang === 'ru') {
   locale = ar[0];
   }
   }*/

  let locale = process.env.REACT_APP_LANGUAGE;

  let path = window.location ? window.location.pathname : false;

  if (path && path.length > 0) {
    let strings = path.replace(/\//, '').split('/');

    if (strings && strings.length > 0 && ['ru', 'uk', 'en'].indexOf(strings[0]) !== -1) {
      locale = strings[0];

      sessionStorage.setItem('interfaceLanguage', locale)
    } else {
      const currentLanguage = localStorage.getItem(`interfaceLanguage`);

      if (currentLanguage !== null && currentLanguage != undefined && ['ru', 'uk', 'en'].indexOf(currentLanguage) !== -1) {
        locale = currentLanguage;
      }
    }
  }

  // console.log("locale", locale, path)
  
  return locale;
};