import React, {Component} from 'react';
import {Translate} from 'react-localize-redux';

import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

const MemorizationSystem = ({}) => (
  <div className="memorizationsystem" style={{marginTop: 90}}>
    <Container>
      <Card style={{padding: "20px 40px"}}>
        <h5 className="font-weight-bold mb-0">
          Запоминание английских слов и фраз по методике интервального повторения
        </h5>

        <p className="font-italic font-weight-light">
          (повторение через определенные промежутки времени)
        </p>

        <p>
          Эта методика основана на исследованиях немецкого ученого-психолога Эббингауза, который опытным путем подтвердил,
          что повторение информации через определенные промежутки времени значительно способствует запоминанию информации.
          Эффективность интервального повторения в несколько раз выше эффективности заучивания информации методом
          "зазубривания".
        </p>
        <p>
          Эббингауз вывел кривую забывания, которая наглядно показывает, как быстро наша память забывает новую информацию.
        </p>
        <p>
          Однако, повторение информации в нужные моменты времени с увеличивающимися интервалами между повторениями
          позволяет замедлить процесс забывания и увеличить процент запомненной информации.
        </p>

        <h5 className="font-weight-bold">
          Применение методики интервального повторения для запоминания английских слов
        </h5>
        <p>
          Если на "Кривой Эббингауза" выделить пять точек (сегодня, через 24 часа, через 48 часов, через 2 недели, через 2
          месяца) и повторять в эти дни, то на момент пятого повторения в вашей памяти останется более 90% запоминаемых
          слов. И останутся они там надолго.
        </p>
        <Image
          className="mt-2 mb-3 ml-auto mr-auto"
          style={{maxWidth: 600}}
          src={require('../../../assets/images/ebbinghause.png')}
        />

        <h5 className="font-weight-bold">
          Реализация методики интервального повторения на нашем сайте
        </h5>

        <div>Встроенная методика интервального повторения слов, благодаря которой эффективность запоминания у самых дисциплинированных пользователей достигает 100%.
        Календарь интервальных повторений, который покажет и подскажет когда и какие слова необходимо повторить.
        Дополнительное повторение слов, в которых вы допускаете ошибки на упражнениях.</div>
        <p>
          Для того, чтобы вы могли легко и просто использовать методику для пополнения словарного запаса мы разработали
          календарь интервальных повторений, а также систему напоминаний о списках, которые необходимо повторить.
        </p>

        <p>
          Календарь интервальных повторений - организует и упростит запоминание большого количества слов по методике.
        </p>
        <p>
          Начать необходимо с создания списков слов, которые вы хотели бы выучить.
          Найти новые слова и фразы вы сможете в разделе Списки.

          Все сохраненные слова появятся в блоке под названием "Новые списки"
        </p>

        <p>
          Для того, чтобы список попал в календарь, вам достаточно начать учить список на любой из тренировок.
        </p>
        <p>
          После этого список слов попадет в календарь и в определенные дни подскажет, когда вам необходимо будет повторить
          этот список.
        </p>

        <p>
          Для того, чтобы вы не пропустили день повторения каждого списка слов, мы создали блок "Пора повторить".
          В него попадают списки слов, которые вы уже начали повторять в блоке "Новые списки".
        </p>

        <p>
          Для того чтобы вы запомнили основные значения слов, их написание, смогли распознавать из на слух, а также
          использовать в речи мы разработали 10 тренировок для запоминания слов, которые подойдут для пользователей любого уровня.
        </p>


      </Card>
    </Container>
  </div>
);

export default MemorizationSystem;
