import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faCogs,
  faCheckCircle,
  faFilter,
  faTimes,
  faSearch,
  faPlus,
  faSort,
  faCalendarAlt,
  faImages
} from '@fortawesome/free-solid-svg-icons';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const CourseListToolbar = ({
  translate,
  ratio,
  deviceWidth,
  deviceHeight,
  toolbar,
  userData,

  courseData,
  list,
  lists,

  showWordSentence,
  listCards,
  lifetimeStatus,
  products,

  changeShowSentence,
  buyListCards,
  generateCards,
  toListCalendar,
  removeFromCalendar,
  toStatistics,
  toggleTrainingButtons,
  listWords,
  toFirstTraining

}) => (

  <Container fluid className="courselisttoolbar">
    <div className={toolbar}>
      <div
        className="d-flex flex-column-reverse flex-md-row justify-content-center justify-content-md-between align-items-center">

        <div className="d-flex justify-content-start align-items-center">
          {
            listWords.length > 0 ? (
              <Button
                style={{width: 200}}
                variant="outline-success"
                onClick={() => {
                if(userData && userData.active_trainings_set) {
                    toFirstTraining(list)
                  } else {
                     toggleTrainingButtons(true)
                  }
                }}
              >
                {translate('learn_words')}
              </Button>
            ) : null
          }
        </div>
        <div className="d-flex justify-content-end align-items-center mb-2 mb-md-0">

          {
            list && list.repetitions && list.repetitions.length != 0 && (
              <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='top' overlay={<Tooltip>{translate('course_list_calendar')}</Tooltip>}>
                <a href="#" onClick={toListCalendar} className="ml-2 mr-2 p-2">
                  <FontAwesomeIcon style={{fontSize: 20}} icon={faCalendarAlt}/>
                </a>
              </OverlayTrigger>
            )
          }

          {/*<OverlayTrigger delay={{ show: 500, hide: 500 }} placement='top'
                          overlay={<Tooltip>{(!listCards && !lifetimeStatus) ? `${translate('buy_list_cards')}` : translate('generate_list_cards')}</Tooltip>}>
            <a href="#"
               onClick={(e) => (!listCards && !lifetimeStatus) ? buyListCards(e) : generateCards(e)}
               className="ml-2 mr-2 p-2">
              <FontAwesomeIcon style={{fontSize: 20}} icon={faImages}/>
            </a>
          </OverlayTrigger>*/}

          <Dropdown alignRight>
            <Dropdown.Toggle
              as="a"
              className="p2 toggled-link"
            >
              <FontAwesomeIcon style={{fontSize: 20}} icon={faCogs}/>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as="button" onClick={changeShowSentence}>
                {translate(showWordSentence ? 'hide_example' : 'show_example')}
              </Dropdown.Item>

              {
                list && list.repetitions && list.repetitions.length != 0 && (
                  <React.Fragment>
                    <Dropdown.Divider/>

                    <Dropdown.Item as="button" className="text-danger" onClick={removeFromCalendar}>
                      {translate('remove_from_calendar')}
                    </Dropdown.Item>
                  </React.Fragment>
                )
              }

              {
                false && list && list.repetitions && list.repetitions.length != 0 && (
                  <a href="#" onClick={toStatistics} className="mh2">
                    {translate('course_list_statistics')}
                  </a>
                )
              }
            </Dropdown.Menu>

          </Dropdown>

        </div>
      </div>
    </div>
  </Container>
);

export default CourseListToolbar;
