import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faPlusCircle, faCheckCircle, faCircle} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import Loader from "../../../components/Custom/Loader/index";

import {getTranslationLanguages, change_translation_language} from '../../../actions/userActions';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

class ChangeTranslationLanguage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tablet: false,
      modalVisible: false,
      languages: [
        {code: 'ru', language: 'Russian'},
        {code: 'uk', language: 'Ukrainian'},
        {code: 'en', language: 'English'},
      ],
      currentLanguage: false
    };
  }

  componentDidMount() {
    const {translationLanguages} = this.props.user;
    if(translationLanguages.length == 0) {
      this.props.getTranslationLanguages();
    }
  }

  _changeTranslationLanguage = (language) => {
    if(!this.props.tour) {
      this.props.fetchingData(true);
      this.props.onClose('translationLanguageModal',false);

      this.props.change_translation_language(language);
      this.props.logActivity({activityId: 78, comment: language});
    } else {
      this.props.onClose('translationLanguageModal',false);

      this.props.toRegister(true);
    }
  };

  render() {
    const {fetchingData} = this.props.activity;
    const {translate, onClose, isVisible} = this.props;
    const {translationLanguages, userData} = this.props.user;

    const translationLanguage = userData && userData.translation_language ? userData.translation_language : 'ru';

    return (
      <Modal
        size="sm"
        className="changeinterfacelanguage"
        show={isVisible}
        onHide={()=>onClose('translationLanguageModal',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="translation_language"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {
            translationLanguages.map((item, i) => {
              return (
                <Button
                  key={i}
                  block
                  active={translationLanguage == item.code}
                  variant="outline-info"
                  onClick={()=>{
                    if(item.code != translationLanguage) {
                      this._changeTranslationLanguage(item.code);
                    }
                  }}
                >
                  {translate(item.language)}
                </Button>
              )
            })
          }
          
        </Modal.Body>

        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize)
});

function bindAction(dispatch) {
  return {
    getTranslationLanguages: () => dispatch(getTranslationLanguages()),
    change_translation_language: (translationLanguage) => dispatch(change_translation_language(translationLanguage)),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status))
  };
}

export default connect(mapStateToProps, bindAction)(ChangeTranslationLanguage);
