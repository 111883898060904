import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Translate, getTranslate} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faCheckCircle, faCircle, faTimes, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {toastr} from 'react-redux-toastr';
import moment from 'moment';
import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import ListSettings from '../../components/Modals/ListSettings/index';
import GenerateLists from '../../components/Modals/GenerateLists/index';
import InfoModal from '../../components/Modals/InfoModal/index';

import Loader from "../../components/Custom/Loader/index";

import SubscriptionAlert from '../../components/Modals/SubscriptionAlert/index';
import TrainingButtons from '../../components/Modals/TrainingButtons/index';
import ListsToFill from '../../components/Modals/ListsToFill/index';
import Mistakes from './Mistakes/index';
import CourseMistakes from './Courses/CourseMistakes/index';
import CourseTests from './Courses/CourseTests/index';
import CourseRepeats from './Courses/CourseRepeats/index';

import Repeats from './Repeats/index';
import NewLists from './NewLists/index';
import Relax from './Relax/index';
import TrainingsSet from '../../components/Modals/TrainingsSet/index';

import {fetchingData} from '../../actions/activity';
import {
  setUserId,
  setOriginalUserId,
  getUserScores,
  toggleReviewModal,
  getUserData,
  navigateToCourse,
  updateUserToken,
  getAssetsSource,
  setNewUser
} from '../../actions/userActions';
import {
  getListsToRepeat,
  postponeInCalendar,
  setCurrentList,
  getListsByLang,
  setShowArchive,
  toggleListTrainingButtonsModal,
  cleanListCache,
  removeFromCalendar,
  deleteListFromList,
  generateListsNotWizard,
  resetCurrentList
} from '../../actions/lists';

import {logActivity, logApiError} from '../../actions/logActions';
import {
  getEverydayPhrase,
  getEverydayWord,
  setGettingEverydayPhrase,
  setGettingEverydayWord,
  addEverydayToList
} from '../../actions/everydayActions';
import {setHideKnown, setHideKnownCourse} from '../../actions/trainingActions';
import {
  getRegistrationDate, getUserLimits, setProducts, setSubscriptions, setListCardsPurchaseData,
  setSubscriptionData, setCardsPurchaseData, checkWebSubscriptionStatus,
  checkForHappyDay
} from '../../actions/purchasesActions';
import {setFontSize, setDeviceVersion} from '../../actions/settingsActions';
import {
  checkForCourseDebts,
  setCourseStatus,
  setCurrentCourseSlug,
  setCourseListType,
  setCourseList,
  setCurrentCourseId,
  setOnlyCourseData
} from '../../actions/courseActions';
import {getUnreadNews} from '../../actions/newsActions';

import colors from '../../lib/colors';

class Home extends Component {

  constructor(props) {
    super(props);

    const {translate} = this.props;

    this.state = {
      testsModalVisible: false,
      postponeModalVisible: false,
      dictionaryModalVisible: false,
      repetitionsModalVisible: false,
      listTypeModalVisible: false,
      repetitions: [],
      limit: 5,
      refreshing: false,
      listTypeTitle: "",
      isOnline: false,
      isConnected: true,
      swiped: 1,
      list: false,
      subscriptionAlertVisible: false,
      subscriptionMessage: '',
      shouldShowWhatNextAlert: false,
      alertWhatNextModalVisible: false,
      studyLanguage: 'en',
      tablet: false,
      swipedValue: 0,
      trainingsModalVisible: false,
      currentList: false,
      currentType: false,
      currentEntity: false,
      currentListIndex: false,
      showNewListsInfo: false,
      showRepeatsInfo: false,
      socialsModal: false,
      hideDemoView: false,
      mistakesInfo: false,
      hidePromoView: false,
      listWordsSettingsModalVisible: false,
      generatorModalVisible: false,
      infoModalVisible: false,
      currentListType: false,
      firstTutorialVisible: false,
      courses: true,
      listsModalIsVisible: false,
      everydayItem: false,
      everydayType: false,
      trainingsSetModal: false,
      relaxDataFull: [
        {name: translate('course_info_header'), description: translate('course_tour_description'), segue: 'Course1000'},
        {
          name: translate('course_info_header_2000'),
          description: translate('course_tour_description'),
          segue: 'Course2000'
        },
        {name: translate('glossaries_home'), description: translate('glossaries_tour_description'), segue: 'glossaries'},
        {name: translate('auto_words_home'), description: translate('auto_words_home_description'), segue: 'SelectLevel'},
        // {name: translate('vocabulary_test'), segue: 'WordTest'},
        // {name: translate('games_home'), description: translate('games_home_description'), segue: 'Games'},
        // {name: translate('video_instructions'), segue: 'VideoInstructions'},
        // {name: translate('subscription_plans'), segue: 'SubscriptionPlans'},
        // {name: translate('join_us_home'), segue: 'Socials'}
      ],
      relaxDataLimited: [
        {name: translate('course_info_header'), description: translate('course_tour_description'), segue: 'Course1000'},
        {
          name: translate('course_info_header_2000'),
          description: translate('course_tour_description'),
          segue: 'Course2000'
        },
        {name: translate('glossaries_home'), description: translate('glossaries_tour_description'), segue: 'glossaries'},
        {name: translate('auto_words_home'), description: translate('auto_words_home_description'), segue: 'SelectLevel'},
        // {name: translate('tour_course'), description: translate('course_tour_description'), segue: 'Course1000'},
        // {name: translate('vocabulary_test'), segue: 'WordTest'},
        // {name: translate('games_home'), description: translate('games_home_description'), segue: 'Games'},
        // {name: translate('glossaries'), segue: 'Glossaries'},
        // {name: translate('words_generator'), segue: 'SelectLevel'},
        // {name: translate('course_title'), segue: 'Course1000'},
        // {name: translate('vocabulary_test'), segue: 'WordTest'},
        // {name: translate('games'), segue: 'Games'},
        // {name: translate('video_instructions'), segue: 'VideoInstructions'},
        // {name: translate('subscription_plans'), segue: 'SubscriptionPlans'},
        // {name: translate('join_us_home'), segue: 'Socials'}
      ]

    };

    this.myRef = React.createRef();
    this.newListRef = React.createRef();
    this.timeOut = null;
    this.timeOut1 = null;
    this._checkUser();
  }

  componentDidMount() {
    const {hidePromoView} = this.state;
    const {userId} = this.props.user;
    const hidePromo = localStorage.getItem(`hidePromo${userId}`);
    const hideDemo = localStorage.getItem(`hideDemo${userId}`);
    
    const tour = localStorage.getItem('tour');

    let today = moment().format('YYYY-MM-DD');

    if (hideDemo && hideDemo != null && hideDemo != undefined) {
      this.setState({
        hideDemoView: hideDemo == today
      })
    }

    if (hidePromo && hidePromo != null && hidePromo != undefined) {
      this.setState({
        hidePromoView: hidePromo == today
      })
    }

  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut1);
    // this.props.resetCurrentList();
  }

  _checkUser = () => {
    const userId = localStorage.getItem('userId');
    const {history} = this.props;
    const {locale} = this.props.settings;

    if (!userId) {
      history.replace(`/${locale}/login`);
    }
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toSubscription = (productId) => {
    this._toggleSubscriptionAlert(false);
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId});
  };

  hideTodayPromo = (e) => {
    e.preventDefault();

    const {userId} = this.props.user;

    localStorage.setItem(`hidePromo${userId}`, moment().format('YYYY-MM-DD'));

    this.setState({
      hidePromoView: true
    })
  };

  hideTodayDemo = (e) => {
    e.preventDefault();

    const {userId} = this.props.user;

    localStorage.setItem(`hideDemo${userId}`, moment().format('YYYY-MM-DD'));

    this.setState({
      hideDemoView: true
    })
  };

  getAlerts = async(userId) => {
    const show_alert_where_to_go = localStorage.getItem(`show_alert_where_to_go${userId}`);

    if (show_alert_where_to_go === null) {
      this.setState({
        shouldShowWhatNextAlert: true
      })
    }
    this.timeOut = setTimeout(() => {
      this.checkAlertsToShow(userId)
    }, 2000);
  };

  checkAlertsToShow = (userId) => {
    const {shouldShowWhatNextAlert} = this.state;
    const {relaxPlan} = this.props.lists;

    if (relaxPlan && shouldShowWhatNextAlert) {
      this.setState({
        alertWhatNextModalVisible: true
      });
      this.setAlert(userId, 'show_alert_where_to_go')
    }
  };

  setAlert = (userId, alert) => {
    localStorage.setItem(`${alert}${userId}`, 'true')
  };

  toggleSocialsModal = (status) => {
    this.setState({
      socialsModal: status
    })
  };

  _getGamesLimit = () => {
    const {subscriptionActive, gamesLimit, todayGamesLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;

    const {userData} = this.props.user;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    let active = false;

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayGamesLimit < gamesLimit;
    }

    return active;
  };

  _onPressItem = (list, index, type, e) => {
    e.preventDefault();
    e.stopPropagation();
    const {locale} = this.props.settings;

    const {relaxPlan} = this.props.lists;

    if (!relaxPlan) {
      this.props.setCurrentList(list);
      this.props.history.push(`/${locale}/list/${list.id}`, {list, back: true});
      this.props.logActivity({activityId: 31, model: 'list', modelId: list.id, comment: "from home"});
    }
  };

  _onLongPress = (list, index, type, e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.setCurrentList(list);

    this.setState({
      currentList: list,
      currentType: type,
      currentListIndex: index
    });
    this._toggleListWordsSettingsModal(true);
  };

  _toggleRepetitionsModal = (status) => {
    this.setState({
      repetitionsModalVisible: status
    })
  };

  _togglePostponeModal = (list, status) => {
    this.setState({
      list,
      postponeModalVisible: status
    });

  };

  _toggleListTypeModal = (status) => {
    this.setState({
      listTypeModalVisible: status
    })
  };

  _toggleFirstTutorial = (status) => {
    this.setState({
      firstTutorialVisible: status
    })
  };

  setCurrentTitle = (title, e) => {
    e.preventDefault();

    let message = "";
    const {translate} = this.props;

    if (title === 'with_mistakes') {
      message = translate("mistakes_section_description");
    } else if (title === 'time_to_repeat') {
      message = `${translate("repeats_description")}\n\n${translate('repeats_description2')}`;
    } else if (title === 'new_lists') {
      message = translate("start_training_with_repetition_system");
    }

    this.setState({
      listTypeTitle: message
    });

    this.props.logActivity({activityId: 51, comment: title});

    this._toggleListTypeModal(true);
  };

  onRepetitionsPressed = (intervals, e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.logActivity({activityId: 30, comment: 'from home'});
  };

  _postpone = (list, e) => {
    e.preventDefault();
    this.props.fetchingData(true);
    this.props.postponeInCalendar(list.id);
    this._toggleListWordsSettingsModal(false);
    this.props.logActivity({activityId: 50, model: 'list', modelId: list.id, comment: "from home"});
  };

  _onRelaxPressed = (item, e) => {
    e.preventDefault();

    const {isConnected} = this.props.settings;
    const {locale} = this.props.settings;

    if (item.segue == 'Games') {
      if (isConnected) {
        this.props.getRegistrationDate();
      }
    } else if (item.segue == 'SelectLevel') {
      this._toListGenerator();
    } else if (item.segue == 'Course1000' || item.segue == 'Course2000') {
      if (item.segue == 'Course1000') {
        this.props.setCurrentCourseSlug("Course 1000", '1000');
        this.props.history.push(`/${locale}/course/${1000}`, {slug: "1000", name: "Course 1000"})
      } else if (item.segue == 'Course2000') {
        this.props.setCurrentCourseSlug("Course 2000", '2000');
        this.props.history.push(`/${locale}/course/${2000}`, {slug: "2000", name: "Course 2000"})
      }

    } else if (item.segue == 'Socials') {
      this.toggleSocialsModal(true);
    } else {
      this.props.history.push(`${item.segue}`, {back: true});
    }
  };

  _toListGenerator = () => {
    const {subscriptionActive, listsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {lists} = this.props.lists;
    let active = false;

    if (lifetimeStatus || subscriptionActive || webSubscriptionStatus || !applyLimit) {
      active = true;
    }

    if (active) {
      this._toggleModal('generatorModalVisible', true);

      this.props.logActivity({activityId: 165, comment: 'from home'});
    } else {
      this.setState({
        subscriptionMessage: 'subscription_disabled_function'
      });
      this._toggleSubscriptionAlert(true);
    }
  };

  toggleReviewModal = () => {
    this.props.toggleReviewModal(false)
  };

  _onRepeatPressed = (list, type, e) => {
    e.preventDefault();
    e.stopPropagation();

    const {userData} = this.props.user;

    this.props.setCurrentList(list);

    this.setState({
      currentList: list,
      currentType: type,
      currentEntity: 'list'
    });

    if (userData && userData.active_trainings_set && list.id != 0) {
      this._goDirectlyToTraining(list, 'list');
    } else {
      this.toggleTrainingButtons(true);
    }
  };

  toggleTrainingButtons = (status) => {
    this.setState({
      trainingsModalVisible: status
    });

    // this.props.toggleListTrainingButtonsModal(false)
  };

  _getMistakes = () => {
    const {lists: {sections}} = this.props;

    let mistakes = false;

    sections.length && sections.forEach((section) => {

      if (section.title == 'with_mistakes') {
        mistakes = section.data[0]
      }
    });

    return mistakes;
  };

  _getRepeats = () => {
    const {lists: {sections}} = this.props;

    let repeats = [];

    sections.length && sections.forEach((section) => {
      if (section.title == 'time_to_repeat') {
        repeats = section.data
      }
    });

    return repeats;
  };

  _getNewLists = () => {
    const {lists: {sections}} = this.props;

    let newLists = [];

    sections.length && sections.forEach((section) => {
      if (section.title == 'new_lists') {
        newLists = section.data
      }
    });

    return newLists;
  };

  _checkFullAccess = (bought) => {
    const {registrationDate, subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {userData} = this.props.user;

    // console.log("subscriptionActive", subscriptionActive, webSubscriptionStatus);
    let active = false;

    if (!bought) {
      if (lifetimeStatus || subscriptionActive || webSubscriptionStatus || !applyLimit) {
        active = true;
      }
    } else {
      // if (lifetimeStatus || webSubscriptionStatus) {
      if (lifetimeStatus || subscriptionActive || webSubscriptionStatus) {
        active = true;
      }
    }

    return active;
  };

  trainingButtonSelected = (training) => {
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;

    const {currentEntity} = this.state;
    const {locale} = this.props.settings;

    const {userData} = this.props.user;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    let active = false;

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }
    /*
     this.setState({
     trainingsModalVisible: false
     });*/

    this.props.checkForHappyDay();
    this.toggleTrainingButtons(false);

    if (active) {
      const {currentList} = this.state;

      let activityId = '';

      let path = '';

      if (training == 'Preview') {
        activityId = 39;
        path = 'preview'
      } else if (training == 'DragImage') {
        path = 'image';

        activityId = 40;
      } else if (training == 'SelectTranslation') {
        path = 'translation';
        activityId = 41;
      } else if (training == 'SelectWord') {
        path = 'word';
        activityId = 42;
      } else if (training == 'CollectWord') {
        path = 'collect';
        activityId = 43;
      } else if (training == 'CheckSpelling') {
        path = 'spelling';
        activityId = 44;
      } else if (training == 'CollectSentence') {
        activityId = 114;
        path = 'sentence';
      } else if (training == 'CollectSentenceTap') {
        activityId = 335;
        path = 'sentence';
      } else if (training == 'MemoryCards') {
        activityId = 131;
        path = 'memory'
      } else if (training == 'WordDefinition') {
        path = 'definition';
        activityId = 148;
      } else if (training == 'SpeakOut') {
        activityId = 95;
      } else if (training == 'MissingLetter') {
        path = 'missing';
        activityId = 315;
      } else if (training == 'Listening') {
        path = 'listening';
        activityId = 345;
      } else if (training == 'TranslateSentence') {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut1 = setTimeout(()=> {
        if (currentEntity == 'list') {
          this.props.history.push(`/${locale}/${path}/${currentList.id}`, {
            type: 'listId',
            from: 'listWords',
            listId: currentList.id
          });
          this.props.logActivity({activityId, model: 'list', modelId: currentList.id, comment: 'from home'});
        } else if (currentEntity == 'course') {
          this.props.history.push(`/${locale}/${path}/${currentList.id}`, {
            type: 'courseListId',
            from: 'listWords',
            listId: currentList.id
          });
          this.props.logActivity({activityId, model: 'courseList', modelId: currentList.id, comment: 'from home'});
        }

      }, 100);
    } else {
      this.setState({
        subscriptionMessage: 'subscription_trainings'
      });

      this._toggleSubscriptionAlert(true);
    }
  };

  _checkActiveStatus = () => {
    const {lists} = this.props.lists;
    const {listsLimit, applyLimit, webSubscriptionStatus} = this.props.purchases;
    let active = false;

    if (webSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = lists.length < listsLimit;
    }

    return active;
  };

  _toggleNewListInfo = (status) => {
    this.setState({
      showNewListsInfo: !this.state.showNewListsInfo
    })
  };

  _toggleRepeatsInfo = (status) => {
    this.setState({
      showRepeatsInfo: !this.state.showRepeatsInfo
    })
  };

  _toggleMistakesInfo = (status) => {
    this.setState({
      mistakesInfo: !this.state.mistakesInfo
    })
  };

  _toggleListWordsSettingsModal = (status) => {
    this.setState({
      listWordsSettingsModalVisible: status
    })
  };

  removeFromCalendar = () => {
    const {currentList} = this.state;
    const {translate} = this.props;

    this.props.logActivity({activityId: 27, model: 'list', modelId: currentList.id});

    this._toggleModal('listWordsSettingsModalVisible', false);
    if (currentList.intervals.length > 0) {
      this.props.removeFromCalendar(currentList.id, "by_list_name", "asc");
      currentList.intervals = [];

      this.props.setCurrentList(currentList);
    } else {
      toastr.error('', translate('cannot_remove_from_calendar'));
    }
  };

  deleteList = (e) => {
    e.preventDefault();

    const {currentList} = this.state;

    this.props.fetchingData(true);

    this.props.deleteListFromList(currentList.id);
    this._toggleListWordsSettingsModal(false);

    this.props.logActivity({activityId: 25, model: 'list', modelId: currentList.id});
  };

  _getNewEveryday = (type) => {
    if (type == 'word') {
      this.props.setGettingEverydayWord(true);
      this.props.getEverydayWord();
    } else if (type == 'phrase') {
      this.props.setGettingEverydayPhrase(true);
      this.props.getEverydayPhrase();
    }
  };

  _addEverydayToList = (word, type) => {
    this.setState({
      everydayItem: word,
      everydayType: type
    });

    this.toggleListsToFillModal(true);
  };

  toggleListsToFillModal = (status) => {
    this.setState({
      listsModalIsVisible: status
    })
  };

  addEveryDayToSelectedList = (id) => {
    const {everydayItem, everydayType} = this.state;

    this.toggleListsToFillModal(false);

    let active = true;

    if (id == 0) {
      active = this._checkActiveStatus();
    }

    if (active) {
      this.props.fetchingData(true);

      this.props.addEverydayToList(id, everydayItem.id, everydayType);

      this.setState({
        everydayItem: false,
        everydayType: false
      })
    } else {
      this.setState({
        subscriptionMessage: 'subscription_lists'
      });
      this._toggleSubscriptionAlert(true);
    }
  };

  _cleanListCache = (e) => {
    e.preventDefault();

    const {currentList} = this.state;
    const {translate} = this.props;

    this._toggleListWordsSettingsModal(false);

    this.props.logActivity({activityId: 314});

    this.props.cleanListCache(currentList.id);

    toastr.success("", translate('list_cache_cleaned'));
  };

  _toggleModal = (modal, status) => {
    this.setState({
      [modal]: status
    })
  };

  _generateLists = (level, glossaryId, total, e) => {
    e.preventDefault();
    this._toggleModal('generatorModalVisible', false);
    const {userData} = this.props.user;

    let listSize = userData && userData.list_size ? userData.list_size : 10;

    this.props.fetchingData(true);

    const words = total * listSize;

    this.props.generateListsNotWizard(words, glossaryId, level, true);

    this.props.logActivity({activityId: 165, comment: `${words}`});
  };

  _notNewUser = (e) => {
    e.preventDefault();
    this.props.setNewUser(false)
  };

  _onRepeatMistakesPressed = (list, course, e) => {
    e.preventDefault();

    this.setState({
      currentList: list,
      currentType: 'mistake',
      currentEntity: 'course'
    });

    this.props.setCourseListType("mistake");
    this.props.setCurrentCourseId(course.courseData.id);
    this.props.setOnlyCourseData(course.courseData);
    this.props.setCurrentCourseSlug(course.courseData.course_name, course.slug);
    this.props.setCourseList(list);

    this.toggleTrainingButtons(true);
  };

  _onCourseTestPressed = (list, course) => {
    const {locale} = this.props.settings;

    this.props.setCourseList(list);
    this.props.setCourseListType('test');
    this.props.setCurrentCourseId(course.courseData.id);
    this.props.setOnlyCourseData(course.courseData);
    this.props.setCurrentCourseSlug(course.courseData.course_name, course.slug);

    this.setState({
      currentList: list,
      currentType: 'test',
      currentEntity: 'course'
    });

    this.props.history.push(`/${locale}/courselist/${list.id}`, {list, back: true});

    this.props.logActivity({activityId: 253, model: 'courseList', modelId: list.id});

  };

  _onTestPressed = (list, course, e) => {

    e.stopPropagation();
    e.preventDefault();

    const {todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus} = this.props.purchases;
    const {locale} = this.props.settings;

    const {userData} = this.props.user;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    let active = false;

    if (tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    this.setState({
      trainingsModalVisible: false
    });


    this.props.setCourseList(list);
    this.props.setCourseListType("test");
    this.props.setCurrentCourseId(course.courseData.id);
    this.props.setOnlyCourseData(course.courseData);
    this.props.setCurrentCourseSlug(course.courseData.course_name, course.slug);

    this.setState({
      currentList: list
    });

    if (active) {
      this.props.history.push(`/${locale}/test/${list.id}`, {type: 'courseListId', from: 'course', courseListId: list.id});
    } else {
      this._toggleSubscriptionAlert(true);
    }

    this.props.logActivity({activityId: 256, model: 'courseList', modelId: list.id});
  };

  _onCourseListPressed = (list, course) => {
    const {locale} = this.props.settings;

    this.props.setCourseList(list);
    this.props.setCourseListType('repeat');

    this.props.setCurrentCourseId(course.courseData.id);
    this.props.setOnlyCourseData(course.courseData);
    this.props.setCurrentCourseSlug(course.courseData.course_name, course.slug);

    this.setState({
      currentList: list
    });

    this.props.history.push(`/${locale}/courselist/${list.id}`, {list});

    this.props.logActivity({activityId: 253, model: 'courseList', modelId: list.id});
  };

  _onCourseRepeatPressed = (list, course, e) => {
    e.stopPropagation();
    const {userData} = this.props.user;

    this.props.setCourseList(list);
    this.props.setCourseListType("repeat");

    this.props.setCurrentCourseId(course.courseData.id);
    this.props.setOnlyCourseData(course.courseData);
    this.props.setCurrentCourseSlug(course.courseData.course_name, course.slug);


    this.setState({
      currentList: list,
      currentType: 'repeat',
      currentEntity: 'course'
    });

    if (userData && userData.active_trainings_set && list.id != 0) {
      this._goDirectlyToTraining(list, 'course');
    } else {
      this.toggleTrainingButtons(true);
    }
  };

  _checkForCoursesData = () => {
    const {cachedCourseData} = this.props.courseCache;
    let found = false;

    cachedCourseData.forEach((course, index) => {
      if (course && course.repeats && course.repeats.length > 0) {
        found = true;
      } else if (course && course.tests && course.tests.length > 0) {
        found = true;
      } else if (course && course.mistakes && course.mistakes.words > 0) {
        found = true;
      }
    });

    return found;
  };

  _customizeButtons = (e) => {
    e.preventDefault();
    this.toggleTrainingButtons(false);
    this._toggleModal('trainingsSetModal', true)
  };

  _goDirectlyToTraining = (list, currentEntity) => {
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {userData} = this.props.user;
    const {locale} = this.props.settings;

    let active = false;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    if (active) {
      let trainingIds = userData.trainings_set != null ? userData.trainings_set.split(',').map(Number) : [];

      let trainingId = trainingIds.length > 0 ? trainingIds[0] : 1;

      let activityId = '';
      let path = '';

      if (trainingId == 1) {
        activityId = 39;
        path = 'preview'
      } else if (trainingId == 6) {
        path = 'image';
        activityId = 40;
      } else if (trainingId == 2) {
        path = 'translation';
        activityId = 41;
      } else if (trainingId == 4) {
        path = 'word';
        activityId = 42;
      } else if (trainingId == 3) {
        path = 'collect';
        activityId = 43;
      } else if (trainingId == 5) {
        path = 'spelling';
        activityId = 44;
      } else if (trainingId == 8) {
        activityId = 114;
        path = 'sentence';
      } else if (trainingId == 9) {
        activityId = 131;
        path = 'memory'
      } else if (trainingId == 10) {
        path = 'definition';
        activityId = 148;
      } else if (trainingId == 7) {
        activityId = 95;
      } else if (trainingId == 12) {
        path = 'missing';
        activityId = 315;
      } else if (trainingId == 14) {
        path = 'listening';
        activityId = 345;
      } else if (trainingId == 15) {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut4 = setTimeout(()=> {
        if (currentEntity == 'list') {
          this.props.history.push(`/${locale}/${path}/${list.id}`, {
            type: 'listId',
            from: 'listWords',
            listId: list.id
          });
          this.props.logActivity({activityId, model: 'list', modelId: list.id, comment: 'from home'});
        } else if (currentEntity == 'course') {
          this.props.history.push(`/${locale}/${path}/${list.id}`, {
            type: 'courseListId',
            from: 'listWords',
            listId: list.id
          });
          this.props.logActivity({activityId, model: 'courseList', modelId: list.id, comment: 'from home'});
        }
      }, 100);
    } else {
      this._toggleSubscriptionAlert(true);
    }
  };

  _showDemoModal = () => {
    this._toggleModal('infoModalVisible', true)
  };

  _getDemoView = () => {
    const {happyDay, happyEndDay, graceStatus, graceDaysLeft, registrationDate} = this.props.purchases;
    const {fontSizeRation} = this.props.settings;
    let {tablet, hideDemoView} = this.state;
    let {translate} = this.props;

    const today = moment(registrationDate.today).format("YYYY-MM-DD");

    let promo = false;
    let promoDays = 0;
    let daysName = 'days';

    if (happyDay || graceStatus) {
      promo = true;

      promoDays = graceDaysLeft > happyEndDay ? graceDaysLeft : happyEndDay;

      if (promoDays == 1) {
        daysName = 'days1'
      } else if (promoDays >= 2 && promoDays <= 4) {
        daysName = 'days2'
      }
    }

    let fullAccess = this._checkFullAccess(true);

    if (!fullAccess && !hideDemoView) {
      if (promo && (promoDays >= 1 || promoDays == 0)) {
        let text = promoDays >= 1 ? `${translate('demo_ends_in')}${promoDays} ${translate(daysName)}` : `${translate('demo_ends_today')}`;

        return (
          <div className="ml-auto mr-auto mb-3" style={{maxWidth: 500, position: 'relative'}}>

            <a href="#" onClick={(e) => {
              e.preventDefault();
              this._showDemoModal()
                  // this.props.logActivity({activityId: 330});
              }}
            >
              <div className="promoContainer">
                <span className="promo" style={{whiteSpace: 'pre-line',fontSize: 16}}>
                  {text}
                </span>

                <span style={{fontSize: 20, color: 'white', marginTop: 10, textTransform: 'uppercase'}}>
                  {translate('learn_more')}
                </span>
              </div>
            </a>

            <a href="#"
               style={{position: 'absolute', top: 10, right: 10}}
               onClick={this.hideTodayDemo}
            >
              <FontAwesomeIcon
                icon={faTimes}
                style={{fontSize: !tablet ? 20 : 25, color: colors.coral}}
              />
            </a>
          </div>
        )
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  _getPromoText = () => {
    const {happyDay, happyEndDay, graceStatus, graceDaysLeft, registrationDate, webSubscriptionStatus} = this.props.purchases;
    let {tablet, hidePromoView} = this.state;
    let {translate} = this.props;
    const {locale} = this.props.settings;


    const today = moment().format("YYYY-MM-DD");

    // console.log("Happy days", happyDay, happyEndDay)
    // console.log("Grace", graceDaysLeft, graceStatus)
    // console.log("hidePromoView", hidePromoView)

    let promo = false;
    let promoDays = 0;
    let daysName = 'days';


    if (happyDay || graceStatus) {
      promo = true;

      promoDays = graceDaysLeft > happyEndDay ? graceDaysLeft : happyEndDay;

      if (promoDays == 1) {
        daysName = 'days1'
      } else if (promoDays >= 2 && promoDays <= 4) {
        daysName = 'days2'
      }
    }

    let fullAccess = this._checkFullAccess(true);

    if (today == registrationDate) {
      return null;
    }

    if (!fullAccess && !hidePromoView && !webSubscriptionStatus) {
      if (promo && (promoDays >= 1 || promoDays == 0)) {
        let text = promoDays >= 1 ? `${translate('happy_days_left')}${promoDays} ${translate(daysName)}` : `${translate('promo_days_left_zero')}`;

        return (
          <div className="ml-auto mr-auto mb-3" style={{maxWidth: 400, position: 'relative'}}>
            <a href="#"
               onClick={(e) => {
                e.preventDefault();
                this.props.history.push(`/${locale}/products`);
                  this.props.logActivity({activityId: 330});
              }}
            >
              <div className="promoContainer">
                <span className="promo" style={{fontSize: 16}}>
                  {text}
                </span>
              </div>
            </a>

            <a href="#"
               style={{position: 'absolute', top: 10, right: 10}}
               onClick={this.hideTodayPromo}
            >
              <FontAwesomeIcon
                icon={faTimes}
                style={{fontSize: !tablet ? 20 : 25, color: colors.coral}}
              />
            </a>
          </div>
        )
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  render() {
    const {
      subscriptionAlertVisible,
      subscriptionMessage,
      tablet,
      trainingsModalVisible,
      currentList,
      currentType,
      listWordsSettingsModalVisible,
      listsModalIsVisible,
      everydayItem,
      everydayType,
      generatorModalVisible,
      trainingsSetModal,
      infoModalVisible
    } = this.state;

    let {relaxDataFull, relaxDataLimited} = this.state;
    const {translate} = this.props;

    const {isConnected, deviceHeight, deviceWidth} = this.props.settings;
    const {unreadFetch, unreadNews} = this.props.news;

    const {lists: {sections, showListTrainingButtons}, user: {scores, newUser, reviewModalVisible, userId, userData, approvedDictionaryTranslations}, user} = this.props;
    const {relaxPlan, lists} = this.props.lists;
    const {courseStatus} = this.props.course;
    const {everydayWord, everydayPhrase, gettingPhrase, gettingWord} = this.props.everydays;

    const {fetchingData} = this.props.activity;
    const {fontSizeRation} = this.props.settings;
    let level = userData && userData.level ? userData.level : 0;
    let listSize = userData && userData.list_size ? userData.list_size : 10;

    let relaxData = (level == 0 || level == 1) ? relaxDataFull : relaxDataLimited;

    let mistakes = this._getMistakes();
    let repeats = this._getRepeats();
    let newLists = this._getNewLists();

    let mistakesToRepeat = mistakes && mistakes.words > 0 && !relaxPlan;

    let wrapperStyle = classNames({
      'flex': !relaxPlan,
      'd-flex': relaxPlan,
      'flex-column': relaxPlan,
      'justify-content-start': relaxPlan
    });

    const coursesData = this._checkForCoursesData();

    let coursesClasses = classNames({
      'd-flex justify-content-center flex-wrap align-items-center': true,
      'mb-3': coursesData
    });

    let listsClasses = classNames({
      'd-flex justify-content-center flex-wrap align-items-center': true,
      'mb-3': (mistakesToRepeat || (repeats && repeats.length > 0))
    });

    const demoView = this._getDemoView();

    return (
      <div className="home">

        <Breadcrumb style={{marginTop: 50}}>
          <Breadcrumb.Item active>
            {translate('home')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container style={{marginTop: 20}} className="pageWrapper">

          {this._getPromoText()}
          {demoView}

          <div className={wrapperStyle}>
            <div>
              {
                coursesData && (
                  <OverlayTrigger
                    delay={{ show: 700, hide: 400 }}
                    trigger={['hover', 'focus']} placement="auto" overlay={<Popover id="popover-basic">
                        <Popover.Content>
                          <p className="nl2br">{translate('time_to_repeat_course_lists_description')}</p>
                        </Popover.Content>
                      </Popover>}
                  >
                    <div className="flexH flexVStart mb-2 hover">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{color: colors.tealish, fontSize: !tablet ? 20 : 30}}
                      />
                        <span
                          className="fontBold textCenter"
                          style={{fontSize: fontSizeRation * 16, color: colors.tealish, marginLeft: 10}}
                        >
                          {translate('course_data_to_repeat')}
                        </span>
                    </div>
                  </OverlayTrigger>
                )
              }
              <div className={coursesClasses}>
                <CourseMistakes
                  key={Math.random()}
                  onRepeatMistakes={this._onRepeatMistakesPressed}
                />

                <CourseTests
                  key={Math.random()}
                  onTestPressed={this._onCourseTestPressed}
                  onTestRepeat={this._onTestPressed}
                />

                <CourseRepeats
                  key={Math.random()}
                  onListPressed={this._onCourseListPressed}
                  onRepeatPressed={this._onCourseRepeatPressed}
                />
              </div>
            </div>

            <div className="mb-3">
              {
                (mistakesToRepeat || (repeats && repeats.length > 0)) && (
                  <OverlayTrigger
                    delay={{ show: 700, hide: 400 }}
                    trigger={['hover', 'focus']} placement="auto" overlay={<Popover id="popover-basic">
                        <Popover.Content>
                        {(repeats && repeats.length > 0) ? (
                        <div>
                          <p className="nl2br">{translate('repeats_description')}</p>
                          <p className="nl2br">{translate('repeats_description2')}</p>
                         </div>
                        ): <p className="nl2br">{translate('mistakes_section_description')}</p>}

                        </Popover.Content>
                      </Popover>}
                  >
                    <div className="flexH flexVStart mb-2 hover">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{color: colors.tealish, fontSize: !tablet ? 20 : 30}}
                      />
                        <span
                          className="fontBold textCenter"
                          style={{fontSize: fontSizeRation * 16, color: colors.tealish, marginLeft: 10}}
                        >
                          {translate('time_to_repeat_lists')}
                        </span>
                    </div>
                  </OverlayTrigger>
                )
              }

              <div className={listsClasses}>
                <Mistakes
                  key={Math.random()}
                  ratio={fontSizeRation}
                  translate={translate}
                  tablet={tablet}
                  mistakes={mistakes}
                  mistakesToRepeat={mistakesToRepeat}
                  onPressCard={this._onPressItem}
                  onRepeatPressed={this._onRepeatPressed}
                />

                <Repeats
                  key={Math.random()}
                  ratio={fontSizeRation}
                  translate={translate}
                  tablet={tablet}
                  mistakes={mistakes}
                  relaxPlan={relaxPlan}
                  repeats={repeats}
                  mistakesToRepeat={mistakesToRepeat}
                  onPressCard={this._onPressItem}
                  onLongPressed={this._onLongPress}
                  onRepeatPressed={this._onRepeatPressed}
                  onRepetitionsPressed={this.onRepetitionsPressed}
                />
              </div>
            </div>

            <NewLists
              key={Math.random()}
              ratio={fontSizeRation}
              translate={translate}
              tablet={tablet}
              mistakes={mistakes}
              relaxPlan={relaxPlan}
              repeats={repeats}
              newLists={newLists}
              mistakesToRepeat={mistakesToRepeat}
              onPressCard={this._onPressItem}
              onLongPressed={this._onLongPress}
              onRepeatPressed={this._onRepeatPressed}
              onRepetitionsPressed={this.onRepetitionsPressed}
            />

            {
              relaxPlan && !coursesData && (
                <Relax
                  key={Math.random()}
                  relaxPlan={relaxPlan}
                  relaxData={relaxData}
                  level={level}
                  ratio={fontSizeRation}
                  translate={translate}
                  tablet={tablet}
                  deviceHeight={deviceHeight}
                  deviceWidth={deviceWidth}
                  onItemPressed={this._onRelaxPressed}
                  everydayWord={everydayWord}
                  everydayPhrase={everydayPhrase}
                  gettingWord={gettingWord}
                  gettingPhrase={gettingPhrase}
                  getNewEveryday={this._getNewEveryday}
                  addEverydayToList={this._addEverydayToList}
                  newUser={newUser}
                  notNewUser={this._notNewUser}
                />
              )
            }

          </div>

          {/*** MODALS ***/}

          {listWordsSettingsModalVisible && (
            <ListSettings
              isVisible={listWordsSettingsModalVisible}
              onClose={this._toggleModal}
              title={currentList ? currentList.list_name : ""}
              deleteList={this.deleteList}
              postpone={this._postpone}
              removeFromCalendar={this.removeFromCalendar}
              list={currentList}
              ratio={fontSizeRation}
              cleanListCache={this._cleanListCache}
              openTagsModal={this._openTagModal}
              type={currentType}
              translate={translate}
              home={true}
              toggleFavorite={this._toggleFavorite}
            />
          )}
          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={subscriptionMessage}
                translate={translate}
              />
            )
          }

          {
            generatorModalVisible && (
              <GenerateLists
                onClose={this._toggleModal}
                isVisible={generatorModalVisible}
                translate={translate}
                generateList={this._generateLists}
              />
            )
          }

          {
            infoModalVisible && (
              <InfoModal
                onClose={this._toggleModal}
                isVisible={infoModalVisible}
                translate={translate}
              />
            )
          }

          {(trainingsModalVisible) && (
            <TrainingButtons
              modalVisible={trainingsModalVisible}
              training={this.trainingButtonSelected}
              onClose={() => this.toggleTrainingButtons(false)}
              ratio={fontSizeRation}
              listId={currentList ? currentList.id : null}
              title="select_training_to_repeat"
              translationLanguage={userData.translation_language}
              course={false}
              level={level}
              tablet={tablet}
              translate={translate}
              customiseButtons={this._customizeButtons}

            />
          )}

          {
            trainingsSetModal && (
              <TrainingsSet
                onClose={this._toggleModal}
                isVisible={trainingsSetModal}
              />
            )
          }

          {listsModalIsVisible && everydayItem && (
            <ListsToFill
              isVisible={listsModalIsVisible}
              lists={lists}
              fillLists={this.addEveryDayToSelectedList}
              listSize={listSize}
              ratio={fontSizeRation}
              everyday={everydayItem}
              selectedWords={1}
              type={everydayType}
              translate={translate}
              onClose={this.toggleListsToFillModal}/>
          )}

        </Container>
        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}


function bindAction(dispatch) {
  return {
    setUserId: (id) => dispatch(setUserId(id)),
    setOriginalUserId: (id) => dispatch(setOriginalUserId(id)),
    getListsToRepeat: (limit, userId) => dispatch(getListsToRepeat(limit, userId)),
    setCurrentList: (list) => dispatch(setCurrentList(list)),
    postponeInCalendar: (listId) => dispatch(postponeInCalendar(listId)),
    getUserScores: (userId) => dispatch(getUserScores(userId)),
    logActivity: (data) => dispatch(logActivity(data)),
    getRegistrationDate: () => dispatch(getRegistrationDate()),
    getAssetsSource: () => dispatch(getAssetsSource()),
    getUserLimits: () => dispatch(getUserLimits()),
    setProducts: (data) => dispatch(setProducts(data)),
    logApiError: (data) => dispatch(logApiError(data)),
    toggleReviewModal: (status) => dispatch(toggleReviewModal(status)),
    setSubscriptions: (data) => dispatch(setSubscriptions(data)),
    checkWebSubscriptionStatus: (userId) => dispatch(checkWebSubscriptionStatus(userId)),
    setCardsPurchaseData: (status, date) => dispatch(setCardsPurchaseData(status, date)),
    setListCardsPurchaseData: (status, date) => dispatch(setListCardsPurchaseData(status, date)),
    setSubscriptionData: (data) => dispatch(setSubscriptionData(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    setFontSize: (size) => dispatch(setFontSize(size)),
    setDeviceVersion: (version) => dispatch(setDeviceVersion(version)),
    getUserData: (userId) => dispatch(getUserData(userId)),
    checkForCourseDebts: (userId) => dispatch(checkForCourseDebts(userId)),
    navigateToCourse: (status) => dispatch(navigateToCourse(status)),
    setCourseStatus: (status) => dispatch(setCourseStatus(status)),
    setHideKnown: (status) => dispatch(setHideKnown(status)),
    setShowArchive: (status) => dispatch(setShowArchive(status)),
    setHideKnownCourse: (status) => dispatch(setHideKnownCourse(status)),
    getListsByLang: (filter, order) => dispatch(getListsByLang(filter, order)),
    checkForHappyDay: () => dispatch(checkForHappyDay()),
    updateUserToken: (token, userId) => dispatch(updateUserToken(token, userId)),
    toggleListTrainingButtonsModal: (status) => dispatch(toggleListTrainingButtonsModal(status)),
    cleanListCache: (id) => dispatch(cleanListCache(id)),
    removeFromCalendar: (listId, filter, order) => dispatch(removeFromCalendar(listId, filter, order)),
    deleteListFromList: (listId, back) => dispatch(deleteListFromList(listId, back)),
    getEverydayPhrase: () => dispatch(getEverydayPhrase()),
    getEverydayWord: () => dispatch(getEverydayWord()),
    setGettingEverydayWord: (status) => dispatch(setGettingEverydayWord(status)),
    setGettingEverydayPhrase: (status) => dispatch(setGettingEverydayPhrase(status)),
    addEverydayToList: (listId, wordId, type) => dispatch(addEverydayToList(listId, wordId, type)),
    getUnreadNews: () => dispatch(getUnreadNews()),
    resetCurrentList: () => dispatch(resetCurrentList()),
    generateListsNotWizard: (words, glossaryId, level, byLists) => dispatch(generateListsNotWizard(words, glossaryId, level, byLists)),
    setCurrentCourseSlug: (name, slug) => dispatch(setCurrentCourseSlug(name, slug)),
    setNewUser: (status) => dispatch(setNewUser(status)),
    setCourseListType: (type) => dispatch(setCourseListType(type)),
    setCourseList: (list) => dispatch(setCourseList(list)),
    setCurrentCourseId: (id) => dispatch(setCurrentCourseId(id)),
    setOnlyCourseData: (data) => dispatch(setOnlyCourseData(data)),

  };
}

const mapStateToProps = state => ({
  user: state.user,
  news: state.news,
  course: state.course,
  lists: state.lists,
  purchases: state.purchases,
  activity: state.activity,
  settings: state.settings,
  everydays: state.everydays,
  translate: getTranslate(state.localize),
  courseCache: state.courseCache,

});

export default connect(
  mapStateToProps,
  bindAction,
)(withRouter(Home));
