import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faInfoCircle,
  faCogs,
  faRedo,
  faFlag,
  faEye,
  faCheck,
  faTimes,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';

import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';

import classNames from 'classnames';

import colors from '../../../lib/colors';

class TrainingSubHeader extends Component {

  format(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(nextProps.progress === this.props.progress && nextProps.scores === this.props.scores) || !(nextProps.seconds === this.props.seconds)
  }

  render() {
    const {
      changeWord,
      progress,
      scores,
      hideButtons,
      onlyButtons,
      ratio,
      freeze,
      trainingId,
      deviceHeight,
      translate,
      tablet,
      exit,
      trainingName,
      subtitle,
      test,
      url,
      title,
      text,
      hideInfo,
      toggleSettings,
      hideSettings,


      hangman,
      onHint,
      hints,
      surrender,
      refresh,
      attempts,
      gameOver,
      win,


      extractor,
      seconds,
      typedWord,
      erase,
      confirm,
      showExit
    } = this.props;

    let marginVertical = 20;

    if (!onlyButtons) {
      marginVertical = !tablet ? (deviceHeight > 650 ? 20 : 10) : (deviceHeight > 950 ? 30 : 20)
    } else {
      marginVertical = 0;
    }

    let progressBar = classNames({
      'mr-2': (!test || !showExit),
      'leftPart': true
    });

    const resultText = win ? "you_won" : 'you_lost';


    let min = extractor ? Math.floor(seconds / 60) : 0;
    let sec = extractor ? (seconds - min * 60) : 0;

    const time = extractor ? (this.format(min, '0', 2) + ':' + this.format(sec, '0', 2)) : 0;

    const exitClasses = classNames({
      'd-none': test === true || showExit === false,
      // 'p-2': true
    });

    const settingsClasses = classNames({
      'd-none': hideSettings === true,
      // 'p-2': true
    });

    return (
      <div className="trainingSubheader">
        <div className="mb-3 d-flex flex-row justify-content-between align-items-center"
        >
          <div className="block trainingBlock">
            <span className="d-inline-block training-name text-center position-relative">
              {translate(trainingName)}
              {
                !hideInfo && (
                  <OverlayTrigger
                    rootClose={true}
                    trigger="click"
                    placement="bottom"
                    overlay={<Popover id="popover-basic">
                    <Popover.Title as="h5">
                      {translate(title)}
                    </Popover.Title>
                    <Popover.Content>
                      <span style={{whiteSpace: 'pre-line'}}>
                         {translate(text)}
                      </span>

                        {url && <a
                          className="d-block pt-2 pb-2 text-center"
                          href={url} target="_new">
                          {translate('watch_video_instruction')}
                        </a>
                        }
                    </Popover.Content>
                  </Popover>}
                  >
                    <FontAwesomeIcon className="infoIcon ml-2" icon={faInfoCircle}/>
                  </OverlayTrigger>
                )
              }
            </span>
            {
              subtitle && <span style={{fontSize: ratio * 16}} className="font-weight-light d-block text-left">
              {subtitle}
            </span>
            }

          </div>

          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="block exitBlock">
              <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='left' overlay={<Tooltip>{translate('finishTraining')}</Tooltip>}>
                <a className={exitClasses} size="sm" variant="light" href="#"
                   onClick={(e) => {
                  e.preventDefault();
                  exit();
                 }}>
                  <FontAwesomeIcon className="infoIcon" icon={faSignOutAlt}/>
                </a>
              </OverlayTrigger>
            </div>

            <div className="block settingsBlock ml-3">
              <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='left' overlay={<Tooltip>{translate('settings')}</Tooltip>}>
                <a
                  className={settingsClasses}
                  href="#" onClick={(e)=>{
                  e.preventDefault();
                  toggleSettings(true)
                }}>
                  <FontAwesomeIcon className="infoIcon" icon={faCogs}/>
                </a>
              </OverlayTrigger>
            </div>
          </div>
        </div>

        <div className="subHeaderWrapper" style={{marginVertical: marginVertical}}>
          <div className={progressBar} style={{width: !test ? '80%' : '100%'}}>
            {
              !onlyButtons && (
                <div className="scoresWrapper" style={{top: !tablet ? -20 : -30}}>
                <span className="scores" style={{fontSize: ratio * (!tablet ? 10 : 12)}}>
                  {`${translate('scoresUC')}: ${scores}`}
                </span>

                  {extractor && (
                    <span className="timer d-block"
                          style={{color: seconds > (!tablet ? 10 : 14) ? colors.greyishBrown : colors.darkCoral}}
                    >
                  {seconds > 0 ? time : "0:00"}
                </span>
                  )}
                </div>
              )
            }

            {!onlyButtons && !extractor && (
              <ProgressBar
                style={{width: '100%'}}
                variant="success"
                now={progress*100}
                // label={`${progress*100}%`}
              />
            )}

            {
              hangman && !gameOver && (
                <div className="attemptsView" style={{top: !tablet ? 12 : 20}}>
                  <span className="attemptsText" style={{fontSize: ratio * (!tablet ? 12 : 14)}}>
                    {translate('attempts_left')}: {attempts}
                  </span>
                </div>
              )
            }
            {
              hangman && gameOver && (
                <div className="attemptsView" style={{top: !tablet ? 12 : 20}}>
                  <span style={{color: win ? colors.pine : colors.coral, fontSize: ratio * (!tablet ? 12 : 14)}}>
                    {translate(resultText)}
                  </span>
                </div>
              )
            }
          </div>
          {
            !hideButtons && !hangman && !extractor && (

              <ButtonGroup
                style={{
                  opacity: !freeze ? 1 : 0.5,
                  width: 200
                }}
                aria-label="Control buttons">
                <Button
                  disabled={freeze}
                  onClick={() => !freeze ? changeWord('right') : null}
                  variant="outline-info">
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    style={{opacity: trainingId && trainingId == 9 ? 0 : 1, fontSize: !tablet ? 25 : 35}}/>
                </Button>
                <Button
                  disabled={freeze}
                  onClick={() => !freeze ? changeWord('left') : null}
                  variant="outline-info">
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{opacity: trainingId && trainingId == 9 ? 0 : 1, fontSize: !tablet ? 25 : 35}}/>
                </Button>
              </ButtonGroup>
            )
          }

          {
            hangman && (

              <ButtonGroup
                style={{
                  width: 200
                }}
                aria-label="Control buttons">

                <Button
                  disabled={hints === 0 || gameOver}
                  onClick={() => {
                    if(hints > 0 && !gameOver) {
                      onHint()
                    }

                  }}
                  variant="outline-info">
                  <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='top'
                                  overlay={<Tooltip>{translate(hints !== 0 ? 'open_letter' : 'no_more_hints')}</Tooltip>}
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: !tablet ? 25 : 35}}/>
                  </OverlayTrigger>

                </Button>
                <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='top'
                                overlay={<Tooltip>{translate(!gameOver ? 'surrender' : 'refresh_word')}</Tooltip>}
                >
                  <Button
                    disabled={freeze}
                    onClick={() => {
                   if(gameOver) {
                      refresh()
                    } else {
                      surrender()
                    }
                  }}
                    variant="outline-info">

                    <FontAwesomeIcon
                      icon={gameOver ? faRedo : faFlag}
                      style={{fontSize: !tablet ? 25 : 35}}/>
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
            )
          }

          {
            extractor && (
              <ButtonGroup
                style={{
                  width: 250
                }}
                aria-label="Control buttons">

                <Button
                  disabled={typedWord.length === 0}
                  onClick={() => erase()}
                  variant="outline-info"
                >
                  <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='top' overlay={<Tooltip>{translate('erase')}</Tooltip>}>
                    <FontAwesomeIcon icon={faTimes} style={{ fontSize: !tablet ? 25 : 35}}/>
                  </OverlayTrigger>
                </Button>

                <Button
                  disabled={typedWord.length < 3}
                  onClick={() => confirm()}
                  variant="outline-info"
                >
                  <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='top'
                                  overlay={<Tooltip>{translate(typedWord.length < 3 ? 'less_than_3' : 'check')}</Tooltip>}>

                    <FontAwesomeIcon icon={faCheck} style={{fontSize: !tablet ? 25 : 35}}/>
                  </OverlayTrigger>
                </Button>

                <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='top' overlay={<Tooltip>{translate('change_letters')}</Tooltip>}>
                  <Button
                    onClick={() => refresh()}
                    variant="outline-info">
                    <FontAwesomeIcon icon={faRedo} style={{ fontSize: !tablet ? 25 : 35}}/>
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
            )
          }
        </div>
      </div>
    );
  }
}

export default TrainingSubHeader;