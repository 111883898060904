/* GENERAL */

export const ACTIVITY_INDICATOR = 'ACTIVITY_INDICATOR';
export const SET_LOCALE = 'SET_LOCALE';
export const SEND_FORGOT_LINK = 'SEND_FORGOT_LINK';
export const SET_CONNECTION_STATUS = 'SET_CONNECTION_STATUS';
export const GET_ASSETS_SOURCE = 'GET_ASSETS_SOURCE';
export const SET_ASSETS_SOURCE = 'SET_ASSETS_SOURCE';

/* USER */
export const SET_USER_ID = 'SET_USER_ID';
export const SET_USER_DATA = 'SET_USER_DATA';
export const UPDATE_USER_LEVELS = 'UPDATE_USER_LEVELS';
export const UPDATE_USER_LEVEL = 'UPDATE_USER_LEVEL';
export const GET_USER_SCORES = 'GET_USER_SCORES';
export const SET_USER_SCORES = 'SET_USER_SCORES';
export const SET_LIST_SIZE = 'SET_LIST_SIZE';
export const SET_NEW_LIST_SIZE = 'SET_NEW_LIST_SIZE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SET_STUDY_LANGUAGES = 'SET_STUDY_LANGUAGES';
export const GET_STUDY_LANGUAGES = 'GET_STUDY_LANGUAGES';
export const SET_NEW_STUDY_LANGUAGE = 'SET_NEW_STUDY_LANGUAGE';
export const STUDY_LANGUAGE_CHANGE = 'STUDY_LANGUAGE_CHANGE';
export const GET_TRANSLATION_LANGUAGES = 'GET_TRANSLATION_LANGUAGES';
export const SET_TRANSLATION_LANGUAGES = 'SET_TRANSLATION_LANGUAGES';
export const TRANSLATION_LANGUAGE_CHANGE = 'TRANSLATION_LANGUAGE_CHANGE';
export const SET_NEW_INTERFACE_LANGUAGE = 'SET_NEW_INTERFACE_LANGUAGE';
export const TRANSLATION_LANGUAGE_CHANGE_WIZARD = 'TRANSLATION_LANGUAGE_CHANGE_WIZARD';
export const CHANGE_INTERFACE_LANGUAGE = 'CHANGE_INTERFACE_LANGUAGE';
export const UPDATE_INTERFACE_LANGUAGE = 'UPDATE_INTERFACE_LANGUAGE';
export const SET_NEW_TRANSLATION_LANGUAGE = 'SET_NEW_TRANSLATION_LANGUAGE';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_BY_ID = 'GET_USER_PROFILE_BY_ID';
export const CHANGE_USER_PROFILE = 'CHANGE_USER_PROFILE';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPLOAD_AVATAR = 'UPLOAD_AVATAR';
export const SEND_CONTACT_US_MESSAGE = 'SEND_CONTACT_US_MESSAGE';
export const SEND_ERROR_MESSAGE = 'SEND_ERROR_MESSAGE';
export const SYNC_WEB_ACCOUNT_DATA = 'SYNC_WEB_ACCOUNT_DATA';
export const SYNC_WEB_PROGRESS = 'SYNC_WEB_PROGRESS';
export const CHANGE_NOTIFICATION_TIME = 'CHANGE_NOTIFICATION_TIME';
export const CHANGE_NOTIFICATION_STATUS = 'CHANGE_NOTIFICATION_STATUS';
export const GET_INTRODUCTION_SLIDES = 'GET_INTRODUCTION_SLIDES';
export const SET_INTRODUCTION_SLIDES = 'SET_INTRODUCTION_SLIDES';
export const TOGGLE_REVIEW_MODAL = 'TOGGLE_REVIEW_MODAL';
export const GET_USER_DATA = 'GET_USER_DATA';
export const CHANGE_USER_WORD_LEVEL = 'CHANGE_USER_WORD_LEVEL';
export const TOGGLE_SHUFFLE_WORDS_SETTING = 'TOGGLE_SHUFFLE_WORDS_SETTING';
export const UPDATE_SHUFFLE_WORDS = 'UPDATE_SHUFFLE_WORDS';
export const SET_IP_ADDRESS = 'SET_IP_ADDRESS';
export const GET_USER_COUNTRY = 'GET_USER_COUNTRY';
export const SET_USER_COUNTRY = 'SET_USER_COUNTRY';
export const ADD_TO_FRAUDS = 'ADD_TO_FRAUDS';
export const CHANGE_VOCABULARY_LEVEL = 'CHANGE_VOCABULARY_LEVEL';
export const RESET_USER_DATA = 'RESET_USER_DATA';
export const SET_NEW_USER = 'SET_NEW_USER';
export const UPDATE_TRAININGS_SET = 'UPDATE_TRAININGS_SET';
export const ACTIVATE_TRAININGS_SET = 'ACTIVATE_TRAININGS_SET';
export const SET_UPDATED_TRAININGS_SET = 'SET_UPDATED_TRAININGS_SET';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';

/* USER CONTACTS */
export const GET_USER_CONTACTS = 'GET_USER_CONTACTS';
export const DELETE_USER_CONTACT = 'DELETE_USER_CONTACT';
export const UPDATE_USER_CONTACT = 'UPDATE_USER_CONTACT';
export const ADD_USER_CONTACT = 'ADD_USER_CONTACT';
export const SET_USER_CONTACTS = 'SET_USER_CONTACTS';
export const FETCHED_USER_CONTACTS = 'FETCHED_USER_CONTACTS';


/*** SHARE ENTITIES ***/

export const SHARE_LIST = 'SHARE_LIST';
export const SHARE_GLOSSARY = 'SHARE_GLOSSARY';
export const SHARE_WORD = 'SHARE_WORD';
export const GET_SHARED_WORDS = 'GET_SHARED_WORDS';
export const SET_SHARED_WORDS = 'SET_SHARED_WORDS';
export const GET_SHARED_LISTS = 'GET_SHARED_LISTS';
export const SET_SHARED_LISTS = 'SET_SHARED_LISTS';
export const GET_SHARED_GLOSSARIES = 'GET_SHARED_GLOSSARIES';
export const SET_SHARED_GLOSSARIES = 'SET_SHARED_GLOSSARIES';
export const GET_SHARED_NUMBERS = 'GET_SHARED_NUMBERS';
export const SET_SHARED_NUMBERS = 'SET_SHARED_NUMBERS';
export const DELETE_SHARED_WORD = 'DELETE_SHARED_WORD';
export const DELETE_SHARED_LIST = 'DELETE_SHARED_LIST';
export const DELETE_SHARED_GLOSSARY = 'DELETE_SHARED_GLOSSARY';
export const SAVE_SHARED_LIST = 'SAVE_SHARED_LIST';
export const TOGGLE_SHARED_WORD_VOCABULARY = 'TOGGLE_SHARED_WORD_VOCABULARY';
export const TOGGLE_SHARED_WORD_FAVORITE = 'TOGGLE_SHARED_WORD_FAVORITE';


/* LOGIN */

export const LOGIN = 'LOGIN';

/* REGISTER */
export const REGISTER = 'REGISTER';
export const SET_USER_CONFIRMED = 'SET_USER_CONFIRMED';
export const SHOULD_CHECK_VERIFIED = 'SHOULD_CHECK_VERIFIED';
export const VERIFY = 'VERIFY';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const UPDATE_USER_TOKEN = 'UPDATE_USER_TOKEN';
export const CONTACT_ABOUT_REGISTRATION = 'CONTACT_ABOUT_REGISTRATION';
export const RESEND_ACTIVATION_LINK = 'RESEND_ACTIVATION_LINK';

/** ACCOUNTS **/

export const GET_CONNECTED_ACCOUNTS = 'GET_CONNECTED_ACCOUNTS';
export const SET_CONNECTED_ACCOUNTS = 'SET_CONNECTED_ACCOUNTS';
export const SET_ORIGINAL_USER_ID = 'SET_ORIGINAL_USER_ID';
export const DELETE_CONNECTED_ACCOUNT = 'DELETE_CONNECTED_ACCOUNT';
export const CREATE_CHILD_ACCOUNT = 'CREATE_CHILD_ACCOUNT';
export const NEED_PASSWORD_TO_CONFIRM = 'NEED_PASSWORD_TO_CONFIRM';
export const ACCOUNT_CONNECTED = 'ACCOUNT_CONNECTED';
export const CHECK_PASSWORD_TO_CONNECT = 'CHECK_PASSWORD_TO_CONNECT';

/* DICTIONARY */

export const SEARCH_DICTIONARY_WORDS = "SEARCH_DICTIONARY_WORDS";
export const SEARCH_IN_DICTIONARY = "SEARCH_IN_DICTIONARY";
export const SET_FOUND_DICTIONARY_WORDS = "SET_FOUND_DICTIONARY_WORDS";
export const RESET_DICTIONARY_SEARCH_FIELD = "RESET_DICTIONARY_SEARCH_FIELD";
export const FIND_WORD_IN_DICTIONARY = "FIND_WORD_IN_DICTIONARY";
export const SET_FOUND_DICTIONARY_WORD = "SET_FOUND_DICTIONARY_WORD";
export const RESET_FOUND_DICTIONARY_WORD = "RESET_FOUND_DICTIONARY_WORD";

/* WORD */

export const GET_CURRENT_WORD = "GET_CURRENT_WORD";
export const SET_CURRENT_WORD = "SET_CURRENT_WORD";
export const UPDATE_CURRENT_WORD = "UPDATE_CURRENT_WORD";
export const TOGGLE_WORD_VOCABULARY_DETAILS = "TOGGLE_WORD_VOCABULARY_DETAILS";
export const TOGGLE_WORD_FAVORITE_DETAILS = "TOGGLE_WORD_FAVORITE_DETAILS";
export const SET_CURRENT_WORD_DEFINITIONS = "SET_CURRENT_WORD_DEFINITIONS";
export const SET_CURRENT_WORD_EXAMPLES = "SET_CURRENT_WORD_EXAMPLES";
export const SET_CURRENT_WORD_SYNONYMS = "SET_CURRENT_WORD_SYNONYMS";
export const SET_CURRENT_WORD_ANTONYMS = "SET_CURRENT_WORD_ANTONYMS";
export const GET_EXTENDED_INFO = "GET_EXTENDED_INFO";
export const RESET_WORD_DETAILS = "RESET_WORD_DETAILS";
export const ADD_WORD_TRANSLATION = "ADD_WORD_TRANSLATION";
export const ADD_WORD_ASSOCIATION = "ADD_WORD_ASSOCIATION";
export const DELETE_WORD_ASSOCIATION = "DELETE_WORD_ASSOCIATION";
export const DELETE_ASSOCIATION_FROM_WORD = "DELETE_ASSOCIATION_FROM_WORD";
export const ADD_ASSOCIATION_TO_WORD = "ADD_ASSOCIATION_TO_WORD";
export const ADD_ASSOCIATION_TO_CACHED_WORD = "ADD_ASSOCIATION_TO_CACHED_WORD";
export const DELETE_ASSOCIATION_FROM_CACHED_WORD = "DELETE_ASSOCIATION_FROM_CACHED_WORD";
export const ADD_ASSOCIATION_TO_CACHED_COURSE_WORD = "ADD_ASSOCIATION_TO_CACHED_COURSE_WORD";
export const DELETE_ASSOCIATION_FROM_CACHED_COURSE_WORD = "DELETE_ASSOCIATION_FROM_CACHED_COURSE_WORD";
export const ADD_WORD_ASSOCIATION_WORDS_FOR_TRAINING = "ADD_WORD_ASSOCIATION_WORDS_FOR_TRAINING";
export const DELETE_WORD_ASSOCIATION_WORDS_FOR_TRAINING = "DELETE_WORD_ASSOCIATION_WORDS_FOR_TRAINING";
export const CHANGE_MAIN_TRANSLATION = "CHANGE_MAIN_TRANSLATION";
export const DELETE_USER_TRANSLATION = "DELETE_USER_TRANSLATION";
export const SET_FIRST_PLAYER = "SET_FIRST_PLAYER";
export const SET_WORD_TO_SHOW = "SET_WORD_TO_SHOW";
export const GET_WORD_TO_OPEN = "GET_WORD_TO_OPEN";
export const DELETE_WORD_USER_IMAGE = "DELETE_WORD_USER_IMAGE";
export const REMOVE_IMAGE_FROM_CURRENT_WORD = "REMOVE_IMAGE_FROM_CURRENT_WORD";
export const ADD_IMAGE_TO_LISTS = "ADD_IMAGE_TO_LISTS";
export const ADD_IMAGE_TO_LIST_WORDS = "ADD_IMAGE_TO_LIST_WORDS";
export const REMOVE_USER_IMAGE_FROM_LIST_WORDS = "REMOVE_USER_IMAGE_FROM_LIST_WORDS";
export const ADD_USER_IMAGE_TO_CACHED_LIST_WORDS = "ADD_USER_IMAGE_TO_CACHED_LIST_WORDS";
export const REMOVE_USER_IMAGE_TO_CACHED_LIST_WORDS = "REMOVE_USER_IMAGE_TO_CACHED_LIST_WORDS";
export const ADD_CACHED_TRAINING_WORD_IMAGE = "ADD_CACHED_TRAINING_WORD_IMAGE";
export const ADD_WORD_IMAGE_FOR_TRAINING = "ADD_WORD_IMAGE_FOR_TRAINING";
export const REMOVE_USER_IMAGE_FROM_CACHED_TRAINING_WORDS = "REMOVE_USER_IMAGE_FROM_CACHED_TRAINING_WORDS";
export const REMOVE_USER_IMAGE_FROM_TRAINING_WORDS = "REMOVE_USER_IMAGE_FROM_TRAINING_WORDS";
export const ADD_USER_EXAMPLE = "ADD_USER_EXAMPLE";


/** DATA **/
export const FETCHING_DATA = "FETCHING_DATA";

/* GLOSSARIES */

export const GET_PARENT_GLOSSARIES = 'GET_PARENT_GLOSSARIES';
export const SET_PARENT_GLOSSARIES = 'SET_PARENT_GLOSSARIES';
export const SET_CURRENT_PARENT = 'SET_CURRENT_PARENT';

export const SET_PARENT_CHILDREN = 'SET_PARENT_CHILDREN';
export const GET_PARENT_CHILDREN = 'GET_PARENT_CHILDREN';
export const SET_CURRENT_CHILD = 'SET_CURRENT_CHILD';
export const SET_GLOSSARY_WORDS = 'SET_GLOSSARY_WORDS';
export const GET_GLOSSARY_WORDS_NEXT_CHUNK = 'GET_GLOSSARY_WORDS_NEXT_CHUNK';

export const GET_GLOSSARY_WORDS = 'GET_GLOSSARY_WORDS';
export const RESET_GLOSSARY_WORDS = 'RESET_GLOSSARY_WORDS';
export const TOGGLE_GLOSSARY_WORD_VOCABULARY = 'TOGGLE_GLOSSARY_WORD_VOCABULARY';
export const TOGGLE_GLOSSARY_WORD_FAVORITES = 'TOGGLE_GLOSSARY_WORD_FAVORITES';
export const GLOSSARY_WORD_TOGGLED_IN_VOCABULARY = 'GLOSSARY_WORD_TOGGLED_IN_VOCABULARY';
export const GLOSSARY_WORD_TOGGLED_IN_FAVORITES = 'GLOSSARY_WORD_TOGGLED_IN_FAVORITES';
export const GLOSSARY_TOGGLED_IN_FAVORITES = 'GLOSSARY_TOGGLED_IN_FAVORITES';
export const TOGGLE_GLOSSARY_IN_FAVORITES = 'TOGGLE_GLOSSARY_IN_FAVORITES';

export const GET_GLOSSARIES_FOR_LIST = 'GET_GLOSSARIES_FOR_LIST';
export const SET_GLOSSARIES_FOR_LIST = 'SET_GLOSSARIES_FOR_LIST';
export const GET_GLOSSARY_WORDS_FOR_LIST = 'GET_GLOSSARY_WORDS_FOR_LIST';
export const SEARCH_GLOSSARY_WORDS_FOR_LIST = 'SEARCH_GLOSSARY_WORDS_FOR_LIST';
export const SET_FOUND_GLOSSARY_WORDS_FOR_LIST = 'SET_FOUND_GLOSSARY_WORDS_FOR_LIST';
export const SET_GLOSSARY_WORDS_FOR_LIST = 'SET_GLOSSARY_WORDS_FOR_LIST';
export const TOGGLE_GLOSSARY_WORD_IN_LIST = 'TOGGLE_GLOSSARY_WORD_IN_LIST';
export const TOGGLED_GLOSSARY_WORD_IN_LIST = 'TOGGLED_GLOSSARY_WORD_IN_LIST';
export const TOGGLED_GLOSSARY_WORD_IN_LIST_ = 'TOGGLED_GLOSSARY_WORD_IN_LIST_';
export const UPDATE_CURRENT_LIST_WORDS = 'UPDATE_CURRENT_LIST_WORDS';
export const UPDATE_CURRENT_LIST_ONE_WORDS = 'UPDATE_CURRENT_LIST_ONE_WORDS';
export const SEND_CARDS = 'SEND_CARDS';
export const SEND_LIST_CARDS = 'SEND_LIST_CARDS';
export const SEARCH_GLOSSARY_WORDS = 'SEARCH_GLOSSARY_WORDS';
export const SET_FOUND_GLOSSARY_WORDS = 'SET_FOUND_GLOSSARY_WORDS';

export const RESET_PARENT_GLOSSARIES = 'RESET_PARENT_GLOSSARIES';
export const SEARCH_GLOSSARIES = 'SEARCH_GLOSSARIES';
export const RESET_CHILD_GLOSSARIES = 'RESET_CHILD_GLOSSARIES';
export const SEARCH_CHILD_GLOSSARIES = 'SEARCH_CHILD_GLOSSARIES';
export const DELETE_SELECTED_LISTS = 'DELETE_SELECTED_LISTS';
export const RESET_CURRENT_CHILD_GLOSSARY = 'RESET_CURRENT_CHILD_GLOSSARY';
export const RESET_GLOSSARY_WORDS_WITHOUT_PAGES = 'RESET_GLOSSARY_WORDS_WITHOUT_PAGES';
export const CLEAN_GLOSSARIES_CACHE = 'CLEAN_GLOSSARIES_CACHE';

/* LISTS */

export const POSTPONE_IN_CALENDAR = 'POSTPONE_IN_CALENDAR';
export const REMOVE_FROM_CALENDAR = 'REMOVE_FROM_CALENDAR';

export const GET_USER_LISTS = 'GET_USER_LISTS';
export const SET_CURRENT_LIST = 'SET_CURRENT_LIST';
export const GET_CURRENT_LIST_DATA = 'GET_CURRENT_LIST_DATA';
export const CHANGE_LIST_VIEW_TYPE = 'CHANGE_LIST_VIEW_TYPE';
export const SET_USER_LISTS = 'SET_USER_LISTS';
export const RESET_USER_LISTS = 'RESET_USER_LISTS';
export const RESET_CURRENT_LIST = 'RESET_CURRENT_LIST';
export const UPDATE_CURRENT_LIST_TRAININGS = 'UPDATE_CURRENT_LIST_TRAININGS';

export const GET_LIST_WORDS = 'GET_LIST_WORDS';
export const RESET_LIST_WORDS = 'RESET_LIST_WORDS';
export const GET_LISTS_TO_FILL = 'GET_LISTS_TO_FILL';
export const GET_LISTS_TO_FILL_SHARE = 'GET_LISTS_TO_FILL_SHARE';
export const SET_USER_LISTS_TO_FILL = 'SET_USER_LISTS_TO_FILL';
export const FILL_LISTS_WITH_GLOSSARY_WORDS = 'FILL_LISTS_WITH_GLOSSARY_WORDS';
export const GET_CURRENT_GLOSSARY_DATA = 'GET_CURRENT_GLOSSARY_DATA';
export const SET_CURRENT_GLOSSARY_DATA = 'SET_CURRENT_GLOSSARY_DATA';
export const LISTS_FILLED_WITH_GLOSSARY_WORDS = 'LISTS_FILLED_WITH_GLOSSARY_WORDS';
export const SET_LIST_WORDS = 'SET_LIST_WORDS';
export const DELETE_LIST_WORD = 'DELETE_LIST_WORD';
export const LIST_WORD_DELETED = 'LIST_WORD_DELETED';
export const LIST_WORD_DELETED_BY_ID = 'LIST_WORD_DELETED_BY_ID';
export const MOVE_TO_OTHER_LISTS = 'MOVE_TO_OTHER_LISTS';
export const UPDATE_CURRENT_LIST_WORDS_DATA = 'UPDATE_CURRENT_LIST_WORDS_DATA';
export const TOGGLE_LIST_WORD_FAVORITE = 'TOGGLE_LIST_WORD_FAVORITE';
export const TOGGLE_LIST_WORD_VOCABULARY = 'TOGGLE_LIST_WORD_VOCABULARY';
export const WORD_TOGGLED_IN_VOCABULARY = 'WORD_TOGGLED_IN_VOCABULARY';
export const WORD_TOGGLED_IN_FAVORITE = 'WORD_TOGGLED_IN_FAVORITE';
export const UPDATE_WORD_FAVORITE_STATUS = 'UPDATE_WORD_FAVORITE_STATUS';
export const TOGGLE_LIST_FAVORITE = 'TOGGLE_LIST_FAVORITE';
export const LIST_TOGGLED_IN_FAVORITE = 'LIST_TOGGLED_IN_FAVORITE';
export const ADD_WORD_TO_LIST_FROM_DICTIONARY = 'ADD_WORD_TO_LIST_FROM_DICTIONARY';
export const ADD_WORD_TO_CURRENT_LIST = 'ADD_WORD_TO_CURRENT_LIST';
export const ADD_NEW_LIST = 'ADD_NEW_LIST';
export const ADD_NEW_USER_LIST = 'ADD_NEW_USER_LIST';
export const APPEND_NEW_LIST = 'APPEND_NEW_LIST';
export const EDIT_LIST_NAME = 'EDIT_LIST_NAME';
export const DELETE_LIST = 'DELETE_LIST';
export const DELETE_LIST_FROM_LIST = 'DELETE_LIST_FROM_LIST';
export const UPDATE_CACHED_FAVORITE_LIST = 'UPDATE_CACHED_FAVORITE_LIST';
export const UPDATE_CURRENT_LIST_FAVORITE = 'UPDATE_CURRENT_LIST_FAVORITE';
export const SEARCH_USER_LISTS = 'SEARCH_USER_LISTS';
export const TOGGLE_ARCHIVE_LIST = 'TOGGLE_ARCHIVE_LIST';
export const REMOVE_ARCHIVED_LIST = 'REMOVE_ARCHIVED_LIST';
export const REMOVE_ARCHIVED_CACHED_LIST = 'REMOVE_ARCHIVED_CACHED_LIST';
export const UPDATE_CURRENT_LIST_ARCHIVE_STATUS = 'UPDATE_CURRENT_LIST_ARCHIVE_STATUS';
export const UPDATE_HOME_LISTS_ARCHIVE = 'UPDATE_HOME_LISTS_ARCHIVE';
export const TOGGLE_SHOW_ARCHIVED_LIST = 'TOGGLE_SHOW_ARCHIVED_LIST';
export const TOGGLE_SHOW_ARCHIVED_USER_LISTS = 'TOGGLE_SHOW_ARCHIVED_USER_LISTS';
export const UPDATE_SHOW_ARCHIVE_USER_DATA = 'UPDATE_SHOW_ARCHIVE_USER_DATA';
export const SAVE_SCANNED_TEXT = 'SAVE_SCANNED_TEXT';
export const CLEAR_SCANNED_TEXT = 'CLEAR_SCANNED_TEXT';


export const GET_LISTS_TO_REPEAT = 'GET_LISTS_TO_REPEAT';
export const SET_LISTS_TO_REPEAT = 'SET_LISTS_TO_REPEAT';
export const RESET_LISTS_TO_REPEAT = 'RESET_LISTS_TO_REPEAT';
export const GET_LISTS_NUMBER = 'GET_LISTS_NUMBER';
export const SET_LISTS_NUMBER = 'SET_LISTS_NUMBER';
export const GENERATE_LISTS_NOT_WIZARD = 'GENERATE_LISTS_NOT_WIZARD';
export const GENERATE_BY_LISTS_NOT_WIZARD = 'GENERATE_BY_LISTS_NOT_WIZARD';
export const ADD_USER_WORDS = 'ADD_USER_WORDS';
export const SET_NEWLY_CREATED_LISTS = 'SET_NEWLY_CREATED_LISTS';
export const SET_SHOW_NEWLY_CREATED_LISTS = 'SET_SHOW_NEWLY_CREATED_LISTS';
export const SET_SHOW_LIST_TRAINING_BUTTONS_MODAL = 'SET_SHOW_LIST_TRAINING_BUTTONS_MODAL';
export const UPDATE_LIST_NAME = 'UPDATE_LIST_NAME';
export const DELETE_FROM_USER_LISTS = 'DELETE_FROM_USER_LISTS';
export const REMOVE_REPETITIONS_FROM_LIST = 'REMOVE_REPETITIONS_FROM_LIST';
export const DELETE_LISTS_FROM_USER_LISTS = 'DELETE_LISTS_FROM_USER_LISTS';
export const INSERT_NEW_LIST = 'INSERT_NEW_LIST';
export const UPDATE_LIST_INTERVALS = 'UPDATE_LIST_INTERVALS';
export const TOGGLED_WORD_IN_CURRENT_LIST = 'TOGGLED_WORD_IN_CURRENT_LIST';
export const TOGGLED_WORDS_NUMBER_IN_CURRENT_LIST = 'TOGGLED_WORDS_NUMBER_IN_CURRENT_LIST';
export const GET_ONLY_MISTAKES = 'GET_ONLY_MISTAKES';
export const SET_ONLY_MISTAKES = 'SET_ONLY_MISTAKES';
export const TOGGLE_AUTO_POSTPONE = 'TOGGLE_AUTO_POSTPONE';
export const TOGGLE_COURSE_AUTO_POSTPONE = 'TOGGLE_COURSE_AUTO_POSTPONE';
export const UPDATE_AUTO_POSTPONE = 'UPDATE_AUTO_POSTPONE';
export const UPDATE_COURSE_AUTO_POSTPONE = 'UPDATE_COURSE_AUTO_POSTPONE';
export const PERFORM_BULK_ACTION = 'PERFORM_BULK_ACTION';

/*** SYNC ***/
export const SYN_USER_LISTS = 'SYN_USER_LISTS';

/* TRAININGS */

export const GET_TRAINING_WORDS = 'GET_TRAINING_WORDS';
export const SET_WORDS_FOR_TRAINING = 'SET_WORDS_FOR_TRAINING';
export const UPDATE_WORD_TRANSLATIONS_FOR_TRAINING = 'UPDATE_WORD_TRANSLATIONS_FOR_TRAINING';
export const RESET_TRAINING_DATA = 'RESET_TRAINING_DATA';
export const SET_WORDS_FOR_TRAINING_MEMORY_CARDS = 'SET_WORDS_FOR_TRAINING_MEMORY_CARDS';
export const RESET_TRAINING_WORDS = 'RESET_TRAINING_WORDS';
export const TOGGLE_SHOW_MEMORY_CARDS = 'TOGGLE_SHOW_MEMORY_CARDS';
export const SET_WORDS_FOR_TRAINING_COLLECT_SENTENCE = 'SET_WORDS_FOR_TRAINING_COLLECT_SENTENCE';
export const GET_GOOGLE_EXAMPLE_TRANSLATION = 'GET_GOOGLE_EXAMPLE_TRANSLATION';
export const SET_NEW_GOOGLE_EXAMPLE_TRANSLATION = 'SET_NEW_GOOGLE_EXAMPLE_TRANSLATION';

export const SET_PREVIEW_SETTINGS = 'SET_PREVIEW_SETTINGS';
export const SET_DRAG_IMAGE_SETTINGS = 'SET_DRAG_IMAGE_SETTINGS';
export const SET_SELECT_TRANSLATION_SETTINGS = 'SET_SELECT_TRANSLATION_SETTINGS';
export const SET_SELECT_WORD_SETTINGS = 'SET_SELECT_WORD_SETTINGS';
export const SET_COLLECT_WORD_SETTINGS = 'SET_COLLECT_WORD_SETTINGS';
export const SET_COLLECT_SENTENCE_SETTINGS = 'SET_COLLECT_SENTENCE_SETTINGS';
export const SET_TRANSLATE_SENTENCE_SETTINGS = 'SET_TRANSLATE_SENTENCE_SETTINGS';
export const SET_MEMORY_CARDS_SETTINGS = 'SET_MEMORY_CARDS_SETTINGS';
export const SET_SPELLING_SETTINGS = 'SET_SPELLING_SETTINGS';
export const SET_DEFINITION_SETTINGS = 'SET_DEFINITION_SETTINGS';
export const SET_MISSING_LETTERS_SETTINGS = 'SET_MISSING_LETTERS_SETTINGS';
export const SET_LISTENING_SETTINGS = 'SET_LISTENING_SETTINGS';
export const SET_CURRENT_TRAINING_ID = 'SET_CURRENT_TRAINING_ID';
export const RESET_CURRENT_TRAINING_ID = 'RESET_CURRENT_TRAINING_ID';
export const SET_HIDE_FROM_KNOWN = 'SET_HIDE_FROM_KNOWN';
export const SET_HIDE_FROM_KNOWN_COURSE = 'SET_HIDE_FROM_KNOWN_COURSE';
export const CLEAN_TRAININGS_CACHE = 'CLEAN_TRAININGS_CACHE';
export const TOGGLE_SHOW_LEARNT_WORDS = 'TOGGLE_SHOW_LEARNT_WORDS';
export const TOGGLE_SHOW_LEARNT_WORDS_COURSE = 'TOGGLE_SHOW_LEARNT_WORDS_COURSE';
export const UPDATE_SHOW_LEARN_USER_DATA = 'UPDATE_SHOW_LEARN_USER_DATA';
export const UPDATE_SHOW_LEARN_COURSE_USER_DATA = 'UPDATE_SHOW_LEARN_COURSE_USER_DATA';
export const MOVE_TO_GENERAL_VOCABULARY = 'MOVE_TO_GENERAL_VOCABULARY';


/* VOCABULARY TEST */

export const GET_TEST_WORD = "GET_TEST_WORD";
export const SET_TEST_WORD = "SET_TEST_WORD";
export const LOG_TEST_WORD = "LOG_TEST_WORD";
export const GET_TEST_RESULTS = "GET_TEST_RESULTS";
export const SET_TEST_RESULTS = "SET_TEST_RESULTS";
export const GENERATE_LISTS = "GENERATE_LISTS";
export const SAVE_WORDS_AFTER_TEST = "SAVE_WORDS_AFTER_TEST";


/** GAMES **/
export const CHECK_WORD_EXTRACTOR_WORD = "CHECK_WORD_EXTRACTOR_WORD";
export const ADD_EXTRACTOR_WORD = "ADD_EXTRACTOR_WORD";
export const RESET_EXTRACTOR_WORDS = "RESET_EXTRACTOR_WORDS";
export const GET_WORD_EXTRACTOR_RESULTS = "GET_WORD_EXTRACTOR_RESULTS";
export const SET_WORD_EXTRACTOR_RESULTS = "SET_WORD_EXTRACTOR_RESULTS";
export const GET_HANGMAN_SCORES = "GET_HANGMAN_SCORES";
export const SET_HANGMAN_SCORES = "SET_HANGMAN_SCORES";
export const GET_HANGMAN_WORD = "GET_HANGMAN_WORD";
export const SET_HANGMAN_WORD = "SET_HANGMAN_WORD";
export const LOG_HANGMAN_WORD = "LOG_HANGMAN_WORD";


/* VOCABULARY */

export const GET_VOCABULARY = 'GET_VOCABULARY';
export const FOUND_VOCABULARY_WORDS = 'FOUND_VOCABULARY_WORDS';
export const SET_VOCABULARY_WORDS = 'SET_VOCABULARY_WORDS';
export const SET_GOAL = 'SET_GOAL';
export const SET_USER_GOAL = 'SET_USER_GOAL';
export const DELETE_FROM_VOCABULARY = 'DELETE_FROM_VOCABULARY';
export const RESET_VOCABULARY = 'RESET_VOCABULARY';
export const VOCABULARY_WORD_DELETED = 'VOCABULARY_WORD_DELETED';
export const TOGGLE_VOCABULARY_WORD_FAVOFITES = 'TOGGLE_VOCABULARY_WORD_FAVOFITES';
export const VOCABULARY_WORD_TOGGLED_IN_FAVORITES = 'VOCABULARY_WORD_TOGGLED_IN_FAVORITES';
export const GET_VOCABULARY_WORDS_NEXT_CHUNK = 'GET_VOCABULARY_WORDS_NEXT_CHUNK';
export const VOCABULARY_WORDS_TOGGLED_IN_FAVORITES = 'VOCABULARY_WORDS_TOGGLED_IN_FAVORITES';


/* PHRASEBOOKS */

export const SET_PHRASEBOOKS = 'SET_PHRASEBOOKS';
export const SET_PHRASEBOOK_PHRASES = 'SET_PHRASEBOOK_PHRASES';
export const SET_CURRENT_PHRASEBOOK = 'SET_CURRENT_PHRASEBOOK';
export const GET_PHRASEBOOKS = 'GET_PHRASEBOOKS';
export const GET_PHRASEBOOK_PHRASES = 'GET_PHRASEBOOK_PHRASES';
export const RESET_PHRASES = 'RESET_PHRASES';
export const SEARCH_PHRASE = 'SEARCH_PHRASE';
export const RESET_FOUND_PHRASES = 'RESET_FOUND_PHRASES';
export const SET_FOUND_PHRASES = 'SET_FOUND_PHRASES';


/* FAVORITE */

export const GET_FAVORITE_LISTS = 'GET_FAVORITE_LISTS';
export const SET_FAVORITE_LISTS = 'SET_FAVORITE_LISTS';
export const GET_FAVORITE_GLOSSARIES = 'GET_FAVORITE_GLOSSARIES';
export const SET_FAVORITE_GLOSSARIES = 'SET_FAVORITE_GLOSSARIES';
export const GET_FAVORITE_WORDS = 'GET_FAVORITE_WORDS';
export const SET_FAVORITE_WORDS = 'SET_FAVORITE_WORDS';
export const SET_FAVORITE_WORDS_WITH_PAGES = 'SET_FAVORITE_WORDS_WITH_PAGES';
export const GET_FAVORITE_NUMBERS = 'GET_FAVORITE_NUMBERS';
export const SET_FAVORITE_NUMBERS = 'SET_FAVORITE_NUMBERS';
export const DELETE_FAVORITE_WORD = 'DELETE_FAVORITE_WORD';
export const TOGGLE_FAVORITE_PHRASE = 'TOGGLE_FAVORITE_PHRASE';
export const UPDATE_FAVORITE_PHRASE = 'UPDATE_FAVORITE_PHRASE';
export const GET_FAVORITE_PHRASES = 'GET_FAVORITE_PHRASES';
export const DELETE_FAVORITE_PHRASE = 'DELETE_FAVORITE_PHRASE';
export const DELETE_FAVORITE_PHRASE_FROM_PHRASES = 'DELETE_FAVORITE_PHRASE_FROM_PHRASES';
export const SET_FAVORITE_PHRASES_WITH_PAGES = 'SET_FAVORITE_PHRASES_WITH_PAGES';
export const UPDATE_VOCABULARY_IN_FAVORITE_WORDS = 'UPDATE_VOCABULARY_IN_FAVORITE_WORDS';

/***** LOGS *****/

export const LOG_SHARED_LINK_VISIT = 'LOG_SHARED_LINK_VISIT';
export const LOG_LIST_WORD = 'LOG_LIST_WORD';
export const LOG_USER_LIST = 'LOG_USER_LIST';
export const LOG_PHRASE = 'LOG_PHRASE';
export const LOG_GLOSSARY_WORD = 'LOG_GLOSSARY_WORD';
export const LOG_SCORES = 'LOG_SCORES';
export const LOG_ACTIVITY = 'LOG_ACTIVITY';
export const LOG_PURCHASE = 'LOG_PURCHASE';
export const LOG_API_ERROR = 'LOG_API_ERROR';
export const LOG_LINKS_PAGE = 'LOG_LINKS_PAGE';

/***** PURCHASES *****/

export const SET_PURCHASES = 'SET_PURCHASES';
export const SET_CARDS_PURCHASE = 'SET_CARDS_PURCHASE';
export const SET_SYNC_PURCHASE = 'SET_SYNC_PURCHASE';
export const SET_REGISTRATION_DATE = 'SET_REGISTRATION_DATE';
export const GET_REGISTRATION_DATE = 'GET_REGISTRATION_DATE';
export const GET_USER_LIMITS = 'GET_USER_LIMITS';
export const SET_USER_LIMITS = 'SET_USER_LIMITS';
export const SET_WORD_LIMIT = 'SET_WORD_LIMIT';
export const SET_TRAINING_LIMIT = 'SET_TRAINING_LIMIT';
export const LOG_TRAINING_LIMIT = 'LOG_TRAINING_LIMIT';
export const LOG_WORD_LIMIT = 'LOG_WORD_LIMIT';
export const SET_GAMES_LIMIT = 'SET_GAMES_LIMIT';
export const LOG_GAMES_LIMIT = 'LOG_GAMES_LIMIT';
export const EXCHANGE_SCORES = 'EXCHANGE_SCORES';
export const UPDATE_EXCHANGED_SCORES = 'UPDATE_EXCHANGED_SCORES';

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
export const GET_PURCHASE_DATA = 'GET_PURCHASE_DATA';
export const GET_LIQPAY_SUBSCRIPTION_STATUS = 'GET_LIQPAY_SUBSCRIPTION_STATUS';
export const SET_LIQPAY_SUBSCRIPTION_STATUS = 'SET_LIQPAY_SUBSCRIPTION_STATUS';
export const SET_PURCHASE_DATA = 'SET_PURCHASE_DATA';

export const SET_SUBSCRIPTION_STATUS = 'SET_SUBSCRIPTION_STATUS';
export const SET_CARDS_STATUS = 'SET_CARDS_STATUS';
export const SET_LIST_CARDS_STATUS = 'SET_LIST_CARDS_STATUS';
export const SET_LIST_CARDS_PURCHASE_DATA = 'SET_LIST_CARDS_PURCHASE_DATA';
export const SET_SYNCS_STATUS = 'SET_SYNCS_STATUS';
export const SET_WEB_SUBSCRIPTION_STATUS = 'SET_WEB_SUBSCRIPTION_STATUS';
export const GET_WEB_SUBSCRIPTION_STATUS = 'GET_WEB_SUBSCRIPTION_STATUS';
export const SET_CARDS_PURCHASE_DATA = 'SET_CARDS_PURCHASE_DATA';
export const SET_ACTIVE_SUBSCRIPTION_DATA = 'SET_ACTIVE_SUBSCRIPTION_DATA';
export const USE_PROMO_CODE = 'USE_PROMO_CODE';
export const SET_LIFETIME_ACCESS = 'SET_LIFETIME_ACCESS';
export const STORE_PURCHASES_FETCHED = 'STORE_PURCHASES_FETCHED';
export const SET_HAPPY_DAY = 'SET_HAPPY_DAY';
export const CHECK_HAPPY_DAY = 'CHECK_HAPPY_DAY';
export const GET_USER_PURCHASES = 'GET_USER_PURCHASES';
export const SET_USER_PURCHASES = 'SET_USER_PURCHASES';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const SET_DISCOUNT_CODE = 'SET_DISCOUNT_CODE';
export const CHECK_DISCOUNT_CODE = 'CHECK_DISCOUNT_CODE';
export const GET_EXCHANGE_RATE = 'GET_EXCHANGE_RATE';
export const SET_CURRENCY_RATES = 'SET_CURRENCY_RATES';






export const CHANGE_AUDIO_LANGUAGE = 'CHANGE_AUDIO_LANGUAGE';


/***** CALENDAR *****/
export const GET_CALENDAR_MONTH_DATA = 'GET_CALENDAR_MONTH_DATA';
export const SET_CALENDAR_MONTH_DATA = 'SET_CALENDAR_MONTH_DATA';
export const GET_CALENDAR_DAY_DATA = 'GET_CALENDAR_DAY_DATA';
export const SET_CALENDAR_DAY_DATA = 'SET_CALENDAR_DAY_DATA';

/***** SETTINGS *****/
export const SET_FONT_SIZE = 'SET_FONT_SIZE';
export const SET_WINDOW_DIMENSIONS = 'SET_WINDOW_DIMENSIONS';
export const SET_DEVICE_VERSION = 'SET_DEVICE_VERSION';

/***** BADGES *****/
export const GET_USER_BADGES = 'GET_USER_BADGES';
export const SET_USER_BADGES = 'SET_USER_BADGES';
export const RESET_BADGES = 'RESET_BADGES';


/***** COURSE *****/
export const SET_COURSE_STATUS = 'SET_COURSE_STATUS';
export const COURSE_OPTION_SELECTED = 'COURSE_OPTION_SELECTED';
export const GET_COURSE_DATA = 'GET_COURSE_DATA';
export const SET_COURSE_DATA = 'SET_COURSE_DATA';
export const SET_ONLY_COURSE_DATA = 'SET_ONLY_COURSE_DATA';
export const SET_COURSE_LIST = 'SET_COURSE_LIST';
export const SET_COURSE_DATA_FETCHED = 'SET_COURSE_DATA_FETCHED';
export const GENERATE_COURSE_LISTS = 'GENERATE_COURSE_LISTS';
export const GET_COURSE_LIST_WORDS = 'GET_COURSE_LIST_WORDS';
export const ADD_COURSE_WORD_TO_LIST = 'ADD_COURSE_WORD_TO_LIST';
export const SET_COURSE_LIST_WORDS = 'SET_COURSE_LIST_WORDS';
export const RESET_COURSE_LIST_WORDS = 'RESET_COURSE_LIST_WORDS';
export const LOG_COURSE_LIST = 'LOG_COURSE_LIST';
export const LOG_COURSE_LIST_WORD = 'LOG_COURSE_LIST_WORD';
export const GET_COURSE_LIST_DATA = 'GET_COURSE_LIST_DATA';
export const SET_COURSE_LIST_DATA = 'SET_COURSE_LIST_DATA';
export const GET_COURSE_LIST_CALENDAR = 'GET_COURSE_LIST_CALENDAR';
export const SET_COURSE_LIST_CALENDAR = 'SET_COURSE_LIST_CALENDAR';
export const RESET_COURSE_LIST_DATA = 'RESET_COURSE_LIST_DATA';
export const RESET_COURSE_DATA = 'RESET_COURSE_DATA';
export const SET_NAVIGATE_TO_COURSE = 'SET_NAVIGATE_TO_COURSE';
export const DELETE_COURSE_DATA = 'DELETE_COURSE_DATA';
export const GET_COURSE_CALENDAR_MONTH_DATA = 'GET_COURSE_CALENDAR_MONTH_DATA';
export const SET_COURSE_CALENDAR_MONTH_DATA = 'SET_COURSE_CALENDAR_MONTH_DATA';
export const GET_COURSE_STATISTICS = 'GET_COURSE_STATISTICS';
export const SET_COURSE_STATISTICS = 'SET_COURSE_STATISTICS';
export const REMOVE_COURSE_LIST_FROM_CALENDAR = 'REMOVE_COURSE_LIST_FROM_CALENDAR';
export const GET_COURSE_VOCABULARY = 'GET_COURSE_VOCABULARY';
export const COURSE_VOCABULARY_BULK_ACTION = 'COURSE_VOCABULARY_BULK_ACTION';
export const SET_COURSE_VOCABULARY = 'SET_COURSE_VOCABULARY';
export const RESET_COURSE_VOCABULARY = 'RESET_COURSE_VOCABULARY';
export const GET_COURSE_VOCABULARY_NEXT_CHUNK = 'GET_COURSE_VOCABULARY_NEXT_CHUNK';
export const NAVIGATE_TO_SEGUE = 'NAVIGATE_TO_SEGUE';

export const SET_SHOW_WORD_SENTENCE = 'SET_SHOW_WORD_SENTENCE';
export const SET_TIME_TO_GO_TO_COURSE = 'SET_TIME_TO_GO_TO_COURSE';
export const CHECK_COURSE_DEBTS = 'CHECK_COURSE_DEBTS';
export const RATE_COURSE = 'RATE_COURSE';
export const SET_COURSE_RATE = 'SET_COURSE_RATE';
export const RESET_COURSE_LIST_STATISTICS = 'RESET_COURSE_LIST_STATISTICS';
export const SET_COURSE_LIST_TYPE = 'SET_COURSE_LIST_TYPE';
export const GET_ALL_COURSE_MISTAKES = 'GET_ALL_COURSE_MISTAKES';
export const SET_ALL_COURSE_MISTAKES = 'SET_ALL_COURSE_MISTAKES';
export const RESET_CURRENT_COURSE_LIST = 'RESET_CURRENT_COURSE_LIST';
export const TOGGLE_IN_COURSE_VOCABULARY = 'TOGGLE_IN_COURSE_VOCABULARY';
export const UPDATE_IN_COURSE_VOCABULARY = 'UPDATE_IN_COURSE_VOCABULARY';
export const DELETE_WORD_FROM_COURSE_LIST_WORDS = 'DELETE_WORD_FROM_COURSE_LIST_WORDS';
export const DELETE_COURSE_WORD = 'DELETE_COURSE_WORD';
export const DELETE_COURSE_WORD_FROM_LIST = 'DELETE_COURSE_WORD_FROM_LIST';
export const DELETE_COURSE_WORD_FROM_TRAININGS = 'DELETE_COURSE_WORD_FROM_TRAININGS';


/****** QUIZZES *****/

export const SET_IRREGULAR_VERBS = 'SET_IRREGULAR_VERBS';
export const GET_IRREGULAR_VERBS = 'GET_IRREGULAR_VERBS';
export const RESET_IRREGULAR_VERBS = 'RESET_IRREGULAR_VERBS';
export const LOG_IRREGULAR_VERB = 'LOG_IRREGULAR_VERB';

/***** WORD MAKER *****/
export const GET_WORD_MAKER_WORDS = 'GET_WORD_MAKER_WORDS';
export const SET_WORD_MAKER_WORDS = 'SET_WORD_MAKER_WORDS';
export const RESET_WORD_MAKER_WORDS = 'RESET_WORD_MAKER_WORDS';
export const LOG_WORD_MAKER_PART = 'LOG_WORD_MAKER_PART';
export const SET_CURRENT_WORD_MAKER_WORD = 'SET_CURRENT_WORD_MAKER_WORD';
export const SET_FETCHING_WORD_MAKER_WORDS = 'SET_FETCHING_WORD_MAKER_WORDS';
export const GET_WORD_MAKER_PARTS = 'GET_WORD_MAKER_PARTS';
export const SET_WORD_MAKER_PARTS = 'SET_WORD_MAKER_PARTS';
export const RESET_WORD_MAKER_PARTS = 'RESET_WORD_MAKER_PARTS';
export const SET_WORD_MAKER_LEVEL_SCORE = 'SET_WORD_MAKER_LEVEL_SCORE';
export const LOG_BUY_WORD_MAKER_WORD = 'LOG_BUY_WORD_MAKER_WORD';
export const BUY_WORD_MAKER_LEVEL = 'BUY_WORD_MAKER_LEVEL';
export const SET_FREE_HINTS = 'SET_FREE_HINTS';
export const ADD_WORD_MAKER_HINTS = 'ADD_WORD_MAKER_HINTS';
export const SUBTRACT_FROM_FREE_HINTS = 'SUBTRACT_FROM_FREE_HINTS';
export const BUY_WORD_MAKER_HINTS = 'BUY_WORD_MAKER_HINTS';
export const SET_WORD_MAKER_DATA = 'SET_WORD_MAKER_DATA';
export const GET_WORD_MAKER_DATA = 'GET_WORD_MAKER_DATA';


/***** CACHE *****/
export const SET_CACHED_LISTS = 'SET_CACHED_LISTS';
export const UPDATE_CACHED_LISTS = 'UPDATE_CACHED_LISTS';
export const SET_CACHED_LISTS_STATUS = 'SET_CACHED_LISTS_STATUS';
export const SET_USER_LISTS_FROM_CACHE = 'SET_USER_LISTS_FROM_CACHE';
export const UPDATE_CACHED_LIST_NAME = 'UPDATE_CACHED_LIST_NAME';
export const DELETE_FROM_CACHED_USER_LISTS = 'DELETE_FROM_CACHED_USER_LISTS';
export const REMOVE_REPETITIONS_FROM_CACHED_LIST = 'REMOVE_REPETITIONS_FROM_CACHED_LIST';
export const DELETE_LISTS_FROM_CACHED_USER_LISTS = 'DELETE_LISTS_FROM_CACHED_USER_LISTS';
export const INSERT_NEW_CACHED_LIST = 'INSERT_NEW_CACHED_LIST';
export const CACHED_LIST_WORD_DELETED = 'CACHED_LIST_WORD_DELETED';
export const UPDATE_CACHED_LIST_INTERVALS = 'UPDATE_CACHED_LIST_INTERVALS';
export const SET_VISITED_CACHED_LISTS = 'SET_VISITED_CACHED_LISTS';
export const RESET_VISITED_CACHED_LISTS = 'RESET_VISITED_CACHED_LISTS';
export const CACHED_LIST_WORD_DELETED_FROM_LISTS = 'CACHED_LIST_WORD_DELETED_FROM_LISTS';
export const SET_CACHED_HOME_LISTS_STATUS = 'SET_CACHED_HOME_LISTS_STATUS';
export const SET_CACHED_HOME_LISTS = 'SET_CACHED_HOME_LISTS';
export const UPDATE_LISTS_TO_REPEAT = 'UPDATE_LISTS_TO_REPEAT';
export const RESET_CACHED_LISTS = 'RESET_CACHED_LISTS';
export const ADD_TO_CACHED_LISTS = 'ADD_TO_CACHED_LISTS';
export const ADD_TO_HOME_NEW_LISTS = 'ADD_TO_HOME_NEW_LISTS';
export const ADD_WORD_NUMBERS_TO_CACHED_LIST = 'ADD_WORD_NUMBERS_TO_CACHED_LIST';
export const SET_CACHED_GLOSSARIES = 'SET_CACHED_GLOSSARIES';
export const SET_GLOSSARIES_FROM_CACHE = 'SET_GLOSSARIES_FROM_CACHE';
export const UPDATE_CACHED_GLOSSARIES = 'UPDATE_CACHED_GLOSSARIES';
export const UPDATE_NUMBER_OF_WORDS_IN_LISTS_GLOSSARY = 'UPDATE_NUMBER_OF_WORDS_IN_LISTS_GLOSSARY';
export const SET_CACHED_GLOSSARIES_WORDS = 'SET_CACHED_GLOSSARIES_WORDS';
export const SET_CACHED_GLOSSARY_WORDS = 'SET_CACHED_GLOSSARY_WORDS';
export const APPEND_CACHED_GLOSSARY_WORDS = 'APPEND_CACHED_GLOSSARY_WORDS';
export const RESET_GLOSSARY_WORDS_DATA = 'RESET_GLOSSARY_WORDS_DATA';

export const SET_CACHED_PREVIEW_LIST_WORDS = 'SET_CACHED_PREVIEW_LIST_WORDS';
export const SET_CACHED_COLLECT_WORD_LIST_WORDS = 'SET_CACHED_COLLECT_WORD_LIST_WORDS';
export const SET_CACHED_CHECK_SPELLING_LIST_WORDS = 'SET_CACHED_CHECK_SPELLING_LIST_WORDS';
export const UPDATE_CACHED_TRAINING_WORD_TRANSLATIONS = 'UPDATE_CACHED_TRAINING_WORD_TRANSLATIONS';
export const SET_PREVIEW_WORDS_FROM_CACHE = 'SET_PREVIEW_WORDS_FROM_CACHE';
export const SET_MISSING_LETTERS_FROM_CACHE = 'SET_MISSING_LETTERS_FROM_CACHE';
export const SET_COLLECT_SENTENCE_WORDS_FROM_CACHE = 'SET_COLLECT_SENTENCE_WORDS_FROM_CACHE';
export const SET_CACHED_COLLECT_SENTENCE_LIST_WORDS = 'SET_CACHED_COLLECT_SENTENCE_LIST_WORDS';
export const SET_SELECT_TRANSLATION_WORDS_FROM_CACHE = 'SET_SELECT_TRANSLATION_WORDS_FROM_CACHE';
export const SET_CACHED_SELECT_TRANSLATION_LIST_WORDS = 'SET_CACHED_SELECT_TRANSLATION_LIST_WORDS';
export const SET_CACHED_SELECT_WORD_LIST_WORDS = 'SET_CACHED_SELECT_WORD_LIST_WORDS';
export const SET_CACHED_DEFINITION_LIST_WORDS = 'SET_CACHED_DEFINITION_LIST_WORDS';
export const SET_SELECT_WORD_WORDS_FROM_CACHE = 'SET_SELECT_WORD_WORDS_FROM_CACHE';
export const SET_DRAG_IMAGE_WORDS_FROM_CACHE = 'SET_DRAG_IMAGE_WORDS_FROM_CACHE';
export const SET_CACHED_DRAG_IMAGE_LIST_WORDS = 'SET_CACHED_DRAG_IMAGE_LIST_WORDS';
export const SET_DEFINITION_WORDS_FROM_CACHE = 'SET_DEFINITION_WORDS_FROM_CACHE';
export const SET_MEMORY_CARD_WORDS_FROM_CACHE = 'SET_MEMORY_CARD_WORDS_FROM_CACHE';
export const SET_CACHED_MEMORY_CARD_LIST_WORDS = 'SET_CACHED_MEMORY_CARD_LIST_WORDS';
export const DELETE_WORD_FROM_CACHED_WORDS_FOR_TRAININGS = 'DELETE_WORD_FROM_CACHED_WORDS_FOR_TRAININGS';
export const DELETE_CACHED_LIST_FROM_TRAINING_LISTS = 'DELETE_CACHED_LIST_FROM_TRAINING_LISTS';
export const DELETE_CACHED_LISTS_FROM_TRAINING_LISTS = 'DELETE_CACHED_LISTS_FROM_TRAINING_LISTS';
export const CLEAN_LISTS_CACHE = 'CLEAN_LISTS_CACHE';
export const CLEAN_LIST_CACHE = 'CLEAN_LIST_CACHE';
export const CLEAN_APP_CACHE = 'CLEAN_APP_CACHE';
export const MOVE_NEW_LIST_TO_REPEAT = 'MOVE_NEW_LIST_TO_REPEAT';
export const UPDATE_LIST_TRAININGS = 'UPDATE_LIST_TRAININGS';
export const REMOVE_LIST_FROM_REPEAT_SECTION = 'REMOVE_LIST_FROM_REPEAT_SECTION';
export const UPDATE_TRAINING_LIST_REPEAT_SECTION = 'UPDATE_TRAINING_LIST_REPEAT_SECTION';
export const UPDATE_CACHED_LIST_TRAININGS = 'UPDATE_CACHED_LIST_TRAININGS';
export const ADD_TO_USER_LISTS = 'ADD_TO_USER_LISTS';
export const RESET_GLOSSARY_CACHE = 'RESET_GLOSSARY_CACHE';
export const UPDATE_CACHED_LIST_TRAININGS_AND_INTERVALS = 'UPDATE_CACHED_LIST_TRAININGS_AND_INTERVALS';
export const SET_CACHED_GLOSSARY_WORDS_FOR_LIST = 'SET_CACHED_GLOSSARY_WORDS_FOR_LIST';
export const APPEND_CACHED_GLOSSARY_WORDS_FOR_LIST = 'APPEND_CACHED_GLOSSARY_WORDS_FOR_LIST';
export const RESET_GLOSSARY_WORDS_DATA_FOR_LIST = 'RESET_GLOSSARY_WORDS_DATA_FOR_LIST';
export const SET_CACHED_GLOSSARIES_WORDS_FOR_LIST = 'SET_CACHED_GLOSSARIES_WORDS_FOR_LIST';
export const UPDATED_CACHED_GLOSSARY_WORDS = 'UPDATED_CACHED_GLOSSARY_WORDS';
export const SET_WORDS_FOR_MISSING_LETTER = 'SET_WORDS_FOR_MISSING_LETTER';
export const SET_CACHED_MISSING_LETTER_LIST_WORDS = 'SET_CACHED_MISSING_LETTER_LIST_WORDS';
export const SET_COURSE_CACHED_MISSING_LETTER_LIST_WORDS = 'SET_COURSE_CACHED_MISSING_LETTER_LIST_WORDS';
export const SHUFFLE_LIST_TRAINING_WORDS = 'SHUFFLE_LIST_TRAINING_WORDS';
export const ADD_USER_EXAMPLE_TRANSLATION = 'ADD_USER_EXAMPLE_TRANSLATION';
export const SET_USER_EXAMPLE_TRANSLATION = 'SET_USER_EXAMPLE_TRANSLATION';
export const SET_WORDS_FOR_TRAINING_LISTENING = 'SET_WORDS_FOR_TRAINING_LISTENING';
export const SET_CACHED_LISTENING_LIST_WORDS = 'SET_CACHED_LISTENING_LIST_WORDS';
export const SET_LISTENING_WORDS_FROM_CACHE = 'SET_LISTENING_WORDS_FROM_CACHE';


/***** COURSE CACHE *****/
export const SET_CURRENT_COURSE_SLUG = 'SET_CURRENT_COURSE_SLUG';
export const GET_COURSE_INITIAL_DATA = 'GET_COURSE_INITIAL_DATA';
export const GET_COURSES_INITIAL_DATA = 'GET_COURSES_INITIAL_DATA';
export const SET_CACHE_COURSES_DATA = 'SET_CACHE_COURSES_DATA';
export const SET_COURSE_INITIAL_DATA = 'SET_COURSE_INITIAL_DATA';
export const GET_COURSE_LIST_STATISTICS = 'GET_COURSE_LIST_STATISTICS';
export const SET_CACHE_COURSE_DATA = 'SET_CACHE_COURSE_DATA';
export const SET_CURRENT_COURSE_ID = 'SET_CURRENT_COURSE_ID';
export const SET_COURSE_DATA_FROM_CACHE = 'SET_COURSE_DATA_FROM_CACHE';
export const SET_CACHE_LIST_WORDS = 'SET_CACHE_LIST_WORDS';
export const SET_COURSE_LIST_WORDS_FROM_CACHE = 'SET_COURSE_LIST_WORDS_FROM_CACHE';
export const RESET_COURSE_CACHE = 'RESET_COURSE_CACHE';
export const SET_COURSE_LIST_STATISTICS = 'SET_COURSE_LIST_STATISTICS';
export const SET_COURSE_DEBTS = 'SET_COURSE_DEBTS';
export const DELETE_COURSE_LIST = 'DELETE_COURSE_LIST';
export const DELETE_FROM_COURSE_VOCABULARY = 'DELETE_FROM_COURSE_VOCABULARY';
export const REMOVE_FROM_COURSE_VOCABULARY = 'REMOVE_FROM_COURSE_VOCABULARY';
export const GET_JUST_COURSE_DATA = 'GET_JUST_COURSE_DATA';
export const SET_JUST_COURSE_DATA = 'SET_JUST_COURSE_DATA';

export const SET_COURSE_PREVIEW_WORDS_FROM_CACHE = 'SET_COURSE_PREVIEW_WORDS_FROM_CACHE';
export const SET_COURSE_CACHED_LISTENING_LIST_WORDS = 'SET_COURSE_CACHED_LISTENING_LIST_WORDS';

export const SET_COURSE_CACHED_PREVIEW_LIST_WORDS = 'SET_COURSE_CACHED_PREVIEW_LIST_WORDS';
export const SET_COURSE_CACHED_COLLECT_WORD_LIST_WORDS = 'SET_COURSE_CACHED_COLLECT_WORD_LIST_WORDS';
export const SET_COLLECT_WORDS_FROM_CACHE = 'SET_COLLECT_WORDS_FROM_CACHE';
export const SET_CHECK_SPELLING_WORDS_FROM_CACHE = 'SET_CHECK_SPELLING_WORDS_FROM_CACHE';
export const SET_COURSE_CACHED_CHECK_SPELLING_LIST_WORDS = 'SET_COURSE_CACHED_CHECK_SPELLING_LIST_WORDS';
export const SET_COURSE_CACHED_SELECT_TRANSLATION_LIST_WORDS = 'SET_COURSE_CACHED_SELECT_TRANSLATION_LIST_WORDS';
export const SET_COURSE_CACHED_SELECT_WORD_LIST_WORDS = 'SET_COURSE_CACHED_SELECT_WORD_LIST_WORDS';
export const SET_COURSE_CACHED_DRAG_IMAGE_LIST_WORDS = 'SET_COURSE_CACHED_DRAG_IMAGE_LIST_WORDS';
export const SET_COURSE_CACHED_DEFINITION_LIST_WORDS = 'SET_COURSE_CACHED_DEFINITION_LIST_WORDS';
export const SET_COURSE_CACHED_MEMORY_CARD_LIST_WORDS = 'SET_COURSE_CACHED_MEMORY_CARD_LIST_WORDS';
export const SET_COURSE_CACHED_COLLECT_SENTENCE_LIST_WORDS = 'SET_COURSE_CACHED_COLLECT_SENTENCE_LIST_WORDS';
export const CLEAN_COURSE_CACHE = 'CLEAN_COURSE_CACHE';
export const GET_COURSE_MISTAKES = 'GET_COURSE_MISTAKES';
export const SET_ONLY_MISTAKES_COURSE = 'SET_ONLY_MISTAKES_COURSE';
export const SET_ONLY_REPEATS_COURSE = 'SET_ONLY_REPEATS_COURSE';
export const SET_ONLY_TESTS_COURSE = 'SET_ONLY_TESTS_COURSE';

export const MOVE_COURSE_LIST_TO_REPEAT = 'MOVE_COURSE_LIST_TO_REPEAT';
export const SET_SHOW_LIST_TRAINING_BUTTONS_MODAL_COURSE = 'SET_SHOW_LIST_TRAINING_BUTTONS_MODAL_COURSE';
export const UPDATE_CACHED_LIST_TRAININGS_COURSE = 'UPDATE_CACHED_LIST_TRAININGS_COURSE';
export const MOVED_CACHED_LIST_TO_TEST = 'MOVED_CACHED_LIST_TO_TEST';
export const GET_UPDATED_COURSE_LISTS = 'GET_UPDATED_COURSE_LISTS';
export const UPDATE_CACHED_COURSE_LISTS = 'UPDATE_CACHED_COURSE_LISTS';
export const UPDATE_CURRENT_LIST_SCORES = 'UPDATE_CURRENT_LIST_SCORES';
export const GET_UPDATED_COURSE_DATA = 'GET_UPDATED_COURSE_DATA';
export const GET_CURRENT_COURSE_LIST_DATA = 'GET_CURRENT_COURSE_LIST_DATA';
export const SET_CURRENT_COURSE_LIST_DATA = 'SET_CURRENT_COURSE_LIST_DATA';
export const UPDATE_CACHED_COURSE_DATA = 'UPDATE_CACHED_COURSE_DATA';
export const UPDATE_COURSE_LISTS = 'UPDATE_COURSE_LISTS';
export const UPDATE_COURSE_DATA = 'UPDATE_COURSE_DATA';
export const GET_UPDATED_COURSE_CURRENT_LIST_SCORES = 'GET_UPDATED_COURSE_CURRENT_LIST_SCORES';
export const UPDATE_CACHED_TRAINING_WORD_TRANSLATIONS_COURSE = 'UPDATE_CACHED_TRAINING_WORD_TRANSLATIONS_COURSE';
export const UPDATE_WORD_IN_VOCABULARY = 'UPDATE_WORD_IN_VOCABULARY';
export const UPDATE_WORD_IN_VOCABULARY_FAV = 'UPDATE_WORD_IN_VOCABULARY_FAV';
export const CLEAN_TRAININGS_CACHE_COURSE = 'CLEAN_TRAININGS_CACHE_COURSE';
export const SHUFFLE_COURSE_LIST_TRAINING_WORDS = 'SHUFFLE_COURSE_LIST_TRAINING_WORDS';
export const SHUFFLE_TRAINING_WORDS = 'SHUFFLE_TRAINING_WORDS';

/***** TOUR *****/
export const GET_TOUR_GLOSSARIES = 'GET_TOUR_GLOSSARIES';
export const SET_TOUR_GLOSSARIES = 'SET_TOUR_GLOSSARIES';
export const GET_TOUR_GLOSSARY = 'GET_TOUR_GLOSSARY';
export const SET_TOUR_GLOSSARY = 'SET_TOUR_GLOSSARY';
export const RESET_TOUR_GLOSSARY = 'RESET_TOUR_GLOSSARY';
export const GET_TOUR_WORD = 'GET_TOUR_WORD';
export const SET_TOUR_WORD = 'SET_TOUR_WORD';
export const RESET_TOUR_WORD = 'RESET_TOUR_WORD';
export const SET_CURRENT_TOUR_LIST = 'SET_CURRENT_TOUR_LIST';
export const RESET_CURRENT_TOUR_LIST = 'RESET_CURRENT_TOUR_LIST';
export const GET_TOUR_LISTS = 'GET_TOUR_LISTS';
export const SET_TOUR_LISTS = 'SET_TOUR_LISTS';
export const GET_TOUR_LIST_WORDS = 'GET_TOUR_LIST_WORDS';
export const SET_TOUR_LIST_WORDS = 'SET_TOUR_LIST_WORDS';
export const RESET_TOUR_LIST_WORDS = 'RESET_TOUR_LIST_WORDS';
export const GET_TOUR_COURSE_DATA = 'GET_TOUR_COURSE_DATA';
export const SET_TOUR_COURSE_DATA = 'SET_TOUR_COURSE_DATA';
export const RESET_CURRENT_TOUR_COURSE_LIST = 'RESET_CURRENT_TOUR_COURSE_LIST';
export const SET_CURRENT_TOUR_COURSE_LIST = 'SET_CURRENT_TOUR_COURSE_LIST';
export const SET_CURRENT_TOUR_COURSE_LIST_TYPE = 'SET_CURRENT_TOUR_COURSE_LIST_TYPE';
export const GET_TOUR_COURSE_LIST_WORDS = 'GET_TOUR_COURSE_LIST_WORDS';
export const SET_TOUR_COURSE_LIST_WORDS = 'SET_TOUR_COURSE_LIST_WORDS';
export const RESET_TOUR_COURSE_LIST_WORDS = 'RESET_TOUR_COURSE_LIST_WORDS';
export const GET_TOUR_WORDS_FOR_TRAINING = 'GET_TOUR_WORDS_FOR_TRAINING';
export const SET_SHOW_LIST_TRAINING_BUTTONS_MODAL_TOUR = 'SET_SHOW_LIST_TRAINING_BUTTONS_MODAL_TOUR';
export const GET_TOUR_CALENDAR_DATA = 'GET_TOUR_CALENDAR_DATA';



export const SET_EVERYDAY_PHRASE = 'SET_EVERYDAY_PHRASE';
export const SET_EVERYDAY_WORD = 'SET_EVERYDAY_WORD';
export const GET_EVERYDAY_PHRASE = 'GET_EVERYDAY_PHRASE';
export const GET_EVERYDAY_WORD = 'GET_EVERYDAY_WORD';
export const SET_GETTING_PHRASE = 'SET_GETTING_PHRASE';
export const SET_GETTING_WORD = 'SET_GETTING_WORD';
export const ADD_EVERYDAY_TO_LIST = 'ADD_EVERYDAY_TO_LIST';


// USER NEWS
export const GET_USER_NEWS = 'GET_USER_NEWS';
export const GET_ALERT_NEWS = 'GET_ALERT_NEWS';
export const SET_ALERT_NEWS = 'SET_ALERT_NEWS';
export const GET_UNREAD_NEWS = 'GET_UNREAD_NEWS';
export const SET_UNREAD_NEWS = 'SET_UNREAD_NEWS';
export const SET_USER_NEWS = 'SET_USER_NEWS';
export const MANAGE_USER_NEWS = 'MANAGE_USER_NEWS';
export const GET_CURRENT_ARTICLE = 'GET_CURRENT_ARTICLE';
export const TOGGLE_CURRENT_ARTICLE_VISIBILITY = 'TOGGLE_CURRENT_ARTICLE_VISIBILITY';
export const SET_CURRENT_ARTICLE = 'SET_CURRENT_ARTICLE';

// TAGS
export const GET_TAGS = 'GET_TAGS';
export const GET_CONNECTED_TAGS = 'GET_CONNECTED_TAGS';
export const SET_CONNECTED_TAGS = 'SET_CONNECTED_TAGS';
export const SET_TAGS = 'SET_TAGS';
export const CONNECT_TAG = 'CONNECT_TAG';
export const CREATE_TAG = 'CREATE_TAG';
export const DELETE_TAG = 'DELETE_TAG';
export const DISCONNECT_TAG = 'DISCONNECT_TAG';
export const REMOVE_TAG_FROM_CURRENT_LIST = 'REMOVE_TAG_FROM_CURRENT_LIST';
export const SET_SELECTED_TAG = 'SET_SELECTED_TAG';
export const RESET_SELECTED_TAGS = 'RESET_SELECTED_TAGS';
export const UPDATE_LIST_TAG = 'UPDATE_LIST_TAG';


// MULTI WORD MAKER
export const GET_MULTI_WORD_MAKER_GAMES = 'GET_MULTI_WORD_MAKER_GAMES';
export const SET_MULTI_WORD_MAKER_GAMES = 'SET_MULTI_WORD_MAKER_GAMES';
export const RESET_MULTI_WORD_MAKER_GAMES = 'RESET_MULTI_WORD_MAKER_GAMES';
export const CREATE_WORD_MAKER_MULTI_GAME = 'CREATE_WORD_MAKER_MULTI_GAME';
export const CANCEL_WORD_MAKER_MULTI_GAME = 'CANCEL_WORD_MAKER_MULTI_GAME';
export const JOIN_WORD_MAKER_MULTI_GAME = 'JOIN_WORD_MAKER_MULTI_GAME';
export const EXIT_WORD_MAKER_MULTI_GAME = 'EXIT_WORD_MAKER_MULTI_GAME';
export const START_WORD_MAKER_MULTI_GAME = 'START_WORD_MAKER_MULTI_GAME';
export const GET_CURRENT_WORD_MAKER_MULTI_GAME = 'GET_CURRENT_WORD_MAKER_MULTI_GAME';
export const SET_CURRENT_ACTIVE_MULTI_WORD_MAKER_GAME = 'SET_CURRENT_ACTIVE_MULTI_WORD_MAKER_GAME';
export const RESET_CURRENT_WORD_MAKER_MULTI_GAME = 'RESET_CURRENT_WORD_MAKER_MULTI_GAME';
export const LOG_WORD_MAKER_MULTI_GAME_RESULTS = 'LOG_WORD_MAKER_MULTI_GAME_RESULTS';
export const SET_MULTI_WORD_MAKER_GAME_RESULTS = 'SET_MULTI_WORD_MAKER_GAME_RESULTS';
export const GET_WORD_MAKER_MULTI_GAME_RESULTS = 'GET_WORD_MAKER_MULTI_GAME_RESULTS';
export const MAKE_WORD_MAKER_MULTI_GAME_INACTIVE = 'MAKE_WORD_MAKER_MULTI_GAME_INACTIVE';
export const INVITE_USERS_TO_MULTI_MAKER = 'INVITE_USERS_TO_MULTI_MAKER';
export const RESET_MULTI_WORD_MAKER_GAME_RESULTS = 'RESET_MULTI_WORD_MAKER_GAME_RESULTS';
export const GET_MULTI_WORD_MAKER_GAME_RATING = 'GET_MULTI_WORD_MAKER_GAME_RATING';
export const SET_MULTI_WORD_MAKER_GAME_RATING = 'SET_MULTI_WORD_MAKER_GAME_RATING';
export const SHOULD_START_MULTI_MAKER = 'SHOULD_START_MULTI_MAKER';

// HELPER ARTICLES
export const GET_HELPER_ARTICLES = 'GET_HELPER_ARTICLES';
export const SET_HELPER_ARTICLES = 'SET_HELPER_ARTICLES';
export const GET_HELPER_ARTICLE = 'GET_HELPER_ARTICLE';
export const GET_HELPER_ARTICLE_BY_SLUG = 'GET_HELPER_ARTICLE_BY_SLUG';
export const SET_HELPER_ARTICLE = 'SET_HELPER_ARTICLE';
export const SET_CURRENT_HELPER_ARTICLE = 'SET_CURRENT_HELPER_ARTICLE';
export const SET_ARTICLE_FONT_SIZE = 'SET_ARTICLE_FONT_SIZE';







