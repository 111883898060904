import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faCircle} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import * as helpers from '../../../lib/validators';

class UploadImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      checked: false
    };
  }

  onChangeHandler = (e) => {
    const {translate} = this.props;

    if(e.target.files[0]) {
      const checked = this.checkImage(e.target.files[0]);
      if(checked) {
        this.setState({
          file: e.target.files[0],
          checked: true
        })
      }

    } else {
      toastr.error(translate('no_image_selected'));
    }
  };

  checkImage = (file) => {
    const {translate} = this.props;

    let types = [
      'image/jpeg',
      'image/jpg',
      'image/png'
    ];


    if (types.indexOf(file.type.toLowerCase()) === -1) {
      toastr.error(`${file.type} ${translate('wrong_image_type')}`);
      return false;
    } else if (file.size > 5000000) {
      toastr.error(translate('big_image_size'));
      return false;
    }
    return true;
  };

  render() {
    const {
      translate,
      isVisible,
      onClose,
      onSubmit,

    } = this.props;
    const {file, checked} = this.state;

    return (
      <Modal
        className="uploadimage"
        show={isVisible}
        onHide={()=>onClose('uploadImageVisible',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="upload_image"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">
          <div className="position-relative">
            <p>
              {translate('add_word_image_description')}
            </p>

            <p>
              {translate('add_word_image_requirements')}
            </p>
            <Form>
              <Form.File
                id="upload-file"
                label={!checked ? translate('select_image_from_your_computer') : file && file.name ? file.name : 'File'}
                data-browse={translate('image')}
                custom
                onChange={this.onChangeHandler}
              />
            </Form>

            <Button
              className="mt-3"
              block
              disabled={!checked}
              variant="outline-secondary"
              onClick={()=>onSubmit(file)}>
              {translate('upload_image')}
            </Button>

          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(null, null)(UploadImage);
