import React, {Component} from 'react';
import {Translate} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircle, faCogs, faCheckCircle,
  faTrashAlt,
  faCamera,
  faVolumeUp,
  faStar,
  faArchive,
  faTimes,
  faSearch,
  faPlus,
  faBars
} from '@fortawesome/free-solid-svg-icons';

import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import colors from '../../../lib/colors';
import {IMAGE_URL_GLIDE} from '../../../lib/constants';

class HelperArticleCell extends Component {

  render() {
    const {
      article,
      ratio,
      onItemPressed,
      index,
      tablet,
      translate,
      deviceWidth,
    } = this.props;

    const imageUrl = article && article.image ? article.image : false;

    const imageWidth = 200;

    let s3image = `${IMAGE_URL_GLIDE}/${imageUrl}?w=${imageWidth}`;

    return (
      <Card
        style={{
            width: 300,
            height: 350,
            margin: 20,
            }}
        className="articleWrapper"
        onClick={(e) => onItemPressed(article, index)}
      >
        <div className="articleContainer">
            <span className="articleName" style={{
            minHeight: !tablet ? 40 : deviceWidth > 600 ? 70 : 50,
            padding: '10px 25px', fontSize: ratio * (!tablet ? 14 : 16)}}
            >
              {article.title && article.title.length > 35 ? `${article.title.substr(0, 32)}...` : article.title}
            </span>
          {
            imageUrl && (
              <Image
                draggable="false"
                onContextMenu={(e)=>e.preventDefault()}
                className="wordImage"
                style={{height:  !tablet ? 150: 200, width: !tablet ? 150: 200 }}
                src={s3image}
              />
            ) || <div className="imagePlaceholder" style={{height: !tablet ? 150: 200, width: !tablet ? 150 : 200}}/>
          }

          <Button
            variant="outline-success"
            className="dFlex flexCenter flex-column mt-1 mb-2"
            style={{minWidth: !tablet ? 120 : 150, height: !tablet ? (deviceWidth > 360 ? 50 : 45) : 75}}
            onClick={(e) => onItemPressed(article, index)}

          >
            <span style={{fontSize: ratio * (!tablet ? 14 : 16)}}>
              {translate('read')}
            </span>
          </Button>
        </div>
      </Card>
    );
  }
}

export default HelperArticleCell;
