import * as types from './types';

export const searchWordsForList = (word, listId, byTranslation) => (dispatch) => {
  dispatch({type: types.SEARCH_DICTIONARY_WORDS, payload: {word,listId, byTranslation}});
};

export const search_words = (word, byTranslation) => (dispatch) => {
  dispatch({type: types.SEARCH_IN_DICTIONARY, payload: {word, byTranslation}});
};

export const resetDictionaryWords = () => (dispatch) => {
  dispatch({type: types.SET_FOUND_DICTIONARY_WORDS, payload: []});
};

export const resetDictionarySearchField = (status) => (dispatch) => {
  dispatch({type: types.RESET_DICTIONARY_SEARCH_FIELD, payload: status});
};

export const findWordInDictionary = (word) => (dispatch) => {
  dispatch({type: types.FIND_WORD_IN_DICTIONARY, payload: word});
};

export const resetFoundDictionaryWord = () => (dispatch) => {
  dispatch({type: types.RESET_FOUND_DICTIONARY_WORD});
};