import React, {Component} from 'react';
import {connect} from "react-redux";

import _ from 'lodash';

import {Translate, getTranslate} from 'react-localize-redux';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faPlusCircle, faTimes} from '@fortawesome/free-solid-svg-icons';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import TrainingSubHeader from '../../../components/Headers/TrainingSubHeader/index';
import Loader from "../../../components/Custom/Loader/index";

import {
  getWordsForTraining,
  resetWords,
  resetCurrentTrainingId,
  setCurrentTrainingId
} from '../../../actions/trainingActions'
import {
  logListWord,
  logUserList,
  logGlossaryWord,
  logPhrase,
  logCourseList,
  logCourseListWord
} from '../../../actions/logActions'
import {logActivity} from '../../../actions/logActions';
import {setFirstPlayed} from '../../../actions/wordActions';
import {fetchingData} from '../../../actions/activity';
import {getUpdatedCourseLists, getUpdatedCurrentListScores, getUpdatedCourseData} from '../../../actions/courseActions';
import {logTrainingLimits} from '../../../actions/purchasesActions';

import {checkForCachedWords, checkForCourseCachedWords} from '../../../lib/trainingFunctions';

import colors from '../../../lib/colors';
import * as constants from '../../../lib/constants';

class ListTest extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
      translationsHeight: 40,
      currentPage: 1,
      totalRepeated: 0,
      downloads: 0,
      totalWords: 0,
      currentChunk: 0,
      scores: 0,
      score: 5,
      trainingId: 11,
      showImage: false,
      correctIndex: null,
      selectedIndex: null,
      answered: false,
      trainingName: 'Test',
      autoslide: "1",
      autoslide_delay: "2",
      autosound_word: "1",
      image_or_word: "word",
      mistakes: 0,
      withMistakes: [],
      trainingResults: false,
      exiting: false,

      tablet: false,
      scrolled: 0,

      entityId: this.props.match.params.id,
      listId: undefined,
      courseListId: undefined,
      from: false,
      type: false,
      inside: false,

    };

    this.delayPlayWord = _.debounce(this._play, 500);
    this.track = null;
    this.timeOut = null;
    this.timeOut1 = null;
    this.timeOut2 = null;
  }

  componentDidMount() {
    document.addEventListener("keydown", this._handleKeyDown);

    if (!this.props.history.location || !this.props.history.location.state || !this.props.history.location.state.type) {
      this.props.history.goBack();
    } else {
      this.setState({
        [this.props.history.location.state.type]: parseInt(this.props.match.params.id, 10),
        from: this.props.history.location.state.from,
        type: this.props.history.location.state.type,
        inside: this.props.history.location.state.inside,
      })
    }

    this.props.fetchingData(true);

    this.setCorrectIndex();

    this.timeOut = setTimeout(()=> {
      this.getWords();
    }, 100);

    this.props.setCurrentTrainingId(11);
  }

  componentDidUpdate(prevProps, prevState) {
    const {words} = this.props.data;

    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      if (words.length == 0) {
        this.getWords();
      }
    }
  }

  componentWillUnmount() {
    const {listId, courseListId} = this.state;

    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut1);
    clearTimeout(this.timeOut2);
    
    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    this.props.resetWords();
    this.props.resetCurrentTrainingId();

    /*if(listId !== undefined) {
     this.onUnmountUpdates();
     }*/
    
    if (courseListId != undefined) {
      this.onUnmountCourseUpdates();
    }

    this.props.setFirstPlayed(false);
    document.removeEventListener("keydown", this._handleKeyDown);
  }

  _handleKeyDown = (event) => {
    const {freeze} = this.state;

    switch (event.keyCode) {
      case 39:
        if (!freeze) {
          this.changeWord('left')
        }
        break;
      case 37:
        if (!freeze) {
          this.changeWord('right')
        }
        break;

      case 27:
        this.props.history.goBack();
        break;
      default:
        break;
    }
  };

  onUnmountCourseUpdates = () => {
    const {courseListId} = this.state;
    
    this.props.fetchingData(true);

    this.props.getUpdatedCourseLists();
    this.props.getUpdatedCourseData();

    this.props.getUpdatedCurrentListScores(courseListId);
  };

  changeWord = (direction) => {
    const {totalWords} = this.props.data;
    const {currentIndex} = this.state;
    var index = currentIndex;

    if (direction == "left") {
      this.checkIfNeedMore();

      if (currentIndex == totalWords - 1) {
        this.logList();
      } else {
        index = currentIndex + 1;

        this.setState({
          totalRepeated: this.state.totalRepeated + 1
        })
      }
    } else {
      if (currentIndex == 0) {
        index = totalWords - 1
      } else {
        index = currentIndex - 1
      }
    }

    if (currentIndex < totalWords - 1) {
      this.setCorrectIndex();

      this.setState({
        currentIndex: index,
        selectedIndex: null,
        answered: false
      });

      this.delayPlayWord(index);
    }
  };

  logList = () => {
    const {logUserList, logCourseList} = this.props;
    const {trainingId, listId, courseListId} = this.state;

    if (listId && listId > 0) {
      logUserList({trainingId, listId, type: "end"})
    } else if (courseListId && courseListId != 0) {
      logCourseList({trainingId, listId: courseListId, type: "end"});
    }

    this.timeOut1 = setTimeout(()=> {
      this.props.history.goBack();
    }, 100);
  };

  _play = (index) => {
    const {autosound_word} = this.state;
    const {words} = this.props.data;
    const {firstPlayed} = this.props;
    const {userData} = this.props.user;

    let audioSource = constants.BACKEND_URL;

    let word = words.length > 0 ? words[index] : false;

    if (word && word.audio) {
      let audio = word.audio;

      if (userData && userData.audio_language && word[userData.audio_language]) {
        audio = word[userData.audio_language];
        audioSource = constants.S3;
      }

      if (autosound_word == '1') {
        if (!firstPlayed) {
          this.props.setFirstPlayed(true)
        }

        if (this.track != null) {
          this.track.pause();
          this.track = null;
        }

        this.track = new Audio(`${audioSource}${audio}`);
        if(this.track != null) {
          this.track.play();
        }
      }
    }
  };

  playSound = () => {
    const {words} = this.props.data;
    const {userData} = this.props.user;
    let audioSource = constants.BACKEND_URL;

    if (words.length > 0 && words.length > this.state.currentIndex) {

      const word = words[this.state.currentIndex];

      if (word && word.audio) {
        let audio = word.audio;

        if (userData && userData.audio_language && word[userData.audio_language]) {
          audio = word[userData.audio_language];
          audioSource = constants.S3;
        }

        if (this.track != null) {
          this.track.pause();
          this.track = null;
        }

        this.track = new Audio(`${audioSource}${audio}`);
        if(this.track != null) {
          this.track.play();
        }
      }
    }
  };

  checkIfNeedMore = () => {
    const {currentIndex, currentPage, downloads} = this.state;
    const {totalWords, words} = this.props.data;

    if (currentIndex + 3 == words.length && words.length < totalWords) {
      this.setState({
        currentPage: currentPage + 1,
        download: downloads + 1
      });

      this.getWords();
    }
  };

  getWords = () => {
    const {courseListId, listId, glossaryId, phrasebookId} = this.state;
    let params = {
      trainingId: this.state.trainingId,
      page: this.state.currentPage,
      history: this.props.history
    };

    const {isConnected} = this.props.settings;

    if (listId >= -2) {
      params.listId = listId;
      params.endpoint = 'getTrainingWords'
    } else if (courseListId !== false) {
      params.courseListId = courseListId;
      params.endpoint = 'getCourseTrainingWords'
    }
    if (isConnected) {
      this.props.getWordsForTraining(params);
    }
  };

  checkAnswer = (i) => {
    const {currentIndex, correctIndex, scores, score, autoslide, autoslide_delay} = this.state;
    const {words} = this.props.data;
    const word = words[currentIndex];

    this.setState({
      selectedIndex: i,
      answered: true
    });

    let mistake = 1;

    if (i === correctIndex) {
      this.setState({
        scores: scores + score
      });

      mistake = 0;
    } else {
      let withMistakes = this.state.withMistakes;
      let found = withMistakes.indexOf(word);

      if (found === -1) {
        withMistakes.push(word);

        this.setState({
          mistakes: this.state.mistakes + 1,
          withMistakes
        });
      }
    }

    this._log(mistake);

    this.checkIfNeedMore();

    if (autoslide == '1') {
      if (this.state.exiting) return;

      this.timeOut2 = setTimeout(() => {
        if (this.state.exiting) return;

        this.changeWord("left")
      }, parseFloat(autoslide_delay) * 1000)
    }
  };

  setCorrectIndex = () => {
    let n = Math.floor(Math.random() * 4);

    this.setState({
      correctIndex: n
    });
  };

  renderButton = (word, ind) => {
    const {selectedIndex, correctIndex, answered, tablet} = this.state;
    const {fontSizeRation} = this.props.settings;

    const translation = word && word.main_translation && word.main_translation.translation ? word.main_translation.translation : "";

    if (!word) return;

    if (selectedIndex !== null && selectedIndex === ind) {
      return <Button
        disabled={answered}
        className="answerButton"
        variant={selectedIndex === correctIndex ? 'success' : 'danger'}
        style={{fontSize:fontSizeRation * (!tablet ? 14 : 16)}}
        onClick={()=>null}
      >
        {word && correctIndex === ind ? translation : word.other_translations && word.other_translations.length > 0 && word.other_translations[ind] ? word.other_translations[ind].translation : ""}
      </Button>
    } else {
      return <Button
        disabled={answered}
        className="answerButton"
        variant="outline-secondary"
        style={{fontSize:fontSizeRation * (!tablet ? 14 : 16)}}
        onClick={() => this.checkAnswer(ind)}
      >
        {word && correctIndex === ind ? translation : word.other_translations && word.other_translations.length > 0 && word.other_translations[ind] ? word.other_translations[ind].translation : ""}
      </Button>
    }
  };

  _log = (mistake) => {
    const {words} = this.props.data;
    const {currentIndex} = this.state;
    let word = words.length > 0 ? words[currentIndex] : false;

    const {trainingId, listId, courseListId} = this.state;

    if (word) {
      let params = {
        trainingId: trainingId,
        mistake,
        id: word.id
      };

      if (listId >= -1) {
        params.listId = listId;
        this.props.logListWord(params);
      } else if (courseListId !== false) {
        params.courseListId = courseListId;
        this.props.logCourseListWord(params);
      }
    }
  };

  render() {
    const {words, totalWords} = this.props.data;
    const {currentIndex, autosound_word, scores, tablet, trainingId} = this.state;
    const {firstPlayed} = this.props;
    const {fetchingData} = this.props.activity;
    const {fontSizeRation, version, isConnected, deviceWidth, deviceHeight} = this.props.settings;
    const {currentList} = this.props.lists;
    const {course, translate} = this.props;

    let subtitle = "";

    if (currentList && currentList.list_name && totalWords) {
      subtitle = `${currentList.list_name}: ${totalWords}`
    } else if (course && course.currentList && course.currentList.list_name) {
      subtitle = `${course.currentList.list_name}: ${totalWords}`
    }


    let word = words.length > 0 ? words[currentIndex] : false;

    if (currentIndex === 0 && autosound_word == '1') {
      if (!firstPlayed) {
        this.delayPlayWord(currentIndex);
      }
    }
    const progress = totalWords !== 0 ? ((currentIndex + 1) / totalWords) : 0;

    return (
      <div className="listtest">
        <Container className="pageWrapper">

          <TrainingSubHeader
            trainingId={trainingId}
            hideButtons
            version={version}
            ratio={fontSizeRation}
            scores={scores}
            progress={progress}
            changeWord={this.changeWord}

            deviceHeight={deviceHeight}
            deviceWidth={deviceWidth}
            translate={translate}
            tablet={tablet}
            exit={this._goBack}
            test={true}
            history={this.props.history}
            trainingName="course_test"
            subtitle={subtitle}
            hideInfo={true}
            hideSettings
          />

          <Card style={{minHeight: deviceHeight - 140}}>
            <div style={{opacity: word && word.id ? 1 : 0}} className="contentWrapper">
              <div className="behindImage" style={{height: !tablet ? 120 : 150}}>
                <span
                  className="behindImageText"
                  style={{fontSize: fontSizeRation * (!tablet ? 20 : 24)}}>
                  {word ? word.word : ""}
                </span>
              </div>

              <div className="answerButtonsWrapper" style={{ marginTop: !tablet ? 15 : 30}}>
                {this.renderButton(word, 0)}
                {this.renderButton(word, 1)}
                {this.renderButton(word, 2)}
                {this.renderButton(word, 3)}
              </div>
            </div>
          </Card>

        </Container>
        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  data: state.trainings,
  firstPlayed: state.word.firstPlayed,
  activity: state.activity,
  settings: state.settings,
  courseCache: state.courseCache,
  lists: state.lists,
  course: state.course,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    getWordsForTraining: (data) => dispatch(getWordsForTraining(data)),
    resetWords: () => dispatch(resetWords()),
    logListWord: (data) => dispatch(logListWord(data)),
    logPhrase: (data) => dispatch(logPhrase(data)),
    logGlossaryWord: (data) => dispatch(logGlossaryWord(data)),
    logUserList: (data) => dispatch(logUserList(data)),
    logActivity: (data) => dispatch(logActivity(data)),
    setFirstPlayed: (status) => dispatch(setFirstPlayed(status)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    logCourseListWord: (data) => dispatch(logCourseListWord(data)),
    logCourseList: (data) => dispatch(logCourseList(data)),
    getUpdatedCourseLists: () => dispatch(getUpdatedCourseLists()),
    getUpdatedCurrentListScores: (listId) => dispatch(getUpdatedCurrentListScores(listId)),
    resetCurrentTrainingId: () => dispatch(resetCurrentTrainingId()),
    setCurrentTrainingId: (id) => dispatch(setCurrentTrainingId(id)),
    logTrainingLimits: () => dispatch(logTrainingLimits()),
    getUpdatedCourseData: () => dispatch(getUpdatedCourseData()),

  };
}
export default connect(mapStateToProps, bindAction)(ListTest);
