import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logApiError} from './logSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* searchWordsForList(data) {
  const params = data.payload;
  
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'search_words_for_list')
    .query({
      user_id: userId,
      list_id : params.listId,
      word : params.word,
      by_translation: params.byTranslation
    });

    // console.log("searchWordsForList", result.body)
    
    yield put({
      type: types.SET_FOUND_DICTIONARY_WORDS,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({payload: {function: 'search_words_for_list', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


export function* findWordInDictionary(data) {

  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'findWordInDictionary')
    .query({
      user_id: userId,
      word : data.payload,
    });

    // console.log("findWordInDictionary", result.body)

    yield put({
      type: types.SET_FOUND_DICTIONARY_WORD,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({payload: {function: 'findWordInDictionary', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* search_words(data) {
  const params = data.payload;

  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'search_words')
    .query({
      user_id: userId ? userId : 1,
      word : params.word,
      by_translation: params.byTranslation
    });

    // console.log("search_words", result.body)
    
    yield put({
      type: types.SET_FOUND_DICTIONARY_WORDS,
      payload: result.body.words
    });

  } catch (error) {
    yield logApiError({payload: {function: 'search_words', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}
