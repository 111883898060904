import moment from 'moment';

export const checkForCachedWords = (cachedWords, listId, page) => {
  if(!listId || listId <= 0) return false;
  
  let pageWords = false;
  let pageFound = false;

  cachedWords.forEach(list => {
    if (list.listId == listId) {
      list.pages.forEach(p => {

        if (p == page) {
          pageFound = true;
        }
      })

      if (pageFound) {
        pageWords = {
          words: list.data.words,
          totalWords: list.data.totalWords,
          totalPages: list.data.totalPages,
          shuffledIndices: list.data.shuffledIndices
        };
      }
    }
  })

  return pageWords;
};

export const checkForCourseCachedWords = (cachedWords, courseListId, page) => {
  if(!courseListId || courseListId <= 0) return false;
  
  let pageWords = false;
  let pageFound = false;

  cachedWords.forEach(list => {
    if (list.listId == courseListId) {
      list.pages.forEach(p => {

        if (p == page) {
          pageFound = true;
        }
      })

      if (pageFound) {
        pageWords = {
          words: list.data.words,
          totalWords: list.data.totalWords,
          totalPages: list.data.totalPages,
          shuffledIndices: list.data.shuffledIndices
        };
      }
    }
  })

  return pageWords;
};

export const checkForCachedWordsMemoryCards = (cachedWords, listId, page) => {
  if(!listId || listId <= 0) return false;

  let pageWords = false;
  let pageFound = false;

  cachedWords.forEach(list => {
    if (list.listId == listId) {
      list.pages.forEach((p, pageIndex) => {

        if (p.page == page) {
          pageWords = {
            words: p.words,
            totalWords: list.data.totalWords,
            totalPages: list.data.totalPages,
            shuffledIndices: p.shuffledIndices
          };
        }
      })

    }
  })

  return pageWords;
};

export const checkForCourseCachedWordsMemoryCards = (cachedWords, courseListId, page) => {
  if(!courseListId || courseListId <= 0) return false;

  let pageWords = false;
  let pageFound = false;

  cachedWords.forEach(list => {
    if (list.listId == courseListId) {
      list.pages.forEach((p, pageIndex) => {

        if (p.page == page) {
          pageWords = {
            words: p.words,
            totalWords: list.data.totalWords,
            totalPages: list.data.totalPages,
            shuffledIndices: p.shuffledIndices
          };
        }
      })

    }
  })

  return pageWords;
};

export const createListWithIntervals = (list, userId) => {
  if(list.intervals && list.intervals.length != 0) {
    list.trainings = 1;
    return list;
  }

  let newList = list;

  let intervals = [];

  [0,1,2,3,4,5,6].forEach(index => {
    intervals.push({
      training_index: index,
      user_id: userId,
      list_id: list.id,
      trainings: 1,
      repetition_date: moment().add([0, 1, 3, 7, 21, 60, 90][index], 'days').format("YYYY-MM-DD")
    })
  })

  newList.intervals = intervals;
  newList.trainings = 1;
  newList.repetition = 1;

  // console.log("createListWithIntervals", newList)
  return newList;
};

export const createCourseListWithIntervals = (list) => {
  let newList = list;

  let repetitions = [];

  [0,1,2,3,4,5].forEach(index => {

    repetitions.push({
      course_id: list.course_id,
      user_id: null,
      list_id: list.id,
      trainings: index === 0 ? 1 : 0,
      test: 0,
      test_results: null,
      training_index: index,
      repetition_date: moment().add([0, 1, 3, 7, 21, 60][index], 'days').format("YYYY-MM-DD")
    })
  })

  newList.trainings = 1;
  newList.repetitions = repetitions;

  // console.log("createCourseListWithIntervals", newList)
  return newList;
};