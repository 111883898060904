import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {getListsByLang, getListsToRepeat} from './lists';
import {logApiError} from './logSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* getEverydayPhrase() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getEverydayPhrase')
    .query({
      user_id: userId,
    });

    // console.log("getEverydayPhrase", result.body)

    yield put({
      type: types.SET_EVERYDAY_PHRASE,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({payload: {function: 'getEverydayPhrase', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.SET_GETTING_PHRASE,
      payload: false
    });
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getEverydayWord() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getEverydayWord')
    .query({
      user_id: userId,
    });

    // console.log("getEverydayWord", result.body)

    yield put({
      type: types.SET_EVERYDAY_WORD,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({payload: {function: 'getEverydayWord', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.SET_GETTING_WORD,
      payload: false
    });
    
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* addEverydayToList(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'addEverydayToList')
    .send({
      user_id: userId,
      list_id: data.payload.listId,
      word_id: data.payload.wordId,
    });

    // console.log("addEverydayToList", result.body)

    // toastr.info('', translate(result.body.result ? 'word_was_added_to_list' : 'error'));

    if(result.body.result) {
      if(data.payload.listId != 0) {
        yield getListsToRepeat({payload: {userId, limit: 20}})
      }
      
      if(data.payload.type == 'word') {
        yield put({
          type: types.SET_GETTING_WORD,
          payload: true
        });
        yield getEverydayWord();
      } else {
        yield put({
          type: types.SET_GETTING_PHRASE,
          payload: true
        });
        yield getEverydayPhrase();
      }

      yield put({
        type: types.CLEAN_TRAININGS_CACHE,
      });

      yield getListsByLang({payload: {filter: "by_list_name", order: "asc"}});
    }

  } catch (error) {
    // console.log(error.message)
    yield logApiError({payload: {function: 'addEverydayToList', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}
