import * as types from './types';

export const register = (data) => (dispatch) => {
  dispatch({ type: types.REGISTER, payload: data });
};

export const login = (data) => (dispatch) => {
  dispatch({ type: types.LOGIN, payload: data });
};

export const sendLink = (data) => (dispatch) => {
  dispatch({ type: types.SEND_FORGOT_LINK, payload: data });
};

export const checkVerified = (userId, history) => (dispatch) => {
  dispatch({ type: types.VERIFY, payload: {userId, history} });
};

export const resendActivationLink = (email) => (dispatch) => {
  dispatch({ type: types.RESEND_ACTIVATION_LINK, payload: email });
};

export const resetPassword = (email, password, confirmPassword, token, history) => (dispatch) => {
  dispatch({ type: types.RESET_PASSWORD, payload: {email, password, confirmPassword, token, history} });
};
