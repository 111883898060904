import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logApiError} from './logSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* getParentGlossaries(data) {
  const {userId} = yield select(state => state.user);
  const {filter, order} = yield select(state => state.glossaries);

  try {
    const result = yield request
    .post(API_URL + 'getGlossariesByLevel')
    // .post(API_URL + 'getParentGlossariesWithChildren')
    .send({
      user_id: userId && userId > 0 ? userId : 1,
      filter,
      order,
      level: data.payload
    });

    // console.log("getGlossariesByLevel", result.body)

    yield put({
      type: types.SET_PARENT_GLOSSARIES,
      payload: result.body
    });
    
    yield put({
      type: types.SET_CACHED_GLOSSARIES,
      payload: {
        data: result.body,
        level: data.payload
      }
    });
    
  } catch (error) {
    yield logApiError({payload: {function: 'getGlossariesByLevel', payload: data.payload,error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* searchGlossaries(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    // .get(API_URL + 'searchParentGlossariesWithChildren')
    .get(API_URL + 'searchParentGlossariesWithChildrenOptimized')
    .query({
      user_id: userId,
      glossary: data.payload,
    });
    
    // console.log("searchGlossaries", result.body)

    yield put({
      type: types.SET_PARENT_GLOSSARIES,
      payload: result.body
    });
  } catch (error) {
    yield logApiError({payload: {function: 'searchParentGlossariesWithChildren', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getParentChildren(data) {
  const {userId} = yield select(state => state.user);
  const glossaryId = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'getParentChildren')
    .send({
      user_id: userId,
      glossary_id: glossaryId
    });

    yield put({
      type: types.SET_PARENT_CHILDREN,
      payload: result.body
    });
  } catch (error) {
    yield logApiError({payload: {function: 'getParentChildren', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* searchChildGlossaries(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'searchChildGlossaries')
    .query({
      user_id: userId,
      glossary: data.payload.glossaryName,
      glossary_id: data.payload.glossaryId
    });

    yield put({
      type: types.SET_PARENT_CHILDREN,
      payload: result.body
    });
  } catch (error) {
    yield logApiError({payload: {function: 'searchChildGlossaries', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }
}

export function* getCurrentGlossaryData(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getCurrentGlossaryData')
    .query({
      user_id: userId && userId > 0 ? userId : 1,
      glossary_id: data.payload.glossaryId
    });

    // console.log('getCurrentGlossaryData',result.body)
    yield put({
      type: types.SET_CURRENT_GLOSSARY_DATA,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({payload: {function: 'getCurrentGlossaryData', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }
}

export function* get_glossaries_for_list(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'get_glossaries_for_list')
    .query({
      user_id: userId && userId > 0 ? userId : 1,
      limit: data.payload
    });

    yield put({
      type: types.SET_GLOSSARIES_FOR_LIST,
      payload: result.body
    });
  } catch (error) {
    yield logApiError({payload: {function: 'get_glossaries_for_list', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }
}

export function* getGlossary(data) {
  const {userId} = yield select(state => state.user);
  const {fetchingNextChunk} = yield select(state => state.glossaries);
  const params = data.payload;
  let append = fetchingNextChunk;

  try {
    const result = yield request
    // .post(API_URL + 'getGlossary')
    .post(API_URL + 'getGlossaryWordsOptimized')
    .send({
      user_id: userId && userId > 0 ? userId : 1,
      glossary_id: params.id,
      page: params.page
    });

    // console.log("getGlossary", result.body)

    yield put({
      type: types.SET_GLOSSARY_WORDS,
      payload: result.body
    });

    yield put({
      type: types.SET_CACHED_GLOSSARIES_WORDS,
      payload: {
        glossaryId: params.id,
        page: params.page,
        words: result.body.data,
        totalGlossaryWords: result.body.total_words,
        totalPages: result.body.total_pages
      }
    });
  } catch (error) {
    yield logApiError({payload: {function: 'getGlossary', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* searchWordsInThisGlossary(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;

  try {
    const result = yield request
    // .get(API_URL + 'searchWordsInThisGlossary')
    .get(API_URL + 'searchWordsInThisGlossaryOptimized')
    .query({
      user_id: userId && userId > 0 ? userId : 1,
      glossary_id: params.glossaryId,
      word: params.word
    });
    
    // console.log("searchWordsInThisGlossary", result.body)

    yield put({
      type: types.SET_FOUND_GLOSSARY_WORDS,
      payload: result.body
    });
  } catch (error) {
    yield logApiError({payload: {function: 'searchWordsInThisGlossary', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* searchWordsInThisGlossaryForList(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;

  try {
    const result = yield request
    .get(API_URL + 'searchWordsInThisGlossary')
    .query({
      user_id: userId,
      glossary_id: params.glossaryId,
      word: params.word
    });

    yield put({
      type: types.SET_FOUND_GLOSSARY_WORDS_FOR_LIST,
      payload: result.body
    });
  } catch (error) {
    yield logApiError({payload: {function: 'searchWordsInThisGlossaryForList', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getGlossaryWordsForList(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;

  try {
    const result = yield request
    // .get(API_URL + 'getGlossaryWordsForList')
    .get(API_URL + 'getGlossaryWordsForListOptimized')
    .query({
      user_id: userId,
      glossary_id: params.glossaryId,
      list_id: params.listId,
      page: params.page
    });

    // console.log("getGlossaryWordsForList", result.body)
    
    if (result.statusCode === 200) {
      yield put({
        type: types.SET_GLOSSARY_WORDS_FOR_LIST,
        payload: result.body
      });

      yield put({
        type: types.SET_CACHED_GLOSSARIES_WORDS_FOR_LIST,
        payload: {
          glossaryId: params.glossaryId,
          page: params.page,
          words: result.body.data,
          totalGlossaryWords: result.body.total_words,
          totalPages: result.body.total_pages
        }
      });
    }

  } catch (error) {
    yield logApiError({payload: {function: 'getGlossaryWordsForList', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* toggleGlossaryWordInVocabulary(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'toggleInVocabulary')
    .send({
      user_id: userId,
      word_id: params.word_id,
      source: 'easy'
    });

    if (result.statusCode === 200) {
      yield put({
        type: types.GLOSSARY_WORD_TOGGLED_IN_VOCABULARY,
        payload: data.payload.index
      });
    }
  } catch (error) {
    yield logApiError({payload: {function: 'toggleInVocabulary', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }
}

export function* toggleGlossaryWordInFavorites(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'addGlossaryWordToFavourites')
    .send({
      user_id: userId,
      word_id: params.word_id,
    });

    if (result.statusCode === 200) {
      yield put({
        type: types.GLOSSARY_WORD_TOGGLED_IN_FAVORITES,
        payload: data.payload.index
      });
    }
  } catch (error) {
    yield logApiError({payload: {function: 'addGlossaryWordToFavourites', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }
}

export function* toggleGlossaryInFavorites(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .get(API_URL + 'toggle_glossary')
    .query({
      user_id: userId,
      glossary_id: params.glossaryId,
    });

    if (result.statusCode === 200) {
      toastr.info('', result.body.result ? translate('glossary_added_to_favorites') : translate('glossary_deleted_from_favorites'));

      yield put({
        type: types.GLOSSARY_TOGGLED_IN_FAVORITES,
        payload: data.payload.index
      });
    }
  } catch (error) {
    yield logApiError({payload: {function: 'toggleGlossaryInFavorites', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }
}


export function* sendCards(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'sendCards')
    .send({
      user_id: userId,
      glossary_id: params.glossary_id,
      glossary_name: params.glossary_name,
      include_image: params.include_image,
      sample: params.sample,
      include_translation: params.include_translation,
      include_example: params.include_example,
      page: params.page,
      words_on_page: params.words_on_page
    });

    // console.log("sendCards",result.body)
    
    if (result.statusCode === 200) {
      toastr.success('', translate('cards_sent'));
    } else {
      toastr.error('', translate('error_sending_cards'));
    }
  } catch (error) {
    yield logApiError({payload: {function: 'sendCards', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}
