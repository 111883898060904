import * as types from './types';

export const getUserNews = () => (dispatch) => {
    dispatch({ type: types.GET_USER_NEWS, payload: {} });
};

export const getAlertNews = () => (dispatch) => {
    dispatch({ type: types.GET_ALERT_NEWS, payload: {alert: 1} });
};

export const getUnreadNews = () => (dispatch) => {
    dispatch({ type: types.GET_UNREAD_NEWS, payload: {} });
};

export const manageNews = (newsId, type, alert) => (dispatch) => {
    dispatch({ type: types.MANAGE_USER_NEWS, payload: {newsId, type, alert} });
};

export const getArticle = (id) => (dispatch) => {
    dispatch({ type: types.GET_CURRENT_ARTICLE, payload: id });
};

export const setCurrentArticle = (article) => (dispatch) => {
    dispatch({ type: types.SET_CURRENT_ARTICLE, payload: article });
};

