import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Translate} from 'react-localize-redux';

const WordSettings = ({
  isVisible,
  ratio, noWord, openTrainingInfo, openTrainingSettings, reportTrainingBug,
  toWordDetails, onClose, title, phrasebook, changeVoice, toggleShuffle, shuffle, shouldShuffle, tour
}) => (
  <Modal
    className="wordsettings"
    size="sm"
    show={isVisible}
    onHide={()=>onClose(false)}
    keyboard={false}
  >
    <Modal.Header closeButton>
      <Modal.Title as="h5">
        {title}
      </Modal.Title>
    </Modal.Header>

    <Modal.Body className="body">
      <ListGroup variant="flush">
        <ListGroup.Item as="button" disabled={tour} onClick={openTrainingSettings}>
          <Translate id="training_setting"/>
        </ListGroup.Item>

        <ListGroup.Item as="button" disabled={tour} onClick={changeVoice}>
          <Translate id="change_audio_language"/>
        </ListGroup.Item>

        {shouldShuffle && (
          <ListGroup.Item disabled={tour} as="button" onClick={toggleShuffle}>
            <Translate id={shuffle == "1" ? 'not_shuffle_words' : 'shuffle_words'}/>
          </ListGroup.Item>
        )}

        {(!noWord && !phrasebook) && (
          <ListGroup.Item disabled={tour} as="button" onClick={toWordDetails}>
            <Translate id={'to_word_details'}/>
          </ListGroup.Item>
        )}

        <ListGroup.Item as="button" disabled={tour} className="text-danger" onClick={reportTrainingBug}>
          <Translate id="report_training_bug"/>
        </ListGroup.Item>
      </ListGroup>
    </Modal.Body>
  </Modal>
);

export default WordSettings;