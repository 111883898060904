import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faSpinner
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import _ from 'lodash'

import {Translate, getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';
import moment from 'moment';

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

// import MyPagination from '../../../../components/Custom/MyPagination/index';

import SubscriptionAlert from '../../../../components/Modals/SubscriptionAlert/index';
import AddUserContact from '../../../../components/Modals/AddUserContact/index';
import InviteUsersToMultiWordMaker from '../../../../components/Modals/InviteUsersToMultiWordMaker/index';
import Loader from "../../../../components/Custom/Loader/index";

import {
  getActiveMultiWordMakerGames,
  resetMultiWordMakerGames,
  createWordMakerMultiGame,
  cancelMultiWordMakerGame,
  joinMultiWordMakerGame,
  resetCurrentGame,
  getTotalRating
} from '../../../../actions/multiWordMakerActions';
import {logActivity} from '../../../../actions/logActions';
import {fetchingData} from '../../../../actions/activity';
import {getUserContacts} from '../../../../actions/userContactsActions';

class MultiWordMakerGamesList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      subscriptionAlertVisible: false,
      addContactModalVisible: false,
      inviteContactModalVisible: false,
      tablet: false,
      intervals: 0,
      page: 1,
      currentIntervals: 0
    };

    this.checkUpdates = null;
  }

  componentDidMount() {
    this._getActiveGames();
    this.props.getTotalRating();
    this.props.getUserContacts();
    this.props.fetchingData(true);

    this.checkUpdates = setInterval(()=> {
      this._getActiveGames();
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    const {currentActiveGame} = this.props.multiWordMaker;
    const {locale} = this.props.settings;

    if (this.state.intervals == 100) {
      clearInterval(this.checkUpdates)
    }

    if (currentActiveGame && currentActiveGame.id) {
      this.props.history.push(`/${locale}/contest-wordmaker/${currentActiveGame.id}/waiting`, {game: currentActiveGame})
    }
  }

  componentWillUnmount() {
    clearInterval(this.checkUpdates);
    this.props.resetMultiWordMakerGames();
  }

  _getActiveGames = () => {
    const {page} = this.state;
    this.props.getActiveMultiWordMakerGames(page);

    this.setState({
      intervals: this.state.intervals + 1
    });
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toSubscription = (productId) => {
    this._toggleSubscriptionAlert(false);
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId});
  };

  _getGamesLimit = () => {
    const {subscriptionActive, gamesLimit, todayGamesLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;

    const {userData} = this.props.user;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    let active = false;

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayGamesLimit < gamesLimit;
    }

    return active;
  };

  _renderGameItem = (item, index) => {
    const {userId} = this.props.user;
    const {translate} = this.props;

    let connected = false;
    let invited = false;

    item.users.forEach(user => {
      if (user.user_id == userId) {
        connected = true;
      }
    });
    
    if (item.by_invitation == 1) {
      item.invited.forEach(user => {
        if (user.user_id == userId) {
          invited = true;
        }
      });
    }

    return (
      <div key={index} className="d-flex justify-content-between align-items-center mb-2">
        <div className="flex-grow-0">
          {index + 1}. {item.word.word}
        </div>
        <div className="flex-grow-0">
          {translate('game_users_')} {item.users_count}
        </div>
        <div className="flex-grow-0">
          {
            item.user_id != userId && !connected && (
              <Button
                disabled={item.by_invitation && !invited}
                size="sm"
                variant="outline-secondary"
                onClick={()=>this._joinGame(item.id)}
              >
                {translate('join_game')}
                {item.by_invitation && (
                  <span className="d-block"
                        style={{fontSize: 10, marginTop: -5}}
                  >{translate('by_invitation')}</span>
                ) || null}
              </Button>
            )
          }
        </div>
      </div>
    )
  };

  _createNewGame = (e)=> {
    e.preventDefault();

    const active = this._getGamesLimit();
    this.props.logActivity({activityId: 362});

    if (active) {
      this.props.fetchingData(true);
      this.props.createWordMakerMultiGame(this.props.history);
    } else {
      this._toggleSubscriptionAlert(true);
    }
  };

  _createNewGameInvitation = (e)=> {
    e.preventDefault();
    this.props.logActivity({activityId: 363});

    const active = this._getGamesLimit();
    if (active) {
      this._toggleModal('inviteContactModalVisible', true);

    } else {
      this._toggleSubscriptionAlert(true);
    }
  };

  _joinGame = (gameId)=> {
    const active = this._getGamesLimit();

    this.setState({
      intervals: 0
    });

    if (active) {
      this.props.fetchingData(true);
      this.props.joinMultiWordMakerGame(gameId, this.props.history);
    } else {
      this._toggleSubscriptionAlert(true);
    }
    this.props.logActivity({activityId: 364});
  };

  _toggleModal = (type, status) => {
    this.setState({
      [type]: status
    })
  };

  _toAddContact = (e) => {
    e.preventDefault();
    this._toggleModal('inviteContactModalVisible', false);
    this._toggleModal('addContactModalVisible', true);
  };

  pageClicked = (page) => {
    this.setState({
      page,
    });

    this.props.getActiveMultiWordMakerGames(page);
  };

  render() {
    const {
      activeGamesData,
      activeGamesArray,
      activeGamesFetched,
      currentActiveGame,
      multiMakerRating
    } = this.props.multiWordMaker;
    const {
      subscriptionAlertVisible,
      subscriptionMessage,
      addContactModalVisible,
      inviteContactModalVisible,
      page
    } = this.state;
    const {translate} = this.props;
    const {fetchingData} = this.props.activity;
    const {locale} = this.props.settings;

    return (
      <div className="phrasebooks">
        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate('word_maker_contest')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container className="pageWrapper">

          {
            multiMakerRating && (
              <React.Fragment>
                <h5 className="text-center">
                  {translate('you_multi_maker_rating')}: # {multiMakerRating.position}
                </h5>
                <p className="text-center">
                  ({translate('played_games')}: {multiMakerRating.games}, {translate('scores')}: {multiMakerRating.scores})</p>
              </React.Fragment>
            )
          }
          <div className="d-flex justify-content-center align-items-center"
               style={{height: 50}}
          >

            {
              !currentActiveGame && (
                <React.Fragment>
                  <Button
                    variant="outline-success"
                    className="mb-3 d-inline-block"
                    onClick={(e) => this._createNewGame(e)}
                  >
                    {translate('create_new_game')}
                  </Button>

                  {false && <a href="#"
                               className="ml-3 d-inline-block p-3"
                               onClick={(e) =>this._createNewGameInvitation(e)}
                  >
                    {translate('create_new_game_invitation')}
                  </a>}
                </React.Fragment>
              )
            }
          </div>


          <Card style={{maxWidth: 800, minHeight: 300}} className="ml-auto mr-auto p-3">

            {
              activeGamesFetched && activeGamesArray.length > 0 && (
                activeGamesArray.map((game, index) => {
                  return this._renderGameItem(game, index)
                })
              )
            }

            {
              activeGamesFetched && activeGamesArray.length == 0 && (
                <p style={{
                maxWidth: 400,
                height: 300, fontSize: 20, lineHeight: '30px'
                }}
                   className="nl2br text-center mb-0 ml-auto mr-auto d-flex justify-content-center align-items-center">
                  {translate('no_games_start_new')}
                </p>
              )
            }

          </Card>
          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={subscriptionMessage}
                translate={translate}
              />
            )
          }

          {
            addContactModalVisible && (
              <AddUserContact
                onClose={this._toggleModal}
                isVisible={addContactModalVisible}
                translate={translate}
              />
            )
          }
          {
            inviteContactModalVisible && (
              <InviteUsersToMultiWordMaker
                onClose={this._toggleModal}
                isVisible={inviteContactModalVisible}
                toAddContact={this._toAddContact}
                translate={translate}
              />
            )
          }
        </Container>

        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  multiWordMaker: state.multiWordMaker,
  purchases: state.purchases,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize)

});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    getActiveMultiWordMakerGames: (page) => dispatch(getActiveMultiWordMakerGames(page)),
    resetMultiWordMakerGames: () => dispatch(resetMultiWordMakerGames()),
    createWordMakerMultiGame: (history) => dispatch(createWordMakerMultiGame(history)),
    getUserContacts: () => dispatch(getUserContacts()),
    getTotalRating: () => dispatch(getTotalRating()),
    resetCurrentGame: () => dispatch(resetCurrentGame()),
    cancelMultiWordMakerGame: (gameId, history) => dispatch(cancelMultiWordMakerGame(gameId, history)),
    joinMultiWordMakerGame: (gameId, history) => dispatch(joinMultiWordMakerGame(gameId, history)),


  };
}

export default connect(mapStateToProps, bindAction)(MultiWordMakerGamesList);
