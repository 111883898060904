import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCogs,
  faFilter,
  faTimes,
  faSearch,
  faPlus,
  faSort,
  faBars,
  faVideo,
  faTag
} from '@fortawesome/free-solid-svg-icons';

import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import MyPagination from '../../../components/Custom/MyPagination/index';

const ListToolbar = ({
  lists,
  translate,
  ratio,
  handleKeyPress,
  toolbar,
  active,
  tablet,
  deviceHeight,
  deviceWidth,
  favoriteWords,
  isVisible,
  onClose,
  title,
  toggleFavorite,
  shareListWords,
  disabled,
  realList,
  favourite,
  paste,
  studyLanguage,
  updateList,
  deleteList,
  removeFromCalendar,
  list,
  buyListCards,
  listCards,
  generateCards,
  toVideo,
  lifetimeStatus,
  postpone,
  toggleArchive,
  cleanListCache,
  approvedDictionaryTranslations,
  translationLanguage,
  tour,
  words,
  openTagsModal,
  toBulkMode,
  bulkMode,
  fromDictionary,
  fromGlossaries,
  showDictionary,
  toggleTrainingButtons,
  listWords,
  pageClicked,
  totalPages,
  page,
  listSize,
  userData,
  toFirstTraining,
  listId
}) => (

  <Container fluid>
    <div className={toolbar}>

      <div className="justify-content-between align-items-center flex-md-row flex-column-reverse d-flex">
        <div className="d-flex justify-content-between align-items-center flex-md-row  flex-column-reverse ">
          {
            listWords.length > 0 && !bulkMode && !favoriteWords && realList && (
              <Button
                className="mr-0 mr-md-3"
                style={{width: 200}}
                variant="outline-success"
                onClick={() => {
                  if(userData && userData.active_trainings_set) {
                    toFirstTraining(list)
                  } else {
                     toggleTrainingButtons(true)
                  }
                }}
              >
                {translate('learn_words')}
              </Button>
            ) || null
          }

          {
            listWords.length > 0 && !bulkMode && favoriteWords && (
              <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='top' overlay={<Tooltip>{listSize} {translate('random_words_')}</Tooltip>}>
                <Button
                  className="mr-0 mr-md-3 mt-3 mt-md-0"
                  style={{maxWidth: 200}}
                  variant="outline-success"
                  onClick={() => toggleTrainingButtons(true)}
                >
                  {translate('repeat')}
                </Button>
              </OverlayTrigger>
            )
          }

          {
            favoriteWords && (
              <div>
                <MyPagination
                  range={5}
                  pages={totalPages}
                  ratio={ratio}
                  currentPage={page}
                  tablet={tablet}
                  onClick={pageClicked}
                />
              </div>
            ) || null
          }
        </div>

        <div className="d-flex justify-content-end align-items-center">

          {
            realList && list && list.tag ? (
              <OverlayTrigger
                delay={{ show: 1000, hide: 400 }}
                placement='auto' overlay={<Tooltip>{translate('manage_list_tag')}{" "}{list.tag.tag_name}</Tooltip>}
              >
                <a href="#" className="ml-1 p2" onClick={(e)=>{
                  e.preventDefault();
                  openTagsModal(list);
                }}>
                  <FontAwesomeIcon icon={faTag} style={{fontSize: 20, color: list.tag.color}}/>
                </a>
              </OverlayTrigger>
            ) : null
          }

          {!favoriteWords && <OverlayTrigger
            delay={{ show: 1000, hide: 400 }}
            placement='auto' overlay={<Tooltip>{translate('watch_video_instructions')}</Tooltip>}
          >
            <a href="https://youtu.be/yqU8tXYrVUM" className="ml-1 p2 mr-1" target="_blank">
              <FontAwesomeIcon icon={faVideo} style={{fontSize: 20}}/>
            </a>
          </OverlayTrigger>}

          {!favoriteWords && realList && !tour &&(
            <Dropdown alignRight className="mr-1">
              <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='top' overlay={<Tooltip>{translate('save_words_to_list')}</Tooltip>}>
                <Dropdown.Toggle as="a" className="p2 toggled-link">
                  <FontAwesomeIcon style={{fontSize: 20}} icon={faPlus}/>
                </Dropdown.Toggle>
              </OverlayTrigger>

              <Dropdown.Menu>
                {realList && showDictionary && (
                  <Dropdown.Item as="button" disabled={disabled} onClick={() => disabled ? null : fromDictionary()}>
                    {translate('add_from_dictionary')}
                    {disabled && (
                      <span className="text-muted d-block" style={{fontSize: ratio * 12}}>
                      {translate('list_is_full')}
                    </span>
                    )}
                  </Dropdown.Item>
                )}

                {realList && (
                  <Dropdown.Item as="button" disabled={disabled} onClick={() => disabled ? null : fromGlossaries(true)}>
                    {translate('add_from_glossaries')}
                    {disabled && (
                      <span className="text-muted d-block" style={{fontSize: ratio * 12}}>
                      {translate('list_is_full')}
                    </span>
                    )}
                  </Dropdown.Item>
                )}

                {realList && studyLanguage == 'en' && showDictionary && (
                  <Dropdown.Item as="button" disabled={disabled} onClick={() => disabled ? null : paste()}>
                    {translate('paste_text')}

                    {disabled && (
                      <span className="text-muted d-block" style={{fontSize: ratio * 12}}>
                      {translate('list_is_full')}
                    </span>
                    )}
                  </Dropdown.Item>
                )}

              </Dropdown.Menu>

            </Dropdown>
          ) || null}


              <Dropdown alignRight className="mr-1">
                <OverlayTrigger delay={{ show: 1000, hide: 400 }} placement='top' overlay={<Tooltip>{translate('manage_list_words')}</Tooltip>}>
                  <Dropdown.Toggle as="a" className="p2 toggled-link" variant="outline-secondary">
                    <FontAwesomeIcon style={{fontSize: 20}} icon={faBars}/>
                  </Dropdown.Toggle>
                </OverlayTrigger>

                <Dropdown.Menu>
                  {words > 0 && (
                    <Dropdown.Item as="button" onClick={()=>toBulkMode('vocabulary')}>
                      {translate('list_words_to_vocabulary')}
                    </Dropdown.Item>
                  )}

                  {realList && words > 0 && !favoriteWords && (
                    <Dropdown.Item as="button" onClick={()=>toBulkMode('favorite')}>
                      {translate('list_words_to_favorite')}
                    </Dropdown.Item>
                  )}

                  {words > 0 && (
                    <Dropdown.Item as="button" onClick={()=>toBulkMode('move')}>
                      {translate('list_words_to_move')}
                    </Dropdown.Item>
                  )}

                  <Dropdown.Divider/>

                  {words > 0 && (
                    <Dropdown.Item as="button" className="text-danger" onClick={()=>toBulkMode('delete')}>
                      {translate('list_words_to_delete')}
                    </Dropdown.Item>
                  )}

                </Dropdown.Menu>

              </Dropdown>

          {
            !favoriteWords && (
              <Dropdown alignRight>
                <OverlayTrigger delay={{ show: 1000, hide: 400 }} placement='top' overlay={<Tooltip>{translate('list_cogs')}</Tooltip>}>
                  <Dropdown.Toggle as="a" className="p2 toggled-link">
                    <FontAwesomeIcon style={{fontSize: 20}} icon={faCogs}/>
                  </Dropdown.Toggle>
                </OverlayTrigger>

                <Dropdown.Menu>

                  {realList && list && !list.tag && (
                    <Dropdown.Item as="button" onClick={()=>openTagsModal(list)}>
                      {translate(list && list.tag ? 'manage_list_tag' : 'add_list_tag')}
                    </Dropdown.Item>
                  )}

                  {realList && (
                    <Dropdown.Item as="button" onClick={updateList}>
                      {translate('update_list')}
                    </Dropdown.Item>
                  )}

                  {/*<Dropdown.Item href="https://youtu.be/yqU8tXYrVUM" target="_blank">
                   {translate('watch_video_instructions')}
                   </Dropdown.Item>*/}

                  {realList && false &&(
                    <Dropdown.Item as="button"
                                   onClick={() => (!listCards && !lifetimeStatus) ? buyListCards() : generateCards()}>
                      {(!listCards && !lifetimeStatus) ? `${translate('buy_list_cards')}` : translate('generate_list_cards')}
                    </Dropdown.Item>
                  )}

                  <Dropdown.Divider />

                  {realList && (
                    <Dropdown.Item as="button" onClick={toggleArchive}>
                      {translate(list.archive ? 'delete_from_archive' : 'add_to_archive')}
                    </Dropdown.Item>
                  )}

                  {realList && (
                    <Dropdown.Item as="button" onClick={toggleFavorite}>
                      {favourite ? translate('delete_list_from_favorites') : translate('add_list_to_favorites') }
                    </Dropdown.Item>
                  )}

                  <Dropdown.Divider />

                  {realList && (
                    <Dropdown.Item as="button" className="text-danger" onClick={cleanListCache}>
                      {translate('clean_list_cache')}
                    </Dropdown.Item>
                  )}

                  {realList && list && list.intervals && list.intervals.length > 0 && (
                    <Dropdown.Item as="button" onClick={postpone}>
                      {translate('postpone_one_day')}
                    </Dropdown.Item>
                  )}

                  {realList && list && list.intervals && list.intervals.length > 0 && (
                    <Dropdown.Item as="button" className="text-danger" onClick={()=>removeFromCalendar(true)}>
                      {translate('remove_from_calendar')}
                    </Dropdown.Item>
                  )}

                  {realList && (
                    <Dropdown.Item as="button" className="text-danger" onClick={deleteList}>
                      {translate('delete_list')}
                    </Dropdown.Item>
                  )}

                </Dropdown.Menu>
              </Dropdown>
            ) || null
          }

        </div>
      </div>
    </div>
  </Container>
);

export default ListToolbar;
