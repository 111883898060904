import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash, faTrash} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import Loader from "../../../components/Custom/Loader/index";

import {manageNews} from '../../../actions/newsActions';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

import colors from '../../../lib/colors';

class AlertNews extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {news} = this.props;
    news.map((currentArticle)=> {
      if (!currentArticle.seen) {
        this.props.manageNews(currentArticle.id, 'seen', true);
      }
    })
  }

  getLink = (currentArticle) => {
    const {translate} = this.props;

    if (currentArticle && currentArticle.link) {
      if (currentArticle.link_type == 'review') {
        return (<div className="d-flex justify-content-center mt-3">
          <a className="mr-3" href="https://itunes.apple.com/app/id1438980777" target="_blank">
            <Image
              style={{height:50}}
              src={require('../../../assets/images/app-store-clear.png')}/>
          </a>

          <a href="http://play.google.com/store/apps/details?id=com.mnemonicrn" target="_blank">
            <Image
              style={{height:50}}
              src={require('../../../assets/images/google-play-clear-.png')}/>
          </a>
        </div>)
      } else if (currentArticle.link_type == 'out') {
        return <a href={currentArticle.link} target="_blank" className="btn btn-outline-success mt-3">
          {translate('open_link')}
        </a>
      } else {
        return (<Button
          className="mt-3"
          variant="outline-info"
          style={{alignSelf: 'center'}}
          onClick={this._toLink}
        >
          {translate('open_link')}
        </Button>)
      }
    } else {
      return null;
    }
  };

  render() {
    const {isVisible, onClose, translate, news} = this.props;
    const {fetchingData} = this.props.activity;

    const tablet = false;
    return (
      <Modal
        className="alertnews"
        show={isVisible}
        onHide={()=>onClose(false)}
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h4" style={{color: colors.tealish}}>
            {translate('hot_news')}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">
          <div style={{width: '100%', marginBottom: 20, maxHeight: 400, overflowY: 'scroll'}}>
            {
              news.map((currentArticle, i) => {
                return (
                  <Card
                    key={i}
                    className="mb-3" style={{maxWidth: 500, margin: '0 auto', padding: 20}}>
                    <p
                      className="title"
                      style={{marginBottom: !tablet ? 5 : 10, fontSize: 18}}
                    >
                      {currentArticle ? currentArticle.title : ''}
                    </p>

                    <p className="content" style={{fontSize: 16}}>
                      {currentArticle ? currentArticle.content : ''}
                    </p>

                    {this.getLink(currentArticle)}
                  </Card>
                )
              })
            }
          </div>
        </Modal.Body>
        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  activity: state.activity,
});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    manageNews: (newsId, type, alert) => dispatch(manageNews(newsId, type, alert)),
    fetchingData: (status) => dispatch(fetchingData(status)),
  };
}

export default connect(mapStateToProps, bindAction)(AlertNews);
