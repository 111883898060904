import React, {Component} from 'react';
import {Translate, getTranslate} from 'react-localize-redux';
import {connect} from "react-redux";

import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import ReportBug from '../../../components/Modals/ReportBug/index';

class Policy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bugModalVisible: false
    };
  }

  _toggleBugModal = (status) => {
    this.setState({
      bugModalVisible: status
    });
  };

  render() {
    const {translate} = this.props;
    const {bugModalVisible} = this.state;

    return (  <div style={{marginTop: 90}}>
        <Container>
          <Card style={{padding: "20px 40px"}}>
            <h2>{translate('privacy.privacy_policy')}</h2>
            <p>{translate('privacy.date')} {translate('September')} 01, 2020</p>

            <p>{translate('privacy.operates_the_website')}</p>

            <p>{translate('privacy.this_page_informs')}</p>

            <p>
              {translate('privacy.we_use_your_data')} {translate('privacy.by_using_the_service')} {translate('privacy.unless_otherwise_defined')}
            </p>

            <h4>{translate('privacy.information_collection')}</h4>

            <p>{translate('privacy.we_collect_several')}</p>

            <h4>{translate('privacy.types_of_data')}</h4>

            <h4>{translate('privacy.personal_data')}</h4>

            <p>{translate('privacy.while_using')} {translate('privacy.personally_identifiable')}</p>

            <ul>
              <li>{translate('privacy.email_address')}</li>
              <li>{translate('privacy.first_name_and')}</li>
              <li>{translate('privacy.country_and_city')}</li>
              <li>{translate('privacy.cookies_and')}</li>
            </ul>

            <h4>{translate('privacy.usage_data')}</h4>

            <p>{translate('privacy.we_may_also_collect')}</p>
            <p>{translate('privacy.this_usage_data')} {translate('privacy.browser_type')} {translate('privacy.the_time_spent')}</p>
            <p>{translate('privacy.when_you_access')} {translate('privacy.the_IP_address')}</p>

            <h4>{translate('privacy.tracking_cookies')}</h4>
            <p>{translate('privacy.we_use_cookies')}</p>
            <p>{translate('privacy.cookies_are_files')} {translate('privacy.cookies_are_sent')} {translate('privacy.tracking_technologies')}</p>
            <p>{translate('privacy.you_can_instruct')} {translate('privacy.however_if_you')}</p>
            
            <p>{translate('privacy.examples_of_cookies')}</p>
            <ul>
              <li><strong>{translate('privacy.session_cookies')}</strong> {translate('privacy.we_use_session_cookies')}</li>
              <li><strong>{translate('privacy.preference_Cookies')}</strong> {translate('privacy.we_use_preference')}</li>
              <li><strong>{translate('privacy.security_cookies')}</strong> {translate('privacy.we_use_security')}</li>
            </ul>

            <h4>{translate('privacy.use_of_data')}</h4>

            <p>{translate('privacy.uses_the_collected_data')}</p>
            <ul>
              <li>{translate('privacy.to_provide_and_maintain')}</li>
              <li>{translate('privacy.to_notify_you')}</li>
              <li>{translate('privacy.to_allow_you')}</li>
              <li>{translate('privacy.to_provide_customer')}</li>
              <li>{translate('privacy.to_provide_analysis')}</li>
              <li>{translate('privacy.to_monitor')}</li>
              <li>{translate('privacy.to_detect')}</li>
            </ul>

            <h4>{translate('privacy.transfer_of_data')}</h4>
            <p>{translate('privacy.your_information')} {translate('privacy.country_or_other')}</p>
            <p>{translate('privacy.if_you_are_located')}</p>
            <p>{translate('privacy.your_consent')}</p>
            <p>{translate('privacy.will_take_all')} {translate('privacy.and_no_transfer')}</p>

            <h4>{translate('privacy.disclosure_of_data')}</h4>

            <h4>{translate('privacy.legal_requirements')}</h4>
            <p>{translate('privacy.may_disclose')}</p>
            <ul>
              <li>{translate('privacy.to_comply_with')}</li>
              <li>{translate('privacy.to_protect')}</li>
              <li>{translate('privacy.to_prevent_or')}</li>
              <li>{translate('privacy.to_protect_the_personal')}</li>
              <li>{translate('privacy.to_protect_against_legal')}</li>
            </ul>

            <h4>{translate('privacy.security_of_data')}</h4>
            <p>{translate('privacy.the_security_of_your')} {translate('privacy.while_we_strive')}</p>

            <h4>{translate('privacy.service_providers')}</h4>
            <p>{translate('privacy.we_may_employ')} {translate('privacy.to_provide_the_service')}</p>
            <p>{translate('privacy.these_third_parties')}</p>

            <h4>{translate('privacy.analytics')}</h4>
            <p>{translate('privacy.we_may_use_third_party')}</p>
            <ul>
              <li>
                <p><strong>Google Analytics</strong></p>
                <p>Google Analytics {translate('privacy.is_a_web_analytics')} {translate('privacy.google_uses')} {translate('privacy.this_data_is')} {translate('privacy.google_may_use')}</p>
                <p>{translate('privacy.for_more_information')} <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=ru</a></p>
              </li>
            </ul>

            <h4>{translate('privacy.links_to_other')}</h4>
            <p>{translate('privacy.our_service_may')} {translate('privacy.if_you_click')} {translate('privacy.we_strongly_advise')}</p>
            <p>{translate('privacy.we_have_no_control')}</p>


            <h4>{translate('privacy.children_privacy')}</h4>
            <p>{translate('privacy.our_service_does')}</p>
            <p>{translate('privacy.we_do_not_knowingly')} {translate('privacy.if_you_are_a_parent')} {translate('privacy.if_we_become_aware')}</p>
            
            <h4>{translate('privacy.changes')}</h4>
            <p>{translate('privacy.we_may_update')} {translate('privacy.we_will_notify')}</p>
            <p>{translate('privacy.we_will_let_you')}</p>
            <p>{translate('privacy.you_are_advised')} {translate('privacy.changes_to_this')}</p>


            <h4>{translate('privacy.contact_us')}</h4>
            <p>{translate('privacy.if_you_have_any')} <a
              className="text-info text-center mr-3"
              href="#"
              onClick={(e)=> {
                e.preventDefault();
                this._toggleBugModal(true)
              }}
            >{translate('privacy.text_us')}</a></p>
          </Card>
        </Container>
        {
          bugModalVisible && (
            <ReportBug
              onClose={this._toggleBugModal}
              isVisible={bugModalVisible}
              type="contact"
              training={false}
              extra={false}
            />
          )
        }
      </div>
    )
  }
}


const mapStateToProps = (state, dispatch) => ({
  translate: getTranslate(state.localize)
});

export default connect(mapStateToProps, null)(Policy);
