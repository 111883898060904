import React, {Component} from 'react';
import {connect} from "react-redux";
import _ from 'lodash';
import {withRouter, Link} from 'react-router-dom';
import {Helmet} from "react-helmet";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle, faCogs, faCheckCircle, faTimes, faSearch, faPlus, faStar} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import {Translate, getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';

import GlossaryWord from '../../../components/Cells/GlossaryWord/index';
import GlossaryToolbar from '../../../components/Toolbars/GlossaryToolbar/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
import GenerateListCards from '../../../components/Modals/GenerateListCards/index';
import RegisterAlert from '../../../components/Modals/RegisterAlert/index';

import Loader from "../../../components/Custom/Loader/index";

import {
  getGlossary,
  searchWordsInThisGlossary,
  resetGlossaryWords,
  resetGlossaryWordsWithoutPages,
  toggleGlossaryWordInVocabulary,
  toggleGlossaryWordInFavorites,
  toggleGlossaryInFavorites,
  gettingNewChunk,
  setCachedGlossaryWords,
  appendCachedGlossaryWords,
  resetGlossaryWordsData,
  resetCurrentChildGlossary,
  setCurrentChild,
  getCurrentGlossaryData,
} from '../../../actions/glossaryActions.js'
import {
  getUserListsToFill,
  fillListFromGlossaryWithSelectedWords,
  getListsNumber, toggleNewListsModal, setCurrentList,
  toggleListTrainingButtonsModal,
  addNewList,
  toggleWordInUserList,
  getListWords,
} from '../../../actions/lists';
import {fetchingData} from '../../../actions/activity';
import {clearGlossaryCache} from '../../../actions/cacheActions';
import {logActivity, logPurchase} from '../../../actions/logActions';
import {shareGlossary, shareWord} from '../../../actions/shareActions';
import {getUserContacts} from '../../../actions/userContactsActions';

import colors from '../../../lib/colors';
import * as constants from '../../../lib/constants';

class GlossaryWords extends Component {

  constructor(props) {
    super(props);

    this.glossaryWordsRef = null;

    this.state = {
      searchBarVisible: false,
      trainingsModalVisible: false,
      registerModalVisible: false,
      refreshing: false,
      page: 1,
      listName: '',
      glossaryId: this.props.match.params.id,
      currentChunk: 0,
      totalWords: 0,
      selectedWords: [],
      listsModalIsVisible: false,
      flashCardsModalVisible: false,
      listSize: 10,
      word: '',
      scrolled: 0,
      currentWord: false,
      deviceWidth: window.innerWidth,
      deviceHeight: window.innerHeight,

      shouldShowAddToGlossaryAlert: false,
      alertAddToGlossaryVisible: false,
      subscriptionAlertVisible: false,
      subscriptionMessage: '',
      contactsModalVisible: false,
      whatToShare: 'with_glossary',
      glossaryWordsSettingsVisible: false,
      tablet: false,
      back: false,
      shouldGetWords: false,
      generateListCardsModal: false,
      tour: localStorage.getItem('tour'),
      list: {}

    };

    this.timeOut = null;
    this.timeOut1 = null;
    this.timeOut2 = null;
    this.onChangeTextDelayed = _.debounce(this._onChangeText, 750);
  }

  componentDidMount() {
    const {currentChild} = this.props.glossaries;
    const {glossaryId} = this.state;
    
    if (this.props.history.location && this.props.history.location.state) {
      this.setState({
        list: this.props.history.location.state.list,
        back: this.props.history.location.state.back,
      })
    } else {
      this.props.history.goBack();
    }

    if (!currentChild.id) {
      this.props.getCurrentGlossaryData(glossaryId)
    }

    this.getGlossaryWordsInfo();

    window.addEventListener('scroll', this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    const {list, shouldGetWords} = this.state;
    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut1);
    clearTimeout(this.timeOut2);
    
    window.removeEventListener('scroll', this.handleScroll);

    this.props.resetGlossaryWords();
    this.props.resetCurrentChildGlossary();

    if(list && list.id && shouldGetWords) {
      this.props.getListWords(list.id);
    }

  };

  updateWindowDimensions() {
    this.setState({
      deviceWidth: window.innerWidth,
      deviceHeight: window.innerHeight
    });
  }

  handleScroll() {
    const scrollY = window.scrollY;

    this.setState({
      scrolled: scrollY
    });
  }

  getGlossaryWordsInfo = () => {
    const {isConnected} = this.props.settings;

    let cachedWords = this.checkForCachedWords(this.state.page);

    if (!cachedWords) {
      if (isConnected) {
        this.props.fetchingData(true);
        this.getGlossaryWords(this.state.page);
      }
    } else {
      this.props.setCachedGlossaryWords(cachedWords)
    }
    if (isConnected) {
      this.props.getListsNumber();

      this.timeOut2 = setTimeout(() => {
        this.props.getUserListsToFill();
      }, 500);
    }
  };

  checkForCachedWords = (page) => {
    const {cachedGlossariesWords} = this.props.cache;
    const {currentChild} = this.props.glossaries;

    let pageWords = false;

    cachedGlossariesWords.forEach(glossary => {
      if (glossary.glossaryId == currentChild.id) {
        glossary.pages.forEach(p => {
          if (p.page == page) {
            pageWords = {
              words: p.words,
              totalGlossaryWords: glossary.totalGlossaryWords,
              totalPages: glossary.totalPages,
            };
          }
        })
      }
    });

    return pageWords;
  };

  handleBackPress = async() => {
    this.props.history.goBack();
    return true;
  };

  getGlossaryWords = (page) => {
    const {glossaryId} = this.state;

    if (!glossaryId) {
      this.props.history.goBack();
    } else {
      this.props.getGlossary(glossaryId, page)
    }
  };

  _onChangeText() {
    const {page, glossaryId} = this.state;

    if (this.state.word != "") {
      this.props.fetchingData(true);

      this.props.searchWordsInThisGlossary(glossaryId, this.state.word)
    } else {
      let cachedWords = this.checkForCachedWords(page);

      if (!cachedWords) {
        this.props.resetGlossaryWords();

        this.props.fetchingData(true);
        this.props.getGlossary(glossaryId, page)
      } else {
        this.props.setCachedGlossaryWords(cachedWords)
      }
    }
  }

  onWordSelected = (word) => {
    let {selectedWords, list} = this.state;

    const index = selectedWords.findIndex(id => id === word.id);

    if(list && list.id) {
      this.onWordSelectedForList(word,index)
    } else {
      if (index === -1) {
        selectedWords.push(word.id);
      } else {
        selectedWords.splice(index, 1);
      }

      this.setState({
        selectedWords: selectedWords
      });
    }
  };

  onWordSelectedForList = (word, index) => {
    const {currentList} = this.props.lists;
    const {userData} = this.props.user;
    const {translate} = this.props;
    const {glossaryId} = this.state;
    
    if (currentList.words === userData.list_size && !word.in_user_lists) {
      toastr.warning('', translate('full_list'));
    } else {
      this.props.fetchingData(true);

      this.setState({
        shouldGetWords: true
      });

      this.props.toggleWordInUserList({
        glossaryId,
        listId: this.state.list.id,
        wordId: word.id,
        index,
        status: word.in_list
      });
    }
  };

  vocabularyToggle = (id, index) => {
    if(!this.state.tour) {
      this.props.toggleGlossaryWordInVocabulary(id, index)
    } else {
      this._toggleRegisterModal(true);
    }
  };

  favoriteWordToggle = (id, index) => {
    if(!this.state.tour) {
      this.props.toggleGlossaryWordInFavorites(id, index);
    } else {
      this._toggleRegisterModal(true);
    }
  };

  playTrack = (url) => {
    const {isConnected} = this.props.settings;

    if (isConnected) {
      this.track = new Audio(url);
      this.track.play();
    }
  };

  _renderItem = ({item, index}) => {
    const {fontSizeRation, version} = this.props.settings;
    const {tablet, selectedWords, list} = this.state;
    const {userData} = this.props.user;
    const {translate} = this.props;
    let audioSource = constants.BACKEND_URL;

    let imageWidth = 200;

    let selected = false;

    selectedWords.map(i => {
      if (i === item.id) {
        selected = true;
      }
    });

    const translation = item.translation && item.translation.translation ? item.translation.translation : "";
    const imageUrl = item.image && item.image.filename ? item.image.filename : false;
    let audio = item.word.audio ? item.word.audio : false;

    if (userData && userData.audio_language && item.word[userData.audio_language]) {
      audio = item.word[userData.audio_language];
      audioSource = constants.S3
    }

    return (
      <GlossaryWord
        list={list}
        key={index}
        index={index}
        imageUrl={imageUrl}
        translation={translation}
        imageWidth={imageWidth}
        word={item}
        inVocabulary={item.in_vocabulary}
        inList={item.in_user_lists}
        inFavorite={item.is_favourite}
        selected={selected}
        tablet={tablet}
        version={version}
        ratio={fontSizeRation}
        onPlay={this.playTrack}
        audio={audio}
        audioSource={audioSource}
        onSelectedItem={this.onWordSelected}
        translate={translate}
        vocabularyToggle={this.vocabularyToggle}
        favoriteWordToggle={this.favoriteWordToggle}
      />
    );
  };

  toggleFavoriteGlossary = (id) => {
    if(!this.state.tour) {
      this.props.toggleGlossaryInFavorites(id)
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toSubscription = (productId) => {
    this._toggleSubscriptionAlert(false);
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId});
  };

  deselectWords = () => {
    this.setState({
      selectedWords: []
    })
  };

  selectWords = () => {
    const {glossaryWords, currentChild} = this.props.glossaries;
    const {userData} = this.props.user;
    const listSize = userData && userData.list_size ? userData.list_size : 10;

    let counter = 0;

    glossaryWords.map((word, i)=> {
      if (counter < listSize && !word.in_user_lists) {
        counter++;
        this.onWordSelected(word)
      }
    });
  };

  fillLists = (id) => {
    const {currentChild} = this.props.glossaries;

    const {listsLimit, applyLimit, webSubscriptionStatus} = this.props.purchases;
    const {listsNumber} = this.props.lists;

    let active = false;

    if (webSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      if (id == 0) {
        active = listsNumber < listsLimit;
      } else {
        active = true;
      }
    }

    if (active) {
      this.props.fetchingData(true);

      this.props.fillListFromGlossaryWithSelectedWords({
        listId: id,
        glossaryId: currentChild.id,
        glossaryName: currentChild.glossary_name,
        selectedWords: this.state.selectedWords
      });

      this.timeOut = setTimeout(()=> {
        this.setState({
          selectedWords: []
        });
      }, 1000);

      this.props.logActivity({activityId: id != 0 ? 19 : 20, model: 'list', modelId: id});
    } else {
      this.setState({
        subscriptionMessage: 'subscription_lists'
      });
      this._toggleSubscriptionAlert(true);
    }
  };
  
  toggleFlashCardsModal = (status) => {
    const {currentChild} = this.props.glossaries;

    if (status) {
      this.props.logActivity({activityId: 107, model: 'glossary', modelId: currentChild.id});
    }

    this.setState({
      flashCardsModalVisible: status
    })
  };

  _buyCards = () => {
    const {currentChild} = this.props.glossaries;
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: 'glossary_cards'});

    this.props.logActivity({activityId: 109, model: 'glossary', modelId: currentChild.id});
  };

  generateCards = (sample) => {
    const {currentChild, totalGlossaryWords} = this.props.glossaries;

    this._toggleModal('generateListCardsModal', true);

    this.props.logActivity({activityId: 108, model: 'glossary', modelId: currentChild.id});
  };

  pageClicked = (page) => {
    const {currentChild} = this.props.glossaries;
    const {isConnected} = this.props.settings;
    const {glossaryId, tour} = this.state;

    if(tour == 1) {
      this._toggleRegisterModal(true);
    } else {
      let cachedWords = this.checkForCachedWords(page);

      this.setState({
        page,
        word: '',
        searchBarVisible: false
      });

      this.props.resetGlossaryWordsData();

      this.timeOut1 = setTimeout(() => {
        if (!cachedWords) {
          if (isConnected) {
            this.props.fetchingData(true);

            this.props.getGlossary(glossaryId, page)
          }
        } else {
          this.props.setCachedGlossaryWords(cachedWords)
        }
      }, 100)
    }
  };

  _clearGlossaryCache = () => {
    const {translate} = this.props;
    const {glossaryId} = this.state;

    this.props.clearGlossaryCache(glossaryId);

    toastr.info("", translate('glossary_cache_was_reset'), [{text: "Ok"}]);
    this.props.logActivity({activityId: 300});
  };

  _typeGlossaryWord = (word) => {
    this.setState({word});
    this.onChangeTextDelayed(word);
  };

  _searchGlossaryWords = () => {
    const {word, page, glossaryId} = this.state;

    if (word != "") {
      this.props.fetchingData(true);

      this.props.searchWordsInThisGlossary(glossaryId, this.state.word)
    } else {
      let cachedWords = this.checkForCachedWords(page);

      if (!cachedWords) {
        this.props.resetGlossaryWords();

        this.props.fetchingData(true);
        this.props.getGlossary(glossaryId, page)
      } else {
        this.props.setCachedGlossaryWords(cachedWords)
      }
    }
  };

  _clearFoundGlossaryWords = () => {
    this.setState({
      word: ''
    });

    this._searchGlossaryWords();
  };

  _handleKeyPress = (type, event) => {
    const {word, listName} = this.state;

    if (event.key === 'Enter') {
      if (type == 'search' && word.length > 0) {
        this._searchGlossaryWords();
      } else if (type == 'list' && listName.length > 0) {
        this._saveNewList();
      }
    }
  };

  _renderListsToFill = () => {
    const {listsToFill} = this.props.lists;
    const {userData} = this.props.user;
    const {translate} = this.props;
    const {selectedWords} = this.state;
    const listSize = userData && userData.list_size ? userData.list_size : 10;

    return listsToFill.map((list, index) => {
      if ((listSize - list.words) >= selectedWords.length) {
        return <Dropdown.Item href="#" key={index}
                              disabled={selectedWords.length === 0}
                              onClick={(e)=>{
            e.preventDefault();
            if(selectedWords.length > 0) {
               this.fillLists(list.id)
            }
          }}
        >
          {list.list_name}{" "}<span className="text-muted">{translate('lwords')} - {list.words}</span>
        </Dropdown.Item>
      }
    })
  };

  _typeNewListName = (listName) => {
    let name = listName.length <= 25 ? listName : listName.substr(0, 25);
    name = name.length == 0 ? name : `${name.charAt(0).toUpperCase()}${name.slice(1)}`;

    this.setState({
      listName: name
    })
  };

  _saveNewList = () => {
    const {listName} = this.state;
    const {listsLimit, applyLimit, webSubscriptionStatus} = this.props.purchases;
    const {listsNumber} = this.props.lists;

    let active = false;

    if (webSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
        active = listsNumber < listsLimit;
    }

    if(active) {
      if (listName.length > 0) {
        this.props.fetchingData(true);
        this.props.addNewList(listName);

        this.setState({
          listName: ''
        })
      }
    } else {
      this.setState({
        subscriptionMessage: 'subscription_lists'
      });
      this._toggleSubscriptionAlert(true);
    }
  };

  _toggleModal = (modal, status) => {
    this.setState({
      [modal]: status
    })
  };

  _toggleRegisterModal = (status) => {
    this.setState({
      registerModalVisible: status
    })
  };

  _toRegister = () => {
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/register`)
  };

  render() {
    const {currentChild, glossaryWords, totalGlossaryWords, totalPages} = this.props.glossaries;
    const {fetchingData} = this.props.activity;
    const {currentList} = this.props.lists;
    const {lifetimeStatus} = this.props.purchases;
    const {selectedWords, list} = this.state;

    const {
      word,
      tablet,
      page,
      glossaryId,
      listName,
      subscriptionAlertVisible,
      subscriptionMessage,
      scrolled,
      back,
      generateListCardsModal,
      registerModalVisible,
      tour
    } = this.state;
    const {translate} = this.props;

    const {fontSizeRation, locale} = this.props.settings;
    const {userData} = this.props.user;

    const listSize = userData && userData.list_size ? userData.list_size : 10;

    let cards = userData && userData.glossary_cards ? userData.glossary_cards : 0;

    const lists = this._renderListsToFill();

    const toolbar = classNames({
      'fixed-toolbar': scrolled >= 50,
      'justify-content-between glossaryToolbar justify-content-center mb-4': true
    });

    return (
      <div className="glossaryWords position-relative">
        <Breadcrumb style={{marginTop: 50}}>
          {
            !back ? (
              <React.Fragment>
                <li className="breadcrumb-item">
                  <Link to={`/${locale}/home`}>
                    {translate('home')}
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/${locale}/glossaries`}>
                    {translate('glossaries')}
                  </Link>
                </li>
                <Breadcrumb.Item active>
                  {currentChild.glossary_name}
                </Breadcrumb.Item>
              </React.Fragment>
            ) : (
              <li className="breadcrumb-item">
                <a href="#" onClick={(e)=>{
                  e.preventDefault();
                  this.props.history.goBack()
                }}>
                  {translate('cancel_back')}
                </a>
              </li>
            )
          }
          
        </Breadcrumb>

        <GlossaryToolbar
          selectedWords={selectedWords}
          toolbar={toolbar}
          translate={translate}
          totalPages={totalPages}
          page={page}
          ratio={fontSizeRation}
          tablet={tablet}
          words={glossaryWords}
          word={word}
          lists={lists}
          cards={cards}
          lifetimeStatus={lifetimeStatus}
          glossaryId={glossaryId}
          listName={listName}
          currentChild={currentChild}

          pageClicked={this.pageClicked}
          handleKeyPress={this._handleKeyPress}
          typeGlossaryWord={this._typeGlossaryWord}
          clearFoundGlossaryWords={this._clearFoundGlossaryWords}
          searchGlossaryWords={this._searchGlossaryWords}
          typeNewListName={this._typeNewListName}
          saveNewList={this._saveNewList}
          toggleFavoriteGlossary={this.toggleFavoriteGlossary}

          generateCards={this.generateCards}
          buyCards={this._buyCards}
          tour={tour}
        />

        <Container className="pageWrapper">

          {list && list.words && currentList && (
            <div className="listDataWrapper">
              <span className="listName d-block" style={{fontSize: fontSizeRation * 16}}>
                {list.list_name}</span>
              <span
                className="listCapacity d-block"
                style={{fontSize: fontSizeRation * 14}}>
                {`${currentList.words}/${listSize}`}
              </span>
            </div>
          )|| null}

          <div
            style={{opacity: glossaryWords.length == 0 ? 0 : 1}}
            className="text-center font-weight-bold">
            <span style={{fontSize: fontSizeRation * 16, color: colors.greyishBrown}}>
              {translate(list && list.id ? 'click_word_to_toggle' : 'select_some_glossary_words')}
            </span>
          </div>

          <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
            {glossaryWords.map((word, index) => {
              return this._renderItem({item: word, index})
            })}
          </div>

          {
            generateListCardsModal && (
              <GenerateListCards
                isVisible={generateListCardsModal}
                onClose={this._toggleModal}
                glossaryName={currentChild.glossary_name}
                glossaryId={currentChild.id}
                type={'glossary'}
                words={totalGlossaryWords}
                totalPages={totalPages}
              />
            )
          }

          {subscriptionAlertVisible && (
            <SubscriptionAlert
              onClose={this._toggleSubscriptionAlert}
              isVisible={subscriptionAlertVisible}
              toSubscription={this._toSubscription}
              title={'subscription_title'}
              description={subscriptionMessage}
              translate={translate}
            />
          )}

          {registerModalVisible && (
            <RegisterAlert
              onClose={this._toggleRegisterModal}
              isVisible={registerModalVisible}
              translate={translate}
              toRegister={this._toRegister}
            />
          )}
        </Container>
        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  glossaries: state.glossaries,
  lists: state.lists,
  activity: state.activity,
  purchases: state.purchases,
  userContacts: state.userContacts,
  settings: state.settings,
  cache: state.cache,
  translate: getTranslate(state.localize)
});

function bindAction(dispatch) {
  return {
    fetchingData: (status) => dispatch(fetchingData(status)),
    toggleGlossaryWordInVocabulary: (word_id, index) => dispatch(toggleGlossaryWordInVocabulary(word_id, index)),
    toggleGlossaryWordInFavorites: (word_id, index) => dispatch(toggleGlossaryWordInFavorites(word_id, index)),
    toggleGlossaryInFavorites: (glossary_id) => dispatch(toggleGlossaryInFavorites(glossary_id)),
    getGlossary: (id, page) => dispatch(getGlossary(id, page)),
    searchWordsInThisGlossary: (glossaryId, word) => dispatch(searchWordsInThisGlossary(glossaryId, word)),
    getUserListsToFill: () => dispatch(getUserListsToFill()),
    resetGlossaryWords: () => dispatch(resetGlossaryWords()),
    resetCurrentChildGlossary: () => dispatch(resetCurrentChildGlossary()),
    resetGlossaryWordsData: () => dispatch(resetGlossaryWordsData()),
    fillListFromGlossaryWithSelectedWords: (data) => dispatch(fillListFromGlossaryWithSelectedWords(data)),
    logActivity: (data) => dispatch(logActivity(data)),
    getListsNumber: () => dispatch(getListsNumber()),
    resetGlossaryWordsWithoutPages: () => dispatch(resetGlossaryWordsWithoutPages()),
    logPurchase: (data) => dispatch(logPurchase(data)),
    setCachedGlossaryWords: (data) => dispatch(setCachedGlossaryWords(data)),
    appendCachedGlossaryWords: (data) => dispatch(appendCachedGlossaryWords(data)),
    shareGlossary: (glossaryId, contacts) => dispatch(shareGlossary(glossaryId, contacts)),
    shareWord: (wordId, contacts) => dispatch(shareWord(wordId, contacts)),
    getUserContacts: () => dispatch(getUserContacts()),
    gettingNewChunk: () => dispatch(gettingNewChunk()),
    toggleNewListsModal: () => dispatch(toggleNewListsModal()),
    setCurrentList: (list) => dispatch(setCurrentList(list)),
    toggleListTrainingButtonsModal: (status) => dispatch(toggleListTrainingButtonsModal(status)),
    clearGlossaryCache: (id) => dispatch(clearGlossaryCache(id)),
    getCurrentGlossaryData: (id) => dispatch(getCurrentGlossaryData(id)),
    setCurrentChild: (child) => dispatch(setCurrentChild(child)),
    addNewList: (listName) => dispatch(addNewList(listName)),
    getListWords: (id) => dispatch(getListWords(id)),
    toggleWordInUserList: (data) => dispatch(toggleWordInUserList(data))
  };
}

export default connect(mapStateToProps, bindAction)(withRouter(GlossaryWords));
