import request from 'superagent';
import {select, put, call, all} from 'redux-saga/effects';

import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {getListsByLangF, getFavoriteWords} from './favoritesSagas';
import {logApiError} from './logSagas';
import {getVocabulary} from './vocabulary';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* getListsByLang(data) {
  const {userId, userData} = yield select(state => state.user);
  const {showArchive} = yield select(state => state.lists);
  const {selectedTags} = yield select(state => state.tags);

  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'getUserListsWithImages')
    .send({
      user_id: userId && userId > 0 ? userId : 1,
      filter: params.filter,
      order: params.order,
      tags: selectedTags,
      hideArchive: userData.show_archive == "0" ? "1" : "0"
    });

    // console.log("getListsByLang", result.body)

    yield put({
      type: types.SET_USER_LISTS,
      payload: result.body
    });

    yield put({
      type: types.SET_CACHED_LISTS,
      payload: result.body
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getListsByLang',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* searchUserLists(data) {
  const {userId} = yield select(state => state.user);
  const {showArchive} = yield select(state => state.lists);
  const params = data.payload;

  try {
    const result = yield request
    .get(API_URL + 'searchUserListsWithImages')
    .query({
      user_id: userId,
      search: params,
    });

    // console.log("searchUserListsWithImages", result.body)

    yield put({
      type: types.SET_USER_LISTS,
      payload: result.body
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'searchUserListsWithImages',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getListsNumber() {
  const {userId, userData} = yield select(state => state.user);
  const {showArchive} = yield select(state => state.lists);

  try {
    const result = yield request
    .get(API_URL + 'getListsNumber')
    .query({
      user_id: userId && userId > 0 ? userId : 1,
      hideArchive: userData.show_archive == "0" ? "1" : "0"
    });

    yield put({
      type: types.SET_LISTS_NUMBER,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getListsNumber',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* getListWords(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    // .post(API_URL + 'getListWords')
    .post(API_URL + 'getListWordsO')
    .send({
      user_id: userId && userId > 0 ? userId : 1,
      list_id: data.payload
    });

    // console.log("getListWords", result.body)

    yield put({
      type: types.SET_LIST_WORDS,
      payload: result.body
    });

    if (data.payload != 0) {
      yield put({
        type: types.SET_VISITED_CACHED_LISTS,
        payload: {
          listId: data.payload,
          listWords: result.body.data
        }
      });
    }

    yield put({
      type: types.UPDATE_CURRENT_LIST_WORDS,
      payload: result.body.data.length
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getListWords',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


export function* getCurrentListData(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getCurrentListData')
    .query({
      user_id: userId && userId > 0 ? userId : 1,
      list_id: data.payload
    });

    // console.log("getCurrentListData", result.body)

    yield put({
      type: types.SET_CURRENT_LIST,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getCurrentListData',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getListsToRepeat(data) {
  const {showArchive} = yield select(state => state.lists);
  const {userData} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'getListsToRepeatWithImages')
    .send({
      user_id: data.payload.userId,
      limit: data.payload.limit,
      hideArchive: userData.show_archive == "0" ? "1" : "0"
    });

    // console.log("getListsToRepeat", result.body)

    yield put({
      type: types.SET_LISTS_TO_REPEAT,
      payload: result.body.data
    });

    yield put({
      type: types.SET_CACHED_HOME_LISTS,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getListsToRepeatWithImages',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getOnlyMistakes() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getOnlyMistakes')
    .query({
      user_id: userId,
    });

    yield put({
      type: types.SET_ONLY_MISTAKES,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getOnlyMistakes',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* postponeInCalendar(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'postponeInCalendar')
    .send({
      user_id: userId,
      list_id: data.payload
    });

    if (result.statusCode === 200) {
      yield put({
        type: types.UPDATE_LIST_INTERVALS,
        payload: {listId: data.payload, intervals: result.body.new_intervals}
      });

      yield put({
        type: types.UPDATE_CACHED_LIST_INTERVALS,
        payload: {listId: data.payload, intervals: result.body.new_intervals}
      });

      yield put({
        type: types.UPDATE_LISTS_TO_REPEAT,
        payload: {listId: data.payload, type: 'deleteList'}
      });
      // toastr.info('', translate('all_repetions_were_postponed'));
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'postponeInCalendar',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getUserListsToFill() {
  const {userId} = yield select(state => state.user);

  if(!userId)return;
  try {
    const result = yield request
    .post(API_URL + 'getUserListsToFill')
    .send({
      user_id: userId && userId > 0 ? userId : 1,
    });

    // console.log("getUserListsToFill", result.body)

    yield put({
      type: types.SET_USER_LISTS_TO_FILL,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getUserListsToFill',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* fillListFromGlossaryWithSelectedWords(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  let params = {
    user_id: userId,
    selected_words: data.payload.selectedWords
  };

  if (data.payload.listId) {
    params.list_id = data.payload.listId
  }

  if (data.payload.glossaryId) {
    params.glossary_id = data.payload.glossaryId
    params.glossary_name = data.payload.glossaryName
  }

  try {
    const result = yield request
    .post(API_URL + 'fillListFromGlossaryWithSelectedWords')
    .send(params);

    // console.log("fillListFromGlossaryWithSelectedWords", result.body)

    const statusCode = result.statusCode;

    if (statusCode === 200) {
      if (data.payload.glossaryId) {
        yield put({
          type: types.LISTS_FILLED_WITH_GLOSSARY_WORDS,
          payload: data.payload.selectedWords
        });
      }

      if (data.payload.glossaryId && data.payload.listId == "0") {
        if (result.body.createdLists.length > 0) {
          yield put({
            type: types.SET_NEWLY_CREATED_LISTS,
            payload: result.body.createdLists
          });

          // TODO the problem occurs where the users didn't visit lists before
          yield put({
            type: types.ADD_TO_USER_LISTS,
            payload: {lists: result.body.createdLists, words: result.body.newWords}
          });

          yield put({
            type: types.ADD_TO_CACHED_LISTS,
            payload: {lists: result.body.createdLists, words: result.body.newWords}
          });

          /*yield put({
           type: types.RESET_CACHED_LISTS
           });*/

          yield put({
            type: types.ADD_TO_HOME_NEW_LISTS,
            payload: {lists: result.body.createdLists}
          });
        }
      } else {
        yield put({
          type: types.ADD_WORD_NUMBERS_TO_CACHED_LIST,
          payload: {listsId: data.payload.listId, words: result.body.newWords}
        });

        yield getListWords({payload: data.payload.listId});
        toastr.info('', data.payload.glossaryId ? translate('selected_words_added_to_list') : data.payload.selectedWords.length > 1 ? translate('words_were_added_to_list') : translate('word_was_added_to_list'));
      }

      yield getUserListsToFill();
      yield getListsNumber();

      if (data.payload.glossaryId) {

        try {
          // const glossariesLevel = yield AsyncStorage.getItem(`glossariesLevel${userId}`);
          const glossariesLevel = null;// TODO

          yield put({
            type: types.UPDATE_NUMBER_OF_WORDS_IN_LISTS_GLOSSARY,
            payload: {
              words: data.payload.selectedWords.length,
              glossaryId: data.payload.glossaryId,
              level: glossariesLevel !== null ? glossariesLevel : "1"
            }
          });
        } catch (error) {
          console.log(error.message);
        }
      }
      // yield getListsByLang({payload: {filter: "by_list_name", order: "asc"}});

    } else if (statusCode === 202) {
      toastr.info('', translate('no_selected'));
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'fillListFromGlossaryWithSelectedWords',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* deleteListWord(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    // .post(API_URL + 'deleteWordFromList')
    .post(API_URL + 'deleteWordFromListO')
    .send({
      user_id: userId,
      list_id: data.payload.list_id,
      word_id: data.payload.word_id
    });

    if (result.statusCode === 200) {
      yield put({
        type: types.LIST_WORD_DELETED_BY_ID,
        payload: {
          index: data.payload.index,
          listId: data.payload.list_id,
          wordId: data.payload.word_id
        }
      });

      yield put({
        type: types.CACHED_LIST_WORD_DELETED_FROM_LISTS,
        payload: {wordId: data.payload.word_id, listId: data.payload.list_id}
      });

      yield put({
        type: types.DELETE_WORD_FROM_CACHED_WORDS_FOR_TRAININGS,
        payload: {wordId: data.payload.word_id, listId: data.payload.list_id}
      });

      yield put({
        type: types.CACHED_LIST_WORD_DELETED,
      });
      if (result.body.updateLists) {
        yield getListsByLang({payload: {filter: "by_list_name", order: "asc"}});
      }
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'deleteWordFromList',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* toggleInVocabulary(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'toggleInVocabulary')
    .send({
      user_id: userId,
      word_id: data.payload.word_id
    });

    if (result.statusCode === 200) {
      /*yield put({
       type: types.WORD_TOGGLED_IN_VOCABULARY,
       payload: data.payload.index
       });*/

      if (data.payload.fav) {
        yield put({
          type: types.UPDATE_VOCABULARY_IN_FAVORITE_WORDS,
          payload: {
            index: data.payload.index,
            wordId: data.payload.word_id
          }
        });
      }

      yield put({
        type: types.UPDATE_WORD_IN_VOCABULARY,
        payload: {
          index: data.payload.index,
          wordId: data.payload.word_id
        }
      });
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'toggleInVocabulary',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* addListWordToFavorites(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'addListWordToFavorites')
    .send({
      user_id: userId,
      list_id: data.payload.list_id,
      word_id: data.payload.word_id
    });

    if (result.statusCode === 200) {
      yield put({
        type: types.WORD_TOGGLED_IN_FAVORITE,
        payload: data.payload.index
      });
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'addListWordToFavorites',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* toggleFavouriteList(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'toggleFavouriteList')
    .send({
      user_id: userId,
      list_id: data.payload.list_id,
    });

    // console.log("toggleFavouriteList", result.body)

    if (result.statusCode === 200) {
      toastr.info('', !result.body.status ? translate('list_removed_from_favourites') : translate('list_added_to_favourites'));

      if (data.payload.fromList) {
        yield put({
          type: types.UPDATE_CURRENT_LIST_FAVORITE,
        });
      } else {
        yield put({
          type: types.UPDATE_CACHED_FAVORITE_LIST,
          payload: data.payload.list_id
        });
      }

      if (data.payload.favorites) {
        yield getListsByLangF();
      }
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'toggleFavouriteList',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* toggleWordInUserList(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'toggleWordInUserList')
    .send({
      user_id: userId,
      word_id: data.payload.wordId,
      list_id: data.payload.listId,
      glossary_id: data.payload.glossaryId,
      status: data.payload.status
    });

    // console.log("toggleWordInUserList", result.body)

    if (result.statusCode === 200) {
      yield put({
        type: types.TOGGLED_GLOSSARY_WORD_IN_LIST,
        payload: data.payload.index
      });

      yield put({
        type: types.TOGGLED_GLOSSARY_WORD_IN_LIST_,
        payload: data.payload.wordId
      });

      yield put({
        type: types.TOGGLED_WORD_IN_CURRENT_LIST,
        payload: result.body.word
      });

      /*yield put({
       type: types.TOGGLED_WORDS_NUMBER_IN_CURRENT_LIST,
       payload: status
       });*/
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'toggleWordInUserList',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

/*export function* addWordToUserList(data) {
 const {userId} = yield select(state => state.user);

 const params = data.payload;

 try {
 const result = yield request
 .post(API_URL + 'addWordToUserList')
 .send({
 user_id: userId,
 list_id: params.listId,
 word_id: params.wordId,
 word: params.word
 });

 if (result.statusCode === 200) {
 toastr.info('', translate('word_was_added_to_list'));

 yield put({type: types.GET_LIST_WORDS, payload: params.listId});
 } else {
 toastr.info('', translate('full_list'));
 }
 } catch (error) {
 toastr.info('', translate('error'));
 }
 }*/

export function* addList(data) {
  const {userId} = yield select(state => state.user);

  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'addList')
    .send({
      user_id: userId,
      list_name: params.listName,
    });

    if (result.statusCode === 200) {

      let newList = result.body.list;
      newList.intervals = [];
      newList.repetitions = 0;
      newList.words = 0;
      newList.trainings = 0;
      newList.show_at = null;
      newList.cell_id = null;
      newList.favourite = 0;

      yield put({
        type: types.INSERT_NEW_LIST,
        payload: newList
      });
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'addList',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* addNewList(data) {
  const {userId} = yield select(state => state.user);

  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'addNewList')
    .send({
      user_id: userId,
      list_name: params.listName,
    });

    // console.log("addNewList", result.body)

    if (result.body.result) {
      /*yield put({
       type: types.APPEND_NEW_LIST,
       payload: result.body.list
       });*/
      yield getUserListsToFill()
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'addList',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* editList(data) {
  const {userId} = yield select(state => state.user);

  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'editList')
    .send({
      user_id: userId,
      list_name: params.listName,
      list_id: params.listId,
    });

    if (result.statusCode === 200) {
      yield put({
        type: types.UPDATE_LIST_NAME,
        payload: {
          listName: params.listName, listId: params.listId
        }
      });
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'editList',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* deleteList(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'deleteList')
    .send({
      user_id: userId,
      list_id: params.listId,
    });

    if (result.statusCode === 200 && result.body.result) {
      yield put({
        type: types.FETCHING_DATA,
        payload: true
      });
      toastr.info('', translate('list_deleted'));

      yield getListsByLang({payload: {filter: params.filter, order: params.order}});
      // yield getListsToRepeat({payload: {userId, limit: 20}})
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'deleteList',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* deleteListFromList(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));
  const {locale} = yield select(state => state.settings);

  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'deleteList')
    .send({
      user_id: userId,
      list_id: params.listId,
    });

    if (result.statusCode === 200 && result.body.result) {
      yield put({
        type: types.DELETE_FROM_USER_LISTS,
        payload: params.listId
      });

      yield put({
        type: types.DELETE_FROM_CACHED_USER_LISTS,
        payload: params.listId
      });

      yield put({
        type: types.UPDATE_LISTS_TO_REPEAT,
        payload: {listId: params.listId, type: 'deleteList'}
      });

      yield put({
        type: types.DELETE_CACHED_LIST_FROM_TRAINING_LISTS,
        payload: {listId: params.listId, type: 'deleteList'}
      });

      yield put({
        type: types.CLEAN_GLOSSARIES_CACHE,
      });


      if (params.back && params.history) {
        params.history.replace(`/${locale}/lists`);
      }

      toastr.info('', translate('list_deleted'));

      // yield getListsByLang({payload: {filter: "by_list_name", order: "asc"}});
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'deleteListFromList',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* removeFromCalendar(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'removeFromCalendar')
    .send({
      user_id: userId,
      list_id: params.listId,
    });

    if (result.statusCode === 200) {
      toastr.info('', translate('repeatition_removed_from_server_calendar'));

      yield put({
        type: types.REMOVE_REPETITIONS_FROM_LIST,
        payload: params.listId
      });

      yield put({
        type: types.REMOVE_REPETITIONS_FROM_CACHED_LIST,
        payload: params.listId
      });

      yield put({
        type: types.UPDATE_LISTS_TO_REPEAT,
        payload: {listId: params.listId, type: 'deleteList'}
      });

      // yield getListsByLang({payload: {filter: params.filter, order: params.order}});
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'removeFromCalendar',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* generateListsNotWizard(data) {
  const params = data.payload;

  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'generateListsNotWizard')
    .send({
      user_id: userId,
      words: params.words,
      level: params.level,
      check_in_lists: true,
      by_lists: params.byLists,
      glossary_id: params.glossaryId
    });

    // console.log("generateListsNotWizard", result.body);

    if (result.statusCode == 200) {
      yield put({
        type: types.ADD_TO_CACHED_LISTS,
        payload: {lists: result.body.createdLists, words: result.body.newWords}
      });

      yield put({
        type: types.ADD_TO_USER_LISTS,
        payload: {lists: result.body.createdLists, words: result.body.newWords}
      });

      yield put({
        type: types.ADD_TO_HOME_NEW_LISTS,
        payload: {lists: result.body.createdLists}
      });

      if (!params.byLists) {
        yield put({
          type: types.UPDATE_USER_LEVEL,
          payload: params.level
        });
      }

      yield put({
        type: types.CLEAN_GLOSSARIES_CACHE
      });

      toastr.info('', translate('lists_generated'));
    } else {

      if (result.body.words.length == 0) {
        toastr.warning('', translate('all_words_in_lists'));
      } else {
        toastr.error('', translate('error'));
      }
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'generateListsNotWizard',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* addUserWords(data) {
  const params = data.payload;

  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'addUserWords')
    .send({
      user_id: userId,
      text: params.text,
      list_id: params.listId,
      allWords: params.allWords,
    });

    if (result.statusCode == 200 && result.body.total > 0) {
      toastr.info('', translate('pasted_words_added_to_list'));
      yield getListWords({payload: params.listId})
      yield getListsByLang({payload: {filter: "by_list_name", order: "asc"}});
    } else {
      toastr.error('', translate('error'));
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'addUserWords',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


export function* saveScannedWords(data) {
  const params = data.payload;

  const {userId, userData} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'saveScannedWords')
    .send({
      user_id: userId,
      text: params.text,
      allWords: params.flag,
      excludeLists: params.excludeLists
    });

    if (result.body.total > 0) {

      yield put({
        type: types.CLEAR_SCANNED_TEXT,
        payload: true
      });

      let text = translate('scanned_words_added');
      text = text.replace('%1', result.body.total)

      toastr.info('', text);
      yield getListsByLang({payload: {filter: "by_list_name", order: "asc"}});
    } else {
      toastr.warning('', translate('no_new_words_found'));
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'saveScannedWords',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* sendListCards(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'sendListCards')
    .send({
      user_id: userId,
      list_id: params.list_id,
      course_list_id: params.course_list_id,
      include_image: params.include_image,
      include_translation: params.include_translation,
      include_example: params.include_example,
      words_on_page: params.words_on_page
    });

    if (result.statusCode === 200) {
      toastr.info('', translate('cards_sent'));
    } else {
      toastr.error('', translate('error_sending_cards'));
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'sendListCards',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* deleteSelectedLists(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'deleteSelectedLists')
    .send({
      user_id: userId,
      lists: params,
    });

    if (result.statusCode === 200) {
      toastr.info('', translate('lists_deleted'));

      yield put({
        type: types.DELETE_LISTS_FROM_USER_LISTS,
        payload: params
      });

      yield put({
        type: types.DELETE_LISTS_FROM_CACHED_USER_LISTS,
        payload: params
      });

      yield put({
        type: types.UPDATE_LISTS_TO_REPEAT,
        payload: {listId: params, type: 'deleteLists'}
      });

      yield put({
        type: types.DELETE_CACHED_LISTS_FROM_TRAINING_LISTS,
        payload: {lists: params}
      });

      yield put({
        type: types.CLEAN_GLOSSARIES_CACHE,
      });

      // yield getListsByLang({payload: {filter: "by_list_name", order: "asc"}});
    } else {
      toastr.error('', translate('error'));
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'deleteSelectedLists',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* toggleArchiveList(data) {
  const {userId, userData} = yield select(state => state.user);
  const {showArchive} = yield select(state => state.lists);
  const translate = yield select(state => getTranslate(state.localize));

  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'toggleArchiveList')
    .send({
      user_id: userId,
      list_id: data.payload,
    });

    toastr.info('', result.body.result ? translate('added_to_archive') : translate('deleted_from_archive'));

    yield put({
      type: types.UPDATE_CURRENT_LIST_ARCHIVE_STATUS,
      payload: data.payload
    });

    if (result.body.result && showArchive == "0") {
      yield put({
        type: types.UPDATE_HOME_LISTS_ARCHIVE,
        payload: data.payload
      });

      yield put({
        type: types.REMOVE_ARCHIVED_CACHED_LIST,
        payload: data.payload
      });

      yield put({
        type: types.REMOVE_ARCHIVED_LIST,
        payload: data.payload
      });
    } else if (!result.body.result && showArchive == "1") {
      yield getListsByLang({payload: {filter: "by_list_name", order: "asc"}});
      yield getListsToRepeat({payload: {userId, limit: 20}})
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'toggleArchiveList',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* performBulkAction(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'performBulkAction')
    .send({
      user_id: userId,
      list_id: params.listId,
      words: params.words,
      type: params.type,
      source: params.source
    });

    // console.log("performBulkAction", result.body)

    if (params.type == 'vocabulary') {
      if (params.source == 'list') {
        for (let i = 0; i < params.words.length; i++) {
          yield put({
            type: types.UPDATE_WORD_IN_VOCABULARY,
            payload: {
              wordId: params.words[i]
            }
          });
        }
      } else if (params.source == 'favorite') {
        for (let i = 0; i < params.words.length; i++) {
          yield put({
            type: types.UPDATE_VOCABULARY_IN_FAVORITE_WORDS,
            payload: {
              wordId: params.words[i]
            }
          });
        }
      }

      if (result.body.result) {
        toastr.info("", translate('words_added_to_vocabulary'))
      }
    } else if (params.type == "favorite") {
      if (result.body.result) {
        if (params.source == 'list') {
          for (let i = 0; i < params.words.length; i++) {
            yield put({
              type: types.UPDATE_WORD_FAVORITE_STATUS,
              payload: params.words[i]
            });
          }
        } else if (params.source == 'vocabulary') {
          for (let i = 0; i < params.words.length; i++) {
            yield put({
              type: types.VOCABULARY_WORDS_TOGGLED_IN_FAVORITES,
              payload: params.words[i]
            });
          }
        }

        toastr.info("", translate('words_added_to_favorite'))
      }

    } else if (params.type == 'delete') {
      if (result.body.result) {
        if (params.source == 'list') {
          for (let i = 0; i < params.words.length; i++) {
            yield put({
              type: types.LIST_WORD_DELETED_BY_ID,
              payload: {wordId: params.words[i], listId: params.listId}
            });

            yield put({
              type: types.CACHED_LIST_WORD_DELETED_FROM_LISTS,
              payload: {wordId: params.words[i], listId: params.listId}
            });

            yield put({
              type: types.DELETE_WORD_FROM_CACHED_WORDS_FOR_TRAININGS,
              payload: {wordId: params.words[i], listId: params.listId}
            });

            yield put({
              type: types.CACHED_LIST_WORD_DELETED,
            });
          }
          if (result.body.updateLists) {
            yield getListsByLang({payload: {filter: "by_list_name", order: "asc"}});
          }
        } else if (params.source == 'favorite') {
          yield getFavoriteWords({payload: 1})
        } else if (params.source == 'vocabulary') {
          yield getVocabulary({payload: {page: 1}})
        }

        toastr.info("", translate('words_deleted'))
      }

    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'performBulkAction',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* moveToOtherLists(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'moveToOtherLists')
    .send({
      user_id: userId,
      from_list_id: params.fromListId,
      to_list_id: params.toListId,
      words: params.words,
      source: params.source,
    });

    // console.log("moveToOtherLists", result.body)

    if (result.body.result) {
      toastr.info("", translate(params.source == 'list' ? 'words_moved' : 'selected_words_added_to_list'))

      if (params.source == 'list') {
        for (let i = 0; i < params.words.length; i++) {
          yield put({
            type: types.LIST_WORD_DELETED_BY_ID,
            payload: {wordId: params.words[i], listId: params.listId}
          });
        }

        yield put({
          type: types.UPDATE_CURRENT_LIST_WORDS_DATA,
          payload: result.body.movedWords.length
        });
      }

      yield getListsByLang({payload: {filter: "by_list_name", order: "asc"}});
      yield getListsToRepeat({payload: {userId, limit: 20}})

      yield put({
        type: types.CLEAN_TRAININGS_CACHE
      })

    } else {
      toastr.error("", translate('words_not_moved'))
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'moveToOtherLists',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* addCourseWordToList(data) {
  const params = data.payload;

  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'addCourseWordToList')
    .send({
      user_id: userId,
      list_id: params.listId,
      word_id: params.wordId,
    });

    if (result.body.result) {
      yield getListsByLang({payload: {filter: "by_list_name", order: "asc"}});
      yield getListsToRepeat({payload: {userId, limit: 20}})

      yield put({
        type: types.CLEAN_TRAININGS_CACHE
      })
    }

    toastr.info("", translate(result.body.result ? 'word_was_added_to_list' : 'word_was_not_added_to_list'))

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'addСourseWordToList',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}