import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faPlusCircle, faCheckCircle, faCircle} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import Loader from "../../../components/Custom/Loader/index";

import {changeVocabularyLevel} from '../../../actions/userActions';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

class ChangeVocabularyLevel extends Component {

  _changeLevel = (level) => {
    if(!this.props.tour) {
      this.props.fetchingData(true);
      this.props.changeVocabularyLevel(level);
      this.props.logActivity({activityId: 340, comment: `${level}`});
      this.props.onClose('levelModalVisible',false);
    } else {
      this.props.onClose('levelModalVisible',false);
      this.props.toRegister(true);
    }
  };

  render() {
    const {fetchingData} = this.props.activity;
    const {translate, onClose, isVisible} = this.props;
    const {userData} = this.props.user;
    const {fontSizeRation} = this.props.settings;
    const level = userData && userData.level ? userData.level : 0;

    return (
      <Modal
        size="sm"
        className="changelevel"
        show={isVisible}
        onHide={()=>onClose('levelModalVisible',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="change_vocabulary_level"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p style={{fontSize: fontSizeRation * 14, whiteSpace: "pre-wrap"}}>
            {translate('select_level_description')}
          </p>

          {
            [
              "Basic",
              "Elementary",
              "Intermediate",
              "Advanced"
            ].map((item, i) => {
              return (
                <Button
                  block
                  variant="outline-info"
                  key={i}
                  active={level == i}
                  onClick={(e)=>this._changeLevel(i, e)}
                >
                  {item}
                </Button>
              )
            })
          }

        </Modal.Body>
        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    changeVocabularyLevel: (level) => dispatch(changeVocabularyLevel(level)),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
  };
}

export default connect(mapStateToProps, bindAction)(ChangeVocabularyLevel);
