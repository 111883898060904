import * as types from '../actions/types';

const INITIAL_STATE = {
  word: [],
  result: false,
  extractorWords: [],
  extractorScores: 0,
  hangmanScores: 0,
  hangmanWord: false,
  extractorResultsLogged: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_TEST_WORD:
      return Object.assign({}, state, {
        word: action.payload
      });

    case types.SET_TEST_RESULTS:
      return Object.assign({}, state, {
        result: action.payload
      });
      
    case types.SET_WORD_EXTRACTOR_RESULTS:
      return Object.assign({}, state, {
        extractorResultsLogged: true
      });

    case types.ADD_EXTRACTOR_WORD:
    {
      return Object.assign({}, state, {
        extractorWords: [...state.extractorWords, action.payload.word],
        extractorScores: state.extractorScores + action.payload.scores
      });
    }

    case types.RESET_EXTRACTOR_WORDS:
    {
      return Object.assign({}, state, {
        extractorWords: [],
        extractorScores: 0,
        extractorResultsLogged: false
      });
    }

    case types.SET_HANGMAN_SCORES:
    {
      return Object.assign({}, state, {
        hangmanScores: action.payload
      });
    }

    case types.SET_HANGMAN_WORD:
    {
      let word = action.payload;

      if (word) {
        word.split = word.word.split('');
      }

      return Object.assign({}, state, {
        hangmanWord: word
      });
    }
      
    default:
      return state;
  }
}