import React, {PureComponent} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp,} from '@fortawesome/free-solid-svg-icons';

const WordExample = ({example, playTrack, ratio}) => {

  return (
    <div className="wordExample">
      <div className="wordWrapper">
        {example.audio && (
          <a href="#" className="touchable mr-2" onClick={(e) => playTrack(example, e)}>
            <FontAwesomeIcon icon={faVolumeUp} className="infoButton"/>
          </a>
        )}

        <div className="wordTranslationWrapper">
          <span className="word" style={{fontSize: ratio * 14}}>
            {example.example}
          </span>
          <span className="translation" style={{fontSize: ratio * 12}}>
            {example.translation && example.translation.translation ? example.translation.translation : ''}
          </span>
        </div>
      </div>
    </div>
  );
};

export default WordExample;