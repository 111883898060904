import React, {Component} from 'react';
import {connect} from "react-redux";
import {Translate, getTranslate} from 'react-localize-redux';
import {withRouter} from 'react-router-dom';
import {Helmet} from "react-helmet";

import _ from 'lodash'
import request from 'superagent';

import {Link} from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faCheckCircle, faCircle, faTimes} from '@fortawesome/free-solid-svg-icons';
import {toastr} from 'react-redux-toastr';

import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import DictionaryWord from '../../../components/Cells/DictionaryWord/index';
import WordInLists from '../../../components/Modals/WordInLists/index';
import Loader from "../../../components/Custom/Loader/index";

import {
  searchWordsForList,
  search_words,
  resetDictionaryWords,
  resetDictionarySearchField
} from '../../../actions/dictionaryActions';
import {getListWords, updateCurrentListWords, setCurrentList} from '../../../actions/lists';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

import * as constants from '../../../lib/constants';

const API_URL = constants.API_URL;

class Dictionary extends Component {

  constructor(props) {
    super(props);

    this.state = {
      word: "",
      byTranslation: false,
      dictionary: false,
      listSize: 10,
      tablet: false,
      dontShowModalVisible: false,
      neverShowWordAdded: false,
      shouldGetWords: false,
      wordInListsModal: false,
      lists: false,
      listId: false,
      listName: false,
      back: false,
      currentList: false

    };
    this.inputRef = React.createRef();
    this.timeOut = null;
    
    this.onChangeTextDelayed = _.debounce(this.onChangeText, 1000);
  }

  componentDidMount() {
    this.setListSize();
    this.setNeverShow();

    if (this.props.history.location && this.props.history.location.state) {
      this.setState({
        listId: this.props.history.location.state.listId,
        listName: this.props.history.location.state.listName,
        dictionary: this.props.history.location.state.dictionary,
        currentList: this.props.history.location.state.list,
        back: this.props.history.location.state.back
      });

      if (this.props.history.location.state.list) {
        this.props.setCurrentList(this.props.history.location.state.list);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {resetSearchField} = this.props.dictionary;

    if (resetSearchField !== prevProps.dictionary.resetSearchField && resetSearchField === true) {
      this.props.resetDictionarySearchField(false);

      this.setState({
        word: ""
      });

      if (this.refs && this.refs.field) {
        this.timeOut = setTimeout(()=> {
          this.refs.field.focus();
        }, 2000)
      }
    }
  }

  componentWillUnmount() {
    const {listId, shouldGetWords} = this.state;
    const {isConnected} = this.props.settings;

    clearTimeout(this.timeOut);

    this.props.resetDictionaryWords();

    if (listId && shouldGetWords && isConnected) {
      this.props.getListWords(listId);
    }
  };

  setNeverShow = async() => {
    const {userId} = this.props.user;
    const never = localStorage.getItem(`neverShowWordAdded${userId}`);

    if (never !== null) {
      this.setState({
        neverShowWordAdded: true
      })
    }
  };

  setListSize = async() => {
    const {userId} = this.props.user;

    const listSize = localStorage.getItem(`listSize${userId}`);

    if (listSize !== null) {
      this.setState({
        listSize
      });
    }
  };

  toggleByTranslation = () => {
    this.setState({
      byTranslation: !this.state.byTranslation
    });
  };

  onChangeText(word) {
    const {listId} = this.state;

    if (word.length > 0) {
      if (listId) {
        this.props.fetchingData(true);
        this.props.searchWordsForList(word, listId, this.state.byTranslation);
      } else {
        this.props.fetchingData(true);

        this.props.search_words(word, this.state.byTranslation);
      }
    } else {
      this.props.resetDictionaryWords();
    }
  }

  _keyExtractor = (item) => {
    return `dictionary-word-${item.id}`
  };

  _onPressItem = (word, e) => {
    e.preventDefault();

    const {locale} = this.props.settings;
    const {userId} = this.props.user;
    const {translate} = this.props;
    const {listId, neverShowWordAdded} = this.state;
    const {isConnected} = this.props.settings;

    if (listId && isConnected) {
      this.props.resetDictionaryWords();
      this.props.fetchingData(true);

      this.props.logActivity({activityId: 38, model: 'word', modelId: word.id, comment: 'from dictionary'});

      request
      .post(API_URL + 'addWordToUserList')
      .send({
        user_id: userId,
        list_id: listId,
        word_id: word.id,
        word: word
      }).then(response => {
        if (response.statusCode == 200) {
          // this.props.fetchingData(true);

          this.setState({
            shouldGetWords: true
          });

          this.props.updateCurrentListWords();

          // this.props.getListWords(listId);

          if (neverShowWordAdded) {
            if (this.refs && this.refs.field) {
              this.refs.field.focus();
            }
          } else {
            this.toggleDontShowModal(true);
          }
        } else {
          toastr.warning('', translate('full_list'));
        }

      }).catch(err => {
        console.log(err)
      }).finally(()=> {
        this.props.fetchingData(false);
        if (this.inputRef) {
          this.inputRef.current.focus();
        }
      });

      this.setState({word: ""});

    } else {
      this.props.history.push(`/${locale}/dictionary/${word.word}`, {word});
    }
  };

  _onInfoPressed = (word) => {
    const {listId, listName} = this.state;
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/dictionary/${word.word}`, {word, listId, listName});

    this.props.logActivity({activityId: 45, model: 'word', modelId: word.id, comment: 'from dictionary'});
  };

  _showLists = (lists, e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      lists
    });

    this._toggleInListsWord(true)
  };

  _toggleInListsWord = (status) => {
    this.setState({
      wordInListsModal: status
    })
  };

  _renderItem = (item, index) => {
    const {fontSizeRation, version} = this.props.settings;
    const {tablet} = this.state;
    const {userData} = this.props.user;
    const {translate} = this.props;

    let audio = item && item.audio ? item.audio : false;
    let audioSource = constants.BACKEND_URL;

    if (userData && userData.audio_language && item[userData.audio_language]) {
      audio = item[userData.audio_language];
      audioSource = constants.S3
    }

    return (
      <DictionaryWord
        key={index}
        audio={audio}
        audioSource={audioSource}
        ratio={fontSizeRation}
        word={item}
        tablet={tablet}
        version={version}
        dictionary={this.state.dictionary}
        onPressItem={this._onPressItem}
        onInfoPressed={this._onInfoPressed}
        onPlay={this.playTrack}
        showLists={this._showLists}
        translate={translate}
      />
    );
  };

  playTrack = (url) => {
    const {isConnected} = this.props.settings;

    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    if (isConnected) {
      this.track = new Audio(url);
      this.track.play();
    }
  };

  toggleDontShowModal = (status) => {
    this.setState({
      dontShowModalVisible: status
    });

    if (!status) {
      if (this.refs && this.refs.field) {
        this.refs.field.focus();
      }
    }
  };

  setVariable = (dont, variable) => {
    if (dont == 1) {
      this.defineVar(variable);
    }
  };

  defineVar = async(variable) => {
    const {userId} = this.props.user;

    this.setState({
      neverShowWordAdded: true
    });
    try {
      localStorage.setItem(`${variable}${userId}`, "1")
    } catch (err) {
      console.log(err)
    }
  };

  _handleKeyPress = (type, event) => {
    const {word, listName} = this.state;

    if (event.key === 'Enter') {
      this.onChangeText(word);
    }
  };

  _typeDictionaryWord = (word) => {
    this.setState({word});
    this.onChangeTextDelayed(word);
  };

  _clearFoundWords = () => {
    this.setState({
      word: ''
    });
    this.inputRef.current.focus();
    this.props.resetDictionaryWords();
  };

  render() {
    const {words} = this.props.dictionary;
    const {
      byTranslation,
      word,
      dictionary,
      listSize,
      tablet,
      dontShowModalVisible,
      wordInListsModal,
      lists,
      back,
      currentList

    } = this.state;
    const {userId} = this.props.user;
    const {translate} = this.props;
    const {fontSizeRation, isConnected, locale} = this.props.settings;
    const {fetchingData} = this.props.activity;
    // const {currentList} = this.props.lists;

    return (
      <div className="dictionary">
        <Breadcrumb style={{marginTop: 50}}>
          {
            !back ? (
              <React.Fragment>
                <li className="breadcrumb-item">
                  <Link to={`/${locale}/home`}>
                    {translate('home')}
                  </Link>
                </li>
                <Breadcrumb.Item active>
                  {translate('english_dictionary')}
                </Breadcrumb.Item>
              </React.Fragment>
            ) : (
              <li className="breadcrumb-item">
                <a href="#" onClick={(e)=>{
                  e.preventDefault();
                  this.props.history.goBack()
                }}>
                  {translate('cancel_back')}
                </a>
              </li>
            )
          }

        </Breadcrumb>

        <Container style={{marginTop: 20}} className="pageWrapper">

          {
            !userId && (
              <div style={{marginTop: 15, marginBottom: 25}}>
                <h1 style={{fontSize: 24}}>
                  Английский словарь на 180 000 слов
                </h1>
                <p style={{fontSize: 18}}>
                  Ищите английские слова в нашем словаре и добавляйте их в свои списки для дальнейшего запоминания
                  на 15 разнообразных упражнениях.
                </p>
                <p style={{fontSize: 18}}>
                  Английские слова и фразы содержат не только картинку, транскрипцию, основной перевод и озвучивание, но
                  и примеры использования, определения на английском языке, а также синонимы и антонимы.
                </p>
              </div>
            )
          }

          <div className="m-auto" style={{maxWidth: 600}}>
            <InputGroup>
              <FormControl
                onKeyPress={(e)=>this._handleKeyPress('search', e)}
                value={word}
                autoFocus
                ref={this.inputRef}
                onChange={(e)=>this._typeDictionaryWord(e.target.value)}
                placeholder={translate('start_entering_english_word')}
              />

              <InputGroup.Append>
                <Button
                  disabled={word.length == 0}
                  variant="outline-secondary" onClick={this._clearFoundWords}>
                  <FontAwesomeIcon icon={faTimes}/>
                </Button>
              </InputGroup.Append>

              {/*<InputGroup.Append>
               <InputGroup.Checkbox
               onChange={this.toggleByTranslation}
               checked={byTranslation}
               />

               <Button onClick={this.toggleByTranslation} variant="outline-secondary">
               {translate('select_by_translation')}
               </Button>
               </InputGroup.Append>*/}
            </InputGroup>

            {currentList && currentList.words && !dictionary && isConnected && (
              <div className="listDataWrapper">
              <span className="listName d-block" style={{fontSize: fontSizeRation * 16}}>
                {currentList.list_name}</span>
              <span
                className="listCapacity d-block"
                style={{fontSize: fontSizeRation * 14}}>
                {`${currentList.words}/${listSize}`}
              </span>
              </div>
            ) || null}

            {
              words.length > 0 && (
                <Card>
                  <ListGroup variant="flush">
                    {
                      words.map((word, index)=> {
                        return this._renderItem(word, index);
                      })
                    }
                  </ListGroup>
                </Card>
              )
            }
          </div>

          {
            wordInListsModal && (
              <WordInLists
                onClose={this._toggleInListsWord}
                isVisible={wordInListsModal}
                lists={lists}
              />
            )
          }
        </Container>
        <Loader fetchingData={fetchingData}/>

        <Helmet>
          <title>{translate('app_dictionary_title_unlocalized')}</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="description" content={translate('app_dictionary_description_unlocalized')} />
          <meta property="og:url" content={window.location.href} />
          <meta name="og:description" content={translate('app_dictionary_description_unlocalized')} />
          <meta property="og:title" content={translate('app_dictionary_title_unlocalized')} />
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  lists: state.lists,
  dictionary: state.dictionary,
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    searchWordsForList: (word, listId, byTranslation) => dispatch(searchWordsForList(word, listId, byTranslation)),
    search_words: (word, byTranslation) => dispatch(search_words(word, byTranslation)),
    resetDictionaryWords: () => dispatch(resetDictionaryWords()),
    logActivity: (data) => dispatch(logActivity(data)),
    getListWords: (listId) => dispatch(getListWords(listId)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    updateCurrentListWords: () => dispatch(updateCurrentListWords()),
    resetDictionarySearchField: (status) => dispatch(resetDictionarySearchField(status)),
    setCurrentList: (list) => dispatch(setCurrentList(list)),

  };
}

export default connect(mapStateToProps, bindAction)(withRouter(Dictionary));
