import * as types from './types';

export const getActiveMultiWordMakerGames = (page) => (dispatch) => {
    dispatch({ type: types.GET_MULTI_WORD_MAKER_GAMES, payload: page });
};

export const resetMultiWordMakerGames = () => (dispatch) => {
    dispatch({ type: types.RESET_MULTI_WORD_MAKER_GAMES });
};

export const createWordMakerMultiGame = (history) => (dispatch) => {
    dispatch({ type: types.CREATE_WORD_MAKER_MULTI_GAME, payload: {history} });
};

export const cancelMultiWordMakerGame = (gameId, history) => (dispatch) => {
    dispatch({ type: types.CANCEL_WORD_MAKER_MULTI_GAME, payload: {gameId, history} });
};

export const joinMultiWordMakerGame = (gameId, history) => (dispatch) => {
    dispatch({ type: types.JOIN_WORD_MAKER_MULTI_GAME, payload: {gameId, history} });
};

export const exitMultiWordMakerGame = (gameId, history) => (dispatch) => {
    dispatch({ type: types.EXIT_WORD_MAKER_MULTI_GAME, payload: {gameId, history} });
};

export const startMultiWordMakerGame = (game, gameId, history) => (dispatch) => {
    dispatch({ type: types.START_WORD_MAKER_MULTI_GAME, payload: {game, gameId, history} });
};

export const getCurrentActiveWordMakerGame = (gameId) => (dispatch) => {
    dispatch({ type: types.GET_CURRENT_WORD_MAKER_MULTI_GAME, payload: gameId });
};

export const getMultiWordMakerResults = (gameId) => (dispatch) => {
    dispatch({ type: types.GET_WORD_MAKER_MULTI_GAME_RESULTS, payload: gameId });
};

export const makeMultiWordMakerGameInactive = (gameId) => (dispatch) => {
    dispatch({ type: types.MAKE_WORD_MAKER_MULTI_GAME_INACTIVE, payload: gameId });
};

export const logMultiWordMakerResults = (words, gameId) => (dispatch) => {
    dispatch({ type: types.LOG_WORD_MAKER_MULTI_GAME_RESULTS, payload: {words, gameId} });
};

export const resetCurrentGame = () => (dispatch) => {
    dispatch({ type: types.RESET_CURRENT_WORD_MAKER_MULTI_GAME });
};

export const inviteUsersToMultiMaker = (userIds) => (dispatch) => {
    dispatch({ type: types.INVITE_USERS_TO_MULTI_MAKER, payload: userIds });
};

export const resetMultiWordMakerResults = () => (dispatch) => {
    dispatch({ type: types.RESET_MULTI_WORD_MAKER_GAME_RESULTS });
};

export const getTotalRating = () => (dispatch) => {
    dispatch({ type: types.GET_MULTI_WORD_MAKER_GAME_RATING });
};

export const resetStartStatus = (status) => (dispatch) => {
    dispatch({ type: types.SHOULD_START_MULTI_MAKER, payload:status });
};
