import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Translate, getTranslate} from 'react-localize-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGoogle, faFacebook} from '@fortawesome/free-brands-svg-icons'


import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import {logActivity, logLinksPage} from '../../../actions/logActions';
import {setUserIpAddress} from '../../../actions/userActions';

import Logo from "../../../assets/images/logo-boy-prepared.png";

const publicIp = require('public-ip');

class InstaLink extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ip: false
    }
  }

  componentDidMount() {
    this._getIpAddress();
  }

  _getIpAddress = async() => {
    const ip = await publicIp.v4();
    if (ip) {
      this.setState({
        ip
      });
    }

    this.props.logLinksPage(ip, 'instagram')
  };

  _logClick = (type) => {
    this.props.logActivity({activityId: 357, comment: type});

  };

  render() {
    const {translate} = this.props;


    return (
      <Container className="links">

        <Card className="p-3 ml-auto mr-auto link-wrapper">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h4 className="title mb-0">
              Mnemonic Words
            </h4>
            <a className="mb-3 text-secondary" style={{fontSize: 20}}
               href="https://mnemonicwords.com" target="_blank"
              onClick={()=>this._logClick('site')}
            >
              mnemonicwords.com
            </a>

            <span className="description">
              {translate('app_title_simple')}
            </span>

            <a href="https://mnemonicwords.com" className="d-block mt-5 mb-5"
               onClick={()=>this._logClick('site')}
            >
              <Image src={Logo} className="logo"/>
            </a>

            <div className="d-flex justify-content-center icons">
              <a href="https://itunes.apple.com/app/id1438980777" target="_blank"
                 onClick={()=>this._logClick('apple')}
              >
                <Image
                  className="itunes"
                  style={{height:50}}
                  src={require('../../../assets/images/app-store-clear.png')}/>
              </a>

              <a href="http://play.google.com/store/apps/details?id=com.mnemonicrn" target="_blank"
                 onClick={()=>this._logClick('android')}
              >
                <Image
                  style={{height:50}}
                  src={require('../../../assets/images/google-play-clear-.png')}/>
              </a>
            </div>
          </div>
        </Card>

      </Container>
    );
  }
}


function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    setUserIpAddress: (ip) => dispatch(setUserIpAddress(ip)),
    logLinksPage: (ip, source) => dispatch(logLinksPage(ip, source)),
  };
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  user: state.user
});

export default connect(
  mapStateToProps,
  bindAction,
)(withRouter(InstaLink));
