import React, {Component} from 'react';
import {connect} from "react-redux";

import moment from 'moment';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import ListCardCell from '../../../components/Cells/ListCardCell/index'

import colors from '../../../lib/colors';

class Repeats extends Component {
  getDots = (list) => {
    const {tablet} = this.props;

    let trainings = 0;
    let found = false;

    list.intervals.forEach(day => {
      if (day.repetition_date == moment().format("YYYY-MM-DD")) {
        trainings = day.trainings;
        found = true;
      }
    });

    return [0, 1, 2].map((item, i) => {
      return <div
        className="dot ml-2 mr-2"
        key={`${item}-${i}`}
        style={{backgroundColor: trainings > item ? colors.tealishThree : colors.lightGrey}}/>
    });
  };

  _renderRepeats = () => {
    const {
      translate,
      ratio,
      tablet,

      mistakesToRepeat,
      repeats,

      relaxPlan,
      onPressCard,
      onRepeatPressed,

      onLongPressed,
      onRepetitionsPressed,
    }= this.props;

    const lists = [];

    repeats.forEach((list, index) => {
      const dots = this.getDots(list);

      let item  = <ListCardCell
        key={index}
        translate={translate}
        tablet={tablet}
        ratio={ratio}
        list={list}
        index={index}
        repeat={true}
        dots={dots}
        newList={false}
        onLongPressed={onLongPressed}
        onItemPressed={onPressCard}
        onRepeatPressed={onRepeatPressed}
        showRepetitionDays={onRepetitionsPressed}
      />;

      lists.push(item)
    });

    return lists;
  };

  render() {
    const lists = this._renderRepeats();
    
    return lists.length > 0 ? lists : null;
  }
}

export default connect(null, null)(Repeats);
