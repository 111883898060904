import * as types from '../actions/types';
import moment from 'moment';
import _ from 'lodash';

import {createListWithIntervals} from '../lib/trainingFunctions';
import {createCourseListWithIntervals} from '../lib/trainingFunctions';

import letters from '../lib/letters';

const tablet = false;

const INITIAL_STATE = {
  courseData: false,
  courseRate: false,

  cachedCourseData: [],
  cachedListsWords: [],

  previewCourseCachedWords: [],
  collectWordsCourseCachedWords: [],
  checkSpellingCourseCachedWords: [],
  selectTranslationCourseCachedWords: [],
  collectSentenceCourseCachedWords: [],
  selectWordCourseCachedWords: [],
  dragImageCourseCachedWords: [],
  definitionCourseCachedWords: [],
  memoryCardsCourseCachedWords: [],
  missingLettersCourseCachedWords: [],
  listeningCourseCachedWords: [],

};

function shuffle(array) {
  let counter = array.length;

  while (counter > 0) {
    let index = Math.floor(Math.random() * counter);
    counter--;
    let temp = array[counter];
    array[counter] = array[index];
    array[index] = temp;
  }
  return array;
}

function shuffleSentence(array) {
  let counter = array.length;

  while (counter > 0) {
    let index = Math.floor(Math.random() * counter);

    counter--;

    let temp = array[counter];

    array[counter] = array[index];
    array[index] = temp;
  }
  return array;
}

function getAllSubStrings(str) {
  var i, j, result = [];

  for (i = 0; i < str.length; i++) {
    for (j = i + 1; j < str.length + 1; j++) {
      let substring = str.slice(i, j);

      // if (substring != str) {
      result.push({substring, i});
      // }
    }
  }

  result.sort(function (a, b) {
    return b.substring.length - a.substring.length;
  });

  return result;
}

function _findCharsToHide(word, lang) {
  if (!word) return false;

  let combos = getAllSubStrings(word)
  // console.log("combos", combos)

  let substitution = _findSubstitution(combos, lang);

  return substitution;
}

function _findSubstitution(combos, lang) {

  let substitutions = letters[lang];
  let found = false;

  combos.forEach(combo => {
    substitutions.forEach(sub => {
      if (sub.sub.toLowerCase() == combo.substring.toLowerCase() && !found) {
        let n = Math.floor(Math.random() * (sub.options.length));

        found = {
          toReplace: combo,
          substituteOptions: sub,
          substitution: sub.options && sub.options[n] ? sub.options[n] : sub.options[0],
          correctButtonIndex: Math.floor(Math.random() * 2)
        }

        return found;
      }
    });
  });

  return found ? found : {
    toReplace: {
      substring: '?',
      i: 0
    },
    substituteOptions: [],
    substitution: '?',
    correctButtonIndex: Math.floor(Math.random() * 2)
  };
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case types.RESET_COURSE_CACHE:
    {

      return Object.assign({}, state, {
        courseData: false,
        courseRate: false,

        cachedCourseData: [],
        cachedListsWords: [],

        previewCourseCachedWords: [],
        collectWordsCourseCachedWords: [],
        checkSpellingCourseCachedWords: [],
        selectTranslationCourseCachedWords: [],
        collectSentenceCourseCachedWords: [],
        selectWordCourseCachedWords: [],
        dragImageCourseCachedWords: [],
        definitionCourseCachedWords: [],
        memoryCardsCourseCachedWords: [],
        missingLettersCourseCachedWords: [],
        listeningCourseCachedWords: [],
      });
    }

    case types.CLEAN_TRAININGS_CACHE_COURSE:
    {

      return Object.assign({}, state, {
        previewCourseCachedWords: [],
        collectWordsCourseCachedWords: [],
        checkSpellingCourseCachedWords: [],
        selectTranslationCourseCachedWords: [],
        collectSentenceCourseCachedWords: [],
        selectWordCourseCachedWords: [],
        dragImageCourseCachedWords: [],
        definitionCourseCachedWords: [],
        memoryCardsCourseCachedWords: [],
        missingLettersCourseCachedWords: [],
        listeningCourseCachedWords: [],
      });
    }

    case types.SET_CACHE_COURSE_DATA:
    {
      let cachedCourseData = state.cachedCourseData;
      
      let found = false;

      cachedCourseData.forEach((course, index) => {
        if (course.slug == action.payload.slug) {
          found = true;
          course.lists = action.payload.data.data;
          course.repeats = action.payload.data.repeats;
          course.tests = action.payload.data.tests;
          course.mistakes = action.payload.data.mistakes;

          course.courseRate = action.payload.data.rate;
          course.courseData = action.payload.data.course_data;
        }
      });
      
      if(!found) {
        cachedCourseData.push({
          slug: action.payload.slug,
          lists: action.payload.data.data,
          repeats: action.payload.data.repeats,
          tests: action.payload.data.tests,
          mistakes: action.payload.data.mistakes,
          courseRate: action.payload.data.rate,
          courseData: action.payload.data.course_data,
        })
      }
      
      return Object.assign({}, state, {
        cachedCourseData
      });
    }
    
    case types.SET_CACHE_COURSES_DATA:
    {
      let cachedCourseData = state.cachedCourseData;
      
      let found = false;

      cachedCourseData.forEach((course, index) => {
        if (course.slug == action.payload.slug) {
          found = true;
          course.repeats = action.payload.data.repeats;
          course.tests = action.payload.data.tests;
          course.mistakes = action.payload.data.mistakes;

          course.courseRate = action.payload.data.rate;
          course.courseData = action.payload.data.course_data;
        }
      })
      
      if(!found) {
        cachedCourseData.push({
          slug: action.payload.slug,
          lists: action.payload.data.data,
          repeats: action.payload.data.repeats,
          tests: action.payload.data.tests,
          mistakes: action.payload.data.mistakes,
          courseRate: action.payload.data.rate,
          courseData: action.payload.data.course_data,
        })
      }
      
      return Object.assign({}, state, {
        cachedCourseData
      });
    }

    case types.UPDATE_CACHED_COURSE_LISTS:
    {
      let cachedCourseData = state.cachedCourseData.map(course => {
        if(course.slug == action.payload.slug) {

          let lists = course.lists.map(list => {
            let newList = list;

            action.payload.data.lists.forEach(innerList => {
              if (innerList.id === list.id) {
                newList = innerList;
              }
            })
            return newList
          });

          course.lists = lists;
          course.repeats = action.payload.data.repeats;
          course.tests = action.payload.data.tests;
          course.mistakes = action.payload.data.mistakes;
          course.courseData = action.payload.data.course_data;
        }
        return course;
      });

      // console.log("UPDATE_CACHED_COURSE_LISTS", action.payload, cachedCourseData)

      return Object.assign({}, state, {
        cachedCourseData
      });
    }

    case types.UPDATE_CACHED_COURSE_DATA:
    {
      let cachedCourseData = state.cachedCourseData.map(course => {
        if(course.slug == action.payload.slug) {
          course.courseData = action.payload.data;
        }
        return course;
      })
        
      return Object.assign({}, state, {
        cachedCourseData,
      });
    }

    case types.SET_ONLY_MISTAKES_COURSE:
    {
      let cachedCourseData = state.cachedCourseData.map(course => {
        if(course.slug == action.payload.slug) {
          course.mistakes = action.payload.data;
        }
        return course;
      })

      return Object.assign({}, state, {
        cachedCourseData
      });
    }

    case types.SET_ONLY_REPEATS_COURSE:
    {
      let cachedCourseData = state.cachedCourseData.map(course => {
        if(course.slug == action.payload.slug) {
          course.repeats = action.payload.data;
        }
        return course;
      })

      return Object.assign({}, state, {
        cachedCourseData
      });
    }

    case types.SET_ONLY_TESTS_COURSE:
    {
      let cachedCourseData = state.cachedCourseData.map(course => {
        if(course.slug == action.payload.slug) {
          course.tests = action.payload.data;
        }
        return course;
      })

      return Object.assign({}, state, {
        cachedCourseData
      });
    }

    case types.SET_CACHE_LIST_WORDS:
    {
      let lists = state.cachedListsWords;

      let found = false;
      let foundIndex = null;

      lists.forEach((item, i) => {
        if (item.listId === action.payload.listId) {
          found = true;
          foundIndex = i;
        }
      })

      if (!found) {
        lists.push(action.payload)
      }

      if (foundIndex !== null) {
        if (lists[foundIndex] !== action.payload) {
          lists[foundIndex] = action.payload
        }
      }

      return Object.assign({}, state, {
        cachedListsWords: lists,
      });
    }

    case types.UPDATE_IN_COURSE_VOCABULARY:
    {
      let lists = state.cachedListsWords.map((item, i) => {
        if (item && item.listWords) {
          if (item.listId == action.payload.listId) {

            item.listWords.map(word => {
              if (word.word_id == action.payload.wordId) {
                word.vocabulary = !word.vocabulary
              }
            })
          }
        }

        return item;
      })

      return Object.assign({}, state, {
        cachedListsWords: lists,
      });
    }

    case types.DELETE_COURSE_WORD_FROM_LIST:
    {
      let lists = state.cachedListsWords.map((item, i) => {
        if (item && item.listWords) {
          if (item.listId == action.payload.listId) {

            item.listWords = item.listWords.filter(word => {
              return word.word_id != action.payload.wordId
            })
          }
        }

        return item;
      })

      return Object.assign({}, state, {
        cachedListsWords: lists,
      });
    }

    // TRAININGS

    case types.SET_COURSE_CACHED_PREVIEW_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.previewCourseCachedWords;

      state.previewCourseCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          // item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        // let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            // shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          // item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        // let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        // cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);

      }

      // console.log("PREVIEW cachedWords", cachedWords)

      return Object.assign({}, state, {
        previewCourseCachedWords: cachedWords,
      });
    }
     
    case types.SET_COURSE_CACHED_COLLECT_WORD_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.collectWordsCourseCachedWords;

      state.collectWordsCourseCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);
      }

      return Object.assign({}, state, {
        collectWordsCourseCachedWords: cachedWords,
      });
    }
      
    case types.SET_COURSE_CACHED_CHECK_SPELLING_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.checkSpellingCourseCachedWords;

      state.checkSpellingCourseCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          // item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        // let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            // shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          // item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        // let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        // cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);
      }
      
      return Object.assign({}, state, {
        checkSpellingCourseCachedWords: cachedWords,
      });
    }

    case types.SET_COURSE_CACHED_MISSING_LETTER_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.missingLettersCourseCachedWords;
      let lang = action.payload.language;

      state.missingLettersCourseCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.chars = _findCharsToHide(item.word, lang)
          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.chars = _findCharsToHide(item.word, lang)
          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);
      }

      return Object.assign({}, state, {
        missingLettersCourseCachedWords: cachedWords,
      });
    }

    case types.SET_COURSE_CACHED_SELECT_TRANSLATION_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.selectTranslationCourseCachedWords;

      state.selectTranslationCourseCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);

      }

      // console.log("SELECT TRANSLATION cachedWords", cachedWords)

      return Object.assign({}, state, {
        selectTranslationCourseCachedWords: cachedWords,
      });
    }

    case types.SET_COURSE_CACHED_SELECT_WORD_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.selectWordCourseCachedWords;

      state.selectWordCourseCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);

      }

      // console.log("SELECT WORD cachedWords", cachedWords)

      return Object.assign({}, state, {
        selectWordCourseCachedWords: cachedWords,
      });
    }

    case types.SET_COURSE_CACHED_DRAG_IMAGE_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.dragImageCourseCachedWords;

      state.dragImageCourseCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);

      }

      // console.log("DRAG IMAGE cachedWords", cachedWords)

      return Object.assign({}, state, {
        dragImageCourseCachedWords: cachedWords,
      });
    }

    case types.SET_COURSE_CACHED_DEFINITION_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.definitionCourseCachedWords;

      state.definitionCourseCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);

      }

      // console.log("DEFINITION cachedWords", cachedWords)

      return Object.assign({}, state, {
        definitionCourseCachedWords: cachedWords,
      });
    }

    case types.SET_COURSE_CACHED_MEMORY_CARD_LIST_WORDS:
    {

      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.memoryCardsCourseCachedWords;

      state.memoryCardsCourseCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [
            {
              page: action.payload.page,
              shuffledIndices,
              words: action.payload.data.data,
            }
          ],
          data: {
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(
          {
            page: action.payload.page,
            shuffledIndices,
            words: action.payload.data.data
          }
        );
      }

      // console.log("MEMORY CARDS cachedWords", cachedWords)

      return Object.assign({}, state, {
        memoryCardsCourseCachedWords: cachedWords,
      });
    }

    case types.SET_COURSE_CACHED_COLLECT_SENTENCE_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.collectSentenceCourseCachedWords;

      state.collectSentenceCourseCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          let shuffledExamples = [];
          let examples = [];
          let maxWidths = [];

          item.examples && item.examples.map(sentence => {
            if (sentence.example.length > 0) {
              let exploded = sentence.example.split(" ")
              let explodedWithOrigIndices = [];

              let maxWidth = null;

              exploded.forEach((item, i) => {
                explodedWithOrigIndices.push({
                  word: item,
                  origIndex: i
                })

                if (maxWidth == null || item.length > maxWidth) {
                  maxWidth = item.length;
                }
              });

              let width = !tablet ? 50 : 70;

              if (maxWidth != null) {
                width = maxWidth * (!tablet ? 10 : 12)
              }
              examples.push(exploded);
              maxWidths.push(width);

              // let shuffledSent = shuffle([...exploded]);
              let shuffledSent = shuffleSentence([...explodedWithOrigIndices]);

              shuffledExamples.push(shuffledSent)
            }

          });
          item.sentences = examples;
          item.maxWidths = maxWidths;
          item.shuffledSentences = shuffledExamples;

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          let shuffledExamples = [];
          let examples = [];
          let maxWidths = [];

          item.examples && item.examples.map(sentence => {
            if (sentence.example.length > 0) {
              let exploded = sentence.example.split(" ")

              let maxWidth = null;

              exploded.forEach((item, i) => {
                if (maxWidth == null || item.length > maxWidth) {
                  maxWidth = item.length;
                }
              });

              let width = !tablet ? 50 : 70;

              if (maxWidth != null) {
                width = maxWidth * (!tablet ? 10 : 12)
              }
              examples.push(exploded);
              maxWidths.push(width);

              let shuffledSent = shuffle([...exploded]);

              shuffledExamples.push(shuffledSent)
            }

          });
          item.sentences = examples;
          item.maxWidths = maxWidths;
          item.shuffledSentences = shuffledExamples;

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);
      }

      // console.log("COLLECT SENTENCE cachedWords", cachedWords)

      return Object.assign({}, state, {
        collectSentenceCourseCachedWords: cachedWords,
      });
    }
    
    case types.SET_COURSE_CACHED_LISTENING_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.listeningCourseCachedWords;

      state.listeningCourseCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          /*item.shuffledWord = shuffle([...item.word]);

          let shuffledExamples = [];
          let examples = [];
          let maxWidths = [];

          item.examples && item.examples.map(sentence => {
            if (sentence.example.length > 0) {
              let exploded = sentence.example.split(" ")
              let explodedWithOrigIndices = [];

              let maxWidth = null;

              exploded.forEach((item, i) => {
                explodedWithOrigIndices.push({
                  word: item,
                  origIndex: i
                })

                if (maxWidth == null || item.length > maxWidth) {
                  maxWidth = item.length;
                }
              });

              let width = !tablet ? 50 : 70;

              if (maxWidth != null) {
                width = maxWidth * (!tablet ? 10 : 12)
              }
              examples.push(exploded);
              maxWidths.push(width);

              // let shuffledSent = shuffle([...exploded]);
              let shuffledSent = shuffleSentence([...explodedWithOrigIndices]);

              shuffledExamples.push(shuffledSent)
            }

          });
          item.sentences = examples;
          item.maxWidths = maxWidths;
          item.shuffledSentences = shuffledExamples;*/

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          /*item.shuffledWord = shuffle([...item.word]);

          let shuffledExamples = [];
          let examples = [];
          let maxWidths = [];

          item.examples && item.examples.map(sentence => {
            if (sentence.example.length > 0) {
              let exploded = sentence.example.split(" ")

              let maxWidth = null;

              exploded.forEach((item, i) => {
                if (maxWidth == null || item.length > maxWidth) {
                  maxWidth = item.length;
                }
              });

              let width = !tablet ? 50 : 70;

              if (maxWidth != null) {
                width = maxWidth * (!tablet ? 10 : 12)
              }
              examples.push(exploded);
              maxWidths.push(width);

              let shuffledSent = shuffle([...exploded]);

              shuffledExamples.push(shuffledSent)
            }

          });
          item.sentences = examples;
          item.maxWidths = maxWidths;
          item.shuffledSentences = shuffledExamples;*/

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);
      }


      return Object.assign({}, state, {
        listeningCourseCachedWords: cachedWords,
      });
    }

      // NOT USED
    case types.MOVE_COURSE_LIST_TO_REPEAT:
    {
      let cachedCourseData = state.cachedCourseData;

      let list = action.payload.list;

      // console.log("MOVE_COURSE_LIST_TO_REPEAT", list)

      let newList = createCourseListWithIntervals(list)

      let found = false;

      cachedCourseData.repeats.forEach(item => {
        if (item.id === list.id) {
          found = true;
        }
      })

      if (!found) {
        cachedCourseData.repeats.push(newList)
      }

      // console.log(cachedCourseData)

      return Object.assign({}, state, {
        cachedCourseData
      });
    }

      //NOT USED
    case types.UPDATE_CACHED_LIST_TRAININGS_COURSE:
    {
      let cachedCourseData = state.cachedCourseData;
      let repeats = cachedCourseData.repeats;
      let today = moment().format("YYYY-MM-DD");

      let listId = action.payload.listId;

      // console.log("UPDATE_CACHED_LIST_TRAININGS_COURSE", listId)

      let found = false;

      let newRepeats = repeats.map(item => {
        if (item.id === listId) {
          item.trainings++;

          let repetitions = item.repetitions.map(day => {
            if (day.repetition_date == today) {
              // console.log("today", day)
              day.trainings++;
            }

            return day;
          })
          item.repetitions = repetitions;
        }

        return item;
      })

      cachedCourseData.repeats = newRepeats;

      // console.log("cachedCourseData.repeats",cachedCourseData.repeats)

      return Object.assign({}, state, {
        cachedCourseData
      });
    }

      // NOT USED
    case types.MOVED_CACHED_LIST_TO_TEST:
    {
      let cachedCourseData = state.cachedCourseData;
      let repeats = cachedCourseData.repeats;
      let tests = cachedCourseData.tests;

      let today = moment().format("YYYY-MM-DD");

      let listId = action.payload.listId;
      let testList = false;

      let newRepeats = repeats.filter(item => {
        if (item.id === listId) {
          testList = item;
        }
        return item.id !== listId
      })

      cachedCourseData.repeats = newRepeats;

      let found = false;

      cachedCourseData.tests.forEach(item => {
        if (item.id === listId) {
          found = true;
        }
      })

      if (!found) {
        cachedCourseData.tests.push(testList)
      }

      // console.log("MOVED_CACHED_LIST_TO_TEST", cachedCourseData)

      return Object.assign({}, state, {
        cachedCourseData
      });
    }

    case types.ADD_ASSOCIATION_TO_CACHED_COURSE_WORD:
    {
      let previewCourseCachedWords = state.previewCourseCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload.wordId) {
            word.association = action.payload.association
          }
        })

        return list;
      })

      return Object.assign({}, state, {
        previewCourseCachedWords,
      });
    }

    case types.DELETE_ASSOCIATION_FROM_CACHED_COURSE_WORD:
    {
      let previewCourseCachedWords = state.previewCourseCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload.wordId) {
            word.association = false
          }
        })

        return list;
      })


      return Object.assign({}, state, {
        previewCourseCachedWords,
      });
    }
      
    case types.SHUFFLE_COURSE_LIST_TRAINING_WORDS:
    {
      let previewCourseCachedWords = state.previewCourseCachedWords;
      let collectWordsCourseCachedWords = state.collectWordsCourseCachedWords;
      let checkSpellingCourseCachedWords = state.checkSpellingCourseCachedWords;
      let missingLettersCourseCachedWords = state.missingLettersCourseCachedWords;
      let selectTranslationCourseCachedWords = state.selectTranslationCourseCachedWords;
      let collectSentenceCourseCachedWords = state.collectSentenceCourseCachedWords;
      let selectWordCourseCachedWords = state.selectWordCourseCachedWords;
      let dragImageCourseCachedWords = state.dragImageCourseCachedWords;
      let definitionCourseCachedWords = state.definitionCourseCachedWords;
      let memoryCardsCourseCachedWords = state.memoryCardsCourseCachedWords;
      let listeningCourseCachedWords = state.listeningCourseCachedWords;

      if(action.payload.trainingId == 1) {
        previewCourseCachedWords = state.previewCourseCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.data.words = shuffle(list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 3) {
        collectWordsCourseCachedWords = state.collectWordsCourseCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.data.words = shuffle(list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 5) {
        checkSpellingCourseCachedWords = state.checkSpellingCourseCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.data.words = shuffle(list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 12) {
        missingLettersCourseCachedWords = state.missingLettersCourseCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.data.words = shuffle(list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 2) {
        selectTranslationCourseCachedWords = state.selectTranslationCourseCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.data.words = shuffle(list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 8) {
        collectSentenceCourseCachedWords = state.collectSentenceCourseCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.data.words = shuffle(list.data.words);

            list.data.words && list.data.words.forEach(word => {
              if(word.examples && word.examples.length > 1) {
                var a = word.examples[0];
                word.examples[0] = word.examples[word.examples.length - 1];
                word.examples[word.examples.length - 1] = a;

                if(word.maxWidths && word.maxWidths.length != 0) {
                  var b = word.maxWidths[0];
                  word.maxWidths[0] = word.maxWidths[word.maxWidths.length - 1];
                  word.maxWidths[word.maxWidths.length - 1] = b;
                }

                if(word.shuffledSentences && word.shuffledSentences.length != 0) {
                  var c = word.shuffledSentences[0];
                  word.shuffledSentences[0] = word.shuffledSentences[word.shuffledSentences.length - 1];
                  word.shuffledSentences[word.shuffledSentences.length - 1] = c;
                }

                if(word.sentences && word.sentences.length != 0) {
                  var d = word.sentences[0];
                  word.sentences[0] = word.sentences[word.sentences.length - 1];
                  word.sentences[word.sentences.length - 1] = d;
                }
              }
            })
            // console.log("Shuffled course",list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 14) {
        listeningCourseCachedWords = state.listeningCourseCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.data.words = shuffle(list.data.words);

            list.data.words && list.data.words.forEach(word => {
              if(word.examples && word.examples.length > 1) {
                var a = word.examples[0];
                word.examples[0] = word.examples[word.examples.length - 1];
                word.examples[word.examples.length - 1] = a;

               
                if(word.sentences && word.sentences.length != 0) {
                  var d = word.sentences[0];
                  word.sentences[0] = word.sentences[word.sentences.length - 1];
                  word.sentences[word.sentences.length - 1] = d;
                }
              }
            })
            // console.log("Shuffled course",list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 4) {
        selectWordCourseCachedWords = state.selectWordCourseCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.data.words = shuffle(list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 6) {
        dragImageCourseCachedWords = state.dragImageCourseCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.data.words = shuffle(list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 10) {
        definitionCourseCachedWords = state.definitionCourseCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.data.words = shuffle(list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 9) {
        memoryCardsCourseCachedWords = state.memoryCardsCourseCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.pages = list.pages.map(page => {
              page.words = shuffle(page.words)
              return page;
            })
          }
          return list;
        })
      }
   
      return Object.assign({}, state, {
        previewCourseCachedWords,
        collectWordsCourseCachedWords,
        checkSpellingCourseCachedWords,
        selectTranslationCourseCachedWords,
        collectSentenceCourseCachedWords,
        selectWordCourseCachedWords,
        dragImageCourseCachedWords,
        definitionCourseCachedWords,
        memoryCardsCourseCachedWords,
        missingLettersCourseCachedWords,
        listeningCourseCachedWords,
      });
    }

    case types.DELETE_COURSE_WORD_FROM_TRAININGS:
    {
      let previewCourseCachedWords = state.previewCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          if(list.data && list.data.words) {
            list.data.words = list.data.words.filter(word => {
              return word.word_id != action.payload.wordId
            })

            list.data.totalWords = list.data.totalWords - 1;
          }
        }

        return list;
      })

      let collectWordsCourseCachedWords = state.collectWordsCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          if(list.data && list.data.words) {
            list.data.words = list.data.words.filter(word => {
              return word.word_id != action.payload.wordId
            })
            list.data.totalWords = list.data.totalWords - 1;
          }
        }

        return list;
      })

      let checkSpellingCourseCachedWords = state.checkSpellingCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          if(list.data && list.data.words) {
            list.data.words = list.data.words.filter(word => {
              return word.word_id != action.payload.wordId
            });
            list.data.totalWords = list.data.totalWords - 1;
          }
        }

        return list;
      })

      let selectTranslationCourseCachedWords = state.selectTranslationCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          if(list.data && list.data.words) {
            list.data.words = list.data.words.filter(word => {
              return word.word_id != action.payload.wordId
            });
            list.data.totalWords = list.data.totalWords - 1;
          }
        }

        return list;
      })

      let collectSentenceCourseCachedWords = state.collectSentenceCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          if(list.data && list.data.words) {
            list.data.words = list.data.words.filter(word => {
              return word.word_id != action.payload.wordId
            });
            list.data.totalWords = list.data.totalWords - 1;
          }
        }

        return list;
      })

      let selectWordCourseCachedWords = state.selectWordCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          if(list.data && list.data.words) {
            list.data.words = list.data.words.filter(word => {
              return word.word_id != action.payload.wordId
            });
            list.data.totalWords = list.data.totalWords - 1;
          }
        }

        return list;
      })

      let dragImageCourseCachedWords = state.dragImageCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          if(list.data && list.data.words) {
            list.data.words = list.data.words.filter(word => {
              return word.word_id != action.payload.wordId
            });
            list.data.totalWords = list.data.totalWords - 1;
          }
        }

        return list;
      })

      let definitionCourseCachedWords = state.definitionCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          if(list.data && list.data.words) {
            list.data.words = list.data.words.filter(word => {
              return word.word_id != action.payload.wordId
            });
            list.data.totalWords = list.data.totalWords - 1;
          }
        }

        return list;
      })

      let memoryCardsCourseCachedWords = state.memoryCardsCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          if(list.data && list.data.words) {
            list.data.words = list.data.words.filter(word => {
              return word.word_id != action.payload.wordId
            });
            list.data.totalWords = list.data.totalWords - 1;
          }
        }

        return list;
      })

      let missingLettersCourseCachedWords = state.missingLettersCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          if(list.data && list.data.words) {
            list.data.words = list.data.words.filter(word => {
              return word.word_id != action.payload.wordId
            });
            list.data.totalWords = list.data.totalWords - 1;
          }
        }

        return list;
      })

      let listeningCourseCachedWords = state.listeningCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          if(list.data && list.data.words) {
            list.data.words = list.data.words.filter(word => {
              return word.word_id != action.payload.wordId
            });
            list.data.totalWords = list.data.totalWords - 1;
          }
        }

        return list;
      })

      return Object.assign({}, state, {
        previewCourseCachedWords,
        collectWordsCourseCachedWords,
        checkSpellingCourseCachedWords,
        selectTranslationCourseCachedWords,
        collectSentenceCourseCachedWords,
        selectWordCourseCachedWords,
        dragImageCourseCachedWords,
        definitionCourseCachedWords,
        memoryCardsCourseCachedWords,
        missingLettersCourseCachedWords,
        listeningCourseCachedWords,
      });
    }
    
    case types.UPDATE_CACHED_TRAINING_WORD_TRANSLATIONS_COURSE:
    {
      let previewCourseCachedWords = state.previewCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      let collectWordsCourseCachedWords = state.collectWordsCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })
      
      let checkSpellingCourseCachedWords = state.checkSpellingCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      let selectTranslationCourseCachedWords = state.selectTranslationCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      let collectSentenceCourseCachedWords = state.collectSentenceCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      let selectWordCourseCachedWords = state.selectWordCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      let dragImageCourseCachedWords = state.dragImageCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      let definitionCourseCachedWords = state.definitionCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      let memoryCardsCourseCachedWords = state.memoryCardsCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      let missingLettersCourseCachedWords = state.missingLettersCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      let listeningCourseCachedWords = state.listeningCourseCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      return Object.assign({}, state, {
        previewCourseCachedWords,
        collectWordsCourseCachedWords,
        checkSpellingCourseCachedWords,
        selectTranslationCourseCachedWords,
        collectSentenceCourseCachedWords,
        selectWordCourseCachedWords,
        dragImageCourseCachedWords,
        definitionCourseCachedWords,
        memoryCardsCourseCachedWords,
        missingLettersCourseCachedWords,
        listeningCourseCachedWords,
      });
    }

    default:
      return state;
  }


}