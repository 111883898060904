import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faPlusCircle, faCheckCircle, faCircle} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';

import Loader from "../../../components/Custom/Loader/index";

class ListsToFill extends Component {

  render() {
    const {
      translate,
      tablet,
      isVisible,
      listSize,
      lists,
      selectedWords,
      fillLists,
      onClose,
      ratio,
      currentListId,
      fetchingData
    } = this.props;

    return (
      <Modal
        className="liststofill"
        show={isVisible}
        onHide={()=>onClose(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="select_lists_or_create"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{padding: 0}} className="text-center">
          <ListGroup variant="flush" style={{borderBottom: '1px solid rgba(0, 0, 0, 0.125)'}}>
            {
              lists.map((list, i) => {
                if (listSize - list.words >= selectedWords && currentListId != list.id) {
                  return (
                    <ListGroup.Item
                      action
                      key={i}
                      className="text-center"
                      onClick={(e) => fillLists(list.id, e)}
                    >
                      {list.list_name}{" "}{translate('lwords')} - {list.words}
                    </ListGroup.Item>
                  )
                }
              })
            }
          </ListGroup>

          <Button
            className="mt-3 mb-3"
            variant="outline-success"
            onClick={(e) => fillLists(0, e)}>
            {translate('create_new_and_add')}
          </Button>

        </Modal.Body>

        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}

export default connect(null, null)(ListsToFill);
