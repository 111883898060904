import React, {Component} from 'react';
import {connect} from "react-redux";

import {withRouter, Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircle, faCogs, faCheckCircle,
  faTrashAlt,
  faCamera,
  faVolumeUp,
  faTimes, faSearch, faPlus
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import {Translate, getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';
import moment from 'moment';

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import Loader from "../../../components/Custom/Loader/index";


import TrainingButtons from '../../../components/Modals/TrainingButtons/index';
import PhraseCell from '../../../components/Cells/PhraseCell/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
import TrainingsSet from '../../../components/Modals/TrainingsSet/index';

import {getFavoritePhrases, getAllFavoriteNumbers, deleteFromFavoritePhrases} from '../../../actions/favoriteActions';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

import * as constants from '../../../lib/constants';

import MyPagination from '../../../components/Custom/MyPagination/index';

class FavoritePhrases extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searchBarVisible: false,
      trainingsModalVisible: false,
      listId: false,
      refreshing: false,
      subscriptionAlertVisible: false,
      tablet: false,
      page: 1,
      trainingsSetModal: false,

    };

    this.track = null;
  }

  componentDidMount() {
    this._getGlossariesData();
    this.props.logActivity({activityId: 319});
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._getGlossariesData();
    }
  }

  componentWillUnmount() {

  }

  _getGlossariesData = () => {
    const {isConnected} = this.props.settings;
    const {page} = this.state;

    if (isConnected) {
      this.props.fetchingData(true);

      this.props.getFavoritePhrases(page);
    }
  };


  favoriteToggle(id) {
    this.props.fetchingData(true);
    this.props.deleteFromFavoritePhrases(id)
  }

  toggleTrainingButtons = (status) => {
    this.setState({
      trainingsModalVisible: status
    })
  };

  trainingSelected = (training) => {
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {locale} = this.props.settings;

    let active = false;

    if (lifetimeStatus || subscriptionActive || webSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    this.setState({
      trainingsModalVisible: false
    });

    if (active) {

      let activityId = '';
      let path = '';

      if (training == 'Preview') {
        activityId = 39;
        path = 'preview'
      } else if (training == 'CheckSpelling') {
        path = 'spelling';
        activityId = 44;
      } else if (training == 'CollectSentence') {
        activityId = 129;
        path = 'sentence'
      } else if (training == 'CollectSentenceTap') {
        activityId = 335;
        path = 'sentence'
      } else if (training == 'MissingLetter') {
        activityId = 315;
        path = 'missing';
      }
      
      this.props.history.push(`/${locale}/${path}/-2`, {type: 'phrasebookId', inside: true, from: 'listWords', phrasebookId: -2});

      this.props.logActivity({activityId, model: 'phrasebook', modelId: -2, comment: 'from favorite phrases'});

    } else {
      this._toggleSubscriptionAlert(true);
    }
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toSubscription = (productId) => {
    this._toggleSubscriptionAlert(false);
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId}); 
  };
  
  playTrack = (url, e) => {
    e.preventDefault();

    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    this.track = new Audio(url);
    this.track.play()
  };

  _renderItem = (item, index) => {
    const {fontSizeRation, version} = this.props.settings;
    const {userData} = this.props.user;
    const {tablet} = this.state;
    const {translate} = this.props;

    let audio = item && item.audio ? item.audio : false;
    let audioSource = constants.BACKEND_URL;

    if (userData && userData.audio_language && item[userData.audio_language]) {
      audio = item[userData.audio_language];
      audioSource = constants.S3
    }
    return (
      <PhraseCell
        audio={audio}
        audioSourse={audioSource}
        inFavorite={true}
        key={index}
        index={index}
        tablet={tablet}
        version={version}
        phrase={item}
        ratio={fontSizeRation}
        playTrack={this.playTrack}
        onFavorite={this._deleteFromFavoritePhrases}
        translate={translate}
      />
    )
  };

  _deleteFromFavoritePhrases = (phraseId, index, e) => {
    e.preventDefault();
    this.props.fetchingData(true);
    this.props.deleteFromFavoritePhrases(phraseId, index);

    this.props.logActivity({
      activityId: 320,
      model: 'phrase',
      modelId: phraseId
    });

  };

  pageClicked = (page) => {
    const {isConnected} = this.props.settings;

    this.setState({
      page
    });

    if (isConnected) {
      this.props.fetchingData(true);

      this.props.getFavoritePhrases(page);
    }
  };

  _toggleModal = (type, status) => {
    this.setState({
      [type]: status
    })
  };

  _customizeButtons = (e) => {
    e.preventDefault();
    this.toggleTrainingButtons(false);
    this._toggleModal('trainingsSetModal', true)
  };

  render() {
    const {phrases, totalPhrasesPages, totalPhrases} = this.props.favorites;
    const {subscriptionAlertVisible, trainingsModalVisible, tablet, page,
      trainingsSetModal} = this.state;
    const {fetchingData} = this.props.activity;
    const {fontSizeRation, locale} = this.props.settings;
    const {userData} = this.props.user;
    const {translate} = this.props;

    let level = userData && userData.level ? userData.level : 0;
    const subtitle = `${translate('phrases')}: ${totalPhrases}`;

    return (
      <div className="favoriteWords">
        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate('favorite_phrases')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <p className="text-secondary text-center">
          <span className="font-weight-bold">
            {translate('favorite_phrases')}
          </span>
          <span className="font-weight-light d-block">
            {subtitle}
          </span>
        </p>

        <Container className="pageWrapper">

          {/*<Pagination style={[!tablet ? b.mb3 : b.mb4, !tablet ? b.mt1 : b.mt2]}
           pages={totalPhrasesPages}
           ratio={fontSizeRation}
           currentPage={page} tablet={tablet}
           onClick={this.pageClicked}
           />*/}

          <div className="m-auto" style={{maxWidth: 600}}>
            <MyPagination
              range={5}
              pages={totalPhrasesPages}
              ratio={fontSizeRation}
              currentPage={page}
              tablet={tablet}
              onClick={this.pageClicked}
            />

            <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
              {
                phrases.map((list, index) => {
                  return this._renderItem(list, index);
                })
              }
            </div>
          </div>
          
          {trainingsModalVisible && (
            <TrainingButtons
              modalVisible={this.state.trainingsModalVisible}
              training={this.trainingSelected}
              ratio={fontSizeRation}
              tablet={tablet}
              level={level}
              translationLanguage={userData.translation_language}
              onClose={() => this.toggleTrainingButtons(false)}
              translate={translate}
              customiseButtons={this._customizeButtons}

            />
          )}

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={'subscription_trainings'}
                translate={translate}

              />
            )
          }

        </Container>

        {
          trainingsSetModal && (
            <TrainingsSet
              onClose={this._toggleModal}
              isVisible={trainingsSetModal}
            />
          )
        }
        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  favorites: state.favorites,
  purchases: state.purchases,
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    getAllFavoriteNumbers: () => dispatch(getAllFavoriteNumbers()),
    getFavoritePhrases: (page) => dispatch(getFavoritePhrases(page)),
    logActivity: (data) => dispatch(logActivity(data)),
    deleteFromFavoritePhrases: (id, index) => dispatch(deleteFromFavoritePhrases(id, index)),
    fetchingData: (status) => dispatch(fetchingData(status)),
  };
}

export default connect(mapStateToProps, bindAction)(FavoritePhrases);
