import * as types from './types';

export const getWordsForTraining = (data) => (dispatch) => {
    dispatch({ type: types.GET_TRAINING_WORDS, payload: data });
};

export const resetWords = () => (dispatch) => {
    dispatch({ type: types.RESET_TRAINING_DATA });
};

export const resetCurrentList = () => (dispatch) => {
    dispatch({ type: types.RESET_CURRENT_LIST });
};

export const resetOnlyWords = () => (dispatch) => {
    dispatch({ type: types.RESET_TRAINING_WORDS });
};

export const toggleShowCards = (status) => (dispatch) => {
    dispatch({ type: types.TOGGLE_SHOW_MEMORY_CARDS, payload: status });
};

export const getGoogleExampleTranslation = (wordId, exampleId) => (dispatch) => {
  dispatch({ type: types.GET_GOOGLE_EXAMPLE_TRANSLATION, payload: {wordId, exampleId}});
};


export const setPreviewSettings = (data) => (dispatch) => {
  dispatch({ type: types.SET_PREVIEW_SETTINGS, payload: data});
};

export const setDragImageSettings = (data) => (dispatch) => {
  dispatch({ type: types.SET_DRAG_IMAGE_SETTINGS, payload: data});
};

export const setSpellingSettings = (data) => (dispatch) => {
  dispatch({ type: types.SET_SPELLING_SETTINGS, payload: data});
};

export const setCollectSentenceSettings = (data) => (dispatch) => {
  dispatch({ type: types.SET_COLLECT_SENTENCE_SETTINGS, payload: data});
};

export const setTranslateSentenceSettings = (data) => (dispatch) => {
  dispatch({ type: types.SET_TRANSLATE_SENTENCE_SETTINGS, payload: data});
};

export const setCollectWordSettings = (data) => (dispatch) => {
  dispatch({ type: types.SET_COLLECT_WORD_SETTINGS, payload: data});
};

export const setMemoryCardsSettings = (data) => (dispatch) => {
  dispatch({ type: types.SET_MEMORY_CARDS_SETTINGS, payload: data});
};

export const setSelectTranslationSettings = (data) => (dispatch) => {
  dispatch({ type: types.SET_SELECT_TRANSLATION_SETTINGS, payload: data});
};

export const setSelectWordSettings = (data) => (dispatch) => {
  dispatch({ type: types.SET_SELECT_WORD_SETTINGS, payload: data});
};

export const setDefinitionSettings = (data) => (dispatch) => {
  dispatch({ type: types.SET_DEFINITION_SETTINGS, payload: data});
};

export const setMissingLetterSettings = (data) => (dispatch) => {
  dispatch({ type: types.SET_MISSING_LETTERS_SETTINGS, payload: data});
};

export const setListeningSettings = (data) => (dispatch) => {
  dispatch({ type: types.SET_LISTENING_SETTINGS, payload: data});
};

export const setCachedPreviewWords = (data) => (dispatch) => {
  dispatch({ type: types.SET_PREVIEW_WORDS_FROM_CACHE, payload: data});
};

export const setCachedCollectWordWords = (data) => (dispatch) => {
  dispatch({ type: types.SET_COLLECT_WORDS_FROM_CACHE, payload: data});
};

export const setCachedListeningWords = (data) => (dispatch) => {
  dispatch({ type: types.SET_LISTENING_WORDS_FROM_CACHE, payload: data});
};

export const setCachedCheckSpellingWords = (data) => (dispatch) => {
  dispatch({ type: types.SET_CHECK_SPELLING_WORDS_FROM_CACHE, payload: data});
};

export const setCachedMissingLettersWords = (data) => (dispatch) => {
  dispatch({ type: types.SET_MISSING_LETTERS_FROM_CACHE, payload: data});
};

export const setCachedCoursePreviewWords = (data) => (dispatch) => {
  dispatch({ type: types.SET_COURSE_PREVIEW_WORDS_FROM_CACHE, payload: data});
};

export const setCachedCollectSentenceWords = (data) => (dispatch) => {
  dispatch({ type: types.SET_COLLECT_SENTENCE_WORDS_FROM_CACHE, payload: data});
};

export const setCachedSelectTranslationWords = (data) => (dispatch) => {
  dispatch({ type: types.SET_SELECT_TRANSLATION_WORDS_FROM_CACHE, payload: data});
};

export const setCachedSelectWordWords = (data) => (dispatch) => {
  dispatch({ type: types.SET_SELECT_WORD_WORDS_FROM_CACHE, payload: data});
};

export const setCachedDragImageWords = (data) => (dispatch) => {
  dispatch({ type: types.SET_DRAG_IMAGE_WORDS_FROM_CACHE, payload: data});
};

export const setCachedDefinitionWords = (data) => (dispatch) => {
  dispatch({ type: types.SET_DEFINITION_WORDS_FROM_CACHE, payload: data});
};

export const setCachedMemoryCardsWords = (data) => (dispatch) => {
  dispatch({ type: types.SET_MEMORY_CARD_WORDS_FROM_CACHE, payload: data});
};

export const moveNewListToRepeat = (list) => (dispatch) => {
  dispatch({ type: types.MOVE_NEW_LIST_TO_REPEAT, payload: {list} });
};

export const removeListFromRepeatSection = (listId) => (dispatch) => {
  dispatch({ type: types.REMOVE_LIST_FROM_REPEAT_SECTION, payload: {listId} });
};

export const updateListTrainings = (listId) => (dispatch) => {
  dispatch({ type: types.UPDATE_LIST_TRAININGS, payload: {listId} });
};

export const updateTrainingsListFromRepeatSection = (listId) => (dispatch) => {
  dispatch({ type: types.UPDATE_TRAINING_LIST_REPEAT_SECTION, payload: {listId} });
};

export const updateCurrentListTrainings = (listId) => (dispatch) => {
  dispatch({ type: types.UPDATE_CURRENT_LIST_TRAININGS, payload: {listId} });
};

export const setCurrentTrainingId = (id) => (dispatch) => {
  dispatch({ type: types.SET_CURRENT_TRAINING_ID, payload: id });
};

export const resetCurrentTrainingId = () => (dispatch) => {
  dispatch({ type: types.RESET_CURRENT_TRAINING_ID });
};

export const setHideKnown = (status) => (dispatch) => {
  dispatch({ type: types.SET_HIDE_FROM_KNOWN, payload: status });
};

export const setHideKnownCourse = (status) => (dispatch) => {
  dispatch({ type: types.SET_HIDE_FROM_KNOWN_COURSE, payload: status });
};

export const cleanTrainingsCache = () => (dispatch) => {
  dispatch({ type: types.CLEAN_TRAININGS_CACHE });
};

export const cleanTrainingsCacheCourse = () => (dispatch) => {
  dispatch({ type: types.CLEAN_TRAININGS_CACHE_COURSE });
};

export const addImageToTrainingWords = (image) => (dispatch) => {
  dispatch({ type: types.ADD_CACHED_TRAINING_WORD_IMAGE, payload: image });
};

export const shuffleTrainingWords = () => (dispatch) => {
  dispatch({ type: types.SHUFFLE_TRAINING_WORDS });
};

export const toggleShuffleWords = () => (dispatch) => {
  dispatch({ type: types.TOGGLE_SHUFFLE_WORDS_SETTING });
};

export const addUserExampleTranslation = (wordId, exampleId, translation) => (dispatch) => {
  dispatch({ type: types.ADD_USER_EXAMPLE_TRANSLATION, payload: {wordId, exampleId, translation} });
};
