import React, {Component} from 'react';
import {connect} from "react-redux";
import {Translate} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faPlusCircle, faCheckCircle, faCircle} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Loader from "../../../components/Custom/Loader/index";

import {logActivity} from '../../../actions/logActions';
import {changeAudioLanguage} from '../../../actions/userActions';
import {fetchingData} from '../../../actions/activity';

import * as constants from '../../../lib/constants';
import colors from '../../../lib/colors';

class ChangeAudioLanguage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tour: localStorage.getItem('tour'),
    };
  }

  _changeAudioLanguage = (language) => {
    if(!this.state.tour) {
      this.props.logActivity({activityId: 331, comment: language});
      this.props.fetchingData(true);

      this.props.changeAudioLanguage(language);
    } else {
      this.props.onClose(false);
      this.props.toRegister(true);
    }
  };

  playTrack = (url, e) => {
    e.preventDefault();

    const {isConnected} = this.props.settings;

    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    if (isConnected) {
      this.track = new Audio(`${constants.BACKEND_URL}${url}`);
      this.track.play();
    }
  };

  render() {
    const {isVisible, onClose} = this.props;
    const {fontSizeRation, deviceWidth} = this.props.settings;
    const {userData} = this.props.user;
    const {fetchingData} = this.props.activity;

    return (
      <Modal
        className="changeaudiolanguage"
        show={isVisible}
        onHide={()=>onClose(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="audio_language"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            <Translate id="audio_language_description"/>
          </p>

          <div className="flexH spaceBetween" style={{width: '100%', marginBottom: 10}}>
            <a href="#"
               onClick={(e) => {
                e.preventDefault();
                if(userData && userData.audio_language != 'audio_gb_male') {
                  this._changeAudioLanguage('audio_gb_male')
                }
               }}
            >
              <div className="p3 flexH flexStart">
                <FontAwesomeIcon
                  icon={userData && userData.audio_language == 'audio_gb_male' ? faCheckCircle: faCircle}
                  style={{color: userData && userData.audio_language == 'audio_gb_male' ? colors.tealish : colors.lightGreyThree, marginRight: 10, fontSize: 20}}
                />

                <span className="font-weight-bold text-secondary" style={{fontSize: fontSizeRation * 14}}>
                  <Translate id="audio_gb_male"/>
                </span>
              </div>
            </a>

            <a href="#"
               style={{width: 50}}
               onClick={(e) => this.playTrack('/upload/audio/dictionary_en/audio_gb_male/2020/04/04/44046_WVVhklNyMHE7cRgLEaNdMI1HNpIdmVom.mp3', e)}
            >
              <FontAwesomeIcon
                icon={faVolumeUp}
                style={{padding: 10,color: colors.greyishBrown, fontSize: 40}}/>
            </a>
          </div>

          <div className="flexH spaceBetween" style={{width: '100%', marginBottom: 10}}>
            <a href="#"
               onClick={(e) => {
                e.preventDefault();
                if(userData && userData.audio_language != 'audio_gb_female') {
                  this._changeAudioLanguage('audio_gb_female')
                }
               }}
            >
              <div className="p3 flexH flexStart">
                <FontAwesomeIcon
                  icon={userData && userData.audio_language == 'audio_gb_female' ? faCheckCircle: faCircle}
                  style={{color: userData && userData.audio_language == 'audio_gb_female' ? colors.tealish : colors.lightGreyThree, marginRight: 10, fontSize: 20}}
                />

                <span className="font-weight-bold text-secondary" style={{fontSize: fontSizeRation * 14}}>
                  <Translate id="audio_gb_female"/>
                </span>
              </div>
            </a>

            <a href="#"
               style={{width: 50}}
               onClick={(e) => this.playTrack('/upload/audio/dictionary_en/audio_gb_female/2020/04/04/44046_XAPIFzkozKKCGlKlMz4XkIUirlvqQBoe.mp3', e)}
            >
              <FontAwesomeIcon
                icon={faVolumeUp}
                style={{padding: 10,color: colors.greyishBrown, fontSize: 40}}/>
            </a>
          </div>

          <div className="flexH spaceBetween" style={{width: '100%', marginBottom: 10}}>
            <a href="#"
               onClick={(e) => {
                e.preventDefault();
                  if(userData && userData.audio_language != 'audio_us_male') {
                    this._changeAudioLanguage('audio_us_male')
                  }
               }}
            >
              <div className="p3 flexH flexStart">
                <FontAwesomeIcon
                  icon={userData && userData.audio_language == 'audio_us_male' ? faCheckCircle : faCircle}
                  style={{color: userData && userData.audio_language == 'audio_us_male' ? colors.tealish : colors.lightGreyThree, marginRight: 10, fontSize: 20}}
                />

                <span className="font-weight-bold text-secondary" style={{fontSize: fontSizeRation * 14}}>
                  <Translate id="audio_us_male"/>
                </span>
              </div>
            </a>

            <a
              href="#"
              style={{width: 50}}
              onClick={(e) => this.playTrack('/upload/audio/dictionary_en/audio_us_male/2020/04/04/44046_kBmuEvinjJKOgSQ93FD4DOaKuEuvw0yx.mp3', e)}>
              <FontAwesomeIcon
                icon={faVolumeUp}
                style={{padding: 10,color: colors.greyishBrown, fontSize: 40}}/>
            </a>
          </div>

          <div className="flexH spaceBetween" style={{width: '100%', marginBottom: 10}}>
            <a href="#"
               onClick={(e) => {
                e.preventDefault();
                  if(userData && userData.audio_language != 'audio_us_female') {
                    this._changeAudioLanguage('audio_us_female')
                  }
               }}
            >
              <div className="p3 flexH flexStart">
                <FontAwesomeIcon
                  icon={userData && userData.audio_language == 'audio_us_female' ? faCheckCircle: faCircle}
                  style={{color: userData && userData.audio_language == 'audio_us_female' ? colors.tealish : colors.lightGreyThree, marginRight: 10, fontSize: 20}}
                />

                <span className="font-weight-bold text-secondary" style={{fontSize: fontSizeRation * 14}}>
                  <Translate id="audio_us_female"/>
                </span>
              </div>
            </a>

            <a href="#"
               style={{width: 50}}
               onClick={(e) => this.playTrack('/upload/audio/dictionary_en/audio_us_female/2020/04/04/44046_L9CBwcq0XRD6gdef70M679BYtAK6ZJ31.mp3', e)}>
              <FontAwesomeIcon
                icon={faVolumeUp}
                style={{padding: 10,color: colors.greyishBrown, fontSize: 40}}/>
            </a>
          </div>

        </Modal.Body>
        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  purchases: state.purchases,
  settings: state.settings,
  user: state.user,
  activity: state.activity,
});

function bindAction(dispatch) {
  return {
    changeAudioLanguage: (language) => dispatch(changeAudioLanguage(language)),
    fetchingData: (status) => dispatch(fetchingData(status)),

    logActivity: (data) => dispatch(logActivity(data)),
  };
}

export default connect(mapStateToProps, bindAction)(ChangeAudioLanguage);

