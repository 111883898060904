import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner, faTrophy, faGift,faQuestion} from '@fortawesome/free-solid-svg-icons';

import {Translate, getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';

import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import ListGroup from 'react-bootstrap/ListGroup';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import WordInfo from '../../../../components/Modals/WordInfo/index';

import Loader from "../../../../components/Custom/Loader/index";

import {
  getMultiWordMakerResults,
  resetMultiWordMakerResults,
  resetStartStatus
} from '../../../../actions/multiWordMakerActions';
import {logActivity} from '../../../../actions/logActions';
import {fetchingData} from '../../../../actions/activity';
import {get_word_details} from '../../../../actions/wordActions';

import colors from '../../../../lib/colors';

class MultiWordMakerGameResults extends Component {

  constructor(props) {
    super(props);

    this.state = {
      wordInfoVisible: false,
      gameId: this.props.match.params.id,
    };

    this.timeout = null;
  }

  componentDidMount() {
    this.props.fetchingData(true);

    this.timeout = setTimeout(()=> {
      this._getResults();
    }, 2000);

  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.props.resetMultiWordMakerResults();
    this.props.resetStartStatus(false);
  }

  _getResults = () => {
    this.props.fetchingData(true);
    this.props.getMultiWordMakerResults(this.props.match.params.id)
  };

  _showWordDetails = (e, wordId) => {
    e.preventDefault();

    this.props.fetchingData(true);
    this.props.get_word_details(wordId);
    this._toggleWordInfo(true);
  };

  _toggleWordInfo = (status) => {
    this.setState({
      wordInfoVisible: status
    })
  };

  render() {
    const {currentActiveGameResults} = this.props.multiWordMaker;
    const {translate} = this.props;
    const {wordInfoVisible} = this.state;
    const {userId} = this.props.user;
    const {fetchingData} = this.props.activity;

    return (
      <div className="multiwordmakergameresults">
        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to="/home">
              {translate('home')}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/contest-wordmaker">
              {translate('word_maker_contest')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate('word_maker_contest_results')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container className="pageWrapper">

          <Card className="p-3 d-flex justify-content-center align-items-center" style={{minHeight: 300}}>
            {
              currentActiveGameResults && (
                <div className="d-flex flex-column justify-content-center align-items-center">
                  {
                    currentActiveGameResults.position == 1 && (
                      <div className="mb-3 text-center">
                        <h4>{translate('our_congratulations')}</h4>
                        <FontAwesomeIcon
                          color={colors.golden}
                          style={{fontSize: 40}}
                          icon={faTrophy}
                        />
                      </div>
                    )
                  }

                  {
                    currentActiveGameResults.position == 2 && (
                      <div className="mb-3 text-center">
                        <h4>{translate('our_congratulations_second_place')}</h4>
                        <FontAwesomeIcon
                          color={colors.silver}
                          style={{fontSize: 40}}
                          icon={faTrophy}
                        />
                      </div>
                    )
                  }
                  {
                    currentActiveGameResults.position == 3 && (
                      <div className="mb-3 text-center">
                        <h4>{translate('our_congratulations_third_place')}</h4>
                        <FontAwesomeIcon
                          color={colors.bronze}
                          style={{fontSize: 40}}
                          icon={faTrophy}
                        />
                      </div>
                    )
                  }
                  {
                    currentActiveGameResults.position > 3 && (
                      <div className="mb-3 text-center">
                        <h4>{translate('our_congratulations_on_participation')}</h4>
                        <FontAwesomeIcon
                          color={colors.waterBlue}
                          style={{fontSize: 40}}
                          icon={faGift}
                        />
                      </div>
                    )
                  }
                  
                  {
                    !currentActiveGameResults.all_finished && (
                      <Alert variant="warning" className="mt-3 mb-3 text-center ml-auto mr-auto" style={{maxWidth: 600}}>
                        <p className="mb-2 text-muted">
                          {translate('not_all_finished')}
                        </p>
                        
                        <a href="#" onClick={(e)=> {
                          e.preventDefault();
                          this._getResults();
                        }}>{translate('refresh_game_results')}</a>
                      </Alert>
                    ) || ''
                  }
                  <p className="mb-0">{translate('your_place')}: {currentActiveGameResults.position} / {currentActiveGameResults.total}</p>
                  <p>{translate('scoresUC')}: {currentActiveGameResults.scores}</p>

                  <ListGroup style={{minWidth: 400}}>
                    {
                      currentActiveGameResults.users.map((user, i) => {
                        return (
                          <ListGroup.Item key={i} className={user.user_id == userId ? 'active' : ''}>
                            <div className="d-flex justify-content-between align-items-center">
                              <span>
                              {i+1}. {user.user && user.user.first_name ? user.user.first_name : 'John Doe'}
                              </span>
                              <div>
                              <span>
                                {user.scores} {translate('scores')} <span style={{fontStyle: 'italic'}}>({user.word_ids ? user.word_ids.length : 0} {translate('lwords')})</span>
                              </span>
                                {
                                  user.finished == 0 && (
                                  <FontAwesomeIcon
                                    style={{fontSize: 15, marginLeft: 10}}
                                    icon={faQuestion}
                                  />
                                  ) ||  null
                                }
                                </div>
                            </div>
                          </ListGroup.Item>
                        )
                      })
                    }
                  </ListGroup>

                  {
                    currentActiveGameResults.words && currentActiveGameResults.words.length > 0 && (
                      <React.Fragment>
                        <h5 className="mt-3 text-center">{translate('found_in_dictionary')}</h5>

                        <div className="d-flex flex-wrap justify-content-center align-items-center">
                          {currentActiveGameResults.words.map((word, id) => {
                            return (
                              <a
                                key={id}
                                href="#"
                                onClick={(e) => this._showWordDetails(e, word.id)}
                                className="d-inline-block p-2 "
                              >
                                {word.word}
                              </a>
                            )
                          })}
                        </div>
                      </React.Fragment>
                    )
                  }
                  {
                    currentActiveGameResults.not_found && currentActiveGameResults.not_found.length > 0 && (
                      <React.Fragment>
                        <h5 className="mt-3 text-center">{translate('not_found_in_dictionary')}</h5>

                        <div className="d-flex flex-wrap justify-content-center align-items-center">
                          {currentActiveGameResults.not_found.map((word, id) => {
                            return (
                              <span key={id} className="d-inline-block p-2 text-cross">
                                {word}
                              </span>
                            )
                          })}
                        </div>
                      </React.Fragment>
                    )
                  }
                </div>
              ) || (
                <React.Fragment>
                  <h4 className="text-center">{translate('fetching_data')}</h4>

                  <FontAwesomeIcon
                    className="fa-spin"
                    style={{fontSize: 40}}
                    icon={faSpinner}
                  />
                </React.Fragment>
              )
            }
          </Card>

        </Container>

        {wordInfoVisible && (<WordInfo onClose={this._toggleWordInfo} isVisible={wordInfoVisible}/>)}
        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  multiWordMaker: state.multiWordMaker,
  purchases: state.purchases,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize)

});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    getMultiWordMakerResults: (gameId) => dispatch(getMultiWordMakerResults(gameId)),
    resetMultiWordMakerResults: () => dispatch(resetMultiWordMakerResults()),
    get_word_details: (wordId) => dispatch(get_word_details(wordId)),
    resetStartStatus: (status) => dispatch(resetStartStatus(status)),

  };
}

export default connect(mapStateToProps, bindAction)(MultiWordMakerGameResults);
