import * as types from '../actions/types';

const INITIAL_STATE = {
  glossaries: [],
  lists: [],
  words: [],
  phrases: [],
  totalWords: 0,
  totalPhrases: 0,
  totalPages: 1,
  totalPhrasesPages: 1,
  fetchedFavoriteWords: false,
  glossariesBadge: 0,
  listsBadge: 0,
  wordsBadge: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_FAVORITE_GLOSSARIES:
      return Object.assign({}, state, {
        glossaries: action.payload.data,
      });

    case types.SET_FAVORITE_LISTS:
      return Object.assign({}, state, {
        lists: action.payload.data,
      });

    case types.SET_FAVORITE_WORDS:
      return Object.assign({}, state, {
        fetchedFavoriteWords: true,
        words: action.payload.data,
      });

    case types.SET_FAVORITE_WORDS_WITH_PAGES:
      return Object.assign({}, state, {
        words: action.payload.data,
        fetchedFavoriteWords: true,
        totalWords: action.payload.total_words,
        totalPages: action.payload.total_pages,
      });

    case types.SET_FAVORITE_PHRASES_WITH_PAGES:
      return Object.assign({}, state, {
        phrases: action.payload.data,
        totalPhrases: action.payload.total_phrases,
        totalPhrasesPages: action.payload.total_pages,
      });

    case types.SET_FAVORITE_NUMBERS:
      return Object.assign({}, state, {
        glossariesBadge: action.payload.glossaries,
        listsBadge: action.payload.lists,
        wordsBadge: action.payload.words,
      });

    case types.DELETE_FAVORITE_PHRASE_FROM_PHRASES:
    {
      const phrases = state.phrases.filter(phrase => {
        return phrase.id != action.payload;
      })
      return Object.assign({}, state, {
        phrases,
        totalPhrases: state.totalPhrases - 1
      });
    }

    case types.UPDATE_VOCABULARY_IN_FAVORITE_WORDS:
    {
      let words = state.words.map((word, i) => {

        if (word.word_id == action.payload.wordId) {
          word.in_vocabulary = !word.in_vocabulary
        }

        return word;
      })

      return Object.assign({}, state, {
        words,
      });
    }

    default:
      return state;
  }
}