import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faCircle} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const InfoModal = ({isVisible, onClose, translate}) => {
  
  return (
    <Modal
      className="listnamemodal"
      show={isVisible}
      onHide={()=>onClose('infoModalVisible',false)}
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <Translate id={'demo_modal_title'}/>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="text-center">
        <p style={{whiteSpace: 'pre-line', textAlign: 'left'}}>
          {translate('demo_modal_description')}
        </p>

        <Button
          className="mt-3"
          block
          variant="outline-secondary"
          onClick={()=>onClose('infoModalVisible',false)}>
          {translate('ok')}
        </Button>
      </Modal.Body>
    </Modal>
  )
};

export default connect(null, null)(InfoModal);
