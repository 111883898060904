import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircle, faCogs, faCheckCircle,
  faTrashAlt,
  faCamera,
  faVolumeUp,
  faTimes, faSearch, faPlus
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import _ from 'lodash'

import {Translate, getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';
import moment from 'moment';

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ListGroup from 'react-bootstrap/ListGroup';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';


import {getHelperArticles, setCurrentHelperArticle} from '../../../actions/helperArticlesActions';
import Loader from "../../../components/Custom/Loader/index";
import HelperArticleCell from "../../../components/Cells/HelperArticleCell/index";

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

import colors from '../../../lib/colors';
import * as constants from '../../../lib/constants';

class HelperArticles extends Component {

  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      searchBarVisible: false,
      word: '',

    };

    this.timeOut = null;
  }

  componentDidMount() {
    this._getHelperArticles();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._getHelperArticles();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
  }

  _getHelperArticles = () => {
    this.props.fetchingData(true);
    this.props.getHelperArticles();
  };

  refreshData = () => {
    this.setState({
      refreshing: true,
    });

    this._getHelperArticles();

    this.timeOut = setTimeout(()=> {
      this.setState({
        refreshing: false,
      })
    }, 500)
  };

  _onPressItem = (article, index) => {
    this.props.setCurrentHelperArticle(article);
    this.props.logActivity({activityId: 546, model: 'helper article', modelId: article.id});

    // this.props.navigation.navigate('HelperArticle')
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/article/${article.slug}`, {back: true, article});
  };

  _keyExtractor = (item, index) => {
    return `article-${item.id}-${index}`
  };

  _renderItem = (item, index) => {
    const {word} = this.state;
    const {fontSizeRation, version} = this.props.settings;
    const {translate} = this.props;

    if(!word || item.keywords.indexOf(word) !== -1) {
      return (
        <HelperArticleCell
          key={index}
          article={item}
          index={index}
          version={version}
          ratio={fontSizeRation}
          translate={translate}
          onItemPressed={this._onPressItem}
        />
      )
    } else {
      return null;
    }
  };

  toggleSearchBar = () => {
    // this.props.logActivity({activityId: 16});

    if (this.state.searchBarVisible) {
      this.setState({
        word: ''
      });
    }

    this.setState({
      searchBarVisible: !this.state.searchBarVisible
    })
  };
  
  render() {
    const {translate} = this.props;
    const {searchBarVisible, word} = this.state;
    const {helperArticles} = this.props.helperArticles;
    const {fetchingData} = this.props.activity;
    const {fontSizeRation, locale} = this.props.settings;


    return (
      <div className="helper-articles">
        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate('helper_articles')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container fluid className="pageWrapper">
            <div className="w-100 flex-wrap d-flex flex-row justify-content-center align-items-center">
              {
                helperArticles.map((article, index) => {
                  return this._renderItem(article, index);
                })
              }
            </div>
        </Container>

        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  helperArticles: state.helperArticles,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize)

});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    getHelperArticles: () => dispatch(getHelperArticles()),
    setCurrentHelperArticle: (article) => dispatch(setCurrentHelperArticle(article)),

  };
}

export default connect(mapStateToProps, bindAction)(HelperArticles);
