import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faCheck, faTrash, faTimes} from '@fortawesome/free-solid-svg-icons';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import Modal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/FormControl';

import Loader from "../../../components/Custom/Loader/index";

import {createTag, deleteTag, disconnectTag, setSelectedTag, resetSelectedTags} from '../../../actions/tagActions';
import {fetchingData} from '../../../actions/activity';

import colors from '../../../lib/colors';

class TagsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      tagName: '',
      color: colors.tealish,
      colorsVisible: false
    };
    
    this.timeOut = null;
    this.timeOut1 = null;
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut1);
  }

  _createTag = (color) => {
    const {tagName} = this.state;

    if (tagName.length > 0) {
      this.props.fetchingData(true);
      this.props.createTag(tagName, color);

      this.props.logActivity({activityId: 350});

      this.setState({
        colorsVisible: false,
        tagName: ''
      })
    }
  };

  _showColors = () => {
    const {tagName} = this.state;

    if (tagName.length > 0) {
      this.setState({
        colorsVisible: true
      })
    }
  };

  _setColor = (color, e) => {
    e.preventDefault();
    this._createTag(color);
  };

  _onDelete = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.resetSelectedTags();

    this.timeOut = setTimeout(()=> {
      this.props.fetchingData(true);
      this.props.deleteTag(id);

      this.props.logActivity({activityId: 351});

    }, 100)
  };

  _disconnectTag = (listId, tagId, e) => {
    e.preventDefault();

    this.props.resetSelectedTags();

    this.timeOut1 = setTimeout(()=> {
      this.props.fetchingData(true);
      this.props.disconnectTag(listId);
    }, 100)
  };

  _setTagName = (tagName) => {

    this.setState({
      colorsVisible: tagName.length > 0,
      tagName: tagName.length < 20 ? tagName : tagName.substr(0, 20)
    })
  };


  _handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this._showColors();
    }
  };

  render() {
    const {isVisible, onClose, ratio, tablet, tags, onSelect, currentList, translate, tour} = this.props;
    const {tagName, colorsVisible} = this.state;
    const {fetchingData} = this.props.activity;

    return (
      <Modal
        className="tags"
        show={isVisible}
        onHide={()=>onClose('tagsModalVisible',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id={currentList && currentList.id ? "select_create_tag" : "create_tag"}/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{minHeight: 200}}>
          <FormControl
            disabled={tour}
            onKeyPress={(e)=>this._handleKeyPress(e)}
            value={tagName}
            onChange={(e)=>this._setTagName(e.target.value)}
            placeholder={translate('enter_tag_name')}
          />

          {
            colorsVisible && (
              <div className="w100 flexV flexStart" style={{marginBottom: 25}}>
                <span
                  className="tagName"
                  style={{fontSize: ratio * (!tablet ? 14 : 16),marginBottom: 10, marginTop: 10}}
                >
                  {translate('select_tag_color')}
                </span>

                <div className="w100 flexH spaceAround">
                  {
                    [colors.dustyOrange, colors.greyishBrown, colors.tealish, colors.coral, colors.waterBlue, colors.melon,
                      colors.golden, colors.headerBlue, colors.dustyGreen].map((item, i) => {
                      return (
                        <a
                          href="#"
                          key={i}
                          onClick={(e)=> this._setColor(item, e)}
                        >
                          <div style={{
                            backgroundColor: item,
                            width: !tablet ? 20 : 30,
                            height: !tablet ? 20 : 30,
                            borderRadius: !tablet ? 10 : 15,
                            marginHorizontal: 5,
                          }}/>
                        </a>
                      )
                    })
                  }
                </div>
              </div>
            )
          }
          {
            currentList && currentList.tag && !colorsVisible && (
              <a href="#"
                 className="flexH flexStart w100"
                 style={{marginBottom: 10,paddingHorizontal: 25}}
                 onClick={(e)=> this._disconnectTag(currentList.id, currentList.tag.id, e)}
              >
                <FontAwesomeIcon
                  style={{marginRight: 10, color: colors.coral, fontSize: !tablet ? 20 : 30}}
                  icon={faTimes}
                />
                <span
                  className="tagName"
                  style={{fontSize: ratio * (!tablet ? 14 : 16)}}
                >
                  {currentList && currentList.tag ? currentList.tag.tag_name : ''}
                </span>
              </a>
            )
          }

          <div style={{padding: "10px 0", width: '100%',minHeight: 250, maxHeight: 300, overflowY: 'auto'}}>
            {
              !colorsVisible && tags.length > 0 && tags.map((item, i) => {
                return (
                  <div
                    style={{cursor: currentList && currentList.id ? 'pointer' : 'default'}}
                    href="#" key={i} onClick={(e) => {
                    if(currentList && currentList.id) {
                      onSelect(item, e);
                    }
                  }}>
                    <div className="flexH spaceBetween" style={{marginBottom: !tablet ? 10 : 20, width: '100%'}}
                    >
                      <div className="flexH flexCenter">

                        <div style={{
                            backgroundColor: item.color,
                            width: !tablet ? 20 : 30,
                            height: !tablet ? 20 : 30,
                            borderRadius: !tablet ? 10 : 15,
                            marginRight: 10,
                          }}/>

                          <span style={{fontSize: ratio * (!tablet ? 16 : 20), color: colors.greyishBrown}}>
                            {item.tag_name}
                          </span>
                      </div>

                      <a href="#"
                         style={{paddingRight:20}}
                         onClick={(e)=>this._onDelete(item.id, e)}
                      >
                        <FontAwesomeIcon
                          style={{color: colors.coral, fontSize: !tablet ? 15 : 25}}
                          icon={faTrash}
                        />
                      </a>

                    </div>
                  </div>
                )
              })
            }
          </div>

          <Loader fetchingData={fetchingData}/>
        </Modal.Body>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),
  // tags: state.tags,

});

function bindAction(dispatch) {
  return {
    createTag: (tagName, color) => dispatch(createTag(tagName, color)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    deleteTag: (id) => dispatch(deleteTag(id)),
    setSelectedTag: (id) => dispatch(setSelectedTag(id)),
    disconnectTag: (listId) => dispatch(disconnectTag(listId)),
    resetSelectedTags: () => dispatch(resetSelectedTags()),

  };
}

export default connect(mapStateToProps, bindAction)(TagsModal);
