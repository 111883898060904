import * as types from '../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {
  parents: [],
  totalGlossaries: 0,
  totalGlossariesWords: 0,
  parentSections: [],
  currentChild: {},
  glossaryWords: [],
  totalGlossaryWords: 0,
  totalPages: 0,

  currentListInLists: {},
  // currentList: {},

  listsFetched: false,
  lists: [],
  totalLists: 0,
  totalWords: 0,
  showListTrainingButtons: false,

  fetchedListWords: false,
  listWords: [],
  word: {},


  coursesDebts: [],
  courseLists: [],
  courseListWords: [],
  listRepetitions: [],
  courseData: false,
  fetched: false,
  currentCourseSlug: 1000,
  currentCourseName: "Course 1000",
  currentList: false,
  courseListType: false,
  courseListWords: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_TOUR_GLOSSARIES:
    {
      let parentSections = [];

      action.payload.data.map((item, i) => {
        parentSections.push({
          title: item.glossary_name,
          children: item.children_number,
          words: item.total_words,
          data: item.children,
          index: i
        })
      });

      return Object.assign({}, state, {
        parents: action.payload.data,
        totalGlossaries: action.payload.total_glossaries,
        totalGlossariesWords: action.payload.total_words,
        parentSections
      });
    }

    case types.SET_TOUR_GLOSSARY:
      return Object.assign({}, state, {
        glossaryWords: _.union(state.glossaryWords, action.payload.data),
        totalGlossaryWords: action.payload.total_words,
        totalPages: action.payload.total_pages,
      });

    case types.RESET_TOUR_GLOSSARY:
      return Object.assign({}, state, {
        glossaryWords: [],
        totalGlossaryWords: 0,
        totalPages: 0,
      });

    case types.SET_TOUR_WORD:
    {
      return Object.assign({}, state, {
        word: action.payload,
      });
    }

    case types.RESET_TOUR_WORD:
    {
      return Object.assign({}, state, {
        word: {},
      });
    }

    case types.SET_CURRENT_TOUR_LIST:
    {
      return Object.assign({}, state, {
        currentList: action.payload,
        currentListInLists: action.payload,
      });
    }

    case types.SET_TOUR_LISTS:
      return Object.assign({}, state, {
        lists: action.payload.data,
        totalLists: action.payload.total_lists,
        totalWords: action.payload.total_words,
        listsFetched: true
      });


    case types.SET_SHOW_LIST_TRAINING_BUTTONS_MODAL_TOUR:
      return Object.assign({}, state, {
        showListTrainingButtons: action.payload,
      });

    case types.SET_TOUR_LIST_WORDS:
      return Object.assign({}, state, {
        listWords: action.payload.data,
        fetchedListWords: true
      });

    case types.RESET_TOUR_LIST_WORDS:
      return Object.assign({}, state, {
        listWords: [],
        fetchedListWords: false
      });

    case types.SET_TOUR_COURSE_DATA:
      return Object.assign({}, state, {
        courseLists: action.payload.data,
        fetched: true,
        courseData: action.payload.course_data
      });

    case types.SET_CURRENT_TOUR_COURSE_LIST:
      return Object.assign({}, state, {
        currentList: action.payload,
      });

    case types.RESET_CURRENT_TOUR_COURSE_LIST:
      return Object.assign({}, state, {
        currentList: false,
      });

    case types.SET_CURRENT_TOUR_COURSE_LIST_TYPE:
      return Object.assign({}, state, {
        courseListType: action.payload,
      });

    case types.SET_TOUR_COURSE_LIST_WORDS:
      return Object.assign({}, state, {
        courseListWords: action.payload.data,
      });

    case types.RESET_TOUR_COURSE_LIST_WORDS:
      return Object.assign({}, state, {
        courseListWords: [],
      });

    default:
      return state;
  }
}