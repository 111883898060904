import * as types from './types';

export const get_word_details = (wordId, byWord, history) => (dispatch) => {
  dispatch({type: types.GET_CURRENT_WORD, payload: {wordId, byWord, history}});
};

export const resetWordDetails = (wordId) => (dispatch) => {
  dispatch({type: types.RESET_WORD_DETAILS, payload: wordId});
};

export const toggleInVocabularyWordDetails = (word_id) => (dispatch) => {
  dispatch({ type: types.TOGGLE_WORD_VOCABULARY_DETAILS, payload: word_id });
};

export const toggleFavoriteWordDetails = (word_id) => (dispatch) => {
  dispatch({ type: types.TOGGLE_WORD_FAVORITE_DETAILS, payload: word_id });
};

export const get_extended_info = (method, word_id) => (dispatch) => {
  dispatch({ type: types.GET_EXTENDED_INFO, payload: {method, word_id} });
};

export const addUserTranslation = (translation, word_id) => (dispatch) => {
  dispatch({ type: types.ADD_WORD_TRANSLATION, payload: {translation, word_id} });
};

export const addUserAssociation = (association, word_id) => (dispatch) => {
  dispatch({ type: types.ADD_WORD_ASSOCIATION, payload: {association, word_id} });
};

export const deleteUserAssociation = (associationId, wordId) => (dispatch) => {
  dispatch({ type: types.DELETE_WORD_ASSOCIATION, payload: {associationId, wordId} });
};

export const changeMainTranslation = (translation_id, word_id) => (dispatch) => {
  dispatch({ type: types.CHANGE_MAIN_TRANSLATION, payload: {translation_id, word_id} });
};

export const deleteUserTranslation = (translation_id, word_id) => (dispatch) => {
  dispatch({ type: types.DELETE_USER_TRANSLATION, payload: {translation_id, word_id} });
};

export const setFirstPlayed = (status) => (dispatch) => {
  dispatch({ type: types.SET_FIRST_PLAYER, payload: status});
};

export const getWordToOpen = (wordId, userId) => (dispatch) => {
  dispatch({ type: types.GET_WORD_TO_OPEN, payload: {wordId, userId}});
};

export const deleteUserImage = (wordId) => (dispatch) => {
  dispatch({ type: types.DELETE_WORD_USER_IMAGE, payload: wordId});
};

export const addUserExample = (wordId, example) => (dispatch) => {
  dispatch({ type: types.ADD_USER_EXAMPLE, payload: {wordId, example}});
};
