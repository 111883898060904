import * as types from '../actions/types';

const INITIAL_STATE = {
  badges: [],
  opened: 0,
  fetched: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_USER_BADGES:
      return Object.assign({}, state, {
        badges: action.payload.data,
        opened: action.payload.opened,
        fetched: true
      });
    
    case types.RESET_BADGES:
      return Object.assign({}, state, {
        badges: [],
        opened: 0,
        fetched: false
      });

    default:
      return state;
  }
}