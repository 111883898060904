import React, {Component} from 'react';
import {connect} from "react-redux";

import moment from 'moment';
import {LiqPayPay, LiqPaySubscribe} from "react-liqpay";
import {Translate, getTranslate} from 'react-localize-redux';
import {withRouter, Link} from 'react-router-dom';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRedo} from '@fortawesome/free-solid-svg-icons';

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

import {fetchingData} from '../../../actions/activity';
import {
  getUserPurchases,
  cancelSubscription,
  getPurchaseData,
  getLiqpaySubscriptionStatus
} from '../../../actions/purchasesActions';

import Loader from "../../../components/Custom/Loader/index";

class Purchases extends Component {
  constructor(props) {
    super(props);
    const userId = localStorage.getItem('userId');


    this.state = {
      tablet: false,
      showRefreshButton: false,
      products: [
        {
          id: 'lifetime_access',
          name: 'lifetime_access',
          description: 'lifetime_access_description',
        },
        {
          id: 'lifetime.access',
          name: 'lifetime_access',
          description: 'lifetime_access_description',
        },
        {
          id: 'lifetime.access.full',
          name: 'lifetime_access',
          description: 'lifetime_access_description',
        },
        {
          id: 'lifetime.access.full_30',
          name: 'lifetime_access',
          description: 'lifetime_access_description',
        },
        {
          id: 'lifetime.access.full_50',
          name: 'lifetime_access',
          description: 'lifetime_access_description',
        },

        {
          id: 'yearly_subscription',
          name: 'yearly_subscription_title',
          description: 'subscription_description_short',
        },
        {
          id: 'mnemonic.subscription.yearly_full',
          name: 'yearly_subscription_title',
          description: 'subscription_description_short',
        },
        {
          id: 'mnemonic.subscription.yearly_full_30',
          name: 'yearly_subscription_title',
          description: 'subscription_description_short',
        },
        {
          id: 'mnemonic.subscription.yearly_full_50',
          name: 'yearly_subscription_title',
          description: 'subscription_description_short',
        },

        {
          id: 'mnemonic.subscription.yearly_1.0',
          name: 'yearly_subscription_title',
          description: 'subscription_description_short',
        },
        {
          id: 'com.kudryaalexey.Mnemonic.fullYear',
          name: 'yearly_subscription_title',
          description: 'subscription_description_short',
        },
        {
          id: 'monthly_subscription',
          name: 'monthly_subscription_title',
          description: 'subscription_description_short',
        },
        {
          id: 'mnemonic.subscription.monthly_1.0',
          name: 'monthly_subscription_title',
          description: 'subscription_description_short',
        },
        {
          id: 'mnemonic.subscription.monthly_no_discount',
          name: 'monthly_subscription_title',
          description: 'subscription_description_short',
        },
        {
          id: 'com.kudryaalexey.Mnemonic.fullMontly',
          name: 'monthly_subscription_title',
          description: 'subscription_description_short',
        },
        {
          id: 'com.kudryaalexey.Mnemonic.webMonthly',
          name: 'monthly_subscription_title',
          description: 'subscription_description_short',
        },
        {
          id: 'glossary_cards',
          name: 'glossary_flash_cards',
          description: 'glossary_flash_cards_description',
        },
        {
          id: 'glossary_cards_generation',
          name: 'glossary_flash_cards',
          description: 'glossary_flash_cards_description',
        }, {
          id: 'com.kudryaalexey.Mnemonic.glossaryCardsGeneration',
          name: 'glossary_flash_cards',
          description: 'glossary_flash_cards_description',
        },
        {
          id: 'glossary_cards.generation.1.0',
          name: 'glossary_flash_cards',
          description: 'glossary_flash_cards_description',
        },
        {
          id: 'list_cards',
          name: 'list_flash_cards',
          description: 'list_flash_cards_description',
        },
        {
          id: 'com.kudryaalexey.Mnemonic.listCardsGeneration',
          name: 'list_flash_cards',
          description: 'list_flash_cards_description',
        },
        {
          id: 'flash_cards.lists',
          name: 'list_flash_cards',
          description: 'list_flash_cards_description',
        },

        {
          id: 'flash_cards',
          name: 'flash_cards',
          description: 'flash_cards_description',
        },
        {
          id: 'flash_cards.all.full',
          name: 'flash_cards',
          description: 'flash_cards_description',
        },
        {
          id: 'flash_cards.all.50',
          name: 'flash_cards',
          description: 'flash_cards_description',
        },

        {
          id: 'flash_cards.all.25',
          name: 'flash_cards',
          description: 'flash_cards_description',
        },

        {
          id: 'audio_tracks.full',
          name: 'audio_tracks',
          description: 'audio_tracks_description',
        },
        {
          id: 'audio_tracks',
          name: 'audio_tracks',
          description: 'audio_tracks_description',
        },

        {
          id: 'audio_tracks.50',
          name: 'audio_tracks',
          description: 'audio_tracks_description',
        },

        {
          id: 'audio_tracks.25',
          name: 'audio_tracks',
          description: 'audio_tracks_description',
        },
        
        {
          id: 'combo.audio_cards.full.25',
          name: 'audio_cards',
          description: 'audio_cards_description',
        },
        
        {
          id: 'combo.audio_cards.full',
          name: 'audio_cards',
          description: 'audio_cards_description',
        },
        
        {
          id: 'word_maker',
          name: 'word_maker_hints',
          description: 'word_maker_hints_description',
        },
        {
          id: 'word_maker.10_hints',
          name: 'word_maker_hints',
          description: 'word_maker_hints_description',
        },
      ]
    };
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);

    this._getPurchase();
    this._getData();

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._getData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);

    localStorage.removeItem("orderId");
  }

  onUnload = () => {
    localStorage.removeItem("orderId");
  };

  _getPurchase = () => {
    const orderId = localStorage.getItem("orderId");

    // const orderId = '1600346400-monthly_subscription-21-3';

    if (orderId != null && orderId != undefined) {
      let orderParts = orderId.split('-');
      this.props.fetchingData(true);

      if (orderParts.length > 1 && orderParts[1]) {
        if (orderParts[1] == 'monthly_subscription' || orderParts[1] == 'yearly_subscription') {
          this.props.getLiqpaySubscriptionStatus(orderId);
        } else {
          this.props.getPurchaseData(orderId);
        }
      }
    }
  };

  _refreshData = () => {
    this.props.fetchingData(true);
    this._getPurchase();
    this.props.getUserPurchases();
  };

  _toggleModal = (type, status) => {
    this.setState({
      [type]: status
    })
  };

  _getData = () => {
    const {isConnected} = this.props.settings;

    if (isConnected) {
      this.props.getUserPurchases();
    }
  };

  _getPurchaseName = (id) => {
    const {products} = this.state;


    return products.filter((p) => p.id == id)
  };

  _unsubscribe = (purchase) => {
    const info = JSON.parse(purchase.info);
    this.props.fetchingData(true);
    this.props.cancelSubscription(info.order_id, purchase.id)
  };

  render() {
    const {userData} = this.props.user;
    const {
      fetchedUserPurchases,
      userPurchases,
      webSubscriptionStatus,
      webSubscriptionExpireAt,
      liqpayPurchase,
      liqpayPurchaseFetched
    } = this.props.purchases;
    const {fetchingData} = this.props.activity;
    const {locale} = this.props.settings;

    const {translate} = this.props;

    return (
      <div className="allpurchases">
        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate('all_purchases')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container style={{marginTop: 20}} className="pageWrapper">
          {
            liqpayPurchaseFetched && liqpayPurchase && liqpayPurchase.status == 'subscribed' && (
              <div style={{maxWidth: 600, margin: '15px auto', clear: 'both'}}>
                <Alert variant="warning" className="d-flex flex-column justify-content-center align-items-center">
                  <p>{translate('subscribed_but_no_payment_received')}</p>
                  <Button variant="outline-secondary" size="sm" onClick={this._refreshData}>
                    <FontAwesomeIcon
                      icon={faRedo}
                      style={{marginRight: 10}}
                    />
                    <span>{translate('refresh_data')}</span>
                  </Button>
                </Alert>
              </div>
            ) || null
          }

          <div className="d-flex flex-wrap justify-content-around align-items-center">

            {
              userData && userData.id && fetchedUserPurchases ? (
                <React.Fragment>
                  {
                    userPurchases.length > 0 ? (
                      userPurchases.map((purchase, index) => {
                        const product = this._getPurchaseName(purchase.identifier);
                        
                        if(!product) return null;
                        
                        return <Card
                          className="border-success"
                          key={index} style={{ width: '22rem', marginBottom: 20, minHeight: 180}}>
                          <Card.Body className="position-relative">
                            <Card.Title>
                              {translate(product[0].name)}
                            </Card.Title>
                            <Card.Subtitle className="text-muted">
                              {translate('purchased_at')} {moment(purchase.created_at).format("YYYY-MM-DD HH:mm")}
                            </Card.Subtitle>
                            {
                              (purchase.action == 'subscribe' || purchase.identifier == 'lifetime.access' || purchase.identifier == 'lifetime_access') ? (
                                <Card.Subtitle className="text-muted mt-1">
                                  {translate('web_subscription_expire_on')} {moment(purchase.expiration_date).format("YYYY-MM-DD")}
                                </Card.Subtitle>
                              ) : null
                            }


                            <Card.Text className="mt-1 text-capitalize text-info">
                              {purchase.type}
                            </Card.Text>

                            {
                              (purchase.type == "website" && purchase.canceled == 0 && (purchase.identifier == 'monthly_subscription' || purchase.identifier == 'yearly_subscription')) ?
                                (
                                  <Button
                                    className="cancel-button"
                                    block
                                    variant="outline-secondary"
                                    onClick={()=>this._unsubscribe(purchase)}
                                  >
                                    {translate('unsubscribe')}
                                  </Button>
                                ) : null
                            }

                            {
                              purchase.type == "website" && purchase.canceled == 1 && (purchase.identifier == 'monthly_subscription' || purchase.identifier == 'yearly_subscription') ?
                                (<Card.Text className="canceled">
                                  {translate('subscription_canceled')}
                                </Card.Text>) : null
                            }

                          </Card.Body>
                        </Card>
                      })

                    ) : (
                      webSubscriptionStatus ? (
                        <Alert variant="secondary">
                          <p>
                            {translate('you_have_web_subscription')}
                          </p>
                          <p className="mb-0">
                            {translate('web_subscription_expire_on')} {webSubscriptionExpireAt}
                          </p>
                        </Alert>
                      ) : <Alert variant="warning">
                        {translate('you_have_no_purchases')}
                      </Alert>
                    )

                  }
                </React.Fragment>
              ) :
                <Loader fetchingData={fetchingData}/>
            }
          </div>

          {
            userPurchases.length > 0 ?
              (<div style={{maxWidth: 400, margin: '15px auto', clear: 'both'}}>
                <Alert variant="secondary">
                  {translate('manage_app_purchases')}
                </Alert>
                <div className="d-flex justify-content-center align-items-center">
                  <a href="https://apps.apple.com/account/subscriptions" className="mr-4" target="_blank">
                    <Image style={{height: 40}} src={require('../../../assets/images/app-store-clear.png')}/>
                  </a>
                  <a href="https://play.google.com/store/account/subscriptions?package=com.mnemonicrn" target="_blank">
                    <Image style={{height: 40}} src={require('../../../assets/images/google-play-clear-.png')}/>
                  </a>
                </div>
              </div>) : null
          }

          <Loader fetchingData={fetchingData}/>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  purchases: state.purchases,
  settings: state.settings,
  translate: getTranslate(state.localize),
  activity: state.activity,

});

function bindAction(dispatch) {
  return {
    fetchingData: (status) => dispatch(fetchingData(status)),
    getPurchaseData: (orderId) => dispatch(getPurchaseData(orderId)),
    getLiqpaySubscriptionStatus: (orderId) => dispatch(getLiqpaySubscriptionStatus(orderId)),
    getUserPurchases: () => dispatch(getUserPurchases()),
    cancelSubscription: (orderId, purchaseId) => dispatch(cancelSubscription(orderId, purchaseId)),
  };
}

export default connect(mapStateToProps, bindAction)(Purchases);
