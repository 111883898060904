import * as types from '../actions/types';
import _ from 'lodash';

import letters from '../lib/letters';

const tablet = true;

const INITIAL_STATE = {
  words: [],
  totalWords: 0,
  totalPages: 0,
  shuffledIndices: [],
  showCards: false,
  fetchedWords: false,
  currentTrainingId: false,

  autosound_word_preview: "1",
  autosound_translation_preview: "0",

  autoslide_drag_image: "1",
  autoslide_delay_drag_image: "2",
  autosound_word_drag_image: "1",

  autoslide_memory_cards: "1",
  autoslide_delay_memory_cards: "2",
  autosound_word_memory_cards: "1",

  autoslide_definition: "1",
  autoslide_delay_definition: "2",
  autosound_word_definition: "1",

  autoslide_collect_sentence: "0",
  autoslide_delay_collect_sentence: "2",
  autoslide_type_collect_sentence: "sentence",
  autosound_sentence_collect_sentence: "1",
  
  autoslide_translate_sentence: "0",
  autoslide_delay_translate_sentence: "2",
  autoslide_type_translate_sentence: "sentence",
  autosound_sentence_translate_sentence: "1",

  autoslide_spelling: "1",
  autoslide_delay_spelling: "2",
  autosound_word_spelling: "1",
  image_or_word_spelling: "image",

  autoslide_select_translation: "1",
  autoslide_delay_select_translation: "2",
  autosound_word_select_translation: "1",
  image_or_word_select_translation: "word",

  autoslide_select_word: "1",
  autoslide_delay_select_word: "2",
  autosound_word_select_word: "1",
  autosound_translation_select_word: "1",
  image_or_word_select_word: "image",

  autoslide_collect_word: "1",
  autoslide_delay_collect_word: "2",
  autosound_word_collect_word: "1",
  image_or_word_collect_word: "image",

  autoslide_missing_letters: "1",
  autoslide_delay_missing_letters: "2",
  autosound_word_missing_letters: "1",
  image_or_word_missing_letters: "word",

  autoslide_delay_listening: "1",
  autosound_word_listening: "1",
  autosound_translation_listening: "1",
  autosound_example_translation_listening: "1",
  autosound_sentence_listening: "1",
  sentence_speed_listening: "1",
  loop_listening: "0",

  hideKnown: "1",
  hideKnownCourse: "0",
};

function shuffle(array) {
  let counter = array.length;

  while (counter > 0) {
    let index = Math.floor(Math.random() * counter);
    counter--;
    let temp = array[counter];
    array[counter] = array[index];
    array[index] = temp;
  }
  return array;
}

function shuffleSentence(array) {
  let counter = array.length;

  while (counter > 0) {
    let index = Math.floor(Math.random() * counter);

    counter--;

    let temp = array[counter];

    array[counter] = array[index];
    array[index] = temp;
  }
  return array;
}

function getAllSubStrings(str) {
  var i, j, result = [];

  for (i = 0; i < str.length; i++) {
    for (j = i + 1; j < str.length + 1; j++) {
      let substring = str.slice(i, j);

      // if (substring != str) {
        result.push({substring, i});
      // }
    }
  }

  result.sort(function (a, b) {
    return b.substring.length - a.substring.length;
  });

  return result;
}

function _findCharsToHide(word, lang) {
  if (!word) return false;

  let combos = getAllSubStrings(word)

  // console.log("combos", combos)
  let substitution = _findSubstitution(combos, lang);

  return substitution;
}

function _findSubstitution(combos, lang) {

  let substitutions = letters[lang];
  let found = false;

  // console.log('substitutions', substitutions)

  combos.forEach(combo => {
    substitutions.forEach(sub => {
      if (sub.sub.toLowerCase() == combo.substring.toLowerCase() && !found) {
        let n = Math.floor(Math.random() * (sub.options.length));

        found = {
          toReplace: combo,
          substituteOptions: sub,
          substitution: sub.options && sub.options[n] ? sub.options[n] : sub.options[0],
          correctButtonIndex: Math.floor(Math.random() * 2)
        }

        return found;
      }
    });
  });

  return found ? found : {
    toReplace: {
      substring: '?',
      i: 0
    },
    substituteOptions: [],
    substitution: '?',
    correctButtonIndex: Math.floor(Math.random() * 2)
  };
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_WORDS_FOR_TRAINING:
    {

      let words = action.payload.data.map((item) => {
        item.shuffledWord = shuffle([...item.word]);

        return item;
      });
      let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

      let newArray = _.union(state.words, words)

      return Object.assign({}, state, {
        // words: state.words.concat(action.payload.data),
        // words: [...state.words, ...words],
        words: newArray,
        totalWords: action.payload.total_words,
        totalPages: action.payload.total_pages,
        fetchedWords: true,
        shuffledIndices
      });
    }

    case types.SET_WORDS_FOR_MISSING_LETTER:
    {
      let lang = action.payload.language;

      let words = action.payload.data.data.map(item => {
        item.chars = _findCharsToHide(item.word, lang)

        return item;
      });

      let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

      let newArray = _.union(state.words, words)

      return Object.assign({}, state, {
        words: newArray,
        totalWords: action.payload.data.total_words,
        totalPages: action.payload.data.total_pages,
        fetchedWords: true,
        shuffledIndices
      });
    }

    case types.UPDATE_WORD_TRANSLATIONS_FOR_TRAINING:
    {
      let words = state.words.map((item) => {
        if (item.word_id === action.payload.wordId) {
          // item.details[0].translations = action.payload.translations
          item.main_translation = action.payload.mainTranslation;
          item.otherTranslationsString = action.payload.otherTranslationsString;
        }

        return item;
      });

      return Object.assign({}, state, {
        words
      });
    }
      
    case types.SHUFFLE_TRAINING_WORDS:
    {
      let words = shuffle(state.words);

      words && words.forEach(word => {
        if(word.examples && word.examples.length > 1) {
          var a = word.examples[0];
          word.examples[0] = word.examples[word.examples.length - 1];
          word.examples[word.examples.length - 1] = a;

          if(word.maxWidths && word.maxWidths.length != 0) {
            var b = word.maxWidths[0];
            word.maxWidths[0] = word.maxWidths[word.maxWidths.length - 1];
            word.maxWidths[word.maxWidths.length - 1] = b;
          }

          if(word.shuffledSentences && word.shuffledSentences.length != 0) {
            var c = word.shuffledSentences[0];
            word.shuffledSentences[0] = word.shuffledSentences[word.shuffledSentences.length - 1];
            word.shuffledSentences[word.shuffledSentences.length - 1] = c;
          }

          if(word.sentences && word.sentences.length != 0) {
            var d = word.sentences[0];
            word.sentences[0] = word.sentences[word.sentences.length - 1];
            word.sentences[word.sentences.length - 1] = d;
          }
        }
      })
      
      // console.log(words)

      return Object.assign({}, state, {
        words
      });
    }

    case types.ADD_WORD_ASSOCIATION_WORDS_FOR_TRAINING:
    {
      let words = state.words.map((item) => {
        if (item.word_id === action.payload.wordId) {
          item.association = action.payload.association
        }

        return item;
      });

      return Object.assign({}, state, {
        words
      });
    }
    
    case types.DELETE_WORD_ASSOCIATION_WORDS_FOR_TRAINING:
    {
      let words = state.words.map((item) => {
        if (item.word_id === action.payload.wordId) {
          item.association = false
        }

        return item;
      });

      return Object.assign({}, state, {
        words
      });
    }

    case types.ADD_WORD_IMAGE_FOR_TRAINING:
    {
      let words = state.words.map((item) => {
        if (item.word_id === action.payload.word_id) {
          item.image = action.payload
        }

        return item;
      });

      return Object.assign({}, state, {
        words
      });
    }

    case types.REMOVE_USER_IMAGE_FROM_TRAINING_WORDS:
    {
      let words = state.words.map((item) => {
        if (item.word_id === action.payload.word_id) {
          item.image = action.payload
        }

        return item;
      });

      return Object.assign({}, state, {
        words
      });
    }

    case types.SET_PREVIEW_WORDS_FROM_CACHE:
    {
      return Object.assign({}, state, {
        words: [...state.words, ...action.payload.words],
        totalWords: action.payload.totalWords,
        totalPages: action.payload.totalPages,
        fetchedWords: true,
        // shuffledIndices: action.payload.shuffledIndices
      });
    }

    case types.SET_COLLECT_WORDS_FROM_CACHE:
    {
      return Object.assign({}, state, {
        words: [...state.words, ...action.payload.words],
        totalWords: action.payload.totalWords,
        totalPages: action.payload.totalPages,
        fetchedWords: true,
        shuffledIndices: action.payload.shuffledIndices
      });
    }

    case types.SET_LISTENING_WORDS_FROM_CACHE:
    {
      return Object.assign({}, state, {
        words: [...state.words, ...action.payload.words],
        totalWords: action.payload.totalWords,
        totalPages: action.payload.totalPages,
        fetchedWords: true,
        shuffledIndices: action.payload.shuffledIndices
      });
    }

    case types.SET_CHECK_SPELLING_WORDS_FROM_CACHE:
    {
      return Object.assign({}, state, {
        words: [...state.words, ...action.payload.words],
        totalWords: action.payload.totalWords,
        totalPages: action.payload.totalPages,
        fetchedWords: true,
        // shuffledIndices: action.payload.shuffledIndices
      });
    }

    case types.SET_MISSING_LETTERS_FROM_CACHE:
    {
      return Object.assign({}, state, {
        words: [...state.words, ...action.payload.words],
        totalWords: action.payload.totalWords,
        totalPages: action.payload.totalPages,
        fetchedWords: true,
        shuffledIndices: action.payload.shuffledIndices
      });
    }

    case types.SET_HIDE_FROM_KNOWN:
    {
      return Object.assign({}, state, {
        hideKnown: action.payload
      });
    }

    case types.SET_HIDE_FROM_KNOWN_COURSE:
    {
      return Object.assign({}, state, {
        hideKnownCourse: action.payload
      });
    }

    case types.SET_CURRENT_TRAINING_ID:
    {
      return Object.assign({}, state, {
        currentTrainingId: action.payload
      });
    }

    case types.RESET_CURRENT_TRAINING_ID:
    {
      return Object.assign({}, state, {
        currentTrainingId: false
      });
    }

    case types.SET_COURSE_PREVIEW_WORDS_FROM_CACHE:
    {
      return Object.assign({}, state, {
        words: [...state.words, ...action.payload.words],
        totalWords: action.payload.totalWords,
        totalPages: action.payload.totalPages,
        fetchedWords: true,
        shuffledIndices: action.payload.shuffledIndices
      });
    }

    case types.SET_SELECT_TRANSLATION_WORDS_FROM_CACHE:
    {
      return Object.assign({}, state, {
        words: [...state.words, ...action.payload.words],
        totalWords: action.payload.totalWords,
        totalPages: action.payload.totalPages,
        fetchedWords: true,
        shuffledIndices: action.payload.shuffledIndices
      });
    }

    case types.SET_COLLECT_SENTENCE_WORDS_FROM_CACHE:
    {
      return Object.assign({}, state, {
        words: [...state.words, ...action.payload.words],
        totalWords: action.payload.totalWords,
        totalPages: action.payload.totalPages,
        fetchedWords: true,
        shuffledIndices: action.payload.shuffledIndices
      });
    }

    case types.SET_SELECT_WORD_WORDS_FROM_CACHE:
    {
      return Object.assign({}, state, {
        words: [...state.words, ...action.payload.words],
        totalWords: action.payload.totalWords,
        totalPages: action.payload.totalPages,
        fetchedWords: true,
        shuffledIndices: action.payload.shuffledIndices
      });
    }

    case types.SET_DEFINITION_WORDS_FROM_CACHE:
    {
      return Object.assign({}, state, {
        words: [...state.words, ...action.payload.words],
        totalWords: action.payload.totalWords,
        totalPages: action.payload.totalPages,
        fetchedWords: true,
        shuffledIndices: action.payload.shuffledIndices
      });
    }

    case types.SET_MEMORY_CARD_WORDS_FROM_CACHE:
    {
      return Object.assign({}, state, {
        words: action.payload.words,
        totalWords: action.payload.totalWords,
        totalPages: action.payload.totalPages,
        fetchedWords: true,
        shuffledIndices: action.payload.shuffledIndices
      });
    }

    case types.SET_DRAG_IMAGE_WORDS_FROM_CACHE:
    {
      return Object.assign({}, state, {
        words: [...state.words, ...action.payload.words],
        totalWords: action.payload.totalWords,
        totalPages: action.payload.totalPages,
        fetchedWords: true,
        shuffledIndices: action.payload.shuffledIndices
      });
    }

    case types.SET_PREVIEW_SETTINGS:
    {
      return Object.assign({}, state, {
        autosound_word_preview: action.payload.autosound_word == "1" || action.payload.autosound_word == "0" ? action.payload.autosound_word : state.autosound_word_preview,
        autosound_translation_preview: action.payload.autosound_translation == "1" || action.payload.autosound_translation == "0" ? action.payload.autosound_translation : state.autosound_translation_preview,
      });
    }

    case types.SET_DRAG_IMAGE_SETTINGS:
    {
      return Object.assign({}, state, {
        autoslide_drag_image: action.payload.autoslide == "1" || action.payload.autoslide == "0" ? action.payload.autoslide : state.autoslide_drag_image,
        autoslide_delay_drag_image: action.payload.autoslide_delay ? action.payload.autoslide_delay : state.autoslide_delay_drag_image,
        autosound_word_drag_image: action.payload.autosound_word == "1" || action.payload.autosound_word == "0" ? action.payload.autosound_word : state.autosound_word_drag_image,
      });
    }

    case types.SET_MEMORY_CARDS_SETTINGS:
    {
      return Object.assign({}, state, {
        autoslide_memory_cards: action.payload.autoslide == "1" || action.payload.autoslide == "0" ? action.payload.autoslide : state.autoslide_memory_cards,
        autoslide_delay_memory_cards: action.payload.autoslide_delay ? action.payload.autoslide_delay : state.autoslide_delay_memory_cards,
        autosound_word_memory_cards: action.payload.autosound_word == "1" || action.payload.autosound_word == "0" ? action.payload.autosound_word : state.autosound_word_memory_cards,
      });
    }

    case types.SET_DEFINITION_SETTINGS:
    {
      return Object.assign({}, state, {
        autoslide_definition: action.payload.autoslide == "1" || action.payload.autoslide == "0" ? action.payload.autoslide : state.autoslide_definition,
        autoslide_delay_definition: action.payload.autoslide_delay ? action.payload.autoslide_delay : state.autoslide_delay_definition,
        autosound_word_definition: action.payload.autosound_word == "1" || action.payload.autosound_word == "0" ? action.payload.autosound_word : state.autosound_word_definition,
      });
    }

    case types.SET_SPELLING_SETTINGS:
    {
      return Object.assign({}, state, {
        autoslide_spelling: action.payload.autoslide == "1" || action.payload.autoslide == "0" ? action.payload.autoslide : state.autoslide_spelling,
        autoslide_delay_spelling: action.payload.autoslide_delay ? action.payload.autoslide_delay : state.autoslide_delay_spelling,
        autosound_word_spelling: action.payload.autosound_word == "1" || action.payload.autosound_word == "0" ? action.payload.autosound_word : state.autosound_word_spelling,
        image_or_word_spelling: action.payload.image_or_word ? action.payload.image_or_word : state.image_or_word_spelling,
      });
    }

    case types.SET_MISSING_LETTERS_SETTINGS:
    {
      return Object.assign({}, state, {
        autoslide_missing_letters: action.payload.autoslide == "1" || action.payload.autoslide == "0" ? action.payload.autoslide : state.autoslide_missing_letters,
        autoslide_delay_missing_letters: action.payload.autoslide_delay ? action.payload.autoslide_delay : state.autoslide_delay_missing_letters,
        autosound_word_missing_letters: action.payload.autosound_word == "1" || action.payload.autosound_word == "0" ? action.payload.autosound_word : state.autosound_word_missing_letters,
        image_or_word_missing_letters: action.payload.image_or_word ? action.payload.image_or_word : state.image_or_word_missing_letters,
      });
    }

    case types.SET_LISTENING_SETTINGS:
    {
      // console.log("SET_LISTENING_SETTINGS",action.payload)
      return Object.assign({}, state, {
        autoslide_delay_listening: action.payload.autoslide_delay ? action.payload.autoslide_delay : state.autoslide_delay_listening,
        autosound_word_listening: action.payload.autosound_word == "1" || action.payload.autosound_word == "0" ? action.payload.autosound_word : state.autosound_word_listening,
        autosound_translation_listening: action.payload.autosound_translation == "1" || action.payload.autosound_translation == "0" ? action.payload.autosound_translation : state.autosound_translation_listening,
        autosound_example_translation_listening: action.payload.autosound_example_translation == "1" || action.payload.autosound_example_translation == "0" ? action.payload.autosound_example_translation : state.autosound_example_translation_listening,
        autosound_sentence_listening: action.payload.autosound_sentence == "1" || action.payload.autosound_sentence == "0" ? action.payload.autosound_sentence : state.autosound_sentence_listening,
        loop_listening: action.payload.loop == "1" || action.payload.loop == "0" ? action.payload.loop : state.loop_listening,
        sentence_speed_listening: action.payload.speed ? action.payload.speed : state.sentence_speed_listening,

      });
    }

    case types.SET_SELECT_TRANSLATION_SETTINGS:
    {
      return Object.assign({}, state, {
        autoslide_select_translation: action.payload.autoslide == "1" || action.payload.autoslide == "0" ? action.payload.autoslide : state.autoslide_select_translation,
        autoslide_delay_select_translation: action.payload.autoslide_delay ? action.payload.autoslide_delay : state.autoslide_delay_select_translation,
        autosound_word_select_translation: action.payload.autosound_word == "1" || action.payload.autosound_word == "0" ? action.payload.autosound_word : state.autosound_word_select_translation,
        image_or_word_select_translation: action.payload.image_or_word ? action.payload.image_or_word : state.image_or_word_select_translation,
      });
    }

    case types.SET_SELECT_WORD_SETTINGS:
    {
      return Object.assign({}, state, {
        autoslide_select_word: action.payload.autoslide == "1" || action.payload.autoslide == "0" ? action.payload.autoslide : state.autoslide_select_word,
        autoslide_delay_select_word: action.payload.autoslide_delay ? action.payload.autoslide_delay : state.autoslide_delay_select_word,
        autosound_word_select_word: action.payload.autosound_word == "1" || action.payload.autosound_word == "0" ? action.payload.autosound_word : state.autosound_word_select_word,
        autosound_translation_select_word: action.payload.autosound_translation == "1" || action.payload.autosound_translation == "0" ? action.payload.autosound_translation : state.autosound_translation_select_word,
        image_or_word_select_word: action.payload.image_or_word ? action.payload.image_or_word : state.image_or_word_select_word,
      });
    }

    case types.SET_COLLECT_SENTENCE_SETTINGS:
    {
      return Object.assign({}, state, {
        autoslide_collect_sentence: action.payload.autoslide == "1" || action.payload.autoslide == "0" ? action.payload.autoslide : state.autoslide_collect_sentence,
        autoslide_type_collect_sentence: action.payload.autoslide_type == "word" || action.payload.autoslide_type == "sentence" ? action.payload.autoslide_type : state.autoslide_type_collect_sentence,
        autosound_sentence_collect_sentence: action.payload.autosound_sentence == "1" || action.payload.autosound_sentence == "0" ? action.payload.autosound_sentence : state.autosound_sentence_collect_sentence,
        autoslide_delay_collect_sentence: action.payload.autoslide_delay ? action.payload.autoslide_delay : state.autoslide_delay_collect_sentence,
      });
    }
    
    case types.SET_TRANSLATE_SENTENCE_SETTINGS:
    {
      return Object.assign({}, state, {
        autoslide_translate_sentence: action.payload.autoslide == "1" || action.payload.autoslide == "0" ? action.payload.autoslide : state.autoslide_translate_sentence,
        autoslide_type_translate_sentence: action.payload.autoslide_type == "word" || action.payload.autoslide_type == "sentence" ? action.payload.autoslide_type : state.autoslide_type_translate_sentence,
        autosound_sentence_translate_sentence: action.payload.autosound_sentence == "1" || action.payload.autosound_sentence == "0" ? action.payload.autosound_sentence : state.autosound_sentence_translate_sentence,
        autoslide_delay_translate_sentence: action.payload.autoslide_delay ? action.payload.autoslide_delay : state.autoslide_delay_translate_sentence,
      });
    }

    case types.SET_COLLECT_WORD_SETTINGS:
    {
      return Object.assign({}, state, {
        autoslide_collect_word: action.payload.autoslide == "1" || action.payload.autoslide == "0" ? action.payload.autoslide : state.autoslide_collect_word,
        autoslide_delay_collect_word: action.payload.autoslide_delay ? action.payload.autoslide_delay : state.autoslide_delay_collect_word,
        autosound_word_collect_word: action.payload.autosound_word == "1" || action.payload.autosound_word == "0" ? action.payload.autosound_word : state.autosound_word_collect_word,
        image_or_word_collect_word: action.payload.image_or_word ? action.payload.image_or_word : state.image_or_word_collect_word,
      });
    }

    case types.SET_WORDS_FOR_TRAINING_COLLECT_SENTENCE:
    {
      let words = action.payload.data.map((item) => {
        item.shuffledWord = shuffle([...item.word]);

        let shuffledExamples = [];
        let examples = [];
        let maxWidths = [];

        item.examples && item.examples.map(sentence => {
          if (sentence.example.length > 0) {
            let exploded = sentence.example.split(" ")
            let explodedWithOrigIndices = [];

            let maxWidth = null;

            exploded.forEach((item, i) => {
              explodedWithOrigIndices.push({
                word: item,
                origIndex: i
              })
              if (maxWidth == null || item.length > maxWidth) {
                maxWidth = item.length;
              }
            });

            let width = !tablet ? 50 : 70;

            if (maxWidth != null) {
              width = maxWidth * (!tablet ? 12 : 14)
            }

            examples.push(exploded);
            maxWidths.push(width);

            // let shuffledSent = shuffleSentence([...exploded]);
            let shuffledSent = shuffleSentence([...explodedWithOrigIndices]);

            shuffledExamples.push(shuffledSent)
          }

        });
        item.sentences = examples;
        item.maxWidths = maxWidths;
        item.shuffledSentences = shuffledExamples;

        return item;
      });

      let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

      return Object.assign({}, state, {
        // words: state.words.concat(action.payload.data),
        words: [...state.words, ...words],
        totalWords: action.payload.total_words,
        totalPages: action.payload.total_pages,
        fetchedWords: true,
        shuffledIndices
      });
    }

    case types.SET_WORDS_FOR_TRAINING_LISTENING:
    {
      let words = action.payload.data.map((item) => {
        /*item.shuffledWord = shuffle([...item.word]);

        let shuffledExamples = [];
        let examples = [];
        let maxWidths = [];

        item.examples && item.examples.map(sentence => {
          if (sentence.example.length > 0) {
            let exploded = sentence.example.split(" ")
            let explodedWithOrigIndices = [];

            let maxWidth = null;

            exploded.forEach((item, i) => {
              explodedWithOrigIndices.push({
                word: item,
                origIndex: i
              })
              if (maxWidth == null || item.length > maxWidth) {
                maxWidth = item.length;
              }
            });

            let width = !tablet ? 50 : 70;

            if (maxWidth != null) {
              width = maxWidth * (!tablet ? 10 : 12)
            }

            examples.push(exploded);
            maxWidths.push(width);

            // let shuffledSent = shuffleSentence([...exploded]);
            let shuffledSent = shuffleSentence([...explodedWithOrigIndices]);

            shuffledExamples.push(shuffledSent)
          }

        });
        item.sentences = examples;
        item.maxWidths = maxWidths;
        item.shuffledSentences = shuffledExamples;*/

        return item;
      });

      let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

      return Object.assign({}, state, {
        // words: state.words.concat(action.payload.data),
        words: [...state.words, ...words],
        totalWords: action.payload.total_words,
        totalPages: action.payload.total_pages,
        fetchedWords: true,
        shuffledIndices
      });
    }

    case types.SET_WORDS_FOR_TRAINING_MEMORY_CARDS:
    {
      let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

      return Object.assign({}, state, {
        words: action.payload.data,
        totalWords: action.payload.total_words,
        totalPages: action.payload.total_pages,
        fetchedWords: true,
        shuffledIndices
      });
    }

    case types.RESET_TRAINING_DATA:
      return Object.assign({}, state, {
        words: [],
        totalWords: 0,
        totalPages: 1,
        fetchedWords: false,
      });

    case types.SET_NEW_GOOGLE_EXAMPLE_TRANSLATION:
    {
      let words = state.words;

      let w = words.map(item => {
        if (item.word_id == action.payload.wordId) {
          item.examples && item.examples.map(example => {
            if (example.example_id == action.payload.exampleId) {
              example.translation = {translation: action.payload.translation};
            }
          })
        }

        return item;
      });

      return Object.assign({}, state, {
        words: w,
      });
    }

    case types.RESET_TRAINING_WORDS:
      return Object.assign({}, state, {
        words: [],
        fetchedWords: false,
      });

    case types.TOGGLE_SHOW_MEMORY_CARDS:
      return Object.assign({}, state, {
        showCards: action.payload,
      });

    default:
      return state;
  }
}