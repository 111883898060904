import request from 'superagent';
import {select, put, call} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {getListsByLang} from './lists';
import {logApiError} from './logSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* syncUserLists(data) {
  const params = data.payload;

  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'syncUserLists')
    .send({
      user_id: userId,
      endpoint: params,
    });
    
    if(result.statusCode == 200) {
      if(params == "download") {
        yield getListsByLang({payload: {filter: "by_list_name", order: "asc"}});
      }
      // toastr.info('', translate(params == "upload" ? "lists_successfylly_uploaded" : 'lists_successfully_downloaded'));
    } else {
      // toastr.info('', translate(result.body.error ? result.body.error : 'error' ));
    }

  } catch (error) {
    yield logApiError({payload: {function: 'syncUserLists', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
    // yield getListsByLang({payload: {filter: "by_list_name", order: "asc"}});
  }
}
