import * as types from '../actions/types';
import _ from 'lodash';
import {createListWithIntervals} from '../lib/trainingFunctions';

import letters from '../lib/letters';

const tablet = false;

const INITIAL_STATE = {
  cachedListsStatus: false,
  cachedLists: [],
  cachedGlossaries: [],

  cachedGlossariesWords: [],
  cachedGlossariesWordsForList: [],

  cachedListsWords: 0,
  cachedVisitedLists: [],
  cachedHomeLists: [],
  cachedHomeRelax: false,
  cachedHomeListsStatus: false,

  previewCachedWords: [],
  collectWordCachedWords: [],
  checkSpellingCachedWords: [],
  selectTranslationCachedWords: [],
  collectSentenceCachedWords: [],
  selectWordCachedWords: [],
  dragImageCachedWords: [],
  definitionCachedWords: [],
  memoryCardsCachedWords: [],
  missingLetterCachedWords: [],
  listeningCachedWords: [],

};

function shuffle(array) {
  let counter = array.length;

  while (counter > 0) {
    let index = Math.floor(Math.random() * counter);
    counter--;
    let temp = array[counter];
    array[counter] = array[index];
    array[index] = temp;
  }
  return array;
}

function shuffleSentence(array) {
  let counter = array.length;

  while (counter > 0) {
    let index = Math.floor(Math.random() * counter);

    counter--;

    let temp = array[counter];

    array[counter] = array[index];
    array[index] = temp;
  }
  return array;
}

function getAllSubStrings(str) {
  var i, j, result = [];

  for (i = 0; i < str.length; i++) {
    for (j = i + 1; j < str.length + 1; j++) {
      let substring = str.slice(i, j);

      // if (substring != str) {
      result.push({substring, i});
      // }
    }
  }

  result.sort(function (a, b) {
    return b.substring.length - a.substring.length;
  });

  return result;
}

function _findCharsToHide(word, lang) {
  if (!word) return false;

  let combos = getAllSubStrings(word)
  // console.log("combos", combos)

  let substitution = _findSubstitution(combos, lang);

  return substitution;
}

function _findSubstitution(combos, lang) {

  let substitutions = letters[lang];
  let found = false;

  combos.forEach(combo => {
    substitutions.forEach(sub => {
      if (sub.sub.toLowerCase() == combo.substring.toLowerCase() && !found) {
        let n = Math.floor(Math.random() * (sub.options.length));

        found = {
          toReplace: combo,
          substituteOptions: sub,
          substitution: sub.options && sub.options[n] ? sub.options[n] : sub.options[0],
          correctButtonIndex: Math.floor(Math.random() * 2)
        }

        return found;
      }
    });
  });

  return found ? found : {
    toReplace: {
      substring: '?',
      i: 0
    },
    substituteOptions: [],
    substitution: '?',
    correctButtonIndex: Math.floor(Math.random() * 2)
  };
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_CACHED_LISTS_STATUS:
      return Object.assign({}, state, {
        cachedListsStatus: action.payload,
      });

    case types.CLEAN_LISTS_CACHE:
      return Object.assign({}, state, {
        cachedListsStatus: false,
        cachedLists: [],
        cachedListsWords: 0,
        cachedVisitedLists: [],
        cachedHomeLists: [],
        cachedHomeRelax: false,
        cachedHomeListsStatus: false,
      });

    case types.CLEAN_LIST_CACHE:
    {
      let lists = state.cachedVisitedLists.filter((item, i) => {
        return item.listId !== action.payload;
      });

      let previewCachedWords = state.previewCachedWords.filter((list) => {
        return list.listId != action.payload;
      })
      
      let collectWordCachedWords = state.collectWordCachedWords.filter((list) => {
        return list.listId != action.payload;
      })
      
      let checkSpellingCachedWords = state.checkSpellingCachedWords.filter((list) => {
        return list.listId != action.payload;
      })

      let missingLetterCachedWords = state.missingLetterCachedWords.filter((list) => {
        return list.listId != action.payload;
      })

      let selectTranslationCachedWords = state.selectTranslationCachedWords.filter((list) => {
        return list.listId != action.payload;
      })

      let collectSentenceCachedWords = state.collectSentenceCachedWords.filter((list) => {
        return list.listId != action.payload;
      })

      let selectWordCachedWords = state.selectWordCachedWords.filter((list) => {
        return list.listId != action.payload;
      })

      let dragImageCachedWords = state.dragImageCachedWords.filter((list) => {
        return list.listId != action.payload;
      })

      let definitionCachedWords = state.definitionCachedWords.filter((list) => {
        return list.listId != action.payload;
      })

      let memoryCardsCachedWords = state.memoryCardsCachedWords.filter((list) => {
        return list.listId != action.payload;
      })

      let listeningCachedWords = state.listeningCachedWords.filter((list) => {
        return list.listId != action.payload;
      })

      return Object.assign({}, state, {
        cachedVisitedLists: lists,
        missingLetterCachedWords,
        previewCachedWords,
        collectWordCachedWords,
        checkSpellingCachedWords,
        selectTranslationCachedWords,
        collectSentenceCachedWords,
        selectWordCachedWords,
        dragImageCachedWords,
        definitionCachedWords,
        memoryCardsCachedWords,
        listeningCachedWords,
      });

    }

    case types.CLEAN_APP_CACHE:{
      return Object.assign({}, state, {
        cachedListsStatus: false,
        cachedLists: [],
        cachedGlossaries: [],
        cachedGlossariesWords: [],
        cachedListsWords: 0,
        cachedVisitedLists: [],
        cachedHomeLists: [],
        cachedHomeRelax: false,
        cachedHomeListsStatus: false,

        previewCachedWords: [],
        collectWordCachedWords: [],
        checkSpellingCachedWords: [],
        selectTranslationCachedWords: [],
        collectSentenceCachedWords: [],
        selectWordCachedWords: [],
        dragImageCachedWords: [],
        definitionCachedWords: [],
        memoryCardsCachedWords: [],
        missingLetterCachedWords: [],
        listeningCachedWords: [],
      });
    }

    case types.CLEAN_TRAININGS_CACHE:{
      return Object.assign({}, state, {
        previewCachedWords: [],
        collectWordCachedWords: [],
        checkSpellingCachedWords: [],
        selectTranslationCachedWords: [],
        collectSentenceCachedWords: [],
        selectWordCachedWords: [],
        dragImageCachedWords: [],
        definitionCachedWords: [],
        memoryCardsCachedWords: [],
        missingLetterCachedWords: [],
        listeningCachedWords: [],
      });}

    case types.CLEAN_GLOSSARIES_CACHE:
      return Object.assign({}, state, {
        cachedGlossaries: [],
        cachedGlossariesWords: [],
      });

    case types.SET_CACHED_GLOSSARIES:
    {
      let found = false;
      let glossaries = state.cachedGlossaries;

      state.cachedGlossaries.forEach(level => {
        if (level.level == action.payload.level) {
          found = true;
        }
      })

      if (!found) {
        let parentSections = [];

        action.payload.data.data.map((item, i) => {
          parentSections.push({
            title: item.glossary_name,
            children: item.children_number,
            words: item.total_words,
            data: item.children, index: i
          })
        });

        glossaries.push({
          level: action.payload.level,
          data: {
            parentSections,
            parents: action.payload.data.data,
            totalGlossaries: action.payload.data.total_glossaries,
            totalGlossariesWords: action.payload.data.total_words,
          }
        })
      }

      return Object.assign({}, state, {
        cachedGlossaries: glossaries
      });
    }

    case types.SET_CACHED_GLOSSARIES_WORDS:
    {
      let found = false;
      let foundGlossaryIndex = null;
      let foundPageIndex = null;

      let glossariesWords = state.cachedGlossariesWords;

      state.cachedGlossariesWords.forEach((glossary, i) => {
        if (glossary.glossaryId == action.payload.glossaryId) {
          foundGlossaryIndex = i;

          glossary.pages.forEach((page, pi) => {
            if (page.page == action.payload.page) {
              foundPageIndex = pi;
            }
          })
        }
      })

      if (foundGlossaryIndex === null) {
        glossariesWords.push({
          glossaryId: action.payload.glossaryId,
          totalGlossaryWords: action.payload.totalGlossaryWords,
          totalPages: action.payload.totalPages,
          pages: [
            {
              page: action.payload.page,
              words: action.payload.words
            }
          ]
        })
      } else {
        if (foundPageIndex === null) {
          glossariesWords[foundGlossaryIndex].pages.push(
            {
              page: action.payload.page,
              words: action.payload.words
            }
          )
        }
      }

      return Object.assign({}, state, {
        cachedGlossariesWords: glossariesWords
      });
    }

    case types.UPDATED_CACHED_GLOSSARY_WORDS:
    {

      let glossariesWords = state.cachedGlossariesWords.map((glossary, i) => {
        if (glossary.glossaryId == action.payload.glossaryId) {

          glossary.pages.map((page, pi) => {
            page.words.map(word => {
              action.payload.words.map(wordId=> {
                if (wordId === word.id) {
                  word.in_user_lists = true;
                }
              });
            })
          })
        }

        return glossary;
      })

      return Object.assign({}, state, {
        cachedGlossariesWords: glossariesWords
      });
    }

    case types.SET_CACHED_GLOSSARIES_WORDS_FOR_LIST:
    {
      let found = false;
      let foundGlossaryIndex = null;
      let foundPageIndex = null;

      let glossariesWords = state.cachedGlossariesWordsForList;

      state.cachedGlossariesWordsForList.forEach((glossary, i) => {
        if (glossary.glossaryId == action.payload.glossaryId) {
          foundGlossaryIndex = i;

          glossary.pages.forEach((page, pi) => {
            if (page.page == action.payload.page) {
              foundPageIndex = pi;
            }
          })
        }
      })

      if (foundGlossaryIndex === null) {
        glossariesWords.push({
          glossaryId: action.payload.glossaryId,
          totalGlossaryWords: action.payload.totalGlossaryWords,
          totalPages: action.payload.totalPages,
          pages: [
            {
              page: action.payload.page,
              words: action.payload.words
            }
          ]
        })
      } else {
        if (foundPageIndex === null) {
          glossariesWords[foundGlossaryIndex].pages.push(
            {
              page: action.payload.page,
              words: action.payload.words
            }
          )
        }
      }

      return Object.assign({}, state, {
        cachedGlossariesWordsForList: glossariesWords
      });
    }

    case types.UPDATE_NUMBER_OF_WORDS_IN_LISTS_GLOSSARY:
    {

      let glossaries = state.cachedGlossaries.map(level => {
        if (level.level == action.payload.level) {

          level.data.parents.map(glossary => {
            glossary.children.map(child => {
              if (child.id == action.payload.glossaryId) {
                let newNumber = child.in_user_lists + action.payload.words;
                child.in_user_lists = newNumber > child.words ? child.words : newNumber
              }
            })
          })
        }

        return level;
      })

      return Object.assign({}, state, {
        cachedGlossaries: glossaries
      });
    }

    case types.UPDATE_CACHED_GLOSSARIES:
    {
      let found = false;
      let glossaries = state.cachedGlossaries;

      state.cachedGlossaries.forEach(level => {
        if (level == action.payload.level) {
          found = true;
        }
      })

      if (!found) {
        let parentSections = [];

        action.payload.data.data.map((item, i) => {
          parentSections.push({
            title: item.glossary_name,
            children: item.children_number,
            words: item.total_words,
            data: item.children, index: i
          })
        });

        glossaries.push({
          level: action.payload.level,
          data: {
            parentSections,
            parents: action.payload.data.data,
            totalGlossaries: action.payload.data.total_glossaries,
            totalGlossariesWords: action.payload.data.total_words,
          }
        })
      }

      return Object.assign({}, state, {
        cachedGlossaries: glossaries
      });
    }

    case types.SET_CACHED_HOME_LISTS_STATUS:
      return Object.assign({}, state, {
        cachedHomeListsStatus: action.payload,
      });

    case types.SET_CACHED_HOME_LISTS:
    {
      let sections = [];
      if (action.payload.mistakes.length > 0 && action.payload.mistakes[0].words > 0) {
        sections.push({title: 'with_mistakes', data: action.payload.mistakes})
      }
      if (action.payload.repeat.length > 0) {
        sections.push({title: 'time_to_repeat', data: action.payload.repeat})
      }
      if (action.payload.new.length > 0) {
        sections.push({title: 'new_lists', data: action.payload.new})
      }

      let relaxPlan = sections.length === 0;
      
      // console.log("SET_CACHED_HOME_LISTS", relaxPlan, sections)

      if (relaxPlan) {
        /*sections.push({
          title: 'relax', data: [
            {list_name: translate('look_in_glossaries'), segue: 'Glossaries'},
            {list_name: translate('try_word_maker'), segue: 'WordMakerWords'},
            {list_name: translate('try_test'), segue: 'WordTest'},
            {list_name: translate('try_extractor'), segue: 'WordExtractor'},
          ]
        });*/
      }

      return Object.assign({}, state, {
        cachedHomeLists: sections,
        cachedHomeRelax: relaxPlan,
        cachedHomeListsStatus: true,
      });
    }

    case types.SET_VISITED_CACHED_LISTS:
    {
      let lists = state.cachedVisitedLists;

      let found = false;
      let foundIndex = null;

      lists.forEach((item, i) => {
        if (item.listId === action.payload.listId) {
          found = true;
          foundIndex = i;
        }
      })

      if (!found) {
        lists.push(action.payload)
      }

      if (foundIndex !== null) {
        if (lists[foundIndex] !== action.payload) {
          lists[foundIndex] = action.payload
        }
      }

      return Object.assign({}, state, {
        cachedVisitedLists: lists,
      });
    }

    case types.UPDATE_WORD_IN_VOCABULARY:
    {
      let lists = state.cachedVisitedLists.map((item, i) => {
        if (item && item.listWords) {
          item.listWords.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.in_vocabulary = !word.in_vocabulary
            }
          })
        }

        return item;
      })

      return Object.assign({}, state, {
        cachedVisitedLists: lists,
      });
    }

    case types.UPDATE_WORD_FAVORITE_STATUS:
    {
      let lists = state.cachedVisitedLists.map((item, i) => {
        if (item && item.listWords) {
          item.listWords.map(word => {
            if (word.word_id == action.payload) {
              word.is_favourite = !word.is_favourite
            }
          })
        }

        return item;
      })

      return Object.assign({}, state, {
        cachedVisitedLists: lists,
      });
    }

    case types.ADD_USER_IMAGE_TO_CACHED_LIST_WORDS:
    {
      let lists = state.cachedVisitedLists.map((item, i) => {
        if (item && item.listWords) {
          item.listWords.map(word => {
            if (word.word_id == action.payload.word_id) {
              word.image = action.payload;
            }
          })
        }

        return item;
      })

      return Object.assign({}, state, {
        cachedVisitedLists: lists,
      });
    }

    case types.REMOVE_USER_IMAGE_TO_CACHED_LIST_WORDS:
    {
      let lists = state.cachedVisitedLists.map((item, i) => {
        if (item && item.listWords) {
          item.listWords.map(word => {
            if (word.word_id == action.payload.word_id) {
              word.image = false
            }
          })
        }

        return item;
      })

      return Object.assign({}, state, {
        cachedVisitedLists: lists,
      });
    }

    case types.CACHED_LIST_WORD_DELETED_FROM_LISTS:
    {
      let newLists = state.cachedVisitedLists.map((item, i) => {
        if (item.listId == action.payload.listId) {

          let words = item.listWords.filter(word => {
            return word.word_id !== action.payload.wordId
          })

          item.listWords = words;
        }
        return item;
      });

      return Object.assign({}, state, {
        cachedVisitedLists: newLists,
      });
    }

    case types.RESET_VISITED_CACHED_LISTS:
    {
      return Object.assign({}, state, {
        cachedVisitedLists: [],
      });
    }

    case types.SET_CACHED_LISTS:
      return Object.assign({}, state, {
        cachedListsStatus: true,
        cachedLists: action.payload.data,
        cachedListsWords: action.payload.total_words,
      });

    case types.ADD_TO_CACHED_LISTS:
    {
      let words = state.cachedListsWords;
      let newLists = _.union(state.cachedLists, action.payload.lists);

      return Object.assign({}, state, {
        cachedListsStatus: true,
        cachedLists: newLists,
        cachedListsWords: words + action.payload.words,
      });
    }

    case types.RESET_CACHED_LISTS:
      return Object.assign({}, state, {
        cachedListsStatus: false,
        cachedLists: [],
        cachedListsWords: 0,
      });

    case types.RESET_GLOSSARY_CACHE:
    {
      let glossariesWords = state.cachedGlossariesWords.filter((glossary, i) => {
        return glossary.glossaryId !== action.payload;
      })

      return Object.assign({}, state, {
        cachedGlossariesWords: glossariesWords
      });
    }

    case types.CACHED_LIST_WORD_DELETED:
      return Object.assign({}, state, {
        cachedListsWords: state.cachedListsWords - 1,
      });

    case types.UPDATE_CACHED_LIST_NAME:
    {
      let newLists = state.cachedLists.map((item, i) => {
        if (item.id == action.payload.listId) {
          item.list_name = action.payload.listName
        }
        return item;
      });

      return Object.assign({}, state, {
        cachedLists: newLists,
      });
    }
    
    case types.UPDATE_CACHED_FAVORITE_LIST:
    {
      let newLists = state.cachedLists.map((item, i) => {
        if (item.id == action.payload) {
          item.favourite = item.favourite == 0 ? 1 : 0;
        }
        return item;
      });

      return Object.assign({}, state, {
        cachedLists: newLists,
      });
    }

    case types.ADD_WORD_NUMBERS_TO_CACHED_LIST:
    {
      let newLists = state.cachedLists.map((item, i) => {
        if (item.id == action.payload.listsId) {
          item.words = item.words + action.payload.words
        }
        return item;
      });

      return Object.assign({}, state, {
        cachedLists: newLists,
        cachedListsWords: state.cachedListsWords + action.payload.words,
      });
    }

    case types.DELETE_FROM_CACHED_USER_LISTS:
    {
      let _totalWords = 0;

      let newLists = state.cachedLists.filter((item, i) => {

        if (item.id === action.payload) {
          _totalWords += item.words;
        }

        return item.id !== action.payload;
      });

      let lists = state.cachedVisitedLists.filter((item, i) => {
        return item.listId !== action.payload;
      });

      return Object.assign({}, state, {
        cachedLists: newLists,
        cachedListsWords: state.cachedListsWords - _totalWords,
        cachedVisitedLists: lists,
      });
    }

    case types.DELETE_LISTS_FROM_USER_LISTS:
    {
      let _totalWords = 0;

      let newLists = state.cachedLists.filter((item, i) => {
        let toDelete = false;

        action.payload.forEach(listId => {

          if (item.id === listId) {
            _totalWords += item.words;
            toDelete = true;
          }
        });

        return !toDelete;
      })

      let lists = state.cachedVisitedLists.filter((item, i) => {
        let toDelete = false;

        action.payload.forEach(listId => {

          if (item.listId === listId) {
            toDelete = true;
          }
        });

        return !toDelete;
      });

      return Object.assign({}, state, {
        cachedLists: newLists,
        cachedListsWords: state.cachedListsWords - _totalWords,
        cachedVisitedLists: lists,
      });
    }

    case types.REMOVE_REPETITIONS_FROM_CACHED_LIST:
    {
      let newLists = state.cachedLists.map((item, i) => {

        if (item.id == action.payload) {
          item.intervals = []
        }

        return item;
      });

      return Object.assign({}, state, {
        cachedLists: newLists,
      });
    }

    case types.UPDATE_CACHED_LISTS:
    {
      return Object.assign({}, state, {
        cachedLists: action.payload,
      });
    }

    case types.UPDATE_CACHED_LIST_INTERVALS:
    {
      let newLists = state.cachedLists.map((item, i) => {

        if (item.id == action.payload.listId) {
          item.intervals = action.payload.intervals
        }

        return item;
      });

      return Object.assign({}, state, {
        cachedLists: newLists,
      });
    }

    case types.INSERT_NEW_CACHED_LIST:
    {
      let newLists = state.cachedLists;

      newLists.unshift(action.payload)

      return Object.assign({}, state, {
        cachedLists: newLists,
      });
    }

    // TRAININGS

    case types.UPDATE_CACHED_TRAINING_WORD_TRANSLATIONS:
    {
      let previewCachedWords = state.previewCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })
      
      let collectWordCachedWords = state.collectWordCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })
      
      let checkSpellingCachedWords = state.checkSpellingCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      let missingLetterCachedWords = state.missingLetterCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      let selectTranslationCachedWords = state.selectTranslationCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      let collectSentenceCachedWords = state.collectSentenceCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      let selectWordCachedWords = state.selectWordCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      let dragImageCachedWords = state.dragImageCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      let definitionCachedWords = state.definitionCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      let memoryCardsCachedWords = state.memoryCardsCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      let listeningCachedWords = state.listeningCachedWords.map((list) => {
        if (list.listId == action.payload.listId) {
          list.data && list.data.words && list.data.words.map(word => {
            if (word.word_id == action.payload.wordId) {
              word.main_translation = action.payload.mainTranslation;
              word.otherTranslationsString = action.payload.otherTranslationsString;
            }
          })
        }

        return list;
      })

      return Object.assign({}, state, {
        previewCachedWords,
        checkSpellingCachedWords,
        collectWordCachedWords,
        selectTranslationCachedWords,
        collectSentenceCachedWords,
        selectWordCachedWords,
        dragImageCachedWords,
        definitionCachedWords,
        memoryCardsCachedWords,
        missingLetterCachedWords,
        listeningCachedWords,
      });
    }

    case types.SHUFFLE_LIST_TRAINING_WORDS:
    {
      let previewCachedWords = state.previewCachedWords;
      let collectWordCachedWords = state.collectWordCachedWords;
      let checkSpellingCachedWords = state.checkSpellingCachedWords;
      let missingLetterCachedWords = state.missingLetterCachedWords;
      let selectTranslationCachedWords = state.selectTranslationCachedWords;
      let collectSentenceCachedWords = state.collectSentenceCachedWords;
      let selectWordCachedWords = state.selectWordCachedWords;
      let dragImageCachedWords = state.dragImageCachedWords;
      let definitionCachedWords = state.definitionCachedWords;
      let memoryCardsCachedWords = state.memoryCardsCachedWords;
      let listeningCachedWords = state.listeningCachedWords;

      if(action.payload.trainingId == 1) {
        previewCachedWords = state.previewCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.data.words = shuffle(list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 3) {
        collectWordCachedWords = state.collectWordCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.data.words = shuffle(list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 5) {
        checkSpellingCachedWords = state.checkSpellingCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.data.words = shuffle(list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 12) {
        missingLetterCachedWords = state.missingLetterCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.data.words = shuffle(list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 2) {
        selectTranslationCachedWords = state.selectTranslationCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.data.words = shuffle(list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 8) {
        collectSentenceCachedWords = state.collectSentenceCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
              list.data.words = shuffle(list.data.words)

            list.data.words && list.data.words.forEach(word => {
              if(word.examples && word.examples.length > 1) {
                var a = word.examples[0];
                word.examples[0] = word.examples[word.examples.length - 1];
                word.examples[word.examples.length - 1] = a;

                if(word.maxWidths && word.maxWidths.length != 0) {
                  var b = word.maxWidths[0];
                  word.maxWidths[0] = word.maxWidths[word.maxWidths.length - 1];
                  word.maxWidths[word.maxWidths.length - 1] = b;
                }

                if(word.shuffledSentences && word.shuffledSentences.length != 0) {
                  var c = word.shuffledSentences[0];
                  word.shuffledSentences[0] = word.shuffledSentences[word.shuffledSentences.length - 1];
                  word.shuffledSentences[word.shuffledSentences.length - 1] = c;
                }

                if(word.sentences && word.sentences.length != 0) {
                  var d = word.sentences[0];
                  word.sentences[0] = word.sentences[word.sentences.length - 1];
                  word.sentences[word.sentences.length - 1] = d;
                }
              }
            })
            // console.log("Shuffle list",list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 14) {
        listeningCachedWords = state.listeningCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
              list.data.words = shuffle(list.data.words)

            list.data.words && list.data.words.forEach(word => {
              if(word.examples && word.examples.length > 1) {
                word.examples = shuffle(word.examples);
              }
            })
          }
          return list;
        })
      } else if(action.payload.trainingId == 4) {
        selectWordCachedWords = state.selectWordCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.data.words = shuffle(list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 6) {
        dragImageCachedWords = state.dragImageCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.data.words = shuffle(list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 10) {
        definitionCachedWords = state.definitionCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.data.words = shuffle(list.data.words)
          }
          return list;
        })
      } else if(action.payload.trainingId == 9) {
        memoryCardsCachedWords = state.memoryCardsCachedWords.map((list) => {
          if (list.listId == action.payload.listId) {
            list.pages = list.pages.map(page => {
              page.words = shuffle(page.words)
              return page;
            })
          }
          return list;
        })
      }
      
      return Object.assign({}, state, {
        previewCachedWords,
        collectWordCachedWords,
        checkSpellingCachedWords,
        selectTranslationCachedWords,
        collectSentenceCachedWords,
        selectWordCachedWords,
        dragImageCachedWords,
        definitionCachedWords,
        memoryCardsCachedWords,
        missingLetterCachedWords,
        listeningCachedWords,
      });
    }

    case types.ADD_ASSOCIATION_TO_CACHED_WORD:
    {
      let previewCachedWords = state.previewCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload.wordId) {
            word.association = action.payload.association
          }
        })
        
        return list;
      })
      
      return Object.assign({}, state, {
        previewCachedWords,
      });
    }
    
    case types.DELETE_ASSOCIATION_FROM_CACHED_WORD:
    {
      let previewCachedWords = state.previewCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload.wordId) {
            word.association = false
          }
        })
        
        return list;
      })
      
      return Object.assign({}, state, {
        previewCachedWords,
      });
    }

    case types.ADD_CACHED_TRAINING_WORD_IMAGE:
    {
      let previewCachedWords = state.previewCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload.word_id) {
            word.image = action.payload;
          }
        })

        return list;
      })
      
      let collectWordCachedWords = state.collectWordCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload.word_id) {
            word.image = action.payload;
          }
        })

        return list;
      })
      
      let checkSpellingCachedWords = state.checkSpellingCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload.word_id) {
            word.image = action.payload;
          }
        })

        return list;
      })
      
      let missingLetterCachedWords = state.missingLetterCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload.word_id) {
            word.image = action.payload;
          }
        })

        return list;
      })

      let selectTranslationCachedWords = state.selectTranslationCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload.word_id) {
            word.image = action.payload;
          }
        })

        return list;
      })

      let selectWordCachedWords = state.selectWordCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload.word_id) {
            word.image = action.payload;
          }
        })

        return list;
      })

      let dragImageCachedWords = state.dragImageCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload.word_id) {
            word.image = action.payload;
          }
        })

        return list;
      })

      let memoryCardsCachedWords = state.memoryCardsCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload.word_id) {
            word.image = action.payload;
          }
        })

        return list;
      })

      return Object.assign({}, state, {
        previewCachedWords,
        collectWordCachedWords,
        checkSpellingCachedWords,
        missingLetterCachedWords,
        selectTranslationCachedWords,
        selectWordCachedWords,
        dragImageCachedWords,
        memoryCardsCachedWords,
      });
    }

    case types.REMOVE_USER_IMAGE_FROM_CACHED_TRAINING_WORDS:
    {

      let previewCachedWords = state.previewCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload) {
            word.image = false
          }
        })

        return list;
      })
      
      let collectWordCachedWords = state.collectWordCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload) {
            word.image = false
          }
        })

        return list;
      })
      
      let checkSpellingCachedWords = state.checkSpellingCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload) {
            word.image = false
          }
        })

        return list;
      })

      let missingLetterCachedWords = state.missingLetterCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload) {
            word.image = false
          }
        })

        return list;
      })

      let selectTranslationCachedWords = state.selectTranslationCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload) {
            word.image = false
          }
        })

        return list;
      })

      let selectWordCachedWords = state.selectWordCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload) {
            word.image = false
          }
        })

        return list;
      })

      let dragImageCachedWords = state.dragImageCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload) {
            word.image = false
          }
        })

        return list;
      })

      let memoryCardsCachedWords = state.memoryCardsCachedWords.map((list) => {
        list.data && list.data.words && list.data.words.map(word => {
          if (word.word_id == action.payload) {
            word.image = false
          }
        })

        return list;
      })

      return Object.assign({}, state, {
        previewCachedWords,
        collectWordCachedWords,
        checkSpellingCachedWords,
        missingLetterCachedWords,
        selectTranslationCachedWords,
        selectWordCachedWords,
        dragImageCachedWords,
        memoryCardsCachedWords,
      });
    }

    case types.SET_CACHED_PREVIEW_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.previewCachedWords;

      state.previewCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          // item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        // let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            // shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          // item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        // let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        // cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);
      }

      // console.log("PREVIEW cachedWords", cachedWords)

      return Object.assign({}, state, {
        previewCachedWords: cachedWords,
      });
    }
    
    case types.SET_CACHED_COLLECT_WORD_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.collectWordCachedWords;

      state.collectWordCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);
      }

      return Object.assign({}, state, {
        collectWordCachedWords: cachedWords,
      });
    }

    case types.SET_CACHED_CHECK_SPELLING_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.checkSpellingCachedWords;

      state.checkSpellingCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          // item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        // let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            // shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          // item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        // let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        // cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);
      }

      return Object.assign({}, state, {
        checkSpellingCachedWords: cachedWords,
      });
    }

    case types.SET_CACHED_MISSING_LETTER_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.missingLetterCachedWords;
      let lang = action.payload.language;

      state.missingLetterCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.chars = _findCharsToHide(item.word, lang)
          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.chars = _findCharsToHide(item.word, lang)
          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);
      }

      return Object.assign({}, state, {
        missingLetterCachedWords: cachedWords,
      });
    }

    case types.SET_CACHED_SELECT_TRANSLATION_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.selectTranslationCachedWords;

      state.selectTranslationCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);

      }

      // console.log("SELECT TRANSLATION cachedWords", cachedWords)

      return Object.assign({}, state, {
        selectTranslationCachedWords: cachedWords,
      });
    }

    case types.SET_CACHED_SELECT_WORD_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.selectWordCachedWords;

      state.selectWordCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);

      }

      // console.log("SELECT WORD cachedWords", cachedWords)

      return Object.assign({}, state, {
        selectWordCachedWords: cachedWords,
      });
    }

    case types.SET_CACHED_DRAG_IMAGE_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.dragImageCachedWords;

      state.dragImageCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);

      }

      // console.log("DRAG IMAGE cachedWords", cachedWords)

      return Object.assign({}, state, {
        dragImageCachedWords: cachedWords,
      });
    }

    case types.SET_CACHED_DEFINITION_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.definitionCachedWords;

      state.definitionCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);

      }

      // console.log("DEFINITION cachedWords", cachedWords)

      return Object.assign({}, state, {
        definitionCachedWords: cachedWords,
      });
    }

    case types.SET_CACHED_MEMORY_CARD_LIST_WORDS:
    {

      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.memoryCardsCachedWords;

      state.memoryCardsCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [
            {
              page: action.payload.page,
              shuffledIndices,
              words: action.payload.data.data,
            }
          ],
          data: {
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(
          {
            page: action.payload.page,
            shuffledIndices,
            words: action.payload.data.data
          }
        );
      }

      // console.log("MEMORY CARDS cachedWords", cachedWords)

      return Object.assign({}, state, {
        memoryCardsCachedWords: cachedWords,
      });
    }

    case types.SET_CACHED_COLLECT_SENTENCE_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.collectSentenceCachedWords;

      state.collectSentenceCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          let shuffledExamples = [];
          let examples = [];
          let maxWidths = [];

          item.examples && item.examples.map(sentence => {
            if (sentence.example.length > 0) {
              let exploded = sentence.example.split(" ")
              let explodedWithOrigIndices = [];

              let maxWidth = null;

              exploded.forEach((item, i) => {
                explodedWithOrigIndices.push({
                  word: item,
                  origIndex: i
                })

                if (maxWidth == null || item.length > maxWidth) {
                  maxWidth = item.length;
                }
              });

              let width = !tablet ? 50 : 70;

              if (maxWidth != null) {
                width = maxWidth * (!tablet ? 10 : 12)
              }
              examples.push(exploded);
              maxWidths.push(width);

              // let shuffledSent = shuffle([...exploded]);
              let shuffledSent = shuffleSentence([...explodedWithOrigIndices]);

              shuffledExamples.push(shuffledSent)
            }

          });
          item.sentences = examples;
          item.maxWidths = maxWidths;
          item.shuffledSentences = shuffledExamples;

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          item.shuffledWord = shuffle([...item.word]);

          let shuffledExamples = [];
          let examples = [];
          let maxWidths = [];

          item.examples && item.examples.map(sentence => {
            if (sentence.example.length > 0) {
              let exploded = sentence.example.split(" ")

              let maxWidth = null;

              exploded.forEach((item, i) => {
                if (maxWidth == null || item.length > maxWidth) {
                  maxWidth = item.length;
                }
              });

              let width = !tablet ? 50 : 70;

              if (maxWidth != null) {
                width = maxWidth * (!tablet ? 10 : 12)
              }
              examples.push(exploded);
              maxWidths.push(width);

              let shuffledSent = shuffle([...exploded]);

              shuffledExamples.push(shuffledSent)
            }

          });
          item.sentences = examples;
          item.maxWidths = maxWidths;
          item.shuffledSentences = shuffledExamples;

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);
      }

      // console.log("COLLECT SENTENCE cachedWords", cachedWords)

      return Object.assign({}, state, {
        collectSentenceCachedWords: cachedWords,
      });
    }
    
    case types.SET_CACHED_LISTENING_LIST_WORDS:
    {
      let foundListIndex = null;
      let foundListPageIndex = null;
      let cachedWords = state.listeningCachedWords;

      state.listeningCachedWords.forEach((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          foundListIndex = listIndex;

          let pageIndex = list.pages.indexOf(action.payload.page);

          if (pageIndex !== -1) {
            foundListPageIndex = pageIndex;
          }
        }
      })

      if (foundListIndex === null) {
        let words = action.payload.data.data.map((item) => {
          /*item.shuffledWord = shuffle([...item.word]);

          let shuffledExamples = [];
          let examples = [];
          let maxWidths = [];

          item.examples && item.examples.map(sentence => {
            if (sentence.example.length > 0) {
              let exploded = sentence.example.split(" ")
              let explodedWithOrigIndices = [];

              let maxWidth = null;

              exploded.forEach((item, i) => {
                explodedWithOrigIndices.push({
                  word: item,
                  origIndex: i
                })

                if (maxWidth == null || item.length > maxWidth) {
                  maxWidth = item.length;
                }
              });

              let width = !tablet ? 50 : 70;

              if (maxWidth != null) {
                width = maxWidth * (!tablet ? 10 : 12)
              }
              examples.push(exploded);
              maxWidths.push(width);

              // let shuffledSent = shuffle([...exploded]);
              let shuffledSent = shuffleSentence([...explodedWithOrigIndices]);

              shuffledExamples.push(shuffledSent)
            }

          });
          item.sentences = examples;
          item.maxWidths = maxWidths;
          item.shuffledSentences = shuffledExamples;*/

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords.push({
          listId: action.payload.listId,
          trainingId: action.payload.trainingId,
          pages: [action.payload.page],
          data: {
            words,
            shuffledIndices,
            totalWords: action.payload.data.total_words,
            totalPages: action.payload.data.total_pages
          }
        })
      }

      if (foundListIndex !== null && foundListPageIndex === null) {
        let words = action.payload.data.data.map((item) => {
          /*item.shuffledWord = shuffle([...item.word]);

          let shuffledExamples = [];
          let examples = [];
          let maxWidths = [];

          item.examples && item.examples.map(sentence => {
            if (sentence.example.length > 0) {
              let exploded = sentence.example.split(" ")

              let maxWidth = null;

              exploded.forEach((item, i) => {
                if (maxWidth == null || item.length > maxWidth) {
                  maxWidth = item.length;
                }
              });

              let width = !tablet ? 50 : 70;

              if (maxWidth != null) {
                width = maxWidth * (!tablet ? 10 : 12)
              }
              examples.push(exploded);
              maxWidths.push(width);

              let shuffledSent = shuffle([...exploded]);

              shuffledExamples.push(shuffledSent)
            }

          });
          item.sentences = examples;
          item.maxWidths = maxWidths;
          item.shuffledSentences = shuffledExamples;*/

          return item;
        });

        let shuffledIndices = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

        cachedWords[foundListIndex].pages.push(action.payload.page);
        cachedWords[foundListIndex].data.shuffledIndices = shuffledIndices;
        cachedWords[foundListIndex].data.words = _.union(cachedWords[foundListIndex].data.words, words);
      }

      // console.log("COLLECT SENTENCE cachedWords", cachedWords)

      return Object.assign({}, state, {
        listeningCachedWords: cachedWords,
      });
    }

    case types.DELETE_WORD_FROM_CACHED_WORDS_FOR_TRAININGS:
    {

      let previewCachedWords = state.previewCachedWords.map((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          list.data.words = list.data.words.filter(word => {
            return word.word_id !== action.payload.wordId;
          })
          list.data.totalWords = list.data.totalWords - 1
        }
        return list;
      });

      let collectWordCachedWords = state.collectWordCachedWords.map((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          list.data.words = list.data.words.filter(word => {
            return word.word_id !== action.payload.wordId;
          })
          list.data.totalWords = list.data.totalWords - 1
        }
        return list;
      });

      let checkSpellingCachedWords = state.checkSpellingCachedWords.map((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          list.data.words = list.data.words.filter(word => {
            return word.word_id !== action.payload.wordId;
          })
          list.data.totalWords = list.data.totalWords - 1
        }
        return list;
      });

      let missingLetterCachedWords = state.missingLetterCachedWords.map((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          list.data.words = list.data.words.filter(word => {
            return word.word_id !== action.payload.wordId;
          })
          list.data.totalWords = list.data.totalWords - 1
        }
        return list;
      });

      let selectTranslationCachedWords = state.selectTranslationCachedWords.map((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          list.data.words = list.data.words.filter(word => {
            return word.word_id !== action.payload.wordId;
          })

          list.data.totalWords = list.data.totalWords - 1
        }
        return list;
      });

      let collectSentenceCachedWords = state.collectSentenceCachedWords.map((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          list.data.words = list.data.words.filter(word => {
            return word.word_id !== action.payload.wordId;
          })

          list.data.totalWords = list.data.totalWords - 1
        }
        return list;
      });

      let selectWordCachedWords = state.selectWordCachedWords.map((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          list.data.words = list.data.words.filter(word => {
            return word.word_id !== action.payload.wordId;
          });
          list.data.totalWords = list.data.totalWords - 1
        }
        return list;
      });

      let dragImageCachedWords = state.dragImageCachedWords.map((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          list.data.words = list.data.words.filter(word => {
            return word.word_id !== action.payload.wordId;
          })
          list.data.totalWords = list.data.totalWords - 1
        }
        return list;
      });

      let definitionCachedWords = state.definitionCachedWords.map((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          list.data.words = list.data.words.filter(word => {
            return word.word_id !== action.payload.wordId;
          })
          list.data.totalWords = list.data.totalWords - 1
        }
        return list;
      });

      let listeningCachedWords = state.listeningCachedWords.map((list, listIndex) => {
        if (list.listId == action.payload.listId) {
          list.data.words = list.data.words.filter(word => {
            return word.word_id !== action.payload.wordId;
          })
          list.data.totalWords = list.data.totalWords - 1
        }
        return list;
      });

      return Object.assign({}, state, {
        previewCachedWords,
        collectWordCachedWords,
        checkSpellingCachedWords,
        missingLetterCachedWords,
        selectTranslationCachedWords,
        collectSentenceCachedWords,
        selectWordCachedWords,
        dragImageCachedWords,
        definitionCachedWords,
        listeningCachedWords,
        memoryCardsCachedWords: []
      });
    }

    case types.DELETE_CACHED_LISTS_FROM_TRAINING_LISTS:
    {
      let previewCachedWords = state.previewCachedWords;
      let collectWordCachedWords = state.collectWordCachedWords;
      let checkSpellingCachedWords = state.checkSpellingCachedWords;
      let selectTranslationCachedWords = state.selectTranslationCachedWords;
      let missingLetterCachedWords = state.missingLetterCachedWords;
      let collectSentenceCachedWords = state.collectSentenceCachedWords;
      let selectWordCachedWords = state.selectWordCachedWords;
      let dragImageCachedWords = state.dragImageCachedWords;
      let definitionCachedWords = state.definitionCachedWords;
      let memoryCardsCachedWords = state.memoryCardsCachedWords;
      let listeningCachedWords = state.listeningCachedWords;

      action.payload.lists.map(listId => {
        previewCachedWords = state.previewCachedWords.filter((list, listIndex) => {
          return list.listId !== listId;
        });

        collectWordCachedWords = state.collectWordCachedWords.filter((list, listIndex) => {
          return list.listId !== listId;
        });

        checkSpellingCachedWords = state.checkSpellingCachedWords.filter((list, listIndex) => {
          return list.listId !== listId;
        });

        missingLetterCachedWords = state.missingLetterCachedWords.filter((list, listIndex) => {
          return list.listId !== listId;
        });

        selectTranslationCachedWords = state.selectTranslationCachedWords.map((list, listIndex) => {
          return list.listId !== listId;
        });

        collectSentenceCachedWords = state.collectSentenceCachedWords.map((list, listIndex) => {
          return list.listId !== listId;
        });

        selectWordCachedWords = state.selectWordCachedWords.map((list, listIndex) => {
          return list.listId !== listId;
        });

        dragImageCachedWords = state.dragImageCachedWords.map((list, listIndex) => {
          return list.listId !== listId;
        });

        definitionCachedWords = state.definitionCachedWords.map((list, listIndex) => {
          return list.listId !== listId;
        });

        memoryCardsCachedWords = state.memoryCardsCachedWords.map((list, listIndex) => {
          return list.listId !== listId;
        });

        listeningCachedWords = state.listeningCachedWords.map((list, listIndex) => {
          return list.listId !== listId;
        });
      })

      return Object.assign({}, state, {
        previewCachedWords,
        collectWordCachedWords,
        checkSpellingCachedWords,
        missingLetterCachedWords,
        selectTranslationCachedWords,
        collectSentenceCachedWords,
        selectWordCachedWords,
        dragImageCachedWords,
        definitionCachedWords,
        memoryCardsCachedWords,
        listeningCachedWords,
      });
    }

    case types.DELETE_CACHED_LIST_FROM_TRAINING_LISTS:
    {
      let previewCachedWords = state.previewCachedWords.filter((list, listIndex) => {
        return list.listId !== action.payload.listId;
      });
      
      let collectWordCachedWords = state.collectWordCachedWords.filter((list, listIndex) => {
        return list.listId !== action.payload.listId;
      });
      
      let checkSpellingCachedWords = state.checkSpellingCachedWords.filter((list, listIndex) => {
        return list.listId !== action.payload.listId;
      });

      let missingLetterCachedWords = state.missingLetterCachedWords.filter((list, listIndex) => {
        return list.listId !== action.payload.listId;
      });

      let selectTranslationCachedWords = state.selectTranslationCachedWords.map((list, listIndex) => {
        return list.listId !== action.payload.listId;
      });

      let collectSentenceCachedWords = state.collectSentenceCachedWords.map((list, listIndex) => {
        return list.listId !== action.payload.listId;
      });

      let selectWordCachedWords = state.selectWordCachedWords.map((list, listIndex) => {
        return list.listId !== action.payload.listId;
      });

      let dragImageCachedWords = state.dragImageCachedWords.map((list, listIndex) => {
        return list.listId !== action.payload.listId;
      });

      let definitionCachedWords = state.definitionCachedWords.map((list, listIndex) => {
        return list.listId !== action.payload.listId;
      });

      let memoryCardsCachedWords = state.memoryCardsCachedWords.map((list, listIndex) => {
        return list.listId !== action.payload.listId;
      });

      let listeningCachedWords = state.listeningCachedWords.map((list, listIndex) => {
        return list.listId !== action.payload.listId;
      });
      
      return Object.assign({}, state, {
        previewCachedWords,
        collectWordCachedWords,
        checkSpellingCachedWords,
        missingLetterCachedWords,
        selectTranslationCachedWords,
        collectSentenceCachedWords,
        selectWordCachedWords,
        dragImageCachedWords,
        definitionCachedWords,
        memoryCardsCachedWords,
        listeningCachedWords,
      });
    }

    case types.UPDATE_CACHED_LIST_TRAININGS:
    {
      let newLists = state.cachedLists.map((item, i) => {
        if (item.id == action.payload.listId) {
          // console.log("UPDATE_CACHED_LIST_TRAININGS 1", item.trainings)
          item.trainings = item.trainings + 1;
          // console.log("UPDATE_CACHED_LIST_TRAININGS 2", item.trainings)
        }
        return item;
      });

      return Object.assign({}, state, {
        cachedLists: newLists,
      });
    }

    case types.UPDATE_CACHED_LIST_TRAININGS_AND_INTERVALS:
    {
      let newLists = state.cachedLists.map((item, i) => {
        if (item.id == action.payload.list.id) {
          item = createListWithIntervals(action.payload.list);
        }

        return item;
      });

      return Object.assign({}, state, {
        cachedLists: newLists,
      });
    }

    case types.REMOVE_ARCHIVED_CACHED_LIST:
    {
      let newLists = state.cachedLists.filter((item, i) => {
        return item.id != action.payload;
      });

      return Object.assign({}, state, {
        cachedLists: newLists,
      });
    }

    default:
      return state;
  }
}