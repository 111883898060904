import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import Loader from "../../../components/Custom/Loader/index";

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import {getUserAppProfile} from '../../../actions/userActions';

import colors from '../../../lib/colors';

class UserProfile extends Component {

  componentDidMount() {
    const {userData} = this.props.user;

    if (!userData || !userData.id) {
      this.props.getUserAppProfile();
    }
  }

  render() {
    const {fetchingData} = this.props.activity;
    const {translate, onClose, isVisible, changeProfile} = this.props;
    const {userData} = this.props.user;
    const {fontSizeRation} = this.props.settings;

    let levels = [
      "Basic",
      "Elementary",
      "Intermediate",
      "Advanced"
    ];

    let currentLevel = levels[userData ? userData.level : 0];

    return (
      <Modal
        className="userprofile"
        show={isVisible}
        onHide={()=>onClose('profileModalVisible',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="profile"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Card className="p-2 d-flex flex-column justify-content-center">
            {
              userData && userData.avatar ? (
                <Image
                  thumbnail
                  roundedCircle
                  style={{width: 100, height: 100, margin: '0 auto 10px'}}
                  src={userData.avatar}
                />
              ) : (
                <Card className="img-thumbnail rounded-circle"
                      style={{width: 100, height: 100, margin: '0 auto 10px', backgroundColor: colors.lightGrey5}}
                />
              )
            }

            <div>
              <span className="font-weight-light mr-2" style={{fontSize: fontSizeRation * 14}}>
                {translate('first_name_')}
              </span>
              <span className="font-weight-bold" style={{fontSize: fontSizeRation * 14}}>
                {userData && userData.first_name}
              </span>
            </div>

            <div>
              <span className="font-weight-light mr-2" style={{fontSize: fontSizeRation * 14}}>
                {translate('last_name_')}
              </span>
              <span className="font-weight-bold" style={{fontSize: fontSizeRation * 14}}>
                {userData && userData.last_name}
              </span>
            </div>

            <div>
              <span className="font-weight-light mr-2" style={{fontSize: fontSizeRation * 14}}>
                {translate('email_')}
              </span>
              <span className="font-weight-bold" style={{fontSize: fontSizeRation * 14}}>
                {userData && userData.email}
              </span>
            </div>

            <div>
              <span className="font-weight-light mr-2" style={{fontSize: fontSizeRation * 14}}>
                {translate('birthday_')}
              </span>
              <span className="font-weight-bold" style={{fontSize: fontSizeRation * 14}}>
                {userData && userData.dob}
              </span>
            </div>

            <div>
              <span className="font-weight-light mr-2" style={{fontSize: fontSizeRation * 14}}>
                {translate('language_level')}
              </span>
              <span className="font-weight-bold" style={{fontSize: fontSizeRation * 14}}>
                {currentLevel}
              </span>
            </div>

          </Card>

          <Button
            className="mt-3"
            variant="outline-info"
            block
            onClick={changeProfile}
          >
            <Translate id="update_profile"/>
          </Button>
        </Modal.Body>

        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    getUserAppProfile: () => dispatch(getUserAppProfile()),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
  };
}

export default connect(mapStateToProps, bindAction)(UserProfile);
