import * as types from '../actions/types';

const INITIAL_STATE = {
  fetchingData: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCHING_DATA:
      return Object.assign({}, state, {
        fetchingData: action.payload,
      });

    default:
      return state;
  }
}