import * as types from './types';

export const getWordMakerWords = (level) => (dispatch) => {
    dispatch({ type: types.GET_WORD_MAKER_WORDS, payload: {level} });
};

export const resetWordMakerWords = () => (dispatch) => {
  dispatch({ type: types.RESET_WORD_MAKER_WORDS });
};

export const resetWordMakerParts = () => (dispatch) => {
  dispatch({ type: types.RESET_WORD_MAKER_PARTS });
};

export const logWordMakerPart = (wordId, word) => (dispatch) => {
  dispatch({ type: types.LOG_WORD_MAKER_PART, payload: {wordId,word} });
};

export const setWordMakerWord = (word) => (dispatch) => {
  dispatch({ type: types.SET_CURRENT_WORD_MAKER_WORD, payload: word });
};

export const setFetchingMakerWord = () => (dispatch) => {
  dispatch({ type: types.SET_FETCHING_WORD_MAKER_WORDS });
};

export const getWordMakerParts = (wordId) => (dispatch) => {
  dispatch({ type: types.GET_WORD_MAKER_PARTS, payload: wordId });
};

export const logBuyWordMakerWord = (status) => (dispatch) => {
  dispatch({ type: types.LOG_BUY_WORD_MAKER_WORD, payload: status });
};

export const openWordMakerLevel = () => (dispatch) => {
  dispatch({ type: types.BUY_WORD_MAKER_LEVEL });
};

export const buyWordMakerHints = () => (dispatch) => {
  dispatch({ type: types.BUY_WORD_MAKER_HINTS });
};

export const getWordMakerData = () => (dispatch) => {
  dispatch({ type: types.GET_WORD_MAKER_DATA });
};