import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logActivity} from './logSagas';
import {getListsByLang, getListWords} from './lists';
import {getCourseListWords} from './courseSagas';
import {getWordsForTraining, getUpdatedWordForTraining} from './trainings';
import {logApiError} from './logSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* get_word_details(data) {
  const params = data.payload;

  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    // .get(API_URL + 'get_word_details')
    .get(API_URL + 'getWordDetails')
    .query({
      user_id: userId && userId > 0 ? userId : 1,
      word_id: params.wordId,
      by_word: params.byWord,
      full_package: true,
      all_translations: true
    });

    // console.log("getWordDetails",result.body)

    if (result.statusCode === 200) {
      yield put({
        type: types.SET_CURRENT_WORD,
        payload: result.body.details
      });
      
      if(!result.body.details) {
        toastr.warning(translate('no_such_word_in_dictionary'))
        params.history.goBack();
      }
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'get_word_details',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* get_extended_info(data) {
  const params = data.payload;

  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'get_extended_info')
    .query({
      user_id: userId,
      word_id: params.word_id,
      method: params.method
    });

    if (result.statusCode === 200) {
      if (params.method === 'get_definitions') {

        yield put({
          type: types.SET_CURRENT_WORD_DEFINITIONS,
          payload: result.body
        });
      }

      if (params.method === 'get_examples') {
        yield put({
          type: types.SET_CURRENT_WORD_EXAMPLES,
          payload: result.body
        });
      }

      if (params.method === 'get_synonyms') {
        yield put({
          type: types.SET_CURRENT_WORD_SYNONYMS,
          payload: result.body
        });
      }

      if (params.method === 'get_antonyms') {
        yield put({
          type: types.SET_CURRENT_WORD_ANTONYMS,
          payload: result.body
        });
      }
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'get_extended_info',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* toggleInVocabularyDetails(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'toggleInVocabulary')
    .send({
      user_id: userId,
      word_id: params,
      source: "easy"
    });

    if (result.statusCode === 200) {
      yield put({
        type: types.UPDATE_CURRENT_WORD,
        payload: 'vocabulary'
      });
      // yield get_word_details({payload: {wordId:params}})

      if (result.body.result === true) {
        toastr.info('', translate('word_removed_from_vocabulary'));
      } else {
        toastr.success('', translate('word_added_to_vocabulary'));
      }
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'toggleInVocabularyDetails',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* toggleInFavoriteWordDetails(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'addWordToFavourite')
    .send({
      user_id: userId,
      word_id: params
    });

    // console.log("toggleInFavoriteWordDetails", result.body);

    if (result.statusCode === 200) {
      // yield get_word_details({payload: {wordId:params}});

      yield put({
        type:types.UPDATE_CURRENT_WORD,
        payload: 'favorite'
      });

      if (result.body.result !== true) {
        toastr.success('', translate('word_added_to_favorites'));
      } else {
        toastr.success('', translate('word_removed_from_favorites'));
      }
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'toggleInFavoriteWordDetails',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* addUserTranslation(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'addUserTranslation')
    .send({
      user_id: userId,
      word_id: params.word_id,
      translation: params.translation.trim()
    });

    if (result.statusCode === 200) {
      if (result.body.result == true) {

        yield logActivity({payload: {activityId: 199}});

        toastr.success('', translate('translation_saved'));
        yield get_word_details({payload: {wordId:params.word_id}})
      } else {
        toastr.error('', translate('translation_not_saved'));
      }
    } else {
      toastr.warning('', translate('not_allowed_characters'));
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'addUserTranslation',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.UPDATE_CURRENT_WORD,
      payload: 'favorite'
    });
  }
}

export function* addWordAssociation(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'addWordAssociation')
    .send({
      user_id: userId,
      word_id: params.word_id,
      association: params.association.trim()
    });

    if (result.statusCode === 200) {
      if (result.body.result == true) {
        toastr.success('', translate('association_saved'));

        yield put({
          type: types.ADD_ASSOCIATION_TO_WORD,
          payload: result.body.association
        });

        yield put({
          type: types.ADD_ASSOCIATION_TO_CACHED_WORD,
          payload: {
            association: result.body.association,
            wordId: params.word_id
          }
        });

        yield put({
          type: types.ADD_ASSOCIATION_TO_CACHED_COURSE_WORD,
          payload: {
            association: result.body.association,
            wordId: params.word_id
          }
        });

        // TODO add association to TRAINING cache
      } else {
        toastr.error('', translate('association_not_saved'));
      }
    } else {
      toastr.warning('', translate('not_allowed_characters'));
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'addWordAssociation',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


export function* addUserExample(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'addUserExample')
    .send({
      user_id: userId,
      word_id: params.wordId,
      example: params.example.trim()
    });
    
    // console.log("addUserExample", result.body)

    if(result.body.result) {
      toastr.info('', translate(result.body.result == 2 ? 'suggest_example_already' : 'suggest_example_added'));
    } else {
      toastr.error('', translate('error'));
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'addUserExample',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* deleteUserAssociation(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'deleteUserAssociationBy')
    .send({
      user_id: userId,
      association_id: params.associationId,
    });

    // console.log("deleteUserAssociationBy",result.body)

    toastr.info('', translate(result.body.result ? 'association_deleted' : 'association_not_deleted'));

    if (result.body.result) {
      yield put({
        type: types.DELETE_ASSOCIATION_FROM_WORD,
        payload: false
      });

      yield put({
        type: types.DELETE_ASSOCIATION_FROM_CACHED_WORD,
        payload: {
          wordId: params.wordId
        }
      });

      yield put({
        type: types.DELETE_ASSOCIATION_FROM_CACHED_COURSE_WORD,
        payload: {
          wordId: params.wordId
        }
      });
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'deleteUserAssociationBy',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* changeMainTranslation(data) {
  const {userId} = yield select(state => state.user);
  const {currentList} = yield select(state => state.lists);
  const {currentTrainingId} = yield select(state => state.trainings);
  const {course} = yield select(state => state);
  const translate = yield select(state => getTranslate(state.localize));

  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'changeMainTranslation')
    .send({
      user_id: userId,
      word_id: params.word_id,
      translation_id: params.translation_id
    });

    if (result.statusCode === 200) {
      if (result.body.result == true) {
        toastr.success('', translate('main_translation_changed'));

        yield get_word_details({payload: {wordId: params.word_id}});

        if (currentList && currentList.id) {

          yield getListWords({
            payload: currentList.id
          })

          yield getUpdatedWordForTraining({
            payload: {
              wordId: params.word_id,
              trainingId: currentTrainingId,
              listId: currentList.id,
            }
          })
        } else {
          if (course.currentList && course.currentList.id) {
            yield getCourseListWords({
              payload: course.currentList.id
            })

            yield getUpdatedWordForTraining({
              payload: {
                wordId: params.word_id,
                trainingId: currentTrainingId,
                courseListId: course.currentList.id,
              }
            })
          }
        }
      }
    } else {
      toastr.error('', translate('error'));
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'changeMainTranslation',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


export function* deleteUserTranslation(data) {
  const {userId} = yield select(state => state.user);
  const {currentList} = yield select(state => state.lists);
  const {currentTrainingId} = yield select(state => state.trainings);
  const {course} = yield select(state => state);
  const translate = yield select(state => getTranslate(state.localize));

  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'deleteUserTranslation')
    .send({
      user_id: userId,
      word_id: params.word_id,
      translation_id: params.translation_id
    });

    // console.log("deleteUserTranslation",result.body)

    if (result.body.result == true) {
      toastr.info('', translate('your_translation_deleted'));
      yield get_word_details({payload: {wordId: params.word_id}});

      if (currentList && currentList.id) {
        yield getUpdatedWordForTraining({
          payload: {
            wordId: params.word_id,
            trainingId: currentTrainingId,
            listId: currentList.id,
          }
        })
      } else {
        if (course.currentList && course.currentList.id) {
          yield getUpdatedWordForTraining({
            payload: {
              wordId: params.word_id,
              trainingId: currentTrainingId,
              courseListId: course.currentList.id,
            }
          })
        }
      }
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'deleteUserTranslation',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getWordToOpen(data) {
  const params = data.payload;
  const translate = yield select(state => getTranslate(state.localize));

  yield put({
    type: types.FETCHING_DATA,
    payload: true
  });
  try {
    const result = yield request
    .get(API_URL + 'getWordToOpen')
    .query({
      user_id: params.userId,
      word_id: params.wordId,
    });

    if (result.statusCode === 200 && result.body.result) {
      checkIfRedirect(result.body.data);
      let userId = yield checkIfRedirect(result.body.data);
      if (userId !== null) {
        // yield put(NavigationActions.navigate({routeName: 'WordDetails', params: {word: result.body.data}}));
      }
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getWordToOpen',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* deleteUserImage(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'deleteUserImage')
    .send({
      user_id: userId,
      word_id: params,
    });

    toastr.info('', !result.body.result ? translate('error') : translate('image_deleted'));

    yield put({
      type: types.REMOVE_IMAGE_FROM_CURRENT_WORD,
      payload: params
    });

    yield put({
      type: types.REMOVE_USER_IMAGE_FROM_LIST_WORDS,
      payload: params
    });

    yield put({
      type: types.REMOVE_USER_IMAGE_TO_CACHED_LIST_WORDS,
      payload: params
    });

    yield put({
      type: types.REMOVE_USER_IMAGE_FROM_CACHED_TRAINING_WORDS,
      payload: params
    });
    // TODO remove this image from everywhere

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'deleteUserImage',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

const checkIfRedirect = async() => {
  try {
    // return await AsyncStorage.getItem('userId');
  } catch (error) {
    // console.log(error.message);
  }
};