import * as types from '../actions/types';

const INITIAL_STATE = {
  sharedWords: [],
  sharedGlossaries: [],
  sharedLists: [],
  glossariesBadge: 0,
  listsBadge: 0,
  wordsBadge: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_SHARED_GLOSSARIES:
      return Object.assign({}, state, {
        sharedGlossaries: action.payload,
      });

    case types.SET_SHARED_LISTS:
      return Object.assign({}, state, {
        sharedLists: action.payload,
      });

    case types.SET_SHARED_WORDS:
      return Object.assign({}, state, {
        sharedWords: action.payload,
      });
 case types.SET_SHARED_NUMBERS:
      return Object.assign({}, state, {
        glossariesBadge: action.payload.glossaries,
        listsBadge: action.payload.lists,
        wordsBadge: action.payload.words,
      });

    default:
      return state;
  }
}