import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import userReducer from './userReducer';
import authReducer from './authReducer';
import listsReducer from './listsReducer';
import trainingsReducer from './trainingsReducer';
import dictionaryReducer from './dictionaryReducer';
import glossaryReducer from './glossaryReducer';
import testReducer from './testReducer';
import vocabularyReducer from './vocabularyReducer';
import phrasebooksReducer from './phrasebooksReducer';
import favoritesReducer from './favoritesReducer';
import accountsReducer from './accountsReducer';
import wordReducer from './wordReducer';
import activity from './activity';
import purchases from './purchases';
import userContacts from './userContactsReducer';
import sharedReducer from './sharedReducer';
import calendarReducer from './calendarReducer';
import settingsReducer from './settingsReducer';
import badgesReducer from './badgesReducer';
import courseReducer from './courseReducer';
import quizReducer from './quizReducer';
import wordMakerReducer from './wordMakerReducer';
import cacheReducer from './cacheReducer';
import courseCacheReducer from './courseCacheReducer';
import tourReducer from './tourReducer';
import everydaysReducer from './everydaysReducer';
import newsReducer from './newsReducer';
import tagsReducer from './tagsReducer';
import multiWordMakerReducer from './multiWordMakerReducer';
import helperArticlesReducer from './helperArticlesReducer';


export default combineReducers({
  localize: localizeReducer,
  toastr: toastrReducer,
  user: userReducer,
  auth: authReducer,
  lists: listsReducer,
  trainings: trainingsReducer,
  dictionary: dictionaryReducer,
  glossaries: glossaryReducer,
  test: testReducer,
  vocabulary: vocabularyReducer,
  phrasebooks: phrasebooksReducer,
  favorites: favoritesReducer,
  accounts: accountsReducer,
  word: wordReducer,
  activity: activity,
  purchases: purchases,
  userContacts: userContacts,
  shared: sharedReducer,
  calendar: calendarReducer,
  settings: settingsReducer,
  badges: badgesReducer,
  course: courseReducer,
  quiz: quizReducer,
  wordMaker: wordMakerReducer,
  cache: cacheReducer,
  courseCache: courseCacheReducer,
  tour: tourReducer,
  everydays: everydaysReducer,
  news: newsReducer,
  tags: tagsReducer,
  multiWordMaker: multiWordMakerReducer,
  helperArticles: helperArticlesReducer,

});