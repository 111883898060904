import request from 'superagent';
import {put, select} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logApiError} from './logSagas';

import * as types from '../../actions/types';
import * as constants from '../../lib/constants';

require('superagent-charset')(request);

const API_URL = constants.API_URL;

export function* getUserContacts() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getUserContacts')
    .query({
      user_id: userId,
    });

    console.log('getUserContacts', result.body)

    yield put({
      type: types.FETCHED_USER_CONTACTS,
      payload: true
    });
    
    yield put({
      type: types.SET_USER_CONTACTS,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({payload: {function: 'getUserContacts', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* createUserContact(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'createUserContact')
    .send({
      user_id: userId,
      email: data.payload.email,
      username: data.payload.name,
    });

    console.log('createUserContact', result.body)

    if(result.body.result) {
      yield getUserContacts();
      toastr.info('', translate('contact_added'));
    } else {
      toastr.info('', translate(result.body.error));
    }

  } catch (error) {
    yield logApiError({payload: {function: 'createUserContact', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* deleteUserContact(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .del(API_URL + 'deleteUserContact')
    .send({
      user_id: userId,
      id: data.payload,
    });

    console.log('deleteUserContact', result.body)

    if(result.body.result) {
      yield getUserContacts();
      toastr.info('', translate('contact_deleted'));
    } else {
      toastr.info('', translate('error'));
    }

  } catch (error) {
    yield logApiError({payload: {function: 'deleteUserContact', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* updateUserContact(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .put(API_URL + 'updateUserContact')
    .send({
      user_id: userId,
      connected_user_id: data.payload.id,
      username: data.payload.username,
    });
    
    console.log('updateUserContact', result.body)

    if(result.body.result) {
      yield getUserContacts();
      toastr.info('', translate('contact_name_updated'));
    } else {
      toastr.info('', translate('error'));
    }
  } catch (error) {
    yield logApiError({payload: {function: 'updateUserContact', payload: data.payload,error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}