import * as types from './types';

export const getRegistrationDate = () => (dispatch) => {
  dispatch({type: types.GET_REGISTRATION_DATE});
};

export const getUserLimits = () => (dispatch) => {
  dispatch({type: types.GET_USER_LIMITS});
};

export const logTrainingLimits = () => (dispatch) => {
  dispatch({type: types.LOG_TRAINING_LIMIT});
};

export const logWordLimits = () => (dispatch) => {
  dispatch({type: types.LOG_WORD_LIMIT});
};

export const logGamesLimits = () => (dispatch) => {
  dispatch({type: types.LOG_GAMES_LIMIT});
};

export const setProducts = (data) => (dispatch) => {
  dispatch({type: types.SET_PRODUCTS, payload: data});
};

export const setSubscriptions = (data) => (dispatch) => {
  dispatch({type: types.SET_SUBSCRIPTIONS, payload: data});
};

export const setSubscriptionStatus = (status) => (dispatch) => {
  dispatch({type: types.SET_SUBSCRIPTION_STATUS, payload: status});
};

export const setCardsStatus = (status) => (dispatch) => {
  dispatch({type: types.SET_CARDS_STATUS, payload: status});
};

export const setListCardsStatus = (status) => (dispatch) => {
  dispatch({type: types.SET_LIST_CARDS_STATUS, payload: status});
};

export const setCardsPurchaseData = (status, date) => (dispatch) => {
  dispatch({type: types.SET_CARDS_PURCHASE_DATA, payload: {status, date}});
};

export const storePurchasesFetched = () => (dispatch) => {
  dispatch({type: types.STORE_PURCHASES_FETCHED, payload: true});
};

export const setListCardsPurchaseData = (status, date) => (dispatch) => {
  dispatch({type: types.SET_LIST_CARDS_PURCHASE_DATA, payload: {status, date}});
};

export const setSyncStatus = (status) => (dispatch) => {
  dispatch({type: types.SET_SYNCS_STATUS, payload: status});
};

export const checkWebSubscriptionStatus = (userId) => (dispatch) => {
  dispatch({type: types.GET_WEB_SUBSCRIPTION_STATUS, payload: userId});
};

export const setSubscriptionData = (data) => (dispatch) => {
  dispatch({type: types.SET_ACTIVE_SUBSCRIPTION_DATA, payload: data});
};

export const usePromoCode = (code) => (dispatch) => {
  dispatch({type: types.USE_PROMO_CODE, payload: code});
};

export const setLifetimeAccess = (status, date) => (dispatch) => {
  dispatch({type: types.SET_LIFETIME_ACCESS, payload: {status, date}});
};

export const checkForHappyDay = () => (dispatch) => {
  dispatch({type: types.CHECK_HAPPY_DAY});
};

export const exchangeScores = (days) => (dispatch) => {
  dispatch({type: types.EXCHANGE_SCORES, payload: days});
};

export const getPurchaseData = (orderId) => (dispatch) => {
  dispatch({type: types.GET_PURCHASE_DATA, payload: orderId});
};

export const getLiqpaySubscriptionStatus = (orderId) => (dispatch) => {
  dispatch({type: types.GET_LIQPAY_SUBSCRIPTION_STATUS, payload: orderId});
};

export const getUserPurchases = () => (dispatch) => {
  dispatch({type: types.GET_USER_PURCHASES});
};

export const cancelSubscription = (orderId, purchaseId) => (dispatch) => {
  dispatch({type: types.CANCEL_SUBSCRIPTION, payload: {orderId, purchaseId}});
};

export const checkDiscountCode = (code) => (dispatch) => {
  dispatch({type: types.CHECK_DISCOUNT_CODE, payload: code});
};

export const getCurrencyExchangeRate = (code) => (dispatch) => {
  dispatch({type: types.GET_EXCHANGE_RATE, payload: code});
};

export const setDiscountCode = () => (dispatch) => {
  dispatch({type: types.SET_DISCOUNT_CODE, payload: false});
};
