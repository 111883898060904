import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';

import {Translate, getTranslate} from 'react-localize-redux';
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons';

import CourseListCell from '../../../Courses/CourseListCell/index';

import colors from '../../../../lib/colors';

class CourseRepeats extends Component {

  _renderTestItem = (item, index, course) => {
    const {translate, onListPressed, onRepeatPressed} = this.props;
    const {fontSizeRation} = this.props.settings;
    const {courseData} =  this.props.course;
    const {isConnected} = this.props.settings;

    let courseName = course.slug == 1000 ? 'course_beginner' : 'course_elementary';

    let dots = this.getDots(item);

    return (
      <CourseListCell
        key={index}
        isConnected={isConnected}
        tablet={false}
        ratio={fontSizeRation}
        list={item}
        courseData={courseData}
        index={index}
        repeat={true}
        dots={dots}
        translate={translate}
        courseName={courseName}
        testProgress={false}
        onItemPressed={() => onListPressed(item, course)}
        onRepeatPressed={(list, some, e) => onRepeatPressed(item, course, e)}
        onInfoPressed={this._infoPressed}
        onCalendarPressed={this._onCalendarPressed}
      />
    )
  };

  getDots = (list) => {
    let trainings = 0;
    let found = false;

    list.repetitions.forEach(day => {
      if (day.repetition_date == moment().format("YYYY-MM-DD")) {
        trainings = day.trainings;
        found = true;
      }
    });

    return [0, 1, 2].map((item, i) => {
      return <div key={`${item}-${i}`}
                  className="dot mh2"
                  style={{backgroundColor: trainings > item ? colors.tealishThree: colors.lightGrey}}/>
    });
  };

  _getRepeats = () => {
    const {cachedCourseData} = this.props.courseCache;
    let repeats = [];

    cachedCourseData.forEach((course, index) => {
         if(course && course.repeats) {
           course.repeats.forEach((list, i) => {
             let test =  this._renderTestItem(list, `${index}-${i}`, course);
             repeats.push(test)
           })
         }
      });

    return repeats;
  };


  render() {
    const repeats = this._getRepeats();
    
    return repeats.length > 0 ?  (
      repeats
    ) : null;
  }
}


const mapStateToProps = (state, dispatch) => ({
  activity: state.activity,
  trainings: state.trainings,
  settings: state.settings,
  purchases: state.purchases,
  user: state.user,
  course: state.course,

  courseCache: state.courseCache,
  translate: getTranslate(state.localize),

});


export default connect(mapStateToProps, null)(CourseRepeats);
