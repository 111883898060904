import * as types from './types';

export const getListsByLangF = () => (dispatch) => {
  dispatch({ type: types.GET_FAVORITE_LISTS });
};

export const getFavoriteWords = (page) => (dispatch) => {
  dispatch({ type: types.GET_FAVORITE_WORDS, payload: page });
};

export const get_favourite_glossaries = () => (dispatch) => {
  dispatch({ type: types.GET_FAVORITE_GLOSSARIES });
};

export const getAllFavoriteNumbers = () => (dispatch) => {
  dispatch({ type: types.GET_FAVORITE_NUMBERS });
};

export const deleteFromFavoriteWords = (id) => (dispatch) => {
  dispatch({ type: types.DELETE_FAVORITE_WORD, payload: id });
};

export const deleteFromFavoritePhrases = (id, index) => (dispatch) => {
  dispatch({ type: types.DELETE_FAVORITE_PHRASE, payload: {id, index} });
};

export const getFavoritePhrases = (page) => (dispatch) => {
  dispatch({ type: types.GET_FAVORITE_PHRASES, payload: page });
};
