import * as types from '../actions/types';

const INITIAL_STATE = {
  userId: false,
  originalUserId: false,
  userData: {},
  userLevel: 0,
  confirmed: false,
  shouldCheck: false,
  listSize: 10,
  scores: 0,
  ip: false,
  country: false,
  city: false,
  studyLanguages: [],
  translationLanguages: [],
  syncProgress: false,
  slides: [],
  token: false,
  reviewModalVisible: false,
  navigateToCourse: false,
  approvedDictionaryTranslations: ['uk', 'ru'],
  newUser: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_USER_ID:
      return Object.assign({}, state, {
        userId: action.payload
      });

    case types.SET_NEW_USER:
      return Object.assign({}, state, {
        newUser: action.payload
      });
    
    case types.RESET_USER_DATA:
      return Object.assign({}, state, {
        userId: false,
        originalUserId: false,
        userData: {}
      });
    
    case types.SET_IP_ADDRESS:{
      return Object.assign({}, state, {
        ip: action.payload
      });
    }
    
    case types.SET_USER_COUNTRY:{
      return Object.assign({}, state, {
        country: action.payload.country,
        city: action.payload.city
      });
    }

    case types.TOGGLE_REVIEW_MODAL:{
      return Object.assign({}, state, {
        reviewModalVisible: action.payload
      });
    }

    case types.SET_NAVIGATE_TO_COURSE:
      return Object.assign({}, state, {
        navigateToCourse: action.payload
      });

    case types.SET_ORIGINAL_USER_ID: {
      return Object.assign({}, state, {
        originalUserId: action.payload
      });
    }

    case types.SET_USER_TOKEN: {
      return Object.assign({}, state, {
        token: action.payload
      });
    }
      
    case types.SET_USER_DATA:
      return Object.assign({}, state, {
        userData: action.payload
      });

    case types.UPDATE_USER_LEVEL:{
      let userData = state.userData;
      userData.level = action.payload;

      return Object.assign({}, state, {
        userData
      });
    }

    case types.UPDATE_INTERFACE_LANGUAGE:{
      let userData = state.userData;
      userData.interface_language = action.payload;

      return Object.assign({}, state, {
        userData
      });
    }

    case types.UPDATE_SHOW_LEARN_USER_DATA:{
      let userData = state.userData;
      userData.show_learnt = userData.show_learnt == 1 ? 0 : 1;

      return Object.assign({}, state, {
        userData
      });
    }
    
    case types.UPDATE_SHOW_LEARN_COURSE_USER_DATA:{
      let userData = state.userData;
      userData.show_learnt_course = userData.show_learnt_course == 1 ? 0 : 1;

      return Object.assign({}, state, {
        userData
      });
    }

    case types.SET_UPDATED_TRAININGS_SET:{
      return Object.assign({}, state, {
        userData: action.payload
      });
    }
    
    case types.UPDATE_SHOW_ARCHIVE_USER_DATA:{
      let userData = state.userData;
      userData.show_archive = userData.show_archive == 1 ? 0 : 1;

      return Object.assign({}, state, {
        userData
      });
    }

    case types.SET_USER_GOAL:{
      let userData = state.userData;
      userData.goal = action.payload;

      return Object.assign({}, state, {
        userData
      });}
    
    case types.UPDATE_USER_LEVELS:{
      let userData = state.userData;
      userData.level = action.payload;
      userData.word_level_id = action.payload;

      return Object.assign({}, state, {
        userData
      });
    }

    case types.SET_NEW_LIST_SIZE:{
      let userData = state.userData;
      userData.list_size = action.payload;

      return Object.assign({}, state, {
        userData
      });
    }
    
    case types.SET_NEW_TRANSLATION_LANGUAGE:{
      let userData = state.userData;
      userData.translation_language = action.payload;

      return Object.assign({}, state, {
        userData
      });
    }

    case types.SET_NEW_INTERFACE_LANGUAGE:{
      let userData = state.userData;
      userData.interface_language = action.payload;

      return Object.assign({}, state, {
        userData
      });
    }
    
    case types.SYNC_WEB_PROGRESS:
      return Object.assign({}, state, {
        syncProgress: action.payload
      });

    case types.UPDATE_USER_DATA:
      return Object.assign({}, state, {
        userData: action.payload
      });
    
    case types.UPDATE_AUTO_POSTPONE:{
      let userData = state.userData;
      userData.postpone = state.userData.postpone == "1" ? "0" : "1"
      
      return Object.assign({}, state, {
        userData
      });
    }
    
    case types.UPDATE_COURSE_AUTO_POSTPONE:{
      let userData = state.userData;
      userData.course_postpone = state.userData.course_postpone == "1" ? "0" : "1"
      
      return Object.assign({}, state, {
        userData
      });
    }

    case types.UPDATE_SHUFFLE_WORDS:{
      let userData = state.userData;
      userData.shuffle = state.userData.shuffle == "1" ? "0" : "1"

      return Object.assign({}, state, {
        userData
      });
    }
    
    case types.SET_USER_CONFIRMED:
      return Object.assign({}, state, {
        confirmed: true
      });
    
    case types.SHOULD_CHECK_VERIFIED:
      return Object.assign({}, state, {
        shouldCheck: action.payload
      }); 
    
    case types.SET_USER_SCORES:
      return Object.assign({}, state, {
        scores: action.payload.data
      });

    case types.SET_STUDY_LANGUAGES:
      return Object.assign({}, state, {
        studyLanguages: action.payload
      });

    case types.SET_TRANSLATION_LANGUAGES:
      return Object.assign({}, state, {
        translationLanguages: action.payload
      });

    case types.SET_INTRODUCTION_SLIDES:
      return Object.assign({}, state, {
        slides: action.payload.data
      });

    default:
      return state;
  }
}