import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';

const UnlockModal = ({isVisible, onClose, ratio, tablet, unlock, translate, deviceHeight}) => (
  <Modal
    size="sm"
    className="wordmakerunlock"
    show={isVisible}
    onHide={()=>onClose(false)}
    keyboard={false}
  >
    <Modal.Header closeButton>
      <Modal.Title as="h6">
        {translate('word_level_locked')}
      </Modal.Title>
    </Modal.Header>

    <Modal.Body className="modalView">
      <p className="description"
         style={{marginTop: !tablet ? 15 : 25, marginBottom: 20, fontSize: ratio * (!tablet ? 14 : 16)}}>
        {translate('word_level_how_unlock')}
      </p>
    </Modal.Body>
  </Modal>
);

export default UnlockModal;