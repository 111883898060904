import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import Loader from "../../../components/Custom/Loader/index";

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import colors from '../../../lib/colors';

class ListFiltersModal extends Component {
  

  render() {
    const {fetchingData} = this.props.activity;
    const {translate, onClose, isVisible, filter, order, tablet, ratio, sortBy} = this.props;
    const {userData} = this.props.user;

    return (
      <Modal
        className="listsfilters"
        show={isVisible}
        onHide={()=>onClose('filtersModalVisible',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="sort_by"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="sortRow" style={{height: !tablet ? 50 : 70}}>
            <div className="orderButtons" style={{width: !tablet ? 70 : 100}}>
              <a href="#"
                 className="sortButtonWrapper"
                 style={{height: !tablet ? 50 : 70,width: !tablet ? 35 : 50}}
                 onClick={(e) => sortBy('by_list_name', 'desc', e)}>
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className="sortButton"
                  style={{color: filter === 'by_list_name' && order === 'desc' ? colors.tealish : colors.lightGreyThree}} />
              </a>

              <a
                href="#"
                className="sortButtonWrapper"
                style={{height: !tablet ? 50 : 70,width: !tablet ? 35 : 50}}
                 onClick={(e) => sortBy('by_list_name', 'asc', e)}>
                <FontAwesomeIcon
                  icon={faAngleUp}
                  className="sortButton"
                  style={{color: filter === 'by_list_name' && order === 'asc' ? colors.tealish : colors.lightGreyThree}} />
              </a>
            </div>
            <span className="filterName" style={{fontSize: ratio * (!tablet ? 14 : 16)}}>
              {translate('by_list_name')}
            </span>
          </div>

          <div className="sortRow" style={{height: !tablet ? 50 : 70}}>
            <div className="orderButtons" style={{width: !tablet ? 70 : 100}}>
              <a
                href="#"
                className="sortButtonWrapper"
                style={{height: !tablet ? 50 : 70,width: !tablet ? 35 : 50}}
                onClick={(e) => sortBy('by_created_at', 'desc', e)}>
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className="sortButton"
                  style={{color: filter === 'by_created_at' && order === 'desc' ? colors.tealish : colors.lightGreyThree}}
                />
              </a>

              <a
                href="#"
                className="sortButtonWrapper"
                style={{height: !tablet ? 50 : 70,width: !tablet ? 35 : 50}}
                onClick={(e) => sortBy('by_created_at', 'asc', e)}>
                <FontAwesomeIcon
                  icon={faAngleUp}
                  className="sortButton"
                  style={{color: filter === 'by_created_at' && order === 'asc' ? colors.tealish : colors.lightGreyThree}} />
              </a>
            </div>
            <span className="filterName" style={{fontSize: ratio * (!tablet ? 14 : 16)}}>
              {translate('by_created_at')}
            </span>
          </div>

          <div className="sortRow" style={{height: !tablet ? 50 : 70}}>
            <div className="orderButtons" style={{width: !tablet ? 70 : 100}}>
              <a
                href="#"
                className="sortButtonWrapper"
                style={{height: !tablet ? 50 : 70,width: !tablet ? 35 : 50}}
                onClick={(e) => sortBy('by_words', 'desc', e)}
              >
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className="sortButton"
                  style={{color: filter === 'by_words' && order === 'desc' ? colors.tealish : colors.lightGreyThree}}
                />
              </a>

              <a
                href="#"
                className="sortButtonWrapper"
                style={{height: !tablet ? 50 : 70,width: !tablet ? 35 : 50}}
                onClick={(e) => sortBy('by_words', 'asc', e)}
              >
                <FontAwesomeIcon
                  icon={faAngleUp}
                  className="sortButton"
                  style={{color: filter === 'by_words' && order === 'asc' ? colors.tealish : colors.lightGreyThree}} />
              </a>
            </div>
            <span className="filterName" style={{fontSize: ratio * (!tablet ? 14 : 16)}}>
              {translate('by_words')}
            </span>
          </div>

          <div className="sortRow" style={{height: !tablet ? 50 : 70}}>
            <div className="orderButtons" style={{width: !tablet ? 70 : 100}}>
              <a
                href="#"
                className="sortButtonWrapper"
                style={{height: !tablet ? 50 : 70,width: !tablet ? 35 : 50}}
                onClick={(e) => sortBy('by_trainings', 'desc', e)}>
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className="sortButton"
                  style={{color: filter === 'by_trainings' && order === 'desc' ? colors.tealish : colors.lightGreyThree}}
                />
              </a>

              <a href="#"
                 className="sortButtonWrapper"
                 style={{height: !tablet ? 50 : 70,width: !tablet ? 35 : 50}}
                 onClick={(e) => sortBy('by_trainings', 'asc', e)}>
                <FontAwesomeIcon
                  icon={faAngleUp}
                  className="sortButton"
                  style={{color: filter === 'by_trainings' && order === 'asc' ? colors.tealish : colors.lightGreyThree}}
                />
              </a>
            </div>
            <span className="filterName" style={{fontSize: ratio * (!tablet ? 14 : 16)}}>
              {translate('by_trainings')}
            </span>
          </div>
        </Modal.Body>
        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
  };
}

export default connect(mapStateToProps, bindAction)(ListFiltersModal);
