import React, {Fragment} from 'react';
import {Route, Switch} from 'react-router-dom';

import InstaLink from 'containers/Out/InstaLink/index';
import SharedLink from 'containers/Out/SharedLink/index';

// AUTH
import Login from 'containers/Auth/Login/index';
import Register from 'containers/Auth/Register/index';
import Forgot from 'containers/Auth/Forgot/index';
import ResetPassword from 'containers/Auth/ResetPassword/index';
import Landing from 'containers/Landing/index';

// SERIVE 
import Terms from 'containers/Service/Terms/index';
import Policy from 'containers/Service/Policy/index';
import Agreement from 'containers/Service/Agreement/index';
import MemorizationSystem from 'containers/Service/MemorizationSystem/index';
import VideoInstructions from 'containers/Service/VideoInstructions/index';
import HelperArticles from 'containers/HelperArticles/HelperArticles/index';
import HelperArticle from 'containers/HelperArticles/HelperArticle/index';

// REUSABLE
import Home from 'containers/Home/index';
import UserNavbar from 'components/Layout/UserNavbar/index';
import GuestNavbar from 'components/Layout/GuestNavbar/index';
import Footer from 'components/Layout/Footer/index';
import NoMatch from 'components/Layout/NoMatch/index';

// WIZARD
import Wizard from 'containers/Wizard/index';

// UserBadges
import UserBadges from 'containers/Badges/UserBadges/index';

// Glossaries
import Glossaries from 'containers/Glossaries/Glossaries/index';
import GlossaryWords from 'containers/Glossaries/GlossaryWords/index';

//Lists
import Lists from 'containers/Lists/Lists/index';
import ListWords from 'containers/Lists/ListWords/index';

// Dictionary
import Dictionary from 'containers/Dictionary/Dictionary/index';
import WordDetails from 'containers/Dictionary/WordDetails/index';

// Course
import Course1000 from 'containers/Courses/Course1000/index';
import Courses from 'containers/Courses/Courses/index';
import CourseListWords from 'containers/Courses/CourseListWords/index';
import CourseVocabulary from 'containers/Courses/CourseVocabulary/index';
import CourseMistakes from 'containers/Courses/CourseMistakes/index';

// Trainings
import Preview from 'containers/Trainings/Preview/index';
import CollectWord from 'containers/Trainings/CollectWord/index';
import CheckSpelling from 'containers/Trainings/CheckSpelling/index';
import CollectSentence from 'containers/Trainings/CollectSentence/index';
import SelectWord from 'containers/Trainings/SelectWord/index';
import SelectTranslation from 'containers/Trainings/SelectTranslation/index';
import MissingLetter from 'containers/Trainings/MissingLetter/index';
import ListTest from 'containers/Trainings/ListTest/index';
import Listening from 'containers/Trainings/Listening/index';
import WordDefinition from 'containers/Trainings/WordDefinition/index';
import MemoryCards from 'containers/Trainings/MemoryCards/index';
import TranslateSentence from 'containers/Trainings/TranslateSentence/index';
import DragImage from 'containers/Trainings/DragImage/index';

// Games
import Hangman from 'containers/Games/Hangman/index';
import WordExtractor from 'containers/Games/WordExtractor/index';
import WordMaker from 'containers/Games/WordMaker/WordMaker/index';
import WordMakerWord from 'containers/Games/WordMaker/WordMakerWord/index';
import WordTest from 'containers/Games/WordTest/index';
import WordTestResults from 'containers/Games/WordTestResults/index';
import MultiWordMakerGamesList from 'containers/Games/MultiWordMaker/MultiWordMakerGamesList/index';
import MultiWordMakerGame from 'containers/Games/MultiWordMaker/MultiWordMakerGame/index';
import MultiWordMakerGameResults from 'containers/Games/MultiWordMaker/MultiWordMakerGameResults/index';
import MultiWordMakerWaiting from 'containers/Games/MultiWordMaker/MultiWordMakerWaiting/index';

// Purchases
import AllProducts from 'containers/Purchases/AllProducts/index';
import Purchases from 'containers/Purchases/Purchases/index';
import Liqpay from 'containers/Purchases/Liqpay/index';
import PaymentResult from 'containers/Purchases/PaymentResult/index';

//Family
import FamilyAccounts from 'containers/FamilyAccess/FamilyAccounts/index';

// News
import Article from 'containers/News/Article/index';

// Vocabulary
import Vocabulary from 'containers/Vocabulary/index';

// Phrasebooks
import Phrasebooks from 'containers/Phrasebooks/index';

// Favorite
import FavoriteGlossaries from 'containers/Favorite/FavoriteGlossaries/index';
import FavoriteLists from 'containers/Favorite/FavoriteLists/index';
import FavoriteWords from 'containers/Favorite/FavoriteWords/index';
import FavoritePhrases from 'containers/Favorite/FavoritePhrases/index';

// Calendar
import RepetitionCalendar from 'containers/RepetitionCalendar/index';

const AuthLayout = ({
  path,
  exact,
  isFooterVisible,
  component: Component,
  ...rest
}) => (
  <Route
    path={path}
    exact={exact}
    render={props => (
      <Fragment>
        <GuestNavbar/>
        <Component {...props} {...rest} />
        <Footer />
      </Fragment>
    )}
  />
);

const NoLayout = ({
  path,
  exact,
  isFooterVisible,
  component: Component,
  ...rest
}) => (
  <Route
    path={path}
    exact={exact}
    render={props => (
      <Fragment>
        <Component {...props} {...rest} />
      </Fragment>
    )}
  />
);

const DefaultLayout = ({
  path,
  exact,
  component: Component,
  ...rest
}) => (
  <Route
    key={Math.random().toString(10)}
    path={path}
    exact={exact}
    render={props => (
      <Fragment>
         {localStorage.getItem('userId') ? <UserNavbar/> : <GuestNavbar/>}
         <Component {...props} {...rest} />
         {
          path !== '/wizard' &&
            <Footer />
         }
      </Fragment>
    )}
  />
);


const TrainingLayout = ({
  path,
  exact,
  component: Component,
  sidebarLeftComponent: SidebarLeftComponent,
  sidebarRightComponent: SidebarRightComponent,
  ...rest
}) => (
  <Route
    key={Math.random().toString(10)}
    path={path}
    exact={exact}
    render={props => (
      <Fragment>
         <Component {...props} {...rest} />
      </Fragment>
    )}
  />
);

const Routes = () => (
  <Switch>
    <AuthLayout path="/ru/login" exact component={Login}/>
    <AuthLayout path="/uk/login" exact component={Login}/>
    <AuthLayout path="/en/login" exact component={Login}/>
    <AuthLayout path="/login" exact component={Login}/>
    
    <AuthLayout path="/register" exact component={Register}/>
    <AuthLayout path="/ru/register" exact component={Register}/>
    <AuthLayout path="/uk/register" exact component={Register}/>
    <AuthLayout path="/en/register" exact component={Register}/>
    
    <AuthLayout path="/ru/forgot" exact component={Forgot}/>
    <AuthLayout path="/uk/forgot" exact component={Forgot}/>
    <AuthLayout path="/en/forgot" exact component={Forgot}/>
    <AuthLayout path="/forgot" exact component={Forgot}/>
    
    <AuthLayout path="/password/reset/:token" exact component={ResetPassword}/>
    <AuthLayout path="/ru/password/reset/:token" exact component={ResetPassword}/>
    <AuthLayout path="/uk/password/reset/:token" exact component={ResetPassword}/>
    <AuthLayout path="/en/password/reset/:token" exact component={ResetPassword}/>
    
    <AuthLayout path="/" exact component={Landing}/>
    <AuthLayout path="/ru" exact component={Landing}/>
    <AuthLayout path="/uk" exact component={Landing}/>
    <AuthLayout path="/en" exact component={Landing}/>

    <DefaultLayout path="/ru/terms" exact component={Terms}/>
    <DefaultLayout path="/uk/terms" exact component={Terms}/>
    <DefaultLayout path="/en/terms" exact component={Terms}/>
    
    <DefaultLayout path="/ru/terms" exact component={Terms}/>
    <DefaultLayout path="/uk/terms" exact component={Terms}/>
    <DefaultLayout path="/en/terms" exact component={Terms}/>
    <DefaultLayout path="/terms" exact component={Terms}/>
    
    <DefaultLayout path="/ru/policy" exact component={Policy}/>
    <DefaultLayout path="/uk/policy" exact component={Policy}/>
    <DefaultLayout path="/en/policy" exact component={Policy}/>
    <DefaultLayout path="/policy" exact component={Policy}/>
    
    <DefaultLayout path="/ru/agreement" exact component={Agreement}/>
    <DefaultLayout path="/uk/agreement" exact component={Agreement}/>
    <DefaultLayout path="/en/agreement" exact component={Agreement}/>
    <DefaultLayout path="/agreement" exact component={Agreement}/>
   
    <DefaultLayout path="/ru/memorization-system" exact component={MemorizationSystem}/>
    <DefaultLayout path="/uk/memorization-system" exact component={MemorizationSystem}/>
    <DefaultLayout path="/en/memorization-system" exact component={MemorizationSystem}/>
    <DefaultLayout path="/memorization-system" exact component={MemorizationSystem}/>
    
    
    <DefaultLayout path="/ru/video-instructions" exact component={VideoInstructions}/>
    <DefaultLayout path="/uk/video-instructions" exact component={VideoInstructions}/>
    <DefaultLayout path="/en/video-instructions" exact component={VideoInstructions}/>
    <DefaultLayout path="/video-instructions" exact component={VideoInstructions}/>
   
    <DefaultLayout path="/ru/articles" exact component={HelperArticles}/>
    <DefaultLayout path="/uk/articles" exact component={HelperArticles}/>
    <DefaultLayout path="/en/articles" exact component={HelperArticles}/>
    <DefaultLayout path="/articles" exact component={HelperArticles}/>
    
    <DefaultLayout path="/ru/article/:slug" exact component={HelperArticle}/>
    <DefaultLayout path="/uk/article/:slug" exact component={HelperArticle}/>
    <DefaultLayout path="/en/article/:slug" exact component={HelperArticle}/>
    <DefaultLayout path="/article/:slug" exact component={HelperArticle}/>

    <DefaultLayout path="/ru/wizard" exact component={Wizard}/>
    <DefaultLayout path="/en/wizard" exact component={Wizard}/>
    <DefaultLayout path="/uk/wizard" exact component={Wizard}/>
    <DefaultLayout path="/wizard" exact component={Wizard}/>

    <DefaultLayout path="/ru/home" exact key={Math.random().toString(10)} component={props => <Home {...props} />}/>
    <DefaultLayout path="/uk/home" exact key={Math.random().toString(10)} component={props => <Home {...props} />}/>
    <DefaultLayout path="/en/home" exact key={Math.random().toString(10)} component={props => <Home {...props} />}/>
    <DefaultLayout path="/home" exact key={Math.random().toString(10)} component={props => <Home {...props} />}/>
    
    <DefaultLayout path="/ru/glossaries" exact component={Glossaries}/>
    <DefaultLayout path="/uk/glossaries" exact component={Glossaries}/>
    <DefaultLayout path="/en/glossaries" exact component={Glossaries}/>
    <DefaultLayout path="/glossaries" exact component={Glossaries}/>

    <DefaultLayout path="/ru/glossary/:id" exact component={GlossaryWords}/>
    <DefaultLayout path="/uk/glossary/:id" exact component={GlossaryWords}/>
    <DefaultLayout path="/en/glossary/:id" exact component={GlossaryWords}/>
    <DefaultLayout path="/glossary/:id" exact component={GlossaryWords}/>

    <DefaultLayout path="/ru/dictionary/:word" key={Math.random().toString(10)} exact component={WordDetails}/>
    <DefaultLayout path="/uk/dictionary/:word" key={Math.random().toString(10)} exact component={WordDetails}/>
    <DefaultLayout path="/en/dictionary/:word" key={Math.random().toString(10)} exact component={WordDetails}/>
    <DefaultLayout path="/dictionary/:word" key={Math.random().toString(10)} exact component={WordDetails}/>
    
    <DefaultLayout path="/ru/dictionary" exact component={Dictionary}/>
    <DefaultLayout path="/uk/dictionary" exact component={Dictionary}/>
    <DefaultLayout path="/en/dictionary" exact component={Dictionary}/>
    <DefaultLayout path="/dictionary" exact component={Dictionary}/>

    <DefaultLayout key={Math.random().toString(10)} path="/ru/lists" exact component={props => <Lists {...props} />}/>
    <DefaultLayout key={Math.random().toString(10)} path="/uk/lists" exact component={props => <Lists {...props} />}/>
    <DefaultLayout key={Math.random().toString(10)} path="/en/lists" exact component={props => <Lists {...props} />}/>
    <DefaultLayout key={Math.random().toString(10)} path="/lists" exact component={props => <Lists {...props} />}/>
    
    <DefaultLayout path="/ru/list/:id" exact component={ListWords}/>
    <DefaultLayout path="/uk/list/:id" exact component={ListWords}/>
    <DefaultLayout path="/en/list/:id" exact component={ListWords}/>
    <DefaultLayout path="/list/:id" exact component={ListWords}/>
    
    <DefaultLayout key={Math.random().toString(10)} path="/ru/courselist/:id" exact component={props => <CourseListWords {...props} />}/>
    <DefaultLayout key={Math.random().toString(10)} path="/uk/courselist/:id" exact component={props => <CourseListWords {...props} />}/>
    <DefaultLayout key={Math.random().toString(10)} path="/en/courselist/:id" exact component={props => <CourseListWords {...props} />}/>
    <DefaultLayout key={Math.random().toString(10)} path="/courselist/:id" exact component={props => <CourseListWords {...props} />}/>
    
    
    <DefaultLayout key={Math.random().toString(10)} path="/ru/course/:slug/vocabulary" exact component={props => <CourseVocabulary {...props} />}/>
    <DefaultLayout key={Math.random().toString(10)} path="/uk/course/:slug/vocabulary" exact component={props => <CourseVocabulary {...props} />}/>
    <DefaultLayout key={Math.random().toString(10)} path="/en/course/:slug/vocabulary" exact component={props => <CourseVocabulary {...props} />}/>
    <DefaultLayout key={Math.random().toString(10)} path="/course/:slug/vocabulary" exact component={props => <CourseVocabulary {...props} />}/>
   
   
    <DefaultLayout key={Math.random().toString(10)} path="/ru/course/:slug/mistakes" exact component={props => <CourseMistakes {...props} />}/>
    <DefaultLayout key={Math.random().toString(10)} path="/uk/course/:slug/mistakes" exact component={props => <CourseMistakes {...props} />}/>
    <DefaultLayout key={Math.random().toString(10)} path="/en/course/:slug/mistakes" exact component={props => <CourseMistakes {...props} />}/>
    <DefaultLayout key={Math.random().toString(10)} path="/course/:slug/mistakes" exact component={props => <CourseMistakes {...props} />}/>
    
    
    <DefaultLayout key={Math.random().toString(10)} path="/ru/course/:slug" exact component={props => <Course1000 {...props} />}/>
    <DefaultLayout key={Math.random().toString(10)} path="/uk/course/:slug" exact component={props => <Course1000 {...props} />}/>
    <DefaultLayout key={Math.random().toString(10)} path="/en/course/:slug" exact component={props => <Course1000 {...props} />}/>
    <DefaultLayout key={Math.random().toString(10)} path="/course/:slug" exact component={props => <Course1000 {...props} />}/>
    
    <DefaultLayout key={Math.random().toString(10)} path="/ru/courses" exact component={props => <Courses {...props} />}/>
    <DefaultLayout key={Math.random().toString(10)} path="/uk/courses" exact component={props => <Courses {...props} />}/>
    <DefaultLayout key={Math.random().toString(10)} path="/en/courses" exact component={props => <Courses {...props} />}/>
    <DefaultLayout key={Math.random().toString(10)} path="/courses" exact component={props => <Courses {...props} />}/>

    <TrainingLayout path="/ru/preview/:id" exact component={props => <Preview {...props} />}/>
    <TrainingLayout path="/uk/preview/:id" exact component={props => <Preview {...props} />}/>
    <TrainingLayout path="/en/preview/:id" exact component={props => <Preview {...props} />}/>
    <TrainingLayout path="/preview/:id" exact component={props => <Preview {...props} />}/>
    
    <TrainingLayout path="/ru/spelling/:id" exact component={props => <CheckSpelling {...props} />}/>
    <TrainingLayout path="/uk/spelling/:id" exact component={props => <CheckSpelling {...props} />}/>
    <TrainingLayout path="/en/spelling/:id" exact component={props => <CheckSpelling {...props} />}/>
    <TrainingLayout path="/spelling/:id" exact component={props => <CheckSpelling {...props} />}/>
    
    <TrainingLayout path="/ru/collect/:id" exact component={props => <CollectWord {...props} />}/>
    <TrainingLayout path="/uk/collect/:id" exact component={props => <CollectWord {...props} />}/>
    <TrainingLayout path="/en/collect/:id" exact component={props => <CollectWord {...props} />}/>
    <TrainingLayout path="/collect/:id" exact component={props => <CollectWord {...props} />}/>
    
    
    <TrainingLayout path="/ru/sentence/:id" exact component={props => <CollectSentence {...props} />}/>
    <TrainingLayout path="/uk/sentence/:id" exact component={props => <CollectSentence {...props} />}/>
    <TrainingLayout path="/en/sentence/:id" exact component={props => <CollectSentence {...props} />}/>
    <TrainingLayout path="/sentence/:id" exact component={props => <CollectSentence {...props} />}/>
    
    <TrainingLayout path="/ru/word/:id" exact component={props => <SelectWord {...props} />}/>
    <TrainingLayout path="/uk/word/:id" exact component={props => <SelectWord {...props} />}/>
    <TrainingLayout path="/en/word/:id" exact component={props => <SelectWord {...props} />}/>
    <TrainingLayout path="/word/:id" exact component={props => <SelectWord {...props} />}/>
    
    <TrainingLayout path="/ru/translation/:id" exact component={props => <SelectTranslation {...props} />}/>
    <TrainingLayout path="/en/translation/:id" exact component={props => <SelectTranslation {...props} />}/>
    <TrainingLayout path="/uk/translation/:id" exact component={props => <SelectTranslation {...props} />}/>
    <TrainingLayout path="/translation/:id" exact component={props => <SelectTranslation {...props} />}/>
    
    <TrainingLayout path="/ru/missing/:id" exact component={props => <MissingLetter {...props} />}/>
    <TrainingLayout path="/uk/missing/:id" exact component={props => <MissingLetter {...props} />}/>
    <TrainingLayout path="/en/missing/:id" exact component={props => <MissingLetter {...props} />}/>
    <TrainingLayout path="/missing/:id" exact component={props => <MissingLetter {...props} />}/>
    
    <TrainingLayout path="/ru/test/:id" exact component={props => <ListTest {...props} />}/>
    <TrainingLayout path="/uk/test/:id" exact component={props => <ListTest {...props} />}/>
    <TrainingLayout path="/en/test/:id" exact component={props => <ListTest {...props} />}/>
    <TrainingLayout path="/test/:id" exact component={props => <ListTest {...props} />}/>
    
    <TrainingLayout path="/ru/listening/:id" exact component={props => <Listening {...props} />}/>
    <TrainingLayout path="/en/listening/:id" exact component={props => <Listening {...props} />}/>
    <TrainingLayout path="/uk/listening/:id" exact component={props => <Listening {...props} />}/>
    <TrainingLayout path="/listening/:id" exact component={props => <Listening {...props} />}/>
    
    <TrainingLayout path="/ru/definition/:id" exact component={props => <WordDefinition {...props} />}/>
    <TrainingLayout path="/uk/definition/:id" exact component={props => <WordDefinition {...props} />}/>
    <TrainingLayout path="/en/definition/:id" exact component={props => <WordDefinition {...props} />}/>
    <TrainingLayout path="/definition/:id" exact component={props => <WordDefinition {...props} />}/>
    
    <TrainingLayout path="/ru/memory/:id" exact component={props => <MemoryCards {...props} />}/>
    <TrainingLayout path="/uk/memory/:id" exact component={props => <MemoryCards {...props} />}/>
    <TrainingLayout path="/en/memory/:id" exact component={props => <MemoryCards {...props} />}/>
    <TrainingLayout path="/memory/:id" exact component={props => <MemoryCards {...props} />}/>
    
    <TrainingLayout path="/ru/translate/:id" exact component={props => <TranslateSentence {...props} />}/>
    <TrainingLayout path="/uk/translate/:id" exact component={props => <TranslateSentence {...props} />}/>
    <TrainingLayout path="/en/translate/:id" exact component={props => <TranslateSentence {...props} />}/>
    <TrainingLayout path="/translate/:id" exact component={props => <TranslateSentence {...props} />}/>
    
    <TrainingLayout path="/ru/image/:id" exact component={props => <DragImage {...props} />}/>
    <TrainingLayout path="/uk/image/:id" exact component={props => <DragImage {...props} />}/>
    <TrainingLayout path="/en/image/:id" exact component={props => <DragImage {...props} />}/>
    <TrainingLayout path="/image/:id" exact component={props => <DragImage {...props} />}/>


    <TrainingLayout path="/ru/hangman" exact component={props => <Hangman {...props} />}/>
    <TrainingLayout path="/uk/hangman" exact component={props => <Hangman {...props} />}/>
    <TrainingLayout path="/en/hangman" exact component={props => <Hangman {...props} />}/>
    <TrainingLayout path="/hangman" exact component={props => <Hangman {...props} />}/>

    <TrainingLayout path="/ru/wordextractor" exact component={props => <WordExtractor {...props} />}/>
    <TrainingLayout path="/uk/wordextractor" exact component={props => <WordExtractor {...props} />}/>
    <TrainingLayout path="/en/wordextractor" exact component={props => <WordExtractor {...props} />}/>
    <TrainingLayout path="/wordextractor" exact component={props => <WordExtractor {...props} />}/>

    <TrainingLayout path="/ru/wordtest" exact component={props => <WordTest {...props} />}/>
    <TrainingLayout path="/uk/wordtest" exact component={props => <WordTest {...props} />}/>
    <TrainingLayout path="/en/wordtest" exact component={props => <WordTest {...props} />}/>
    <TrainingLayout path="/wordtest" exact component={props => <WordTest {...props} />}/>

    <DefaultLayout path="/ru/wordmaker" exact component={props => <WordMaker {...props} />}/>
    <DefaultLayout path="/uk/wordmaker" exact component={props => <WordMaker {...props} />}/>
    <DefaultLayout path="/en/wordmaker" exact component={props => <WordMaker {...props} />}/>
    <DefaultLayout path="/wordmaker" exact component={props => <WordMaker {...props} />}/>

    <DefaultLayout path="/ru/wordmaker/:id" exact component={props => <WordMakerWord {...props} />}/>
    <DefaultLayout path="/uk/wordmaker/:id" exact component={props => <WordMakerWord {...props} />}/>
    <DefaultLayout path="/en/wordmaker/:id" exact component={props => <WordMakerWord {...props} />}/>
    <DefaultLayout path="/wordmaker/:id" exact component={props => <WordMakerWord {...props} />}/>

    <DefaultLayout path="/ru/family" exact component={props => <FamilyAccounts {...props} />}/>
    <DefaultLayout path="/uk/family" exact component={props => <FamilyAccounts {...props} />}/>
    <DefaultLayout path="/en/family" exact component={props => <FamilyAccounts {...props} />}/>
    <DefaultLayout path="/family" exact component={props => <FamilyAccounts {...props} />}/>

    <DefaultLayout path="/ru/wordtestresult" exact component={props => <WordTestResults {...props} />}/>
    <DefaultLayout path="/uk/wordtestresult" exact component={props => <WordTestResults {...props} />}/>
    <DefaultLayout path="/en/wordtestresult" exact component={props => <WordTestResults {...props} />}/>
    <DefaultLayout path="/wordtestresult" exact component={props => <WordTestResults {...props} />}/>

    <DefaultLayout path="/ru/contest-wordmaker/:id/results" exact component={props => <MultiWordMakerGameResults {...props} />}/>
    <DefaultLayout path="/uk/contest-wordmaker/:id/results" exact component={props => <MultiWordMakerGameResults {...props} />}/>
    <DefaultLayout path="/en/contest-wordmaker/:id/results" exact component={props => <MultiWordMakerGameResults {...props} />}/>
    <DefaultLayout path="/contest-wordmaker/:id/results" exact component={props => <MultiWordMakerGameResults {...props} />}/>

    <TrainingLayout path="/ru/contest-wordmaker/:id/waiting" exact component={props => <MultiWordMakerWaiting {...props} />}/>
    <TrainingLayout path="/uk/contest-wordmaker/:id/waiting" exact component={props => <MultiWordMakerWaiting {...props} />}/>
    <TrainingLayout path="/en/contest-wordmaker/:id/waiting" exact component={props => <MultiWordMakerWaiting {...props} />}/>
    <TrainingLayout path="/contest-wordmaker/:id/waiting" exact component={props => <MultiWordMakerWaiting {...props} />}/>

    <TrainingLayout path="/ru/contest-wordmaker/:id" exact component={props => <MultiWordMakerGame {...props} />}/>
    <TrainingLayout path="/uk/contest-wordmaker/:id" exact component={props => <MultiWordMakerGame {...props} />}/>
    <TrainingLayout path="/en/contest-wordmaker/:id" exact component={props => <MultiWordMakerGame {...props} />}/>
    <TrainingLayout path="/contest-wordmaker/:id" exact component={props => <MultiWordMakerGame {...props} />}/>

    <DefaultLayout path="/ru/contest-wordmaker" exact component={props => <MultiWordMakerGamesList {...props} />}/>
    <DefaultLayout path="/uk/contest-wordmaker" exact component={props => <MultiWordMakerGamesList {...props} />}/>
    <DefaultLayout path="/en/contest-wordmaker" exact component={props => <MultiWordMakerGamesList {...props} />}/>
    <DefaultLayout path="/contest-wordmaker" exact component={props => <MultiWordMakerGamesList {...props} />}/>


    <DefaultLayout path="/ru/liqpay" exact component={Liqpay}/>
    <DefaultLayout path="/uk/liqpay" exact component={Liqpay}/>
    <DefaultLayout path="/en/liqpay" exact component={Liqpay}/>
    <DefaultLayout path="/liqpay" exact component={Liqpay}/>

    <DefaultLayout path="/ru/paymentresults" exact component={PaymentResult}/>
    <DefaultLayout path="/uk/paymentresults" exact component={PaymentResult}/>
    <DefaultLayout path="/en/paymentresults" exact component={PaymentResult}/>
    <DefaultLayout path="/paymentresults" exact component={PaymentResult}/>

    <DefaultLayout path="/ru/products" exact component={AllProducts}/>
    <DefaultLayout path="/uk/products" exact component={AllProducts}/>
    <DefaultLayout path="/en/products" exact component={AllProducts}/>
    <DefaultLayout path="/products" exact component={AllProducts}/>

    <DefaultLayout path="/ru/purchases" exact component={Purchases}/>
    <DefaultLayout path="/uk/purchases" exact component={Purchases}/>
    <DefaultLayout path="/en/purchases" exact component={Purchases}/>
    <DefaultLayout path="/purchases" exact component={Purchases}/>

    <DefaultLayout path="/ru/article/:id" exact component={props => <Article {...props} />}/>
    <DefaultLayout path="/uk/article/:id" exact component={props => <Article {...props} />}/>
    <DefaultLayout path="/en/article/:id" exact component={props => <Article {...props} />}/>
    <DefaultLayout path="/article/:id" exact component={props => <Article {...props} />}/>

    <DefaultLayout path="/ru/vocabulary" exact component={props => <Vocabulary {...props} />}/>
    <DefaultLayout path="/uk/vocabulary" exact component={props => <Vocabulary {...props} />}/>
    <DefaultLayout path="/en/vocabulary" exact component={props => <Vocabulary {...props} />}/>
    <DefaultLayout path="/vocabulary" exact component={props => <Vocabulary {...props} />}/>

    <DefaultLayout path="/ru/phrasebooks" exact component={props => <Phrasebooks {...props} />}/>
    <DefaultLayout path="/uk/phrasebooks" exact component={props => <Phrasebooks {...props} />}/>
    <DefaultLayout path="/en/phrasebooks" exact component={props => <Phrasebooks {...props} />}/>
    <DefaultLayout path="/phrasebooks" exact component={props => <Phrasebooks {...props} />}/>

    <DefaultLayout path="/ru/favorite/glossaries" exact component={props => <FavoriteGlossaries {...props} />}/>
    <DefaultLayout path="/en/favorite/glossaries" exact component={props => <FavoriteGlossaries {...props} />}/>
    <DefaultLayout path="/uk/favorite/glossaries" exact component={props => <FavoriteGlossaries {...props} />}/>
    <DefaultLayout path="/favorite/glossaries" exact component={props => <FavoriteGlossaries {...props} />}/>

    <DefaultLayout path="/ru/favorite/lists" exact component={props => <FavoriteLists {...props} />}/>
    <DefaultLayout path="/uk/favorite/lists" exact component={props => <FavoriteLists {...props} />}/>
    <DefaultLayout path="/en/favorite/lists" exact component={props => <FavoriteLists {...props} />}/>
    <DefaultLayout path="/favorite/lists" exact component={props => <FavoriteLists {...props} />}/>

    <DefaultLayout path="/ru/favorite/words" exact component={props => <FavoriteWords {...props} />}/>
    <DefaultLayout path="/uk/favorite/words" exact component={props => <FavoriteWords {...props} />}/>
    <DefaultLayout path="/en/favorite/words" exact component={props => <FavoriteWords {...props} />}/>
    <DefaultLayout path="/favorite/words" exact component={props => <FavoriteWords {...props} />}/>

    <DefaultLayout path="/ru/favorite/phrases" exact component={props => <FavoritePhrases {...props} />}/>
    <DefaultLayout path="/uk/favorite/phrases" exact component={props => <FavoritePhrases {...props} />}/>
    <DefaultLayout path="/en/favorite/phrases" exact component={props => <FavoritePhrases {...props} />}/>
    <DefaultLayout path="/favorite/phrases" exact component={props => <FavoritePhrases {...props} />}/>

    <DefaultLayout path="/ru/calendar" exact component={props => <RepetitionCalendar {...props} />}/>
    <DefaultLayout path="/uk/calendar" exact component={props => <RepetitionCalendar {...props} />}/>
    <DefaultLayout path="/en/calendar" exact component={props => <RepetitionCalendar {...props} />}/>
    <DefaultLayout path="/calendar" exact component={props => <RepetitionCalendar {...props} />}/>

    <DefaultLayout path="/ru/badges" exact component={props => <UserBadges {...props} />}/>
    <DefaultLayout path="/uk/badges" exact component={props => <UserBadges {...props} />}/>
    <DefaultLayout path="/en/badges" exact component={props => <UserBadges {...props} />}/>
    <DefaultLayout path="/badges" exact component={props => <UserBadges {...props} />}/>

    <NoLayout path="/install" exact component={InstaLink}/>

    <NoLayout path="/ru/link/:link" exact component={SharedLink}/>
    <NoLayout path="/uk/link/:link" exact component={SharedLink}/>
    <NoLayout path="/en/link/:link" exact component={SharedLink}/>
    <NoLayout path="/link/:link" exact component={SharedLink}/>

    <Route path="*">
      {localStorage.getItem('userId') ? <UserNavbar/> : <GuestNavbar/>}
      <NoMatch />
      <Footer />
    </Route>
  </Switch>
);

export default Routes;
