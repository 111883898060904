import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';
import {logApiError} from './logSagas';

const API_URL = constants.API_URL;

export function* getCalendarMonthData(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getCalendarMonthDataWeb')
    .query({
      user_id: userId,
      date: data.payload,
    });

    // console.log('getCalendarMonthDataWeb', result.body)

    yield put({
      type: types.SET_CALENDAR_MONTH_DATA,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({payload: {function: 'getCalendarMonthData', payload: data.payload,error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getCalendarDayData(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getCalendarDayData')
    .query({
      user_id: userId,
      date: data.payload,
    });
    
    yield put({
      type: types.SET_CALENDAR_DAY_DATA,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({payload: {function: 'getCalendarDayData', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}
