import request from 'superagent';
import {select, call, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import * as constants from '../../lib/constants';

const API_URL = constants.API_URL;

export function* logActivity(data) {
  const {userId, token} = yield select(state => state.user);
  const {isConnected} = yield select(state => state.settings);
  if (!isConnected) return;

  const params = data.payload;

  const sessionId = localStorage.getItem('sessionId');
  const uniqueToken = localStorage.getItem('uniqueToken');

  request
  .post(API_URL + 'logActivity')
  .send({
    user_id: userId,
    // platform: Platform.OS,
    token,
    device_id: uniqueToken,
    session_id: parseInt(sessionId, 10),
    activity_id: params.activityId,
    model: params.model ? params.model : false,
    model_id: params.modelId ? params.modelId : false,
    comment: params.comment ? params.comment : false,
  }).then(result => {
    // console.log("logActivity", result.body)
    if (result.body.session_id) {
      setSession(result.body.session_id)
    }
  }).catch(e => {
    // console.log(e)
  });
}

export function* logSharedLinkVisit(data) {
  const params = data.payload;

  request
  .post(API_URL + 'logSharedLinkVisit')
  .send({
   link: params.link,
    ip: params.ip,
    store: params.type
  }).then(result => {
    // console.log("logSharedLinkVisit", result.body)
    
  }).catch(e => {
    // console.log(e)
  });
}

export function* logLinksPage(data) {
  request
  .post(API_URL + 'logLinksPage')
  .send({
    ip: data.payload.ip,
    source: data.payload.source,
    browser:  window.navigator.userAgent
  }).then(result => {
    // console.log("logLinksPage", result.body)
  }).catch(e => {
    // console.log(e)
  });
}

const setSession = (sessionId) => {
  localStorage.setItem('sessionId', `${sessionId}`);
};

export function* logListWord(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;
  if(!userId) return;

  const {isConnected} = yield select(state => state.settings);
  if (!isConnected) return;

  try {
    const result = yield request
    .post(API_URL + 'logListWord')
    .send({
      user_id: userId,
      training_id: params.trainingId,
      list_id: params.listId,
      word_id: params.id,
      mistake: params.mistake,
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'logListWord',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* logCourseListWord(data) {
  const {userId} = yield select(state => state.user);
  const {courseData} = yield select(state => state.course);
  if(!userId) return;

  if (!courseData || !courseData.id) return;

  const params = data.payload;

  const {isConnected} = yield select(state => state.settings);
  if (!isConnected) return;

  try {
    const result = yield request
    .post(API_URL + 'logCourseListWord')
    .send({
      user_id: userId,
      training_id: params.trainingId,
      list_id: params.courseListId,
      word_id: params.id,
      mistake: params.mistake,
      course_id: courseData.id,
    });
    // console.log("logCourseListWord", result.body);
    
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'logCourseListWord',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* logScores(data) {
  const {userId} = yield select(state => state.user);
  if(!userId) return;

  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'logScores')
    .send({
      user_id: userId,
      scores: params,
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'logScores',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* logUserList(data) {
  const {userId} = yield select(state => state.user);

  if(!userId) return;
  const params = data.payload;

  const {isConnected} = yield select(state => state.settings);
  if (!isConnected) return;

  try {
    const result = yield request
    .post(API_URL + 'logUserList')
    .send({
      user_id: userId,
      training_id: params.trainingId,
      list_id: params.listId,
      type: params.type,
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'logUserList',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* logCourseList(data) {
  const {userId} = yield select(state => state.user);
  const {courseData} = yield select(state => state.course);
  const translate = yield select(state => getTranslate(state.localize));
  if(!userId) return;

  const params = data.payload;

  const {isConnected} = yield select(state => state.settings);
  if (!isConnected) return;
  if(!courseData || !courseData.id) return null;

  try {
    const result = yield request
    .post(API_URL + 'logCourseList')
    .send({
      user_id: userId,
      training_id: params.trainingId,
      list_id: params.listId,
      course_id: courseData.id,
      type: params.type,
      only_first: true
    });

    // console.log("logCourseList", result .body)

    if (params.trainingId == 11 && result.body.opened == true) {
      toastr.success('', translate('opened_next_step'));
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'logCourseList',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* logPhrase(data) {
  const {userId} = yield select(state => state.user);
  if(!userId) return;

  const params = data.payload;

  const {isConnected} = yield select(state => state.settings);
  if (!isConnected) return;

  try {
    const result = yield request
    .post(API_URL + 'logPhrase')
    .send({
      user_id: userId,
      training_id: params.trainingId,
      phrasebook_id: params.phrasebookId,
      phrase_id: params.id,
      mistake: params.mistake,
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'logPhrase',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* logGlossaryWord(data) {
  const {userId} = yield select(state => state.user);
  if(!userId) return;

  const params = data.payload;

  const {isConnected} = yield select(state => state.settings);
  if (!isConnected) return;

  try {
    const result = yield request
    .post(API_URL + 'logGlossaryWord')
    .send({
      user_id: userId,
      training_id: params.trainingId,
      glossary_id: params.glossaryId,
      word_id: params.id,
      mistake: params.mistake,
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'logGlossaryWord',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* logPurchase(data) {
  const {userId} = yield select(state => state.user);

  const params = data.payload;

  if (!userId || userId === undefined) return;

  const {isConnected} = yield select(state => state.settings);
  if (!isConnected) return;

  try {
    const result = yield request
    .post(API_URL + 'log_purchase')
    .send({
      user_id: userId,
      identifier: params.identifier,
      type: params.type,
      amount: params.amount,
      currency: params.currency,
      platform: 'android',
      expiration_date: params.expirationDate,
      info: params.allInfo
    });

    if (params.identifier != 'word_maker.10_hints') {
      // toastr.info('', translate('purchase_success'));
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'log_purchase',
        payload: params,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* logApiError(data) {
  const {userId} = yield select(state => state.user);
  if(!userId) return;

  const params = data.payload;

  const {isConnected} = yield select(state => state.settings);
  // if(!userId || __DEV__) return;

  try {
    const result = yield request
    .post(API_URL + 'logApiError')
    .send({
      user_id: userId,
      function: params.function,
      error: params.error,
      payload: params.payload,
      connection: isConnected
    });

  } catch (error) {
    console.log(error)
  }
}


