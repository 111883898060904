import * as types from '../actions/types';

const INITIAL_STATE = {
  activeGamesData: {},
  activeGamesArray: [],
  currentActiveGame: {},
  userActiveGame: false,
  activeGamesFetched: false,
  joinedGame: false,
  owner: false,
  currentActiveGameResults: false,
  multiMakerRating: false,
  shouldStartGame: false

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_MULTI_WORD_MAKER_GAMES:{
      
      return Object.assign({}, state, {
        activeGamesData: action.payload.data,
        activeGamesArray: action.payload.data.data,
        userActiveGame: action.payload.isActive,
        activeGamesFetched: true,
        currentActiveGame: action.payload.currentActiveGame,
        joinedGame: action.payload.isActive && !action.payload.owner,
        owner: action.payload.isActive && action.payload.owner,
      });
    }
    
    case types.RESET_MULTI_WORD_MAKER_GAMES:
      return Object.assign({}, state, {
        activeGamesData: {},
        activeGamesArray: [],
        userActiveGame: false,
        joinedGame: false,
        owner: false,
        // currentActiveGame: {},
        activeGamesFetched: false
      });

    case types.SET_CURRENT_ACTIVE_MULTI_WORD_MAKER_GAME:
      return Object.assign({}, state, {
        currentActiveGame: action.payload.data,
        userActiveGame: action.payload.isActive,
        joinedGame: action.payload.isActive && !action.payload.owner,
        owner: action.payload.isActive && action.payload.owner,
      });
     
    case types.RESET_CURRENT_WORD_MAKER_MULTI_GAME:
      return Object.assign({}, state, {
        currentActiveGame: {},
        joinedGame: false,
        owner: false,
        userActiveGame: false,
      });
    
    case types.SET_MULTI_WORD_MAKER_GAME_RESULTS:
      return Object.assign({}, state, {
        currentActiveGameResults: action.payload,
        
      });

    case types.RESET_MULTI_WORD_MAKER_GAME_RESULTS:
      return Object.assign({}, state, {
        currentActiveGameResults: false,

      });
    
    case types.SET_MULTI_WORD_MAKER_GAME_RATING:
      return Object.assign({}, state, {
        multiMakerRating: action.payload,

      });
    
    case types.SHOULD_START_MULTI_MAKER:
      return Object.assign({}, state, {
        shouldStartGame: action.payload,

      });
    
    default:
      return state;
  }
}