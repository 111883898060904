import * as types from './types';

export const getTags = () => (dispatch) => {
  dispatch({ type: types.GET_TAGS});
};

export const resetSelectedTags = () => (dispatch) => {
  dispatch({ type: types.RESET_SELECTED_TAGS});
};

export const getConnectedTags = () => (dispatch) => {
  dispatch({ type: types.GET_CONNECTED_TAGS});
};

export const deleteTag = (tagId) => (dispatch) => {
  dispatch({ type: types.DELETE_TAG, payload:tagId});
};

export const createTag = (tagName, color) => (dispatch) => {
  dispatch({ type: types.CREATE_TAG, payload:{tagName, color,}});
};

export const connectTag = (listId, tag, lists) => (dispatch) => {
  dispatch({ type: types.CONNECT_TAG, payload:{listId, tag, lists}});
};

export const disconnectTag = (listId) => (dispatch) => {
  dispatch({ type: types.DISCONNECT_TAG, payload:listId});
};

export const setSelectedTag = (id) => (dispatch) => {
  dispatch({ type: types.SET_SELECTED_TAG, payload: id});
};

