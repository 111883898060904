import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faCogs,
  faCheckCircle,
  faFilter,
  faTimes,
  faSearch,
  faPlus,
  faSort,
  faBars,
  faCalendarAlt,
  faTrophy,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Container from 'react-bootstrap/Container';

const CourseToolbar = ({
  translate,
  ratio,
  deviceWidth,
  deviceHeight,
  toolbar,
  userData,

  courseData,
  fetched,
  lists,
  hideKnown,

  toVocabulary,
  toMistakes,
  toCalendar,
  toCourseInfo,
  onDeleteCourse,
  clearCache,
  toRateCourse,
  setHideKnown,
  togglePostpone,
  toBadges
}) => (

  <Container fluid className="coursetoolbar">
    <div className={toolbar}>

      <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">

        <div className="d-flex justify-content-start align-items-center">
          {
            courseData && (
              <React.Fragment>
                <a href="#" className="text-secondary p2 mr-2" onClick={toMistakes}>
                  {translate('your_course_mistakes')}
                </a>
                <a href="#" className="text-secondary p2 ml-2" onClick={toVocabulary}>
                  {translate('course_vocabulary')}
                </a>
              </React.Fragment>
            ) || null
          }
        </div>
        
        <div className="d-flex justify-content-end align-items-center">
          <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='top' overlay={<Tooltip>{translate('course_info')}</Tooltip>}>
            <a href="#" onClick={toCourseInfo} className="mr-2 text-secondary p-2">
              <FontAwesomeIcon style={{fontSize: 20}} icon={faInfoCircle}/>
            </a>
          </OverlayTrigger>
          {
            courseData ? (
              <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='top' overlay={<Tooltip>{translate('course_calendar')}</Tooltip>}>
                <a href="#" onClick={toCalendar} className="mr-2 ml-2 p-2">
                  <FontAwesomeIcon style={{fontSize: 20}} icon={faCalendarAlt}/>
                </a>
              </OverlayTrigger>
            ) : null
          }

          {
            courseData ? (
              <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='top' overlay={<Tooltip>{translate('course_badges')}</Tooltip>}>
                <a href="#" onClick={toBadges} className="mr-2 ml-2 p-2">
                  <FontAwesomeIcon style={{fontSize: 20}} icon={faTrophy}/>
                </a>
              </OverlayTrigger>
            ) : null
          }

          {courseData && false ? (
            <Dropdown alignRight className="mr-2">
              <Dropdown.Toggle
                as="a"
                className="p2 toggled-link"
              >
                <FontAwesomeIcon style={{fontSize: 20}} icon={faBars}/>
              </Dropdown.Toggle>

              <Dropdown.Menu>

                <Dropdown.Item as="button" onClick={toMistakes}>
                  {translate('your_course_mistakes')}
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={toVocabulary}>
                  {translate('course_vocabulary')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : null}

          {courseData ? (
            <Dropdown alignRight>
              <Dropdown.Toggle
                as="a"
                className="p2 toggled-link"
              >
                <FontAwesomeIcon style={{fontSize: 20}} icon={faCogs}/>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="button" onClick={toRateCourse}>
                  {translate('review_course')}
                </Dropdown.Item>

                <Dropdown.Divider/>

                {userData ? (
                  <Dropdown.Item as="button" onClick={togglePostpone}>
                    {translate(userData.course_postpone != "1" ? "course_auto_postpone_on" : "course_auto_postpone_off")}
                  </Dropdown.Item>
                ) : null}

                <Dropdown.Item as="button" onClick={setHideKnown}>
                  {translate(userData.show_learnt_course == "0" ? "show_known" : "hide_known")}
                </Dropdown.Item>

                <Dropdown.Divider/>

                <Dropdown.Item className="text-danger" as="button" onClick={clearCache}>
                  {translate('reset_course_cache')}
                </Dropdown.Item>

                <Dropdown.Item className="text-danger" as="button" onClick={()=>onDeleteCourse(true)}>
                  {translate('reset_course')}
                </Dropdown.Item>
              </Dropdown.Menu>

            </Dropdown>
          ) : null}
        </div>
      </div>
    </div>
  </Container>
);

export default CourseToolbar;
