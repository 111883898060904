import React, {Component} from 'react';

import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import colors from '../../../lib/colors';
import * as constants from '../../../lib/constants';
import "./index.scss";

class ChildGlossary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tablet: false
    }
  }

  render() {
    let {glossary,
      ratio,
      onItemPressed, index,
      glossariesLimit,
      applyLimit,

      webSubscriptionStatus,
      cards,
      userData,
      translate,
      deviceWidth
    } = this.props;

    const imageUrl = glossary && glossary.filename ? glossary.filename : false;
    const {tablet} = this.state;
    
    let active = false;

    if (cards || webSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = index < glossariesLimit;
    }

    let imageWidth = 200;

    let glossaryName = '';

    if (tablet) {
      glossaryName = glossary.glossary_name;
    } else {
      glossaryName = glossary.glossary_name;
    }

    return (

      <Card
        className="glossaryCard"
        style={{
        height: !tablet ? 260 : 410,
        width: !tablet ? 200 : 300,
        marginBottom: 20,
        marginLeft: 15,
        marginRight: 15,
        opacity: active ? 1 : 0.3,
        }}
        onClick={() => onItemPressed(glossary, index)}>
        <div>
          <div className="glossaryContainer">
            <p
              className="glossaryName"
              style={{
                height: !tablet ? 55 : 65,
                fontSize: ratio * (!tablet ? 14 : 16),
                 marginTop: !tablet ? 5 : 15,
                 marginBottom: 5
                 }
          }>
              {glossaryName}
            </p>

            {
              imageUrl && (
                <Image
                  alt={glossaryName}
                  draggable="false"
                  onContextMenu={(e)=>e.preventDefault()}
                  fluid
                  className="wordImage"
                  style={{height: 100, width: 100 * 1.38,
                  marginTop: 10, marginBottom: 10}}
                  src={`${constants.S3MarkedImages}-${imageWidth}/${imageUrl}`}/>
              ) || <div
                className="imagePlaceholder"
                style={{height: !tablet ? 110 : 160, width: !tablet ? 110 : 160,marginTop: !tablet ? 0 : 15,marginBottom: !tablet ? 0 : 15}}/>
            }
            <div className="textWrapper">
              <p className="totalWords" style={{fontSize: ratio * (!tablet ? 10 : 14)}}>
                {`${translate('total_words')}: ${glossary.words}`}</p>
              {
                glossary.in_user_lists !== false && (
                  <p className="inLists" style={{fontSize: ratio * (!tablet ? 10 : 14)}}>
                    {`${translate('in_your_lists')}: ${glossary.in_user_lists}`}
                  </p>
                ) || null
              }
              {
                glossary.in_vocabulary !== false && (
                  <p className="inLists" style={{fontSize: ratio * (!tablet ? 10 : 14)}}>
                    {`${translate('words_you_know')}: ${glossary.in_vocabulary}`}
                  </p>
                ) || null
              }
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default ChildGlossary;
