import * as types from '../actions/types';
import moment from 'moment';

const INITIAL_STATE = {
  purchases: [],
  cards: false,
  cardsPurchaseDate: false,
  listCards: false,
  listCardsPurchaseDate: false,
  sync: null,
  syncActive: false,
  subscriptionActive: false,
  listsLimit: 500,
  wordsLimit: 100,
  trainingsLimit: 0,
  glossariesLimit: 200,
  phrasebooksLimit: 200,
  registrationDate: false,
  fullTimeLimit: 14, // was 3
  applyLimit: false,
  todayWordsLimit: 0,
  todayTrainingsLimit: 0,
  todayGamesLimit: 0,
  gamesLimit: 2,
  products: [],
  subscriptions: [],
  webSubscriptionStatus: false,
  webSubscriptionExpireAt: false,
  activeSubscriptionStatus: false,
  activeSubscriptionType: false,
  activeSubscriptionPeriod: false,
  activeSubscriptionPurchasedAt: false,
  activeSubscriptionWillAutoRenew: false,
  activeSubscriptionLinkId: false,
  lifetimeStatus: false,
  lifetimeDate: false,
  purchasesFetched: false,

  gracePeriod: 14,
  graceStatus: false,
  graceDaysLeft: 0,

  happyDay: false,
  happyEndDay: false,
  liqpayPurchase: false,
  liqpayPurchaseFetched: false,

  userPurchases: [],
  fetchedUserPurchases: false,
  
  discountCode: false,
  currencyRates: []
  
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_ACTIVE_SUBSCRIPTION_DATA:
      return Object.assign({}, state, {
        activeSubscriptionStatus: action.payload.status,
        activeSubscriptionType: action.payload.type,
        activeSubscriptionPurchasedAt: action.payload.purchasedAt,
        activeSubscriptionWillAutoRenew: action.payload.autorenew,
        activeSubscriptionPeriod: action.payload.period,
        subscriptionActive: true,
        applyLimit: false
      });

    case types.SET_PURCHASES:
      return Object.assign({}, state, {
        purchases: action.payload,
      });

    case types.SET_PURCHASE_DATA:
      return Object.assign({}, state, {
        liqpayPurchase: action.payload,
        liqpayPurchaseFetched: true,
      });
    
    case types.SET_USER_PURCHASES:
      return Object.assign({}, state, {
        userPurchases: action.payload,
        fetchedUserPurchases: true,

      });
    
    case types.STORE_PURCHASES_FETCHED:
      return Object.assign({}, state, {
        purchasesFetched: action.payload,
      });

    case types.SET_CARDS_PURCHASE:
      return Object.assign({}, state, {
        cards: action.payload,
      }); 
    
    case types.SET_LIFETIME_ACCESS:
      return Object.assign({}, state, {
        lifetimeStatus: action.payload.status,
        // lifetimeStatus: true,
        lifetimeDate: action.payload.date,
      }); 
    
    case types.SET_WORD_LIMIT:
      return Object.assign({}, state, {
        todayWordsLimit: action.payload,
      });

    case types.SET_PRODUCTS:
      return Object.assign({}, state, {
        products: action.payload,
      });

    case types.SET_SUBSCRIPTIONS:
      return Object.assign({}, state, {
        subscriptions: action.payload,
      });
    case types.SET_WEB_SUBSCRIPTION_STATUS:
      return Object.assign({}, state, {
        webSubscriptionStatus: action.payload.result,
        webSubscriptionExpireAt: action.payload.date
      });
    
    case types.SET_TRAINING_LIMIT:
      return Object.assign({}, state, {
        todayTrainingsLimit: action.payload,
      });
    
    case types.SET_GAMES_LIMIT:
      return Object.assign({}, state, {
        todayGamesLimit: action.payload,
      });

    case types.SET_USER_LIMITS:
      return Object.assign({}, state, {
        todayWordsLimit: action.payload && action.payload.words ? action.payload.words : 0,
        todayTrainingsLimit: action.payload && action.payload.trainings ? action.payload.trainings : 0,
        todayGamesLimit: action.payload && action.payload.games ? action.payload.games : 0,
      });

    case types.SET_SYNC_PURCHASE:
      return Object.assign({}, state, {
        sync: action.payload,
      });

    case types.SET_SUBSCRIPTION_STATUS:
      return Object.assign({}, state, {
        subscriptionActive: action.payload,
        applyLimit: false
      });

    case types.SET_SYNCS_STATUS:
      return Object.assign({}, state, {
        syncActive: action.payload,
      });

    case types.SET_CARDS_STATUS:
      return Object.assign({}, state, {
        cards: action.payload,
      }); 
    
    case types.SET_LIST_CARDS_STATUS:
      return Object.assign({}, state, {
        listCards: action.payload,
      }); 
    
    case types.SET_CARDS_PURCHASE_DATA:
      return Object.assign({}, state, {
        cards: action.payload.status,
        cardsPurchaseDate: action.payload.date,
      });
    
    case types.SET_LIST_CARDS_PURCHASE_DATA:
      return Object.assign({}, state, {
        listCards: action.payload.status,
        listCardsPurchaseDate: action.payload.date,
      });
    
    case types.SET_DISCOUNT_CODE:
      return Object.assign({}, state, {
        discountCode: action.payload,
      });
    
    case types.SET_CURRENCY_RATES:
      return Object.assign({}, state, {
        currencyRates: action.payload,
      });
    
    case types.SET_HAPPY_DAY:{
      let today = moment();
      // console.log(today)
      let endDate = moment(action.payload.happyEndDay);
      // console.log(endDate)

      let diff = endDate.diff(today, 'days');

      // console.log("Diff", diff)

      return Object.assign({}, state, {
        happyDay: diff >= 0 && action.payload.data,
        happyEndDay: diff
      });
    }

    case types.SET_REGISTRATION_DATE: {
      let apply = state.applyLimit;
      let graceStatus = false;
      let graceDaysLeft = 0;

      if(action.payload) {
        let today = moment();
        let regDate = moment(action.payload);
        let diff = today.diff(regDate, 'days');

        apply = diff > state.fullTimeLimit;


        graceStatus = diff <= state.gracePeriod;
        graceDaysLeft = state.gracePeriod - diff;

        // console.log("SET_REGISTRATION_DATE",diff, apply, graceStatus, graceDaysLeft)

      }
      // console.log("graceDaysLeft", graceDaysLeft)
      // console.log("Active subscription", state.subscriptionActive)

      if(state.subscriptionActive) {
        apply = false;
      }

      return Object.assign({}, state, {
        registrationDate: action.payload,
        applyLimit: apply,
        graceStatus,
        graceDaysLeft
      });
    }

    default:
      return state;
  }
}