import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import moment from 'moment';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';

import Loader from "../../../components/Custom/Loader/index";

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

import {
  createChildAccount, closePasswordModal, checkPasswordToConnect,
  setAccountConnected
}
  from '../../../actions/accountsActions';
import * as helpers from '../../../lib/validators';

class AddAccount extends Component {

  constructor(props) {
    super(props);

    this.fullName = React.createRef();
    this.email = React.createRef();
    this.password = React.createRef();

    this.state = {
      fullName: "",
      email: "",
      password: "",
      months: false,
      startedAt: false,
      tablet: false

    }
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    this.props.setAccountConnected(false)
  }

  handleChange(field, value) {
    this.setState({[field]: value});
  }

  _handleKeyPress = (type, event) => {
    if (event.key === 'Enter' || event.key == 'Tab') {
      if (type == 'fullName') {
        this.email.current.focus();
      } else if (type == 'email') {
        this._connectAccount();
      } else if (type == 'password') {
        this._checkPassword();
      }
    }
  };

  _connectAccount = () => {
    const {
      webSubscriptionStatus,
      webSubscriptionExpireAt,
      activeSubscriptionStatus,
      activeSubscriptionPurchasedAt,
      activeSubscriptionType,
      lifetimeDate,
      lifetimeStatus
    } = this.props.purchases;
    const {translate} = this.props;

    /*let purchasedAt = moment().format("YYYY-MM-DD");
     let months = false;

     if (activeSubscriptionType) {
     if (activeSubscriptionType == 'mnemonic.subscription.monthly_1.0' || activeSubscriptionType == 'com.kudryaalexey.Mnemonic.fullMontly' || activeSubscriptionType == 'mnemonic.subscription.monthly_no_discount') {
     months = 1;
     } else if (activeSubscriptionType == 'mnemonic.subscription.yearly_1.0' || activeSubscriptionType == 'com.kudryaalexey.Mnemonic.fullYear' || activeSubscriptionType == 'mnemonic.subscription.yearly_full') {
     months = 12;
     }
     purchasedAt = activeSubscriptionPurchasedAt
     }
     */
    /*if (lifetimeStatus) {
     months = 240;
     purchasedAt = lifetimeDate;
     }*/

    let endAt = false;

    if (webSubscriptionStatus) {
      endAt = moment(webSubscriptionExpireAt).format("YYYY-MM-DD");
    } else {
      endAt = moment().add(1, 'month').format("YYYY-MM-DD")
    }

    // endAt = moment(activeSubscriptionPurchasedAt).add(months, 'month').format("YYYY-MM-DD")

    const {fullName, email} = this.state;

    let error = "";

    if (fullName.length === 0) {
      error = 'empty_current_password';
    } else if (email.length == 0) {
      error = "empty_new_password";
    } else if (!helpers.checkEmail(email)) {
      error = 'valid_email';
    }

    if (error) {
      toastr.error('', translate(error));
    } else {
      this.props.fetchingData(true);

      this.props.logActivity({activityId: 175, comment: email});

      this.props.createChildAccount(fullName, email, endAt);
    }
  };

  _checkPassword = () => {
    const {email, password} = this.state;
    this.props.fetchingData(true);

    this.props.checkPasswordToConnect(email, password);
  };

  render() {
    const {fetchingData} = this.props.activity;
    const {translate, onClose, isVisible} = this.props;
    const {userData} = this.props.user;
    const {fontSizeRation} = this.props.settings;
    const {fullName, email, password} = this.state;
    const {needPassword, accountConnected} = this.props.accounts;

    return (
      <Modal
        onShow={() => this.fullName.current.focus()}
        className="addaccount"
        show={isVisible}
        onHide={()=>onClose(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="connect_user_account"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {
            !accountConnected && (
              <React.Fragment>
                <Alert variant="secondary">
                  <span className="d-block font-weight-light font-italic text-center mb-2">
                    {translate(!needPassword ? 'connect_existing_account' : 'enter_password_from_the_account_to_connect')}
                  </span>
                </Alert>

                {!needPassword && (<div className="d-flex flex-column justify-content-center">
                  <Form.Group controlId="full_name">
                    <Form.Label>{translate('full_name')}</Form.Label>

                    <Form.Control
                      ref={this.fullName}
                      tabIndex="1"
                      onKeyPress={(e)=>this._handleKeyPress('fullName', e)}
                      value={fullName}
                      placeholder={translate('full_name')}
                      onChange={(e)=>this.handleChange('fullName',e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label>{translate('email')}</Form.Label>
                    <Form.Control
                      ref={this.email}
                      tabIndex="1"
                      onKeyPress={(e)=>this._handleKeyPress('email', e)}
                      value={email}
                      type="email"
                      placeholder={translate('email')}
                      onChange={(e)=>this.handleChange('email',e.target.value)}
                    />
                  </Form.Group>
                </div>) || (
                  <Form.Group controlId="password">
                    <Form.Label>{translate('password')}</Form.Label>
                    <Form.Control
                      ref={this.password}
                      onKeyPress={(e)=>this._handleKeyPress('password', e)}
                      value={password}
                      autoFocus
                      type="password"
                      placeholder={translate('password')}
                      onChange={(e)=>this.handleChange('password',e.target.value)}
                    />
                  </Form.Group>
                )}

                <Button
                  disabled={fullName.length === 0 || email.length === 0}
                  className="mt-3"
                  variant="outline-success"
                  block
                  onClick={!needPassword ? this._connectAccount : this._checkPassword}
                >
                  <Translate id={!needPassword ? "connect_account" : "confirm"}/>
                </Button>
              </React.Fragment>
            ) || (
              <Alert variant="success">
                {translate('account_was_connected')}
              </Alert>
            )
          }

        </Modal.Body>

        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  purchases: state.purchases,
  translate: getTranslate(state.localize),
  accounts: state.accounts,

});

function bindAction(dispatch) {
  return {
    createChildAccount: (fullName, email, endAt) => dispatch(createChildAccount(fullName, email, endAt)),
    checkPasswordToConnect: (email, password) => dispatch(checkPasswordToConnect(email, password)),
    closePasswordModal: () => dispatch(closePasswordModal()),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    setAccountConnected: (status) => dispatch(setAccountConnected(status)),
  };
}

export default connect(mapStateToProps, bindAction)(AddAccount);
