import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock, faAward} from '@fortawesome/free-solid-svg-icons';

import {toastr} from 'react-redux-toastr';

import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import colors from '../../../lib/colors';

class CourseCell extends Component {

  render() {
    const {
      ratio,
      version,
      index,
      tablet,
      isConnected,
      deviceWidth,
      active,
      course,
      onItemPressed,
      translate,
      userId

    } = this.props;

    return (
      <Card
        className="courseList"
        style={{width: 250, height: 350}}
        onClick={() => onItemPressed(course.name, course.slug)}
      >
        <div className="listWrapper">
          <div className="listContainer p-3">

            <div className="courseListName text-center">
              <p style={{fontSize: (!tablet ? 15 : 17),marginBottom: 5}} className="text-center font-weight-bold">
                {translate(course.title)}
              </p>

              <p className="courseDebt text-center"
                 style={{
                 marginBottom: 0,
                        color: colors.tealish,
                        fontSize: (!tablet ? 13 : 15)}}>
                {active && active.data ? active.data.learnt : 0} / {course.words}
              </p>
            </div>

            <Image
              alt={translate(course.title)}
              className="wordImage"
              style={{marginTop: 10, marginBottom: 20, height: 130}}
              src={course.image}
            />

            <div className="flexRow">
              <p className="scores" style={{fontSize: (!tablet ? 13 : 15)}}>
                {translate('scoresUC')}:
              </p>
              <p className="scoresNum" style={{fontSize: (!tablet ? 13 : 15)}}>
                {active && active.data ? active.data.scores : 0}
              </p>
            </div>

            {
              active && (
                <div className="debtsRow">
                  <p className="courseDebt" style={{
                        color: active && active.mistakes && active.mistakes > 0 ? colors.coral : colors.greyishBrown63,
                        fontSize: (!tablet ? 13 : 15)}}>
                    {translate('course_mistakes')} {active && active.mistakes ? active.mistakes : 0}
                  </p>

                  <p className="courseDebt"
                     style={{
                        color: active && active.tests && active.tests.length > 0 ? colors.coral : colors.greyishBrown63,
                        fontSize: (!tablet ? 13 : 15)}}>
                    {translate('course_tests')} {active && active.tests ? active.tests.length : 0}
                  </p>

                  <p
                    className="courseDebt"
                    style={{
                        color: active && active.repeats && active.repeats.length > 0 ? colors.coral : colors.greyishBrown63,
                        fontSize: (!tablet ? 13 : 15)}}>
                    {translate('course_repeats')} {active && active.repeats ? active.repeats.length : 0}
                  </p>
                </div>
              ) || userId ? <div style={{height: !tablet ? 73 : 83}}/> : null
            }

            <Button
              className="mt-2"
              variant="outline-success"
              onClick={() => onItemPressed(course.name, course.slug)}
            >
              {translate(!active ? 'start_course' : 'continue_course') }
            </Button>

          </div>
        </div>
      </Card>
    );
  }
}

export default CourseCell;
