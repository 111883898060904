import * as types from '../actions/types';

const INITIAL_STATE = {
  userContacts: [],
  fetchedContacts: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_USER_CONTACTS:
      return Object.assign({}, state, {
        userContacts: action.payload
      });

    case types.FETCHED_USER_CONTACTS:
      return Object.assign({}, state, {
        fetchedContacts: action.payload
      });

    default:
      return state;
  }
}