import React, {Component} from 'react';
import {connect} from "react-redux";
import {Helmet} from "react-helmet";

import {Translate, getTranslate} from 'react-localize-redux';

import {Link} from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faPlusCircle, faTimes} from '@fortawesome/free-solid-svg-icons';

import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import _ from 'lodash';
import moment from 'moment';

import TrainingSubHeader from '../../../components/Headers/TrainingSubHeader/index';
import FinishTrainingButtons from '../../../components/Modals/FinishTrainingButtons/index';
import WordSettings from '../../../components/Modals/WordSettings/index';
import WordInfo from '../../../components/Modals/WordInfo/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
import ChangeAudioLanguage from '../../../components/Modals/ChangeAudioLanguage/index';
import ReportBug from '../../../components/Modals/ReportBug/index';
import TrainingSettings from '../../../components/Modals/TrainingSettings/index';
import RegisterAlert from '../../../components/Modals/RegisterAlert/index';

import Loader from "../../../components/Custom/Loader/index";

import {
  getWordsForTraining,
  resetWords,
  setSpellingSettings,
  setCachedCheckSpellingWords,

  moveNewListToRepeat,
  removeListFromRepeatSection,
  updateListTrainings,
  updateTrainingsListFromRepeatSection,
  resetCurrentTrainingId,
  setCurrentTrainingId,
  shuffleTrainingWords,
  toggleShuffleWords
} from '../../../actions/trainingActions';
import {
  logListWord,
  logUserList,
  logGlossaryWord,
  logPhrase,
  logCourseList,
  logCourseListWord,
  logActivity
} from '../../../actions/logActions';
import {
  updateCachedListTrainings,
  updateCachedListTrainingsAndIntervals,
  shuffleListTrainingWords
} from '../../../actions/cacheActions';
import {getListsToRepeat, toggleListTrainingButtonsModal, getOnlyMistakes} from '../../../actions/lists';
import {setFirstPlayed, get_word_details} from '../../../actions/wordActions';

import {fetchingData} from '../../../actions/activity';
import {
  toggleListTrainingButtonsModalCourse,
  getUpdatedCourseLists,
  getUpdatedCourseData,
  getUpdatedCurrentListScores,
  shuffleCourseListTrainingWords,
  getOnlyMistakesCourse
} from '../../../actions/courseActions';
import {logTrainingLimits} from '../../../actions/purchasesActions';

import {checkForCachedWords, checkForCourseCachedWords} from '../../../lib/trainingFunctions';

import colors from '../../../lib/colors';
import * as constants from '../../../lib/constants';

class CheckSpelling extends Component {

  constructor(props) {
    super(props);

    this.wordInput = null;

    this.state = {
      currentIndex: 0,
      translationsHeight: 40,
      currentPage: 1,
      totalRepeated: 1,
      downloads: 0,
      totalWords: 0,
      currentChunk: 0,
      scores: 0,
      score: 20,
      finishButtonsModalVisible: false,
      audioModalVisible: false,
      trainingId: 5,
      showImage: true,
      selectedIndex: null,
      answered: false,
      correct: null,
      typed: "",
      phrasebook: false,
      trainingInfoVisible: false,
      bugModalVisible: false,
      trainingSettingsModalVisible: false,
      wordInfoVisible: false,
      tour: localStorage.getItem('tour'),

      trainingName: 'Check Spelling',
      autoslide: "1",
      autoslide_delay: "2",
      autosound_word: "1",
      image_or_word: "image",
      playedFirst: false,
      mistakes: 0,
      withMistakes: [],
      trainingResults: false,
      notTouched: false,
      wordSettingsModalVisible: false,
      freeze: false,
      finishedTraining: false,
      subscriptionMessage: 'subscription_trainings',
      subscriptionAlertVisible: false,
      shuffle: true,
      exiting: false,
      registerModalVisible: false,

      tablet: false,
      scrolled: 0,

      entityId: this.props.match.params.id,
      listId: undefined,
      courseListId: undefined,
      from: false,
      type: false,
      inside: false,

    };

    this.track = null;
    this.timeOut = null;
    this.timeOut1 = null;
    this.timeOut2 = null;
    this.timeOut3 = null;
    this.delayPlayWord = _.debounce(this._play, 500);
  }

  componentDidMount() {
    const {image_or_word_spelling} = this.props.data;

    this.setState({
      showImage: image_or_word_spelling === 'image'
    });

    this._setTrainingSettings();

    document.addEventListener("keydown", this._handleKeyDown);

    this.props.setCurrentTrainingId(5);

    if (!this.props.history.location || !this.props.history.location.state || !this.props.history.location.state.type) {
      this.props.history.goBack();
    } else {
      this.setState({
        [this.props.history.location.state.type]: parseInt(this.props.match.params.id, 10),
        from: this.props.history.location.state.from,
        type: this.props.history.location.state.type,
        inside: this.props.history.location.state.inside,
      })
    }

    this.timeOut = setTimeout(()=> {
      this.getWords();
    }, 100);
  }

  componentDidUpdate(prevProps, prevState) {
    const {words} = this.props.data;

    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      if (words.length == 0) {
        this.getWords();
      }
    }
  }

  componentWillUnmount() {
    const {listId, courseListId} = this.state;
    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut1);
    clearTimeout(this.timeOut2);
    clearTimeout(this.timeOut3);
    
    this.props.resetCurrentTrainingId();
    this.props.resetWords();

    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    if (listId != undefined) {
      this.onUnmountUpdates();
    }

    if (courseListId != undefined) {
      this.onUnmountCourseUpdates();
    }

    this.props.setFirstPlayed(false);

    document.removeEventListener("keydown", this._handleKeyDown);
  }

  _handleKeyDown = (event) => {
    const {freeze, typed} = this.state;

    switch (event.keyCode) {
      case 39:
        if (!freeze && typed.length == 0) {
          this.changeWord('left')
        }
        break;
      case 37:
        if (!freeze && typed.length == 0) {
          this.changeWord('right')
        }
        break;

      case 27:
        this.props.history.goBack();
        break;
      default:
        break;
    }
  };

  onUnmountCourseUpdates = () => {
    const {withMistakes, finishedTraining} = this.state;
    const {inside, courseListId} = this.state;

    if (inside == undefined) {
      this.props.fetchingData(true);
    }

    this._shuffleCourseWords();

    if (finishedTraining || withMistakes.length > 0 || courseListId == 0) {
      this.props.getUpdatedCourseLists();
    } else {
      this.props.getUpdatedCourseData();
    }

    if (courseListId != 0) {
      this.props.getUpdatedCurrentListScores(courseListId);
    }
  };

  _shuffleWords = () => {
    const {trainingId} = this.state;
    const {currentList} = this.props.lists;
    const {userData: {shuffle}} = this.props.user;

    if (currentList && (currentList.id > 0 || currentList.id == -1) && shuffle == "1") {
      this.props.shuffleListTrainingWords(trainingId, currentList.id)
    }
  };

  _shuffleCourseWords = () => {
    const {trainingId} = this.state;
    const {courseListId} = this.state;
    const {userData: {shuffle}} = this.props.user;

    if (courseListId && (courseListId > 0 || courseListId == -1) && shuffle == "1") {
      this.props.shuffleCourseListTrainingWords(trainingId, courseListId)
    }
  };

  onUnmountUpdates = () => {
    const {withMistakes, finishedTraining} = this.state;
    const {currentList} = this.props.lists;
    const {userId} = this.props.user;

    this._shuffleWords();

    if (finishedTraining) {
      this.moveLists();
    }

    if (currentList && currentList.id == 0) {
      this.props.getOnlyMistakes();
    }

    if (currentList && currentList.id > 0 && withMistakes.length > 0) {
      this.props.getOnlyMistakes();
    }
  };

  moveLists = () => {
    const {currentList} = this.props.lists;

    let today = moment().format("YYYY-MM-DD");
    let todayTrainings = null;

    currentList.intervals && currentList.intervals.forEach(interval => {
      if (interval.repetition_date == today) {
        todayTrainings = interval.trainings;
      }
    });

    if (currentList && currentList.id > 0) {
      if (currentList.intervals && currentList.intervals.length != 0 && todayTrainings == 0) {
        this.props.updateTrainingsListFromRepeatSection(currentList.id);
      } else if (todayTrainings == 0) {
        this.props.moveNewListToRepeat(currentList);
      } else if (todayTrainings == 1) {
        this.props.updateTrainingsListFromRepeatSection(currentList.id);
      } else if (todayTrainings >= 2) {
        this.props.removeListFromRepeatSection(currentList.id);
        this.props.updateCachedListTrainings(currentList.id);
      } else if (todayTrainings == null) {
        this.props.moveNewListToRepeat(currentList);
        this.props.updateCachedListTrainingsAndIntervals(currentList);
      }
    }
  };

  _logOnExit = () => {
    const {words} = this.props.data;
    const {logUserList, logCourseList} = this.props;
    const {totalRepeated, trainingId} = this.state;
    const {listId, courseListId} = this.state;

    const percentage = totalRepeated / (words.length != 0 ? words.length : 20);

    this.setState({
      exiting: true
    });

    if (percentage >= 0.85 && listId && listId > 0) {
      this.setState({
        finishedTraining: true
      });

      logUserList({trainingId, listId, type: "end"})
    }

    if (percentage >= 0.85 && courseListId && courseListId != 0) {
      this.setState({
        finishedTraining: true
      });

      logCourseList({trainingId, listId: courseListId, type: "end"});
    }
  };

  _setTrainingSettings = async() => {
    const {userId} = this.props.user;
    const {trainingId} = this.state;

    const autoslide = localStorage.getItem(`autoslide_${trainingId}_${userId}`);
    const autoslide_delay = localStorage.getItem(`autoslide_delay_${trainingId}_${userId}`);
    const autosound_word = localStorage.getItem(`autosound_word_${trainingId}_${userId}`);
    const image_or_word = localStorage.getItem(`image_or_word_${trainingId}_${userId}`);

    if (autoslide != null) {
      this.setState({autoslide})
    } else {
      localStorage.setItem(`autoslide_${trainingId}_${userId}`, '1');
    }

    if (autoslide_delay != null) {
      this.setState({autoslide_delay})
    } else {
      localStorage.setItem(`autoslide_delay_${trainingId}_${userId}`, '2');
    }

    if (autosound_word != null) {
      this.setState({autosound_word})
    } else {
      localStorage.setItem(`autosound_word_${trainingId}_${userId}`, '1');
    }

    if (image_or_word != null) {
      this.setState({image_or_word})
    } else {
      localStorage.setItem(`image_or_word_${trainingId}_${userId}`, 'image');
    }

    this.props.setSpellingSettings({
      autoslide: autoslide != null ? autoslide : "1",
      autoslide_delay: autoslide_delay != null ? autoslide_delay : "2",
      autosound_word: autosound_word != null ? autosound_word : "1",
      image_or_word: image_or_word != null ? image_or_word : "image"
    })
  };

  changeWord = (direction) => {
    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    const {totalWords, autosound_word_spelling}
      = this.props.data;
    const {currentIndex} = this.state;
    var index = currentIndex;
    const {listId, courseListId} = this.state;
    const {userData} = this.props.user;

    let last = false;

    if (direction == "left") {
      this.checkIfNeedMore();

      if (currentIndex == totalWords - 1) {
        last = true;

        if ((listId != undefined && listId == 0) || (courseListId != undefined && courseListId == 0)) {
          this.props.history.goBack();
        } else {
          let active = this._checkTrainingsLimit();

          if (active) {
            if(userData && userData.active_trainings_set) {
              this.setState({
                finishedTraining: true
              });
              this._goToNextTraining()
            } else {
              this.setState({
                finishButtonsModalVisible: true
              })
            }
          } else {
            this._toggleSubscriptionAlert(true);
          }
        }
      } else {
        index = currentIndex + 1;

        this.setState({
          totalRepeated: this.state.totalRepeated + 1
        })
      }
    } else {
      if (currentIndex == 0) {
        index = totalWords - 1
      } else {
        index = currentIndex - 1
      }
    }

    this.setState({
      // showImage: image_or_word_spelling === 'image',
      currentIndex: index,
      selectedIndex: null,
      answered: false,
      typed: '',
      correct: null,
      freeze: false
    });

    if (!last) {
      if (this.wordInput && this.wordInput != null) {
        this.wordInput.focus();
      }

      if (autosound_word_spelling == "1") {
        this.delayPlayWord(index);
      }
    }
  };

  _goToNextTraining = () => {
    const {logUserList, logCourseList} = this.props;

    const {listId, courseListId, type, trainingId} = this.state;
    const {userData} = this.props.user;
    const {locale} = this.props.settings;

    if (listId && listId > 0) {
      logUserList({trainingId, listId, type: "end"})
    } else if (courseListId && courseListId != 0) {
      logCourseList({trainingId, listId: courseListId, type: "end"});
    }

    let currentListId = null;

    if (listId != undefined) {
      currentListId = listId;
    }

    if (courseListId != undefined) {
      currentListId = courseListId;
    }

    let trainingIds = userData.trainings_set != null ? userData.trainings_set.split(',').map(Number) : [];

    const trainingIndex = trainingIds.length > 0 ? trainingIds.indexOf(trainingId) : null;

    if(trainingIndex === null || trainingIndex == (trainingIds.length - 1)) {
      this.setState({
        finishButtonsModalVisible: true
      });
      return;
    }

    if(currentListId != 0) {
      let activityId = '';
      let path = '';

      if (trainingIds[trainingIndex+1] == 1) {
        activityId = 39;
        path = 'preview'
      } else if (trainingIds[trainingIndex+1] == 6) {
        path = 'image';
        activityId = 40;
      } else if (trainingIds[trainingIndex+1] == 2) {
        path = 'translation';
        activityId = 41;
      } else if (trainingIds[trainingIndex+1] == 4) {
        path = 'word';
        activityId = 42;
      } else if (trainingIds[trainingIndex+1] == 3) {
        path = 'collect';
        activityId = 43;
      } else if (trainingIds[trainingIndex+1] == 5) {
        path = 'spelling';
        activityId = 44;
      } else if (trainingIds[trainingIndex+1] == 8) {
        activityId = 114;
        path = 'sentence';
      } else if (trainingIds[trainingIndex+1] == 9) {
        activityId = 131;
        path = 'memory'
      } else if (trainingIds[trainingIndex+1] == 10) {
        path = 'definition';
        activityId = 148;
      } else if (trainingIds[trainingIndex+1] == 7) {
        activityId = 95;
      } else if (trainingIds[trainingIndex+1] == 12) {
        path = 'missing';
        activityId = 315;
      } else if (trainingIds[trainingIndex+1] == 14) {
        path = 'listening';
        activityId = 345;
      } else if (trainingIds[trainingIndex+1] == 15) {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut4 = setTimeout(()=> {
        this.props.history.replace(`/${locale}/${path}/${currentListId}`, {
          type,
          from: 'inside',
          listId: currentListId
        });
      }, 100);

      this.props.logActivity({activityId, model: 'list', modelId: currentListId, comment: 'from home'});

    } else {
      this.setState({
        finishButtonsModalVisible: true
      })
    }
  };

  _play = (index) => {
    const {autosound_word} = this.state;
    const {words} = this.props.data;
    const {firstPlayed} = this.props;
    const {userData} = this.props.user;
    let audioSource = constants.BACKEND_URL;

    let word = words.length > 0 ? words[index] : false;

    if (word && word.audio) {
      let audio = word.audio;

      if (userData && userData.audio_language && word[userData.audio_language]) {
        audio = word[userData.audio_language];
        audioSource = constants.S3;
      }

      if (autosound_word == '1') {
        if (!firstPlayed) {
          this.props.setFirstPlayed(true)
        }

        if (this.track != null) {
          this.track.pause();
          this.track = null;
        }

        this.track = new Audio(`${audioSource}${audio}`);
        if(this.track != null) {
          this.track.play();
        }
      }
    }
  };

  playSound = () => {
    const {words} = this.props.data;
    const {userData} = this.props.user;
    let audioSource = constants.BACKEND_URL;

    if (words.length > 0 && words.length > this.state.currentIndex) {
      const word = words[this.state.currentIndex];

      this.setState({
        playedFirst: true
      });
      if (word && word.audio) {
        let audio = word.audio;

        if (userData && userData.audio_language && word[userData.audio_language]) {
          audio = word[userData.audio_language];
          audioSource = constants.S3;
        }

        if (this.track != null) {
          this.track.pause();
          this.track = null;
        }

        this.track = new Audio(`${audioSource}${audio}`);
        if(this.track != null) {
          this.track.play();
        }
      }
    }
  };

  toSettings = () => {
    this._toggleTrainingSettingsModal(true);
    this.props.logActivity({activityId: 189, comment: 'from check spelling'});
  };

  checkIfNeedMore = () => {
    const {currentIndex, currentPage, downloads} = this.state;
    const {totalWords, words} = this.props.data;

    if (currentIndex + 3 == words.length && words.length < totalWords) {
      this.setState({
        currentPage: currentPage + 1,
        download: downloads + 1
      });

      this.getWords();
    }
  };

  getWords = () => {
    const {checkSpellingCachedWords} = this.props.cache;
    const {checkSpellingCourseCachedWords} = this.props.courseCache;
    const {isConnected} = this.props.settings;

    const {currentPage, trainingId} = this.state;
    const {logUserList, logCourseList} = this.props;

    let params = {
      trainingId,
      page: currentPage,
      history: this.props.history
    };

    const {listId, courseListId, glossaryId, phrasebookId, from, type} = this.state;

    if (listId !== undefined && listId >= -2) {
      params.listId = listId;
      params.endpoint = 'getTrainingWords'
    } else if (courseListId !== undefined && courseListId !== false) {
      params.courseListId = courseListId;
      params.endpoint = 'getCourseTrainingWords'
    } else if (glossaryId !== undefined && glossaryId) {
      params.glossaryId = glossaryId;
      params.endpoint = 'getGlossaryWordsForTraining'
    } else if (phrasebookId !== undefined && phrasebookId) {
      params.phrasebookId = phrasebookId;
      params.endpoint = 'getPhrasebookForTrainingO';

      this.setState({
        phrasebook: true
      });
    }

    let cachedWords = false;

    if (listId) {
      cachedWords = checkForCachedWords(checkSpellingCachedWords, listId, currentPage);
    } else if (courseListId) {
      cachedWords = checkForCourseCachedWords(checkSpellingCourseCachedWords, courseListId, currentPage);
    }

    if (!cachedWords) {
      if (isConnected) {
        // if(currentPage == 1) {
        this.props.fetchingData(true);
        // }
        this.props.getWordsForTraining(params);
      }
    } else {
      this.props.setCachedCheckSpellingWords(cachedWords);
      this.props.logTrainingLimits();

      if (currentPage == 1) {
        if (listId && listId > 0) {
          logUserList({trainingId, listId, type: "start"})
        }

        if (courseListId && courseListId > 0) {
          logCourseList({trainingId, listId: courseListId, type: "start"});
        }
      }
    }
  };

  closeFinishButtonsModal = () => {
    this.setState({
      finishButtonsModalVisible: false
    });
  };

  continueTraining = () => {
    const {logUserList, logCourseList} = this.props;
    const {trainingId, exiting} = this.state;
    const {listId, courseListId} = this.state;

    const {userData: {shuffle}} = this.props.user;

    this.moveLists();

    if (listId && (listId > 0 || listId == -1)) {
      if (shuffle == "1") {
        this.props.shuffleTrainingWords();
      }

      if (listId && listId > 0) {
        logUserList({trainingId, listId, type: "end"});

        if (this.state.exiting) return;

        this.timeOut1 = setTimeout(() => {
          if (exiting)return;
          logUserList({trainingId, listId, type: "start"})
        }, 1000);
      }
    }

    if (courseListId && (courseListId > 0 || courseListId == -1)) {
      if (shuffle == "1") {
        this.props.shuffleTrainingWords();
      }

      if (courseListId && courseListId > 0) {
        logCourseList({trainingId, listId: courseListId, type: "end"});

        if (this.state.exiting) return;

        this.timeOut2 =setTimeout(()=> {
          if (exiting)return;

          logCourseList({trainingId, listId: courseListId, type: "start"});
        }, 1000);
      }
    }

    this.setState({
      finishButtonsModalVisible: false,
      currentIndex: 0,
      totalRepeated: 1,
    });

    this.props.logTrainingLimits();

    this.props.logActivity({activityId: 90});
  };

  _checkTrainingsLimit = () => {
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;

    const {userData} = this.props.user;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    let active = false;

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    return active;
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    });

    if (!status) {
      this.props.history.goBack();
    }
  };

  _toSubscription = (productId) => {
    this.setState({
      subscriptionAlertVisible: false
    });
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId}); 
  };

  anotherTraining = () => {
    const {logUserList, logCourseList} = this.props;
    const {trainingId} = this.state;
    const {listId, courseListId, from, type} = this.state;


    if (listId !== undefined && from == 'listWords') {
      this.props.toggleListTrainingButtonsModal(true)
    }

    if (courseListId !== undefined && from == 'listWords') {
      this.props.toggleListTrainingButtonsModalCourse(true)
    }

    if (listId && listId > 0) {
      logUserList({trainingId, listId, type: "end"})
    } else if (courseListId && courseListId != 0) {
      logCourseList({trainingId, listId: courseListId, type: "end"});
    }

    this.setState({
      finishButtonsModalVisible: false,
      finishedTraining: true
    });

    this.props.history.goBack();

    this.props.logActivity({activityId: 190, comment: 'Check Spelling'});
  };
  
  changeWhatToDisplay = (e) => {
    e.preventDefault();

    this.setState({
      showImage: !this.state.showImage
    })
  };

  checkAnswer = (word) => {
    const {typed, autoslide, autoslide_delay, scores, score, exiting} = this.state;
    const {
      autoslide_spelling,
      autoslide_delay_spelling, autosound_word_spelling, image_or_word_spelling
    }
      = this.props.data;

    if (!word) return;

    this.setState({
      answered: true
    });
    
    if (autosound_word_spelling == "1") {
      this.playSound();
    }

    let mistake = 1;

    if (word.word.toLowerCase() == typed.trim().toLowerCase()) {
      mistake = 0;

      this.setState({
        scores: scores + score,
        correct: true
      });
    } else {
      let withMistakes = this.state.withMistakes;
      let found = withMistakes.indexOf(word);

      if (found === -1) {
        withMistakes.push(word);
      }

      this.setState({
        mistakes: this.state.mistakes + 1,
        withMistakes,
        correct: false
      });
    }

    this._log(mistake);

    this.checkIfNeedMore();

    if (autoslide_spelling == '1') {
      this.setState({
        freeze: true
      });

      if (this.state.exiting) return;

      this.timeOut3 = setTimeout(() => {
        if (exiting)return;

        this.changeWord("left")
      }, parseFloat(autoslide_delay_spelling) * 1000)
    }
  };

  _log = (mistake) => {
    const {words} = this.props.data;
    const {currentIndex} = this.state;
    let word = words.length > 0 ? words[currentIndex] : false;

    const {trainingId} = this.state;
    const {listId, courseListId, glossaryId} = this.state;

    if (word) {
      let params = {
        trainingId: trainingId,
        mistake,
        id: word.id
      };

      if (listId !== undefined && listId >= -1) {
        params.listId = listId;
        this.props.logListWord(params);
      } else if (courseListId !== undefined && courseListId >= -1) {
        params.courseListId = courseListId;
        this.props.logCourseListWord(params);
      } else if (glossaryId !== undefined && glossaryId) {
        params.glossaryId = glossaryId;
        this.props.logGlossaryWord(params);
      }
    }
  };

  toggleInfoModal = (status) => {
    this.setState({
      trainingInfoVisible: status
    })
  };

  _toggleAudioModal = (status) => {
    this.setState({
      audioModalVisible: status
    });
  };

  _toResultsFromModal = () => {
    const {withMistakes, scores} = this.state;
    this.setState({
      finishButtonsModalVisible: false
    });
    // this.props.navigation.navigate('TrainingResults', {withMistakes, scores});
  };

  _openTrainingInfo = () => {
    this.toggleWordSettingsModal(false);
    this.toggleInfoModal(true);
  };

  _reportTrainingBug = () => {
    this.toggleWordSettingsModal(false);

    const {words, totalWords} = this.props.data;
    const {currentIndex} = this.state;
    const {listId, courseListId} = this.state;

    let word = words.length > 0 ? words[currentIndex] : false;
    let extra = {};

    extra.word = word && word.word ? word.word : "";
    extra.translation = word && word.main_translation && word.main_translation.translation ? word.main_translation.translation : "";

    extra.listId = listId;
    extra.courseListId = courseListId;

    this.setState({
      extra
    });

    this._toggleBugModal(true);
    this.props.logActivity({activityId: 234, comment: "Check Spelling"});
  };

  _openTrainingSettings = () => {
    this.toggleInfoModal(false);
    this.toggleWordSettingsModal(false);

    if(!this.state.tour) {
      this.toSettings();
    } else {
      this._toggleRegisterModal(true)
    }
  };

  _toWordDetails = () => {
    this.toggleWordSettingsModal(false);
    const {words} = this.props.data;
    const {currentIndex} = this.state;

    let word = words.length > 0 ? words[currentIndex] : false;

    if (!word) return;
    this.props.get_word_details(word.id);

    this._toggleWordInfo(true);

    this.props.logActivity({activityId: 45, model: 'word', modelId: word.id, comment: "from Check Spelling"});

    // this.props.history.push(`/dictionary/${word.word}`, {word});
  };

  _changeVoice = () => {
    this.toggleWordSettingsModal(false);

    this.props.logActivity({activityId: 332});

    this._toggleAudioModal(true);
  };

  toggleWordSettingsModal = (status) => {
    this.toggleInfoModal(false);

    this.setState({
      wordSettingsModalVisible: status
    })
  };

  _goBack = () => {
    this.setState({
      finishButtonsModalVisible: false
    });

    this._logOnExit();

    this.props.history.goBack();

    this.props.logActivity({activityId: 306, comment: 'Check Spelling'});
  };

  _toggleShuffle = () => {
    const {userData} = this.props.user;

    this.toggleWordSettingsModal(false);
    
    if(!this.state.tour) {
      this.props.fetchingData(true);

      this.props.toggleShuffleWords();
      this.props.logActivity({activityId: 333, comment: userData && userData.shuffle == 1 ? "on" : "off"});
    } else {
      this._toggleRegisterModal(true)
    }
  };

  _typeWord = (typed) => {
    this.setState({typed});
  };

  _clearTyped = () => {
    this.setState({typed: ''});
  };

  _handleKeyPress = (word, event) => {
    if (event.key === 'Enter') {
      this.checkAnswer(word);
    }
  };

  _toggleBugModal = (status) => {
    this.setState({
      bugModalVisible: status
    });
  };

  _toggleTrainingSettingsModal = (status) => {
    this.setState({
      trainingSettingsModalVisible: status
    });
  };

  _toggleWordInfo = (status) => {
    this.setState({
      wordInfoVisible: status
    })
  };


  _toggleRegisterModal = (status, e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      registerModalVisible: status
    })
  };

  _toRegister = () => {
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/register`)
  };

  render() {
    const {words, totalWords} = this.props.data;
    const {
      currentIndex, trainingId, totalRepeated, scores, correct, typed, answered,
      finishButtonsModalVisible, withMistakes, touched, autosound_word, phrasebook,
      trainingInfoVisible, image_or_word, showImage, mistakes, wordSettingsModalVisible,
      tablet,
      freeze,
      subscriptionAlertVisible,
      subscriptionMessage,
      trainingName,
      audioModalVisible,
      bugModalVisible, 
      extra,
      trainingSettingsModalVisible,
      wordInfoVisible,
      registerModalVisible
    } = this.state;
    const {listId, courseListId, glossaryId, phrasebookId, from, type} = this.state;

    const {firstPlayed} = this.props;
    const {userData, userId} = this.props.user;
    const {fetchingData} = this.props.activity;
    const {fontSizeRation, version, locale, deviceHeight, deviceWidth} = this.props.settings;
    const {currentList} = this.props.lists;
    const {course, translate} = this.props;

    let subtitle = "";

    if (currentList && currentList.list_name && totalWords) {
      subtitle = `${currentList.list_name}: ${totalWords}`
    } else if (course && course.currentList && course.currentList.list_name) {
      subtitle = `${course.currentList.list_name}: ${totalWords}`
    }

    if (listId == -1 || courseListId == -1) {
      subtitle = translate('vocabulary')
    }

    let word = words.length > 0 ? words[currentIndex] : false;

    const progress = totalWords !== 0 ? ((currentIndex + 1) / totalWords) : 0;

    let shouldShowImage = showImage;

    /*if (currentIndex === 0 && shouldShowImage) {
      shouldShowImage = image_or_word_spelling === 'image'
    }*/

    let inputStyles = {
      color: colors.greyishBrown63,
      backgroundColor: colors.lightGrey,
      fontSize: 16 * fontSizeRation,
    };

    if (answered && correct !== null) {
      if (correct) {
        inputStyles.color = colors.white;
        inputStyles.backgroundColor = colors.pine
      } else {
        inputStyles.color = colors.white;
        inputStyles.backgroundColor = colors.coral
      }
    }

    const translation = word && word.main_translation && word.main_translation.translation ? word.main_translation.translation : "";
    const otherTranslations = word && word.otherTranslationsString ? word.otherTranslationsString : "";
    const imageUrl = word && word.image && word.image.filename ? word.image.filename : false;
    const imageUserId = word && word.image && word.image.user_id ? word.image.user_id : false;

    if (currentIndex === 0 && autosound_word == '1') {
      if (!firstPlayed) {
        this.delayPlayWord(currentIndex);
      }
    }

    let correctHeight = true;

    const imageBgStyles = {
      width: 300,
      height: 218,
      marginBottom: 15,
      backgroundColor: correctHeight && imageUrl && shouldShowImage ? colors.lightGrey : colors.white
    };

    const imageStyles = {
      width: 300,
      height: 218,
    };


    const behindImageStyles = {
      width: 300,
      height: 218,
    };

    let imageWidth = 300;

    let s3image = `${constants.S3MarkedImages}-${imageWidth}/${imageUrl}`;

    if (imageUserId && imageUserId == userId) {
      s3image = `${constants.IMAGE_URL}/${imageUrl}?width=${imageWidth}`;
    }

    let imageWrapperWidth = !tablet ? (deviceHeight > 650 ? 250 : deviceHeight > 550 ? 200 : 150) : 350;
    let imageWrapperHeight = !tablet ? (deviceHeight > 650 ? 180 : deviceHeight > 550 ? 145 : 108) : 253

    if (deviceHeight < 600) {
      imageWrapperWidth = 150;
      imageWrapperHeight = imageWrapperWidth / 1.38;
    }

    return (
      <div className="spelling">
        <Container className="pageWrapper">

          <TrainingSubHeader
            trainingId={trainingId}
            freeze={freeze}
            key={freeze}
            version={version}
            ratio={fontSizeRation}
            scores={scores}
            progress={progress}
            changeWord={this.changeWord}

            deviceHeight={deviceHeight}
            deviceWidth={deviceWidth}
            translate={translate}
            tablet={tablet}
            exit={this._goBack}
            history={this.props.history}
            trainingName="check_spelling_training"
            subtitle={subtitle}

            title={'check_spelling_training'}
            text="check_spelling_training_description"
            url="https://youtu.be/UANsKK4rizg"
            toggleSettings={this.toggleWordSettingsModal}
          />

          <Card style={{minHeight: deviceHeight - 140}}>
            <div style={{opacity: word && word.id ? 1 : 0}} className="contentWrapper">

              <div
                className="wordImageWrapper"
                style={{
                width: 300, height: 218,marginBottom: 10,
                backgroundColor: imageUrl && shouldShowImage ? colors.lightGrey : colors.white}}
                >
                {!answered && (
                  <a href="#" onClick={this.changeWhatToDisplay}>
                    {imageUrl && correctHeight && shouldShowImage && (
                      <Image
                        draggable="false"
                        onContextMenu={(e)=>e.preventDefault()}
                        thumbnail
                        src={s3image}
                        className="wordImage"
                        style={{width: 300, height: 218}}
                      />
                    ) || (
                      <div className="behindImage" style={{width: 300,height: 218}}>
                        <span
                          className="behindImageText"
                          style={{fontSize: fontSizeRation * (!phrasebook ? (!tablet ? 14  : 16) : 12)}}
                        >
                          {translation}
                        </span>
                        {
                          otherTranslations && (
                            <span
                              className="ph2 mt2 textCenter text-secondary"
                              style={{fontSize: fontSizeRation * (!tablet ? (deviceWidth > 360 ? 12 : 11) : 14)}}>
                              {otherTranslations}
                            </span>
                          ) || null
                        }
                      </div>
                    )}
                  </a>
                )}

                {answered && (
                  <div className="behindImage" style={{width: 300,height: 218}}>
                    <span
                      className="behindImageText"
                      style={{fontSize:fontSizeRation * (!tablet ? 16 : 20)}}>
                      {word.word}
                      </span>
                  </div>
                )}

              </div>

              <div
                className="inputContainer"
                style={{width: !tablet ? (deviceWidth < 360 ? 200 : 300) : 450,
                  marginTop: !tablet ? (deviceHeight > 700 ? 10 : 5) : (deviceHeight > 950 ? 20 : 5)}}
              >

                <InputGroup>
                  <FormControl
                    style={inputStyles}
                    className="inputFieldS"
                    autoFocus={true}
                    ref={(input) => { this.wordInput = input }}

                    disabled={answered}
                    onKeyPress={(e)=>this._handleKeyPress(word, e)}
                    value={typed}
                    onChange={(e)=>this._typeWord(e.target.value)}
                    placeholder={translate('enter_word')}
                  />

                  <InputGroup.Append>
                    <Button
                      disabled={typed.length === 0}
                      variant="outline-secondary" onClick={this._clearTyped}>
                      <FontAwesomeIcon icon={faTimes}/>
                    </Button>
                  </InputGroup.Append>

                </InputGroup>
              </div>

              <Button
                disabled={answered}
                style={{width: 300,marginTop: 20}}
                variant="outline-info"
                onClick={()=> {this.checkAnswer(word)}}
              >
                <span className="checkButtonText" style={{fontSize: 16 * fontSizeRation}}>
                  {typed.length === 0 ? translate('dont_know') : translate('check')}
                </span>
              </Button>

              {word.association && word.association.association && (
                <div className="mt3 flexH flexCenter" style={{position: 'relative'}}>
                  <span className="association textCenter" style={{fontSize: !tablet ? 14 : (16 * fontSizeRation)}}>
                    {word.association.association}
                  </span>
                </div>
              )
              || null}

            </div>
          </Card>


          {finishButtonsModalVisible && (
            <FinishTrainingButtons
              ratio={fontSizeRation}
              isVisible={finishButtonsModalVisible}
              onClose={this.closeFinishButtonsModal}
              continueTraining={this.continueTraining}
              anotherTraining={this.anotherTraining}
              withMistakes={withMistakes}
              toResults={this._toResultsFromModal}
              returnBack={this._goBack}
              from={from}
              translate={translate}
              tablet={tablet}
            />
          )}

          {wordSettingsModalVisible && (
            <WordSettings
              changeVoice={this._changeVoice}
              ratio={fontSizeRation}
              isVisible={wordSettingsModalVisible}
              onClose={this.toggleWordSettingsModal}
              openTrainingInfo={this._openTrainingInfo}
              openTrainingSettings={this._openTrainingSettings}
              toWordDetails={this._toWordDetails}
              reportTrainingBug={this._reportTrainingBug}
              title={translate('check_spelling_training')}
              shuffle={userData.shuffle}
              shouldShuffle={true}
              toggleShuffle={this._toggleShuffle}
            />
          )}

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={subscriptionMessage}
                translate={translate}
                deviceWidth={deviceWidth}
                deviceHeight={deviceHeight}
              />
            )
          }

          {
            audioModalVisible && (
              <ChangeAudioLanguage
                onClose={this._toggleAudioModal}
                isVisible={audioModalVisible}
                toRegister={this._toggleRegisterModal}

              />
            )
          }

          {
            bugModalVisible && (
              <ReportBug
                onClose={this._toggleBugModal}
                isVisible={bugModalVisible}
                type="training"
                training="Check Spelling"
                extra={extra}
              />
            )
          }

          {
            trainingSettingsModalVisible && (
              <TrainingSettings
                onClose={this._toggleTrainingSettingsModal}
                isVisible={trainingSettingsModalVisible}
                trainingName="check_spelling_training"
                trainingId={trainingId}
              />
            )
          }
          {wordInfoVisible && (<WordInfo onClose={this._toggleWordInfo} isVisible={wordInfoVisible}/>)}
          
          {registerModalVisible && (
            <RegisterAlert
              onClose={this._toggleRegisterModal}
              isVisible={registerModalVisible}
              translate={translate}
              toRegister={this._toRegister}
            />
          )}
        </Container>
        <Loader fetchingData={fetchingData}/>

        <Helmet>
          <title>{`${translate('app_title_check_spelling_training')}`}</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="description" content={translate('app_title_check_spelling_training_description')} />
          <meta property="og:url" content={window.location.href} />
          <meta name="og:description" content={translate('app_title_check_spelling_training_description')} />
          <meta property="og:title" content={`${translate('app_title_check_spelling_training')}`} />
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  data: state.trainings,
  firstPlayed: state.word.firstPlayed,
  activity: state.activity,
  settings: state.settings,
  cache: state.cache,
  lists: state.lists,
  courseCache: state.courseCache,
  course: state.course,
  purchases: state.purchases,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    getWordsForTraining: (data) => dispatch(getWordsForTraining(data)),
    resetWords: () => dispatch(resetWords()),
    logListWord: (data) => dispatch(logListWord(data)),
    logPhrase: (data) => dispatch(logPhrase(data)),
    logGlossaryWord: (data) => dispatch(logGlossaryWord(data)),
    logUserList: (data) => dispatch(logUserList(data)),
    logActivity: (data) => dispatch(logActivity(data)),
    getListsToRepeat: (limit, userId) => dispatch(getListsToRepeat(limit, userId)),
    setFirstPlayed: (status) => dispatch(setFirstPlayed(status)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    logCourseListWord: (data) => dispatch(logCourseListWord(data)),
    logCourseList: (data) => dispatch(logCourseList(data)),
    setSpellingSettings: (data) => dispatch(setSpellingSettings(data)),
    setCachedCheckSpellingWords: (data) => dispatch(setCachedCheckSpellingWords(data)),
    toggleListTrainingButtonsModal: (status) => dispatch(toggleListTrainingButtonsModal(status)),

    moveNewListToRepeat: (list) => dispatch(moveNewListToRepeat(list)),
    removeListFromRepeatSection: (listId) => dispatch(removeListFromRepeatSection(listId)),
    updateListTrainings: (listId) => dispatch(updateListTrainings(listId)),
    updateTrainingsListFromRepeatSection: (listId) => dispatch(updateTrainingsListFromRepeatSection(listId)),
    getOnlyMistakes: () => dispatch(getOnlyMistakes()),
    updateCachedListTrainings: (listId) => dispatch(updateCachedListTrainings(listId)),
    updateCachedListTrainingsAndIntervals: (list) => dispatch(updateCachedListTrainingsAndIntervals(list)),
    getOnlyMistakesCourse: () => dispatch(getOnlyMistakesCourse()),

    toggleListTrainingButtonsModalCourse: (status) => dispatch(toggleListTrainingButtonsModalCourse(status)),
    getUpdatedCurrentListScores: (listId) => dispatch(getUpdatedCurrentListScores(listId)),
    getUpdatedCourseLists: () => dispatch(getUpdatedCourseLists()),
    getUpdatedCourseData: () => dispatch(getUpdatedCourseData()),
    resetCurrentTrainingId: () => dispatch(resetCurrentTrainingId()),
    setCurrentTrainingId: (id) => dispatch(setCurrentTrainingId(id)),
    logTrainingLimits: () => dispatch(logTrainingLimits()),
    shuffleListTrainingWords: (trainingId, listId) => dispatch(shuffleListTrainingWords(trainingId, listId)),
    shuffleCourseListTrainingWords: (trainingId, listId) => dispatch(shuffleCourseListTrainingWords(trainingId, listId)),
    shuffleTrainingWords: () => dispatch(shuffleTrainingWords()),
    toggleShuffleWords: () => dispatch(toggleShuffleWords()),
    get_word_details: (wordId) => dispatch(get_word_details(wordId)),

  };
}

export default connect(mapStateToProps, bindAction)(CheckSpelling);
