import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircle, faCogs, faCheckCircle,
  faTrashAlt,
  faCamera,
  faVolumeUp,
  faTimes, faSearch, faPlus
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import _ from 'lodash'

import {Translate, getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';
import moment from 'moment';

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import ListWord from '../../components/Cells/ListWord/index';
import TrainingButtons from '../../components/Modals/TrainingButtons/index';
import SubscriptionAlert from '../../components/Modals/SubscriptionAlert/index';
import ListsToFill from '../../components/Modals/ListsToFill/index';
// import BulkActionsModal from '../../components/Modals/BulkActionsModal/index';
import VocabularyToolbar from '../../components/Toolbars/VocabularyToolbar/index';
import TrainingsSet from '../../components/Modals/TrainingsSet/index';

import Loader from "../../components/Custom/Loader/index";

import {
  getVocabulary,
  gettingNewChunk,
  resetVocabulary,
  setGoal,
  deleteFromVocabulary,
  toggleFavoriteWord
} from '../../actions/vocabularyActions.js';
import {logActivity} from '../../actions/logActions';
import {fetchingData} from '../../actions/activity';
import {performBulkAction, moveToOtherLists, toggleListTrainingButtonsModal}from '../../actions/lists.js';

import colors from '../../lib/colors';
import * as constants from '../../lib/constants';

class Vocabulary extends Component {
  get toggleBulkMode() {
    return this._toggleBulkMode;
  }

  set toggleBulkMode(value) {
    this._toggleBulkMode = value;
  }

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      searchBarVisible: false,
      trainingsModalVisible: false,
      listId: -1,
      refreshing: false,
      currentChunk: 0,
      totalWords: 0,
      word: '',
      goal: 1000,
      currentWord: false,

      shouldShowVocabularyGoalModal: false,
      subscriptionAlertVisible: false,
      tablet: false,

      bulkMode: false,
      bulkModeType: false,
      listsModalIsVisible: false,
      selectedWords: [],
      wordsSettingsVisible: false,
      trainingsSetModal: false,
      scrolled: 0,

    };
    this.goalRef = React.createRef();

    this.onChangeTextDelayed = _.debounce(this.onChangeText, 250);

    this.track = null;
    this.timeOut = null;
  }

  componentDidMount() {
    this._setGoal();
    window.addEventListener('scroll', this.handleScroll.bind(this));

    this._getVocabularyData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._getVocabularyData();
    }
    if (this.props.user.userData !== prevProps.user.userData) {
      this._setGoal();
    }
  }

  componentWillUnmount() {
    this.props.resetVocabulary();

    clearTimeout(this.timeOut);

    window.removeEventListener('scroll', this.handleScroll);
  }

  _setGoal = () => {
    const {userData} = this.props.user;

    if (userData && userData.goal) {
      this.setState({
        goal: userData.goal
      })
    }
  };

  _setNewGoal = (goal) => {
    if(goal > 10000) {
      goal = 9999;
    }

    this.setState({
      goal
    })
  };

  handleScroll() {
    const scrollY = window.scrollY;

    this.setState({
      scrolled: scrollY
    });
  }

  _getVocabularyData = () => {
    const {isConnected} = this.props.settings;

    if (isConnected) {
      this.props.fetchingData(true);

      this.props.getVocabulary({page: this.state.page});
    }
  };

  favoriteToggle = (id, index) => {
    const {words} = this.props.vocabulary;
    let word = words[index];

    this.props.fetchingData(true);

    this.props.toggleFavoriteWord(id, index);

    this.props.logActivity({
      activityId: word.is_favourite == true ? 47 : 46,
      model: 'word',
      modelId: id,
      comment: "from glossary"
    });
  };

  deleteWord = (id, index) => {
    this.props.fetchingData(true);
    this.props.deleteFromVocabulary(id, index);

    this.props.logActivity({activityId: 49, model: 'word', modelId: id, comment: "from vocabulary"});
  };

  toggleTrainingButtons = (status) => {
    this.setState({
      trainingsModalVisible: status
    });

    this.props.toggleListTrainingButtonsModal(false)
  };

  trainingSelected = (training) => {
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;

    const {userData} = this.props.user;
    const {locale} = this.props.settings;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    let active = false;

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    this.props.toggleListTrainingButtonsModal(false);

    this.setState({
      trainingsModalVisible: false
    });

    if (active) {
      let activityId = '';

      let path = '';

      if (training == 'Preview') {
        activityId = 39;
        path = 'preview'
      } else if (training == 'DragImage') {
        path = 'image';
        activityId = 40;
      } else if (training == 'SelectTranslation') {
        path = 'translation';
        activityId = 41;
      } else if (training == 'SelectWord') {
        path = 'word';
        activityId = 42;
      } else if (training == 'CollectWord') {
        path = 'collect';
        activityId = 43;
      } else if (training == 'CheckSpelling') {
        path = 'spelling';
        activityId = 44;
      } else if (training == 'CollectSentence') {
        activityId = 114;
        path = 'sentence';
      } else if (training == 'CollectSentenceTap') {
        activityId = 335;
        path = 'sentence';
      } else if (training == 'MemoryCards') {
        activityId = 131;
        path = 'memory'
      } else if (training == 'WordDefinition') {
        path = 'definition';
        activityId = 148;
      } else if (training == 'SpeakOut') {
        activityId = 95;
      } else if (training == 'MissingLetter') {
        path = 'missing';
        activityId = 315;
      } else if (training == 'Listening') {
        path = 'listening';
        activityId = 345;
      } else if (training == 'TranslateSentence') {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut = setTimeout(()=> {
        this.props.history.push(`/${locale}/${path}/-1`, {type: 'listId', from: 'listWords', listId: -1});
        this.props.logActivity({activityId, model: 'list', modelId: -1, comment: 'from vocabulary'});
      }, 100);

    } else {
      this._toggleSubscriptionAlert(true);
    }
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toSubscription = (productId) => {
    this._toggleSubscriptionAlert(false);
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId});
  };

  _keyExtractor = (item) => {
    return `vocabulary-word-${item.id}${item.word_id}`
  };

  playTrack = (url) => {
    const {isConnected} = this.props.settings;
    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    if (isConnected) {
      this.track = new Audio(url);
      this.track.play();
    }
  };

  _renderItem = (item, index) => {
    const {fontSizeRation, version} = this.props.settings;
    const {tablet, bulkMode, bulkModeType, selectedWords} = this.state;
    const imageUrl = item.image && item.image.filename ? item.image.filename : false;
    const {userData, userId} = this.props.user;
    const {translate} = this.props;
    const imageUserId = item && item.image && item.image.user_id ? item.image.user_id : false;


    let selected = selectedWords.indexOf(item.id);

    let circleColor = colors.lightGrey;

    if (bulkMode) {
      if (bulkModeType == 'delete') {
        circleColor = colors.coral;
      } else if (bulkModeType == 'vocabulary') {
        circleColor = colors.waterBlue;
      } else if (bulkModeType == 'favorite') {
        circleColor = colors.golden;
      } else if (bulkModeType == 'move') {
        circleColor = colors.greyishBrown;
      }
    }

    const translation = item && item.main_translation && item.main_translation.translation ? item.main_translation.translation : "";

    let audio = item && item.audio ? item.audio : false;
    let audioSource = constants.BACKEND_URL;

    if (userData && userData.audio_language && item[userData.audio_language]) {
      audio = item[userData.audio_language];
      audioSource = constants.S3
    }
    const imageWidth = 150;
    const wordName = item && item.word ? item.word : "";
    const transcription = item && item.transcription ? item.transcription : "";

    return (
      <ListWord
        key={index}
        index={index}
        imageUserId={imageUserId}
        userId={userId}
        userData={userData}
        imageUrl={imageUrl}
        word={item}
        inVocabulary={true}
        inFavorite={item.is_favourite}
        version={version}
        tablet={tablet}
        ratio={fontSizeRation}

        translate={translate}
        transcription={transcription}
        wordName={wordName}
        imageWidth={imageWidth}
        translation={translation}

        audio={audio}
        audioSource={audioSource}

        onPressWord={this._onPressWord}
        vocabularyToggle={this.deleteWord}
        favoriteWordToggle={this.favoriteToggle}
        onPlay={this.playTrack}

        bulkMode={bulkMode}
        circleColor={circleColor}
        selected={selected !== -1}
      />
    )
  };

  _onPressWord = (word) => {
    const {bulkMode, bulkModeType, selectedWords} = this.state;
    const {translate} = this.props;

    if (bulkMode) {
      let index = selectedWords.indexOf(word.id);

      if (index === -1) {
        if (bulkModeType == 'delete' || bulkModeType == 'move') {
          this.setState({
            selectedWords: [...selectedWords, word.id]
          })
        } else {
          if (bulkModeType == 'vocabulary') {
            if (!word.in_vocabulary) {
              this.setState({
                selectedWords: [...selectedWords, word.id]
              })
            } else {
              toastr.warning("", translate('already_there'))
            }
          } else if (bulkModeType == 'favorite') {
            if (!word.is_favourite) {
              this.setState({
                selectedWords: [...selectedWords, word.id]
              })
            } else {
              toastr.warning("", translate('already_there'))
            }
          }
        }
      } else {
        selectedWords.splice(index, 1);

        this.setState({
          selectedWords
        })
      }
    }
  };

  setUserGoal = (goal) => {
    this.props.fetchingData(true);
    this.props.setGoal(goal);
    this.props.logActivity({activityId: 53, comment: `${goal}`});
  };

  getVocabularyWords = () => {
    const {isConnected} = this.props.settings;

    const {getVocabulary} = this.props;
    const {page} = this.state;

    if (isConnected) {
      getVocabulary({page})
    }
  };

  toggleSearchBar = () => {
    const {isConnected} = this.props.settings;

    if (this.state.searchBarVisible) {
      this.setState({
        word: ''
      });

      this.props.resetVocabulary();

      if (isConnected) {
        this.props.getVocabulary({page: 1})
      }
    } else {
      this.props.logActivity({activityId: 16});
    }
    this.setState({
      searchBarVisible: !this.state.searchBarVisible
    })
  };

  onChangeText() {
    this.searchVocabularyWords()
  }

  searchVocabularyWords = () => {
    const {isConnected} = this.props.settings;

    const {getVocabulary} = this.props;
    const {word} = this.state;

    if (isConnected) {
      getVocabulary({word})
    }
  };

  _handleKeyPress = (type, event) => {
    const {word, goal} = this.state;
    console.log("g", goal)

    if (event.key === 'Enter') {
      if (type == 'search' && word.length > 0) {
        this.searchVocabularyWords();
      } else if (type == 'goal' && goal > 0) {
        this.setUserGoal(goal);
      }
    }
  };

  pageClicked = (page) => {
    const {isConnected} = this.props.settings;

    this.setState({
      page,
      word: '',
      searchBarVisible: false
    });

    if (isConnected) {
      this.props.fetchingData(true);

      this.props.getVocabulary({page});
    }
  };

  _toggleBulkMode = (type) => {
    this.setState({
      bulkMode: true,
      bulkModeType: type
    })
  };

  _performBulkAction = () => {
    const {translate} = this.props;
    const {currentList} = this.props.lists;

    const {selectedWords, bulkModeType} = this.state;

    if (selectedWords.length == 0) {
      toastr.warning("", translate('no_words_selected'));
    } else {
      if (bulkModeType != 'move') {
        this.setState({
          bulkMode: false
        });
        this.props.fetchingData(true);

        this.props.performBulkAction(-1, selectedWords, bulkModeType, 'vocabulary');
        this.props.logActivity({activityId: 338, comment: `${bulkModeType}`});

        this._disableBulkMode();
      } else {
        this.toggleListsToFillModal(true);
      }
    }
  };

  toggleSelectAll = (e) => {
    e.preventDefault();
    
    const {selectedWords, bulkModeType} = this.state;
    const {words} = this.props.vocabulary;

    if (selectedWords.length > 0) {
      this.setState({
        selectedWords: []
      })
    } else {
      let selected = [];

      words.forEach(item => {
        if (bulkModeType == 'delete' || bulkModeType == 'move') {
          selected.push(item.id)
        } else {
          if (bulkModeType == 'vocabulary') {
            if (!item.in_vocabulary) {
              selected.push(item.id)
            }
          } else if (bulkModeType == 'favorite') {
            if (!item.is_favourite) {
              selected.push(item.id)
            }
          }
        }
      });

      this.setState({
        selectedWords: selected
      })
    }
  };

  _disableBulkMode = () => {
    this.setState({
      selectedWords: [],
      bulkMode: false,
      bulkModeType: false
    })
  };

  toggleListsToFillModal = (status) => {
    this.setState({
      listsModalIsVisible: status
    })
  };

  fillLists = (id) => {
    const {selectedWords} = this.state;

    this.toggleListsToFillModal(false);

    this.props.fetchingData(true);

    this.props.moveToOtherLists(-1, id, selectedWords, 'vocabulary');

    this._disableBulkMode();

    // this.props.logActivity({activityId: id != 0 ? 19 : 20, model: 'list', modelId: id});
  };

  _typeVocabularyWord = (word) => {
    this.setState({word});
    this.onChangeTextDelayed(word);
  };

  _customizeButtons = (e) => {
    e.preventDefault();
    this.toggleTrainingButtons(false);
    this._toggleModal('trainingsSetModal', true)
  };

  _toggleModal = (type, status) => {
    this.setState({
      [type]: status
    })
  };

  render() {
    const {words, totalWords, totalPages, fetchedVocabularyWords} = this.props.vocabulary;
    const {
      searchBarVisible,
      word,
      subscriptionAlertVisible, tablet, page,
      wordsSettingsVisible,
      listsModalIsVisible,
      selectedWords,
      bulkMode,
      bulkModeType,
      trainingsModalVisible,
      scrolled,
      goal,
      trainingsSetModal
    } = this.state;
    const {fetchingData} = this.props.activity;
    const {translate} = this.props;
    const {fontSizeRation, locale, deviceHeight, deviceWidth} = this.props.settings;
    const {lists, showListTrainingButtons} = this.props.lists;
    const {lifetimeStatus, subscriptionActive, webSubscriptionStatus} = this.props.purchases;

    let subtitle = translate('know_goal');

    subtitle = subtitle.replace('%1', totalWords);
    subtitle = subtitle.replace('%2', goal);

    const width = searchBarVisible ? (deviceWidth - 50) : (!tablet ? 170 : 200);

    const {userData} = this.props.user;
    let level = userData && userData.level ? userData.level : 0;
    const listSize = userData && userData.list_size ? userData.list_size : 10;

    const toolbar = classNames({
      'fixed-toolbar': scrolled >= 50,
      'justify-content-between listToolbar justify-content-center mb-4': true
    });
    let active = false;

    if (subscriptionActive || webSubscriptionStatus) {
      active = true;
    }

    return (
      <div className="vocabulary">
        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate('vocabulary')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <VocabularyToolbar
          translate={translate}
          ratio={fontSizeRation}
          tablet={tablet}
          toolbar={toolbar}
          active={active}
          deviceHeight={deviceHeight}
          deviceWidth={deviceWidth}

          toVideo={this._toVideoInstructions}
          lifetimeStatus={lifetimeStatus}
          toggleArchive={this._toggleArchive}
          cleanListCache={this._cleanListCache}
          translationLanguage={userData.translation_language}

          toBulkMode={this._toggleBulkMode}
          bulkMode={bulkMode}
          toggleTrainingButtons={this.toggleTrainingButtons}

          handleKeyPress={this._handleKeyPress}
          totalPages={totalPages}
          currentPage={page}
          pageClicked={this.pageClicked}
          totalWords={totalWords}
          typedWord={word}
          words={totalWords}
          listSize={listSize}
          typeVocabularyWord={this._typeVocabularyWord}

          goal={goal}
          userData={userData}
          goalRef={this.goalRef}
          setGoal={this._setNewGoal}
          setUserGoal={this.setUserGoal}
        />

        <p className="text-secondary text-center">
          <span className="font-weight-bold">
            {translate('your_vocabulary')}
          </span>
          <span className="font-weight-light d-block">
            {subtitle}
          </span>
        </p>

        <Container className="pageWrapper">

          {
            fetchedVocabularyWords && words.length > 0 && bulkMode && bulkModeType && (
              <Card className="deleteListsTextWrapper">
                  <span className="bulkModeText font-weight-bold" style={{fontSize: fontSizeRation * 16}}>
                    {translate(`list_words_to_${bulkModeType}_action`)}
                  </span>

                <div className="bulkModeWrapper flex-md-row flex-sm-column flex-column" style={{marginTop: 10}}>
                  <a href="#"
                     className="mr-3 mb-0 mb-sm-2 mb-md-0"
                     onClick={this.toggleSelectAll}>
                    <FontAwesomeIcon
                      className="selectedIcon"
                      icon={selectedWords.length > 0 ? faCheckCircle : faCircle}
                      style={{marginRight: !tablet ? 10 : 20, fontSize: !tablet ? 20 : 30}}/>
                    <span
                      className="selectAllWords pv4"
                      style={{marginRight: 10, color: colors.greyishBrown, fontSize: fontSizeRation * 16}}>
                      {selectedWords.length > 0 ? translate('deselect_words') : translate('select_all_words')}
                    </span>
                  </a>

                  <Button
                    size="sm"
                    className="mr-3 mb-0 mb-sm-2 mb-md-0"
                    disabled={selectedWords.length === 0}
                    variant={bulkModeType == 'delete' ? 'outline-danger' : 'outline-success'}

                    onClick={this._performBulkAction}
                  >
                    {translate(`to_${bulkModeType}`)} - {selectedWords.length}
                  </Button>

                  <Button
                    size="sm"
                    className="mr-3"
                    variant="outline-secondary"
                    onClick={this._disableBulkMode}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </Card>
            )
          }

          <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
            {
              words.map((list, index) => {
                return this._renderItem(list, index);
              })
            }
          </div>

          {(trainingsModalVisible || showListTrainingButtons) && (
            <TrainingButtons
              modalVisible={trainingsModalVisible || showListTrainingButtons}
              training={this.trainingSelected}
              onClose={() => this.toggleTrainingButtons(false)}
              ratio={fontSizeRation}
              tablet={tablet}
              level={level}
              listId={-1}
              translate={translate}
              translationLanguage={userData.translation_language}
              title="select_training_to_repeat_vocabulary"
              customiseButtons={this._customizeButtons}

            />
          )}

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={'subscription_trainings'}
                translate={translate}
              />
            )
          }

          {listsModalIsVisible && (
            <ListsToFill
              isVisible={listsModalIsVisible}
              lists={lists}
              fillLists={this.fillLists}
              listSize={listSize}
              currentListId={-1}
              ratio={fontSizeRation}
              selectedWords={selectedWords.length}
              onClose={this.toggleListsToFillModal}
              translate={translate}
              tablet={tablet}
              fetchingData={fetchingData}
            />
          )}

        </Container>

        {
          trainingsSetModal && (
            <TrainingsSet
              onClose={this._toggleModal}
              isVisible={trainingsSetModal}
            />
          )
        }

        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  vocabulary: state.vocabulary,
  user: state.user,
  lists: state.lists,
  purchases: state.purchases,
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize)
});

function bindAction(dispatch) {
  return {
    getVocabulary: (data) => dispatch(getVocabulary(data)),
    deleteFromVocabulary: (id, index) => dispatch(deleteFromVocabulary(id, index)),
    toggleFavoriteWord: (id, index) => dispatch(toggleFavoriteWord(id, index)),
    setGoal: (goal) => dispatch(setGoal(goal)),
    resetVocabulary: () => dispatch(resetVocabulary()),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    gettingNewChunk: () => dispatch(gettingNewChunk()),
    performBulkAction: (listId, words, type, source) => dispatch(performBulkAction(listId, words, type, source)),
    moveToOtherLists: (fromListId, toListId, words, source) => dispatch(moveToOtherLists(fromListId, toListId, words, source)),
    toggleListTrainingButtonsModal: (status) => dispatch(toggleListTrainingButtonsModal(status)),

  };
}

export default connect(mapStateToProps, bindAction)(Vocabulary);
