import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faCircle, faStar} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import * as helpers from '../../../lib/validators';

import {logActivity} from '../../../actions/logActions';
import {rateCourse} from '../../../actions/courseActions'
import {fetchingData} from '../../../actions/activity';

import colors from '../../../lib/colors';

class CourseRate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      rate: null,
      tablet: false
    };

    this.inputRef = React.createRef();
  }

  fullStar(index, rate) {
    if (rate === null) return false;
    return rate >= index;
  }

  changeRate =  (index, e) => {
    e.preventDefault();

    this.setState({
      rate: index
    })
  };

  getStars = () => {
    const {rate, tablet} = this.state;

    let stars = [0, 1, 2, 4, 5].map((item, i) => {
      let full = this.fullStar(i, rate);

      return (
        <a href="#"
          key={`${item}-${i}`}
          onClick={(e)=>this.changeRate(i, e)}
        >
          <FontAwesomeIcon
            icon={faStar}
            style={{marginHorizontal: !tablet ? 10 : 15,
                   color: !full ? colors.greyishBrown : colors.golden,
                   fontSize: !tablet ? 40 : 50}}
          />
        </a>
      );
    });

    return stars;
  };

  setText = (message) => {
    this.setState({
      message: helpers.sanitizeString(message, 500)
    })
  };

  sendMessage = () => {
    const {rate, message} = this.state;
    const {translate} = this.props;

    let error = false;

    if (rate === null) {
      error = true;
    }

    if(error) {
      toastr.error(translate('rate_course_error'));
    } else {
      this.props.fetchingData(true);
      this.props.rateCourse(rate, message);
      this.props.logActivity({activityId: 225, comment: `Rate: ${rate}, Message: ${message}`});
      this.props.onClose('courseRateModalVisible', false);
    }
  };

  render() {
    const {
      translate,
      isVisible,
      onClose,
      tour
    } = this.props;

    const {message, rate} = this.state;


    return (
      <Modal
        onShow={() => this.inputRef.current.focus()}
        className="ratemodal"
        show={isVisible}
        onHide={()=>onClose('courseRateModalVisible',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="course_rating"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">

          <div className="flexHorizontal flexCenter mt1 mb3">
            {this.getStars()}
          </div>

          <p className="introText">
            {translate('right_about_course')}
          </p>


          <div className="position-relative">
            <InputGroup className="ph1 mt-2">
              <FormControl
                as="textarea"
                rows="5"
                placeholder={translate('message')}
                ref={this.inputRef}
                style={{fontSize: 12}}
                value={message}
                onChange={(e)=> this.setText(e.target.value)}
              />
              <InputGroup.Append>
                <Button
                  style={{fontSize: 12}}
                  disabled={message.length == 0}
                  variant="outline-secondary"
                  onClick={()=>this.setText("")}>
                  <FontAwesomeIcon icon={faTimes}/>
                </Button>
              </InputGroup.Append>
            </InputGroup>

            <p className="chars" style={{fontSize: 12}}>{message.length} / 500</p>

            <Button
              className="mt-3"
              block
              disabled={message.length == 0 || tour}
              variant="outline-secondary"
              onClick={this.sendMessage}>
              {translate('send')}
            </Button>

          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,

});

function bindAction(dispatch) {
  return {
    rateCourse: (rate, message)=> dispatch(rateCourse(rate, message)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    logActivity: (data) => dispatch(logActivity(data)),
  };
}

export default connect(mapStateToProps, bindAction)(CourseRate);
