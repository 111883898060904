import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';
import {logApiError} from './logSagas';

const API_URL = constants.API_URL;

export function* getUserBadges(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getUserBadges')
    .query({
      user_id: userId,
      course_id: data.payload
    });

    yield put({
      type: types.SET_USER_BADGES,
      payload: result.body
    });

  } catch (error) {
    yield logApiError({payload: {function: 'getUserBadges', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}
