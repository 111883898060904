import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircle, faCogs, faCheckCircle,
  faTrashAlt,
  faCamera,
  faVolumeUp,
  faTimes, faSearch, faPlus
} from '@fortawesome/free-solid-svg-icons';

import {Translate, getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';


import SubscriptionAlert from '../../../../components/Modals/SubscriptionAlert/index';
import WordMakerCell from '../WordMakerCell/index';
// import WordMakerInfoModal from '../WordMakerInfoModal/index';
import UnlockModal from '../UnlockModal/index';
import Loader from "../../../../components/Custom/Loader/index";

import {
  getWordMakerWords, resetWordMakerWords, setWordMakerWord,
  setFetchingMakerWord, openWordMakerLevel
} from '../../../../actions/wordMakerActions';
import {logActivity} from '../../../../actions/logActions';
import {fetchingData} from '../../../../actions/activity';
import {getRegistrationDate} from '../../../../actions/purchasesActions';

import colors from '../../../../lib/colors';

class WordMaker extends Component {

  constructor(props) {
    super(props);

    this.state = {
      infoModalVisible: false,
      lockedModalVisible: false,
      subscriptionAlertVisible: false,
      currentWord: false,
      wordDimensions: {width: 200, height: 200},
      tablet: false
    };
  }

  componentDidMount() {
    this._getWordMakerData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._getWordMakerData();
    }
  }

  componentWillUnmount() {
    // this.props.resetWordMakerWords();
  }

  _getWordMakerData = () => {
    const {isConnected} = this.props.settings;
    const {wordMakerWords} = this.props.wordMaker;

    if (isConnected) {
      // if(wordMakerWords.length == 0) {
      this.props.getRegistrationDate();
      this.props.fetchingData(true);
      this.props.getWordMakerWords();
      // }
    }
  };

  refreshData = () => {
    const {isConnected} = this.props.settings;

    if (isConnected) {
      this.props.setFetchingMakerWord();
    }
    this._getWordMakerData();
  };

  _onPressItem = (word, index, unlocked) => {
    this.props.setWordMakerWord(word);
    const {locale} = this.props.settings;

    this.setState({
      currentWord: word
    });

    if (unlocked) {
      this.props.history.push(`/${locale}/wordmaker/${word.id}`, {word});
    } else {
      // this._toggleSubscriptionAlert(true);
      this._toggleLockedModal(true);
    }

    this.props.logActivity({activityId: 284, model: 'game', modelId: word.id, comment: `unlocked: ${unlocked}`});
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toggleLockedModal = (status) => {
    this.setState({
      lockedModalVisible: status
    })
  };

  _toSubscription = (productId) => {
    this._toggleSubscriptionAlert(false);
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId}); 
  };

  _renderItem = (item, index) => {
    const {fontSizeRation, version, deviceWidth, deviceHeight} = this.props.settings;
    const {tablet} = this.state;
    const {currentLevel, openedLevels, freeHints} = this.props.wordMaker;
    const {translate} = this.props;

    return (
      <WordMakerCell
        key={index}
        ratio={fontSizeRation}
        word={item}
        tablet={tablet}
        currentLevel={currentLevel}
        index={index}
        freeHints={freeHints}
        version={version}
        openedLevels={openedLevels}
        onItemPressed={this._onPressItem}
        deviceWidth={deviceWidth}
        deviceHeight={deviceHeight}
        translate={translate}
      />
    )
  };

  toggleInfoModal = (status) => {
    this.setState({
      infoModalVisible: status
    })
  };

  _reportTrainingBug = () => {
    this.props.logActivity({activityId: 234, comment: "Word Maker"});

    this.toggleInfoModal(false);

    this.props.navigation.navigate('ReportBug', {type: 'training', training: "Word Maker"})
  };

  _unlockLevel = () => {
    this._toggleLockedModal(false);
    this.props.fetchingData(true);
    this.props.openWordMakerLevel()
  };

  getInitialIndex = () => {
    const {fetched, currentLevel} =  this.props.wordMaker;

    if (fetched && currentLevel) {
      return Math.floor((currentLevel - 1) / 2)
    }
    return 0;
  };

  _toVideoInstructions = () => {
    this.toggleInfoModal(false);
    const url = 'https://youtu.be/AfejcwIqSOg';

    this.props.logActivity({activityId: 159, comment: `Word Maker: ${url}`});

  };


  render() {
    const {fetchingData} = this.props.activity;
    const {fontSizeRation, deviceHeight, locale} = this.props.settings;
    const {fetched, fetching, totalScores, wordMakerWords, place, freeHints, totalPlayers} = this.props.wordMaker;
    const {
      subscriptionAlertVisible,
      infoModalVisible,
      currentWord,
      tablet, lockedModalVisible,
      wordDimensions
    } = this.state;
    const {translate} = this.props;

    return (
      <div className="wordmaker">

        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate('word_maker')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container style={{marginTop: 25}} className="pageWrapper">

          {/*<ScreenHeader headerImageId={1}
           translateTitle={false}
           toolbarHidden={false}
           width={(!tablet ? 90 : 120)}
           subtitle={fetched ? `${translate('scoresUC')}: ${totalScores ? totalScores : 0}` : ''}
           title={"Word Maker"}
           goBack={()=> this.props.navigation.goBack()}
           >

           <TouchableOpacity
           style={!tablet ? headers.toolbarButtonWrapper : headers.toolbarButtonWrapperTablet}
           onPress={() => {
           this.props.logActivity({activityId: 283});
           this.toggleInfoModal(true)
           }}>
           <FontAwesome
           style={[{color: colors.greyishBrown}, !tablet ? headers.toolbarButton : headers.toolbarButtonTablet]}
           icon={LightIcons.infoCircle}
           pro={true}/>
           </TouchableOpacity>
           </ScreenHeader>*/}

          <div style={{flex: 1}}>

            {
              fetched && place && (
                <div className="flexH flexCenter mv4">
                <span
                  style={{marginRight: 10, fontSize: fontSizeRation * (!tablet ? 16 : 20), color: colors.greyishBrown}}>
                  {translate('your_place')}:
                </span>
                <span
                  style={{fontWeight: '700', color: place == 1 ? colors.pine : colors.greyishBrown, fontSize: fontSizeRation * (!tablet ? 16 : 20)}}>
                  {place} / {totalPlayers}
                </span>
                </div>
              )
            }
            {
              fetched && wordMakerWords.length > 0 && (
                <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">

                  {
                    wordMakerWords.map((word, index) => {
                      return this._renderItem(word, index);
                    })
                  }
                </div>
              )
            }
          </div>

          {
            /*infoModalVisible && (
             <WordMakerInfoModal
             tablet={tablet}
             reportBug={this._reportTrainingBug}
             isVisible={infoModalVisible}
             // logActivity={this.props.logActivity}
             onClose={this.toggleInfoModal}
             ratio={fontSizeRation}
             title={'word_maker_title'}
             text={'word_maker_description'}
             toVideo={this._toVideoInstructions}
             />
             )*/
          }

          {
            lockedModalVisible && (
              <UnlockModal
                tablet={tablet}
                translate={translate}
                deviceHeight={deviceHeight}
                isVisible={lockedModalVisible}
                onClose={this._toggleLockedModal}
                unlock={this._unlockLevel}
                ratio={fontSizeRation}
              />
            )
          }

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={'subscription_trainings'}
              />
            )
          }
          <Loader fetchingData={fetchingData}/>

        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  activity: state.activity,
  settings: state.settings,
  purchases: state.purchases,
  wordMaker: state.wordMaker,
  translate: getTranslate(state.localize),
});

function bindAction(dispatch) {
  return {
    fetchingData: (status) => dispatch(fetchingData(status)),
    getWordMakerWords: () => dispatch(getWordMakerWords()),
    resetWordMakerWords: () => dispatch(resetWordMakerWords()),
    setFetchingMakerWord: () => dispatch(setFetchingMakerWord()),
    setWordMakerWord: (word) => dispatch(setWordMakerWord(word)),
    openWordMakerLevel: () => dispatch(openWordMakerLevel()),

    logActivity: (data) => dispatch(logActivity(data)),
    getRegistrationDate: () => dispatch(getRegistrationDate())
  };
}

export default connect(mapStateToProps, bindAction)(WordMaker);
