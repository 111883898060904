export const checkEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const sanitizeString = (example, charsLength) => {
  let sentence = example.length > 0 ? example.substr(0, charsLength) : example;

  sentence = sentence.replace('<', "")
  sentence = sentence.replace('>', "")
  sentence = sentence.replace('@', "")
  sentence = sentence.replace('{', "")
  sentence = sentence.replace('}', "")
  sentence = sentence.replace('[', "")
  sentence = sentence.replace(']', "")
  sentence = sentence.replace('+', "")
  sentence = sentence.replace('=', "")
  sentence = sentence.replace('*', "")
  sentence = sentence.replace('\\', "")
  sentence = sentence.replace('^', "")
  sentence = sentence.replace('|', "")
  sentence = sentence.replace('\n', "")

  return sentence;
};

export const checkDiscountCodeValidity = (code) => {
  var re = /^[a-z0-9]{8}$/i;
  return re.test(String(code));
};