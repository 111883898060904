import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logApiError} from './logSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* getHelperArticles(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getHelperArticlesUsers')
    .query({
      user_id: userId,
    });

    // console.log("getHelperArticlesUsers", result.body)

    yield put({
      type: types.SET_HELPER_ARTICLES,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getHelperArticlesUsers',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getHelperArticle(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getHelperArticleUser')
    .query({
      user_id: userId,
      id: data.payload,
    });

    // console.log("getHelperArticleUser", result.body.data.connections)

    yield put({
      type: types.SET_HELPER_ARTICLE,
      payload: result.body.data
    });

  } catch (error) {
    console.log("error", error)
    yield logApiError({
      payload: {
        function: 'getHelperArticleUser',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getHelperArticleBySlug(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getHelperArticleBySlug')
    .query({
      user_id: userId,
      slug: data.payload,
    });

    // console.log("getHelperArticleBySlug", result.body.data)

    yield put({
      type: types.SET_HELPER_ARTICLE,
      payload: result.body.data
    });

    yield put({
      type: types.SET_CURRENT_HELPER_ARTICLE,
      payload: result.body.data
    });

  } catch (error) {
    console.log("error", error)
    yield logApiError({
      payload: {
        function: 'getHelperArticleBySlug',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}