import * as types from './types';


export const setCurrentCourseSlug = (name, slug) => (dispatch) => {
  dispatch({ type: types.SET_CURRENT_COURSE_SLUG, payload: {name,slug}});
};

export const setCurrentCourseData = (course) => (dispatch) => {
  dispatch({ type: types.SET_COURSE_DATA, payload: course});
};

export const setOnlyCourseData = (course) => (dispatch) => {
  dispatch({ type: types.SET_ONLY_COURSE_DATA, payload: course});
};

export const setCourseStatus = (status) => (dispatch) => {
  dispatch({ type: types.SET_COURSE_STATUS, payload: status});
};

export const setCurrentCourseId = (id) => (dispatch) => {
  dispatch({ type: types.SET_CURRENT_COURSE_ID, payload: id});
};

export const courseOptionSelected = (status) => (dispatch) => {
  dispatch({ type: types.COURSE_OPTION_SELECTED, payload: status});
};

export const getInitialCourseData = (slug) => (dispatch) => {
  dispatch({ type: types.GET_COURSE_INITIAL_DATA, payload: slug});
};

export const getCoursesMistakesTestsRepeats = () => (dispatch) => {
  dispatch({ type: types.GET_COURSES_INITIAL_DATA});
};

export const getCourseListStatistics = (listId) => (dispatch) => {
  dispatch({ type: types.GET_COURSE_LIST_STATISTICS, payload: listId});
};

export const getCourseData = () => (dispatch) => {
  dispatch({ type: types.GET_COURSE_DATA});
};

export const setCourseList = (list) => (dispatch) => {
  dispatch({ type: types.SET_COURSE_LIST, payload: list });
};

export const setFetchedCourseData = (status) => (dispatch) => {
  dispatch({ type: types.SET_COURSE_DATA_FETCHED, payload: status });
};

export const generateCourseLists = (name, slug) => (dispatch) => {
  dispatch({ type: types.GENERATE_COURSE_LISTS, payload: {name, slug}});
};

export const getCourseListWords = (listId) => (dispatch) => {
  dispatch({ type: types.GET_COURSE_LIST_WORDS, payload: listId});
};

export const resetCourseListWords = () => (dispatch) => {
  dispatch({ type: types.RESET_COURSE_LIST_WORDS});
};

export const resetCurrentCourseList = () => (dispatch) => {
  dispatch({ type: types.RESET_CURRENT_COURSE_LIST});
};

export const resetCourseListData = () => (dispatch) => {
  dispatch({ type: types.RESET_COURSE_LIST_DATA});
};

export const resetCourseListStatistics = () => (dispatch) => {
  dispatch({ type: types.RESET_COURSE_LIST_STATISTICS});
};

export const setCourseListType = (type) => (dispatch) => {
  dispatch({ type: types.SET_COURSE_LIST_TYPE, payload: type});
};

export const resetCourseData = () => (dispatch) => {
  dispatch({ type: types.RESET_COURSE_DATA});
};

export const getCourseListData = (listId) => (dispatch) => {
  dispatch({ type: types.GET_COURSE_LIST_DATA, payload: listId});
};

export const getCourseListCalendar = (listId) => (dispatch) => {
  dispatch({ type: types.GET_COURSE_LIST_CALENDAR, payload: listId});
};

export const deleteCourseData = (courseData) => (dispatch) => {
  dispatch({ type: types.DELETE_COURSE_DATA, payload: {courseData}});
};

export const getCourseCalendarMonthData = (date) => (dispatch) => {
  dispatch({ type: types.GET_COURSE_CALENDAR_MONTH_DATA, payload: date });
};

export const setCourseCalendarMonthData = (data) => (dispatch) => {
  dispatch({ type: types.SET_COURSE_CALENDAR_MONTH_DATA, payload: data });
};

export const getCourseStatistics = () => (dispatch) => {
  dispatch({ type: types.GET_COURSE_STATISTICS });
};

export const resetCourseStatistics = () => (dispatch) => {
  dispatch({ type: types.SET_COURSE_STATISTICS, payload: false });
};

export const removeCourseListFromCalendar = (listId) => (dispatch) => {
  dispatch({ type: types.REMOVE_COURSE_LIST_FROM_CALENDAR, payload: listId });
};

export const getCourseVocabulary = (data) => (dispatch) => {
  dispatch({ type: types.GET_COURSE_VOCABULARY, payload: data });
};

export const performCourseBulkAction = (listId,words, type, source) => (dispatch) => {
  dispatch({ type: types.COURSE_VOCABULARY_BULK_ACTION, payload: {listId,words, type, source} });
};

export const moveToGeneralVocabulary = (data) => (dispatch) => {
  dispatch({ type: types.MOVE_TO_GENERAL_VOCABULARY, payload:data });
};

export const gettingCourseNewChunk = () => (dispatch) => {
  dispatch({ type: types.GET_COURSE_VOCABULARY_NEXT_CHUNK });
};

export const resetCourseVocabulary = () => (dispatch) => {
  dispatch({ type: types.RESET_COURSE_VOCABULARY });
};

export const setShowWordSentence = (status) => (dispatch) => {
  dispatch({ type: types.SET_SHOW_WORD_SENTENCE, payload: status });
};

export const checkForCourseDebts = (userId) => (dispatch) => {
  dispatch({ type: types.CHECK_COURSE_DEBTS, payload: userId });
};

export const rateCourse = (rate, message) => (dispatch) => {
  dispatch({ type: types.RATE_COURSE, payload: {rate, message} });
};

export const setCourseDataFromCache = (data) => (dispatch) => {
  dispatch({ type: types.SET_COURSE_DATA_FROM_CACHE, payload: data });
};

export const setCourseListWordsFromCache = (data) => (dispatch) => {
  dispatch({ type: types.SET_COURSE_LIST_WORDS_FROM_CACHE, payload: data });
};

export const resetCourseCache = () => (dispatch) => {
  dispatch({ type: types.RESET_COURSE_CACHE });
};

export const getOnlyMistakesCourse = () => (dispatch) => {
  dispatch({ type: types.GET_COURSE_MISTAKES });
};

export const toggleListTrainingButtonsModalCourse = (status) => (dispatch) => {
  dispatch({ type: types.SET_SHOW_LIST_TRAINING_BUTTONS_MODAL_COURSE, payload: status });
};

export const moveFreshCourseListToRepeat = (list) => (dispatch) => {
  dispatch({ type: types.MOVE_COURSE_LIST_TO_REPEAT, payload: {list} });
};

export const updateCachedListTrainingsCourse = (listId) => (dispatch) => {
  dispatch({ type: types.UPDATE_CACHED_LIST_TRAININGS_COURSE, payload: {listId} });
};

export const moveCachedListToTest = (listId) => (dispatch) => {
  dispatch({ type: types.MOVED_CACHED_LIST_TO_TEST, payload: {listId} });
};

export const getUpdatedCourseLists = () => (dispatch) => {
  dispatch({ type: types.GET_UPDATED_COURSE_LISTS });
};

export const getUpdatedCourseData = () => (dispatch) => {
  dispatch({ type: types.GET_UPDATED_COURSE_DATA });
};

export const getUpdatedCurrentListScores = (listId) => (dispatch) => {
  dispatch({ type: types.GET_UPDATED_COURSE_CURRENT_LIST_SCORES, payload: listId });
};

export const getCurrentCourseListData = (listId) => (dispatch) => {
  dispatch({ type: types.GET_CURRENT_COURSE_LIST_DATA, payload: {listId} });
};

export const getAllCourseMistakes = (history) => (dispatch) => {
  dispatch({ type: types.GET_ALL_COURSE_MISTAKES, payload: {history} });
};

export const toggleInCourseVocabulary = (listId, wordId) => (dispatch) => {
  dispatch({ type: types.TOGGLE_IN_COURSE_VOCABULARY, payload: {listId, wordId} });
};

export const deleteCourseWord = (listId, wordId) => (dispatch) => {
  dispatch({ type: types.DELETE_COURSE_WORD, payload: {listId, wordId} });
};

export const shuffleCourseListTrainingWords = (trainingId, listId) => (dispatch) => {
  dispatch({ type: types.SHUFFLE_COURSE_LIST_TRAINING_WORDS, payload: {trainingId, listId} });
};

export const deleteFromCourseVocabulary = (word) => (dispatch) => {
  dispatch({ type: types.DELETE_FROM_COURSE_VOCABULARY, payload: {word} });
};

export const getJustCourseData = (courseId) => (dispatch) => {
  dispatch({ type: types.GET_JUST_COURSE_DATA, payload: {courseId} });
};