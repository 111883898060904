import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import {withRouter, Link} from 'react-router-dom';
import {Helmet} from "react-helmet";

import {Translate, getTranslate} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash, faTrash} from '@fortawesome/free-solid-svg-icons';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Loader from "../../../components/Custom/Loader/index";

import {getHelperArticle, changeArticleFontSize, setCurrentHelperArticle, getHelperArticleBySlug} from '../../../actions/helperArticlesActions';
import {getUserData} from '../../../actions/userActions';
import {fetchingData} from '../../../actions/activity';
import {logActivity} from '../../../actions/logActions';

import colors from '../../../lib/colors';

class HelperArticle extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fontViewVisible: false
    }
  }

  componentDidMount() {
    const {currentHelperArticle, helperArticle} = this.props.helperArticles;

    if (!currentHelperArticle) {
      this.props.fetchingData(true);
      let slug = window.location.href.split('/').pop();
      this.props.getHelperArticleBySlug(slug);
    } else {
      this._getData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._getData();
    }
  }

  componentWillUnmount() {
  }

  _getData = () => {
    const {currentHelperArticle} = this.props.helperArticles;

    this.props.fetchingData(true);
    this.props.getHelperArticle(currentHelperArticle.id);
  };

  _changeArticle = (article, e) => {
    e.preventDefault();
    const {fontSizeRation, locale} = this.props.settings;

    this.props.setCurrentHelperArticle(article);
    this.props.logActivity({activityId: 547, model: 'article', modelId: article.id});

    this.props.history.replace(`/${locale}/article/${article.slug}`, {back: true, article});
    this.props.fetchingData(true);
    this.props.getHelperArticle(article.id);
  };

  _toggleFontVisible = () => {
    this.setState({
      fontViewVisible: !this.state.fontViewVisible
    })
  };

  fontSliderCompleted = async(value) => {
    this.props.changeArticleFontSize(value);
    this.setFont(value);

    this.props.logActivity({activityId: 548, comment: `${value}`});
  };

  setFont = async(value) => {
    const {userId} = this.props.user;

    try {
      localStorage.setItem(`articleFontSize${userId}`, `${value}`);
    } catch (error) {
      console.log(error.message);
    }
  };

  _toggleModal = (modal, status) => {
    this.setState({
      [modal]: status
    });
  };

  render() {
    const {locale} = this.props.settings;
    const {translate} = this.props;
    const {fetchingData} = this.props.activity;
    const {currentHelperArticle, articleFontSize, helperArticle} = this.props.helperArticles;
    let articleText = helperArticle && helperArticle.text ? helperArticle.text : ''

    return (
      <div className="article position-relative">
        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/articles`}>
              {translate('helper_articles')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {helperArticle.title}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container className="pageWrapper">

          <Card style={{minHeight: 200, maxWidth: 960, margin: '20px auto 10px', padding: 20}}>

            <div dangerouslySetInnerHTML={{ __html: articleText }}/>

            {
              helperArticle && helperArticle.connections && helperArticle.connections.length > 0 && (
                <div className="d-flex flex-wrap flex-row justify-content-center align-items-center mt-5">
                  {helperArticle.connections.map((connection, i) => {
                    return (
                      <a href="#" key={i} onClick={(e)=>this._changeArticle(connection.article, e)}>
                        <span style={{fontSize: 16, color: colors.headerBlue}}>
                          {connection.article.title}
                        </span>
                      </a>
                    )
                  })}
                </div>
              )
            }
          </Card>

        </Container>
        <Loader fetchingData={fetchingData}/>

        <Helmet>
          <title>{helperArticle.title}</title>
          <link rel="canonical" href={window.location.href} />
          <meta property="og:type" content="article" />
          <meta name="description" content={helperArticle.short_description} />
          <meta name="keywords" content={helperArticle.keywords} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={helperArticle.meta_image} />
          <meta name="og:description" content={helperArticle.short_description} />
          <meta property="og:title" content={helperArticle.meta_title} />
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  helperArticles: state.helperArticles,
  activity: state.activity,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    getUserData: (userId) => dispatch(getUserData(userId)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    logActivity: (data) => dispatch(logActivity(data)),
    getHelperArticle: (id) => dispatch(getHelperArticle(id)),
    changeArticleFontSize: (size) => dispatch(changeArticleFontSize(size)),
    getHelperArticleBySlug: (slug) => dispatch(getHelperArticleBySlug(slug)),
    setCurrentHelperArticle: (article) => dispatch(setCurrentHelperArticle(article)),

  };
}

export default connect(mapStateToProps, bindAction)(HelperArticle);
