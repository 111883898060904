import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircle, faCogs, faCheckCircle,
  faTrashAlt,
  faCamera,
  faVolumeUp,
  faTimes, faSearch, faPlus
} from '@fortawesome/free-solid-svg-icons';

import ListWord from '../../../components/Cells/ListWord/index';

import ListsToFill from '../../../components/Modals/ListsToFill/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
import {Translate, getTranslate} from 'react-localize-redux';

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import {getUserListsToFill, fillListFromGlossaryWithSelectedWords, getListsNumber} from '../../../actions/lists';
import {createListsFromTests, getTestResults} from '../../../actions/testActions';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

import Loader from "../../../components/Custom/Loader/index";

import colors from '../../../lib/colors';
import * as constants from '../../../lib/constants';

class WordTestResults extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searchBarVisible: false,
      refreshing: false,
      listModalVisible: false,
      listSettingsVisible: false,
      currentList: false,
      totalWords: 50,
      listSize: 10,
      selectedWords: [],
      setSelected: false,
      subscriptionAlertVisible: false,
      subscriptionMessage: '',
      tablet: false,
      from: false,
      token: false,

    };
  }

  componentDidMount() {

    if (this.props.history.location && this.props.history.location.state) {
      this.setState({
        token: this.props.history.location.state.token,
        totalWords: this.props.history.location.state.totalWords,
        from: this.props.history.location.state.from
      });
      this._getData(this.props.history.location.state.token);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      // this._getData();
    }
  }

  componentWillUnmount() {

  }

  _getData = (token) => {
    this.props.fetchingData(true);
    this.props.getTestResults(token);
    this.props.getUserListsToFill();
    this.props.getListsNumber();
  };

  fillLists = (id) => {
    const {subscriptionActive, listsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {listsNumber} = this.props.lists;
    const {translate} = this.props;
    let active = false;

    if (lifetimeStatus || subscriptionActive || webSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      if (id == 0) {
        active = listsNumber.length < listsLimit;
      } else {
        active = true;
      }
    }

    this.toggleListsToFillModal(false);

    if (active) {
      this.props.logActivity({activityId: id != 0 ? 19 : 20, model: 'list', modelId: id});

      this.props.fetchingData(true);
      this.props.fillListFromGlossaryWithSelectedWords({
        listId: id,
        glossaryId: 0,
        glossaryName: translate('vocabulary_test'),
        selectedWords: this.state.selectedWords
      });

      this.setState({
        selectedWords: []
      })
    } else {
      this.setState({
        subscriptionMessage: 'subscription_lists'
      });
      this._toggleSubscriptionAlert(true);
    }
  };

  playTrack = (url) => {
    const {isConnected} = this.props.settings;

    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    if (isConnected) {
      this.track = new Audio(url);
      this.track.play();
    }
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toSubscription = (productId) => {
    this._toggleSubscriptionAlert(false);
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId});
  };

  toggleListsToFillModal = (status) => {
    this.props.logActivity({activityId: 18});

    this.setState({
      listsModalIsVisible: status
    })
  };

  _onPressWord = (word) => {
    let words = this.state.selectedWords;

    const index = words.findIndex(id => id === word.id);

    if (index === -1) {
      words.push(word.id);
    } else {
      words.splice(index, 1);
    }

    this.setState({
      setSelected: !this.state.setSelected,
      selectedWords: words
    });
  };

  _renderItem = (item, index) => {
    const {fontSizeRation, version} = this.props.settings;
    const {tablet, selectedWords} = this.state;
    const {translate} = this.props;
    const imageUrl = item && item.image && item.image.filename ? item.image.filename : false;
    const {userData, userId} = this.props.user;
    const imageUserId = item && item.image && item.image.user_id ? item.image.user_id : false;

    let selected = selectedWords.indexOf(item.id);

    let circleColor = colors.greyishBrown;

    const translation = item && item.translation ? item.translation : "";

    let audio = item && item.audio ? item.audio : false;
    let audioSource = constants.BACKEND_URL;

    if (userData && userData.audio_language && item[userData.audio_language]) {
      audio = item[userData.audio_language];
      audioSource = constants.S3
    }

    const imageWidth = !tablet ? 150 : 200;
    const wordName = item && item.word ? item.word : "";
    const transcription = item && item.transcription ? item.transcription : "";

    return (
      <ListWord
        key={index}

        wordName={wordName}
        transcription={transcription}
        imageWidth={imageWidth}
        translation={translation}
        audio={audio}
        audioSource={audioSource}
        userData={userData}
        word={item}
        index={index}
        imageUrl={imageUrl}
        imageUserId={imageUserId}
        userId={userId}
        inVocabulary={item.in_vocabulary}
        inFavorite={item.is_favourite}
        tablet={tablet}
        version={version}
        ratio={fontSizeRation}
        onPlay={this.playTrack}
        onPressWord={this._onPressWord}
        bulkMode={true}
        circleColor={circleColor}
        selected={selected !== -1}
        translate={translate}
        favoriteWordToggle={false}
        vocabularyToggle={false}
        bulkMode={'move'}
        
        test={true}
      />
    )
  };

  toggleSelected = (e) => {
    e.preventDefault();

    const {selectedWords} = this.state;
    const {result} = this.props.test;

    if (selectedWords.length > 0) {
      this.setState({
        selectedWords: []
      })
    } else {
      let selected = result.words.map((item)=> {
        return item.id
      });

      this.setState({
        selectedWords: selected
      })
    }
  };

  navigateTo = () => {
    const {token, from} = this.state;

    if (from == 'test') {
      /*const resetAction = StackActions.reset({
        index: 0,
        actions: [NavigationActions.navigate({routeName: 'Home'})],
      });
      this.props.navigation.dispatch(resetAction);*/
    } else {
      // this.props.navigation.navigate('WizardLevel', {token})
    }
  };

  render() {
    const {selectedWords, listsModalIsVisible, subscriptionAlertVisible, subscriptionMessage, tablet} = this.state;
    const {result} = this.props.test;
    const {listsToFill} = this.props.lists;
    const {translate} = this.props;
    const {totalWords} = this.state;
    const {userData} = this.props.user;
    const {fontSizeRation,locale} = this.props.settings;
    const {fetchingData} = this.props.activity;

    const listSize = userData ? userData.list_size : 10;

    let words = result ? result.words : [];
    let scores = result ? result.scores : null;

    let level = 0;

    let levelText = "";
    let resultText = "";
    let pageText = translate('test_results_level');

    if (result) {
      switch (result.index) {
        case 0:
          level = 0;
          levelText = "Beginner";
          resultText = "could_be_better";
          break;
        case 1:
          level = 1;
          levelText = "Elementary";
          resultText = "not_bad";
          break;
        case 2:
          level = 2;
          levelText = "Intermediate";
          resultText = "very_well";
          break;
        case 3:
          level = 3;
          levelText = "Advanced";
          resultText = "amazing_results";
          break;
        case -1:
          levelText = "";
          resultText = "refused_from_test";
          break;
        default:
          console.log("default")
      }
    }

    pageText = pageText.replace('%@', `${levelText}`);

    return (
      <div className="wordtestresults">
        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate("vocabulary_test_results")}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container className="pageWrapper">


          {/*<p className="resultText" style={{marginVertical: !tablet ? 5 : 15}}>
            {resultText ? translate(resultText) : ""}
          </p>*/}

          {result && <p className="correctAnswers mb-1">
            {translate("know")}{" "}{result ? result.total_correct : 0}/{totalWords}{" "}{translate("lwords")}
          </p>}

          {scores !== null && <div className="mb-2">
           <p className="text-center mb-0">{translate('earnedScores')}{" "}{scores} {translate('scores')}</p>
           </div>}

          {result && <p className="levelDescription" style={{fontSize: fontSizeRation * 16, marginVertical: !tablet ? 5 : 10}}>
            {pageText}
          </p>}

          {
            result && result.words.length > 0 && (
              <Card className="deleteListsTextWrapper">
                  <span className="bulkModeText font-weight-bold" style={{fontSize: fontSizeRation * 16}}>
                    {translate(`words_could_learn`)}
                  </span>


                <div className="bulkModeWrapper justify-content-between align-items-center flex-md-row flex-sm-column flex-column" style={{marginTop: 10}}>

                  <a href="#"
                     className="mr-3 mb-0 mb-sm-2 mb-md-0"
                     onClick={this.toggleSelected}>
                    <FontAwesomeIcon
                      className="selectedIcon"
                      icon={selectedWords.length > 0 ? faCheckCircle : faCircle}
                      style={{
                      color: selectedWords.length > 0 ? colors.tealish : colors.lightGreyTwo,
                      marginRight: !tablet ? 10 : 20, fontSize: !tablet ? 20 : 30}}/>
                    <span
                      className="selectAllWords pv4"
                      style={{marginRight: 10, color: colors.greyishBrown, fontSize: fontSizeRation * 16}}>
                      {selectedWords.length > 0 ? translate('deselect_words') : translate('select_all_words')}
                    </span>
                  </a>

                  <span className="mr-2 mr-2">{translate('selected_words')} {selectedWords.length}</span>

                  <Button
                    disabled={selectedWords.length == 0}
                    variant="outline-info"
                    className="mr-3 mb-0 mb-sm-2 mb-md-0"

                    onClick={()=> {
                      if(selectedWords.length > 0) {
                          this.toggleListsToFillModal(true)
                        }
                    }}>

                    {translate('add_words_to_list')}
                  </Button>
                </div>
              </Card>
            )
          }


          <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
            {
              words.map((list, index) => {
                return this._renderItem(list, index);
              })
            }
          </div>

          {listsModalIsVisible && (
            <ListsToFill
              isVisible={listsModalIsVisible}
              lists={listsToFill}
              fillLists={this.fillLists}
              listSize={listSize}
              ratio={fontSizeRation}
              selectedWords={selectedWords.length}
              onClose={this.toggleListsToFillModal}
              translate={translate}

            />
          )}

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={subscriptionMessage}
                translate={translate}

              />
            )
          }

        </Container>

        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  test: state.test,
  lists: state.lists,
  user: state.user,
  purchases: state.purchases,
  settings: state.settings,
  translate: getTranslate(state.localize),
  activity: state.activity,

});

function bindAction(dispatch) {
  return {
    createListsFromTests: (selectedWords) => dispatch(createListsFromTests(selectedWords)),
    fillListFromGlossaryWithSelectedWords: (data) => dispatch(fillListFromGlossaryWithSelectedWords(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    getTestResults: (token) => dispatch(getTestResults(token)),

    logActivity: (data) => dispatch(logActivity(data)),
    getListsNumber: () => dispatch(getListsNumber()),
    getUserListsToFill: () => dispatch(getUserListsToFill()),

  };
}

export default connect(mapStateToProps, bindAction)(WordTestResults);