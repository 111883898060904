import * as types from './types';

export const getTestWord = (currentWordIndex, token) => (dispatch) => {
    dispatch({ type: types.GET_TEST_WORD, payload: {currentWordIndex, token} });
};

export const getTestResults = (token) => (dispatch) => {
    dispatch({ type: types.GET_TEST_RESULTS, payload: token });
};

export const resetTestWord = (currentWordIndex, token) => (dispatch) => {
    dispatch({ type: types.SET_TEST_WORD, payload: false });
};

export const logTestWord = (wordId, token, result, level) => (dispatch) => {
    dispatch({ type: types.LOG_TEST_WORD, payload: {wordId, token, result, level} });
};

export const generateLists = (words, listSize, glossaryId, level, checkInLists) => (dispatch) => {
    dispatch({ type: types.GENERATE_LISTS, payload: {words, listSize, glossaryId, level, checkInLists} });
};

export const createListsFromTests = (words) => (dispatch) => {
    dispatch({ type: types.SAVE_WORDS_AFTER_TEST, payload: words });
};


export const checkDictionaryWord = (token, word) => (dispatch) => {
  dispatch({ type: types.CHECK_WORD_EXTRACTOR_WORD, payload: {token, word} });
};

export const logWordExtractorResults = (words, scores) => (dispatch) => {
  dispatch({ type: types.GET_WORD_EXTRACTOR_RESULTS, payload: {words, scores} });
};

export const resetExtractorWords = () => (dispatch) => {
  dispatch({ type: types.RESET_EXTRACTOR_WORDS });
};

export const getHangmanScores = () => (dispatch) => {
  dispatch({ type: types.GET_HANGMAN_SCORES });
};

export const getHangmanWord = () => (dispatch) => {
  dispatch({ type: types.GET_HANGMAN_WORD });
};

export const resetHangmanWord = () => (dispatch) => {
  dispatch({ type: types.SET_HANGMAN_WORD, payload: false });
};

export const logHangmanWord = (data) => (dispatch) => {
  dispatch({ type: types.LOG_HANGMAN_WORD, payload: data });
};