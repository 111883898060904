import React, {PureComponent} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faStar} from '@fortawesome/free-solid-svg-icons';

import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import colors from '../../../lib/colors';

const PhraseCell = ({phrase, playTrack, ratio, version, tablet, height, audio, audioSourse, inFavorite, onFavorite, translate}) => (
  <Card className="phrase wordContainer">
    <div className="wordWrapper">
      <div className="wordTranslationWrapper">
        <span className="word" style={{fontSize: ratio * (!tablet ? 14 : 16)}}>
          {phrase.phrase}
        </span>
        <span className="translation" style={{fontSize: ratio * (!tablet ? 10 : 12)}}>
          {phrase.translation}
        </span>
      </div>

      <div className="rightPartWrapper pr-2">
        <OverlayTrigger
          delay={{ show: 1000, hide: 400 }}
          placement='auto' overlay={<Tooltip>{translate(inFavorite ? 'delete_from_favorites' : 'add_to_favorites')}</Tooltip>}
        >
          <a href="#" className="mr-3 p-1" onClick={(e) => onFavorite(phrase.id, inFavorite, e)}>
            <FontAwesomeIcon icon={faStar}
                             style={{fontSize: 15, color: inFavorite ? colors.golden : colors.greyishBrown5}}/>
          </a>
        </OverlayTrigger>

        {
          audio && (
            <a href="#" className="p-1" onClick={(e) => playTrack(`${audioSourse}${audio}`, e)}>
              <FontAwesomeIcon icon={faVolumeUp} style={{fontSize: 15}}/>
            </a>
          )
        }
      </div>
    </div>
  </Card>
);

export default PhraseCell;