import * as types from './types';

export const getConnectedAccounts = () => (dispatch) => {
    dispatch({ type: types.GET_CONNECTED_ACCOUNTS });
};

export const deleteConnected = (connectedId) => (dispatch) => {
    dispatch({ type: types.DELETE_CONNECTED_ACCOUNT, payload: {connectedId} });
};

export const createChildAccount = (fullName, email, endAt) => (dispatch) => {
    dispatch({ type: types.CREATE_CHILD_ACCOUNT, payload: {fullName, email, endAt} });
};

export const checkPasswordToConnect = (email, password) => (dispatch) => {
    dispatch({ type: types.CHECK_PASSWORD_TO_CONNECT, payload: {email, password} });
};

export const closePasswordModal = () => (dispatch) => {
    dispatch({ type: types.NEED_PASSWORD_TO_CONFIRM, payload: false });
};

export const setAccountConnected = (status) => (dispatch) => {
    dispatch({ type: types.ACCOUNT_CONNECTED, payload: status });
};

