import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faCircle} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import * as helpers from '../../../lib/validators';

class ListName extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      listName: ''
    };
    
    this.inputRef = React.createRef();
  }
  
  componentDidMount() {
    this.setState({
      listName: this.props.list.list_name
    })
  }

  setText = (text) => {
    this.setState({
      listName: helpers.sanitizeString(text, 25)
    })
  };

  render() {
    const {
      translate,
      isVisible,
      onClose,
      handleList,
      handleKeyPress
    } = this.props;
    
    const {listName} = this.state;

    return (
      <Modal
        size="sm"
        onShow={() => this.inputRef.current.focus()}
        className="listnamemodal"
        show={isVisible}
        onHide={()=>onClose(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="update_list"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">
          <div className="position-relative">
              <FormControl
                onKeyPress={(e)=> handleKeyPress('list', e)}
                ref={this.inputRef}
                style={{fontSize: 12}}
                value={listName}
                onChange={(e)=> this.setText(e.target.value)}
              />
            
            <Button
              className="mt-3"
              block
              disabled={listName.length == 0}
              variant="outline-secondary"
              onClick={()=>handleList(listName)}>
              {translate('update')}
            </Button>

          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(null, null)(ListName);
