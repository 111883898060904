import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {Helmet} from "react-helmet";

import {Translate, getTranslate} from 'react-localize-redux';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faPlusCircle, faTimes} from '@fortawesome/free-solid-svg-icons';
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';

import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import TrainingSubHeader from '../../../components/Headers/TrainingSubHeader/index';
import FinishTrainingButtons from '../../../components/Modals/FinishTrainingButtons/index';
import WordSettings from '../../../components/Modals/WordSettings/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
import ChangeAudioLanguage from '../../../components/Modals/ChangeAudioLanguage/index';
import ReportBug from '../../../components/Modals/ReportBug/index';
import TrainingSettings from '../../../components/Modals/TrainingSettings/index';
import RegisterAlert from '../../../components/Modals/RegisterAlert/index';

import Loader from "../../../components/Custom/Loader/index";

import {
  getWordsForTraining,
  resetWords,
  resetOnlyWords,
  toggleShowCards,
  setMemoryCardsSettings,
  setCachedMemoryCardsWords,

  moveNewListToRepeat,
  removeListFromRepeatSection,
  updateListTrainings,
  updateTrainingsListFromRepeatSection,
  resetCurrentTrainingId,
  setCurrentTrainingId,
  shuffleTrainingWords,
  toggleShuffleWords
} from '../../../actions/trainingActions';
import {
  updateCachedListTrainings,
  updateCachedListTrainingsAndIntervals,
  shuffleListTrainingWords
} from '../../../actions/cacheActions';
import {
  logListWord,
  logUserList,
  logGlossaryWord,
  logPhrase,
  logScores,
  logCourseList,
  logCourseListWord,
  logActivity
} from '../../../actions/logActions'
import {getListsToRepeat, toggleListTrainingButtonsModal, getOnlyMistakes} from '../../../actions/lists';
import {fetchingData} from '../../../actions/activity';
import {
  toggleListTrainingButtonsModalCourse,
  getUpdatedCourseLists,
  getUpdatedCourseData,
  getUpdatedCurrentListScores,
  shuffleCourseListTrainingWords
} from '../../../actions/courseActions';
import {logTrainingLimits} from '../../../actions/purchasesActions';

import {checkForCachedWordsMemoryCards, checkForCourseCachedWordsMemoryCards} from '../../../lib/trainingFunctions';

import * as constants from '../../../lib/constants';
import colors from '../../../lib/colors';

class MemoryCards extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
      translationsHeight: 40,
      currentPage: 0,
      totalRepeated: 1,
      downloads: 0,
      totalWords: 0,
      currentChunk: 0,
      scores: 0,
      score: 5,
      finishButtonsModalVisible: false,
      trainingInfoVisible: false,
      audioModalVisible: false,
      bugModalVisible: false,
      trainingSettingsModalVisible: false,
      registerModalVisible: false,
      tour: localStorage.getItem('tour'),

      trainingId: 9,
      showImage: true,
      answered: false,
      indicesToHide: [],
      ratio: 1,
      selectedItems: 0,
      buttons: [],
      buttonIndices: [],
      usedIndices: [],
      selectedCards: [],
      mistakes: 0,
      guessed: 0,
      tapped: [],
      guessedIndices: [],
      initialTimer: true,
      tappedIndices: [],
      countTime: 5,
      timer: 1,
      seconds: 5,
      counter: null,
      trainingName: 'Memory Cards',
      autoslide: "1",
      autoslide_delay: "2",
      autosound_word: "1",
      wordSettingsModalVisible: false,
      freeze: false,
      finishedTraining: false,
      subscriptionMessage: 'subscription_trainings',
      subscriptionAlertVisible: false,
      shuffle: true,
      exiting: false,

      tablet: false,

      entityId: this.props.match.params.id,
      listId: undefined,
      courseListId: undefined,
      from: false,
      type: false,
      inside: false
    };

    this.track = null;

    this.timeOut = null;
    this.timeOut1 = null;
    this.timeOut2 = null;
    this.timeOut3 = null;
    this.timeOut4 = null;
    this.timeOut5 = null;
    this.timeOut6 = null;
    this.timeOut7 = null;
  }

  componentDidMount() {
    document.addEventListener("keydown", this._handleKeyDown);

    this._setTrainingSettings();

    this.setState({currentPage: 1});

    if (!this.props.history.location || !this.props.history.location.state || !this.props.history.location.state.type) {
      this.props.history.goBack();
    } else {
      this.setState({
        [this.props.history.location.state.type]: parseInt(this.props.match.params.id, 10),
        from: this.props.history.location.state.from,
        type: this.props.history.location.state.type,
        inside: this.props.history.location.state.inside,
      })
    }

    this.timeOut = setTimeout(()=> {
      this.getWords(1);
    }, 100);

    this.props.setCurrentTrainingId(9);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data.words !== prevProps.data.words) {
      this.intervalId = setInterval(this.timer, 50);
    }

    const {words} = this.props.data;

    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      if (words.length == 0) {
        this.getWords();
      }
    }
  }

  timer = () => {
    this.setState({
      seconds: this.state.seconds - 0.05
    });

    if (parseInt(this.state.seconds, 10) <= 0) {
      clearInterval(this.intervalId);
      this.setState({
        seconds: 0
      })
    }
  };

  componentWillUnmount() {
    const {listId, courseListId} = this.state;

    this.props.resetWords();
    this.props.resetCurrentTrainingId();
    
    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut1);
    clearTimeout(this.timeOut2);
    clearTimeout(this.timeOut3);
    clearTimeout(this.timeOut4);
    clearTimeout(this.timeOut5);
    clearTimeout(this.timeOut6);
    clearTimeout(this.timeOut7);

    clearInterval(this.intervalId);

    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    if (listId != undefined) {
      this.onUnmountUpdates();
    }

    if (courseListId != undefined) {
      this.onUnmountCourseUpdates();
    }

    document.removeEventListener("keydown", this._handleKeyDown);
  }

  _handleKeyDown = (event) => {
    const {freeze} = this.state;

    switch (event.keyCode) {
      case 39:
        if (!freeze) {
          this.changeWord('left')
        }
        break;
      case 37:
        if (!freeze) {
          this.changeWord('right')
        }
        break;

      case 27:
        this.props.history.goBack();
        break;
      default:
        break;
    }
  };

  _shuffleCourseWords = () => {
    const {trainingId, courseListId} = this.state;
    const {userData: {shuffle}} = this.props.user;

    if (courseListId && courseListId > 0 && shuffle == "1") {
      this.props.shuffleCourseListTrainingWords(trainingId, courseListId)
    }
  };

  _shuffleWords = () => {
    const {trainingId} = this.state;
    const {currentList} = this.props.lists;
    const {userData: {shuffle}} = this.props.user;

    if (currentList && currentList.id > 0 && shuffle == "1") {
      this.props.shuffleListTrainingWords(trainingId, currentList.id)
    }
  };

  onUnmountUpdates = () => {
    const {finishedTraining} = this.state;
    const {currentList} = this.props.lists;

    this._shuffleWords();

    if (finishedTraining) {
      this.moveLists();
    }

    if (currentList && currentList.id == 0) {
      // this.props.getOnlyMistakes();
    }
  };

  moveLists = () => {
    const {currentList} = this.props.lists;

    let today = moment().format("YYYY-MM-DD");
    let todayTrainings = null;

    currentList.intervals && currentList.intervals.forEach(interval => {
      if (interval.repetition_date == today) {
        todayTrainings = interval.trainings;
      }
    });

    if (currentList && currentList.id > 0) {
      if (currentList.intervals && currentList.intervals.length != 0 && todayTrainings == 0) {
        this.props.updateTrainingsListFromRepeatSection(currentList.id);
      } else if (todayTrainings == 0) {
        this.props.moveNewListToRepeat(currentList);
      } else if (todayTrainings == 1) {
        this.props.updateTrainingsListFromRepeatSection(currentList.id);
      } else if (todayTrainings >= 2) {
        this.props.removeListFromRepeatSection(currentList.id);
        this.props.updateCachedListTrainings(currentList.id);
      } else if (todayTrainings == null) {
        this.props.moveNewListToRepeat(currentList);
        this.props.updateCachedListTrainingsAndIntervals(currentList);
      }
    }
  };

  onUnmountCourseUpdates = () => {
    const {finishedTraining, courseListId, inside} = this.state;

    this._shuffleCourseWords();

    if (inside == undefined) {
      this.props.fetchingData(true);
    }

    if (finishedTraining || courseListId == 0) {
      this.props.getUpdatedCourseLists();
    } else {
      this.props.getUpdatedCourseData();
    }

    if (courseListId != 0) {
      this.props.getUpdatedCurrentListScores(courseListId);
    }
  };

  _logOnExit = () => {
    const {words, totalPages} = this.props.data;
    const {logUserList, logCourseList} = this.props;
    const {totalRepeated, trainingId, currentPage, listId, courseListId} = this.state;

    const percentage = currentPage / totalPages;

    this.setState({
      exiting: true
    });

    if (percentage >= 1 && listId && listId > 0) {
      this.setState({
        finishedTraining: true
      });

      logUserList({trainingId, listId, type: "end"})
    }

    if (percentage >= 1 && courseListId && courseListId != 0) {
      this.setState({
        finishedTraining: true
      });

      logCourseList({trainingId, listId: courseListId, type: "end"});
    }
  };

  _setTrainingSettings = async() => {
    const {userId} = this.props.user;
    const {trainingId} = this.state;

    const autoslide = localStorage.getItem(`autoslide_${trainingId}_${userId}`);
    const autoslide_delay = localStorage.getItem(`autoslide_delay_${trainingId}_${userId}`);
    const autosound_word = localStorage.getItem(`autosound_word_${trainingId}_${userId}`);

    if (autoslide != null) {
      this.setState({autoslide})
    } else {
      localStorage.setItem(`autoslide_${trainingId}_${userId}`, '1');
    }

    if (autoslide_delay != null) {
      this.setState({autoslide_delay})
    } else {
      localStorage.setItem(`autoslide_delay_${trainingId}_${userId}`, '2');
    }

    if (autosound_word != null) {
      this.setState({autosound_word})
    } else {
      localStorage.setItem(`autosound_word_${trainingId}_${userId}`, '1');
    }

    this.props.setMemoryCardsSettings({
      autoslide: autoslide != null ? autoslide : "1",
      autoslide_delay: autoslide_delay != null ? autoslide_delay : "2",
      autosound_word: autosound_word != null ? autosound_word : "1"
    })
  };

  changeCards = (direction) => {
    if (direction == "right") return null;

    const {totalPages} = this.props.data;
    const {currentPage, listId, courseListId} = this.state;
    var index = currentPage;
    var getCards = false;
    const {userData} = this.props.user;

    let last = false;

    if (direction == "left") {
      if (currentPage == totalPages) {
        last = true;

        if ((listId != undefined && listId == 0) || (courseListId != undefined && courseListId == 0) || totalPages === 1) {
          this.props.history.goBack();
        } else {
          let active = this._checkTrainingsLimit();

          if (active) {
            if(userData && userData.active_trainings_set) {
              this.setState({
                finishedTraining: true
              });
              
              this._goToNextTraining()
            } else {
              this.setState({
                finishButtonsModalVisible: true
              })
            }
          } else {
            this._toggleSubscriptionAlert(true);
          }
        }
      } else {
        index = currentPage + 1;
        getCards = true;

        this.setState({
          totalRepeated: this.state.totalRepeated + 1
        })
      }
    } else {
      if (currentPage == 1) {
        getCards = true;

        index = currentPage
      } else {
        index = currentPage - 1
      }
    }

    this.setState({
      currentPage: index,
      selectedIndex: null,
      answered: false,
      timesTapped: 0,
      seconds: 5,
      currentCharIndex: -1,
      tapped: [],
      guessedIndices: [],
      initialTimer: true,
      tappedIndices: [],
      freeze: false
    });

    if (getCards) {
      this.props.toggleShowCards(false);

      if (this.state.exiting) return;

      this.timeOut1 = setTimeout(() => {
        if (this.state.exiting) return;

        this.getWords(index);
      }, 150);
    }
  };

  _goToNextTraining = () => {
    const {logUserList, logCourseList} = this.props;

    const {listId, courseListId, type, trainingId} = this.state;
    const {userData} = this.props.user;
    const {locale} = this.props.settings;

    if (listId && listId > 0) {
      logUserList({trainingId, listId, type: "end"})
    } else if (courseListId && courseListId != 0) {
      logCourseList({trainingId, listId: courseListId, type: "end"});
    }
    
    let currentListId = null;

    if (listId != undefined) {
      currentListId = listId;
    }

    if (courseListId != undefined) {
      currentListId = courseListId;
    }

    let trainingIds = userData.trainings_set != null ? userData.trainings_set.split(',').map(Number) : [];

    const trainingIndex = trainingIds.length > 0 ? trainingIds.indexOf(trainingId) : null;

    if(trainingIndex === null || trainingIndex == (trainingIds.length - 1)) {
      this.setState({
        finishButtonsModalVisible: true
      });
      return;
    }

    if(currentListId != 0) {
      let activityId = '';
      let path = '';

      if (trainingIds[trainingIndex+1] == 1) {
        activityId = 39;
        path = 'preview'
      } else if (trainingIds[trainingIndex+1] == 6) {
        path = 'image';
        activityId = 40;
      } else if (trainingIds[trainingIndex+1] == 2) {
        path = 'translation';
        activityId = 41;
      } else if (trainingIds[trainingIndex+1] == 4) {
        path = 'word';
        activityId = 42;
      } else if (trainingIds[trainingIndex+1] == 3) {
        path = 'collect';
        activityId = 43;
      } else if (trainingIds[trainingIndex+1] == 5) {
        path = 'spelling';
        activityId = 44;
      } else if (trainingIds[trainingIndex+1] == 8) {
        activityId = 114;
        path = 'sentence';
      } else if (trainingIds[trainingIndex+1] == 9) {
        activityId = 131;
        path = 'memory'
      } else if (trainingIds[trainingIndex+1] == 10) {
        path = 'definition';
        activityId = 148;
      } else if (trainingIds[trainingIndex+1] == 7) {
        activityId = 95;
      } else if (trainingIds[trainingIndex+1] == 12) {
        path = 'missing';
        activityId = 315;
      } else if (trainingIds[trainingIndex+1] == 14) {
        path = 'listening';
        activityId = 345;
      } else if (trainingIds[trainingIndex+1] == 15) {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut4 = setTimeout(()=> {
        this.props.history.replace(`/${locale}/${path}/${currentListId}`, {
          type,
          from: 'inside',
          listId: currentListId
        });
      }, 100);

      this.props.logActivity({activityId, model: 'list', modelId: currentListId, comment: 'from home'});

    } else {
      this.setState({
        finishButtonsModalVisible: true
      })
    }
  };

  _checkTrainingsLimit = () => {
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;

    const {userData} = this.props.user;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    let active = false;

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    return active;
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    });

    if (!status) {
      this.props.history.goBack();
    }
  };

  _toSubscription = (productId) => {
    this.setState({
      subscriptionAlertVisible: false
    });
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId});
  };

  playSound = (url) => {
    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    this.track = new Audio(url);
    if(this.track != null) {
      this.track.play();
    }
  };

  toSettings = () => {
    this._toggleTrainingSettingsModal(true);
    this.props.logActivity({activityId: 189, comment: 'from memory cards'});
  };

  getWords = (page) => {
    const {memoryCardsCachedWords} = this.props.cache;
    const {memoryCardsCourseCachedWords} = this.props.courseCache;
    const {isConnected} = this.props.settings;

    const {logUserList, logCourseList} = this.props;
    const {trainingId, courseListId, listId, glossaryId} = this.state;

    let params = {
      trainingId,
      page,
      history: this.props.history
    };

    if (listId >= -2) {
      params.listId = listId;
      params.endpoint = 'getTrainingWords'
    } else if (courseListId !== false) {
      params.courseListId = courseListId;
      params.endpoint = 'getCourseTrainingWords'
    } else if (glossaryId) {
      params.glossaryId = glossaryId;
      params.endpoint = 'getGlossaryWordsForTraining'
    }

    let cachedWords = false;

    if (listId) {
      cachedWords = checkForCachedWordsMemoryCards(memoryCardsCachedWords, listId, page);
    } else if (courseListId) {
      cachedWords = checkForCourseCachedWordsMemoryCards(memoryCardsCourseCachedWords, courseListId, page);
    }

    if (!cachedWords) {
      if (isConnected) {
        if (page == 1) {
          this.props.fetchingData(true);
        }
        this.props.getWordsForTraining(params);
      }
    } else {
      this.props.setCachedMemoryCardsWords(cachedWords);
      this.props.toggleShowCards(true);

      if (page == 1) {
        this.props.logTrainingLimits();

        if (listId && listId > 0) {
          logUserList({trainingId, listId, type: "start"})
        }

        if (courseListId && courseListId > 0) {
          logCourseList({trainingId, listId: courseListId, type: "start"});
        }
      }
    }
  };

  closeFinishButtonsModal = () => {
    this.setState({
      finishButtonsModalVisible: false
    });
  };

  continueTraining = () => {
    const {logUserList, logCourseList} = this.props;
    const {trainingId, listId, courseListId} = this.state;

    const {userData: {shuffle}} = this.props.user;

    if (listId && listId > 0) {
      if (shuffle == "1") {
        this.props.shuffleTrainingWords();
      }

      logUserList({trainingId, listId, type: "end"});

      if (this.state.exiting) return;

      this.timeOut2 = setTimeout(() => {
        if (this.state.exiting) return;

        logUserList({trainingId, listId, type: "start"})
      }, 1000);
    }

    if (courseListId && courseListId != 0) {
      if (shuffle == "1") {
        this.props.shuffleTrainingWords();
      }

      logCourseList({trainingId, listId: courseListId, type: "end"});

      if (this.state.exiting) return;

      this.timeOut3 = setTimeout(()=> {
        if (this.state.exiting) return;

        logCourseList({trainingId, listId: courseListId, type: "start"});
      }, 1000);
    }

    this.setState({
      finishButtonsModalVisible: false,
      currentPage: 1,
      totalRepeated: 1
    });

    if (this.state.exiting) return;

    this.timeOut4 = setTimeout(() => {
      if (this.state.exiting) return;

      this.getWords(1);
    }, 150);

    this.props.logTrainingLimits();

    this.props.logActivity({activityId: 133});
  };

  anotherTraining = () => {
    const {logUserList, logCourseList} = this.props;
    const {trainingId, listId, courseListId, from} = this.state;

    if (listId !== undefined && from == 'listWords') {
      this.props.toggleListTrainingButtonsModal(true)
    }

    if (courseListId !== undefined && from == 'listWords') {
      this.props.toggleListTrainingButtonsModalCourse(true)
    }

    if (listId && listId > 0) {
      logUserList({trainingId, listId, type: "end"})
    } else if (courseListId && courseListId != 0) {
      logCourseList({trainingId, listId: courseListId, type: "end"});
    }

    this.setState({
      finishButtonsModalVisible: false,
      finishedTraining: true
    });

    this.props.history.goBack();

    this.props.logActivity({activityId: 190, comment: 'Memory Cards'});
  };

  _log = (mistake) => {
    const {words} = this.props.data;
    const {currentIndex} = this.state;
    let word = words.length > 0 ? words[currentIndex] : false;

    const {trainingId, listId, courseListId, glossaryId} = this.state;

    if (word) {
      let params = {
        trainingId: trainingId,
        mistake,
        id: word.id
      };

      if (listId >= -1) {
        params.listId = listId;
        this.props.logListWord(params);
      } else if (glossaryId) {
        params.glossaryId = glossaryId;
        this.props.logGlossaryWord(params);
      } else if (courseListId !== false) {
        params.courseListId = courseListId;
        this.props.logCourseListWord(params);
      }
    }
  };

  cardTapped = (type, id, i, e) => {
    e.preventDefault();

    const {tapped, tappedIndices, seconds} = this.state;

    let tap = {type, id, i};

    if (tapped.length > 0 && tapped[0].i === i) return;
    if (tapped.length === 2 || seconds > 0) return;

    let taps = tapped;
    let tI = tappedIndices;

    taps.push(tap);
    tI.push(i);

    this.setState({
      tapped: taps,
      tappedIndices: tI
    });

    if (this.state.tapped.length == 2) {
      this.checkTapped();
    }
  };

  checkTapped = () => {
    const {tapped, guessedIndices, autoslide, autoslide_delay, autosound_word} = this.state;
    const {words, autoslide_memory_cards, autoslide_delay_memory_cards, autosound_word_memory_cards} = this.props.data;
    const {userData} = this.props.user;
    let audioSource = constants.BACKEND_URL;

    if (tapped[0].type !== tapped[1].type && tapped[0].id === tapped[1].id) {
      if (this.state.exiting) return;

      this.timeOut5 = setTimeout(()=> {
        if (this.state.exiting) return;

        let guessed = guessedIndices;
        guessed.push(tapped[0].i);
        guessed.push(tapped[1].i);

        if (autosound_word == '1') {
          const wordId = tapped[0].id;

          let word = words.filter(word => word.id === wordId);

          if (word && word.length > 0) {
            let url = word[0].audio ? word[0].audio : false;


            if (userData && userData.audio_language && word[0][userData.audio_language]) {
              url = word[0][userData.audio_language];
              audioSource = constants.S3
            }

            if (url) {
              if (autosound_word_memory_cards == "1") {
                this.playSound(`${audioSource}${url}`)
              }
            }
          }
        }

        this.setState({
          guessedIndices: guessed
        });

        if (guessed.length === 10) {
          let result = 5 - this.state.mistakes;
          result = result == 5 ? result * 2 : result;

          if (result > 0) {
            this.props.logScores(result * this.state.score);
            this.setState({
              scores: this.state.scores + (result * this.state.score)
            })
          }

          if (autoslide_memory_cards == '1') {
            this.setState({
              freeze: true
            });

            if (this.state.exiting) return;

            this.timeOut6 = setTimeout(() => {
              if (this.state.exiting) return;

              this.changeCards("left");
            }, parseFloat(autoslide_delay_memory_cards) * 1000)
          }
        }
      }, 500);
    } else {
      this.setState({
        mistakes: this.state.mistakes + 1
      })
    }

    if (this.state.exiting) return;

    this.timeOut7 = setTimeout(()=> {
      if (this.state.exiting) return;

      this.setState({
        tapped: [],
        tappedIndices: []
      })
    }, 1000)
  };

  makeButtons = (words, shuffledIndices) => {
    if (words.length === 0 || words.length < 5) return;

    const {guessedIndices, tapped, tappedIndices, seconds, tablet} = this.state;
    const {fontSizeRation} = this.props.settings;

    let wordIndices = [];
    let imageIndices = [];

    shuffledIndices.map((item, i) => {
      if (i <= 4) {
        wordIndices.push(item)
      }
      if (i >= 5) {
        imageIndices.push(item)
      }
    });

    let currentWordIndex = 0;
    let currentImageIndex = 0;
    let buttttons = [];

    shuffledIndices.map((item, i) => {

      if (wordIndices.indexOf(i) !== -1) {
        let word = words[currentWordIndex];
        currentWordIndex++;

        buttttons.push(
          <div
            key={i}
            onClick={(e)=> this.cardTapped('word', word.id, i, e)}
            className="wordcard"
            style={{backgroundColor: seconds <= 0 ? tappedIndices.indexOf(i) === -1 ? colors.tealish : colors.white : colors.white,
               opacity: guessedIndices.indexOf(i) === -1 ? 1 : 0,
               
               }}>
              <span
                className="wordCardText"
                style={{fontSize: fontSizeRation *16}}>
                {word.word}
              </span>
          </div>
        )
      }

      if (imageIndices.indexOf(i) !== -1) {
        let word = words[currentImageIndex];
        currentImageIndex++;
        const imageUrl = word && word.image && word.image.filename ? word.image.filename : false;

        const imageWidth = 150;

        const s3image = `${constants.S3MarkedImages}-${imageWidth}/${imageUrl}`;

        buttttons.push(
          <div
            onClick={(e)=>{this.cardTapped('image',word.id, i, e)}}
            key={i}
            className="wordcard"
            style={{backgroundColor: seconds > 0 || tappedIndices.indexOf(i) !== -1 ? colors.white : colors.tealish,
              opacity: guessedIndices.indexOf(i) === -1 ? 1 : 0,
                        }}>
            <Image
              className="imageCard"
              src={s3image}
              style={{opacity: seconds <= 0 ? tappedIndices.indexOf(i) === -1 ? 0: 1 : 1}}
            />
          </div>
        )
      }
    });

    return buttttons;
  };

  toggleInfoModal = (status) => {
    this.setState({
      trainingInfoVisible: status
    })
  };

  _toggleAudioModal = (status) => {
    this.setState({
      audioModalVisible: status
    });
  };

  _openTrainingInfo = () => {
    this.toggleWordSettingsModal(false);
    this.toggleInfoModal(true);
  };

  _reportTrainingBug = () => {
    this.toggleWordSettingsModal(false);

    let extra = {};

    const {listId, courseListId} = this.state;

    extra.listId = listId;
    extra.courseListId = courseListId;

    this.setState({
      extra
    });

    this._toggleBugModal(true);
    this.props.logActivity({activityId: 234, comment: "Memory Cards"});
  };

  _openTrainingSettings = () => {
    this.toggleInfoModal(false);
    this.toggleWordSettingsModal(false);

    if(!this.state.tour) {
      this.toSettings();
    } else {
      this._toggleRegisterModal(true)
    }
  };

  _toWordDetails = () => {
    this.toggleWordSettingsModal(false);
    const {words} = this.props.data;
    const {currentIndex} = this.state;

    let word = words.length > 0 ? words[currentIndex] : false;

    if (!word) return;

    this.props.logActivity({activityId: 45, model: 'word', modelId: word.id, comment: "from preview"});
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/dictionary/${word.word}`, {word});
  };

  _changeVoice = () => {
    this.toggleWordSettingsModal(false);

    this.props.logActivity({activityId: 332});

    this._toggleAudioModal(true);
  };

  toggleWordSettingsModal = (status) => {
    this.toggleInfoModal(false);

    this.setState({
      wordSettingsModalVisible: status
    })
  };

  _goBack = () => {
    this.setState({
      finishButtonsModalVisible: false
    });

    this._logOnExit();

    this.props.history.goBack();

    this.props.logActivity({activityId: 306, comment: 'Memory Cards'});
  };

  _toggleShuffle = () => {
    const {userData} = this.props.user;

    this.toggleWordSettingsModal(false);

    if(!this.state.tour) {
      this.props.fetchingData(true);

      this.props.toggleShuffleWords();
      this.props.logActivity({activityId: 333, comment: userData && userData.shuffle == 1 ? "on" : "off"});

    } else {
      this._toggleRegisterModal(true)
    }
  };

  _toggleBugModal = (status) => {
    this.setState({
      bugModalVisible: status
    });
  };

  _toggleTrainingSettingsModal = (status) => {
    this.setState({
      trainingSettingsModalVisible: status
    });
  };
  
  _toggleRegisterModal = (status, e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      registerModalVisible: status
    })
  };

  _toRegister = () => {
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/register`)
  };

  render() {
    const {words, shuffledIndices, totalPages, totalWords, showCards} = this.props.data;
    const {
      wordSettingsModalVisible, seconds, trainingId, totalRepeated,
      scores, finishButtonsModalVisible, trainingInfoVisible, currentPage,
      tablet,
      freeze,
      subscriptionAlertVisible,
      subscriptionMessage,
      listId,
      from,
      audioModalVisible,
      bugModalVisible,
      trainingSettingsModalVisible,
      extra,
      registerModalVisible
    } = this.state;
    const {fetchingData} = this.props.activity;
    const {userData} = this.props.user;
    const {fontSizeRation, version, deviceHeight, deviceWidth} = this.props.settings;
    const {currentList} = this.props.lists;
    const {course, translate} = this.props;

    let subtitle = "";

    if (currentList && currentList.list_name && totalWords) {
      subtitle = `${currentList.list_name}: ${totalWords}`
    } else if (course && course.currentList && course.currentList.list_name) {
      subtitle = `${course.currentList.list_name}: ${totalWords}`
    }

    let cards = this.makeButtons(words, shuffledIndices);

    const progress = totalPages !== 0 ? (currentPage / totalPages) : 0;

    return (
      <div className="memorycards">
        <Container className="pageWrapper">

          <TrainingSubHeader
            key={Math.random().toString(10)}
            freeze={freeze}
            version={version}
            ratio={fontSizeRation}
            scores={scores}
            hideButtons={totalPages === 1}
            progress={progress}
            trainingId={trainingId}
            changeWord={this.changeCards}

            deviceHeight={deviceHeight}
            deviceWidth={deviceWidth}
            translate={translate}
            tablet={tablet}
            exit={this._goBack}
            history={this.props.history}
            trainingName="memory_cards_training"
            subtitle={subtitle}

            title={'memory_cards_training'}
            text="memory_cards_training_description"
            url="https://youtu.be/njmodQNiZlk"
            toggleSettings={this.toggleWordSettingsModal}
          />

          <Card style={{minHeight: deviceHeight - 140}}>
            <div style={{opacity: showCards ? 1 : 0}} className="contentWrapper">
              {
                showCards && (
                  <div className="cardsWrapper">
                    {cards}
                  </div>
                )
              }
            </div>
          </Card>

          {
            words.length > 0 && seconds > 0 && (
              <div className="circleWrapper">
                <div style={{width: 100, height: 100}}>
                  {
                    showCards && (
                      <CircularProgressbar
                        animated
                        minValue={0}
                        maxValue={1}
                        value={(seconds / 5)}
                        strokeWidth={10}
                        counterClockwise
                        styles={buildStyles({
                        rotation: 0.25,
                        pathTransitionDuration: 0.05
                      })}
                      />
                    )
                  }
                </div>
              </div>
            )
          }
          {finishButtonsModalVisible && (
            <FinishTrainingButtons
              ratio={fontSizeRation}
              isVisible={finishButtonsModalVisible}
              onClose={this.closeFinishButtonsModal}
              continueTraining={this.continueTraining}
              anotherTraining={this.anotherTraining}
              returnBack={this._goBack}
              from={from}
              translate={translate}
              tablet={tablet}
            />
          )}

          {wordSettingsModalVisible && (
            <WordSettings
              changeVoice={this._changeVoice}
              ratio={fontSizeRation}
              isVisible={wordSettingsModalVisible}
              onClose={this.toggleWordSettingsModal}
              openTrainingInfo={this._openTrainingInfo}
              openTrainingSettings={this._openTrainingSettings}
              toWordDetails={this._toWordDetails}
              reportTrainingBug={this._reportTrainingBug}
              noWord={true}
              title={translate('memory_cards_training')}
              shuffle={userData.shuffle}
              shouldShuffle={true}
              toggleShuffle={this._toggleShuffle}
            />
          )}

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={subscriptionMessage}
                translate={translate}
                deviceWidth={deviceWidth}
                deviceHeight={deviceHeight}
              />
            )
          }
          {
            audioModalVisible && (
              <ChangeAudioLanguage
                onClose={this._toggleAudioModal}
                isVisible={audioModalVisible}
                toRegister={this._toggleRegisterModal}

              />
            )
          }
          {
            bugModalVisible && (
              <ReportBug
                onClose={this._toggleBugModal}
                isVisible={bugModalVisible}
                type="training"
                training="Memory Cards"
                extra={extra}
              />
            )
          }
          {
            trainingSettingsModalVisible && (
              <TrainingSettings
                onClose={this._toggleTrainingSettingsModal}
                isVisible={trainingSettingsModalVisible}
                trainingName="memory_cards_training"
                trainingId={trainingId}
              />
            )
          }
          {registerModalVisible && (
            <RegisterAlert
              onClose={this._toggleRegisterModal}
              isVisible={registerModalVisible}
              translate={translate}
              toRegister={this._toRegister}
            />
          )}
        </Container>
        <Loader fetchingData={fetchingData}/>
        <Helmet>
          <title>{`${translate('app_title_memory_cards_training')}`}</title>
          <meta property="og:title" content={`${translate('app_title_memory_cards_training')}`} />
          <meta name="description" content={translate('app_title_memory_cards_training_description')} />
          <meta name="og:description" content={translate('app_title_memory_cards_training_description')} />

          <link rel="canonical" href={window.location.href} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  data: state.trainings,
  user: state.user,
  activity: state.activity,
  settings: state.settings,
  cache: state.cache,
  lists: state.lists,
  courseCache: state.courseCache,
  purchases: state.purchases,
  course: state.course,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    getWordsForTraining: (data) => dispatch(getWordsForTraining(data)),
    resetWords: () => dispatch(resetWords()),
    resetOnlyWords: () => dispatch(resetOnlyWords()),
    logListWord: (data) => dispatch(logListWord(data)),
    logPhrase: (data) => dispatch(logPhrase(data)),
    logGlossaryWord: (data) => dispatch(logGlossaryWord(data)),
    logUserList: (data) => dispatch(logUserList(data)),
    logScores: (scores) => dispatch(logScores(scores)),
    logActivity: (data) => dispatch(logActivity(data)),
    getListsToRepeat: (limit, userId) => dispatch(getListsToRepeat(limit, userId)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    toggleListTrainingButtonsModal: (status) => dispatch(toggleListTrainingButtonsModal(status)),
    toggleShowCards: (status) => dispatch(toggleShowCards(status)),
    logCourseListWord: (data) => dispatch(logCourseListWord(data)),
    logCourseList: (data) => dispatch(logCourseList(data)),
    setMemoryCardsSettings: (data) => dispatch(setMemoryCardsSettings(data)),
    setCachedMemoryCardsWords: (data) => dispatch(setCachedMemoryCardsWords(data)),
    getOnlyMistakes: () => dispatch(getOnlyMistakes()),

    moveNewListToRepeat: (list) => dispatch(moveNewListToRepeat(list)),
    removeListFromRepeatSection: (listId) => dispatch(removeListFromRepeatSection(listId)),
    updateListTrainings: (listId) => dispatch(updateListTrainings(listId)),
    updateTrainingsListFromRepeatSection: (listId) => dispatch(updateTrainingsListFromRepeatSection(listId)),
    updateCachedListTrainings: (listId) => dispatch(updateCachedListTrainings(listId)),
    updateCachedListTrainingsAndIntervals: (list) => dispatch(updateCachedListTrainingsAndIntervals(list)),

    toggleListTrainingButtonsModalCourse: (status) => dispatch(toggleListTrainingButtonsModalCourse(status)),
    getUpdatedCurrentListScores: (listId) => dispatch(getUpdatedCurrentListScores(listId)),
    getUpdatedCourseLists: () => dispatch(getUpdatedCourseLists()),
    getUpdatedCourseData: () => dispatch(getUpdatedCourseData()),
    resetCurrentTrainingId: () => dispatch(resetCurrentTrainingId()),
    setCurrentTrainingId: (id) => dispatch(setCurrentTrainingId(id)),
    logTrainingLimits: () => dispatch(logTrainingLimits()),
    shuffleListTrainingWords: (trainingId, listId) => dispatch(shuffleListTrainingWords(trainingId, listId)),
    shuffleCourseListTrainingWords: (trainingId, listId) => dispatch(shuffleCourseListTrainingWords(trainingId, listId)),
    shuffleTrainingWords: () => dispatch(shuffleTrainingWords()),
    toggleShuffleWords: () => dispatch(toggleShuffleWords()),

  };
}

export default connect(mapStateToProps, bindAction)(MemoryCards);
