import React, {Component} from 'react';
import {connect} from "react-redux";
import {Translate, getTranslate} from 'react-localize-redux';
import {withRouter} from 'react-router-dom';

import {Link, Element, Events, animateScroll as scroll, scrollSpy, scroller} from 'react-scroll'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faCheckCircle, faCircle, faTimes} from '@fortawesome/free-solid-svg-icons';
import {toastr} from 'react-redux-toastr';

import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Logo from "../../assets/images/logo-boy-prepared.png";

import {logActivity} from '../../actions/logActions';
import {fetchingData} from '../../actions/activity';

class Landing extends Component {

  constructor(props) {
    super(props);

    this.state = {
      trainingsSlides: [
        {image: require('../../assets/images/landing/trainings/preview_ru.jpg'), title: 'preview_training'},
        {image: require('../../assets/images/landing/trainings/drag_image_ru.jpg'), title: 'drag_image_training'},
        {
          image: require('../../assets/images/landing/trainings/select_translation_ru.jpg'),
          title: 'select_translation_training'
        },
        {image: require('../../assets/images/landing/trainings/select_word_ru.jpg'), title: 'select_word_training'},
        {image: require('../../assets/images/landing/trainings/collect_word_ru.jpg'), title: 'collect_word_training'},
        {image: require('../../assets/images/landing/trainings/spelling_ru.jpg'), title: 'check_spelling_training'},
        {image: require('../../assets/images/landing/trainings/missing_ru.jpg'), title: 'missing_letter_training'},
        {
          image: require('../../assets/images/landing/trainings/collect_sentence_ru.jpg'),
          title: 'collect_sentence_training'
        },
        {image: require('../../assets/images/landing/trainings/listening_ru.jpg'), title: 'listening_training'},
        {image: require('../../assets/images/landing/trainings/word_test_ru.jpg'), title: 'vocabulary_test'},
      ],

      glossariesSlides: [
        {image: require('../../assets/images/landing/glossaries/glossaries_ru.jpg'), title: 'glossaries'},
        {image: require('../../assets/images/landing/glossaries/glossary_words_ru.jpg'), title: 'glossary_words'},
        {image: require('../../assets/images/landing/glossaries/glossary_word_ru.jpg'), title: 'glossary_word'},
      ],

      methodSlides: [
        {image: require('../../assets/images/landing/method/time_to_repeat_ru.jpg'), title: 'time_to_repeat'},
        {image: require('../../assets/images/landing/method/mistakes_ru.jpg'), title: 'with_mistakes'},
        {image: require('../../assets/images/landing/method/new_lists_ru.jpg'), title: 'new_lists'},
        {image: require('../../assets/images/landing/method/calendar_ru.jpg'), title: 'calendar'},
      ],
      listsSlides: [
        {image: require('../../assets/images/landing/lists/lists_ru.jpg'), title: 'lists'},
        {image: require('../../assets/images/landing/lists/list_words_ru.jpg'), title: 'list_words'},
        {image: require('../../assets/images/landing/lists/generator_ru.jpg'), title: 'auto_words_home'},
        {image: require('../../assets/images/landing/lists/generator_2_ru.jpg'), title: 'auto_words_home'},
        {image: require('../../assets/images/landing/lists/from_dictionary_ru.jpg'), title: 'add_from_dictionary'},
      ],

      coursesSlides: [
        {image: require('../../assets/images/landing/courses/courses_ru.jpg'), title: 'vocabulary_courses'},
        {image: require('../../assets/images/landing/courses/course_steps_ru.jpg'), title: 'course_lists'},
        {image: require('../../assets/images/landing/courses/course_ru.jpg'), title: 'course_tests'},
        {image: require('../../assets/images/landing/courses/course_words_ru.jpg'), title: 'course_words'},
        {image: require('../../assets/images/landing/courses/course_calendar_ru.jpg'), title: 'course_calendar'},
      ]
    };
  }

  componentDidMount() {
    Events.scrollEvent.register('begin', function (to, element) {
      // console.log('begin', arguments);
    });

    Events.scrollEvent.register('end', function (to, element) {
      // console.log('end', arguments);
    });

    scrollSpy.update();
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  render() {

    const {translate} = this.props;
    const {locale} = this.props.settings;
    const {methodSlides, listsSlides, trainingsSlides, glossariesSlides, coursesSlides} = this.state;

    return (
      <div className="landing">
        <div className="sub-nav d-flex flex-wrap justify-content-center align-items-center">
          <Link
            className="p-2 mr-2"
            activeClass="active"
            to="method" spy={true} smooth={true} offset={-80} duration={750}>
            {translate('landing.method')}
          </Link>

          <Link
            className="p-2 mr-2"
            activeClass="active"
            to="glossaries" spy={true} smooth={true} offset={-80} duration={750}>
            {translate('glossaries')}
          </Link>
          <Link activeClass="active" className="p-2" to="trainings" spy={true} offset={-80} smooth={true} duration={750}>
            {translate('trainings')}
          </Link>
          <Link className="p-2 mr-2" to="course" spy={true} smooth={true} offset={-80} duration={750}>
            {translate('courses')}
          </Link>
          <Link activeClass="active" className="p-2" to="lists" spy={true} offset={-80} smooth={true} duration={750}>
            {translate('lists')}
          </Link>
          <Link activeClass="active" className="p-2" to="other" spy={true} offset={-80} smooth={true} duration={750}>
            {translate('other')}
          </Link>
        </div>

        <div className="pageWrapper">

          <div className="header-block block d-flex flex-column justify-content-start align-items-center logo-container">
            <h1 className="font-weight-bold text-center main-header">Mnemonic Words</h1>
            <h2 className="font-weight-light text-center main-sub-header">
              {translate('landing.effective_method')}
            </h2>

            {/*<Image alt="logo" src={Logo} className="logo mt-3"/>*/}
            <div className="mt-2 mt-md-5">
              <Row>
                <Col md="6" className="d-flex flex-column justify-content-center align-items-center mt-md-0 mt-3">
                  <Image className="devices" src={require('../../assets/images/landing/all_devices.png')}/>
                </Col>
                <Col md="6">
                  <div className="description text-center mt-3">
                    <p className="ml-auto mr-auto">
                      {translate('landing.download_app')}
                    </p>

                    <div className="d-flex justify-content-center icons align-items-center mb-2">
                      <a href="https://itunes.apple.com/app/id1438980777" target="_blank">
                        <Image
                          className="itunes"
                          style={{height:50}}
                          src={require('../../assets/images/app-store-clear.png')}/>
                      </a>

                      <a href="http://play.google.com/store/apps/details?id=com.mnemonicrn" target="_blank">
                        <Image
                          style={{height:50}}
                          src={require('../../assets/images/google-play-clear-.png')}/>
                      </a>
                    </div>

                    <p className="font-italic font-weight-light text-center" style={{fontSize: 14}}>
                      {translate('landing.auto_sync')}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="block small-block word-sources">
            <div className="container">

              <div className="description">
                <h3 className="header">
                  {translate('landing.words_sources')}
                </h3>
                <ul>
                  <li>{translate('landing.source_course')}</li>
                  <li>{translate('landing.source_glossaries')}</li>
                  <li>{translate('landing.source_dictionary')}</li>
                  <li>{translate('landing.source_copy')}</li>
                </ul>

                <p>
                  {translate('landing.about_trainings_methods')}
                </p>
              </div>
            </div>
          </div>

          <Element name="method">
            <div className="block method-block">
              <h3 className="header mt-4">{translate('landing.remembering_method')}</h3>

              <Row>
                <Col md="4">
                  <div className="m-auto" style={{maxWidth: 250}}>
                    <Carousel>
                      {methodSlides.map((slide, i) => {
                        return (
                          <Carousel.Item key={i}>
                            <LazyLoadImage
                              alt={slide.title}
                              effect="blur"
                              className="d-block w-100"
                              src={slide.image} />
                          </Carousel.Item>
                        )
                      })}
                    </Carousel>
                  </div>
                </Col>

                <Col md="8">
                  <ul className="description mt-md-0 mt-4">
                    <li>{translate('landing.built_in_method')}</li>
                    <li>{translate('landing.repetition_calendar')}</li>
                    <li>{translate('landing.working_with_mistakes')}</li>
                  </ul>
                </Col>
              </Row>

            </div>
          </Element>

          <Element name="glossaries">
            <div className="dictionary-block block">
              <h3 className="header">{translate('landing.dictionary_base')}</h3>

              <Row>
                <Col md="8">
                  <div className="description mb-md-0 mb-4">
                    <p>{translate('landing.glossaries_general')}</p>
                    <ul>
                      <li>{translate('landing.beginner_level')}</li>
                      <li>{translate('landing.elementary_level')}</li>
                      <li>{translate('landing.intermediate_level')}</li>
                      <li>{translate('landing.advanced_level')}</li>
                      <li>{translate('landing.glossary_topics')}</li>
                      <li>{translate('landing.glossary_phrases')}</li>
                    </ul>
                    <p>{translate('landing.about_dictionary')}</p>
                    <p>{translate('landing.working_with_word_base')}</p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="m-auto" style={{maxWidth: 250}}>
                    <Carousel>
                      {glossariesSlides.map((slide, i) => {
                        return (
                          <Carousel.Item key={i}>
                            <LazyLoadImage
                              alt={slide.title}
                              effect="blur"
                              className="d-block w-100"
                              src={slide.image} />
                          </Carousel.Item>
                        )
                      })}
                    </Carousel>
                  </div>
                </Col>
              </Row>
            </div>
          </Element>

          <Element name="trainings">
            <div className="block training-block">
              <h3 className="header text-white text-center">
                {translate('landing.trainings')}
              </h3>
              <Row>
                <Col md="4">
                  <div className="m-auto" style={{maxWidth: 250}}>
                    <Carousel>
                      {trainingsSlides.map((slide, i) => {
                        return (
                          <Carousel.Item key={i}>
                            <LazyLoadImage
                              alt={slide.title}
                              effect="blur"
                              className="d-block w-100"
                              src={slide.image} />
                          </Carousel.Item>
                        )
                      })}
                    </Carousel>
                  </div>
                </Col>
                <Col md="8">
                  <div className="description mt-md-0 mt-4">
                    <p>{translate('landing.about_trainings')}</p>
                    <p>{translate('landing.about_games')}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Element>

          <Element name="course">
            <div className="course-block block">
              <h3 className="header">{translate('landing.courses')}</h3>
              <Row>
                <Col md="8">
                  <div className="description mb-md-0 mb-4">
                    <ul>
                      <li>{translate('landing.course_beginner')}</li>
                    </ul>
                    <p>{translate('landing.beginners_course_audience')}</p>
                    <p>{translate('landing.beginners_course_content')}</p>

                    <ul>
                      <li>{translate('landing.course_elementary')}</li>
                    </ul>

                    <p>{translate('landing.elementary_course')}</p>
                    <p>{translate('landing.about_course_words')}</p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="m-auto" style={{maxWidth: 250}}>
                    <Carousel>
                      {coursesSlides.map((slide, i) => {
                        return (
                          <Carousel.Item key={i}>
                            <LazyLoadImage
                              alt={slide.title}
                              effect="blur"
                              className="d-block w-100"
                              src={slide.image} />
                          </Carousel.Item>
                        )
                      })}
                    </Carousel>
                  </div>
                </Col>

              </Row>
            </div>
          </Element>

          <Element name="lists">
            <div className="lists-block block">
              <h3 className="header">
                {translate('landing.lists_creation')}
              </h3>

              <Row>
                <Col md="4">
                  <div className="m-auto" style={{maxWidth: 250}}>
                    <Carousel>
                      {listsSlides.map((slide, i) => {
                        return (
                          <Carousel.Item key={i}>
                            <LazyLoadImage
                              alt={slide.title}
                              effect="blur"
                              className="d-block w-100"
                              src={slide.image} />
                          </Carousel.Item>
                        )
                      })}
                    </Carousel>
                  </div>
                </Col>
                <Col md="8">
                  <div className="description mt-md-0 mt-4">
                    <p>{translate('landing.about_lists_size')}</p>
                    <p>{translate('landing.about_lists_creation')}</p>
                    <ul>
                      <li>{translate('landing.from_generator')}</li>
                      <li>{translate('landing.from_glossaries')}</li>
                      <li>{translate('landing.from_dictionary')}</li>
                      <li>{translate('landing.from_paste')}</li>
                    </ul>
                  </div>
                </Col>

              </Row>
            </div>
          </Element>

          <Element name="other">
            <div className="other-block block">
              <Row>
                <Col md="2">

                </Col>
                <Col md="8" sm="12">
                  <h3 className="header">
                    {translate('landing.working_with_words')}
                  </h3>
                  <div className="description">
                    <ul>
                      <li>{translate('landing.adding_assocs')}</li>
                      <li>{translate('landing.adding_images')}</li>
                      <li>{translate('landing.adding_translations')}</li>
                      <li>{translate('landing.changing_main_translation')}</li>
                    </ul>
                  </div>

                  <h3 className="header">
                    {translate('landing.phrasebooks')}
                  </h3>

                  <div className="description">
                    <p>{translate('landing.about_phrasebooks')}</p>
                  </div>
                  <h3 className="header">
                    {translate('landing.family_access')}
                  </h3>
                  <div className="description">

                    <p>
                      {translate('landing.family_access_description')}
                    </p>
                  </div>
                  <h3 className="header">
                    {translate('landing.other_functions')}
                  </h3>
                  <div className="description">

                    <ul>
                      <li>{translate('landing.flash_cards')}</li>
                      <li>{translate('landing.favorites')}</li>
                      <li>{translate('landing.vocabulary')}</li>
                    </ul>
                  </div>
                </Col>
                <Col md="2">

                </Col>
              </Row>
            </div>
          </Element>

          {/*<div className="block">
           <div className="description">
           <p>
           После установки приложения у вас будет возможность в течение 7-ми дней пользоваться всеми доступными
           функциями без
           ограничений (за исключением функции "Семейный доступ", а также создания "Флеш карточек").
           </p>
           <p>
           По истечению данного срока, приложение будет работать в ограниченном режиме.
           </p>
           <p>
           Для снятия ограничений у вас будет возможность приобрести одну из доступных подписок:
           </p>
           <ul>
           <li>Автоматически возобновляемую месячную или годовую подписку.</li>
           <li>”Пожизненную подписку”, которая включает в себя все встроенные покупки приложения.</li>
           </ul>
           </div>
           </div>*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize),
});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
  };
}

export default connect(mapStateToProps, bindAction)(withRouter(Landing));
