import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {Translate, getTranslate} from 'react-localize-redux';

import {Link} from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
  faLock, faAward, faInfoCircle,
  faCircle, faCogs, faCheckCircle,
  faTrashAlt,
  faCamera,
  faVolumeUp,
  faTimes, faSearch, faPlus
} from '@fortawesome/free-solid-svg-icons';

import {toastr} from 'react-redux-toastr';
import classNames from 'classnames';

import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';

import CourseListWordCell from '../CourseListWordCell/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
import TrainingButtons from '../../../components/Modals/TrainingButtons/index';
import VocabularyToolbar from '../../../components/Toolbars/VocabularyToolbar/index';
import TrainingsSet from '../../../components/Modals/TrainingsSet/index';

import Loader from "../../../components/Custom/Loader/index";

import {
  getCourseVocabulary, gettingCourseNewChunk, resetCourseVocabulary,
  deleteFromCourseVocabulary,
  toggleListTrainingButtonsModalCourse,
  moveToGeneralVocabulary,
  performCourseBulkAction
}
  from '../../../actions/courseActions';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

import colors from '../../../lib/colors';

class CourseVocabulary extends Component {
  get toggleBulkMode() {
    return this._toggleBulkMode;
  }

  set toggleBulkMode(value) {
    this._toggleBulkMode = value;
  }

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      refreshing: false,
      currentChunk: 0,
      totalWords: 0,
      listWordStatisticsModalVisible: false,
      currentWord: false,
      tablet: false,
      subscriptionAlertVisible: false,
      trainingsModalVisible: false,
      scrolled: 0,

      bulkMode: false,
      bulkModeType: false,
      trainingsSetModal: false,
      selectedWords: [],
    };

    this.viewabilityConfig = {
      waitForInteraction: true,
      viewAreaCoveragePercentThreshold: 0
    };

    this.track = null;
  }

  componentDidMount() {
    const {history} = this.props;
    this.props.fetchingData(true);
    window.addEventListener('scroll', this.handleScroll.bind(this));

    this.props.getCourseVocabulary({page: this.state.page, history});
  }

  componentWillUnmount() {
    this.props.resetCourseVocabulary();
    window.removeEventListener('scroll', this.handleScroll);

    this.props.toggleListTrainingButtonsModalCourse(false);
  }


  handleScroll() {
    const scrollY = window.scrollY;

    this.setState({
      scrolled: scrollY
    });
  }

  _toWordDetails = (word) => {
    const {bulkMode, bulkModeType, selectedWords} = this.state;
    const {translate} = this.props;
    const {locale} = this.props.settings;

    if (bulkMode) {
      let index = selectedWords.indexOf(word.id);

      if (index === -1) {
        if (bulkModeType == 'delete' || bulkModeType == 'move') {
          this.setState({
            selectedWords: [...selectedWords, word.id]
          })
        } else {
          if (bulkModeType == 'vocabulary') {
            if (!word.in_vocabulary) {
              this.setState({
                selectedWords: [...selectedWords, word.id]
              })
            } else {
              toastr.warning("", translate('already_there'))
            }
          } else if (bulkModeType == 'favorite') {
            if (!word.is_favourite) {
              this.setState({
                selectedWords: [...selectedWords, word.id]
              })
            } else {
              toastr.warning("", translate('already_there'))
            }
          }
        }
      } else {
        selectedWords.splice(index, 1);

        this.setState({
          selectedWords
        })
      }
    } else {
      word.id = word.word_id;
      this.props.history.push(`/${locale}/dictionary/${word.word.word}`, {word, back: true});
      this.props.logActivity({activityId: 45, model: 'word', modelId: word.id, comment: "from course list"});
    }
  };

  playSound = (url, e) => {
    e.preventDefault();
    e.stopPropagation();

    if (url) {
      if (this.track != null) {
        this.track.pause();
        this.track = null;
      }

      this.track = new Audio(url);
      this.track.play()
    }
  };

  _onStatisticsPressed = (word, e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      currentWord: word
    });

    this._toggleStatisticsModal(true);

    this.props.logActivity({activityId: 259, model: 'word', modelId: word.id, comment: "from course list"});
  };

  _toggleStatisticsModal = (status) => {
    this.setState({
      listWordStatisticsModalVisible: status
    })
  };

  _deleteFromVocabulary = (word, e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.fetchingData(true);
    this.props.deleteFromCourseVocabulary(word);
  };

  _renderItem = (item, index) => {
    const {fontSizeRation, version} = this.props.settings;
    const {tablet, bulkMode, selectedWords, bulkModeType} = this.state;
    const {showWordSentence} = this.props.course;
    const {userData} = this.props.user;
    const {isConnected} = this.props.settings;
    const {translate} = this.props;
    
    let selected = selectedWords.indexOf(item.id);

    let circleColor = colors.lightGrey;

    if (bulkMode) {
      if (bulkModeType == 'delete') {
        circleColor = colors.coral;
      } else if (bulkModeType == 'vocabulary') {
        circleColor = colors.waterBlue;
      } else if (bulkModeType == 'favorite') {
        circleColor = colors.golden;
      } else if (bulkModeType == 'move') {
        circleColor = colors.greyishBrown;
      }
    }

    return (
      <CourseListWordCell
        key={index}
        translate={translate}
        userData={userData}
        opened={true}
        ratio={fontSizeRation}
        version={version}
        vocabulary={true}
        isConnected={isConnected}
        tablet={tablet}
        showSentence={showWordSentence}
        word={item}
        onPlay={this.playSound}
        onStatisticsPressed={this._onStatisticsPressed}
        toWordDetails={this._toWordDetails}
        index={index}
        deleteFromVocabulary={this._deleteFromVocabulary}
        mistakes={false}
        bulkMode={bulkMode}
        circleColor={circleColor}
        selected={selected !== -1}
      />
    )
  };

  getVocabularyWords = () => {
    const {getCourseVocabulary, history} = this.props;
    const {page} = this.state;

    getCourseVocabulary({page, history})
  };

  toggleTrainingButtons = (status) => {
    this.setState({
      trainingsModalVisible: status
    });

    this.props.toggleListTrainingButtonsModalCourse(false)
  };

  trainingSelected = (training) => {
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {locale} = this.props.settings;

    const {userData} = this.props.user;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    let active = false;

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    this.props.toggleListTrainingButtonsModalCourse(false);

    this.setState({
      trainingsModalVisible: false
    });

    if (active) {
      // const {list} = this.props.navigation.state.params;

      let activityId = '';

      let path = '';

      if (training == 'Preview') {
        activityId = 39;
        path = 'preview'
      } else if (training == 'DragImage') {
        path = 'image';

        activityId = 40;
      } else if (training == 'SelectTranslation') {
        path = 'translation';
        activityId = 41;
      } else if (training == 'SelectWord') {
        path = 'word';
        activityId = 42;
      } else if (training == 'CollectWord') {
        path = 'collect';
        activityId = 43;
      } else if (training == 'CheckSpelling') {
        path = 'spelling';
        activityId = 44;
      } else if (training == 'CollectSentence') {
        activityId = 114;
      } else if (training == 'CollectSentenceTap') {
        activityId = 335;
        path = 'sentence'
      } else if (training == 'MemoryCards') {
        activityId = 131;
        path = 'memory'
      } else if (training == 'WordDefinition') {
        path = 'definition';
        activityId = 148;
      } else if (training == 'SpeakOut') {
        activityId = 95;
      } else if (training == 'MissingLetter') {
        path = 'missing';
        activityId = 315;
      } else if (training == 'Listening') {
        path = 'listening';
        activityId = 345;
      } else if (training == 'TranslateSentence') {
        path = 'translate';
        activityId = 353;
      }
      
      this.props.history.push(`/${locale}/${path}/-1`, {type: 'courseListId', inside: true, from: 'listWords', courseListId: -1});
      this.props.logActivity({activityId, model: 'courseList', modelId: -1, comment: 'from course voc'});
    } else {
      this._toggleSubscriptionAlert(true);
    }
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toSubscription = (productId) => {
    this._toggleSubscriptionAlert(false);
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId});
  };

  _moveToVocabulary = () => {
    const {moveToGeneralVocabulary, history} = this.props;
    const {page} = this.state;

    this.props.fetchingData(true);
    moveToGeneralVocabulary({history});
  };

  pageClicked = (page) => {
    const {history} = this.props;
    const {isConnected} = this.props.settings;

    this.setState({
      page,
    });

    if (isConnected) {
      this.props.fetchingData(true);

      this.props.getCourseVocabulary({page, history});
    }
  };

  _toggleBulkMode = (type) => {
    this.setState({
      bulkMode: true,
      bulkModeType: type
    })
  };

  _performBulkAction = () => {
    const {translate} = this.props;

    const {selectedWords, bulkModeType} = this.state;

    if (selectedWords.length == 0) {
      toastr.warning("", translate('no_words_selected'));
    } else {
      if (bulkModeType != 'move') {
        this.setState({
          bulkMode: false
        });
        this.props.fetchingData(true);

        this.props.performCourseBulkAction(-1, selectedWords, bulkModeType, 'vocabulary');
        this.props.logActivity({activityId: 338, comment: `${bulkModeType}`});

        this._disableBulkMode();
      } else {
        // this.toggleListsToFillModal(true);
      }
    }
  };

  toggleSelectAll = (e) => {
    e.preventDefault();

    const {selectedWords, bulkModeType} = this.state;
    const {vocabularyWords} = this.props.course;

    if (selectedWords.length > 0) {
      this.setState({
        selectedWords: []
      })
    } else {
      let selected = [];

      vocabularyWords.forEach(item => {
        if (bulkModeType == 'delete' || bulkModeType == 'move') {
          selected.push(item.id)
        } else {
          if (bulkModeType == 'vocabulary') {
            if (!item.in_vocabulary) {
              selected.push(item.id)
            }
          } else if (bulkModeType == 'favorite') {
            if (!item.is_favourite) {
              selected.push(item.id)
            }
          }
        }
      });

      this.setState({
        selectedWords: selected
      })
    }
  };

  _disableBulkMode = () => {
    this.setState({
      selectedWords: [],
      bulkMode: false,
      bulkModeType: false
    })
  };

  _customizeButtons = (e) => {
    e.preventDefault();
    this.toggleTrainingButtons(false);
    this._toggleModal('trainingsSetModal', true)
  };

  _toggleModal = (type, status) => {
    this.setState({
      [type]: status
    })
  };

  render() {
    const {
      vocabularyWords,
      totalWords,
      totalPages,
      fetchingNextChunk,
      fetchedVocabularyWords,
      showListTrainingButtonsCourse,
      currentCourseSlug
    } = this.props.course;
    const {currentWord,
      tablet,
      listWordStatisticsModalVisible,
      subscriptionAlertVisible,
      trainingsModalVisible,
      page,
      scrolled,
      bulkMode,
      bulkModeType,
      selectedWords,
      trainingsSetModal
    } = this.state;
    const {fetchingData} = this.props.activity;
    const {fontSizeRation, deviceHeight, deviceWidth, locale} = this.props.settings;
    const {userData} = this.props.user;
    const {translate} = this.props;
    const {lifetimeStatus, subscriptionActive, webSubscriptionStatus} = this.props.purchases;

    let listSize = userData && userData.list_size ? userData.list_size : 10;

    let courseWords = currentCourseSlug == 1000 ? 1000 : 1200;

    const toolbar = classNames({
      'fixed-toolbar': scrolled >= 50,
      'justify-content-between listToolbar justify-content-center mb-4': true
    });

    let active = false;

    if (subscriptionActive || webSubscriptionStatus) {
      active = true;
    }

    return (
      <div className="coursevocabulary">

        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>

          <li className="breadcrumb-item">
            <Link to={{
              pathname: `/${locale}/course/${currentCourseSlug}`,
              
            }}>
              {translate('course')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate('your_course_vocabulary')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <VocabularyToolbar
          translate={translate}
          ratio={fontSizeRation}
          tablet={tablet}
          toolbar={toolbar}
          active={active}
          deviceHeight={deviceHeight}
          deviceWidth={deviceWidth}

          course={true}
          toVideo={this._toVideoInstructions}
          lifetimeStatus={lifetimeStatus}
          toggleArchive={()=>null}
          cleanListCache={()=>null}
          translationLanguage={userData.translation_language}

          toBulkMode={this._toggleBulkMode}
          bulkMode={bulkMode}
          toggleTrainingButtons={this.toggleTrainingButtons}

          handleKeyPress={()=>null}
          totalPages={totalPages}
          currentPage={page}
          pageClicked={this.pageClicked}
          totalWords={totalWords}
          typedWord={''}
          words={totalWords}
          listSize={listSize}
          typeVocabularyWord={()=>null}

          goal={1000}
          goalRef={null}
          setGoal={()=>null}
          setUserGoal={()=> null}
          moveToVocabulary={this._moveToVocabulary}
        />

        <Container style={{marginTop: 20}} className="pageWrapper">
          <div className="text-center">
            <h5>
              <span className="d-inline-block mr-2">
                {translate('your_course_vocabulary')}
              </span>
              <OverlayTrigger
                delay={{ show: 500, hide: 500 }}
                rootClose={true}
                placement="bottom"
                overlay={<Tooltip>{translate('course_vocabulary_description')}</Tooltip>}
              >
                <FontAwesomeIcon className="infoIcon" icon={faInfoCircle}/>
              </OverlayTrigger>
            </h5>
            <p className="mb-1" style={{color: colors.tealish, fontSize: fontSizeRation * (!tablet ? 12 : 14)}}>
              {totalWords} / {courseWords}
            </p>
          </div>

          {
            fetchedVocabularyWords && vocabularyWords.length > 0 && bulkMode && bulkModeType && (
              <Card className="deleteListsTextWrapper">
                  <span className="bulkModeText font-weight-bold" style={{fontSize: fontSizeRation * 16}}>
                    {translate(`list_words_to_${bulkModeType}_action`)}
                  </span>

                <div className="bulkModeWrapper flex-md-row flex-sm-column flex-column" style={{marginTop: 10}}>
                  <a href="#"
                     className="mr-3 mb-0 mb-sm-2 mb-md-0"
                     onClick={this.toggleSelectAll}>
                    <FontAwesomeIcon
                      className="selectedIcon"
                      icon={selectedWords.length > 0 ? faCheckCircle : faCircle}
                      style={{marginRight: !tablet ? 10 : 20, fontSize: !tablet ? 20 : 30}}/>
                    <span
                      className="selectAllWords pv4"
                      style={{marginRight: 10, color: colors.greyishBrown, fontSize: fontSizeRation * 16}}>
                      {selectedWords.length > 0 ? translate('deselect_words') : translate('select_all_words')}
                    </span>
                  </a>

                  <Button
                    size="sm"
                    className="mr-3 mb-0 mb-sm-2 mb-md-0"
                    disabled={selectedWords.length === 0}
                    variant={bulkModeType == 'delete' ? 'outline-danger' : 'outline-success'}

                    onClick={this._performBulkAction}
                  >
                    {translate(`to_${bulkModeType}`)} - {selectedWords.length}
                  </Button>

                  <Button
                    size="sm"
                    className="mr-3"
                    variant="outline-secondary"
                    onClick={this._disableBulkMode}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </Card>
            )
          }

          <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
            {vocabularyWords.map((item, index) => {
              return this._renderItem(item, index)
            })}
          </div>

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={'subscription_trainings'}
                translate={translate}
              />
            )
          }

          {(trainingsModalVisible || showListTrainingButtonsCourse) && (
            <TrainingButtons
              modalVisible={trainingsModalVisible || showListTrainingButtonsCourse}
              training={this.trainingSelected}
              onClose={() => this.toggleTrainingButtons(false)}
              ratio={fontSizeRation}
              tablet={tablet}
              level={0}
              listId={-1}
              title="select_training_to_repeat"
              translationLanguage={userData.translation_language}
              course={true}
              translate={translate}
              customiseButtons={this._customizeButtons}

            />
          )}

        </Container>

        {
          trainingsSetModal && (
            <TrainingsSet
              onClose={this._toggleModal}
              isVisible={trainingsSetModal}
            />
          )
        }

        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  course: state.course,
  user: state.user,
  activity: state.activity,
  settings: state.settings,
  purchases: state.purchases,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    getCourseVocabulary: (data) => dispatch(getCourseVocabulary(data)),
    resetCourseVocabulary: () => dispatch(resetCourseVocabulary()),
    gettingCourseNewChunk: () => dispatch(gettingCourseNewChunk()),
    moveToGeneralVocabulary: () => dispatch(moveToGeneralVocabulary()),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    deleteFromCourseVocabulary: (word) => dispatch(deleteFromCourseVocabulary(word)),
    toggleListTrainingButtonsModalCourse: (status) => dispatch(toggleListTrainingButtonsModalCourse(status)),
    performCourseBulkAction: (listId, words, type, source) => dispatch(performCourseBulkAction(listId, words, type, source)),

  };
}

export default connect(mapStateToProps, bindAction)(CourseVocabulary);
