import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import moment from 'moment';
import {withRouter, Link} from 'react-router-dom';

import {Translate, getTranslate} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash, faTrash} from '@fortawesome/free-solid-svg-icons';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Loader from "../../../components/Custom/Loader/index";

import {manageNews, setCurrentArticle, getArticle} from '../../../actions/newsActions';
import {getUserData} from '../../../actions/userActions';
import {fetchingData} from '../../../actions/activity';
import {logActivity} from '../../../actions/logActions';

import colors from '../../../lib/colors';

class Article extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tablet: false
    }
  }

  componentDidMount() {
    const {currentArticle} = this.props.news;

    if (currentArticle && currentArticle.id) {
      if (!currentArticle.seen) {
        this.props.manageNews(currentArticle.id, 'seen');
      }
    } else {
      this.props.fetchingData();
      this.props.getArticle(this.props.match.params.id)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._getData();
    }
  }

  componentWillUnmount() {
  }

  _getData = () => {
    const {isConnected} = this.props.settings;
    const {userId} = this.props.user;

    if (isConnected && userId) {
      // this.props.getUserData(userId);
    }
  };

  _toLink = () => {
    const {currentArticle} = this.props.news;
    const {locale} = this.props.settings;
    this.props.logActivity({activityId: 348, model: 'article', modelId: currentArticle.id});

    if (currentArticle.link_type == 'segue') {
      this.props.history.push(`/${locale}/${currentArticle.link.toLowerCase()}`)
    }
  };

  getLink = () => {
    const {currentArticle} = this.props.news;
    const {translate} = this.props;

    if (currentArticle && currentArticle.link) {
      if (currentArticle.link_type == 'review') {
        return (<div className="d-flex justify-content-center mt-3">
          <a className="mr-3" href="https://itunes.apple.com/app/id1438980777" target="_blank">
            <Image
              style={{height:50}}
              src={require('../../../assets/images/app-store-clear.png')}/>
          </a>

          <a href="http://play.google.com/store/apps/details?id=com.mnemonicrn" target="_blank">
            <Image
              style={{height:50}}
              src={require('../../../assets/images/google-play-clear-.png')}/>
          </a>
        </div>)
      } else if (currentArticle.link_type == 'out') {
        return <a href={currentArticle.link} target="_blank" className="btn btn-outline-success mt-3">
          {translate('open_link')}
        </a>
      } else {
        return (<Button
          className="mt-3"
          variant="outline-info"
          style={{alignSelf: 'center'}}
          onClick={this._toLink}
        >
          {translate('open_link')}
        </Button>)
      }
    } else {
      return null;
    }
  };


  _manageArticle = (newsId, type, e) => {
    e.preventDefault();
    const {locale} = this.props.settings;

    this.props.fetchingData(true);

    this.props.manageNews(newsId, type);

    this.props.logActivity({
      activityId: 344,
      model: 'news',
      modelId: newsId,
      comment: type
    });

    if (type == 'delete') {
      this.props.history.replace(`/${locale}/home`);
    }
  };

  render() {
    const {tablet} = this.state;
    const {fontSizeRation, locale} = this.props.settings;
    const {userData} = this.props.user;
    const {translate} = this.props;
    const {fetchingData} = this.props.activity;
    const {currentArticle} = this.props.news;
    
    return (
      <div className="article position-relative">
        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {currentArticle.title}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container className="pageWrapper">

          <Card style={{minHeight: 200, maxWidth: 500, margin: '0 auto', padding: 20}}>

            {
              currentArticle && currentArticle.id && (
                <div className="d-flex justify-content-center position-absolute" style={{width: 80, right: 5, top: 0}}>
                  <OverlayTrigger
                    delay={{ show: 500, hide: 500 }}
                    placement="auto" overlay={<Tooltip>{translate(currentArticle.seen ? 'make_article_unseen' : 'make_article_seen')}</Tooltip>}>
                    <a href="#"
                       style={{padding: '10px 5px', marginRight: 10}}
                       onClick={(e) => this._manageArticle(currentArticle.id, currentArticle.seen ? 'unseen' : 'seen', e)}>
                      <FontAwesomeIcon icon={currentArticle.seen ? faEyeSlash : faEye}/>
                    </a>
                  </OverlayTrigger>
                  <OverlayTrigger
                    delay={{ show: 500, hide: 500 }}
                    placement="auto" overlay={<Tooltip>{translate('delete_article')}</Tooltip>}>
                    <a href="#"
                       style={{padding: '10px 5px'}}
                       onClick={(e) => this._manageArticle(currentArticle.id, 'delete', e)}>
                      <FontAwesomeIcon icon={faTrash} style={{color: colors.coral }}/>
                    </a>
                  </OverlayTrigger>
                </div>
              )
            }
            <p
              className="title"
              style={{
                marginBottom: !tablet ? 5 : 10,
                fontSize: fontSizeRation * 18}}>
              {currentArticle ? currentArticle.title : ''}
            </p>

            <p className="content" style={{fontSize: fontSizeRation * 16}}>
              {currentArticle ? currentArticle.content : ''}
            </p>

            {this.getLink()}
          </Card>

        </Container>
        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  news: state.news,
  activity: state.activity,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    getUserData: (userId) => dispatch(getUserData(userId)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    logActivity: (data) => dispatch(logActivity(data)),
    manageNews: (newsId, type) => dispatch(manageNews(newsId, type)),
    getArticle: (id) => dispatch(getArticle(id)),

  };
}

export default connect(mapStateToProps, bindAction)(Article);
