import * as types from '../actions/types';
import * as constants from '../lib/constants';

const INITIAL_STATE = {
  fontSizeRation: 1,
  version: 7,
  locale: 7,
  isConnected: true,
  assetsSource: constants.BACKEND_URL,
  deviceWidth: window.innerWidth,
  deviceHeight: window.innerHeight,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_FONT_SIZE:
      return Object.assign({}, state, {
        fontSizeRation: action.payload,
      });
    
    case types.SET_LOCALE:
      return Object.assign({}, state, {
        locale: action.payload,
      });
    
    case types.SET_WINDOW_DIMENSIONS:
      return Object.assign({}, state, {
        deviceWidth: action.payload.width,
        deviceHeight: action.payload.height,
      });
    
    case types.SET_DEVICE_VERSION:
      return Object.assign({}, state, {
        version: action.payload,
      });
    
    case types.SET_CONNECTION_STATUS:{
      return Object.assign({}, state, {
        isConnected: action.payload,
      });
    }
    
    case types.SET_ASSETS_SOURCE:{
      return Object.assign({}, state, {
        assetsSource: action.payload,
      });
    }

    default:
      return state;
  }
}