import * as types from './types';

export const setUserListsFromCache = (data) => (dispatch) => {
    dispatch({ type: types.SET_USER_LISTS_FROM_CACHE, payload: data });
};

export const resetCachedLists = () => (dispatch) => {
    dispatch({ type: types.RESET_CACHED_LISTS });
};

export const clearGlossaryCache = (id) => (dispatch) => {
    dispatch({ type: types.RESET_GLOSSARY_CACHE, payload: id });
};

export const resetVisitedCachedLists = () => (dispatch) => {
    dispatch({ type: types.RESET_VISITED_CACHED_LISTS });
};

export const cleanAppCache = () => (dispatch) => {
    dispatch({ type: types.CLEAN_APP_CACHE });
};

export const updateCachedListTrainings = (listId) => (dispatch) => {
    dispatch({ type: types.UPDATE_CACHED_LIST_TRAININGS, payload: {listId} });
};

export const updateCachedListTrainingsAndIntervals = (list) => (dispatch) => {
    dispatch({ type: types.UPDATE_CACHED_LIST_TRAININGS_AND_INTERVALS, payload: {list} });
};

export const addImageToCachedListWords = (image) => (dispatch) => {
    dispatch({ type: types.ADD_USER_IMAGE_TO_CACHED_LIST_WORDS, payload: image });
};

export const addImageToCachedTrainings = (image) => (dispatch) => {
    dispatch({ type: types.ADD_USER_IMAGE_TO_CACHED_LIST_WORDS, payload: image });
};

export const shuffleListTrainingWords = (trainingId, listId) => (dispatch) => {
    dispatch({ type: types.SHUFFLE_LIST_TRAINING_WORDS, payload: {trainingId, listId} });
};

