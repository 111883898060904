import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';

import moment from 'moment';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Translate} from 'react-localize-redux';

import colors from '../../../lib/colors';

const CourseWordSettings = ({
  isVisible,
  onClose,
  ratio,
  tablet,
  
  translate,
  list,
  repetitions
}) => (
  <Modal
    className="courselistrepetitions"
    size="sm"
    show={isVisible}
    onHide={()=>onClose(false)}
    keyboard={false}
  >
    <Modal.Header closeButton>
      <Modal.Title as="h6">
        {translate('repetition_intervals_course')}
        <br/>
        <span className="introText2">{list.list_name}</span>
      </Modal.Title>
    </Modal.Header>

    <Modal.Body className="body">
      <div className="repetitionItems">
        {repetitions.map((item, i) => {

          let color = item.trainings == 0 && item.repetition_date < moment().format("YYYY-MM-DD") ? colors.coral : colors.greenish;

          let times = 'trainings_lc_plural';
          if(item.trainings == 1) {
            times = 'trainings_lc_singular';
          } else if(item.trainings > 1 && item.trainings < 5) {
            times = 'trainings_lc_und';
          } else if(item.trainings >= 5) {
            times = 'trainings_lc_plural';
          }

          return (
            <div key={i} className="w100 d-flex justify-content-start align-items-center mb2">
              <span className="description mr2" style={{fontSize: ratio * (!tablet ? 14 : 16)}}>
                {i + 1}. {item.repetition_date}
              </span>
              <span style={{fontSize: ratio * (!tablet ? 14 : 16), color}}>
                {item.trainings > 0 ? ` - ${item.trainings} ${translate(times)}` : item.repetition_date < moment().format("YYYY-MM-DD") ? translate('repetition_missed') : ""}
              </span>
            </div>
          )
        })}
      </div>
    </Modal.Body>
  </Modal>
);

export default CourseWordSettings;