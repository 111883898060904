import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logApiError} from './logSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* getUserNews(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getUserNews')
    .query({
      user_id: userId,
      type: 'web',
      alert: data.payload &&  data.payload.alert ? data.payload.alert : false
    });

    // console.log("getUserNews", result.body, data.payload)
    
    if(data.payload && data.payload.alert) {
      yield put({
        type: types.SET_ALERT_NEWS,
        payload: result.body.data
      });
    } else {
      yield put({
        type: types.SET_USER_NEWS,
        payload: result.body.data
      });
    }
  } catch (error) {
    yield logApiError({payload: {function: 'getUserNews', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getArticle(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getArticle')
    .query({
      user_id: userId,
      article_id: data.payload
    });

    // console.log("getArticle", result.body)

    yield put({
      type: types.SET_CURRENT_ARTICLE,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({payload: {function: 'getArticle', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getUnreadNews() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getUnreadNews')
    .query({
      user_id: userId,
    });

    // console.log("getUnreadNews", result.body)

    yield put({
      type: types.SET_UNREAD_NEWS,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({payload: {function: 'getUnreadNews', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* manageNews(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'manageNews')
    .send({
      user_id: userId,
      news_id: data.payload.newsId,
      type: data.payload.type,
    });

    // console.log('manageNews', result.body);
    
    if(result.body.result) {
      if(data.payload.type == 'seen' || data.payload.type == 'unseen') {
        yield put({
          type: types.TOGGLE_CURRENT_ARTICLE_VISIBILITY,
          payload: data.payload.type
        });
      }

      yield getUserNews({payload: {}});
      yield getUnreadNews();
    }
  } catch (error) {
    yield logApiError({payload: {function: 'manageNews', payload: data.payload,error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}