import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import moment from 'moment';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';

import Loader from "../../../components/Custom/Loader/index";

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import {getUserContacts} from '../../../actions/userContactsActions';
import {inviteUsersToMultiMaker} from '../../../actions/multiWordMakerActions';

import colors from '../../../lib/colors';


class InviteUsersToMultiWordMaker extends Component {

  constructor(props) {
    super(props);

    this.state = {

      tablet: false,
      invited: []

    }
  }

  componentDidMount() {
    // this.props.fetchingData(true);
    // this.props.getUserContacts();
  }

  componentWillUnmount() {
  }

  sendInvitation = () => {
    const {invited} = this.state;
    this.props.fetchingData(true);
    this.props.inviteUsersToMultiMaker(invited);
    this.props.onClose('inviteContactModalVisible', false)

  };

  _toggleInvited = (userId) => {
    const {invited} = this.state;
    let users = invited;

    if (invited.indexOf(userId) === -1) {
      users.push(userId);
    } else {
      users = users.filter(user => user != userId);
    }

    this.setState({
      invited: users
    })
  };

  render() {
    const {fetchingData} = this.props.activity;
    const {translate, onClose, isVisible} = this.props;
    const {userData} = this.props.user;
    const {invited} = this.state;
    const {userContacts} = this.props.userContacts;
    const {fontSizeRation} = this.props.settings;

    return (
      <Modal
        className="inviteusers"
        show={isVisible}
        onHide={()=>onClose('inviteContactModalVisible', false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="invite_friends"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>

          {
            userContacts.length > 0 && (
              <div>
                {userContacts.map((user, i) => {
                  return (
                    <div key={i} className="d-flex justify-content-start align-items-center">
                      <Form.Group controlId={i} className="mb-1">
                        <Form.Label className="d-flex justify-content-start align-items-start">
                          <Form.Check
                            id={i}
                            inline

                            type="checkbox"
                            checked={invited.indexOf(user.connected_user_id) !== -1}
                            onChange={()=>this._toggleInvited(user.connected_user_id)}
                          />
                          <div style={{marginTop: -3}} className="hover d-flex flex-column justify-content-start align-items-start">
                            <span style={{color: colors.tealish}}>{user.connected_username}</span>
                            <span className="text-muted">{user.user.email}</span>
                          </div>
                        </Form.Label>

                      </Form.Group>
                    </div>
                  )
                })}

                <Button
                  className="d-block ml-auto mr-auto mt-4"
                  variant="outline-info"
                  disabled={invited.length == 0}
                  onClick={this.sendInvitation}
                >
                  {translate('invite')}
                </Button>

                <a href="#" className="d-block mt-3 text-center"
                  onClick={this.props.toAddContact}
                >
                  {translate('add_contact')}
                </a>
              </div>
            )
          }


        </Modal.Body>

        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),
  userContacts: state.userContacts

});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    getUserContacts: () => dispatch(getUserContacts()),
    inviteUsersToMultiMaker: (users) => dispatch(inviteUsersToMultiMaker(users)),
  };
}

export default connect(mapStateToProps, bindAction)(InviteUsersToMultiWordMaker);
