import * as types from './types';

export const getHelperArticles = () => (dispatch) => {
    dispatch({ type: types.GET_HELPER_ARTICLES });
};

export const getHelperArticle = (id) => (dispatch) => {
  dispatch({ type: types.GET_HELPER_ARTICLE, payload: id });
};

export const getHelperArticleBySlug = (slug) => (dispatch) => {
  dispatch({ type: types.GET_HELPER_ARTICLE_BY_SLUG, payload: slug });
};

export const setCurrentHelperArticle = (article) => (dispatch) => {
    dispatch({ type: types.SET_CURRENT_HELPER_ARTICLE, payload: article });
};

export const changeArticleFontSize = (size) => (dispatch) => {
    dispatch({ type: types.SET_ARTICLE_FONT_SIZE, payload: size });
};

