import React, {Component} from 'react';
import {connect} from "react-redux";
import {Translate, getTranslate} from 'react-localize-redux';
import {Helmet} from "react-helmet";

import {Link} from "react-router-dom";
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faPlusCircle, faTimes} from '@fortawesome/free-solid-svg-icons';

import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import moment from 'moment';

import TrainingSubHeader from '../../../components/Headers/TrainingSubHeader/index';
import FinishTrainingButtons from '../../../components/Modals/FinishTrainingButtons/index';
import WordSettings from '../../../components/Modals/WordSettings/index';
import WordInfo from '../../../components/Modals/WordInfo/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
import ChangeAudioLanguage from '../../../components/Modals/ChangeAudioLanguage/index';
import ReportBug from '../../../components/Modals/ReportBug/index';
import TrainingSettings from '../../../components/Modals/TrainingSettings/index';
import RegisterAlert from '../../../components/Modals/RegisterAlert/index';

import Loader from "../../../components/Custom/Loader/index";

import {
  getWordsForTraining,
  resetWords,
  setDragImageSettings,
  setCachedDragImageWords,

  moveNewListToRepeat,
  removeListFromRepeatSection,
  updateListTrainings,
  updateTrainingsListFromRepeatSection,
  resetCurrentTrainingId,
  setCurrentTrainingId,
  shuffleTrainingWords,
  toggleShuffleWords
} from '../../../actions/trainingActions';
import {
  logListWord,
  logUserList,
  logGlossaryWord,
  logPhrase,
  logCourseList,
  logCourseListWord,
  logActivity
} from '../../../actions/logActions';
import {
  updateCachedListTrainings,
  updateCachedListTrainingsAndIntervals,
  shuffleListTrainingWords
} from '../../../actions/cacheActions';
import {getListsToRepeat, toggleListTrainingButtonsModal, getOnlyMistakes} from '../../../actions/lists';
import {fetchingData} from '../../../actions/activity';
import {
  toggleListTrainingButtonsModalCourse,
  getUpdatedCourseLists,
  getUpdatedCourseData,
  getUpdatedCurrentListScores,
  shuffleCourseListTrainingWords
} from '../../../actions/courseActions';
import {logTrainingLimits} from '../../../actions/purchasesActions';

import {checkForCachedWords, checkForCourseCachedWords} from '../../../lib/trainingFunctions';

import * as constants from '../../../lib/constants';
import colors from '../../../lib/colors';

const isTablet = false;

class DragImage extends Component {

  constructor(props) {
    super(props);

    this.track = null;

    this.state = {
      currentIndex: 0,
      translationsHeight: 40,
      currentPage: 1,
      totalRepeated: 1,
      downloads: 0,
      totalWords: 0,
      currentChunk: 0,
      scores: 0,
      score: 5,
      trainingId: 6,
      finishButtonsModalVisible: false,
      phrasebook: false,
      trainingInfoVisible: false,
      guessed: false,
      dropZoneValues: null,
      wordCardDimensions: null,
      imageDimensions: null,
      trainingName: 'Drag Image',
      tour: localStorage.getItem('tour'),

      autoslide: "1",
      autoslide_delay: "2",
      autosound_word: "1",
      showImage: true,
      imageWidth: '',
      imageHeight: '',
      correctIndex: null,
      selectedIndex: null,
      mistakes: 0,
      withMistakes: [],
      trainingResults: false,
      audioModalVisible: false,
      registerModalVisible: false,
      bugModalVisible: false,

      trainingAlertShouldShow: false,
      trainingAlertVisible: false,
      checking: false,
      wordSettingsModalVisible: false,
      trainingSettingsModalVisible: false,
      wordInfoVisible: false,
      draggingStatus: false,

      // position,
      // panResponder,
      // viewPosition,
      // viewOpacity,
      // viewPanResponder,

      freeze: false,
      finishedTraining: false,
      subscriptionMessage: 'subscription_trainings',
      subscriptionAlertVisible: false,
      shuffle: true,
      exiting: false,

      tablet: false,
      scrolled: 0,

      entityId: this.props.match.params.id,
      listId: undefined,
      courseListId: undefined,
      from: false,
      type: false,
      inside: false,

      imageTop: 0,
      imageLeft: 0,
      draggedData: false


    };

    this.timeOut = null;
    this.timeOut1 = null;
    this.timeOut2 = null;
    this.timeOut3 = null;
    this.timeOut4 = null;
  }

  componentDidMount() {
    this.getAlerts();

    this._setTrainingSettings();

    this.setCorrectIndex();

    if (!this.props.history.location || !this.props.history.location.state || !this.props.history.location.state.type) {
      this.props.history.goBack();
    } else {
      this.setState({
        [this.props.history.location.state.type]: parseInt(this.props.match.params.id, 10),
        from: this.props.history.location.state.from,
        type: this.props.history.location.state.type,
        inside: this.props.history.location.state.inside,
      })
    }

    const {deviceHeight, deviceWidth} = this.props.settings;

    const imageW = 200;
    const imageH = 144;

    this.origImageW = !isTablet ? (deviceHeight > 700 ? 200 : 145) : 300;
    this.origImageH = !isTablet ? (deviceHeight > 700 ? 145 : 105) : 218;

    this.origCardW = (deviceWidth - (!isTablet ? 40 : 180)) / 2;
    this.origCardH = deviceHeight > 700 ? ((deviceWidth / 2) - ((!isTablet ? 40 : 180) / 2)) / 1.38 : 105;


    this.setState({
      imageWidth: imageW,
      imageHeight: imageH,
    });


    this.timeOut = setTimeout(()=> {
      this.getWords();
    }, 100);

    this.props.setCurrentTrainingId(6);

    if (this.state.exiting) return;

    this.timeOut1 = setTimeout(() => {
      if (this.state.exiting) return;

      this.checkAlertsToShow()
    }, 2000);
  }

  componentDidUpdate(prevProps, prevState) {
    const {words} = this.props.data;

    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      if (words.length == 0) {
        this.getWords();
      }
    }
  }

  componentWillUnmount() {
    const {listId, courseListId} = this.state;

    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut1);
    clearTimeout(this.timeOut2);
    clearTimeout(this.timeOut3);
    clearTimeout(this.timeOut4);


    this.props.resetWords();
    this.props.resetCurrentTrainingId();

    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    if (listId != undefined) {
      this.onUnmountUpdates();
    }

    if (courseListId != undefined) {
      this.onUnmountCourseUpdates();
    }
  }

  onUnmountCourseUpdates = () => {
    const {withMistakes, finishedTraining} = this.state;
    const {inside, courseListId} = this.state;

    this._shuffleCourseWords();

    if (inside == undefined) {
      this.props.fetchingData(true);
    }

    if (finishedTraining || withMistakes.length > 0 || courseListId == 0) {
      this.props.getUpdatedCourseLists();
    } else {
      this.props.getUpdatedCourseData();
    }

    if (courseListId != 0) {
      this.props.getUpdatedCurrentListScores(courseListId);
    }
  };

  _shuffleCourseWords = () => {
    const {trainingId} = this.state;
    const {courseListId} = this.state;
    const {userData: {shuffle}} = this.props.user;

    if (courseListId && (courseListId > 0 || courseListId == -1) && shuffle == "1") {
      this.props.shuffleCourseListTrainingWords(trainingId, courseListId)
    }
  };

  _shuffleWords = () => {
    const {trainingId} = this.state;
    const {currentList} = this.props.lists;
    const {userData: {shuffle}} = this.props.user;

    if (currentList && (currentList.id > 0 || currentList.id == -1) && shuffle == "1") {
      this.props.shuffleListTrainingWords(trainingId, currentList.id)
    }
  };

  onUnmountUpdates = () => {
    const {withMistakes, finishedTraining} = this.state;
    const {currentList} = this.props.lists;

    this._shuffleWords();

    if (finishedTraining) {
      this.moveLists();
    }

    if (currentList && currentList.id == 0) {
      this.props.getOnlyMistakes();
    }

    if (currentList && currentList.id > 0 && withMistakes.length > 0) {
      this.props.getOnlyMistakes();
    }
  };

  moveLists = () => {
    const {currentList} = this.props.lists;

    let today = moment().format("YYYY-MM-DD");
    let todayTrainings = null;

    currentList.intervals && currentList.intervals.forEach(interval => {
      if (interval.repetition_date == today) {
        todayTrainings = interval.trainings;
      }
    });

    if (currentList && currentList.id > 0) {
      if (currentList.intervals && currentList.intervals.length != 0 && todayTrainings == 0) {
        this.props.updateTrainingsListFromRepeatSection(currentList.id);
      } else if (todayTrainings == 0) {
        this.props.moveNewListToRepeat(currentList);
      } else if (todayTrainings == 1) {
        this.props.updateTrainingsListFromRepeatSection(currentList.id);
      } else if (todayTrainings >= 2) {
        this.props.removeListFromRepeatSection(currentList.id);
        this.props.updateCachedListTrainings(currentList.id);
      } else if (todayTrainings == null) {
        this.props.moveNewListToRepeat(currentList);
        this.props.updateCachedListTrainingsAndIntervals(currentList);
      }
    }
  };

  _logOnExit = () => {
    const {words} = this.props.data;
    const {logUserList, logCourseList} = this.props;
    const {totalRepeated, trainingId} = this.state;
    const {listId, courseListId} = this.state;

    const percentage = totalRepeated / (words.length != 0 ? words.length : 20);

    this.setState({
      exiting: true
    });

    if (percentage >= 0.85 && listId && listId > 0) {
      this.setState({
        finishedTraining: true
      });
      logUserList({trainingId, listId, type: "end"});
    }

    if (percentage >= 0.85 && courseListId && courseListId != 0) {
      this.setState({
        finishedTraining: true
      });
      logCourseList({trainingId, listId: courseListId, type: "end"});
    }
  };

  getAlerts = () => {
    const {userId} = this.props.user;

    const alert_drag_word = localStorage.getItem(`alert_drag_word${userId}`);

    if (alert_drag_word === null) {
      this.setState({
        trainingAlertShouldShow: true
      })
    }
  };

  checkAlertsToShow = () => {
    const {trainingAlertShouldShow} = this.state;

    if (trainingAlertShouldShow) {
      this.setState({
        trainingAlertVisible: true
      });
      this.setAlert('alert_drag_word')
    }
  };

  togglAlertModal = (status) => {
    this.setState({
      trainingAlertVisible: status
    })
  };

  setAlert = (alert) => {
    const {userId} = this.props.user;

    localStorage.setItem(`${alert}${userId}`, 'true')
  };

  setCorrectIndex = () => {
    let n = Math.floor(Math.random() * 4);
    this.setState({
      correctIndex: n
    });
  };

  _setTrainingSettings = () => {
    const {userId} = this.props.user;
    const {trainingId} = this.state;

    try {
      const autoslide = localStorage.getItem(`autoslide_${trainingId}_${userId}`);
      const autoslide_delay = localStorage.getItem(`autoslide_delay_${trainingId}_${userId}`);
      const autosound_word = localStorage.getItem(`autosound_word_${trainingId}_${userId}`);

      if (autoslide != null) {
        this.setState({autoslide})
      } else {
        localStorage.setItem(`autoslide_${trainingId}_${userId}`, '1');
      }

      if (autoslide_delay != null) {
        this.setState({autoslide_delay})
      } else {
        localStorage.setItem(`autoslide_delay_${trainingId}_${userId}`, '2');
      }

      if (autosound_word != null) {
        this.setState({autosound_word})
      } else {
        localStorage.setItem(`autosound_word_${trainingId}_${userId}`, '1');
      }

      this.props.setDragImageSettings({
        autoslide: autoslide != null ? autoslide : "1",
        autoslide_delay: autoslide_delay != null ? autoslide_delay : "2",
        autosound_word: autosound_word != null ? autosound_word : "1"
      })
    } catch (e) {
      console.log(e)
    }
  };

  changeWord = (direction) => {
    const {totalWords} = this.props.data;
    const {currentIndex, scores} = this.state;
    var index = currentIndex;
    const {userData} = this.props.user;

    const {listId, courseListId} = this.state;

    let last = false;

    if (direction == "left") {
      this.checkIfNeedMore();

      if (currentIndex == totalWords - 1) {
        last = true;

        if ((listId != undefined && listId == 0) || (courseListId != undefined && courseListId == 0)) {
          this.props.history.goBack();
        } else {
          let active = this._checkTrainingsLimit();

          if (active) {
            if(userData && userData.active_trainings_set) {
              this.setState({
                finishedTraining: true
              });
              this._goToNextTraining()
            } else {
              this.setState({
                finishButtonsModalVisible: true
              })
            }
          } else {
            this._toggleSubscriptionAlert(true);
          }
        }
      } else {
        index = currentIndex + 1;

        this.setState({
          totalRepeated: this.state.totalRepeated + 1
        })
      }
    } else {
      if (currentIndex == 0) {
        index = totalWords - 1
      } else {
        index = currentIndex - 1
      }
    }

    this.setCorrectIndex();

    this.setState({
      showImage: true,
      guessed: false,
      checking: false,
      selectedIndex: null,
      currentIndex: index,
      freeze: false,
      draggingStatus: false
    });
  };

  _goToNextTraining = () => {
    const {logUserList, logCourseList} = this.props;

    const {listId, courseListId, type, trainingId} = this.state;
    const {userData} = this.props.user;
    const {locale} = this.props.settings;

    if (listId && listId > 0) {
      logUserList({trainingId, listId, type: "end"})
    } else if (courseListId && courseListId != 0) {
      logCourseList({trainingId, listId: courseListId, type: "end"});
    }
    
    let currentListId = null;

    if (listId != undefined) {
      currentListId = listId;
    }

    if (courseListId != undefined) {
      currentListId = courseListId;
    }

    let trainingIds = userData.trainings_set != null ? userData.trainings_set.split(',').map(Number) : [];

    const trainingIndex = trainingIds.length > 0 ? trainingIds.indexOf(trainingId) : null;

    if(trainingIndex === null || trainingIndex == (trainingIds.length - 1)) {
      this.setState({
        finishButtonsModalVisible: true
      });
      return;
    }

    if(currentListId != 0) {
      let activityId = '';
      let path = '';

      if (trainingIds[trainingIndex+1] == 1) {
        activityId = 39;
        path = 'preview'
      } else if (trainingIds[trainingIndex+1] == 6) {
        path = 'image';
        activityId = 40;
      } else if (trainingIds[trainingIndex+1] == 2) {
        path = 'translation';
        activityId = 41;
      } else if (trainingIds[trainingIndex+1] == 4) {
        path = 'word';
        activityId = 42;
      } else if (trainingIds[trainingIndex+1] == 3) {
        path = 'collect';
        activityId = 43;
      } else if (trainingIds[trainingIndex+1] == 5) {
        path = 'spelling';
        activityId = 44;
      } else if (trainingIds[trainingIndex+1] == 8) {
        activityId = 114;
        path = 'sentence';
      } else if (trainingIds[trainingIndex+1] == 9) {
        activityId = 131;
        path = 'memory'
      } else if (trainingIds[trainingIndex+1] == 10) {
        path = 'definition';
        activityId = 148;
      } else if (trainingIds[trainingIndex+1] == 7) {
        activityId = 95;
      } else if (trainingIds[trainingIndex+1] == 12) {
        path = 'missing';
        activityId = 315;
      } else if (trainingIds[trainingIndex+1] == 14) {
        path = 'listening';
        activityId = 345;
      } else if (trainingIds[trainingIndex+1] == 15) {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut4 = setTimeout(()=> {
        this.props.history.replace(`/${locale}/${path}/${currentListId}`, {
          type,
          from: 'inside',
          listId: currentListId
        });
      }, 100);

      this.props.logActivity({activityId, model: 'list', modelId: currentListId, comment: 'from home'});

    } else {
      this.setState({
        finishButtonsModalVisible: true
      })
    }
  };

  _checkTrainingsLimit = () => {
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;

    const {userData} = this.props.user;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    let active = false;

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    return active;
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    });

    if (!status) {
      this.props.history.goBack();
    }
  };

  _toSubscription = (productId) => {
    this.setState({
      subscriptionAlertVisible: false
    });
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId});
  };

  checkAnswer = (guessed) => {
    const {autoslide, autoslide_delay} = this.state;

    const {autoslide_drag_image, autoslide_delay_drag_image, autosound_word_drag_image} = this.props.data;

    if (guessed && autosound_word_drag_image == "1") {
      this.playSound();
    }

    this.checkIfNeedMore();

    if (guessed) {
      if (autoslide_drag_image == '1') {
        this.setState({
          freeze: true
        });

        if (this.state.exiting) return;

        this.timeOut2 = setTimeout(() => {
          if (this.state.exiting) return;

          this.changeWord("left")
        }, parseFloat(autoslide_delay_drag_image) * 1000)
      } else {
        this.setState({
          showImage: false,
          checking: false
        });
      }
    } else {
      this.setState({
        checking: false
      })
    }
  };

  playSound = () => {
    const {words} = this.props.data;
    const {userData} = this.props.user;
    let audioSource = constants.BACKEND_URL;

    if (words.length > 0 && words.length > this.state.currentIndex) {

      const word = words[this.state.currentIndex];

      if (word && word.audio) {

        let audio = word.audio;

        if (userData && userData.audio_language && word[userData.audio_language]) {
          audio = word[userData.audio_language];
          audioSource = constants.S3
        }

        if (this.track != null) {
          this.track.pause();
          this.track = null;
        }

        this.track = new Audio(`${audioSource}${audio}`);
        if (this.track != null) {
          this.track.play();
        }
      }
    }
  };

  toSettings = () => {
    this._toggleTrainingSettingsModal(true);
    this.props.logActivity({activityId: 189, comment: 'from drag image'});
  };

  checkIfNeedMore = () => {
    const {currentIndex, currentPage, downloads} = this.state;
    const {totalWords, words} = this.props.data;

    if (currentIndex + 3 == words.length && words.length < totalWords) {
      this.setState({
        currentPage: currentPage + 1,
        download: downloads + 1
      });

      this.getWords();
    }
  };

  getWords = () => {
    const {currentPage, trainingId} = this.state;
    const {dragImageCachedWords} = this.props.cache;
    const {dragImageCourseCachedWords} = this.props.courseCache;
    const {logUserList, logCourseList} = this.props;
    const {isConnected} = this.props.settings;

    let params = {
      trainingId,
      page: currentPage,
      history: this.props.history
    };

    const {listId, courseListId, glossaryId, phrasebookId, from, type} = this.state;

    if (listId >= -2) {
      params.listId = listId;
      params.endpoint = 'getTrainingWords'
    } else if (glossaryId) {
      params.glossaryId = glossaryId;
      params.endpoint = 'getCourseListWordsForTraining'
    } else if (courseListId !== false) {
      params.courseListId = courseListId;
      params.endpoint = 'getCourseTrainingWords'
    } else if (phrasebookId) {
      params.phrasebookId = phrasebookId;
      params.endpoint = 'getPhrasebookForTrainingO';

      this.setState({
        phrasebook: true
      });
    }

    let cachedWords = false;

    if (listId) {
      cachedWords = checkForCachedWords(dragImageCachedWords, listId, currentPage);
    } else if (courseListId) {
      cachedWords = checkForCourseCachedWords(dragImageCourseCachedWords, courseListId, currentPage);
    }

    if (!cachedWords) {
      if (isConnected) {
        if (currentPage == 1) {
          this.props.fetchingData(true);
        }
        this.props.getWordsForTraining(params);
      }
    } else {
      this.props.setCachedDragImageWords(cachedWords);
      this.props.logTrainingLimits();

      if (currentPage == 1) {
        if (listId && listId > 0) {
          logUserList({trainingId, listId, type: "start"})
        }

        if (courseListId && courseListId > 0) {
          logCourseList({trainingId, listId: courseListId, type: "start"});
        }
      }
    }
  };

  closeFinishButtonsModal = () => {
    this.setState({
      finishButtonsModalVisible: false
    });
  };

  continueTraining = () => {
    const {logUserList, logCourseList} = this.props;
    const {listId, courseListId, trainingId} = this.state;

    const {userData: {shuffle}} = this.props.user;

    this.moveLists();

    if (listId && (listId > 0 || listId == -1)) {
      if (shuffle == "1") {
        this.props.shuffleTrainingWords();
      }

      if (listId && listId > 0) {
        logUserList({trainingId, listId, type: "end"});

        if (this.state.exiting) return;

        this.timeOut3 = setTimeout(() => {
          if (this.state.exiting) return;

          logUserList({trainingId, listId, type: "start"})
        }, 1000);
      }
    }

    if (courseListId && (courseListId > 0 || courseListId == -1)) {
      if (shuffle == "1") {
        this.props.shuffleTrainingWords();
      }

      if (courseListId && courseListId > 0) {
        logCourseList({trainingId, listId: courseListId, type: "end"});

        if (this.state.exiting) return;

        this.timeOut4 = setTimeout(()=> {
          if (this.state.exiting) return;

          logCourseList({trainingId, listId: courseListId, type: "start"});
        }, 1000);
      }
    }

    this.setState({
      finishButtonsModalVisible: false,
      currentIndex: 0,
      totalRepeated: 1,
    });

    this.props.logTrainingLimits();

    this.props.logActivity({activityId: 92});
  };

  anotherTraining = () => {
    const {logUserList, logCourseList} = this.props;
    const {trainingId, listId, courseListId, from, type} = this.state;

    if (listId !== undefined && from == 'listWords') {
      this.props.toggleListTrainingButtonsModal(true)
    }

    if (courseListId !== undefined && from == 'listWords') {
      this.props.toggleListTrainingButtonsModalCourse(true)
    }

    if (listId && listId > 0) {
      logUserList({trainingId, listId, type: "end"})
    } else if (courseListId && courseListId != 0) {
      logCourseList({trainingId, listId: courseListId, type: "end"});
    }

    this.setState({
      finishButtonsModalVisible: false,
      finishedTraining: true

    });

    this.props.history.goBack();

    this.props.logActivity({activityId: 190, comment: 'Drag Image'});
  };

  toggleInfoModal = (status) => {
    this.setState({
      trainingInfoVisible: status
    })
  };

  _openTrainingInfo = () => {
    this.toggleWordSettingsModal(false);
    this.toggleInfoModal(true);
  };

  _reportTrainingBug = () => {
    const {words, totalWords} = this.props.data;
    const {currentIndex, listId, courseListId} = this.state;
    let word = words.length > 0 ? words[currentIndex] : false;
    let extra = {};

    extra.word = word && word.word ? word.word : "";
    extra.translation = word && word.main_translation && word.main_translation.translation ? word.main_translation.translation : "";
    extra.listId = listId;
    extra.courseListId = courseListId;

    this.setState({
      extra
    });
    this.props.logActivity({activityId: 234, comment: "Drag Image"});

    this.toggleWordSettingsModal(false);

    this._toggleBugModal(true);
  };

  _openTrainingSettings = () => {
    this.toggleInfoModal(false);
    this.toggleWordSettingsModal(false);

    if(!this.state.tour) {
      this.toSettings();
    } else {
      this._toggleRegisterModal(true)
    }
  };

  _toWordDetails = () => {
    this.toggleWordSettingsModal(false);
    const {words} = this.props.data;
    const {currentIndex} = this.state;

    let word = words.length > 0 ? words[currentIndex] : false;

    if (!word) return;

    this.props.get_word_details(word.id);

    this._toggleWordInfo(true);

    this.props.logActivity({activityId: 45, model: 'word', modelId: word.id, comment: "from Drag Image"});
  };

  _changeVoice = () => {
    this.toggleWordSettingsModal(false);

    this.props.logActivity({activityId: 332});

    this._toggleAudioModal(true);
  };

  _toggleAudioModal = (status) => {
    this.setState({
      audioModalVisible: status
    });
  };

  toggleWordSettingsModal = (status) => {
    this.toggleInfoModal(false);

    this.setState({
      wordSettingsModalVisible: status
    })
  };

  _log = (mistake) => {
    const {words} = this.props.data;
    const {currentIndex} = this.state;
    let word = words.length > 0 ? words[currentIndex] : false;
    const {trainingId, listId, courseListId, glossaryId} = this.state;

    if (word) {
      let params = {
        trainingId: trainingId,
        mistake,
        id: word.id
      };

      if (listId !== undefined && listId >= -1) {
        params.listId = listId;
        this.props.logListWord(params);
      } else if (glossaryId !== undefined && glossaryId) {
        params.glossaryId = glossaryId;
        this.props.logGlossaryWord(params);
      } else if (courseListId !== undefined && courseListId >= -1) {
        params.courseListId = courseListId;
        this.props.logCourseListWord(params);
      }
    }
  };

  _toResults = () => {
    const {withMistakes, trainingResults, scores} = this.state;
    if (!trainingResults) {
      // this.props.navigation.navigate('TrainingResults', {withMistakes, scores});

      this.setState({trainingResults: true});
    } else {
      this.props.history.goBack();
    }
  };

  _toResultsFromModal = () => {
    const {withMistakes, scores} = this.state;
    this.setState({
      finishButtonsModalVisible: false,
    });
    // this.props.navigation.navigate('TrainingResults', {withMistakes, scores});
  };

  _dropImage = (e) => {
    e.persist();
    e.preventDefault();

    this._check(e);
  };

  _check = (e) => {
    const {draggedData} = this.state;

    // console.log("dropData", e.target.id, `X: ${e.screenX}, Y: ${e.screenY}`)
    // console.log("draggedData", draggedData, `X: ${draggedData.screenX}, Y: ${draggedData.screenY}`)

    const id = e.target.id;

    if (this.isDropZone(e, id)) {
      this.setState({
        guessed: true,
        showImage: false,
        scores: this.state.scores + this.state.score
      });
      this.checkAnswer(true);
      this._log(0);
    } else {
      const {words} = this.props.data;
      const word = words[this.state.currentIndex];

      let withMistakes = this.state.withMistakes;
      let found = withMistakes.indexOf(word);

      if (found === -1) {
        withMistakes.push(word);
      }

      this.setState({
        mistakes: this.state.mistakes + 1,
        withMistakes,
      });

      this.checkAnswer(false);
      this._log(1);
    }
  };

  isDropZone(e, id) {
    const {deviceWidth, deviceHeight} = this.props.settings;
    const {correctIndex, dropZoneValues, tablet} = this.state;

    // console.log("isDropZone",correctIndex, id)

    if (id == correctIndex) {
      this.setState({
        selectedIndex: correctIndex
      });
      return true;
    } else {
      this.setState({
        selectedIndex: false
      });

      return false;
    }
  }

  renderButton = (word, ind) => {
    const {selectedIndex, correctIndex, tablet, checking, guessed} = this.state;
    const imageUrl = word && word.image && word.image.filename ? word.image.filename : false;
    const {fontSizeRation, deviceWidth, deviceHeight} = this.props.settings;

    let imageWidthh = !tablet ? 300 : 400;

    const s3image = `${constants.S3Images}-${imageWidthh}/${imageUrl}`;

    if (selectedIndex !== null && selectedIndex === ind) {
      return (
        <div
          id={ind}
          className="wordCard"
          style={{
        margin: "5px 5px",
        width: 200,
        height: 144,
        borderColor: colors.tealish}
        }>
          <Image
            className="wordImage"
            src={s3image}
            style={{width: 198, height: 142, borderWidth: 0}}
          />
        </div>
      )
    } else {
      return (
        <div
          id={ind}
          onDrop={this._dropImage}
          onDragOver={e => {
          e.persist();
              e.preventDefault();
          }}
          className="notSelected wordCard"
          style={{
        margin: "5px 5px",
        width: 200,
        height: 144,
        borderColor: selectedIndex === false ? colors.coral : colors.tealish
        }}
        >
          <p
            className="wordCardText mb-0"
            style={{color: selectedIndex === false ? colors.coral :  colors.tealish, fontSize: fontSizeRation * (!tablet ? 16 : 18)}}>
            {word && correctIndex === ind ? word.word : word && word.other_words && word.other_words.length > 0 ? word.other_words[ind].word : ""}
          </p>
        </div>
      )
    }
  };

  changeWhatToDisplay = (e) => {
    e.preventDefault();

    this.setState({
      showImage: !this.state.showImage
    })
  };

  _goBack = () => {
    this.setState({
      finishButtonsModalVisible: false
    });

    this._logOnExit();

    this.props.history.goBack();

    this.props.logActivity({activityId: 306, comment: 'Drag Image'});
  };

  _toggleShuffle = () => {
    const {userData} = this.props.user;

    this.toggleWordSettingsModal(false);

    if(!this.state.tour) {
      this.props.fetchingData(true);
      this.props.toggleShuffleWords();
      this.props.logActivity({activityId: 333, comment: userData && userData.shuffle == 1 ? "on" : "off"});
    }  else {
      this._toggleRegisterModal(true)
    }
  };
    

  _toggleRegisterModal = (status, e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      registerModalVisible: status
    })
  };

  _toRegister = () => {
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/register`)
  };

  _toggleBugModal = (status) => {
    this.setState({
      bugModalVisible: status
    });
  };

  _toggleTrainingSettingsModal = (status) => {
    this.setState({
      trainingSettingsModalVisible: status
    });
  };

  _toggleWordInfo = (status) => {
    this.setState({
      wordInfoVisible: status
    })
  };

  _draggingImage = (e) => {
    this.setState({
      draggedData: e
    });
  };

  _imageDragFinished = (e) => {
    this.setState({
      draggedData: e,
      draggingStatus: false
    });
  };

  _setDraggingStatus = (e) => {
    this.setState({
      draggingStatus: true
    })
  };

  render() {
    const {words, totalWords} = this.props.data;
    const {logUserList, logCourseList, translate} = this.props;

    const {
      currentIndex, guessed, mistakes, withMistakes,
      trainingId, totalRepeated, imageWidth, imageHeight,
      showImage, scores, finishButtonsModalVisible, trainingInfoVisible,
      trainingAlertVisible, checking, wordSettingsModalVisible,
      tablet,
      freeze,
      subscriptionAlertVisible,
      subscriptionMessage,
      audioModalVisible,
      registerModalVisible,
      bugModalVisible,
      extra,
      trainingSettingsModalVisible,
      wordInfoVisible,
      imageTop,
      imageLeft,
      draggingStatus
    } = this.state;
    const {fontSizeRation, version, deviceWidth, deviceHeight} = this.props.settings;

    const {listId, courseListId, glossaryId, phrasebookId, from, type} = this.state;

    const {fetchingData} = this.props.activity;
    const {userData, userId} = this.props.user;
    const {currentList} = this.props.lists;
    const {course} = this.props;

    let subtitle = "";

    if (currentList && currentList.list_name && totalWords) {
      subtitle = `${currentList.list_name}: ${totalWords}`
    } else if (course && course.currentList && course.currentList.list_name) {
      subtitle = `${course.currentList.list_name}: ${totalWords}`
    }

    if (listId == -1 || courseListId == -1) {
      subtitle = translate('vocabulary')
    }

    let word = words.length > 0 ? words[currentIndex] : false;

    const progress = totalWords !== 0 ? ((currentIndex + 1) / totalWords) : 0;

    const translation = word && word.main_translation && word.main_translation.translation ? word.main_translation.translation : "";
    const otherTranslations = word && word.otherTranslationsString ? word.otherTranslationsString : "";
    const imageUrl = word && word.image && word.image.filename ? word.image.filename : false;
    const imageUserId = word && word.image && word.image.user_id ? word.image.user_id : false;

    let imageWidthh = !tablet ? 300 : 400;

    let s3image = `${constants.S3Images}-${imageWidthh}/${imageUrl}`;

    if (imageUserId && imageUserId == userId) {
      s3image = `${constants.IMAGE_URL}/${imageUrl}?width=${imageWidth}`;
    }

    let imageWrapperClasses = classNames({
      'd-flex justify-content-center align-items-center imageWrap': true,
      'wordImageWrapper': (imageUrl && showImage && !checking),
      'textImageWrapper': !imageUrl || !showImage || checking
    });

    return (
      <div className="dragimage">
        <Container className="pageWrapper">

          <TrainingSubHeader
            trainingId={trainingId}
            freeze={freeze}
            key={freeze}
            version={version}
            ratio={fontSizeRation}
            scores={scores}
            progress={progress}
            changeWord={this.changeWord}

            deviceHeight={deviceHeight}
            deviceWidth={deviceWidth}
            translate={translate}
            tablet={tablet}
            exit={this._goBack}
            history={this.props.history}
            trainingName="drag_image_training"
            subtitle={subtitle}

            title={'drag_image_training'}
            text="drag_image_training_description"
            url="https://youtu.be/QkL98_pKyAU"
            toggleSettings={this.toggleWordSettingsModal}
          />

          <Card
            style={{minHeight: deviceHeight - 140,backgroundColor: 'white',opacity: this.state.viewOpacity}}
            className="flexVertical flexCenter"
          >
            <div style={{opacity: word && word.id ? 1 : 0}} className="contentWrapper">

              <div
                onClick={this.changeWhatToDisplay}
                className={imageWrapperClasses}
                style={{width: 200, height: 144, marginTop: 10}}
              >
                {imageUrl && showImage && !checking && (
                  <Image
                    draggable="true"
                    // onTouchStart={e => this._setDraggingStatus(true)}
                    onDragStart={e => this._setDraggingStatus(true)}
                    onDrag={e =>{
                      e.persist();
                      this._draggingImage(e);
                      // console.log(e, e.nativeEvent.offsetX,e.nativeEvent.offsetY)
                    }}
                    /*onTouchMove={e =>{
                      e.persist();
                      this._draggingImage(e);
                      // console.log(e, e.nativeEvent.offsetX,e.nativeEvent.offsetY)
                    }}
                    onTouchEnd={e => {
                      e.persist();
                      this._imageDragFinished(e);
                    }}*/
                    onDragEnd={e =>{
                      e.persist();
                      this._imageDragFinished(e);
                      // console.log(e, e.clientX, e.clientY, e.nativeEvent.offsetX,e.nativeEvent.offsetY)
                    }}
                    className="wordImage"
                    src={s3image}
                    style={{
                    opacity: draggingStatus ? 0 : 1,
                    top:imageTop, left:imageLeft,
                    width: imageWidth, height: imageHeight, userSelect: 'none'}}
                  />
                ) || (
                    <div
                      onClick={this.changeWhatToDisplay}
                      className="behindImage d-flex flex-column"
                      style={{width: 200, height: 144}}
                    >
                      <p
                        className="behindImageText mb-0"
                        style={{fontSize: fontSizeRation * (!tablet ? (deviceWidth > 360 ? 14 : 12) : 16)}}
                      >
                        {translation}
                      </p>
                      {
                        otherTranslations && (
                          <p
                            className="textCenter ph2 mt2"
                            style={{
                          fontSize: fontSizeRation * (!tablet ? (deviceWidth > 360 ? 12 : 11) : 14)
                          }}>
                            {otherTranslations}
                          </p>
                        ) || null
                      }
                    </div>
                )}
              </div>

              <div className="wordsRows">
                {this.renderButton(word, 0)}
                {this.renderButton(word, 1)}
              </div>
              <div className="wordsRows">
                {this.renderButton(word, 2)}
                {this.renderButton(word, 3)}
              </div>

            </div>
          </Card>

          {finishButtonsModalVisible && (
            <FinishTrainingButtons
              ratio={fontSizeRation}
              isVisible={finishButtonsModalVisible}
              onClose={this.closeFinishButtonsModal}
              continueTraining={this.continueTraining}
              anotherTraining={this.anotherTraining}
              withMistakes={withMistakes}
              toResults={this._toResultsFromModal}
              glossaryId={glossaryId}
              returnBack={this._goBack}
              from={from}

              translate={translate}
              tablet={tablet}
            />
          )}

          {wordSettingsModalVisible && (
            <WordSettings
              changeVoice={this._changeVoice}
              ratio={fontSizeRation}
              isVisible={wordSettingsModalVisible}
              onClose={this.toggleWordSettingsModal}
              openTrainingInfo={this._openTrainingInfo}
              openTrainingSettings={this._openTrainingSettings}
              toWordDetails={this._toWordDetails}
              reportTrainingBug={this._reportTrainingBug}
              title={translate('drag_image_training')}
              shuffle={userData.shuffle}
              shouldShuffle={true}
              toggleShuffle={this._toggleShuffle}
            />
          )}

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={subscriptionMessage}
                translate={translate}
                deviceWidth={deviceWidth}
                deviceHeight={deviceHeight}
              />
            )
          }

          {
            audioModalVisible && (
              <ChangeAudioLanguage
                onClose={this._toggleAudioModal}
                isVisible={audioModalVisible}
                toRegister={this._toggleRegisterModal}

              />
            )
          }

          {
            bugModalVisible && (
              <ReportBug
                onClose={this._toggleBugModal}
                isVisible={bugModalVisible}
                type="training"
                training="Drag image"
                extra={extra}
              />
            )
          }

          {
            trainingSettingsModalVisible && (
              <TrainingSettings
                onClose={this._toggleTrainingSettingsModal}
                isVisible={trainingSettingsModalVisible}
                trainingName="drag_image_training"
                trainingId={trainingId}
              />
            )
          }

          {wordInfoVisible && (<WordInfo onClose={this._toggleWordInfo} isVisible={wordInfoVisible}/>)}

          {registerModalVisible && (
            <RegisterAlert
              onClose={this._toggleRegisterModal}
              isVisible={registerModalVisible}
              translate={translate}
              toRegister={this._toRegister}
            />
          )}

          <Loader fetchingData={fetchingData}/>

        </Container>

        <Helmet>
          <title>{`${translate('app_title_drag_image_training')}`}</title>
          <meta property="og:title" content={`${translate('app_title_drag_image_training')}`} />
          <meta name="description" content={translate('app_title_drag_image_training_description')} />
          <meta name="og:description" content={translate('app_title_drag_image_training_description')} />

          <link rel="canonical" href={window.location.href} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
      </div>
    )
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  lists: state.lists,
  data: state.trainings,
  activity: state.activity,
  settings: state.settings,
  cache: state.cache,
  courseCache: state.courseCache,
  purchases: state.purchases,
  course: state.course,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    getWordsForTraining: (data) => dispatch(getWordsForTraining(data)),
    resetWords: () => dispatch(resetWords()),
    logListWord: (data) => dispatch(logListWord(data)),
    logPhrase: (data) => dispatch(logPhrase(data)),
    logGlossaryWord: (data) => dispatch(logGlossaryWord(data)),
    logUserList: (data) => dispatch(logUserList(data)),
    logActivity: (data) => dispatch(logActivity(data)),
    getListsToRepeat: (limit, userId) => dispatch(getListsToRepeat(limit, userId)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    toggleListTrainingButtonsModal: (status) => dispatch(toggleListTrainingButtonsModal(status)),
    logCourseListWord: (data) => dispatch(logCourseListWord(data)),
    logCourseList: (data) => dispatch(logCourseList(data)),
    setDragImageSettings: (data) => dispatch(setDragImageSettings(data)),
    setCachedDragImageWords: (data) => dispatch(setCachedDragImageWords(data)),

    moveNewListToRepeat: (list) => dispatch(moveNewListToRepeat(list)),
    removeListFromRepeatSection: (listId) => dispatch(removeListFromRepeatSection(listId)),
    updateListTrainings: (listId) => dispatch(updateListTrainings(listId)),
    updateTrainingsListFromRepeatSection: (listId) => dispatch(updateTrainingsListFromRepeatSection(listId)),
    getOnlyMistakes: () => dispatch(getOnlyMistakes()),
    updateCachedListTrainings: (listId) => dispatch(updateCachedListTrainings(listId)),
    updateCachedListTrainingsAndIntervals: (list) => dispatch(updateCachedListTrainingsAndIntervals(list)),

    toggleListTrainingButtonsModalCourse: (status) => dispatch(toggleListTrainingButtonsModalCourse(status)),
    getUpdatedCurrentListScores: (listId) => dispatch(getUpdatedCurrentListScores(listId)),
    getUpdatedCourseLists: () => dispatch(getUpdatedCourseLists()),
    getUpdatedCourseData: () => dispatch(getUpdatedCourseData()),
    resetCurrentTrainingId: () => dispatch(resetCurrentTrainingId()),
    setCurrentTrainingId: (id) => dispatch(setCurrentTrainingId(id)),
    logTrainingLimits: () => dispatch(logTrainingLimits()),
    shuffleListTrainingWords: (trainingId, listId) => dispatch(shuffleListTrainingWords(trainingId, listId)),
    shuffleCourseListTrainingWords: (trainingId, listId) => dispatch(shuffleCourseListTrainingWords(trainingId, listId)),
    shuffleTrainingWords: () => dispatch(shuffleTrainingWords()),
    toggleShuffleWords: () => dispatch(toggleShuffleWords()),

  };
}

export default connect(mapStateToProps, bindAction)(DragImage);
