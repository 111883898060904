import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import _ from 'lodash'

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faPlusCircle, faCheckCircle, faCircle} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';

import Loader from "../../../components/Custom/Loader/index";

import {setFontSize} from '../../../actions/settingsActions';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

class ListSettings extends Component {

  render() {
    const {fetchingData} = this.props.activity;
    const {
      isVisible,
      onClose,
      title,
      ratio,
      deleteList,
      removeFromCalendar,
      list,
      postpone,
      cleanListCache,
      type,
      tour,
      openTagsModal,
      translate,
      toggleFavorite,
      home
    } = this.props;

    return (
      <Modal
        size="sm"
        className="listsettings"
        show={isVisible}
        onHide={()=>onClose('listWordsSettingsModalVisible',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            {title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{padding: '5px 0'}}>
          <ListGroup variant="flush">

            {
              !home && (
                <ListGroup.Item action onClick={(e)=>openTagsModal(list, e)}>
                  {translate(list && list.tag ? 'manage_list_tag' : 'add_list_tag')}
                </ListGroup.Item>
              ) || null
            }

            <ListGroup.Item action onClick={cleanListCache}>
              {translate('clean_list_cache')}
            </ListGroup.Item>

            {
              !home && (
                <ListGroup.Item action onClick={(e)=>toggleFavorite(list, e)}>
                  {translate(list.favourite ? 'delete_from_favorites' : 'add_to_favorites')}
                </ListGroup.Item>
              ) || null
            }
            {type == 'repeat' && list && list.intervals && list.intervals.length > 0 && (
              <ListGroup.Item action onClick={(e)=>postpone(list, e)}>
                {translate('postpone_one_day')}
              </ListGroup.Item>
            )}

            {type == 'repeat' && list && list.intervals && list.intervals.length > 0 && (
              <ListGroup.Item action className="text-danger" onClick={(e)=>removeFromCalendar(e)}>
                {translate('remove_from_calendar')}
              </ListGroup.Item>
            )}

            <ListGroup.Item action className="text-danger" onClick={deleteList}>
              {translate('delete_list')}
            </ListGroup.Item>

          </ListGroup>
        </Modal.Body>
        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    setFontSize: (size) => dispatch(setFontSize(size)),
  };
}

export default connect(mapStateToProps, bindAction)(ListSettings);
