import React, {Component} from 'react';
import {connect} from "react-redux";
import {Helmet} from "react-helmet";

import {Translate, getTranslate} from 'react-localize-redux';

import {Link} from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faPlusCircle, faTimes} from '@fortawesome/free-solid-svg-icons';

import {toastr} from 'react-redux-toastr';

import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';

import moment from 'moment';

import TrainingSubHeader from '../../../components/Headers/TrainingSubHeader/index';
import FinishTrainingButtons from '../../../components/Modals/FinishTrainingButtons/index';
import TrainingAlert from '../../../components/Modals/TrainingAlert/index';
import WordSettings from '../../../components/Modals/WordSettings/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
import ChangeAudioLanguage from '../../../components/Modals/ChangeAudioLanguage/index';
import ReportBug from '../../../components/Modals/ReportBug/index';
import TrainingSettings from '../../../components/Modals/TrainingSettings/index';
import WordInfo from '../../../components/Modals/WordInfo/index';
import RegisterAlert from '../../../components/Modals/RegisterAlert/index';

import AddExampleTranslationModal from '../../../components/Modals/AddExampleTranslationModal/index';
import AddWordModal from '../../../components/Modals/AddWordModal/index';
import ListsToFill from '../../../components/Modals/ListsToFill/index';
import Loader from "../../../components/Custom/Loader/index";

import {
  getWordsForTraining,
  resetWords,
  getGoogleExampleTranslation,
  setCollectSentenceSettings,
  setCachedCollectSentenceWords,

  moveNewListToRepeat,
  removeListFromRepeatSection,
  updateListTrainings,
  updateTrainingsListFromRepeatSection,
  resetCurrentTrainingId,
  setCurrentTrainingId,
  shuffleTrainingWords,
  toggleShuffleWords,
  addUserExampleTranslation,
} from '../../../actions/trainingActions';
import {
  updateCachedListTrainings,
  updateCachedListTrainingsAndIntervals,
  shuffleListTrainingWords
} from '../../../actions/cacheActions';
import {
  logListWord,
  logUserList,
  logGlossaryWord,
  logPhrase,
  logCourseList,
  logCourseListWord,
  logActivity
} from '../../../actions/logActions'
import {
  getListsToRepeat,
  getListsNumber,
  toggleListTrainingButtonsModal,
  getOnlyMistakes,
  fillListFromGlossaryWithSelectedWords,
  getUserListsToFill
} from '../../../actions/lists';
import {setFirstPlayed, get_word_details} from '../../../actions/wordActions';
import {fetchingData} from '../../../actions/activity';
import {
  toggleListTrainingButtonsModalCourse,
  getUpdatedCourseLists,
  getUpdatedCourseData,
  getUpdatedCurrentListScores,
  shuffleCourseListTrainingWords
} from '../../../actions/courseActions';
import {logTrainingLimits} from '../../../actions/purchasesActions';
import {findWordInDictionary, resetFoundDictionaryWord} from '../../../actions/dictionaryActions';

import {checkForCachedWords, checkForCourseCachedWords} from '../../../lib/trainingFunctions';

import colors from '../../../lib/colors';
import * as constants from '../../../lib/constants';

class CollectSentence extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
      translationsHeight: 40,
      currentPage: 1,
      totalRepeated: 1,
      downloads: 0,
      totalWords: 0,
      currentChunk: 0,
      scores: 0,
      score: 30,
      finishButtonsModalVisible: false,
      exampleTranslationModalVisible: false,
      audioModalVisible: false,
      bugModalVisible: false,
      trainingSettingsModalVisible: false,
      wordInfoVisible: false,

      trainingId: 8,
      correctIndex: null,
      selectedIndex: null,
      answered: false,
      timesTapped: 0,
      wordButtons: [],
      shuffledWordButtons: [],
      wordButtonsToHide: [],
      shuffledLetters: [],
      wasShuffled: false,
      trainingName: 'Collect Sentence (tap)',
      registerModalVisible: false,
      tour: localStorage.getItem('tour'),

      draggedWords: [],
      autoslide: "0",
      autoslide_delay: "2",
      autosound_sentence: "1",
      autoslide_type: "sentence",
      mistakes: 0,
      totalMistakes: 0,
      withMistakes: [],
      trainingResults: false,
      trainingAlertShouldShow: false,
      trainingAlertVisible: false,
      wordSettingsModalVisible: false,
      currentExampleIndex: 0,
      phrasebook: false,
      exampleTranslationHeight: null,
      freeze: false,
      finishedTraining: false,
      subscriptionMessage: 'subscription_trainings',
      subscriptionAlertVisible: false,
      translateOptionsModalVisible: false,
      shuffle: true,
      currentCharIndex: -1,
      allowedMistakes: 2,
      exiting: false,
      addWordModalVisible: false,
      lookWord: false,
      listsModalIsVisible: false,


      tablet: false,
      scrolled: 0,

      entityId: this.props.match.params.id,
      listId: undefined,
      courseListId: undefined,
      from: false,
      type: false,
      inside: false,

    };

    this.track = null;
    this.timeOut = null;
    this.timeOut1 = null;
    this.timeOut2 = null;
    this.timeOut3 = null;
    this.timeOut4 = null;
    this.timeOut5 = null;
  }

  componentDidMount() {
    document.addEventListener("keydown", this._handleKeyDown);

    if (!this.props.history.location || !this.props.history.location.state || !this.props.history.location.state.type) {
      this.props.history.goBack();
    } else {
      this.setState({
        [this.props.history.location.state.type]: parseInt(this.props.match.params.id, 10),
        from: this.props.history.location.state.from,
        type: this.props.history.location.state.type,
        inside: this.props.history.location.state.inside,
      })
    }

    this._setTrainingSettings();
    this.props.setCurrentTrainingId(8);

    this.setCorrectIndex();

    this._showPossibleErrorsAlert();

    this.timeOut = setTimeout(()=> {
      this.getWords();
    }, 100);

    this.timeOut1 = setTimeout(() => {
      this.props.getListsNumber();

      this.props.getUserListsToFill();
    }, 3000);
  }

  componentDidUpdate(prevProps, prevState) {
    const {words} = this.props.data;

    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      if (words.length == 0) {
        this.getWords();
      }
    }
  }

  componentWillUnmount() {
    const {listId, courseListId} = this.state;
    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut1);
    clearTimeout(this.timeOut2);
    clearTimeout(this.timeOut3);
    clearTimeout(this.timeOut4);
    clearTimeout(this.timeOut5);

    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    this.props.resetWords();
    this.props.resetCurrentTrainingId();

    if (listId != undefined) {
      this.onUnmountUpdates();
    }

    if (courseListId != undefined) {
      this.onUnmountCourseUpdates();
    }

    this.props.setFirstPlayed(false);

    document.removeEventListener("keydown", this._handleKeyDown);
  }

  _handleKeyDown = (event) => {
    const {freeze} = this.state;

    switch (event.keyCode) {
      case 39:
        if (!freeze) {
          this.changeWord('left')
        }
        break;
      case 37:
        if (!freeze) {
          this.changeWord('right')
        }
        break;

      case 27:
        this.props.history.goBack();
        break;
      default:
        break;
    }
  };

  _showPossibleErrorsAlert = async() => {
    const {userId} = this.props.user;
    const {translate} = this.props;
    const {trainingId} = this.state;

    const errorsAlert = localStorage.getItem(`errors_alert_${trainingId}_${userId}`);

    if (errorsAlert == null) {
      toastr.error(translate('to_info'), translate('possible_error'), {showCloseButton: true, closeOnToastrClick: true});
      localStorage.setItem(`errors_alert_${trainingId}_${userId}`, "1")
    }
  };

  onUnmountCourseUpdates = () => {
    const {totalMistakes, finishedTraining} = this.state;
    const {inside, courseListId} = this.state;

    this._shuffleCourseWords();

    if (inside == undefined) {
      this.props.fetchingData(true);
    }

    if (finishedTraining || totalMistakes > 0 || courseListId == 0) {
      this.props.getUpdatedCourseLists();
    } else {
      this.props.getUpdatedCourseData();
    }

    if (courseListId != 0) {
      this.props.getUpdatedCurrentListScores(courseListId);
    }
  };

  _shuffleCourseWords = () => {
    const {trainingId, courseListId} = this.state;
    const {userData: {shuffle}} = this.props.user;

    if (courseListId && (courseListId > 0 || courseListId == -1) && shuffle == "1") {
      this.props.shuffleCourseListTrainingWords(trainingId, courseListId)
    }
  };

  _shuffleWords = () => {
    const {trainingId} = this.state;
    const {listId} = this.state;
    const {userData: {shuffle}} = this.props.user;

    if (listId && (listId > 0 || listId == -1) && shuffle == "1") {
      this.props.shuffleListTrainingWords(trainingId, listId)
    }
  };

  onUnmountUpdates = () => {
    const {totalMistakes, finishedTraining, listId} = this.state;
    const {currentList} = this.props.lists;
    const {userId} = this.props.user;

    this._shuffleWords();

    if (finishedTraining) {
      this.moveLists();
    }

    if (currentList && currentList.id == 0) {
      this.props.getOnlyMistakes();
    }

    if (currentList && currentList.id > 0 && totalMistakes > 0) {
      this.props.getOnlyMistakes();
    }
  };

  moveLists = () => {
    const {currentList} = this.props.lists;

    let today = moment().format("YYYY-MM-DD");
    let todayTrainings = null;

    currentList.intervals && currentList.intervals.forEach(interval => {
      if (interval.repetition_date == today) {
        todayTrainings = interval.trainings;
      }
    });

    if (currentList && currentList.id > 0) {
      if (currentList.intervals && currentList.intervals.length != 0 && todayTrainings == 0) {
        this.props.updateTrainingsListFromRepeatSection(currentList.id);
      } else if (todayTrainings == 0) {
        this.props.moveNewListToRepeat(currentList);
      } else if (todayTrainings == 1) {
        this.props.updateTrainingsListFromRepeatSection(currentList.id);
      } else if (todayTrainings >= 2) {
        this.props.removeListFromRepeatSection(currentList.id);
        this.props.updateCachedListTrainings(currentList.id);
      } else if (todayTrainings == null) {
        this.props.moveNewListToRepeat(currentList);
        this.props.updateCachedListTrainingsAndIntervals(currentList);
      }
    }
  };

  _logOnExit = () => {
    const {words} = this.props.data;
    const {logUserList, logCourseList} = this.props;
    const {totalRepeated, trainingId, listId, courseListId} = this.state;
    const percentage = totalRepeated / (words.length != 0 ? words.length : 20);

    this.setState({
      exiting: true
    });

    if (percentage >= 0.85 && listId && listId > 0) {
      this.setState({
        finishedTraining: true
      });
      logUserList({trainingId, listId, type: "end"})
    }

    if (percentage >= 0.85 && courseListId && courseListId != 0) {
      this.setState({
        finishedTraining: true
      });
      logCourseList({trainingId, listId: courseListId, type: "end"});
    }
  };


  togglAlertModal = (status) => {
    this.setState({
      trainingAlertVisible: status
    })
  };

  _setTrainingSettings = async() => {
    const {userId} = this.props.user;
    const {trainingId} = this.state;


    const autoslide = localStorage.getItem(`autoslide_${trainingId}_${userId}`);
    const autoslide_type = localStorage.getItem(`autoslide_type_${trainingId}_${userId}`);
    const autoslide_delay = localStorage.getItem(`autoslide_delay_${trainingId}_${userId}`);
    const autosound_sentence = localStorage.getItem(`autosound_sentence_${trainingId}_${userId}`);

    if (autoslide != null) {
      this.setState({autoslide})
    } else {
      localStorage.setItem(`autoslide_${trainingId}_${userId}`, '0');
    }

    if (autoslide_delay != null) {
      this.setState({autoslide_delay})
    } else {
      localStorage.setItem(`autoslide_delay_${trainingId}_${userId}`, '2');
    }

    if (autoslide_type != null) {
      this.setState({autoslide_type})
    } else {
      localStorage.setItem(`autoslide_type_${trainingId}_${userId}`, 'sentence');
    }

    if (autosound_sentence != null) {
      this.setState({autosound_sentence})
    } else {
      localStorage.setItem(`autosound_sentence_${trainingId}_${userId}`, '1');
    }

    this.props.setCollectSentenceSettings({
      autoslide: autoslide != null ? autoslide : "0",
      autoslide_delay: autoslide_delay != null ? autoslide_delay : "2",
      autosound_sentence: autosound_sentence != null ? autosound_sentence : "1",
      autoslide_type: autoslide_type != null ? autoslide_type : "sentence"
    })
  };

  changeWord = (direction) => {
    const {totalWords} = this.props.data;
    const {currentIndex, listId, courseListId} = this.state;
    var index = currentIndex;
    const {userData} = this.props.user;

    let last = false;

    if (direction == "left") {
      this.checkIfNeedMore();

      if (currentIndex == totalWords - 1) {
        last = true;

        if ((listId != undefined && listId == 0) || (courseListId != undefined && courseListId == 0)) {
          this.props.history.goBack();
        } else {
          let active = this._checkTrainingsLimit();

          if (active) {
            if(userData && userData.active_trainings_set) {
              this.setState({
                finishedTraining: true
              });
              this._goToNextTraining()
            } else {
              this.setState({
                finishButtonsModalVisible: true
              })
            }
          } else {
            this._toggleSubscriptionAlert(true);
          }
        }
      } else {
        index = currentIndex + 1;

        this.setState({
          totalRepeated: this.state.totalRepeated + 1
        })
      }
    } else {
      if (currentIndex == 0) {
        index = totalWords - 1
      } else {
        index = currentIndex - 1
      }
    }

    this.setCorrectIndex();

    this.setState({
      mistakes: 0,
      currentIndex: index,
      selectedIndex: null,
      answered: false,
      timesTapped: 0,
      wasShuffled: false,
      shuffledLetters: [],
      currentExampleIndex: 0,
      currentCharIndex: -1,
      wordButtonsToHide: [],
      shuffledWordButtons: [],
      draggedWords: [],
      freeze: false
    });
  };

  _goToNextTraining = () => {
    const {logUserList, logCourseList} = this.props;

    const {listId, courseListId, type, trainingId} = this.state;
    const {userData} = this.props.user;
    const {locale} = this.props.settings;

    if (listId && listId > 0) {
      logUserList({trainingId, listId, type: "end"})
    } else if (courseListId && courseListId != 0) {
      logCourseList({trainingId, listId: courseListId, type: "end"});
    }
    
    let currentListId = null;

    if (listId != undefined) {
      currentListId = listId;
    }

    if (courseListId != undefined) {
      currentListId = courseListId;
    }

    let trainingIds = userData.trainings_set != null ? userData.trainings_set.split(',').map(Number) : [];

    const trainingIndex = trainingIds.length > 0 ? trainingIds.indexOf(trainingId) : null;

    if(trainingIndex === null || trainingIndex == (trainingIds.length - 1)) {
      this.setState({
        finishButtonsModalVisible: true
      });
      return;
    }

    if(currentListId != 0) {
      let activityId = '';
      let path = '';

      if (trainingIds[trainingIndex+1] == 1) {
        activityId = 39;
        path = 'preview'
      } else if (trainingIds[trainingIndex+1] == 6) {
        path = 'image';
        activityId = 40;
      } else if (trainingIds[trainingIndex+1] == 2) {
        path = 'translation';
        activityId = 41;
      } else if (trainingIds[trainingIndex+1] == 4) {
        path = 'word';
        activityId = 42;
      } else if (trainingIds[trainingIndex+1] == 3) {
        path = 'collect';
        activityId = 43;
      } else if (trainingIds[trainingIndex+1] == 5) {
        path = 'spelling';
        activityId = 44;
      } else if (trainingIds[trainingIndex+1] == 8) {
        activityId = 114;
        path = 'sentence';
      } else if (trainingIds[trainingIndex+1] == 9) {
        activityId = 131;
        path = 'memory'
      } else if (trainingIds[trainingIndex+1] == 10) {
        path = 'definition';
        activityId = 148;
      } else if (trainingIds[trainingIndex+1] == 7) {
        activityId = 95;
      } else if (trainingIds[trainingIndex+1] == 12) {
        path = 'missing';
        activityId = 315;
      } else if (trainingIds[trainingIndex+1] == 14) {
        path = 'listening';
        activityId = 345;
      } else if (trainingIds[trainingIndex+1] == 15) {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut4 = setTimeout(()=> {
        this.props.history.replace(`/${locale}/${path}/${currentListId}`, {
          type,
          from: 'inside',
          listId: currentListId
        });
      }, 100);

      this.props.logActivity({activityId, model: 'list', modelId: currentListId, comment: 'from home'});

    } else {
      this.setState({
        finishButtonsModalVisible: true
      })
    }
  };

  playSound = () => {
    const {words} = this.props.data;
    const {userData} = this.props.user;
    let audioSource = constants.BACKEND_URL

    if (words.length > 0 && words.length > this.state.currentIndex) {
      const word = words[this.state.currentIndex];

      if (word && word.audio) {
        let audio = word.audio;

        if (userData && userData.audio_language && word[userData.audio_language]) {
          audio = word[userData.audio_language];
          audioSource = constants.S3
        }

        if (this.track != null) {
          this.track.pause();
          this.track = null
        }

        this.track = new Audio(`${audioSource}${audio}`);
        if(this.track != null) {
          this.track.play();
        }
      }
    }
  };

  playSentence = (e) => {
    if (e) {
      e.preventDefault();
    }

    const {words} = this.props.data;
    const {currentIndex, currentExampleIndex} = this.state;
    const {userData} = this.props.user;
    let audioSource = constants.BACKEND_URL

    if (words.length > 0 && words.length > currentIndex) {
      const word = words[currentIndex];
      let example = word && word.examples && word.examples.length != 0 ? word.examples[currentExampleIndex] : false;

      if (example && example.audio) {
        let audio = example.audio;

        if (userData && userData.audio_language && example[userData.audio_language]) {
          audio = example[userData.audio_language];
          audioSource = constants.S3;
        }

        if (this.track != null) {
          this.track.pause();
          this.track = null
        }

        this.track = new Audio(`${audioSource}${audio}`);
        if(this.track != null) {
          this.track.play();
        }
      }
    }
  };

  toSettings = () => {
    this._toggleTrainingSettingsModal(true);
    this.props.logActivity({activityId: 189, comment: 'from collect sentence'});
  };

  checkIfNeedMore = () => {
    const {currentIndex, currentPage, downloads} = this.state;
    const {totalWords, words} = this.props.data;

    if (currentIndex + 3 == words.length && words.length < totalWords) {
      this.setState({
        currentPage: currentPage + 1,
        download: downloads + 1
      });

      this.getWords();
    }
  };

  getWords = () => {
    const {collectSentenceCachedWords} = this.props.cache;
    const {collectSentenceCourseCachedWords} = this.props.courseCache;

    const {currentPage, trainingId, courseListId, listId, glossaryId, phrasebookId} = this.state;
    const {logUserList, logCourseList} = this.props;
    const {isConnected} = this.props.settings;

    let params = {
      trainingId,
      page: this.state.currentPage,
      history: this.props.history
    };

    if (listId !== undefined && listId >= -2) {
      params.listId = listId;
      params.endpoint = 'getTrainingWords'
    } else if (courseListId !== undefined && courseListId !== false) {
      params.courseListId = courseListId;
      params.endpoint = 'getCourseTrainingWords'
    } else if (glossaryId !== undefined && glossaryId) {
      params.glossaryId = glossaryId;
      params.endpoint = 'getGlossaryWordsForTraining'
    } else if (phrasebookId !== undefined && phrasebookId) {
      params.phrasebookId = phrasebookId;
      params.endpoint = 'getPhrasebookForTrainingO';

      this.setState({
        phrasebook: true
      });
    }

    let cachedWords = false;

    if (listId) {
      cachedWords = checkForCachedWords(collectSentenceCachedWords, listId, currentPage);
    } else if (courseListId) {
      cachedWords = checkForCourseCachedWords(collectSentenceCourseCachedWords, courseListId, currentPage);
    }

    if (!cachedWords) {
      if (isConnected) {
        if (currentPage == 1) {
          this.props.fetchingData(true);
        }
        this.props.getWordsForTraining(params);
      }
    } else {
      this.props.setCachedCollectSentenceWords(cachedWords);
      this.props.logTrainingLimits();

      if (currentPage == 1) {
        if (listId && listId > 0) {
          logUserList({trainingId, listId, type: "start"})
        }

        if (courseListId && courseListId > 0) {
          logCourseList({trainingId, listId: courseListId, type: "start"});
        }
      }
    }
  };

  _checkTrainingsLimit = () => {
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;

    const {userData} = this.props.user;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    let active = false;

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    return active;
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    });

    if (!status) {
      this.props.history.goBack();
    }
  };

  _toSubscription = (productId) => {
    this.setState({
      subscriptionAlertVisible: false
    });
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId});
  };

  closeFinishButtonsModal = () => {
    this.setState({
      finishButtonsModalVisible: false
    });
  };

  continueTraining = () => {
    const {logUserList, logCourseList} = this.props;
    const {trainingId, listId, courseListId} = this.state;
    const {userData: {shuffle}} = this.props.user;

    this.moveLists();

    if (listId && (listId > 0 || listId == -1)) {
      if (shuffle == "1") {
        this.props.shuffleTrainingWords();
      }

      if (listId && listId > 0) {
        logUserList({trainingId, listId, type: "end"});

        if (this.state.exiting) return;

        this.timeOut2 = setTimeout(() => {
          if (this.state.exiting) return;

          logUserList({trainingId, listId, type: "start"})
        }, 1000);
      }
    }

    if (courseListId && (courseListId > 0 || courseListId == -1)) {
      if (shuffle == "1") {
        this.props.shuffleTrainingWords();
      }

      if (courseListId && courseListId > 0) {
        logCourseList({trainingId, listId: courseListId, type: "end"});

        if (this.state.exiting) return;

        this.timeOut3 = setTimeout(()=> {
          if (this.state.exiting) return;

          logCourseList({trainingId, listId: courseListId, type: "start"});
        }, 1000);
      }
    }

    this.setState({
      finishButtonsModalVisible: false,
      currentIndex: 0,
      totalRepeated: 1,
    });

    this.props.logTrainingLimits();

    this.props.logActivity({activityId: 113});
  };

  anotherTraining = () => {
    const {logUserList, logCourseList} = this.props;
    const {trainingId, listId, courseListId, from} = this.state;

    if (listId !== undefined && from == 'listWords') {
      this.props.toggleListTrainingButtonsModal(true)
    }

    if (courseListId !== undefined && from == 'listWords') {
      this.props.toggleListTrainingButtonsModalCourse(true)
    }

    if (listId && listId > 0) {
      logUserList({trainingId, listId, type: "end"})
    } else if (courseListId && courseListId != 0) {
      logCourseList({trainingId, listId: courseListId, type: "end"});
    }

    this.setState({
      finishButtonsModalVisible: false,
      finishedTraining: true
    });

    this.props.history.goBack();

    this.props.logActivity({activityId: 190, comment: 'Collect Sentence Tap'});
  };

  setCorrectIndex = () => {
    let n = Math.floor(Math.random() * 4);

    this.setState({
      correctIndex: n
    });
  };

  _log = (mistake) => {
    const {words} = this.props.data;
    const {currentIndex} = this.state;
    let word = words.length > 0 ? words[currentIndex] : false;

    const {trainingId, listId, glossaryId, courseListId} = this.state;

    if (word) {
      let params = {
        trainingId: trainingId,
        mistake,
        id: word.id
      };

      if (listId !== undefined && listId >= -1) {
        params.listId = listId;
        this.props.logListWord(params);
      } else if (glossaryId !== undefined && glossaryId) {
        params.glossaryId = glossaryId;
        this.props.logGlossaryWord(params);
      } else if (courseListId !== undefined && courseListId >= -1) {
        params.courseListId = courseListId;
        this.props.logCourseListWord(params);
      }
    }
  };

  _inDraggedWords = (word, index) => {
    const {draggedWords} = this.state;
    let result = false;

    draggedWords.forEach(item => {
      if (item.word == word && item.index === index) {
        result = true;
      }
    });

    return result;
  };

  renderSentenceWords = (word) => {
    if (!word || !word.sentences || word.sentences.length == 0) return;
    const {currentExampleIndex, currentCharIndex, draggedWords, tablet} = this.state;
    const {deviceHeight, deviceWidth} = this.props.settings;

    let example = word.sentences[currentExampleIndex];
    let sentence = word.examples[currentExampleIndex];
    let width = word.maxWidths[currentExampleIndex];

    if (width && width > 80) {
      // width = 80;
    }

    if (example && sentence && sentence.example && example.length && example.length != 0 && example.length == draggedWords.length) return null;

    return example.map((item, i) => {
      const inDragged = this._inDraggedWords(item, i);

      return (
        <div
          className="wordButton"
          key={`${item}${i}${sentence.example}`}
          style={{
          borderColor: currentCharIndex >= i ? colors.tealish : colors.greyishBrown,
          width,
          marginBottom: !tablet ? (deviceHeight < 650 ? 5 : 5) : 5,
          height: 45
          }}>
          <span
            className="wordButtonText"
            style={{
            fontSize: 16,
            color: currentCharIndex >= i ? colors.tealish : colors.white
             }}>
            {item}
          </span>
        </div>
      )
    });
  };

  renderShuffledSentenceWords = (word) => {
    const {currentExampleIndex, wordButtonsToHide, draggedWords, dragIndexStyle, tablet} = this.state;
    const {deviceHeight, deviceWidth} = this.props.settings;

    if (!word || !word.shuffledSentences || word.shuffledSentences.length === 0 || !word.shuffledSentences[currentExampleIndex]) return;

    let example = word.shuffledSentences[currentExampleIndex];

    if (!example) return;

    let width = word.maxWidths[currentExampleIndex];

    if (width && width > 80) {
      // width = 80;
    }

    let left = 5;
    let top = 5;
    let rowWidth = 0;

    let row = 1;
    let totalItems = Math.floor(deviceWidth / (width + 4));

    return example.map((item, i) => {
      const inDragged = this._inDraggedWords(item.word, item.origIndex);

      rowWidth += width + 4;

      if ((left + width + width + 4) >= deviceWidth) {
        left = 5;
        top += !tablet ? (deviceHeight < 650 ? 35 : 45) : 65;

        let reminder = example.length - (totalItems * row);

        if (reminder > totalItems) {
          left = (deviceWidth - (totalItems * (width + 4))) / 2;
        } else {
          left = (deviceWidth - (reminder * (width + 4))) / 2;
        }
        row++;
      } else {
        if (i != 0) {
          left += (width + 4)
        } else {
          if (totalItems > example.length) {
            left = (deviceWidth - (example.length * (width + 4))) / 2;

          } else {
            left = (deviceWidth - (totalItems * (width + 4))) / 2;
          }
        }
      }

      return (
        <a href="#" key={i} onClick={(e)=>this.shuffledWordButtonPressed(item,i, e)}>
          <div
            className="shuffledWordButton"
            key={i}
            index={i}
            style={{
              height: 45,
              borderColor: wordButtonsToHide.indexOf(i) === -1 ? colors.greyishBrown : colors.white,
              marginBottom: !tablet ? (deviceHeight < 650 ? 5 : 5) : 5, width,
              }}
          >
            <span
              className="shuffledWordButtonText"
              index={i}
              style={{fontSize: 16,
              color: wordButtonsToHide.indexOf(i) === -1 ? colors.greyishBrown : colors.white}}>
              {item.word}
            </span>
          </div>
        </a>
      )
    });
  };

  shuffledWordButtonPressed = (tappedWord, i, e) => {
    e.preventDefault();
    const {words, autoslide_collect_sentence, autoslide_type_collect_sentence, autoslide_delay_collect_sentence, autosound_sentence_collect_sentence} = this.props.data;
    const {
      currentIndex, currentCharIndex, wordButtonsToHide, scores, score, autoslide, autoslide_delay, mistakes,
      currentExampleIndex
    } = this.state;

    let word = words.length > 0 ? words[currentIndex] : false;
    let example = word && word.sentences && word.sentences.length && word.sentences[currentExampleIndex] ? word.sentences[currentExampleIndex] : false;

    let correctWord = example && example.length && example[currentCharIndex + 1];

    if (correctWord && correctWord.toLowerCase() === tappedWord.word.toLowerCase()) {
      this.setState({
        currentCharIndex: currentCharIndex + 1,
        wordButtonsToHide: [...wordButtonsToHide, i]
      });

      if (currentCharIndex + 2 === example.length) {
        if (autosound_sentence_collect_sentence == "1") {
          this.playSentence();
        }

        if (autoslide_collect_sentence == '1') {
          this.setState({
            freeze: true
          });

          if (autoslide_type_collect_sentence == "word") {
            if (this.state.exiting) return;

            this.timeOut4 = setTimeout(() => {
              if (this.state.exiting) return;

              this.changeWord("left")
            }, parseFloat(autoslide_delay_collect_sentence) * 1000);
          } else {
            if (this.state.exiting) return;

            this.timeOut5 = setTimeout(() => {
              if (this.state.exiting) return;

              if ((currentExampleIndex + 1) < word.shuffledSentences.length) {
                this.setState({
                  currentExampleIndex: currentExampleIndex + 1,
                  mistakes: 0,
                  selectedIndex: null,
                  answered: false,
                  timesTapped: 0,
                  wasShuffled: false,
                  shuffledLetters: [],
                  wordButtonsToHide: [],
                  currentCharIndex: -1,
                  shuffledWordButtons: [],
                  draggedWords: [],
                  freeze: false
                })
              } else {
                this.changeWord("left")
              }
            }, parseFloat(autoslide_delay_collect_sentence) * 1000);
          }
        }

        this.setState({
          scores: this.state.mistakes <= 2 ? this.state.scores + this.state.score : this.state.scores
        });

        this._log(this.state.mistakes <= 2 ? 0 : 1);
      }
    } else {
      this.setState({
        mistakes: this.state.mistakes + 1,
        totalMistakes: this.state.totalMistakes + 1,
      });
    }
  };

  renderSentencesPages = (word) => {
    const {freeze} = this.state;
    const {deviceHeight, deviceWidth} = this.props.settings;

    if (!word) return;
    if (!word.sentences) return;

    const {
      currentExampleIndex, tablet
    } = this.state;

    return [...Array(word.sentences.length).keys()].map((item) => {
      return (
        <a href="#" key={item} onClick={(e)=> !freeze ? this.changeSentenceIndex(item, e) : null}>
          <div
            className="sentenceButton"
            key={item}
            style={{
            opacity: !freeze ? 1 : 0.5,
            borderColor: currentExampleIndex !== item ? colors.greyishBrown : colors.tealish,
             backgroundColor:currentExampleIndex !== item ? colors.white : colors.tealish,
              width: !tablet ? (deviceWidth > 360 ? 40 : 35) : 50,
              height: !tablet ? (deviceWidth > 360 ? 40 : 35) : 50,
              borderRadius: !tablet ? (deviceWidth > 360 ? 20 : 35/2) : 50 / 2,
              }}>
            <span
              className="sentenceButtonText"
              style={{color: currentExampleIndex !== item ? colors.greyishBrown : colors.white,
               fontSize: !tablet ? (deviceWidth < 360 ? 14 : 16) : 20}}>
              {item + 1}
            </span>
          </div>
        </a>
      )
    });
  };

  changeSentenceIndex = (item, e) => {
    e.preventDefault();
    this.setState({
      draggedWords: [],
      wordButtonsToHide: [],
      mistakes: 0,
      currentCharIndex: -1,
      currentExampleIndex: item
    });
  };

  toggleInfoModal = (status) => {
    this.setState({
      trainingInfoVisible: status
    })
  };

  _toggleAudioModal = (status) => {
    this.setState({
      audioModalVisible: status
    });
  };

  _openTrainingInfo = () => {
    this.toggleWordSettingsModal(false);
    this.toggleInfoModal(true);
  };

  _reportTrainingBug = () => {
    this.toggleWordSettingsModal(false);

    const {words, totalWords} = this.props.data;
    const {currentIndex, currentExampleIndex, listId, courseListId} = this.state;

    let word = words.length > 0 ? words[currentIndex] : false;
    let extra = {};

    extra.word = word && word.word ? word.word : "";
    extra.translation = word && word.main_translation && word.main_translation.translation ? word.main_translation.translation : "";
    extra.example = word && word.examples && word.examples.length != 0 ? word.examples[currentExampleIndex] : false;

    this.setState({
      extra
    });

    this._toggleBugModal(true);
    this.props.logActivity({activityId: 234, comment: "Collect Sentence Tap"});

  };

  _openTrainingSettings = () => {
    this.toggleInfoModal(false);
    this.toggleWordSettingsModal(false);

    if(!this.state.tour) {
      this.toSettings();
    } else {
      this._toggleRegisterModal(true)
    }
  };

  _toWordDetails = () => {
    this.toggleWordSettingsModal(false);
    const {words} = this.props.data;
    const {currentIndex} = this.state;


    let word = words.length > 0 ? words[currentIndex] : false;

    if (!word) return;
    this.props.get_word_details(word.id);

    this._toggleWordInfo(true);

    this.props.logActivity({activityId: 45, model: 'word', modelId: word.id, comment: "from Collect Sentence Tap"});

    // this.props.history.push(`/dictionary/${word.word}`, {word});
  };

  _changeVoice = () => {
    this.toggleWordSettingsModal(false);

    this.props.logActivity({activityId: 332});

    this._toggleAudioModal(true);
  };

  toggleWordSettingsModal = (status) => {
    this.toggleInfoModal(false);

    this.setState({
      wordSettingsModalVisible: status
    })
  };

  _toResults = () => {
    const {withMistakes, trainingResults, scores} = this.state;
    if (!trainingResults) {
      this.props.navigation.navigate('TrainingResults', {withMistakes, scores});

      this.setState({trainingResults: true});
    } else {
      this.props.history.goBack();
    }
  };

  _toResultsFromModal = () => {
    const {withMistakes, scores} = this.state;
    this.setState({
      finishButtonsModalVisible: false
    });
    this.props.navigation.navigate('TrainingResults', {withMistakes, scores});
  };

  setExampleTranslationView(event) {
    var {x, y, width, height} = event.nativeEvent.layout;
    this.setState({exampleTranslationHeight: height})
  }

  _getGoogleTranslation = (e) => {
    e.preventDefault();
    const {words, totalWords} = this.props.data;
    const {currentIndex, currentExampleIndex} = this.state;

    let word = words.length > 0 ? words[currentIndex] : false;

    let example = word && word.examples && word.examples.length != 0 ? word.examples[currentExampleIndex] : false;

    if (word && example) {
      this.props.fetchingData(true);
      this.props.getGoogleExampleTranslation(word.word_id, example.example_id);
      this.props.logActivity({activityId: 287, model: 'example', modelId: example.example_id, comment: word.word});
    }
  };

  _goBack = () => {
    this.setState({
      finishButtonsModalVisible: false
    });

    this._logOnExit();

    this.props.history.goBack();

    this.props.logActivity({activityId: 306, comment: 'Collect Sentence Tap'});
  };

  _toggleShuffle = () => {
    const {userData} = this.props.user;

    this.toggleWordSettingsModal(false);

    if(!this.state.tour) {
      this.props.fetchingData(true);

      this.props.toggleShuffleWords();
      this.props.logActivity({activityId: 333, comment: userData && userData.shuffle == 1 ? "on" : "off"});

    } else {
      this._toggleRegisterModal(true)
    }
  };

  _toggleAddTranslationModal = (status) => {
    this.setState({
      exampleTranslationModalVisible: status
    })
  };

  _addTranslation = (translation) => {
    const {words, totalWords} = this.props.data;
    const {currentIndex, currentExampleIndex} = this.state;

    let word = words.length > 0 ? words[currentIndex] : false;
    let extra = {};

    let example = word && word.examples && word.examples.length != 0 ? word.examples[currentExampleIndex] : false;

    this._toggleAddTranslationModal(false);

    this.props.fetchingData(true);

    if (word && example && translation) {
      this.props.addUserExampleTranslation(word.id, example.id, translation);
      // this.props.logActivity({activityId: 287, model: 'example', modelId: example.example_id, comment: word.word});
    }
  };

  _showWord = (word, e) => {
    e.preventDefault();

    this.props.findWordInDictionary(word);

    this.setState({
      lookWord: word
    });

    this._toggleShowWordModal(true)
  };

  _toggleShowWordModal = (status) => {
    this.setState({
      addWordModalVisible: status
    });

    if (!status) {
      this.props.resetFoundDictionaryWord();
    }
  };

  toggleListsToFillModal = (status) => {
    // this.props.logActivity({activityId: 18});

    this.setState({
      addWordModalVisible: false,
      listsModalIsVisible: status
    })
  };

  fillLists = (id) => {
    const {subscriptionActive, listsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {listsNumber} = this.props.lists;
    const {userData} = this.props.user;
    const {foundWord, fetchedWord} = this.props.dictionary;

    if (!foundWord)return;

    let active = false;

    if (lifetimeStatus || subscriptionActive || webSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      if (id == 0) {
        active = listsNumber < listsLimit;
      } else {
        active = true;
      }
    }

    this.toggleListsToFillModal(false);

    if (active) {
      this.props.fetchingData(true);

      this.props.fillListFromGlossaryWithSelectedWords({
        listId: id,
        selectedWords: [foundWord.id]
      });

      this.props.logActivity({activityId: id != 0 ? 19 : 20, model: 'list', modelId: id});
    } else {
      this.setState({
        subscriptionMessage: 'subscription_lists'
      });
      this._toggleSubscriptionAlert(true);
    }

    this.props.resetFoundDictionaryWord();
  };

  _toggleBugModal = (status) => {
    this.setState({
      bugModalVisible: status
    });
  };

  _toggleTrainingSettingsModal = (status) => {
    this.setState({
      trainingSettingsModalVisible: status
    });
  };

  _toggleWordInfo = (status) => {
    this.setState({
      wordInfoVisible: status
    })
  };


  _toggleRegisterModal = (status, e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      registerModalVisible: status
    })
  };

  _toRegister = () => {
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/register`)
  };

  render() {
    const {words, totalWords, fetchedWords} = this.props.data;
    const {foundWord, fetchedWord} = this.props.dictionary;
    const {
      currentIndex, trainingId, phrasebook,
      totalRepeated, scores, withMistakes,
      trainingInfoVisible,
      finishButtonsModalVisible,
      exampleTranslationModalVisible,
      trainingAlertVisible,
      wordSettingsModalVisible, tablet,
      currentExampleIndex,
      freeze,
      mistakes,
      draggedWords,
      subscriptionAlertVisible,
      subscriptionMessage,
      wordButtonsToHide,
      allowedMistakes,
      translateOptionsModalVisible,
      notch,
      lookWord,
      addWordModalVisible,
      listsModalIsVisible,
      listId,
      glossaryId,
      courseListId,
      from,
      audioModalVisible,
      bugModalVisible,
      trainingSettingsModalVisible,
      extra,
      wordInfoVisible,
      registerModalVisible
    } = this.state;

    const {fetchingData} = this.props.activity;
    const {userData} = this.props.user;
    const {fontSizeRation, version, isConnected, deviceHeight, deviceWidth} = this.props.settings;
    const {currentList, listsToFill} = this.props.lists;
    const {course, translate} = this.props;
    let listSize = userData && userData.list_size ? userData.list_size : 10;

    let subtitle = "";

    if (currentList && currentList.list_name && totalWords) {
      subtitle = `${currentList.list_name}: ${totalWords}`
    } else if (course && course.currentList && course.currentList.list_name) {
      subtitle = `${course.currentList.list_name}: ${totalWords}`
    }

    if (listId == -1 || courseListId == -1) {
      subtitle = translate('vocabulary')
    }

    let word = words.length > 0 ? words[currentIndex] : false;

    let wordButtons = this.renderSentenceWords(word);

    let sentencesPages = this.renderSentencesPages(word);

    let example = word && word.examples && word.examples.length != 0 ? word.examples[currentExampleIndex] : false;
    let shuffledExample = word && word.shuffledSentences && word.shuffledSentences.length != 0 ? word.shuffledSentences[currentExampleIndex] : false;

    let exampleTranslation = example && example.translation && example.translation.translation ? example.translation.translation : "";
    let shuffledWordButtons = this.renderShuffledSentenceWords(word);

    const progress = totalWords !== 0 ? ((currentIndex + 1) / totalWords) : 0;

    let showExample = shuffledExample && example && example.example && shuffledExample.length && shuffledExample.length != 0 && shuffledExample.length == wordButtonsToHide.length;

    let sentencesHeight = 150;

    let sentenceShuffled = word && word.sentences && word.sentences.length != 0 ? word.sentences[currentExampleIndex] : false;

    if (deviceHeight < 600) {
      sentencesHeight = 130;
      if (deviceHeight < 550) {
        // sentencesHeight = 120;
      }
    } else {
      sentencesHeight *= (deviceHeight / 600)
    }

    return (
      <div className="sentencetap">
        <Container className="pageWrapper">

          <TrainingSubHeader
            trainingId={trainingId}
            freeze={freeze}
            key={freeze}
            version={version}
            ratio={fontSizeRation}
            scores={scores}
            progress={progress}
            changeWord={this.changeWord}

            deviceHeight={deviceHeight}
            deviceWidth={deviceWidth}
            translate={translate}
            tablet={tablet}
            exit={this._goBack}
            history={this.props.history}
            trainingName="collect_sentence_training"
            subtitle={subtitle}

            title={'collect_sentence_training'}
            text="collect_sentence_training_tap_description"
            url="https://youtu.be/ljMFLDppUsI"
            toggleSettings={this.toggleWordSettingsModal}
          />

          <Card style={{minHeight: deviceHeight - 140}}>
            <div style={{opacity: word && word.id ? 1 : 0}} className="contentWrapper">
              {
                fetchedWords && (
                  <div
                    className="w100 text-center ph2"
                    style={{paddingBottom: !tablet ? 5 : 15, paddingTop: !tablet ? 5 : 15}}
                  >
                    {
                      exampleTranslation && (
                        <span
                          className="exampleTranslation"
                          style={{marginBottom: 15, fontSize: fontSizeRation * 16}}
                        >
                        {exampleTranslation}
                      </span>)
                      ||
                      (
                        (isConnected && (
                          <div className="w100 flexV flexCenter mb-3">
                            <Dropdown alignRight>
                              <Dropdown.Toggle
                                as="a"
                                className="dropdown-link"
                                style={{width: '100%', paddingTop: !tablet ? 5 : 10, paddingBottom: !tablet ? 5 : 10}}
                              >
                                <FontAwesomeIcon
                                  style={{fontSize: 20, color: colors.waterBlue, marginRight: 10}}
                                  icon={faPlusCircle}
                                />
                                <span
                                  className="getTranslation"
                                  style={{fontSize: (!tablet ? (deviceHeight > 650 ? 16 : 18) : 20)}}>
                                  {translate('translate_example')}
                                </span>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item as="button" onClick={this._getGoogleTranslation}>
                                  {translate('get_google_translation_e')}
                                </Dropdown.Item>
                                <Dropdown.Item as="button" onClick={()=>this._toggleAddTranslationModal(true)}>
                                  {translate('suggest_translation')}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        ))
                      )
                    }

                    {
                      showExample && (
                        <div className="flexCenter flexV">
                          {
                            sentenceShuffled && (
                              <div className="flexH flexCenter" style={{flexWrap:'wrap',marginTop: !tablet ? 5 : 10}}>
                                {sentenceShuffled.map((word, i) => {
                                  return (
                                    <a href="#"
                                       key={i}
                                       onClick={(e)=> this._showWord(word, e)}
                                    >
                                      <span
                                        className="word"
                                        style={{fontSize: fontSizeRation * 18}}
                                      >
                                        {word}
                                    </span>
                                    </a>
                                  )
                                })}
                              </div>
                            )
                          }

                          {
                            isConnected && example && example.audio && (
                              <a href="#"
                                 className="flexH flexCenter pv3"
                                 style={{width: '50%'}}
                                 onClick={this.playSentence}
                              >
                                <FontAwesomeIcon style={{fontSize: !tablet ? 30 : 40}} icon={faVolumeUp}
                                />
                              </a>
                            )
                          }
                        </div>
                      ) || null
                    }
                  </div>
                )
              }

              {
                !showExample && (
                  <div className="wordButtonsWrapper">
                    {wordButtons}
                  </div>
                )
              }
              <div style={{width: '100%', height: sentencesHeight, marginBottom: 100}}>
                <div style={{marginBottom: 10, paddingBottom: 10}}>
                  <div className="shuffledWordButtonsWrapper" style={{marginTop: deviceHeight > 600 ? 50 : 20}}>
                    {shuffledWordButtons}
                  </div>
                </div>
              </div>

              {
                !phrasebook && (
                  <div
                    className="sentencesSwitcher"
                    style={{bottom: 10, height: !tablet ? 50 : 70, justifyContent: 'center'}}
                  >
                    {sentencesPages}
                  </div>
                )
              }
            </div>
          </Card>

          {finishButtonsModalVisible && (
            <FinishTrainingButtons
              ratio={fontSizeRation}
              isVisible={finishButtonsModalVisible}
              onClose={this.closeFinishButtonsModal}
              continueTraining={this.continueTraining}
              anotherTraining={this.anotherTraining}
              withMistakes={withMistakes}
              toResults={this._toResultsFromModal}
              glossaryId={glossaryId}
              returnBack={this._goBack}
              from={from}
              translate={translate}
              tablet={tablet}
            />
          )}


          {trainingAlertVisible && (
            <TrainingAlert
              ratio={fontSizeRation}
              isVisible={trainingAlertVisible}
              onClose={this.togglAlertModal}
              infoDescription={'alert_drag_word_on_position'}
              title="collect_sentence_training"
            />
          )}

          {wordSettingsModalVisible && (
            <WordSettings
              changeVoice={this._changeVoice}
              ratio={fontSizeRation}
              phrasebook={phrasebook}
              isVisible={wordSettingsModalVisible}
              onClose={this.toggleWordSettingsModal}
              openTrainingInfo={this._openTrainingInfo}
              openTrainingSettings={this._openTrainingSettings}
              toWordDetails={this._toWordDetails}
              reportTrainingBug={this._reportTrainingBug}
              title={translate('collect_sentence_training')}
              shuffle={userData.shuffle}
              shouldShuffle={true}
              toggleShuffle={this._toggleShuffle}
            />
          )}

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={subscriptionMessage}
                translate={translate}
                deviceWidth={deviceWidth}
                deviceHeight={deviceHeight}
              />
            )
          }

          {
            exampleTranslationModalVisible && (
              <AddExampleTranslationModal
                onClose={this._toggleAddTranslationModal}
                isVisible={exampleTranslationModalVisible}
                onSubmit={this._addTranslation}
                example={example}
                showExample={showExample}
                ratio={fontSizeRation}
                translate={translate}
              />
            )
          }

          {
            addWordModalVisible && (
              <AddWordModal
                onClose={this._toggleShowWordModal}
                isVisible={addWordModalVisible}
                tablet={tablet}
                word={lookWord}
                userData={userData}
                foundWord={foundWord}
                fetchedWord={fetchedWord}
                ratio={fontSizeRation}
                toggleListsToFill={this.toggleListsToFillModal}
                translate={translate}
              />
            )
          }

          {listsModalIsVisible && (
            <ListsToFill
              isVisible={listsModalIsVisible}
              lists={listsToFill}
              fillLists={this.fillLists}
              listSize={listSize}
              ratio={fontSizeRation}
              selectedWords={1}
              onClose={this.toggleListsToFillModal}
              translate={translate}

            />
          )}
          {
            audioModalVisible && (
              <ChangeAudioLanguage
                onClose={this._toggleAudioModal}
                isVisible={audioModalVisible}
                toRegister={this._toggleRegisterModal}

              />
            )
          }
          {
            bugModalVisible && (
              <ReportBug
                onClose={this._toggleBugModal}
                isVisible={bugModalVisible}
                type="training"
                training="Collect Sentence (tap)"
                extra={extra}
              />
            )
          }
          {
            trainingSettingsModalVisible && (
              <TrainingSettings
                onClose={this._toggleTrainingSettingsModal}
                isVisible={trainingSettingsModalVisible}
                trainingName="collect_sentence_training"
                trainingId={trainingId}
              />
            )
          }
          {wordInfoVisible && (<WordInfo onClose={this._toggleWordInfo} isVisible={wordInfoVisible}/>)}
          {registerModalVisible && (
            <RegisterAlert
              onClose={this._toggleRegisterModal}
              isVisible={registerModalVisible}
              translate={translate}
              toRegister={this._toRegister}
            />
          )}
        </Container>
        <Loader fetchingData={fetchingData}/>

        <Helmet>
          <title>{`${translate('app_title_collect_sentence_training')}`}</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="description" content={translate('app_title_collect_sentence_training_description')} />
          <meta property="og:url" content={window.location.href} />
          <meta name="og:description" content={translate('app_title_collect_sentence_training_description')} />
          <meta property="og:title" content={`${translate('app_title_collect_sentence_training')}`} />
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  data: state.trainings,
  user: state.user,
  firstPlayed: state.word.firstPlayed,
  activity: state.activity,
  dictionary: state.dictionary,
  settings: state.settings,
  cache: state.cache,
  lists: state.lists,
  courseCache: state.courseCache,
  purchases: state.purchases,
  course: state.course,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    getWordsForTraining: (data) => dispatch(getWordsForTraining(data)),
    resetWords: () => dispatch(resetWords()),
    logListWord: (data) => dispatch(logListWord(data)),
    logPhrase: (data) => dispatch(logPhrase(data)),
    logGlossaryWord: (data) => dispatch(logGlossaryWord(data)),
    logUserList: (data) => dispatch(logUserList(data)),
    logActivity: (data) => dispatch(logActivity(data)),
    getListsToRepeat: (limit, userId) => dispatch(getListsToRepeat(limit, userId)),
    setFirstPlayed: (status) => dispatch(setFirstPlayed(status)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    logCourseListWord: (data) => dispatch(logCourseListWord(data)),
    logCourseList: (data) => dispatch(logCourseList(data)),
    setCachedCollectSentenceWords: (data) => dispatch(setCachedCollectSentenceWords(data)),
    setCollectSentenceSettings: (data) => dispatch(setCollectSentenceSettings(data)),
    getGoogleExampleTranslation: (wordId, exampleId) => dispatch(getGoogleExampleTranslation(wordId, exampleId)),
    toggleListTrainingButtonsModal: (status) => dispatch(toggleListTrainingButtonsModal(status)),

    moveNewListToRepeat: (list) => dispatch(moveNewListToRepeat(list)),
    removeListFromRepeatSection: (listId) => dispatch(removeListFromRepeatSection(listId)),
    updateListTrainings: (listId) => dispatch(updateListTrainings(listId)),
    updateTrainingsListFromRepeatSection: (listId) => dispatch(updateTrainingsListFromRepeatSection(listId)),
    getOnlyMistakes: () => dispatch(getOnlyMistakes()),
    updateCachedListTrainings: (listId) => dispatch(updateCachedListTrainings(listId)),
    updateCachedListTrainingsAndIntervals: (list) => dispatch(updateCachedListTrainingsAndIntervals(list)),

    toggleListTrainingButtonsModalCourse: (status) => dispatch(toggleListTrainingButtonsModalCourse(status)),
    getUpdatedCurrentListScores: (listId) => dispatch(getUpdatedCurrentListScores(listId)),
    getUpdatedCourseLists: () => dispatch(getUpdatedCourseLists()),
    getUpdatedCourseData: () => dispatch(getUpdatedCourseData()),
    resetCurrentTrainingId: () => dispatch(resetCurrentTrainingId()),
    setCurrentTrainingId: (id) => dispatch(setCurrentTrainingId(id)),
    logTrainingLimits: () => dispatch(logTrainingLimits()),
    shuffleListTrainingWords: (trainingId, listId) => dispatch(shuffleListTrainingWords(trainingId, listId)),
    shuffleCourseListTrainingWords: (trainingId, listId) => dispatch(shuffleCourseListTrainingWords(trainingId, listId)),
    shuffleTrainingWords: () => dispatch(shuffleTrainingWords()),
    toggleShuffleWords: () => dispatch(toggleShuffleWords()),
    resetFoundDictionaryWord: () => dispatch(resetFoundDictionaryWord()),
    getUserListsToFill: () => dispatch(getUserListsToFill()),
    findWordInDictionary: (word) => dispatch(findWordInDictionary(word)),
    addUserExampleTranslation: (wordId, exampleId, translation) => dispatch(addUserExampleTranslation(wordId, exampleId, translation)),
    getListsNumber: () => dispatch(getListsNumber()),
    fillListFromGlossaryWithSelectedWords: (data) => dispatch(fillListFromGlossaryWithSelectedWords(data)),
    get_word_details: (wordId) => dispatch(get_word_details(wordId)),

  };
}

export default connect(mapStateToProps, bindAction)(CollectSentence);
