import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faSpinner
} from '@fortawesome/free-solid-svg-icons';

import {Translate, getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import Loader from "../../../../components/Custom/Loader/index";

import {
  getActiveMultiWordMakerGames,
  resetMultiWordMakerGames,
  cancelMultiWordMakerGame,
  exitMultiWordMakerGame,
  startMultiWordMakerGame,
  getCurrentActiveWordMakerGame,
  resetCurrentGame
} from '../../../../actions/multiWordMakerActions';
import {logActivity} from '../../../../actions/logActions';
import {fetchingData} from '../../../../actions/activity';

class MultiWordMakerWaiting extends Component {

  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      tablet: false,
      intervals: 0,
      gameId: this.props.match.params.id,
    };

    this.checkUpdates = null;
  }

  componentDidMount() {
    this.props.fetchingData(true);
    this._getCurrentActiveStatus();

    this.checkUpdates = setInterval(()=> {
      this._getCurrentActiveStatus();
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    const {currentActiveGame} = this.props.multiWordMaker;
    const {locale} = this.props.settings;

    if (this.state.intervals == 360) {
      clearInterval(this.checkUpdates)
    }

    if (this.props.multiWordMaker.currentActiveGame && this.props.multiWordMaker.currentActiveGame.started == 1 && this.props.multiWordMaker.currentActiveGame.started != prevProps.multiWordMaker.currentActiveGame.started) {
      clearInterval(this.checkUpdates);
      this.props.history.push(`/${locale}/contest-wordmaker/${currentActiveGame.id}`, {game: currentActiveGame});
      this.props.resetCurrentGame();
    }

    if (this.props.multiWordMaker.currentActiveGame && this.props.multiWordMaker.currentActiveGame.active == 0 && this.props.multiWordMaker.currentActiveGame.started == 0) {
      this.props.resetCurrentGame();

      clearInterval(this.checkUpdates);
      this.props.history.goBack();
    }

    if (this.props.multiWordMaker.shouldStartGame && prevProps.multiWordMaker.shouldStartGame !== this.props.multiWordMaker.shouldStartGame) {
      clearInterval(this.checkUpdates);
      this.props.history.push(`/${locale}/contest-wordmaker/${this.props.match.params.id}`, {game: currentActiveGame});
      this.props.resetCurrentGame();
    }
  }

  componentWillUnmount() {
    clearInterval(this.checkUpdates);
  }

  _getCurrentActiveStatus = () => {
    this.props.getCurrentActiveWordMakerGame(this.props.match.params.id);

    this.setState({
      intervals: this.state.intervals + 1
    })
  };

  _cancelGame = (gameId)=> {
    this.props.logActivity({activityId: 365});
    clearInterval(this.checkUpdates);
    this.props.resetCurrentGame();

    this.props.fetchingData(true);
    this.props.cancelMultiWordMakerGame(gameId, this.props.history);
  };

  _startGame = (gameId)=> {
    const {
      currentActiveGame
    } = this.props.multiWordMaker;

    clearInterval(this.checkUpdates);

    this.props.logActivity({activityId: 366});

    this.props.fetchingData(true);
    this.props.startMultiWordMakerGame(currentActiveGame, gameId, this.props.history);
    this.props.resetCurrentGame();
  };

  _exitGame = (gameId)=> {
    this.props.resetCurrentGame();
    clearInterval(this.checkUpdates);

    this.props.logActivity({activityId: 367});
    this.props.fetchingData(true);
    this.props.exitMultiWordMakerGame(gameId, this.props.history);
  };

  render() {
    const {
      userActiveGame,
      joinedGame,
      currentActiveGame,
      shouldStartGame
    } = this.props.multiWordMaker;
    const {translate} = this.props;
    const {fetchingData} = this.props.activity;

    return (
      <div className="phrasebooks">

        <Container className="pageWrapper">

          <Card style={{maxWidth: 800, minHeight: 300, marginTop: 30}} className="ml-auto mr-auto p-3">

            {
              !shouldStartGame && (
                <div className="d-flex justify-content-center flex-column align-items-center w-100"
                     style={{minHeight: 300}}>
                  {
                    currentActiveGame && joinedGame && userActiveGame && (
                      <React.Fragment>
                        <p style={{fontSize: 18}}>{translate('word')}: <span
                          className="font-weight-bold">{currentActiveGame.word.word}</span></p>

                        <p>{translate('waiting_for_the_game_start')}</p>
                        <p>{translate('game_users_')} {currentActiveGame.users_count}</p>

                        <FontAwesomeIcon
                          className="fa-spin"
                          style={{fontSize: 60}}
                          icon={faSpinner}
                        />
                      </React.Fragment>
                    )
                  }

                  {
                    currentActiveGame && !joinedGame && userActiveGame && (
                      <React.Fragment>
                        <p style={{fontSize: 18}}>{translate('word')}: <span
                          className="font-weight-bold">{currentActiveGame.word.word}</span></p>
                        <p>{translate('wait_for_users_to_connect')}</p>
                        {
                          currentActiveGame.by_invitation == 1 && (
                            <p>{translate('invited')} {currentActiveGame.invited_count}</p>
                          )
                        }
                        <div style={{fontStyle: 'italic'}}>

                          {
                            currentActiveGame.users_count == 1 && (
                              <p>{translate('connected_to_game')} {translate('only_you')}</p>
                            )
                          }
                          {
                            currentActiveGame.users_count > 1 && (
                              <p>{translate('game_users_')} {currentActiveGame.users_count}</p>
                            )
                          }
                        </div>

                        {
                          currentActiveGame.users_count > 1 && (
                            <Button
                              variant="outline-success"
                              onClick={()=>this._startGame(currentActiveGame.id)}
                            >
                              {translate('start_game')}
                            </Button>
                          )
                        }
                      </React.Fragment>
                    )
                  }

                  {
                    currentActiveGame && joinedGame && (
                      <Button
                        size="sm"
                        className="mt-3"
                        variant="outline-danger"
                        onClick={()=>this._exitGame(currentActiveGame.id)}
                      >
                        {translate('exit_game')}
                      </Button>
                    )
                  }

                  {
                    currentActiveGame && !joinedGame && userActiveGame && (
                      <Button
                        size="sm"
                        className="mt-3"
                        variant="outline-danger"
                        onClick={()=>this._cancelGame(currentActiveGame.id)}
                      >
                        {translate('cancel_game')}
                      </Button>
                    )
                  }

                </div>
              ) || (
                <div className="d-flex justify-content-center flex-column align-items-center w-100"
                     style={{minHeight: 300}}>
                  <FontAwesomeIcon
                    className="fa-spin"
                    style={{fontSize: 60}}
                    icon={faSpinner}
                  />
                </div>
              )
            }
            
          </Card>
        </Container>

        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  multiWordMaker: state.multiWordMaker,
  purchases: state.purchases,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize)

});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    getActiveMultiWordMakerGames: (page) => dispatch(getActiveMultiWordMakerGames(page)),
    resetMultiWordMakerGames: () => dispatch(resetMultiWordMakerGames()),
    resetCurrentGame: () => dispatch(resetCurrentGame()),
    cancelMultiWordMakerGame: (gameId, history) => dispatch(cancelMultiWordMakerGame(gameId, history)),
    exitMultiWordMakerGame: (gameId, history) => dispatch(exitMultiWordMakerGame(gameId, history)),
    startMultiWordMakerGame: (game, gameId, history) => dispatch(startMultiWordMakerGame(game, gameId, history)),
    getCurrentActiveWordMakerGame: (gameId) => dispatch(getCurrentActiveWordMakerGame(gameId)),


  };
}

export default connect(mapStateToProps, bindAction)(MultiWordMakerWaiting);
