import React, {PureComponent} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {Translate, getTranslate} from 'react-localize-redux';

import colors from '../../../lib/colors';

const TrainingSettingCell = ({
  item,
  trainingId, 
  onPressItem,
  autoslide,
  autoplay_delay, 
  autoslide_type,
  autosound_sentence,
  autoslide_delay,
  autosound_word,
  autosound_translation,
  image_or_word,
  ratio,
  tablet,
  autosound_example_translation,
  loop,
  speed,
  translate
}) => {

  let value = '';
  if (item.name === 'autoslide') {
    value = autoslide
  }

  if (item.name === 'loop') {
    value = loop
  }

  if (item.name === 'speed') {
    value = speed
  }

  if (item.name === 'autoplay_delay') {
    value = autoplay_delay
  }

  if (item.name === 'autoslide_delay') {
    value = autoslide_delay
  }

  if (item.name === 'autosound_word') {
    value = autosound_word
  }

  if (item.name === 'autosound_translation') {
    value = autosound_translation
  }

  if (item.name === 'autosound_example_translation') {
    value = autosound_example_translation
  }

  if (item.name === 'image_or_word') {
    value = image_or_word;
  }

  if (item.name === 'autosound_sentence') {
    value = autosound_sentence;
  }

  if (item.name === 'autoslide_type') {
    value = autoslide_type;
  }

  return (
    <a href="#"
      className="trainingsettingcell"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onPressItem(item.name, value)
      }}
    >
      <div
        className="wordContainer"
        style={{height: !tablet ? 60 : 100}}>
        <div className="wordWrapper">
          <span 
            className="setting"
            style={{color: colors.greyishBrown, fontSize: ratio * (!tablet ? 12 : 14)}}>
            {translate(item.name)}
          </span>
          {
            item.description !== '' && (
              <span
                className="description"
                style={{fontStyle:'italic', fontSize: ratio * (!tablet ? 12 : 14)}}
              >
              {item.description}
              </span>
            )
          }
        </div>

        {(item.name === 'autoplay_delay' || item.name === 'speed' || item.name === 'autoslide_delay' || item.name === 'image_or_word' || item.name === 'autoslide_type') && (
          <span className="value" style={{fontSize: ratio * (!tablet ? 12 : 14), width: !tablet ? 50 : 80}}>
            {item.name === 'speed' && `${speed}`}
            {item.name === 'autoplay_delay' && `${autoplay_delay} ${translate('seconds')}`}
            {item.name === 'autoslide_delay' && `${autoslide_delay} ${translate('seconds')}`}
            {item.name === 'image_or_word' && `${image_or_word}`}
            {item.name === 'autoslide_type' && `${translate(autoslide_type)}`}
          </span>
        )}

        {item.name === 'autoslide' && autoslide == 1 &&
        <FontAwesomeIcon style={{fontSize: !tablet ? 25 : 35}} icon={faCheck}/>}
        {item.name === 'autosound_word' && autosound_word == 1 &&
        <FontAwesomeIcon style={{fontSize: !tablet ? 25 : 35}} icon={faCheck}/>}
        {item.name === 'autosound_sentence' && autosound_sentence == 1 &&
        <FontAwesomeIcon style={{fontSize: !tablet ? 25 : 35}} icon={faCheck}/>}
        {item.name === 'autosound_translation' && autosound_translation == 1 &&
        <FontAwesomeIcon style={{fontSize: !tablet ? 25 : 35}} icon={faCheck}/>}
        {item.name === 'autosound_example_translation' && autosound_example_translation == 1 &&
        <FontAwesomeIcon style={{fontSize: !tablet ? 25 : 35}} icon={faCheck}/>}
        {item.name === 'loop' && loop == 1 &&
        <FontAwesomeIcon style={{fontSize: !tablet ? 25 : 35}} icon={faCheck}/>}
      </div>
    </a>
  )
};

export default TrainingSettingCell;