import * as types from './types';

export const shareList = (listId, contacts) => (dispatch) => {
    dispatch({ type: types.SHARE_LIST, payload: {listId, contacts}});
};

export const shareWord = (wordId, contacts) => (dispatch) => {
    dispatch({ type: types.SHARE_WORD, payload: {wordId, contacts}});
};

export const shareGlossary = (glossaryId, contacts) => (dispatch) => {
    dispatch({ type: types.SHARE_GLOSSARY, payload: {glossaryId, contacts}});
};

export const getSharedGlossaries = () => (dispatch) => {
    dispatch({ type: types.GET_SHARED_GLOSSARIES});
};

export const getSharedLists = () => (dispatch) => {
    dispatch({ type: types.GET_SHARED_LISTS});
};

export const getSharedWords = () => (dispatch) => {
    dispatch({ type: types.GET_SHARED_WORDS});
};

export const getSharedNumbers = () => (dispatch) => {
    dispatch({ type: types.GET_SHARED_NUMBERS});
};

export const deleteSharedWord = (word_id) => (dispatch) => {
    dispatch({ type: types.DELETE_SHARED_WORD, payload: word_id});
};

export const deleteSharedList = (list_id) => (dispatch) => {
    dispatch({ type: types.DELETE_SHARED_LIST, payload: list_id});
};
export const deleteSharedGlossary = (glossary_id) => (dispatch) => {
    dispatch({ type: types.DELETE_SHARED_GLOSSARY, payload: glossary_id});
};

export const saveSharedList = (list_id) => (dispatch) => {
    dispatch({ type: types.SAVE_SHARED_LIST, payload: list_id});
};

export const toggleInFavoriteSharedWord = (word_id) => (dispatch) => {
    dispatch({ type: types.TOGGLE_SHARED_WORD_FAVORITE, payload: word_id});
};

export const toggleInVocabularySharedWord = (word_id) => (dispatch) => {
    dispatch({ type: types.TOGGLE_SHARED_WORD_VOCABULARY, payload: word_id});
};
