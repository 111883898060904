import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {LocalizeProvider} from 'react-localize-redux';
import ReduxToastr from 'react-redux-toastr';
import ReactGA from 'react-ga';

import Main from 'containers/main';

import Routes from './routes';
import store from './storeConfig';

import 'normalize.css';
// import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/index.scss';

let moment = require('moment-timezone');
ReactGA.initialize('UA-176319064-1');
ReactGA.pageview(window.location.pathname + window.location.search);

moment.locale('ru');

const app = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <div>
      <LocalizeProvider store={store}>
        <Main>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </Main>
      </LocalizeProvider>
      <ReduxToastr
        timeOut={3000}
        preventDuplicates
        position="top-center"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
      />
    </div>
  </Provider>, app);

