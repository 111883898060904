import * as types from '../actions/types';
import moment from 'moment';

const INITIAL_STATE = {
  monthData: [],
  dayData: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_CALENDAR_MONTH_DATA:{

      const today = moment().format("YYYY-MM-DD");

      let data = [];
      let dataArray = [];

      if(action.payload.length !== 0) {
        data = action.payload;

        dataArray = data.map((item, index) => {
          let color = '#F35757';
          if (item.repetition_date >= today) {
            color = item.trainings == 0 ? 'rgba(62,61,61,0.5)' : '#26C1CD';
          } else {
            color = item.trainings == 0 ? '#F35757' : '#26C1CD';
          }
          return {
            id: index,
            title: item.list_name,
            allDay: false,
            start: new Date(item.repetition_date),
            end: new Date(item.repetition_date),
            color,
            list_id: item.list_id,
            day: item.repetition_date,
            list: item
          }
        })
      }
      
      return Object.assign({}, state, {
        monthData: dataArray
      });}
    
    case types.SET_CALENDAR_DAY_DATA:
      return Object.assign({}, state, {
        dayData: action.payload.length > 0 ? action.payload : {} ,
      });

    default:
      return state;
  }
}