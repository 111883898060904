import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logApiError} from './logSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* get_favourite_glossaries() {
  let {userId} = yield select(state => state.user);

  if (!userId) {
    userId = yield getUserId();
  }

  if (!userId) return;

  if (userId) {
    try {
      const result = yield request
      .get(API_URL + 'get_favourite_glossaries')
      .query({
        user_id: userId,
      });

      // console.log("get_favourite_glossaries", result.body)

      yield put({
        type: types.SET_FAVORITE_GLOSSARIES,
        payload: result.body
      });
    } catch (error) {
      yield logApiError({
        payload: {
          function: 'get_favourite_glossaries',
          error: error && error.message ? error.message : (error.response ? error.response : 'error')
        }
      })
    }
  }
}

export function* getListsByLangF() {
  const {userId} = yield select(state => state.user);

  if (!userId) return;

  try {
    const result = yield request
    .get(API_URL + 'getListsByLang')
    .query({
      user_id: userId,
      favourite: true
    });

    yield put({
      type: types.SET_FAVORITE_LISTS,
      payload: result.body
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getListsByLang',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getFavoriteWords(data) {
  const {userId} = yield select(state => state.user);

  if (!userId) return;

  try {
    const result = yield request
    // .get(API_URL + 'getFavoriteWordsWithPages')
    .get(API_URL + 'getFavoriteWordsWithPagesOptimized')
    .query({
      user_id: userId,
      page: data.payload
    });

    // console.log("getFavoriteWordsWithPagesOptimized", result.body)

    yield put({
      type: types.SET_FAVORITE_WORDS_WITH_PAGES,
      payload: result.body
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getFavoriteWordsWithPages',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getFavoritePhrases(data) {
  const {userId} = yield select(state => state.user);

  if (!userId) return;

  try {
    const result = yield request
    .get(API_URL + 'getFavoritePhrases')
    .query({
      user_id: userId,
      page: data.payload
    });

    yield put({
      type: types.SET_FAVORITE_PHRASES_WITH_PAGES,
      payload: result.body
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getFavoritePhrases',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* deleteFromFavoriteWords(data) {
  const {userId} = yield select(state => state.user);

  if (!userId) return;

  try {
    const result = yield request
    .post(API_URL + 'deleteFromFavoriteWords')
    .send({
      user_id: userId,
      word_id: data.payload
    });

    yield getFavoriteWords({payload: 1});

    yield getAllFavoriteNumbers();
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'deleteFromFavoriteWords',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* deleteFromFavoritePhrases(data) {
  const {userId} = yield select(state => state.user);

  if (!userId) return;

  try {
    const result = yield request
    .post(API_URL + 'deleteFromFavoritePhrases')
    .send({
      user_id: userId,
      phrase_id: data.payload.id
    });

    if (result.body.result) {
      // toastr.info('', translate('phrase_removed_from_favorites'));
      yield put({
        type: types.DELETE_FAVORITE_PHRASE_FROM_PHRASES,
        payload: data.payload.id
      });
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'deleteFromFavoritePhrases',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getAllFavoriteNumbers() {
  let {userId} = yield select(state => state.user);

  if (!userId) {
    userId = yield getUserId();
  }

  if (!userId) return;

  try {
    const result = yield request
    .post(API_URL + 'getAllFavoriteNumbers')
    .send({
      user_id: userId,
    });
    yield put({
      type: types.SET_FAVORITE_NUMBERS,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getAllFavoriteNumbers',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }

}

const getUserId = () => {
  return localStorage.getItem('userId');
};