import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';

import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import colors from '../../../lib/colors';
import * as constants from '../../../lib/constants';


class AccountCell extends Component {

  render() {
    const {account, onItemPressed, userId, onDeletePressed, translate, tablet} = this.props;

    const imageUrl = account && account.avatar ?
    account.avatar.indexOf('http') == 0 ? account.avatar : `${constants.BACKEND_URL}${account.avatar}?w=350`  :
      (account.gender && account.gender == 'female' ? require("../../../assets/images/girl-down.png")
      : require("../../../assets/images/boy-down.png"));

    return (
      <Card
        className="accountcell"
        onClick={() => onItemPressed(account)}
      >
        <div className="accountWrapper">
          <a href="#" onClick={(e)=>onDeletePressed(account, e)} className="delete p-2">
            <FontAwesomeIcon icon={faTrashAlt} />
          </a>

          <div className="accountContainer">
            <div
              className="imageWrapper"
              style={{
                height: 150,
                width: 150,
                borderRadius: 150/2,
                borderStyle: 'solid',
                  borderColor: account.id == userId ? colors.tealish : colors.lightGreyTwo
            }}>
              {
                <Image
                  className="accountImage"
                  style={{
                  // height: 145,
                  width: 145,
                  borderRadius: 145/2,
                  borderWidth: 5
                }}
                  src={imageUrl}/>
              }
            </div>

            <div className="textWrapper">
              <span className="emailText" style={{fontSize: !tablet ? 12 : 16}}>
                {account.email}
              </span>
              <span className="nameText" style={{fontSize: !tablet ? 12 : 16}}>
                {`${account.first_name ? account.first_name : ''} ${account.last_name ? account.last_name : ''}`}
              </span>
              <span className="langText" style={{fontSize: !tablet ? 12 : 16}}>
                {`${account.study_language} - ${account.translation_language}`}
              </span>
            </div>
          </div>
          
        </div>
      </Card>
    );
  }
}

export default AccountCell;
