import request from 'superagent';
import {select, put, call, delay} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logActivity, logApiError} from './logSagas';

import {getEverydayPhrase, getEverydayWord,} from './everydaySagas';
import {getListsToRepeat, getListsByLang} from './lists';
// import {setHideKnown, setHideKnownCourse} from './trainings';
import {
  getRegistrationDate, getUserLimits,
  checkForHappyDay,checkWebSubscriptionStatus
} from './purchasesSagas';
import {checkForCourseDebts, getCoursesMistakesTestsRepeats} from './courseSagas';
import {getUnreadNews, getUserNews} from './newsSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* register(data) {
  const payload = data.payload;

  const {token, ip, country, city} = yield select(state => state.user);
  const {locale} = yield select(state => state.settings);
  const translate = yield select(state => getTranslate(state.localize));

  var userLang = navigator.language || navigator.userLanguage;
  let localeCode = process.env.REACT_APP_LANGUAGE;

  if (userLang) {
    let ar = userLang.split('-');

    if (ar.length === 2 && ['ru', 'uk', 'en'].indexOf(ar[0]) !== -1 || userLang === 'uk' || userLang === 'ru') {
      localeCode = ar[0];
    }
  }

  var date = new Date();
  var timeZone = date.getTimezoneOffset() * 60;

  try {
    let params = {
      email: payload.email,
      locale: localeCode,
      device_token: token,
      time_zone: timeZone,
      // device_version: DeviceInfo.getSystemVersion(),
      // device_name: DeviceInfo.getDeviceId(),
      // model_name: DeviceInfo.getModel(),
      // device_id: DeviceInfo.getDeviceId(),
      // system_name: DeviceInfo.getSystemName(),
      // brand: DeviceInfo.getBrand(),
      tablet: 0,
      platform: 'web',
      ip,
      country,
      city
    };

    if (payload.social) {
      params.social_media_id = payload.social.id;
      params.social_media_platform = payload.platform;
      params.first_name = payload.social.first_name;
      params.last_name = payload.social.last_name;
      params.avatar = payload.social.avatar;
    }

    const registerResult = yield request
    .post(API_URL + 'register')
    .send(params);

    if (registerResult.statusCode === 201) {
      if (!registerResult.body.fraud) {
        toastr.error(translate('error'), registerResult.body.error);
      } else {
        toastr.error(translate('error'), translate('fraudery'));
      }
    } else if (registerResult.statusCode === 202) {
      const user = registerResult.body.user;

      if (user && registerResult.body.request.social_media_platform) {
        yield put({type: types.RESET_USER_LISTS});
        yield put({type: types.RESET_CACHED_LISTS});

        yield put({type: types.SET_USER_DATA, payload: user});
        yield put({type: types.SET_USER_ID, payload: user.id});
        yield put({type: types.SET_ORIGINAL_USER_ID, payload: user.id});

        setUserId(user.id);
        setData(user);
        setTrainingSettings(user.id);

        yield put({type: types.SET_USER_CONFIRMED});

        const wizard = yield call(nextStep);
        
        localStorage.removeItem('tour');

        if (!wizard) {
          yield logActivity({payload: {activityId: 160}});

          payload.history.replace(`/${locale}/wizard`);
        } else {
          payload.history.replace(`/${locale}/home`);
        }
        yield getListsToRepeat({payload: {limit: 5, userId: user.id}});

        const showArchive = localStorage.getItem(`showArchive${user.id}`);
        const fontSize = localStorage.getItem(`fontSize${user.id}`);
   
        if (fontSize != null && fontSize != undefined) {
          yield put({ type: types.SET_FONT_SIZE, payload: fontSize })
        }

        if (showArchive != null && showArchive != undefined) {
          yield put({ type: types.TOGGLE_SHOW_ARCHIVED_LIST, payload: showArchive })
        } else {
          yield put({ type: types.TOGGLE_SHOW_ARCHIVED_LIST, payload: "0" });

          localStorage.setItem(`showArchive${user.id}`, "0")
        }

        yield delay(1000);

        yield getCoursesMistakesTestsRepeats();
        yield getUserNews({payload: {alert: 1}});
        yield getUserLimits();
        yield checkForCourseDebts({payload: user.id});
        yield checkForHappyDay();
        yield getEverydayPhrase();
        yield getRegistrationDate();
        yield getUserNews();
        yield getUnreadNews();
        yield checkWebSubscriptionStatus({payload:user.id});
        yield getEverydayWord();
        yield getListsByLang({payload:{filter:"by_list_name", order:"asc"}});

      } else {
        toastr.error(translate('error'), registerResult.body.error);
      }
    } else {
      const user = registerResult.body.user;
      setNewUser(user.id);
      yield put({type: types.SET_NEW_USER, payload: user});

      if (!payload.social) {
        toastr.success('', translate('successeful_registration'));

        yield put({type: types.SET_USER_DATA, payload: user});
        yield put({type: types.SET_USER_ID, payload: user.id});
        yield put({type: types.SHOULD_CHECK_VERIFIED, payload: true});
      } else {
        if (user) {
          yield put({type: types.RESET_USER_LISTS});
          yield put({type: types.RESET_CACHED_LISTS});

          yield put({type: types.SET_USER_DATA, payload: user});
          yield put({type: types.SET_USER_ID, payload: user.id});
          yield put({type: types.SET_ORIGINAL_USER_ID, payload: user.id});

          setUserId(user.id);
          setData(user);
          setTrainingSettings(user.id);

          yield put({type: types.SET_USER_CONFIRMED});

          const wizard = yield call(nextStep);
          localStorage.removeItem('tour');

          if (!wizard) {
            yield logActivity({payload: {activityId: 160}});
            payload.history.replace(`/${locale}/wizard`);
          } else {
            payload.history.replace(`/${locale}/home`);
          }
          yield getListsToRepeat({payload: {limit: 5, userId: user.id}});

          const showArchive = localStorage.getItem(`showArchive${user.id}`);
          const fontSize = localStorage.getItem(`fontSize${user.id}`);
          
          if (fontSize != null && fontSize != undefined) {
            yield put({ type: types.SET_FONT_SIZE, payload: fontSize })
          }

          if (showArchive != null && showArchive != undefined) {
            yield put({ type: types.TOGGLE_SHOW_ARCHIVED_LIST, payload: showArchive })
          } else {
            yield put({ type: types.TOGGLE_SHOW_ARCHIVED_LIST, payload: "0" });

            localStorage.setItem(`showArchive${user.id}`, "0")
          }
          
          yield delay(1000);
          yield getCoursesMistakesTestsRepeats();
          yield getUserNews({payload: {alert: 1}});
          yield getUserLimits();
          yield checkForCourseDebts({payload: user.id});
          yield checkForHappyDay();
          yield getEverydayPhrase();
          yield getRegistrationDate();
          yield getUserNews();
          yield getUnreadNews();
          yield checkWebSubscriptionStatus({payload:user.id});
          yield getEverydayWord();
          yield getListsByLang({payload:{filter:"by_list_name", order:"asc"}});

        }
      }
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'register',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* checkUser(data) {
  const {locale} = yield select(state => state.settings);

  try {
    const result = yield request
    .post(API_URL + 'checkUserStatus')
    .send({user_id: data.payload.userId});

    // console.log("checkUser", result.statusCode , result.body)

    if (result.statusCode === 200) {
      const user = result.body.user;

      if (user) {
        localStorage.removeItem('tour');

        yield put({type: types.RESET_USER_LISTS});
        yield put({type: types.RESET_CACHED_LISTS});

        yield put({type: types.SET_USER_DATA, payload: user});
        yield put({type: types.SET_USER_ID, payload: user.id});
        yield put({type: types.SET_ORIGINAL_USER_ID, payload: user.id});
        yield put({type: types.SHOULD_CHECK_VERIFIED, payload: false});
        yield put({type: types.SET_USER_CONFIRMED});

        setUserId(user.id);
        setData(user);
        setTrainingSettings(user.id);

        const wizard = localStorage.getItem('wizard');
        
        if (!wizard) {
          data.payload.history.replace(`/${locale}/wizard`);
        } else {
          data.payload.history.replace(`/${locale}/home`);
        }
        yield getListsToRepeat({payload: {limit: 5, userId: user.id}});

        const showArchive = localStorage.getItem(`showArchive${user.id}`);
        const fontSize = localStorage.getItem(`fontSize${user.id}`);
       
        if (fontSize != null && fontSize != undefined) {
          yield put({ type: types.SET_FONT_SIZE, payload: fontSize })
        }

        if (showArchive != null && showArchive != undefined) {
          yield put({ type: types.TOGGLE_SHOW_ARCHIVED_LIST, payload: showArchive })
        } else {
          yield put({ type: types.TOGGLE_SHOW_ARCHIVED_LIST, payload: "0" });

          localStorage.setItem(`showArchive${user.id}`, "0")
        }
        
        yield delay(1000);

        yield getCoursesMistakesTestsRepeats();
        yield getUserNews({payload: {alert: 1}});
        yield getUserLimits();
        yield checkForCourseDebts({payload: user.id});
        yield checkForHappyDay();
        yield getEverydayPhrase();
        yield getRegistrationDate();
        yield getUserNews();
        yield getUnreadNews();
        yield checkWebSubscriptionStatus({payload:user.id});
        yield getEverydayWord();
        yield getListsByLang({payload:{filter:"by_list_name", order:"asc"}});
      }
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'checkUser',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* resendActivationLink(data) {
  try {
    const {userId} = yield select(state => state.user);

    const result = yield request
    .post(API_URL + 'resendActivationLink')
    .send({
      user_id: userId,
      email: data.payload
    });

    if (result.statusCode === 200) {
      // toastr.info("", translate('resend_activation_link_success'))
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'resendActivationLink',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* login(data) {
  const payload = data.payload;
  const {token, ip, country, city} = yield select(state => state.user);
  const {locale} = yield select(state => state.settings);
  const translate = yield select(state => getTranslate(state.localize));

  var userLang = navigator.language || navigator.userLanguage;
  let localeCode = process.env.REACT_APP_LANGUAGE;

  if (userLang) {
    let ar = userLang.split('-');

    if (ar.length === 2 && ['ru', 'uk', 'en'].indexOf(ar[0]) !== -1 || userLang === 'uk' || userLang === 'ru') {
      localeCode = ar[0];
    }
  }

  var date = new Date();
  var timeZone = date.getTimezoneOffset() * 60;

  let params = {
    email: payload.email,
    password: payload.password,
    locale: localeCode,
    device_token: token,
    time_zone: timeZone,
    // device_version: DeviceInfo.getSystemVersion(),
    // device_name: DeviceInfo.getDeviceId(),
    // model_name: DeviceInfo.getModel(),
    // device_id: DeviceInfo.getUniqueID(),
    // system_name: DeviceInfo.getSystemName(),
    // brand: DeviceInfo.getBrand(),
    platform:'web',
    tablet: 0,
    ip,
    country,
    city
  };

  if (payload.social) {
    params.social_media_id = payload.social;
    params.social_media_platform = payload.platform;
  }

  try {
    const loginResult = yield request
    .post(API_URL + 'login')
    .set('Content-Type', 'application/json')
    .send(params);

    // console.log("loginResult", loginResult.body)

    if (loginResult.statusCode !== 200) {
      if (!loginResult.body.fraud) {
        if (payload.social) {
          toastr.warning(translate('error'), translate('register'));
        } else {
          toastr.error(translate('error'), loginResult.body.error);
        }

        payload.history.push(`/${locale}/register`, {email: payload.email});
      } else {
        toastr.error(translate('error'), translate('fraudery'));
      }
    } else {
      const user = loginResult.body.user;
      localStorage.removeItem('tour');

      if (user) {
        yield put({type: types.RESET_USER_LISTS});
        yield put({type: types.RESET_CACHED_LISTS});

        yield put({type: types.SET_USER_ID, payload: user.id});
        yield put({type: types.SET_ORIGINAL_USER_ID, payload: user.id});
        yield put({type: types.SET_USER_DATA, payload: user});

        setUserId(user.id);
        setData(user);
        setTrainingSettings(user.id);

        yield checkWebSubscriptionStatus({payload: user.id});
      }

      const wizard = yield call(nextStep);

      if (!wizard) {
        yield logActivity({payload: {activityId: 160}});
        payload.history.replace(`/${locale}/wizard`);
      } else {
        payload.history.replace(`/${locale}/home`);
      }

      yield getListsToRepeat({payload: {limit: 5, userId: user.id}});

      const showArchive = localStorage.getItem(`showArchive${user.id}`);
      const fontSize = localStorage.getItem(`fontSize${user.id}`);

      if (fontSize != null && fontSize != undefined) {
        yield put({ type: types.SET_FONT_SIZE, payload: fontSize })
      }

      if (showArchive != null && showArchive != undefined) {
        yield put({ type: types.TOGGLE_SHOW_ARCHIVED_LIST, payload: showArchive })
      } else {
        yield put({ type: types.TOGGLE_SHOW_ARCHIVED_LIST, payload: "0" });

        localStorage.setItem(`showArchive${user.id}`, "0")
      }

      yield delay(1000);

      yield getCoursesMistakesTestsRepeats();
      yield getUserNews({payload: {alert: 1}});
      yield getUserLimits();
      yield checkForCourseDebts({payload: user.id});
      yield checkForHappyDay();
      yield getEverydayPhrase();
      yield getRegistrationDate();
      yield getUserNews();
      yield getUnreadNews();
      yield checkWebSubscriptionStatus({payload:user.id});
      yield getEverydayWord();
      yield getListsByLang({payload:{filter:"by_list_name", order:"asc"}});

    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'login',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* updateUserToken(data) {
  let params = {
    device_token: data.payload.token,
    user_id: data.payload.userId,
    // platform: Platform.OS,
    // tablet: DeviceInfo.isTablet()
  };

  try {
    const result = yield request
    .post(API_URL + 'updateUserToken')
    .send(params);
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'updateUserToken',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* sendLink(data) {
  const payload = data.payload;
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'password/email')
    .set('Content-Type', 'application/json')
    .send({
      email: payload,
      web: 1
    });

    // console.log("sendLink", result.body)

    if (result.statusCode !== 200) {
      toastr.error(translate('error'), translate('no_such_email'))
    } else {
      toastr.success('', translate('reset_link_sent'));
      
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'sendLink',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

const nextStep = () => {
  return localStorage.getItem('wizard');
};

const setUserId = (userId) => {
  localStorage.setItem('userId', `${userId}`);
  localStorage.setItem('originalUserId', `${userId}`);
};

const setNewUser = (userId) => {
  localStorage.setItem(`newUser`, `${userId}`);
};

const setData = async(user) => {
  localStorage.setItem(`listSize${user.id}`, `${user.list_size}`);
  localStorage.setItem(`studyLanguage${user.id}`, `${user.study_language}`);
  localStorage.setItem(`translationLanguage${user.id}`, `${user.translation_language}`);
  localStorage.setItem(`filterLists${user.id}`, 'by_list_name');
  localStorage.setItem(`orderLists${user.id}`, 'asc');
};

const setTrainingSettings = async(userId) => {
  try {
    // training 1
    // await AsyncStorage.setItem(`autoplay_delay_1_${userId}`, '2');
    // await AsyncStorage.setItem(`autosound_word_1_${userId}`, '1');
    // await AsyncStorage.setItem(`autosound_translation_1_${userId}`, '0');

    // training 2
    // await AsyncStorage.setItem(`autoslide_2_${userId}`, '1');
    // await AsyncStorage.setItem(`autoslide_delay_2_${userId}`, '2');
    // await AsyncStorage.setItem(`autosound_word_2_${userId}`, '1');
    // await AsyncStorage.setItem(`image_or_word_2_${userId}`, 'word');

    // training 3
    // await AsyncStorage.setItem(`autoslide_3_${userId}`, '1');
    // await AsyncStorage.setItem(`autoslide_delay_3_${userId}`, '2');
    // await AsyncStorage.setItem(`autosound_word_3_${userId}`, '1');
    // await AsyncStorage.setItem(`image_or_word_3_${userId}`, 'image');

    // training 4
    // await AsyncStorage.setItem(`autoslide_4_${userId}`, '1');
    // await AsyncStorage.setItem(`autoslide_delay_4_${userId}`, '2');
    // await AsyncStorage.setItem(`autosound_word_4_${userId}`, '1');
    // await AsyncStorage.setItem(`autosound_translation_4_${userId}`, '1');
    // await AsyncStorage.setItem(`image_or_word_4_${userId}`, 'image');

    // training 5
    // await AsyncStorage.setItem(`autoslide_5_${userId}`, '1');
    // await AsyncStorage.setItem(`autoslide_delay_5_${userId}`, '2');
    // await AsyncStorage.setItem(`autosound_word_5_${userId}`, '1');
    // await AsyncStorage.setItem(`image_or_word_5_${userId}`, 'image');

    // training 6
    // await AsyncStorage.setItem(`autoslide_6_${userId}`, '1');
    // await AsyncStorage.setItem(`autoslide_delay_6_${userId}`, '2');
    // await AsyncStorage.setItem(`autosound_word_6_${userId}`, '1');
    // await AsyncStorage.setItem(`autosound_translation_6_${userId}`, '1');
    //
    // training 7
    // await AsyncStorage.setItem(`autoslide_7_${userId}`, '1');
    // await AsyncStorage.setItem(`autoslide_delay_7_${userId}`, '2');
    // await AsyncStorage.setItem(`autosound_word_7_${userId}`, '1');
    // await AsyncStorage.setItem(`image_or_word_7_${userId}`, 'image');

    // training 8
    // await AsyncStorage.setItem(`autoslide_8_${userId}`, '0');
    // await AsyncStorage.setItem(`autoslide_delay_8_${userId}`, '2');
    // await AsyncStorage.setItem(`autoslide_type_8_${userId}`, 'sentence');
    // await AsyncStorage.setItem(`autosound_sentence_8_${userId}`, '1');

    // training 9
    // await AsyncStorage.setItem(`autoslide_9_${userId}`, '1');
    // await AsyncStorage.setItem(`autoslide_delay_9_${userId}`, '2');
    // await AsyncStorage.setItem(`autosound_word_9_${userId}`, '1');

    // training 10
    // await AsyncStorage.setItem(`autoslide_10_${userId}`, '1');
    // await AsyncStorage.setItem(`autoslide_delay_10_${userId}`, '2');
    // await AsyncStorage.setItem(`autosound_word_10_${userId}`, '1');

    // training 12
    // await AsyncStorage.setItem(`autoslide_12_${userId}`, '1');
    // await AsyncStorage.setItem(`autoslide_delay_12_${userId}`, '2');
    // await AsyncStorage.setItem(`autosound_word_12_${userId}`, '1');
    // await AsyncStorage.setItem(`image_or_word_12_${userId}`, 'word');

    // training 14
    // await AsyncStorage.setItem(`autoslide_delay_14_${userId}`, '1');
    // await AsyncStorage.setItem(`autosound_word_14_${userId}`, '1');
    // await AsyncStorage.setItem(`autosound_translation_14_${userId}`, '1');
    // await AsyncStorage.setItem(`autosound_example_translation_14_${userId}`, '1');
    // await AsyncStorage.setItem(`loop_14_${userId}`, '0');
    // await AsyncStorage.setItem(`autosound_sentence_14_${userId}`, '1');
    // await AsyncStorage.setItem(`sentence_speed_listening_14_${userId}`, '1');

  } catch (error) {
    // console.log(error.message);
  }
};