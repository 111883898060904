import React, {Component} from 'react';
import {connect} from "react-redux";
import {Translate, getTranslate} from 'react-localize-redux';
import {withRouter, Link} from 'react-router-dom';

import {toastr} from 'react-redux-toastr';

import { LiqPayPay, LiqPaySubscribe } from "react-liqpay";

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import {fetchingData} from '../../../actions/activity';
import {getPurchaseData} from '../../../actions/purchasesActions';

import Loader from "../../../components/Custom/Loader/index";

class PaymentResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tablet: false

    }
  }

  componentDidMount() {
    const {locale} = this.props.settings;

    const orderId = localStorage.getItem("orderId");

    if(!orderId || orderId == null || orderId == undefined) {
      this.props.history.replace(`/${locale}/products`);
    } else {
      this.props.fetchingData(true);
      this.props.getPurchaseData(orderId);
    }
  }
  
  componentWillUnmount() {
    localStorage.removeItem("orderId");
  }

  render() {
    const {locale} = this.props.settings;
    const {tablet} = this.state;
    const {translate} = this.props;
    const {liqpayPurchase} = this.props.purchases;
    const {fetchingData} = this.props.activity;

    // console.log(liqpayPurchase)
    return (
      <div className="paymentresults" style={{marginTop: 50}}>
        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate('payment_result')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container style={{marginTop: 20}} className="pageWrapper">
        </Container>

        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  purchases: state.purchases,
  settings: state.settings,
  translate: getTranslate(state.localize),
  activity: state.activity,

});

function bindAction(dispatch) {
  return {
    fetchingData: (status) => dispatch(fetchingData(status)),
    getPurchaseData: (orderId) => dispatch(getPurchaseData(orderId)),
  };
}

export default connect(mapStateToProps, bindAction)(PaymentResult);
