import React, {Component} from 'react';
import {connect} from "react-redux";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
  faCircle,
  faWindowRestore,
  faCheckCircle,
  faImage,
  faLanguage,
  faPuzzlePiece,
  faPenFancy,
  faBrain,
  faVolumeUp,
  faHandPointer,
  faStream,
  faPencilAlt,
  faBalanceScaleLeft,
} from '@fortawesome/free-solid-svg-icons';
import {faWindows} from '@fortawesome/free-brands-svg-icons';

import {Translate} from 'react-localize-redux';

class TrainingButtons extends Component {

  render() {
    const {
      modalVisible,
      phrasebook,
      onClose,
      training,
      title,
      ratio,
      course,
      listId,
      tablet,
      level,
      translationLanguage,
      tour,
      translate,
      customiseButtons
    } = this.props;

    const {userData} = this.props.user;
    let trainingIds = userData.trainings_set != null ? userData.trainings_set.split(',').map( Number ) : [];


    return (
      <Modal
        className="trainingButtonsModal"
        show={modalVisible}
        onHide={()=>onClose(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="introText" as="h6">
            {translate(title ? title : 'select_training_to_repeat_words')}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="trainingButtons">

          <a href="#" className="d-block text-center p-1 mb-3" onClick={customiseButtons}>
            {translate('change_trainings_set')}
          </a>

          <ButtonGroup vertical className="d-block">

            {
              listId !== 0 && trainingIds.indexOf(1) !== -1 && (
                <Button
                  block
                  variant="outline-info"
                  onClick={() => training('Preview')}
                >
                  {/*<FontAwesomeIcon className="icon" icon={faImage}/>*/}
                  <Translate className="buttonText" id="preview_training"/>
                </Button>
              )
            }

            {
              !phrasebook && window.innerWidth > 600 && (trainingIds.indexOf(6) !== -1 || listId === 0)  && (
                <Button
                  block
                  variant="outline-info"
                  onClick={() => training('DragImage')}
                >
                  {/*<FontAwesomeIcon className="icon" icon={faWindowRestore}/>*/}
                  <Translate className="buttonText" id="drag_image_training"/>
                </Button>
              )}

            {
              !phrasebook && (trainingIds.indexOf(2) !== -1 || listId === 0)  &&(
                <Button
                  block
                  variant="outline-info"
                  onClick={() => training('SelectTranslation')}
                >
                  {/*<FontAwesomeIcon className="icon" icon={faLanguage}/>*/}
                  <Translate className="buttonText" id="select_translation_training"/>
                </Button>
              )
            }

            {
              !phrasebook && (trainingIds.indexOf(4) !== -1 || listId === 0)  &&(
                <Button
                  block
                  variant="outline-info"
                  onClick={() => training('SelectWord')}
                >
                  {/*<FontAwesomeIcon className="icon" icon={faWindows}/>*/}
                  <Translate className="buttonText" id="select_word_training"/>
                </Button>
              )
            }

            {
              !phrasebook && (trainingIds.indexOf(3) !== -1 || listId === 0)  && (
                <Button
                  block
                  variant="outline-info"
                  onClick={() => training('CollectWord')}
                >
                  {/*<FontAwesomeIcon className="icon" icon={faPuzzlePiece}/>*/}
                  <Translate className="buttonText" id="collect_word_training"/>
                </Button>
              )
            }

            {
              (trainingIds.indexOf(5) !== -1 || listId === 0)  && (
                <Button
                  block
                  variant="outline-info"
                  onClick={() => training('CheckSpelling')}
                >
                  {/*<FontAwesomeIcon className="icon" icon={faPencilAlt}/>*/}
                  <Translate className="buttonText" id="check_spelling_training"/>
                </Button>
              )
            }


            {
              !phrasebook && (trainingIds.indexOf(12) !== -1 || listId === 0) && (
                <Button
                  block
                  variant="outline-info"
                  onClick={() => training('MissingLetter')}
                >
                  {/*<FontAwesomeIcon className="icon" icon={faBalanceScaleLeft}/>*/}
                  <Translate className="buttonText" id="missing_letter_training"/>
                </Button>
              )
            }

            {
              listId !== 0 && (translationLanguage == 'ru' || translationLanguage == 'uk') && trainingIds.indexOf(8) !== -1  && (
                <Button
                  block
                  variant="outline-info"
                  onClick={() => training('CollectSentence')}
                >
                  {/*<FontAwesomeIcon className="icon" icon={faStream}/>*/}
                  <Translate className="buttonText" id="collect_sentence_training"/>
                </Button>
              )
            }

            {
              false && listId !== 0 && (translationLanguage == 'ru' || translationLanguage == 'uk') && (
                <Button
                  block
                  variant="outline-info"
                  onClick={() => training('CollectSentenceTap')}
                >
                  {/*<FontAwesomeIcon className="icon" icon={faHandPointer}/>*/}
                  <Translate className="buttonText" id="collect_sentence_training_tap"/>
                </Button>
              )
            }

            {
              (!phrasebook && level == 0 || level == 1) && !tour && listId !== 0 && trainingIds.indexOf(14) !== -1  && (
                <Button
                  block
                  variant="outline-info"
                  onClick={() => training('Listening')}
                >
                  {/*<FontAwesomeIcon className="icon" icon={faVolumeUp}/>*/}
                  <Translate className="buttonText" id="listening_training"/>
                </Button>
              )}
            {
              (!phrasebook && listId !== 0 && listId != -1 && !tour && level != 3 && level != 2) && trainingIds.indexOf(9) !== -1  && (
                <Button
                  block
                  variant="outline-info"
                  onClick={() => training('MemoryCards')}
                >
                  {/*<FontAwesomeIcon className="icon" icon={faBrain}/>*/}
                  <Translate className="buttonText" id="memory_cards_training"/>
                </Button>
              )
            }

            {
              !phrasebook && !course && level == 3 && (trainingIds.indexOf(10) !== -1 || listId === 0)  && (
                <Button
                  block
                  variant="outline-info"
                  onClick={() => training('WordDefinition')}
                >
                  {/*<FontAwesomeIcon className="icon" icon={faPenFancy}/>*/}
                  <Translate className="buttonText" id="definition_training"/>
                </Button>
              )
            }
            {
              level !== 3 && listId !== 0 && trainingIds.indexOf(15) !== -1  && (
                <Button
                  block
                  variant="outline-info"
                  onClick={() => training('TranslateSentence')}
                >
                  {/*<FontAwesomeIcon className="icon" icon={faPenFancy}/>*/}
                  <Translate className="buttonText" id="translate_sentence_training"/>
                </Button>
              )
            }

          </ButtonGroup>
        </Modal.Body>
      </Modal>
    )
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(TrainingButtons);
