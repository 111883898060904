import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logApiError} from './logSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* getTourGlossaries() {

  let localeCode = 'ru';

  /*let curLocale = I18n.currentLocale();
  if(curLocale) {
    curLocale = I18n.currentLocale().split('-');

    if(curLocale.length) {
      localeCode = curLocale[0]
    }
  }*/

  try {
    const result = yield request
    .get(API_URL + 'getTourGlossaries')
    .query({
      translationLanguage: localeCode
    });

    // console.log("getTourGlossaries",result.body)

    yield put({
      type: types.SET_TOUR_GLOSSARIES,
      payload: result.body
    });
    
  } catch (error) {

  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getTourGlossary(data) {
  let localeCode = 'ru';

  /*let curLocale = I18n.currentLocale();
  if(curLocale) {
    curLocale = I18n.currentLocale().split('-');

    if(curLocale.length) {
      localeCode = curLocale[0]
    }
  }*/

  try {
    const result = yield request
    .get(API_URL + 'getTourGlossary')
    .query({
      glossary_id: data.payload,
      translationLanguage: localeCode
    });

    // console.log("getTourGlossaries",result.body)

    yield put({
      type: types.SET_TOUR_GLOSSARY,
      payload: result.body
    });
    
  } catch (error) {

  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


export function* getTourWord(data) {
  let localeCode = 'ru';

  /*let curLocale = I18n.currentLocale();
  if(curLocale) {
    curLocale = I18n.currentLocale().split('-');

    if(curLocale.length) {
      localeCode = curLocale[0]
    }
  }*/



  try {
    const result = yield request
    .get(API_URL + 'getTourWord')
    .query({
      word_id: data.payload,
      translationLanguage: localeCode
    });

    // console.log("getTourWord",result.body)

    yield put({
      type: types.SET_TOUR_WORD,
      payload: result.body.details
    });
    
  } catch (error) {
  console.log(error)
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


export function* getTourLists(data) {
  let localeCode = 'ru';

  /*let curLocale = I18n.currentLocale();
  if(curLocale) {
    curLocale = I18n.currentLocale().split('-');

    if(curLocale.length) {
      localeCode = curLocale[0]
    }
  }*/
  
  try {
    const result = yield request
    .get(API_URL + 'getTourLists')
    .query({
      translationLanguage: localeCode
    });

    // console.log("getTourLists",result.body)

    yield put({
      type: types.SET_TOUR_LISTS,
      payload: result.body
    });
    
  } catch (error) {
  // console.log(error)
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}
export function* getTourListWords(data) {
  let localeCode = 'ru';

  /*let curLocale = I18n.currentLocale();
  if(curLocale) {
    curLocale = I18n.currentLocale().split('-');

    if(curLocale.length) {
      localeCode = curLocale[0]
    }
  }*/
  
  try {
    const result = yield request
    .get(API_URL + 'getTourListWords')
    .query({
      list_id: data.payload,
      translationLanguage: localeCode
    });

    // console.log("getTourListWords",result.body)

    yield put({
      type: types.SET_TOUR_LIST_WORDS,
      payload: result.body
    });
    
  } catch (error) {
  // console.log(error)
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getTourCourse() {
  let localeCode = 'ru';

  /*let curLocale = I18n.currentLocale();
  if(curLocale) {
    curLocale = I18n.currentLocale().split('-');

    if(curLocale.length) {
      localeCode = curLocale[0]
    }
  }*/
  
  try {
    const result = yield request
    .get(API_URL + 'getTourCourse')
    .query({
      translationLanguage: localeCode
    });

    // console.log("getTourCourse",result.body)

    yield put({
      type: types.SET_TOUR_COURSE_DATA,
      payload: result.body
    });
    
  } catch (error) {
    // console.log(error)
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getTourCourseListWords(data) {
  let localeCode = 'ru';

  /*let curLocale = I18n.currentLocale();
  if(curLocale) {
    curLocale = I18n.currentLocale().split('-');

    if(curLocale.length) {
      localeCode = curLocale[0]
    }
  }*/
  
  try {
    const result = yield request
    .get(API_URL + 'getTourCourseListWords')
    .query({
      translationLanguage: localeCode,
      list_id: data.payload
    });

    // console.log("getTourCourseListWords",result.body)

    yield put({
      type: types.SET_TOUR_COURSE_LIST_WORDS,
      payload: result.body
    });
    
  } catch (error) {
    // console.log(error)
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


export function* getTourCourseCalendarMonthData(data) {
  const {courseData} = yield select(state => state.reducers.course);

  try {
    const result = yield request
    .get(API_URL + 'getTourCourseCalendarMonthData')
    .query({
      user_id: 1,
      date: data.payload,
      course_id: 3023
    });

    // console.log("getTourCourseCalendarMonthData",result.body)

    yield put({
      type: types.SET_COURSE_CALENDAR_MONTH_DATA,
      payload: result.body.data
    });

  } catch (error) {
    // console.log(error)
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getTourWordsForTraining(data) {
  const params = data.payload;

  const page = data.payload.page;

  try {
    const result = yield request
    // .post(API_URL + __DEV__ ? "getTrainingWords" : params.endpoint)
    .post(API_URL + params.endpoint)
    // .post(API_URL + "getTrainingWords")
    .send({
      user_id: 1,
      training_id : params.trainingId,
      page : params.page,
      list_id: params.listId,
      glossary_id: params.glossaryId,
      phrasebook_id: params.phrasebookId,
      course_list_id: params.courseListId,
      translations_limit: 5,
      hideKnown: false
    });

    // console.log("TIME",result.body, params)

    if (params.trainingId === 8) {
      if(params.page == 1 && result.body.data.length == 0) {
        if(!params.updateWords) {
          // yield put(NavigationActions.back());
          // toastr.info('', translate('not_enough_words'));
        }
      } else {
        yield put({
          type: types.SET_WORDS_FOR_TRAINING_COLLECT_SENTENCE,
          payload: result.body
        });

        if(params.listId && params.listId > 0) {
          yield put({
            type: types.SET_CACHED_COLLECT_SENTENCE_LIST_WORDS,
            payload: {
              page: page,
              data: result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }

        if(params.courseListId && params.courseListId > 0) {
          yield put({
            type: types.SET_COURSE_CACHED_COLLECT_SENTENCE_LIST_WORDS,
            payload: {
              page: page,
              data: result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }
      }
    } else if (params.trainingId === 12) {
      if(params.page == 1 && result.body.data.length == 0) {
        if(!params.updateWords) {
          // yield put(NavigationActions.back());
          // toastr.info('', translate('not_enough_words'));
        }
      } else {
        yield put({
          type: types.SET_WORDS_FOR_MISSING_LETTER,
          payload: {data: result.body, language: 'en'}
        });

        if(params.listId && params.listId > 0) {
          yield put({
            type: types.SET_CACHED_MISSING_LETTER_LIST_WORDS,
            payload: {
              page: page,
              data: result.body,
              trainingId: params.trainingId,
              listId: params.listId,
              language: 'en'
            }
          });
        }

        if(params.courseListId && params.courseListId > 0) {
          yield put({
            type: types.SET_COURSE_CACHED_MISSING_LETTER_LIST_WORDS,
            payload: {
              page: page,
              data: result.body,
              trainingId: params.trainingId,
              listId: params.courseListId,
              language: 'en'
            }
          });
        }
      }
    } else if(params.trainingId === 9) {
      if(params.page = 1 && result.body.data.length < 5) {
        if(!params.updateWords) {
          // yield put(NavigationActions.back());
          // toastr.info('', translate('not_enough_words'));
        }
      } else {
        yield put({
          type: types.SET_WORDS_FOR_TRAINING_MEMORY_CARDS,
          payload: result.body
        });

        if(params.listId && params.listId > 0) {
          yield put({
            type: types.SET_CACHED_MEMORY_CARD_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }

        if(params.courseListId && params.courseListId > 0) {
          yield put({
            type: types.SET_COURSE_CACHED_MEMORY_CARD_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }

        yield put({
          type: types.TOGGLE_SHOW_MEMORY_CARDS,
          payload: true
        });
      }
    }
    else {
      if(params.page == 1 && result.body.data.length == 0) {
        if(!params.updateWords) {
          // yield put(NavigationActions.back());
          // toastr.info('', translate('not_enough_words'));
        }
      } else {
        yield put({
          type: types.SET_WORDS_FOR_TRAINING,
          payload: result.body
        });

        if(params.listId && params.listId > 0 && params.trainingId == 1) {
          yield put({
            type: types.SET_CACHED_PREVIEW_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }

        if(params.listId && params.listId > 0 && params.trainingId == 3) {
          yield put({
            type: types.SET_CACHED_COLLECT_WORD_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }

        if(params.listId && params.listId > 0 && params.trainingId == 5) {
          yield put({
            type: types.SET_CACHED_CHECK_SPELLING_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }

        // COURSE
        if(params.courseListId && params.courseListId > 0 && params.trainingId == 1) {
          yield put({
            type: types.SET_COURSE_CACHED_PREVIEW_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }

        if(params.courseListId && params.courseListId > 0 && params.trainingId == 3) {
          yield put({
            type: types.SET_COURSE_CACHED_COLLECT_WORD_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }

        if(params.courseListId && params.courseListId > 0 && params.trainingId == 5) {
          yield put({
            type: types.SET_COURSE_CACHED_CHECK_SPELLING_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }

        if(params.listId && params.listId > 0 && params.trainingId == 2) {
          yield put({
            type: types.SET_CACHED_SELECT_TRANSLATION_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }

        // COURSE
        if(params.courseListId && params.courseListId > 0 && params.trainingId == 2) {
          yield put({
            type: types.SET_COURSE_CACHED_SELECT_TRANSLATION_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }

        if(params.listId && params.listId > 0 && params.trainingId == 4) {
          yield put({
            type: types.SET_CACHED_SELECT_WORD_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }

        // COURSE
        if(params.courseListId && params.courseListId > 0 && params.trainingId == 4) {
          yield put({
            type: types.SET_COURSE_CACHED_SELECT_WORD_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }

        if(params.listId && params.listId > 0 && params.trainingId == 6) {
          yield put({
            type: types.SET_CACHED_DRAG_IMAGE_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }

        // COURSE
        if(params.courseListId && params.courseListId > 0 && params.trainingId == 6) {
          yield put({
            type: types.SET_COURSE_CACHED_DRAG_IMAGE_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }

        if(params.listId && params.listId > 0 && params.trainingId == 10) {
          yield put({
            type: types.SET_CACHED_DEFINITION_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }

        // COURSE
        if(params.courseListId && params.courseListId > 0 && params.trainingId == 10) {
          yield put({
            type: types.SET_COURSE_CACHED_DEFINITION_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }

      }
    }
  } catch (error) {
    // console.log(error)
    // console.log(error.message)
    // yield logApiError({payload: {function: `${params.endpoint}`, payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}