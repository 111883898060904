import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';
import moment from 'moment';
import {logApiError} from './logSagas';

const API_URL = constants.API_URL;

export function* getConnectedAccounts() {
  // const {originalUserId} = yield select(state => state.user);

  const originalUserId = localStorage.getItem('originalUserId');
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .get(API_URL + 'getConnectedAccounts')
    .query({
      user_id: originalUserId,
      with_user: true,
    });

    yield put({
      type: types.SET_CONNECTED_ACCOUNTS,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({payload: {function: 'getConnectedAccounts', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* deleteConnected(data) {
  // const {originalUserId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));
  const originalUserId = localStorage.getItem('originalUserId');

  try {
    const result = yield request
    .get(API_URL + 'deleteConnected')
    .query({
      user_id: originalUserId,
      child_user_id: data.payload.connectedId,
    });

    if(result.statusCode == 200) {
      yield setUserId(originalUserId);
      yield getConnectedAccounts();

      toastr.success('', translate('successfully_deleted_connected'));
    }
  } catch (error) {
    yield logApiError({payload: {function: 'deleteConnected', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* checkPasswordToConnect(data) {
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .get(API_URL + 'checkPasswordToConnect')
    .query({
      password: data.payload.password,
      email: data.payload.email,
    });

    // console.log("checkPasswordToConnect", result.body)

    if(result.statusCode == 200) {
      yield put({
        type: types.NEED_PASSWORD_TO_CONFIRM,
        payload: false
      });

      yield connectUser(result.body.data.id);
    } else {
      toastr.error(translate('wrong_password'))
    }
  } catch (error) {
    yield logApiError({payload: {function: 'checkPasswordToConnect', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* createChildAccount(data) {
  // const {originalUserId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));
  const originalUserId = localStorage.getItem('originalUserId');

  try {
    const result = yield request
    .post(API_URL + 'createChildAccount')
    .send({
      user_id: originalUserId,
      email: data.payload.email,
      full_name: data.payload.fullName,
      avatar: null,
      endAt: data.payload.endAt
    });

    if(result.statusCode == 200) {
      yield getConnectedAccounts();

      toastr.success('', translate('account_was_connected'));
      yield put({
        type: types.ACCOUNT_CONNECTED,
        payload: true
      });
    } else {
      yield put({
        type: types.NEED_PASSWORD_TO_CONFIRM,
        payload: true
      });
    }
  } catch (error) {
    yield logApiError({payload: {function: 'createChildAccount', payload: data.payload,error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* connectUser(id) {
  // const {originalUserId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));
  const originalUserId = localStorage.getItem('originalUserId');

  const {activeSubscriptionStatus,
    webSubscriptionStatus,
    webSubscriptionExpireAt,
    activeSubscriptionPurchasedAt,
    activeSubscriptionType, lifetimeDate, lifetimeStatus} = yield select(state => state.purchases);

  /*let months = false;

  if (activeSubscriptionType) {
    if (activeSubscriptionType == 'mnemonic.subscription.monthly_1.0' || activeSubscriptionType == 'com.kudryaalexey.Mnemonic.fullMontly' || activeSubscriptionType == 'mnemonic.subscription.monthly_no_discount') {
      months = 1;
    } else if (activeSubscriptionType == 'mnemonic.subscription.yearly_1.0' || activeSubscriptionType == 'com.kudryaalexey.Mnemonic.fullYear' || activeSubscriptionType == 'mnemonic.subscription.yearly_full') {
      months = 12;
    }
  }

  if(lifetimeStatus) {
    months = 240;
  }*/

  let endAt = false;

  if(webSubscriptionStatus) {
    endAt = moment(webSubscriptionExpireAt).format("YYYY-MM-DD");
  } else {
    endAt = moment().add(1, 'month').format("YYYY-MM-DD")
  }

  // endAt = moment(activeSubscriptionPurchasedAt).add(months, 'month').format("YYYY-MM-DD");
  
  try {
    const result = yield request
    .get(API_URL + 'connectUser')
    .query({
      user_id: originalUserId,
      child_user_id: id,
      endAt
    });

    if(result.statusCode == 200) {
      yield getConnectedAccounts();
      // yield put(NavigationActions.navigate({routeName: 'ChangeAccount'}));

      toastr.success('', translate('account_was_connected'));
      yield put({
        type: types.ACCOUNT_CONNECTED,
        payload: true
      });
    } else {
      toastr.error('', translate('unable_to_connect'));
    }
  } catch (error) {
    yield logApiError({payload: {function: 'connectUser', payload: id,error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

const setUserId = (userId) => {
  localStorage.setItem('userId', `${userId}`);
};
