import React, {Component} from 'react';
import moment from 'moment';
import {connect} from "react-redux";

import {withRouter, Link} from 'react-router-dom';

import {Translate, getTranslate} from 'react-localize-redux';
import {toastr} from 'react-redux-toastr';

import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import Loader from "../../../components/Custom/Loader/index";
import TrainingSubHeader from "../../../components/Headers/TrainingSubHeader/index";
import Thinking from "../../../assets/images/thinking-boy.png";

import {getTestWord, logTestWord, resetTestWord} from '../../../actions/testActions';
import {logActivity} from '../../../actions/logActions';
import {logGamesLimits} from '../../../actions/purchasesActions';
import {fetchingData} from '../../../actions/activity';
import {getUserScores} from '../../../actions/userActions';

class WordTest extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
      totalWords: 50,
      currentWordIndex: 0,
      scores: 0,
      correctIndex: null,
      selectedIndex: null,
      answered: false,
      token: moment().format('YYYY-MM-DD HH:mm:ss'),
      tablet: false

    };
    
    this.timeOut = null;
    this.timeOut1 = null;
  }

  componentDidMount() {
    const {isConnected} = this.props.settings;

    if (isConnected) {
      this._getTestWord();
      this.props.logGamesLimits();
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._getTestWord();
    }
  }

  componentWillUnmount() {
    const {userId} = this.props.user;
    const {isConnected} = this.props.settings;

    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut1);
    
    this.props.resetTestWord();

    if (isConnected) {
      this.props.getUserScores(userId);
    }
  }
  
  _getTestWord = () => {
    const {token, currentWordIndex, totalWords} = this.state;
    const {isConnected, locale} = this.props.settings;

    this.props.resetTestWord();

    this.setCorrectIndex();

    this.setState({
      selectedIndex: null,
      answered: false
    });

    if (isConnected) {
      if (currentWordIndex < totalWords) {
        // this.props.fetchingData(true);

        this.props.getTestWord(
          currentWordIndex,
          token
        );
      } else {

        this.props.history.push(`/${locale}/wordtestresult`, {totalWords: 50, token, finished: currentWordIndex !== 0, from: 'test'});

        this.props.logActivity({activityId: 141});
      }
    }
  };

  setCorrectIndex = () => {
    let n = Math.floor(Math.random() * 4);

    this.setState({
      correctIndex: n
    });
  };

  checkAnswer = (i) => {
    const {correctIndex, currentWordIndex, scores, token} = this.state;
    const {logTestWord} = this.props;
    const {word} = this.props.test;

    if (word) {
      this.setState({
        selectedIndex: i,
        currentWordIndex: currentWordIndex + 1,
        answered: true
      });

      let result = false;

      if (i === correctIndex) {
        result = true;

        this.setState({
          scores: scores + 5
        });
      }

      logTestWord(word.id, token, result, word.level);

      this.timeOut = setTimeout(() => {
        this._getTestWord();
      }, 500);
    }
  };

  dontKnowPressed = () => {
    const {token, currentWordIndex, totalWords} = this.state;
    const {logTestWord} = this.props;
    const {word} = this.props.test;

    if (word) {
      this.setState({
        currentWordIndex: currentWordIndex + 1,
        answered: true
      });

      logTestWord(word.id, token, false, word.level);

      this.timeOut1 =setTimeout(() => {
        this._getTestWord();
      }, 500)
    }
  };

  renderButton = (ind) => {
    const {word} = this.props.test;
    const {selectedIndex, correctIndex, answered} = this.state;
    const {fontSizeRation} = this.props.settings;
    const {tablet} = this.state;
    const {deviceWidth} = this.props.settings;

    if (!word) return;

    const translation = word.translation;

    if (selectedIndex !== null && selectedIndex === ind) {
      return <Button
        variant={selectedIndex === correctIndex ? 'success' : 'danger'}
        key={ind}
        disabled
        onClick={() =>  null}
        style={{width: deviceWidth > 400 ? 400 : deviceWidth - 40,marginBottom: 15}}
      >
        {word && correctIndex === ind ? translation : word.other_translations && word.other_translations.length > 0 && word.other_translations[ind] ? word.other_translations[ind].translation : ""}
      </Button>
    } else {
      return <Button
        variant="outline-secondary"
        key={ind}
        style={{width: deviceWidth > 400 ? 400 : deviceWidth - 40,marginBottom: 15}}
        disabled={answered}
        onClick={() => {
          this.checkAnswer(ind)
      }}>
        {word && correctIndex === ind ? translation : word.other_translations && word.other_translations.length > 0 && word.other_translations[ind] ? word.other_translations[ind].translation : ""}
      </Button>
    }
  };

  _goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const {word} = this.props.test;
    const {currentWordIndex, totalWords, answered, scores, tablet} = this.state;
    const {fetchingData} = this.props.activity;
    const {translate} = this.props;
    const {fontSizeRation, isConnected, deviceHeight, deviceWidth} = this.props.settings;

    const progress = totalWords !== 0 ? ((currentWordIndex + 1) / totalWords) : 0;

    const buttons = [
      this.renderButton(0),
      this.renderButton(1),
      this.renderButton(2),
      this.renderButton(3)
    ];

    return (
      <div className="wordtest">

        <Image
          className="thinkingBoy"
          src={Thinking}
        />

        <Container className="pageWrapper">

          <TrainingSubHeader
            trainingId={false}
            freeze={false}
            key={false}
            version={false}
            ratio={fontSizeRation}
            scores={scores}
            progress={progress}
            changeWord={false}
            hideButtons
            deviceHeight={deviceHeight}
            deviceWidth={deviceWidth}
            translate={translate}
            tablet={tablet}
            exit={this._goBack}
            history={this.props.history}
            trainingName="vocabulary_test"
            subtitle={false}
            hideInfo
            test
            showExit
            hideSettings
            title={'check_spelling_training'}
            text="check_spelling_training_description"
            url="https://youtu.be/UANsKK4rizg"
            toggleSettings={this.toggleWordSettingsModal}
          />

          <Card style={{minHeight: deviceHeight - 130}}>

            <div className="testWordWrapper">
            <span
              className="testWord"
              style={{fontSize: fontSizeRation * 24, marginTop: !tablet ? 10 : 20,
            marginBottom: !tablet ? 20 : 40, height: !tablet ? 36 : 46}}>
              {word ? word.word : ""}
            </span>
            </div>

            {/*<div className="barWrapper">
              <ProgressBar
                style={{width: '100%'}}
                variant="success"
                now={progress*100}
              />
            </div>*/}

            <div className="answerButtonsWrapper">
              <div style={{height: deviceHeight < 700 ? 230 : 285, width:'100%'}}>
                {
                  <div className="answerButtons" style={{flex: 1, textAlign: 'center'}}>
                    {buttons}
                  </div>
                }
              </div>
              {
                !answered && word && isConnected && (
                  <Button
                    style={{width: deviceWidth > 400 ? 400 : deviceWidth - 40,marginBottom: 15}}
                    variant="outline-danger"
                    onClick={this.dontKnowPressed}
                  >
                    {translate('dont_know')}
                  </Button>
                )
              }
            </div>
          </Card>
        </Container>
        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  test: state.test,
  user: state.user,
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize),
});

function bindAction(dispatch) {
  return {
    getTestWord: (currentWordIndex, token) => dispatch(getTestWord(currentWordIndex, token)),
    logTestWord: (wordId, token, result, level) => dispatch(logTestWord(wordId, token, result, level)),
    resetTestWord: () => dispatch(resetTestWord()),
    logActivity: (data) => dispatch(logActivity(data)),
    logGamesLimits: () => dispatch(logGamesLimits()),
    fetchingData: (status) => dispatch(fetchingData(status)),
    getUserScores: (userId) => dispatch(getUserScores(userId)),

  };
}

export default connect(mapStateToProps, bindAction)(WordTest);
