import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logApiError} from './logSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* getWordMakerWords(data) {
  const params = data.payload;

  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'getWordMakerWords')
    .send({
      user_id: userId,
      // level: params.level,
    });

    yield put({
      type: types.SET_WORD_MAKER_WORDS,
      payload: result.body
    });

  } catch (error) {
    yield logApiError({payload: {function: 'getWordMakerWords', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getWordMakerParts(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'getWordMakerParts')
    .send({
      user_id: userId,
      word_id: data.payload
    });

    yield put({
      type: types.SET_WORD_MAKER_PARTS,
      payload: result.body
    });
  } catch (error) {
    yield logApiError({payload: {function: 'getWordMakerParts', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getWordMakerData(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getWordMakerData')
    .query({
      user_id: userId,
      word_id: data.payload
    });
    // console.log("getWordMakerData", result.body)

    yield put({
      type: types.SET_WORD_MAKER_DATA,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({payload: {function: 'getWordMakerData', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* logWordMakerPart(data) {
  const params = data.payload;

  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'logWordMakerPart')
    .send({
      user_id: userId,
      word_id: params.wordId,
      word: params.word,
    });

    if(result.body.result) {
      toastr.success('', translate('guessed_new_word'));

      if(result.body.new_level) {
        toastr.success('', translate('new_word_maker_level'));
      }

      yield getWordMakerLevelScore({payload: params.wordId});
      yield getWordMakerParts({payload: params.wordId})
    } else {
      toastr.error('', translate(result.body.error));
    }
  } catch (error) {
    yield logApiError({payload: {function: 'logWordMakerPart', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }  finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* logBuyWordMakerWord(data) {
  const params = data.payload;
  const translate = yield select(state => getTranslate(state.localize));

  yield put({
    type: types.FETCHING_DATA,
    payload: true
  });
  
  const {userId} = yield select(state => state.user);
  const {currentWord} = yield select(state => state.wordMaker);

  try {
    const result = yield request
    .post(API_URL + 'logBuyWordMakerWord')
    .send({
      user_id: userId,
      word_id: currentWord.id,
      free_hint: params
    });
    
    if(result.body.result) {
      
      if(params) {
        yield put({
          type: types.SUBTRACT_FROM_FREE_HINTS,
        });
      }
      
      let message = `${translate('opened_new_word')}\n\n${result.body.word}`;
      
      toastr.confirm(message,{
        onOk: () => {
          if(result.body.new_level) {
            toastr.info('', translate('new_word_maker_level'));
          }
        },
        disableCancel: true
      });

      yield getWordMakerLevelScore({payload: currentWord.id});
      yield getWordMakerParts({payload: currentWord.id})
    } else {
      toastr.error('', translate(result.body.error));
    }

  } catch (error) {
    yield logApiError({payload: {function: 'logBuyWordMakerWord', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }  finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* buyWordMakerHints(data) {
  const params = data.payload;
  const translate = yield select(state => getTranslate(state.localize));

  yield put({
    type: types.FETCHING_DATA,
    payload: true
  });
  
  const {userId} = yield select(state => state.user);
  const {currentWord} = yield select(state => state.wordMaker);

  try {
    const result = yield request
    .post(API_URL + 'buyWordMakerHints')
    .send({
      user_id: userId,
      word_id: currentWord.id,
      free_hint: params
    });
    
    if(result.body.result) {
      yield put({
        type: types.ADD_WORD_MAKER_HINTS,
      });

      toastr.info('', translate("hints_bought_scores_added"));

    } else {
      toastr.error('', translate(result.body.error));
    }

  } catch (error) {
    yield logApiError({payload: {function: 'buyWordMakerHints', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }  finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* openWordMakerLevel() {
  const {userId} = yield select(state => state.user);
  const {currentWord} = yield select(state => state.wordMaker);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'openWordMakerLevel')
    .send({
      user_id: userId,
      word_id: currentWord.id,
      level: currentWord.level
    });

    if(result.body.result) {
      toastr.success('', translate('new_word_maker_level'));

      yield getWordMakerLevelScore({payload: currentWord.id})
      yield getWordMakerWords({payload: currentWord.level})
    } else {
      toastr.error('', translate(result.body.error));
    }
  } catch (error) {
    yield logApiError({payload: {function: 'openWordMakerLevel', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }  finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getWordMakerLevelScore(data) {
  const params = data.payload;

  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getWordMakerLevelScore')
    .query({
      user_id: userId,
      word_id: params,
    });

    yield put({
      type: types.SET_WORD_MAKER_LEVEL_SCORE,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({payload: {function: 'getWordMakerLevelScore', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }  finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}
