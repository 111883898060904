import React, {Component} from 'react';
import {Link} from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock, faAward} from '@fortawesome/free-solid-svg-icons';

import {toastr} from 'react-redux-toastr';

import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import colors from '../../../lib/colors';
import * as constants from '../../../lib/constants';

class CourseListCell extends Component {

  render() {
    const {list,
      ratio,
      test,
      onItemPressed,
      repeat,
      onRepeatPressed,
      index,
      courseData,
      onInfoPressed,
      onCalendarPressed,
      tablet,
      testProgress,
      dots,
      isConnected,
      deviceWidth,
      deviceHeight,
      translate,
      courseName
    } = this.props;

    const imageUrl = list && list.image && list.image.filename ? list.image.filename : false;

    let locked = courseData && courseData.last_step < index;

    let correctColors = colors.greyishBrown;

    if (!locked) {
      if (list.correct > 0 && list.correct < 50) {
        correctColors = colors.coral;
      } else if (list.correct >= 50 && list.correct < 75) {
        correctColors = colors.greyishBrown;
      } else if (list.correct >= 75) {
        correctColors = colors.tealish;
      }
    }

    let type = 'list';

    if(repeat) {
      type = 'repeat'
    }
    if(test) {
      type = 'test'
    }

    let cardHeight = 250;

    if(tablet) {
      cardHeight =  (tablet && deviceWidth > 600 ? 380 : 410)
    } else {
      cardHeight = (deviceWidth > 350 ? 300 : 280)
    }

    if(tablet) {
      if(ratio > 1.7) {
        cardHeight += (20 * ratio);
      }
    } else {
      if(ratio > 1.25) {
        cardHeight += (20 * ratio);
      }
    }

    const imageWidth = 300;

    const s3image = `${constants.S3MarkedImages}-${imageWidth}/${imageUrl}`;

    return (
      <Card
        className="courseList"
        style={{width: 200, height: cardHeight}}
        onClick={() => onItemPressed(list, index, locked, type)}
      >
        <div className="listWrapper">
          <div className="listContainer">

            {
              list.completed && (
                <FontAwesomeIcon
                  className="icon20"
                  icon={faAward}
                  style={{position: 'absolute',top: 5,right: 5,color: colors.greyishTeal}}
                />
              ) || null
            }
            
            <span className="listName" style={{fontSize: ratio * (!tablet ? 14 : 16)}}>
              {list.list_name}<br/>
              {courseName ? translate(courseName) : null}
            </span>


            {
              imageUrl && (
                <Image
                  className="wordImage"
                  style={{height: !tablet ? (deviceWidth > 350 ? 80 : 70) : 120, width: !tablet ? (deviceWidth > 350 ? 110 : 96) : 165}}
                  src={s3image}
                />
              ) || <div 
                className="imagePlaceholder"
                style={{height: !tablet ? 80 : 120, width: !tablet ? 110 : 165}}/>
            }

            <div className="flexRow mt-2">
              <span className="scores" style={{fontSize: ratio * (!tablet ? 12 : 14)}}>
                {translate('scoresUC')}:
              </span>
              <span className="scoresNum" style={{fontSize: ratio * (!tablet ? 12 : 14)}}>
                {list.scores}
              </span>
            </div>
            
            {
              !locked && list.repetitions.length > 0 && (
                <div 
                  className="starsContainer" 
                  style={{height: 24,marginTop: (!repeat && !test) ? 35 : 10}}
                >

                  {!repeat && !test ? testProgress : null}
                  {repeat && dots}
                  {test && testProgress}

                </div>
              ) || <div style={{height: 24, marginTop: (!repeat && !test) ? 35 : 10}}/>
            }

            {locked && (
              <FontAwesomeIcon
                icon={faLock}
                className="lock"
                style={{fontSize: !tablet ? 30 : 45,
                        marginTop: !tablet ? 30 : tablet && deviceWidth > 600 ? 35 : 40}}
              />)}
            {
              (repeat || (!locked && list.repetitions.length == 0)) && (
                <Button
                  className="mt-4"
                  variant="outline-success"
                  onClick={(e) => onRepeatPressed(list, repeat, e)}
                >
                    {translate(repeat ? 'repeat' : 'start')}
                </Button>
              )
            }
            {
              test && isConnected && (
                <Button
                  className="mt-4"
                  variant="outline-success"
                  onClick={(e) => onRepeatPressed(list, false, e)}
                >
                    {translate('pass_test')}
                </Button>
              )
            }
          </div>
        </div>
      </Card>
    );
  }
}

export default CourseListCell;
