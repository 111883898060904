import * as types from '../actions/types';

const INITIAL_STATE = {
  tags: [],
  connectedTags: [],
  connectedTagsFetched: false,
  selectedTags: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_TAGS:
      return Object.assign({}, state, {
        tags: action.payload,
      });
    
    case types.SET_CONNECTED_TAGS:
      return Object.assign({}, state, {
        connectedTags: action.payload,
        connectedTagsFetched: true,
      });

    case types.RESET_SELECTED_TAGS:
      return Object.assign({}, state, {
        selectedTags: [],
      });

    case types.SET_SELECTED_TAG: {
      let selected = state.selectedTags;

      const index = selected.findIndex(id => id === action.payload);

      if (index === -1) {
        selected.push(action.payload);
      } else {
        selected.splice(index, 1);
      }

      return Object.assign({}, state, {
        selectedTags: selected,
      });
    }

    default:
      return state;
  }
}