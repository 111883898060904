import React, {Component} from 'react';
import moment from 'moment';
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Translate, getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';

import Container from 'react-bootstrap/Container';

import TrainingSubHeader from '../../../components/Headers/TrainingSubHeader/index';
import Loader from "../../../components/Custom/Loader/index";

import {checkDictionaryWord, logWordExtractorResults, resetExtractorWords} from '../../../actions/testActions';
import {logActivity} from '../../../actions/logActions';
import {logGamesLimits} from '../../../actions/purchasesActions';
import {getUserScores} from '../../../actions/userActions';

import colors from '../../../lib/colors';

class WordExtractor extends Component {

  constructor(props) {
    super(props);

    this.state = {
      token: moment().format('YYYY-MM-DD HH:mm:ss'),
      typedWord: '',
      trainingInfoVisible: false,
      scores: 0,
      score: 5,
      seconds: 180,
      gameOver: false,
      pressedButtons: [],
      allLetters: {
        'en': ['E', 'T', 'O', 'E', 'A', 'E', 'A', 'O', 'S', 'O', 'A', 'T', 'C', 'T', 'I', 'C', 'N', 'R', 'N', 'R', 'T', 'A', 'R', 'A',
          'I', 'O', 'N', 'O', 'I', 'S', 'E', 'H', 'M', 'H', 'D', 'L', 'R', 'E', 'I', 'E', 'F', 'I', 'F', 'L', 'C',
          'M', 'E', 'T', 'E', 'M', 'L', 'T', 'D', 'T', 'U', 'A', 'R', 'U', 'F', 'U', 'G', 'Y', 'G', 'Y', 'P', 'W', 'G', 'W',
          'B', 'B', 'V', 'V', 'Q', 'P', 'Y', 'P', 'J', 'K', 'A', 'A', 'Z', 'S', 'X', 'N', 'H', 'H', 'D', 'S', 'D', 'N'],
        'ru': [
          'О', 'Е', 'А', 'Т', 'Л', 'О', 'И', 'Т', 'Л', 'Й', 'А', 'Е', 'Н', 'С', 'З', 'И', 'Н', 'Т', 'С', 'Н', 'Е', 'Т', 'Р', 'Б', 'Т', 'О', 'Р', 'В', 'З',
          'С', 'Р', 'Л', 'К', 'Р', 'О', 'В', 'М', 'Т', 'В', 'Р', 'Л', 'М', 'З', 'Л', 'В', 'К', 'М', 'Д', 'К', 'М', 'Д', 'П', 'Ч', 'М', 'Е', 'Д', 'П', 'Г',
          'Д', 'Н', 'П', 'У', 'Х', 'П', 'Д', 'У', 'Ы', 'З', 'Й', 'У', 'Н', 'П', 'Я', 'Ч', 'Я', 'Н', 'К', 'У', 'Ш', 'Ы', 'О', 'Р', 'Г', 'Ю', 'Ь', 'А', 'Ь', 'Г',
          'Г', 'О', 'А', 'Ж', 'Ц', 'Б', 'Е', 'К', 'Ж', 'Ч', 'Е', 'К', 'Й', 'О', 'Я', 'Х', 'И', 'В', 'Ж', 'И', 'Т', 'Ш', 'И', 'В', 'Ю', 'И', 'Л',
          'Ц', 'И', 'С', 'Е', 'Щ', 'О', 'Н', 'Ы', 'Э', 'Е', 'У', 'Я', 'Б', 'Ф', 'А', 'Я', 'Ы', 'Б', 'Ъ', 'А', 'С', 'Ь', 'Х', 'Ё', 'А', 'С', 'Ь'
        ],
        'de': [
          'E', 'S', 'T', 'D', 'N', 'A', 'H', 'G', 'I', 'E', 'S', 'M', 'R', 'N', 'U', 'B', 'S', 'D', 'L', 'O', 'T', 'I', 'C', 'F', 'A', 'N', 'U', 'W',
          'H', 'E', 'G', 'K', 'D', 'L', 'M', 'Z', 'U', 'I', 'O', 'Ä', 'L', 'R', 'S', 'P', 'C', 'E', 'B', 'G', 'N', 'F', 'M', 'T', 'Z', 'ß', 'O', 'R', 'V',
          'B', 'I', 'C', 'Ü', 'W', 'R', 'A', 'F', 'T', 'D', 'K', 'R', 'H', 'J', 'Z', 'N', 'L', 'Ö', 'V', 'E', 'A', 'P', 'R', 'S', 'G', 'Ü', 'I', 'H',
          'Ä', 'N', 'C', 'K', 'ß', 'T', 'A', 'W', 'Ö', 'S', 'U', 'J', 'E', 'D', 'X', 'E', 'A', 'H', 'Y', 'N', 'T', 'O', 'Q', 'E', 'I', 'M'
        ],
        'id': [
          'A', 'K', 'R', 'M', 'N', 'D', 'U', 'H', 'E', 'A', 'S', 'B', 'I', 'N', 'G', 'T', 'A', 'L', 'K', 'E', 'H', 'O', 'D', 'A', 'P',
          'R', 'I', 'T', 'C', 'U', 'E', 'S', 'M', 'A', 'Y', 'S', 'A', 'N', 'J', 'G', 'E', 'I', 'R', 'L', 'A', 'D', 'U', 'H', 'N', 'E', 'M',
          'B', 'A', 'W', 'P', 'I', 'D', 'Y', 'N', 'T', 'G', 'O', 'A', 'K', 'J', 'N', 'T', 'C', 'I', 'M', 'W', 'E', 'U', 'F', 'N', 'R', 'S', 'G',
          'V', 'T', 'K', 'L', 'Z', 'A', 'D', 'M', 'X', 'I', 'U', 'P', 'É', 'E', 'K', 'B', 'Q', 'A', 'T', 'R', 'L',
        ]
      },
      letters: [],
      studyLanguage: 'en',
      tablet: false

    };
    
    this.timeOut = null;
    this.interval = null;
  }

  componentDidMount() {
    document.addEventListener("keydown", this._handleKeyDown);

    this._setStudyLanguage();

    const {isConnected} = this.props.settings;

    if (isConnected) {
      this.props.logGamesLimits();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._resetWord();
      this._prepareLetters();
    }

    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected !== true) {
      clearInterval(this.interval);
      this.gameOver();
    }

    if (this.state.seconds === 0) {
      clearInterval(this.interval);
      this.gameOver();
    }
  }
  
  componentWillUnmount() {
    const {userId} = this.props.user;
    const {isConnected} = this.props.settings;

    clearTimeout(this.timeOut);
    clearInterval(this.interval);

    this.props.resetExtractorWords();

    if (isConnected) {
      this.props.getUserScores(userId);
    }

    document.removeEventListener("keydown", this._handleKeyDown);
  }

  _handleKeyDown = (event) => {
    const {typedWord, letters} = this.state;

    switch (event.keyCode) {
      case 27:
        this.props.history.goBack();
        break;

      case 8:
        this._eraseWord();
        break;

      case 13:
        event.preventDefault();
        if(typedWord.length >= 3) {
          this._confirmWord();
        }
        break;
      default:
        if(event.key && event.key.length !== 0 ) {
          let char = event.key.toLowerCase();
          let found = false;
          letters.forEach((letter, index) => {
            if(letter.toLowerCase() == char && !found) {
              this._buttonClicked(letter, index, event);
              found = true;
            }
          })
        }
        break;
    }
  };

  _goBack = () => {
    this.props.history.goBack();
  };

  _setStudyLanguage = () => {
    const {userId} = this.props.user;
    const {isConnected} = this.props.settings;

    const studyLanguage = localStorage.getItem(`studyLanguage${userId}`);

    if (studyLanguage !== null && studyLanguage != undefined) {
      this.setState({
        studyLanguage
      });
    }
    this.timeOut = setTimeout(()=> {
      if (isConnected) {
        this.startTimer();
      }
      this._prepareLetters();
    }, 250);
  };

  startTimer = () => {
    this.interval = setInterval(
      () => this.setState((prevState)=> ({seconds: prevState.seconds - 1})),
      1000
    );
  };

  _prepareLetters = () => {
    const {allLetters, studyLanguage} = this.state;
    const keyboard = allLetters[studyLanguage];

    let letters = this._shuffle(keyboard);

    letters = letters.splice(0, 16);

    this.setState({
      letters
    })
  };

  _shuffle(array) {
    let counter = array.length;

    while (counter > 0) {
      let index = Math.floor(Math.random() * counter);
      counter--;
      let temp = array[counter];
      array[counter] = array[index];
      array[index] = temp;
    }
    return array;
  }

  gameOver = () => {
    const {extractorResultsLogged} = this.props.test;

    clearInterval(this.interval);

    if (!extractorResultsLogged) {
      this.props.logActivity({activityId: 137});

      this.props.logWordExtractorResults(
        this.props.test.extractorWords.length, this.props.test.extractorScores
      )
    }
  };

  _resetWord = () => {
    this.setState((prevState) => ({
      typedWord: '',
      gameOver: false,
      seconds: 180,
      pressedButtons: [],
      letters: []
    }));
    this.props.resetExtractorWords();
    this.startTimer();
  };

  _buttonClicked = (letter, index, e) => {
    e.preventDefault();

    let foundIndex = null;
    let buttons = this.state.pressedButtons;

    this.state.pressedButtons.map((l, i) => {
      if (letter === l.letter && l.index === index) {
        foundIndex = i;
      }
    });

    if (foundIndex !== null) {
      buttons.splice(foundIndex, 1)
    } else {
      buttons.push({index, letter})
    }

    this.setState({
      pressedButtons: buttons
    });

    this._composeTypedWord();
  };

  _composeTypedWord = () => {
    let wordLetters = this.state.pressedButtons.map((item)=> {
      return item.letter;
    });

    this.setState({
      typedWord: wordLetters.join('')
    });
  };

  _eraseWord = () => {
    this.setState({
      pressedButtons: [],
      typedWord: ''
    })
  };

  _confirmWord = () => {
    const {extractorWords} = this.props.test;

    if (this.state.typedWord.length < 3) return;

    if (extractorWords.indexOf(this.state.typedWord.toLowerCase()) === -1) {
      this.props.checkDictionaryWord(this.state.token, this.state.typedWord);
    }

    this._eraseWord();
  };

  _refreshWords = () => {
    this._resetWord();
    this._prepareLetters();
    this.props.logActivity({activityId: 143});
  };
  
  render() {
    const {trainingInfoVisible, typedWord, pressedButtons, seconds, letters, gameOver, tablet} = this.state;
    const {extractorWords, extractorScores} = this.props.test;
    const {fontSizeRation, version, isConnected, deviceWidth, deviceHeight} = this.props.settings;
    const {fetchingData} = this.props.activity;
    const {translate} = this.props;

    const subtractor = !tablet ? (deviceHeight < 600 ? 100 : 40) : 240;

    let buttons = letters.map((item, i) => {
      let pressed = false;

      pressedButtons.map((typed, index) => {
        if (typed.letter === item && typed.index === i) {
          pressed = true;
        }
      });

      return (
        <a href="#" key={i} onClick={(e) => this._buttonClicked(item,i, e)}>
          <div
            className="letterView"
            style={{
              borderColor: pressed ? colors.tealish : colors.lightGreyTwo,
              backgroundColor: pressed ? colors.tealish : colors.white,
              width:80,
              height: 80,
              borderRadius: 40,

          }}>
            <span
              className="letter"
              style={{
                  color: pressed ? colors.white : colors.greyishBrown, 
                  fontSize: !tablet ? (deviceHeight < 600 ? 22 : 26) : 30
                }}
            >
              {item}
            </span>
          </div>
        </a>
      )
    });

    return (
      <div className="wordextractor">
        <Container className="pageWrapper">

          {/*<ScreenHeader width={!tablet ? 120 : 155}
           headerImageId={2} translateTitle={false} title="Word Extractor"
           goBack={() => {
           if (!gameOver) {
           this.props.logActivity({activityId: 138});
           }
           clearInterval(this.interval);
           this.props.navigation.goBack()
           }}
           >
           <TouchableOpacity style={!tablet ? headers.toolbarButtonWrapper : headers.toolbarButtonWrapperTablet}
           onClick={()=>{
           this._resetWord();
           this._prepareLetters();
           this.props.logActivity({activityId: 143});
           }}>
           <FontAwesome style={[!tablet ? headers.toolbarButton : headers.toolbarButtonTablet,]} icon={LightIcons.redo} pro={true}/>
           </TouchableOpacity>

           <TouchableOpacity style={!tablet ? headers.toolbarButtonWrapper : headers.toolbarButtonWrapperTablet} onClick={() => this.toggleInfoModal(true)}>
           <FontAwesome style={[!tablet ? headers.toolbarButton : headers.toolbarButtonTablet,]} icon={LightIcons.infoCircle} pro={true}/>
           </TouchableOpacity>
           </ScreenHeader>*/}

          <TrainingSubHeader
            trainingId={-1}
            freeze={false}
            key={typedWord}
            ratio={fontSizeRation}
            scores={extractorScores}
            progress={false}
            changeWord={false}

            deviceHeight={deviceHeight}
            deviceWidth={deviceWidth}
            translate={translate}
            tablet={tablet}
            exit={this._goBack}
            history={this.props.history}
            trainingName="word_extractor"
            subtitle=""
            hideSettings

            title={'word_extractor'}
            text="word_extractor_tip"
            url="https://youtu.be/lRdQ7ICtY4Q"
            toggleSettings={false}

            extractor
            seconds={seconds}
            typedWord={typedWord}
            erase={this._eraseWord}
            confirm={this._confirmWord}
            refresh={this._refreshWords}
          />

          <div
            className="typedWordView"
            style={{height: 50, margin: '15px 0'}}>
            <span className="typedWord" style={{fontSize: 30}}>{typedWord}</span>
          </div>

          {seconds > 0 && (
            <div
              className="lettersWrapper"
              style={{width: 360, justifyContent: !tablet ? 'flex-start' : 'center'}}
            >
              {buttons}
            </div>
          )}

          <div style={{width: '100%'}} className="correctWordsView">
            <span className="correctWords" style={{fontSize: fontSizeRation  * (!tablet ? 12 : 16)}}>
              {extractorWords.length > 0 && extractorWords.join(', ')}
            </span>
          </div>
          
          <Loader fetchingData={fetchingData}/>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  test: state.test,
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    checkDictionaryWord: (token, word) => dispatch(checkDictionaryWord(token, word)),
    logWordExtractorResults: (words, scores) => dispatch(logWordExtractorResults(words, scores)),
    resetExtractorWords: () => dispatch(resetExtractorWords()),
    logGamesLimits: () => dispatch(logGamesLimits()),
    logActivity: (data) => dispatch(logActivity(data)),
    getUserScores: (userId) => dispatch(getUserScores(userId)),

  };
}

export default connect(mapStateToProps, bindAction)(WordExtractor);
