import React, {Component} from 'react';
import {connect} from "react-redux";

import {Translate, getTranslate} from 'react-localize-redux';
import {withRouter} from 'react-router-dom';

import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';


import {Link} from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faCheckCircle, faCircle, faTimes} from '@fortawesome/free-solid-svg-icons';
import {toastr} from 'react-redux-toastr';

import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';


// import {Calendar, CalendarList, Agenda} from 'react-native-calendars';
// import {LocaleConfig} from 'react-native-calendars';

/*LocaleConfig.locales['fr'] = {
 monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
 monthNamesShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
 dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
 dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
 today: 'Aujourd\'hui'
 };

 LocaleConfig.locales['ru'] = {
 monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
 monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Нояб', 'Дек'],
 dayNames: ['Понедельник', 'Вторник', 'Среда', 'Черверг', 'Пятница', 'Суббота', 'Воскресенье'],
 dayNamesShort: ['пн.', 'вт.', 'ср.', 'чт.', 'пт.', 'сб.', 'вс.'],
 today: 'Сегодня'
 };

 LocaleConfig.locales['uk'] = {
 monthNames: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
 monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Нояб', 'Дек'],
 dayNames: ['Понеділок', 'Вівторок', 'Середа', 'Четвер', 'П\'ятниця', 'Субота', 'Неділю'],
 dayNamesShort: ['пн.', 'вт.', 'ср.', 'чт.', 'пт.', 'сб.', 'вс.'],
 today: 'Сегодня'
 };*/


// import RepetitionLists from '../../components/Modals/RepetitionLists/index';
import Loader from "../../components/Custom/Loader/index";

import {getCalendarDayData, getCalendarMonthData, setCalendarMonthData} from '../../actions/calendarActions';
import {logActivity} from '../../actions/logActions';
import {fetchingData} from '../../actions/activity';
import {setCurrentList} from '../../actions/lists.js';
import {togglePostpone, toggleCoursePostpone} from '../../actions/userActions';
import {getCourseCalendarMonthData, setCourseCalendarMonthData, setCourseList} from '../../actions/courseActions';

import colors from '../../lib/colors';

const localizer = momentLocalizer(moment);

class RepetitionCalendar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentMonth: moment().format("MM"),
      currentYear: moment().format("YYYY"),
      currentDay: moment().format("DD"),
      dayData: false,
      emptyDate: false,
      showModal: false,
      dateString: false,
      tablet: false,
      back: false,
      type: false

    };

    this.timeOut = null;
    // const localeCode = I18n.currentLocale().split('-');
    //
    // if (localeCode.length > 0) {
    //   if (localeCode[0] == 'ru' || localeCode[0] == 'fr' || localeCode[0] == 'uk') {
    //     LocaleConfig.defaultLocale = localeCode[0];
    //   }
    // }
  }

  componentDidMount() {
    // console.log('com', this.props.history.location)
    if (this.props.history.location && this.props.history.location.state) {
      this.setState({
        back: this.props.history.location.state.back,
        type: this.props.history.location.state.type
      });

      this.timeOut = setTimeout(() => {
        this._getCalendarData();
      }, 100);
    } else {
      this.props.history.goBack();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._getCalendarData();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut)
  }

  _getCalendarData = () => {
    const {currentYear, currentMonth, type} = this.state;
    const {courseData} = this.props.course;

    this.props.fetchingData(true, courseData.id);

    if (this.props.history.location.state.type == 'general') {
      this.props.getCalendarMonthData(`${currentYear}-${currentMonth}`);
    } else if (this.props.history.location.state.type == 'course') {
      if (courseData.id) {
        this.props.getCourseCalendarMonthData(`${currentYear}-${currentMonth}`);
      } else {
        this.props.history.goBack();
      }
    }
  };

  showDayData = (day) => {
    this.setState({
      currentYear: day.year,
      currentMonth: day.month,
      currentDay: day.day,
      dateString: day.dateString
    });

    let data = this.props.calendar.monthData;

    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        if (key == day.dateString) {
          data[key].selected = true;
          data[key].selectedColor = colors.lightGrey;

          if (data[key]['dots'].length > 0) {
            this.setState({
              dayData: data[key]['dots'],
              showModal: true
            })
          } else {
            this.setState({
              emptyDate: true
            })
          }
        } else {
          data[key].selected = false;
          data[key].selectedColor = colors.white;
        }
      }
    }
    this.props.setCalendarMonthData(data);
  };

  monthChanged = (month) => {
    const {isConnected} = this.props.settings;
    const {type} = this.state;

    this.setState({
      currentYear: month.year,
      currentMonth: month.month,
    });

    if (isConnected) {
      this.props.fetchingData(true);
      if (type == 'general') {
        this.props.getCalendarMonthData(`${month.year}-${month.month}`);
      } else if (type == 'course') {
        this.props.getCourseCalendarMonthData(`${month.year}-${month.month}`);
      }
    }
  };

  toggleModal = (status) => {
    this.setState({
      showModal: status
    })
  };

  toList = (list) => {
    const {type} = this.state;
    const {locale} = this.props.settings;
    
    if (type == 'general') {
      this.props.setCurrentList(list.list);
    } else if (type == 'course') {
      this.props.setCourseList(list);
    }

    // this.toggleModal(false);

    this.setState({
      dayData: false
    });

    if (type == 'general') {
      this.props.history.push(`/${locale}/list/${list.list_id}`, {list: list.list, back: true})
    } else if (type == 'course') {
      this.props.history.push(`/${locale}/courselist/${list.list_id}`, {list: list.list, back: true})
    }

    this.props.logActivity({activityId: 242});
  };

  _togglePostpone = (e) => {
    e.preventDefault();

    const {userData} = this.props.user;
    const {type} = this.state;

    this.props.fetchingData(true);

    if (type == 'general') {
      this.props.togglePostpone();
    } else if (type == 'course') {
      this.props.toggleCoursePostpone();
    }

    this.props.logActivity({activityId: 326, comment: userData.postpone == "0" ? "1" : "0"});
  };

  render() {
    const {fetchingData} = this.props.activity;
    const {monthData} = this.props.calendar;
    const {courseMonthData} = this.props.course;
    const {tablet, back, type} = this.state;
    const {fontSizeRation, locale} = this.props.settings;
    const {translate} = this.props;
    const {userData} = this.props.user;

    let data = [];

    if (type == 'general') {
      data = monthData;
    } else if (type == 'course') {
      data = courseMonthData;
    }

    return (
      <div className="calendar">
        <Breadcrumb style={{marginTop: 50}}>
          {
            !back ? (
              <React.Fragment>
                <li className="breadcrumb-item">
                  <Link to={`/${locale}/home`}>
                    {translate('home')}
                  </Link>
                </li>
                <Breadcrumb.Item active>
                  {translate('calendar')}
                </Breadcrumb.Item>
              </React.Fragment>
            ) : (
              <li className="breadcrumb-item">
                <a href="#" onClick={(e)=>{
                  e.preventDefault();
                  this.props.history.goBack()
                }}>
                  {translate('cancel_back')}
                </a>
              </li>
            )
          }

        </Breadcrumb>

        <Container style={{marginTop: 20}} className="pageWrapper">
          <div className="d-flex justify-content-center flex-column m-auto text-center" style={{maxWidth: 700}}>
            <p
              className="description"
              style={{fontSize: fontSizeRation * (16),marginTop: !tablet ? 10 : 20, marginBottom: !tablet ? 10 : 20}}
            >
              {type == 'general' ? translate('repetitions_calendar_description') : null}
              {type == 'course' ? translate('repetitions_course_calendar_description') : null}
            </p>

            {
              userData && (
                <a href="#"
                   className="postponeButton"
                   style={{height: !tablet ? 50 : 70}}
                   onClick={this._togglePostpone}
                >
                  {type == 'general' && translate(userData.postpone != "1" ? "course_auto_postpone_on" : "course_auto_postpone_off") || null}
                  {type == 'course' && translate(userData.course_postpone != "1" ? "course_auto_postpone_on" : "course_auto_postpone_off") || null}
                </a>
              ) || null
            }
          </div>

          {
            true && (
              <Calendar
                views={['month']}
                onSelectSlot={(e)=> {
                // console.log("onSelectSlot", e)
                }}
                selectable={false}
                onNavigate={(e)=>{
                  let data = moment(e);

                  let period = {};

                  period.year = data.format("yyyy");
                  period.month = data.format("MM");
                  this.monthChanged(period)
                }}
                onSelectEvent={(e)=>{
                  this.toList(e)
                }}
                onDrillDown={(e)=>{
                  // this.showDayData(e)
                }}
                startAccessor="start"
                endAccessor="end"
                culture='ru-Ru'
                step={30}
                showMultiDayTimes={true}
                timeslots={7}
                formats={{
                    monthHeaderFormat: "MMMM yyyy"
                }}
                events={data}
                localizer={localizer}
                eventPropGetter={event => {
                   return { style: { backgroundColor: event.color}};
               }}
                style={{ height: 600 }}
                defaultDate={moment().toDate()}

              />
            )
          }
        </Container>

        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  calendar: state.calendar,
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize),
  course: state.course,

});

function bindAction(dispatch) {
  return {
    getCalendarDayData: (date)=> dispatch(getCalendarDayData(date)),
    getCalendarMonthData: (date)=> dispatch(getCalendarMonthData(date)),
    setCalendarMonthData: (data)=> dispatch(setCalendarMonthData(data)),
    logActivity: (data) => dispatch(logActivity(data)),
    setCurrentList: (list) => dispatch(setCurrentList(list)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    togglePostpone: () => dispatch(togglePostpone()),

    setCourseList: (list) => dispatch(setCourseList(list)),
    getCourseCalendarMonthData: (date)=> dispatch(getCourseCalendarMonthData(date)),
    setCourseCalendarMonthData: (data)=> dispatch(setCourseCalendarMonthData(data)),
    toggleCoursePostpone: () => dispatch(toggleCoursePostpone()),
  };
}

export default connect(mapStateToProps, bindAction)(RepetitionCalendar);
