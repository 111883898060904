import React, {Component} from 'react';
import Image from 'react-bootstrap/Image';

import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';

import colors from '../../../lib/colors';

class Badge extends Component {

  render() {
    const {ratio, onItemPressed, badge, tablet, translate} = this.props;

    let badgeImage = require('../../../assets/badges/unknown-badge.png');

    if (badge.progress && badge.progress >= 1) {
      if (badge.code == 'profile') {
        badgeImage = require('../../../assets/badges/known-badge.png');
      } else if (badge.code == 'vocabulary_all_1000' || badge.code == 'vocabulary_100') {
        badgeImage = require('../../../assets/badges/vocabulary-easy-1000.png');
      } else if (badge.code == 'vocabulary_all_3000' || badge.code == 'vocabulary_500') {
        badgeImage = require('../../../assets/badges/vocabulary-easy-3000.png');
      } else if (badge.code == 'vocabulary_all_5000' || badge.code == 'vocabulary_1000') {
        badgeImage = require('../../../assets/badges/vocabulary-easy-5000.png');
      } else if (badge.code == 'scores_1000') {
        badgeImage = require('../../../assets/badges/scores_1000.png');
      } else if (badge.code == 'scores_10000') {
        badgeImage = require('../../../assets/badges/scores_10000.png');
      } else if (badge.code == 'scores_100000') {
        badgeImage = require('../../../assets/badges/scores_100000.png');
      } else if (badge.code == 'scores_100000') {
        badgeImage = require('../../../assets/badges/scores_100000.png');
      } else if (badge.code == 'step_5') {
        badgeImage = require('../../../assets/badges/step_5.png');
      } else if (badge.code == 'step_25') {
        badgeImage = require('../../../assets/badges/step_25.png');
      } else if (badge.code == 'step_50') {
        badgeImage = require('../../../assets/badges/step_50.png');
      } else if (badge.code == 'time_3600') {
        badgeImage = require('../../../assets/badges/time_3600.png');
      } else if (badge.code == 'time_28800') {
        badgeImage = require('../../../assets/badges/time_28800.png');
      } else if (badge.code == 'time_86400') {
        badgeImage = require('../../../assets/badges/time_86400.png');
      } else if (badge.code == 'trainings_100') {
        badgeImage = require('../../../assets/badges/trainings_100.png');
      } else if (badge.code == 'trainings_500') {
        badgeImage = require('../../../assets/badges/trainings_500.png');
      } else if (badge.code == 'trainings_1000') {
        badgeImage = require('../../../assets/badges/trainings_1000.png');
      } else if (badge.code == 'test_100_10') {
        badgeImage = require('../../../assets/badges/test_100_10.png');
      } else if (badge.code == 'test_100_100') {
        badgeImage = require('../../../assets/badges/test_100_100.png');
      } else if (badge.code == 'test_100_300') {
        badgeImage = require('../../../assets/badges/test_100_300.png');
      } else if (badge.code == 'non_stop_5') {
        badgeImage = require('../../../assets/badges/non_stop_5.png');
      } else if (badge.code == 'non_stop_10') {
        badgeImage = require('../../../assets/badges/non_stop_10.png');
      } else if (badge.code == 'non_stop_30') {
        badgeImage = require('../../../assets/badges/non_stop_30.png');
      } else if (badge.code == 'purchase_month') {
        badgeImage = require('../../../assets/badges/purchase_month.png');
      } else if (badge.code == 'purchase_half_year') {
        badgeImage = require('../../../assets/badges/non_stop_30.png');
      } else if (badge.code == 'purchase_year') {
        badgeImage = require('../../../assets/badges/purchase_year.png');
      } else if (badge.code == 'purchase_list_cards') {
        badgeImage = require('../../../assets/badges/purchase_list_cards.png');
      } else if (badge.code == 'purchase_glossary_cards') {
        badgeImage = require('../../../assets/badges/purchase_glossary_cards.png');
      } else if (badge.code == 'all_purchases') {
        badgeImage = require('../../../assets/badges/all_purchases.png');
      } else if (badge.code == 'activity_rate' || badge.code == 'activity_course_rating') {
        badgeImage = require('../../../assets/badges/activity_rate.png');
      } else if (badge.code == 'activity_instagram') {
        badgeImage = require('../../../assets/badges/activity_instagram.png');
      } else if (badge.code == 'activity_fb') {
        badgeImage = require('../../../assets/badges/activity_fb.png');
      } else if (badge.code == 'activity_shared') {
        badgeImage = require('../../../assets/badges/activity_share.png');
      }
    }

    return (
      <div
        className="userbadge p-1"
        onClick={(e) => onItemPressed(badge, badgeImage, e)}
      >
        <div className="badgeWrapper"
             style={{width: 100,height: 140}}>
          <CircularProgressbar
            value={(badge.progress * 100)}
            strokeWidth={5}
            styles={buildStyles({
            pathColor: (badge.progress == 1) ? colors.tealish : colors.waterBlue,
            pathTransitionDuration: 1
          })}
          />
          <div 
            className="background"
            style={{width: !tablet ? 90 : 130, top: 5,
            overflow:'hidden',
                   height: !tablet ? 90 : 130, borderRadius: !tablet ? 90/2 : 130/2,
                    backgroundColor: badge.progress >= 1 ? colors.white :colors.lightGrey}}
          >
            <Image
              className="imageBadge"
              style={{height: !tablet ? 90 : 130, width: !tablet ? 90 : 130, borderRadius: !tablet ? 90/2 : 130/2}}
              src={badgeImage}
            />
          </div>
           <span
             className="badgeText"
             style={{marginTop: 5, fontSize: ratio * (!tablet ? 12 : 14),
              color: badge.progress >= 1 ? colors.tealish : colors.greyishBrown}}
           >
            {translate(badge.name)}
          </span>
        </div>
      </div>
    );
  }
}

export default Badge;
