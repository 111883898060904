import React, {Component, PureComponent} from 'react';
import {connect} from "react-redux";
import classNames from 'classnames';
import {Link} from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faLightbulb,
  faCircle,
  faCheckCircle,
  faBars,
  faList,
  faVolumeUp,
  faInfoCircle,
  faStar
} from '@fortawesome/free-solid-svg-icons';

// import {faInfoCircle} from '@fortawesome/free-regular-svg-icons';



import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import * as constants from '../../../lib/constants';
import colors from '../../../lib/colors';

class GlossaryWord extends PureComponent {

  render() {
    const {
      word,
      audio,
      audioSource,
      inVocabulary,
      deviceWidth,
      inFavorite, inList,
      onPressItem,
      onPlay,
      onSelectedItem,
      imageUrl,
      translation,
      ratio, version,
      tablet,
      imageWidth,
      selected,
      showWordSettings,
      translate,
      favoriteWordToggle,
      vocabularyToggle,
      index,
      list
    } = this.props;

    const s3image = `${constants.S3MarkedImages}-${imageWidth}/${imageUrl}`;


    const wordName = word && word.word_name ? word.word_name : "";
    const transcription = word && word.word && word.word.transcription ? word.word.transcription : "";
    const widthMin = !tablet ? 20 : (deviceWidth > 600 ? 30 : 20);
    let height = !tablet ? (deviceWidth >= 360 ? 300 : 260) : (deviceWidth > 600 ? 400 : 450);

    if (tablet) {
      if (ratio > 1.7) {
        height += (20 * ratio);
      }
    } else {
      if (ratio > 1.25) {
        height += (20 * ratio);
      }
    }
    const cardStyle = classNames({
      'wordContainer': true,
      'selected': selected
    });

    const showSentence = false;
    const preview = false;

    return (
      <Card
        onClick={(e) => onSelectedItem(word, index)}
        className="glossaryWordCard"
      >
        {(!list) && <span className="selectButton">
              <FontAwesomeIcon
                // className="icon20"
                icon={selected ? faCheckCircle : faCircle}
                style={{color: selected ? colors.tealish : colors.lightGreyTwo}}
              />
            </span>}
        
        <div className="listWrapper" style={{width: 200,height}}>
          <div className="listContainer">
            <span
              className="word mt-0 mb-2"
              style={{fontSize: ratio * (!tablet ? 17 : 19)}}>
              {wordName}
            </span>

            <span
              className="transcription"
              style={{minHeight: 35,fontSize: ratio * (!tablet ? 12 : 14), marginBottom: 10}}>
              {transcription}
            </span>

            {
              imageUrl && (
                <Image
                  draggable="false"
                  onContextMenu={(e)=>e.preventDefault()}
                  style={{height: 100, width: 138}}
                  src={s3image}
                />
              ) || <div style={{height: 100, width: 138}}/>
            }

            <span
              className="wordTranslation"
              style={{fontSize: ratio * (!tablet ? 16 : 18), marginTop: 10, marginBottom: 10}}>
              {translation && translation.length < 30 ? translation : `${translation.substr(0, 30)}`}
            </span>

            <div className="d-flex flex-center">
              <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='top'
                overlay={<Tooltip>{translate(inVocabulary ? 'delete_from_vocabulary' : 'add_to_vocabulary')}</Tooltip>}
              >
              <a className="mr-4" href="#" onClick={(e)=> {
                e.preventDefault();
                e.stopPropagation();
                vocabularyToggle(word.id, index)
              }}>
                <FontAwesomeIcon icon={faLightbulb}
                                 className="icon20"
                                 style={{color: inVocabulary ? colors.pine : colors.lightGreyThree}}
                />
              </a>
                </OverlayTrigger>
              <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='top'
                overlay={<Tooltip>{translate(inFavorite ? 'delete_from_favorites' : 'add_to_favorites')}</Tooltip>}
              >
                <a className="mr-4" href="#" onClick={(e)=> {
                e.preventDefault();
                e.stopPropagation();
                favoriteWordToggle(word.id, index)
                }}>
                  <FontAwesomeIcon icon={faStar} className="icon20" style={{color: inFavorite ? colors.golden : colors.lightGreyThree}}
                  />
                </a>
              </OverlayTrigger>


              <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='top'
                              overlay={<Tooltip>{translate(inList ? 'word_in_your_list' : 'click_word_to_add_to_list')}</Tooltip>}
              >
                <FontAwesomeIcon icon={faList}
                                 className="icon20 mr-4"
                                 style={{color: inList ? colors.waterBlue : colors.lightGreyThree}}
                />
                </OverlayTrigger>
              
              <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='top'
                              overlay={<Tooltip>{translate('to_word_details')}</Tooltip>}
              >
                <Link to={{
                  pathname: `/dictionary/${wordName}`,
                  state: {word, back: true}
                 }}>
                  <FontAwesomeIcon icon={faInfoCircle}
                                   className="icon20"
                                   style={{color: colors.greyishBrown5}}
                  />
                </Link>
                </OverlayTrigger>
            </div>

            <div className="d-flex mt-2 flex-center soundWrapperWord">

              <a href="#"
                 className="pt-1 w-100 d-flex justify-content-center align-items-center"
                 onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  if(audio) {
                    onPlay(`${audioSource}${audio}`)
                  }
                }}
              >
                <FontAwesomeIcon icon={faVolumeUp}
                                 className="icon30"
                                 style={{color: colors.greyishBrown}}
                />
              </a>

            </div>

          </div>
        </div>
      </Card>
    );
  }
}

export default GlossaryWord;