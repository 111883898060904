import * as types from './types';

export const setFontSize = (size) => (dispatch) => {
    dispatch({ type: types.SET_FONT_SIZE, payload: size });
};

export const setDeviceVersion = (version) => (dispatch) => {
    dispatch({ type: types.SET_DEVICE_VERSION, payload: version });
};

export const setConnectionStatus = (status) => (dispatch) => {
    dispatch({ type: types.SET_CONNECTION_STATUS, payload: status });
};

export const setDimensions = (dimensions) => (dispatch) => {
    dispatch({ type: types.SET_WINDOW_DIMENSIONS, payload: dimensions });
};

export const setLocale = (locale) => (dispatch) => {
    dispatch({ type: types.SET_LOCALE, payload: locale });
};

