import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import throttle from 'lodash/throttle';
import rootReducer from 'reducers/index';
import { loadState, saveState } from './stateLoader';
import createSagaMiddleware from 'redux-saga';

import rootSaga from 'sagas/root';

const sagaMiddleware = createSagaMiddleware();


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  loadState(),
  composeEnhancers(applyMiddleware(thunk, sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);

store.subscribe(
  throttle(() => {
    saveState({
      expireTime: new Date().getTime() + 24 * 3600000,
      state: {
        userData: store.getState().userData,
        auth: store.getState().auth,
      },
    });
  }, 1000),
);

export default store;
