import * as types from '../actions/types';

const INITIAL_STATE = {
  accounts: [],
  needPassword: false,
  accountConnected: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_CONNECTED_ACCOUNTS:
      return Object.assign({}, state, {
        accounts: action.payload,
      });

    case types.NEED_PASSWORD_TO_CONFIRM:
      return Object.assign({}, state, {
        needPassword: action.payload,
      }); 
    
    case types.ACCOUNT_CONNECTED:
      return Object.assign({}, state, {
        accountConnected: action.payload,
      });

    default:
      return state;
  }
}