import * as types from '../actions/types';

const INITIAL_STATE = {
  news: [],
  alertNews: [],
  unreadNews: 0,
  unreadFetch: false,
  currentArticle: {},
  fetched: false,
  fetchedAlertNews: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_USER_NEWS:
      return Object.assign({}, state, {
        news: action.payload,
        fetched: true
      });
    
    case types.SET_ALERT_NEWS:
      return Object.assign({}, state, {
        alertNews: action.payload,
        fetchedAlertNews: true
      });
    
    case types.SET_UNREAD_NEWS:{
      return Object.assign({}, state, {
        unreadNews: action.payload,
        unreadFetch: true
      });}

    case types.SET_CURRENT_ARTICLE:
      return Object.assign({}, state, {
        currentArticle: action.payload
      });

    case types.TOGGLE_CURRENT_ARTICLE_VISIBILITY:{
      let currentArticle = state.currentArticle;

      currentArticle.seen = !currentArticle.seen;
      
      return Object.assign({}, state, {
        currentArticle
      });}
    
    default:
      return state;
  }
}