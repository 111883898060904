import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Translate, getTranslate} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGoogle, faFacebook} from '@fortawesome/free-brands-svg-icons'
import {toastr} from 'react-redux-toastr';


import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import {sendLink} from '../../../actions/authActions';

import * as helpers from '../../../lib/validators';

import Logo from "../../../assets/images/logo-boy-prepared.png";
import Loader from "../../../components/Custom/Loader/index";

class Forgot extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
    };


  }

  componentDidMount(){}

  componentWillUnmount() {}

  sendEmailLink = () => {
    const {translate} = this.props;

    this.setState({
      userInfo: false
    });

    if (this.state.email.length == 0) {
      toastr.error('',translate('empty_email'))
    } else if (!helpers.checkEmail(this.state.email)) {
      toastr.error('',translate('valid_email'));
    } else {
      this.props.fetchingData(true);
      this.props.sendLink(this.state.email);
      this.props.logActivity({activityId: 9, comment: this.state.email});
      this.props.history.goBack();
    }
  };

  handleChange(field, value) {
    this.setState({[field]: value});
  }

  _handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.sendEmailLink();
    }
  };

  render() {
    const {fetchingData} = this.props.activity;
    const {fontSizeRation} = this.props.settings;
    const {translate} = this.props;

    return (
      <div className="auth-logo">
        <div className="d-flex flex-column justify-content-center align-items-center mb-3 logo-container">
          <span className="font-weight-bold" style={{fontSize: fontSizeRation * 40}}>Mnemonic Words</span>

          <Image src={Logo} className="logo mt-2"/>
        </div>

        <div className="form-signin register">
          <label htmlFor="inputEmail" className="sr-only">{translate('Email')}</label>
          <input
            type="email"
            id="inputEmail"
            value={this.state.email}
            className="form-control"
            placeholder={translate('Email')} required autoFocus
            onChange={(e)=>this.handleChange('email',e.target.value)}
            onKeyPress={(e)=>this._handleKeyPress(e)}
          />

          <Button
            style={{fontSize: fontSizeRation * 16}}
            className="gradient-blue mt-4"
            variant="primary" type="button" size="lg" block onClick={this.sendEmailLink}>
            <Translate id="send_reset_link"/>
          </Button>
        </div>

        <Loader fetchingData={fetchingData} />
      </div>
    );
  }
}


function bindAction(dispatch) {
  return {
    fetchingData: (status) => dispatch(fetchingData(status)),
    sendLink: (email) => dispatch(sendLink(email)),
    logActivity: (data) => dispatch(logActivity(data)),
  };
}

const mapStateToProps = state => ({
  user: state.user,
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize),
});

export default connect(
  mapStateToProps,
  bindAction,
)(withRouter(Forgot));
