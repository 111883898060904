import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logGamesLimits} from './purchasesSagas';
import {logApiError} from './logSagas';
import {getListsToRepeat} from './lists';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* getTestWord(data) {
  const params = data.payload;
  const {isConnected} = yield select(state => state.settings);
  if(!isConnected) return;

  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    // .post(API_URL + 'getTestWord')
    .post(API_URL + 'getWordTestWord')
    .send({
      user_id: userId,
      current_index: params.currentWordIndex,
      token: params.token,
      limit: 4
    });

    // console.log("getTestWord", result.body)
    
    yield put({
      type: types.SET_TEST_WORD,
      payload: result.body.word
    });

  } catch (error) {
    yield logApiError({payload: {function: 'getTestWord', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getTestResult(data) {
  const params = data.payload;
  const {isConnected} = yield select(state => state.settings);
  if(!isConnected) return;

  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'getTestResult')
    .send({
      user_id: userId,
      token: params,
      total_words: 25
    });

    // console.log("getTestResult", result.body)
    
    yield put({
      type: types.SET_TEST_RESULTS,
      payload: result.body
    });
  } catch (error) {
    yield logApiError({payload: {function: 'getTestResult', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* checkDictionaryWord(data) {
  const params = data.payload;

  const {isConnected} = yield select(state => state.settings);
  if(!isConnected) return;

  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'checkDictionaryWord')
    .send({
      user_id: userId,
      token: params.token,
      word: params.word
    });

    if(result.body.result) {
      yield put({
        type: types.ADD_EXTRACTOR_WORD,
        payload: {word: params.word.toLowerCase(), scores: result.body.scores}
      });
    }
  } catch (error) {
    yield logApiError({payload: {function: 'checkDictionaryWord', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }
}

export function* logWordExtractorResults(data) {
  const params = data.payload;

  const {isConnected} = yield select(state => state.settings);
  if(!isConnected) return;

  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'logWordExtractorResults')
    .send({
      user_id: userId,
      scores: params.scores,
      words: params.words
    });

    let message = translate('word_extractor_results_description');

    message = message.replace('%1', `${result.body.scores_position}`);
    message = message.replace('%2', `${result.body.words_position}`);
    message = message.replace('%3', `${params.scores}`);
    message = message.replace('%4', `${params.words}`);

    yield put({type: types.SET_WORD_EXTRACTOR_RESULTS});

    toastr.info(translate('word_extractor_results_title'), message);
  } catch (error) {
    yield logApiError({payload: {function: 'logWordExtractorResults', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }
}

export function* generateLists(data) {
  const params = data.payload;

  const {isConnected} = yield select(state => state.settings);
  if(!isConnected) return;

  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'generateLists')
    .send({
      user_id: userId,
      words: params.words,
      list_size: params.listSize,
      level: params.level,
      check_in_lists: params.checkInLists,
      glossary_id: params.glossaryId
    });

    if (result.statusCode == 200) {
      yield getListsToRepeat({
        payload: {
          userId,
          limit: 20
        }
      })
    } else {
      toastr.error('', translate('error'));
    }
  } catch (error) {
    yield logApiError({payload: {function: 'generateLists', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* logTestWord(data) {
  const params = data.payload;

  const {isConnected} = yield select(state => state.settings);
  if(!isConnected) return;

  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'logTestWord')
    .send({
      user_id: userId,
      word_id: params.wordId,
      token: params.token,
      level: params.level,
      result: params.result
    });

  } catch (error) {
    yield logApiError({payload: {function: 'logTestWord', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }
}
export function* createListsFromTests(data) {
  const params = data.payload;
  const translate = yield select(state => getTranslate(state.localize));

  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'createListsFromTests')
    .send({
      user_id: userId,
      selected_words: params,
    });

    toastr.info('', translate('words_were_saved'));
  } catch (error) {
    yield logApiError({payload: {function: 'createListsFromTests', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }
}

export function* getHangmanScores() {
  const {userId} = yield select(state => state.user);

  const {isConnected} = yield select(state => state.settings);
  if(!isConnected) return;

  try {
    const result = yield request
    .post(API_URL + 'getHangmanScores')
    .send({
      user_id: userId,
    });

    if(result.statusCode == 200) {
      yield put({
        type: types.SET_HANGMAN_SCORES,
        payload: result.body.scores
      });
    }
  } catch (error) {
    yield logApiError({payload: {function: 'getHangmanScores', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }
}

export function* getHangmanWord() {
  const {userId} = yield select(state => state.user);

  const {isConnected} = yield select(state => state.settings);
  if(!isConnected) return;

  try {
    const result = yield request
    .post(API_URL + 'getHangmanWord')
    .send({
      user_id: userId,
    });

    if(result.statusCode == 200) {
      yield put({
        type: types.SET_HANGMAN_WORD,
        payload: result.body.word
      });
    }
  } catch (error) {
    yield logApiError({payload: {function: 'getHangmanWord', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* logHangmanWord(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  const params = data.payload;

  const {isConnected} = yield select(state => state.settings);
  if(!isConnected) return;
  
  try {
    const result = yield request
    .post(API_URL + 'logHangmanWord')
    .send({
      user_id: userId,
      word_id: params.word_id,
      result : params.result,
      level : params.level,
      scores: params.scores
    });

    if(params.result) {
      yield getHangmanScores();
    }
    if(result.body.statistics) {
      
      let message = translate('was_guessed_times');
      message = message.replace('%1', result.body.guessed);
      message = message.replace('%2', result.body.percentage);
      
      toastr.info('', message);
    }
  } catch (error) {
    yield logApiError({payload: {function: 'logHangmanWord', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}
