import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Translate, getTranslate} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGoogle, faFacebook} from '@fortawesome/free-brands-svg-icons'
import {toastr} from 'react-redux-toastr';


import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

import Logo from "../../../assets/images/logo-boy-prepared.png";


class UserNavbar extends Component {

  constructor(props) {
    super(props);


    console.log(window.location.pathname)
  }

  render() {
    const {translate} = this.props;

    return (
      <Container style={{marginTop: 70}}>
        <Card className="p-3" style={{minHeight: '60vh'}}>
          <div className="d-flex flex-column justify-content-center align-items-center mb-3 logo-container">
            <p style={{fontSize: 60}}>404</p>
            <h4>{translate('page_404')}</h4>
            <p className="mt-2">
              {translate('try_old_site')}
            </p>
            <a href={`https://old.mnemonicwords.com${window.location.pathname}`} target="_blank">old.mnemonicwords.com</a>
          </div>
        </Card>
      </Container>
    )
  }
}

function bindAction(dispatch) {
  return {

  };
}

const mapStateToProps = state => ({
  user: state.user,
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize),
});

export default connect(
  mapStateToProps,
  bindAction,
)(withRouter(UserNavbar));
