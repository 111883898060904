import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import Loader from "../../../components/Custom/Loader/index";

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

import colors from '../../../lib/colors';

class GenerateLists extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      stage: 0,
      level: null,
      glossaryId: false,
      glossaries: [
        [
          {name:"all_randomly", id: 1497},
          {name:"from_nouns", id: 1499},
          {name:"from_verbs", id: 1498},
          {name:"from_adjectives", id: 1500}
        ],
        [
          {name:"all_randomly", id: 1504},
          {name:"from_nouns", id: 1505},
          {name:"from_verbs", id: 1507},
          {name:"from_adjectives", id: 1506}
        ],
        [
          {name:"all_randomly", id: 1508},
          {name:"from_nouns", id: 1509},
          {name:"from_verbs", id: 1511},
          {name:"from_adjectives", id: 1510}
        ],
        [
          {name:"all_randomly", id: 1637},
          {name:"from_nouns", id: 1647},
          {name:"from_verbs", id: 1646},
          {name:"from_adjectives", id: 1648},
        ],
      ]
    }
  }

  _changeStage = (stage)=> {
    this.setState({
      stage
    })
  };

  levelSelected = (level, e) => {
    e.preventDefault();
    this.setState({
      level
    });
    this._changeStage(1);
    this.props.logActivity({activityId: 168, comment: `${level}`});
  };

  setGlossary = (id, e) => {
    e.preventDefault();

    this.setState({
      glossaryId: id
    });

    this._changeStage(2);
  };

  render() {
    const {fetchingData} = this.props.activity;
    const {translate, onClose, isVisible, generateList} = this.props;
    const {userData} = this.props.user;
    const {fontSizeRation} = this.props.settings;
    const {stage, level, glossaries, glossaryId} = this.state;


    let listSize = userData && userData.list_size ? userData.list_size : 10;

    let textLevel = "";

    switch (level) {
      case 0:
        textLevel = "Beginner";
        break;
      case 1:
        textLevel = "Elementary";
        break;
      case 2:
        textLevel = "Intermediate";
        break;
      case 3:
        textLevel = 'Advanced';
        break;
      default:
        console.log("default")
    }

    let listsProp = translate('how_many_lists');

    listsProp = listsProp.replace('%@1', `${listSize}`);
    listsProp = listsProp.replace('%@2', textLevel);

    return (
      <Modal
        className="listsgenerator"
        show={isVisible}
        onHide={()=>onClose('generatorModalVisible',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h4">
            <Translate id={stage === 0 ? 'select_level' : 'select_words'}/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {
            stage === 0 && (
              <div className="selectlevel contentWrapper">
                <div className="levelsWrapper">
                  <div className="levelsWrapperRow" style={{maxHeight: 300, marginBottom: 20}}>
                    <a href="#"
                       onClick={(e)=>this.levelSelected(0, e)}>
                      <Card
                        className="levelItem"
                        style={{marginRight: 20, borderColor: level === 0 ? colors.tealish : colors.lightGrey}}
                      >
                        <Image
                          className="itemImage1"
                          src={require("../../../assets/images/test-beginner.png")}
                        />
                        <span className="levelText">{translate('level_basic_w')}</span>
                        <span className="levelNumbers">{translate('total_words')}: 1000</span>
                      </Card>
                    </a>
                    <a href="#" onClick={(e)=>this.levelSelected(1, e)}>
                      <Card
                        className="levelItem"
                        style={{borderColor: level === 1 ? colors.tealish : colors.lightGrey}}
                      >
                        <Image
                          className="itemImage"
                          src={require("../../../assets/images/test-elementary.png")}
                        />
                        <span className="levelText">{translate('level_elementary_w')}</span>
                        <span className="levelNumbers">{translate('total_words')}: 1782</span>
                      </Card>
                    </a>
                  </div>

                  <div className="levelsWrapperRow" style={{maxHeight: 300}}>

                    <a href="#" onClick={(e)=>this.levelSelected(2, e)}>
                      <Card
                        className="levelItem"
                        style={{marginRight: 20, borderColor: level === 1 ? colors.tealish : colors.lightGrey}}
                      >
                        <Image
                          className="itemImage"
                          src={require("../../../assets/images/test-intermediate.png")}
                        />
                        <span className="levelText">{translate('level_intermediate_w')}</span>
                        <span className="levelNumbers">{translate('total_words')}: 2490</span>
                      </Card>
                    </a>
                    <a hre="#" onClick={(e)=>this.levelSelected(3, e)}>
                      <Card
                        className="levelItem"
                        style={{borderColor: level === 1 ? colors.tealish : colors.lightGrey}}
                      >
                        <Image
                          className="itemImage"
                          src={require("../../../assets/images/test-advanced.png")}
                        />
                        <span className="levelText">{translate('level_advanced_w')}</span>
                        <span className="levelNumbers">{translate('total_words')}: 6593</span>
                      </Card>
                    </a>
                  </div>
                </div>
              </div>
            )
          }

          {
            stage === 1 && (
              <div className="selectwords contentWrapper">
                <p className="howManyLists" style={{fontSize: fontSizeRation * 18}}>
                  {translate('from_what_glossary')}
                </p>

                <div className="choiceWrapper">
                  {
                    glossaries[level].map((glossary, i) => {
                      return (
                        <a href="#"
                           key={i}
                           onClick={(e) => this.setGlossary(glossary.id, e)}>
                          <div className="choiceButtonItem wider">
                            <span className="choiceButtonItemText" style={{fontSize: fontSizeRation * 16}}>
                              {translate(glossary.name)}
                            </span>
                          </div>
                        </a>
                      )
                    })
                  }
                </div>
              </div>
            )
          }
          
          {
            stage === 2 && (
              <div className="selectwords contentWrapper">
                <p className="howManyLists" style={{fontSize: fontSizeRation * 18}}>
                  {listsProp}
                </p>

                <p className="totalWordsRemark" style={{fontSize: fontSizeRation * 14}}>
                  {translate('random_words_generate')}
                </p>

                <div className="choiceWrapper">
                  {
                    [1,2,3,4,5].map(list => {
                      return (
                        <a href="#"
                          key={list}
                          onClick={(e) => generateList(level, glossaryId, list, e)}>
                          <div className="choiceButtonItem">
                            <span className="choiceButtonItemText" style={{fontSize: fontSizeRation * 16}}>
                              {list}
                            </span>
                          </div>
                        </a>
                      )
                    })
                  }
                </div>
              </div>
            )
          }
          

        </Modal.Body>
        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
  };
}

export default connect(mapStateToProps, bindAction)(GenerateLists);
