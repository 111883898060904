import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faCircle} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import * as helpers from '../../../lib/validators';

class AddAssociationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      association: ""
    };

    this.inputRef = React.createRef();
  }

  setText = (association) => {
    this.setState({
      association: helpers.sanitizeString(association, 75)
    })
  };
  
  render() {
    const {
      translate,
      ratio,
      isVisible,
      onClose,
      onSubmit
    } = this.props;
    const {association} = this.state;

    return (
      <Modal
        onShow={() => this.inputRef.current.focus()}
        className="associationmodal"
        show={isVisible}
        onHide={()=>onClose(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="add_word_association"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">
          <div className="position-relative">
            <InputGroup className="ph1 mt-2">
              <FormControl
                as="textarea"
                rows="5"
                ref={this.inputRef}
                placeholder={translate('enter_association')}
                style={{fontSize: 12}}
                value={association}
                onChange={(e)=> this.setText(e.target.value)}
              />
              <InputGroup.Append>
                <Button
                  style={{fontSize: 12}}
                  disabled={association.length == 0}
                  variant="outline-secondary"
                  onClick={()=>{
                    this.setText("");
                    this.inputRef.current.focus();
                  }}>
                  <FontAwesomeIcon icon={faTimes}/>
                </Button>
              </InputGroup.Append>
            </InputGroup>
            <p className="chars" style={{fontSize: ratio * 12}}>{association.length} / 75</p>

            <Button
              className="mt-3"
              block
              disabled={association.length == 0}
              variant="outline-secondary"
              onClick={(e)=>onSubmit(association, e)}>
              {translate('add')}
            </Button>

          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(null, null)(AddAssociationModal);
