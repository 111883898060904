import * as types from '../actions/types';

const INITIAL_STATE = {
  goal: 0,
  words: [],
  totalWords: 0,
  totalPages: 0,
  fetchingNextChunk: false,
  fetchedVocabularyWords: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_VOCABULARY_WORDS:
      return Object.assign({}, state, {
        fetchedVocabularyWords: true,
        words: action.payload.data,
        totalWords: action.payload.total_words,
        totalPages: action.payload.total_pages,
        fetchingNextChunk: false,
        goal: action.payload.goal
      });

    case types.GET_VOCABULARY_WORDS_NEXT_CHUNK:
      return Object.assign({}, state, {
        fetchingNextChunk: true,
      });
    
    case types.FOUND_VOCABULARY_WORDS: {
      return Object.assign({}, state, {
        words: action.payload.data,
        totalWords: action.payload.total_words,
      });
    }

    case types.RESET_VOCABULARY:
      return Object.assign({}, state, {
        fetchedVocabularyWords: false,
        words: [],
        totalWords: 0,
        totalPages: 0,
        fetchingNextChunk: false
      });

    case types.VOCABULARY_WORD_TOGGLED_IN_FAVORITES: {
      return Object.assign({}, state, {
        words: state.words.map((item, i) => {
          if(i === action.payload) {
            item.is_favourite = !item.is_favourite
          }

          return item;
        })
      });
    }

    case types.VOCABULARY_WORDS_TOGGLED_IN_FAVORITES: {
      return Object.assign({}, state, {
        words: state.words.map((item, i) => {
          if(item.word_id === action.payload) {
            item.is_favourite = !item.is_favourite
          }

          return item;
        })
      });
    }

    case types.VOCABULARY_WORD_DELETED: {
      return Object.assign({}, state, {
        words: state.words.filter((item, i) => i !== action.payload),
        totalWords: state.totalWords - 1
      });
    }
      
      
    default:
      return state;
  }
}