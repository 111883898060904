import React, {Component} from 'react';
import {connect} from "react-redux";

import {Translate, getTranslate} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons';

import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import ListCardCell from '../../../../components/Cells/ListCardCell/index'
import Mistakes from "../../../../assets/images/home/mistakes.jpg";

import colors from '../../../../lib/colors';

class CourseMistakes extends Component {

  render() {
    const {cachedCourseData} = this.props.courseCache;
    const {translate, onRepeatMistakes} = this.props;
    const {fontSizeRation} = this.props.settings;

    return (
      cachedCourseData.map((course, index) => {
        let image = false;
        if (course.slug == 1000) {
          image = require("../../../../assets/images/sitting-boy.png")
        } else if (course.slug == 2000) {
          image = require("../../../../assets/images/level-elementary.png")
        }
        return course && course.mistakes && course.mistakes.words > 0 ? (
          <Card
            key={index}
            className="mistakesWrapper ml-2 mr-2"

            style={{width: 200,height: 280}}
          >
            <p
              className="mb-0 listName font-weight-bold"
              style={{fontSize: fontSizeRation * 14, whiteSpace: 'pre-line'}}>
              {translate(course.slug == 1000 ? 'with_mistakes_beginner' : 'with_mistakes_elementary')}
            </p>

            <Image className="mistakesImage" src={image} />

            <div className="flexH mb-3">
              <span
                className="scores"
                style={{fontSize: fontSizeRation * 14}}
              >
                {translate('wordss')}:
              </span>
              <span
                className="scoresNum"
                style={{fontSize: fontSizeRation * 14}}
              >
                {course.mistakes.words}
              </span>
            </div>

            <Button
              variant="outline-success"
              onClick={(e) => {onRepeatMistakes(course.mistakes, course, e)}}
            >
              {translate('correct_mistakes')}
            </Button>
          </Card>
        ) : null;
      })

    )
  }
}


const mapStateToProps = (state, dispatch) => ({
  activity: state.activity,
  trainings: state.trainings,
  settings: state.settings,
  purchases: state.purchases,
  user: state.user,
  course: state.course,

  courseCache: state.courseCache,
  translate: getTranslate(state.localize),

});


export default connect(mapStateToProps, null)(CourseMistakes);
