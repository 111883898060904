import React, {Component} from 'react';
import {connect} from "react-redux";
import {Translate, getTranslate} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltLeft, faCheck} from '@fortawesome/free-solid-svg-icons'
import {withRouter, Link} from 'react-router-dom';
import {toastr} from 'react-redux-toastr';


import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ProgressBar from 'react-bootstrap/ProgressBar';

import SubscriptionAlert from '../../../../components/Modals/SubscriptionAlert/index';
import WordInfo from '../../../../components/Modals/WordInfo/index';
// import BuyWordModal from '../BuyWordModal/index';
// import WordMakerWordSettings from '../WordMakerWordSettings/index';
import Loader from "../../../../components/Custom/Loader/index";


import {
  getWordMakerParts, resetWordMakerParts, setFetchingMakerWord,
  logWordMakerPart, getWordMakerWords, logBuyWordMakerWord
} from '../../../../actions/wordMakerActions';
import {logActivity} from '../../../../actions/logActions';
import {get_word_details} from '../../../../actions/wordActions';
import {fetchingData} from '../../../../actions/activity';

import colors from '../../../../lib/colors';

class WordMakerWord extends Component {

  constructor(props) {
    super(props);

    this.state = {
      word: false,
      buyWordVisible: false,
      settingsVisible: false,
      wordInfoVisible: false,
      typedWord: "",
      totalWords: 0,
      guessedWords: 0,
      wordButtonsToHide: [],
      tablet: false
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this._handleKeyDown);

    this._getWordMakerParts();

  }

  componentDidUpdate(prevProps, prevState) {
    const {currentWord} = this.props.wordMaker;

    if (!currentWord.id) return;

    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._getWordMakerParts();
    }
  }

  componentWillUnmount() {
    const {isConnected} = this.props.settings;

    if (isConnected) {
      this.props.getWordMakerWords();
    }

    this.props.resetWordMakerParts();
    document.removeEventListener("keydown", this._handleKeyDown);
  }

  _handleKeyDown = (event) => {
    const {typedWord, letters} = this.state;
    const {currentSplitWord} = this.props.wordMaker;
    const {wordButtonsToHide} = this.state;

    switch (event.keyCode) {
      case 27:
        this.props.history.goBack();
        break;

      case 8:
        this.eraseLastChar();
        break;

      case 13:
        event.preventDefault();
        if(typedWord.length >= 3) {
          this.checkWord();
        }
        break;
      default:
        if(event.key && event.key.length !== 0 ) {
          let char = event.key.toLowerCase();
          let found = false;
          currentSplitWord.forEach((letter, index) => {
            if(letter.toLowerCase() == char && !found && wordButtonsToHide.indexOf(index) === -1) {
              this.shuffledWordButtonPressed(letter, index, event);
              found = true;
            }
          })
        }
        break;
    }
  };

  _getWordMakerParts = () => {
    const {isConnected} = this.props.settings;

    const {currentWord} = this.props.wordMaker;

    if (currentWord && currentWord.id) {
      if (isConnected) {
        this.props.fetchingData(true);
        this.props.getWordMakerParts(currentWord.id);
      }
    } else {
      this.props.history.goBack();
    }
  };


  renderWordLetters = () => {
    const {currentSplitWord} = this.props.wordMaker;

    if (!currentSplitWord) return;
    const {wordButtonsToHide, tablet} = this.state;
    const {deviceWidth, deviceHeight} = this.props.settings;

    return currentSplitWord.map((item, i) => {
      return (
        <a href="#"
           key={i}
           onClick={(e) => {
            e.preventDefault();
            if(wordButtonsToHide.indexOf(i) === -1) {
              this.shuffledWordButtonPressed(item,i)
            }
          }}
        >
          <div
            className="shuffledWordButton"
            style={{
            width: !tablet ? (deviceWidth < 360 ? 35 : 45) : (deviceHeight > 950 ? 70 : 60),
            height:  !tablet ? (deviceWidth < 360 ? 35 : 45) : (deviceHeight > 950 ? 70 : 60),
            borderRadius: 5,
            marginLeft: !tablet ? 2 : 5,
            marginRight: !tablet ? 2 : 5,
            marginBottom: !tablet ? 5 : 10,
            borderColor: wordButtonsToHide.indexOf(i) === -1 ? colors.greyishBrown : colors.greyishBrown2 }}>
            <span
              className="shuffledWordButtonText"
              style={{fontSize: !tablet ? (deviceWidth < 360 ? 16 : 18) : 24,
              color: wordButtonsToHide.indexOf(i) === -1 ? colors.greyishBrown : colors.greyishBrown2 }}>
              {item}
            </span>
          </div>
        </a>
      )
    });
  };

  renderAllTyped = () => {
    const {wordMakerWordParts} = this.props.wordMaker;
    const {tablet} = this.state;
    const {fontSizeRation} = this.props.settings;

    return wordMakerWordParts.map((item, i) => {
      let word = item;
      word.id = item.word_id;
      return (
        <a href="#"
           style={{padding: 5, marginVertical: !tablet ? 2 : 4, marginHorizontal: !tablet ? 5 : 7,}}
           key={i} onClick={(e)=>this.toWordDetails(word,e)}>
          <span style={{color: this.getColor(word.length),
           fontSize: fontSizeRation * (!tablet ? 14 : 16),
           textAlign: 'center', fontWeight: '300'}}>
            {item.word}
          </span>
        </a>
      )
    })
  };

  toWordDetails = (word, e) => {
    e.preventDefault();
    this.props.get_word_details(word.id);
    this._toggleWordInfo(true);
    this.props.logActivity({activityId: 45, model: 'word', modelId: word.id, comment: "word maker"});
    // this.props.navigation.navigate('WordDetails', {word})
  };

  shuffledWordButtonPressed = (char, i) => {
    const {wordButtonsToHide, typedWord} = this.state;

    this.setState({
      wordButtonsToHide: [...wordButtonsToHide, i],
      typedWord: `${typedWord}${char}`
    });
  };


  checkWord = () => {
    const {currentWord} = this.props.wordMaker;

    const {typedWord} = this.state;
    const {translate} = this.props;

    if (currentWord.word != typedWord) {
      this.props.fetchingData(true);
      this.props.logWordMakerPart(currentWord.id, typedWord)
    } else {
      toastr.warning('', translate('the_same_word'));
    }

    this.setState({
      wordButtonsToHide: [],
      typedWord: ''
    })
  };

  eraseLastChar = () => {
    const {wordButtonsToHide, typedWord} = this.state;
    let erased = typedWord.slice(0, -1);

    let popped = wordButtonsToHide.pop();

    this.setState({
      wordButtonsToHide: wordButtonsToHide,
      typedWord: erased
    })
  };

  getStatistics = () => {
    const {byLength} = this.props.wordMaker;
    const {tablet} = this.state;
    const {fontSizeRation} = this.props.settings;

    return byLength.map((item, i) => {
      return (
        <div key={i} style={{marginRight: !tablet ? 10 : 15}}>
          <span
            style={{fontSize: fontSizeRation * (!tablet ? 12 : 14),
            color: item.guessed == item.total ? colors.tealish : colors.greyishBrown, fontWeight: '700', marginRight: 5}}>
            {item.length}: 
          </span>
          <span
            style={{fontWeight: '300', fontSize: fontSizeRation * (!tablet ? 12 : 14),
             color: item.guessed == item.total ? colors.tealish : colors.greyishBrown}}
          >
            {item.guessed}/{item.total}
          </span>
        </div>
      )
    });
  };

  getColor = (length) => {
    let color = colors.greyishBrown;

    switch (length) {
      case 3:
        color = colors.pine;
        break;
      case 4:
        color = colors.waterBlue;
        break;
      case 5:
        color = colors.greyishBrown;
        break;
      case 6:
        color = colors.tealish;
        break;
      case 7:
        color = colors.headerBlue;
        break;
      case 8:
        color = colors.coral;
        break;
      case 9:
        color = colors.dustyOrange;
        break;
      case 10:
        color = colors.golden;
        break;

    }

    return color;
  };

  toggleBuyWord = (status) => {
    this.setState({
      buyWordVisible: status
    })
  };

  openBuyModal = (e) => {
    e.preventDefault();
    let {lifetimeStatus} = this.props.purchases;
    const {freeHints} = this.props.wordMaker;
    this.toggleSettings(false);

    if (!lifetimeStatus && freeHints == 0) {
      this.toggleBuyWord(true);
      // this._buyWord();
      this.props.logActivity({activityId: 281});
    } else {
      this.props.logBuyWordMakerWord(freeHints > 0);
      this.props.logActivity({activityId: 293});
    }
  };

  toggleSettings = (status) => {
    this.setState({
      settingsVisible: status
    })
  };

  _buyWord = () => {
    const {currentWord} = this.props.wordMaker;
    this.props.logActivity({activityId: 285, model: 'game', modelId: currentWord.id});

    this.toggleBuyWord(false);
    this.requestPurchase();
    // this.props.logBuyWordMakerWord();
  };

  getLocalizedPrice = ()=> {
    const {products} = this.props.purchases;

    let price = '0.00';
    products && products.forEach(sub => {
      if (sub.productId == 'word_maker.10_hints') {
        price = sub.localizedPrice;
      }
    });

    return price;
  };

  _reportTrainingBug = () => {
    const {currentWord} = this.props.wordMaker;
    this.toggleSettings(false);

    this.props.navigation.navigate('ReportBug', {type: 'training', training: "Word Maker", extra: currentWord});

    this.props.logActivity({activityId: 234, comment: "Word Maker"});
  };

  _toggleWordInfo = (status) => {
    this.setState({
      wordInfoVisible: status
    })
  };
  
  render() {
    const {fetchingData} = this.props.activity;
    const {fontSizeRation, version, isConnected, deviceWidth, deviceHeight} = this.props.settings;
    const {lifetimeStatus} = this.props.purchases;
    const {translate} = this.props;
    const {
      fetched, fetching, totalScores, wordMakerWordParts,
      currentWord, currentSplitWord, levelScore,
      totalGuessed, totalWords,
      freeHints
    } = this.props.wordMaker;
    const {tablet, typedWord, buyWordVisible, settingsVisible,
      wordInfoVisible} = this.state;

    let shuffledWordButtons = this.renderWordLetters();
    let allTyped = this.renderAllTyped();
    let statistics = this.getStatistics();
    let price = this.getLocalizedPrice();

    let progress = totalWords != 0 ? (totalGuessed / totalWords) : 0;

    return (
      <div className="wordmakerword">
        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to="/home">
              {translate('home')}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/wordmaker">
              {translate('word_maker')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {currentWord ? currentWord.word : ''}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container style={{marginTop: 20}} className="pageWrapper">
          {/*<ScreenHeader headerImageId={1}
           translateTitle={false}
           toolbarHidden={!(totalWords != 0 && totalWords > totalGuessed) || !isConnected}
           width={(!tablet ? 90 : 120)}
           subtitle={fetched ? `${translate('scoresUC')}: ${levelScore}` : ''}
           title={"Word Maker"}
           goBack={()=> this.props.navigation.goBack()}
           >

           <TouchableOpacity
           style={!tablet ? headers.toolbarButtonWrapper : headers.toolbarButtonWrapperTablet}
           onClick={() => {
           this.props.logActivity({activityId: 286});
           this.toggleSettings(true)
           }}>
           <FontAwesome
           style={[{color: colors.greyishBrown}, !tablet ? headers.toolbarButton : headers.toolbarButtonTablet]}
           icon={LightIcons.cogs}
           pro={true}/>
           </TouchableOpacity>

           </ScreenHeader>*/}

          <div style={{flex: 1, justifyContent: 'space-between', alignItems: 'center', }}>

            {
              (totalWords != 0 && totalWords > totalGuessed) && (
                <div >
                  <a href="#" className="mv3 d-flex flex-column" onClick={this.openBuyModal}>
                  <span
                    className="fontBold textCenter d-block mb-1 text-secondary"
                    style={{fontWeight: '700',fontSize: fontSizeRation * (!tablet ? 14 : 16)}}>
                    {(lifetimeStatus || freeHints > 0) ? translate('word_maker_open_word') : translate('buy_free_hints')}
                  </span>
                    {
                      !lifetimeStatus && (
                        <span
                          className="textCenter d-block"
                          style={{marginTop: 0, fontSize: fontSizeRation * (!tablet ? 12 : 14), fontWeight: '400',
                   color: freeHints > 0 ? colors.tealish : colors.coral}}
                        >
                        {freeHints > 0 ? `${translate('left_free_hints')} ${freeHints}` : translate('no_free_hints')}
                      </span>
                      )
                    }
                  </a>
                </div>
              )
            }

            <div className="barWrapper" style={{ marginTop: !tablet ? 0 : 10}}>
              <ProgressBar
                style={{width: '100%'}}
                variant="success"
                now={progress*100}
              />
            </div>

            <div
              className="w100 flexStart flexVertical"
              style={{marginVertical: !tablet ? 10 : 20}}>
            <span className="textCenter currentWord" style={{fontSize: fontSizeRation * (!tablet ? 30 : 40)}}>
              {currentWord.word}
            </span>

              <div className="flexH flexVertical" style={{ width: '100%'}}>
                {false && <span className="textCenter" style={{fontSize: fontSizeRation * (!tablet ? 14 : 16)}}>
                {translate('by_word_length')}
              </span>}

                <div className="textCenter flexCenter flexHorizontal" style={{flexWrap: 'wrap', width: '100%'}}>
                  {statistics}
                </div>
              </div>

            <span
              className="textCenter typedWord"
              style={{minHeight: !tablet ? 60 : 90, marginTop: !tablet ? 20 : 30, 
              fontSize: fontSizeRation * (!tablet ? 40 : 50)}}>
              {typedWord}
            </span>
            </div>

            <div style={{flex: 1, width: '100%'}}>
              <div style={{flex: 1}}>
                <div className="allTypedWords">
                  {allTyped}
                </div>
              </div>
            </div>

            {
              totalWords != 0 && totalWords > totalGuessed && (
                <div className="flexVertical flexCenter">
                  <div className="shuffledWordButtonsWrapper" style={{marginTop: 15, marginBottom: 15}}>
                    {shuffledWordButtons}
                  </div>

                  <div className="flexHorizontal flexCenter mv2">

                    <ButtonGroup style={{width: 200}} aria-label="Control buttons">
                      <Button
                        style={{borderLeftTopRadius: 25, borderLeftBottomRadius: 25}}
                        disabled={typedWord.length === 0}
                        onClick={() => typedWord.length > 0 ? this.eraseLastChar() : null}
                        variant="outline-info">
                        <FontAwesomeIcon
                          icon={faLongArrowAltLeft}
                          style={{fontSize: !tablet ? 25 : 35}}/>
                      </Button>

                      <Button
                        disabled={typedWord.length < 3}
                        onClick={() => typedWord.length >= 3 ? this.checkWord() : null}
                        variant="outline-info">
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{fontSize: !tablet ? 25 : 35}}/>
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              )
            }

            {
              totalWords != 0 && totalWords == totalGuessed && (
                <div className="flexVertical flexCenter mb3">
                  <span
                    style={{color: colors.tealish, fontWeight: '700', fontSize: fontSizeRation * (!tablet ? 18 : 22)}}>
                    {translate('congratulations')}
                  </span>
                  <span style={{fontSize: fontSizeRation * (!tablet ? 16 : 20)}}>
                    {translate('you_guessed_all_words')}
                  </span>
                </div>
              )
            }

          </div>

          {
            /*buyWordVisible && (
             <BuyWordModal
             tablet={tablet}
             isVisible={buyWordVisible}
             onClose={this.toggleBuyWord}
             buyWord={this._buyWord}
             lifetimeStatus={lifetimeStatus}
             ratio={fontSizeRation}
             title={'word_maker_title'}
             text={'word_maker_description'}
             price={price}
             />
             )*/
          }

          {
            /*settingsVisible && (
             <WordMakerWordSettings
             tablet={tablet}
             freeHints={freeHints}
             isVisible={settingsVisible}
             onClose={this.toggleSettings}
             openBuyWord={this.openBuyModal}
             lifetimeStatus={lifetimeStatus}
             ratio={fontSizeRation}
             reportBug={this._reportTrainingBug}
             />
             )*/
          }
          {wordInfoVisible && (<WordInfo onClose={this._toggleWordInfo} isVisible={wordInfoVisible}/>)}
          <Loader fetchingData={fetchingData}/>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  activity: state.activity,
  settings: state.settings,
  purchases: state.purchases,
  wordMaker: state.wordMaker,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    fetchingData: (status) => dispatch(fetchingData(status)),
    getWordMakerParts: (wordId) => dispatch(getWordMakerParts(wordId)),
    get_word_details: (wordId) => dispatch(get_word_details(wordId)),
    logWordMakerPart: (wordId, word) => dispatch(logWordMakerPart(wordId, word)),
    resetWordMakerParts: () => dispatch(resetWordMakerParts()),
    getWordMakerWords: () => dispatch(getWordMakerWords()),
    setFetchingMakerWord: () => dispatch(setFetchingMakerWord()),
    logBuyWordMakerWord: (status) => dispatch(logBuyWordMakerWord(status)),

    logActivity: (data) => dispatch(logActivity(data)),
  };
}

export default connect(mapStateToProps, bindAction)(withRouter(WordMakerWord));
