import * as types from './types';

export const getGroupedPhrasebooks = () => (dispatch) => {
  dispatch({ type: types.GET_PHRASEBOOKS });
};

export const getPhrases = (id) => (dispatch) => {
  dispatch({ type: types.GET_PHRASEBOOK_PHRASES, payload: id });
};

export const resetPhrases = () => (dispatch) => {
  dispatch({ type: types.RESET_PHRASES});
};
export const resetFoundPhrases = () => (dispatch) => {
  dispatch({ type: types.RESET_FOUND_PHRASES});
};

export const setCurrentPhrasebook = (phrasebook) => (dispatch) => {
  dispatch({ type: types.SET_CURRENT_PHRASEBOOK, payload: phrasebook });
};

export const searchPhrases = (phrase, byTranslation) => (dispatch) => {
  dispatch({ type: types.SEARCH_PHRASE, payload: {phrase, byTranslation} });
};

export const toggleFavoritePhrase = (phraseId) => (dispatch) => {
  dispatch({ type: types.TOGGLE_FAVORITE_PHRASE, payload: {phraseId} });
};
