import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {

  faGooglePlay,
  faAppStore,
 } from '@fortawesome/free-brands-svg-icons'

import {logActivity} from '../../../actions/logActions';


class SubscriptionAlert extends Component {

  constructor(props) {
    super(props);
    const userId = localStorage.getItem('userId');

    this.state = {
      tablet: false,
      products: [
        {
          id: 'monthly_subscription',
          orderId: `${moment().unix()}-monthly_subscription-${userId}`,
          price: '1.49',
          discountPrice: '0.99',
          currency: 'USD',
          name: 'monthly_subscription_title',
          description: 'subscription_description_short',
          buttonText: 'buy_for_price',
          buttonText2: 'monthly_subscription_no_price',
          info: 'subscription_description',
          type: 'subscribe',
          periodicity: "month"
        },

        {
          id: 'yearly_subscription',
          orderId: `${moment().unix()}-yearly_subscription-${userId}`,
          price: '14.99',
          discountPrice: '9.99',
          currency: 'USD',
          name: 'yearly_subscription_title',
          description: 'subscription_description_short',
          buttonText: 'buy_for_price',
          buttonText2: 'yearly_subscription_no_price',
          info: 'subscription_description',
          type: 'subscribe',
          periodicity: "year",
        },
        {
          id: 'lifetime_access',
          orderId: `${moment().unix()}-lifetime_access-${userId}`,
          price: '39.99',
          discountPrice: '35.99',
          currency: 'USD',
          name: 'lifetime_access',
          description: 'lifetime_access_description',
          buttonText: 'buy_for_price',
          type: 'buy',
          info: 'lifetime_subscription_terms'
        },
      ]
    };
  }
  
  render() {
    const {isVisible, toSubscription, onClose, title, description, deviceWidth, translate} = this.props;
    const {fontSizeRation} = this.props.settings;
    const {tablet, products} = this.state;
    
    const {happyDay, happyEndDay, graceStatus, graceDaysLeft} = this.props.purchases;

    let promo = false;
    let promoDays = 0;
    let daysName = 'days';

    if (happyDay || graceStatus) {
      promo = true;

      promoDays = graceDaysLeft > happyEndDay ? graceDaysLeft : happyEndDay;

      if (promoDays == 1) {
        daysName = 'days1'
      } else if (promoDays >= 2 && promoDays <= 4) {
        daysName = 'days2'
      }
    }

    return (
      <Modal
        className="subscriptionsModal"
        show={isVisible}
        onHide={()=>onClose(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="introText" as="h6">
            {translate(title ? title : 'select_training_to_repeat_words')}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="introWrapper">
            {
              description && (<p className="introDescription">
                {description && translate(description)}
              </p>) || null
            }

            {
              promo && (
                <h6 className="promoDays">
                  {promoDays >= 1 ? `${translate('happy_days_left')}${promoDays} ${translate(daysName)}` : `${translate('promo_days_left_zero')}`}
                </h6>
              ) || null
            }
          </div>

          <h6 style={{marginTop: 30, textAlign: 'center'}}>
            {translate('make_payment_via_app')}
          </h6>

          <div className="d-flex justify-content-center align-items-center mt-2">
            <a style={{marginRight: 50}} href="http://play.google.com/store/apps/details?id=com.mnemonicrn"
               target="_blank">
              <FontAwesomeIcon className="text-secondary" style={{fontSize: 50}} icon={faGooglePlay}/>
            </a>

            <a href="https://itunes.apple.com/app/id1438980777" target="_blank">
              <FontAwesomeIcon className="text-secondary" style={{fontSize: 50}} icon={faAppStore}/>
            </a>
          </div>

          {
            false && products.map((product, index) => {
              let price = product.price;

              if (graceStatus || happyDay) {
                price = product.discountPrice;
              }
              
              return (
                <Button
                  key={index}
                  block
                  className="mb-1"
                  variant={"outline-success"}
                  onClick={() =>toSubscription(product.id)}
                >
                  <span className="d-block">{translate(product.name)}</span>
                  <span className="d-block">{translate(product.buttonText)} {price}$ {product.id != 'lifetime_access' ? translate(product.buttonText2) : ''}</span>
                  {promo ? (
                    <div className="d-flex justify-content-center">
                      <span style={{textDecoration: 'line-through'}} className="text-muted">
                        {translate('no_discount')} {product.price}$ {product.id != 'lifetime_access' ? translate(product.buttonText2) : ''}
                      </span>
                    </div>
                  ) : null}
                </Button>
              )
            })
          }
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  purchases: state.purchases,
  settings: state.settings
});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
  };
}

export default connect(mapStateToProps, bindAction)(SubscriptionAlert);

