import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Loader = ({fetchingData, size = 'lg'}) => (
  fetchingData && <div className="custom-spinner">
    <Spinner animation="border" variant="info" role="status" size={size}>
      <span className="sr-only">Loading...</span>
    </Spinner></div> || null
);

export default Loader;