import * as types from './types';

export const getCalendarMonthData = (date) => (dispatch) => {
    dispatch({ type: types.GET_CALENDAR_MONTH_DATA, payload: date });
};

export const getCalendarDayData = (date) => (dispatch) => {
  dispatch({ type: types.GET_CALENDAR_DAY_DATA, payload: date });
};

export const setCalendarMonthData = (data) => (dispatch) => {
  dispatch({ type: types.SET_CALENDAR_MONTH_DATA, payload: data });
};



