import React, {Component} from 'react';
import {connect} from "react-redux";
import {Translate, getTranslate} from 'react-localize-redux';
import {Helmet} from "react-helmet";

import {Link} from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faPlusCircle, faTimes, faPlayCircle, faPauseCircle} from '@fortawesome/free-solid-svg-icons';

import {toastr} from 'react-redux-toastr';

import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import moment from 'moment';

import TrainingSubHeader from '../../../components/Headers/TrainingSubHeader/index';
import FinishTrainingButtons from '../../../components/Modals/FinishTrainingButtons/index';
import WordSettings from '../../../components/Modals/WordSettings/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
import ChangeAudioLanguage from '../../../components/Modals/ChangeAudioLanguage/index';
import ReportBug from '../../../components/Modals/ReportBug/index';
import TrainingSettings from '../../../components/Modals/TrainingSettings/index';
import WordInfo from '../../../components/Modals/WordInfo/index';
import RegisterAlert from '../../../components/Modals/RegisterAlert/index';

import AddWordModal from '../../../components/Modals/AddWordModal/index';
import ListsToFill from '../../../components/Modals/ListsToFill/index';
import Loader from "../../../components/Custom/Loader/index";

import {
  getWordsForTraining,
  resetWords,
  setListeningSettings,
  setCachedListeningWords,

  moveNewListToRepeat,
  removeListFromRepeatSection,
  updateListTrainings,
  updateTrainingsListFromRepeatSection,
  resetCurrentTrainingId,
  setCurrentTrainingId,
  shuffleTrainingWords,
  toggleShuffleWords,
  addUserExampleTranslation,
} from '../../../actions/trainingActions';
import {
  updateCachedListTrainings,
  updateCachedListTrainingsAndIntervals,
  shuffleListTrainingWords
} from '../../../actions/cacheActions';
import {
  logListWord,
  logUserList,
  logGlossaryWord,
  logPhrase,
  logCourseList,
  logCourseListWord,
  logActivity
} from '../../../actions/logActions'

import {
  getListsToRepeat,
  getListsNumber,
  toggleListTrainingButtonsModal,
  getOnlyMistakes,
  fillListFromGlossaryWithSelectedWords,
  getUserListsToFill
} from '../../../actions/lists';
import {setFirstPlayed, get_word_details} from '../../../actions/wordActions';
import {fetchingData} from '../../../actions/activity';
import {
  toggleListTrainingButtonsModalCourse,
  getUpdatedCourseLists,
  getUpdatedCourseData,
  getUpdatedCurrentListScores,
  shuffleCourseListTrainingWords
} from '../../../actions/courseActions';
import {logTrainingLimits} from '../../../actions/purchasesActions';
import {findWordInDictionary, resetFoundDictionaryWord} from '../../../actions/dictionaryActions';

import {checkForCachedWords, checkForCourseCachedWords} from '../../../lib/trainingFunctions';

import * as constants from '../../../lib/constants';

class Listening extends Component {

  constructor(props) {
    super(props);
    this.track = null;

    this.state = {
      currentIndex: 0,
      translationsHeight: 40,
      currentPage: 1,
      totalRepeated: 1,
      downloads: 0,
      totalWords: 0,
      currentChunk: 0,
      scores: 0,
      score: 1,
      finishButtonsModalVisible: false,
      exampleTranslationModalVisible: false,
      audioModalVisible: false,
      bugModalVisible: false,
      trainingSettingsModalVisible: false,
      wordInfoVisible: false,
      tour: localStorage.getItem('tour'),

      trainingId: 14,
      selectedIndex: null,
      answered: false,
      timesTapped: 0,
      wordButtons: [],
      shuffledWordButtons: [],
      wordButtonsToHide: [],
      shuffledLetters: [],
      wasShuffled: false,
      trainingInfoVisible: false,
      trainingName: 'Listening',
      draggedWords: [],
      autoslide: "0",
      autoslide_delay: "1",
      autosound_sentence: "1",
      autosound_translation: "1",
      loop: "0",
      autosound_word: "1",
      sentence_speed_listening: "1",
      autosound_example_translation: "1",
      autoslide_type: "sentence",
      mistakes: 0,
      totalMistakes: 0,
      withMistakes: [],
      trainingResults: false,
      registerModalVisible: false,

      trainingAlertShouldShow: false,
      trainingAlertVisible: false,
      wordSettingsModalVisible: false,
      currentExampleIndex: 0,
      phrasebook: false,
      exampleTranslationHeight: null,
      freeze: false,
      finishedTraining: false,
      subscriptionMessage: 'subscription_trainings',
      subscriptionAlertVisible: false,
      translateOptionsModalVisible: false,
      shuffle: true,
      currentCharIndex: -1,
      allowedMistakes: 2,
      playing: false,
      currentPlayingItem: 'word',

      addWordModalVisible: false,
      lookWord: false,
      listsModalIsVisible: false,

      tablet: false,

      entityId: this.props.match.params.id,
      listId: undefined,
      courseListId: undefined,
      from: false,
      type: false,
      inside: false,
    };

    this.timeOut = null;
    this.timeOut1 = null;
    this.timeOut2 = null;
    this.timeOut3 = null;
    this.timeOut4 = null;
    this.timeOut5 = null;
    this.timeOut6 = null;
    this.timeOut7 = null;
    this.timeOut8 = null;
    this.timeOut9 = null;
    this.timeOut10 = null;
    this.timeOut11 = null;
    this.timeOut12 = null;
    this.timeOut13 = null;

  }

  componentDidMount() {
    this._setTrainingSettings();
    document.addEventListener("keydown", this._handleKeyDown);

    // this._showPossibleErrorsAlert();

    if (!this.props.history.location || !this.props.history.location.state || !this.props.history.location.state.type) {
      this.props.history.goBack();
    } else {
      this.setState({
        [this.props.history.location.state.type]: parseInt(this.props.match.params.id, 10),
        from: this.props.history.location.state.from,
        type: this.props.history.location.state.type,
        inside: this.props.history.location.state.inside,
      })
    }

    this.timeOut = setTimeout(()=> {
      this.getWords();
    }, 100);

    this.props.setCurrentTrainingId(14);
  }

  componentDidUpdate(prevProps, prevState) {
    const {words} = this.props.data;

    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      if (words.length == 0) {
        this.getWords();
      }
    }
  }

  componentWillUnmount() {
    const {listId, courseListId} = this.state;

    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut1);
    clearTimeout(this.timeOut2);
    clearTimeout(this.timeOut3);
    clearTimeout(this.timeOut4);
    clearTimeout(this.timeOut5);
    clearTimeout(this.timeOut6);
    clearTimeout(this.timeOut7);
    clearTimeout(this.timeOut8);
    clearTimeout(this.timeOut9);
    clearTimeout(this.timeOut10);
    clearTimeout(this.timeOut11);
    clearTimeout(this.timeOut12);
    clearTimeout(this.timeOut13);
    this.props.resetWords();
    this.props.resetCurrentTrainingId();

    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    if (listId != undefined) {
      this.onUnmountUpdates();
    }

    if (courseListId != undefined) {
      this.onUnmountCourseUpdates();
    }

    this.props.setFirstPlayed(false);
    document.removeEventListener("keydown", this._handleKeyDown);
  }

  _handleKeyDown = (event) => {
    const {freeze} = this.state;

    switch (event.keyCode) {
      case 39:
        if (!freeze) {
          this.changeWord('left')
        }
        break;
      case 37:
        if (!freeze) {
          this.changeWord('right')
        }
        break;

      case 27:
        this.props.history.goBack();
        break;
      default:
        break;
    }
  };

  _showPossibleErrorsAlert = async() => {
    const {userId} = this.props.user;
    const {trainingId} = this.state;
    const {translate} = this.props;

    const errorsAlert = localStorage.getItem(`errors_alert_${trainingId}_${userId}`);


    if (errorsAlert == null) {
      toastr.info(translate('to_info'), translate('possible_error'), {showCloseButton: true, closeOnToastrClick: true});

      localStorage.setItem(`errors_alert_${trainingId}_${userId}`, "1")
    }
  };

  onUnmountCourseUpdates = () => {
    const {totalMistakes, finishedTraining, courseListId, inside} = this.state;

    this._shuffleCourseWords();

    if (inside == undefined) {
      this.props.fetchingData(true);
    }

    if (finishedTraining || totalMistakes > 0 || courseListId == 0) {
      this.props.getUpdatedCourseLists();
    } else {
      this.props.getUpdatedCourseData();
    }

    if (courseListId != 0) {
      this.props.getUpdatedCurrentListScores(courseListId);
    }
  };

  _shuffleCourseWords = () => {
    const {trainingId, courseListId} = this.state;
    const {userData: {shuffle}} = this.props.user;

    if (courseListId && (courseListId > 0 || courseListId == -1) && shuffle == "1") {
      this.props.shuffleCourseListTrainingWords(trainingId, courseListId)
    }
  };

  _shuffleWords = () => {
    const {trainingId} = this.state;
    const {currentList} = this.props.lists;
    const {userData: {shuffle}} = this.props.user;

    if (currentList && (currentList.id > 0 || currentList.id == -1) && shuffle == "1") {
      this.props.shuffleListTrainingWords(trainingId, currentList.id)
    }
  };

  onUnmountUpdates = () => {
    const {totalMistakes, finishedTraining} = this.state;
    const {currentList} = this.props.lists;

    this._shuffleWords();

    if (finishedTraining) {
      this.moveLists();
    }

    if (currentList && currentList.id == 0) {
      this.props.getOnlyMistakes();
    }

    if (currentList && currentList.id > 0 && totalMistakes > 0) {
      this.props.getOnlyMistakes();
    }
  };

  moveLists = () => {
    const {currentList} = this.props.lists;

    let today = moment().format("YYYY-MM-DD");
    let todayTrainings = null;

    currentList.intervals && currentList.intervals.forEach(interval => {
      if (interval.repetition_date == today) {
        todayTrainings = interval.trainings;
      }
    });

    if (currentList && currentList.id > 0) {
      if (currentList.intervals && currentList.intervals.length != 0 && todayTrainings == 0) {
        this.props.updateTrainingsListFromRepeatSection(currentList.id);
      } else if (todayTrainings == 0) {
        this.props.moveNewListToRepeat(currentList);
      } else if (todayTrainings == 1) {
        this.props.updateTrainingsListFromRepeatSection(currentList.id);
      } else if (todayTrainings >= 2) {
        this.props.removeListFromRepeatSection(currentList.id);
        this.props.updateCachedListTrainings(currentList.id);
      } else if (todayTrainings == null) {
        this.props.moveNewListToRepeat(currentList);
        this.props.updateCachedListTrainingsAndIntervals(currentList);
      }
    }
  };

  _logOnExit = () => {
    const {words} = this.props.data;
    const {logUserList, logCourseList} = this.props;
    const {totalRepeated, trainingId, listId, courseListId} = this.state;

    const percentage = totalRepeated / (words.length != 0 ? words.length : 20);

    this._togglePlaying(false);

    if (percentage >= 0.85 && listId && listId > 0) {
      this.setState({
        finishedTraining: true
      });

      logUserList({trainingId, listId, type: "end"})
    }

    if (percentage >= 0.85 && courseListId && courseListId != 0) {
      this.setState({
        finishedTraining: true
      });
      logCourseList({trainingId, listId: courseListId, type: "end"});
    }
  };


  _setTrainingSettings = async() => {
    const {userId} = this.props.user;
    const {trainingId} = this.state;

    const autoslide_delay = localStorage.getItem(`autoslide_delay_${trainingId}_${userId}`);
    const autosound_word = localStorage.getItem(`autosound_word_${trainingId}_${userId}`);
    const autosound_translation = localStorage.getItem(`autosound_translation_${trainingId}_${userId}`);
    const autosound_example_translation = localStorage.getItem(`autosound_example_translation_${trainingId}_${userId}`);
    const loop = localStorage.getItem(`loop_${trainingId}_${userId}`);
    const sentence_speed_listening = localStorage.getItem(`speed_${trainingId}_${userId}`);

    const autosound_sentence = localStorage.getItem(`autosound_sentence_${trainingId}_${userId}`);

    if (autoslide_delay != null) {
      this.setState({autoslide_delay})
    } else {
      localStorage.setItem(`autoslide_delay_${trainingId}_${userId}`, '1');
    }

    if (autosound_word != null) {
      this.setState({autosound_word})
    } else {
      localStorage.setItem(`autosound_word_${trainingId}_${userId}`, '1');
    }

    if (autosound_translation != null) {
      this.setState({autosound_translation})
    } else {
      localStorage.setItem(`autosound_translation_${trainingId}_${userId}`, '1');
    }

    if (autosound_example_translation != null) {
      this.setState({autosound_example_translation})
    } else {
      localStorage.setItem(`autosound_example_translation_${trainingId}_${userId}`, '1');
    }

    if (autosound_sentence != null) {
      this.setState({autosound_sentence})
    } else {
      localStorage.setItem(`autosound_sentence_${trainingId}_${userId}`, '1');

    }

    if (loop != null) {
      this.setState({loop})
    } else {
      localStorage.setItem(`loop_${trainingId}_${userId}`, '0');
    }

    if (sentence_speed_listening != null) {
      this.setState({sentence_speed_listening})
    } else {
      localStorage.setItem(`speed_${trainingId}_${userId}`, '1');
    }

    this.props.setListeningSettings({
      loop: loop != null ? loop : "1",
      autoslide_delay: autoslide_delay != null ? autoslide_delay : "1",
      autosound_sentence: autosound_sentence != null ? autosound_sentence : "1",
      autosound_word: autosound_word != null ? autosound_word : "1",
      autosound_translation: autosound_translation != null ? autosound_translation : "1",
      autosound_example_translation: autosound_example_translation != null ? autosound_example_translation : "1",
      speed: sentence_speed_listening != null ? sentence_speed_listening : "1",
    })
  };

  changeWord = (direction, play) => {
    const {totalWords, autoslide_delay_listening, loop_listening} = this.props.data;
    const {currentIndex, scores, score, playing, listId, courseListId} = this.state;
    var index = currentIndex;
    const {userData} = this.props.user;

    let last = false;

    // if(!playing) return;

    if (direction == "left") {
      this.checkIfNeedMore();

      if (currentIndex == totalWords - 1) {
        last = true;

        if ((listId != undefined && listId == 0) || (courseListId != undefined && courseListId == 0)) {
          this.props.history.goBack();
        } else {
          let active = this._checkTrainingsLimit();

          if (active) {
            if (loop_listening == '1' && play) {
              index = 0;
              this.continueTraining();

              this.timeOut1 = setTimeout(() => {
                if (!playing) return;

                this.playSound();
              }, parseFloat(autoslide_delay_listening) * 1000)
            } else {
              if(userData && userData.active_trainings_set) {
                this.setState({
                  finishedTraining: true
                });
                
                this._goToNextTraining()
              } else {
                this.setState({
                  finishButtonsModalVisible: true
                })
              }
            }
          } else {
            this._toggleSubscriptionAlert(true);
          }
        }
      } else {
        index = currentIndex + 1;

        this.setState({
          totalRepeated: this.state.totalRepeated + 1
        });

        if (play) {
          this.timeOut2 = setTimeout(()=> {
            if (!playing) return;

            this.playSound();
          }, parseFloat(autoslide_delay_listening) * 1000)
        }

      }
    } else {
      if (currentIndex == 0) {
        index = totalWords - 1
      } else {
        index = currentIndex - 1
      }
    }

    this.track = null;

    this.setState({
      mistakes: 0,
      currentIndex: index,
      selectedIndex: null,
      answered: false,
      timesTapped: 0,
      wasShuffled: false,
      shuffledLetters: [],
      currentExampleIndex: 0,
      currentCharIndex: -1,
      wordButtonsToHide: [],
      shuffledWordButtons: [],
      draggedWords: [],
      freeze: false,
      currentPlayingItem: 'word',
      scores: scores + score,

    });
  };

  _goToNextTraining = () => {
    const {logUserList, logCourseList} = this.props;

    const {listId, courseListId, type, trainingId} = this.state;
    const {userData} = this.props.user;
    const {locale} = this.props.settings;

    if (listId && listId > 0) {
      logUserList({trainingId, listId, type: "end"})
    } else if (courseListId && courseListId != 0) {
      logCourseList({trainingId, listId: courseListId, type: "end"});
    }
    
    let currentListId = null;

    if (listId != undefined) {
      currentListId = listId;
    }

    if (courseListId != undefined) {
      currentListId = courseListId;
    }

    let trainingIds = userData.trainings_set != null ? userData.trainings_set.split(',').map(Number) : [];

    const trainingIndex = trainingIds.length > 0 ? trainingIds.indexOf(trainingId) : null;

    if(trainingIndex === null || trainingIndex == (trainingIds.length - 1)) {
      this.setState({
        finishButtonsModalVisible: true
      });
      return;
    }

    if(currentListId != 0) {
      let activityId = '';
      let path = '';

      if (trainingIds[trainingIndex+1] == 1) {
        activityId = 39;
        path = 'preview'
      } else if (trainingIds[trainingIndex+1] == 6) {
        path = 'image';
        activityId = 40;
      } else if (trainingIds[trainingIndex+1] == 2) {
        path = 'translation';
        activityId = 41;
      } else if (trainingIds[trainingIndex+1] == 4) {
        path = 'word';
        activityId = 42;
      } else if (trainingIds[trainingIndex+1] == 3) {
        path = 'collect';
        activityId = 43;
      } else if (trainingIds[trainingIndex+1] == 5) {
        path = 'spelling';
        activityId = 44;
      } else if (trainingIds[trainingIndex+1] == 8) {
        activityId = 114;
        path = 'sentence';
      } else if (trainingIds[trainingIndex+1] == 9) {
        activityId = 131;
        path = 'memory'
      } else if (trainingIds[trainingIndex+1] == 10) {
        path = 'definition';
        activityId = 148;
      } else if (trainingIds[trainingIndex+1] == 7) {
        activityId = 95;
      } else if (trainingIds[trainingIndex+1] == 12) {
        path = 'missing';
        activityId = 315;
      } else if (trainingIds[trainingIndex+1] == 14) {
        path = 'listening';
        activityId = 345;
      } else if (trainingIds[trainingIndex+1] == 15) {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut4 = setTimeout(()=> {
        this.props.history.replace(`/${locale}/${path}/${currentListId}`, {
          type,
          from: 'inside',
          listId: currentListId
        });
      }, 100);

      this.props.logActivity({activityId, model: 'list', modelId: currentListId, comment: 'from home'});

    } else {
      this.setState({
        finishButtonsModalVisible: true
      })
    }
  };
  
  toSettings = () => {
    this._toggleTrainingSettingsModal(true);
    this.props.logActivity({activityId: 189, comment: 'from listening'});
  };

  checkIfNeedMore = () => {
    const {currentIndex, currentPage, downloads} = this.state;
    const {totalWords, words} = this.props.data;

    if (currentIndex + 3 == words.length && words.length < totalWords) {
      this.setState({
        currentPage: currentPage + 1,
        download: downloads + 1
      });

      this.getWords();
    }
  };

  getWords = () => {
    const {listeningCachedWords} = this.props.cache;
    const {listeningCourseCachedWords} = this.props.courseCache;

    const {currentPage, trainingId, courseListId, listId, glossaryId, phrasebookId} = this.state;
    const {logUserList, logCourseList} = this.props;
    const {isConnected} = this.props.settings;

    let params = {
      trainingId,
      page: this.state.currentPage,
      history: this.props.history
    };

    if (listId !== undefined && listId >= -2) {
      params.listId = listId;
      params.endpoint = 'getTrainingWords'
    } else if (courseListId !== undefined && courseListId !== false) {
      params.courseListId = courseListId;
      params.endpoint = 'getCourseTrainingWords'
    } else if (glossaryId !== undefined && glossaryId) {
      params.glossaryId = glossaryId;
      params.endpoint = 'getGlossaryWordsForTraining'
    } else if (phrasebookId !== undefined && phrasebookId) {
      params.phrasebookId = phrasebookId;
      params.endpoint = 'getPhrasebookForTrainingO';

      this.setState({
        phrasebook: true
      });
    }

    let cachedWords = false;

    if (listId) {
      cachedWords = checkForCachedWords(listeningCachedWords, listId, currentPage);
    } else if (courseListId) {
      cachedWords = checkForCourseCachedWords(listeningCourseCachedWords, courseListId, currentPage);
    }

    if (!cachedWords) {
      if (isConnected) {
        if (currentPage == 1) {
          this.props.fetchingData(true);
        }
        this.props.getWordsForTraining(params);
      }
    } else {
      this.props.setCachedListeningWords(cachedWords);
      this.props.logTrainingLimits();

      if (currentPage == 1) {
        if (listId && listId > 0) {
          logUserList({trainingId, listId, type: "start"})
        }

        if (courseListId && courseListId > 0) {
          logCourseList({trainingId, listId: courseListId, type: "start"});
        }
      }
    }
  };

  _checkTrainingsLimit = () => {
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;

    const {userData} = this.props.user;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    let active = false;

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    return active;
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    });

    if (!status) {
      this.props.history.goBack();
    }
  };

  _toSubscription = (productId) => {
    this.setState({
      subscriptionAlertVisible: false
    });

    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId});
  };

  closeFinishButtonsModal = () => {
    this.setState({
      finishButtonsModalVisible: false
    });
  };

  continueTraining = () => {
    const {logUserList, logCourseList} = this.props;
    const {trainingId, listId, courseListId} = this.state;

    const {userData: {shuffle}} = this.props.user;

    this.moveLists();

    if (listId && (listId > 0 || listId == -1)) {
      if (shuffle == "1") {
        this.props.shuffleTrainingWords();
      }

      if (listId && listId > 0) {
        logUserList({trainingId, listId, type: "end"});

        this.timeOut3 = setTimeout(() => {
          logUserList({trainingId, listId, type: "start"})
        }, 1000);
      }
    }

    if (courseListId && (courseListId > 0 || courseListId == -1)) {
      if (shuffle == "1") {
        this.props.shuffleTrainingWords();
      }

      if (courseListId && courseListId > 0) {
        logCourseList({trainingId, listId: courseListId, type: "end"});

        this.timeOut4 = setTimeout(()=> {
          logCourseList({trainingId, listId: courseListId, type: "start"});
        }, 1000);
      }
    }

    this.setState({
      finishButtonsModalVisible: false,
      currentIndex: 0,
      totalRepeated: 1
    });

    this.props.logTrainingLimits();

    this.props.logActivity({activityId: 113});
  };

  anotherTraining = () => {
    const {logUserList, logCourseList} = this.props;
    const {trainingId, listId, courseListId, from} = this.state;

    if (listId !== undefined && from == 'listWords') {
      this.props.toggleListTrainingButtonsModal(true)
    }

    if (courseListId !== undefined && from == 'listWords') {
      this.props.toggleListTrainingButtonsModalCourse(true)
    }

    if (listId && listId > 0) {
      logUserList({trainingId, listId, type: "end"})
    } else if (courseListId && courseListId != 0) {
      logCourseList({trainingId, listId: courseListId, type: "end"});
    }

    this.setState({
      finishButtonsModalVisible: false,
      finishedTraining: true
    });

    this.props.history.goBack();

    this.props.logActivity({activityId: 190, comment: 'Listening'});
  };

  _log = (mistake) => {
    const {words} = this.props.data;
    const {currentIndex} = this.state;
    let word = words.length > 0 ? words[currentIndex] : false;

    const {trainingId, listId, glossaryId, courseListId} = this.state;

    if (word) {
      let params = {
        trainingId: trainingId,
        mistake: 0,
        id: word.id
      };

      if (listId !== undefined && listId >= -1) {
        params.listId = listId;
        this.props.logListWord(params);
      } else if (glossaryId !== undefined && glossaryId) {
        params.glossaryId = glossaryId;
        this.props.logGlossaryWord(params);
      } else if (courseListId !== undefined && courseListId >= -1) {
        params.courseListId = courseListId;
        this.props.logCourseListWord(params);
      }
    }
  };

  toggleInfoModal = (status) => {
    this.setState({
      trainingInfoVisible: status
    })
  };

  _toggleAudioModal = (status) => {
    this.setState({
      audioModalVisible: status
    });
  };

  _openTrainingInfo = () => {
    this.toggleWordSettingsModal(false);
    this.toggleInfoModal(true);
  };

  _reportTrainingBug = () => {
    this.toggleWordSettingsModal(false);

    const {words, totalWords} = this.props.data;
    const {currentIndex, currentExampleIndex, listId, courseListId} = this.state;

    let word = words.length > 0 ? words[currentIndex] : false;
    let extra = {};

    extra.word = word && word.word ? word.word : "";
    extra.translation = word && word.main_translation && word.main_translation.translation ? word.main_translation.translation : "";
    extra.example = word && word.examples && word.examples.length != 0 ? word.examples[currentExampleIndex] : false;
    extra.listId = listId;
    extra.courseListId = courseListId;

    this.setState({
      extra
    });

    this._toggleBugModal(true);

    this.props.logActivity({activityId: 234, comment: "Listening"});
  };

  _openTrainingSettings = () => {
    this.toggleInfoModal(false);
    this.toggleWordSettingsModal(false);

    if(!this.state.tour) {
      this.toSettings();
    } else {
      this._toggleRegisterModal(true)
    }
  };

  _toWordDetails = () => {
    this.toggleWordSettingsModal(false);
    const {words} = this.props.data;
    const {currentIndex} = this.state;

    let word = words.length > 0 ? words[currentIndex] : false;

    if (!word) return;
    this.props.get_word_details(word.id);

    this._toggleWordInfo(true);

    this.props.logActivity({activityId: 45, model: 'word', modelId: word.id, comment: "from Listening"});

    // this.props.history.push(`/dictionary/${word.word}`, {word});
  };

  _changeVoice = () => {
    this.toggleWordSettingsModal(false);

    this.props.logActivity({activityId: 332});

    this._toggleAudioModal(true);
  };

  toggleWordSettingsModal = (status) => {
    this.toggleInfoModal(false);

    this.setState({
      wordSettingsModalVisible: status
    })
  };

  _toResults = () => {
    const {withMistakes, trainingResults, scores} = this.state;
    if (!trainingResults) {
      this.props.navigation.navigate('TrainingResults', {withMistakes, scores});

      this.setState({trainingResults: true});
    } else {
      this.props.history.goBack();
    }
  };

  _toResultsFromModal = () => {
    const {withMistakes, scores} = this.state;
    this.setState({
      finishButtonsModalVisible: false
    });
    this.props.navigation.navigate('TrainingResults', {withMistakes, scores});
  };

  _goBack = () => {
    this.setState({
      finishButtonsModalVisible: false
    });

    this._logOnExit();

    this.props.history.goBack();

    this.props.logActivity({activityId: 306, comment: 'Listening'});
  };

  _toggleShuffle = () => {
    const {userData} = this.props.user;

    this.toggleWordSettingsModal(false);

    if(!this.state.tour) {
      this.props.fetchingData(true);

      this.props.toggleShuffleWords();
      this.props.logActivity({activityId: 333, comment: userData && userData.shuffle == 1 ? "on" : "off"});

    } else {
      this._toggleRegisterModal(true)
    }
  };

  _togglePlaying = (status) => {
    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    this.setState({
      playing: status
    });

    if (status) {
      this.timeOut5 = setTimeout(() => {
        this.playSound();
      }, 150);
    }
  };

  playSound = () => {
    const {words, autosound_translation_listening, autosound_word_listening} = this.props.data;
    const {userData} = this.props.user;
    const {playing} = this.state;

    let audioSource = constants.BACKEND_URL;

    if (words.length > 0 && words.length > this.state.currentIndex) {
      const word = words[this.state.currentIndex];

      const translationAudio = word && word.main_translation && word.main_translation.audio ? word.main_translation.audio : false;

      if (!playing)return;

      if (word && word.audio) {
        if (autosound_word_listening == '1') {
          let audio = word.audio;

          if (userData && userData.audio_language && word[userData.audio_language]) {
            audio = word[userData.audio_language];
            audioSource = constants.S3
          }

          if (this.track != null) {
            this.track.pause();
            this.track = null;
          }

          this.track = new Audio(`${audioSource}${audio}`);

          this.track.addEventListener('loadedmetadata', (e) => {
            let duration = e.target.duration;

            if (!duration) {
              duration = 1;
            }

            duration += 0.25;

            if (this.track != null) {
              this.track.play();

              if (translationAudio && autosound_translation_listening == '1') {
                if (!playing) return;

                this.timeOut6 = setTimeout(()=> {
                  if (!playing) return;

                  this.track = new Audio(`${constants.BACKEND_URL}${translationAudio}`);

                  this.track.addEventListener('loadedmetadata', (e) => {
                    let duration = e.target.duration;

                    if (!duration) {
                      duration = 1;
                    }

                    duration += 0.25;

                    if (this.track != null) {
                      this.track.play();

                      this.timeOut7 = setTimeout(()=> {
                        if (!playing) return;
                        this.playSentence();
                      }, duration * 1000)
                    }
                  });

                }, duration * 1000)
              } else {
                this.timeOut8 = setTimeout(()=> {
                  if (!playing) return;
                  this.playSentence();
                }, duration * 1000)
              }
            }
          });
        } else {
          if (!playing) return;
          this.timeOut9 = setTimeout(()=> {
            if (!playing) return;
            this.playSentence();
          }, 500)
        }
      }
    }
  };

  playSentence = () => {
    const {words, autosound_example_translation_listening, autosound_sentence_listening, sentence_speed_listening} = this.props.data;
    const {currentIndex, currentExampleIndex, playing} = this.state;
    const {userData} = this.props.user;
    let audioSource = constants.BACKEND_URL;

    if (words.length > 0 && words.length > currentIndex) {
      const word = words[currentIndex];
      let example = word && word.examples && word.examples.length != 0 ? word.examples[currentExampleIndex] : false;
      let exampleTranslationAudio = example && example.translation && example.translation.audio ? example.translation.audio : false;

      if (!playing) return;

      if (example && example.audio) {
        let audio = example.audio;

        if (userData && userData.audio_language && example[userData.audio_language]) {
          audio = example[userData.audio_language];
          audioSource = constants.S3;
        }

        if (autosound_sentence_listening == '1') {
          if (this.track != null) {
            this.track.pause();
            this.track = null;
          }

          if (!playing) return;

          // for sentence
          this.track = new Audio(`${audioSource}${audio}`);
          this.track.playbackRate = parseFloat(sentence_speed_listening);


          this.track.addEventListener('loadedmetadata', (e) => {
            let duration = e.target.duration;

            if (!duration) {
              duration = 1;
            }

            duration += 0.25;

            if (sentence_speed_listening < 1) {
              duration = duration + parseFloat(1 * sentence_speed_listening);
            }


            if (this.track != null) {
              this.track.play();

              if (exampleTranslationAudio && autosound_example_translation_listening == '1') {
                if (!playing) return;
                this.timeOut10 = setTimeout(()=> {

                  this.track = new Audio(`${constants.BACKEND_URL}${exampleTranslationAudio}`);

                  this.track.addEventListener('loadedmetadata', (e) => {
                      let duration = e.target.duration;

                      if (!duration) {
                        duration = 1;
                      }

                      duration += 0.25;

                      if (this.track != null) {
                        this.track.play();

                        this.timeOut11 = setTimeout(() => {
                          this._changeWord(100);
                        }, duration * 1000)
                      }

                    }
                  )
                }, duration * 1000)
              } else {
                this._changeWord(100);
              }
            }

          });
        } else {
          if (exampleTranslationAudio && autosound_example_translation_listening == '1') {
            if (!playing) return;

            this.track = new Audio(`${constants.BACKEND_URL}${exampleTranslationAudio}`);

            this.track.addEventListener('loadedmetadata', (e) => {
              let duration = e.target.duration;

              if (!duration) {
                duration = 1;
              }

              duration += 0.25;

              if (this.track != null) {
                this.track.play();

                this.timeOut12 = setTimeout(() => {
                  this._changeWord(100);
                }, duration * 1000)
              }
            });

          } else {
            this._changeWord(100);
          }
        }
      } else {
        this._changeWord(100);
      }
    }
  };

  _changeWord = (time) => {
    const {playing} = this.state;
    if (!playing) return;

    this.timeOut13 = setTimeout(() => {
      if (!playing) return;
      this.changeWord('left', true)
    }, time)
  };

  _showWord = (word, e) => {
    e.preventDefault();

    this.props.findWordInDictionary(word);

    this.setState({
      lookWord: word
    });

    this._toggleShowWordModal(true)
  };

  _toggleShowWordModal = (status) => {
    this.setState({
      addWordModalVisible: status
    });

    if (!status) {
      this.props.resetFoundDictionaryWord();
    }
  };

  toggleListsToFillModal = (status) => {
    // this.props.logActivity({activityId: 18});

    this.setState({
      addWordModalVisible: false,
      listsModalIsVisible: status
    })
  };

  fillLists = (id) => {
    const {subscriptionActive, listsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {listsNumber} = this.props.lists;
    const {userData} = this.props.user;
    const {foundWord, fetchedWord} = this.props.dictionary;

    if (!foundWord)return;

    let active = false;

    if (lifetimeStatus || subscriptionActive || webSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      if (id == 0) {
        active = listsNumber < listsLimit;
      } else {
        active = true;
      }
    }

    this.toggleListsToFillModal(false);

    if (active) {
      this.props.fetchingData(true);

      this.props.fillListFromGlossaryWithSelectedWords({
        listId: id,
        selectedWords: [foundWord.id]
      });

      this.props.logActivity({activityId: id != 0 ? 19 : 20, model: 'list', modelId: id});
    } else {
      this.setState({
        subscriptionMessage: 'subscription_lists'
      });
      this._toggleSubscriptionAlert(true);
    }

    this.props.resetFoundDictionaryWord();
  };

  _toggleBugModal = (status) => {
    this.setState({
      bugModalVisible: status
    });
  };

  _toggleTrainingSettingsModal = (status) => {
    this.setState({
      trainingSettingsModalVisible: status
    });
  };

  _toggleWordInfo = (status) => {
    this.setState({
      wordInfoVisible: status
    })
  };
  
  _toggleRegisterModal = (status, e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      registerModalVisible: status
    })
  };

  _toRegister = () => {
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/register`)
  };

  render() {
    const {words, totalWords, fetchedWords} = this.props.data;
    const {
      currentIndex, trainingId, phrasebook,
      totalRepeated, scores, withMistakes,
      trainingInfoVisible,
      finishButtonsModalVisible,
      exampleTranslationModalVisible,
      trainingAlertVisible,
      wordSettingsModalVisible, tablet,
      currentExampleIndex,
      freeze,
      mistakes,
      draggedWords,
      subscriptionAlertVisible,
      subscriptionMessage,
      wordButtonsToHide,
      allowedMistakes,
      translateOptionsModalVisible,
      notch,
      playing,
      lookWord,
      addWordModalVisible,
      listsModalIsVisible,
      from,
      listId,
      glossaryId,
      courseListId,
      audioModalVisible,
      bugModalVisible,
      trainingSettingsModalVisible,
      extra,
      wordInfoVisible,
      registerModalVisible
    } = this.state;

    const {fetchingData} = this.props.activity;
    const {userData} = this.props.user;
    const {fontSizeRation, version, isConnected, deviceWidth, deviceHeight} = this.props.settings;
    const {currentList, listsToFill} = this.props.lists;
    const {course, translate} = this.props;
    const {foundWord, fetchedWord} = this.props.dictionary;
    let listSize = userData && userData.list_size ? userData.list_size : 10;

    let subtitle = "";

    if (currentList && currentList.list_name && totalWords) {
      subtitle = `${currentList.list_name}: ${totalWords}`
    } else if (course && course.currentList && course.currentList.list_name) {
      subtitle = `${course.currentList.list_name}: ${totalWords}`
    }

    if (listId == -1 || courseListId == -1) {
      subtitle = translate('vocabulary')
    }

    let word = words.length > 0 ? words[currentIndex] : false;

    let example = word && word.examples && word.examples.length != 0 ? word.examples[currentExampleIndex] : false;

    let exampleSentence = example && example.example ? example.example : "";
    let exampleTranslation = example && example.translation && example.translation.translation ? example.translation.translation : "";

    let sentenceShuffled = exampleSentence ? exampleSentence.split(' ') : false;

    const progress = totalWords !== 0 ? ((currentIndex + 1) / totalWords) : 0;

    let sentencesHeight = 150;

    if (deviceHeight < 600) {
      sentencesHeight = 130;
      if (deviceHeight < 550) {
        // sentencesHeight = 120;
      }
    } else {
      sentencesHeight *= (deviceHeight / 600)
    }

    return (
      <div className="listening">
        <Container className="pageWrapper">

          <TrainingSubHeader
            trainingId={trainingId}
            freeze={playing}
            key={playing}
            version={version}
            ratio={fontSizeRation}
            scores={scores}
            progress={progress}
            changeWord={this.changeWord}

            deviceHeight={deviceHeight}
            deviceWidth={deviceWidth}
            translate={translate}
            tablet={tablet}
            exit={this._goBack}
            history={this.props.history}
            trainingName="listening_training"
            subtitle={subtitle}

            title={'listening_training'}
            text="listening_training_tap_description"
            toggleSettings={this.toggleWordSettingsModal}
          />

          {
            fetchedWords && (
              <Card style={{minHeight: deviceHeight - 140}}>
                <div style={{opacity: word && word.id ? 1 : 0}} className="shuffledWordButtonsWrapper">

                  <a href="#"
                     onClick={(e) => {
                     e.preventDefault();
                      this.props.logActivity({activityId: 228, comment: "Listening"});
                      this._togglePlaying(playing ? false : true)
                  }}>
                    <FontAwesomeIcon
                      style={{fontSize: !tablet ? 60 : 80}}
                      icon={!playing ? faPlayCircle : faPauseCircle}
                    />
                  </a>

                  <div
                    className="flexCenter flexV"
                    style={{paddingHorizontal: !tablet ? 15 : 25, marginTop: !tablet ? 20 : 30}}
                  >
                    <p className="word" style={{fontSize: fontSizeRation * (!tablet ? 20 : 24)}}>
                      {word ? word.word : ""}
                    </p>

                    <p className="translation"
                       style={{marginTop: !tablet ? 10 : 20, fontSize: fontSizeRation * (!tablet ? 14 : 16)}}>
                      {word && word.main_translation && word.main_translation.translation ? word.main_translation.translation : ""}
                    </p>

                    {
                      exampleSentence && false && (
                        <p
                          className="example"
                          style={{marginTop: !tablet ? 20 : 30, fontSize: fontSizeRation * (!tablet ? 14: 16)}}
                        >
                          {exampleSentence}
                        </p>
                      ) || null
                    }

                    {
                      sentenceShuffled && (
                        <div className="flexH flexCenter" style={{
                            flexWrap:'wrap',
                            width: deviceWidth -20,
                            marginTop: !tablet ? 5 : 10,}}>
                          {sentenceShuffled.map((word, i) => {
                            return (
                              <a className="word" href="#" key={i}
                                 style={{padding: '5px 4px', fontSize: fontSizeRation * (!tablet ? 16 : 18)}}
                                 onClick={(e)=> this._showWord(word, e)}
                              >
                                {word}
                              </a>
                            )
                          })}
                        </div>
                      ) || null
                    }

                    {
                      exampleTranslation && (
                        <span className="exampleTranslation"
                              style={{marginTop: !tablet ? 5 : 10, fontSize: fontSizeRation * (!tablet ? 14 : 16)}}>
                          {exampleTranslation}
                        </span>
                      ) || null
                    }
                  </div>
                </div>
              </Card>
            )
          }

          {finishButtonsModalVisible && (
            <FinishTrainingButtons
              ratio={fontSizeRation}
              isVisible={finishButtonsModalVisible}
              onClose={this.closeFinishButtonsModal}
              continueTraining={this.continueTraining}
              anotherTraining={this.anotherTraining}
              withMistakes={withMistakes}
              toResults={this._toResultsFromModal}
              glossaryId={glossaryId}
              returnBack={this._goBack}
              from={from}
              translate={translate}
              tablet={tablet}
            />
          )}

          {wordSettingsModalVisible && (
            <WordSettings
              changeVoice={this._changeVoice}
              ratio={fontSizeRation}
              phrasebook={phrasebook}
              isVisible={wordSettingsModalVisible}
              onClose={this.toggleWordSettingsModal}
              openTrainingInfo={this._openTrainingInfo}
              openTrainingSettings={this._openTrainingSettings}
              toWordDetails={this._toWordDetails}
              reportTrainingBug={this._reportTrainingBug}
              title={translate('listening_training')}
              shuffle={userData.shuffle}
              shouldShuffle={true}
              toggleShuffle={this._toggleShuffle}
            />
          )}

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={subscriptionMessage}
                translate={translate}
                deviceWidth={deviceWidth}
                deviceHeight={deviceHeight}
              />
            )
          }

          {
            addWordModalVisible && (
              <AddWordModal
                onClose={this._toggleShowWordModal}
                isVisible={addWordModalVisible}
                tablet={tablet}
                word={lookWord}
                userData={userData}
                foundWord={foundWord}
                fetchedWord={fetchedWord}
                ratio={fontSizeRation}
                toggleListsToFill={this.toggleListsToFillModal}
                translate={translate}

              />
            )
          }

          {listsModalIsVisible && (
            <ListsToFill
              isVisible={listsModalIsVisible}
              lists={listsToFill}
              fillLists={this.fillLists}
              listSize={listSize}
              ratio={fontSizeRation}
              selectedWords={1}
              onClose={this.toggleListsToFillModal}
              translate={translate}
            />
          )}
          {
            audioModalVisible && (
              <ChangeAudioLanguage
                onClose={this._toggleAudioModal}
                isVisible={audioModalVisible}
                toRegister={this._toggleRegisterModal}

              />
            )
          }
          {
            bugModalVisible && (
              <ReportBug
                onClose={this._toggleBugModal}
                isVisible={bugModalVisible}
                type="training"
                training="Listening"
                extra={extra}
              />
            )
          }
          {
            trainingSettingsModalVisible && (
              <TrainingSettings
                onClose={this._toggleTrainingSettingsModal}
                isVisible={trainingSettingsModalVisible}
                trainingName="listening_training"
                trainingId={trainingId}
              />
            )
          }
          {wordInfoVisible && (<WordInfo onClose={this._toggleWordInfo} isVisible={wordInfoVisible}/>)}
          {registerModalVisible && (
            <RegisterAlert
              onClose={this._toggleRegisterModal}
              isVisible={registerModalVisible}
              translate={translate}
              toRegister={this._toRegister}
            />
          )}
        </Container>
        <Loader fetchingData={fetchingData}/>

        <Helmet>
          <title>{`${translate('app_title_listening_training')}`}</title>
          <meta property="og:title" content={`${translate('app_title_listening_training')}`} />
          <meta name="description" content={translate('app_title_listening_training_description')} />
          <meta name="og:description" content={translate('app_title_listening_training_description')} />

          <link rel="canonical" href={window.location.href} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  data: state.trainings,
  user: state.user,
  firstPlayed: state.word.firstPlayed,
  activity: state.activity,
  settings: state.settings,
  cache: state.cache,
  lists: state.lists,
  courseCache: state.courseCache,
  purchases: state.purchases,
  course: state.course,
  dictionary: state.dictionary,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    getWordsForTraining: (data) => dispatch(getWordsForTraining(data)),
    resetWords: () => dispatch(resetWords()),
    logListWord: (data) => dispatch(logListWord(data)),
    logPhrase: (data) => dispatch(logPhrase(data)),
    logGlossaryWord: (data) => dispatch(logGlossaryWord(data)),
    logUserList: (data) => dispatch(logUserList(data)),
    logActivity: (data) => dispatch(logActivity(data)),
    getListsToRepeat: (limit, userId) => dispatch(getListsToRepeat(limit, userId)),
    setFirstPlayed: (status) => dispatch(setFirstPlayed(status)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    logCourseListWord: (data) => dispatch(logCourseListWord(data)),
    logCourseList: (data) => dispatch(logCourseList(data)),
    setCachedListeningWords: (data) => dispatch(setCachedListeningWords(data)),
    setListeningSettings: (data) => dispatch(setListeningSettings(data)),
    toggleListTrainingButtonsModal: (status) => dispatch(toggleListTrainingButtonsModal(status)),

    moveNewListToRepeat: (list) => dispatch(moveNewListToRepeat(list)),
    removeListFromRepeatSection: (listId) => dispatch(removeListFromRepeatSection(listId)),
    updateListTrainings: (listId) => dispatch(updateListTrainings(listId)),
    updateTrainingsListFromRepeatSection: (listId) => dispatch(updateTrainingsListFromRepeatSection(listId)),
    getOnlyMistakes: () => dispatch(getOnlyMistakes()),
    updateCachedListTrainings: (listId) => dispatch(updateCachedListTrainings(listId)),
    updateCachedListTrainingsAndIntervals: (list) => dispatch(updateCachedListTrainingsAndIntervals(list)),

    toggleListTrainingButtonsModalCourse: (status) => dispatch(toggleListTrainingButtonsModalCourse(status)),
    getUpdatedCurrentListScores: (listId) => dispatch(getUpdatedCurrentListScores(listId)),
    getUpdatedCourseLists: () => dispatch(getUpdatedCourseLists()),
    getUpdatedCourseData: () => dispatch(getUpdatedCourseData()),
    resetCurrentTrainingId: () => dispatch(resetCurrentTrainingId()),
    setCurrentTrainingId: (id) => dispatch(setCurrentTrainingId(id)),
    logTrainingLimits: () => dispatch(logTrainingLimits()),
    shuffleListTrainingWords: (trainingId, listId) => dispatch(shuffleListTrainingWords(trainingId, listId)),
    shuffleCourseListTrainingWords: (trainingId, listId) => dispatch(shuffleCourseListTrainingWords(trainingId, listId)),
    shuffleTrainingWords: () => dispatch(shuffleTrainingWords()),
    toggleShuffleWords: () => dispatch(toggleShuffleWords()),
    addUserExampleTranslation: (wordId, exampleId, translation) => dispatch(addUserExampleTranslation(wordId, exampleId, translation)),

    getUserListsToFill: () => dispatch(getUserListsToFill()),
    findWordInDictionary: (word) => dispatch(findWordInDictionary(word)),
    getListsNumber: () => dispatch(getListsNumber()),
    fillListFromGlossaryWithSelectedWords: (data) => dispatch(fillListFromGlossaryWithSelectedWords(data)),
    resetFoundDictionaryWord: () => dispatch(resetFoundDictionaryWord()),
    get_word_details: (wordId) => dispatch(get_word_details(wordId)),

  };
}

export default connect(mapStateToProps, bindAction)(Listening);
