import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle, faPlusCircle, faPlus} from '@fortawesome/free-solid-svg-icons';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';
import {withRouter, Link} from 'react-router-dom';

import {connect} from "react-redux";

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';

import AccountCell from '../../../components/Cells/AccountCell/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
import AddAccount from '../../../components/Modals/AddAccount/index';
import Loader from "../../../components/Custom/Loader/index";

import {getConnectedAccounts, deleteConnected} from '../../../actions/accountsActions'
import {setUserId, getUserScores, getUserAppProfileById} from '../../../actions/userActions';
import {getListsToRepeat} from '../../../actions/lists';
import {cleanAppCache} from '../../../actions/cacheActions';
import {resetCourseCache, getCoursesMistakesTestsRepeats, checkForCourseDebts} from '../../../actions/courseActions';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

class FamilyAccounts extends Component {

  constructor(props) {
    super(props);

    this.state = {
      originalUserId: false,
      currentAccount: false,
      trainingInfoVisible: false,
      wrongAccount: false,
      infoModalVisible: false,
      addAccountVisible: false,
      title: '',

      subscriptionAlertVisible: false,
      subscriptionMessage: 'subscription_no_family_access',
      tablet: false
    };

    this.timeOut = null;
  }

  componentDidMount() {
    this.setOriginalUserId();

    this._getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._getData();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
  };

  _getData = () => {
    const {isConnected} = this.props.settings;

    if (isConnected) {
      this.props.fetchingData(true);

      this.props.getConnectedAccounts();
    }
  };


  setOriginalUserId = () => {
    let userId = localStorage.getItem('userId');

    this.setState({
      originalUserId: userId
    })
  };

  _onPressItem = (account) => {
    this._changeData(account);

    this.props.history.replace('/home');
  };

  _changeData = (account) => {
    const {isConnected} = this.props.settings;
    const {translate} = this.props;
    const {originalUserId} = this.state;

    if (originalUserId != account.id) {

      toastr.success("", translate('account_was_changed'));

      this.props.logActivity({activityId: 173, comment: `${account.id}`});

      this.props.cleanAppCache();
      this.props.resetCourseCache();

      this.props.setUserId(account.id);

      this.props.getUserScores(account.id);

      this.props.getUserAppProfileById(account.id);

      this.props.getCoursesMistakesTestsRepeats();
      this.props.checkForCourseDebts(account.id);
      this.props.getListsToRepeat(20, account.id);


      localStorage.setItem('userId', `${account.id}`);
      localStorage.setItem(`listSize${account.id}`, `${account.list_size}`);
      localStorage.setItem(`studyLanguage${account.id}`, `${account.study_language}`);
      localStorage.setItem(`translationLanguage${account.id}`, `${account.translation_language}`);
    }
  };

  _onDeletePressed = (account, e) => {
    e.preventDefault();
    e.stopPropagation();

    const {originalUserId} = this.props.user;
    const {translate} = this.props;

    this.setState({
      wrongAccount: originalUserId == account.id,
      currentAccount: account,
      title: originalUserId == account.id ? 'you_cant_disconnect_your_account' : 'deleteConnected',
    });

    if (originalUserId == account.id) {
      toastr.warning(translate('you_cant_disconnect_your_account'))
    } else {
      toastr.confirm(translate('deleteConnected'), {
        onOk: () => {
          this._onConfirm();
        },
        cancelText: translate('cancel')
      });
    }
  };

  _renderItem = (item, index) => {
    const {tablet} = this.state;
    const {translate} = this.props;

    return (
      <AccountCell
        key={index}
        translate={translate}
        tablet={tablet}
        onDeletePressed={this._onDeletePressed}
        userId={this.props.user.userId}
        account={item}
        onItemPressed={this._onPressItem}
      />
    );
  };

  _onConfirm = () => {
    this.props.fetchingData(true);

    this.props.logActivity({activityId: 174, comment: `${this.state.currentAccount.id}`});

    this.props.deleteConnected(this.state.currentAccount.id);
  };

  _toAddAccount = () => {
    const {subscriptionActive, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {userData} = this.props.user;

    let active = false;

    if (lifetimeStatus || subscriptionActive || webSubscriptionStatus) {
      active = true;
    }

    if (active) {
      this._toggleAddAccount(true);
      this.props.logActivity({activityId: 172});
    } else {
      this._toggleSubscriptionAlert(true);
    }
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toggleAddAccount = (status) => {
    this.setState({
      addAccountVisible: status
    })
  };

  _toSubscription = (productId) => {
    const {locale} = this.props.settings;
    this._toggleSubscriptionAlert(false);

    this.props.history.push(`/${locale}/products`, {back: true, product: productId});
  };


  render() {
    const {accounts} = this.props.accounts;
    const {
      isModalVisible,
      addAccountVisible,
      title, subscriptionMessage, subscriptionAlertVisible, tablet
    } = this.state;
    const {fetchingData} = this.props.activity;
    const {fontSizeRation, locale} = this.props.settings;
    const {originalUserId, userId} = this.props.user;
    let {lifetimeStatus} = this.props.purchases;
    let {translate} = this.props;

    let family_access_description = translate('family_access_description');

    family_access_description = family_access_description.replace(' %@', "");

    let showAddButton = accounts.length < 5;

    if (lifetimeStatus && accounts.length < 7) {
      showAddButton = true;
    }

    return (
      <div className="familyaccounts">

        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>

          <Breadcrumb.Item active>
            {translate('family_access')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container className="pageWrapper">

          <div className="introView mb-3">
            <span className="accountsLabel" style={{fontSize: fontSizeRation * (!tablet ? 16 : 18)}}>
              {translate('your_accounts')}

              <OverlayTrigger
                rootClose={true}
                trigger="click"
                placement="bottom"
                overlay={<Popover id="popover-basic">
                    <Popover.Title as="h5">
                      {translate('family_access')}
                    </Popover.Title>
                    <Popover.Content>
                      <span style={{whiteSpace: 'pre-line'}}>
                         {translate('family_access_description_web')}
                      </span>
                      <a
                        className="pt-2 pb-2 text-center d-block"
                        href="https://youtu.be/QQ3ql8ZBnOk" target="_new">
                        {translate('watch_video_instruction')}
                      </a>
                    </Popover.Content>
                  </Popover>}
              >
                    <FontAwesomeIcon style={{fontSize: 20}} className="pl-1 infoIcon" icon={faInfoCircle}/>
                  </OverlayTrigger>
            </span>

            {
              showAddButton && originalUserId == userId && (
                <OverlayTrigger
                  delay={{ show: 500, hide: 400 }}
                  placement='bottom'
                  overlay={<Tooltip>{translate('add_family_account')}</Tooltip>}
                >
                  <Button variant="outline-success" size="sm" className="float-right" onClick={this._toAddAccount}>
                    <FontAwesomeIcon icon={faPlus}/>
                  </Button>
                </OverlayTrigger>
              )
            }
          </div>

          <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
            {accounts.map((item, index) => this._renderItem(item, index))}
          </div>

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={subscriptionMessage}
                translate={translate}
              />
            )
          }

          {
            addAccountVisible && (
              <AddAccount
                isVisible={addAccountVisible}
                onClose={this._toggleAddAccount}
              />
            )
          }

        </Container>
        <Loader fetchingData={fetchingData}/>

      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  accounts: state.accounts,
  user: state.user,
  purchases: state.purchases,
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    getConnectedAccounts: () => dispatch(getConnectedAccounts()),
    setUserId: (id) => dispatch(setUserId(id)),
    deleteConnected: (connectedId) => dispatch(deleteConnected(connectedId)),
    getListsToRepeat: (limit, userId) => dispatch(getListsToRepeat(limit, userId)),
    getUserScores: (userId) => dispatch(getUserScores(userId)),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    getUserAppProfileById: (userId) => dispatch(getUserAppProfileById(userId)),
    cleanAppCache: () => dispatch(cleanAppCache()),
    resetCourseCache: () => dispatch(resetCourseCache()),
    checkForCourseDebts: (userId) => dispatch(checkForCourseDebts(userId)),
    getCoursesMistakesTestsRepeats: () => dispatch(getCoursesMistakesTestsRepeats()),
  };
}

export default connect(mapStateToProps, bindAction)(withRouter(FamilyAccounts));
