import React, {PureComponent} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
  faCircle, faCogs, faCheckCircle,
  faTrashAlt,
  faCamera,
  faVolumeUp,
  faTimes,
  faAngleDoubleUp
} from '@fortawesome/free-solid-svg-icons';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import colors from '../../../lib/colors';

const TranslationCell = ({
  translation,
  playTrack,
  changeMainTranslation,
  deleteUserTranslation,
  ratio,
  tablet,
  isConnected,
  userId,
  minimal,
  translate
}) => (
  <div className="wordTranslation wordContainer">
    <div className="wordWrapper">
      <div className="d-flex justify-content-start align-items-center">
        {!minimal && <a href="#" className="touchable" onClick={(e) => playTrack(translation.audio, e)}>
          <FontAwesomeIcon
            className="mr-2"
            style={{fontSize: 15, color: colors.greyishBrown5}}
            icon={faVolumeUp}
          />
        </a>}

        <span className="translation" style={{fontSize: ratio * (!tablet ? 12 : 14)}}>
          {translation.translation}
        </span>
      </div>

      <div className="d-flex justify-content-end"
           style={{width: translation.user_id ? (!tablet ? 110 : 150) : (!tablet ? 50 : 70)}}>
        {
          isConnected && translation.user_id && translation.user_id == userId && !minimal && (
            <OverlayTrigger
              delay={{ show:500, hide: 400 }}
              placement='top' overlay={<Tooltip>{translate('delete_user_word_translation')}</Tooltip>}>
              <a
                href="#"
                className="makeButton"
                style={{marginRight: 10,width: !tablet ? 40 : 70, height: !tablet ? 40 : 50}}
                onClick={(e) => deleteUserTranslation(translation.id, e)}
              >
                <FontAwesomeIcon
                  style={{fontSize: 15, color: colors.coral}}
                  icon={faTimes}
                />
              </a>
            </OverlayTrigger>
          )
        }

        {
          isConnected && (
            <OverlayTrigger
              delay={{ show:500, hide: 400 }}
              placement='top' overlay={<Tooltip>{translate('word_change_main_translation')}</Tooltip>}>
              <a
                href="#"
                className="makeButton"
                onClick={(e) => changeMainTranslation(translation.id, e)}
              >
                <FontAwesomeIcon
                  className="icon toolbarButtonActive"
                  style={{fontSize: 15}}
                  icon={faAngleDoubleUp}
                />
              </a>
            </OverlayTrigger>
          )
        }
      </div>

    </div>
  </div>
);

export default TranslationCell;