import * as types from '../actions/types';

const INITIAL_STATE = {
  word: {},
  wordDefinitions: [],
  wordExamples: [],
  wordSynonyms: [],
  wordAntonyms: [],
  sections: [],
  firstPlayed: false,
  wordToOpen: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_CURRENT_WORD:{
      return Object.assign({}, state, {
        word: action.payload,
      });
    }

    case types.DELETE_ASSOCIATION_FROM_WORD: {
      let word = state.word;
      word.association = false;

      return Object.assign({}, state, {
        word,
      });
    }
    
    case types.ADD_ASSOCIATION_TO_WORD: {
      let word = state.word;
      word.association = action.payload;

      return Object.assign({}, state, {
        word,
      });
    }

    case types.REMOVE_IMAGE_FROM_CURRENT_WORD: {
      let word = state.word;
      word.images = false
      
      return Object.assign({}, state, {
        word,
      });
    }

    case types.SET_FIRST_PLAYER:
      return Object.assign({}, state, {
        firstPlayed: action.payload,
      });

    case types.RESET_WORD_DETAILS:
      return Object.assign({}, state, {
        word: {},
        wordDefinitions: [],
        wordExamples: [],
        wordSynonyms: [],
        wordAntonyms: [],
      });

    case types.SET_CURRENT_WORD_DEFINITIONS: {
      let sections = [];

      action.payload.data.map((item, i) => {
        sections.push({title: item.meaning, data : item.items})
      });

      return Object.assign({}, state, {
        wordDefinitions: sections,
      });
    }
     
    case types.SET_CURRENT_WORD_EXAMPLES: {
      let sections = [];
      action.payload.data.map((item, i) => {
        sections.push({title: item.meaning, data : item.items})
      });
      
      return Object.assign({}, state, {
        wordExamples: sections,
      });
    }
    case types.SET_CURRENT_WORD_SYNONYMS: {
      let sections = [];
      action.payload.data.map((item, i) => {
        sections.push({title: item.meaning, data : item.items})
      });

      return Object.assign({}, state, {
        wordSynonyms: sections,
      });
    }

    case types.SET_CURRENT_WORD_ANTONYMS: {
      let sections = [];
      action.payload.data.map((item, i) => {
        sections.push({title: item.meaning, data : item.items})
      });

      return Object.assign({}, state, {
        wordAntonyms: sections,
      });
    }

    case types.UPDATE_CURRENT_WORD:
    {
      let word = state.word;
      
      if (action.payload == 'vocabulary') {
        word.in_vocabulary = !word.in_vocabulary;
      } else if (action.payload == 'favorite') {
        word.is_favourite = !word.is_favourite;
      }
      return Object.assign({}, state, {
        word,
      });
    }
      
    default:
      return state;
  }
}