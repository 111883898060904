import * as types from '../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {
  parents: [],
  currentParent: {},
  totalGlossaries: 0,
  totalGlossariesWords: 0,
  filter: 'by_glossary_popularity',
  order: 'asc',
  parentSections: [],

  children: [],
  currentChild: {},
  totalChildWords: 0,
  totalChildGlossaries: 0,
  
  glossaryWords: [],
  totalGlossaryWords: 0,
  totalPages: 0,
  fetchingNextChunk: false,

  glossaryWordsForList: [],
  totalGlossaryWordsForList: 0,
  glossariesForList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_PARENT_GLOSSARIES: {
      let parentSections = [];

      action.payload.data.map((item, i) => {
        parentSections.push({
          title: item.glossary_name,
          children: item.children_number,
          words: item.total_words, 
          data : item.children,
          index: i
        })
      });
      
      return Object.assign({}, state, {
        parents: action.payload.data,
        totalGlossaries: action.payload.total_glossaries,
        totalGlossariesWords: action.payload.total_words,
        parentSections
      });
    }
    
    case types.SET_GLOSSARIES_FROM_CACHE: {
      return Object.assign({}, state, {
        parents: action.payload.parents,
        totalGlossaries: action.payload.totalGlossaries,
        totalGlossariesWords: action.payload.totalGlossariesWords,
        parentSections: action.payload.parentSections
      });
    }
      
    case types.RESET_PARENT_GLOSSARIES:
      return Object.assign({}, state, {
        parents: [],
        totalGlossaries: 0,
        totalGlossariesWords: 0,
      });
    
    case types.RESET_CHILD_GLOSSARIES:
      return Object.assign({}, state, {
        children: [],
      });

    case types.RESET_CURRENT_CHILD_GLOSSARY:
      return Object.assign({}, state, {
        currentChild: {},
      });

    case types.SET_CURRENT_GLOSSARY_DATA:
      return Object.assign({}, state, {
        currentChild: action.payload,
      });

    case types.SET_PARENT_CHILDREN:
      return Object.assign({}, state, {
        children: action.payload.data,
      });
    
    case types.SET_GLOSSARIES_FOR_LIST:
      return Object.assign({}, state, {
        glossariesForList: action.payload.data,
      });

    case types.GLOSSARY_WORD_TOGGLED_IN_VOCABULARY: {
      return Object.assign({}, state, {
        glossaryWords: state.glossaryWords.map((item, i) => {
          if(i === action.payload) {
            item.in_vocabulary = !item.in_vocabulary
          }

          return item;
        })
      });
    }

    case types.TOGGLED_GLOSSARY_WORD_IN_LIST: {
      return Object.assign({}, state, {
        glossaryWordsForList: state.glossaryWordsForList.map((item, i) => {
          if(i === action.payload) {
            item.in_list = !item.in_list
          }

          return item;
        })
      });
    }

    case types.TOGGLED_GLOSSARY_WORD_IN_LIST_: {
      return Object.assign({}, state, {
        glossaryWords: state.glossaryWords.map((item, i) => {
          if(item.word_id === action.payload) {
            item.in_user_lists = !item.in_user_lists
          }

          return item;
        })
      });
    }

    case types.GLOSSARY_WORD_TOGGLED_IN_FAVORITES: {
      return Object.assign({}, state, {
        glossaryWords: state.glossaryWords.map((item, i) => {
          if(i === action.payload) {
            item.is_favourite = !item.is_favourite
          }

          return item;
        })
      });
    }

    case types.LISTS_FILLED_WITH_GLOSSARY_WORDS: {
      return Object.assign({}, state, {
        glossaryWords: state.glossaryWords.map((item, i) => {
          action.payload.map(key=> {
            if(key === item.id){
              item.in_user_lists = true;
            }
          });
          return item;
        })
      });
    }

    case types.SET_CURRENT_PARENT:
      return Object.assign({}, state, {
        currentParent: action.payload,
      });

    case types.SET_CURRENT_CHILD:
      return Object.assign({}, state, {
        currentChild: action.payload,
      });

    case types.SET_GLOSSARY_WORDS:
      return Object.assign({}, state, {
        glossaryWords: action.payload.data,
        // glossaryWords: _.union(state.glossaryWords, action.payload.data),
        totalGlossaryWords: action.payload.total_words,
        totalPages: action.payload.total_pages,
        fetchingNextChunk: false
      });

    case types.SET_GLOSSARY_WORDS_FOR_LIST:
      return Object.assign({}, state, {
        glossaryWordsForList: _.union(state.glossaryWordsForList, action.payload.data),
        totalGlossaryWordsForList: action.payload.total_words,
        totalPages: action.payload.total_pages,
        fetchingNextChunk: false
      });
    
    case types.SET_CACHED_GLOSSARY_WORDS: {
      return Object.assign({}, state, {
        glossaryWords: action.payload.words,
        totalGlossaryWords: action.payload.totalGlossaryWords,
        totalPages: action.payload.totalPages,
        fetchingNextChunk: false
      });
    }

    case types.SET_CACHED_GLOSSARY_WORDS_FOR_LIST: {
      return Object.assign({}, state, {
        glossaryWordsForList: action.payload.words,
        totalGlossaryWordsForList: action.payload.totalGlossaryWords,
        totalPages: action.payload.totalPages,
        fetchingNextChunk: false
      });
    }
    
    case types.APPEND_CACHED_GLOSSARY_WORDS: {
      return Object.assign({}, state, {
        glossaryWords:_.union(state.glossaryWords, action.payload.words) ,
        totalGlossaryWords: action.payload.totalGlossaryWords,
        totalPages: action.payload.totalPages,
        fetchingNextChunk: false
      });
    }

    case types.APPEND_CACHED_GLOSSARY_WORDS_FOR_LIST: {
      return Object.assign({}, state, {
        glossaryWordsForList:_.union(state.glossaryWords, action.payload.words) ,
        totalGlossaryWordsForList: action.payload.totalGlossaryWords,
        totalPages: action.payload.totalPages,
        fetchingNextChunk: false
      });
    }

    case types.GET_GLOSSARY_WORDS_NEXT_CHUNK:
      return Object.assign({}, state, {
        fetchingNextChunk: true,
      });
    
    case types.SET_FOUND_GLOSSARY_WORDS: {
      return Object.assign({}, state, {
        glossaryWords: action.payload.data,
        totalGlossaryWords: action.payload.total_words
      });
    }

    case types.SET_FOUND_GLOSSARY_WORDS_FOR_LIST: {
      return Object.assign({}, state, {
        glossaryWordsForList: action.payload.data,
        totalGlossaryWordsForList: action.payload.total_words
      });
    }

    case types.RESET_GLOSSARY_WORDS:
      return Object.assign({}, state, {
        glossaryWords: [],
        glossaryWordsForList: [],
        totalGlossaryWords: 0,
        totalGlossaryWordsForList:0,
        totalPages: 0
      });

    case types.RESET_GLOSSARY_WORDS_WITHOUT_PAGES:
      return Object.assign({}, state, {
        glossaryWords: [],
        glossaryWordsForList: [],
        totalGlossaryWords: 0,
        totalGlossaryWordsForList:0,
        fetchingNextChunk: false

      });

    case types.RESET_GLOSSARY_WORDS_DATA:
      return Object.assign({}, state, {
        glossaryWords: [],
        fetchingNextChunk: false
      });

    case types.RESET_GLOSSARY_WORDS_DATA_FOR_LIST:
      return Object.assign({}, state, {
        glossaryWordsForList: [],
        fetchingNextChunk: false
      });

    case types.GLOSSARY_TOGGLED_IN_FAVORITES: {
      let child = state.currentChild;
      child.is_favourite = child.is_favourite === 1 ? 0 : 1;

      return Object.assign({}, state, {
        currentChild: child,
      });
    }

    default:
      return state;
  }
}