import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logApiError} from './logSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* getGroupedPhrasebooks() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'getGroupedPhrasebooks')
    .send({
      user_id: userId,
    });

    yield put({
      type: types.SET_PHRASEBOOKS,
      payload: result.body
    });
  } catch (error) {
    yield logApiError({payload: {function: 'getGroupedPhrasebooks', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* searchPhrases(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'searchPhrases')
    .query({
      user_id: userId,
      phrase: data.payload.phrase,
      by_translation: data.payload.byTranslation
    });

    // console.log("searchPhrases", result.body)
    yield put({
      type: types.SET_FOUND_PHRASES,
      payload: result.body
    });
  } catch (error) {
    yield logApiError({payload: {function: 'searchPhrases', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getPhrases(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'getPhrases')
    .send({
      user_id: userId,
      phrasebook_id: data.payload
    });
    
    // console.log("getPhrase", result.body)

    yield put({
      type: types.SET_PHRASEBOOK_PHRASES,
      payload: result.body
    });
  } catch (error) {
    yield logApiError({payload: {function: 'getPhrases', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }  finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* toggleFavoritePhrase(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'toggleFavoritePhrase')
    .send({
      user_id: userId,
      phrase_id: data.payload.phraseId
    });
    
    if (result.body.added === true) {
      // toastr.info('', translate('phrase_added_to_favorites'));
    } else {
      // toastr.info('', translate('phrase_removed_from_favorites'));
    }
    
    yield put({
      type: types.UPDATE_FAVORITE_PHRASE,
      payload: {
        phraseId: data.payload.phraseId,
      }
    });
  } catch (error) {
    yield logApiError({payload: {function: 'toggleFavoritePhrase', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }  finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}