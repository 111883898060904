import React, {Component} from 'react';
import {connect} from "react-redux";

import {Translate, getTranslate} from 'react-localize-redux';
import {Link} from "react-router-dom";


import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'

import {logActivity} from '../../../actions/logActions';
import colors from '../../../lib/colors';

class VideoInstructions extends Component {
  constructor(props) {
    super(props);

    const {translate} = this.props;

    this.state = {
      playVideo: false,
      currentUrl: false,
      tablet: false,
      items: [
        {
          title: 'glossaries',
          data: [
            {name: translate('glossaries_general'), uri: 'https://youtu.be/gZ3LnrLPlXg'},
            {name: translate('glossaries_saving_words'), uri: 'https://youtu.be/A0ywCLTzGy8'},
            {name: translate('glossaries_making_cards'), uri: 'https://youtu.be/RqDVHDSsjao'}
          ]
        },
        {
          title: 'lists',
          data: [
            {name: translate('lists'), uri: 'https://youtu.be/se2x7QSJGhM'},
            {name: translate('lists_manipulation'), uri: 'https://youtu.be/DEfFq_xO25c'},
            {name: translate('lists_add_methods'), uri: 'https://youtu.be/yqU8tXYrVUM'},
            {name: translate('lists_generation'), uri: 'https://youtu.be/QrhcmMTSyAM'},
            {name: translate('lists_start_training'), uri: 'https://youtu.be/_SAtOnMqv2k'},
            {name: translate('lists_change_size'), uri: 'https://youtu.be/rJQ4y073BV4'},
            {name: translate('lists_delete_lists'), uri: 'https://youtu.be/8yCCy7t1edY'},
            {name: translate('lists_hide_known_words'), uri: 'https://youtu.be/nv6jTKIeYXs'},
            {name: translate('lists_tags'), uri: 'https://youtu.be/zPwb0DXBThE'}
          ]
        },
        {
          title: 'course',
          data: [
            {name: translate('course_general'), uri: 'https://youtu.be/P2f2nXFPZ40'}
          ]
        },

        {
          title: 'training_plan',
          data: [
            {name: translate('home_lists'), uri: 'https://youtu.be/YImLb3wsQYY'}
          ]
        },

        {
          title: 'family_access',
          data: [
            {name: translate('family_access'), uri: 'https://youtu.be/QQ3ql8ZBnOk'}
          ]
        },

        {
          title: 'words',
          data: [
            {name: translate('word_add_image'), uri: 'https://youtu.be/8UfSzZAJTuA'},
            {name: translate('word_change_main_translation'), uri: 'https://youtu.be/OwW-H827Q2s'},
            {name: translate('word_add_text_association'), uri: 'https://youtu.be/I0hG23yLIjE'},
            {name: translate('word_change_voice'), uri: 'https://youtu.be/xvdF0pTVWAY'}
          ]
        },

        {
          title: 'exercises',
          data: [
            {name: translate('trainings_general'), uri: "https://youtu.be/rQv1PbnprBk"},
            {name: translate('preview_training'), uri: "https://youtu.be/9YuX14wz5qk"},
            {name: translate('drag_image_training'), uri: "https://youtu.be/QkL98_pKyAU"},
            {name: translate('select_translation_training'), uri: "https://youtu.be/3ntOUr1Nd8M"},
            {name: translate('select_word_training'), uri: "https://youtu.be/bYChBPtE_xQ"},
            {name: translate('collect_word_training'), uri: "https://youtu.be/-GYCvhrZKHI"},
            {name: translate('collect_sentence_training'), uri: "https://youtu.be/IJhj32BrHic"},
            {name: translate('collect_sentence_training_tap'), uri: "https://youtu.be/ljMFLDppUsI"},
            {name: translate('check_spelling_training'), uri: "https://youtu.be/UANsKK4rizg"},
            {name: translate('memory_cards_training'), uri: "https://youtu.be/njmodQNiZlk"},
            {name: translate('definition_training'), uri: "https://youtu.be/g0ZWZ3w5KFw"}

          ]
        },

        {
          title: 'word_games',
          data: [
            {name: translate('word_game_hangman'), uri: 'https://youtu.be/l10-2xPTujs'},
            {name: translate('word_game_extractor'), uri: 'https://youtu.be/lRdQ7ICtY4Q'},
            {name: translate('word_game_word_maker'), uri: 'https://youtu.be/AfejcwIqSOg'},
          ]
        }
      ]
    }
  }

  componentDidMount() {
  };

  componentWillUnmount() {
  }

  _onPressItem = (url) => {
    this.props.logActivity({activityId: 186, comment: url});
  };

  render() {
    const {items} = this.state;
    const {translate} = this.props;
    const {locale} = this.props.settings;

    return (
      <div className="videoinstructions">
        <Breadcrumb style={{marginTop: 50}}>

          <React.Fragment>
            <li className="breadcrumb-item">
              <Link to={`/${locale}/home`}>
                {translate('home')}
              </Link>
            </li>
            <Breadcrumb.Item active>
              {translate('video_instructions')}
            </Breadcrumb.Item>
          </React.Fragment>

        </Breadcrumb>

        <Container style={{marginTop: 20}} className="pageWrapper">

          <Accordion defaultActiveKey={0}>
            {
              items.map((section, index) => {
                return (
                  <Card key={index}>
                    <Accordion.Toggle as={Card.Header} eventKey={index}>
                      {translate(section.title)}
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey={index}>
                      <Card.Body style={{padding: "2px 0 0 0"}}>
                        <ListGroup variant="flush">
                          {
                            section.data.map((video, i) => {
                              return <ListGroup.Item
                                as="a"
                                style={{color: colors.waterBlue}}
                                href={video.uri}
                                key={video.id}
                                target="_new"
                                className="pt-2 pb-2 pl-5 d-flex font-weight-light align-items-center justify-content-between"
                                onClick={()=>this._onPressItem(video.uri)}
                              >
                                {video.name}
                              </ListGroup.Item>
                            })
                          }
                        </ListGroup>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )
              })
            }
          </Accordion>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
  };
}

export default connect(mapStateToProps, bindAction)(VideoInstructions);
