import React, {Component} from 'react';
import {connect} from "react-redux";
import request from 'superagent';
import classNames from 'classnames';
import {Helmet} from "react-helmet";

import {Link} from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faCamera,
  faVolumeUp,
  faTimes
} from '@fortawesome/free-solid-svg-icons';

import {Translate, getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';

import Image from 'react-bootstrap/Image';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import ListsToFill from '../../../components/Modals/ListsToFill/index';
import TranslationCell from '../../../components/Cells/TranslationCell/index';
import WordDefinition from '../../../components/Cells/WordDefinition/index';
import WordSynonymAntonym from '../../../components/Cells/WordSynonymAntonym/index';
import WordExample from '../../../components/Cells/WordExample/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
import WordToolbar from '../../../components/Toolbars/WordToolbar/index';
import AddTranslationModal from '../../../components/Modals/AddTranslationModal/index';
import AddAssociationModal from '../../../components/Modals/AddAssociationModal/index';
import AddWordExample from '../../../components/Modals/AddWordExample/index';
import ReportBug from '../../../components/Modals/ReportBug/index';
import UploadImage from '../../../components/Modals/UploadImage/index';
import RegisterAlert from '../../../components/Modals/RegisterAlert/index';

import {getUserListsToFill, getListsNumber, fillListFromGlossaryWithSelectedWords} from '../../../actions/lists';

import {
  get_word_details,
  resetWordDetails,
  toggleInVocabularyWordDetails,
  toggleFavoriteWordDetails,
  get_extended_info,
  addUserTranslation,
  changeMainTranslation,
  deleteUserTranslation,
  deleteUserImage,
  addUserAssociation,
  deleteUserAssociation,
  addUserExample,
} from '../../../actions/wordActions';

import {resetDictionaryWords, resetDictionarySearchField} from '../../../actions/dictionaryActions';
import {getListWords, updateCurrentListWords} from '../../../actions/lists';

import {addImageToLists, addImageToListWords} from '../../../actions/lists';
import {addImageToCachedListWords, addImageToCachedTrainings} from '../../../actions/cacheActions';
import {addImageToTrainingWords} from '../../../actions/trainingActions';

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import {getUserContacts} from '../../../actions/userContactsActions';
import {shareWord} from '../../../actions/shareActions';

import * as constants from '../../../lib/constants';
import colors from '../../../lib/colors';

import Loader from "../../../components/Custom/Loader/index";

const API_URL = constants.API_URL;

class WordDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      listsModalIsVisible: false,
      subscriptionAlertVisible: false,
      addTranslationModalVisible: false,
      subscriptionMessage: '',
      contactsModalVisible: false,
      tablet: false,
      listId: false,
      listName: false,
      dontShowModalVisible: false,
      neverShowWordAdded: false,
      shouldGetWords: false,
      associationModal: false,
      exampleModalVisible: false,
      deviceWidth: window.innerWidth,
      deviceHeight: window.innerHeight,
      scrolled: 0,
      matchWord: this.props.match.params.word,
      wordDetails: false,
      activeTab: 'examples',
      back: false,
      uploadImageVisible: false,
      registerModalVisible: false,
      tour: localStorage.getItem('tour')
    };

    this.track = null;
    this.timeOut = null;
    this.timeOut1 = null;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));

    if (this.props.history.location && this.props.history.location.state) {
      this.setState({
        listId: this.props.history.location.state.listId,
        back: this.props.history.location.state.back,
        wordDetails: this.props.history.location.state.word,
        listName: this.props.history.location.state.listId
      })
    }

    if (this.props.match.params && this.props.match.params.word) {
      this._getWordDetailsData();
    } else {
      this.props.history.goBack();
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._getWordDetailsData();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut1);
    
    const {shouldGetWords, listId} = this.state;
    this.props.resetWordDetails();

    if (listId && shouldGetWords) {
      this.props.getListWords(listId);
    }

    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const scrollY = window.scrollY;

    this.setState({
      scrolled: scrollY
    });
  }

  _getWordDetailsData = () => {
    const {isConnected} = this.props.settings;
    const {matchWord, wordDetails} = this.state;

    if (isConnected) {
      this.props.getListsNumber();

      this.props.fetchingData(true);

      if (wordDetails && wordDetails.id) {
        this.props.get_word_details(wordDetails.id, true, this.props.history);
      } else {
        this.props.get_word_details(matchWord, true, this.props.history);
      }

      this.timeOut = setTimeout(()=> {
        this.props.getUserListsToFill();
      }, 500)
    }
  };


  playSound = (e) => {
    e.preventDefault();

    const {word} = this.props.word;
    const {isConnected} = this.props.settings;
    const {userData} = this.props.user;
    let audioSource = constants.BACKEND_URL;

    if (word.audio) {
      let audio = word.audio;

      if (userData && userData.audio_language && word[userData.audio_language]) {
        audio = word[userData.audio_language];
        audioSource = constants.S3
      }

      if (this.track != null) {
        this.track.pause();
        this.track = null;
      }

      if (isConnected) {
        this.track = new Audio(`${audioSource}${audio}`);
        this.track.play();
      }
    }
  };

  playExample = (example, e) => {
    e.preventDefault();

    const {isConnected} = this.props.settings;
    const {userData} = this.props.user;
    let audioSource = constants.BACKEND_URL;

    if (example.audio) {
      let audio = example.audio;

      if (userData && userData.audio_language && example[userData.audio_language]) {
        audio = example[userData.audio_language];
        audioSource = constants.S3
      }

      if (this.track != null) {
        this.track.pause();
        this.track = null;
      }

      if (isConnected) {
        this.track = new Audio(`${audioSource}${audio}`);
        this.track.play();
      }
    }
  };

  playTrack = (url, e) => {
    e.preventDefault();
    const {isConnected} = this.props.settings;

    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }
    if (url && isConnected) {
      this.track = new Audio(`${constants.BACKEND_URL}${url}`);
      this.track.play();
    }
  };

  toggleListsToFillModal = (status) => {
    if(!this.state.tour) {
      this.setState({
        listsModalIsVisible: status
      })
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _toggleInVocabulary = (e) => {
    e.preventDefault();
    const {word} = this.props.word;

    if(!this.state.tour) {
      this.props.fetchingData(true);

      this.props.toggleInVocabularyWordDetails(word.id);

      this.props.logActivity({
        activityId: word.in_vocabulary == true ? 49 : 48,
        model: 'word',
        modelId: word.id,
        comment: "word details"
      });
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _toggleFavorite = (e) => {
    e.preventDefault();

    const {word} = this.props.word;

    if(!this.state.tour) {
      this.props.fetchingData(true);
      this.props.toggleFavoriteWordDetails(word.id);

      this.props.logActivity({
        activityId: word.is_favourite == true ? 47 : 46,
        model: 'word',
        modelId: word.id,
        comment: "word details"
      });
    } else {
      this._toggleRegisterModal(true);
    }
  };

  fillLists = (id) => {
    const {word} = this.props.word;

    this.props.logActivity({activityId: id != 0 ? 19 : 20, model: 'list', modelId: id, comment: "word details"});

    const {subscriptionActive, listsLimit, applyLimit, webSubscriptionStatus} = this.props.purchases;
    const {listsNumber} = this.props.lists;
    let active = false;

    if (subscriptionActive || webSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      if (id == 0) {
        active = listsNumber.length < listsLimit;
      } else {
        active = true;
      }
    }

    this.toggleListsToFillModal(false);

    if (active) {
      this.props.fillListFromGlossaryWithSelectedWords({
        listId: id,
        selectedWords: [word.id]
      });
    } else {
      this.setState({
        subscriptionMessage: 'subscription_lists'
      });
      this._toggleSubscriptionAlert(true);
    }
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toSubscription = (productId) => {
    const {locale} = this.props.settings;
    
    this._toggleSubscriptionAlert(false);

    this.props.history.push(`/${locale}/products`, {back: true, product: productId}); 
  };

  changeMainTranslation = (translationId, e) => {
    e.preventDefault();

    const {word} = this.props.word;

    if(!this.state.tour) {
      this.props.changeMainTranslation(translationId, word.id);
      this.props.logActivity({activityId: 200, model: 'translation', modelId: translationId, comment: ""});
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _deleteUserTranslation = (translationId, e) => {
    e.preventDefault();

    const {word} = this.props.word;

    this.props.fetchingData(true);
    this.props.deleteUserTranslation(translationId, word.id);

    this.props.logActivity({activityId: 334, model: 'translation', modelId: translationId, comment: ""});
  };

  _renderItem = (item, index) => {
    const {fontSizeRation} = this.props.settings;
    const {tablet} = this.state;
    const {isConnected} = this.props.settings;
    const {userId} = this.props.user;
    const {translate} = this.props;

    return (
      <TranslationCell
        key={index}
        isConnected={isConnected}
        tablet={tablet}
        ratio={fontSizeRation}
        translation={item}
        translate={translate}
        deleteUserTranslation={this._deleteUserTranslation}
        changeMainTranslation={this.changeMainTranslation}
        playTrack={this.playTrack}
        userId={userId}
      />
    )
  };

  toggleAddTranslationModal = (status) => {
    if(!this.state.tour) {
      this.setState({
        addTranslationModalVisible: status
      })
    } else {
      this._toggleRegisterModal(true);
    }
  };

  addTranslation = (translation, e) => {
    e.preventDefault();

    const {word} = this.props.word;

    this.toggleAddTranslationModal(false);

    this.props.addUserTranslation(translation, word.id)
  };

  _reportBug = (e) => {
    e.preventDefault();
    const {word} = this.props.word;

    this._toggleBugModal(true);

    this.props.logActivity({activityId: 233, model: 'word', modelId: word.id});
  };

  _toUploadImage = (e) => {
    e.preventDefault();

    const {word} = this.props.word;

    if(!this.state.tour) {
      this._toggleModal('uploadImageVisible', true);

      this.props.logActivity({activityId: 312, model: 'word', modelId: word.id});
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _toggleModal = (type, status) => {
    this.setState({
      [type]: status
    })
  };

  _uploadUserWordImage = (file) => {
    this._toggleModal('uploadImageVisible', false);

    const {translate} = this.props;
    const {userId} = this.props.user;
    const {word} = this.props.word;
    // let body = this.createFormData(file);

    this.props.fetchingData(true);

    request
    .post(API_URL + 'uploadUserWordImageWeb')
    .attach('picture', file)
    .field('user_id', userId)
    .field('word_id', word.id)

    .then(response => {
      const {word} = this.props.word;

      this.props.fetchingData(false);

      if (response.body.result && response.body.result.filename) {
        this.props.get_word_details(word.id);

        this.props.addImageToListWords(response.result);
        this.props.addImageToCachedListWords(response.result);
        this.props.addImageToCachedTrainings(response.result);
        this.props.addImageToTrainingWords(response.result);
      }

      toastr.info('', response.body.result ? translate('word_image_uploaded') : translate('error'));
    }).catch(error => {
      // console.log(error)
      // console.log(error.response);
      this.props.fetchingData(false);
    });
  };

  _deleteUserImage = (e) => {
    e.preventDefault();

    const {word} = this.props.word;
    this.props.fetchingData(true);

    this.props.deleteUserImage(word.id);
    this.props.logActivity({activityId: 313, model: 'word', modelId: word.id});
  };

  setVariable = (dont, variable) => {
    if (dont == 1) {
      this.defineVar(variable);
    }
  };

  defineVar = async(variable) => {
    const {userId} = this.props.user;

    this.setState({
      neverShowWordAdded: true
    });

    try {
      localStorage.setItem(`${variable}${userId}`, "1")
    } catch (err) {
      console.log(err)
    }
  };

  _toggleAssociationModal = (status) => {
    this.setState({
      associationModal: status
    })
  };

  _addAssociation = (association, e) => {
    e.preventDefault();
    const {word} = this.props.word;

    this._toggleAssociationModal(false);
    this.props.fetchingData(true);

    this.props.addUserAssociation(association, word.id);

    this.props.logActivity({activityId: 323, model: 'word', modelId: word.id, comment: association})
  };

  _deleteUserWordAssociation = (e) => {
    e.preventDefault();
    const {word} = this.props.word;

    if(!this.state.tour) {
      this.props.fetchingData(true);

      this.props.deleteUserAssociation(word.association.id, word.id);

      this.props.logActivity({activityId: 324, model: 'word', modelId: word.id})
    } else {
      this._toggleRegisterModal(true);
    }
  };

  openAssociationModal = () => {
    if(!this.state.tour) {
      this._toggleAssociationModal(true);
      this.props.logActivity({activityId: 322})
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _toggleAddExampleModal = () => {
    if(!this.state.tour) {
      this._showExampleModal(true);
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _showExampleModal = (status) => {
    if(!this.state.tour) {
      this.setState({
        exampleModalVisible: status
      })
    } else {
      this._toggleRegisterModal(true);
    }

  };

  addWordExample = (example) => {
    const {word} = this.props.word;

    this._showExampleModal(false);

    this.props.fetchingData(true);
    this.props.addUserExample(word.id, example);
  };

  _setActiveTab = (k) => {
    this.setState({
      activeTab: k
    })
  };

  _toWord = (e, word, type) => {
    e.preventDefault();
    const {locale} = this.props.settings;


    this.setState({
      matchWord: word[type].replace("%20", ' '),
      wordDetails: false
    });

    this.timeOut1 = setTimeout(()=> {
      this.props.history.push(`/${locale}/dictionary/${word[type]}`, {back: true});

      this._getWordDetailsData();
    }, 100);
  };

  _toggleBugModal = (status) => {
    this.setState({
      bugModalVisible: status
    });
  };

  _toggleRegisterModal = (status) => {
    this.setState({
      registerModalVisible: status
    })
  };

  _toRegister = () => {
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/register`)
  };

  render() {
    const {word} = this.props.word;
    const {listsToFill} = this.props.lists;
    const {userId, userData} = this.props.user;
    const {translate} = this.props;

    const {listSize} = this.props.user;
    const {locale} = this.props.settings;
    const {
      addTranslationModalVisible,
      subscriptionAlertVisible,
      subscriptionMessage,
      tablet,
      listName,
      listsModalIsVisible,
      associationModal,
      exampleModalVisible,
      matchWord,
      activeTab,
      back,
      scrolled,
      bugModalVisible,
      uploadImageVisible,
      registerModalVisible
    } = this.state;
    const {fetchingData} = this.props.activity;


    // const translation = word.translations && word.translations.length > 0 ? word.translations[0].translation : "";
    const transcription = word && word.transcription ? word.transcription : '';
    const imageUrl = word && word.images && word.images.length > 0 ? word.images[0].filename : false;
    const imageUserId = word && word.images && word.images.length > 0 ? word.images[0].user_id : false;
    const {fontSizeRation, deviceWidth, deviceHeight, isConnected} = this.props.settings;
    const studyLanguage = userData && userData.study_language ? userData.study_language : 'en';

    let title = "";

    if (listName) {
      title = listName;
    } else if (word && word.word) {
      title = `${word.word}`;
    }

    const translation = word && word.main_translation && word.main_translation.translation ? word.main_translation.translation : "";

    const translations = word && word.otherTranslations ? word.otherTranslations : [];
    const examples = word && word.examples ? word.examples : [];
    const definitions = word && word.definitions ? word.definitions : [];
    const synonyms = word && word.synonyms ? word.synonyms : [];
    const antonyms = word && word.antonyms ? word.antonyms : [];

    const imageWidth = 300;

    let s3image = `${constants.S3MarkedImages}-${imageWidth}/${imageUrl}`;

    if (imageUserId && imageUserId == userId) {
      s3image = `${constants.IMAGE_URL}/${imageUrl}?width=${imageWidth}`;
    }

    const toolbar = classNames({
      'fixed-toolbar': scrolled >= 50,
      'justify-content-between listToolbar justify-content-center mb-4': true
    });

    return (
      <div className="worddetails position-relative">
        <Breadcrumb style={{marginTop: 50}}>
          {!back ? (
            <React.Fragment>
              <li className="breadcrumb-item">
                <Link to={`/${locale}/home`}>
                  {translate('home')}
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/${locale}/dictionary`}>
                  {translate('dictionary')}
                </Link>
              </li>
              <Breadcrumb.Item active>
                {matchWord}
              </Breadcrumb.Item>
            </React.Fragment>
          ) : (
            <li className="breadcrumb-item">
              <a href="#" onClick={(e)=>{
                  e.preventDefault();
                  this.props.history.goBack()
                }}>
                {translate('cancel_back')}
              </a>
            </li>
          )}
        </Breadcrumb>

        <WordToolbar
          translate={translate}
          ratio={fontSizeRation}
          deviceWidth={deviceWidth}
          deviceHeight={deviceHeight}
          toolbar={toolbar}
          userData={userData}

          word={word}

          toggleInVocabulary={this._toggleInVocabulary}
          toggleFavorite={this._toggleFavorite}

          addAssociation={this.openAssociationModal}
          deleteAssociation={this._deleteUserWordAssociation}
          addTranslation={this.toggleAddTranslationModal}
          toggleAddExampleModal={this._toggleAddExampleModal}

          toggleListsToFillModal={this.toggleListsToFillModal}
          reportBug={this._reportBug}
          uploadImage={this._toUploadImage}
          showUploadButton={!imageUrl}
        />

        <Container className="pageWrapper">
          <Row className="no-gutters">
            <Col md={6}>
              { word && word.id &&
              <div className="contentWrapper">
                <div
                  className="wordImageWrapper card"
                  style={{
                      marginTop: !tablet ? -15 : 0,
                      width: !tablet ? (deviceHeight > 650 ? 250 : 200) : 300,
                      height: !tablet ? (deviceHeight > 650 ? 180 : 145) : 218,
                      marginBottom: !tablet ? (deviceHeight > 650 ? 10 : 5) : 30
                }}>
                  {
                    imageUrl && imageUserId && imageUserId == userId && (
                      <a href="#"
                         className="deleteUserImageButton"
                         onClick={this._deleteUserImage}>
                        <FontAwesomeIcon className="deleteUserImage"
                                         style={{fontSize: !tablet ? 15 : 20}} icon={faTrashAlt}
                        />
                      </a>
                    )
                  }

                  {
                    (word && !imageUrl) && (
                      <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='auto' overlay={<Tooltip>{translate('upload_image')}</Tooltip>}>
                        <a href="#"
                           className="uploadUserImageButton"
                           onClick={this._toUploadImage}
                        >
                          <FontAwesomeIcon style={{fontSize: 15, color: colors.pine}} icon={faCamera}
                          />
                        </a>
                      </OverlayTrigger>
                    ) || null
                  }
                  {imageUrl && (
                    <Image
                      draggable="false"
                      onContextMenu={(e)=>e.preventDefault()}
                      rounded
                      alt={ word && word.word ? word.word : ''}
                      src={s3image}
                      style={{
                        width: !tablet ? (deviceHeight > 650 ? 250 : 200) : 300,
                        // height: !tablet ? (deviceHeight > 650 ? 180 : 145) : 218,
                        }}
                    />
                  )}
                </div>

                <a href="#" onClick={this.playSound}>
                  <span className="wordText" style={{fontSize: fontSizeRation * 20}}>
                    { word && word.word ? word.word : ''}
                  </span>
                </a>

                {(word && isConnected) && (<a href="#"
                                              className="d-flex justify-content-center align-items-center pv-2 mt-3 mb-3"
                                              style={{width: '100%'}}
                                              onClick={this.playSound}>
                  <FontAwesomeIcon style={{color: colors.greyishBrown}} className="toolbarButton" icon={faVolumeUp}/>
                </a>) || null}

                <span className="transcription" style={{fontSize: fontSizeRation * (!tablet ? 12 : 14)}}>
                  {transcription}
                </span>

                <div className="mainTranslationWrapper">
                  <span className="mainTranslation" style={{fontSize: fontSizeRation * (!tablet ? 14 : 16)}}>
                    {translation}
                  </span>
                </div>

                {word && word.association && word.association.association && (
                  <Card className="w-100 d-flex mb-3 mt-1 p-1 justify-content-center align-items-center"
                        style={{position: 'relative'}}>

                    <span className="association text-center"
                          style={{fontSize: fontSizeRation * (!tablet ? 14 : 16),paddingHorizontal: !tablet ? 30 : 45}}>
                      {word.association.association}
                    </span>

                    <a href="#" className="closeButtonWrapper" onClick={this._deleteUserWordAssociation}>
                      <FontAwesomeIcon className="closeButton" icon={faTimes}
                                       style={{fontSize: !tablet ? 20 : 25,}}/>
                    </a>
                  </Card>
                )
                || null}

                <div className="translations w-100">
                  {
                    translations.map((item, index) => {
                      return this._renderItem(item, index)
                    })
                  }

                </div>
              </div>
              }
            </Col>
            <Col md={6}>
              {
                word && word.id ? (
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={activeTab}
                    onSelect={(k) => this._setActiveTab(k)}
                  >
                    {
                      examples.length > 0 && (
                        <Tab eventKey="examples" title={translate('examples')}>
                          {
                            examples.map((example, index) => {
                              return <WordExample
                                key={index}
                                ratio={fontSizeRation}
                                playTrack={this.playExample}
                                example={example}
                              />
                            })
                          }
                        </Tab>
                      )
                    }

                    {
                      definitions.length > 0 && (
                        <Tab eventKey="definitions" title={translate('definitions')}>
                          <ul>
                            {
                              definitions.map((definition, index) => {
                                return <WordDefinition
                                  key={index}
                                  ratio={fontSizeRation}
                                  definition={definition}
                                />
                              })
                            }
                          </ul>
                        </Tab>
                      )
                    }
                    {
                      synonyms.length > 0 && (
                        <Tab eventKey="synonyms" title={translate('synonyms')}>
                          <div className="d-flex flex-wrap">
                            {
                              synonyms.map((synonym, index) => {
                                return <WordSynonymAntonym
                                  key={index}
                                  ratio={fontSizeRation}
                                  word={synonym}
                                  type="synonym"
                                  toWord={this._toWord}
                                />
                              })
                            }
                          </div>
                        </Tab>
                      )
                    }
                    {
                      antonyms.length > 0 && (
                        <Tab eventKey="antonyms" title={translate('antonyms')}>
                          {
                            antonyms.map((antonym, index) => {
                              return <WordSynonymAntonym
                                key={index}
                                ratio={fontSizeRation}
                                word={antonym}
                                type="antonym"
                                toWord={this._toWord}
                              />
                            })
                          }
                        </Tab>
                      )
                    }
                  </Tabs>
                ) : null
              }

            </Col>


          </Row>

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={subscriptionMessage}
                translate={translate}
              />
            )
          }

          {
            addTranslationModalVisible && (
              <AddTranslationModal
                onClose={this.toggleAddTranslationModal}
                isVisible={addTranslationModalVisible}
                onSubmit={this.addTranslation}
                toggleAddTranslationModal={this.toggleAddTranslationModal}
                ratio={fontSizeRation}
                translate={translate}
              />
            )
          }

          {listsModalIsVisible && (
            <ListsToFill
              isVisible={listsModalIsVisible}
              lists={listsToFill}
              fillLists={this.fillLists}
              listSize={listSize}
              selectedWords={1}
              onClose={this.toggleListsToFillModal}
              ratio={fontSizeRation}
              translate={translate}
            />
          )}

          {
            exampleModalVisible && (
              <AddWordExample
                onClose={this._showExampleModal}
                isVisible={exampleModalVisible}
                onSubmit={this.addWordExample}
                ratio={fontSizeRation}
                word={word.word}
                translate={translate}
              />
            )
          }

          {
            associationModal && (
              <AddAssociationModal
                onClose={this._toggleAssociationModal}
                isVisible={associationModal}
                onSubmit={this._addAssociation}
                ratio={fontSizeRation}
                translate={translate}
              />
            )
          }

          {
            bugModalVisible && (
              <ReportBug
                onClose={this._toggleBugModal}
                isVisible={bugModalVisible}
                type="word"
                extra={word ? word.word : false}
              />
            )
          }

          {
            uploadImageVisible && (
              <UploadImage
                translate={translate}
                onClose={this._toggleModal}
                isVisible={uploadImageVisible}
                onSubmit={this._uploadUserWordImage}
              />
            )
          }
          {registerModalVisible && (
            <RegisterAlert
              onClose={this._toggleRegisterModal}
              isVisible={registerModalVisible}
              translate={translate}
              toRegister={this._toRegister}
            />
          )}
        </Container>
        <Loader fetchingData={fetchingData}/>

        <Helmet>
          <title>{`${translate('app_title_dictionary_word')} ${word && word.word ? word.word.toUpperCase() : ''} ${translate('with_mnemonic_words')}`}</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="description" content={translate('app_dictionary_word_description')} />
          <meta property="og:url" content={window.location.href} />
          <meta name="og:description" content={translate('app_dictionary_word_description')} />
          <meta property="og:title" content={`${translate('app_title_dictionary_word')} ${word && word.word ? word.word.toUpperCase() : ''} ${translate('with_mnemonic_words')}`} />
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  word: state.word,
  lists: state.lists,
  purchases: state.purchases,
  userContacts: state.userContacts,
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize)

});

function bindAction(dispatch) {
  return {
    get_word_details: (wordId, byWord, history) => dispatch(get_word_details(wordId, byWord, history)),
    resetWordDetails: () => dispatch(resetWordDetails()),
    getUserListsToFill: () => dispatch(getUserListsToFill()),
    fillListFromGlossaryWithSelectedWords: (data) => dispatch(fillListFromGlossaryWithSelectedWords(data)),
    toggleInVocabularyWordDetails: (wordId) => dispatch(toggleInVocabularyWordDetails(wordId)),
    toggleFavoriteWordDetails: (wordId) => dispatch(toggleFavoriteWordDetails(wordId)),
    get_extended_info: (method, word_id) => dispatch(get_extended_info(method, word_id)),
    logActivity: (data) => dispatch(logActivity(data)),
    getListsNumber: () => dispatch(getListsNumber()),
    addUserTranslation: (translation, wordId) => dispatch(addUserTranslation(translation, wordId)),
    addUserAssociation: (association, wordId) => dispatch(addUserAssociation(association, wordId)),
    changeMainTranslation: (translationId, wordId) => dispatch(changeMainTranslation(translationId, wordId)),
    deleteUserTranslation: (translationId, wordId) => dispatch(deleteUserTranslation(translationId, wordId)),
    getUserContacts: () => dispatch(getUserContacts()),
    shareWord: (wordId, contacts) => dispatch(shareWord(wordId, contacts)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    resetDictionaryWords: () => dispatch(resetDictionaryWords()),
    resetDictionarySearchField: (status) => dispatch(resetDictionarySearchField(status)),
    updateCurrentListWords: () => dispatch(updateCurrentListWords()),
    getListWords: (listId) => dispatch(getListWords(listId)),
    deleteUserImage: (wordId) => dispatch(deleteUserImage(wordId)),
    addUserExample: (wordId, example) => dispatch(addUserExample(wordId, example)),
    deleteUserAssociation: (associationId, wordId) => dispatch(deleteUserAssociation(associationId, wordId)),

    addImageToListWords: (image) => dispatch(addImageToListWords(image)),
    addImageToLists: (image) => dispatch(addImageToLists(image)),
    addImageToCachedListWords: (image) => dispatch(addImageToCachedListWords(image)),
    addImageToTrainingWords: (image) => dispatch(addImageToTrainingWords(image)),
    addImageToCachedTrainings: (image) => dispatch(addImageToCachedTrainings(image)),

  };
}

export default connect(mapStateToProps, bindAction)(WordDetails);
