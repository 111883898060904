import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faCircle} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';

const CourseInfo = ({isVisible, onClose, slug, translate}) => {
  let description = translate('course_info_description');
  let header = 'course_info_header';


  if(slug == 1000) {
    description = description.replace("%1", "50");
  } else if(slug == 2000) {
    description = description.replace("%1", "60");
    header = 'course_info_header_2000';
  } else if(slug == 3000) {
    description = description.replace("%1", "70");
    header = 'course_info_header_3000';
  } else if(slug == 4000) {
    description = description.replace("%1", "150");
    header = 'course_info_header_4000';
  }


  return (
    <Modal
      className="listnamemodal"
      show={isVisible}
      onHide={()=>onClose(false)}
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <Translate id={header}/>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="text-center">
        <p style={{whiteSpace: 'pre-line', textAlign: 'left'}}>
          {description}
        </p>
        <a href="https://youtu.be/J4o7g-ZS_qo" target="_new">
          {translate('watch_video')}
        </a>
      </Modal.Body>
    </Modal>
  )
};

export default connect(null, null)(CourseInfo);
