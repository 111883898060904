import * as types from './types';

export const getParentGlossaries = (level) => (dispatch) => {
  dispatch({ type: types.GET_PARENT_GLOSSARIES, payload: level });
};

export const searchGlossaries = (glossaryName) => (dispatch) => {
  dispatch({ type: types.SEARCH_GLOSSARIES, payload: glossaryName });
};

export const searchChildGlossaries = (glossaryId, glossaryName) => (dispatch) => {
  dispatch({ type: types.SEARCH_CHILD_GLOSSARIES, payload: {glossaryId, glossaryName} });
};

export const resetGlossaries = () => (dispatch) => {
  dispatch({ type: types.RESET_PARENT_GLOSSARIES });
};

export const setCurrentParent = (parent) => (dispatch) => {
  dispatch({ type: types.SET_CURRENT_PARENT, payload: parent });
};

export const getParentChildren = (id) => (dispatch) => {
  dispatch({ type: types.GET_PARENT_CHILDREN, payload: id });
};

export const resetChildGlossaries = () => (dispatch) => {
  dispatch({ type: types.RESET_CHILD_GLOSSARIES });
};

export const setCurrentChild = (child) => (dispatch) => {
  dispatch({ type: types.SET_CURRENT_CHILD, payload: child });
};

export const getGlossary = (id, page) => (dispatch) => {
  dispatch({ type: types.GET_GLOSSARY_WORDS, payload: {id, page} });
};

export const gettingNewChunk = () => (dispatch) => {
  dispatch({ type: types.GET_GLOSSARY_WORDS_NEXT_CHUNK });
};

export const resetGlossaryWords = (id, page) => (dispatch) => {
  dispatch({ type: types.RESET_GLOSSARY_WORDS, payload: {id, page} });
};

export const resetCurrentChildGlossary = () => (dispatch) => {
  dispatch({ type: types.RESET_CURRENT_CHILD_GLOSSARY});
};

export const resetGlossaryWordsWithoutPages = () => (dispatch) => {
  dispatch({ type: types.RESET_GLOSSARY_WORDS_WITHOUT_PAGES });
};

export const toggleGlossaryWordInVocabulary = (word_id, index) => (dispatch) => {
  dispatch({ type: types.TOGGLE_GLOSSARY_WORD_VOCABULARY, payload: {word_id, index} });
};

export const toggleGlossaryWordInFavorites = (word_id, index) => (dispatch) => {
  dispatch({ type: types.TOGGLE_GLOSSARY_WORD_FAVORITES, payload: {word_id, index} });
};

export const toggleGlossaryInFavorites = (glossaryId) => (dispatch) => {
  dispatch({ type: types.TOGGLE_GLOSSARY_IN_FAVORITES, payload: {glossaryId} });
};

export const getCurrentGlossaryData = (glossaryId) => (dispatch) => {
  dispatch({ type: types.GET_CURRENT_GLOSSARY_DATA, payload: {glossaryId} });
};

export const get_glossaries_for_list = (limit) => (dispatch) => {
  dispatch({ type: types.GET_GLOSSARIES_FOR_LIST, payload: limit });
};

export const getGlossaryWordsForList = (data) => (dispatch) => {
  dispatch({ type: types.GET_GLOSSARY_WORDS_FOR_LIST, payload: data });
};

export const searchWordsInThisGlossaryForList = (glossaryId, word) => (dispatch) => {
  dispatch({ type: types.SEARCH_GLOSSARY_WORDS_FOR_LIST, payload: {glossaryId, word} });
};

export const sendCards = (data) => (dispatch) => {
  dispatch({ type: types.SEND_CARDS, payload: data });
};

export const searchWordsInThisGlossary = (glossaryId, word) => (dispatch) => {
  dispatch({ type: types.SEARCH_GLOSSARY_WORDS, payload: {glossaryId, word} });
};

export const setGlossariesFromCached = (data) => (dispatch) => {
  dispatch({ type: types.SET_GLOSSARIES_FROM_CACHE, payload: data });
};

export const setCachedGlossaryWords = (data) => (dispatch) => {
  dispatch({ type: types.SET_CACHED_GLOSSARY_WORDS, payload: data });
};

export const setCachedGlossaryWordsForList = (data) => (dispatch) => {
  dispatch({ type: types.SET_CACHED_GLOSSARY_WORDS_FOR_LIST, payload: data });
};

export const appendCachedGlossaryWords = (data) => (dispatch) => {
  dispatch({ type: types.APPEND_CACHED_GLOSSARY_WORDS, payload: data });
};

export const appendCachedGlossaryWordsForList = (data) => (dispatch) => {
  dispatch({ type: types.APPEND_CACHED_GLOSSARY_WORDS_FOR_LIST, payload: data });
};

export const resetGlossaryWordsData = () => (dispatch) => {
  dispatch({ type: types.RESET_GLOSSARY_WORDS_DATA });
};

export const resetGlossaryWordsDataForList = () => (dispatch) => {
  dispatch({ type: types.RESET_GLOSSARY_WORDS_DATA_FOR_LIST });
};