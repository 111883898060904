import React from 'react';

const WordDefinition = ({definition, ratio}) => {

  return (
    <li className="wordDefinition">
      <span className="word" style={{fontSize: ratio * 14}}>
        {definition.definition}
      </span>
    </li>
  );
};

export default WordDefinition;