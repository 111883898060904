import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faCogs,
  faCheckCircle,
  faFilter,
  faTimes,
  faSearch,
  faPlus,
  faSort,
  faTag,
  faTags,
  faVideo
} from '@fortawesome/free-solid-svg-icons';

import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ListsToolbar = ({
  lists,
  translate,
  ratio,
  onSearchLists,
  handleKeyPress,
  setListName,
  listName,
  newListName,
  toolbar,
  handleList,
  list,
  listRef,
  toggleDelete,
  cleanCache,
  toggleModal,
  setHideKnown,
  hideKnown,
  toGenerator,
  active,
  toggleArchiveStatus,
  showArchive,
  togglePostpone,
  userData,
  openTagsModal,
  timeOut,
  tour,
  toggleRegister
}) => (

  <Container fluid>
    <div className={toolbar}>

      <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">

        <InputGroup style={{maxWidth: 300}} className="mb-2 mb-lg-0">
          <FormControl
            disabled={lists.length == 0 || tour}
            onKeyPress={(e)=>handleKeyPress('search', e)}
            value={listName}
            onChange={(e)=>onSearchLists(e.target.value)}
            placeholder={translate('search_lists')}
          />
          <InputGroup.Append>
            <Button disabled={listName.length === 0} variant="outline-secondary" onClick={()=>onSearchLists('')}>
              <FontAwesomeIcon icon={faTimes}/>
            </Button>
          </InputGroup.Append>
        </InputGroup>

        <div className="d-flex justify-content-end align-items-center">
          <OverlayTrigger
            delay={{ show: 1000, hide: 400 }}
            placement='auto' overlay={<Tooltip>{translate('filter_by_tag')}</Tooltip>}>
            <a href="#"
               className="mr-3 p2"
               variant="outline-secondary"
               onClick={(e)=> {
                e.preventDefault();
                if(lists.length !== 0) {
                  toggleModal('tagsFilterModalVisible', true);
                }
               }}
            >
              <FontAwesomeIcon icon={faTags}/>
            </a>
          </OverlayTrigger>

          <OverlayTrigger
            delay={{ show: 1000, hide: 400 }}
            placement='auto' overlay={<Tooltip>{translate('sort_your_lists')}</Tooltip>}>
            <a href="#"
               className="mr-3 p2"
               onClick={(e)=>{
                e.preventDefault();
                if(lists.length !== 0) {
                  toggleModal('filtersModalVisible', true)
                }
            }}
            >
              <FontAwesomeIcon style={{fontSize: 20}} icon={faSort}/>
            </a>
          </OverlayTrigger>

          <Dropdown
            alignRight
            className="mr-1"
            onToggle={(e)=>{
            timeOut = setTimeout(()=> {
              if(e && listRef && listRef.current) {
                 listRef.current.focus();
              }
            }, 100)
        }}>
            <OverlayTrigger
              delay={{ show: 1000, hide: 400 }}
              placement='auto' overlay={<Tooltip>{translate('create_lists')}</Tooltip>}>
              <Dropdown.Toggle
                as="a"
                className="p2 toggled-link"
              >
                <FontAwesomeIcon icon={faPlus} style={{fontSize: 20}}/>
              </Dropdown.Toggle>
            </OverlayTrigger>

            <Dropdown.Menu style={{minWidth: 240}}>
              <Dropdown.Item
                as="button"
                onClick={()=>toGenerator(true)}
              >
                {translate('create_level_lists')}
              </Dropdown.Item>

              <Dropdown.Divider />

            <span className="d-block p-2 text-secondary text-center">
              {translate('add_new_list')}
            </span>

              <InputGroup className="ph1">
                <FormControl
                  disabled={tour}
                  ref={listRef}
                  style={{fontSize: 12}}
                  onKeyPress={(e)=> handleKeyPress('new_list', e)}
                  value={newListName}
                  onChange={(e)=> setListName(e.target.value)}
                  placeholder={translate('enter_list_name')}
                />
                <InputGroup.Append>
                  <Button
                    style={{fontSize: 12}}
                    disabled={newListName.length == 0 || tour}
                    variant="outline-secondary"
                    onClick={()=>handleList(newListName, false)}>
                    <FontAwesomeIcon icon={faPlus}/>
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Dropdown.Menu>
          </Dropdown>

          <OverlayTrigger
            delay={{ show: 1000, hide: 400 }}
            placement='auto' overlay={<Tooltip>{translate('watch_video_instructions')}</Tooltip>}
          >
            <a href="https://youtu.be/se2x7QSJGhM" className="ml-2 p2" target="_blank">
              <FontAwesomeIcon icon={faVideo} style={{fontSize: 20}}/>
            </a>
          </OverlayTrigger>

          <Dropdown alignRight>
            <Dropdown.Toggle
              as="a"
              disabled={lists.length == 0}
              className="p2 toggled-link"
            >
              <FontAwesomeIcon
                style={{fontSize: 20}}
                icon={faCogs}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as="button" onClick={(e) => openTagsModal(false, e)}>
                {translate('tags')}
              </Dropdown.Item>

              <Dropdown.Item as="button" onClick={()=> {
                if(!tour) {
                  toggleModal('listSizeModalVisible', true)
                } else {
                  toggleRegister(true)
                }
              }}>
                {translate('set_lists_capacity')}
              </Dropdown.Item>

              <Dropdown.Divider />

              <Dropdown.Item as="button" onClick={setHideKnown}>
                {translate(userData.show_learnt == "0" ? "show_known" : "hide_known")}
              </Dropdown.Item>

              <Dropdown.Item as="button" onClick={toggleArchiveStatus}>
                {translate(userData.show_archive == "1" ? "hide_archived_lists" : "show_archived_lists")}
              </Dropdown.Item>

              <Dropdown.Item as="button" onClick={togglePostpone}>
                {translate(userData && userData.postpone != "1" ? "auto_postpone_on" : "auto_postpone_off")}
              </Dropdown.Item>

              <Dropdown.Divider/>

              <Dropdown.Item
                disabled={lists.length === 0}
                as="button"
                className="text-danger"
                onClick={toggleDelete}
              >
                {translate('delete_some_lists')}
              </Dropdown.Item>

              <Dropdown.Item
                disabled={lists.length === 0}
                as="button"
                className="text-danger"
                onClick={cleanCache}
              >
                {translate('clean_lists_cache')}
              </Dropdown.Item>

              {/*<Dropdown.Divider/>*/}

              {/*<Dropdown.Item href="https://youtu.be/DEfFq_xO25c" target="_blank">
               {translate('watch_video_instructions')}
               </Dropdown.Item>*/}

            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  </Container>
);

export default ListsToolbar;
