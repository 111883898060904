import React, {PureComponent} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp,} from '@fortawesome/free-solid-svg-icons';

const WordSynonymAntonym = ({word, type, toWord, ratio}) => {

  return (
    <div className="wordSynonym">
      <div className="wordWrapper">
        <div className="wordTranslationWrapper">
          <a href="#" onClick={(e)=>toWord(e, word, type)}>
            <span className="word" style={{fontSize: ratio * 14}}>
              {word[type]}
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default WordSynonymAntonym;