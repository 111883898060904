import request from 'superagent';
import {select, put, delay} from 'redux-saga/effects';
import moment from 'moment';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logActivity} from './logSagas';
import {getUserBadges} from './badgesSagas';
import {logApiError} from './logSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* generateCourseLists(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'generateCourseLists')
    .send({
      user_id: userId,
      name: data.payload.name,
      slug: data.payload.slug,
      only_first: true
    });

    if (result.statusCode == 200) {
      // toastr.info(translate('wish_good_luck'), '');

      yield getCourseData();
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'generateCourseLists',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getInitialCourseData(data) {
  const {userId} = yield select(state => state.user);
  const {courseData, currentCourseSlug} = yield select(state => state.course);

  yield put({
    type: types.FETCHING_DATA,
    payload: true
  });

  try {
    const result = yield request
    // .get(API_URL + 'getInitialCourseDataOptimized')
    .get(API_URL + 'getInitialCourseData')
    .query({
      user_id: userId ? userId : 1,
      slug: data && data.payload ? data.payload : currentCourseSlug,
      // slug: courseData ? courseData.slug : '1000'
    });

    // console.log("getInitialCourseData", data, result.body)

    yield put({
      type: types.SET_COURSE_DATA,
      payload: result.body
    });

    if (result.body.course_data) {
      localStorage.setItem('courseId', result.body.course_data.id);
    }

    yield put({
      type: types.SET_CACHE_COURSE_DATA,
      payload: {
        slug: data && data.payload ? data.payload : currentCourseSlug,
        data: result.body
      }
    });

    if (!result.body.rate && result.body.course_data) {
      let today = moment();
      let regDate = moment(result.body.course_data.created_at);
      let diff = today.diff(regDate, 'days');

      let todayDay = today.format('YYYY-MM-DD');

      /*try {
       const todayRefused = yield AsyncStorage.getItem("notNowCourse");

       if (todayRefused == null || todayRefused != todayDay) {
       if (diff >= 10 && diff % 5 == 0) {

       yield put(NavigationActions.navigate({routeName: 'CourseRate'}));
       yield logActivity({payload: {activityId: 290}});
       yield setRedirectedToCourse()
       }
       }
       } catch (error) {
       }*/
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getInitialCourseData',
        payload: {currentCourseSlug},
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getCoursesMistakesTestsRepeats() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getCoursesMistakesTestsRepeats')
    .query({
      user_id: userId,
    });

    // console.log("getCoursesMistakesTestsRepeats", result.body.data, result.body.data.length)

    if (result.body.data.length > 0) {
      for (let i = 0; i < result.body.data.length; i++) {

        let course = result.body.data[i];

        yield put({
          type: types.SET_CACHE_COURSE_DATA,
          payload: {
            slug: course.slug,
            data: course
          }
        });
      }
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getCoursesMistakesTestsRepeats',
        payload: {},
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getCourseData() {
  const {userId} = yield select(state => state.user);
  const {courseData, currentCourseSlug} = yield select(state => state.course);

  yield put({
    type: types.FETCHING_DATA,
    payload: true
  });

  try {
    const result = yield request
    .get(API_URL + 'getInitialCourseData')
    .query({
      user_id: userId ? userId : 1,
      // slug: courseData ? courseData.slug : '1000',
      slug: currentCourseSlug
    });

    if (result.body.course_data) {
      localStorage.setItem('courseId', result.body.course_data.id);
    }

    yield put({
      type: types.SET_COURSE_DATA,
      payload: result.body
    });

    yield put({
      type: types.SET_CACHE_COURSE_DATA,
      payload: result.body
    });

    if (!result.body.rate && result.body.course_data) {
      let today = moment();
      let regDate = moment(result.body.course_data.created_at);
      let diff = today.diff(regDate, 'days');

      let todayDay = today.format('YYYY-MM-DD');

      /*try {
       const todayRefused = yield AsyncStorage.getItem("notNowCourse");

       if (todayRefused == null || todayRefused != todayDay) {
       if (diff >= 10 && diff % 5 == 0) {
       // yield put(NavigationActions.navigate({routeName: 'CourseRate'}));
       yield logActivity({payload: {activityId: 290}});
       yield setRedirectedToCourse()
       }
       }
       } catch (error) {
       // console.log(error.message);
       }*/
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getCourseData',
        payload: {currentCourseSlug},
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

const setRedirectedToCourse = async() => {
  try {
    // await AsyncStorage.setItem("notNowCourse", moment().format('YYYY-MM-DD'));
  } catch (error) {
    console.log(error);
  }
}

export function* checkForCourseDebts(data) {
  try {
    const result = yield request
    .get(API_URL + 'checkForCoursesDebts')
    // .get(API_URL + 'checkForCoursesDebtsOptimized')
    .query({
      user_id: data.payload ? data.payload : 1,
    });

    // console.log("COURSE debts", result.body)

    yield put({
      type: types.SET_TIME_TO_GO_TO_COURSE,
      payload: result.body.data
    });

    yield put({
      type: types.SET_COURSE_DEBTS,
      payload: result.body.debts
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'checkForCourseDebts',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* getCourseListStatistics(data) {
  const {userId} = yield select(state => state.user);
  const {courseData} = yield select(state => state.course);

  try {
    const result = yield request
    .get(API_URL + 'getCourseListStatistics')
    .query({
      user_id: userId,
      list_id: data.payload,
      course_id: courseData.id
    });

    yield put({
      type: types.SET_COURSE_LIST_STATISTICS,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getCourseListStatistics',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getUpdatedCourseLists(data) {
  const {userId} = yield select(state => state.user);
  const {courseData, currentCourseSlug} = yield select(state => state.course);

  if (!userId) return;
  if(!courseData || !courseData.id) return null;

  yield delay(500);

  try {
    const result = yield request
    .get(API_URL + 'getUpdatedCourseLists')
    .query({
      user_id: userId,
      course_id: courseData.id
    });

    // console.log("getUpdatedCourseLists", result.body, currentCourseSlug);

    yield put({
      type: types.UPDATE_COURSE_LISTS,
      payload: result.body
    });

    yield put({
      type: types.UPDATE_CACHED_COURSE_LISTS,
      payload: {
        slug: currentCourseSlug,
        data: result.body
      }
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getUpdatedCourseLists',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getUpdatedListScores(data) {
  const {userId} = yield select(state => state.user);
  const {courseData} = yield select(state => state.course);

  if (!userId) return;
  try {
    const result = yield request
    .get(API_URL + 'getUpdatedListScores')
    .query({
      user_id: userId,
      list_id: data.payload
    });

    yield put({
      type: types.UPDATE_CURRENT_LIST_SCORES,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getUpdatedListScores',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getUpdatedCourseData(data) {
  const {userId} = yield select(state => state.user);
  const {courseData, currentCourseSlug, currentCourseId} = yield select(state => state.course);

  if(!courseData || !courseData.id) return null;
  try {
    const result = yield request
    .get(API_URL + 'getUpdatedCourseData')
    .query({
      user_id: userId,
      course_id: courseData.id
    });

    yield put({
      type: types.UPDATE_CACHED_COURSE_DATA,
      payload: {
        slug: currentCourseSlug,
        data: result.body.data
      }
    });

    yield put({
      type: types.UPDATE_COURSE_DATA,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getUpdatedCourseData',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getJustCourseData(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getJustCourseData')
    .query({
      user_id: userId ? userId : 1,
      course_id: data.payload.courseId
    });

    // console.log("getJustCourseData", result.body.data)

    yield put({
      type: types.UPDATE_COURSE_DATA,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getJustCourseData',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getCourseListWords(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    // .get(API_URL + 'getCourseListWords')
    .get(API_URL + 'getCourseListWordsOptimized')
    .query({
      user_id: userId ? userId : 1,
      list_id: data.payload
    });

    // console.log("getCourseListWords", result.body)

    yield put({
      type: types.SET_COURSE_LIST_WORDS,
      payload: result.body
    });

    yield put({
      type: types.SET_CACHE_LIST_WORDS,
      payload: {
        listId: data.payload,
        listWords: result.body.data
      }
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getCourseListWords',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getOnlyMistakesCourse(data) {
  const {userId} = yield select(state => state.user);
  const {courseData, currentCourseSlug} = yield select(state => state.course);

  try {
    const result = yield request
    .get(API_URL + 'getOnlyMistakesCourse')
    .query({
      user_id: userId,
      // slug: courseData ? courseData.slug : '1000'
      slug: currentCourseSlug
    });

    yield put({
      type: types.SET_ONLY_MISTAKES_COURSE,
      payload: {
        slug: currentCourseSlug,
        data: result.body.data
      }
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getOnlyMistakesCourse',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getCourseListData(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getCourseListData')
    .query({
      user_id: userId,
      list_id: data.payload
    });

    yield put({
      type: types.SET_COURSE_LIST_DATA,
      payload: result.body
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getCourseListData',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getCurrentCourseListData(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getCurrentCourseListData')
    .query({
      user_id: userId ? userId : 1,
      list_id: data.payload.listId
    });

    // console.log('getCurrentCourseListData', result.body)
    yield put({
      type: types.SET_CURRENT_COURSE_LIST_DATA,
      payload: result.body
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getCurrentCourseListData',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getCourseListCalendar(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getCourseListCalendar')
    .query({
      user_id: userId,
      list_id: data.payload
    });

    yield put({
      type: types.SET_COURSE_LIST_CALENDAR,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getCourseListCalendar',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* deleteCourseData(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .delete(API_URL + 'deleteCourseData')
    .send({
      user_id: userId,
      course_id: data.payload.courseData.id
    });

    if (result.statusCode == 200) {
      yield getCourseData();
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'deleteCourseData',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* removeCourseListFromCalendar(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .delete(API_URL + 'removeCourseListFromCalendar')
    .send({
      user_id: userId,
      list_id: data.payload
    });

    if (result.statusCode == 200) {
      yield getCourseData();
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'removeCourseListFromCalendar',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getCourseCalendarMonthData(data) {
  const {userId} = yield select(state => state.user);
  const {courseData} = yield select(state => state.course);

  try {
    const result = yield request
    .get(API_URL + 'getCourseCalendarMonthDataWeb')
    .query({
      user_id: userId,
      date: data.payload,
      course_id: courseData.id
    });

    // console.log("getCourseCalendarMonthData", result.body)

    yield put({
      type: types.SET_COURSE_CALENDAR_MONTH_DATA,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getCourseCalendarMonthData',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getCourseStatistics(data) {
  const {userId} = yield select(state => state.user);
  const {courseData} = yield select(state => state.course);

  try {
    const result = yield request
    .get(API_URL + 'getCourseStatistics')
    .query({
      user_id: userId,
      course_id: courseData.id
    });

    yield put({
      type: types.SET_COURSE_STATISTICS,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getCourseStatistics',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getCourseVocabulary(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;
  const {courseData} = yield select(state => state.course);

  if (!courseData.id) {
    data.payload.history.goBack();
  }

  let param = {};
  param.user_id = userId;
  param.page = params.page;
  param.course_id = courseData.id

  try {
    const result = yield request
    .get(API_URL + 'getCourseVocabulary')
    .query(param);

    // console.log("getCourseVocabulary", result.body)

    yield put({
      type: types.SET_COURSE_VOCABULARY,
      payload: result.body
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getCourseVocabulary',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* moveToGeneralVocabulary(data) {
  const {userId} = yield select(state => state.user);
  const {courseData} = yield select(state => state.course);
  const translate = yield select(state => getTranslate(state.localize));

  if (!courseData.id) {
    data.payload.history.goBack();
  }

  let param = {};
  param.user_id = userId;
  param.course_id = courseData.id

  try {
    const result = yield request
    .post(API_URL + 'moveToGeneralVocabulary')
    .send(param);

    // console.log("moveToGeneralVocabulary", result.body)

    if (result.body.result == -1) {
      toastr.info(translate('all_words_already_in_vocabulary'));
    } else if (result.body.result) {
      toastr.success(translate('successfully_moved_to_vocabulary'));
    } else {
      toastr.info(translate('error'));
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'moveToGeneralVocabulary',
        payload: '',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* performCourseBulkAction(data) {
  const {userId} = yield select(state => state.user);
  const {courseData} = yield select(state => state.course);
  const translate = yield select(state => getTranslate(state.localize));

  if (!courseData.id) {
    return null;
    // data.payload.history.goBack();
  }

  let params = data.payload;
  params.user_id = userId;
  params.course_id = courseData.id

  try {
    const result = yield request
    .post(API_URL + 'performCourseBulkAction')
    .send({
      user_id: userId,
      list_id: params.listId,
      words: params.words,
      type: params.type,
      source: params.source,
      course_id: courseData.id
    });

    // console.log("performCourseBulkAction", result.body)

    if (params.type == 'delete') {
      if (result.body.result) {

        yield put({
          type: types.RESET_COURSE_VOCABULARY,
          payload: true
        });

        yield put({
          type: types.FETCHING_DATA,
          payload: true
        });
        yield getCourseVocabulary({payload: {page: 1}})

        toastr.info("", translate('words_deleted_from_course_vocabulary'))
      }
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'performCourseBulkAction',
        payload: '',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getAllCourseMistakes(data) {
  const {userId} = yield select(state => state.user);
  const {courseData} = yield select(state => state.course);

  let param = {};
  param.user_id = userId;
  param.course_id = courseData.id;

  // console.log("getAllCourseMistakes",data.payload)

  if (!courseData.id) {
    data.payload.history.goBack();
  }

  try {
    const result = yield request
    .get(API_URL + 'getAllCourseMistakes')
    .query(param);

    yield put({
      type: types.SET_ALL_COURSE_MISTAKES,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getAllCourseMistakes',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* rateCourse(data) {
  const {userId} = yield select(state => state.user);
  const {courseData, currentCourseSlug} = yield select(state => state.course);

  try {
    const result = yield request
    .post(API_URL + 'rateCourse')
    .send({
      user_id: userId,
      // slug: courseData ? courseData.slug : '1000',
      slug: currentCourseSlug,
      subject: 'course rating',
      rate: data.payload.rate,
      message: data.payload.message,
    });

    // toastr.info(translate('thanks_for_feedback'), '');

    yield put({
      type: types.SET_COURSE_RATE,
      payload: true
    });

    if (courseData) {
      yield getUserBadges({payload: courseData.id});
    }

    // yield put(NavigationActions.back());
    yield setRate(userId);
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'rateCourse',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


export function* toggleInCourseVocabulary(data) {
  const {userId} = yield select(state => state.user);
  const {courseData} = yield select(state => state.course);

  try {
    const result = yield request
    .post(API_URL + 'toggleInCourseVocabulary')
    .send({
      user_id: userId,
      word_id: data.payload.wordId,
      course_id: courseData.id
    });

    // toastr.info(translate(result.body.result === true ? 'deleted_from_course_vocabulary' : 'added_in_course_vocabulary'), '');

    yield put({
      type: types.UPDATE_IN_COURSE_VOCABULARY,
      payload: {
        listId: data.payload.listId,
        wordId: data.payload.wordId,
        courseId: courseData.id
      }
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'toggleInCourseVocabulary',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


export function* deleteFromCourseVocabulary(data) {
  const {userId} = yield select(state => state.user);
  const {courseData} = yield select(state => state.course);

  try {
    const result = yield request
    .post(API_URL + 'deleteFromCourseVocabulary')
    .send({
      user_id: userId,
      word_id: data.payload.word.word_id,
      course_id: data.payload.word.course_id,
    });

    // console.log("deleteFromCourseVocabulary", result.body)

    if (result.body.result) {
      yield put({
        type: types.REMOVE_FROM_COURSE_VOCABULARY,
        payload: {
          wordId: data.payload.word.word_id,
        }
      });
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'deleteFromCourseVocabulary',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* deleteCourseWord(data) {
  const {userId} = yield select(state => state.user);
  const {courseData} = yield select(state => state.course);

  try {
    const result = yield request
    .post(API_URL + 'deleteCourseWord')
    .send({
      user_id: userId,
      word_id: data.payload.wordId,
      list_id: data.payload.listId,
      course_id: courseData.id
    });

    // console.log("deleteCourseWord", result.body)

    yield put({
      type: types.DELETE_COURSE_WORD_FROM_LIST,
      payload: {
        listId: data.payload.listId,
        wordId: data.payload.wordId,
        courseId: courseData.id
      }
    });

    yield put({
      type: types.DELETE_COURSE_WORD_FROM_TRAININGS,
      payload: {
        listId: data.payload.listId,
        wordId: data.payload.wordId,
      }
    });

    yield put({
      type: types.DELETE_WORD_FROM_COURSE_LIST_WORDS,
      payload: {
        wordId: data.payload.wordId,
      }
    });

    if (result.body.opened) {
      // toastr.info(translate('next_step_was_opened'), '');

      // yield put(NavigationActions.back());

      yield getInitialCourseData();

      yield put({
        type: types.RESET_COURSE_CACHE
      });

      yield put({
        type: types.RESET_COURSE_LIST_WORDS
      });

    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'deleteCourseWord',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

const setRate = async(userId) => {
  try {
    // await AsyncStorage.setItem(`courseRate${userId}`, `1`);
  } catch (error) {
    // console.log(error.message);
  }
};