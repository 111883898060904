import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock} from '@fortawesome/free-solid-svg-icons';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import colors from '../../../../lib/colors';

class WordMakerCell extends Component {

  render() {
    const {
      word,
      ratio,
      onItemPressed,
      version,
      index,
      tablet,
      currentLevel,
      openedLevels,
      freeHints,
      translate,
      deviceWidth,
      deviceHeight
    } = this.props;

    let unlocked = openedLevels.indexOf(word.level) !== -1;
    let progress = word.total != 0 ? (word.guessed / word.total) : 0;

    return (
      <Card
        style={{
            width: 150,
            height: 200,
            margin: 10,
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 10
          }}
        className="wordmakercell"
        onClick={() => onItemPressed(word, index, unlocked)}
      >
        <div className="listWrapper">

          <div className="listContainer">

            <span className="listName" style={{fontSize: !tablet ? 20 : 30}}>
              {word.word}
            </span>

            <div className="flexRow mt2">
              <span className="scores" style={{fontSize: !tablet ? 14 : 20}}>
                {translate('total_words')}:
              </span>
              <span className="scoresNum" style={{fontSize: !tablet ? 14 : 20}}>
                {word.total}
              </span>
            </div>

            <div className="flexRow mt2">
              <span className="scores" style={{fontSize: !tablet ? 14 : 20}}>
                {translate('guessed')}:
              </span>
              <span className="scoresNum" style={{fontSize: !tablet ? 14 : 20}}>
                {word.guessed}
              </span>
            </div>

            <div className="flexRow mt2">
              <span className="scores" style={{fontSize: !tablet ? 14 : 20}}>
                {translate('scoresUC')}:
              </span>
              <span className="scoresNum" style={{fontSize: !tablet ? 14 : 20}}>
                {word.scores}
              </span>
            </div>

            {
              !unlocked && (
                <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='top'
                                overlay={<Tooltip>{translate('word_level_locked')}</Tooltip>}>
                  <FontAwesomeIcon
                    icon={faLock}
                    style={{
                    fontSize: !tablet ? 35 : 45,
                    marginTop: !tablet ? 15 : 25,
                    color: colors.lightGreyThree
                  }}
                  />
                </OverlayTrigger>
              ) || (
                <div style={{marginTop: !tablet ? 30 : 35}}>
                  <ProgressBar
                    style={{width: 130}}
                    variant="success"
                    now={progress*100}
                  />
                </div>
              )
            }
          </div>
        </div>
      </Card>
    );
  }
}

export default WordMakerCell;
