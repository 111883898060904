import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logApiError} from './logSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* shareList(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'shareList')
    .send({
      user_id: userId,
      list_id: data.payload.listId,
      contacts: data.payload.contacts
    });

    if(result.body.result) {
      // toastr.info('', translate('shared_list'));
    }
  } catch (error) {
    yield logApiError({payload: {function: 'shareList', payload: data.payload,error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* shareGlossary(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'shareGlossary')
    .send({
      user_id: userId,
      glossary_id: data.payload.glossaryId,
      contacts: data.payload.contacts
    });

    if(result.body.result) {
      // toastr.info('', translate('shared_glossary'));
    }
  } catch (error) {
    yield logApiError({payload: {function: 'shareGlossary', payload: data.payload,error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}
export function* shareWord(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'shareWord')
    .send({
      user_id: userId,
      word_id: data.payload.wordId,
      contacts: data.payload.contacts
    });

    if(result.body.result) {
      // toastr.info('', translate('shared_word'));
    }
  } catch (error) {
    yield logApiError({payload: {function: 'shareWord', payload: data.payload,error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getSharedGlossaries() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getSharedGlossaries')
    .query({
      user_id: userId,
    });

    yield put({
      type: types.SET_SHARED_GLOSSARIES,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({payload: {function: 'getSharedGlossaries', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getSharedLists() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getSharedLists')
    .query({
      user_id: userId,
    });

    yield put({
      type: types.SET_SHARED_LISTS,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({payload: {function: 'getSharedLists', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getSharedWords() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getSharedWords')
    .query({
      user_id: userId,
    });
    
    yield put({
      type: types.SET_SHARED_WORDS,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({payload: {function: 'getSharedWords', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


export function* getSharedNumbers() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getSharedNumbers')
    .query({
      user_id: userId,
    });

    yield put({
      type: types.SET_SHARED_NUMBERS,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({payload: {function: 'getSharedNumbers', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }
}

export function* deleteSharedWord(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .del(API_URL + 'deleteSharedWord')
    .send({
      user_id: userId,
      entity_id: data.payload
    });

    if(result.body.result) {
      // toastr.info('', translate('shared_word_deleted'));
      yield getSharedNumbers();
      yield getSharedWords();
    }
  } catch (error) {
    yield logApiError({payload: {function: 'deleteSharedWord', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* deleteSharedList(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .del(API_URL + 'deleteSharedList')
    .send({
      user_id: userId,
      list_id: data.payload
    });

    if(result.body.result) {
      // toastr.info('', translate('shared_list_deleted'));
      yield getSharedNumbers();
      yield getSharedLists();
    }
  } catch (error) {
    yield logApiError({payload: {function: 'deleteSharedList', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* deleteSharedGlossary(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .del(API_URL + 'deleteSharedGlossary')
    .send({
      user_id: userId,
      entity_id: data.payload
    });

    if(result.body.result) {
      // toastr.info('', translate('shared_glossary_deleted'));
      yield getSharedNumbers();
      yield getSharedGlossaries();
    }
  } catch (error) {
    yield logApiError({payload: {function: 'deleteSharedGlossary', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* saveSharedList(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'saveSharedList')
    .send({
      user_id: userId,
      entity_id: data.payload
    });

    // toastr.info('', translate('shared_list_saved'));

    yield getSharedNumbers();
    yield getSharedLists();

  } catch (error) {
    yield logApiError({payload: {function: 'saveSharedList', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* toggleInVocabularySharedWord(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'toggleInVocabulary')
    .send({
      user_id: userId,
      word_id: params,
      source: "easy"
    });

    if (result.statusCode === 200) {
      yield getSharedNumbers();
      yield getSharedWords();

      if(result.body.result === true) {
        // toastr.info('', translate('word_removed_from_vocabulary'));
      } else {
        // toastr.info('', translate('word_added_to_vocabulary'));
      }
    }
  } catch (error) {
    yield logApiError({payload: {function: 'toggleInVocabulary', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* toggleInFavoriteSharedWord(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'addWordToFavourite')
    .send({
      user_id: userId,
      word_id: params
    });

    if (result.statusCode === 200) {
      yield getSharedNumbers();
      yield getSharedWords();

      if(result.body.result !== true) {
        // toastr.info('', translate('word_added_to_favorites'));
      } else {
        // toastr.info('', translate('word_removed_from_favorites'));
      }
    }
  } catch (error) {
    yield logApiError({payload: {function: 'addWordToFavourite', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}
