import React, {Component} from 'react';
import {connect} from "react-redux";
import {Helmet} from "react-helmet";

import {Translate, getTranslate} from 'react-localize-redux';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faPlusCircle, faTimes} from '@fortawesome/free-solid-svg-icons';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import moment from 'moment';

import TrainingSubHeader from '../../../components/Headers/TrainingSubHeader/index';
import FinishTrainingButtons from '../../../components/Modals/FinishTrainingButtons/index';
import WordSettings from '../../../components/Modals/WordSettings/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
import ChangeAudioLanguage from '../../../components/Modals/ChangeAudioLanguage/index';
import ReportBug from '../../../components/Modals/ReportBug/index';
import TrainingSettings from '../../../components/Modals/TrainingSettings/index';
import WordInfo from '../../../components/Modals/WordInfo/index';
import RegisterAlert from '../../../components/Modals/RegisterAlert/index';

import Loader from "../../../components/Custom/Loader/index";

import {
  getWordsForTraining,
  resetWords,
  setDefinitionSettings,
  setCachedDefinitionWords,

  moveNewListToRepeat,
  removeListFromRepeatSection,
  updateListTrainings,
  updateTrainingsListFromRepeatSection,
  resetCurrentTrainingId,
  setCurrentTrainingId,
  shuffleTrainingWords,
  toggleShuffleWords
} from '../../../actions/trainingActions';
import {
  logListWord,
  logUserList,
  logGlossaryWord,
  logPhrase,
  logCourseList,
  logCourseListWord,
  logActivity
} from '../../../actions/logActions';
import {
  updateCachedListTrainings,
  updateCachedListTrainingsAndIntervals,
  shuffleListTrainingWords
} from '../../../actions/cacheActions';
import {getListsToRepeat, toggleListTrainingButtonsModal, getOnlyMistakes} from '../../../actions/lists';
import {fetchingData} from '../../../actions/activity';
import {get_word_details} from '../../../actions/wordActions';
import {
  toggleListTrainingButtonsModalCourse,
  getUpdatedCourseLists,
  getUpdatedCourseData,
  getUpdatedCurrentListScores,
  shuffleCourseListTrainingWords
} from '../../../actions/courseActions';
import {logTrainingLimits} from '../../../actions/purchasesActions';

import {checkForCachedWords, checkForCourseCachedWords} from '../../../lib/trainingFunctions';

import * as constants from '../../../lib/constants';

class WordDefinition extends Component {

  constructor(props) {
    super(props);

    this.wordInput = null;

    this.state = {
      currentIndex: 0,
      translationsHeight: 40,
      currentPage: 1,
      totalRepeated: 1,
      downloads: 0,
      totalWords: 0,
      currentChunk: 0,
      scores: 0,
      score: 35,
      finishButtonsModalVisible: false,
      audioModalVisible: false,
      bugModalVisible: false,
      trainingSettingsModalVisible: false,
      registerModalVisible: false,
      wordInfoVisible: false,

      trainingId: 10,
      correctIndex: null,
      selectedIndex: null,
      answered: false,
      correct: null,
      totalMistakes: 0,
      typed: "",
      trainingInfoVisible: false,
      definitionIndex: 0,
      trainingName: "Word Definition",
      autoslide: "1",
      autoslide_delay: "2",
      autosound_word: "1",
      viewMargin: 0,
      wordSettingsModalVisible: false,
      freeze: false,
      finishedTraining: false,
      subscriptionMessage: 'subscription_trainings',
      subscriptionAlertVisible: false,
      shuffle: true,

      exiting: false,

      tablet: false,
      tour: localStorage.getItem('tour'),

      entityId: this.props.match.params.id,
      listId: undefined,
      courseListId: undefined,
      from: false,
      type: false,
      inside: false,

    };
    this.track = null;
    this.timeOut = null;
    this.timeOut1 = null;
    this.timeOut2 = null;
    this.timeOut3 = null;
  }

  componentDidMount() {
    document.addEventListener("keydown", this._handleKeyDown);

    this._setTrainingSettings();

    if (!this.props.history.location || !this.props.history.location.state || !this.props.history.location.state.type) {
      this.props.history.goBack();
    } else {
      this.setState({
        [this.props.history.location.state.type]: parseInt(this.props.match.params.id, 10),
        from: this.props.history.location.state.from,
        type: this.props.history.location.state.type,
        inside: this.props.history.location.state.inside
      })
    }

    this.timeOut = setTimeout(()=> {
      this.getWords();
    }, 100);

    this.setCorrectIndex();

    this.props.setCurrentTrainingId(10);
  }

  componentDidUpdate(prevProps, prevState) {
    const {words} = this.props.data;

    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      if (words.length == 0) {
        this.getWords();
      }
    }
  }

  componentWillUnmount() {
    const {listId, courseListId} = this.state;

    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut1);
    clearTimeout(this.timeOut2);
    clearTimeout(this.timeOut3);
    
    this.props.resetWords();

    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    if (listId != undefined) {
      this.onUnmountUpdates();
    }

    if (courseListId != undefined) {
      this.onUnmountCourseUpdates();
    }
    document.removeEventListener("keydown", this._handleKeyDown);
  }

  _handleKeyDown = (event) => {
    const {freeze} = this.state;

    switch (event.keyCode) {
      case 39:
        if (!freeze) {
          this.changeWord('left')
        }
        break;
      case 37:
        if (!freeze) {
          this.changeWord('right')
        }
        break;

      case 27:
        this.props.history.goBack();
        break;
      default:
        break;
    }
  };

  _shuffleCourseWords = () => {
    const {trainingId, courseListId} = this.state;
    const {userData: {shuffle}} = this.props.user;

    if (courseListId && (courseListId > 0 || courseListId == -1) && shuffle == "1") {
      this.props.shuffleCourseListTrainingWords(trainingId, courseListId)
    }
  };

  _shuffleWords = () => {
    const {trainingId} = this.state;
    const {currentList} = this.props.lists;
    const {userData: {shuffle}} = this.props.user;

    if (currentList && (currentList.id > 0 || currentList.id == -1) && shuffle == "1") {
      this.props.shuffleListTrainingWords(trainingId, currentList.id)
    }
  };

  onUnmountUpdates = () => {
    const {finishedTraining, totalMistakes} = this.state;
    const {currentList} = this.props.lists;

    this._shuffleWords();

    if (finishedTraining) {
      this.moveLists();
    }

    if (currentList && currentList.id == 0) {
      this.props.getOnlyMistakes();
    }

    if (currentList && currentList.id > 0 && totalMistakes > 0) {
      this.props.getOnlyMistakes();
    }
  };

  moveLists = () => {
    const {currentList} = this.props.lists;

    let today = moment().format("YYYY-MM-DD");
    let todayTrainings = null;

    currentList.intervals && currentList.intervals.forEach(interval => {
      if (interval.repetition_date == today) {
        todayTrainings = interval.trainings;
      }
    });

    if (currentList && currentList.id > 0) {
      if (currentList.intervals && currentList.intervals.length != 0 && todayTrainings == 0) {
        this.props.updateTrainingsListFromRepeatSection(currentList.id);
      } else if (todayTrainings == 0) {
        this.props.moveNewListToRepeat(currentList);
      } else if (todayTrainings == 1) {
        this.props.updateTrainingsListFromRepeatSection(currentList.id);
      } else if (todayTrainings >= 2) {
        this.props.removeListFromRepeatSection(currentList.id);
        this.props.updateCachedListTrainings(currentList.id);
      } else if (todayTrainings == null) {
        this.props.moveNewListToRepeat(currentList);
        this.props.updateCachedListTrainingsAndIntervals(currentList);
      }
    }
  };

  onUnmountCourseUpdates = () => {
    const {totalMistakes, finishedTraining, courseListId, inside} = this.state;

    this._shuffleCourseWords();

    if (inside == undefined) {
      this.props.fetchingData(true);
    }

    if (finishedTraining || totalMistakes > 0 || courseListId == 0) {
      this.props.getUpdatedCourseLists();
    } else {
      this.props.getUpdatedCourseData();
    }

    if (courseListId != 0) {
      this.props.getUpdatedCurrentListScores(courseListId);
    }
  };

  _logOnExit = () => {
    const {words} = this.props.data;
    const {logUserList, logCourseList} = this.props;
    const {totalRepeated, trainingId, listId, courseListId} = this.state;
    const percentage = totalRepeated / (words.length != 0 ? words.length : 20);

    this.setState({
      exiting: true
    });

    if (percentage >= 0.85 && listId && listId > 0) {
      this.setState({
        finishedTraining: true
      });

      logUserList({trainingId, listId, type: "end"})
    }

    if (percentage >= 0.85 && courseListId && courseListId != 0) {
      this.setState({
        finishedTraining: true
      });

      logCourseList({trainingId, listId: courseListId, type: "end"});
    }
  };

  setCorrectIndex = () => {
    let n = Math.floor(Math.random() * 4);
    this.setState({
      correctIndex: n
    });
  };

  _setTrainingSettings = async() => {
    const {userId} = this.props.user;
    const {trainingId} = this.state;

    const autoslide = localStorage.getItem(`autoslide_${trainingId}_${userId}`);
    const autoslide_delay = localStorage.getItem(`autoslide_delay_${trainingId}_${userId}`);
    const autosound_word = localStorage.getItem(`autosound_word_${trainingId}_${userId}`);

    if (autoslide != null) {
      this.setState({autoslide})
    } else {
      localStorage.setItem(`autoslide_${trainingId}_${userId}`, '1');
    }

    if (autoslide_delay != null) {
      this.setState({autoslide_delay})
    } else {
      localStorage.setItem(`autoslide_delay_${trainingId}_${userId}`, '2');
    }

    if (autosound_word != null) {
      this.setState({autosound_word})
    } else {
      localStorage.setItem(`autosound_word_${trainingId}_${userId}`, '1');
    }

    this.props.setDefinitionSettings({
      autoslide: autoslide != null ? autoslide : "1",
      autoslide_delay: autoslide_delay != null ? autoslide_delay : "2",
      autosound_word: autosound_word != null ? autosound_word : "1",
    })
  };

  changeWord = (direction) => {
    const {totalWords} = this.props.data;
    const {currentIndex, listId, courseListId} = this.state;
    var index = currentIndex;
    const {userData} = this.props.user;

    let last = false;

    if (direction == "left") {
      this.checkIfNeedMore();

      if (currentIndex == totalWords - 1) {
        last = true;

        if ((listId != undefined && listId == 0) || (courseListId != undefined && courseListId == 0)) {
          this.props.history.goBack();
        } else {
          let active = this._checkTrainingsLimit();

          if (active) {
            if(userData && userData.active_trainings_set) {
              this.setState({
                finishedTraining: true
              });
              this._goToNextTraining()
            } else {
              this.setState({
                finishButtonsModalVisible: true
              })
            }
          } else {
            this._toggleSubscriptionAlert(true);
          }
        }
      } else {
        index = currentIndex + 1;

        this.setState({
          totalRepeated: this.state.totalRepeated + 1
        })
      }
    } else {
      if (currentIndex == 0) {
        index = totalWords - 1
      } else {
        index = currentIndex - 1
      }
    }

    this.setCorrectIndex();

    this.setState({
      currentIndex: index,
      selectedIndex: null,
      answered: false,
      // typed: '',
      correct: null,
      definitionIndex: 0,
      freeze: false
    });
  };

  _goToNextTraining = () => {
    const {logUserList, logCourseList} = this.props;

    const {listId, courseListId, type, trainingId} = this.state;
    const {userData} = this.props.user;
    const {locale} = this.props.settings;

    if (listId && listId > 0) {
      logUserList({trainingId, listId, type: "end"})
    } else if (courseListId && courseListId != 0) {
      logCourseList({trainingId, listId: courseListId, type: "end"});
    }
    
    let currentListId = null;

    if (listId != undefined) {
      currentListId = listId;
    }

    if (courseListId != undefined) {
      currentListId = courseListId;
    }

    let trainingIds = userData.trainings_set != null ? userData.trainings_set.split(',').map(Number) : [];

    const trainingIndex = trainingIds.length > 0 ? trainingIds.indexOf(trainingId) : null;

    if(trainingIndex === null || trainingIndex == (trainingIds.length - 1)) {
      this.setState({
        finishButtonsModalVisible: true
      });
      return;
    }

    if(currentListId != 0) {
      let activityId = '';
      let path = '';

      if (trainingIds[trainingIndex+1] == 1) {
        activityId = 39;
        path = 'preview'
      } else if (trainingIds[trainingIndex+1] == 6) {
        path = 'image';
        activityId = 40;
      } else if (trainingIds[trainingIndex+1] == 2) {
        path = 'translation';
        activityId = 41;
      } else if (trainingIds[trainingIndex+1] == 4) {
        path = 'word';
        activityId = 42;
      } else if (trainingIds[trainingIndex+1] == 3) {
        path = 'collect';
        activityId = 43;
      } else if (trainingIds[trainingIndex+1] == 5) {
        path = 'spelling';
        activityId = 44;
      } else if (trainingIds[trainingIndex+1] == 8) {
        activityId = 114;
        path = 'sentence';
      } else if (trainingIds[trainingIndex+1] == 9) {
        activityId = 131;
        path = 'memory'
      } else if (trainingIds[trainingIndex+1] == 10) {
        path = 'definition';
        activityId = 148;
      } else if (trainingIds[trainingIndex+1] == 7) {
        activityId = 95;
      } else if (trainingIds[trainingIndex+1] == 12) {
        path = 'missing';
        activityId = 315;
      } else if (trainingIds[trainingIndex+1] == 14) {
        path = 'listening';
        activityId = 345;
      } else if (trainingIds[trainingIndex+1] == 15) {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut4 = setTimeout(()=> {
        this.props.history.replace(`/${locale}/${path}/${currentListId}`, {
          type,
          from: 'inside',
          listId: currentListId
        });
      }, 100);

      this.props.logActivity({activityId, model: 'list', modelId: currentListId, comment: 'from home'});

    } else {
      this.setState({
        finishButtonsModalVisible: true
      })
    }
  };

  _checkTrainingsLimit = () => {
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;

    const {userData} = this.props.user;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    let active = false;

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    return active;
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    });

    if (!status) {
      this.props.history.goBack();
    }
  };

  _toSubscription = (productId) => {
    this.setState({
      subscriptionAlertVisible: false
    });
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId}); 
  };

  playSound = () => {
    const {words} = this.props.data;
    const {userData} = this.props.user;
    let audioSource = constants.BACKEND_URL;

    if (words.length > 0 && words.length > this.state.currentIndex) {
      const word = words[this.state.currentIndex];

      if (word && word.audio) {
        let audio = word.audio;

        if (userData && userData.audio_language && word[userData.audio_language]) {
          audio = word[userData.audio_language];
          audioSource = constants.S3
        }

        if (this.track != null) {
          this.track.pause();
          this.track = null;
        }

        this.track = new Audio(`${audioSource}${audio}`);
        this.track.play();
      }
    }
  };

  toSettings = () => {
    this._toggleTrainingSettingsModal(true);
    this.props.logActivity({activityId: 189, comment: 'from definition'});
  };

  checkIfNeedMore = () => {
    const {currentIndex, currentPage, downloads} = this.state;
    const {totalWords, words} = this.props.data;

    if (currentIndex + 3 == words.length && words.length < totalWords) {
      this.setState({
        currentPage: currentPage + 1,
        download: downloads + 1
      });

      this.getWords();
    }
  };

  getWords = () => {
    const {currentPage, trainingId, courseListId, listId, glossaryId} = this.state;
    const {definitionCachedWords} = this.props.cache;
    const {definitionCourseCachedWords} = this.props.courseCache;
    const {isConnected} = this.props.settings;

    const {logUserList, logCourseList} = this.props;

    let params = {
      trainingId,
      page: currentPage,
      history: this.props.history
    };

    if (listId >= -2) {
      params.listId = listId;
      params.endpoint = 'getTrainingWords'
    } else if (courseListId !== false) {
      params.courseListId = courseListId;
      params.endpoint = 'getCourseTrainingWords'
    } else if (glossaryId) {
      params.glossaryId = glossaryId;
      params.endpoint = 'getGlossaryWordsForTraining'
    }

    let cachedWords = false;

    if (listId) {
      cachedWords = checkForCachedWords(definitionCachedWords, listId, currentPage);
    } else if (courseListId) {
      cachedWords = checkForCourseCachedWords(definitionCourseCachedWords, courseListId, currentPage);
    }

    if (!cachedWords) {
      if (isConnected) {
        if (currentPage == 1) {
          this.props.fetchingData(true);
        }
        this.props.getWordsForTraining(params);
      }
    } else {
      this.props.setCachedDefinitionWords(cachedWords);
      this.props.logTrainingLimits();

      if (currentPage == 1) {
        if (listId && listId > 0) {
          logUserList({trainingId, listId, type: "start"})
        }

        if (courseListId && courseListId > 0) {
          logCourseList({trainingId, listId: courseListId, type: "start"});
        }
      }
    }
  };

  onSwipeLeft = (gestureState) => {
    this.checkIfNeedMore();

    if (this.state.currentIndex + 1 == this.props.data.totalWords) {
      this.setState({
        finishButtonsModalVisible: true
      })
    } else {

      this.changeWord("left")
    }
  };

  onSwipeRight = (gestureState) => {
    if (this.state.currentIndex == 0) {
      return;
    }

    this.changeWord("right")
  };

  closeFinishButtonsModal = () => {
    this.setState({
      finishButtonsModalVisible: false
    });
  };

  continueTraining = () => {
    const {logUserList, logCourseList} = this.props;
    const {trainingId, listId, courseListId} = this.state;

    const {userData: {shuffle}} = this.props.user;

    this.moveLists();

    if (listId && (listId > 0 || listId == -1)) {
      if (shuffle == "1") {
        this.props.shuffleTrainingWords();
      }

      if (listId && listId > 0) {
        logUserList({trainingId, listId, type: "end"});

        if (this.state.exiting) return;

        this.timeOut1 = setTimeout(()=> {
          if (this.state.exiting) return;

          logUserList({trainingId, listId, type: "start"})
        }, 1000);
      }
    }

    if (courseListId && (courseListId > 0 || courseListId == -1)) {
      if (shuffle == "1") {
        this.props.shuffleTrainingWords();
      }

      if (courseListId && courseListId > 0) {
        logCourseList({trainingId, listId: courseListId, type: "end"});

        if (this.state.exiting) return;

        this.timeOut2 = setTimeout(()=> {
          if (this.state.exiting) return;

          logCourseList({trainingId, listId: courseListId, type: "start"});
        }, 1000);
      }
    }

    this.setState({
      finishButtonsModalVisible: false,
      currentIndex: 0,
      totalRepeated: 1
    });

    this.props.logTrainingLimits();

    this.props.logActivity({activityId: 151});
  };

  anotherTraining = () => {
    const {logUserList, logCourseList} = this.props;
    const {trainingId, listId, courseListId, from} = this.state;

    if (listId !== undefined && from == 'listWords') {
      this.props.toggleListTrainingButtonsModal(true)
    }

    if (courseListId !== undefined && from == 'listWords') {
      this.props.toggleListTrainingButtonsModalCourse(true)
    }

    if (listId && listId > 0) {
      logUserList({trainingId, listId, type: "end"})
    } else if (courseListId && courseListId != 0) {
      logCourseList({trainingId, listId: courseListId, type: "end"});
    }

    this.setState({
      finishButtonsModalVisible: false,
      finishedTraining: true
    });

    this.props.history.goBack();

    this.props.logActivity({activityId: 190, comment: 'Word Definition'});
  };

  _changeDefinition = (word) => {
    const {definitionIndex} = this.state;

    this.props.logActivity({activityId: 152});

    const definitionsLength = word.definitions.length;

    if (definitionsLength > definitionIndex + 1) {
      this.setState({
        definitionIndex: definitionIndex + 1
      })
    } else {
      this.setState({
        definitionIndex: 0
      })
    }
  };

  checkAnswer = (i) => {
    const {currentIndex, correctIndex, scores, score, autosound_word, autoslide, autoslide_delay} = this.state;
    const {words, autoslide_definition, autoslide_delay_definition, autosound_word_definition} = this.props.data;

    const word = words[currentIndex];

    if (!word) return;

    this.setState({
      selectedIndex: i,
      answered: true
    });

    if (autosound_word_definition == '1') {
      this.playSound();
    }

    let mistake = 1;

    if (i === correctIndex) {
      this.setState({
        correct: true,
        scores: scores + score
      });
      mistake = 0;
    } else {
      this.setState({
        correct: false,
        totalMistakes: this.state.totalMistakes + 1
      });
    }

    this._log(mistake);

    this.checkIfNeedMore();

    if (autoslide_definition == '1') {
      this.setState({
        freeze: true
      });

      if (this.state.exiting) return;

      this.timeOut3 = setTimeout(() => {
        if (this.state.exiting) return;

        this.changeWord("left")
      }, parseFloat(autoslide_delay_definition) * 1000)
    }
  };

  _log = (mistake) => {
    const {words} = this.props.data;
    const {currentIndex} = this.state;
    let word = words.length > 0 ? words[currentIndex] : false;

    const {trainingId, listId, glossaryId, courseListId} = this.state;

    if (word) {
      let params = {
        trainingId: trainingId,
        mistake,
        id: word.id
      };

      if (listId >= -1) {
        params.listId = listId;
        this.props.logListWord(params);
      } else if (glossaryId) {
        params.glossaryId = glossaryId;
        this.props.logGlossaryWord(params);
      } else if (courseListId !== false) {
        params.courseListId = courseListId;
        this.props.logCourseListWord(params);
      }
    }
  };

  toggleInfoModal = (status) => {
    this.setState({
      trainingInfoVisible: status
    })
  };

  _toggleAudioModal = (status) => {
    this.setState({
      audioModalVisible: status
    });
  };

  _openTrainingInfo = () => {
    this.toggleWordSettingsModal(false);
    this.toggleInfoModal(true);
  };

  _reportTrainingBug = () => {
    this.toggleWordSettingsModal(false);

    const {words, totalWords} = this.props.data;
    const {currentIndex, definitionIndex, listId, courseListId} = this.state;
    let word = words.length > 0 ? words[currentIndex] : false;
    let extra = {};

    extra.word = word && word.word ? word.word : "";
    extra.listId = listId;
    extra.courseListId = courseListId;
    extra.definition = word && word.definitions && word.definitions.length > 0 ? word.definitions[definitionIndex].definition : "";

    this.setState({
      extra
    });
    
    this._toggleBugModal(true);

    this.props.logActivity({activityId: 234, comment: "Word Definition"});
  };

  _openTrainingSettings = () => {
    this.toggleInfoModal(false);
    this.toggleWordSettingsModal(false);

    if(!this.state.tour) {
      this.toSettings();
    } else {
      this._toggleRegisterModal(true)
    }
  };

  _toWordDetails = () => {
    this.toggleWordSettingsModal(false);
    const {words} = this.props.data;
    const {currentIndex} = this.state;

    let word = words.length > 0 ? words[currentIndex] : false;

    if (!word) return;
    this.props.get_word_details(word.id);

    this._toggleWordInfo(true);
    this.props.logActivity({activityId: 45, model: 'word', modelId: word.id, comment: "from Word Definition"});
    // this.props.history.push(`/dictionary/${word.word}`, {word});
  };

  _changeVoice = () => {
    this.toggleWordSettingsModal(false);

    this.props.logActivity({activityId: 332});

    this._toggleAudioModal(true);
  };

  toggleWordSettingsModal = (status) => {
    this.toggleInfoModal(false);

    this.setState({
      wordSettingsModalVisible: status
    })
  };

  renderButton = (word, ind) => {
    const {selectedIndex, correctIndex, answered, tablet} = this.state;

    const {fontSizeRation} = this.props.settings;

    if (selectedIndex !== null && selectedIndex === ind) {
      return <Button
        disabled={answered}
        className="answerButton"
        style={{fontSize:fontSizeRation * (!tablet ? 14 : 16)}}
        onClick={()=>null}
        variant={selectedIndex === correctIndex ? 'success' : 'danger'}
      >
        {word && correctIndex === ind ? word.word : word && word.other_words && word.other_words.length > 0 ? word.other_words[ind].word : ""}
      </Button>
    } else {
      return <Button
        disabled={answered}
        className="answerButton"
        variant="outline-secondary"
        style={{fontSize:fontSizeRation * (!tablet ? 14 : 16)}}
        onClick={() => {
          this.checkAnswer(ind)
      }}>
        {word && correctIndex === ind ? word.word : word && word.other_words && word.other_words.length > 0 ? word.other_words[ind].word : ""}
      </Button>
    }
  };

  _goBack = () => {
    this.setState({
      finishButtonsModalVisible: false,
    });

    this._logOnExit();

    this.props.history.goBack();

    this.props.logActivity({activityId: 306, comment: 'Word Definition'});
  };

  _toggleShuffle = () => {
    const {userData} = this.props.user;

    this.toggleWordSettingsModal(false);

    if(!this.state.tour) {
      this.props.fetchingData(true);

      this.props.toggleShuffleWords();
      this.props.logActivity({activityId: 333, comment: userData && userData.shuffle == 1 ? "on" : "off"});

    } else {
      this._toggleRegisterModal(true)
    }
  };

  _toggleBugModal = (status) => {
    this.setState({
      bugModalVisible: status
    });
  };

  _toggleTrainingSettingsModal = (status) => {
    this.setState({
      trainingSettingsModalVisible: status
    });
  };

  _toggleWordInfo = (status) => {
    this.setState({
      wordInfoVisible: status
    })
  };
  
  _toggleRegisterModal = (status, e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      registerModalVisible: status
    })
  };

  _toRegister = () => {
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/register`)
  };
  
  render() {
    const {words, totalWords} = this.props.data;
    const {
      currentIndex, wordSettingsModalVisible, typed, trainingId, totalRepeated, correct, answered, scores,
      finishButtonsModalVisible, trainingInfoVisible, definitionIndex,
      tablet,
      freeze,
      subscriptionAlertVisible,
      subscriptionMessage,
      listId,
      courseListId,
      from,
      audioModalVisible,
      bugModalVisible,
      trainingSettingsModalVisible,
      extra,
      wordInfoVisible,
      registerModalVisible
    } = this.state;

    const {fetchingData} = this.props.activity;
    const {userData} = this.props.user;
    const {fontSizeRation, version, isConnected, deviceWidth, deviceHeight} = this.props.settings;
    const {currentList} = this.props.lists;
    const {course, translate} = this.props;

    let subtitle = "";

    if (currentList && currentList.list_name && totalWords) {
      subtitle = `${currentList.list_name}: ${totalWords}`
    } else if (course && course.currentList && course.currentList.list_name) {
      subtitle = `${course.currentList.list_name}: ${totalWords}`
    }

    if (listId == -1 || courseListId == -1) {
      subtitle = translate('vocabulary')
    }

    let word = words.length > 0 ? words[currentIndex] : false;

    const definition = word && word.definitions && word.definitions.length > 0 ? word.definitions[definitionIndex].definition : false;

    const definitionsLength = word && word.definitions ? word.definitions.length : false;

    const progress = totalWords !== 0 ? ((currentIndex + 1) / totalWords) : 0;

    return (
      <div className="worddefinition">
        <Container className="pageWrapper">
          <TrainingSubHeader
            trainingId={trainingId}
            key={freeze}
            freeze={freeze}
            version={version}
            ratio={fontSizeRation}
            scores={scores}
            progress={progress}
            changeWord={this.changeWord}

            deviceHeight={deviceHeight}
            deviceWidth={deviceWidth}
            translate={translate}
            tablet={tablet}
            exit={this._goBack}
            history={this.props.history}
            trainingName="definition_training"
            subtitle={subtitle}

            title={'definition_training'}
            text="word_definition_training_description"
            url="https://youtu.be/g0ZWZ3w5KFw"
            toggleSettings={this.toggleWordSettingsModal}
          />

          <Card style={{minHeight: deviceHeight - 120}}>
            <div style={{opacity: word && word.id ? 1 : 0}} className="contentWrapper">

              {
                !answered && (
                  <div className="definitionScrollView">
                    <span
                      className="definitionText"
                      style={{fontSize: 16 * fontSizeRation}}>
                      {definition}
                    </span>
                  </div>

                ) || (
                  <div className="definitionScrollView">
                    <span className="word" style={{fontSize: (!tablet ? 16 : 18) * fontSizeRation}}>
                      {word.word}
                    </span>
                  </div>
                )
              }

              {
                !answered && false && (
                  <a href="#"
                     onClick={()=> {
                      if(typed.length === 0) {
                        this.changeWord('left')
                      } else {
                        this.checkAnswer(word)
                      }
                    }}
                  >
                    <div
                      className="checkButton"
                      style={{
                        height: !tablet ? 40 : (deviceHeight > 950 ? 70 : 55),
                        width: !tablet ? 200 : 300,
                        marginTop: !tablet ? (deviceHeight > 700 ? 10 : 5) : (deviceHeight > 950 ? 20 : 10)
                    }}>
                      <span className="checkButtonText" style={{fontSize: (!tablet ? 16 : 18) * fontSizeRation}}>
                        {typed.length === 0 ? translate('dont_know') : translate('check')}
                      </span>
                    </div>
                  </a>
                )
              }

              {
                words.length > 0 && (
                  <div className="answerButtonsWrapper">

                    {
                      !answered && definitionsLength && definitionsLength > 1 && (
                        <a href="#"
                           onClick={(e)=> {
                              e.preventDefault();
                              if(!answered) {
                                this._changeDefinition(word);
                              }
                           }}
                        >
                          <div
                            className="checkButton"
                            style={{
                              paddingVertical: !tablet ? (deviceHeight > 600 ? 15 : 5) : 30,
                              marginBottom: !tablet ? (deviceHeight > 600 ? 20 : 10) : 40
                          }}>
                            <span className="anotherDefinitionText"
                                  style={{fontSize: (!tablet ? 14 : 16) * fontSizeRation}}>
                              {translate('another_definition')}
                            </span>
                          </div>
                        </a>
                      )
                    }

                    {this.renderButton(word, 0)}
                    {this.renderButton(word, 1)}
                    {this.renderButton(word, 2)}
                    {this.renderButton(word, 3)}
                  </div>
                )
              }

            </div>
          </Card>

          {finishButtonsModalVisible && (
            <FinishTrainingButtons
              isVisible={finishButtonsModalVisible}
              onClose={this.closeFinishButtonsModal}
              continueTraining={this.continueTraining}
              anotherTraining={this.anotherTraining}
              ratio={fontSizeRation}
              returnBack={this._goBack}
              from={from}
              translate={translate}
              tablet={tablet}
            />
          )}

          {wordSettingsModalVisible && (
            <WordSettings
              changeVoice={this._changeVoice}
              ratio={fontSizeRation}
              isVisible={wordSettingsModalVisible}
              onClose={this.toggleWordSettingsModal}
              openTrainingInfo={this._openTrainingInfo}
              openTrainingSettings={this._openTrainingSettings}
              toWordDetails={this._toWordDetails}
              reportTrainingBug={this._reportTrainingBug}
              title={translate('definition_training')}
              shuffle={userData.shuffle}
              shouldShuffle={true}
              toggleShuffle={this._toggleShuffle}
            />
          )}

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={subscriptionMessage}
                translate={translate}
                deviceWidth={deviceWidth}
                deviceHeight={deviceHeight}
              />
            )
          }
          {
            audioModalVisible && (
              <ChangeAudioLanguage
                onClose={this._toggleAudioModal}
                isVisible={audioModalVisible}
                toRegister={this._toggleRegisterModal}

              />
            )
          }
          {
            bugModalVisible && (
              <ReportBug
                onClose={this._toggleBugModal}
                isVisible={bugModalVisible}
                type="training"
                training="Word Definition"
                extra={extra}
              />
            )
          }
          {
            trainingSettingsModalVisible && (
              <TrainingSettings
                onClose={this._toggleTrainingSettingsModal}
                isVisible={trainingSettingsModalVisible}
                trainingName="definition_training"
                trainingId={trainingId}
              />
            )
          }
          {wordInfoVisible && (<WordInfo onClose={this._toggleWordInfo} isVisible={wordInfoVisible}/>)}
          {registerModalVisible && (
            <RegisterAlert
              onClose={this._toggleRegisterModal}
              isVisible={registerModalVisible}
              translate={translate}
              toRegister={this._toRegister}
            />
          )}
        </Container>
        <Loader fetchingData={fetchingData}/>

        <Helmet>
          <title>{`${translate('app_title_definition_training')}`}</title>
          <meta property="og:title" content={`${translate('app_title_definition_training')}`} />
          <meta name="description" content={translate('app_title_definition_training_description')} />
          <meta name="og:description" content={translate('app_title_definition_training_description')} />

          <link rel="canonical" href={window.location.href} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  data: state.trainings,
  activity: state.activity,
  settings: state.settings,
  cache: state.cache,
  lists: state.lists,
  courseCache: state.courseCache,
  purchases: state.purchases,
  course: state.course,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    getWordsForTraining: (data) => dispatch(getWordsForTraining(data)),
    resetWords: () => dispatch(resetWords()),
    logListWord: (data) => dispatch(logListWord(data)),
    logPhrase: (data) => dispatch(logPhrase(data)),
    logGlossaryWord: (data) => dispatch(logGlossaryWord(data)),
    logUserList: (data) => dispatch(logUserList(data)),
    logActivity: (data) => dispatch(logActivity(data)),
    getListsToRepeat: (limit, userId) => dispatch(getListsToRepeat(limit, userId)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    toggleListTrainingButtonsModal: (status) => dispatch(toggleListTrainingButtonsModal(status)),
    logCourseListWord: (data) => dispatch(logCourseListWord(data)),
    logCourseList: (data) => dispatch(logCourseList(data)),
    setDefinitionSettings: (data) => dispatch(setDefinitionSettings(data)),
    setCachedDefinitionWords: (data) => dispatch(setCachedDefinitionWords(data)),

    moveNewListToRepeat: (list) => dispatch(moveNewListToRepeat(list)),
    removeListFromRepeatSection: (listId) => dispatch(removeListFromRepeatSection(listId)),
    updateListTrainings: (listId) => dispatch(updateListTrainings(listId)),
    updateTrainingsListFromRepeatSection: (listId) => dispatch(updateTrainingsListFromRepeatSection(listId)),
    getOnlyMistakes: () => dispatch(getOnlyMistakes()),
    updateCachedListTrainings: (listId) => dispatch(updateCachedListTrainings(listId)),
    updateCachedListTrainingsAndIntervals: (list) => dispatch(updateCachedListTrainingsAndIntervals(list)),

    toggleListTrainingButtonsModalCourse: (status) => dispatch(toggleListTrainingButtonsModalCourse(status)),
    getUpdatedCurrentListScores: (listId) => dispatch(getUpdatedCurrentListScores(listId)),
    getUpdatedCourseLists: () => dispatch(getUpdatedCourseLists()),
    getUpdatedCourseData: () => dispatch(getUpdatedCourseData()),
    resetCurrentTrainingId: () => dispatch(resetCurrentTrainingId()),
    setCurrentTrainingId: (id) => dispatch(setCurrentTrainingId(id)),
    logTrainingLimits: () => dispatch(logTrainingLimits()),
    shuffleListTrainingWords: (trainingId, listId) => dispatch(shuffleListTrainingWords(trainingId, listId)),
    shuffleCourseListTrainingWords: (trainingId, listId) => dispatch(shuffleCourseListTrainingWords(trainingId, listId)),
    shuffleTrainingWords: () => dispatch(shuffleTrainingWords()),
    toggleShuffleWords: () => dispatch(toggleShuffleWords()),
    get_word_details: (wordId) => dispatch(get_word_details(wordId)),

  };
}

export default connect(mapStateToProps, bindAction)(WordDefinition);
