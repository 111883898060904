import * as types from '../actions/types';

const INITIAL_STATE = {
  helperArticles: [],
  currentHelperArticle: false,
  helperArticle: false,
  articleFontSize: 30,

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_HELPER_ARTICLES:
      return Object.assign({}, state, {
        helperArticles: action.payload,
      });

    case types.SET_HELPER_ARTICLE:
      return Object.assign({}, state, {
        helperArticle: action.payload,
      });

    case types.SET_CURRENT_HELPER_ARTICLE:
      return Object.assign({}, state, {
        currentHelperArticle: action.payload,
      });

    case types.SET_ARTICLE_FONT_SIZE:
    {
      return Object.assign({}, state, {
        articleFontSize: parseFloat(action.payload)
      });
    }
    
    default:
      return state;
  }
}