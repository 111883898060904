import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Translate, getTranslate} from 'react-localize-redux';
import moment from 'moment';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faFacebook,
  faGooglePlay,
  faAppStore,
  faAndroid,
  faApple,
  faYoutubeSquare
} from '@fortawesome/free-brands-svg-icons'

import Container from 'react-bootstrap/Container';

import ReportBug from '../../Modals/ReportBug/index';
import ChangeInterfaceLanguage from '../../Modals/ChangeInterfaceLanguage/index';

class Footer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      bugModalVisible: false,
      interfaceLanguageModal: false,
    };
  }

  _toggleBugModal = (status) => {
    this.setState({
      bugModalVisible: status
    });
  };

  _toggleModal = (modal, status) => {
    this.setState({
      [modal]: status
    })
  };

  render() {
    const {translate, history} = this.props;
    const {locale} = this.props.settings;
    const {bugModalVisible, interfaceLanguageModal} = this.state;

    return (
      <footer style={{minHeight: 100}} className="pb-2 pt-2 footer mnemonic bg-dark justify-content-center d-flex align-items-center mt-5">
        <Container className="p-2">

          <div className="d-flex justify-content-center align-items-center mb-3">
            <a className="mr-4" href="https://www.youtube.com/playlist?list=PLIQKvQgEzpRS3H7nkZTFVotNbox-2Yqgj" target="_blank">
              <FontAwesomeIcon className="text-light" style={{fontSize: 30}} icon={faYoutubeSquare}/>
            </a>
            <a className="mr-4" href="https://instagram.com/mnemonicwords" target="_blank">
              <FontAwesomeIcon className="text-light" style={{fontSize: 30}} icon={faInstagram}/>
            </a>

            <a className="mr-4" href="https://www.facebook.com/Mnemonicwords" target="_blank">
              <FontAwesomeIcon className="text-light" style={{fontSize: 30}} icon={faFacebook}/>
            </a>

            <a className="mr-4" href="http://play.google.com/store/apps/details?id=com.mnemonicrn" target="_blank">
              <FontAwesomeIcon className="text-light" style={{fontSize: 30}} icon={faAndroid}/>
            </a>

            <a href="https://itunes.apple.com/app/id1438980777" target="_blank">
              <FontAwesomeIcon className="text-light" style={{fontSize: 30}} icon={faApple}/>
            </a>
          </div>

          <div className="mb-3 text-center">
            <a
              style={{fontSize: 14}}
              className="text-light text-center mr-3"
              href="#"
              onClick={(e)=> {
                e.preventDefault();
                this._toggleBugModal(true)
              }}
            >
              {translate('contact_us')}
            </a>
            {/*<Link to="/memorization-system" className="text-light text-center mr-3" style={{fontSize: 14}}>
              {translate('memorization_system')}
            </Link>*/}
            <Link to={`/${locale}/video-instructions`} className="text-light text-center mr-3" style={{fontSize: 14}}>
              {translate('video_instructions')}
            </Link>

            <Link to={`/${locale}/articles`} className="text-light text-center mr-3" style={{fontSize: 14}}>
              {translate('helper_articles')}
            </Link>
            <a style={{fontSize: 14}} href="https://old.mnemonicwords.com" target="_blank" className="text-light text-center mr-3">
              {translate('old_version')}
            </a>
          </div>

          <div className="mb-3 text-center">
            <Link to={`/${locale}/agreement`} className="text-light text-center mr-3" style={{fontSize: 12}}>
              {translate('agreement.agreement')}
            </Link>
            <Link to={`/${locale}/terms`} className="text-light text-center mr-3" style={{fontSize: 12}}>
              {translate('terms')}
            </Link>
            <Link to={`/${locale}/policy`} className="text-light text-center mr-3" style={{fontSize: 12}}>
              {translate('policy')}
            </Link>
            <a
              style={{fontSize: 12}}
              className="text-light text-center mr-3"
              href="#"
              onClick={(e)=>{
                        e.preventDefault();
                        this._toggleModal('interfaceLanguageModal',true);
                      }}>
              {translate('interface_language_change')}
            </a>
          </div>
          <div>
            <p className="text-light text-center mb-0" style={{fontSize: 12}}>
              &copy;Mnemonic Words - {moment().format('YYYY')}, {translate('copyright')}
            </p>
          </div>

        </Container>
        {
          bugModalVisible && (
            <ReportBug
              onClose={this._toggleBugModal}
              isVisible={bugModalVisible}
              type="contact"
              training="false"
              extra={false}
            />
          )
        }

        {
          interfaceLanguageModal && (
            <ChangeInterfaceLanguage
              isVisible={interfaceLanguageModal}
              onClose={this._toggleModal}
              history={history}
            />
          )
        }
      </footer>
    )
  }
}

function bindAction(dispatch) {
  return {};
}

const mapStateToProps = state => ({
  user: state.user,
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize),
});

export default connect(
  mapStateToProps,
  bindAction,
)(withRouter(Footer));
