import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircle, faCogs, faCheckCircle,
  faTrashAlt,
  faCamera,
  faVolumeUp,
  faTimes, faSearch, faPlus
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import _ from 'lodash'

import {Translate, getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';
import moment from 'moment';

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ListGroup from 'react-bootstrap/ListGroup';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';


import SubscriptionAlert from '../../components/Modals/SubscriptionAlert/index';
import PhraseCell from '../../components/Cells/PhraseCell/index';
import Loader from "../../components/Custom/Loader/index";
import TrainingButtons from '../../components/Modals/TrainingButtons/index';

import {
  getGroupedPhrasebooks,
  setCurrentPhrasebook,
  searchPhrases,
  resetFoundPhrases,
  toggleFavoritePhrase,
  getPhrases,
  resetPhrases
} from '../../actions/phrasebooksActions.js';
import {logActivity} from '../../actions/logActions';
import {fetchingData} from '../../actions/activity';

import colors from '../../lib/colors';
import * as constants from '../../lib/constants';

class Phrasebooks extends Component {

  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      // currentPhrasebook: false,
      currentPhrasebookId: false,
      subscriptionAlertVisible: false,
      trainingsModalVisible: false,
      tablet: false,
      searchBarVisible: false,
      phrase: '',
      byTranslation: false,
      phraseBooksVisible: true,
      currentSection: {},
      subscriptionMessage: 'subscription_phrasebooks'


    };

    this.track = null;
    this.onChangeTextDelayed = _.debounce(this._onChangeText, 250);
  }

  componentDidMount() {
    this._getPhrasebooks();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._getPhrasebooks();
    }
  }

  componentWillUnmount() {
    this.props.resetFoundPhrases();
  }

  _getPhrasebooks = () => {
    const {isConnected} = this.props.settings;

    if (isConnected) {
      this.props.fetchingData(true);

      this.props.getGroupedPhrasebooks();
    }
  };

  _setCurrentSection = (section) => {
    this.setState({
      currentSection: section
    })
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toSubscription = (productId) => {
    this._toggleSubscriptionAlert(false);
    const {locale} = this.props.settings;
    
    this.props.history.push(`/${locale}/products`, {back: true, product: productId});
  };
  
  _onPressItem = (phrasebook, index, section) => {
    const {subscriptionActive, applyLimit, phrasebooksLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;

    if (lifetimeStatus || subscriptionActive || webSubscriptionStatus || !applyLimit) {
      this.setState({
        currentPhrasebookId: phrasebook.id
      });
      this.props.setCurrentPhrasebook(phrasebook);
      this.props.fetchingData(true);
      this.props.getPhrases(phrasebook.id);
    } else {
      if (section == 0 && index <= phrasebooksLimit) {
        this.setState({
          currentPhrasebookId: phrasebook.id
        });
        this.props.setCurrentPhrasebook(phrasebook);

        this.props.fetchingData(true);

        this.props.getPhrases(phrasebook.id);

      } else {
        this.setState({
          subscriptionMessage: 'subscription_phrasebooks'
        });

        this._toggleSubscriptionAlert(true)
      }
    }
    this.props.logActivity({activityId: 125, model: 'phrasebook', modelId: phrasebook.id});
  };

  _typePhrase = (e, phrase) => {
    e.preventDefault();

    this.setState({
      phrase
    });

    this.onChangeTextDelayed(phrase);
  };

  _resetFoundPhrases = (e) => {
    e.preventDefault();
    this.props.resetFoundPhrases();
    this.setState({
      phrase: ''
    })
  };

  _handleKeyPress = (type, event) => {
    if (event.key === 'Enter') {
      this._onChangeText()
    }
  };

  _onChangeText() {
    const {phrase, byTranslation} = this.state;

    if (phrase != "") {
      this.setState({
        phraseBooksVisible: false
      });

      if (phrase.length >= 3) {
        this.props.fetchingData(true);

        this.props.searchPhrases(phrase, byTranslation)
      }

    } else {
      this.setState({
        phraseBooksVisible: true
      })
    }
  }

  toggleByTranslation = (e) => {
    const {phrase, byTranslation} = this.state;

    e.preventDefault();

    this.setState({
      byTranslation: !byTranslation
    });

    if (phrase.length >= 3) {
      this.props.fetchingData(true);

      this.props.searchPhrases(phrase, !byTranslation)
    }
  };

  playTrack = (url, e) => {
    e.preventDefault();

    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    this.track = new Audio(url);
    this.track.play()
  };

  _toggleFavoritePhrase = (phraseId, isFavorite, e) => {
    e.preventDefault();

    this.props.fetchingData(true);

    this.props.toggleFavoritePhrase(phraseId);

    this.props.logActivity({
      activityId: isFavorite == true ? 320 : 321,
      model: 'phrase',
      modelId: phraseId
    });
  };

  _renderPhraseItem = (item, index) => {
    const {fontSizeRation, version} = this.props.settings;
    const {tablet} = this.state;
    const {userData} = this.props.user;
    const {translate} = this.props;

    let audio = item && item.audio ? item.audio : false;
    let audioSource = constants.BACKEND_URL;

    if (userData && userData.audio_language && item[userData.audio_language]) {
      audio = item[userData.audio_language];
      audioSource = constants.S3
    }
    let inFavorite = item.in_favorite;

    return (
      <PhraseCell
        translate={translate}
        audio={audio}
        audioSourse={audioSource}
        key={index}
        tablet={tablet}
        version={version}
        phrase={item}
        ratio={fontSizeRation}
        inFavorite={inFavorite}
        onFavorite={this._toggleFavoritePhrase}
        playTrack={this.playTrack}
      />
    )
  };

  toggleTrainingButtons = (status) => {
    this.setState({
      trainingsModalVisible: status
    })
  };

  trainingSelected = (training) => {
    const {currentPhrasebook} = this.props.phrasebooks;
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {locale} = this.props.settings;

    const {userData} = this.props.user;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    let active = false;

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    this.setState({
      trainingsModalVisible: false
    });

    if (active) {
      let activityId = '';
      let path = '';

      if (training == 'Preview') {
        activityId = 39;
        path = 'preview'
      } else if (training == 'CheckSpelling') {
        activityId = 44;
        path = 'spelling';
      } else if (training == 'CollectSentence') {
        activityId = 129;
        path = 'sentence';
      } else if (training == 'CollectSentenceTap') {
        activityId = 335;
        path = 'sentence';
      } else if (training == 'MissingLetter') {
        activityId = 315;
        path = 'missing';
      }

      setTimeout(()=> {
        this.props.history.push(`/${locale}/${path}/${currentPhrasebook.id}`, {
          type: 'phrasebookId',
          from: 'listWords',
          listId: currentPhrasebook.id
        });
        this.props.logActivity({activityId, model: 'phrasebook', modelId: currentPhrasebook.id});
      }, 100);
    } else {
      this.setState({
        subscriptionMessage: 'subscription_trainings'
      });
      this._toggleSubscriptionAlert(true);
    }
  };

  render() {
    const {
      phrasebooks,
      sections,
      totalPhrasebooks,
      totalPhrases,
      phrasebookPhrases,
      currentPhrasebook,
      foundPhrases} = this.props.phrasebooks;
    const {
      subscriptionAlertVisible,
      tablet, searchBarVisible, phrase, byTranslation,
      trainingsModalVisible,
      currentPhrasebookId,
      subscriptionMessage
    } = this.state;
    const {translate} = this.props;
    const {userData} = this.props.user;
    const {fetchingData} = this.props.activity;
    const {fontSizeRation, locale, deviceWidth} = this.props.settings;

    const subtitle = `${translate('phrasebooks')}: ${totalPhrasebooks} / ${translate('Phrases')}: ${totalPhrases}`;
    const width = searchBarVisible ? (deviceWidth - 50) : !tablet ? 90 : 120;

    const level = userData && userData.level ? userData.level : 0;

    return (
      <div className="phrasebooks">
        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate('phrasebooks')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container fluid className="pageWrapper">
          <Row>
            <Col sm={4}>
              <div className="mb-1">
                <InputGroup className="mt-md-0 mt-2 vocabulary-input mb-md-0 mb-2 mr-0 mr-md-3">
                  <FormControl
                    onKeyPress={(e)=>this._handleKeyPress('search', e)}
                    value={phrase}
                    onChange={(e)=>this._typePhrase(e, e.target.value)}
                    placeholder={translate('search_phrases')}
                  />
                  <InputGroup.Append>
                    <Button
                      disabled={phrase.length === 0}
                      variant="outline-secondary" onClick={(e)=>this._resetFoundPhrases(e)}>
                      <FontAwesomeIcon icon={faTimes}/>
                    </Button>
                  </InputGroup.Append>
                </InputGroup>

                <div className="d-flex justify-content-start align-items-center mt-1 mb-1">
                  <a href="#" onClick={this.toggleByTranslation}>
                    <FontAwesomeIcon
                      icon={byTranslation ? faCheckCircle: faCircle}
                      style={{color: byTranslation ? colors.tealish : colors.greyishBrown5, marginRight: 10, fontSize: 15}}
                    />

                    <span className="font-weight-light text-secondary" style={{fontSize: fontSizeRation * 14}}>
                      <Translate id="select_phrases_by_translation"/>
                    </span>
                  </a>
                </div>
              </div>


              <Accordion defaultActiveKey={0}>
                {
                  sections.map((section, index) => {

                    return (
                      <Card key={index}>
                        <Accordion.Toggle
                          as={Card.Header}
                          onClick={() => this._setCurrentSection(section)}
                          className="phrasebookHeader" eventKey={index}>

                          <span
                            style={{
                            // color: currentPhrasebook.id == section.id ? colors.tealish : colors.greyishBrown,
                            // fontWeight: currentPhrasebook.id == section.id ? 700 : 400
                            }}
                          >
                            {section.title}
                          </span>

                          {/* <OverlayTrigger placement='top' overlay={<Tooltip>{translate('topics')}</Tooltip>}>
                           <Badge pill className="float-right" variant="info">{section.data.length}</Badge>
                           </OverlayTrigger>*/}
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey={index}>
                          <Card.Body style={{padding: "2px 0 0 0"}}>
                            <ListGroup variant="flush">

                              {
                                section.data.map((phrasebook, i) => {
                                  const phraseBookName = classNames({
                                    'font-weight-light': currentPhrasebookId != phrasebook.id,
                                    'font-weight-bold': currentPhrasebookId == phrasebook.id,
                                  });

                                  return <ListGroup.Item
                                    key={phrasebook.id}
                                    className="pt-2 pb-2 phrasebook d-flex align-items-center justify-content-between"
                                    onClick={()=>this._onPressItem(phrasebook, i, index)}
                                  >
                                  <span
                                    className={phraseBookName}
                                    style={{
                                      marginRight: 30,
                                       color: currentPhrasebook.id == phrasebook.id ? colors.tealish : colors.greyishBrown
                                    }}
                                  >
                                    {phrasebook.phrasebook_name}
                                  </span>
                                    <Badge pill className="float-right" variant="secondary">{phrasebook.phrases}</Badge>
                                  </ListGroup.Item>
                                })
                              }
                            </ListGroup>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    )
                  })
                }
              </Accordion>
            </Col>

            <Col>
              {
                phrase.length == 0 && currentPhrasebook && currentPhrasebook.id && (
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 style={{paddingLeft: 5}} className="d-inline-block">
                      {currentPhrasebook.phrasebook_name}
                    </h5>
                    <Button
                      size="sm"
                      variant="outline-success"
                      className="trainingButtonContainer float-right"
                      onClick={() => this.toggleTrainingButtons(true)}
                    >
                      {translate('learn_words')}
                    </Button>

                  </div>
                )
              }
              {
                phrase.length == 0 && phrasebookPhrases.map((phrase, index) => {
                  return this._renderPhraseItem(phrase, index);
                })
              }

              {
                foundPhrases.length > 0 && (
                  <h5 style={{paddingLeft: 5}} className="d-inline-block">
                    {translate('found_phrases')}: {foundPhrases.length}
                  </h5>
                )
              }
              {
                foundPhrases.length > 0 && foundPhrases.map((phrase, index) => {
                  return this._renderPhraseItem(phrase, index);
                })
              }
            </Col>

          </Row>


          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={subscriptionMessage}
                translate={translate}
              />
            )
          }

          {trainingsModalVisible && (
            <TrainingButtons
              phrasebook
              modalVisible={trainingsModalVisible}
              training={this.trainingSelected}
              tablet={tablet}
              level={level}
              onClose={() => this.toggleTrainingButtons(false)}
              ratio={fontSizeRation}
              translationLanguage={userData.translation_language}
              title="select_training_to_repeat_phrases"
              translate={translate}
            />
          )}
        </Container>

        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  phrasebooks: state.phrasebooks,
  purchases: state.purchases,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize)

});

function bindAction(dispatch) {
  return {
    getGroupedPhrasebooks: () => dispatch(getGroupedPhrasebooks()),
    setCurrentPhrasebook: (phrasebook) => dispatch(setCurrentPhrasebook(phrasebook)),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    searchPhrases: (phrase, byTranslation) => dispatch(searchPhrases(phrase, byTranslation)),
    toggleFavoritePhrase: (phraseId) => dispatch(toggleFavoritePhrase(phraseId)),
    resetFoundPhrases: () => dispatch(resetFoundPhrases()),
    getPhrases: (id) => dispatch(getPhrases(id)),
    resetPhrases: () => dispatch(resetPhrases()),


  };
}

export default connect(mapStateToProps, bindAction)(Phrasebooks);
