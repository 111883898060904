import request from 'superagent';
import {select, put, call, fork} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import moment from 'moment';
import {toggleReviewModal} from '../../actions/userActions';
import {logApiError} from './logSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* getRegistrationDate() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getRegistrationDate')
    .query({
      user_id: userId,
    });

    yield put({
      type: types.SET_REGISTRATION_DATE,
      payload: result.body.data
    });

    // console.log("getRegistrationDate", result.body)

    let todayRefused = yield setReviewModal();
    let today = moment();
    let regDate = moment(result.body.data);
    let diff = today.diff(regDate, 'days');

    let todayDay = today.format('YYYY-MM-DD');

    if (todayRefused != '1') {
      if (todayRefused == null || todayRefused != todayDay) {
        if (diff >= 30 && diff % 5 == 0) {
          yield put({
            type: types.TOGGLE_REVIEW_MODAL,
            payload: true
          });
        }
      }
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getRegistrationDate',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* getPurchaseData(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .get(API_URL + 'getPurchaseData')
    .query({
      user_id: userId,
      order_id: data.payload
    });

    // console.log("getPurchaseData", result.body.data);

    yield put({
      type: types.SET_PURCHASE_DATA,
      payload: result.body.data
    });

    if (result.body.result) {
      toastr.success(translate('purchase_success'))
    } else {
      toastr.error(translate(result.body.error))
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getPurchaseData',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getLiqpaySubscriptionStatus(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .get(API_URL + 'getLiqpaySubscriptionStatus')
    .query({
      user_id: userId,
      order_id: data.payload
    });

    // console.log("getLiqpaySubscriptionStatus", result.body.data);

    yield put({
      type: types.SET_PURCHASE_DATA,
      payload: result.body.data
    });

    if (result.body.result == 'purchased') {
      toastr.success(translate('purchase_success'))
    } else if (result.body.result == 'subscribed') {
      toastr.success(translate('subscribed_but_no_payment_received'))
    } else {
      toastr.error(translate(result.body.error))
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getLiqpaySubscriptionStatus',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getUserPurchases() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getUserPurchases')
    .query({
      user_id: userId,
    });

    // console.log("getUserPurchases", result.body);

    yield put({
      type: types.SET_USER_PURCHASES,
      payload: result.body.data
    });


  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getUserPurchases',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* cancelSubscription(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'cancelSubscription')
    .send({
      user_id: userId,
      order_id: data.payload.orderId,
      purchase_id: data.payload.purchaseId
    });

    // console.log("cancelSubscription", result.body);

    if (result.body.result) {
      yield getUserPurchases();
      toastr.success('', translate('subscription_canceled'));
    } else {
      toastr.error(translate('error'), result.body.response.err_description);
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'cancelSubscription',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* checkForHappyDay() {

  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'checkForHappyDay')
    .query({
      user_id: userId,
    });

    yield put({
      type: types.SET_HAPPY_DAY,
      payload: result.body
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'checkForHappyDay',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

const setReviewModal = ()=> {
  const leftReview = localStorage.getItem("leftReview");

  if (!leftReview || leftReview == null) {
    return localStorage.getItem("notNow");
  } else {
    return leftReview;
  }
};

export function* checkWebSubscriptionStatus(data) {
  try {
    const result = yield request
    .get(API_URL + 'checkWebSubscriptionStatus')
    .query({
      user_id: data.payload,
    });

    yield put({
      type: types.SET_WEB_SUBSCRIPTION_STATUS,
      payload: result.body
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'checkWebSubscriptionStatus',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* getUserLimits() {

  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getUserLimits')
    .query({
      user_id: userId,
    });

    yield put({
      type: types.SET_USER_LIMITS,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getUserLimits',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* logTrainingLimits() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'logTrainingLimits')
    .send({
      user_id: userId,
    });

    yield put({
      type: types.SET_TRAINING_LIMIT,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'logTrainingLimits',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}


export function* exchangeScores(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    // .post(API_URL + 'exchangeScores')
    .post(API_URL + 'exchangeScoresNew')
    .send({
      user_id: userId,
      days: data.payload
    });

    // console.log("exchangeScores", result.body)

    // toastr.info("", translate(result.body.result ? 'exchange_success' : 'exchange_error'));

    if (result.body.result) {
      yield put({
        type: types.UPDATE_USER_DATA,
        payload: result.body.data
      });

      yield put({
        type: types.SET_USER_SCORES,
        payload: {data: result.body.data.scores}
      });

      yield checkWebSubscriptionStatus({payload: userId})
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'exchangeScores',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* usePromoCode(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'usePromoCode')
    .send({
      user_id: userId,
      code: data.payload
    });

    // toastr.info(translate('promocode'), translate('promocode_message_'+result.body.result));

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'usePromoCode',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* logGamesLimits() {

  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'logGamesLimits')
    .send({
      user_id: userId,
    });

    yield put({
      type: types.SET_GAMES_LIMIT,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'logGamesLimits',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

function shouldShowReviewModal() {
  let leftReview = null;
  try {
    /*leftReview =  AsyncStorage.getItem("leftReview").then(res => {
     // console.log("leftReview", res)
     toggleReviewModal(true);
     });*/
  } catch (error) {
    // console.log(error.message);
  }
  return leftReview;
};


function* showReviewModal() {
  put({
    type: types.TOGGLE_REVIEW_MODAL,
    payload: true
  });
}

export function* logWordLimits() {

  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'logWordLimits')
    .send({
      user_id: userId,
    });

    yield put({
      type: types.SET_WORD_LIMIT,
      payload: result.body.data
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'logWordLimits',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* checkDiscountCode(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'checkDiscountCode')
    .send({
      user_id: userId,
      code: data.payload
    });
    
    // console.log("checkDiscountCode", result.body)

    if(result.body.result) {
      yield put({
        type: types.SET_DISCOUNT_CODE,
        payload: result.body.data
      });
    } else {
      yield put({
        type: types.SET_DISCOUNT_CODE,
        payload: false
      });
      if(result.body.message) {
        toastr.error(translate(result.body.message))
      }
    }
    
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'checkDiscountCode',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getCurrencyExchangeRate(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .get('https://api.privatbank.ua/p24api/pubinfo?exchange&json&coursid=11');

    yield put({
      type: types.SET_CURRENCY_RATES,
      payload: result.body
    });
    
    // console.log("getCurrencyExchangeRate", result.body)
    
  } catch (error) {
    /*yield logApiError({
      payload: {
        function: 'checkDiscountCode',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })*/
  }
}

