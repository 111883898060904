import React, {Component} from 'react';
import {connect} from "react-redux";
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import classNames from 'classnames';
import {Helmet} from "react-helmet";

import {Link} from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircle, faCogs, faCheckCircle,
  faCamera,
  faVideo,
  faTimes
} from '@fortawesome/free-solid-svg-icons';

import {Translate, getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import Loader from "../../../components/Custom/Loader/index";
import CourseCell from "../CourseCell/index";

import {logActivity} from '../../../actions/logActions';
import {setCurrentCourseSlug, checkForCourseDebts} from '../../../actions/courseActions';
import {fetchingData} from '../../../actions/activity';

import colors from '../../../lib/colors';

class Courses extends Component {

  constructor(props) {
    super(props);

    this.state = {
      courses: [
        {
          name: 'Course 1000',
          title: 'course_beginner',
          slug: 1000,
          words: 1000,
          image: require("../../../assets/images/level-beginner.png")
        }, {
          name: 'Course 2000',
          title: 'course_elementary',
          slug: 2000,
          words: 1200,
          image: require("../../../assets/images/level-elementary.png")
        }
      ],
      refreshing: false,
    };

  }

  componentDidMount() {
  }

  _courseSelected = (name, slug) => {
    const {history} = this.props;
    const {locale} = this.props.settings;

    this.props.setCurrentCourseSlug(name, slug);

    history.push(`/${locale}/course/${slug}`);
  };

  _activeCourse = (slug) => {
    const {coursesDebts} =  this.props.course;

    // console.log("coursesDebts",coursesDebts)

    let active = false;

    coursesDebts && coursesDebts.forEach(course => {
      if (course.slug == slug) {
        active = course;
      }
    });

    return active;
  };

  _renderItem = ({item, index}) => {
    const {tablet} = this.state;

    const {fontSizeRation, version, deviceWidth} = this.props.settings;
    const {isConnected} = this.props.settings;
    const {userId} = this.props.user;
    const {translate} = this.props;

    let active = this._activeCourse(item.slug)

    return (
      <CourseCell
        key={index}
        isConnected={isConnected}
        tablet={tablet}
        ratio={fontSizeRation}
        index={index}
        version={version}
        deviceWidth={deviceWidth}
        course={item}
        onItemPressed={this._courseSelected}
        active={active}
        translate={translate}
        userId={userId}
      />
    )
  };

  refreshData = () => {
    const {userId} = this.props.user;
    this.props.checkForCourseDebts(userId);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      // this.getCourse();
    }
  }

  componentWillUnmount() {

    this.props.checkForCourseDebts(this.props.user.userId);

  }

  render() {
    const {fetchingData} = this.props.activity;
    const {userId} = this.props.user;
    const {locale} = this.props.settings;
    const {
      courses,
      refreshing
    } = this.state;


    const {translate} = this.props;

    return (
      <div className="coursePage">

        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate('courses')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <div style={{marginTop: 5, marginBottom: 10, textAlign: 'center'}}>
          <a href="https://youtu.be/P2f2nXFPZ40" className="p-3" target="_blank">
            {translate('watch_video_instructions')}
          </a>
        </div>

        <Container className="pageWrapper">

          {
            !userId && (
              <div style={{marginTop: 15, marginBottom: 25}}>
                <h1 style={{fontSize: 24}}>
                  Английские словарные курсы
                </h1>
                <p style={{fontSize: 18}}>
                  Мы подготовили для вас словарные курсы, которые помогут быстро и эффективно запомнить самые необходимые
                  английские слова по методике интервального повторения.
                </p>

                <p style={{fontSize: 18}}>
                  Словарный курс Beginner содержит 1000 самых популярных английских слов,
                  с которых можно начать изучение английского языка.
                </p>

                <p style={{fontSize: 18}}>
                  Словарный курс Elementary содержит 1200 популярных английских слов,
                  которые помогут вам расширить словарный запас английского языка.
                </p>

                <p style={{fontSize: 18}}>
                  Из всех слов курса можно создавать флэш-карточки с картинкой,  переводом и примером использования
                  английского слова в предложении и отправлять их на свой email.
                </p>
              </div>
            )
          }

          <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
            {
              courses.map((child, i) => {
                return this._renderItem({item: child, index: i})
              })
            }
          </div>

          <Loader fetchingData={fetchingData}/>
        </Container>

        <Helmet>
          <title>{`${translate('app_courses_title_unlocalized')}`}</title>
          <link rel="canonical" href={window.location.href}/>
          <meta name="description" content={translate('app_courses_description_unlocalized')}/>
          <meta property="og:url" content={window.location.href}/>
          <meta name="og:description" content={translate('app_courses_description_unlocalized')}/>
          <meta property="og:title" content={`${translate('app_courses_title_unlocalized')}`}/>
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  activity: state.activity,
  trainings: state.trainings,
  settings: state.settings,
  user: state.user,
  course: state.course,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    setCurrentCourseSlug: (name, slug) => dispatch(setCurrentCourseSlug(name, slug)),
    checkForCourseDebts: (userId) => dispatch(checkForCourseDebts(userId)),


  };
}

export default connect(mapStateToProps, bindAction)(Courses);
