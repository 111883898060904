import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faPlusCircle, faCheckCircle, faCircle} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import TrainingSettingCell from '../../../components/Cells/TrainingSettingCell/index';

import Loader from "../../../components/Custom/Loader/index";

import {
  setPreviewSettings,
  setDragImageSettings,
  setSpellingSettings,
  setCollectSentenceSettings,
  setCollectWordSettings,
  setMemoryCardsSettings,
  setSelectTranslationSettings,
  setSelectWordSettings,
  setDefinitionSettings,
  setMissingLetterSettings,
  setListeningSettings,
  setTranslateSentenceSettings
} from '../../../actions/trainingActions';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

class TrainingSettings extends Component {
  constructor(props) {
    super(props);
    const {translate} = this.props;

    this.state = {
      trainingName: '',
      trainingId: false,
      settings: [
        [],
        [
          // {name: 'autoplay_delay', description: ''},
          {name: 'autosound_word', description: ''},
          {name: 'autosound_translation', description: ''}
        ],
        [
          {name: 'autoslide', description: translate('autoslide_description')},
          {name: 'autoslide_delay', description: translate('autoslide_description')},
          {name: 'autosound_word', description: ''},
          {name: 'image_or_word', description: ''}
        ],
        [
          {name: 'autoslide', description: translate('autoslide_description')},
          {name: 'autoslide_delay', description: translate('autoslide_description')},
          {name: 'autosound_word', description: ''},
          {name: 'image_or_word', description: ''}
        ],
        [
          {name: 'autoslide', description: translate('autoslide_description')},
          {name: 'autoslide_delay', description: translate('autoslide_description')},
          {name: 'autosound_word', description: ''},
          {name: 'autosound_translation', description: ''},
          {name: 'image_or_word', description: ''}
        ],
        [
          {name: 'autoslide', description: translate('autoslide_description')},
          {name: 'autoslide_delay', description: translate('autoslide_description')},
          {name: 'autosound_word', description: ''},
          {name: 'image_or_word', description: ''}
        ],
        [
          {name: 'autoslide', description: translate('autoslide_description')},
          {name: 'autoslide_delay', description: translate('autoslide_description')},
          {name: 'autosound_word', description: ''},
          // {name: 'autosound_translation', description: ''}
        ],
        [
          {name: 'autoslide', description: translate('autoslide_description')},
          {name: 'autoslide_delay', description: translate('autoslide_description')},
          {name: 'autosound_word', description: ''},
          {name: 'image_or_word', description: ''}
        ],
        [
          {name: 'autoslide', description: translate('autoslide_description')},
          {name: 'autoslide_type', description: translate('autoslide_type_description')},
          {name: 'autosound_sentence', description: ''},
          {name: 'autoslide_delay', description: translate('autoslide_description')},
        ],
        [
          {name: 'autoslide', description: translate('autoslide_description')},
          {name: 'autoslide_delay', description: translate('autoslide_description')},
          {name: 'autosound_word', description: ''},
        ],
        [
          {name: 'autoslide', description: translate('autoslide_description')},
          {name: 'autoslide_delay', description: translate('autoslide_description')},
          {name: 'autosound_word', description: ''},
        ],
        [],
        [
          {name: 'autoslide', description: translate('autoslide_description')},
          {name: 'autoslide_delay', description: translate('autoslide_description')},
          {name: 'autosound_word', description: ''},
          {name: 'image_or_word', description: ''}
        ],
        [],
        [
          {name: 'autoslide_delay', description: ''},
          {name: 'autosound_word', description: ''},
          {name: 'autosound_translation', description: ''},
          {name: 'autosound_sentence', description: ''},
          {name: 'autosound_example_translation', description: translate('autosound_example_translation_description')},
          {name: 'loop', description: ''},
          {name: 'speed', description: ''},
        ],
        [
          {name: 'autoslide', description: translate('autoslide_description')},
          {name: 'autoslide_type', description: translate('autoslide_type_description')},
          {name: 'autosound_sentence', description: ''},
          {name: 'autoslide_delay', description: translate('autoslide_description')},
        ],
      ],
      autoplay_delay: '2',
      autoslide: '1',
      speed: '1',
      loop: '0',
      autoslide_delay: "2",
      autosound_word: '1',
      autoslide_type: 'sentence',
      autosound_sentence: '0',
      autosound_translation: '1',
      autosound_example_translation: '1',
      image_or_word: 'image',
      currentSetting: false,
      currentSettingValue: '1',
      pickerVisible: false,
      tablet: false

    };
  };

  componentDidMount() {
    this._setUserSettings();
  }

  componentWillUnmount() {
  }

  _setUserSettings = () => {
    const {userId} = this.props.user;
    const {trainingId} = this.props;

    const autoplay_delay = localStorage.getItem(`autoplay_delay_${trainingId}_${userId}`);
    const autoslide = localStorage.getItem(`autoslide_${trainingId}_${userId}`);

    const autoslide_delay = localStorage.getItem(`autoslide_delay_${trainingId}_${userId}`);
    const autosound_word = localStorage.getItem(`autosound_word_${trainingId}_${userId}`);
    const autosound_sentence = localStorage.getItem(`autosound_sentence_${trainingId}_${userId}`);
    const autoslide_type = localStorage.getItem(`autoslide_type_${trainingId}_${userId}`);
    const autosound_translation = localStorage.getItem(`autosound_translation_${trainingId}_${userId}`);
    const autosound_example_translation = localStorage.getItem(`autosound_example_translation_${trainingId}_${userId}`);
    const image_or_word = localStorage.getItem(`image_or_word_${trainingId}_${userId}`);
    const loop = localStorage.getItem(`loop_${trainingId}_${userId}`);
    const speed = localStorage.getItem(`speed_${trainingId}_${userId}`);

    if (autoslide != null) {
      this.setState({
        autoslide
      });
    }

    if (loop != null) {
      this.setState({
        loop
      });
    }

    if (speed != null) {
      this.setState({
        speed
      });
    }

    if (autoplay_delay != null) {
      this.setState({
        autoplay_delay
      })
    }
    if (autoslide_delay != null) {
      this.setState({
        autoslide_delay
      })
    }

    if (autosound_word != null) {
      this.setState({
        autosound_word
      })
    }

    if (autoslide_type != null) {
      this.setState({
        autoslide_type
      })
    }

    if (autosound_sentence != null) {
      this.setState({
        autosound_sentence
      })
    }

    if (autosound_translation != null) {
      this.setState({
        autosound_translation
      })
    }

    if (autosound_example_translation != null) {
      this.setState({
        autosound_example_translation
      })
    }

    if (image_or_word != null) {
      this.setState({
        image_or_word
      })
    }
  };

  onPressItem = (segue, prevValue) => {
    const {userId} = this.props.user;
    const {trainingId} = this.props;
    let value = "";

    if (segue === 'autoslide' || segue === 'image_or_word' || segue === 'autoslide_type'
      || segue === 'autosound_word' || segue === 'autosound_sentence'
      || segue === 'autosound_translation' || segue === 'autosound_example_translation' || segue === 'loop') {
      if (segue === 'image_or_word') {
        value = prevValue == 'image' ? 'word' : 'image';

        if (trainingId == 5) {
          this.props.setSpellingSettings({
            image_or_word: value
          })
        } else if (trainingId == 3) {
          this.props.setCollectWordSettings({
            image_or_word: value
          })
        } else if (trainingId == 2) {
          this.props.setSelectTranslationSettings({
            image_or_word: value
          })
        } else if (trainingId == 4) {
          this.props.setSelectWordSettings({
            image_or_word: value
          })
        } else if (trainingId == 12) {
          this.props.setMissingLetterSettings({
            image_or_word: value
          })
        }
      }

      if (segue === 'autoslide_type') {
        value = prevValue == 'sentence' ? 'word' : 'sentence';

        if (trainingId == 8) {
          this.props.setCollectSentenceSettings({
            autoslide_type: value
          })
        } else if (trainingId == 15) {
          this.props.setTranslateSentenceSettings({
            autoslide_type: value
          })
        }
      }

      if (segue === 'autoslide') {
        value = prevValue == '1' ? '0' : '1';

        if (trainingId == 6) {
          this.props.setDragImageSettings({
            autoslide: value,
          })
        } else if (trainingId == 5) {
          this.props.setSpellingSettings({
            autoslide: value
          })
        } else if (trainingId == 8) {
          this.props.setCollectSentenceSettings({
            autoslide: value
          })
        } else if (trainingId == 15) {
          this.props.setTranslateSentenceSettings({
            autoslide: value
          })
        } else if (trainingId == 3) {
          this.props.setCollectWordSettings({
            autoslide: value
          })
        } else if (trainingId == 9) {
          this.props.setMemoryCardsSettings({
            autoslide: value
          })
        } else if (trainingId == 2) {
          this.props.setSelectTranslationSettings({
            autoslide: value
          })
        } else if (trainingId == 4) {
          this.props.setSelectWordSettings({
            autoslide: value
          })
        } else if (trainingId == 10) {
          this.props.setDefinitionSettings({
            autoslide: value
          })
        } else if (trainingId == 12) {
          this.props.setMissingLetterSettings({
            autoslide: value
          })
        }
      }

      if (segue === 'autosound_sentence') {
        value = prevValue == '1' ? '0' : '1';

        if (trainingId == 8) {
          this.props.setCollectSentenceSettings({
            autosound_sentence: value
          })
        }

        if (trainingId == 15) {
          this.props.setTranslateSentenceSettings({
            autosound_sentence: value
          })
        }

        if (trainingId == 14) {
          this.props.setListeningSettings({
            autosound_sentence: value
          })
        }
      }

      if (segue === 'loop') {
        value = prevValue == '1' ? '0' : '1';

        if (trainingId == 14) {
          this.props.setListeningSettings({
            loop: value
          })
        }
      }

      if (segue === 'autosound_example_translation') {
        value = prevValue == '1' ? '0' : '1';

        if (trainingId == 14) {
          this.props.setListeningSettings({
            autosound_example_translation: value
          })
        }
      }

      if (segue === 'autosound_word') {
        value = prevValue == '1' ? '0' : '1';

        if (trainingId == 1) {
          this.props.setPreviewSettings({
            autosound_word: value
          })
        } else if (trainingId == 6) {
          this.props.setDragImageSettings({
            autosound_word: value
          })
        } else if (trainingId == 5) {
          this.props.setSpellingSettings({
            autosound_word: value
          })
        } else if (trainingId == 3) {
          this.props.setCollectWordSettings({
            autosound_word: value
          })
        } else if (trainingId == 9) {
          this.props.setMemoryCardsSettings({
            autosound_word: value
          })
        } else if (trainingId == 2) {
          this.props.setSelectTranslationSettings({
            autosound_word: value
          })
        } else if (trainingId == 4) {
          this.props.setSelectWordSettings({
            autosound_word: value
          })
        } else if (trainingId == 10) {
          this.props.setDefinitionSettings({
            autosound_word: value
          })
        } else if (trainingId == 12) {
          this.props.setMissingLetterSettings({
            autosound_word: value
          })
        } else if (trainingId == 14) {
          this.props.setListeningSettings({
            autosound_word: value
          })
        }
      }

      if (segue === 'autosound_translation') {
        value = prevValue == '1' ? '0' : '1';

        if (trainingId == 1) {
          this.props.setPreviewSettings({
            autosound_translation: value
          })
        } else if (trainingId == 4) {
          this.props.setSelectWordSettings({
            autosound_translation: value
          })
        } else if (trainingId == 14) {
          this.props.setListeningSettings({
            autosound_translation: value
          })
        }
      }

      this.setState({
        [segue]: value
      });

      localStorage.setItem(`${segue}_${trainingId}_${userId}`, `${value}`);
      
      this.props.logActivity({activityId: 188, comment: `${segue} - ${value}`});
    }

    if (segue === 'autoplay_delay' || segue === 'autoslide_delay' || segue === 'speed') {
      this.setState({
        currentSetting: segue,
        currentSettingValue: prevValue,
        pickerVisible: true
      })
    }
  };

  _changeSetting = (value, e) => {
    e.preventDefault();
    const {userId} = this.props.user;
    const {currentSetting} = this.state;
    const {trainingId} = this.props;

    this.setState({
      pickerVisible: false,
      [currentSetting]: value
    });

    if (currentSetting === 'autoslide_delay') {
      if (trainingId == 6) {
        this.props.setDragImageSettings({
          autoslide_delay: value,
        })
      } else if (trainingId == 5) {
        this.props.setSpellingSettings({
          autoslide_delay: value
        })
      } else if (trainingId == 8) {
        this.props.setCollectSentenceSettings({
          autoslide_delay: value
        })
      } else if (trainingId == 15) {
        this.props.setTranslateSentenceSettings({
          autoslide_delay: value
        })
      } else if (trainingId == 3) {
        this.props.setCollectWordSettings({
          autoslide_delay: value
        })
      } else if (trainingId == 9) {
        this.props.setMemoryCardsSettings({
          autoslide_delay: value
        })
      } else if (trainingId == 2) {
        this.props.setSelectTranslationSettings({
          autoslide_delay: value
        })
      } else if (trainingId == 4) {
        this.props.setSelectWordSettings({
          autoslide_delay: value
        })
      } else if (trainingId == 10) {
        this.props.setDefinitionSettings({
          autoslide_delay: value
        })
      } else if (trainingId == 12) {
        this.props.setMissingLetterSettings({
          autoslide_delay: value
        })
      } else if (trainingId == 14) {
        this.props.setListeningSettings({
          autoslide_delay: value
        })
      }
    } else if (currentSetting === 'speed') {
      if (trainingId == 14) {
        this.props.setListeningSettings({
          speed: value
        })
      }
    }

    this.props.logActivity({activityId: 188, comment: `${currentSetting} - ${value}`});

    localStorage.setItem(`${currentSetting}_${trainingId}_${userId}`, `${value}`);
  };

  _renderItem = (item, index) => {
    const {
      autoslide, autoplay_delay, autoslide_type,
      autoslide_delay, autosound_word, autosound_translation, autosound_sentence,
      image_or_word, autosound_example_translation, loop, speed, tablet
    } = this.state;
    const {fontSizeRation} = this.props.settings;
    const {translate, trainingId} = this.props;

    return <TrainingSettingCell
      item={item}
      key={index}
      tablet={tablet}
      ratio={fontSizeRation}
      autoplay_delay={autoplay_delay}
      autoslide={autoslide}
      autoslide_delay={autoslide_delay}
      autosound_word={autosound_word}
      autosound_sentence={autosound_sentence}
      autoslide_type={autoslide_type}
      autosound_translation={autosound_translation}
      autosound_example_translation={autosound_example_translation}
      image_or_word={image_or_word}
      loop={loop}
      speed={speed}
      translate={translate}
      trainingId={trainingId}
      onPressItem={this.onPressItem}
    />
  };

  render() {
    const {isVisible, onClose, trainingId, trainingName} = this.props;

    const {
      settings,
      currentSetting,
      pickerVisible,
    } = this.state;
    const trainingSettings = settings[trainingId];
    const {fetchingData} = this.props.activity;

    return (
      <Modal
        className="trainingsettings"
        show={isVisible}
        onHide={()=>onClose(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id={trainingName}/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>

          {trainingSettings.length !== 0 && trainingSettings.map((item, i) => {
            return this._renderItem(item, i)
          })}

          {
            pickerVisible && currentSetting !== 'speed' && (
              <div className="valuepicker card">
                <ListGroup variant="flush">
                  {
                    [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5].map((item, i) => {
                      return <ListGroup.Item key={i} action
                                             onClick={(e)=> this._changeSetting(item, e)}>{item}</ListGroup.Item>

                    })
                  }
                </ListGroup>
              </div>
            )
          }

          {
            pickerVisible && currentSetting === 'speed' && (
              <div className="valuepicker card">
                <ListGroup variant="flush">
                  {
                    [0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1, 1.05, 1.1].map((item, i) => {
                      return <ListGroup.Item key={i} action
                                             onClick={(e)=> this._changeSetting(item, e)}>{item}</ListGroup.Item>

                    })
                  }
                </ListGroup>
              </div>
            )
          }

        </Modal.Body>

        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize)
});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),

    setPreviewSettings: (data) => dispatch(setPreviewSettings(data)),
    setDragImageSettings: (data) => dispatch(setDragImageSettings(data)),
    setSpellingSettings: (data) => dispatch(setSpellingSettings(data)),
    setCollectSentenceSettings: (data) => dispatch(setCollectSentenceSettings(data)),
    setCollectWordSettings: (data) => dispatch(setCollectWordSettings(data)),
    setMemoryCardsSettings: (data) => dispatch(setMemoryCardsSettings(data)),
    setSelectTranslationSettings: (data) => dispatch(setSelectTranslationSettings(data)),
    setSelectWordSettings: (data) => dispatch(setSelectWordSettings(data)),
    setDefinitionSettings: (data) => dispatch(setDefinitionSettings(data)),
    setMissingLetterSettings: (data) => dispatch(setMissingLetterSettings(data)),
    setListeningSettings: (data) => dispatch(setListeningSettings(data)),
    setTranslateSentenceSettings: (data) => dispatch(setTranslateSentenceSettings(data))


  };
}

export default connect(mapStateToProps, bindAction)(TrainingSettings);
