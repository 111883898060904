import * as types from '../actions/types';

const INITIAL_STATE = {
  email: false,
  password: false,
  type: 'email'
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
}