import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faLightbulb,
  faStar,
  faPlus,
  faBug
} from '@fortawesome/free-solid-svg-icons';


import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import colors from '../../../lib/colors';

const WordToolbar = ({
  translate,
  ratio,
  deviceWidth,
  deviceHeight,
  toolbar,
  userData,

  word,
  showUploadButton,

  toggleFavorite,
  toggleInVocabulary,
  deleteAssociation,
  addAssociation,

  addTranslation,
  toggleAddExampleModal,
  toggleListsToFillModal,
  reportBug,
  uploadImage

}) => (

  <Container fluid className="wordtoolbar">
    <div className={toolbar}>

      <div className="d-flex justify-content-end align-items-center">
        <OverlayTrigger
          delay={{ show:300, hide: 400 }}
          placement='auto'
          overlay={<Tooltip>{translate('report_bug')}</Tooltip>}
        >
          <a href="#" onClick={reportBug} className="mr-2 p-2">
            <FontAwesomeIcon
              icon={faBug}
              style={{color: colors.coral, fontSize: 20}}
            />
          </a>
        </OverlayTrigger>

        <OverlayTrigger
          delay={{ show:500, hide: 400 }}
          placement='auto'
          overlay={<Tooltip>{word && word.is_favourite ? translate('delete_from_favorites') : translate('add_to_favorites')}</Tooltip>}
        >
          <a href="#" onClick={toggleFavorite} className="ml-2 mr-2 p-2">
            <FontAwesomeIcon
              icon={faStar}
              style={{color: word && word.is_favourite ? colors.golden : colors.greyishBrown5,fontSize: 20}}
            />
          </a>
        </OverlayTrigger>

        <OverlayTrigger
          delay={{ show:500, hide: 400 }}
          placement='auto'
          overlay={<Tooltip>{word && word.in_vocabulary ? translate('delete_from_vocabulary') : translate('add_to_vocabulary')}</Tooltip>}
        >
          <a href="#" onClick={toggleInVocabulary} className="ml-2 mr-2 p-2">
            <FontAwesomeIcon
              icon={faLightbulb}
              style={{color: word && word.in_vocabulary ? colors.dustyGreen : colors.greyishBrown5,fontSize: 20}}
            />
          </a>
        </OverlayTrigger>

        <Dropdown
          alignRight
          className="mr-3">
          <Dropdown.Toggle
            as="a"
            className="p2 toggled-link"
          >
            <FontAwesomeIcon icon={faPlus}/>
          </Dropdown.Toggle>

          <Dropdown.Menu style={{minWidth: 240}}>
            <Dropdown.Item as="button" onClick={() => toggleListsToFillModal(true)}>
              {translate('add_to_your_lists')}
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item as="button" onClick={addTranslation}>
              {translate('add_translation')}
            </Dropdown.Item>
            <Dropdown.Item as="button" onClick={toggleAddExampleModal}>
              {translate('suggest_example')}
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              onClick={(e)=>{
                e.preventDefault();

                if(word.association && word.association.id) {
                deleteAssociation(e);
              } else {
                addAssociation(e)
              }
                }}
            >
              {translate(word && word.association && word.association.id ? 'delete_word_association' : 'add_word_association')}
            </Dropdown.Item>

            {
              showUploadButton && (
                <Dropdown.Item as="button" onClick={uploadImage}>
                  {translate('add_word_image')}
                </Dropdown.Item>
              )
            }

            <Dropdown.Divider />

            <Dropdown.Item as="button" className="text-danger" onClick={reportBug}>
              {translate('report_bug')}
            </Dropdown.Item>
          </Dropdown.Menu>

        </Dropdown>
      </div>
    </div>
  </Container>
);

export default WordToolbar;
