import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faCogs,
  faCheckCircle,
  faTimes,
  faImages,
  faSearch,
  faPlus,
  faStar,
  faVideo
} from '@fortawesome/free-solid-svg-icons';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import colors from '../../../lib/colors';

import MyPagination from '../../../components/Custom/MyPagination/index';

const GlossaryToolbar = ({
  translate,
  totalPages,
  page,
  ratio,
  word,
  lists,
  tablet,
  pageClicked,
  handleKeyPress,
  typeGlossaryWord,
  clearFoundGlossaryWords,
  typeNewListName,
  searchGlossaryWords,
  saveNewList,
  toggleFavoriteGlossary,
  toolbar,
  cards,
  glossaryId,
  listName,
  currentChild,
  selectedWords,
  lifetimeStatus,

  generateCards,
  buyCards,
  words,
  tour
}) => (
  <Container fluid>
    <div className={toolbar}>
      <div className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">
        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">

          <InputGroup style={{maxWidth: 300}} className="mb-2 mb-lg-0">
            <FormControl
              disabled={tour}
              onKeyPress={(e)=>handleKeyPress('search', e)}
              value={word}
              onChange={(e)=>typeGlossaryWord(e.target.value)}
              placeholder={translate('search_word')}
            />
            <InputGroup.Append>
              <Button disabled={word.length == 0} variant="outline-secondary" onClick={clearFoundGlossaryWords}>
                <FontAwesomeIcon icon={faTimes}/>
              </Button>

              <Button disabled={word.length == 0} variant="outline-secondary"
                      onClick={searchGlossaryWords}>
                <FontAwesomeIcon icon={faSearch}/>
              </Button>
            </InputGroup.Append>
          </InputGroup>

          <div className="mb-2 mb-lg-0 ml-md-3 ml-0">
            <MyPagination
              range={5}
              pages={totalPages}
              ratio={ratio}
              currentPage={page}
              tablet={tablet}
              onClick={pageClicked}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">

          <Dropdown alignRight className="mr-md-2">
            <Dropdown.Toggle
              disabled={selectedWords.length == 0 || tour}
              variant="outline-secondary">
              {translate('add_words_to_list')}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div style={{maxHeight: 200, overflowY: 'auto'}}>
                {lists}
              </div>

              <InputGroup>
                <FormControl
                  className="mh1"
                  style={{fontSize: 12}}
                  onKeyPress={(e)=> handleKeyPress('list', e)}
                  value={listName}
                  onChange={(e)=> typeNewListName(e.target.value)}
                  placeholder={translate('enter_list_name')}
                />
                <InputGroup.Append>
                  <Button style={{fontSize: 12}} disabled={listName.length == 0} variant="outline-secondary"
                          onClick={saveNewList}>
                    <FontAwesomeIcon icon={faPlus}/>
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Dropdown.Menu>

          </Dropdown>

          <OverlayTrigger
            delay={{ show: 1000, hide: 400 }}
            placement='auto' overlay={<Tooltip>{currentChild.is_favourite ? translate('delete_from_favorites') : translate('add_to_favorites')}</Tooltip>}>
            <a href="#" onClick={(e)=>{
                    e.preventDefault();
                    toggleFavoriteGlossary(glossaryId)
                    }}
               className="ml-1 mr-1 p2"
            >
              <FontAwesomeIcon icon={faStar}
                               style={{
                color: currentChild.is_favourite ? colors.golden : colors.lightGreyThree,
                fontSize: 20
              }}
              />
            </a>
          </OverlayTrigger>

          {/*<OverlayTrigger
            delay={{ show: 1000, hide: 400 }}
            placement='auto'
            overlay={<Tooltip>{(!cards && !lifetimeStatus) ? `${translate('buy_glossary_cards')}` : translate('generate_flash_cards')}</Tooltip>}
          >
            <a href="#" onClick={(e)=>{
              e.preventDefault();
              if(!cards && !lifetimeStatus) {
                buyCards();
              } else {
                generateCards();
              }
            }}
               className="ml-1 mr-1 p2"
            >
              <FontAwesomeIcon
                icon={faImages}
                style={{color: (!cards && !lifetimeStatus) ? colors.tealish : colors.waterBlue, fontSize: 20}}
              />
            </a>
          </OverlayTrigger>*/}

          <OverlayTrigger
            delay={{ show: 1000, hide: 400 }}
            placement='auto' overlay={<Tooltip>{translate('watch_video_instructions')}</Tooltip>}
          >
            <a href="https://youtu.be/A0ywCLTzGy8" className="ml-1 p2" target="_blank">
              <FontAwesomeIcon icon={faVideo} style={{fontSize: 20}}/>
            </a>
          </OverlayTrigger>

          {/*<Dropdown alignRight>
           <Dropdown.Toggle variant="outline-secondary">
           <FontAwesomeIcon
           icon={faCogs}
           />
           </Dropdown.Toggle>

           <Dropdown.Menu>
           <Dropdown.Item href="https://youtu.be/A0ywCLTzGy8" target="_blank">
           {translate('watch_video_instructions')}
           </Dropdown.Item>
           <Dropdown.Item href="#" onClick={(e)=>{
           e.preventDefault();
           toggleFavoriteGlossary(glossaryId)
           }}>
           {currentChild.is_favourite ? translate('delete_from_favorites') : translate('add_to_favorites') }
           </Dropdown.Item>
           <Dropdown.Item href="#" onClick={(e)=>{
           e.preventDefault();
           if(!cards && !lifetimeStatus) {
           buyCards();
           } else {
           generateCards();
           }
           }}>
           {(!cards && !lifetimeStatus) ? `${translate('buy_glossary_cards')} 4.99$` : translate('generate_flash_cards')}
           </Dropdown.Item>
           </Dropdown.Menu>
           </Dropdown>*/}
        </div>
      </div>
    </div>
  </Container>
);

export default GlossaryToolbar;
