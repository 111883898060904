import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logApiError} from './logSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* getIrregularVerbs(data) {
  const params = data.payload;

  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'getIrregularVerbs')
    .send({
      user_id: userId,
      level: params.level,
    });

    yield put({
      type: types.SET_IRREGULAR_VERBS,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({payload: {function: 'getIrregularVerbs', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* logIrregularVerb(data) {
  const params = data.payload;

  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'logIrregularVerb')
    .send({
      user_id: userId,
      word_id: params.word_id,
      mistake: params.mistake,
      tense: params.tense
    });
    
  } catch (error) {
    yield logApiError({payload: {function: 'logIrregularVerb', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  }
}
