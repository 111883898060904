import * as types from '../actions/types';
import {createCourseListWithIntervals} from '../lib/trainingFunctions';
import _ from 'lodash';
import moment from 'moment';

const INITIAL_STATE = {
  courseStatus: '1',
  courseOptionSelected: false,
  currentCourseSlug: false,
  currentCourseName: "Course 1000",
  currentCourseId: null,
  lists: [],
  coursesDebts: [],
  repeats: [],
  listWords: [],
  tests: [],
  listRepetitions: [],
  courseData: false,
  currentList: false,
  mistakes: false,
  listData: false,
  fetched: false,
  fetchedCourseDebts: false,
  timeSpent: 0,
  courseMonthData: [],
  courseStatistics: false,

  vocabularyWords: [],
  fetchedVocabularyWords: false,
  totalWords: 0,
  totalPages: 0,
  fetchingNextChunk: false,
  timeToGo: false,
  courseRate: false,

  showWordSentence: false,

  fetchedStatistics: false,
  listStatistics: false,
  courseListType: false,
  allCourseMistakesFetched: false,
  allCourseMistakes: [],
  showListTrainingButtonsCourse: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case types.SET_COURSE_STATUS:
      return Object.assign({}, state, {
        courseStatus: action.payload
      });
    
    case types.SET_CURRENT_COURSE_ID:
      return Object.assign({}, state, {
        currentCourseId: action.payload
      });

    case types.SET_CURRENT_COURSE_SLUG:
      return Object.assign({}, state, {
        currentCourseSlug: action.payload.slug,
        currentCourseName: action.payload.name
      });

    case types.SET_ALL_COURSE_MISTAKES:
      return Object.assign({}, state, {
        allCourseMistakesFetched: true,
        allCourseMistakes: action.payload
      });

    case types.COURSE_OPTION_SELECTED:
      return Object.assign({}, state, {
        courseOptionSelected: action.payload
      });

    case types.SET_COURSE_VOCABULARY:
      return Object.assign({}, state, {
        vocabularyWords: action.payload.data,
        totalWords: action.payload.total_words,
        totalPages: action.payload.total_pages,
        fetchingNextChunk: false,
        fetchedVocabularyWords: true
      });

    case types.REMOVE_FROM_COURSE_VOCABULARY:{
      let vocabularyWords = state.vocabularyWords.filter(word => word.word_id != action.payload.wordId)

      return Object.assign({}, state, {
        vocabularyWords,
        totalWords: state.totalWords - 1,
        totalPages: action.payload.total_pages,
        fetchingNextChunk: false,
        fetchedVocabularyWords: true
      });
    }

    case types.GET_COURSE_VOCABULARY_NEXT_CHUNK:
      return Object.assign({}, state, {
        fetchingNextChunk: true,
      });

    case types.SET_COURSE_LIST_TYPE:
      return Object.assign({}, state, {
        courseListType: action.payload,
      });

    case types.SET_COURSE_LIST_STATISTICS:
      return Object.assign({}, state, {
        fetchedStatistics: true,
        listStatistics: action.payload,
      });

    case types.RESET_COURSE_LIST_STATISTICS:
      return Object.assign({}, state, {
        fetchedStatistics: false,
        listStatistics: false,
      });

    case types.RESET_COURSE_VOCABULARY:
      return Object.assign({}, state, {
        vocabularyWords: [],
        totalWords: 0,
        totalPages: 0,
        fetchingNextChunk: false
      });

    case types.RESET_CURRENT_COURSE_LIST:
      return Object.assign({}, state, {
        currentList: false
      });

    case types.SET_COURSE_DATA:{
      // console.log("SET_COURSE_DATA", action.payload)

      return Object.assign({}, state, {
        lists: action.payload.data,
        repeats: action.payload.repeats,
        fetched: true,
        courseRate: action.payload.rate,
        mistakes: action.payload.mistakes,
        tests: action.payload.tests,
        courseData: action.payload.course_data
      });}

    case types.UPDATE_COURSE_LISTS:
    {
      let lists = state.lists.map(list => {
        let newList = list;

        action.payload.lists.forEach(innerList => {
          if (innerList.id === list.id) {
            newList = innerList;
          }
        });
        return newList
      });

      return Object.assign({}, state, {
        lists: lists,
        repeats: action.payload.repeats,
        mistakes: action.payload.mistakes,
        tests: action.payload.tests,
        courseData: action.payload.course_data,
        fetched: true,
      });
    }

    case types.SET_COURSE_DATA_FROM_CACHE:
    {
      return Object.assign({}, state, {
        lists: action.payload.lists,
        tests: action.payload.tests,
        repeats: action.payload.repeats,
        mistakes: action.payload.mistakes,

        fetched: true,
        // courseRate: action.payload.courseRate,
        courseData: action.payload.courseData
      });
    }

    case types.SET_TIME_TO_GO_TO_COURSE:
      return Object.assign({}, state, {
        fetchedCourseDebts: true,
        timeToGo: action.payload
      });

    case types.SET_COURSE_RATE:
      return Object.assign({}, state, {
        courseRate: action.payload,
      });

    case types.RESET_COURSE_DATA:
      return Object.assign({}, state, {
        lists: [],
        repeats: [],
        fetched: false,
        mistakes: false,
        tests: [],
        courseData: false
      });

    case types.SET_COURSE_LIST:
      return Object.assign({}, state, {
        currentList: action.payload,
      });

    case types.SET_COURSE_LIST_DATA:
      return Object.assign({}, state, {
        listData: action.payload.data,
        timeSpent: action.payload.timeSpent
      });

    case types.SET_CURRENT_COURSE_LIST_DATA:
      return Object.assign({}, state, {
        currentList: action.payload.data,
      });

    case types.RESET_COURSE_LIST_DATA:
      return Object.assign({}, state, {
        listData: false,
        timeSpent: false
      });

    case types.RESET_COURSE_LIST_WORDS:
    {
      return Object.assign({}, state, {
        listWords: [],
      });
    }

    case types.SET_COURSE_LIST_CALENDAR:
    {
      return Object.assign({}, state, {
        listRepetitions: [],
      });
    }

    case types.SET_COURSE_LIST_WORDS:
      return Object.assign({}, state, {
        listWords: action.payload.data,
      });


    case types.DELETE_WORD_FROM_COURSE_LIST_WORDS:
    {
      let listWords = state.listWords.filter(word => word.word_id != action.payload.wordId)

      return Object.assign({}, state, {
        listWords
      });
    }

    case types.SET_COURSE_LIST_WORDS_FROM_CACHE:
      return Object.assign({}, state, {
        listWords: action.payload,
      });

    case types.SET_COURSE_DATA_FETCHED:
      return Object.assign({}, state, {
        fetched: action.payload,
      });

    case types.SET_COURSE_CALENDAR_MONTH_DATA:{

      const today = moment().format("YYYY-MM-DD");

      let data = [];
      let dataArray = [];

      if(action.payload.length !== 0) {
        data = action.payload;

        dataArray = data.map((item, index) => {
          let color = '#F35757';
          if (item.repetition_date >= today) {
            color = item.trainings == 0 ? 'rgba(62,61,61,0.5)' : '#26C1CD';
          } else {
            color = item.trainings == 0 ? '#F35757' : '#26C1CD';
          }
          return {
            id: index,
            title: item.list_name,
            allDay: false,
            start: new Date(item.repetition_date),
            end: new Date(item.repetition_date),
            color,
            list_id: item.list_id,
            day: item.repetition_date,
            list: item
          }
        })
      }

      return Object.assign({}, state, {
        courseMonthData: dataArray
      });}

    case types.SET_COURSE_STATISTICS:
      return Object.assign({}, state, {
        courseStatistics: action.payload
      });

    case types.SET_SHOW_WORD_SENTENCE:
      return Object.assign({}, state, {
        showWordSentence: action.payload
      });

    case types.SET_SHOW_LIST_TRAINING_BUTTONS_MODAL_COURSE:
      return Object.assign({}, state, {
        showListTrainingButtonsCourse: action.payload,
      });

    case types.UPDATE_COURSE_DATA:
      return Object.assign({}, state, {
        courseData: action.payload,
      });
    
    case types.SET_ONLY_COURSE_DATA:
      return Object.assign({}, state, {
        courseData: action.payload,
      });

    case types.UPDATE_CURRENT_LIST_SCORES:
    {
      let currentList = state.currentList;
      currentList.scores = action.payload;

      return Object.assign({}, state, {
        currentList,
      });
    }

    case types.SET_COURSE_DEBTS:
    {
      return Object.assign({}, state, {
        coursesDebts: action.payload,
      });
    }

    default:
      return state;
  }
}