import React, {Component} from 'react';
import {connect} from "react-redux";
import _ from 'lodash';
import classNames from 'classnames';
import {withRouter, Link} from 'react-router-dom';
import {Helmet} from "react-helmet";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faVideo, faTimes, faSearch} from '@fortawesome/free-solid-svg-icons'

import {Translate, getTranslate} from 'react-localize-redux';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import ChildGlossary from '../../../components/Cells/ChildGlossary/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';

import Loader from "../../../components/Custom/Loader/index";

import {fetchingData} from '../../../actions/activity'
import {
  getParentGlossaries,
  setCurrentChild,
  searchGlossaries,
  resetGlossaries,
  setCurrentParent,
  setGlossariesFromCached
} from '../../../actions/glossaryActions';
import {logActivity} from '../../../actions/logActions';
import {getRegistrationDate, checkForHappyDay} from '../../../actions/purchasesActions';

class Glossaries extends Component {

  constructor(props) {
    super(props);

    const {translate} = this.props;

    this.state = {
      searchBarVisible: false,
      glossaryName: '',
      loggedSearch: false,
      tablet: false,
      subscriptionAlertVisible: false,
      showSettingsModal: false,
      level: "1",
      levelText: translate('basic_level'),
      deviceWidth: window.innerWidth,
      deviceHeight: window.innerHeight,
      filters: [
        {name: 'most_popular', id: 'top'},
        {name: 'basic_level', id: '1'},
        {name: 'elementary_level', id: '2'},
        {name: 'intermediate_level', id: '3'},
        {name: 'advanced_level', id: '4'},
        {name: 'oxford_5000', id: '9'},
        {name: 'toefl_ielts', id: '6'},
        {name: 'by_topics', id: '7'},
        {name: 'phrasal_verbs', id: '10'}
      ],
      tour: localStorage.getItem('tour')
    };

    this.props.getRegistrationDate();
    this.props.checkForHappyDay();
    this.timeOut = null;
    this.timeOut1 = null;

    this.onChangepDelayed = _.debounce(this._onSearch, 500);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));

    this.timeOut = setTimeout(() => {
      this.getLevel();
    }, 100);
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut1);
    
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      deviceWidth: window.innerWidth,
      deviceHeight: window.innerHeight
    });
  }

  _onClickItem = (child, index) => {
    const {subscriptionActive, applyLimit, glossariesLimit, webSubscriptionStatus, cards, lifetimeStatus} = this.props.purchases;
    const {history} = this.props;
    const {locale} = this.props.settings;

    this.props.setCurrentChild(child);

    if (lifetimeStatus || subscriptionActive || cards || webSubscriptionStatus || !applyLimit) {
      history.push(`/${locale}/glossary/${child.id}`, {child});
    } else {
      if (index < glossariesLimit) {
        history.push(`/${locale}/glossary/${child.id}`, {child});
      } else {
        this._toggleSubscriptionAlert(true)
      }
    }
    this.props.logActivity({activityId: 154, model: 'glossary', modelId: child.id});
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toSubscription = (productId) => {
    this._toggleSubscriptionAlert(false);
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId});
  };

  _renderChildItem = ({item, index}) => {
    const {applyLimit, glossariesLimit, webSubscriptionStatus, cards} = this.props.purchases;
    const {fontSizeRation, version} = this.props.settings;
    const {userData} = this.props.user;
    const {deviceWidth} = this.state;
    const {translate} = this.props;

    return (
      <ChildGlossary
        key={index}
        deviceWidth={deviceWidth}
        translate={translate}
        webSubscriptionStatus={webSubscriptionStatus}
        applyLimit={applyLimit}
        glossariesLimit={glossariesLimit}
        cards={cards}
        userData={userData}
        ratio={fontSizeRation}
        version={version}
        glossary={item}
        index={index}
        onItemPressed={this._onClickItem}
      />
    )
  };

  toggleSearchBar = () => {
    const {searchBarVisible, level} = this.state;

    if (searchBarVisible) {
      this.setState({
        glossaryName: ''
      });

      let cached = this.checkForCachedLevel(level);

      if (!cached) {
        this.props.fetchingData(true);
        this.props.getParentGlossaries(level);
      } else {
        this.props.setGlossariesFromCached(cached)
      }
    }

    this.setState({
      searchBarVisible: !searchBarVisible
    })
  };

  toggleSettingsModal = (status) => {
    this.setState({
      showSettingsModal: status
    });
  };

  _onSearch() {
    const {glossaryName, level, loggedSearch} = this.state;

    if (glossaryName != "") {
      this.props.fetchingData(true);

      this.props.searchGlossaries(glossaryName);

      if (!loggedSearch) {
        this.props.logActivity({activityId: 15, comment: glossaryName});

        this.setState({
          loggedSearch: true
        })
      }
    } else {
      let cached = this.checkForCachedLevel(level);

      if (!cached) {
        this.props.resetGlossaries();

        this.props.fetchingData(true);
        this.props.getParentGlossaries(level);
      } else {
        this.props.setGlossariesFromCached(cached)
      }
    }
  }

  getFilterName = () => {
    const {level} = this.state;

    let filterName = "all_glossaries";

    switch (level) {
      case "all":
        filterName = "all_glossaries";
        break;
      case "1":
        filterName = "basic_level";
        break;
      case "2":
        filterName = "elementary_level";
        break;
      case "3":
        filterName = "intermediate_level";
        break;
      case "4":
        filterName = "advanced_level";
        break;
      case "7":
        filterName = "by_topics";
        break;
      case "10":
        filterName = "phrasal_verbs";
        break;
      case "top":
        filterName = "most_popular";
        break;
    }

    return filterName;
  };

  getLevel = () => {
    const {userId, userData} = this.props.user;
    
    const glossariesLevel = localStorage.getItem(`glossariesLevel${userId}`);

    let cached = this.checkForCachedLevel(glossariesLevel !== null ? glossariesLevel : this.state.level);

    if (glossariesLevel !== null) {
      this.setState({
        level: glossariesLevel
      });
    } else {
      if (userData && userData.level) {
        this.setState({
          level: userData.level + 1
        })
      }
    }

    if (!cached) {
      this.props.fetchingData(true);
      this.props.getParentGlossaries(glossariesLevel !== null ? glossariesLevel : this.state.level);
    } else {
      this.props.setGlossariesFromCached(cached)
    }
  };

  checkForCachedLevel = (level) => {
    const {cachedGlossaries} = this.props.cache;

    let levelGlossaries = false;

    cachedGlossaries.forEach(glossaries => {
      if (level == glossaries.level) {
        levelGlossaries = glossaries.data;
      }
    });

    return levelGlossaries
  };

  getByFilters = (level) => {
    this.setLevel(level);

    let cached = this.checkForCachedLevel(level);

    this.timeOut = setTimeout(()=> {
      if (!cached) {
        this.props.fetchingData(true);
        this.props.getParentGlossaries(level);
      } else {
        this.props.setGlossariesFromCached(cached)
      }
    }, 250);
  };

  setLevel = (level) => {
    const {userId} = this.props.user;

    this.setState({
      level
    });

    localStorage.setItem(`glossariesLevel${userId}`, `${level}`);
  };

  _getFiltersButtons = () => {
    const {translate} = this.props;
    const {level, filters} = this.state;

    return filters.map((filter, i) => {
      const activeClass = classNames({
        'active': level == filter.id,
        'glossaryFilterName': true
      });
      return (
        <Button
          className={activeClass}
          onClick={()=> this.getByFilters(filter.id)}
          key={i}
          variant="secondary"
        >
          {translate(filter.name)}
        </Button>
      )
    })
  };

  _getFiltersDropdown = () => {
    const {translate} = this.props;
    const {level, filters} = this.state;

    return filters.map((filter, i) => {
      const activeClass = classNames({
        'active': level == filter.id,
        'glossaryFilterName': true
      });
      return (
        <Dropdown.Item
          href="#"
          className={activeClass}
          onClick={()=> this.getByFilters(filter.id)}
          key={i}
        >
          {translate(filter.name)}
        </Dropdown.Item>
      )
    })
  };

  render() {
    const {parents, totalGlossariesWords, totalGlossaries, parentSections} = this.props.glossaries;
    const {fetchingData} = this.props.activity;
    const {userData, userId} = this.props.user;

    const {translate} = this.props;

    const {
      tablet,
      subscriptionAlertVisible,
      deviceWidth,
      tour,
    } = this.state;
    const subtitle = `${translate('topicss')}: ${totalGlossaries} / ${translate('wordss')}: ${totalGlossariesWords}`;
    const {fontSizeRation, locale} = this.props.settings;
    const filtersButtons = this._getFiltersButtons();
    const filtersDropdown = this._getFiltersDropdown();
    const filterName = this.getFilterName();

    return (
      <div className="glossaries">
        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate('glossaries')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container style={{marginTop: 20}} className="pageWrapper">

          {
            !userId && (
              <div style={{marginTop: 15, marginBottom: 25}}>
                <h1 style={{fontSize: 24}}>
                  Английская лексика по темам
                </h1>
                <p style={{fontSize: 18}}>
                  В этом разделе мы собрали самые популярные английские слова и для вашего удобства разделили их по темам 
                  и уровням.</p>
                <p style={{fontSize: 18}}>
                  Английские слова глоссариев содержат картинку, транскрипцию, перевод, а также озвучивание,
                  с возможностью переключения между британским и американским вариантом произношения и женским и мужским голосом.
                  Кроме того, слова глоссариев содержат примеры их использования в английских предложениях,
                  что поможет вам лучше запомнить их значение.
                  </p>
                <p style={{fontSize: 18}}>
                  Все английские слова вы можете сохранять в свои списки и учить на наших упражнениях.
                </p>

                <p style={{fontSize: 18}}>
                  Слова из глоссариев "Уровень Beginner" и "Уровень Elementary" составляют 80% слов, которые вам необходимо знать,
                  чтобы понимать большую часть лексики и вести разговор на любые бытовые темы.
                </p>
                <p style={{fontSize: 18}}>
                Слова из глоссария "Уровень Intermediate" позволят вам читать и понимать специализированную литературу.
                </p>

                <p style={{fontSize: 18}}>
                  Слова из глоссариев "Oxford 5000" и "TOEFL / IELTS" позволят вам успешно подготовиться к любому экзамену
                  на получение английского сертификата.
                </p>
              </div>
            )
          }

          <div className="d-flex justify-content-center align-items-center mb-3">
            {deviceWidth >= 992 && <ButtonGroup size="sm">
              {filtersButtons}
            </ButtonGroup>}

            {deviceWidth < 992 && (
              <OverlayTrigger
                delay={{ show: 500, hide: 500 }}
                placement='auto'
                overlay={<Tooltip>{translate('change_glossaries_filter')}</Tooltip>}
              >
                <DropdownButton size="sm" variant="secondary" id="dropdown-basic-button" title={translate(filterName)}>
                  {filtersDropdown}
                </DropdownButton>
              </OverlayTrigger>
            )}

            <OverlayTrigger
              delay={{ show: 1000, hide: 400 }}
              placement='auto' overlay={<Tooltip>{translate('watch_video_instructions')}</Tooltip>}
            >
              <a href="https://youtu.be/gZ3LnrLPlXg" className="ml-1 p2" target="_blank">
                <FontAwesomeIcon icon={faVideo} style={{fontSize: 15}}/>
              </a>
            </OverlayTrigger>
          </div>

          <div style={{flex: 1}}>
            {
              parents.map((item, index) => {
                return (
                  <div key={`${item.id}-${index}`}>
                    <div
                      className="parentHeader"
                      style={{marginBottom: !tablet ? 5 : 10, marginTop: !tablet ? 5 : 15,}}>
                      <p
                        className="headerTitle"

                        style={{fontSize: fontSizeRation * !tablet ? 16 : 22}}>
                        {item.glossary_name}
                      </p>
                      <p
                        className="headerNumbers"
                        style={{fontSize: fontSizeRation * !tablet ? 14 : 20}}>
                        {`${translate('topicss')}: ${item.children_number} / ${translate('wordss')}: ${item.total_words}`}
                      </p>

                      <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
                        {
                          item.children.map((child, i) => {
                            return this._renderChildItem({item: child, index: i})
                          })
                        }
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                translate={translate}
                deviceWidth={deviceWidth}
                description={'subscription_glossaries'}
              />
            )
          }

          <Loader fetchingData={fetchingData}/>
        </Container>

        <Helmet>
          <title>{`${translate('app_glossaries_title_unlocalized')}`}</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="description" content={translate('app_glossaries_description_unlocalized')} />
          <meta property="og:url" content={window.location.href} />
          <meta name="og:description" content={translate('app_glossaries_description_unlocalized')} />
          <meta property="og:title" content={`${translate('app_glossaries_title_unlocalized')}`} />
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  glossaries: state.glossaries,
  activity: state.activity,
  settings: state.settings,
  purchases: state.purchases,
  cache: state.cache,
  translate: getTranslate(state.localize),

});

function

bindAction(dispatch) {
  return {
    fetchingData: (status) => dispatch(fetchingData(status)),
    getParentGlossaries: (level) => dispatch(getParentGlossaries(level)),
    searchGlossaries: (glossaryName) => dispatch(searchGlossaries(glossaryName)),
    resetGlossaries: () => dispatch(resetGlossaries()),
    setCurrentParent: (parent) => dispatch(setCurrentParent(parent)),
    logActivity: (data) => dispatch(logActivity(data)),
    getRegistrationDate: () => dispatch(getRegistrationDate()),
    setCurrentChild: (child) => dispatch(setCurrentChild(child)),
    setGlossariesFromCached: (data) => dispatch(setGlossariesFromCached(data)),
    checkForHappyDay: () => dispatch(checkForHappyDay()),

  };
}

export default connect(mapStateToProps, bindAction)(withRouter(Glossaries));