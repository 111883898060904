import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Translate, getTranslate} from 'react-localize-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGoogle, faFacebook} from '@fortawesome/free-brands-svg-icons'
import {toastr} from 'react-redux-toastr';
import ReCAPTCHA from "react-google-recaptcha";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';

import {GoogleLogin} from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import {login} from '../../../actions/authActions';
import {setUserIpAddress} from '../../../actions/userActions';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

import * as helpers from '../../../lib/validators';

import Logo from "../../../assets/images/logo-boy-prepared.png";
import SmallMnemonic from "../../../components/Custom/SmallMnemonic/index";
import Loader from "../../../components/Custom/Loader/index";

const publicIp = require('public-ip');

class Login extends Component {

  constructor(props) {
    super(props);


    this.state = {
      email: '',
      userInfo: false,
      password: '',
      remember_me: 1,
      socialId: false,
      recaptcha: true,
    };

    this._checkUser();
    this._getIpAddress();

    this.recaptchaRef = React.createRef();
    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();

  }

  componentDidMount() {}

  _checkUser = () => {
    const {locale} = this.props.settings;

    const userId = localStorage.getItem('userId');
    const {history} = this.props;

    if (userId) {
      history.replace(`/${locale}/home`);
    }
  };

  _getIpAddress = async() => {
    const ip = await publicIp.v4();

    if (ip) {
      this.props.setUserIpAddress(ip)
    }
  };

  responseGoogle = (response) => {
    const {translate} = this.props;

    let userInfo = response.profileObj;

    if (!userInfo) {
      toastr.error(translate('canceled_sign_in_flow'))
    } else {
      this.setState({
        userInfo,
        socialId: response.googleId
      });
      this.props.logActivity({activityId: 7, comment: userInfo.email});

      if (!userInfo.email) {
        toastr.error(translate('no_email_returned'));
      } else {
        if (userInfo.email && userInfo.email.length > 0) {
          this.attemptToLogin('google')
        } else {
          toastr.error(translate('no_email_returned'));
        }
      }
    }
  };

  responseFacebook = (response, error) => {
    const {translate} = this.props;

    if (!response.email) {
      // toastr.error(translate('canceled_sign_in_flow'))

      toastr.error(translate('no_email_returned'));
    } else {
      this.setState({
        userInfo: response,
        socialId: response.userID
      });

      this.props.logActivity({activityId: 105, comment: response.email});

      if (response.email && response.email.length > 0) {
        this.attemptToLogin('facebook')
      } else {
        toastr.error(translate('no_email_returned'));
      }
    }
  };

  loginWithEmailPassword = () => {
    const {translate} = this.props;

    this.setState({
      userInfo: false
    });

    if (this.state.email.length === 0 || this.state.password.length === 0) {
      toastr.warning('', translate('empty_fields'), {icon: (<SmallMnemonic />)});
    } else if (!helpers.checkEmail(this.state.email)) {
      toastr.warning('', translate('valid_email'), {icon: (<SmallMnemonic />)});
    } else {
      this.props.logActivity({activityId: 6, comment: this.state.email});

      // const recaptchaValue = this.recaptchaRef.current.getValue();
      // console.log("recaptchaValue",recaptchaValue);

      this.attemptToLogin('email');
    }
  };

  attemptToLogin = (type) => {
    this.props.fetchingData(true);

    const data = {
      ...{
        email: !this.state.userInfo ? this.state.email : this.state.userInfo.email,
        password: this.state.password,
        platform: type,
        remember_me: this.state.remember_me,
        token: this.state.token
      },
      social: this.state.socialId,
      history: this.props.history
    };

    this.props.login(data);
  };

  componentClicked = (event) => {};

  handleChange(field, value) {
    this.setState({[field]: value});
  }

  onRecaptchaChange = (e) => {
    this.setState({
      recaptcha: e
    })
  };

  _handleKeyPress = (type, event) => {
    if (event.key === 'Enter') {
      if (type == 'password') {
        this.loginWithEmailPassword(event);
      }
      if (type == 'email') {
        this.passwordRef.current.focus();
      }
    }
  };

  render() {
    const {fetchingData} = this.props.activity;
    const {fontSizeRation, locale} = this.props.settings;
    const {translate} = this.props;
    const {recaptcha} = this.state;

    return (
      <div className="auth-logo">
        <div className="d-flex flex-column justify-content-center align-items-center mb-3 logo-container">
          <span className="font-weight-bold" style={{fontSize: fontSizeRation * 40}}>Mnemonic Words</span>

          <Image src={Logo} className="logo mt-2"/>
        </div>

        <div className="d-flex flex-rom justify-content-center align-items-center mb-2">
          <GoogleLogin
            clientId="75117688803-5u9a94dor4ghq4ud5jgqjini2ti32e61.apps.googleusercontent.com"
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogle}
            onClick={this.componentClicked}
            render={renderProps => (
              <button className="socialIcon mr-3" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                  <FontAwesomeIcon icon={faGoogle}/>
              </button>
            )}
          />

          <FacebookLogin
            appId="2684485318484487"
            autoLoad={false}
            language={'ru'}
            fields="name,email,picture"
            callback={this.responseFacebook}
            onClick={this.componentClicked}
            render={renderProps => (
              <button className="socialIcon ml-3" onClick={renderProps.onClick}>
                <FontAwesomeIcon icon={faFacebook}/>
              </button>
            )}
          />
        </div>

        <Form className="form-signin">
          <label htmlFor="inputEmail" className="sr-only">{translate('Email')}</label>
          <input
            ref={this.emailRef}
            type="email"
            id="inputEmail"
            onKeyPress={(e)=>this._handleKeyPress('email',e)}
            className="form-control"
            placeholder={translate('Email')} required autoFocus
            onChange={(e)=>this.handleChange('email',e.target.value)}
          />

          <label htmlFor="inputPassword" className="sr-only">{translate('password')}</label>

          <input
            ref={this.passwordRef}

            type="password" id="inputPassword"
            className="form-control"
            onKeyPress={(e)=>this._handleKeyPress('password',e)}

            placeholder={translate('password')} required
            onChange={(e)=>this.handleChange('password',e.target.value)}
          />

          <Link to={`/${locale}/forgot`} className="forgotLink">
            <Translate id="forgot_password"/>
          </Link>

          <div className="checkbox mb-3">
            <label>
              <input
                name="remember_me" type="checkbox"
                onChange={()=>this.handleChange('remember_me', this.state.remember_me == 1 ? 0 : 1)}
                checked={this.state.remember_me == 1} value={this.state.remember_me}/> {translate('remember_me')}
            </label>
          </div>

          <Button
            disabled={!recaptcha}
            style={{fontSize: fontSizeRation * 16}}
            className="gradient-blue"
            variant="primary"
            type="button" size="lg" block onClick={this.loginWithEmailPassword}>
            <Translate id="sign_in"/>
          </Button>

          {/*<div className="mt-2 text-center">
           <ReCAPTCHA
           ref={this.recaptchaRef}
           sitekey={process.env.REACT_APP_CAPTCHA_KEY}
           onChange={this.onRecaptchaChange}
           />
           </div>*/}

          <Link to={`/${locale}/register`} className="signUpLink mt-4">
            <Translate id="sign_up"/>
          </Link>
        </Form>

        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}


function bindAction(dispatch) {
  return {
    setUserIpAddress: (ip) => dispatch(setUserIpAddress(ip)),
    login: (data) => dispatch(login(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    logActivity: (data) => dispatch(logActivity(data)),
  };
}

const mapStateToProps = state => ({
  // token: state.auth.token,
  user: state.user,
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize),
});

export default connect(
  mapStateToProps,
  bindAction,
)(withRouter(Login));
