import React, {Component} from 'react';
import {connect} from "react-redux";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons';

import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import RelaxCell from '../../../components/Cells/RelaxCell/index'
import Everyday from '../../../components/Everyday/index'

import colors from '../../../lib/colors';

class Repeats extends Component {


  render() {
    const {
      translate,
      ratio,
      tablet,
      deviceWidth,
      deviceHeight,

      relaxPlan,
      relaxData,

      everydayWord,
      everydayPhrase,
      gettingWord,
      gettingPhrase,
      getNewEveryday,
      addEverydayToList,

      level,
      onItemPressed,

      newUser,
      notNewUser

    }= this.props;

    return (
      relaxPlan && (
        <React.Fragment>
          {
            newUser && (
              <Card className="newUserInfo p-3">
                <a href="#" onClick={notNewUser} className="close">
                  <FontAwesomeIcon icon={faTimes}/>
                </a>
                <p>
                  {translate('info_for_new_user')}
                </p>
                <p className="mb-0">
                  {translate('info_for_new_user_2')}
                </p>
              </Card>
            )
          }

          <div className="d-flex flex-row justify-content-center align-items-center">
            <OverlayTrigger
              delay={{ show: 700, hide: 400 }}
              trigger={['hover', 'focus']} placement="auto" overlay={<Popover id="popover-basic">
                        <Popover.Content>
                          <p className="nl2br">{translate('info_for_new_user')}</p>
                          <p className="nl2br">{translate('info_for_new_user_2')}</p>
                        </Popover.Content>
                      </Popover>}
            >
              <div className="flexH flexVStart mb-3 hover">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{color: colors.tealish, fontSize: !tablet ? 20 : 30}}
                />
                        <span
                          className="fontBold textCenter"
                          style={{fontSize: ratio * 16, color: colors.tealish, marginLeft: 10}}
                        >
                          {translate('relax_plan_title')}
                        </span>
              </div>
            </OverlayTrigger>
          </div>

          <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
            {
              relaxData.map((list, index) => {
                return (
                  <RelaxCell
                    deviceWidth={deviceWidth}
                    deviceHeight={deviceHeight}
                    key={index}
                    tablet={tablet}
                    ratio={ratio}
                    item={list}
                    level={level}
                    index={index}
                    onItemPressed={onItemPressed}
                  />
                );
              })
            }
          </div>

          {(everydayWord || everydayPhrase) && <Card className="p-2 flexV flexStart everydays"
                                                     style={{marginTop: !tablet ? 15 : 25}}>
            {
              everydayWord && (
                <Everyday
                  translate={translate}
                  deviceHeight={deviceHeight}
                  deviceWidth={deviceWidth}
                  key={1}
                  fetchingData={gettingWord}
                  addToList={addEverydayToList}
                  type={'word'}
                  getNew={getNewEveryday}
                  everyday={everydayWord}
                />
              )
            }

            {
              everydayPhrase && (
                <Everyday
                  translate={translate}
                  deviceHeight={deviceHeight}
                  deviceWidth={deviceWidth}
                  key={2}
                  fetchingData={gettingPhrase}
                  addToList={addEverydayToList}
                  type={'phrase'}
                  getNew={getNewEveryday}
                  everyday={everydayPhrase}
                />
              )
            }

          </Card>}
        </React.Fragment>
      )
    )
  }
}

export default connect(null, null)(Repeats);
