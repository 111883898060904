import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import DatePicker from "react-datepicker";
import moment from 'moment';
import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import Loader from "../../../components/Custom/Loader/index";

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import {getUserAppProfile, deleteAccount} from '../../../actions/userActions';
import {update_profile, update_avatar} from '../../../actions/userActions';

import colors from '../../../lib/colors';
import * as constants from '../../../lib/constants';

class ChangeUserProfile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      birthday: "",
      password: "",
      avatar: "",
      newAvatar: null,
      level: 0,
      show: false,
      tablet: false,
      deleteBlock: false
    };
  }

  componentDidMount() {
    const {userData} = this.props.user;
    
    this._setUserData(userData);

    if (!userData || !userData.id) {
      this.props.getUserAppProfile();
    }
  }

  handleChange(field, value) {
    this.setState({[field]: value});
  }

  _setUserData = (userData) => {
    this.setState({
      firstName: userData.first_name ? userData.first_name : '',
      lastName: userData.last_name ? userData.last_name : '',
      birthday: userData.dob ? new Date(moment(userData.dob)) : '',
      avatar: userData.avatar,
      level: userData.level
    })
  };

  _changeProfile = () => {
    this.setState({
      show: false
    });

    this.props.fetchingData(true);

    this.props.logActivity({activityId: 80});

    this.props.update_profile(this.state)
  };

  handleChoosePhoto = (e) => {
    const files = Array.from(e.target.files);

    const formData = new FormData();
    formData.append('avatar', e.target.files[0]);

    this.handleUploadPhoto(formData)
  };


  handleUploadPhoto = (photo) => {

    fetch(constants.API_URL + 'update_avatar', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data;charset=UTF-8'
      },
      method: "POST",
      photo
    })
    .then(response => response.json())
    .then(response => {
      // console.log(response)
      // this.setState({avatar: response.avatar});
    })
    .catch(error => {
      // console.log(error.response);
    });
  };

  _toggleDeleteBlock = (e, status) => {
    e.preventDefault();
    
    this.setState({
      deleteBlock: status
    })
  };

  _deleteAccount = () => {
    this.props.logActivity({activityId: 358, comment: this.state.password});

    this.props.fetchingData(true);
    this.props.deleteAccount(this.props.history, this.state.password)
  };

  render() {
    const {fetchingData} = this.props.activity;
    const {translate, onClose, isVisible, changeProfile} = this.props;
    const {userData} = this.props.user;
    const {deleteBlock, password} = this.state;
    const {fontSizeRation} = this.props.settings;

    return (
      <Modal
        className="changeuserprofile"
        show={isVisible}
        onHide={()=>onClose('changeProfileModalVisible',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="update_pforile"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!deleteBlock && <React.Fragment>
            <Card className="p-2 d-flex flex-column justify-content-center">
              {
                userData && userData.avatar ? (
                  <Image
                    thumbnail
                    roundedCircle
                    style={{width: 100, height: 100, margin: '0 auto 10px'}}
                    src={userData.avatar}
                  />
                ) : (
                  <Card className="img-thumbnail rounded-circle"
                        style={{width: 100, height: 100, margin: '0 auto 10px', backgroundColor: colors.lightGrey5}}
                  />
                )
              }

              {/*<Form.File
               className="position-relative dnone"
               name="file"
               label="File"
               onChange={this.handleChoosePhoto}
               custom
               />*/}

              <Form.Group controlId="firstName">
                <Form.Label>{translate('first_name')}</Form.Label>
                <Form.Control
                  value={this.state.firstName}
                  type="text" placeholder={translate('first_name')}
                  onChange={(e)=>this.handleChange('firstName',e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="lastName">
                <Form.Label>{translate('last_name')}</Form.Label>
                <Form.Control
                  value={this.state.lastName}
                  type="text" placeholder={translate('last_name')}
                  onChange={(e)=>this.handleChange('lastName',e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="lastName">
                <Form.Label>{translate('birthday')}</Form.Label>
                <DatePicker
                  wrapperClassName="d-block"
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  selected={this.state.birthday}
                  onChange={(date)=>this.handleChange('birthday', date)}
                />
              </Form.Group>

            </Card>

            <Button
              className="mt-3"
              variant="outline-info"
              block
              onClick={this._changeProfile}
            >
              <Translate id="update_profile"/>
            </Button>

            <a className="text-danger d-inline-block ml-auto mt-3" href="#"
               onClick={(e) => this._toggleDeleteBlock(e,true)}>
              {translate('delete_user_account')}
            </a>
          </React.Fragment>}

          {
            deleteBlock && (
              <div className="alert alert-secondary mt-3">
                <h5>{translate('delete_account_ask')}</h5>
                <p>{translate('delete_account_warning')}</p>

                <Form.Group controlId="password">
                  <Form.Label>{translate('enter_account_password')}</Form.Label>
                  <Form.Control
                    value={password}
                    type="password"
                    placeholder={translate('password')}
                    onChange={(e)=>this.handleChange('password',e.target.value)}
                  />
                </Form.Group>

                <div className="mt-2 d-flex justify-content-between">
                  <Button
                    variant="outline-secondary"
                    onClick={(e) => this._toggleDeleteBlock(e,false)}
                  >
                    {translate('cancel')}
                  </Button>
                  <Button
                    variant="outline-danger"
                    disabled={password.length < 6}
                    onClick={this._deleteAccount}
                  >{translate('delete')}</Button>
                </div>
              </div>
            )
          }
        </Modal.Body>

        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    getUserAppProfile: () => dispatch(getUserAppProfile()),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    update_profile: (data) => dispatch(update_profile(data)),
    update_avatar: (image) => dispatch(update_avatar(image)),
    deleteAccount: (history, password) => dispatch(deleteAccount(history, password)),
  };
}

export default connect(mapStateToProps, bindAction)(ChangeUserProfile);
