import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircle, faCogs, faCheckCircle,
  faTrashAlt,
  faCamera,
  faVolumeUp,
  faTimes, faSearch, faPlus
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import {Helmet} from "react-helmet";

import {Translate, getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';
import moment from 'moment';

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import Loader from "../../../components/Custom/Loader/index";

import WordInfo from '../../../components/Modals/WordInfo/index';
import ListWord from '../../../components/Cells/ListWord/index';
import TrainingButtons from '../../../components/Modals/TrainingButtons/index';
import ListToolbar from '../../../components/Toolbars/ListToolbar/index';
import GlossariesForList from '../../../components/Modals/GlossariesForList/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
import CopyPasteWords from '../../../components/Modals/CopyPasteWords/index';
import ListName from '../../../components/Modals/ListName/index';
import ListsToFill from '../../../components/Modals/ListsToFill/index';
import TagsModal from '../../../components/Modals/TagsModal/index';
import GenerateListCards from '../../../components/Modals/GenerateListCards/index';
import RegisterAlert from '../../../components/Modals/RegisterAlert/index';
import TrainingsSet from '../../../components/Modals/TrainingsSet/index';

import {
  resetListWords,
  getListWords,
  deleteListFromList,
  editList,
  setCurrentList,
  deleteListWord,
  toggleInVocabulary,
  toggleFavoriteWord,
  toggleFavouriteList,
  addUserWords,
  removeFromCalendar,
  postponeInCalendar,
  toggleListTrainingButtonsModal,
  setCachedListWords,
  resetCurrentList,
  toggleArchiveList,
  cleanListCache,
  performBulkAction,
  moveToOtherLists,
  getCurrentListData,
}
  from '../../../actions/lists.js';
import {get_glossaries_for_list, setCurrentChild} from '../../../actions/glossaryActions';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import {getTags, connectTag} from '../../../actions/tagActions';

import colors from '../../../lib/colors';
import * as constants from '../../../lib/constants';

class ListWords extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searchBarVisible: false,
      trainingsModalVisible: false,
      glossariesModalVisible: false,
      refreshing: false,
      listSize: 10,
      shouldShowEmptyListAlert: false,
      alertEmptyListVisible: false,
      subscriptionMessage: 'subscription_trainings',
      shouldShowSwipeListAlert: false,
      alertSwipeListVisible: false,
      subscriptionAlertVisible: false,
      wordInfoVisible: false,

      listWordsSettingsModalVisible: false,
      listWordsSettingsDeleteModalVisible: false,
      removeFromCalendarModalVisible: false,
      generateListCardsModal: false,
      listModalVisible: false,
      copyPasteVisible: false,
      bulkMode: false,
      bulkModeType: false,
      listsModalIsVisible: false,
      tagsModalVisible: false,
      selectedWords: [],
      studyLanguage: 'en',
      tablet: false,
      listId: this.props.match.params.id,

      deviceWidth: window.innerWidth,
      deviceHeight: window.innerHeight,
      scrolled: 0,

      back: false,
      tour: localStorage.getItem('tour'),
      registerModalVisible: false,
      trainingsSetModal: false,

    };

    this.track = null;
    this.timeOut1 = null;
    this.timeOut2 = null;
    this.timeOut3 = null;
  }

  componentDidMount() {
    this.setListSize();

    window.addEventListener('scroll', this.handleScroll.bind(this));

    const {listId} = this.state;
    const {currentList} = this.props.lists;

    if (!currentList.id) {
      if (listId != 0) {
        this.props.getCurrentListData(listId);
      }
    }

    if (this.props.history.location && this.props.history.location.state) {
      this.setState({
        back: this.props.history.location.state.back
      })
    }

    this._getListWords();

    this.timeOut1 = setTimeout(() => {
      this.props.getTags();
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    const {listId} = this.state;
    const {currentList} = this.props.lists;

    if (listId === undefined || currentList.id === undefined) {
      // this.props.navigation.goBack();
    }

    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._getListWords();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut1);
    clearTimeout(this.timeOut2);
    clearTimeout(this.timeOut3);
    
    this.props.resetListWords();
    this.props.resetCurrentList();

    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const scrollY = window.scrollY;

    this.setState({
      scrolled: scrollY
    });
  }

  _getListWords = () => {
    const {listId} = this.state;

    const {isConnected} = this.props.settings;

    const {cachedVisitedLists} = this.props.cache;

    const {subscriptionActive, applyLimit, webSubscriptionStatus} = this.props.purchases;

    let active = false;

    if (subscriptionActive || webSubscriptionStatus || !applyLimit) {
      active = true;
    }

    let found = false;
    let foundWords = [];

    cachedVisitedLists.forEach(list => {
      if (list.listId === listId) {
        found = true;
        foundWords = list.listWords;
      }
    });

    if (!found) {
      if (isConnected) {
        this.props.fetchingData(true);

        this.props.getListWords(listId);
      }
    } else {
      this.props.fetchingData(true);

      this.props.setCachedListWords({
        data: foundWords
      });
      this.props.fetchingData(false);
    }

    // TODO OPTIMIZE
    if (isConnected) {
      this.timeOut2 = setTimeout(() => {
        this.props.get_glossaries_for_list(active ? 200 : 10);
      }, 500);
    }
  };

  setListSize = () => {
    const {userId} = this.props.user;

    const listSize = localStorage.getItem(`listSize${userId}`);
    const studyLanguage = localStorage.getItem(`studyLanguage${userId}`);

    if (listSize !== null) {
      this.setState({
        listSize
      });
    }
    if (studyLanguage !== null) {
      this.setState({
        studyLanguage
      });
    }
  };

  vocabularyToggle = (id, index) => {
    const {listWords} = this.props.lists;

    if(!this.state.tour) {
      let word = listWords[index];
      this.props.fetchingData(true);

      this.props.toggleInVocabulary(id, index);

      this.props.logActivity({
        activityId: word.in_vocabulary == true ? 49 : 48,
        model: 'word',
        modelId: id,
        comment: "from list"
      });
    } else {
      this._toggleRegisterModal(true);
    }
  };

  favoriteToggle = (id, index) => {
    const {listWords} = this.props.lists;

    if(!this.state.tour) {
      let word = listWords[index];
      this.props.fetchingData(true);

      this.props.toggleFavoriteWord(this.state.listId, id, index);

      this.props.logActivity({
        activityId: word.is_favourite == true ? 47 : 46,
        model: 'word',
        modelId: id,
        comment: "from glossary"
      });
    } else {
      this._toggleRegisterModal(true);
    }
  };

  deleteWord(id, index) {
    this.props.fetchingData(true);

    this.props.deleteListWord(this.state.listId, id, index);
    this.props.logActivity({activityId: 35, model: 'word', modelId: id});
  }

  toggleTrainingButtons = (status) => {
    this.setState({
      trainingsModalVisible: status
    });

    this.props.toggleListTrainingButtonsModal(false)
  };

  trainingSelected = (training) => {
    const {todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus} = this.props.purchases;

    let active = false;
    const {userData} = this.props.user;
    const {currentList} = this.props.lists;
    const {locale} = this.props.settings;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    this.setState({
      subscriptionMessage: 'subscription_trainings'
    });

    if (tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    this.setState({
      trainingsModalVisible: false
    });

    this.props.toggleListTrainingButtonsModal(false);

    if (active) {
      let activityId = '';

      let path = '';

      if (training == 'Preview') {
        activityId = 39;
        path = 'preview'
      } else if (training == 'DragImage') {
        path = 'image';

        activityId = 40;
      } else if (training == 'SelectTranslation') {
        path = 'translation';
        activityId = 41;
      } else if (training == 'SelectWord') {
        path = 'word';
        activityId = 42;
      } else if (training == 'CollectWord') {
        path = 'collect';
        activityId = 43;
      } else if (training == 'CheckSpelling') {
        path = 'spelling';
        activityId = 44;
      } else if (training == 'CollectSentence') {
        activityId = 114;
        path = 'sentence';
      } else if (training == 'CollectSentenceTap') {
        activityId = 335;
        path = 'sentence';
      } else if (training == 'MemoryCards') {
        activityId = 131;
        path = 'memory'
      } else if (training == 'WordDefinition') {
        path = 'definition';
        activityId = 148;
      } else if (training == 'SpeakOut') {
        activityId = 95;
      } else if (training == 'MissingLetter') {
        path = 'missing';
        activityId = 315;
      } else if (training == 'Listening') {
        path = 'listening';
        activityId = 345;
      } else if (training == 'TranslateSentence') {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut3 = setTimeout(()=> {
        this.props.history.push(`/${locale}/${path}/${currentList.id}`, {type: 'listId', from: 'listWords', listId: currentList.id});
        this.props.logActivity({activityId, model: 'list', modelId: currentList.id, comment: 'from home'});
      }, 100);
    } else {
      this._toggleSubscriptionAlert(true);
    }
  };

  _goDirectlyToTraining = (list) => {
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {userData} = this.props.user;
    const {locale} = this.props.settings;

    let active = false;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    if (active) {
      let trainingIds = userData.trainings_set != null ? userData.trainings_set.split(',').map(Number) : [];

      let trainingId = trainingIds.length > 0 ? trainingIds[0] : 1;

      let activityId = '';
      let path = '';

      if (trainingId == 1) {
        activityId = 39;
        path = 'preview'
      } else if (trainingId == 6) {
        path = 'image';
        activityId = 40;
      } else if (trainingId == 2) {
        path = 'translation';
        activityId = 41;
      } else if (trainingId == 4) {
        path = 'word';
        activityId = 42;
      } else if (trainingId == 3) {
        path = 'collect';
        activityId = 43;
      } else if (trainingId == 5) {
        path = 'spelling';
        activityId = 44;
      } else if (trainingId == 8) {
        activityId = 114;
        path = 'sentence';
      } else if (trainingId == 9) {
        activityId = 131;
        path = 'memory'
      } else if (trainingId == 10) {
        path = 'definition';
        activityId = 148;
      } else if (trainingId == 7) {
        activityId = 95;
      } else if (trainingId == 12) {
        path = 'missing';
        activityId = 315;
      } else if (trainingId == 14) {
        path = 'listening';
        activityId = 345;
      } else if (trainingId == 15) {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut4 = setTimeout(()=> {
        this.props.history.replace(`/${locale}/${path}/${list.id}`, {
          type: 'listId',
          from: 'listWords',
          listId: list.id
        });
      }, 100);

      this.props.logActivity({activityId, model: 'list', modelId: list.id, comment: 'from lists'});

    } else {
      this._toggleSubscriptionAlert(true);
    }
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toSubscription = (productId) => {
    this._toggleSubscriptionAlert(false);
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId});
  };

  toggleFavorite = () => {
    const {listId} = this.state;
    const {currentList} = this.props.lists;

    if(!this.state.tour) {
      this.props.fetchingData(true);

      this.props.toggleFavouriteList(listId, 0, false, currentList.favourite, true);

      this.props.logActivity({
        activityId: currentList.favourite == 0 ? 28 : 29,
        model: 'list',
        modelId: listId,
        comment: "from list"
      });
    } else {
      this._toggleRegisterModal(true);
    }
  };

  toDictionary = () => {
    const {currentList} = this.props.lists;
    const {locale} = this.props.settings;

    this.props.logActivity({activityId: 33, model: 'list', modelId: currentList.id, comment: "from list"});

    this.props.history.push(`/${locale}/dictionary`, {
      list: currentList,
      listId: currentList.id,
      listName: currentList.list_name,
      back: true
    })
  };

  showGlossaries = (status) => {
    if (status) {
      this.props.logActivity({activityId: 32});
    }
    this.setState({
      glossariesModalVisible: status
    })
  };

  _toGlossary = (glossary, e) => {
    e.preventDefault();

    this.showGlossaries(false);
    const {currentList} = this.props.lists;
    const {locale} = this.props.settings;

    this.props.setCurrentChild(glossary);

    this.props.history.push(`/${locale}/glossary/${glossary.id}`, {back: true, list: currentList});
    
    this.props.logActivity({activityId: 34, model: 'glossary', modelId: glossary.id, comment: "from list"});
  };
  
  playTrack = (url) => {
    const {isConnected} = this.props.settings;

    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    if (isConnected) {
      this.track = new Audio(url);
      this.track.play();
    }
  };

  _onPressWord = (word, e) => {
    e.preventDefault();
    const {locale} = this.props.settings;

    const {translate} = this.props;
    const {bulkMode, bulkModeType, selectedWords} = this.state;

    if (bulkMode) {
      let index = selectedWords.indexOf(word.id);

      if (index === -1) {
        if (bulkModeType == 'delete' || bulkModeType == 'move') {
          this.setState({
            selectedWords: [...selectedWords, word.id]
          })
        } else {
          if (bulkModeType == 'vocabulary') {
            if (!word.in_vocabulary) {
              this.setState({
                selectedWords: [...selectedWords, word.id]
              })
            } else {
              toastr.warning("", translate('already_there'))
            }
          } else if (bulkModeType == 'favorite') {
            if (!word.is_favourite) {
              this.setState({
                selectedWords: [...selectedWords, word.id]
              })
            } else {
              toastr.warning("", translate('already_there'))
            }
          }
        }
      } else {
        selectedWords.splice(index, 1);

        this.setState({
          selectedWords
        })
      }
    } else {
      // this._toggleWordInfo(true)
      this.props.history.push(`/${locale}/dictionary/${word.word}`, {word, back: true})
    }
  };

  _renderItem = (item, index) => {
    const {fontSizeRation, version} = this.props.settings;
    const {tablet, bulkMode, bulkModeType, selectedWords} = this.state;
    const {translate} = this.props;
    const imageUrl = item && item.image && item.image.filename ? item.image.filename : false;
    const {userData, userId} = this.props.user;
    const imageUserId = item && item.image && item.image.user_id ? item.image.user_id : false;

    let selected = selectedWords.indexOf(item.id);

    let circleColor = colors.lightGrey;
    if (bulkMode) {
      if (bulkModeType == 'delete') {
        circleColor = colors.coral;
      } else if (bulkModeType == 'vocabulary') {
        circleColor = colors.waterBlue;
      } else if (bulkModeType == 'favorite') {
        circleColor = colors.golden;
      } else if (bulkModeType == 'move') {
        circleColor = colors.greyishBrown;
      }
    }

    const translation = item && item.main_translation && item.main_translation.translation ? item.main_translation.translation : "";

    let audio = item && item.audio ? item.audio : false;
    let audioSource = constants.BACKEND_URL;

    if (userData && userData.audio_language && item[userData.audio_language]) {
      audio = item[userData.audio_language];
      audioSource = constants.S3
    }

    const imageWidth = 200;
    const wordName = item && item.word ? item.word : "";
    const transcription = item && item.transcription ? item.transcription : "";

    return (
      <ListWord
        key={index}

        wordName={wordName}
        transcription={transcription}
        imageWidth={imageWidth}
        translation={translation}
        audio={audio}
        audioSource={audioSource}
        userData={userData}
        word={item}
        index={index}
        imageUrl={imageUrl}
        imageUserId={imageUserId}
        userId={userId}
        inVocabulary={item.in_vocabulary}
        inFavorite={item.is_favourite}
        tablet={tablet}
        version={version}
        ratio={fontSizeRation}
        onPlay={this.playTrack}
        onPressWord={this._onPressWord}
        bulkMode={bulkMode}
        circleColor={circleColor}
        selected={selected !== -1}
        translate={translate}
        favoriteWordToggle={this.favoriteToggle}
        vocabularyToggle={this.vocabularyToggle}
        bulkMode={bulkMode}
      />
    )
  };

  _toggleEmptyListsModal = (status) => {
    this.setState({
      alertEmptyListVisible: status
    })
  };

  _toggleSwipeListsModal = (status) => {
    this.setState({
      alertSwipeListVisible: status
    })
  };

  _toDictionaryFromAlert = () => {
    this._toggleEmptyListsModal(false);

    this.toDictionary();
  };

  _fromGlossaryFromAlert = () => {
    this._toggleEmptyListsModal(false);

    this.showGlossaries(true)
  };

  copyPasteWords = () => {
    this.setState({
      copyPasteVisible: true
    })
  };

  _addUserWords = (text, allWords) => {
    const {currentList} = this.props.lists;

    this.toggleShowPasteModal(false);
    this.props.fetchingData(true);
    this.props.addUserWords(text, allWords, currentList.id);
  };

  toggleShowPasteModal = (status) => {
    this.setState({
      copyPasteVisible: status
    })
  };

  deleteList = () => {
    const {currentList} = this.props.lists;

    if(!this.state.tour) {
      this.props.fetchingData(true);

      this.props.logActivity({activityId: 25, model: 'list', modelId: currentList.id});

      this.props.deleteListFromList(currentList.id, true, this.props.history);
    } else {
      this._toggleRegisterModal(true)
    }
  };

  updateList = () => {
    this._toggleModal('listModalVisible', true);
  };

  _handleList = (listName) => {
    if(!this.state.tour) {
      const {currentList} = this.props.lists;
      this.props.fetchingData(true);
      currentList.list_name = listName;
      this.props.setCurrentList(currentList);

      this.props.editList(listName, currentList.id, "by_list_name", "asc");
      this.props.logActivity({activityId: 24, comment: listName});
    } else {
      this._toggleRegisterModal(true)
    }

    this._toggleModal('listModalVisible', false);
  };

  _handleKeyPress = (type, e) => {
    if (e.key === 'Enter') {
      if (type == 'list' && e.target.value.length > 0) {
        this._handleList(e.target.value);
      }
    }
  };

  removeFromCalendar = () => {
    const {currentList} = this.props.lists;
    const {translate} = this.props;

    if(!this.state.tour) {
      this.props.logActivity({activityId: 27, model: 'list', modelId: currentList.id});

      if (currentList.intervals.length > 0) {
        this.props.removeFromCalendar(currentList.id, "by_list_name", "asc");
        currentList.intervals = [];

        this.props.setCurrentList(currentList);
      } else {
        toastr.warning('', translate('cannot_remove_from_calendar'));
      }
    } else {
      this._toggleRegisterModal(true)
    }

  };

  _buyListCards = () => {
    const {currentList} = this.props.lists;
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: 'list_cards'});
    this.props.logActivity({activityId: 244, model: 'list', modelId: currentList.id});
  };

  _generateCards = () => {
    const {currentList} = this.props.lists;
    this._toggleModal('generateListCardsModal', true);

    this.props.logActivity({activityId: 246, model: 'list', modelId: currentList.id});
  };

  _postpone = () => {
    const {currentList} = this.props.lists;

    if(!this.state.tour) {
      this.props.fetchingData(true);

      this.props.logActivity({activityId: 50, model: 'list', modelId: currentList.id, comment: "from list words"});
      this.props.postponeInCalendar(currentList.id)
    } else {
      this._toggleRegisterModal(true)
    }
  };

  _toggleArchive = () => {
    const {currentList} = this.props.lists;

    if(!this.state.tour) {
      this.props.fetchingData(true);

      this.props.logActivity({
        activityId: 310,
        model: 'list',
        modelId: currentList.id,
        comment: currentList.archive == 1 ? "delete from archive" : "add to archive"
      });
      this.props.toggleArchiveList(currentList.id)
    } else {
      this._toggleRegisterModal(true)
    }
  };

  _cleanListCache = () => {
    const {currentList} = this.props.lists;
    const {translate} = this.props;

    const {isConnected} = this.props.settings;

    this.props.logActivity({activityId: 314});

    this.props.cleanListCache(currentList.id);

    toastr.success("", translate('list_cache_cleaned'));

    if (isConnected) {
      this.props.fetchingData(true);

      this.props.getListWords(currentList.id);
    }
  };

  _toggleBulkMode = (type) => {
    this.setState({
      bulkMode: true,
      bulkModeType: type
    })
  };

  _performBulkAction = (e) => {
    e.preventDefault();
    const {currentList} = this.props.lists;
    const {translate} = this.props;

    const {selectedWords, bulkModeType, tour} = this.state;

    if(!tour) {
      if (selectedWords.length == 0) {
        toastr.warning("", translate('no_words_selected'));
      } else {
        if (bulkModeType != 'move') {
          this.setState({
            bulkMode: false
          });

          this.props.fetchingData(true);

          this.props.performBulkAction(currentList.id, selectedWords, bulkModeType, 'list');
          this.props.logActivity({activityId: 338, comment: `${bulkModeType}`});

          this._disableBulkMode();
        } else {
          this.toggleListsToFillModal(true);
        }
      }
    } else {
      this._toggleRegisterModal(true);
    }
  };

  toggleSelectAll = (e) => {
    e.preventDefault();
    const {selectedWords, bulkModeType} = this.state;
    const {listWords} = this.props.lists;

    if (selectedWords.length > 0) {
      this.setState({
        selectedWords: []
      })
    } else {
      let selected = [];

      listWords.forEach(item => {
        if (bulkModeType == 'delete' || bulkModeType == 'move') {
          selected.push(item.id)
        } else {
          if (bulkModeType == 'vocabulary') {
            if (!item.in_vocabulary) {
              selected.push(item.id)
            }
          } else if (bulkModeType == 'favorite') {
            if (!item.is_favourite) {
              selected.push(item.id)
            }
          }
        }
      });

      this.setState({
        selectedWords: selected
      })
    }
  };

  _disableBulkMode = () => {
    this.setState({
      selectedWords: [],
      bulkMode: false,
      bulkModeType: false
    })
  };

  toggleListsToFillModal = (status) => {
    this.setState({
      listsModalIsVisible: status
    })
  };

  _checkActiveStatus = () => {
    const {lists} = this.props.lists;
    const {listsLimit, applyLimit, webSubscriptionStatus} = this.props.purchases;
    let active = false;

    if (webSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = lists.length < listsLimit;
    }

    return active;
  };

  fillLists = (id, e) => {
    e.preventDefault();
    const {currentList} = this.props.lists;
    const {selectedWords} = this.state;

    this.toggleListsToFillModal(false);
    let active = true;

    if (id == 0) {
      active = this._checkActiveStatus();
    }

    if (active) {
      this.props.fetchingData(true);

      this.props.moveToOtherLists(currentList.id, id, selectedWords, 'list');

      this._disableBulkMode();
    } else {
      this.setState({
        subscriptionMessage: 'subscription_lists'
      });
      this._toggleSubscriptionAlert(true);
    }
  };

  _toggleTagsModal = (status) => {
    this.setState({
      tagsModalVisible: status
    });
  };

  _tagSelected = (tag, e) => {
    e.preventDefault();

    const {currentList} = this.state;

    this.props.fetchingData(true);
    this.props.connectTag(currentList.id, tag);

    this._toggleTagsModal(false);
  };

  _openTagModal = (list) => {
    if(!this.state.tour) {
      this._toggleTagsModal(true);

      this.setState({
        currentList: list
      });
      this.props.setCurrentList(list);
    } else {
      this._toggleRegisterModal(true)
    }
  };

  _toggleModal = (modal, status) => {
    this.setState({
      [modal]: status
    })
  };

  _toggleWordInfo = (status) => {
    this.setState({
      wordInfoVisible: status
    })
  };

  _toggleRegisterModal = (status) => {
    this.setState({
      registerModalVisible: status
    })
  };

  _toRegister = () => {
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/register`)
  };

  _customizeButtons = (e) => {
    e.preventDefault();
    const {tour} = this.state;
    this.toggleTrainingButtons(false);

    if(!tour) {
      this._toggleModal('trainingsSetModal', true)
    } else {
      this._toggleRegisterModal(true);
    }
  };

  render() {
    const {listWords, currentList, showListTrainingButtons, fetchedListWords, lists} = this.props.lists;
    let {
      listSize,
      glossariesModalVisible,
      subscriptionAlertVisible,
      copyPasteVisible,
      studyLanguage,
      listModalVisible,
      tablet,
      trainingsModalVisible,
      bulkMode,
      bulkModeType,
      selectedWords,
      listsModalIsVisible,
      tagsModalVisible,
      scrolled,
      wordInfoVisible,
      back,
      listId,
      generateListCardsModal,
      subscriptionMessage,
      registerModalVisible,
      tour,
      trainingsSetModal
    } = this.state;
    const {tags} = this.props.tags;
    const {translate} = this.props;
    const {glossariesForList} = this.props.glossaries;
    const {fetchingData} = this.props.activity;
    const subtitle = `${translate('wordss')}: ${currentList ? currentList.words : 0}`;
    const disabled = listWords && listWords.length >= listSize;
    const realList = listId > 0;
    const {fontSizeRation, deviceWidth, deviceHeight, locale} = this.props.settings;
    const {products, lifetimeStatus, subscriptionActive, webSubscriptionStatus} = this.props.purchases;
    const {userData, approvedDictionaryTranslations} = this.props.user;

    let level = userData && userData.level ? userData.level : 0;
    let listCards = userData && userData.list_cards ? userData.list_cards : 0;

    const toolbar = classNames({
      'fixed-toolbar': scrolled >= 50,
      'justify-content-between listToolbar justify-content-center mb-4': true
    });
    let active = false;

    if (subscriptionActive || webSubscriptionStatus) {
      active = true;
    }

    let showDictionary = approvedDictionaryTranslations.indexOf(userData.translation_language) !== -1 || tour;

    return (
      <div className="listWords">

        <Breadcrumb style={{marginTop: 50}}>
          {
            !back ? (
              <React.Fragment>
                <li className="breadcrumb-item">
                  <Link to={`/${locale}/home`}>
                    {translate('home')}
                  </Link>
                </li>

                <li className="breadcrumb-item">
                  <Link to={`/${locale}/lists`}>
                    {translate('lists')}
                  </Link>
                </li>
                <Breadcrumb.Item active>
                  {currentList.list_name}
                </Breadcrumb.Item>
              </React.Fragment>
            ) : (
              <li className="breadcrumb-item">
                <a href="#" onClick={(e)=>{
                  e.preventDefault();
                  this.props.history.goBack()
                }}>
                  {translate('cancel_back')}
                </a>
              </li>
            )
          }
        </Breadcrumb>

        <ListToolbar
          translate={translate}
          ratio={fontSizeRation}
          tablet={tablet}
          toolbar={toolbar}
          active={active}
          deviceHeight={deviceHeight}
          deviceWidth={deviceWidth}
          listSize={listSize}
          listId={listId}
          title={currentList ? currentList.list_name : ""}
          fromDictionary={this.toDictionary}
          fromGlossaries={this.showGlossaries}
          paste={this.copyPasteWords}
          toggleFavorite={this.toggleFavorite}
          disabled={disabled}
          updateList={this.updateList}
          deleteList={this.deleteList}
          postpone={this._postpone}
          realList={realList}
          removeFromCalendar={this.removeFromCalendar}
          list={currentList}
          favourite={currentList && currentList.favourite == 1}
          studyLanguage={studyLanguage}
          buyListCards={this._buyListCards}
          listCards={listCards}
          // listCards={true}
          generateCards={this._generateCards}
          products={products}
          toVideo={this._toVideoInstructions}
          lifetimeStatus={lifetimeStatus}
          toggleArchive={this._toggleArchive}
          cleanListCache={this._cleanListCache}
          translationLanguage={userData.translation_language}
          approvedDictionaryTranslations={approvedDictionaryTranslations}
          words={currentList ? currentList.words : 0}
          openTagsModal={this._openTagModal}

          paste={this.copyPasteWords}
          toBulkMode={this._toggleBulkMode}
          fromDictionary={this.toDictionary}
          fromGlossaries={this.showGlossaries}
          showDictionary={showDictionary}
          listWords={listWords}
          bulkMode={bulkMode}
          toggleTrainingButtons={this.toggleTrainingButtons}
          favoriteWords={false}
          tour={tour}
          toFirstTraining={this._goDirectlyToTraining}
          userData={userData}
        />

        <Container className="pageWrapper">

          <div className="d-flex justify-content-center align-items-center mb-2">
            <span
              className="font-weight-bold mr-2">{currentList && currentList.list_name ? `${currentList.list_name}: ` : ''}</span>{" "}
            <span>{currentList && currentList.words ? `${currentList.words} ${translate('lwords')}` : ''}</span>
          </div>

          {
            fetchedListWords && listWords.length > 0 && bulkMode && bulkModeType && (
              <Card className="deleteListsTextWrapper">
                  <span className="bulkModeText font-weight-bold" style={{fontSize: fontSizeRation * 16}}>
                    {translate(`list_words_to_${bulkModeType}_action`)}
                  </span>

                <div className="bulkModeWrapper flex-md-row flex-sm-column flex-column" style={{marginTop: 10}}>

                  <a href="#"
                     className="mr-3 mb-0 mb-sm-2 mb-md-0"
                     onClick={this.toggleSelectAll}>
                    <FontAwesomeIcon
                      className="selectedIcon"
                      icon={selectedWords.length > 0 ? faCheckCircle : faCircle}
                      style={{marginRight: !tablet ? 10 : 20, fontSize: !tablet ? 20 : 30}}/>
                    <span
                      className="selectAllWords pv4"
                      style={{marginRight: 10, color: colors.greyishBrown, fontSize: fontSizeRation * 16}}>
                      {selectedWords.length > 0 ? translate('deselect_words') : translate('select_all_words')}
                    </span>
                  </a>

                  <Button
                    size="sm"
                    className="mr-3 mb-0 mb-sm-2 mb-md-0"
                    disabled={selectedWords.length === 0}
                    variant={bulkModeType == 'delete' ? 'outline-danger' : 'outline-success'}

                    onClick={this._performBulkAction}
                  >
                    {translate(`to_${bulkModeType}`)} - {selectedWords.length}
                  </Button>

                  <Button
                    size="sm"
                    className="mr-3"
                    variant="outline-secondary"
                    onClick={this._disableBulkMode}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </Card>
            )
          }
          
          {
            fetchedListWords && listWords.length == 0 && (
              <div className="flexVertical flexCenter mt5">

                <Button
                  variant="outline-info"
                  style={{marginTop: 15, width: 300, borderRadius: 25}}
                  onClick={this.toDictionary}
                >
                  {translate('add_from_dictionary')}
                </Button>

                <Button
                  variant="outline-info"
                  style={{marginTop: 15, width: 300, borderRadius: 25}}
                  onClick={() => this.showGlossaries(true)}
                >
                  {translate('add_from_glossaries')}
                </Button>

                <Button
                  variant="outline-info"
                  style={{marginTop: 15, width: 300, borderRadius: 25}}
                  onClick={this.copyPasteWords}
                >
                  {translate('paste_text')}
                </Button>
              </div>
            )
          }

          <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
            {
              listWords.map((list, index) => {
                return this._renderItem(list, index);
              })
            }
          </div>

          {(trainingsModalVisible || showListTrainingButtons) && listWords.length > 0 && (
            <TrainingButtons
              tablet={tablet}
              level={level}
              translate={translate}
              modalVisible={trainingsModalVisible || showListTrainingButtons}
              training={this.trainingSelected}
              onClose={() => this.toggleTrainingButtons(false)}
              ratio={fontSizeRation}
              translationLanguage={userData.translation_language}
              title="select_training_to_repeat"
              tour={this.state.tour}
              customiseButtons={this._customizeButtons}

            />
          )}

          {glossariesModalVisible && (
            <GlossariesForList
              isVisible={glossariesModalVisible}
              toGlossary={this._toGlossary}
              glossaries={glossariesForList}
              onClose={this.showGlossaries}
              ratio={fontSizeRation}
              tablet={tablet}
              fetchingData={fetchingData}
              translate={translate}
            />
          )}

          {copyPasteVisible && (
            <CopyPasteWords
              isVisible={copyPasteVisible}
              addUserWords={this._addUserWords}
              onClose={this.toggleShowPasteModal}
              ratio={fontSizeRation}
              translate={translate}
              tablet={tablet}
            />
          )}

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={subscriptionMessage}
                translate={translate}
              />
            )
          }

          {wordInfoVisible && (<WordInfo onClose={this._toggleWordInfo} isVisible={wordInfoVisible}/>)}

          {listModalVisible && (
            <ListName
              handleList={this._handleList}
              list={currentList}
              isVisible={listModalVisible}
              ratio={fontSizeRation}
              onClose={this._toggleModal}
              translate={translate}
              handleKeyPress={this._handleKeyPress}
            />
          )}

          {listsModalIsVisible && (
            <ListsToFill
              isVisible={listsModalIsVisible}
              lists={lists}
              fillLists={this.fillLists}
              listSize={listSize}
              currentListId={currentList ? currentList.id : false}
              ratio={fontSizeRation}
              selectedWords={selectedWords.length}
              onClose={this.toggleListsToFillModal}
              translate={translate}
              tablet={tablet}
              fetchingData={fetchingData}
            />
          )}

          {tagsModalVisible && (
            <TagsModal
              isVisible={tagsModalVisible}
              onClose={this._toggleModal}
              tablet={tablet}
              tags={tags}
              logActivity={this.props.logActivity}
              onSelect={this._tagSelected}
              ratio={fontSizeRation}
              currentList={currentList}
            />
          )}

          {
            generateListCardsModal && (
              <GenerateListCards
                isVisible={generateListCardsModal}
                onClose={this._toggleModal}
                list={currentList}
                listId={listId}
                type={'list'}
              />
            )
          }
          {registerModalVisible && (
            <RegisterAlert
              onClose={this._toggleRegisterModal}
              isVisible={registerModalVisible}
              translate={translate}
              toRegister={this._toRegister}
            />
          )}
        </Container>

        {
          trainingsSetModal && (
            <TrainingsSet
              onClose={this._toggleModal}
              isVisible={trainingsSetModal}
            />
          )
        }
        <Loader fetchingData={fetchingData}/>

        <Helmet>
          <title>{`${translate('app_title_lists')}`}</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="description" content={translate('app_title_lists_description')} />
          <meta property="og:url" content={window.location.href} />
          <meta name="og:description" content={translate('app_title_lists_description')} />
          <meta property="og:title" content={`${translate('app_title_lists')}`} />
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  cache: state.cache,
  lists: state.lists,
  glossaries: state.glossaries,
  purchases: state.purchases,
  activity: state.activity,
  settings: state.settings,
  tags: state.tags,
  translate: getTranslate(state.localize),
});

function bindAction(dispatch) {
  return {
    get_glossaries_for_list: (limit) => dispatch(get_glossaries_for_list(limit)),
    resetListWords: () => dispatch(resetListWords()),
    resetCurrentList: () => dispatch(resetCurrentList()),
    getListWords: (id) => dispatch(getListWords(id)),
    deleteListWord: (list_id, word_id, index) => dispatch(deleteListWord(list_id, word_id, index)),
    toggleInVocabulary: (word_id, index) => dispatch(toggleInVocabulary(word_id, index)),
    toggleFavoriteWord: (list_id, word_id, index) => dispatch(toggleFavoriteWord(list_id, word_id, index)),
    toggleFavouriteList: (list_id, index, favorites, favorite, fromList) => dispatch(toggleFavouriteList(list_id, index, favorites, favorite, fromList)),
    setCurrentChild: (child) => dispatch(setCurrentChild(child)),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    deleteListFromList: (listId, back, history) => dispatch(deleteListFromList(listId, back, history)),
    addUserWords: (text, allWords, listId) => dispatch(addUserWords(text, allWords, listId)),
    editList: (listName, listId, filter, order) => dispatch(editList(listName, listId, filter, order)),
    setCurrentList: (list) => dispatch(setCurrentList(list)),
    removeFromCalendar: (listId, filter, order) => dispatch(removeFromCalendar(listId, filter, order)),
    postponeInCalendar: (listId) => dispatch(postponeInCalendar(listId)),
    toggleListTrainingButtonsModal: (status) => dispatch(toggleListTrainingButtonsModal(status)),
    setCachedListWords: (data) => dispatch(setCachedListWords(data)),
    toggleArchiveList: (id) => dispatch(toggleArchiveList(id)),
    cleanListCache: (id) => dispatch(cleanListCache(id)),
    performBulkAction: (listId, words, type, source) => dispatch(performBulkAction(listId, words, type, source)),
    moveToOtherLists: (fromListId, toListId, words, source) => dispatch(moveToOtherLists(fromListId, toListId, words, source)),
    getTags: () => dispatch(getTags()),
    connectTag: (listId, tag) => dispatch(connectTag(listId, tag)),
    getCurrentListData: (listId) => dispatch(getCurrentListData(listId)),
  };
}

export default connect(mapStateToProps, bindAction)(withRouter(ListWords));
