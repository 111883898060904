import React, {Component} from 'react';
import {toastr} from 'react-redux-toastr';

import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';

import colors from '../../../lib/colors';


class BadgeModal extends Component {

  render() {
    const {onClose,
      isVisible,
      badge,
      badgeImage,
      ratio,
      toSegue,
      tablet, 
      deviceHeight,
      deviceWidth,
      translate
    } = this.props;

    const color = (badge.progress == 1) ? colors.tealish : colors.waterBlue;
    return (
        <Modal
          className="userbadgemodal"
          show={isVisible}
          onHide={()=>onClose(false)}
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title as="h5" style={{color: colors.tealish}}>
              {badge.name ? translate(badge.name) : ""}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="text-center">
            <div className="badgeWrapper">
              <CircularProgressbar
                value={(badge.progress * 100)}
                strokeWidth={10}
                styles={buildStyles({
                pathColor: color,
                pathTransitionDuration: 1
              })}
              />
              <div
                className="background"
                style={{backgroundColor: badge.progress >= 1 ? colors.white :colors.lightGrey}}>
                <Image
                  className="imageBadge"
                  src={badgeImage}
                />
              </div>
            </div>

            {/*<p className="textCenter badgeName" style={{fontSize: ratio * 24}}>
              {badge.name ? translate(badge.name) : ""}
            </p>*/}

            <p className="textCenter badgeDescription" style={{fontSize: ratio * 16}}>
              {translate(badge.description)}
            </p>

            {
              badge.progress >= 1 && (
                <p className="textCenter badgeEarned" style={{fontSize: ratio * 16}}>
                  {translate('badge_earned')}
                </p>
              ) || null
            }

            {
              (badge.segue && badge.segueName) && (badge.progress < 1 || badge.segue == 'ShareApp') && (
                <Button
                  variant="outline-info"
                  onClick={(e)=>toSegue(badge.segue, e)}
                >
                      {translate(badge.segueName)}
                </Button>
              )
            }
          </Modal.Body>
        </Modal>
    );
  }
}

export default BadgeModal;