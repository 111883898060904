import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faVolumeUp,
  faTimes,
  faSearch,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import TranslationCell from '../../../components/Cells/TranslationCell/index';
import Loader from "../../../components/Custom/Loader/index";

import {resetWordDetails,changeMainTranslation} from '../../../actions/wordActions';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

import * as constants from '../../../lib/constants';
import colors from '../../../lib/colors';

class WordInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tour: localStorage.getItem('tour'),
    };

    this.track = null;
  }

  playSound = (e) => {
    e.preventDefault();

    const {word} = this.props.word;
    const {isConnected} = this.props.settings;
    const {userData} = this.props.user;
    let audioSource = constants.BACKEND_URL;

    if (word.audio) {
      let audio = word.audio;

      if (userData && userData.audio_language && word[userData.audio_language]) {
        audio = word[userData.audio_language];
        audioSource = constants.S3
      }

      if (this.track != null) {
        this.track.pause();
        this.track = null;
      }

      if (isConnected) {
        this.track = new Audio(`${audioSource}${audio}`);
        this.track.play();
      }
    }
  };


  playTrack = (url, e) => {
    e.preventDefault();
    const {isConnected} = this.props.settings;

    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }
    if (url && isConnected) {
      this.track = new Audio(`${constants.BACKEND_URL}${url}`);
      this.track.play();
    }
  };


  playExample = (example, e) => {
    e.preventDefault();

    const {isConnected} = this.props.settings;
    const {userData} = this.props.user;
    let audioSource = constants.BACKEND_URL;

    if (example.audio) {
      let audio = example.audio;

      if (userData && userData.audio_language && example[userData.audio_language]) {
        audio = example[userData.audio_language];
        audioSource = constants.S3
      }

      if (this.track != null) {
        this.track.pause();
        this.track = null;
      }

      if (isConnected) {
        this.track = new Audio(`${audioSource}${audio}`);
        this.track.play();
      }
    }
  };

  _renderItem = (item, index) => {
    const {fontSizeRation} = this.props.settings;
    const {tablet} = this.state;
    const {isConnected} = this.props.settings;
    const {userId} = this.props.user;
    const {translate} = this.props;

    return (
      <TranslationCell
        key={index}
        isConnected={isConnected}
        tablet={tablet}
        ratio={fontSizeRation}
        translation={item}
        minimal
        translate={translate}
        deleteUserTranslation={this._deleteUserTranslation}
        changeMainTranslation={this._changeMainTranslation}
        playTrack={this.playTrack}
        userId={userId}
      />
    )
  };

  _changeMainTranslation = (translationId, e) => {
    e.preventDefault();

    const {word} = this.props.word;

    if(!this.state.tour) {
      this.props.changeMainTranslation(translationId, word.id);
      this.props.logActivity({activityId: 200, model: 'translation', modelId: translationId, comment: ""});
    }
  };

  render() {
    const {fetchingData} = this.props.activity;
    const {translate, onClose, isVisible} = this.props;
    const {userData, userId} = this.props.user;
    const {word} = this.props.word;
    const {fontSizeRation} = this.props.settings;
    const size = userData && userData.list_size ? userData.list_size : 10;

    const transcription = word && word.transcription ? word.transcription : '';
    const imageUrl = word && word.images && word.images.length > 0 ? word.images[0].filename : false;
    const imageUserId = word && word.images && word.images.length > 0 ? word.images[0].user_id : false;

    const translation = word && word.main_translation && word.main_translation.translation ? word.main_translation.translation : "";

    const translations = word && word.otherTranslations ? word.otherTranslations : [];
    const examples = word && word.examples ? word.examples : [];

    const imageWidth = 300;

    let s3image = `${constants.S3MarkedImages}-${imageWidth}/${imageUrl}`;

    if (imageUserId && imageUserId == userId) {
      s3image = `${constants.IMAGE_URL}/${imageUrl}?width=${imageWidth}`;
    }

    return (
      <Modal
        className="wordinfo"
        show={isVisible}
        onHide={()=>{
          this.props.resetWordDetails();
          onClose(false)}
          }
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h3">
            {word && word.word ? word.word : ''}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{minHeight: 300}}>
          { word && word.id &&
          <div className="contentWrapper">

            {imageUrl && (
              <Image
                draggable="false"
                onContextMenu={(e)=>e.preventDefault()}
                rounded
                thumbnail
                src={s3image}
                style={{width: 200,height: 145, marginBottom: 10}}
              />
            )}

            <span className="wordText" style={{fontSize: fontSizeRation * 20}}>
              { word && word.word ? word.word : ''}
            </span>

            {word && (
              <a
                href="#"
                className="d-flex justify-content-center align-items-center pv-2 mt-3 mb-3"
                style={{width: '100%'}}
                onClick={this.playSound}
              >
                <FontAwesomeIcon style={{color: colors.greyishBrown}} className="toolbarButton" icon={faVolumeUp}/>
              </a>)}

                <span className="transcription" style={{fontSize: fontSizeRation * 14}}>
                  {transcription}
                </span>

            <div className="mainTranslationWrapper">
                  <span className="mainTranslation" style={{fontSize: fontSizeRation * 16}}>
                    {translation}
                  </span>
            </div>

            <div className="translations">
              {
                translations.map((item, index) => {
                  return this._renderItem(item, index)
                })
              }

            </div>
          </div>
          }
        </Modal.Body>

        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),
  word: state.word,
  lists: state.lists,

});

function bindAction(dispatch) {
  return {
    resetWordDetails: ()=> dispatch(resetWordDetails()),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    changeMainTranslation: (translationId, wordId) => dispatch(changeMainTranslation(translationId, wordId)),
  };
}

export default connect(mapStateToProps, bindAction)(WordInfo);
