import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import Loader from "../../../components/Custom/Loader/index";

class GlossariesForList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      glossaryName: ""
    };

    this.inputRef = React.createRef();
  }

  setGlossaryName = (glossaryName) => {
    this.setState({
      glossaryName
    })
  };

  render() {
    const {
      translate,
      tablet,
      isVisible,
      onClose,
      toGlossary,
      glossaries,
      fetchingData
    } = this.props;
    const {glossaryName} = this.state;

    return (
      <Modal
        onShow={() => this.inputRef.current.focus()}

        className="glossariesforlist"
        show={isVisible}
        onHide={()=>onClose(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="fill_from_glossary"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{padding: 0}} className="text-center">

          <InputGroup className="ph1 mt-2">
            <FormControl
              ref={this.inputRef}
              style={{fontSize: 12}}
              value={glossaryName}
              onChange={(e)=> this.setGlossaryName(e.target.value)}
              placeholder={translate('search_glossary')}
            />
            <InputGroup.Append>
              <Button
                style={{fontSize: 12}}
                disabled={glossaryName.length == 0}
                variant="outline-secondary"
                onClick={()=>this.setGlossaryName("")}>
                <FontAwesomeIcon icon={faTimes}/>
              </Button>
            </InputGroup.Append>
          </InputGroup>


          <ListGroup
            variant="flush"
            style={{maxHeight: 400, overflowY: 'auto', borderBottom: '1px solid rgba(0, 0, 0, 0.125)'}}
          >
            {
              glossaries.map((glossary, i) => {
                if (glossaryName.length == 0 || glossary.glossary_name.toLowerCase().indexOf(glossaryName) != -1) {
                  return (
                    <ListGroup.Item
                      action
                      key={i}
                      className="text-center"
                      onClick={(e) => toGlossary(glossary, e)}
                    >
                      {glossary.glossary_name}{" - "}{glossary.words}
                    </ListGroup.Item>
                  )
                }
              })
            }
          </ListGroup>

        </Modal.Body>

        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}

export default connect(null, null)(GlossariesForList);
