import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import moment from 'moment';
import DatePicker from "react-datepicker";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle, faCheckCircle, faEdit, faTimes} from '@fortawesome/free-solid-svg-icons';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';

import Loader from "../../../components/Custom/Loader/index";

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import {
  getUserAppProfile,
  changeWordLevel,
  change_notification_time,
  change_notification_status
} from '../../../actions/userActions';

import colors from '../../../lib/colors';

class Notifications extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tablet: false,
      type: null,
      time: new Date(),
      visible: false,
      levelPicker: false
    };

    this.listRef = React.createRef();
    this.courseRef = React.createRef();
    this.wordRef = React.createRef();
    this.phraseRef = React.createRef();
    this.promoRef = React.createRef();
    this.timeOut = null;
    this.timeOut1 = null;
    this.timeOut2 = null;
    this.timeOut3 = null;
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut1);
    clearTimeout(this.timeOut2);
    clearTimeout(this.timeOut3);
  }

  _changeTime = (time, type) => {
    if (!this.props.tour) {
      let formatted = moment(time).format("HH:mm");
      this.props.fetchingData(true);
      this.props.change_notification_time(type, formatted);
    } else {
      this.props.toRegister(true);
    }
  };

  _toggleSwitch = (type, value, e) => {
    e.preventDefault();

    if (!this.props.tour) {

      this.props.fetchingData(true);

      this.props.change_notification_status(type, value == 1 ? 0 : 1);

      this.props.logActivity({activityId: value == 1 ? 81 : 80, comment: type});
    } else {
      // this.props.onClose('notificationsModalVisible',false);
      this.props.toRegister(true);
    }
  };

  changeLevel = (level, e) => {
    e.preventDefault();
    const {userData} = this.props.user;

    if (userData && userData.word_level_id != level && !this.props.tour) {
      this.props.fetchingData(true);

      this.props.changeWordLevel(level);

      this.props.logActivity({activityId: 278, comment: `${level}`});
    } else {
      // this.props.onClose('notificationsModalVisible',false);
      this.props.toRegister(true);
    }
  };

  render() {
    const {fetchingData} = this.props.activity;
    const {translate, onClose, isVisible} = this.props;
    const {userData} = this.props.user;
    const {tablet, time} = this.state;
    const {fontSizeRation} = this.props.settings;

    let levels = [
      "Basic",
      "Elementary",
      "Intermediate",
      "Advanced"
    ];

    let currentLevel = levels[userData ? userData.word_level_id : 0];
    let levelId = userData ? userData.word_level_id : 0;


    let listTime = new Date(moment(`${moment().format("YYYY-MM-DD")} ${userData.notification_time}`));
    let courseTime = new Date(moment(`${moment().format("YYYY-MM-DD")} ${userData.course_notification_time}`));
    let wordTime = new Date(moment(`${moment().format("YYYY-MM-DD")} ${userData.everyday_notification_time}`));
    let phraseTime = new Date(moment(`${moment().format("YYYY-MM-DD")} ${userData.everyday_phrase_notification_time}`));
    let promoTime = new Date(moment(`${moment().format("YYYY-MM-DD")} ${userData.promo_notification_time}`));

    return (
      <Modal
        className="notifications"
        show={isVisible}
        onHide={()=>onClose('notificationsModalVisible',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="notifications_push"/>
            <span className="text-danger d-block" style={{fontSize: 14}}>
            {translate('only_installed_app')}
          </span>
          </Modal.Title>

        </Modal.Header>

        <Modal.Body style={{minHeight: 250}}>

          <div>
            <div className="notificationRow">
              <div className="switchRow">
                <span className="notificationType" style={{fontSize: fontSizeRation * (!tablet ? 14 : 16)}}>
                  {translate('repeat_lists')}
                </span>

                <a href="#" onClick={(e) => this._toggleSwitch('list', userData.notifications, e)}>
                  <FontAwesomeIcon
                    style={{color: userData.notifications == 1 ? colors.tealish : colors.greyishBrown5}}
                    icon={userData.notifications == 1 ? faCheckCircle : faCircle}
                  />
                </a>
              </div>

              {
                userData.notifications == 1 && (
                  <div className="timeRow">
                    <span className="notifyText" style={{fontSize: fontSizeRation * (!tablet ? 12 : 14)}}>
                      {translate('notify_me_at')}</span>

                    <span className="notifyTime" style={{fontSize: fontSizeRation * (!tablet ? 12 : 14)}}>
                      <DatePicker
                        ref={this.listRef}
                        selected={listTime}
                        onChange={date => this._changeTime(date, 'list')}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption={translate('time')}
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                      />
                    </span>

                    <a href="#"
                       onClick={(e)=> {
                       e.preventDefault();
                       this.timeOut = setTimeout(()=> {
                         this.listRef.current.input.focus();
                       },100)
                    }}>
                      <FontAwesomeIcon icon={faEdit}/>
                    </a>
                  </div>
                ) || null
              }
            </div>


            <div className="notificationRow">
              <div className="switchRow">
                <span className="notificationType" style={{fontSize: fontSizeRation * (!tablet ? 14 : 16)}}>
                  {translate('repeat_course_lists')}
                </span>
                <a href="#" onClick={(e) => this._toggleSwitch('course', userData.course_notifications, e)}>
                  <FontAwesomeIcon
                    style={{color: userData.course_notifications == 1 ? colors.tealish : colors.greyishBrown5}}
                    icon={userData.course_notifications == 1 ? faCheckCircle : faCircle}
                  />
                </a>
              </div>

              {
                userData.course_notifications == 1 && (
                  <div className="timeRow">
                    <span className="notifyText" style={{fontSize: fontSizeRation * (!tablet ? 12 : 14)}}>
                      {translate('notify_me_at')}
                    </span>

                    <span className="notifyTime" style={{fontSize: fontSizeRation * (!tablet ? 12 : 14)}}>
                      <DatePicker
                        ref={this.courseRef}
                        selected={courseTime}
                        onChange={date => this._changeTime(date, 'course')}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption={translate('time')}
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                      />
                    </span>

                    <a href="#" onClick={(e)=> {
                       e.preventDefault();
                       this.timeOut1 = setTimeout(()=> {
                         this.courseRef.current.input.focus();
                       },100)
                    }}>
                      <FontAwesomeIcon icon={faEdit}/>
                    </a>
                  </div>
                ) || null
              }
            </div>

            <div className="notificationRow">
              <div className="switchRow">
                <span className="notificationType" style={{fontSize: fontSizeRation * (!tablet ? 14 : 16)}}>
                  {translate('word_notifications')}
                </span>

                <a href="#" onClick={(e) => this._toggleSwitch('word', userData.everyday_notifications, e)}>
                  <FontAwesomeIcon
                    style={{color: userData.everyday_notifications == 1 ? colors.tealish : colors.greyishBrown5}}
                    icon={userData.everyday_notifications == 1 ? faCheckCircle : faCircle}
                  />
                </a>
              </div>

              {
                userData.everyday_notifications == 1 && (
                  <div className="switchRow">
                    <span className="notifyText" style={{fontSize: fontSizeRation * (!tablet ? 12 : 14)}}>
                      {translate('select_word_level')}
                    </span>

                    <Dropdown alignRight>
                      <Dropdown.Toggle as="button" variant="outline-success" size="sm">
                        <span className="notifyLevel" style={{fontSize: fontSizeRation * (!tablet ? 12 : 14)}}>
                          {currentLevel}
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {
                          [
                            "Basic",
                            "Elementary",
                            "Intermediate",
                            "Advanced"
                          ].map((item, i) => {
                            return (
                              <Dropdown.Item
                                block
                                variant="outline-info"
                                key={i}
                                active={levelId == i}
                                onClick={(e)=>this.changeLevel(i, e)}
                              >
                                {item}
                              </Dropdown.Item>
                            )
                          })
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )
              }

              {
                userData.everyday_notifications == 1 && (
                  <div className="timeRow">
                    <span className="notifyText" style={{fontSize: fontSizeRation * (!tablet ? 12 : 14)}}>
                      {translate('notify_me_at')}
                    </span>
                    <span className="notifyTime" style={{fontSize: fontSizeRation * (!tablet ? 12 : 14)}}>
                      <DatePicker
                        ref={this.wordRef}
                        selected={wordTime}
                        onChange={date => this._changeTime(date, 'word')}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption={translate('time')}
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                      />
                    </span>

                    <a href="#" onClick={(e)=> {
                      e.preventDefault();
                       this.timeOut2 = setTimeout(()=> {
                         this.wordRef.current.input.focus();
                       },100)
                    }}>
                      <FontAwesomeIcon icon={faEdit}/>
                    </a>
                  </div>
                )
              }
            </div>

            {
              userData && userData.study_language === "en" && userData.translation_language === "ru" &&
              (
                <div className="notificationRow">
                  <div className="switchRow">
                    <span
                      className="notificationType"
                      style={{fontSize: fontSizeRation * (!tablet ? 14 : 16)}}
                    >
                      {translate('phrase_notifications')}
                    </span>
                    <a href="#" onClick={(e) => this._toggleSwitch('phrase', userData.everyday_phrase_notifications, e)}>
                      <FontAwesomeIcon
                        style={{color: userData.everyday_phrase_notifications == 1 ? colors.tealish : colors.greyishBrown5}}
                        icon={userData.everyday_phrase_notifications == 1 ? faCheckCircle : faCircle}
                      />
                    </a>
                  </div>

                  {
                    userData.everyday_phrase_notifications == 1 && (
                      <div className="timeRow">
                        <span className="notifyText" style={{fontSize: fontSizeRation * (!tablet ? 12 : 14)}}>
                          {translate('notify_me_at')}
                        </span>
                        <span className="notifyTime" style={{fontSize: fontSizeRation * (!tablet ? 12 : 14)}}>
                          <DatePicker
                            ref={this.phraseRef}
                            selected={phraseTime}
                            onChange={date => this._changeTime(date, 'phrase')}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeCaption={translate('time')}
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                          />
                        </span>

                        <a href="#" onClick={(e)=> {
                           e.preventDefault();
                           this.timeOut3 = setTimeout(()=> {
                             this.phraseRef.current.input.focus();
                           },100)
                        }}>
                          <FontAwesomeIcon icon={faEdit}/>
                        </a>
                      </div>
                    )
                  }

                </div>
              )
            }

            <div className="notificationRow">
              <div className="switchRow">
                <span className="notificationType" style={{fontSize: fontSizeRation * (!tablet ? 14 : 16)}}>
                  {translate('promo_notifications')}
                </span>

                <a href="#" onClick={(e) => this._toggleSwitch('promo', userData.promo_notifications, e)}>
                  <FontAwesomeIcon
                    style={{color: userData.promo_notifications == 1 ? colors.tealish : colors.greyishBrown5}}
                    icon={userData.promo_notifications == 1 ? faCheckCircle : faCircle}
                  />
                </a>
              </div>

              {
                userData.promo_notifications == 1 && (
                  <div className="timeRow">
                    <span className="notifyText" style={{fontSize: fontSizeRation * (!tablet ? 12 : 14)}}>
                      {translate('send_me_at')}
                    </span>
                    <span className="notifyTime" style={{fontSize: fontSizeRation * (!tablet ? 12 : 14)}}>
                      <DatePicker
                        ref={this.promoRef}
                        selected={promoTime}
                        onChange={date => this._changeTime(date, 'promo')}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption={translate('time')}
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                      />
                    </span>

                    <a href="#" onClick={(e)=> {
                      e.preventDefault();
                       this.timeOut2 = setTimeout(()=> {
                         this.promoRef.current.input.focus();
                       },100)
                    }}>
                      <FontAwesomeIcon icon={faEdit}/>
                    </a>
                  </div>
                )
              }
            </div>
          </div>

        </Modal.Body>
        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    getUserAppProfile: () => dispatch(getUserAppProfile()),
    changeWordLevel: (level) => dispatch(changeWordLevel(level)),
    change_notification_status: (type, status) => dispatch(change_notification_status(type, status)),
    change_notification_time: (type, time) => dispatch(change_notification_time(type, time)),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
  };
}

export default connect(mapStateToProps, bindAction)(Notifications);
