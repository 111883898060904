import React, {Component} from 'react';
import {connect} from "react-redux";
import _ from 'lodash'
import {withRouter, Link} from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import {Helmet} from "react-helmet";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle, faCheckCircle, faTimes} from '@fortawesome/free-solid-svg-icons';

import {getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import ListFiltersModal from '../../../components/Modals/ListFiltersModal/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
import ListsToolbar from '../../../components/Toolbars/ListsToolbar/index';
import GenerateLists from '../../../components/Modals/GenerateLists/index';
import ListCardCell from '../../../components/Cells/ListCardCell/index'
import TrainingButtons from '../../../components/Modals/TrainingButtons/index';
import ListSettings from '../../../components/Modals/ListSettings/index';
import TagsModal from '../../../components/Modals/TagsModal/index';
import TagsFilterModal from '../../../components/Modals/TagsFilterModal/index';
import ChangeListSize from '../../../components/Modals/ChangeListSize/index';
import RegisterAlert from '../../../components/Modals/RegisterAlert/index';
import TrainingsSet from '../../../components/Modals/TrainingsSet/index';

import Loader from "../../../components/Custom/Loader/index";

import {
  removeFromCalendar,
  getListsByLang,
  searchLists,
  setCurrentList,
  addList,
  editList,
  getListsToRepeat,
  deleteSelectedLists,
  cleanListsCache,
  setShowArchive,
  toggleFavouriteList,
  deleteListFromList,
  resetCurrentList,
  postponeInCalendar,
  cleanListCache,
  toggleListTrainingButtonsModal,
  changeListViewType,
  generateListsNotWizard
} from '../../../actions/lists.js';
import {fetchingData} from '../../../actions/activity';
import {togglePostpone, toggleShowLearntWords, toggleShowArchiveLists} from '../../../actions/userActions';
import {logActivity} from '../../../actions/logActions';
import {setUserListsFromCache} from '../../../actions/cacheActions';
import {getRegistrationDate, checkForHappyDay} from '../../../actions/purchasesActions';
import {setHideKnown, cleanTrainingsCache} from '../../../actions/trainingActions';
import {getTags, connectTag, resetSelectedTags} from '../../../actions/tagActions';

import colors from '../../../lib/colors';

class Lists extends Component {

  constructor(props) {
    super(props);

    this.searchInput = null;

    this.state = {
      searchBarVisible: false,
      refreshing: false,
      listModalVisible: false,
      currentList: false,
      currentType: false,
      currentListIndex: null,

      filtersModalVisible: false,
      repetitions: [],
      repetitionsModalVisible: false,
      filter: 'by_list_name',
      order: 'asc',
      listName: '',
      newListName: '',
      removeFromCalendarModalVisible: false,
      createListAlertVisible: false,
      shouldShowCreateListAlertVisible: false,
      listSizeModalVisible: false,
      generatorModalVisible: false,

      shouldShowSwipeToManageAlert: false,
      swipeToManageAlertVisible: false,
      subscriptionAlertVisible: false,
      subscriptionMessage: 'subscription_trainings',
      listsSettingsModalVisible: false,
      listsSettingsModalExtraVisible: false,
      listWordsSettingsModalVisible: false,
      deleteLists: false,
      selectedLists: [],
      tablet: false,
      trainingsModalVisible: false,
      tagsModalVisible: false,
      tagsFilterModalVisible: false,
      studyLanguage: 'en',
      scrolled: 0,
      tour: localStorage.getItem('tour'),
      registerModalVisible: false,
      trainingsSetModal: false,

    };

    this.myRef = React.createRef();
    this.newListRef = React.createRef();

    this.timeOut1 = null;
    this.timeOut2 = null;
    this.timeOut3 = null;
    this.timeOut4 = null;
    this.timeOut5 = null;

    this.onChangeTextDelayed = _.debounce(this.onChangeText, 250);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));

    this.setFilters();

    this.timeOut1 = setTimeout(() => {
      this.props.getTags();
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this.setFilters();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut1);
    clearTimeout(this.timeOut2);
    clearTimeout(this.timeOut3);
    clearTimeout(this.timeOut4);
    clearTimeout(this.timeOut5);

    window.removeEventListener('scroll', this.handleScroll);
    // this.props.resetCurrentList();

    this.setState({
      listModalVisible: false,
      deleteLists: false,
      createListAlertVisible: false,
      removeFromCalendarModalVisible: false
    });
  }

  handleScroll() {
    const scrollY = window.scrollY;

    this.setState({
      scrolled: scrollY
    });
  }


  setFilters = ()=> {
    const {userId} = this.props.user;
    const {cachedLists, cachedListsWords, cachedListsStatus} = this.props.cache;
    const {isConnected} = this.props.settings;

    const filter = localStorage.getItem(`filterLists${userId}`);
    const order = localStorage.getItem(`orderLists${userId}`);

    if (!cachedListsStatus) {
      if (filter !== null) {
        this.setState({
          filter,
          order
        });

        if (isConnected) {
          this.props.fetchingData(true);
          this.props.getListsByLang(filter, order);
          this.props.checkForHappyDay();

        }
      } else {
        if (isConnected) {
          this.props.fetchingData(true);
          this.props.getListsByLang(this.state.filter, this.state.order);
          this.props.checkForHappyDay();
        }
      }
    } else {
      this.props.setUserListsFromCache({
        data: cachedLists,
        total_lists: cachedLists.length,
        total_words: cachedListsWords
      });
    }

    if (isConnected) {
      this.props.getRegistrationDate();
    }
  };

  _getLists = () => {
    const {filter, order} = this.state;
    const {isConnected} = this.props.settings;

    if (isConnected) {
      this.props.fetchingData(true);
      this.props.getListsByLang(filter, order);
    }
  };

  toggleSearch = () => {
    const {searchBarVisible} = this.state;

    // this._toggleListSettingsModal(false);

    if (searchBarVisible) {
      this.setState({
        listName: ''
      });
      this.setFilters();
    } else {
      if (this.searchInput && this.searchInput != null) {
        this.searchInput.focus();
      }

    }

    this.setState({
      searchBarVisible: !this.state.searchBarVisible
    })
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toSubscription = (productId) => {
    this._toggleSubscriptionAlert(false);
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId});
  };

  _onPressItem = (list, index, type, e) => {
    e.preventDefault();
    const {locale} = this.props.settings;

    const {deleteLists, selectedLists} = this.state;

    if (!deleteLists) {
      this.props.setCurrentList(list);

      this.props.history.push(`/${locale}/list/${list.id}`, {list});

      this.props.logActivity({activityId: 31, model: 'list', modelId: list.id});
    } else {
      let index = selectedLists.indexOf(list.id);

      if (index === -1) {
        this.setState({
          selectedLists: [...selectedLists, list.id]
        })
      } else {
        selectedLists.splice(index, 1);

        this.setState({
          selectedLists
        })
      }
    }
  };

  _handleList = (listName, list) => {
    const {filter, order} = this.state;

    if (!list) {
      const active = this._checkActiveStatus();

      if (active) {
        this.props.fetchingData(true);

        this.props.logActivity({activityId: 23, comment: listName});

        this.props.addList(listName, filter, order)
      } else {
        this._toggleSubscriptionAlert(true);
      }
    } else {
      this.props.fetchingData(true);

      this.props.logActivity({activityId: 24, comment: listName});

      this.props.editList(listName, list.id, filter, order)
    }
    this.setState({
      newListName: ''
    });

    this.toggleListModal(false)
  };

  _keyRepeatExtractor = (item, index) => {
    return `to-list-repeat-${item.id}-${index}`
  };

  _renderRepeatsItem = (item, index) => {
    const {fontSizeRation, version} = this.props.settings;
    const {tablet, selectedLists} = this.state;
    const {isConnected, deviceWidth, deviceHeight} = this.props.settings;
    const {translate} = this.props;

    let toDelete = selectedLists.indexOf(item.id) !== -1;
    let dots = this.getDots(item);

    return (
      <ListCardCell
        deviceWidth={deviceWidth}
        deviceHeight={deviceHeight}
        key={index}
        translate={translate}
        toDelete={toDelete}
        isConnected={isConnected}
        tablet={tablet}
        ratio={fontSizeRation}
        list={item}
        index={index}
        repeat={item.trainings > 0}
        dots={dots}
        newList={item.trainings == 0}
        version={version}
        listsRef={this.myRef}
        onLongPressed={this._onLongPress}
        onItemPressed={this._onPressItem}
        onRepeatPressed={this._onRepeatPressed}
        showRepetitionDays={this.onRepetitionsPressed}
        openTagModal={this._openTagModal}
      />
    )
  };

  getDots = (list) => {
    if (!list.intervals || list.intervals.length == 0) {
      return null;
    }

    return list.intervals.map((item, i) => {
      let color = colors.lightGrey;

      if (item.repetition_date < moment().format("YYYY-MM-DD") && item.trainings == 0) {
        color = colors.coral;
      } else if (item.repetition_date <= moment().format("YYYY-MM-DD") && item.trainings > 0) {
        color = colors.tealish;
      }

      return (
        <div
          className="dot"
          key={`${item}-${i}`}
          style={{marginHorizontal: 4,backgroundColor: color}}
        />
      )
    });
  };

  _onLongPress = (list, index, type, e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.setCurrentList(list);

    this.setState({
      currentList: list,
      currentType: type,
      currentListIndex: index
    });
    this._toggleListWordsSettingsModal(true);
  };

  _toggleListWordsSettingsModal = (status) => {
    this.setState({
      listWordsSettingsModalVisible: status
    })
  };

  _toggleFavorite = (list, e) => {
    e.preventDefault();
    const {currentListIndex, tour} = this.state;

    this._toggleModal('listWordsSettingsModalVisible', false);

    if (!tour) {
      this.props.fetchingData(true);

      this.props.toggleFavouriteList(list.id, currentListIndex, false, list.favourite);

      this.props.logActivity({
        activityId: list.favourite == 0 ? 28 : 29,
        model: 'list',
        modelId: list.id,
        comment: "from list"
      });
    } else {
      this._toggleRegisterModal(true);
    }
  };

  toggleListModal = (status) => {
    this._toggleListSettingsExtraModal(false);

    const {subscriptionActive, listsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {lists} = this.props.lists;
    const {userData} = this.props.user;
    let active = false;

    if (lifetimeStatus || subscriptionActive || webSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = lists.length < listsLimit;
    }

    if (!status) {
      this.setState({
        currentList: false
      });
    }

    if (active) {
      this.setState({
        listModalVisible: status
      });
    } else {
      this.setState({
        subscriptionMessage: 'subscription_lists'
      });
      this._toggleSubscriptionAlert(true);
    }
  };

  _checkActiveStatus = () => {
    const {lists} = this.props.lists;
    const {subscriptionActive, listsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    let active = false;

    if (lifetimeStatus || subscriptionActive || webSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = lists.length < listsLimit;
    }

    return active;
  };

  _removeFromCalendar = (e) => {
    e.preventDefault();

    const {currentList, tour} = this.state;
    const {translate} = this.props;

    this._toggleListWordsSettingsModal(false);

    if (!tour) {

      if (currentList.intervals.length > 0) {
        this._toggleRemoveFromCalendarModal(false);

        this.props.removeFromCalendar(currentList.id, this.state.filter, this.state.order);
        // this._toggleRemoveFromCalendarModal(true);
      } else {
        toastr.error('', translate('cannot_remove_from_calendar'));
      }

      this.props.logActivity({activityId: 27, model: 'list', modelId: currentList.id});
    } else {
      this._toggleRegisterModal(true)
    }
  };

  _confirmRemoveFromCalendar = (list) => {
    this._toggleRemoveFromCalendarModal(false);

    this.props.removeFromCalendar(list.id, this.state.filter, this.state.order);
  };

  _toggleRemoveFromCalendarModal = (status) => {
    this.setState({
      removeFromCalendarModalVisible: status
    })
  };

  _toggleRepetitionsModal = (status) => {
    this.setState({
      repetitionsModalVisible: status
    })
  };

  _toggleFilters = (status) => {
    // this._toggleListSettingsModal(false);

    this.timeOut2 = setTimeout(()=> {
      this.setState({
        filtersModalVisible: status
      })
    }, 150)
  };

  _sortBy = (filter, order, e) => {
    e.preventDefault();

    this._toggleFilters(false);
    this._setNewFilters(filter, order);

    this.props.fetchingData(true);

    this.props.getListsByLang(filter, order);
  };

  _setNewFilters = async(filter, order) => {
    const {userId} = this.props.user;

    this.props.logActivity({activityId: 145, comment: filter});

    this.setState({
      filter,
      order
    });

    localStorage.setItem(`filterLists${userId}`, filter);
    localStorage.setItem(`orderLists${userId}`, order);
  };

  onRepetitionsPressed = (intervals, e) => {
    e.preventDefault();
    e.stopPropagation();

    /*this.setState({
     repetitions: intervals
     });*/

    // this._toggleRepetitionsModal(true);

    this.props.logActivity({activityId: 30, model: 'list'});
  };

  _onRepeatPressed = (list, type, e) => {
    e.preventDefault();
    e.stopPropagation();
    const {userData} = this.props.user;

    this.props.setCurrentList(list);

    this.setState({
      currentList: list,
      currentType: type
    });

    if(userData && userData.active_trainings_set && list.id != 0) {
      this._goDirectlyToTraining(list);
    } else {
      this.toggleTrainingButtons(true);
    }
  };

  toggleTrainingButtons = (status) => {
    this.setState({
      trainingsModalVisible: status
    });

    this.props.toggleListTrainingButtonsModal(false)
  };

  onChangeText() {
    const {listName} = this.state;

    if (listName != "") {
      this.props.fetchingData(true);

      this.props.searchLists(listName);

    } else {
      this.setFilters()
    }
  }

  toCreateList = () => {
    this.toggleCreateListModal(false);
    this.toggleListModal(true);
  };

  toggleCreateListModal = (status) => {
    this.setState({
      createListAlertVisible: status
    });
  };

  toggleSwipeToManageModal = (status) => {
    this.setState({
      swipeToManageAlertVisible: status
    });
  };

  _toListGenerator = () => {
    const {subscriptionActive, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {lists} = this.props.lists;
    const {userData} = this.props.user;
    let active = false;

    if (lifetimeStatus || subscriptionActive || webSubscriptionStatus || !applyLimit) {
      active = true;
    }

    if (active) {
      this._toggleModal('generatorModalVisible', true);

      this.props.logActivity({activityId: 165, comment: 'from lists'});
    } else {
      this.setState({
        subscriptionMessage: 'subscription_disabled_function'
      });
      this._toggleSubscriptionAlert(true);
    }
  };

  _toggleListSettingsModal = (status) => {
    this.setState({
      listsSettingsModalVisible: status
    });
    this._cancelDeleteLists();
  };


  _toggleListSettingsExtraModal = (status) => {
    this.setState({
      listsSettingsModalExtraVisible: status
    });
  };

  _enableDeleteListsMode = () => {
    this._toggleListSettingsExtraModal(false);

    this.setState({
      deleteLists: true
    })
  };

  _cleanCache = () => {
    const {translate} = this.props;
    this._toggleListSettingsExtraModal(false);

    this.props.logActivity({activityId: 297});

    this.props.cleanListsCache();

    toastr.success("", translate('lists_cache_cleaned'));

    this.props.getListsByLang(this.state.filter, this.state.order);
  };

  _toggleHideKnown = () => {
    const {userData} = this.props.user;

    if (!this.state.tour) {
      this.props.cleanTrainingsCache();

      this.props.toggleShowLearntWords();
      this.props.logActivity({activityId: 307, comment: userData.show_learnt == "0" ? "1" : "0"});
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _toggleArchiveStatus = () => {
    const {userData} = this.props.user;
    if (!this.state.tour) {
      this.props.toggleShowArchiveLists();

      this.props.logActivity({activityId: 311, comment: userData.show_archive == "0" ? "1" : "0"});
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _togglePostpone = () => {
    const {userData} = this.props.user;

    if (!this.state.tour) {
      this.props.fetchingData(true);

      this.props.togglePostpone();

      this.props.logActivity({activityId: 326, comment: userData.postpone == "0" ? "1" : "0"});
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _cancelDeleteLists = () => {
    this.setState({
      deleteLists: false,
      selectedLists: []
    })
  };

  _deleteSelectedLists = (e) => {
    const {selectedLists} = this.state;

    if (!this.state.tour) {
      this.props.fetchingData(true);

      this.props.deleteSelectedLists(selectedLists);

      this._cancelDeleteLists();
    } else {
      this._toggleRegisterModal(true);
    }
  };

  toggleSelectAll = (e) => {
    e.preventDefault(e);
    const {selectedLists} = this.state;
    const {lists} = this.props.lists;

    if (selectedLists.length > 0) {
      this.setState({
        selectedLists: []
      })
    } else {
      let selected = [];

      lists.forEach(item => {
        selected.push(item.id)
      });

      this.setState({
        selectedLists: selected
      })
    }
  };

  _toGlossaries = () => {
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/glossaries`, {back: true});
    this.props.logActivity({activityId: 294});
  };

  trainingButtonSelected = (training) => {
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {userData} = this.props.user;
    const {locale} = this.props.settings;

    let active = false;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    this.toggleTrainingButtons(false);

    this.props.toggleListTrainingButtonsModal(false);

    this.props.checkForHappyDay();

    if (active) {
      const {currentList} = this.state;
      const {currentListInLists} = this.props.lists;

      let activityId = '';
      let path = '';

      if (training == 'Preview') {
        activityId = 39;
        path = 'preview'
      } else if (training == 'DragImage') {
        path = 'image';

        activityId = 40;
      } else if (training == 'SelectTranslation') {
        path = 'translation';
        activityId = 41;
      } else if (training == 'SelectWord') {
        path = 'word';
        activityId = 42;
      } else if (training == 'CollectWord') {
        path = 'collect';
        activityId = 43;
      } else if (training == 'CheckSpelling') {
        path = 'spelling';
        activityId = 44;
      } else if (training == 'CollectSentence') {
        activityId = 114;
        path = 'sentence';
      } else if (training == 'CollectSentenceTap') {
        activityId = 335;
        path = 'sentence';
      } else if (training == 'MemoryCards') {
        activityId = 131;
        path = 'memory'
      } else if (training == 'WordDefinition') {
        path = 'definition';
        activityId = 148;
      } else if (training == 'SpeakOut') {
        activityId = 95;
      } else if (training == 'MissingLetter') {
        path = 'missing';
        activityId = 315;
      } else if (training == 'Listening') {
        path = 'listening';
        activityId = 345;
      } else if (training == 'TranslateSentence') {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut4 = setTimeout(()=> {
        this.props.history.push(`/${locale}/${path}/${currentListInLists.id}`, {
          type: 'listId',
          from: 'listWords',
          listId: currentListInLists.id
        });
      }, 100);

      this.props.logActivity({activityId, model: 'list', modelId: currentListInLists.id, comment: 'from home'});
    } else {
      this._toggleSubscriptionAlert(true);
    }
  };

  _goDirectlyToTraining = (list) => {
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {userData} = this.props.user;
    const {locale} = this.props.settings;

    let active = false;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    if (active) {
      let trainingIds = userData.trainings_set != null ? userData.trainings_set.split(',').map(Number) : [];
      
      let trainingId = trainingIds.length > 0 ? trainingIds[0] : 1;
      
      let activityId = '';
      let path = '';

      if (trainingId == 1) {
        activityId = 39;
        path = 'preview'
      } else if (trainingId == 6) {
        path = 'image';
        activityId = 40;
      } else if (trainingId == 2) {
        path = 'translation';
        activityId = 41;
      } else if (trainingId == 4) {
        path = 'word';
        activityId = 42;
      } else if (trainingId == 3) {
        path = 'collect';
        activityId = 43;
      } else if (trainingId == 5) {
        path = 'spelling';
        activityId = 44;
      } else if (trainingId == 8) {
        activityId = 114;
        path = 'sentence';
      } else if (trainingId == 9) {
        activityId = 131;
        path = 'memory'
      } else if (trainingId == 10) {
        path = 'definition';
        activityId = 148;
      } else if (trainingId == 7) {
        activityId = 95;
      } else if (trainingId == 12) {
        path = 'missing';
        activityId = 315;
      } else if (trainingId == 14) {
        path = 'listening';
        activityId = 345;
      } else if (trainingId == 15) {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut5 = setTimeout(()=> {
        this.props.history.replace(`/${locale}/${path}/${list.id}`, {
          type: 'listId',
          from: 'listWords',
          listId: list.id
        });
      }, 100);

      this.props.logActivity({activityId, model: 'list', modelId: list.id, comment: 'from lists'});
      
    } else {
      this._toggleSubscriptionAlert(true);
    }
  };

  deleteList = (e) => {
    e.preventDefault();
    const {currentList, tour} = this.state;

    if (!tour) {
      this.props.fetchingData(true);

      this.props.logActivity({activityId: 25, model: 'list', modelId: currentList.id});

      this.props.deleteListFromList(currentList.id);
      this._toggleListWordsSettingsModal(false);
    } else {
      this._toggleListWordsSettingsModal(false);

      this._toggleRegisterModal(true)
    }
  };

  _postpone = (list, e) => {
    e.preventDefault();

    if (!this.state.tour) {
      this.props.logActivity({activityId: 50, model: 'list', modelId: list.id, comment: "from home"});
      this.props.postponeInCalendar(list.id);
    } else {
      this._toggleRegisterModal(true)
    }

    this._toggleListWordsSettingsModal(false)
  };

  _cleanListCache = (e) => {
    e.preventDefault();

    const {currentList} = this.state;
    const {translate} = this.props;

    this._toggleListWordsSettingsModal(false);

    this.props.logActivity({activityId: 314});

    this.props.cleanListCache(currentList.id);

    toastr.success("", translate('list_cache_cleaned'));
  };

  _toggleTagsModal = (status, e) => {
    e.preventDefault();

    this.setState({
      tagsModalVisible: status
    });
  };

  _toggleFilterTagsModal = (status) => {
    this.setState({
      tagsFilterModalVisible: status
    });
  };

  _tagSelected = (tag, e) => {
    e.preventDefault();
    const {currentList} = this.state;

    this.props.fetchingData(true);
    this.props.connectTag(currentList.id, tag, true);

    this.props.logActivity({activityId: 349});

    this._toggleTagsModal(false, e);
  };

  _openTagModal = (list, e) => {
    e.preventDefault();
    this._toggleListWordsSettingsModal(false);

    if (!this.state.tour) {
      this._toggleTagsModal(true, e);

      this.setState({
        currentList: list
      });
      this.props.setCurrentList(list);
    } else {
      this._toggleRegisterModal(true)
    }
  };

  _openFilterTagModal = () => {
    // this._toggleListSettingsModal(false);
    this._toggleFilterTagsModal(true);
  };

  _removeTagsFilter = () => {
    this.props.resetSelectedTags();

    this.timeOut3 = setTimeout(()=> {
      this._getLists();
    }, 100)
  };

  _handleSearch = (listName) => {
    this.setState({
      listName
    });

    this.onChangeTextDelayed();
  };

  _setListName = (newListName) => {
    let name = newListName.length <= 25 ? newListName : newListName.substr(0, 25);
    name = name.length == 0 ? name : `${name.charAt(0).toUpperCase()}${name.slice(1)}`;

    this.setState({
      newListName: name
    });
  };

  _handleKeyPress = (type, event) => {
    const {word, listName, newListName} = this.state;

    if (event.key === 'Enter') {
      if (type == 'search' && word.length > 0) {
        this._searchGlossaryWords();
      } else if (type == 'new_list' && newListName.length > 0) {
        this._handleList(newListName, false);
      }
    }
  };

  _toggleModal = (modal, status) => {
    this.setState({
      [modal]: status
    })
  };

  _generateLists = (level, glossaryId, total, e) => {
    e.preventDefault();
    this._toggleModal('generatorModalVisible', false);
    const {userData} = this.props.user;

    if (!this.state.tour) {
      let listSize = userData && userData.list_size ? userData.list_size : 10;

      this.props.fetchingData(true);

      const words = total * listSize;

      this.props.generateListsNotWizard(words, glossaryId, level, true);

      this.props.logActivity({activityId: 165, comment: `${words}`});
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _toggleRegisterModal = (status) => {
    this.setState({
      registerModalVisible: status
    })
  };

  _toRegister = () => {
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/register`)
  };

  _customizeButtons = (e) => {
    e.preventDefault();
    const {tour} = this.state;
    this.toggleTrainingButtons(false);

    if(!tour) {
      this._toggleModal('trainingsSetModal', true)
    } else {
      this._toggleRegisterModal(true);
    }
  };

  render() {
    const {lists, totalLists, totalWords, listsFetched, showListTrainingButtons, currentListInLists, viewType,} = this.props.lists;
    const {tags, selectedTags} = this.props.tags;
    const {fetchingData} = this.props.activity;
    const {translate} = this.props;


    const {
      filter,
      order,
      listName, filtersModalVisible,
      currentList,

      subscriptionAlertVisible,
      subscriptionMessage,
      deleteLists,
      selectedLists,
      tablet,
      trainingsModalVisible,
      listWordsSettingsModalVisible,
      currentType,
      tagsModalVisible,
      tagsFilterModalVisible,
      listSizeModalVisible,
      scrolled,
      newListName,
      generatorModalVisible,
      registerModalVisible,
      tour,
      trainingsSetModal
    } = this.state;
    const {userData, userId} = this.props.user;
    const {subscriptionActive, webSubscriptionStatus} = this.props.purchases;
    const {fontSizeRation, isConnected, locale} = this.props.settings;

    let level = userData && userData.level ? userData.level : 0;
    let showArchive = localStorage.getItem(`showArchive${userId}`);

    if (showArchive == null || showArchive == undefined) {
      showArchive = "1"
    }

    const {cachedLists} = this.props.cache;
    const {hideKnown} = this.props.trainings;

    const subtitle = `${translate('total_lists')}: ${totalLists} / ${translate('wordss')}: ${totalWords}`;

    let active = false;

    if (subscriptionActive || webSubscriptionStatus) {
      active = true;
    }

    const toolbar = classNames({
      'fixed-toolbar': scrolled >= 50,
      'justify-content-between listsToolbar justify-content-center mb-4': true
    });

    return (
      <div className="lists" ref={this.myRef}>

        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate('lists')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <ListsToolbar
          translate={translate}
          ratio={fontSizeRation}
          tablet={tablet}
          toolbar={toolbar}
          lists={lists}
          listName={listName}
          newListName={newListName}
          onSearchLists={this._handleSearch}
          setListName={this._setListName}
          handleKeyPress={this._handleKeyPress}
          handleList={this._handleList}
          listRef={this.newListRef}
          toggleDelete={this._enableDeleteListsMode}
          cleanCache={this._cleanListCache}
          toggleModal={this._toggleModal}
          setHideKnown={this._toggleHideKnown}
          hideKnown={hideKnown}
          toGenerator={this._toListGenerator}
          active={active}
          toggleArchiveStatus={this._toggleArchiveStatus}
          showArchive={showArchive}
          userData={userData}
          togglePostpone={this._togglePostpone}
          openTagsModal={this._openTagModal}
          timeOut={this.timeOut5}
          tour={tour}
          toggleRegister={this._toggleRegisterModal}

        />

        <Container className="pageWrapper">

          {
            !userId && (
              <div style={{marginTop: 15, marginBottom: 25}}>
                <h1 style={{fontSize: 24}}>
                  Собственные подборки английских слов и фраз
                </h1>
                <p style={{fontSize: 18}}>
                  У вас есть возможность создавать неограниченное количество собственных списков английских слов для 
                  удобного и эффективного запоминания по методике интервального повторения.
                </p>

                <p style={{fontSize: 18}}>
                  Из всех слов списков можно создавать флэш-карточки и отправлять их на свой email.
                </p>
              </div>
            )
          }
          
          <p className="text-secondary text-center font-weight-bold">
            {listsFetched ? subtitle : ''}
          </p>

          <div style={{flex: 1}}>
            {
              selectedTags && selectedTags.length > 0 && (
                <a href="#" onClick={this._removeTagsFilter}>
                  <div
                    className="w-100 d-flex justify-content-start"
                    style={{paddingTop: 15, paddingBottom: 15, marginBottom: -10, paddingHorizontal: 10}}>

                    <FontAwesomeIcon
                      style={{ marginRight: 10, color: colors.coral, fontSize: !tablet ? 15 : 25}}
                      icon={faTimes}
                    />

                    <span
                      style={{fontSize: fontSizeRation * (!tablet ? 14 : 16), color: colors.greyishBrown}}>
                      {translate('remove_tags_filters')}
                    </span>
                  </div>
                </a>
              )
            }

            {
              deleteLists && (
                <Card className="deleteListsTextWrapper">
                  <span className="deleteListsText font-weight-bold" style={{fontSize: fontSizeRation * 16}}>
                    {translate('select_lists_to_delete')}
                  </span>

                  <div className="bulkModeWrapper" style={{marginTop: 10}}>
                    <a href="#"
                       className="mr-3"
                       onClick={this.toggleSelectAll}
                    >
                      <FontAwesomeIcon
                        className="selectedIcon"
                        icon={selectedLists.length > 0 ? faCheckCircle : faCircle}
                        style={{marginRight: !tablet ? 10 : 20, fontSize: !tablet ? 20 : 30}}/>

                    <span
                      className="selectAllWords pv4"
                      style={{marginRight: 10, color: colors.greyishBrown,fontSize: fontSizeRation * 16}}>
                      {selectedLists.length > 0 ? translate('deselect_all_lists') : translate('select_all_lists')}
                    </span>
                    </a>

                    <Button
                      size="sm"
                      className="mr-3"
                      disabled={selectedLists.length === 0}
                      variant="outline-danger"
                      onClick={this._deleteSelectedLists}
                    >
                      {translate('delete')} - {selectedLists.length}
                    </Button>

                    <Button
                      size="sm"
                      variant="outline-secondary"
                      onClick={this._cancelDeleteLists}
                    >
                      {translate('cancel')}
                    </Button>
                  </div>
                </Card>
              )
            }

            {
              listsFetched && lists.length > 0 && viewType == 'card' && (
                <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
                  {
                    lists.map((list, index) => {
                      return this._renderRepeatsItem(list, index);
                    })
                  }
                </div>
              )
            }

            {
              listsFetched && lists.length == 0 && isConnected && listName.length === 0 && (
                <div className="d-flex flex-column m-auto align-items-center flexCenter text-center mt5"
                     style={{maxWidth: 600}}>
                  <span className="ph5 textCenter fontNormal" style={{fontSize: fontSizeRation * (!tablet ? 16 : 18)}}>
                    {translate('no_lists_yet')}
                  </span>

                  <span
                    className="ph5 textCenter fontItalic"
                    style={{marginTop: !tablet ? 30 : 50,fontSize: fontSizeRation * (!tablet ? 14 : 16)}}>
                    {translate('generate_lists')}
                  </span>

                  <Button
                    variant="outline-info"
                    style={{marginTop: 10, fontSize: fontSizeRation * (!tablet ? 14 : 16)}}
                    onClick={this._toListGenerator}
                  >
                    {translate('create_level_lists')}
                  </Button>

                  <span
                    className="ph5 textCenter fontItalic"
                    style={{marginTop: !tablet ? 30 : 50, fontSize: fontSizeRation * (!tablet ? 14 : 16)}}>
                    {translate('find_words_from_glossaries')}
                  </span>

                  <Button
                    variant="outline-info"
                    style={{marginTop: 10, fontSize: fontSizeRation * (!tablet ? 14 : 16)}}
                    onClick={this._toGlossaries}
                  >
                    {translate('find_in_glossaries')}
                  </Button>
                </div>
              )
            }

          </div>

          {
            filtersModalVisible && (
              <ListFiltersModal
                filter={filter}
                tablet={tablet}
                order={order}
                onClose={this._toggleModal}
                sortBy={this._sortBy}
                isVisible={filtersModalVisible}
                ratio={fontSizeRation}
              />
            )
          }

          {(trainingsModalVisible || showListTrainingButtons) && currentListInLists.words > 0 && (
            <TrainingButtons
              modalVisible={trainingsModalVisible || showListTrainingButtons}
              training={this.trainingButtonSelected}
              onClose={() => this.toggleTrainingButtons(false)}
              customiseButtons={this._customizeButtons}
              ratio={fontSizeRation}
              listId={currentListInLists ? currentListInLists.id : null}
              title="select_training_to_repeat"
              translationLanguage={userData.translation_language}
              course={false}
              translate={translate}
              level={level}
              tablet={tablet}
              tour={this.state.tour}

            />
          )}

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={subscriptionMessage}
                translate={translate}
              />
            )
          }

          {
            generatorModalVisible && (
              <GenerateLists
                onClose={this._toggleModal}
                isVisible={generatorModalVisible}
                translate={translate}
                generateList={this._generateLists}
              />
            )
          }

          {listWordsSettingsModalVisible && (
            <ListSettings
              isVisible={listWordsSettingsModalVisible}
              onClose={this._toggleModal}
              title={currentList ? currentList.list_name : ""}
              deleteList={this.deleteList}
              postpone={this._postpone}
              removeFromCalendar={this._removeFromCalendar}
              list={currentList}
              ratio={fontSizeRation}
              cleanListCache={this._cleanListCache}
              openTagsModal={this._openTagModal}
              type={currentType}
              translate={translate}
              toggleFavorite={this._toggleFavorite}
            />
          )}

          {tagsModalVisible && (
            <TagsModal
              isVisible={tagsModalVisible}
              onClose={this._toggleModal}
              tablet={tablet}
              tags={tags}
              logActivity={this.props.logActivity}
              onSelect={this._tagSelected}
              ratio={fontSizeRation}
              currentList={currentListInLists}
            />
          )}
          {tagsFilterModalVisible && (
            <TagsFilterModal
              isVisible={tagsFilterModalVisible}
              onClose={this._toggleModal}
              tablet={tablet}
              tags={tags}
              logActivity={this.props.logActivity}
              ratio={fontSizeRation}
              getLists={this._getLists}
              tour={tour}
            />
          )}

          {
            listSizeModalVisible && (
              <ChangeListSize
                onClose={this._toggleModal}
                isVisible={listSizeModalVisible}
              />
            )
          }
          {registerModalVisible && (
            <RegisterAlert
              onClose={this._toggleRegisterModal}
              isVisible={registerModalVisible}
              translate={translate}
              toRegister={this._toRegister}
            />
          )}

          {
            trainingsSetModal && (
              <TrainingsSet
                onClose={this._toggleModal}
                isVisible={trainingsSetModal}
              />
            )
          }
        </Container>
        <Loader fetchingData={fetchingData}/>

        <Helmet>
          <title>{`${translate('app_lists_title_unlocalized')}`}</title>
          <link rel="canonical" href={window.location.href} />

          <meta name="description" content={translate('app_lists_description_unlocalized')} />
          <meta property="og:url" content={window.location.href} />
          <meta name="og:description" content={translate('app_lists_description_unlocalized')} />
          <meta property="og:title" content={`${translate('app_lists_title_unlocalized')}`} />
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  lists: state.lists,
  activity: state.activity,
  purchases: state.purchases,
  settings: state.settings,
  cache: state.cache,
  trainings: state.trainings,
  tags: state.tags,
  translate: getTranslate(state.localize)

});

function bindAction(dispatch) {
  return {

    getTags: () => dispatch(getTags()),
    resetSelectedTags: () => dispatch(resetSelectedTags()),
    connectTag: (listId, tagId) => dispatch(connectTag(listId, tagId)),
    getListsByLang: (filter, order) => dispatch(getListsByLang(filter, order)),
    setCurrentList: (list) => dispatch(setCurrentList(list)),
    removeFromCalendar: (listId, filter, order) => dispatch(removeFromCalendar(listId, filter, order)),
    searchLists: (listName) => dispatch(searchLists(listName)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    addList: (listName, filter, order) => dispatch(addList(listName, filter, order)),
    editList: (listName, listId, filter, order) => dispatch(editList(listName, listId, filter, order)),
    logActivity: (data) => dispatch(logActivity(data)),
    getListsToRepeat: (limit, userId) => dispatch(getListsToRepeat(limit, userId)),
    getRegistrationDate: () => dispatch(getRegistrationDate()),
    cleanListsCache: () => dispatch(cleanListsCache()),
    cleanTrainingsCache: () => dispatch(cleanTrainingsCache()),
    deleteSelectedLists: (lists) => dispatch(deleteSelectedLists(lists)),
    setUserListsFromCache: (data) => dispatch(setUserListsFromCache(data)),
    setHideKnown: (status) => dispatch(setHideKnown(status)),
    toggleShowLearntWords: (status) => dispatch(toggleShowLearntWords(status)),
    setShowArchive: (status) => dispatch(setShowArchive(status)),
    togglePostpone: () => dispatch(togglePostpone()),
    toggleFavouriteList: (list_id, index, favorites, favorite) => dispatch(toggleFavouriteList(list_id, index, favorites, favorite)),
    deleteListFromList: (listId) => dispatch(deleteListFromList(listId)),
    checkForHappyDay: () => dispatch(checkForHappyDay()),
    resetCurrentList: () => dispatch(resetCurrentList()),
    toggleShowArchiveLists: () => dispatch(toggleShowArchiveLists()),
    postponeInCalendar: (listId) => dispatch(postponeInCalendar(listId)),
    cleanListCache: (id) => dispatch(cleanListCache(id)),
    toggleListTrainingButtonsModal: (status) => dispatch(toggleListTrainingButtonsModal(status)),
    changeListViewType: (type) => dispatch(changeListViewType(type)),
    generateListsNotWizard: (words, glossaryId, level, byLists) => dispatch(generateListsNotWizard(words, glossaryId, level, byLists)),

  };
}

export default connect(mapStateToProps, bindAction)(withRouter(Lists));
