import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Translate} from 'react-localize-redux';

class TrainingAlert extends Component {

  render() {
    const {isVisible, onClose, infoDescription, ratio, title} = this.props;

    return (
      <Modal
        className="trainingalert"
        size="sm"
        show={isVisible}
        onHide={()=>onClose(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id={title} />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="body">
          {
            infoDescription && (
              <Translate id={infoDescription} />
            )
          }
        </Modal.Body>
      </Modal>
    );
  }
}

export default TrainingAlert;