import React, {Component} from 'react';
import {connect} from "react-redux";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle, faCheckCircle, faEdit, faTimes, faPlus, faMinus} from '@fortawesome/free-solid-svg-icons';

import {Translate, getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';

import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';

import Loader from "../../../components/Custom/Loader/index";

import {sendCards} from '../../../actions/glossaryActions';
import {sendListCards} from '../../../actions/lists';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

import colors from '../../../lib/colors';

class GenerateListCards extends Component {

  constructor(props) {
    super(props);

    this.state = {
      image: true,
      translation: true,
      example: true,
      onPage: 16,
      tablet: false,
      words: 0,
      currentPage: 1,
      // totalPages: 1,
      cardsLimit: 48,
    }
  }

  componentDidMount() {
    this.setState({
      // words: this.props.words,
      totalPages: this.props.totalPages
    })
  }

  componentWillUnmount() {
  }


  _toggleSwitch = (type, status, e) => {
    e.preventDefault();

    this.setState({
      [type]: status
    });

    let activityId = false;

    if (type == 'image') {
      activityId = 176;
    } else if (type == 'translation') {
      activityId = 177;
    } else if (type == 'example') {
      activityId = 178;
    }

    if (activityId) {
      this.props.logActivity({activityId, comment: status ? "1" : "0"})
    }
  };

  _sendListCards = () => {
    const {image, translation, example, onPage} = this.state;
    const {type, listId, sample, onClose} = this.props;

    this.props.fetchingData(true);

    this.props.sendListCards({
      list_id: type == 'list' ? listId : false,
      course_list_id: type == 'course' ? listId : false,
      include_image: image,
      include_translation: translation,
      include_example: example,
      words_on_page: onPage,
      sample
    });

    onClose('generateListCardsModal',false);

    this.props.logActivity({activityId: 247, comment: `${listId}`});
  };

  _sendCards = () => {
    const {image, translation, example, onPage, currentPage} = this.state;
    const {glossaryName, glossaryId, sample, onClose, toRegister, tour} = this.props;

    onClose('generateListCardsModal',false);

    if(!tour) {
      this.props.fetchingData(true);

      this.props.sendCards({
        glossary_id: glossaryId,
        glossary_name: glossaryName,
        include_image: image,
        sample,
        include_translation: translation,
        include_example: example,
        page: currentPage,
        words_on_page: onPage
      });

      this.props.logActivity({activityId: 181, comment: `${glossaryId}`});
    } else {
      toRegister(true);
    }
  };


  setOnPage = (num, e) => {
    const {words} = this.props;
    e.preventDefault();

    this.setState({
      onPage: num,
      totalPages: Math.ceil(words / num)
    });

    // this.props.logActivity({activityId: 180, comment: `${num}`});

  };

  _changePage = (sign,e) => {
    const {totalPages, currentPage} = this.state;
    e.preventDefault();

    if (sign === 'plus') {
      if (currentPage < totalPages) {
        this.setState({
          currentPage: currentPage + 1
        })
      }
    } else {
      if (currentPage >= 2) {
        this.setState({
          currentPage: currentPage - 1
        })
      }
    }

    // this.props.logActivity({activityId: 179, comment: `${this.state.currentPage}`});
  };

  render() {
    const {image, translation, example, onPage, tablet, currentPage, totalPages} = this.state;
    const {fetchingData} = this.props.activity;
    const {fontSizeRation} = this.props.settings;
    const {translate, isVisible, onClose, sample, type, words} = this.props;

    return (
      <Modal
        className="generatelistcards"
        show={isVisible}
        onHide={()=>onClose('generateListCardsModal',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            {translate('flash_cards_settings')}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="body">
          <div className="topView">
            <div className="leftPart" style={{height: !tablet ? 220 : 340}}>
              <Image
                className="image"
                style={{marginTop: 10, height: !tablet ? 100 : 150, opacity: image ? 1 : 0}}
                src={require('../../../assets/images/apple.jpg')}/>

              <span className="wordText" style={{fontSize: fontSizeRation * (!tablet ? 16 : 18)}}>
                apple
              </span>
              <span
                className="translationText"
                style={{fontSize: fontSizeRation * (!tablet ? 12 : 16),opacity: translation ? 1 : 0}}
              >
                яблоко
              </span>
              <span
                className="exampleText"
                style={{fontSize: fontSizeRation * (!tablet ? 10 : 14), opacity: example ? 1 : 0}}
              >
                Apple does not fall far from the tree
              </span>
            </div>

            <div className="rightPart">
              <div className="switchRow">
                <span className="switchRowText" style={{fontSize: fontSizeRation * (!tablet ? 12 : 16)}}>
                  {translate('include_image')}
                </span>

                <a href="#" onClick={(e) => this._toggleSwitch('image', !image, e)}>
                  <FontAwesomeIcon
                    style={{color: image ? colors.tealish : colors.greyishBrown5}}
                    icon={image ? faCheckCircle : faCircle}
                  />
                </a>
              </div>

              <div className="switchRow">
                <span className="switchRowText" style={{fontSize: fontSizeRation * (!tablet ? 12 : 16)}}>
                  {translate('include_translation')}
                </span>
                <a href="#" onClick={(e) => this._toggleSwitch('translation', !translation, e)}>
                  <FontAwesomeIcon
                    style={{color: translation ? colors.tealish : colors.greyishBrown5}}
                    icon={translation ? faCheckCircle : faCircle}
                  />
                </a>
              </div>

              <div className="switchRow">
                <span className="switchRowText" style={{fontSize: fontSizeRation * (!tablet ? 12 : 16)}}>
                  {translate('include_example')}
                </span>
                <a href="#" onClick={(e) => this._toggleSwitch('example', !example, e)}>
                  <FontAwesomeIcon
                    style={{color: example ? colors.tealish : colors.greyishBrown5}}
                    icon={example ? faCheckCircle : faCircle}
                  />
                </a>
              </div>


              <div className="switchRowColumn">
                <span className="switchRowText" style={{fontSize: fontSizeRation * (!tablet ? 12 : 16)}}>
                  {translate('words_on_page')}
                </span>

                <div className="switchButtonsColumn" style={{width: !tablet ? 100 : 150,height: !tablet ? 30 : 45}}>
                  <a href="#"
                     className="switchButtonView"
                     style={{width: !tablet ? 50 : 75, height: !tablet ? 28 : 43,
                     backgroundColor: onPage === 9 ? colors.tealish : colors.white}}
                     onClick={(e)=>this.setOnPage(9, e)}
                  >
                    <span
                      className="switchButtonText"
                      style={{fontSize: fontSizeRation * (!tablet ? 12 : 16),
                      color: onPage === 9 ? colors.white : colors.tealish}}>
                      9
                    </span>
                  </a>

                  <a href="#"
                     className="switchButtonView"
                     style={{width: !tablet ? 50 : 75, height: !tablet ? 28 : 43,
                     backgroundColor: onPage === 16 ? colors.tealish : colors.white}}
                     onClick={(e)=>this.setOnPage(16, e)}
                  >
                    <span
                      className="switchButtonText"
                      style={{fontSize: fontSizeRation * (!tablet ? 12 : 16),
                       color: onPage === 16 ? colors.white : colors.tealish}}>
                      16
                    </span>
                  </a>
                </div>
              </div>

              {
                !sample && type == 'glossary' && (
                  <div className="pagesWrapper mt1">
                    <span
                      className="switchRowText"
                      style={{fontSize: fontSizeRation * (!tablet ? 12 : 16)}}>
                      {translate('page')}
                    </span>

                    <span
                      className="switchRowText"
                      style={{fontSize: fontSizeRation * (!tablet ? 12 : 16)}}>
                      {currentPage}/{totalPages}
                    </span>

                    <div
                      className="pagesRow"
                      style={{width: !tablet ? 80 : 120,height: !tablet ? 30 : 45}}>
                      <a href="#"
                         className="plusView"
                         style={{width: !tablet ? 40 : 60,height: !tablet ? 28: 43}}
                         onClick={(e)=>this._changePage('minus',e)}
                      >
                        <FontAwesomeIcon
                          className="plusViewIcon"
                          style={{fontSize: !tablet ? 15 : 35, color: currentPage === 1 ? colors.lightGreyThree : colors.tealish}}
                          icon={faMinus}
                        />
                      </a>

                      <a href="#"
                         className="plusView"
                         style={{width: !tablet ? 40 : 60,height: !tablet ? 28: 43}}
                         onClick={(e)=>this._changePage('plus', e)}
                      >
                        <FontAwesomeIcon
                          className="plusViewIcon"
                          style={{fontSize: !tablet ? 15 : 35,color: currentPage === totalPages ? colors.lightGreyThree : colors.tealish}}
                          icon={faPlus}/>
                      </a>
                    </div>
                  </div>
                )
              }

            </div>
          </div>

          <Button
            block
            variant="outline-success"
            onClick={() => (type == 'glossary' || sample) ? this._sendCards() : this._sendListCards()}
          >
            {translate('send_cards')}
          </Button>
        </Modal.Body>
        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize)

});

function bindAction(dispatch) {
  return {
    fetchingData: (status) => dispatch(fetchingData(status)),
    sendListCards: (data) => dispatch(sendListCards(data)),
    sendCards: (data) => dispatch(sendCards(data)),

    logActivity: (data) => dispatch(logActivity(data))
  };
}

export default connect(mapStateToProps, bindAction)(GenerateListCards);
