import * as types from './types';

export const getEverydayPhrase = () => (dispatch) => {
    dispatch({ type: types.GET_EVERYDAY_PHRASE });
};

export const getEverydayWord = () => (dispatch) => {
    dispatch({ type: types.GET_EVERYDAY_WORD });
};

export const setGettingEverydayWord = (status) => (dispatch) => {
    dispatch({ type: types.SET_GETTING_WORD, payload: status });
};

export const setGettingEverydayPhrase = (status) => (dispatch) => {
    dispatch({ type: types.SET_GETTING_PHRASE, payload: status });
};

export const addEverydayToList = (listId, wordId, type) => (dispatch) => {
    dispatch({ type: types.ADD_EVERYDAY_TO_LIST, payload: {listId, wordId, type} });
};
