import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import ReCAPTCHA from "react-google-recaptcha";

import {Translate, getTranslate} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faPlusCircle, faCheckCircle, faCircle} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import Loader from "../../../components/Custom/Loader/index";

import {contact_us} from '../../../actions/userActions'
import * as helpers from '../../../lib/validators';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

class ReportBug extends Component {

  constructor(props) {
    super(props);

    this.state = {
      subject: "error",
      message: "",
      email: '',
      word: false,
      extra: "",
      type: 'training',
      training: false,
      tablet: false
    };

    this.inputRef = React.createRef();
    this.emailRef = React.createRef();
    this.recaptchaRef = React.createRef();
    this.timeOut = null;

  }

  componentDidMount() {
    const {word} = this.props;

    if (word) {
      this.setState({
        message: word + " "
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
  }

  sendMessage = () => {
    const {subject, message, email} = this.state;
    const {type, word, training, extra, translate} = this.props;

    let error = false;

    if (message.length === 0) {
      error = true;
    }

    if (error) {
      toastr.warning(translate('empty_message'), translate('write_your_message'));
    } else {
      let submessage = training ? `${type} ${training}` : type;

      if (extra) {
        submessage += JSON.stringify(extra)
      }

      this.props.fetchingData(true);

      this.props.contact_us(subject, `${submessage} ${message}`, email);

      this.props.logActivity({
        activityId: type == 'training' ? 235 : 236,
        comment: `${this.state.type}: ${subject}: ${message}`
      });
      this.setState({
        message: ''
      });
      this.timeOut = setTimeout(()=> {
        this.props.onClose(false);
      }, 250);
    }
  };

  _changeSubject = (event) => {
    event.persist();

    this.setState({
      subject: event.target.value
    })
  };

  _changeMessage = (message) => {
    this.setState({
      message
    })
  };

  _changeEmail = (email) => {
    this.setState({
      email
    })
  };

  onRecaptchaChange = (e) => {
    this.setState({
      recaptcha: e
    })
  };

  render() {
    const {message, tablet, subject, recaptcha, email} = this.state;
    const {fontSizeRation} = this.props.settings;
    const {fetchingData} = this.props.activity;
    const {userId} = this.props.user;

    const {translate, onClose, isVisible, word, type} = this.props;

    const types = ['training', 'word', 'contact'];

    let index = types.indexOf(type);

    let allOptions = [
      [
        {label: translate('general'), value: 'general'},
        {label: translate('error'), value: 'error'},
        {label: translate('new_feature'), value: 'new_feature'}
      ],
      [
        {label: translate('word_error'), value: 'word_error'},
        {label: translate('translation_error'), value: 'translation_error'},
        {label: translate('example_error'), value: 'example_error'},
        {label: translate('missing_image'), value: 'missing_image'},
        {label: translate('missing_translation'), value: 'missing_translation'},
        {label: translate('missing_transcription'), value: 'missing_transcription'},
        {label: translate('missing_examples'), value: 'missing_examples'},
        {label: translate('other'), value: 'other'}
      ],
      [
        {label: translate('general'), value: 'general'},
        {label: translate('error'), value: 'error'},
        {label: translate('new_feature'), value: 'new_feature'},
      ]
    ];

    let options = index !== false ? allOptions[index] : allOptions[2];

    let disabledButton = message.length === 0;

    if (!userId && (!recaptcha || email.length == 0 || !helpers.checkEmail(email))) {
      disabledButton = true;
    }

    return (
      <Modal
        className="reportbug"
        show={isVisible}
        onHide={()=>onClose(false)}
        onShow={()=> {
          if(userId) {
            this.inputRef.current.focus();
          } else {
            this.emailRef.current.focus();
          }
        }}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id={(type == 'training' || type == 'word') ? 'report_bug' : 'contact_us'}/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <p
            className="font-italic font-weight-light"
            style={{paddingHorizontal: !tablet ? 20 : 40, fontSize: fontSizeRation * (!tablet ? 14 : 16)}}>
            {(type == 'training' || type == 'word') ? translate('report_bug_description') : translate('contact_us_description')}
          </p>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">
                {translate('subject')}
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control as="select" onChange={this._changeSubject} value={subject}>
              {
                options.map((item, i) => {
                  return <option value={item.value} key={i}>{item.label}</option>
                })
              }
            </Form.Control>
          </InputGroup>

          {
            !userId && (
              <div className="mb-3">
                <Form.Control
                  ref={this.emailRef}
                  placeholder={`${translate('contact_email')}`}
                  autoCapitalize="none"
                  value={email}
                  onChange={(e)=>this._changeEmail(e.target.value)}
                  as="input"
                />
              </div>
            )
          }

          <div className="messageContainer mb-2">
            <Form.Control
              ref={this.inputRef}
              placeholder={`${translate('message')}`}
              autoCapitalize="none"
              value={message}
              onChange={(e)=>this._changeMessage(e.target.value)}
              as="textarea"
              rows="5"
            />
          </div>

          {
            !userId && (
              <div className="mt-2 text-center m-auto d-flex justify-content-center mb-2">
                <ReCAPTCHA
                  ref={this.recaptchaRef}
                  sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                  onChange={this.onRecaptchaChange}
                />
              </div>
            )
          }

          <Button
            disabled={disabledButton}
            className="mt-3"
            block
            variant="outline-secondary"
            onClick={this.sendMessage}>
            <Translate id="send"/>
          </Button>
        </Modal.Body>
        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    contact_us: (subject, message, email)=> dispatch(contact_us(subject, message, email)),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),

  };
}

export default connect(mapStateToProps, bindAction)(ReportBug);
