import React, {Component} from 'react';
import {connect} from "react-redux";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import ListCardCell from '../../../components/Cells/ListCardCell/index'

import colors from '../../../lib/colors';

class NewLists extends Component {

  render() {
    const {translate,
      ratio,
      tablet,

      mistakesToRepeat,
      repeats,
      newLists,

      relaxPlan,
      onPressCard,
      onRepeatPressed,

      onLongPressed,
      onRepetitionsPressed,
    }= this.props;
    
    return (
      // !relaxPlan && !mistakesToRepeat && (!repeats || repeats.length === 0) && newLists && newLists.length > 0 && (
      !relaxPlan && newLists && newLists.length > 0 && (
        <div style={{flex: 1, marginTop: !tablet ? 0 : 5, marginBottom: !tablet ? 0 : 10}}>
          <OverlayTrigger
            delay={{ show: 700, hide: 400 }}
            trigger={['hover', 'focus']} placement="auto" overlay={<Popover id="popover-basic">
                        <Popover.Content>
                          <p className="nl2br">{translate('start_training_with_repetition_system')}</p>
                        </Popover.Content>
                      </Popover>}
          >
            <div className="flexH flexVStart">
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{color: colors.tealish, fontSize: !tablet ? 20 : 30}}
              />
                        <span
                          className="fontBold textCenter"
                          style={{fontSize: ratio * 16, color: colors.tealish, marginLeft: 10}}
                        >
                          {translate('new_lists')}
                        </span>
            </div>
          </OverlayTrigger>

          <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
            {
              newLists.map((list, index) => {
                return (
                  <ListCardCell
                    key={index}
                    translate={translate}
                    tablet={tablet}
                    ratio={ratio}
                    list={list}
                    index={index}
                    repeat={false}
                    dots={false}
                    newList={true}
                    onItemPressed={onPressCard}
                    onLongPressed={onLongPressed}
                    onRepeatPressed={onRepeatPressed}
                    showRepetitionDays={onRepetitionsPressed}

                  />
                );
              })
            }
          </div>
        </div>
      )
    )
  }
}

export default connect(null, null)(NewLists);
