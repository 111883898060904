import React, {Component} from 'react';
import {connect} from "react-redux";
import {Translate, getTranslate} from 'react-localize-redux';

import {Link} from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock, faAward, faInfoCircle} from '@fortawesome/free-solid-svg-icons';

import {toastr} from 'react-redux-toastr';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import CourseListWordCell from '../CourseListWordCell/index';
// import ListWordStatisticsModal from '../ListWordStatisticsModal/index';
import Loader from "../../../components/Custom/Loader/index";

import {
  getAllCourseMistakes,
}
  from '../../../actions/courseActions';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

import colors from '../../../lib/colors';

class CourseMistakes extends Component {

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      refreshing: false,
      currentChunk: 0,
      totalWords: 0,
      listWordStatisticsModalVisible: false,
      currentWord: false,
      tablet: false
    };

    this.viewabilityConfig = {
      waitForInteraction: true,
      viewAreaCoveragePercentThreshold: 0
    };

    this.track = null;
  }

  componentDidMount() {
    this.props.fetchingData(true);

    this.props.getAllCourseMistakes(this.props.history);

  }

  componentWillUnmount() {
  }

  _toWordDetails = (word) => {
    const {locale} = this.props.settings;
    
    word.id = word.word_id;
    this.props.history.push(`/${locale}/dictionary/${word.word.word}`, {word, back: true});

    this.props.logActivity({activityId: 45, model: 'word', modelId: word.id, comment: "from course all mistakes"});
  };

  playSound = (url, e) => {
    e.preventDefault();
    e.stopPropagation();

    if (url) {
      if (this.track != null) {
        this.track.pause();
        this.track = null;
      }

      this.track = new Audio(url);
      this.track.play()
    }
  };

  _onStatisticsPressed = (word, e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      currentWord: word
    });

    this._toggleStatisticsModal(true);
    this.props.logActivity({activityId: 259, model: 'word', modelId: word.id, comment: "from course list"});
  };

  _toggleStatisticsModal = (status) => {
    this.setState({
      listWordStatisticsModalVisible: status
    })
  };

  _renderItem = (item, index) => {
    const {fontSizeRation, version} = this.props.settings;
    const {tablet} = this.state;
    const {showWordSentence} = this.props.course;
    const {userData} = this.props.user;
    const {translate} = this.props;
    const {isConnected} = this.props.settings;

    return (
      <CourseListWordCell
        key={index}
        translate={translate}
        userData={userData}
        isConnected={isConnected}
        opened={true}
        mistakes={true}
        ratio={fontSizeRation}
        version={version}
        tablet={tablet}
        showSentence={false}
        word={item}
        
        onPlay={this.playSound}
        onStatisticsPressed={this._onStatisticsPressed}
        toWordDetails={this._toWordDetails}
        index={index}
      />
    )
  };


  render() {
    const {allCourseMistakesFetched, allCourseMistakes, currentCourseSlug} = this.props.course;
    const {currentWord, tablet, listWordStatisticsModalVisible} = this.state;
    const {fetchingData} = this.props.activity;
    const {translate} = this.props;
    const {fontSizeRation, deviceWidth, locale} = this.props.settings;

    const subtitle = `${translate('wordss')}: ${allCourseMistakes.length}`;

    return (
      <div className="coursemistakes">

        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>

          <li className="breadcrumb-item">
            <Link to={{
              pathname: `/${locale}/course/${currentCourseSlug}`,

            }}>
              {translate('course')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate('your_course_mistakes')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container style={{marginTop: 20}} className="pageWrapper">
          <div className="text-center">
            <h5>
              <span className="d-inline-block mr-2">
                {translate('your_course_mistakes')}
              </span>
              <OverlayTrigger
                delay={{ show: 500, hide: 500 }}
                rootClose={true}
                placement="bottom"
                overlay={<Tooltip>{translate('all_mistakes_description')}</Tooltip>}
              >
                <FontAwesomeIcon className="infoIcon" icon={faInfoCircle}/>
              </OverlayTrigger>
            </h5>
            <p className="mb-1" style={{color: colors.coral, fontSize: fontSizeRation * (!tablet ? 12 : 14)}}>
              {subtitle}
            </p>
          </div>

          <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
            {allCourseMistakes.map((item, index) => {
              return this._renderItem(item, index)
            })}
          </div>

          {/*listWordStatisticsModalVisible && (
           <ListWordStatisticsModal
           isVisible={listWordStatisticsModalVisible}
           onClose={this._toggleStatisticsModal}
           word={currentWord}
           tablet={tablet}
           ratio={fontSizeRation}
           />
           )*/}

        </Container>
        
        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  course: state.course,
  user: state.user,
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    getAllCourseMistakes: (history) => dispatch(getAllCourseMistakes(history)),
  };
}

export default connect(mapStateToProps, bindAction)(CourseMistakes);
