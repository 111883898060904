import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';

import {Translate, getTranslate} from 'react-localize-redux';
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons';

import CourseListCell from '../../../Courses/CourseListCell/index';

import colors from '../../../../lib/colors';

class CourseTests extends Component {

  _renderTestItem = (item, index, course) => {
    const {translate, onTestPressed, onTestRepeat} = this.props;
    const {fontSizeRation} = this.props.settings;
    const {courseData} =  this.props.course;
    const {isConnected} = this.props.settings;

    let testProgress = this.getTestProgress(item, true);
    
    let courseName = course.slug == 1000 ? 'course_beginner' : 'course_elementary';

    return (
      <CourseListCell
        key={index}
        isConnected={isConnected}
        tablet={false}
        ratio={fontSizeRation}
        list={item}
        testProgress={testProgress}
        courseData={courseData}
        index={index}
        translate={translate}
        test={true}
        dots={false}
        courseName={courseName}
        onItemPressed={() => onTestPressed(item, course)}
        onRepeatPressed={(list, some, e) => onTestRepeat(item, course, e)}
      />
    )
  };

  getTestProgress = (list) => {

    return list.repetitions.map((day, i) => {
      let progress = day.test_results / 100;

      let missed = false;

      if (day.repetition_date < moment().format("YYYY-MM-DD") && day.test == 0) {
        missed = true;
      }

      let color = colors.lightGrey;

      if (missed) {
        color = colors.coral
      } else {
        if (progress > 0 && progress < 0.5) {
          color = colors.coral
        } else if (progress >= 0.5 && progress < 0.75) {
          color = colors.waterBlue
        } else if (progress >= 0.75) {
          color = colors.greenish
        }
      }

      return <div key={i} className="mh02">
        <CircularProgressbar
          value={missed ? 100 : (progress*100)}
          strokeWidth={10}
          styles={buildStyles({
            pathTransitionDuration: 1,
            pathColor: color,
            backgroundColor: missed ? colors.coral : (progress == 1 ? colors.greenish : colors.white)
          })}
        />
      </div>
    });
  };

  _getTests = () => {
    const {cachedCourseData} = this.props.courseCache;
    let tests = [];

    cachedCourseData.forEach((course, index) => {
         if(course && course.tests) {
           course.tests.forEach((list, i) => {
             let test =  this._renderTestItem(list, `${index}-${i}`, course);
             tests.push(test)
           })
         }
      });

    return tests;
  };


  render() {
    const tests = this._getTests();

    return tests.length > 0 ?  (
      tests
    ) : null;
  }
}


const mapStateToProps = (state, dispatch) => ({
  activity: state.activity,
  trainings: state.trainings,
  settings: state.settings,
  purchases: state.purchases,
  user: state.user,
  course: state.course,

  courseCache: state.courseCache,
  translate: getTranslate(state.localize),

});


export default connect(mapStateToProps, null)(CourseTests);
