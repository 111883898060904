import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faTrash, faVolumeUp, faCheckCircle, faCircle} from '@fortawesome/free-solid-svg-icons';

import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

import colors from '../../../lib/colors';
import * as constants from '../../../lib/constants';

class CourseListWordCell extends Component {

  render() {
    const {
      word,
      ratio,
      index,
      onPlay,
      opened,
      toWordDetails,
      version,
      tablet,
      showSentence,
      preview,
      mistakes,
      isConnected,
      showWordSettings,
      userData,
      vocabulary,
      deleteFromVocabulary,
      deviceWidth,
      onStatisticsPressed,
      bulkMode,
      selected,
      
    } = this.props;

    const imageUrl = word && word.image ? word.image : false;

    const wordName = word && word.word_name ? word.word_name : "";
    const transcription = word && word.transcription ? word.transcription : "";
    const widthMin = opened ? 20 : 60;
    let height = opened ? (showSentence ? 300 : 280) : 280;
      // (!tablet ? (deviceWidth >= 360 ? 280 : 260) : (deviceWidth > 600 ? (showSentence ? 440 : 400) : 450))
      // : (!tablet ? (deviceWidth >= 360 ? 280 : 240) : (deviceWidth > 600 ? (showSentence ? 440 : 370) : 450));

    const imageWidth = 150;

    const s3image = `${constants.S3MarkedImages}-${imageWidth}/${imageUrl}`;

    let audio = word && word.audio ? word.audio : false;
    let audioSource = constants.BACKEND_URL;

    if(userData && userData.audio_language && word[userData.audio_language]) {
      audio = word[userData.audio_language];
      audioSource = constants.S3
    }

    if(ratio > 1.25) {
      height += (20 * ratio);
    }

    return (
      <Card
        style={{width: 200,height}}
        className="courseListWord"
        onClick={(e) => toWordDetails(word, e)}
      >
        {bulkMode &&(<span className="selectButton">
              <FontAwesomeIcon
                className="icon15"
                icon={selected ? faCheckCircle : faCircle}
                style={{color: selected ? colors.tealish : colors.lightGreyTwo}}
              />
            </span>)||null}
        <div className="listWrapper">
          <div className="listContainer">

            {
              mistakes && word.totalMistakes && (
                <div
                  onClick={(e) => onStatisticsPressed(word, e)}
                  className="d-flex justify-content-center align-items-center mistakes"
                  >
                  <span className="text-center" style={{color: colors.coral, fontSize: ratio * (!tablet ? 14 : 12)}}>
                    {word.totalMistakes}
                  </span>
                </div>
              ) || null
            }

            {
              !mistakes && !preview && !vocabulary &&(
                <a href="#"
                   className="bars"
                    onClick={(e) => {
                    showWordSettings(word,e)
                  }}>
                  <FontAwesomeIcon
                    className="icon20"
                    icon={faBars}
                    style={{color: word.vocabulary ? colors.dustyGreen : colors.lightGreyTwo}}
                  />
                </a>
              )
            }

            {
              vocabulary &&(
                <a href="#"
                  className="trash"
                  onClick={(e) => {
                    deleteFromVocabulary(word, e)
                  }}>
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="icon15"
                    style={{color: colors.greyishBrown5}}
                  />
                </a>
              )
            }

            <p
              className="word"
              style={{fontSize: ratio * (!tablet ? 17 : 19), marginTop: !tablet ? (showSentence ? 5 : 10) : 10, marginBottom: !tablet ? 5 : 10}}>
              {wordName}
            </p>

            <p
              className="transcription"
              style={{minHeight: 35,fontSize: ratio * (!tablet ? 12 : 14), marginBottom: !tablet ? (showSentence ? 5 : 10) : 10}}>
              {transcription}
            </p>

            {
              imageUrl && (
                <Image
                  style={{
                    height: !tablet ? (showSentence ? (deviceWidth >= 360 ? 75 : 60) : (deviceWidth >= 360 ? 100 : 80))
                     : (showSentence ? 100 : 130),
                    width: !tablet ? (showSentence ? (deviceWidth >= 360 ? 104 : 82) : (deviceWidth >= 360 ? 137 : 108)) : (showSentence ? 137 : 178)}
                  }
                  src={s3image}
                />
              ) || <div style={{height: !tablet ? (showSentence ? 75 : 100) : (showSentence ? 100 : 130),
              width: !tablet ? (showSentence ? 104 : 137) : (showSentence ? 137 : 178)}}/>
            }

            <p
              className="translation"
              style={{fontSize: ratio * (!tablet ? 16 : 18)}}>
              {word.translation ? word.translation : ""}
            </p>

            {
              showSentence && (<div className="w100 mv1" style={{minHeight: !tablet ? 38 : 65}}>
                <p
                  className="example"
                  style={{paddingHorizontal: !tablet ? 2 : 10,fontSize: !tablet ? 12 : 14}}>
                  {word.example && word.example.example ? word.example.example : ""}
                </p>
                {
                  opened && deviceWidth <= 600 && false && (
                    word.example && word.example.translation && word.example.translation.translation && (
                      <p
                        className="exampleTranslation"
                        style={{paddingHorizontal: !tablet ? 2 : 10,fontSize: !tablet ? 11 : 13}}>
                        {word.example.translation.translation}
                      </p>
                    ) || null
                  )
                }

              </div>)
            }

            <div className="flexH flexAround soundWrapper">
              {
                isConnected && (
                  <a href="#"
                     className="pv2 w100 dFlex flexCenter"
                    onClick={(e) => audio ? onPlay(`${audioSource}${audio}`, e) : null}
                  >
                    <FontAwesomeIcon
                      icon={faVolumeUp}
                      className="icon25"
                      style={{color: colors.greyishBrown}}
                    />
                  </a>
                )
              }

            </div>

          </div>
        </div>
      </Card>

    );
  }
}

export default CourseListWordCell;
