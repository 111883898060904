import * as types from './types';

export const getVocabulary = (data) => (dispatch) => {
    dispatch({ type: types.GET_VOCABULARY, payload: data });
};

export const resetVocabulary = (page) => (dispatch) => {
    dispatch({ type: types.RESET_VOCABULARY});
};

export const setGoal = (goal) => (dispatch) => {
    dispatch({ type: types.SET_GOAL, payload: goal });
};

export const deleteFromVocabulary = (wordId, index) => (dispatch) => {
    dispatch({ type: types.DELETE_FROM_VOCABULARY, payload: {wordId, index} });
};

export const toggleFavoriteWord = (wordId, index) => (dispatch) => {
    dispatch({ type: types.TOGGLE_VOCABULARY_WORD_FAVOFITES, payload: {wordId, index} });
};

export const gettingNewChunk = () => (dispatch) => {
  dispatch({ type: types.GET_VOCABULARY_WORDS_NEXT_CHUNK });
};
