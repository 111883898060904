let letters = {
  'en': [
    {'sub': 'able', 'options': ['eble', 'aibl', 'abl']},
    {'sub': 'cial', 'options': ['ceal', 'sial']},
    {'sub': 'ious', 'options': ['eous', 'iouse',]},
    {'sub': 'sion', 'options': ['tion', 'shn']},
    {'sub': 'tion', 'options': ['sion', 'cion']},

    {'sub': 'ake', 'options': ['acke', 'aik']},
    {'sub': 'any', 'options': ['eny', 'ani']},
    {'sub': 'ape', 'options': ['aip',]},
    {'sub': 'ary', 'options': ['ery', 'eary', 'ari']},

    {'sub': 'ery', 'options': ['ary']},
    {'sub': 'ent', 'options': ['ant']},

    {'sub': 'ght', 'options': ['gh', 'gt']},

    {'sub': 'ign', 'options': ['in', 'egn']},
    {'sub': 'ing', 'options': ['in', 'inh','eng']},
    {'sub': 'ise', 'options': ['ice']},
    {'sub': 'ice', 'options': ['ise']},
    {'sub': 'ity', 'options': ['ety']},
    {'sub': 'ive', 'options': ['iwe', 'iv']},
    {'sub': 'nal', 'options': ['nel',]},
    {'sub': 'ory', 'options': ['ary', 'ery']},
    {'sub': 'ong', 'options': ['onh', 'on']},
    {'sub': 'ous', 'options': ['os', 'aus', 'ause', 'ouse']},
    {'sub': 'sch', 'options': ['sh', 'sck', 'sk']},

    {'sub': 'tch', 'options': ['ch', 'th']},

    {'sub': 'ai', 'options': ['ei', 'ay']},
    {'sub': 'al', 'options': ['ol', 'el']},
    {'sub': 'an', 'options': ['en']},
    {'sub': 'am', 'options': ['em']},
    {'sub': 'ar', 'options': ['a']},
    {'sub': 'aw', 'options': ['ow', 'ou', 'owe']},
    {'sub': 'au', 'options': ['a', 'o', 'ou']},
    {'sub': 'ay', 'options': ['ey', 'ai',]},

    {'sub': 'bb', 'options': ['b',]},

    {'sub': 'cc', 'options': ['c', 'cs']},
    {'sub': 'ch', 'options': ['c', 'sh', 'sch']},
    {'sub': 'ck', 'options': ['k', 'ch']},

    {'sub': 'ea', 'options': ['ee', 'e', 'a', 'i']},
    {'sub': 'ee', 'options': ['ea', 'oo', 'e', 'i']},
    {'sub': 'en', 'options': ['an']},
    {'sub': 'em', 'options': ['am']},
    {'sub': 'er', 'options': ['ur', 'ar', 'ir']},
    // {'sub': 'es', 'options': ['s',]},
    {'sub': 'ey', 'options': ['ay', 'y']},

    {'sub': 'ff', 'options': ['f',]},
    {'sub': 'ft', 'options': ['f', 'ph', 'pht']},

    {'sub': 'gg', 'options': ['g',]},

    {'sub': 'ie', 'options': ['i', 'ee', 'ea']},
    {'sub': 'ir', 'options': ['er',]},


    {'sub': 'gh', 'options': ['g', 'h', 'f']},

    {'sub': 'kn', 'options': ['k']},

    {'sub': 'll', 'options': ['l']},
    {'sub': 'la', 'options': ['le']},
    {'sub': 'li', 'options': ['lai']},
    {'sub': 'ly', 'options': ['li', 'ley', 'lier']},

    {'sub': 'mm', 'options': ['m']},

    {'sub': 'nn', 'options': ['n']},

    {'sub': 'oa', 'options': ['ou',]},
    {'sub': 'oo', 'options': ['ou', 'ee','u', 'y']},
    {'sub': 'oi', 'options': ['oy']},
    {'sub': 'oy', 'options': ['oi']},
    {'sub': 'ow', 'options': ['aw', 'ou', 'awe']},
    {'sub': 'ol', 'options': ['al']},
    {'sub': 'or', 'options': ['o', 'ar']},
    {'sub': 'ou', 'options': ['o', 'au']},

    {'sub': 'ph', 'options': ['f',]},
    {'sub': 'pp', 'options': ['p',]},

    {'sub': 'rr', 'options': ['r',]},

    {'sub': 'sh', 'options': ['h', 'ch', 'sch']},
    {'sub': 'ss', 'options': ['s',]},

    {'sub': 'th', 'options': ['s', 'z']},
    {'sub': 'tt', 'options': ['t',]},

    {'sub': 'ur', 'options': ['er']},

    {'sub': 'wh', 'options': ['w']},

    {'sub': 'a', 'options': ['o', 'ei', 'ai']},
    {'sub': 'b', 'options': ['p',]},
    {'sub': 'c', 'options': ['s', 'k']},
    {'sub': 'd', 'options': ['t']},
    {'sub': 'e', 'options': ['i', 'a', 'ee']},
    {'sub': 'f', 'options': ['ph',]},
    {'sub': 'g', 'options': ['dg', 'h']},
    {'sub': 'h', 'options': ['sh', 'ch', 'ah']},

    {'sub': 'i', 'options': ['y', 'ai', 'e', 'ee']},
    {'sub': 'j', 'options': ['dj', 'z', 'g']},
    {'sub': 'k', 'options': ['ch', 'c', 'q']},
    {'sub': 'l', 'options': ['ll']},
    {'sub': 'm', 'options': ['n']},
    {'sub': 'n', 'options': ['m']},
    {'sub': 'o', 'options': ['oo', 'ou', 'a']},

    {'sub': 'p', 'options': ['b', 'pp']},
    {'sub': 'q', 'options': ['k', 'ck']},
    {'sub': 'r', 'options': ['ar']},
    {'sub': 's', 'options': ['c', 'z', 'th']},
    {'sub': 't', 'options': ['d']},
    {'sub': 'u', 'options': ['oo', 'a']},
    {'sub': 'v', 'options': ['w',]},
    {'sub': 'w', 'options': ['v', 'wh']},
    {'sub': 'x', 'options': ['ks',]},
    {'sub': 'y', 'options': ['i']},
    {'sub': 'z', 'options': ['s', 'th']},
  ]

}
export default letters;