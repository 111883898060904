import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Translate, getTranslate} from 'react-localize-redux';
import ReCAPTCHA from "react-google-recaptcha";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGoogle, faFacebook} from '@fortawesome/free-brands-svg-icons'
import {toastr} from 'react-redux-toastr';


import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import {GoogleLogin} from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import {register, checkVerified, resendActivationLink} from '../../../actions/authActions';
import {setUserIpAddress} from '../../../actions/userActions';

import * as helpers from '../../../lib/validators';

import Logo from "../../../assets/images/logo-boy-prepared.png";
import Loader from "../../../components/Custom/Loader/index";

const publicIp = require('public-ip');

class Register extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      socialId: false,
      userInfo: false,
      token: false,
      regButtonPressed: false,
      whyRegisterModalVisible: false,
      resendClicked: 0,
      hide: false,
      resendLinkModal: false,
      recaptcha: false,
    };

    this.checker = null;
    this.timeOut = null;
    this._checkUser();
    this._getIpAddress();
    this.recaptchaRef = React.createRef();

  }

  componentDidMount(){
    if(this.props.location && this.props.location.state && this.props.location.state.email) {
      this.setState({
        email: this.props.location.state.email
      })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
    clearInterval(this.checker);
  }

  _checkUser = () => {
    const userId = localStorage.getItem('userId');
    const {history} = this.props;
    const {locale} = this.props.settings;

    if(userId) {
      history.replace(`/${locale}/home`);
    }
  };

  verify = () => {
    const {userId, shouldCheck} = this.props.user;
    
    if (userId && shouldCheck) {
      this.checker = setInterval(() => {
        this.checkIfVerified();
      }, 1000);
    }
  };

  checkIfVerified = () => {
    const {locale} = this.props.settings;

    const {userId, confirmed} = this.props.user;

    if (!confirmed) {
      this.props.checkVerified(userId, this.props.history)
    } else {
      clearInterval(this.checker);
      this.checker = null;
      this.props.history.replace(`/${locale}/wizard`);
    }
  };
  
  _getIpAddress = async() => {
    const ip = await publicIp.v4();
    
    if(ip) {
      this.props.setUserIpAddress(ip)
    }
  };

  responseGoogle = (response) => {
    const {translate} = this.props;

    let userInfo = response.profileObj;

    if (!userInfo) {
      toastr.error(translate('canceled_sign_in_flow'))
    } else {
      if (!userInfo.email) {
        toastr.error(translate('no_email_returned'));
      } else {
        /*let user = {
          email: userInfo.email,
          last_name: userInfo.familyName,
          first_name: userInfo.givenName,
          id: userInfo.googleId,
          avatar: userInfo.imageUrl
        };*/
        
        this.setState({
          userInfo,
          socialId: response.googleId
        });

        this.props.logActivity({activityId: 7, comment: userInfo.email});

        if (userInfo.email && userInfo.email.length > 0) {
          this.attemptToRegister('google')
        } else {
          toastr.error(translate('no_email_returned'));
        }
      }
    }
  };

  responseFacebook = (response) => {
    const {translate} = this.props;

    if(!response) {
      toastr.error(translate('canceled_sign_in_flow'))
    } else {
      if (!response.email) {
        toastr.error(translate('no_email_returned'));
      } else {
        if(response.email && response.email.length > 0) {
          let user = {
            email: response.email,
            last_name: response.last_name,
            first_name: response.first_name,
            id: response.id,
            avatar: response.picture && response.picture.data && response.picture.data.url ? response.picture.data.url : false
          };

          this.setState({
            userInfo: user,
            socialId: response.userID
          });

          this.attemptToRegister('facebook');
          this.props.logActivity({activityId: 105, comment: response.email});
        } else {
          toastr.error(translate('no_email_returned'));
        }
      }
    }
  };


  registerWithEmail = (e) => {
    e.preventDefault();

    const {translate} = this.props;

    if (this.state.email.length == 0) {
      toastr.error('', translate('empty_email'))
    } else if (!helpers.checkEmail(this.state.email)) {
      toastr.error('', translate('valid_email'))
    } else {
      this.setState({
        userInfo: false,
        regButtonPressed: true
      });

      this.props.logActivity({activityId: 2, comment: this.state.email});
      this.attemptToRegister('email');
    }
  };

  attemptToRegister = (type) => {
    this.props.fetchingData(true);

    const data = {
      ...{
        email: !this.state.userInfo ? this.state.email : this.state.userInfo.email,
        platform: type,
      },
      social: this.state.userInfo,
      history: this.props.history
    };

    this.props.register(data);

    if (type == 'email') {
      this.timeOut = setTimeout(() => {
        this.verify();
      }, 3000);
    }
  };

  _toContactUs = () => {
    this.setState({
      resendClicked: 0,
      regButtonPressed: false,
    });

    this.props.logActivity({activityId: 316});
    // this.props.navigation.navigate('ContactUsRegistration', {email: this.state.email})

  };

  componentClicked = (event) => {};

  handleChange(field, value) {
    this.setState({[field]: value});
  }

  _resendActivationLink = (email) => {
    this._toggleResendModal(false);

    this.props.fetchingData(true);

    this.setState({
      resendClicked: this.state.resendClicked + 1
    });

    if (email != this.state.email) {
      this.setState({
        email
      })
    }
    // this.props.resendActivationLink(email);

    this.props.logActivity({activityId: 325});
  };

  _toggleResendModal = (status) => {
    this.setState({
      resendLinkModal: status
    })
  };

  _toggleWhyRegister = (status) => {
    this.setState({
      whyRegisterModalVisible: status
    })
  };

  onRecaptchaChange = (e) => {
    this.setState({
      recaptcha: e
    })
  };

  _handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.registerWithEmail(event);
    }
  };

  render() {
    const {fetchingData} = this.props.activity;
    const {fontSizeRation, locale} = this.props.settings;
    const {translate} = this.props;
    const {recaptcha} = this.state;

    return (
      <div className="auth-logo">
        <div className="d-flex flex-column justify-content-center align-items-center mb-3 logo-container">
          <span className="font-weight-bold" style={{fontSize: fontSizeRation * 40}}>Mnemonic Words</span>

          <Image src={Logo} className="logo mt-2"/>
        </div>

        <div className="d-flex flex-rom justify-content-center align-items-center mb-2">
          <GoogleLogin
            clientId="75117688803-5u9a94dor4ghq4ud5jgqjini2ti32e61.apps.googleusercontent.com"
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogle}
            onClick={this.componentClicked}
            render={renderProps => (
              <button className="socialIcon mr-3" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                  <FontAwesomeIcon icon={faGoogle}/>
              </button>
            )}
          />

          <FacebookLogin
            appId="2684485318484487"
            autoLoad={false}
            language={'ru'}
            fields="first_name,last_name,name,email,picture"
            callback={this.responseFacebook}
            onClick={this.componentClicked}
            render={renderProps => (
              <button className="socialIcon ml-3" onClick={renderProps.onClick}>
                <FontAwesomeIcon icon={faFacebook}/>
              </button>
            )}
          />
        </div>

        <div className="form-signin register">
          <label htmlFor="inputEmail" className="sr-only">{translate('Email')}</label>
          <input
            type="email"
            id="inputEmail"
            disabled={!recaptcha}
            value={this.state.email}
            className="form-control"
            onSubmit={this.registerWithEmail}
            placeholder={translate('Email')}
            required
            autoFocus
            onChange={(e)=>this.handleChange('email',e.target.value)}
            onKeyPress={(e)=>this._handleKeyPress(e)}
          />

          <Button
            disabled={!recaptcha}
            style={{fontSize: fontSizeRation * 16}}
            className="gradient-blue mt-4"
            variant="primary" type="button" size="lg" block onClick={this.registerWithEmail}>
            <Translate id="sign_up"/>
          </Button>
        </div>

        <div className="mt-2 text-center m-auto d-flex justify-content-center mb-2">
          <ReCAPTCHA
            ref={this.recaptchaRef}
            sitekey={process.env.REACT_APP_CAPTCHA_KEY}
            onChange={this.onRecaptchaChange}
          />
        </div>

        <Link to={`/${locale}/login`} className="signUpLink mt-2">
          <Translate id="sign_in" />
        </Link>

        <Loader fetchingData={fetchingData} />
      </div>
    );
  }
}


function bindAction(dispatch) {
  return {
    register: (data) => dispatch(register(data)),
    checkVerified: (userId, history) => dispatch(checkVerified(userId, history)),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    resendActivationLink: (email) => dispatch(resendActivationLink(email)),
    setUserIpAddress: (ip) => dispatch(setUserIpAddress(ip)),
  };
}

const mapStateToProps = state => ({
  user: state.user,
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize),
});

export default connect(
  mapStateToProps,
  bindAction,
)(withRouter(Register));
