import React, {Component} from 'react';
import {connect} from "react-redux";
import {Translate, getTranslate} from 'react-localize-redux';
import {withRouter, Link} from 'react-router-dom';

import {toastr} from 'react-redux-toastr';

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
import ChildGlossary from '../../../components/Cells/ChildGlossary/index';
import Loader from "../../../components/Custom/Loader/index";

import {get_favourite_glossaries, getAllFavoriteNumbers} from '../../../actions/favoriteActions'
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import {setCurrentChild} from '../../../actions/glossaryActions';


class FavoriteGlossaries extends Component {

  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      subscriptionAlertVisible: false,
      tablet: false

    };
  }

  componentDidMount() {
    this._getGlossariesData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._getGlossariesData();
    }
  }

  componentWillUnmount() {
    this._getGlossariesData();
  }

  _getGlossariesData = () => {
    const {isConnected} = this.props.settings;

    if (isConnected) {
      this.props.fetchingData(true);
      // this.props.getAllFavoriteNumbers();
      this.props.get_favourite_glossaries()
    }
  };
  
  _onPressItem = (child, index) => {
    const {subscriptionActive, applyLimit, glossariesLimit, webSubscriptionStatus, cards, lifetimeStatus} = this.props.purchases;
    const {history} = this.props;
    const {locale} = this.props.settings;

    this.props.setCurrentChild(child);

    if (lifetimeStatus || subscriptionActive || cards || webSubscriptionStatus || !applyLimit) {
      history.push(`/${locale}/glossary/${child.id}`, {child, back: true});
    } else {
      if (index < glossariesLimit) {
        history.push(`/${locale}/glossary/${child.id}`, {child, back: true});
      } else {
        this._toggleSubscriptionAlert(true)
      }
    }

    this.props.logActivity({activityId: 154, model: 'glossary', modelId: child.id});
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toSubscription = (productId) => {
    this._toggleSubscriptionAlert(false);
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId}); 
  };

  _renderItem = (item, index) => {
    const {subscriptionActive, applyLimit, glossariesLimit, webSubscriptionStatus, cards, lifetimeStatus} = this.props.purchases;
    const {fontSizeRation, version, deviceWidth} = this.props.settings;
    const {userData} = this.props.user;
    const {translate} = this.props;

    return (
      <ChildGlossary
        glossary={item}
        ratio={fontSizeRation}
        onItemPressed={this._onPressItem}
        userData={userData}
        subscriptionActive={subscriptionActive}
        webSubscriptionStatus={webSubscriptionStatus}
        applyLimit={applyLimit}
        glossariesLimit={glossariesLimit}
        cards={cards}
        lifetimeStatus={lifetimeStatus}
        version={version}
        index={index}

        key={index}
        deviceWidth={deviceWidth}
        translate={translate}
      />
    );
  };

  render() {
    const {glossaries} = this.props.favorites;
    const {fetchingData} = this.props.activity;
    const {translate} = this.props;
    const {locale} = this.props.settings;

    const {subscriptionAlertVisible} = this.state;

    return (
      <div className="favoriteglossaries">
        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate('favourite_glossaries')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container className="pageWrapper">
          <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
            {
              glossaries.map((item, index) => {
                return this._renderItem(item, index)
              })
            }
          </div>
          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={'subscription_glossaries'}
                translate={translate}
              />
            )
          }
        </Container>
        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  favorites: state.favorites,
  user: state.user,
  activity: state.activity,
  settings: state.settings,
  purchases: state.purchases,
  translate: getTranslate(state.localize)
});

function bindAction(dispatch) {
  return {
    get_favourite_glossaries: () => dispatch(get_favourite_glossaries()),
    fetchingData: () => dispatch(fetchingData()),
    getAllFavoriteNumbers: () => dispatch(getAllFavoriteNumbers()),
    logActivity: (data) => dispatch(logActivity(data)),
    setCurrentChild: (child) => dispatch(setCurrentChild(child))
  };
}

export default connect(mapStateToProps, bindAction)(FavoriteGlossaries);
