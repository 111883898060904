import * as types from '../actions/types';

const INITIAL_STATE = {
  words: [],
  foundWord: false,
  fetchedWord: false,
  resetSearchField: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_FOUND_DICTIONARY_WORDS:
      return Object.assign({}, state, {
        words: action.payload,
      });

    case types.RESET_DICTIONARY_SEARCH_FIELD:
      return Object.assign({}, state, {
        resetSearchField: action.payload,
      });

    case types.SET_FOUND_DICTIONARY_WORD:
      return Object.assign({}, state, {
        foundWord: action.payload,
        fetchedWord: true,
      });
    
    case types.RESET_FOUND_DICTIONARY_WORD:
      return Object.assign({}, state, {
        foundWord: false,
        fetchedWord: false,
      });

    default:
      return state;
  }
}