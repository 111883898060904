import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import classNames from 'classnames';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle, faCheckCircle, faEdit, faTimes} from '@fortawesome/free-solid-svg-icons';
import {ReactSortable} from "react-sortablejs";

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import Loader from "../../../components/Custom/Loader/index";

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import {
  updateTrainingsSet,
  activateTrainingsSet,
} from '../../../actions/userActions';

import colors from '../../../lib/colors';

class TrainingsSet extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tablet: false,
      currentType: false,
      sort: false,
      selectedTrainingsSet: [],
      trainingsSet: [
        {name: 'preview_training', id: 1, active: false},
        {name: 'drag_image_training', id: 6, active: false},
        {name: 'select_translation_training', id: 2, active: false},
        {name: 'select_word_training', id: 4, active: false},
        {name: 'collect_word_training', id: 3, active: false},
        {name: 'check_spelling_training', id: 5, active: false},
        {name: 'missing_letter_training', id: 12, active: false},
        {name: 'collect_sentence_training', id: 8, active: false},
        {name: 'listening_training', id: 14, active: false},
        {name: 'memory_cards_training', id: 9, active: false},
        {name: 'definition_training', id: 10, active: false},
        {name: 'translate_sentence_training', id: 15, active: false},
      ]
    };
  }

  componentDidMount() {
    this._makeSets();
  }

  _makeSets = () => {
    const {userData} = this.props.user;
    const {trainingsSet} = this.state;
    let trainingIds = userData.trainings_set != null ? userData.trainings_set.split(',').map(Number) : [];

    let selected = [];
    let updatedSet = trainingsSet.map(training => {
      if (trainingIds.indexOf(training.id) !== -1) {
        training.active = true;
      } else {
        training.active = false;
      }
      return training;
    });

    trainingIds.forEach(id => {
      trainingsSet.forEach(training => {
        if (training.id == id) {
          selected.push(training)
        }
      })
    });

    this.setState({
      trainingsSet: updatedSet,
      selectedTrainingsSet: selected
    })
  };

  componentDidUpdate(prevProps) {
    const {userData} = this.props.user;

    if (userData !== prevProps.user.userData) {
      this._makeSets();
    }
  }


  _updateTrainingsSet = (e, training) => {
    e.preventDefault();
    e.stopPropagation();

    const {userData} = this.props.user;
    let trainingIds = userData.trainings_set != null ? userData.trainings_set.split(',').map(Number) : [];

    let selected = trainingIds.indexOf(training.id);

    if (selected !== -1) {
      trainingIds = trainingIds.filter(id => id !== training.id)
    } else {
      trainingIds.push(training.id)
    }

    this.props.fetchingData(true);
    this.props.updateTrainingsSet(trainingIds.join(','))
  };

  _setPreset = (type) => {
    this.setState({
      currentType: type
    });

    this.props.fetchingData(true);

    if (type == 'basic') {
      this.props.updateTrainingsSet('1,2,4,3,5')
    } else if (type == 'grammar') {
      this.props.updateTrainingsSet('1,3,5,12,8')
    } else if (type == 'advanced') {
      this.props.updateTrainingsSet('1,3,5,12,8,10,15')
    } else if (type == 'all') {
      this.props.updateTrainingsSet('1,6,2,4,3,5,12,8,14,9,10,15')
    }
  };

  _changeActiveSet = () => {
    this.setState({
      sort: false
    });
    
    this.props.fetchingData(true);
    this.props.activateTrainingsSet();
  };

  _sortTrainingsSet = () => {
    const {selectedTrainingsSet} = this.state;
    let trainingIds = [];

    selectedTrainingsSet.forEach(training => {
        trainingIds.push(training.id)
    });

    this.props.fetchingData(true);
    this.props.updateTrainingsSet(trainingIds.join(','))
  };

  _changeSort = () => {
    this.setState({
      sort: !this.state.sort
    })
  };

  render() {
    const {fetchingData} = this.props.activity;
    const {translate, onClose, isVisible} = this.props;
    const {userData} = this.props.user;
    const {trainingsSet, currentType, sort, selectedTrainingsSet} = this.state;
    const {fontSizeRation} = this.props.settings;

    const sortableDiv = classNames({
      'd-none': !sort
    });

    return (
      <Modal
        className="trainings_set"
        show={isVisible}
        onHide={()=>onClose('trainingsSetModal',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="trainings_set"/>
          </Modal.Title>

        </Modal.Header>

        <Modal.Body style={{minHeight: 250}}>

          <div>
            <ButtonGroup size="sm" className="d-flex mb-3">
              <Button variant={currentType == 'basic' ? 'secondary' : "outline-secondary"}
                      onClick={()=>this._setPreset('basic')}>
                {translate('basic_trainings')}
              </Button>
              <Button variant={currentType == 'grammar' ? 'secondary' : "outline-secondary"}
                      onClick={()=>this._setPreset('grammar')}>
                {translate('grammar_trainings')}
              </Button>
              <Button variant={currentType == 'advanced' ? 'secondary' : "outline-secondary"}
                      onClick={()=>this._setPreset('advanced')}>
                {translate('advanced_trainings')}
              </Button>
              <Button variant={currentType == 'all' ? 'secondary' : "outline-secondary"}
                      onClick={()=>this._setPreset('all')}>
                {translate('all_trainings')}
              </Button>
            </ButtonGroup>

            <Card className="p-1 mb-3"

              // style={{fontSize: 12}}
            >
              <Form.Check
                checked={userData.active_trainings_set == 1}
                type="switch"
                id="active_set"
                className="mb-1"
                onChange={this._changeActiveSet}
                label={translate('activate_trainings_set')}
              />

              {
                userData.active_trainings_set == 1 && (
                  <Form.Check
                    checked={sort}
                    type="switch"
                    id="sort"
                    onChange={this._changeSort}
                    label={translate('sort_trainings_order')}
                  />
                )
              }
            </Card>


            <div className={sortableDiv}>
              <h6>{translate('drag_training_on_position')}</h6>
              <ReactSortable
                animation={200}
                delayOnTouchStart={true}
                touchStartThreshold={50}
                delay={50}
                onEnd={this._sortTrainingsSet}
                list={selectedTrainingsSet}
                setList={(newState, e) => this.setState({selectedTrainingsSet: newState})}
              >
                {selectedTrainingsSet.map(training => {
                  return <div key={training.id} className="sortable-element d-flex p-2 justify-content-start align-items-center mb-1 border">
                    <FontAwesomeIcon
                      style={{color: colors.tealish, marginRight: 15}}
                      icon={faCheckCircle}
                    />
                    <span className="text-dark">
                      {translate(training.name)}
                    </span>
                  </div>
                })}
              </ReactSortable>
            </div>

            {!sort && (
              <h6>{translate('select_trainings_you_want_to_use')}</h6>
            )}

            {
              !sort && trainingsSet.map(training => {
                return <div key={training.id} className="d-flex justify-content-start align-items-center mb-3">
                  <a
                    className="mr-3"
                    href="#" onClick={(e) => this._updateTrainingsSet(e, training)}>
                    <FontAwesomeIcon
                      style={{color: training.active ? colors.tealish : colors.lightGreyThree}}
                      icon={training.active ? faCheckCircle : faCircle}
                    />
                  </a>
                  <a className="text-dark" href="#" onClick={(e) => this._updateTrainingsSet(e, training)}>
                    {translate(training.name)}
                  </a>
                </div>
              })
            }
          </div>

        </Modal.Body>
        <Loader fetchingData={fetchingData}/>
      </Modal>
    )
      ;
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    updateTrainingsSet: (trainingsSet) => dispatch(updateTrainingsSet(trainingsSet)),
    activateTrainingsSet: () => dispatch(activateTrainingsSet()),
  };
}

export default connect(mapStateToProps, bindAction)(TrainingsSet);
