import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import moment from 'moment';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faCircle, faStar} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

import LogoBoy from "../../../assets/images/logo-boy-prepared.png";

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';



class ReviewModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tablet: false
    };
  }

  leftReview = (st) => {
    this.props.logActivity({activityId: 206, comment: st});

    localStorage.setItem("leftReview", "1");
    this.props.onClose(false);
  };

  notNow = () => {
    localStorage.setItem("notNow", moment().format('YYYY-MM-DD'));
    this.props.logActivity({activityId: 207});
  };
  
  render() {
    const {
      translate,
      isVisible,
      onClose,
    } = this.props;


    return (
      <Modal
        className="reviewmodal"
        show={isVisible}
        onHide={() => {
          this.notNow();
          onClose();
        }}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <Translate id="leave_your_review"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">
          <Image
            className="m-auto"
            style={{width: 120}}
            src={LogoBoy}
          />

          <p className="mt-3 desc">
            {translate('leave_review_description')}
          </p>

          <div className="d-flex justify-content-center icons mt-3">
            <a
              href="https://itunes.apple.com/app/id1438980777"
              target="_blank"
              onClick={() => this.leftReview('apple')}
            >
              <Image
                className="itunes"
                style={{height:50}}
                src={require('../../../assets/images/app-store-clear.png')}/>
            </a>

            <a
              href="http://play.google.com/store/apps/details?id=com.mnemonicrn"
              target="_blank"
              onClick={() => this.leftReview('android')}
            >
              <Image
                style={{height:50}}
                src={require('../../../assets/images/google-play-clear.png')}/>
            </a>
          </div>
          
          <Card className="p-2 mt-3">
            <span className="font-weight-light font-italic" style={{fontSize: 12}}>
              {translate('about_mistakes')}
            </span>
          </Card>

        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  settings: state.settings,
  activity: state.activity,

});

function bindAction(dispatch) {
  return {
    fetchingData: (status) => dispatch(fetchingData(status)),
    logActivity: (data) => dispatch(logActivity(data)),
  };
}

export default connect(mapStateToProps, bindAction)(ReviewModal);
