import React, {Component} from 'react';
import {connect} from "react-redux";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {withRouter, Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircle, faCogs, faCheckCircle,
  faTrashAlt,
  faCamera,
  faVolumeUp,
  faTimes, faSearch, faPlus
} from '@fortawesome/free-solid-svg-icons';

import ListWord from '../../components/Cells/ListWord/index';

import {Translate, getTranslate} from 'react-localize-redux';
import moment from 'moment';
import {toastr} from 'react-redux-toastr';

import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Card from 'react-bootstrap/Card';

import Loader from "../../components/Custom/Loader/index";
import Thinking from "../../assets/images/thinking-boy.png";
import LogoBoy from "../../assets/images/hello-boy.png";
import ListsToFill from '../../components/Modals/ListsToFill/index';

import {logActivity} from '../../actions/logActions';
import {getTranslationLanguages, changeTranslationLanguage} from '../../actions/userActions';
import {fetchingData} from '../../actions/activity';
import {getUserListsToFill, fillListFromGlossaryWithSelectedWords, getListsNumber} from '../../actions/lists';
import {getTestWord, logTestWord, getTestResults, resetTestWord, generateLists} from '../../actions/testActions';

import colors from '../../lib/colors';
import * as constants from '../../lib/constants';

class Wizard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0,
      locale: 'ru',
      level: 0,
      glossaryLevel: null,
      testLevel: null,
      currentIndex: 0,
      totalWords: 25,
      currentWordIndex: 0,
      scores: 0,
      correctIndex: null,
      selectedIndex: null,
      answered: false,
      mistakes: 0,
      selectedWords: [],
      setSelected: false,
      token: moment().format('YYYY-MM-DD HH:mm:ss'),
      glossaryId: false,
      showedAlert: false,
      glossaries: [
        [
          {name:"all_randomly", id: 1497},
          {name:"from_nouns", id: 1499},
          {name:"from_verbs", id: 1498},
          {name:"from_adjectives", id: 1500}
        ],
        [
          {name:"all_randomly", id: 1504},
          {name:"from_nouns", id: 1505},
          {name:"from_verbs", id: 1507},
          {name:"from_adjectives", id: 1506}
        ],
        [
          {name:"all_randomly", id: 1508},
          {name:"from_nouns", id: 1509},
          {name:"from_verbs", id: 1511},
          {name:"from_adjectives", id: 1510}
        ],
        [
          {name:"all_randomly", id: 1637},
          {name:"from_nouns", id: 1647},
          {name:"from_verbs", id: 1646},
          {name:"from_adjectives", id: 1648}
        ]
      ]
    };

    this.timeOut = null;
    this.timeOut1 = null;
    this.timeOut2 = null;
  }

  componentDidMount() {
    let wizard = localStorage.getItem(`wizard`);
    const {locale} = this.props.settings;

    if (wizard != null && wizard != undefined) {
      this.props.history.push(`/${locale}/home`)
    } else {
      this.setWizard();

      const currentLanguage = localStorage.getItem(`interfaceLanguage`);
      if (currentLanguage) {
        this.setState({
          locale: currentLanguage
        })
      }

      this.props.getTranslationLanguages();

    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {showedAlert, tabIndex} = this.state;
    const {translate} = this.props;

    if (tabIndex == 2 && !showedAlert) {
      toastr.light(translate('to_info'), translate('just_test'), {
        timeOut: 0
      });

      this.setState({
        showedAlert: true
      })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut1);
    clearTimeout(this.timeOut2);
  }

  nextStep = (status) => {

    if (status) {
      this._changeTab(1);

      this.props.logActivity({activityId: 66, comment: "from wizard"});

      /*if (locale != 'ru') {
       this.props.logActivity({activityId: 66, comment: "from wizard"});

       this.setState({
       tabIndex: 1
       });
       } else {
       this.setState({
       tabIndex: 2
       });
       this.props.logActivity({activityId: 161});
       }*/
    } else {
      this._changeTab(4);

      this.setState({
        testLevel: -1
      });

      this.props.logActivity({activityId: 162, comment: 'refused from test'});
    }
  };


  _changeTab = (index) => {
    this.setState({
      tabIndex: index
    });
  };

  setWizard = () => {
    localStorage.setItem('wizard', "true");
  };

  // LANGUAGE
  _changeTranslationLanguage = (language) => {
    this.props.logActivity({activityId: 201, comment: language.code});
    this.props.fetchingData(true);

    this.props.changeTranslationLanguage(language);

    this._changeTab(2);

    this.timeOut = setTimeout(() => {
      this._getTestWord();
    }, 250);
  };

  // TEST
  _getTestWord = () => {
    const {token, currentWordIndex, totalWords, mistakes} = this.state;

    this.props.resetTestWord();

    this.setCorrectIndex();

    this.setState({
      selectedIndex: null,
      answered: false
    });

    if (currentWordIndex < totalWords) {
      this.props.getTestWord(
        currentWordIndex,
        token
      );
    } else {
      if (mistakes === 0) {
        this.props.fetchingData(true);
        this.props.getTestResults(token);
        this._changeTab(4);

      } else {
        this._changeTab(3);
        this._toTestResults();
      }
    }
  };

  _toTestResults = () => {
    const {token, currentWordIndex} = this.state;
    const {isConnected} = this.props.settings;

    this.props.fetchingData(true);
    this.props.getTestResults(token);
    this.props.getUserListsToFill();
    this.props.getListsNumber();

    this.props.logActivity({activityId: 141});

    /*this.props.navigation.navigate('WordTestResults', {
     token,
     totalWords: 25,
     finished: currentWordIndex !== 0,
     from: 'wizard'
     })*/
  };

  setCorrectIndex = () => {
    let n = Math.floor(Math.random() * 4);

    this.setState({
      correctIndex: n
    });
  };


  exit = () => {
    const {currentWordIndex, token, mistakes} = this.state;

    if (mistakes == 0) {
      if (currentWordIndex !== 0) {
        this.props.fetchingData(true);
        this.props.getTestResults(token);
      } else {
        this.setState({
          testLevel: -1
        })
      }

      this._changeTab(4);

      this.props.logActivity({activityId: 163});

    } else {
      this._changeTab(3);

      this._toTestResults();
    }
  };

  checkAnswer = (i) => {
    const {correctIndex, currentWordIndex, scores, token} = this.state;
    const {logTestWord} = this.props;
    const {word} = this.props.test;

    if (word) {
      this.setState({
        selectedIndex: i,
        currentWordIndex: currentWordIndex + 1,
        answered: true
      });

      let result = false;

      if (i === correctIndex) {
        result = true;

        this.setState({
          scores: scores + 5
        });
      } else {
        this.setState({
          mistakes: this.state.mistakes + 1
        })
      }

      logTestWord(word.id, token, result, word.level);

      this.timeOut1 = setTimeout(() => {
        this._getTestWord();
      }, 500);
    }
  };


  dontKnowPressed = () => {
    const {token, currentWordIndex, totalWords} = this.state;
    const {logTestWord} = this.props;
    const {word} = this.props.test;

    if (word) {
      this.setState({
        currentWordIndex: currentWordIndex + 1,
        answered: true
      });

      this.setState({
        mistakes: this.state.mistakes + 1
      });

      logTestWord(word.id, token, false, word.level);

      this.timeOut2 = setTimeout(() => {
        this._getTestWord();
      }, 500)
    }
  };

  renderButton = (ind) => {
    const {word} = this.props.test;
    const {selectedIndex, correctIndex, answered} = this.state;
    const {deviceWidth} = this.props.settings;

    if (!word) return;

    const translation = word.translation;

    if (selectedIndex !== null && selectedIndex === ind) {
      return <Button
        variant={selectedIndex === correctIndex ? 'success' : 'danger'}
        key={ind}
        disabled
        onClick={() =>  null}
        style={{width: deviceWidth > 400 ? 400 : deviceWidth - 40,marginBottom: 15}}
      >
        {word && correctIndex === ind ? translation : word.other_translations && word.other_translations.length > 0 && word.other_translations[ind] ? word.other_translations[ind].translation : ""}
      </Button>
    } else {
      return <Button
        variant="outline-secondary"
        key={ind}
        style={{width: deviceWidth > 400 ? 400 : deviceWidth - 40,marginBottom: 15}}
        disabled={answered}
        onClick={() => {
          this.checkAnswer(ind)
      }}>
        {word && correctIndex === ind ? translation : word.other_translations && word.other_translations.length > 0 && word.other_translations[ind] ? word.other_translations[ind].translation : ""}
      </Button>
    }
  };

  // GENERATOR
  levelSelected = (level, e) => {
    e.preventDefault();
    this.setState({
      glossaryLevel: level
    });

    this._changeTab(5);

    this.props.logActivity({activityId: 168, comment: `${level}`});
  };
  
  setGlossary = (id, e) => {
    e.preventDefault();

    this.setState({
      glossaryId: id
    });

    this.generateWords(id);
  };

  generateWords = (glossaryId) => {
    const {level} = this.state;
    this.props.fetchingData(true);

    this.props.generateLists(50, 10, glossaryId, level, false);

    this.props.logActivity({activityId: 165, comment: "100"});
    this._changeTab(6);
  };

  toggleListsToFillModal = (status) => {
    this.props.logActivity({activityId: 18});

    this.setState({
      listsModalIsVisible: status
    })
  };

  _onPressWord = (word) => {
    let words = this.state.selectedWords;

    const index = words.findIndex(id => id === word.id);

    if (index === -1) {
      words.push(word.id);
    } else {
      words.splice(index, 1);
    }

    this.setState({
      setSelected: !this.state.setSelected,
      selectedWords: words
    });
  };

  _renderItem = (item, index) => {
    const {fontSizeRation, version} = this.props.settings;
    const {tablet, selectedWords} = this.state;
    const {translate} = this.props;
    const imageUrl = item && item.image && item.image.filename ? item.image.filename : false;
    const {userData, userId} = this.props.user;
    const imageUserId = item && item.image && item.image.user_id ? item.image.user_id : false;

    let selected = selectedWords.indexOf(item.id);

    let circleColor = colors.greyishBrown;

    const translation = item && item.translation ? item.translation : "";

    let audio = item && item.audio ? item.audio : false;
    let audioSource = constants.BACKEND_URL;

    if (userData && userData.audio_language && item[userData.audio_language]) {
      audio = item[userData.audio_language];
      audioSource = constants.S3
    }

    const imageWidth = !tablet ? 150 : 200;
    const wordName = item && item.word ? item.word : "";
    const transcription = item && item.transcription ? item.transcription : "";

    return (
      <ListWord
        key={index}

        wordName={wordName}
        transcription={transcription}
        imageWidth={imageWidth}
        translation={translation}
        audio={audio}
        audioSource={audioSource}
        userData={userData}
        word={item}
        index={index}
        imageUrl={imageUrl}
        imageUserId={imageUserId}
        userId={userId}
        inVocabulary={item.in_vocabulary}
        inFavorite={item.is_favourite}
        tablet={tablet}
        version={version}
        ratio={fontSizeRation}
        onPlay={this.playTrack}
        onPressWord={this._onPressWord}
        bulkMode={true}
        circleColor={circleColor}
        selected={selected !== -1}
        translate={translate}
        favoriteWordToggle={false}
        vocabularyToggle={false}
        bulkMode={'move'}

        test={true}
      />
    )
  };

  toggleSelected = (e) => {
    e.preventDefault();

    const {selectedWords} = this.state;
    const {result} = this.props.test;

    if (selectedWords.length > 0) {
      this.setState({
        selectedWords: []
      })
    } else {
      let selected = result.words.map((item)=> {
        return item.id
      });

      this.setState({
        selectedWords: selected
      })
    }
  };

  fillLists = (id) => {
    const {translate} = this.props;

    this.toggleListsToFillModal(false);

    this.props.logActivity({activityId: id != 0 ? 19 : 20, model: 'list', modelId: id});

    this.props.fetchingData(true);
    this.props.fillListFromGlossaryWithSelectedWords({
      listId: id,
      glossaryId: 0,
      glossaryName: translate('vocabulary_test'),
      selectedWords: this.state.selectedWords
    });

    this.setState({
      selectedWords: []
    });
    this._changeTab(4)
  };

  _cancelSave = () => {
    this._changeTab(4)
  };

  render() {
    const {fontSizeRation, isConnected, deviceHeight, deviceWidth, locale} = this.props.settings;
    const {translationLanguages, userData} = this.props.user;
    const {fetchingData} = this.props.activity;
    const {
      currentWordIndex,
      totalWords, answered, tabIndex, testLevel, selectedWords,
      tablet, listsModalIsVisible,
      glossaries,
      glossaryId,
      glossaryLevel
    } = this.state;
    const {listsToFill} = this.props.lists;

    const {word, result} = this.props.test;
    const progress = totalWords !== 0 ? ((currentWordIndex + 1) / totalWords) : 0;

    let level = testLevel;

    const listSize = userData ? userData.list_size : 10;

    const {translate} = this.props;

    const buttons = [
      this.renderButton(0),
      this.renderButton(1),
      this.renderButton(2),
      this.renderButton(3)
    ];

    let levelText = "";
    let resultText = "";
    let resultLevel = '';
    let pageText = translate('select_your_level_wizard');
    let words = result ? result.words : [];
    let scores = result ? result.scores : null;
    let testResultText = translate('test_results_level');

    if (result) {
      switch (result.index) {
        case 0:
          level = 0;
          levelText = "Beginner";
          resultText = "could_be_better";
          testResultText = "could_be_better";

          break;
        case 1:
          level = 1;
          levelText = "Elementary";
          resultText = "not_bad";
          testResultText = "not_bad";

          break;
        case 2:
          level = 2;
          levelText = "Intermediate";
          resultText = "very_well";
          testResultText = "very_well";
          break;
        case 3:
          level = 3;
          levelText = "Advanced";
          resultText = "amazing_results";
          testResultText = "amazing_results";
          break;
        case -1:
          levelText = "";
          resultText = "refused_from_test";
          testResultText = "refused_from_test";
          break;
        case -2:
          levelText = "";
          resultText = "";
          break;
        default:
          console.log("default")
      }

      resultLevel = `${translate('reccomend_to_start_from_level')} ${levelText}`;
      // pageText = pageText.replace('%@', levelText)
    }

    if (level && level === -1) {
      resultText = 'refused_from_test';
      // pageText = translate('select_your_level_wizard')
    } else if (level && level === -2) {
      // pageText = translate('select_your_level_wizard')
    }

    let text = translate('learning_process_description');

    text = text.replace('%1', `${50}`);
    text = text.replace('%2', `${10}`);

    testResultText = testResultText.replace('%@', `${levelText}`);

    return (
      <div className="wizard">

        {
          tabIndex == 2 && deviceWidth > 400 && (
            <Image
              className="thinkingBoy"
              src={Thinking}
            />
          )
        }

        <Container style={{marginTop: 70}} className="pageWrapper d-flex flex-center">
          <Tabs className="m-auto" style={{maxWidth: tabIndex != 3 ? 400 : '100%', width: tabIndex != 3 ? 400 : '100%'}}
                selectedIndex={tabIndex}
                onSelect={index => this._changeTab(index)}>
            {<TabList className="d-none">
              <Tab>First</Tab>
              <Tab>Translation</Tab>
              <Tab>Test</Tab>
              <Tab>TestResults</Tab>
              <Tab>Level</Tab>
              <Tab>Glossaries</Tab>
              <Tab>Good</Tab>
            </TabList>}

            <TabPanel className="mt-4">
              <div className="d-flex flex-column w-100">
                <Image
                  className="m-auto"
                  style={{width: 120}}
                  src={LogoBoy}
                />
                <div>
                  <h6 className="text-center font-weight-bold mb-1 mt-3">
                    {translate('offer_to_past_test')}
                  </h6>
                  <p className="text-center font-weight-light font-italic mb-3">
                    {translate('offer_to_past_test_description')}
                  </p>
                </div>

                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    size="lg"
                    className="mr-3"
                    style={{width: 200}}
                    variant="outline-success"
                    onClick={() => this.nextStep(true)}
                  >
                    {translate('yes')}
                  </Button>

                  <Button
                    style={{width: 200}}
                    size="lg"
                    variant="outline-secondary"
                    onClick={() => this.nextStep(false)}
                  >
                    {translate('no')}
                  </Button>
                </div>
              </div>
            </TabPanel>

            <TabPanel className="text-center">
              <div>
                <h6 className="text-center font-weight-bold mb-1 mt-3">
                  {translate('select_user_translation')}
                </h6>
                <p className="text-center font-weight-light font-italic mb-3">
                  {translate('select_user_translation_description')}
                </p>
              </div>
              {
                translationLanguages.map((item, i) => {
                  return (
                    <Button
                      key={i}
                      block
                      variant="outline-info"
                      onClick={()=>{
                         this._changeTranslationLanguage(item.code);
                      }}
                    >
                      {translate(item.language)}
                    </Button>
                  )
                })
              }
            </TabPanel>

            <TabPanel>
              <div className="test">

                <a href="#" className="d-block text-right text-muted" onClick={(e)=>{
                  e.preventDefault();
                  this.exit();

                }}>
                  {translate('finish')}
                </a>

                <div className="testWordWrapper">
                  <p className="testWord"
                     style={{fontSize: fontSizeRation * 24}}>
                    {word ? word.word : ""}
                  </p>
                </div>

                <div className="barWrapper">
                  <ProgressBar
                    style={{width: '100%'}}
                    variant="success"
                    now={progress*100}
                  />
                </div>

                <div className="answerButtonsWrapper">
                  <div style={{height: deviceHeight < 700 ? 230 : 285, width:'100%'}}>
                    {
                      <div className="answerButtons" style={{flex: 1, textAlign: 'center'}}>
                        {buttons}
                      </div>
                    }
                  </div>
                  {
                    !answered && word && isConnected && (
                      <Button
                        style={{width: deviceWidth > 400 ? 400 : deviceWidth - 40,marginBottom: 15}}
                        variant="outline-danger"
                        onClick={this.dontKnowPressed}
                      >
                        {translate('dont_know')}
                      </Button>
                    )
                  }
                </div>
              </div>

            </TabPanel>

            <TabPanel className="results">
              {result && <div>
                <p className="correctAnswers mb-1">
                  {translate("know")}{" "}{result ? result.total_correct : 0}/{totalWords}{" "}{translate("lwords")}
                </p>

                <div className="mb-2">
                  <p className="text-center mb-0">{translate('earnedScores')}{" "}{scores} {translate('scores')}</p>
                </div>

                {/*<p className="levelDescription" style={{fontSize: fontSizeRation * 16, marginVertical: !tablet ? 5 : 10}}>
                  {testResultText}
                </p>*/}
              </div>}

              {
                result && result.words.length > 0 && (
                  <Card className="deleteListsTextWrapper">
                  <span className="bulkModeText font-weight-bold" style={{fontSize: fontSizeRation * 16}}>
                    {translate(`words_could_learn`)}
                  </span>
                    <div
                      className="bulkModeWrapper justify-content-between align-items-center flex-md-row flex-sm-column flex-column"
                      style={{marginTop: 10}}>

                      <a href="#"
                         className="mr-3 mb-0 mb-sm-2 mb-md-0"
                         onClick={this.toggleSelected}>
                        <FontAwesomeIcon
                          className="selectedIcon"
                          icon={selectedWords.length > 0 ? faCheckCircle : faCircle}
                          style={{
                      color: selectedWords.length > 0 ? colors.tealish : colors.lightGreyTwo,
                      marginRight: !tablet ? 10 : 20, fontSize: !tablet ? 20 : 30}}/>
                    <span
                      className="selectAllWords"
                      style={{marginRight: 10, color: colors.greyishBrown, fontSize: fontSizeRation * 16}}>
                      {selectedWords.length > 0 ? translate('deselect_words') : translate('select_all_words')}
                    </span>
                      </a>

                      <span className="mr-2 mr-2">{translate('selected_words')} {selectedWords.length}</span>

                      <Button
                        disabled={selectedWords.length == 0}
                        variant="outline-info"
                        className="mr-3 mb-0 mb-sm-2 mb-md-0"

                        onClick={()=> {
                      if(selectedWords.length > 0) {
                          this.toggleListsToFillModal(true)
                        }
                    }}>

                        {translate('add_words_to_list')}
                      </Button>

                      <Button
                        variant="outline-secondary"
                        className="mr-3 mb-0 mb-sm-2 mb-md-0"
                        onClick={this._cancelSave}>

                        {translate('cancel')}
                      </Button>
                    </div>
                  </Card>
                )
              }

              <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
                {
                  words.map((list, index) => {
                    return this._renderItem(list, index);
                  })
                }
              </div>
            </TabPanel>

            <TabPanel>
              <div className="leveloptions">

                <div className="mb-3">
                  {
                    resultText && false && (
                      <p className="mb-1" style={{fontSize: fontSizeRation * 20, color: colors.tealish}}>
                        {translate(resultText)}
                      </p>
                    )
                  }

                  {
                    resultLevel && (
                      <p className="mb-1 font-weight-bold" style={{fontSize: fontSizeRation * 16, color: colors.tealish}}>
                        {resultLevel}
                      </p>
                    )
                  }
                </div>

                <p className="mb-3 text-center" style={{fontSize: fontSizeRation * 16}}>
                  {pageText}
                </p>

                <div className="levelsWrapper">
                  <div className="levelsWrapperRow" style={{maxHeight: 300}}>
                    <a href="#"
                       onClick={(e)=>this.levelSelected(0, e)}>
                      <Card
                        className="levelItem"
                        style={{marginRight: 20, borderColor: level === 0 ? colors.tealish : colors.lightGrey}}
                      >
                        <Image
                          className="itemImage1"
                          src={require("../../assets/images/test-beginner.png")}
                        />
                        <span className="levelText">{translate('level_basic_w')}</span>
                        <span className="levelNumbers">{translate('words_0_1000')}</span>
                      </Card>
                    </a>

                    <a href="#" onClick={(e)=>this.levelSelected(1, e)}>
                      <Card
                        className="levelItem"
                        style={{borderColor: level === 1 ? colors.tealish : colors.lightGrey}}
                      >
                        <Image
                          className="itemImage"
                          src={require("../../assets/images/test-elementary.png")}
                        />
                        <span className="levelText">{translate('level_elementary_w')}</span>
                        <span className="levelNumbers">{translate('words_1000_2000')}</span>
                      </Card>
                    </a>
                  </div>

                  <div className="levelsWrapperRow" style={{maxHeight: 300}}>
                    <a href="#" onClick={(e)=>this.levelSelected(2, e)}>
                      <Card
                        className="levelItem"
                        style={{marginRight: 20, borderColor: level === 2 ? colors.tealish : colors.lightGrey}}
                      >
                        <Image
                          className="itemImage"
                          src={require("../../assets/images/test-intermediate.png")}
                        />
                        <span className="levelText">{translate('level_intermediate_w')}</span>
                        <span className="levelNumbers">{translate('words_2000_4000')}</span>
                      </Card>
                    </a>

                    <a hre="#" onClick={(e)=>this.levelSelected(3, e)}>
                      <Card
                        className="levelItem"
                        style={{borderColor: level === 3 ? colors.tealish : colors.lightGrey}}
                      >
                        <Image
                          className="itemImage"
                          src={require("../../assets/images/test-advanced.png")}
                        />
                        <span className="levelText">{translate('level_advanced_w')}</span>
                        <span className="levelNumbers">{translate('words_4000_')}</span>
                      </Card>
                    </a>
                  </div>
                </div>

                <a href="#" onClick={(e)=> {
                    e.preventDefault();
                    this.props.history.push(`/${locale}/home`);
                    this.props.logActivity({activityId: 165, comment: "0"});
                  }}
                >
                  <div className="choiceButtonItemOnMy">
                    <span className="choiceButtonOnMyOwn" style={{fontSize: fontSizeRation * 16}}>
                      {translate('thanks_dont_need')}
                    </span>
                  </div>
                </a>
              </div>
            </TabPanel>
            
            <TabPanel>
              <div className="selectwords contentWrapper">
                <p className="howManyLists" style={{fontSize: fontSizeRation * 18}}>
                  {translate('from_what_glossary')}
                </p>

                <div className="choiceWrapper">
                  {
                    glossaryLevel != null && glossaries[glossaryLevel].map((glossary, i) => {
                      return (
                        <a href="#"
                           key={i}
                           onClick={(e) => this.setGlossary(glossary.id, e)}>
                          <div className="choiceButtonItem wider">
                            <span className="choiceButtonItemText" style={{fontSize: fontSizeRation * 16}}>
                              {translate(glossary.name)}
                            </span>
                          </div>
                        </a>
                      )
                    })
                  }
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <p className="goodword">
                {text}
              </p>

              <Link to={`/${locale}/home`} className="mt-4 btn btn-outline-success m-auto d-block" style={{width: 200}}>
                {translate('ok')}
              </Link>
            </TabPanel>
          </Tabs>
        </Container>
        {listsModalIsVisible && (
          <ListsToFill
            isVisible={listsModalIsVisible}
            lists={listsToFill}
            fillLists={this.fillLists}
            listSize={listSize}
            ratio={fontSizeRation}
            selectedWords={selectedWords.length}
            onClose={this.toggleListsToFillModal}
            translate={translate}

          />
        )}
        <Loader fetchingData={fetchingData}/>

      </div>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  settings: state.settings,
  translate: getTranslate(state.localize),
  user: state.user,
  activity: state.activity,
  test: state.test,
  lists: state.lists,

});

function bindAction(dispatch) {
  return {
    getTranslationLanguages: () => dispatch(getTranslationLanguages()),
    changeTranslationLanguage: (translationLanguage) => dispatch(changeTranslationLanguage(translationLanguage)),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    getTestWord: (currentWordIndex, token) => dispatch(getTestWord(currentWordIndex, token)),
    logTestWord: (wordId, token, result, level) => dispatch(logTestWord(wordId, token, result, level)),
    resetTestWord: () => dispatch(resetTestWord()),
    getTestResults: (token) => dispatch(getTestResults(token)),
    generateLists: (words, listSize, glossaryId, level, checkInLists) => dispatch(generateLists(words, listSize, glossaryId, level, checkInLists)),
    fillListFromGlossaryWithSelectedWords: (data) => dispatch(fillListFromGlossaryWithSelectedWords(data)),
    getListsNumber: () => dispatch(getListsNumber()),
    getUserListsToFill: () => dispatch(getUserListsToFill()),

  };
}

export default connect(mapStateToProps, bindAction)(withRouter(Wizard));
