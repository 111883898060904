import * as types from '../actions/types';

const INITIAL_STATE = {
  phrasebooks: [],
  sections: [],
  totalPhrasebooks: 0,
  totalPhrases: 0,
  currentPhrasebook: false,
  phrasebookPhrases: [],
  foundPhrases: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_PHRASEBOOKS: {
      let sections = [];
      let totalPhrasebooks = 0;
      let totalPhrases = 0;

      action.payload.data.map((item, i) => {
        sections.push({title: item.phrasebook_name, data : item.children, index: i})
        totalPhrasebooks += item.children_number;
        item.children.map((item)=> {
          totalPhrases += item.phrases
        })
      });

      return Object.assign({}, state, {
        phrasebooks: [],
        sections,
        totalPhrasebooks,
        totalPhrases
      });
    }


    case types.SET_PHRASEBOOK_PHRASES:
      return Object.assign({}, state, {
        phrasebookPhrases: action.payload.data,
      });

    case types.UPDATE_FAVORITE_PHRASE:{
      let phrasebookPhrases = state.phrasebookPhrases.map(phrase => {
        if(phrase.id == action.payload.phraseId) {
          phrase.in_favorite = !phrase.in_favorite;
        }

        return phrase;
      })

      let foundPhrases = state.foundPhrases.map(phrase => {
        if(phrase.id == action.payload.phraseId) {
          phrase.in_favorite = !phrase.in_favorite;
        }

        return phrase;
      })

      return Object.assign({}, state, {
        phrasebookPhrases,
        foundPhrases,
      });
    }
    
    case types.SET_FOUND_PHRASES:
      return Object.assign({}, state, {
        foundPhrases: action.payload.data,
      });

    case types.RESET_FOUND_PHRASES:
      return Object.assign({}, state, {
        foundPhrases: [],
      });

    case types.RESET_PHRASES:
      return Object.assign({}, state, {
        phrasebookPhrases: [],
      });
    
    case types.SET_CURRENT_PHRASEBOOK: {
      return Object.assign({}, state, {
        currentPhrasebook: action.payload,
      });
    }

    default:
      return state;
  }
}