import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCogs,
  faFilter,
  faTimes,
  faSearch,
  faPlus,
  faSort,
  faBars
} from '@fortawesome/free-solid-svg-icons';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import MyPagination from '../../../components/Custom/MyPagination/index';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const VocabularyToolbar = ({
  translate,
  ratio,
  handleKeyPress,
  toolbar,
  active,
  tablet,
  deviceHeight,
  deviceWidth,

  typedWord,

  isVisible,
  onClose,

  toVideo,
  lifetimeStatus,

  toBulkMode,
  bulkMode,

  toggleTrainingButtons,
  totalPages,
  page,
  currentPage,
  pageClicked,
  listSize,
  words,

  typeVocabularyWord,

  goal,
  goalRef,
  setGoal,
  setUserGoal,
  course,
  moveToVocabulary,
  userData
}) => (

  <Container fluid>
    <div className={toolbar}>
      <div className="vocabularytoolbar justify-content-between align-items-center flex-md-row flex-column d-flex">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center search-wrapper">
          {!course && <InputGroup className="mt-md-0 mt-2 vocabulary-input mb-md-0 mb-2 mr-0 mr-md-3">
            <FormControl
              onKeyPress={(e)=>handleKeyPress('search', e)}
              value={typedWord}
              onChange={(e)=>typeVocabularyWord(e.target.value)}
              placeholder={translate('search_word')}
            />
            <InputGroup.Append>
              <Button
                disabled={typedWord.length === 0}
                variant="outline-secondary" onClick={(e)=>typeVocabularyWord('')}>
                <FontAwesomeIcon icon={faTimes}/>
              </Button>
            </InputGroup.Append>
          </InputGroup>}

          {typedWord.length == 0 && (
            <div className="mb-md-0 mb-2">
              <MyPagination
                range={5}
                pages={totalPages}
                ratio={ratio}
                currentPage={currentPage}
                tablet={tablet}
                onClick={pageClicked}
              />
            </div>
          ) || null}
        </div>

        <div className="d-flex justify-content-between align-items-center">
          {
            words > 0 && !bulkMode && (
              <OverlayTrigger
                delay={{ show: 1000, hide: 400 }}
                placement='top'
                overlay={<Tooltip>{listSize} {translate('random_words_')}</Tooltip>}
              >
                <Button
                  className="mr-3"
                  style={{maxWidth: 200}}
                  variant="outline-success"
                  onClick={() => toggleTrainingButtons(true)}
                >
                  {translate('repeat')}
                </Button>
              </OverlayTrigger>
            )
          }

          {!course && <Dropdown alignRight className="mr-3">
            <Dropdown.Toggle
              variant="outline-secondary">
              <span>{translate('set_new_goal')}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <InputGroup className="ph1">
                <FormControl
                  ref={goalRef}
                  autoFocus
                  style={{fontSize: 12}}
                  onKeyPress={(e)=> handleKeyPress('goal', e)}
                  value={goal}
                  onChange={(e)=> setGoal(e.target.value.replace(/[^0-9]/g,''))}
                  placeholder={translate('how_many_words')}
                />
                <InputGroup.Append>
                  <Button
                    style={{fontSize: 12}}
                    disabled={goal.length == 0}
                    variant="outline-secondary"
                    onClick={()=>setUserGoal(goal)}>
                    <FontAwesomeIcon icon={faPlus}/>
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Dropdown.Menu>
          </Dropdown>}

          <Dropdown alignRight>
            <OverlayTrigger
              delay={{ show: 1000, hide: 400 }}
              placement='top'
              overlay={<Tooltip>{translate('manage_words')}</Tooltip>}
            >
              <Dropdown.Toggle
                as="a"
                className="p2 toggled-link"
                variant="outline-secondary">
                <FontAwesomeIcon icon={faCogs}/>
              </Dropdown.Toggle>
            </OverlayTrigger>

            <Dropdown.Menu>

              {words > 0 && course && (
                <Dropdown.Item as="button" onClick={()=>moveToVocabulary()}>
                  {translate('move_to_vocabulary')}
                </Dropdown.Item>
              )}

              {words > 0 && !course && (
                <Dropdown.Item as="button" onClick={()=>toBulkMode('favorite')}>
                  {translate('list_words_to_favorite')}
                </Dropdown.Item>
              )}


              {words > 0 && !course && (
                <Dropdown.Item as="button" onClick={()=>toBulkMode('move')}>
                  {translate('list_words_to_move')}
                </Dropdown.Item>
              )}

              <Dropdown.Divider/>

              {words > 0 && (
                <Dropdown.Item as="button" className="text-danger" onClick={()=>toBulkMode('delete')}>
                  {translate('delete_some_words')}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  </Container>
);

export default VocabularyToolbar;
