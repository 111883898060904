import {takeLatest, all} from 'redux-saga/effects'
import * as types from '../../actions/types';
import {register, checkUser, login, sendLink, resendActivationLink, updateUserToken} from './auth';
import {
  getListsToRepeat, fillListFromGlossaryWithSelectedWords,
  getUserListsToFill, editList, addList, addNewList, deleteList,
  getListsByLang,
  searchUserLists,
  getListWords,
  getCurrentListData,
  deleteListWord, deleteListFromList,
  toggleInVocabulary, addListWordToFavorites, toggleFavouriteList,
  toggleWordInUserList,
  postponeInCalendar,
  removeFromCalendar,
  getListsNumber,
  generateListsNotWizard,
  addUserWords,
  sendListCards,
  deleteSelectedLists,
  getOnlyMistakes,
  toggleArchiveList,
  saveScannedWords,
  performBulkAction,
  moveToOtherLists,
  addCourseWordToList
} from './lists';

import {getWordsForTraining, getGoogleExampleTranslation, addUserExampleTranslation} from './trainings';
import {getEverydayPhrase, getEverydayWord, addEverydayToList} from './everydaySagas';

import {
  getCourseData, generateCourseLists, getCourseListWords,
  getCourseListData, getCourseListCalendar, deleteCourseData,
  getCourseCalendarMonthData, getCourseStatistics, removeCourseListFromCalendar,
  getCourseVocabulary,
  checkForCourseDebts,
  rateCourse,
  getInitialCourseData,
  getCoursesMistakesTestsRepeats,
  getCourseListStatistics,
  getUpdatedCourseLists,
  getUpdatedCourseData,
  getUpdatedListScores,
  getAllCourseMistakes,
  toggleInCourseVocabulary,
  deleteFromCourseVocabulary,
  deleteCourseWord,
  getJustCourseData,
  getCurrentCourseListData,
  moveToGeneralVocabulary,
  performCourseBulkAction
} from './courseSagas';

import {getCalendarDayData, getCalendarMonthData,} from './calendarSagas';

import {searchWordsForList, search_words, findWordInDictionary} from './dictionary';
import {getUserContacts, updateUserContact, deleteUserContact, createUserContact} from './userContactsSagas';
import {
  saveSharedList,
  toggleInFavoriteSharedWord, toggleInVocabularySharedWord,
  deleteSharedGlossary, deleteSharedList, deleteSharedWord, shareGlossary, shareList, shareWord, getSharedWords,
  getSharedLists, getSharedNumbers, getSharedGlossaries
} from './sharedSagas';

import {
  getRegistrationDate, checkWebSubscriptionStatus,
  getUserLimits, logTrainingLimits, logWordLimits, logGamesLimits, usePromoCode,
  checkForHappyDay, exchangeScores,
  getPurchaseData,
  getUserPurchases,
  cancelSubscription,
  checkDiscountCode,
  getCurrencyExchangeRate,
  getLiqpaySubscriptionStatus
} from './purchasesSagas';

import {getUserBadges} from './badgesSagas';
import {getUserNews, manageNews, getUnreadNews, getArticle} from './newsSagas';

import {
  update_profile,
  change_notification_status,
  change_notification_time,
  contact_us,
  sendErrorMessage,
  update_avatar,
  getUserScores,
  getUserCountry,
  getAssetsSource,
  getUserAppProfile,
  getUserAppProfileById,
  sync_data,
  change_list_size,
  change_password,
  resetPassword,
  getStudyLanguages,
  change_translation_language,
  changeInterfaceLanguage,
  changeTranslationLanguage,
  getTranslationLanguages,
  change_study_language,
  getSlides,
  getUserData,
  changeAudioLanguage,
  changeWordLevel,
  contactAboutRegistration,
  toggleAutoPostpone,
  toggleCoursePostpone,
  navigateToSegue,
  toggleShuffleWords,
  addToFrauds,
  changeVocabularyLevel,
  toggleShowLearntWords,
  toggleShowLearntCourseWords,
  toggleShowArchiveLists,
  updateTrainingsSet,
  activateTrainingsSet,
  deleteAccount
} from './user';

import {
  checkDictionaryWord,
  logWordExtractorResults,
  getTestWord,
  logTestWord,
  getTestResult,
  createListsFromTests,
  generateLists,
  getHangmanScores,
  getHangmanWord,
  logHangmanWord,
} from './test';

import {getVocabulary, setGoal, deleteFromVocabulary, toggleFavoriteWord} from './vocabulary';

import {getGroupedPhrasebooks, getPhrases, searchPhrases, toggleFavoritePhrase} from './phrasebooksSagas';

import {
  getListsByLangF,
  getFavoriteWords,
  getFavoritePhrases,
  deleteFromFavoriteWords,
  deleteFromFavoritePhrases,
  get_favourite_glossaries,
  getAllFavoriteNumbers
} from './favoritesSagas';
import {
  getParentGlossaries,
  getParentChildren,
  getGlossary,
  toggleGlossaryWordInVocabulary,
  toggleGlossaryWordInFavorites,
  toggleGlossaryInFavorites,
  get_glossaries_for_list,
  getGlossaryWordsForList,
  sendCards,
  searchWordsInThisGlossary,
  searchGlossaries,
  searchChildGlossaries,
  searchWordsInThisGlossaryForList,
  getCurrentGlossaryData,
} from './glossaries';

import {getConnectedAccounts, deleteConnected, createChildAccount, checkPasswordToConnect} from './accountsSagas';
import {
  get_word_details, getWordToOpen, toggleInVocabularyDetails, toggleInFavoriteWordDetails,
  get_extended_info, addUserTranslation, changeMainTranslation,
  deleteUserTranslation, deleteUserImage, addWordAssociation,
  deleteUserAssociation, addUserExample
} from './wordSagas';
import {
  logListWord, logCourseList, logCourseListWord,
  logApiError,
  logUserList, 
  logPhrase,
  logGlossaryWord, 
  logScores,
  logActivity,
  logSharedLinkVisit,
  logPurchase,
  logLinksPage,

} from './logSagas';
import {syncUserLists} from './syncSagas';
import {getIrregularVerbs, logIrregularVerb} from './quizSagas';
import {getTags, connectTag, createTag, deleteTag, disconnectTag, getConnectedTags} from './tagsSagas';
import {
  getWordMakerWords, getWordMakerParts, logWordMakerPart, logBuyWordMakerWord,
  openWordMakerLevel, buyWordMakerHints, getWordMakerData
} from './wordMakerSagas';
import {
  getTourGlossaries,
  getTourGlossary,
  getTourWord,
  getTourLists,
  getTourListWords,
  getTourCourse,
  getTourCourseListWords,
  getTourWordsForTraining,
  getTourCourseCalendarMonthData
} from './tourSagas';

import {
  getActiveMultiWordMakerGames,
  createWordMakerMultiGame,
  cancelMultiWordMakerGame,
  joinMultiWordMakerGame,
  exitMultiWordMakerGame,
  startMultiWordMakerGame,
  getCurrentActiveWordMakerGame,
  logMultiWordMakerResults,
  getMultiWordMakerResults,
  makeMultiWordMakerGameInactive,
  inviteUsersToMultiMaker,
  getTotalRating
} from './multiWordMakerSagas'
import {getHelperArticles, getHelperArticle, getHelperArticleBySlug} from './helperArticlesSagas';

export default function* apiWatcher() {
  yield all([
    takeLatest(types.LOGIN, login),

    /***** LOGS *****/
    takeLatest(types.LOG_LIST_WORD, logListWord),
    takeLatest(types.LOG_PHRASE, logPhrase),
    takeLatest(types.LOG_USER_LIST, logUserList),
    takeLatest(types.LOG_GLOSSARY_WORD, logGlossaryWord),
    takeLatest(types.LOG_SCORES, logScores),
    takeLatest(types.LOG_ACTIVITY, logActivity),
    takeLatest(types.LOG_SHARED_LINK_VISIT, logSharedLinkVisit),
    takeLatest(types.LOG_PURCHASE, logPurchase),
    takeLatest(types.LOG_COURSE_LIST, logCourseList),
    takeLatest(types.LOG_COURSE_LIST_WORD, logCourseListWord),
    takeLatest(types.LOG_API_ERROR, logApiError),
    takeLatest(types.LOG_LINKS_PAGE, logLinksPage),
    
    /***** FAMILY ACCESS *****/

    takeLatest(types.GET_CONNECTED_ACCOUNTS, getConnectedAccounts),
    takeLatest(types.DELETE_CONNECTED_ACCOUNT, deleteConnected),
    takeLatest(types.CREATE_CHILD_ACCOUNT, createChildAccount),
    takeLatest(types.CHECK_PASSWORD_TO_CONNECT, checkPasswordToConnect),

    /***** USER *****/
    takeLatest(types.GET_ASSETS_SOURCE, getAssetsSource),
    takeLatest(types.GET_USER_SCORES, getUserScores),
    takeLatest(types.SET_LIST_SIZE, change_list_size),
    takeLatest(types.CHANGE_PASSWORD, change_password),
    takeLatest(types.RESET_PASSWORD, resetPassword),
    takeLatest(types.GET_STUDY_LANGUAGES, getStudyLanguages),
    takeLatest(types.STUDY_LANGUAGE_CHANGE, change_study_language),
    takeLatest(types.GET_TRANSLATION_LANGUAGES, getTranslationLanguages),
    takeLatest(types.TRANSLATION_LANGUAGE_CHANGE, change_translation_language),
    takeLatest(types.CHANGE_INTERFACE_LANGUAGE, changeInterfaceLanguage),
    takeLatest(types.TRANSLATION_LANGUAGE_CHANGE_WIZARD, changeTranslationLanguage),
    takeLatest(types.GET_USER_PROFILE, getUserAppProfile),
    takeLatest(types.GET_USER_PROFILE_BY_ID, getUserAppProfileById),
    takeLatest(types.CHANGE_USER_PROFILE, update_profile),
    takeLatest(types.UPLOAD_AVATAR, update_avatar),
    takeLatest(types.SEND_CONTACT_US_MESSAGE, contact_us),
    takeLatest(types.CONTACT_ABOUT_REGISTRATION, contactAboutRegistration),
    takeLatest(types.TOGGLE_SHOW_LEARNT_WORDS, toggleShowLearntWords),
    takeLatest(types.TOGGLE_SHOW_LEARNT_WORDS_COURSE, toggleShowLearntCourseWords),
    takeLatest(types.TOGGLE_SHOW_ARCHIVED_USER_LISTS, toggleShowArchiveLists),
    takeLatest(types.UPDATE_TRAININGS_SET, updateTrainingsSet),
    takeLatest(types.ACTIVATE_TRAININGS_SET, activateTrainingsSet),
    takeLatest(types.DELETE_ACCOUNT, deleteAccount),


    // takeLatest(types.SEND_CONTACT_US_MESSAGE, sendErrorMessage),
    takeLatest(types.SYNC_WEB_ACCOUNT_DATA, sync_data),
    takeLatest(types.CHANGE_NOTIFICATION_TIME, change_notification_time),
    takeLatest(types.CHANGE_NOTIFICATION_STATUS, change_notification_status),
    takeLatest(types.GET_USER_DATA, getUserData),
    takeLatest(types.CHANGE_AUDIO_LANGUAGE, changeAudioLanguage),
    takeLatest(types.TOGGLE_SHUFFLE_WORDS_SETTING, toggleShuffleWords),

    takeLatest(types.GET_INTRODUCTION_SLIDES, getSlides),
    takeLatest(types.CHANGE_USER_WORD_LEVEL, changeWordLevel),
    takeLatest(types.TOGGLE_AUTO_POSTPONE, toggleAutoPostpone),
    takeLatest(types.TOGGLE_COURSE_AUTO_POSTPONE, toggleCoursePostpone),
    takeLatest(types.NAVIGATE_TO_SEGUE, navigateToSegue),
    takeLatest(types.GET_USER_COUNTRY, getUserCountry),
    takeLatest(types.ADD_TO_FRAUDS, addToFrauds),
    takeLatest(types.CHANGE_VOCABULARY_LEVEL, changeVocabularyLevel),

    /***** REGISTER *****/

    takeLatest(types.RESEND_ACTIVATION_LINK, resendActivationLink),
    takeLatest(types.REGISTER, register),
    takeLatest(types.VERIFY, checkUser),
    takeLatest(types.SEND_FORGOT_LINK, sendLink),
    takeLatest(types.UPDATE_USER_TOKEN, updateUserToken),

    /**** LIST ****/
    takeLatest(types.GET_USER_LISTS, getListsByLang),
    takeLatest(types.SEARCH_USER_LISTS, searchUserLists),
    takeLatest(types.GET_LISTS_TO_REPEAT, getListsToRepeat),
    takeLatest(types.GET_LIST_WORDS, getListWords),
    takeLatest(types.GET_CURRENT_LIST_DATA, getCurrentListData),
    takeLatest(types.GET_TRAINING_WORDS, getWordsForTraining),
    takeLatest(types.ADD_NEW_LIST, addList),
    takeLatest(types.ADD_NEW_USER_LIST, addNewList),
    takeLatest(types.EDIT_LIST_NAME, editList),
    takeLatest(types.DELETE_LIST_WORD, deleteListWord),
    takeLatest(types.TOGGLE_LIST_WORD_FAVORITE, addListWordToFavorites),
    takeLatest(types.TOGGLE_LIST_WORD_VOCABULARY, toggleInVocabulary),
    takeLatest(types.TOGGLE_LIST_FAVORITE, toggleFavouriteList),
    takeLatest(types.MOVE_TO_OTHER_LISTS, moveToOtherLists),
    takeLatest(types.ADD_COURSE_WORD_TO_LIST, addCourseWordToList),

    /***** DICTIONARY *****/
    takeLatest(types.SEARCH_DICTIONARY_WORDS, searchWordsForList),
    takeLatest(types.SEARCH_IN_DICTIONARY, search_words),
    takeLatest(types.GET_GOOGLE_EXAMPLE_TRANSLATION, getGoogleExampleTranslation),
    takeLatest(types.ADD_USER_EXAMPLE_TRANSLATION, addUserExampleTranslation),
    takeLatest(types.FIND_WORD_IN_DICTIONARY, findWordInDictionary),

    /***** WORD DETAILS *****/
    takeLatest(types.GET_CURRENT_WORD, get_word_details),
    takeLatest(types.GET_EXTENDED_INFO, get_extended_info),
    takeLatest(types.TOGGLE_WORD_VOCABULARY_DETAILS, toggleInVocabularyDetails),
    takeLatest(types.TOGGLE_WORD_FAVORITE_DETAILS, toggleInFavoriteWordDetails),
    takeLatest(types.ADD_WORD_TRANSLATION, addUserTranslation),
    takeLatest(types.CHANGE_MAIN_TRANSLATION, changeMainTranslation),
    takeLatest(types.DELETE_USER_TRANSLATION, deleteUserTranslation),
    takeLatest(types.DELETE_WORD_USER_IMAGE, deleteUserImage),

    takeLatest(types.GET_LISTS_TO_FILL, getUserListsToFill),
    takeLatest(types.FILL_LISTS_WITH_GLOSSARY_WORDS, fillListFromGlossaryWithSelectedWords),
    takeLatest(types.TOGGLE_GLOSSARY_WORD_IN_LIST, toggleWordInUserList),
    takeLatest(types.GET_WORD_TO_OPEN, getWordToOpen),
    takeLatest(types.ADD_WORD_ASSOCIATION, addWordAssociation),
    takeLatest(types.DELETE_WORD_ASSOCIATION, deleteUserAssociation),
    takeLatest(types.ADD_USER_EXAMPLE, addUserExample),


    /***** FAVORITES *****/
    takeLatest(types.GET_FAVORITE_GLOSSARIES, get_favourite_glossaries),
    takeLatest(types.GET_FAVORITE_LISTS, getListsByLangF),
    takeLatest(types.GET_FAVORITE_WORDS, getFavoriteWords),
    takeLatest(types.GET_FAVORITE_PHRASES, getFavoritePhrases),
    takeLatest(types.GET_FAVORITE_NUMBERS, getAllFavoriteNumbers),
    takeLatest(types.DELETE_FAVORITE_WORD, deleteFromFavoriteWords),
    takeLatest(types.DELETE_FAVORITE_PHRASE, deleteFromFavoritePhrases),


    /***** GLOSSARIES *****/
    takeLatest(types.GET_PARENT_GLOSSARIES, getParentGlossaries),
    takeLatest(types.GET_PARENT_CHILDREN, getParentChildren),
    takeLatest(types.GET_GLOSSARY_WORDS, getGlossary),
    takeLatest(types.TOGGLE_GLOSSARY_WORD_VOCABULARY, toggleGlossaryWordInVocabulary),
    takeLatest(types.TOGGLE_GLOSSARY_WORD_FAVORITES, toggleGlossaryWordInFavorites),
    takeLatest(types.TOGGLE_GLOSSARY_IN_FAVORITES, toggleGlossaryInFavorites),
    takeLatest(types.GET_GLOSSARIES_FOR_LIST, get_glossaries_for_list),
    takeLatest(types.GET_GLOSSARY_WORDS_FOR_LIST, getGlossaryWordsForList),
    takeLatest(types.SEARCH_GLOSSARY_WORDS_FOR_LIST, searchWordsInThisGlossaryForList),
    takeLatest(types.SEND_CARDS, sendCards),
    takeLatest(types.SEARCH_GLOSSARY_WORDS, searchWordsInThisGlossary),
    takeLatest(types.SEARCH_GLOSSARIES, searchGlossaries),
    takeLatest(types.SEARCH_CHILD_GLOSSARIES, searchChildGlossaries),
    takeLatest(types.GET_CURRENT_GLOSSARY_DATA, getCurrentGlossaryData),


    /***** TESTS *****/
    takeLatest(types.GET_TEST_WORD, getTestWord),
    takeLatest(types.LOG_TEST_WORD, logTestWord),
    takeLatest(types.GET_TEST_RESULTS, getTestResult),
    takeLatest(types.GENERATE_LISTS, generateLists),
    takeLatest(types.SAVE_WORDS_AFTER_TEST, createListsFromTests),

    takeLatest(types.CHECK_WORD_EXTRACTOR_WORD, checkDictionaryWord),
    takeLatest(types.GET_WORD_EXTRACTOR_RESULTS, logWordExtractorResults),
    takeLatest(types.GET_HANGMAN_SCORES, getHangmanScores),
    takeLatest(types.GET_HANGMAN_WORD, getHangmanWord),
    takeLatest(types.LOG_HANGMAN_WORD, logHangmanWord),


    /***** LISTS *****/
    takeLatest(types.DELETE_LIST, deleteList),
    takeLatest(types.DELETE_LIST_FROM_LIST, deleteListFromList),
    takeLatest(types.POSTPONE_IN_CALENDAR, postponeInCalendar),
    takeLatest(types.REMOVE_FROM_CALENDAR, removeFromCalendar),
    takeLatest(types.SEND_LIST_CARDS, sendListCards),
    takeLatest(types.TOGGLE_ARCHIVE_LIST, toggleArchiveList),


    takeLatest(types.GET_VOCABULARY, getVocabulary),
    takeLatest(types.SET_GOAL, setGoal),
    takeLatest(types.DELETE_FROM_VOCABULARY, deleteFromVocabulary),
    takeLatest(types.TOGGLE_VOCABULARY_WORD_FAVOFITES, toggleFavoriteWord),


    /***** PHRASEBOOKS *****/
    takeLatest(types.GET_PHRASEBOOKS, getGroupedPhrasebooks),
    takeLatest(types.SEARCH_PHRASE, searchPhrases),
    takeLatest(types.TOGGLE_FAVORITE_PHRASE, toggleFavoritePhrase),
    takeLatest(types.GET_PHRASEBOOK_PHRASES, getPhrases),


    takeLatest(types.GENERATE_LISTS_NOT_WIZARD, generateListsNotWizard),
    takeLatest(types.ADD_USER_WORDS, addUserWords),
    takeLatest(types.SAVE_SCANNED_TEXT, saveScannedWords),
    takeLatest(types.DELETE_SELECTED_LISTS, deleteSelectedLists),
    takeLatest(types.GET_ONLY_MISTAKES, getOnlyMistakes),
    takeLatest(types.PERFORM_BULK_ACTION, performBulkAction),

    /***** PURCHASES *****/
    takeLatest(types.GET_REGISTRATION_DATE, getRegistrationDate),
    takeLatest(types.GET_USER_LIMITS, getUserLimits),
    takeLatest(types.LOG_TRAINING_LIMIT, logTrainingLimits),
    takeLatest(types.LOG_WORD_LIMIT, logWordLimits),
    takeLatest(types.GET_LISTS_NUMBER, getListsNumber),
    takeLatest(types.LOG_GAMES_LIMIT, logGamesLimits),
    takeLatest(types.GET_WEB_SUBSCRIPTION_STATUS, checkWebSubscriptionStatus),
    takeLatest(types.GET_PURCHASE_DATA, getPurchaseData),
    takeLatest(types.GET_LIQPAY_SUBSCRIPTION_STATUS, getLiqpaySubscriptionStatus),
    takeLatest(types.USE_PROMO_CODE, usePromoCode),
    takeLatest(types.CHECK_HAPPY_DAY, checkForHappyDay),
    takeLatest(types.EXCHANGE_SCORES, exchangeScores),
    takeLatest(types.GET_USER_PURCHASES, getUserPurchases),
    takeLatest(types.CANCEL_SUBSCRIPTION, cancelSubscription),
    takeLatest(types.CHECK_DISCOUNT_CODE, checkDiscountCode),
    takeLatest(types.GET_EXCHANGE_RATE, getCurrencyExchangeRate),


    /***** SYNC *****/
    takeLatest(types.SYN_USER_LISTS, syncUserLists),

    /***** USER CONTACTS *****/
    takeLatest(types.GET_USER_CONTACTS, getUserContacts),
    takeLatest(types.UPDATE_USER_CONTACT, updateUserContact),
    takeLatest(types.DELETE_USER_CONTACT, deleteUserContact),
    takeLatest(types.ADD_USER_CONTACT, createUserContact),

    /***** SHARE ENTITIES *****/

    takeLatest(types.GET_SHARED_GLOSSARIES, getSharedGlossaries),
    takeLatest(types.GET_SHARED_LISTS, getSharedLists),
    takeLatest(types.GET_SHARED_WORDS, getSharedWords),
    takeLatest(types.SHARE_GLOSSARY, shareGlossary),
    takeLatest(types.SHARE_LIST, shareList),
    takeLatest(types.SHARE_WORD, shareWord),
    takeLatest(types.GET_SHARED_NUMBERS, getSharedNumbers),

    takeLatest(types.DELETE_SHARED_GLOSSARY, deleteSharedGlossary),
    takeLatest(types.DELETE_SHARED_LIST, deleteSharedList),
    takeLatest(types.DELETE_SHARED_WORD, deleteSharedWord),

    takeLatest(types.SAVE_SHARED_LIST, saveSharedList),
    takeLatest(types.TOGGLE_SHARED_WORD_FAVORITE, toggleInFavoriteSharedWord),
    takeLatest(types.TOGGLE_SHARED_WORD_VOCABULARY, toggleInVocabularySharedWord),


    /***** CALENDAR *****/
    takeLatest(types.GET_CALENDAR_MONTH_DATA, getCalendarMonthData),
    takeLatest(types.GET_CALENDAR_DAY_DATA, getCalendarDayData),

    /***** BADGES *****/
    takeLatest(types.GET_USER_BADGES, getUserBadges),

    /***** COURSE *****/
    takeLatest(types.GET_COURSE_INITIAL_DATA, getInitialCourseData),
    takeLatest(types.GET_COURSES_INITIAL_DATA, getCoursesMistakesTestsRepeats),
    takeLatest(types.GET_COURSE_DATA, getCourseData),
    takeLatest(types.GENERATE_COURSE_LISTS, generateCourseLists),
    takeLatest(types.GET_COURSE_LIST_WORDS, getCourseListWords),
    takeLatest(types.GET_COURSE_LIST_DATA, getCourseListData),
    takeLatest(types.GET_UPDATED_COURSE_LISTS, getUpdatedCourseLists),
    takeLatest(types.GET_UPDATED_COURSE_CURRENT_LIST_SCORES, getUpdatedListScores),
    takeLatest(types.GET_UPDATED_COURSE_DATA, getUpdatedCourseData),
    takeLatest(types.GET_COURSE_LIST_STATISTICS, getCourseListStatistics),
    takeLatest(types.GET_COURSE_LIST_CALENDAR, getCourseListCalendar),
    takeLatest(types.DELETE_COURSE_DATA, deleteCourseData),
    takeLatest(types.GET_COURSE_CALENDAR_MONTH_DATA, getCourseCalendarMonthData),
    takeLatest(types.GET_COURSE_STATISTICS, getCourseStatistics),
    takeLatest(types.REMOVE_COURSE_LIST_FROM_CALENDAR, removeCourseListFromCalendar),
    takeLatest(types.GET_COURSE_VOCABULARY, getCourseVocabulary),
    takeLatest(types.COURSE_VOCABULARY_BULK_ACTION, performCourseBulkAction),
    takeLatest(types.CHECK_COURSE_DEBTS, checkForCourseDebts),
    takeLatest(types.RATE_COURSE, rateCourse),
    takeLatest(types.GET_ALL_COURSE_MISTAKES, getAllCourseMistakes),
    takeLatest(types.TOGGLE_IN_COURSE_VOCABULARY, toggleInCourseVocabulary),
    takeLatest(types.DELETE_FROM_COURSE_VOCABULARY, deleteFromCourseVocabulary),
    takeLatest(types.DELETE_COURSE_WORD, deleteCourseWord),
    takeLatest(types.GET_JUST_COURSE_DATA, getJustCourseData),
    takeLatest(types.GET_CURRENT_COURSE_LIST_DATA, getCurrentCourseListData),
    takeLatest(types.MOVE_TO_GENERAL_VOCABULARY, moveToGeneralVocabulary),

    /***** QUIZZES *****/
    takeLatest(types.GET_IRREGULAR_VERBS, getIrregularVerbs),
    takeLatest(types.LOG_IRREGULAR_VERB, logIrregularVerb),


    /***** WORD MAKER *****/
    takeLatest(types.GET_WORD_MAKER_WORDS, getWordMakerWords),
    takeLatest(types.GET_WORD_MAKER_PARTS, getWordMakerParts),
    takeLatest(types.LOG_WORD_MAKER_PART, logWordMakerPart),
    takeLatest(types.LOG_BUY_WORD_MAKER_WORD, logBuyWordMakerWord),
    takeLatest(types.BUY_WORD_MAKER_LEVEL, openWordMakerLevel),
    takeLatest(types.BUY_WORD_MAKER_HINTS, buyWordMakerHints),
    takeLatest(types.GET_WORD_MAKER_DATA, getWordMakerData),

    /***** TOUR *****/
    takeLatest(types.GET_TOUR_GLOSSARIES, getTourGlossaries),
    takeLatest(types.GET_TOUR_GLOSSARY, getTourGlossary),
    takeLatest(types.GET_TOUR_WORD, getTourWord),
    takeLatest(types.GET_TOUR_LISTS, getTourLists),
    takeLatest(types.GET_TOUR_LIST_WORDS, getTourListWords),
    takeLatest(types.GET_TOUR_COURSE_DATA, getTourCourse),
    takeLatest(types.GET_TOUR_COURSE_LIST_WORDS, getTourCourseListWords),
    takeLatest(types.GET_TOUR_WORDS_FOR_TRAINING, getTourWordsForTraining),
    takeLatest(types.GET_TOUR_CALENDAR_DATA, getTourCourseCalendarMonthData),


    takeLatest(types.GET_EVERYDAY_PHRASE, getEverydayPhrase),
    takeLatest(types.GET_EVERYDAY_WORD, getEverydayWord),
    takeLatest(types.ADD_EVERYDAY_TO_LIST, addEverydayToList),

    /***** NEWS *****/

    takeLatest(types.GET_USER_NEWS, getUserNews),
    takeLatest(types.GET_ALERT_NEWS, getUserNews),
    takeLatest(types.GET_UNREAD_NEWS, getUnreadNews),
    takeLatest(types.MANAGE_USER_NEWS, manageNews),
    takeLatest(types.GET_CURRENT_ARTICLE, getArticle),


    /***** TAGS *****/
    takeLatest(types.GET_TAGS, getTags),
    takeLatest(types.GET_CONNECTED_TAGS, getConnectedTags),
    takeLatest(types.CONNECT_TAG, connectTag),
    takeLatest(types.CREATE_TAG, createTag),
    takeLatest(types.DELETE_TAG, deleteTag),
    takeLatest(types.DISCONNECT_TAG, disconnectTag),
    
    /***** MULTI WORD MAKER *****/
    takeLatest(types.GET_MULTI_WORD_MAKER_GAMES, getActiveMultiWordMakerGames),
    takeLatest(types.CREATE_WORD_MAKER_MULTI_GAME, createWordMakerMultiGame),
    takeLatest(types.CANCEL_WORD_MAKER_MULTI_GAME, cancelMultiWordMakerGame),
    takeLatest(types.JOIN_WORD_MAKER_MULTI_GAME, joinMultiWordMakerGame),
    takeLatest(types.EXIT_WORD_MAKER_MULTI_GAME, exitMultiWordMakerGame),
    takeLatest(types.START_WORD_MAKER_MULTI_GAME, startMultiWordMakerGame),
    takeLatest(types.GET_CURRENT_WORD_MAKER_MULTI_GAME, getCurrentActiveWordMakerGame),
    takeLatest(types.LOG_WORD_MAKER_MULTI_GAME_RESULTS, logMultiWordMakerResults),
    takeLatest(types.GET_WORD_MAKER_MULTI_GAME_RESULTS, getMultiWordMakerResults),
    takeLatest(types.MAKE_WORD_MAKER_MULTI_GAME_INACTIVE, makeMultiWordMakerGameInactive),
    takeLatest(types.INVITE_USERS_TO_MULTI_MAKER, inviteUsersToMultiMaker),
    takeLatest(types.GET_MULTI_WORD_MAKER_GAME_RATING, getTotalRating),
    
    /***** HELPER ARTICLES *****/
    takeLatest(types.GET_HELPER_ARTICLES, getHelperArticles),
    takeLatest(types.GET_HELPER_ARTICLE, getHelperArticle),
    takeLatest(types.GET_HELPER_ARTICLE_BY_SLUG, getHelperArticleBySlug),


  ]);
}
