import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import _ from 'lodash'

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faPlusCircle, faCheckCircle, faCircle} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import Loader from "../../../components/Custom/Loader/index";

import {setFontSize} from '../../../actions/settingsActions';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

class ChangeFontSize extends Component {

  constructor(props) {
    super(props);

    this.state = {
      adjustedFont: 14,
      fontSize: 14,
      ratio: 1,
      initialRatio: 1,
      tablet: false
    };
    this.onChangeTextDelayed = _.debounce(this.slideCompleted, 500);
    this.timeOut = null;
  }

  componentDidMount() {
    this.setState({
      initialRatio: parseFloat(this.props.settings.fontSizeRation),
      ratio: parseFloat(this.props.settings.fontSizeRation),
      adjustedFont: 14 * parseFloat(this.props.settings.fontSizeRation)
    });
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
  }

  slideCompleted = (value) => {
    this.setState({
      ratio: value
    });

    this.timeOut = setTimeout(()=> {
      this.props.logActivity({activityId: 243, comment: value});
      this.props.setFontSize(value);
      this.setFont();
    }, 250)
  };

  valueChanged = (value) => {
    let newValue = parseFloat(value);
    let font = parseFloat(this.state.fontSize);

    this.setState({
      adjustedFont: parseFloat(font * newValue),
      ratio: value
    });

    this.onChangeTextDelayed(value)
  };

  setFont = async() => {
    const {userId} = this.props.user;
    localStorage.setItem(`fontSize${userId}`, `${this.state.ratio}`);
  };

  render() {
    const {fetchingData} = this.props.activity;
    const {translate, onClose, isVisible} = this.props;
    const {adjustedFont, ratio, initialRatio, tablet} = this.state;

    return (
      <Modal
        size="sm"
        className="changefontsize"
        show={isVisible}
        onHide={()=>onClose('fontModalVisible',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="change_font_size"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <Form.Group controlId="formBasicRange">
            <Form.Control
              min="0.8"
              max="1.5"
              step="0.05"
              custom
              value={ratio}
              onChange={(e) => this.valueChanged(e.target.value)} type="range" />
          </Form.Group>

          <div className="textSample">
            <span className="textSampleText" style={{fontSize: adjustedFont}}>
              It takes all the running you can do, to keep in the same place. If you want to get somewhere else, you must run at least twice as fast as that!
            </span>
          </div>
          
        </Modal.Body>

        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    setFontSize: (size) => dispatch(setFontSize(size)),
  };
}

export default connect(mapStateToProps, bindAction)(ChangeFontSize);
