import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faCircle} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import * as helpers from '../../../lib/validators';
import colors from '../../../lib/colors';

class AddExampleTranslationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      translation: ""
    };

    this.inputRef = React.createRef();
  }

  setText = (value) => {
    let example = helpers.sanitizeString(value, 100);

    example = example.length > 1 ? `${example.charAt(0).toUpperCase()}${example.slice(1)}` : example;
    this.setState({
      translation: example
    })
  };
  
  render() {
    const {
      translate,
      ratio,
      isVisible,
      onClose,
      onSubmit,
      example
    } = this.props;
    const {translation} = this.state;

    return (
      <Modal
        onShow={() => this.inputRef.current.focus()}
        className="addexample"
        show={isVisible}
        onHide={()=>onClose(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="add_example_translation"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">
          {
            example && example.example && (
              <p style={{fontSize: ratio * 16, color: colors.tealish, fontWeight: 'bold'}}>
                {example.example}
              </p>
            ) || null
          }
          <div className="position-relative">
            <InputGroup className="ph1 mt-2">
              <FormControl
                as="textarea"
                rows="5"
                ref={this.inputRef}
                placeholder={translate('enter_example_translation')}
                style={{fontSize: 12}}
                value={translation}
                onChange={(e)=> this.setText(e.target.value)}
              />
              <InputGroup.Append>
                <Button
                  style={{fontSize: 12}}
                  disabled={translation.length == 0}
                  variant="outline-secondary"
                  onClick={()=>{
                    this.setText("");
                    this.inputRef.current.focus();
                  }}>
                  <FontAwesomeIcon icon={faTimes}/>
                </Button>
              </InputGroup.Append>
            </InputGroup>
            <p className="chars" style={{fontSize: ratio * 12}}>{translation.length} / 100</p>

            <Button
              className="mt-3"
              block
              disabled={translation.length == 0}
              variant="outline-secondary"
              onClick={(e)=>onSubmit(translation, e)}>
              {translate('add')}
            </Button>

          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(null, null)(AddExampleTranslationModal);
