import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Translate, getTranslate} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGoogle, faFacebook} from '@fortawesome/free-brands-svg-icons'
import {faCogs, faShareAlt} from '@fortawesome/free-solid-svg-icons'

import {toastr} from 'react-redux-toastr';
import classNames from 'classnames';


import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import {setCurrentCourseSlug} from '../../../actions/courseActions';

import RegisterAlert from '../../../components/Modals/RegisterAlert/index';
import ChangeInterfaceLanguage from '../../Modals/ChangeInterfaceLanguage/index';
import ChangeTranslationLanguage from '../../Modals/ChangeTranslationLanguage/index';
import ChangeAudioLanguage from '../../Modals/ChangeAudioLanguage/index';
import ChangeFontSize from '../../Modals/ChangeFontSize/index';
import ChangeVocabularyLevel from '../../Modals/ChangeVocabularyLevel/index';
import ChangeListSize from '../../Modals/ChangeListSize/index';
import Notifications from '../../Modals/Notifications/index';
import ShareButtons from '../../Modals/ShareButtons/index';

class GuestNavbar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      registerModalVisible: false,
      interfaceLanguageModal: false,
      listSizeModalVisible: false,
      levelModalVisible: false,
      fontModalVisible: false,
      translationLanguageModal: false,
      audioModalVisible: false,
      notificationsModalVisible: false,
      shareModalVisible: false,

    };
  }

  componentDidMount() {
    localStorage.setItem('tour', 1);
  }

  _courseSelected = (name, slug, e) => {
    const {history} = this.props;
    const {locale} = this.props.settings;

    e.preventDefault();
    this.props.setCurrentCourseSlug(name, slug);
    history.replace(`/${locale}/course/${slug}`, {slug, name})
  };


  _toggleRegisterModal = (status, e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      registerModalVisible: status
    })
  };

  _toRegister = () => {
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/register`)
  };

  _toggleModal = (modal, status) => {
    this.setState({
      [modal]: status
    })
  };

  _toggleAudioModal = (status) => {
    this.setState({
      audioModalVisible: status
    })
  };
  
  render() {
    const {translate, history} = this.props;
    const {registerModalVisible,
      fontModalVisible,
      interfaceLanguageModal,
      listSizeModalVisible,
      levelModalVisible,
      translationLanguageModal,
      audioModalVisible,
      notificationsModalVisible,
      shareModalVisible
    } = this.state;
    const {locale} = this.props.settings;
    const path = history.location.pathname;

    const glossaryLink = classNames({
      'nav-link': true,
      'active': path == '/glossaries' || path.indexOf('glossary') !== -1
    });

    const listsLink = classNames({
      'nav-link': true,
      'active': path == '/lists' || path.indexOf('/list/') !== -1
    });

    const courseLink = classNames({
      'nav-link': true,
      'active': path == '/courses' || path.indexOf('course') !== -1
    });

    const gamesLink = classNames({
      'active': path == '/hangman' || path == '/wordmaker' || path == '/wordextractor'
    });

    return (
      <Navbar bg="dark" variant="dark" fixed="top" className="guestnavbar mnemonic" expand="md">
        <Link to={`/${locale}`} className="navbar-brand">
          Mnemonic Words
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>

        <Navbar.Collapse id="basic-navbar-nav">

          <Nav className="mr-auto">

            <React.Fragment>
              <Link to={`/${locale}/glossaries`} className={glossaryLink}>
                {translate('glossaries')}
              </Link>
              <Link to={`/${locale}/lists`} className={listsLink}>
                {translate('lists')}
              </Link>

              <Link to={`/${locale}/courses`} className={courseLink}>
                {translate('courses')}
              </Link>

              <Link to={`/${locale}/dictionary`} className={path == '/dictionary' ? 'nav-link active' : 'nav-link'}>
                {translate('dictionary')}
              </Link>

              {/*<NavDropdown title={translate('games')} id="games" className={gamesLink}>
                <a href="#" onClick={(e)=>this._toggleRegisterModal(true, e)} className={'dropdown-item'}>
                  {translate('vocabulary_test')}
                </a>

                <NavDropdown.Divider />

                <a href="" onClick={(e)=>this._toggleRegisterModal(true, e)} className={'dropdown-item'}>
                  {translate('hangman')}
                </a>
                <a href="#" onClick={(e)=>this._toggleRegisterModal(true, e)} className={'dropdown-item'}>
                  {translate('word_extractor')}
                </a>
                <a href="#" onClick={(e)=>this._toggleRegisterModal(true, e)} className={'dropdown-item'}>
                  {translate('word_maker')}
                </a>
              </NavDropdown>*/}
              {/*<Link to="/products" className='nav-link'>
                {translate('products')}
              </Link>*/}
            </React.Fragment>
          </Nav>

          <a href="#" className='nav-link' onClick={(e)=>{
                        e.preventDefault();
                        this._toggleModal('shareModalVisible',true)
                      }}>
            <FontAwesomeIcon
              style={{fontSize: 16, color: 'rgba(255, 255, 255, 0.5)'}}
              icon={faShareAlt}/>
          </a>

          {/*<NavDropdown
            alignRight
            title={<OverlayTrigger
                        delay={{ show: 500, hide: 400 }}
                        placement='bottom'
                        overlay={<Tooltip>{translate('app_settings')}</Tooltip>}
                      ><FontAwesomeIcon style={{fontSize: 16, color: 'rgba(255, 255, 255, 0.5)'}}
                       icon={faCogs}/></OverlayTrigger>}
            id="settings"
          >
            <a className='dropdown-item' href="#" onClick={(e)=>{
                        e.preventDefault();
                        this._toggleModal('notificationsModalVisible',true)
                      }}>
              {translate('notification_time')}
            </a>

            <NavDropdown.Divider />

            <a className='dropdown-item' href="#" onClick={(e)=>{
                        e.preventDefault();
                        this._toggleModal('levelModalVisible',true)
                      }}>
              {translate('change_vocabulary_level')}
            </a>

            <a className='dropdown-item' href="#" onClick={(e)=>{
                        e.preventDefault();
                        this._toggleModal('listSizeModalVisible',true)
                      }}>
              {translate('set_list_capacity')}
            </a>

            <NavDropdown.Divider />

            <a className='dropdown-item' href="#" onClick={(e)=>{
                        e.preventDefault();
                        this._toggleModal('translationLanguageModal',true);
                      }}>
              {translate('change_translation_language')}
            </a>

            <a className='dropdown-item' href="#" onClick={(e)=>{
                        e.preventDefault();
                        this._toggleModal('interfaceLanguageModal',true);
                      }}>
              {translate('interface_language_change')}
            </a>

            <a className='dropdown-item' href="#" onClick={(e)=>{
                          e.preventDefault();
                          this._toggleAudioModal(true);
                        }}>
              {translate('change_audio_language')}
            </a>

            <a className='dropdown-item' href="#" onClick={(e)=>{
                          e.preventDefault();
                          this._toggleModal('fontModalVisible',true);
                        }}>
              {translate('change_font_size')}
            </a>

          </NavDropdown>*/}

          <Nav>
            <Link to={`/${locale}/login`} className={path == `${locale}/login` ? 'nav-link active' : 'nav-link'}>
              <Translate id="login"/>
            </Link>
            <Link to={`/${locale}/register`} className={path == `${locale}/register` ? 'nav-link active' : 'nav-link'}>
              <Translate id="registration"/>
            </Link>
          </Nav>
        </Navbar.Collapse>

        {registerModalVisible && (
          <RegisterAlert
            onClose={this._toggleRegisterModal}
            isVisible={registerModalVisible}
            translate={translate}
            toRegister={this._toRegister}
          />
        )}

        {
          interfaceLanguageModal && (
            <ChangeInterfaceLanguage
              isVisible={interfaceLanguageModal}
              onClose={this._toggleModal}
              tour={true}
              toRegister={this._toggleRegisterModal}
              history={history}
            />
          )
        }
        {
          translationLanguageModal && (
            <ChangeTranslationLanguage
              isVisible={translationLanguageModal}
              onClose={this._toggleModal}
              tour={true}
              toRegister={this._toggleRegisterModal}
            />
          )
        }

        {
          audioModalVisible && (
            <ChangeAudioLanguage
              onClose={this._toggleAudioModal}
              isVisible={audioModalVisible}
              tour={true}
              toRegister={this._toggleRegisterModal}
            />
          )
        }

        {
          fontModalVisible && (
            <ChangeFontSize
              onClose={this._toggleModal}
              isVisible={fontModalVisible}
            />
          )
        }

        {
          levelModalVisible && (
            <ChangeVocabularyLevel
              onClose={this._toggleModal}
              isVisible={levelModalVisible}
              tour={true}
              toRegister={this._toggleRegisterModal}
            />
          )
        }
        {
          listSizeModalVisible && (
            <ChangeListSize
              onClose={this._toggleModal}
              isVisible={listSizeModalVisible}
              tour={true}
              toRegister={this._toggleRegisterModal}
            />
          )
        }

        {
          notificationsModalVisible && (
            <Notifications
              onClose={this._toggleModal}
              isVisible={notificationsModalVisible}
              tour={true}
              toRegister={this._toggleRegisterModal}
            />
          )
        }
        {
          shareModalVisible && (
            <ShareButtons
              onClose={this._toggleModal}
              isVisible={shareModalVisible}
              translate={translate}
            />
          )
        }
      </Navbar>

    )
  }
}

function bindAction(dispatch) {
  return {
    fetchingData: (status) => dispatch(fetchingData(status)),
    logActivity: (data) => dispatch(logActivity(data)),
    setCurrentCourseSlug: (name, slug) => dispatch(setCurrentCourseSlug(name, slug)),

  };
}

const mapStateToProps = state => ({
  // token: state.auth.token,
  user: state.user,
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize),
});

export default connect(
  mapStateToProps,
  bindAction,
)(withRouter(GuestNavbar));
