import request from 'superagent';
import {put, select} from 'redux-saga/effects';
import moment from 'moment';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logActivity} from './logSagas';
import {getListsToRepeat, getListsByLang} from './lists';
import {logApiError} from './logSagas';

import * as types from '../../actions/types';
import * as constants from '../../lib/constants';

require('superagent-charset')(request);

const API_URL = constants.API_URL;
const WEB_URL = constants.WEB_URL;

export function* getUserScores(data) {
  try {
    const result = yield request
    .get(API_URL + 'getUserScores')
    .query({
      user_id: data.payload,
    });

    yield put({
      type: types.SET_USER_SCORES,
      payload: result.body
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getUserScores',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* getUserCountry(data) {
  return true;
  try {
    const result = yield request
    // .get(`http://api.ipstack.com/${data.payload}`)
    .get(`https://extreme-ip-lookup.com/json/${data.payload}`)
    // .query({
    //   access_key: "d9a708fcec38ad1bea53a74be670f9f2"
    // })


    yield put({
      type: types.SET_USER_COUNTRY,
      payload: {
        // country: result.body.country_name,
        country: result.body.country,
        city: result.body.city,
      }
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getUserCountry',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* getAssetsSource() {
  // const {userId} = yield select(state => state.user);
  try {
    const result = yield request
    .get(API_URL + 'getAssetsSource')
    .query({
      // user_id: userId,
    });

    yield put({
      type: types.SET_ASSETS_SOURCE,
      payload: result.body
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getAssetsSource',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* navigateToSegue(data) {
  try {
    // yield put(NavigationActions.navigate({routeName: data.payload}));
  } catch (error) {
    // console.log(error)
  }
}

export function* getUserData(data) {
  try {
    const result = yield request
    .get(API_URL + 'getUserData')
    .query({
      user_id: data.payload,
    });
    
    // console.log("getUserData", result.body)

    yield put({
      type: types.SET_USER_DATA,
      payload: result.body.data
    });

    let registered = result.body.data ? moment(result.body.data.created_at).format("YYYY-MM-DD") : false

    // REGISTRATION DATE
    yield put({
      type: types.SET_REGISTRATION_DATE,
      payload: registered
    });

    // WEB SUBSCRIPTION
    yield put({
      type: types.SET_WEB_SUBSCRIPTION_STATUS,
      payload: {
        result: result.body.data.web_subscription == 1,
        date: result.body.data.web_subscription_valid_at
      }
    });

    // SCORES
    yield put({
      type: types.SET_USER_SCORES,
      payload: result.body.data ? {data: result.body.data.scores} : {data: 0}
    });

    // REVIEW MODAL
    let todayRefused = yield setReviewModal();
    let today = moment();
    let regDate = moment(registered);
    let diff = today.diff(regDate, 'days');

    let todayDay = today.format('YYYY-MM-DD');

    // console.log('todayRefused', todayRefused, todayDay, diff)

    // diff = 10
    let scores = result.body.data ? result.body.data.scores : 0;
    // console.log('scores', scores)
    if (todayRefused != '1' && scores > 10000) {
      if (todayRefused == null || todayRefused != todayDay) {
        if (diff >= 30 && diff % 5 == 0) {
          yield put({
            type: types.TOGGLE_REVIEW_MODAL,
            payload: true
          });
        }
      }
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getUserData',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

const setReviewModal = ()=> {
  const leftReview = localStorage.getItem("leftReview");

  if (leftReview == null) {
    return localStorage.getItem("notNow");
  } else {
    return leftReview;
  }
};

export function* getStudyLanguages() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getStudyLanguages')
    .query({
      user_id: userId
    });

    yield put({
      type: types.SET_STUDY_LANGUAGES,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getStudyLanguages',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* getTranslationLanguages() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getTranslationLanguages')
    .query({
      user_id: userId ? userId : 1
    });

    yield put({
      type: types.SET_TRANSLATION_LANGUAGES,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getTranslationLanguages',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* change_study_language(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'change_study_language')
    .send({
      user_id: userId,
      study_language: data.payload
    });

    if (result.statusCode === 200) {
      toastr.info('', translate('study_language_changed'));

      yield setNewStudyLanguage(userId, data.payload);

      yield getListsToRepeat({payload: {limit: 20, userId}});

      yield setTranslationToStudyLanguage(userId, data.payload);

      yield put({
        type: types.SET_NEW_STUDY_LANGUAGE,
        payload: result.body
      });
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'change_study_language',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* changeVocabularyLevel(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'changeVocabularyLevel')
    .send({
      user_id: userId,
      level: data.payload
    });

    // console.log("changeVocabularyLevel", result.body)

    if (result.body.result) {
      toastr.info('', translate('vocabulary_level_changed'));

      yield put({
        type: types.UPDATE_USER_LEVELS,
        payload: data.payload
      });
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'changeVocabularyLevel',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* change_translation_language(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'change_translation_language')
    .send({
      user_id: userId,
      translation_language: data.payload
    });

    if (result.statusCode === 200) {
      toastr.info('', translate('translation_language_changed'));

      yield setNewTranslationLanguage(userId, data.payload);

      yield put({
        type: types.SET_NEW_TRANSLATION_LANGUAGE,
        payload: data.payload
      });

      yield put({
        type: types.CLEAN_APP_CACHE
      });
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'change_translation_language',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


export function* changeInterfaceLanguage(data) {
  const {userId} = yield select(state => state.user);

  if(!userId) {
    yield put({
      type: types.UPDATE_INTERFACE_LANGUAGE,
      payload: data.payload
    });

    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
    
    return;
  }
  try {
    const result = yield request
    .post(API_URL + 'changeInterfaceLanguage')
    .send({
      user_id: userId,
      interface_language: data.payload
    });

    yield put({
      type: types.SET_USER_DATA,
      payload: result.body.user
    });

    // console.log("changeInterfaceLanguage", result.body)
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'changeInterfaceLanguage',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* changeTranslationLanguage(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'change_translation_language')
    .send({
      user_id: userId,
      translation_language: data.payload
    });

    if (result.statusCode === 200) {
      yield setNewTranslationLanguage(userId, data.payload);

      // toastr.info('', translate('translation_language_changed'));

      yield put({
        type: types.SET_NEW_TRANSLATION_LANGUAGE,
        payload: data.payload
      });

      yield put({
        type: types.CLEAN_APP_CACHE
      });

      yield logActivity({payload: {activityId: 161}});
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'change_translation_language',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* change_list_size(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'change_list_size')
    .send({
      user_id: userId,
      list_size: data.payload
    });

    if (result.statusCode === 200) {
      yield put({
        type: types.SET_NEW_LIST_SIZE,
        payload: data.payload
      });

      yield setUserListSize(userId, data.payload);
      toastr.info('', translate('list_size_changed'));
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'change_list_size',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* update_avatar(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'update_avatar')
    .attach('avatar', data.payload, {contentType: 'application/json;charset=UTF-8'})
    .field('user_id', userId)

    if (result.statusCode === 200) {
      if (result.body.user && result.body.avatar) {
        result.body.user.avatar = `${constants.BACKEND_URL}${result.body.avatar}`
      }

      yield put({
        type: types.UPDATE_USER_DATA,
        payload: result.body.user
      });
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'update_avatar',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* change_password(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'change_password')
    .send({
      user_id: userId,
      current_password: data.payload.currentPassword,
      new_password: data.payload.newPassword
    });

    if (result.statusCode === 200) {
      toastr.info('', translate('password_changed'));
    } else {
      toastr.error('', result.body.error);
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'change_password',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* resetPassword(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));
  const {locale} = yield select(state => state.settings);

  try {
    const result = yield request
    .post(API_URL + 'resetPassword')
    .send({
      email: data.payload.email,
      password: data.payload.password,
      password_confirmation: data.payload.confirmPassword,
      token: data.payload.token,
      web: 1
    });

    // console.log("resetPassword", result.body)

    if (result.body.result) {
      toastr.info('', translate('password_changed'));
      data.payload.history.replace(`/${locale}/login`)
    } else {
      toastr.error('', translate(result.body.error));
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'resetPassword',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* addToFrauds(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'addToFrauds')
    .send({
      user_id: userId,
      data: data.payload
    });

    if (result.body.data) {
      toastr.info('', translate('fraudery'));
    }

    // console.log("addToFrauds", result.body);

    yield _logOut();
    // yield put(NavigationActions.navigate({routeName: 'Login'}));
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'addToFrauds',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* update_profile(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'update_profile')
    .send({
      user_id: userId,
      first_name: data.payload.firstName,
      last_name: data.payload.lastName,
      birthday: data.payload.birthday,
      level: data.payload.level
    });

    if (result.body.user && result.body.avatar) {
      result.body.user.avatar = result.body.avatar
    }

    yield put({
      type: types.UPDATE_USER_DATA,
      payload: result.body.user
    });

    // console.log("update_profile", result.body)
    // if(result.body.result) {
    toastr.success('', translate('profile_updated'));
    // }

    yield put({
      type: types.SET_USER_SCORES,
      payload: result.body.user ? {data: result.body.user.scores} : {data: 0}
    });

    // yield put(NavigationActions.back());
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'update_profile',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* changeWordLevel(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'changeWordLevel')
    .send({
      user_id: userId,
      word_level_id: data.payload,
    });

    yield put({
      type: types.UPDATE_USER_DATA,
      payload: result.body.user
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'changeWordLevel',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* toggleShowLearntWords(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'toggleShowLearntWords')
    .send({
      user_id: userId,
    });

    // console.log("toggleShowLearntWords", result.body)

    yield put({
      type: types.UPDATE_SHOW_LEARN_USER_DATA,
      payload: result.body.user
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'toggleShowLearntWords',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}
export function* toggleShowLearntCourseWords(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'toggleShowLearntCourseWords')
    .send({
      user_id: userId,
    });

    // console.log("toggleShowLearntCourseWords", result.body)

    yield put({
      type: types.UPDATE_SHOW_LEARN_COURSE_USER_DATA,
      payload: result.body.user
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'toggleShowLearntCourseWords',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* toggleShowArchiveLists(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'toggleShowArchiveLists')
    .send({
      user_id: userId,
    });

    // console.log("toggleShowArchiveLists", result.body)

    yield put({
      type: types.UPDATE_SHOW_ARCHIVE_USER_DATA,
      payload: result.body.user
    });

    if (result.body.result) {
      yield put({
        type: types.FETCHING_DATA,
        payload: true
      });
      yield getListsByLang({payload: {filter: "by_list_name", order: "asc"}});
      yield getListsToRepeat({payload: {limit: 20, userId}});
    }

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'toggleShowArchiveLists',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


export function* changeAudioLanguage(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'changeAudioLanguage')
    .send({
      user_id: userId,
      language: data.payload,
    });

    yield put({
      type: types.UPDATE_USER_DATA,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'changeAudioLanguage',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* change_notification_time(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'change_notification_time')
    .send({
      user_id: userId,
      type: data.payload.type,
      notification_time: data.payload.time,
    });

    // console.log("change_notification_time", result.body)

    yield put({
      type: types.UPDATE_USER_DATA,
      payload: result.body.user
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'change_notification_time',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* change_notification_status(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'change_notification_status')
    .send({
      user_id: userId,
      type: data.payload.type,
      notifications: data.payload.status,
    });
    // console.log("change_notification_status", result.body)

    yield put({
      type: types.UPDATE_USER_DATA,
      payload: result.body.user
    });

  } catch (error) {
    yield logApiError({
      payload: {
        function: 'change_notification_status',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* contact_us(data) {
  let {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  if (!userId) {
    userId = 1;
  }

  try {
    if (userId) {
      const result = yield request
      .post(API_URL + 'contact_us')
      .send({
        user_id: userId,
        subject: data.payload.subject,
        message: data.payload.message,
        email: data.payload.email
      });

      // console.log("contact_us", result.body)

      toastr.info(translate('message_sent_title'), translate('message_sent_body'));
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'contact_us',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* contactAboutRegistration(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    if (userId) {
      const result = yield request
      .post(API_URL + 'contactAboutRegistration')
      .send({
        user_id: userId,
        email: data.payload,
      });

      toastr.info('', translate('registration_problem_message'));

      // yield put(NavigationActions.back());
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'contactAboutRegistration',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* sendErrorMessage(data) {
  const {userId} = yield select(state => state.user);

  try {
    if (userId) {
      const result = yield request
      .post(API_URL + 'contact_us')
      .send({
        user_id: userId,
        subject: data.payload.subject,
        message: data.payload.message,
      });
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'sendErrorMessage',
        payload: data.payload,
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* sync_data() {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'sync_data')
    .send({
      user_id: userId
    });

    if (result.statusCode === 200) {
      toastr.info('', translate('up_to_date_data'));
    } else {
      toastr.error('', translate('no_user_to_sync'));
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'sync_data',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.SYNC_WEB_PROGRESS,
      payload: false
    });
  }
}

export function* toggleAutoPostpone() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'toggleAutoPostpone')
    .send({
      user_id: userId,
    });

    if (result.body.result) {
      yield put({
        type: types.UPDATE_AUTO_POSTPONE,
      });
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'toggleAutoPostpone',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* toggleCoursePostpone() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'toggleCoursePostpone')
    .send({
      user_id: userId,
    });

    if (result.body.result) {
      yield put({
        type: types.UPDATE_COURSE_AUTO_POSTPONE,
      });
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'toggleCoursePostpone',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


export function* toggleShuffleWords() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'toggleShuffleWords')
    .send({
      user_id: userId,
    });

    if (result.body.result) {
      yield put({
        type: types.UPDATE_SHUFFLE_WORDS,
      });
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'toggleShuffleWords',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* updateTrainingsSet(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'updateTrainingsSet')
    .send({
      user_id: userId,
      trainings_set: data.payload
    });

    // console.log("updateTrainingsSet", result.body)
    
    if (result.body.result) {
      yield put({
        type: types.SET_UPDATED_TRAININGS_SET,
        payload: result.body.user
      });
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'updateTrainingsSet',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}
export function* activateTrainingsSet(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'activateTrainingsSet')
    .send({
      user_id: userId,
      trainings_set: data.payload
    });

    // console.log("activateTrainingsSet", result.body)

    if (result.body.result) {
      yield put({
        type: types.UPDATE_USER_DATA,
        payload: result.body.user
      });
    }
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'activateTrainingsSet',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getUserAppProfile() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getUserAppProfile')
    .query({
      user_id: userId,
    });

    // console.log('getUserAppProfile', result.body)

    yield put({
      type: types.SET_USER_DATA,
      payload: result.body.user
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getUserAppProfile',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

export function* getUserAppProfileById(data) {
  try {
    const result = yield request
    .get(API_URL + 'getUserAppProfile')
    .query({
      user_id: data.payload,
    });

    // console.log('getUserAppProfileById', result.body)

    yield put({
      type: types.SET_USER_DATA,
      payload: result.body.user
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getUserAppProfile',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}


export function* deleteAccount(data) {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));
  const {locale} = yield select(state => state.settings);

  try {
    const result = yield request
    .post(API_URL + 'deleteAccount')
    .send({
      user_id: userId,
      password: data.payload.password
    });
    
    // console.log('deleteAccount', result.body, data.payload)
    
    if(result.body.result) {
      localStorage.removeItem('userId');
      localStorage.removeItem('originalUserId');

      data.payload.history.replace(`/${locale}/login`)
    } else {
      toastr.error('', translate('wrong_password'));
    }
  } catch (error) {
    // console.log(error)
    /*yield logApiError({
      payload: {
        function: 'deleteAccount',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })*/
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getSlides() {
  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .get(API_URL + 'getIntroductionSlides')
    .query({
      user_id: userId,
      start_page: false
    });
    if (result.body.data.length == 0) {
      toastr.warning('', translate('no_slides_for_language'));
    }
    yield put({
      type: types.SET_INTRODUCTION_SLIDES,
      payload: result.body
    });
  } catch (error) {
    yield logApiError({
      payload: {
        function: 'getIntroductionSlides',
        error: error && error.message ? error.message : (error.response ? error.response : 'error')
      }
    })
  }
}

const setUserListSize = async(userId, listSize) => {
  try {
    localStorage.setItem(`listSize${userId}`, `${listSize}`);
  } catch (error) {
    // console.log(error.message);
  }
};

const setNewStudyLanguage = async(userId, studyLanguage) => {
  try {
    localStorage.setItem(`studyLanguage${userId}`, `${studyLanguage}`);
  } catch (error) {
    // console.log(error.message);
  }
};

const setNewTranslationLanguage = async(userId, translationLanguage) => {
  try {
    localStorage.setItem(`translationLanguage${userId}`, `${translationLanguage}`);
  } catch (error) {
    // console.log(error.message);
  }
};

const setTranslationToStudyLanguage = async(userId, studyLanguage) => {
  try {
    let newLang = 'ru';

    if (studyLanguage == 'en') {
      newLang = 'ru'
    } else {
      newLang = 'en'
    }

    // localStorage.setItem(`translationLanguage${userId}`, `${newLang}`);
  } catch (error) {
    console.log(error.message);
  }
};

const _logOut = async() => {
  try {
    // localStorage.removeItem('userId');
    // localStorage.removeItem('originalUserId');
  } catch (e) {
    console.log(e)
  }
};