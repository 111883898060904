import React, {Component} from 'react';
import {connect} from "react-redux";
import {Translate, getTranslate} from 'react-localize-redux';
import {Helmet} from "react-helmet";

import {Link} from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock, faAward} from '@fortawesome/free-solid-svg-icons';

import {toastr} from 'react-redux-toastr';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';

import moment from 'moment';
import classNames from 'classnames';

import CourseListWordCell from '../CourseListWordCell/index';
// import ListWordStatisticsModal from '../ListWordStatisticsModal/index';
import TrainingButtons from '../../../components/Modals/TrainingButtons/index';
import CourseWordSettings from '../../../components/Modals/CourseWordSettings/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
// import CourseRepetitionsModal from '../CourseRepetitionsModal/index';
import CourseListToolbar from '../../../components/Toolbars/CourseListToolbar/index';
import ListsToFill from '../../../components/Modals/ListsToFill/index';
import CourseListRepetitions from '../../../components/Modals/CourseListRepetitions/index';
import GenerateListCards from '../../../components/Modals/GenerateListCards/index';
import Loader from "../../../components/Custom/Loader/index";
import RegisterAlert from '../../../components/Modals/RegisterAlert/index';
import TrainingsSet from '../../../components/Modals/TrainingsSet/index';

import {logActivity} from '../../../actions/logActions';
import {
  getCourseListWords,
  resetCourseListWords,
  setCourseList,
  removeCourseListFromCalendar,
  setShowWordSentence,
  setCourseListWordsFromCache,
  resetCourseListStatistics,
  toggleListTrainingButtonsModalCourse,
  resetCurrentCourseList,
  toggleInCourseVocabulary,
  deleteCourseWord,
  getUpdatedCourseData,
  getJustCourseData,
  getCurrentCourseListData
} from '../../../actions/courseActions';
import {fetchingData} from '../../../actions/activity';
import {addCourseWordToList} from '../../../actions/lists';

class CourseListWords extends Component {

  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      trainingsModalVisible: false,
      listWordStatisticsModalVisible: false,
      subscriptionAlertVisible: false,
      currentWord: false,
      listWordsSettingsModalVisible: false,
      generateListCardsModal: false,
      listInfoModalVisible: false,
      listsModalIsVisible: false,
      wordSettingModalVisible: false,
      calendarModalVisible: false,
      tablet: false,
      deviceWidth: window.innerWidth,
      deviceHeight: window.innerHeight,
      scrolled: 0,
      listId: this.props.match.params.id,
      subscriptionMessage: 'subscription_trainings',
      tour: localStorage.getItem('tour'),
      registerModalVisible: false,
      trainingsSetModal: false,
    };

    window.addEventListener('scroll', this.handleScroll.bind(this));

    this.track = null;
    this.timeOut = null;
    this.timeOut1 = null;
    this.timeOut2 = null;
    this.timeOut3 = null;
    this.checkSettings();
  }

  componentDidMount() {
    const {courseData, currentList} =  this.props.course;
    const {listId} = this.state;
    
    const courseId = localStorage.getItem('courseId');

    if (!courseData && courseId != null && courseId != undefined) {
      this.props.getJustCourseData(courseId);
    }

    if (!currentList || !currentList.id) {
      this.props.getCurrentCourseListData(listId)
    }

    this._getCourseListWords()
  }

  componentDidUpdate(prevProps, prevState) {
    const {currentList} =  this.props.course;

    if (!currentList) {
      // this.props.navigation.goBack();
    }

    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._getCourseListWords();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut1);
    clearTimeout(this.timeOut2);
    clearTimeout(this.timeOut3);

    this.props.resetCourseListWords();
    this.props.resetCurrentCourseList();
    this.props.toggleListTrainingButtonsModalCourse(false);

    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const scrollY = window.scrollY;

    this.setState({
      scrolled: scrollY
    });
  }

  _getCourseListWords = () => {
    const {isConnected} = this.props.settings;
    const {listId} = this.state;

    let cachedWords = this.checkCachedWords();

    if (!cachedWords) {
      if (isConnected) {
        this.props.fetchingData(true);
        this.props.getCourseListWords(listId);
      }
    } else {
      this.props.setCourseListWordsFromCache(cachedWords);
    }
  };

  checkCachedWords = () => {
    const {listId} = this.state;
    const {cachedListsWords} = this.props.cache;

    let found = false;
    let foundWords = false;

    cachedListsWords.forEach(list => {
      if (list.listId === listId) {
        found = true;
        foundWords = list.listWords;
      }
    });

    return foundWords;
  };

  checkSettings = () => {
    const {userId} = this.props.user;

    const showWordSentence = localStorage.getItem(`showCourseSentences${userId}`);

    if (showWordSentence !== null) {
      this.props.setShowWordSentence(showWordSentence == "1");
    }
  };

  changeShowSentence = (e) => {
    e.preventDefault();

    const {userId} = this.props.user;
    const {showWordSentence} = this.props.course;
    this._toggleListWordsSettingsModal(false);
    this.props.setShowWordSentence(!showWordSentence);

    localStorage.setItem(`showCourseSentences${userId}`, showWordSentence ? "0" : "1");
  };

  playSound = (url, e) => {
    e.preventDefault();
    e.stopPropagation();

    const {isConnected} = this.props.settings;

    if (url) {
      if (this.track != null) {
        this.track.pause();
        this.track = null;
      }

      if (isConnected) {
        this.track = new Audio(url);
        this.track.play()
      }
    }
  };

  _renderItem = (item, index) => {
    const {fontSizeRation, version} = this.props.settings;
    const {showWordSentence} = this.props.course;
    const {tablet} = this.state;
    const {isConnected} = this.props.settings;
    const {userData} = this.props.user;
    const {translate} = this.props;

    return (
      <CourseListWordCell
        key={index}
        translate={translate}
        userData={userData}
        isConnected={isConnected}
        opened={true}
        ratio={fontSizeRation}
        version={version}
        tablet={tablet}
        word={item}
        showSentence={showWordSentence}
        onPlay={this.playSound}
        toWordDetails={this._toWordDetails}
        index={index}
        mistakes={false}
        showWordSettings={this._showWordSettings}
      />
    )
  };

  _toggleInVocabulary = (e) => {
    e.preventDefault();

    const {currentWord, listId, tour} = this.state;

    this._toggleWordSettingsModal(false);

    if(!tour) {
      this.props.toggleInCourseVocabulary(listId, currentWord.word_id);
      this.props.logActivity({
        activityId: 309,
        model: 'course word',
        modelId: currentWord.word_id,
        comment: currentWord.vocabulary ? "delete" : "add"
      });
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _showWordSettings = (currentWord, e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({currentWord});

    this._toggleWordSettingsModal(true)
  };

  _deleteCourseWord = (e) => {
    e.preventDefault();

    const {currentWord, listId, tour} = this.state;

    this._toggleWordSettingsModal(false);

    if(!tour) {
      this.props.fetchingData(true);

      this.props.deleteCourseWord(listId, currentWord.id);
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _toggleWordSettingsModal = (status) => {
    this.setState({
      wordSettingModalVisible: status
    })
  };

  removeFromCalendar = (e) => {
    e.preventDefault();
    const {listId, tour} = this.state;

    if(!tour) {
      this.props.fetchingData(true);
      this.props.logActivity({activityId: 268, model: 'course list', modelId: listId});
      this.props.removeCourseListFromCalendar(listId)
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _toWordDetails = (word, e) => {
    e.preventDefault();
    e.stopPropagation();
    const {locale} = this.props.settings;
    
    this.props.history.push(`/${locale}/dictionary/${word.word}`, {word, back: true});

    this.props.logActivity({activityId: 45, model: 'word', modelId: word.id, comment: "from course list"});
  };

  toggleTrainingButtons = (status) => {
    this.setState({
      trainingsModalVisible: status
    });

    this.props.toggleListTrainingButtonsModalCourse(false)
  };

  trainingSelected = (training) => {
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {userData} = this.props.user;
    const {listId} = this.state;
    const {locale} = this.props.settings;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    this.setState({
      subscriptionMessage: 'subscription_trainings'
    });

    let active = false;

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    this.props.toggleListTrainingButtonsModalCourse(false);

    this.setState({
      trainingsModalVisible: false
    });

    if (active) {
      let activityId = '';

      let path = '';

      if (training == 'Preview') {
        activityId = 39;
        path = 'preview'
      } else if (training == 'DragImage') {
        path = 'image';
        activityId = 40;
      } else if (training == 'SelectTranslation') {
        path = 'translation';
        activityId = 41;
      } else if (training == 'SelectWord') {
        path = 'word';
        activityId = 42;
      } else if (training == 'CollectWord') {
        path = 'collect';
        activityId = 43;
      } else if (training == 'CheckSpelling') {
        path = 'spelling';
        activityId = 44;
      } else if (training == 'CollectSentence') {
        activityId = 114;
      } else if (training == 'CollectSentenceTap') {
        activityId = 335;
        path = 'sentence'
      } else if (training == 'MemoryCards') {
        activityId = 131;
        path = 'memory'
      } else if (training == 'WordDefinition') {
        path = 'definition';
        activityId = 148;
      } else if (training == 'SpeakOut') {
        activityId = 95;
      } else if (training == 'MissingLetter') {
        path = 'missing';
        activityId = 315;
      } else if (training == 'Listening') {
        path = 'listening';
        activityId = 345;
      } else if (training == 'TranslateSentence') {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut = setTimeout(()=> {
        this.props.history.push(`/${locale}/${path}/${listId}`, {
          type: 'courseListId',
          inside: true,
          from: 'listWords',
          courseListId: listId
        });
        this.props.logActivity({activityId, model: 'courseList', modelId: listId});
      }, 100);
    } else {
      this._toggleSubscriptionAlert(true);
    }
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toSubscription = (productId) => {
    this._toggleSubscriptionAlert(false);
    const {locale} = this.props.settings;


    this.timeOut1 = setTimeout(()=> {
      this.props.history.push(`/${locale}/products`, {back: true, product: productId}); 
    }, 100);
  };

  _toggleStatisticsModal = (status) => {
    this.setState({
      listWordStatisticsModalVisible: status
    })
  };

  _toggleListWordsSettingsModal = (status) => {
    this.setState({
      listWordsSettingsModalVisible: status
    })
  };

  _buyListCards = (e) => {
    e.preventDefault();
    
    const {currentList} =  this.props.course;
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: 'list_cards'});

    this.props.logActivity({activityId: 244, model: 'current list', modelId: currentList.id});
  };

  _generateCards = (e) => {
    e.preventDefault();
    const {listId} = this.state;
    
    this._toggleModal('generateListCardsModal', true);
    this.props.logActivity({activityId: 246, model: 'list', modelId: listId});
  };

  _toListCalendar = (e) => {
    e.preventDefault();

    this.timeOut2 = setTimeout(()=> {
      this._toggleCalendarModal(true);
    }, 100);
  };

  _toggleCalendarModal = (status) => {
    this.setState({
      calendarModalVisible: status
    })
  };

  _infoPressed = () => {
    const {currentList} =  this.props.course;

    this._toggleListWordsSettingsModal(false);

    this.timeOut3 = setTimeout(() => {
      this._toggleListInfoModal(true);
      this.props.logActivity({activityId: 254, model: 'courseList', modelId: currentList.id});
    }, 100);
  };

  _toggleListInfoModal = (status) => {
    this.props.resetCourseListStatistics();

    this.setState({
      listInfoModalVisible: status
    });
  };

  _showUserLists = () => {
    const {tour} = this.state;
    this._toggleWordSettingsModal(false);

    if(!tour) {
      this.toggleListsToFillModal(true);
    } else {
      this._toggleRegisterModal(true);
    }
  };

  toggleListsToFillModal = (status) => {
    this.setState({
      listsModalIsVisible: status
    })
  };

  _addCourseWordToSelectedList = (id, e) => {
    e.preventDefault();

    const {currentWord} = this.state;
    this.toggleListsToFillModal(false);

    let active = true;

    if(id == 0) {
      active = this._checkActiveStatus();
    }

    if(active) {
      this.props.fetchingData(true);
      this.props.addCourseWordToList(id, currentWord.id);
    } else {
      this.setState({
        subscriptionMessage: 'subscription_lists'
      });
      this._toggleSubscriptionAlert(true);
    }
  };

  _checkActiveStatus = () => {
    const {lists} = this.props.lists;
    const {listsLimit, applyLimit, webSubscriptionStatus} = this.props.purchases;
    let active = false;

    if (webSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = lists.length < listsLimit;
    }

    return active;
  };


  _toggleModal = (type, status) => {
    this.setState({
      [type]: status
    })
  };

  _toggleRegisterModal = (status) => {
    this.setState({
      registerModalVisible: status
    })
  };

  _toRegister = () => {
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/register`)
  };

  _customizeButtons = (e) => {
    e.preventDefault();
    const {tour} = this.state;
    this.toggleTrainingButtons(false);

    if(!tour) {
      this._toggleModal('trainingsSetModal', true)
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _goDirectlyToTraining = (list) => {
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {userData} = this.props.user;
    const {locale} = this.props.settings;

    let active = false;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    if (active) {
      let trainingIds = userData.trainings_set != null ? userData.trainings_set.split(',').map(Number) : [];

      let trainingId = trainingIds.length > 0 ? trainingIds[0] : 1;

      let activityId = '';
      let path = '';

      if (trainingId == 1) {
        activityId = 39;
        path = 'preview'
      } else if (trainingId == 6) {
        path = 'image';
        activityId = 40;
      } else if (trainingId == 2) {
        path = 'translation';
        activityId = 41;
      } else if (trainingId == 4) {
        path = 'word';
        activityId = 42;
      } else if (trainingId == 3) {
        path = 'collect';
        activityId = 43;
      } else if (trainingId == 5) {
        path = 'spelling';
        activityId = 44;
      } else if (trainingId == 8) {
        activityId = 114;
        path = 'sentence';
      } else if (trainingId == 9) {
        activityId = 131;
        path = 'memory'
      } else if (trainingId == 10) {
        path = 'definition';
        activityId = 148;
      } else if (trainingId == 7) {
        activityId = 95;
      } else if (trainingId == 12) {
        path = 'missing';
        activityId = 315;
      } else if (trainingId == 14) {
        path = 'listening';
        activityId = 345;
      } else if (trainingId == 15) {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut4 = setTimeout(()=> {
        this.props.history.push(`/${locale}/${path}/${list.id}`, {
          type: 'courseListId',
          from: 'listWords',
          listId: list.id
        });
        this.props.logActivity({activityId, model: 'courseList', modelId: list.id, comment: 'from course'});
      }, 100);
    } else {
      this._toggleSubscriptionAlert(true);
    }
  };

  render() {
    const {listWords, currentList, courseData, showWordSentence, showListTrainingButtonsCourse, currentCourseSlug} =  this.props.course;
    const {fetchingData} = this.props.activity;
    const {fontSizeRation, isConnected, deviceWidth, deviceHeight, locale} = this.props.settings;
    const {
      trainingsModalVisible,
      subscriptionAlertVisible,
      listWordStatisticsModalVisible,
      currentWord,
      listWordsSettingsModalVisible,
      calendarModalVisible,
      tablet,
      wordSettingModalVisible,
      listInfoModalVisible,
      listsModalIsVisible,
      scrolled,
      generateListCardsModal,
      listId,
      subscriptionMessage,
      registerModalVisible,
      tour,
      trainingsSetModal
    } = this.state;

    const {lists} = this.props.lists;
    const {translate} = this.props;

    const {products, lifetimeStatus} = this.props.purchases;

    const {userData} = this.props.user;
    let level = userData && userData.level ? userData.level : 0;
    let listCards = userData && userData.list_cards ? userData.list_cards : false;

    let listSize = userData && userData.list_size ? userData.list_size : 10;

    const toolbar = classNames({
      'fixed-toolbar': scrolled >= 50,
      'justify-content-between coursetoolbar justify-content-center mb-2': true
    });

    return (
      <div className="courseListWords">

        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>

          <li className="breadcrumb-item">
            <Link to={{
              pathname: `/${locale}/course/${currentCourseSlug}`
            }}>
              {translate('course')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {currentList.list_name}
          </Breadcrumb.Item>
        </Breadcrumb>

        <CourseListToolbar
          ratio={fontSizeRation}
          deviceWidth={deviceWidth}
          deviceHeight={deviceHeight}
          translate={translate}
          toolbar={toolbar}
          userData={userData}
          courseData={courseData}
          lists={lists}
          title={currentList ? currentList.list_name : ""}
          list={currentList}
          tablet={tablet}

          showWordSentence={showWordSentence}
          listCards={listCards}
          // listCards={false}
          lifetimeStatus={lifetimeStatus}
          products={products}
          toggleTrainingButtons={this.toggleTrainingButtons}
          changeShowSentence={this.changeShowSentence}
          buyListCards={this._buyListCards}
          generateCards={this._generateCards}
          toListCalendar={this._toListCalendar}
          removeFromCalendar={this.removeFromCalendar}
          toStatistics={this._infoPressed}
          listWords={listWords}
          tour={tour}
          toFirstTraining={this._goDirectlyToTraining}

        />
        
        <Container className="pageWrapper">
          {
            currentList ? (
              <React.Fragment>
                <div className="d-flex justify-content-center align-items-center">
                  <span className="font-weight-bold mr-2">{currentList && currentList.list_name ? `${currentList.list_name} ` : ''}</span>{" "}
                  <span>{currentList && currentList.words ? `: ${currentList.words} ${translate('lwords')}` : ''}</span>
                </div>
                <p className="text-center mb-1 font-weight-light">
                  {currentList ? `${translate('scoresUC')}: ${currentList ? currentList.scores : 0}` : ''}
                </p>
              </React.Fragment>
            ) : null
          }

          <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
            {
              listWords.map((word, index) => {
                return this._renderItem(word, index);
              })
            }
          </div>

          {(trainingsModalVisible || showListTrainingButtonsCourse) && (
            <TrainingButtons
              modalVisible={trainingsModalVisible || showListTrainingButtonsCourse}
              training={this.trainingSelected}
              onClose={() => this.toggleTrainingButtons(false)}
              ratio={fontSizeRation}
              tablet={tablet}
              level={level}
              title="select_training_to_repeat"
              translationLanguage={userData.translation_language}
              course={true}
              translate={translate}
              customiseButtons={this._customizeButtons}

            />
          )}

          {wordSettingModalVisible && (
            <CourseWordSettings
              isVisible={wordSettingModalVisible}
              onClose={this._toggleWordSettingsModal}
              deleteCourseWord={this._deleteCourseWord}
              toggleInVocabulary={this._toggleInVocabulary}
              showStatistics={this._onStatisticsPressed}
              word={currentWord}
              tablet={tablet}
              ratio={fontSizeRation}
              showLists={this._showUserLists}
            />
          )}

          {
            /*listInfoModalVisible && (
             <ListStatisticsModal
             tablet={tablet}
             isVisible={listInfoModalVisible}
             onClose={this._toggleListInfoModal}
             list={currentList}
             ratio={fontSizeRation}
             />
             )*/
          }

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={subscriptionMessage}
                translate={translate}
              />
            )
          }

          {
            calendarModalVisible && (
              <CourseListRepetitions
                tablet={tablet}
                isVisible={calendarModalVisible}
                onClose={this._toggleCalendarModal}
                list={currentList}
                ratio={fontSizeRation}
                translate={translate}
                repetitions={currentList.repetitions}
              />
            )
          }

          {
            generateListCardsModal && (
              <GenerateListCards
                isVisible={generateListCardsModal}
                onClose={this._toggleModal}
                list={currentList}
                listId={listId}
                type={'course'}
              />
            )
          }

          {listsModalIsVisible && currentWord && (
            <ListsToFill
              isVisible={listsModalIsVisible}
              lists={lists}
              fillLists={this._addCourseWordToSelectedList}
              listSize={listSize}
              ratio={fontSizeRation}
              everyday={false}
              selectedWords={1}
              type={"course"}
              translate={translate}
              onClose={this.toggleListsToFillModal}/>
          )}

        </Container>

        {registerModalVisible && (
          <RegisterAlert
            onClose={this._toggleRegisterModal}
            isVisible={registerModalVisible}
            translate={translate}
            toRegister={this._toRegister}
          />
        )}

        {
          trainingsSetModal && (
            <TrainingsSet
              onClose={this._toggleModal}
              isVisible={trainingsSetModal}
            />
          )
        }
        <Loader fetchingData={fetchingData}/>

        <Helmet>
          <title>{`${translate('app_title_course')}`}</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="description" content={translate('app_title_course_description')} />
          <meta property="og:url" content={window.location.href} />
          <meta name="og:description" content={translate('app_title_course_description')} />
          <meta property="og:title" content={`${translate('app_title_course')}`} />
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  lists: state.lists,
  activity: state.activity,
  settings: state.settings,
  course: state.course,
  purchases: state.purchases,
  cache: state.courseCache,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    getUpdatedCourseData: () => dispatch(getUpdatedCourseData()),
    fetchingData: (status) => dispatch(fetchingData(status)),
    getCourseListWords: (listId) => dispatch(getCourseListWords(listId)),
    getCurrentCourseListData: (listId) => dispatch(getCurrentCourseListData(listId)),
    getJustCourseData: (courseId) => dispatch(getJustCourseData(courseId)),
    resetCourseListWords: () => dispatch(resetCourseListWords()),
    resetCurrentCourseList: () => dispatch(resetCurrentCourseList()),
    resetCourseListStatistics: () => dispatch(resetCourseListStatistics()),
    setShowWordSentence: (status) => dispatch(setShowWordSentence(status)),
    setCourseList: (list) => dispatch(setCourseList(list)),
    removeCourseListFromCalendar: (listId) => dispatch(removeCourseListFromCalendar(listId)),
    setCourseListWordsFromCache: (words) => dispatch(setCourseListWordsFromCache(words)),
    toggleListTrainingButtonsModalCourse: (status) => dispatch(toggleListTrainingButtonsModalCourse(status)),
    toggleInCourseVocabulary: (listId, wordId) => dispatch(toggleInCourseVocabulary(listId, wordId)),
    deleteCourseWord: (listId, wordId) => dispatch(deleteCourseWord(listId, wordId)),
    addCourseWordToList: (listId, wordId) => dispatch(addCourseWordToList(listId, wordId)),

  };
}

export default connect(mapStateToProps, bindAction)(CourseListWords);
