import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft, faCheck } from '@fortawesome/free-solid-svg-icons';

import {Translate, getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';

import Loader from "../../../../components/Custom/Loader/index";

import {
  cancelMultiWordMakerGame,
  exitMultiWordMakerGame,
  getCurrentActiveWordMakerGame,
  logMultiWordMakerResults,
  makeMultiWordMakerGameInactive,
  resetStartStatus
} from '../../../../actions/multiWordMakerActions';
import {logActivity} from '../../../../actions/logActions';
import {fetchingData} from '../../../../actions/activity';

import colors from '../../../../lib/colors';
import beep from '../../../../assets/sounds/beep.mp3';

class MultiWordMakerGame extends Component {

  constructor(props) {
    super(props);

    this.state = {
      gameId: this.props.match.params.id,
      game: {},
      seconds: 60,
      typedWord: "",
      totalWords: 0,
      guessedWords: 0,
      wordButtonsToHide: [],
      tablet: false,
      gameOver: false,
      allTypedWords: []
    };

    this.interval = null;
    this.timeout = null;
    this.track = null;
  }

  componentDidMount() {

    if (this.props.history.location && this.props.history.location.state) {
      this.setState({
        game: this.props.history.location.state.game,
      })
    } else {
      this.props.history.goBack();
    }
    this.timeout = setTimeout(()=> {
      this.startTimer();
    }, 500);

    document.addEventListener("keydown", this._handleKeyDown);
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.seconds === 0) {
      clearInterval(this.interval);
      this.gameOver();
    }
  }

  componentWillUnmount() {
    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }
    
    this.props.resetStartStatus(false);
    this.props.fetchingData(false);

    clearInterval(this.interval);
    clearTimeout(this.timeout);

    document.removeEventListener("keydown", this._handleKeyDown);
  }

  startTimer = () => {
    this.interval = setInterval(()=> {

      this.setState((prevState) => ({seconds: prevState.seconds - 1}));

      if(this.state.seconds > 0 && this.state.seconds < 6) {
        this._playSound();
      }
    }, 1000);
  };

  _playSound = () => {
    if (this.track != null) {
      this.track.pause();
      this.track = null;
    }

    this.track = new Audio(beep);

    if(this.track != null) {
      this.track.play();
    }
  };

  _handleKeyDown = (event) => {
    const {typedWord, letters, game} = this.state;
    const {wordButtonsToHide} = this.state;

    switch (event.keyCode) {
      case 27:
        this.props.history.goBack();
        break;

      case 8:
        this.eraseLastChar();
        break;

      case 13:
        event.preventDefault();
        if(typedWord.length >= 3) {
          this.checkWord();
        }
        break;
      default:
        if(event.key && event.key.length !== 0 ) {
          let char = event.key.toLowerCase();
          let found = false;
          game.current_split_word.forEach((letter, index) => {
            if(letter.toLowerCase() == char && !found && wordButtonsToHide.indexOf(index) === -1) {
              this.shuffledWordButtonPressed(letter, index, event);
              found = true;
            }
          })
        }
        break;
    }
  };


  renderWordLetters = () => {
    const {wordButtonsToHide, tablet, game} = this.state;
    if (!game.current_split_word) return;
    const {deviceWidth, deviceHeight} = this.props.settings;

    return game.current_split_word.map((item, i) => {
      return (
        <a href="#"
           key={i}
           onClick={(e) => {
            e.preventDefault();
            if(wordButtonsToHide.indexOf(i) === -1) {
              this.shuffledWordButtonPressed(item,i)
            }
          }}
        >
          <div
            className="shuffledWordButton"
            style={{
            width: !tablet ? (deviceWidth < 360 ? 35 : 45) : (deviceHeight > 950 ? 70 : 60),
            height:  !tablet ? (deviceWidth < 360 ? 35 : 45) : (deviceHeight > 950 ? 70 : 60),
            borderRadius: 5,
            marginLeft: !tablet ? 2 : 5,
            marginRight: !tablet ? 2 : 5,
            marginBottom: !tablet ? 5 : 10,
            borderColor: wordButtonsToHide.indexOf(i) === -1 ? colors.greyishBrown : colors.greyishBrown2 }}>
            <span
              className="shuffledWordButtonText"
              style={{fontSize: !tablet ? (deviceWidth < 360 ? 16 : 18) : 24,
              color: wordButtonsToHide.indexOf(i) === -1 ? colors.greyishBrown : colors.greyishBrown2 }}>
              {item}
            </span>
          </div>
        </a>
      )
    });
  };

  renderAllTyped = () => {
    const {tablet, allTypedWords} = this.state;
    const {fontSizeRation} = this.props.settings;

    return allTypedWords.map((item, i) => {
      return (
        <span href="#"
           style={{padding: 5}}
           key={i}>
          <span style={{color: this.getColor(item.length),
           fontSize: fontSizeRation * (!tablet ? 14 : 16),
           textAlign: 'center', fontWeight: '300'}}>
            {item}
          </span>
        </span>
      )
    })
  };

  getColor = (length) => {
    let color = colors.greyishBrown;

    switch (length) {
      case 3:
        color = colors.pine;
        break;
      case 4:
        color = colors.waterBlue;
        break;
      case 5:
        color = colors.greyishBrown;
        break;
      case 6:
        color = colors.tealish;
        break;
      case 7:
        color = colors.headerBlue;
        break;
      case 8:
        color = colors.coral;
        break;
      case 9:
        color = colors.dustyOrange;
        break;
      case 10:
        color = colors.golden;
        break;

    }

    return color;
  };

  shuffledWordButtonPressed = (char, i) => {
    const {wordButtonsToHide, typedWord} = this.state;

    this.setState({
      wordButtonsToHide: [...wordButtonsToHide, i],
      typedWord: `${typedWord}${char}`
    });
  };

  eraseLastChar = () => {
    const {wordButtonsToHide, typedWord} = this.state;
    let erased = typedWord.slice(0, -1);

    let popped = wordButtonsToHide.pop();

    this.setState({
      wordButtonsToHide: wordButtonsToHide,
      typedWord: erased
    })
  };

  checkWord = () => {
    const {typedWord, allTypedWords, game} = this.state;
    const {translate} = this.props;

    if (allTypedWords.indexOf(typedWord) === -1) {
      let allWords = allTypedWords;
      allWords.push(typedWord);
      
      this.setState({
        allTypedWords: allWords
      })
    } else {
      toastr.warning('', translate('already_there'));
    }

    this.setState({
      wordButtonsToHide: [],
      typedWord: ''
    })
  };

  gameOver = () => {
    const {gameOver, game, allTypedWords} = this.state;
    const {locale} = this.props.settings;
    clearInterval(this.interval);

    if (!gameOver) {
      this.setState({
        gameOver: true
      });

      this.props.logMultiWordMakerResults(allTypedWords, game.id);

      this.props.history.replace(`/${locale}/contest-wordmaker/${game.id}/results`);
    }
  };

  render() {
    const {
      activeGamesData,
      activeGamesArray,
      userActiveGame,
      activeGamesFetched,
      joinedGame,
      currentActiveGame
    } = this.props.multiWordMaker;
    const {typedWord, game, tablet, seconds} = this.state;
    const {translate} = this.props;
    const {userData, userId} = this.props.user;
    const {fetchingData} = this.props.activity;
    const {fontSizeRation, deviceWidth} = this.props.settings;

    let shuffledWordButtons = this.renderWordLetters();
    let allTyped = this.renderAllTyped();

    return (
      <div className="multiwordmakergame">

        <Container className="pageWrapper">
          <Button
            variant="outline-secondary"
            size="sm"
            className="mb-3 mt-3"
            onClick={this.gameOver}
          >
            {translate('exit_game')}
          </Button>
         <Card className="p-3">
           <h4 className="text-center"
            style={{color: seconds > 5 ? colors.greyishBrown : colors.coral}}
           >{seconds}</h4>
           <div
             className="w100 flexStart flexVertical"
             style={{marginTop: 10, marginBottom: 10}}>
                <span className="textCenter currentWord" style={{fontSize: fontSizeRation * 30}}>
                  {game.word ? game.word.word : ''}
                </span>

            <span
              className="textCenter typedWord"
              style={{minHeight: 90, marginTop: 30, fontSize: fontSizeRation * 40}}>
              {typedWord}
            </span>
           </div>

           <div style={{flex: 1, width: '100%'}}>
             <div style={{flex: 1}}>
               <div className="allTypedWords">
                 {allTyped}
               </div>
             </div>
           </div>

           <div className="flexVertical flexCenter">
             <div className="shuffledWordButtonsWrapper" style={{marginTop: 15, marginBottom: 15}}>
               {shuffledWordButtons}
             </div>

             <div className="flexHorizontal flexCenter mv2">

               <ButtonGroup style={{width: 200}} aria-label="Control buttons">
                 <Button
                   style={{borderLeftTopRadius: 25, borderLeftBottomRadius: 25}}
                   disabled={typedWord.length === 0}
                   onClick={() => typedWord.length > 0 ? this.eraseLastChar() : null}
                   variant="outline-info">
                   <FontAwesomeIcon
                     icon={faLongArrowAltLeft}
                     style={{fontSize: !tablet ? 25 : 35}}/>
                 </Button>

                 <Button
                   disabled={typedWord.length < 3}
                   onClick={() => typedWord.length >= 3 ? this.checkWord() : null}
                   variant="outline-info">
                   <FontAwesomeIcon
                     icon={faCheck}
                     style={{fontSize: !tablet ? 25 : 35}}/>
                 </Button>
               </ButtonGroup>
             </div>
           </div>
         </Card>
         
        </Container>

        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  multiWordMaker: state.multiWordMaker,
  purchases: state.purchases,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize)

});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    cancelMultiWordMakerGame: (gameId) => dispatch(cancelMultiWordMakerGame(gameId)),
    exitMultiWordMakerGame: (gameId) => dispatch(exitMultiWordMakerGame(gameId)),
    getCurrentActiveWordMakerGame: (gameId) => dispatch(getCurrentActiveWordMakerGame(gameId)),
    makeMultiWordMakerGameInactive: (gameId) => dispatch(makeMultiWordMakerGameInactive(gameId)),
    resetStartStatus: (status) => dispatch(resetStartStatus(status)),
    logMultiWordMakerResults: (words, gameId) => dispatch(logMultiWordMakerResults(words, gameId)),


  };
}

export default connect(mapStateToProps, bindAction)(MultiWordMakerGame);
