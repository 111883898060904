import React, {Component} from 'react';
import {Link} from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faListUl, faInfoCircle, faCircle, faTimes, faVolumeUp} from '@fortawesome/free-solid-svg-icons';

import ListGroup from 'react-bootstrap/ListGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import colors from '../../../lib/colors';

const DictionaryWord = ({word, onPressItem, onInfoPressed, dictionary, ratio, version, tablet, showLists, onPlay, audio, audioSource, translate}) => (


  <ListGroup.Item
    className="dictionaryWord"
    onClick={(e) => onPressItem(word, e)}>

    <div className="wordContainer">
      <div className="wordWrapper">

        <div className="d-flex justify-content-center align-items-center">
          <a href="#"
             className="p-3 d-flex justify-content-center align-items-center"
             onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  if(audio) {
                    onPlay(`${audioSource}${audio}`)
                  }
                }}
          >
            <FontAwesomeIcon icon={faVolumeUp} className="icon20" style={{color: colors.greyishBrown}}/>
          </a>

          <div className="wordTranslationWrapper">
          <span className="word" style={{fontSize: (!tablet ? 14 : 16) * ratio}}>
            {word.word}
          </span>
          <span className="translation" style={{fontSize: (!tablet ? 12 : 14) * ratio}}>
            {word.translation}
          </span>
          </div>
        </div>

        <div className="flexH spaceBetween">
          {
            word.in_user_lists && word.in_user_lists.length > 0 && (
              <OverlayTrigger
                delay={{ show: 500, hide: 500 }}
                placement='top'
                overlay={<Tooltip>{translate('word_in_lists')}</Tooltip>}>
                <a href="#"
                   className="flexH flexCenter p-2"
                   onClick={(e) => showLists(word.in_user_lists, e)}>
                  <FontAwesomeIcon
                    className="infoButton"
                    icon={faListUl}
                    style={{fontSize: !tablet ? 20 : 30}}/>
                </a>
              </OverlayTrigger>
            ) || null
          }

          <a href="#"
             className="flexH flexCenter p-2"
             style={{paddingHorizontal: 10}}
             onClick={(e) => onInfoPressed(word, e)}
          >
            <FontAwesomeIcon
              className="infoButton"
              icon={faInfoCircle}
              style={{fontSize: !tablet ? 20 : 30}}/>
          </a>

        </div>
      </div>

    </div>
  </ListGroup.Item>
);

export default DictionaryWord;
