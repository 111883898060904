import React, {Component} from 'react';
import {connect} from "react-redux";
import _ from 'lodash';
import moment from 'moment';

import {Translate, getTranslate} from 'react-localize-redux';
import {withRouter, Link} from 'react-router-dom';

import {toastr} from 'react-redux-toastr';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import ListCardCell from '../../../components/Cells/ListCardCell/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
import Loader from "../../../components/Custom/Loader/index";
import TrainingButtons from '../../../components/Modals/TrainingButtons/index';
import TrainingsSet from '../../../components/Modals/TrainingsSet/index';

import {getListsByLangF, getAllFavoriteNumbers} from '../../../actions/favoriteActions'
import {setCurrentList, toggleFavouriteList,
  toggleListTrainingButtonsModal,

} from '../../../actions/lists.js'
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import {checkForHappyDay} from '../../../actions/purchasesActions';

import colors from '../../../lib/colors';

class FavoriteLists extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searchBarVisible: false,
      refreshing: false,
      listModalVisible: false,
      listSettingsVisible: false,
      trainingsModalVisible: false,
      currentList: false,
      currentType: false,
      currentListIndex: null,

      favoritesAlertShouldShow: false,
      favoritesAlertVisible: false,
      subscriptionAlertVisible: false,
      repetitions: [],
      repetitionsModalVisible: false,
      tablet: false,
      trainingsSetModal: false,

    };

    this.timeOut = null;
  }

  componentDidMount() {
    this._getGlossariesData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this._getGlossariesData();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);

    this.setState({
      listModalVisible: false
    });
  }


  _getGlossariesData = () => {
    const {isConnected} = this.props.settings;

    if (isConnected) {
      this.props.fetchingData(true);
      this.props.getListsByLangF();
    }
  };
  
  toggleTrainingButtons = (status) => {
    this.setState({
      trainingsModalVisible: status
    });

    this.props.toggleListTrainingButtonsModal(false)
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toSubscription = (productId) => {
    this._toggleSubscriptionAlert(false);
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/products`, {back: true, product: productId}); 
  };

  _onPressItem = (list, index, type, e) => {
    e.preventDefault();
    const {locale} = this.props.settings;

    this.props.setCurrentList(list);

    this.props.history.push(`/${locale}/list/${list.id}`, {list, back: true});
    this.props.logActivity({activityId: 31, model: 'list', modelId: list.id, comment: 'from favorite'});
  };

  onRepetitionsPressed = (intervals, e) => {
    e.preventDefault();
    e.stopPropagation();

    this._toggleRepetitionsModal(true);

    this.props.logActivity({activityId: 30, model: 'list'});

    this.setState({
      repetitions: intervals
    })
  };

  getDots = (list) => {
    if (!list.intervals || list.intervals.length == 0) {
      return null;
    }

    return list.intervals.map((item, i) => {
      let color = colors.lightGrey;

      if (item.repetition_date < moment().format("YYYY-MM-DD") && item.trainings == 0) {
        color = colors.coral;
      } else if (item.repetition_date <= moment().format("YYYY-MM-DD") && item.trainings > 0) {
        color = colors.tealish;
      }

      return (
        <div
          className="dot"
          key={`${item}-${i}`}
          style={{marginHorizontal: 4,backgroundColor: color}}
        />
      )
    });
  };

  _onRepeatPressed = (list, type, e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.setCurrentList(list);

    this.setState({
      currentList: list,
      currentType: type
    });

    this.toggleTrainingButtons(true);
  };


  _renderItem = (item, index) => {
    const {fontSizeRation, version} = this.props.settings;
    const {tablet} = this.state;
    const {isConnected, deviceWidth, deviceHeight} = this.props.settings;
    const {translate} = this.props;

    let dots = this.getDots(item);

    return (
      <ListCardCell
        deviceWidth={deviceWidth}
        deviceHeight={deviceHeight}
        key={index}
        translate={translate}
        toDelete={false}
        isConnected={isConnected}
        tablet={tablet}
        ratio={fontSizeRation}
        list={item}
        index={index}
        repeat={item.trainings > 0}
        dots={dots}
        newList={item.trainings == 0}
        version={version}
        listsRef={this.myRef}
        onLongPressed={this._onLongPress}
        onItemPressed={this._onPressItem}
        onRepeatPressed={this._onRepeatPressed}
        showRepetitionDays={this.onRepetitionsPressed}
        openTagModal={this._openTagModal}

        favourite={item.favourite}
        favorites={true}
        onFavorite={this._toggleFavorite}
      />
    )
  };

  _toggleFavorite = (list, index) => {
    this.props.fetchingData(true);

    this.props.toggleFavouriteList(list.id, index, true);

    this.props.logActivity({
      activityId: list.favourite == 0 ? 28 : 29,
      model: 'list',
      modelId: list.id,
      comment: "from list"
    });
  };

  toggleListModal = (status) => {
    this.setState({
      listModalVisible: status,
    });

    if (!status) {
      this.setState({
        currentList: false
      });
    }
  };

  _toggleRepetitionsModal = (status) => {
    this.setState({
      repetitionsModalVisible: status
    })
  };

  trainingButtonSelected = (training) => {
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {userData} = this.props.user;
    const {locale} = this.props.settings;

    let active = false;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    this.toggleTrainingButtons(false);

    this.props.toggleListTrainingButtonsModal(false);

    this.props.checkForHappyDay();

    if (active) {
      const {currentList} = this.state;
      const {currentListInLists} = this.props.lists;

      let activityId = '';
      let path = '';

      if (training == 'Preview') {
        activityId = 39;
        path = 'preview'
      } else if (training == 'DragImage') {
        path = 'image';

        activityId = 40;
      } else if (training == 'SelectTranslation') {
        path = 'translation';
        activityId = 41;
      } else if (training == 'SelectWord') {
        path = 'word';
        activityId = 42;
      } else if (training == 'CollectWord') {
        path = 'collect';
        activityId = 43;
      } else if (training == 'CheckSpelling') {
        path = 'spelling';
        activityId = 44;
      } else if (training == 'CollectSentence') {
        activityId = 114;
        path = 'sentence';
      } else if (training == 'CollectSentenceTap') {
        activityId = 335;
        path = 'sentence';
      } else if (training == 'MemoryCards') {
        activityId = 131;
        path = 'memory'
      } else if (training == 'WordDefinition') {
        path = 'definition';
        activityId = 148;
      } else if (training == 'SpeakOut') {
        activityId = 95;
      } else if (training == 'MissingLetter') {
        path = 'missing';
        activityId = 315;
      } else if (training == 'Listening') {
        path = 'listening';
        activityId = 345;
      } else if (training == 'TranslateSentence') {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut = setTimeout(()=> {
        this.props.history.push(`/${locale}/${path}/${currentListInLists.id}`, {
          type: 'listId',
          from: 'listWords',
          listId: currentListInLists.id
        });
        this.props.logActivity({activityId, model: 'list', modelId: currentListInLists.id, comment: 'from favorite'});
      }, 100)
    } else {
      this._toggleSubscriptionAlert(true);
    }
  };

  _toggleModal = (type, status) => {
    this.setState({
      [type]: status
    })
  };

  _customizeButtons = (e) => {
    e.preventDefault();
    this.toggleTrainingButtons(false);
    this._toggleModal('trainingsSetModal', true)
  };
  
  render() {
    const {lists} = this.props.favorites;
    const {tablet,
      subscriptionAlertVisible,
      trainingsModalVisible,
      trainingsSetModal
    } = this.state;
    const {fetchingData} = this.props.activity;
    const {translate} = this.props;
    const {userData} = this.props.user;
    const {showListTrainingButtons, currentListInLists} = this.props.lists;
    const {fontSizeRation, locale} = this.props.settings;

    let level = userData && userData.level ? userData.level : 0;

    return (
      <div className="favoritelists">

        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate('favourite_lists')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container className="pageWrapper">
          <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
            {
              lists.map((item, index) => {
                return this._renderItem(item, index)
              })
            }
          </div>
          
          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={'subscription_trainings'}
                translate={translate}
              />
            )
          }

          {(trainingsModalVisible || showListTrainingButtons) && currentListInLists.words > 0 && (
            <TrainingButtons
              modalVisible={trainingsModalVisible || showListTrainingButtons}
              training={this.trainingButtonSelected}
              onClose={() => this.toggleTrainingButtons(false)}
              ratio={fontSizeRation}
              listId={currentListInLists ? currentListInLists.id : null}
              title="select_training_to_repeat"
              translationLanguage={userData.translation_language}
              course={false}
              translate={translate}
              level={level}
              tablet={tablet}
              customiseButtons={this._customizeButtons}

            />
          )}

        </Container>

        {
          trainingsSetModal && (
            <TrainingsSet
              onClose={this._toggleModal}
              isVisible={trainingsSetModal}
            />
          )
        }
        
        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  favorites: state.favorites,
  lists: state.lists,
  purchases: state.purchases,
  activity: state.activity,
  settings: state.settings,
  translate: getTranslate(state.localize)
});

function bindAction(dispatch) {
  return {
    getListsByLangF: () => dispatch(getListsByLangF()),
    getAllFavoriteNumbers: () => dispatch(getAllFavoriteNumbers()),
    checkForHappyDay: () => dispatch(checkForHappyDay()),
    setCurrentList: (list) => dispatch(setCurrentList(list)),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    toggleFavouriteList: (list_id, index, favorites) => dispatch(toggleFavouriteList(list_id, index, favorites)),
    toggleListTrainingButtonsModal: (status) => dispatch(toggleListTrainingButtonsModal(status)),

  };
}

export default connect(mapStateToProps, bindAction)(FavoriteLists);
