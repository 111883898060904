import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faCheck} from '@fortawesome/free-solid-svg-icons';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import Modal from 'react-bootstrap/Modal';

import Loader from "../../../components/Custom/Loader/index";

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import colors from '../../../lib/colors';
import {getConnectedTags, setSelectedTag} from '../../../actions/tagActions';

class TagsFilterModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tagName: '',
      color: colors.tealish,
      colorsVisible: false,
    };

    this.props.getConnectedTags();
    this.timeOut = null;
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
  }

  _onSelect = (id, e) => {
    const {getLists} = this.props;
    e.preventDefault();
    this.props.setSelectedTag(id);

    this.timeOut = setTimeout(()=> {
      getLists();
    }, 100);

    this.props.logActivity({activityId: 352});
  };

  render() {
    const {isVisible, onClose, ratio, tablet, translate} = this.props;
    const {tagName, colorsVisible} = this.state;
    const {fetchingData} = this.props.activity;
    const {deviceHeight} = this.props.settings;
    const {connectedTags, connectedTagsFetched, selectedTags} = this.props.tags;

    return (
      <Modal
        className="tagsfilter"
        show={isVisible}
        onHide={()=>onClose('tagsFilterModalVisible',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="select_tags"/>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{minHeight: 200}}>
          {
            connectedTagsFetched && connectedTags.length == 0 && (
              <span
                className="noTags"
                style={{ paddingHorizontal: !tablet ? 35 : 50,
            marginTop: !tablet ? 15 : 25, fontSize: ratio * (!tablet ? 14 : 16)}}
              >
                {translate('no_connected_tags')}
              </span>
            )
          }

          <div style={{paddingHorizontal: 20, width: '100%', marginBottom: 20, marginTop: 10}}>

            {
              connectedTags.map((item, i) => {
                return (
                  <a href="#"
                     key={i}
                     onClick={(e) => this._onSelect(item.id, e)}>
                    <div className="flexH spaceBetween" style={{marginBottom: !tablet ? 10 : 20, width: '100%'}}>
                      <div className="flexH flexCenter">
                        <FontAwesomeIcon
                          style={{marginRight: 10, color: selectedTags.indexOf(item.id) === -1 ? colors.white : colors.tealish, fontSize: !tablet ? 15 : 25}}
                          icon={faCheck}
                        />

                        <div style={{
                              backgroundColor: item.color,
                              width: !tablet ? 20 : 30,
                              height: !tablet ? 20 : 30,
                              borderRadius: !tablet ? 10 : 15,
                              marginRight: 10,
                          }}/>

                          <span style={{fontSize: ratio *(!tablet ? 16 : 20), color: colors.greyishBrown}}>
                            {item.tag_name}
                          </span>
                      </div>
                    </div>
                  </a>
                )
              })
            }
          </div>
          <Loader fetchingData={fetchingData}/>

        </Modal.Body>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),
  tags: state.tags,

});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    getConnectedTags: () => dispatch(getConnectedTags()),
    setSelectedTag: (id) => dispatch(setSelectedTag(id)),
  };
}

export default connect(mapStateToProps, bindAction)(TagsFilterModal);
