import React, {Component} from 'react';
import {Translate, getTranslate} from 'react-localize-redux';
import {connect} from "react-redux";

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import ReportBug from '../../../components/Modals/ReportBug/index';


class Terms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bugModalVisible: false
    };
  }

  _toggleBugModal = (status) => {
    this.setState({
      bugModalVisible: status
    });
  };

  render() {
    const {translate} = this.props;
    const {bugModalVisible} = this.state;

    return (
      <div style={{marginTop: 90}}>
        <Container>
          <Card style={{padding: "20px"}}>
            <h4>{translate('termspage.terms_conditions')}</h4>

            <p><Translate id="termspage.last_updated"/>: {translate('September')} 01, 2020</p>

            <p>{translate('termspage.these_terms_and_conditions')}</p>

            <p>{translate('termspage.please_read')}</p>

            <p>{translate('termspage.your_access_to')} {translate('termspage.these_terms_apply_to_all')} </p>

            <p>{translate('termspage.by_accessing_or_using')} {translate('termspage.if_you_disagree')}</p>

            <h4>{translate('termspage.purchases')}</h4>

            <p>{translate('termspage.if_you_wish_to_purchase')}</p>

            <p>{translate('termspage.you_represent_and_warrant')}</p>

            <p>{translate('termspage.by_submitting_such_information')}</p>

            <p>{translate('termspage.we_reserve_the_right')}</p>
            
            <p>{translate('termspage.we_reserve_the_right_fraud')}</p>
            
            <h4>{translate('termspage.availability_errors')}</h4>

            <p>{translate('termspage.we_are_constantly_updating')} {translate('termspage.the_products_or_services')} </p>

            <p>{translate('termspage.we_cannot_and_do_not_guarantee')} {translate('termspage.we_reserve_the_right_to_change')} </p>

            <h4>{translate('termspage.subscriptions')}</h4>

            <p>{translate('termspage.some_parts_of_the_service')} {translate('termspage.you_will_be_billed')} {translate('termspage.billing_cycles_are_set')}</p>

            <p>{translate('termspage.at_the_end_of_each_billing')} {translate('termspage.you_may_cancel_your_subscription')} </p>

            <p>{translate('termspage.a_valid_payment_method')} {translate('termspage.you_shall_provide')} {translate('termspage.by_submitting_such_payment')}</p>


            <h4>{translate('termspage.free_trial')}</h4>

            <p>{translate('termspage.free_at_its_sole_discretion')}</p>

            <p>{translate('termspage.you_maybe_require_to_enter')}</p>

            <p>{translate('termspage.if_you_do_enter')} {translate('termspage.on_the_last_day')}</p>

            <p>{translate('termspage.at_any_time_without_notice')}</p>


            <h4>{translate('termspage.fee_changes')}</h4>

            <p>{translate('termspage.may_modify_the_subscription_fee')} {translate('termspage.any_subscription_fee_change')}</p>

            <p>{translate('termspage.will_provide_a_reasonable_prior_notice')}</p>

            <p>{translate('termspage.your_continued_use')}</p>


            <h4>{translate('termspage.refunds')}</h4>

            <p>{translate('termspage.except_when_required')}</p>


            <h4>{translate('termspage.accounts')}</h4>

            <p>{translate('termspage.when_you_create_account')} {translate('termspage.failure_to_do_so')}</p>

            <p>{translate('termspage.you_are_responsible')}</p>

            <p>{translate('termspage.you_agree_not_to_disclose')} {translate('termspage.you_must_notify_us_immediately')}</p>


            <h4>{translate('termspage.intellectual_property')}</h4>

            <p>
              {translate('termspage.the_service_and_its_original_content')} {translate('termspage.the_service_is_protected_by_copyright')} {translate('termspage.our_trademarks')}
            </p>


            <h4>{translate('termspage.links_to_websites')}</h4>

            <p>{translate('termspage.our_service_may_contain_links')}</p>

            <p>
              {translate('termspage.has_no_control')} {translate('termspage.you_further_acknowledge')}
            </p>

            <p>{translate('termspage.we_strongly_advise')}</p>


            <h4>{translate('termspage.termination')}</h4>

            <p>{translate('termspage.we_may_terminate')}</p>

            <p>{translate('termspage.upon_termination')} {translate('termspage.if_you_wish_to_terminate')}</p>
            

            <h4>{translate('termspage.limitation_of_liability')}</h4>

            <p>
              {translate('termspage.in_no_event_shall')} {translate('termspage.any_conduct_or_content')} {translate('termspage.unauthorized_access')}
            </p>


            <h4>{translate('termspage.disclaimer')}</h4>

            <p>{translate('termspage.your_use_of_the_Service')} {translate('termspage.the_service_is_provided')} {translate('termspage.provided_without_warranties')}</p>

            <p>{translate('termspage.does_not_warrant')} {translate('termspage.the_service_will_function')} {translate('termspage.any_errors_or_defects')} {translate('termspage.the_service_is_free')} {translate('termspage.the_results_of_using')}</p>


            <h4>{translate('termspage.governing_law')}</h4>

            <p>{translate('termspage.these_terms_shall_be_governed')}</p>

            <p>{translate('termspage.our_failure_to_enforce')} {translate('termspage.if_any_provision')} {translate('termspage.these_terms_constitute')}</p>


            <h4>{translate('termspage.changes')}</h4>

            <p>{translate('termspage.we_reserve_the_right_at')} {translate('termspage.if_a_revision_is_material')} {translate('termspage.what_constitutes')}</p>

            <p>{translate('termspage.by_continuing_to_access')} {translate('termspage.if_you_do_not_agree')}</p>

            <h4>{translate('termspage.contact_us')}</h4>

            <p>{translate('termspage.if_you_have_any_questions')} <a
              className="text-info text-center mr-3"
              href="#"
              onClick={(e)=> {
                e.preventDefault();
                this._toggleBugModal(true)
              }}
            >{translate('termspage.text_us')}</a></p>
          </Card>
        </Container>
        {
          bugModalVisible && (
            <ReportBug
              onClose={this._toggleBugModal}
              isVisible={bugModalVisible}
              type="contact"
              training={false}
              extra={false}
            />
          )
        }
      </div>
    )
  }
}


const mapStateToProps = (state, dispatch) => ({
  translate: getTranslate(state.localize)
});

export default connect(mapStateToProps, null)(Terms);
