import React, {Component} from 'react';
import {connect} from "react-redux";
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import classNames from 'classnames';
import {Helmet} from "react-helmet";

import {Link} from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircle, faCogs, faCheckCircle,
  faTrashAlt,
  faCamera,
  faVolumeUp,
  faTimes, faSearch, faPlus
} from '@fortawesome/free-solid-svg-icons';

import {Translate, getTranslate} from 'react-localize-redux';

import {toastr} from 'react-redux-toastr';

import ProgressBar from 'react-bootstrap/ProgressBar';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import moment from 'moment';

import TrainingButtons from '../../../components/Modals/TrainingButtons/index';
import CourseListCell from '../CourseListCell/index';
// import ListStatisticsModal from '../ListStatisticsModal/index';
// import CourseRepetitionsModal from '../CourseRepetitionsModal/index';
import CourseToolbar from '../../../components/Toolbars/CourseToolbar/index';
import SubscriptionAlert from '../../../components/Modals/SubscriptionAlert/index';
import CourseInfo from '../../../components/Modals/CourseInfo/index';
import CourseRate from '../../../components/Modals/CourseRate/index';
import RegisterAlert from '../../../components/Modals/RegisterAlert/index';
import CourseLockedList from '../../../components/Modals/CourseLockedList/index';
import TrainingsSet from '../../../components/Modals/TrainingsSet/index';

import Loader from "../../../components/Custom/Loader/index";

import {logActivity} from '../../../actions/logActions';
import {
  getInitialCourseData,
  getCourseData,
  getCourseListCalendar,
  resetCourseListData,
  resetCourseData,
  resetCurrentCourseList,
  getCourseListData, setCourseList, setFetchedCourseData,
  generateCourseLists,
  deleteCourseData,
  checkForCourseDebts,
  setCourseDataFromCache,
  resetCourseCache,
  setCourseListType,
  setCurrentCourseData,
  setCurrentCourseSlug
} from '../../../actions/courseActions';
import {fetchingData} from '../../../actions/activity';
import {getRegistrationDate, checkForHappyDay} from '../../../actions/purchasesActions';
import {setHideKnownCourse, cleanTrainingsCacheCourse} from '../../../actions/trainingActions';
import {toggleCoursePostpone, toggleShowLearntCourseWords} from '../../../actions/userActions';

import colors from '../../../lib/colors';

import Mistakes from "../../../assets/images/home/mistakes.jpg";

class Course1000 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      previewModalVisible: false,
      calendarModalVisible: false,
      trainingsModalVisible: false,
      infoModalVisible: false,
      listInfoModalVisible: false,
      subscriptionAlertVisible: false,
      deleteCourseModalVisible: false,
      courseSettingsModalVisible: false,
      progressModalVisible: false,
      courseRateModalVisible: false,
      currentList: false,
      trainingsSetModal: false,
      slug: this.props.match.params.slug,
      name: 'Course 1000',
      tablet: false,

      deviceWidth: window.innerWidth,
      deviceHeight: window.innerHeight,
      scrolled: 0,
      tour: localStorage.getItem('tour'),
      registerModalVisible: false,
      lockedCourseModal: false,
    };

    this.props.getRegistrationDate();
    this.props.checkForHappyDay();

    this.timeOut = null;
    this.timeOut1 = null;
    this.timeOut2 = null;
    this.timeOut4 = null;

  }

  componentDidMount() {
    const {currentCourseSlug, courseData} =  this.props.course;

    window.addEventListener('scroll', this.handleScroll.bind(this));

    if (this.props.history.location && this.props.history.location.state) {
      this.setState({
        slug: this.props.match.params.slug,
        name: this.props.history.location.state.name,
      });
    }

    if (!currentCourseSlug) {

      let name = "";
      if (this.props.history.location && this.props.history.location.state && this.props.history.location.state.name) {
        name = this.props.history.location.state.name;
      } else {
        if (this.props.match.params.slug == 1000) {
          name = "Course 1000";
        } else if (this.props.match.params.slug == 2000) {
          name = "Course 2000"
        } else if (this.props.match.params.slug == 3000) {
          name = "Course 3000"
        } else if (this.props.match.params.slug == 4000) {
          name = "Course 4000"
        }
      }
      this.props.setCurrentCourseSlug(name, this.props.match.params.slug);

    }
    this.getCourse(this.props.match.params.slug);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      // this.getCourse();
    }
  }

  componentWillUnmount() {
    const {tour} = this.state;

    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut1);
    clearTimeout(this.timeOut2);
    clearTimeout(this.timeOut4);

    window.removeEventListener('scroll', this.handleScroll);

    // this.props.resetCurrentCourseList();

    if (!tour) {
      this.props.checkForCourseDebts(this.props.user.userId);
    }
  }

  handleScroll() {
    const scrollY = window.scrollY;

    this.setState({
      scrolled: scrollY
    });
  }


  getCourse = (slug) => {
    let cache = this._checkCourse(slug);

    if (!cache) {
      this.props.getInitialCourseData();
    } else {
      this.props.setCurrentCourseData(cache);
      this.props.setCourseDataFromCache(cache);
    }
  };

  _checkCourse = (slug) => {
    const {cachedCourseData} = this.props.cache;
    const {currentCourseSlug} =  this.props.course;

    let found = false;

    cachedCourseData.forEach((course, index) => {
      if (course.slug == slug) {
        if (course.lists && course.lists.length !== 0) {
          found = course;
        }
      }
    });

    return found;
  };

  _onPressItem = (list, index, locked, type) => {
    const {locale} = this.props.settings;

    this.props.setCourseList(list);
    this.props.setCourseListType(type);

    this.setState({
      currentList: list
    });

    if (locked) {
      this.props.logActivity({activityId: 258, model: 'courseList', modelId: list.id});
      this._toggleModal('lockedCourseModal', true)
    } else {
      this.props.logActivity({activityId: 253, model: 'courseList', modelId: list.id});
      this.props.history.push(`/${locale}/courselist/${list.id}`, {list})
    }
  };

  _infoPressed = (list) => {
    this.props.logActivity({activityId: 254, model: 'courseList', modelId: list.id});

    this.props.setCourseList(list);

    this.setState({
      currentList: list
    });

    this._toggleListInfoModal(true);
  };

  _onRepeatMistakesPressed = (list, e) => {
    this.props.setCourseListType("mistake");

    this._onRepeatPressed(list, true, e);
  };

  _onRepeatPressed = (list, repeat, e) => {
    const {locale} = this.props.settings;

    e.stopPropagation();
    const {userData} = this.props.user;

    this.props.logActivity({activityId: list.id > 0 ? 255 : 259, model: 'courseList', modelId: list.id});

    this.props.setCourseList(list);
    this.props.setCourseListType("repeat");

    this.setState({
      currentList: list
    });

    if (repeat) {
      if(userData && userData.active_trainings_set && list.id != 0) {
        this._goDirectlyToTraining(list);
      } else {
        this.toggleTrainingButtons(true);
      }
    } else {
      this.props.logActivity({activityId: 253, model: 'courseList', modelId: list.id});
      this.props.history.push(`/${locale}/courselist/${list.id}`, {list})
    }
  };

  _onTestPressed = (list, repeat, e) => {
    const {locale} = this.props.settings;

    e.stopPropagation();
    e.preventDefault();

    const {todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus} = this.props.purchases;

    const {userData} = this.props.user;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    let active = false;

    if (tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    this.setState({
      trainingsModalVisible: false
    });


    this.props.setCourseList(list);
    this.props.setCourseListType("test");

    this.setState({
      currentList: list
    });

    if (active) {
      this.props.history.push(`/${locale}/test/${list.id}`, {type: 'courseListId', from: 'course', courseListId: list.id});
    } else {
      this._toggleSubscriptionAlert(true);
    }

    this.props.logActivity({activityId: 256, model: 'courseList', modelId: list.id});
  };

  toggleTrainingButtons = (status) => {
    this.setState({
      trainingsModalVisible: status
    })
  };

  trainingSelected = (training) => {
    const {locale} = this.props.settings;

    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;

    const {userData} = this.props.user;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    let active = false;

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    this.setState({
      trainingsModalVisible: false
    });

    if (active) {
      const {currentList} = this.state;

      let activityId = '';

      let path = '';

      if (training == 'Preview') {
        activityId = 39;
        path = 'preview'
      } else if (training == 'DragImage') {
        path = 'image';

        activityId = 40;
      } else if (training == 'SelectTranslation') {
        path = 'translation';
        activityId = 41;
      } else if (training == 'SelectWord') {
        path = 'word';
        activityId = 42;
      } else if (training == 'CollectWord') {
        path = 'collect';
        activityId = 43;
      } else if (training == 'CheckSpelling') {
        path = 'spelling';
        activityId = 44;
      } else if (training == 'CollectSentence') {
        activityId = 114;
      } else if (training == 'CollectSentenceTap') {
        activityId = 335;
        path = 'sentence'
      } else if (training == 'MemoryCards') {
        activityId = 131;
        path = 'memory'
      } else if (training == 'WordDefinition') {
        path = 'definition';
        activityId = 148;
      } else if (training == 'SpeakOut') {
        activityId = 95;
      } else if (training == 'MissingLetter') {
        path = 'missing';
        activityId = 315;
      } else if (training == 'Listening') {
        path = 'listening';
        activityId = 345;
      } else if (training == 'TranslateSentence') {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut = setTimeout(()=> {
        this.props.history.push(`/${locale}/${path}/${currentList.id}`, {
          type: 'courseListId',
          from: 'course',
          courseListId: parseInt(currentList.id, 10)
        });
        this.props.logActivity({activityId, model: 'courseList', modelId: currentList.id});
      }, 100);
    } else {
      this._toggleSubscriptionAlert(true);
    }
  };

  _toggleSubscriptionAlert = (status) => {
    this.setState({
      subscriptionAlertVisible: status
    })
  };

  _toSubscription = (productId) => {
    const {locale} = this.props.settings;

    this._toggleSubscriptionAlert(false);

    this.props.history.push(`/${locale}/products`, {back: true, product: productId});
  };

  _onCalendarPressed = (list) => {
    this.props.logActivity({activityId: 257, model: 'courseList', modelId: list.id});

    this.props.setCourseList(list);

    this.setState({
      currentList: list
    });

    this._toggleCalendarModal(true);
  };

  _togglePreviewModal = (status) => {
    this.setState({
      previewModalVisible: status
    })
  };

  _toggleCalendarModal = (status) => {
    this.setState({
      calendarModalVisible: status
    })
  };

  _toggleListInfoModal = (status) => {
    this.setState({
      listInfoModalVisible: status
    });

    if (!status) {
      this.props.resetCourseListData();
    }
  };

  _renderItem = (item, index) => {
    const {fontSizeRation, version} = this.props.settings;
    const {courseData} =  this.props.course;
    const {tablet, deviceWidth, deviceHeight} = this.state;
    const {isConnected} = this.props.settings;
    const {translate} = this.props;

    let testProgress = this.getTestProgress(item, false);

    return (
      <CourseListCell
        key={index}
        deviceWidth={deviceWidth}
        deviceHeight={deviceHeight}
        translate={translate}
        isConnected={isConnected}
        ratio={fontSizeRation}
        list={item}
        tablet={tablet}
        courseData={courseData}
        index={index}
        version={version}
        dots={false}
        testProgress={testProgress}
        onRepeatPressed={this._onRepeatPressed}
        onItemPressed={this._onPressItem}
        onInfoPressed={this._infoPressed}
        onCalendarPressed={this._onCalendarPressed}
      />
    )
  };

  _renderRepeatsItem = (item, index) => {
    const {fontSizeRation, version} = this.props.settings;
    const {courseData} =  this.props.course;
    const {tablet} = this.state;
    const {isConnected} = this.props.settings;
    const {translate} = this.props;

    let dots = this.getDots(item);

    return (
      <CourseListCell
        key={index}
        isConnected={isConnected}
        tablet={tablet}
        ratio={fontSizeRation}
        list={item}
        courseData={courseData}
        index={index}
        repeat={true}
        dots={dots}
        translate={translate}
        testProgress={false}
        version={version}
        onItemPressed={this._onPressItem}
        onRepeatPressed={this._onRepeatPressed}
        onInfoPressed={this._infoPressed}
        onCalendarPressed={this._onCalendarPressed}
      />
    )
  };

  _renderTestItem = (item, index) => {
    const {translate} = this.props;

    const {fontSizeRation, version} = this.props.settings;
    const {courseData} =  this.props.course;
    const {tablet} = this.state;
    const {isConnected} = this.props.settings;

    let testProgress = this.getTestProgress(item, true);

    return (
      <CourseListCell
        key={index}
        isConnected={isConnected}
        tablet={tablet}
        ratio={fontSizeRation}
        list={item}
        testProgress={testProgress}
        courseData={courseData}
        index={index}
        translate={translate}
        test={true}
        dots={false}
        version={version}
        onItemPressed={this._onPressItem}
        onRepeatPressed={this._onTestPressed}
      />
    )
  };

  getDots = (list) => {
    const {tablet} = this.state;

    let trainings = 0;
    let found = false;

    list.repetitions.forEach(day => {
      if (day.repetition_date == moment().format("YYYY-MM-DD")) {
        trainings = day.trainings;
        found = true;
      }
    });

    return [0, 1, 2].map((item, i) => {
      return <div key={`${item}-${i}`}
                  className="dot mh2"
                  style={{backgroundColor: trainings > item ? colors.tealishThree: colors.lightGrey}}/>
    });
  };

  getTestProgress = (list, test) => {
    const {tablet, deviceWidth} = this.state;

    return list.repetitions.map((day, i) => {
      let progress = day.test_results / 100;

      let missed = false;

      if (day.repetition_date < moment().format("YYYY-MM-DD") && day.test == 0) {
        missed = true;
      }

      let color = colors.lightGrey;

      if (missed) {
        color = colors.coral
      } else {
        if (progress > 0 && progress < 0.5) {
          color = colors.coral
        } else if (progress >= 0.5 && progress < 0.75) {
          color = colors.waterBlue
        } else if (progress >= 0.75) {
          color = colors.greenish
        }
      }

      return <div key={i} className="mh02">
        <CircularProgressbar
          value={missed ? 100 : (progress*100)}
          strokeWidth={10}
          styles={buildStyles({
            pathTransitionDuration: 1,
            pathColor: color,
            backgroundColor: missed ? colors.coral : (progress == 1 ? colors.greenish : colors.white)
          })}
        />
      </div>
    });
  };

  generateCourseList = () => {
    const {currentCourseSlug, currentCourseName} =  this.props.course;

    this.props.fetchingData(true);
    this.props.logActivity({activityId: 251});

    this.props.generateCourseLists(currentCourseName, currentCourseSlug);
  };

  getInitialIndex = () => {
    const {fetched, courseData} =  this.props.course;
    const {tablet, deviceWidth} = this.state;

    if (fetched && courseData && courseData.last_step) {
      return Math.floor((courseData.last_step) / (tablet && deviceWidth > 600 ? 3 : 2))
    }
    return 0;
  };

  toggleInfoModal = (status) => {
    this.setState({
      infoModalVisible: status
    })
  };

  _toggleDeleteCourse = (status) => {
    this._toggleCourseSettings(false);

    this.timeOut1 = setTimeout(()=> {
      this.setState({
        deleteCourseModalVisible: status
      })
    }, 100)

  };

  _clearCache = () => {
    const {translate} = this.props;
    this._toggleCourseSettings(false);
    this.props.resetCourseCache();

    toastr.success("", translate('reset_course_cache_cleaned'), [{text: "Ok"}]);

    this.props.logActivity({activityId: 301});
  };

  _toggleCourseSettings = (status) => {
    this.setState({
      courseSettingsModalVisible: status
    })
  };

  _toCourseStatistics = () => {
    this.timeOut2 = setTimeout(()=> {
      this.props.logActivity({activityId: 269});
    }, 100)
  };

  _toCourseBadges = (e) => {
    const {locale} = this.props.settings;

    e.preventDefault();

    const {courseData} =  this.props.course;
    const {tour} = this.state;

    if (!tour) {
      this.props.history.push(`/${locale}/badges`, {
        back: true,
        course: courseData.id,
        from: 'course'
      });
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _toCourseVocabulary = (e) => {
    const {locale} = this.props.settings;

    e.preventDefault();
    const {slug, tour} = this.state;

    if (!tour) {
      this.props.history.push(`/${locale}/course/${slug}/vocabulary`);
      this.props.logActivity({activityId: 271});
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _toCourseCalendar = (e) => {
    const {locale} = this.props.settings;

    e.preventDefault();
    const {tour} = this.state;

    if (!tour) {
      this.props.history.push(`/${locale}/calendar`, {type: 'course', back: true});
      this.props.logActivity({activityId: 272});
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _toCourseMistakes = (e) => {
    const {locale} = this.props.settings;

    e.preventDefault();

    const {slug, tour} = this.state;

    if (!tour) {
      this.props.history.push(`/${locale}/course/${slug}/mistakes`);
      this.props.logActivity({activityId: 305});
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _toCourseInfo = (e) => {
    e.preventDefault();
    this.toggleInfoModal(true);
    this.props.logActivity({activityId: 252});
  };

  toggleProgress = () => {
    const {courseData, lists} =  this.props.course;
    const {translate} = this.props;

    let message = `\n\n${translate('you_opened')}  ${courseData.last_step + 1} / ${lists.length} ${translate('steps')}\n\n`;
    toastr.success(courseData.last_step == 49 ? translate('congratulations') : "", message)
  };

  _onDeleteCourse = () => {
    const {courseData} =  this.props.course;
    const {translate} = this.props;
    const {tour} = this.state;

    if (!tour) {
      toastr.confirm(translate('course_delete_description'), {
        onOk: () => {
          this.props.fetchingData(true);

          this.props.deleteCourseData(courseData);

          this.props.resetCourseCache();
          this.props.resetCourseData();

          this.props.logActivity({activityId: 267});
        },
        okText: translate('delete'),
        cancelText: translate('cancel')
      });
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _toRateCourse = (e) => {
    e.preventDefault();
    const {tour} = this.state;

    if (!tour) {
      this._toggleModal('courseRateModalVisible', true);
      this.props.logActivity({activityId: 288});
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _toggleHideKnown = (e) => {
    e.preventDefault();
    const {userId, userData} = this.props.user;

    const {tour} = this.state;

    if (!tour) {
      this.props.cleanTrainingsCacheCourse();
      this.props.toggleShowLearntCourseWords();
      this.props.logActivity({activityId: 308, comment: userData.show_learnt_course == "0" ? "1" : "0"});
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _togglePostpone = (e) => {
    e.preventDefault();

    const {userData} = this.props.user;
    const {tour} = this.state;

    if (!tour) {
      this.props.fetchingData(true);
      this.props.toggleCoursePostpone();

      this.props.logActivity({activityId: 327, comment: userData.course_postpone == "0" ? "1" : "0"});
    } else {
      this._toggleRegisterModal(true);
    }
  };

  _getCourseName = () => {
    const {slug} =  this.state;

    let name = 'my_first_1000';

    if (slug == 2000) {
      name = 'elementary_course'
    } else if (slug == 3000) {
      name = 'intermediate_course'
    } else if (slug == 4000) {
      name = 'course_oxford_3000'
    }

    return name;
  };

  _getCourseTitle = () => {
    const {currentCourseSlug} =  this.props.course;

    let title = 'course_1000_title';

    if (currentCourseSlug == 2000) {
      title = 'elementary_course_title'
    } else if (currentCourseSlug == 3000) {
      title = 'intermediate_course_title'
    } else if (currentCourseSlug == 4000) {
      title = 'course_oxford_3000_title'
    }

    return title;
  };

  _getCourseDescription = () => {
    const {currentCourseSlug} =  this.props.course;

    let description = 'course_intro';

    if (currentCourseSlug == 2000) {
      description = 'course_intro_elementary'
    } else if (currentCourseSlug == 3000) {
      description = 'course_intro_intermediate'
    } else if (currentCourseSlug == 4000) {
      description = 'course_oxford_3000_description'
    }

    return description;
  };

  _getCourseImage = () => {
    const {currentCourseSlug} =  this.props.course;

    let image = require("../../../assets/images/sitting-boy.png");

    if (currentCourseSlug == 2000) {
      image = require("../../../assets/images/level-elementary.png");
    } else if (currentCourseSlug == 3000) {
      image = require("../../../assets/images/level-intermediate.png");
    } else if (currentCourseSlug == 4000) {
      image = require("../../../assets/images/level-oxford-3000.png");
    }

    return image;
  };

  _toggleModal = (type, status) => {
    this.setState({
      [type]: status
    })
  };

  _toggleRegisterModal = (status) => {
    this.setState({
      registerModalVisible: status
    })
  };

  _toRegister = () => {
    const {locale} = this.props.settings;

    this.props.history.push(`/${locale}/register`)
  };

  _customizeButtons = (e) => {
    e.preventDefault();
    this.toggleTrainingButtons(false);
    this._toggleModal('trainingsSetModal', true)
  };

  _goDirectlyToTraining = (list) => {
    const {subscriptionActive, todayTrainingsLimit, trainingsLimit, applyLimit, webSubscriptionStatus, lifetimeStatus} = this.props.purchases;
    const {userData} = this.props.user;
    const {locale} = this.props.settings;


    let active = false;

    let tempSubscriptionStatus = webSubscriptionStatus;

    if (userData && userData.exchange_date_expires_at && userData.exchange_date_expires_at >= moment().format("YYYY-MM-DD")) {
      tempSubscriptionStatus = true;
    }

    if (lifetimeStatus || subscriptionActive || tempSubscriptionStatus || !applyLimit) {
      active = true;
    } else {
      active = todayTrainingsLimit < trainingsLimit;
    }

    if (active) {
      let trainingIds = userData.trainings_set != null ? userData.trainings_set.split(',').map(Number) : [];

      let trainingId = trainingIds.length > 0 ? trainingIds[0] : 1;

      let activityId = '';
      let path = '';

      if (trainingId == 1) {
        activityId = 39;
        path = 'preview'
      } else if (trainingId == 6) {
        path = 'image';
        activityId = 40;
      } else if (trainingId == 2) {
        path = 'translation';
        activityId = 41;
      } else if (trainingId == 4) {
        path = 'word';
        activityId = 42;
      } else if (trainingId == 3) {
        path = 'collect';
        activityId = 43;
      } else if (trainingId == 5) {
        path = 'spelling';
        activityId = 44;
      } else if (trainingId == 8) {
        activityId = 114;
        path = 'sentence';
      } else if (trainingId == 9) {
        activityId = 131;
        path = 'memory'
      } else if (trainingId == 10) {
        path = 'definition';
        activityId = 148;
      } else if (trainingId == 7) {
        activityId = 95;
      } else if (trainingId == 12) {
        path = 'missing';
        activityId = 315;
      } else if (trainingId == 14) {
        path = 'listening';
        activityId = 345;
      } else if (trainingId == 15) {
        path = 'translate';
        activityId = 353;
      }

      this.timeOut4 = setTimeout(()=> {
        this.props.history.push(`/${locale}/${path}/${list.id}`, {
          type: 'courseListId',
          from: 'listWords',
          listId: list.id
        });
        this.props.logActivity({activityId, model: 'courseList', modelId: list.id, comment: 'from course'});
      }, 100);
    } else {
      this._toggleSubscriptionAlert(true);
    }
  };

  render() {
    const {lists, fetched, tests, repeats, mistakes, courseData, currentCourseSlug} =  this.props.course;
    const {fetchingData} = this.props.activity;
    const {fontSizeRation, locale} = this.props.settings;
    const {translate} = this.props;

    const {
      subscriptionAlertVisible, infoModalVisible,
      trainingsModalVisible, currentList,
      listInfoModalVisible,
      calendarModalVisible,
      courseSettingsModalVisible,
      tablet,
      deviceHeight,
      deviceWidth,
      slug,
      scrolled,
      courseRateModalVisible,
      registerModalVisible,
      tour,
      lockedCourseModal,
      trainingsSetModal
    } = this.state;
    const {hideKnownCourse} = this.props.trainings;

    let initialIndex = this.getInitialIndex();
    const {userData} = this.props.user;
    let level = userData && userData.level ? userData.level : 0;

    let name = this._getCourseName();
    let courseTitle = this._getCourseTitle();
    let courseImage = this._getCourseImage();
    let courseDescription = this._getCourseDescription();

    const toolbar = classNames({
      'fixed-toolbar': scrolled >= 50,
      'justify-content-between coursetoolbar justify-content-center mb-4': true
    });

    const steps = currentCourseSlug == 1000 ? '50' : 60

    return (
      <div className="coursePage">

        <Breadcrumb style={{marginTop: 50}}>
          <li className="breadcrumb-item">
            <Link to={`/${locale}/home`}>
              {translate('home')}
            </Link>
          </li>
          <Breadcrumb.Item active>
            {translate(name)}
          </Breadcrumb.Item>
        </Breadcrumb>

        <CourseToolbar
          ratio={fontSizeRation}
          deviceWidth={deviceWidth}
          deviceHeight={deviceHeight}
          translate={translate}
          toolbar={toolbar}
          userData={userData}
          courseData={courseData}
          fetched={fetched}
          lists={lists}
          hideKnown={hideKnownCourse}

          toMistakes={this._toCourseMistakes}
          toVocabulary={this._toCourseVocabulary}
          toCalendar={this._toCourseCalendar}
          toCourseInfo={this._toCourseInfo}

          onDeleteCourse={this._onDeleteCourse}
          clearCache={this._clearCache}
          toRateCourse={this._toRateCourse}
          setHideKnown={this._toggleHideKnown}
          togglePostpone={this._togglePostpone}
          toBadges={this._toCourseBadges}
          tour={tour}
        />

        <Container className="pageWrapper">
          {
            (courseData && courseData.last_step) ? (
              <OverlayTrigger delay={{ show: 500, hide: 500 }} placement='top'
                              overlay={<Tooltip>{translate('course_progress')}{" "}{`${courseData.last_step + 1}`} / {steps}</Tooltip>}>
                <ProgressBar
                  className="mb-2"
                  style={{width: '100%'}}
                  variant="success"
                  now={courseData && courseData.last_step ? ((courseData.last_step + 1) / lists.length * 100) : 0}
                />
              </OverlayTrigger>
            ) : null
          }

          {
            fetched && lists.length == 0 && (
              <Card className="introWrapper m-auto p-3">
                <span
                  className="introTitle text-center"
                  style={{fontSize: fontSizeRation * (!tablet ? 18 : 20), maxWidth: 600}}
                >
                  {translate(courseTitle)}
                </span>
                <Image
                  className="introImage"
                  style={{height: 140,marginTop: 15, marginBottom: 15}}
                  src={courseImage}
                />

                <div style={{marginTop: 10, marginBottom: 20,  maxWidth: 600}}>
                  <span className="introText"
                        style={{whiteSpace: 'pre-line', fontSize: fontSizeRation * (!tablet ? 16 : 18)}}>
                    {translate(courseDescription)}
                  </span>
                </div>

                <Button
                  style={{width: 200}}
                  variant="outline-success"
                  onClick={this.generateCourseList}
                >
                  <Translate id="start_course"/>
                </Button>
              </Card>
            )
          }

          {
            fetched && mistakes.words > 0 && tests.length == 0 && (
              <Card className="flexH flexCenter" style={{maxWidth: 300, margin: "0 auto", }}>
                <div className="mistakesWrapper">
                  <span
                    className="mistakesTitle d-block text-center"
                    style={{fontSize: fontSizeRation * (!tablet ? 16 : 18)}}>
                    {translate('work_with_mistakes')}
                  </span>
                  <span className="mistakesDescription" style={{fontSize: fontSizeRation * (!tablet ? 12 : 14)}}>
                    {translate('work_with_mistakes_before')}
                  </span>

                  <span className="wordsMistakes" style={{fontSize: fontSizeRation * (!tablet ? 16 : 18)}}>
                    {translate('words')}: {mistakes.words}
                  </span>

                  <Image
                    className="mistakesImage"
                    style={{
                      width: !tablet ? 138 : 276,
                      height: !tablet ? 100 : 200,
                      marginTop: !tablet ? 10 : 20,marginBottom: !tablet ? 10 : 20,
                    }}
                    src={Mistakes}/>

                  <Button
                    variant="outline-success"
                    onClick={(e) => {
                      this._onRepeatMistakesPressed(mistakes, e);
                    }}
                  >
                    {translate('correct_mistakes')}
                  </Button>
                </div>
              </Card>
            )
          }
          {
            fetched && tests.length > 0 && (
              <div style={{flex: 1, marginTop: !tablet ? 15 : 25, marginBottom: !tablet ? 10 : 200}}>
                <span
                  className="d-block font-weight-bold text-center mb-4"
                  style={{fontSize: fontSizeRation * 16, color: colors.greyishBrown}}>
                  {translate('time_for_tests')}
                </span>

                <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
                  {
                    tests.map((list, index) => {
                      return this._renderTestItem(list, index)
                    })
                  }
                </div>
              </div>
            )
          }

          {
            fetched && repeats.length > 0 && mistakes.words == 0 && tests.length == 0 && (
              <div style={{ marginTop: !tablet ? 15 : 25, marginBottom: !tablet ? 10 : 20}}>

                <div style={{width: 300, maxWidth: 300, margin: '0 auto'}}
                     className="d-flex flex-column justify-content-center">
                  <p
                    className="font-weight-bold text-center mb-4"
                    style={{fontSize: fontSizeRation * 16, color: colors.greyishBrown}}>
                    {translate('time_to_repeat')}
                  </p>

                  <p className="text-center ph3 mb4" style={{fontSize: fontSizeRation * 14}}>
                    {translate('we_recommend_three_exercises')}
                  </p>
                </div>

                <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
                  {
                    repeats.map((list, index) => {
                      return this._renderRepeatsItem(list, index)
                    })
                  }
                </div>
              </div>
            )
          }

          {
            fetched && lists.length > 0 && repeats.length == 0 && mistakes.words == 0 && tests.length == 0 && (
              <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
                {
                  lists.map((list, index) => {
                    return this._renderItem(list, index)
                  })
                }
              </div>
            )
          }

          {
            subscriptionAlertVisible && (
              <SubscriptionAlert
                onClose={this._toggleSubscriptionAlert}
                isVisible={subscriptionAlertVisible}
                toSubscription={this._toSubscription}
                title={'subscription_title'}
                description={'subscription_trainings'}
                translate={translate}
              />
            )
          }

          {
            /*calendarModalVisible && (
             <CourseRepetitionsModal
             tablet={tablet}
             isVisible={calendarModalVisible}
             onClose={this._toggleCalendarModal}
             list={currentList}
             ratio={fontSizeRation}
             repetitions={currentList.repetitions}
             />
             )*/
          }
          {
            /*listInfoModalVisible && (
             <ListStatisticsModal
             tablet={tablet}
             isVisible={listInfoModalVisible}
             onClose={this._toggleListInfoModal}
             list={currentList}
             ratio={fontSizeRation}
             />
             )*/
          }
          {
            infoModalVisible && (
              <CourseInfo
                isVisible={infoModalVisible}
                onClose={this.toggleInfoModal}
                slug={currentCourseSlug}
                translate={translate}
              />
            )
          }

          {
            courseRateModalVisible && (
              <CourseRate
                isVisible={courseRateModalVisible}
                onClose={this._toggleModal}
                translate={translate}
                tour={tour}
              />
            )
          }

          {
            lockedCourseModal && (
              <CourseLockedList
                isVisible={lockedCourseModal}
                onClose={this._toggleModal}
                translate={translate}
                tour={tour}
              />
            )
          }

          {trainingsModalVisible && (
            <TrainingButtons
              modalVisible={trainingsModalVisible}
              training={this.trainingSelected}
              onClose={() => this.toggleTrainingButtons(false)}
              ratio={fontSizeRation}
              listId={currentList ? currentList.id : null}
              title="select_training_to_repeat"
              translationLanguage={userData.translation_language}
              course={true}
              level={level}
              tablet={tablet}
              translate={translate}
              customiseButtons={this._customizeButtons}

            />
          )}

          {
            trainingsSetModal && (
              <TrainingsSet
                onClose={this._toggleModal}
                isVisible={trainingsSetModal}
              />
            )
          }

          {registerModalVisible && (
            <RegisterAlert
              onClose={this._toggleRegisterModal}
              isVisible={registerModalVisible}
              translate={translate}
              toRegister={this._toRegister}
            />
          )}
          <Loader fetchingData={fetchingData}/>

        </Container>

        <Helmet>
          <title>{`${translate('app_title_course_words')}`}</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="description" content={translate('app_title_course_words_description')} />
          <meta property="og:url" content={window.location.href} />
          <meta name="og:description" content={translate('app_title_course_words_description')} />
          <meta property="og:title" content={`${translate('app_title_course_words')}`} />
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  activity: state.activity,
  trainings: state.trainings,
  settings: state.settings,
  purchases: state.purchases,
  user: state.user,
  course: state.course,

  cache: state.courseCache,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    fetchingData: (status) => dispatch(fetchingData(status)),
    getInitialCourseData: () => dispatch(getInitialCourseData()),
    getCourseData: () => dispatch(getCourseData()),
    resetCourseData: () => dispatch(resetCourseData()),
    resetCurrentCourseList: () => dispatch(resetCurrentCourseList()),
    getCourseListData: (listId) => dispatch(getCourseListData(listId)),
    getCourseListCalendar: (listId) => dispatch(getCourseListCalendar(listId)),
    setFetchedCourseData: (status) => dispatch(setFetchedCourseData(status)),
    generateCourseLists: (name, slug) => dispatch(generateCourseLists(name, slug)),
    resetCourseListData: () => dispatch(resetCourseListData()),
    logActivity: (data) => dispatch(logActivity(data)),
    deleteCourseData: (courseData) => dispatch(deleteCourseData(courseData)),
    getRegistrationDate: () => dispatch(getRegistrationDate()),
    checkForCourseDebts: (userId) => dispatch(checkForCourseDebts(userId)),
    setCourseDataFromCache: (data) => dispatch(setCourseDataFromCache(data)),
    resetCourseCache: () => dispatch(resetCourseCache()),
    setCourseListType: (type) => dispatch(setCourseListType(type)),
    setCourseList: (list) => dispatch(setCourseList(list)),

    setHideKnownCourse: (status) => dispatch(setHideKnownCourse(status)),
    cleanTrainingsCacheCourse: () => dispatch(cleanTrainingsCacheCourse()),
    toggleCoursePostpone: () => dispatch(toggleCoursePostpone()),
    checkForHappyDay: () => dispatch(checkForHappyDay()),
    toggleShowLearntCourseWords: () => dispatch(toggleShowLearntCourseWords()),
    setCurrentCourseData: (course) => dispatch(setCurrentCourseData(course)),
    setCurrentCourseSlug: (name, slug) => dispatch(setCurrentCourseSlug(name, slug)),

  };
}

export default connect(mapStateToProps, bindAction)(Course1000);
