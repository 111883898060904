import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logApiError} from './logSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* getVocabulary(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;

  let param = {};
  param.user_id = userId;

  if(params.word) {
    param.search = params.word;
  } else {
    param.page = params.page;
  }

  try {
    const result = yield request
    // .get(API_URL + 'getVocabulary')
    .get(API_URL + 'getVocabularyOptimized')
    .query(param);

    // console.log("getVocabulary", result.body)
    
    if (params.page) {
      yield put({
        type: types.SET_VOCABULARY_WORDS,
        payload: result.body
      });
    } else {
      yield put({
        type: types.FOUND_VOCABULARY_WORDS,
        payload: result.body
      });
    }
   
  } catch (error) {
    yield logApiError({payload: {function: 'getVocabulary', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* setGoal(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'setGoal')
    .send({
      user_id: userId,
      goal: params,
    });

    // console.log("setGoal", result.body)

    if (result.statusCode === 200) {
      yield put({
        type: types.SET_USER_GOAL,
        payload: params
      });
      
      setUserGoal(params);
    }
  } catch (error) {
    yield logApiError({payload: {function: 'setGoal', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

const setUserGoal = async(goal) => {
  try {
    // await AsyncStorage.setItem('userGoal', `${goal}`);
  } catch (error) {
    // console.log(error.message);
  }
};

export function* deleteFromVocabulary(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'deleteFromVocabulary')
    .send({
      user_id: userId,
      id: params.wordId,
    });

    if (result.statusCode === 200) {
      yield put({
        type: types.VOCABULARY_WORD_DELETED,
        payload: params.index
      });
    }
  } catch (error) {
    yield logApiError({payload: {function: 'deleteFromVocabulary', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* toggleFavoriteWord(data) {
  const {userId} = yield select(state => state.user);
  const params = data.payload;

  try {
    const result = yield request
    .post(API_URL + 'addGlossaryWordToFavourites')
    .send({
      user_id: userId,
      word_id: params.wordId,
    });
    
    if (result.statusCode === 200) {
      yield put({
        type: types.VOCABULARY_WORD_TOGGLED_IN_FAVORITES,
        payload: data.payload.index
      });
    }
  } catch (error) {
    yield logApiError({payload: {function: 'addGlossaryWordToFavourites', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}
