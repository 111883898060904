import * as types from './types';

export const getListsByLang = (filter, order) => (dispatch) => {
  dispatch({ type: types.GET_USER_LISTS, payload: {filter, order} });
};

export const searchLists = (listName) => (dispatch) => {
  dispatch({ type: types.SEARCH_USER_LISTS, payload: listName });
};

export const getListWords = (id) => (dispatch) => {
  dispatch({ type: types.GET_LIST_WORDS, payload: id });
};

export const getCurrentListData = (id) => (dispatch) => {
  dispatch({ type: types.GET_CURRENT_LIST_DATA, payload: id });
};

export const resetListWords = () => (dispatch) => {
  dispatch({ type: types.RESET_LIST_WORDS });
};

export const setCurrentList = (list) => (dispatch) => {
  dispatch({ type: types.SET_CURRENT_LIST, payload: list });
};

export const deleteListWord = (list_id, word_id, index) => (dispatch) => {
  dispatch({ type: types.DELETE_LIST_WORD, payload: {list_id, word_id, index} });
};

export const toggleFavoriteWord = (list_id, word_id, index) => (dispatch) => {
  dispatch({ type: types.TOGGLE_LIST_WORD_FAVORITE, payload: {list_id, word_id, index} });
};

export const toggleFavouriteList = (list_id, index, favorites, favorite, fromList) => (dispatch) => {
  dispatch({ type: types.TOGGLE_LIST_FAVORITE, payload: {list_id, index, favorites, favorite, fromList} });
};

export const toggleInVocabulary = (word_id, index, fav) => (dispatch) => {
  dispatch({ type: types.TOGGLE_LIST_WORD_VOCABULARY, payload: {word_id, index, fav} });
};

export const getListsNumber = () => (dispatch) => {
  dispatch({ type: types.GET_LISTS_NUMBER});
};

export const addList = (listName, filter, order) => (dispatch) => {
  dispatch({ type: types.ADD_NEW_LIST, payload: {listName, filter, order} });
};

export const addNewList = (listName) => (dispatch) => {
  dispatch({ type: types.ADD_NEW_USER_LIST, payload: {listName} });
};

export const editList = (listName, listId, filter, order) => (dispatch) => {
  dispatch({ type: types.EDIT_LIST_NAME, payload: {listName, listId, filter, order} });
};

export const deleteList = (listId, filter, order) => (dispatch) => {
  dispatch({ type: types.DELETE_LIST, payload: {listId, filter, order} });
};

export const getListsToRepeat = (limit, userId) => (dispatch) => {
  dispatch({ type: types.GET_LISTS_TO_REPEAT, payload: {limit, userId} });
};

export const postponeInCalendar = (listId) => (dispatch) => {
  dispatch({ type: types.POSTPONE_IN_CALENDAR, payload: listId });
};

export const removeFromCalendar = (listId, filter, order) => (dispatch) => {
  dispatch({ type: types.REMOVE_FROM_CALENDAR, payload: {listId, filter, order} });
};

export const getUserListsToFill = () => (dispatch) => {
  dispatch({ type: types.GET_LISTS_TO_FILL });
};

export const fillListFromGlossaryWithSelectedWords = (data) => (dispatch) => {
  dispatch({ type: types.FILL_LISTS_WITH_GLOSSARY_WORDS, payload: data });
};

export const toggleWordInUserList = (data) => (dispatch) => {
  dispatch({ type: types.TOGGLE_GLOSSARY_WORD_IN_LIST, payload: data });
};

export const generateListsNotWizard = (words, glossaryId,  level, byLists) => (dispatch) => {
  dispatch({ type: types.GENERATE_LISTS_NOT_WIZARD, payload: {words, glossaryId, level, byLists} });
};

export const addUserWords = (text, allWords, listId) => (dispatch) => {
  dispatch({ type: types.ADD_USER_WORDS, payload: {text, allWords,listId} });
};

export const deleteListFromList = (listId, back, history) => (dispatch) => {
  dispatch({ type: types.DELETE_LIST_FROM_LIST, payload: {listId, back, history} });
};

export const sendListCards = (data) => (dispatch) => {
  dispatch({ type: types.SEND_LIST_CARDS, payload: data });
};

export const deleteSelectedLists = (lists) => (dispatch) => {
  dispatch({ type: types.DELETE_SELECTED_LISTS, payload: lists });
};

export const toggleNewListsModal = (status) => (dispatch) => {
  dispatch({ type: types.SET_SHOW_NEWLY_CREATED_LISTS, payload: status });
};

export const toggleListTrainingButtonsModal = (status) => (dispatch) => {
  dispatch({ type: types.SET_SHOW_LIST_TRAINING_BUTTONS_MODAL, payload: status });
};

export const updateCurrentListWords = () => (dispatch) => {
  dispatch({ type: types.UPDATE_CURRENT_LIST_ONE_WORDS });
};

export const setCachedListWords = (data) => (dispatch) => {
  dispatch({ type: types.SET_LIST_WORDS, payload: data });
};

export const resetHomeLists = () => (dispatch) => {
  dispatch({ type: types.RESET_LISTS_TO_REPEAT });
};

export const resetUserLists = () => (dispatch) => {
  dispatch({ type: types.RESET_USER_LISTS });
};

export const resetCurrentList = () => (dispatch) => {
  dispatch({ type: types.RESET_CURRENT_LIST });
};

export const cleanListsCache = () => (dispatch) => {
  dispatch({ type: types.CLEAN_LISTS_CACHE });
};

export const cleanListCache = (listId) => (dispatch) => {
  dispatch({ type: types.CLEAN_LIST_CACHE, payload: listId });
};

export const getOnlyMistakes = () => (dispatch) => {
  dispatch({ type: types.GET_ONLY_MISTAKES });
};

export const toggleArchiveList = (id) => (dispatch) => {
  dispatch({ type: types.TOGGLE_ARCHIVE_LIST, payload: id });
};

export const setShowArchive = (status) => (dispatch) => {
  dispatch({ type: types.TOGGLE_SHOW_ARCHIVED_LIST, payload: status });
};

export const addImageToListWords = (imageData) => (dispatch) => {
  dispatch({ type: types.ADD_IMAGE_TO_LIST_WORDS, payload: imageData });
};

export const addImageToLists = (imageData) => (dispatch) => {
  dispatch({ type: types.ADD_IMAGE_TO_LISTS, payload: imageData });
};

export const saveScannedText = (text, flag, excludeLists) => (dispatch) => {
  dispatch({ type: types.SAVE_SCANNED_TEXT, payload: {text, flag, excludeLists} });
};

export const clearScannedText = () => (dispatch) => {
  dispatch({ type: types.CLEAR_SCANNED_TEXT, payload: false });
};

export const changeListViewType = (type) => (dispatch) => {
  dispatch({ type: types.CHANGE_LIST_VIEW_TYPE, payload: type });
};

export const performBulkAction = (listId,words, type, source) => (dispatch) => {
  dispatch({ type: types.PERFORM_BULK_ACTION, payload: { listId,words,type, source} });
};

export const moveToOtherLists = (fromListId, toListId, words, source) => (dispatch) => {
  dispatch({ type: types.MOVE_TO_OTHER_LISTS, payload: { fromListId, toListId, words, source } });
};

export const addCourseWordToList = (listId, wordId) => (dispatch) => {
  dispatch({ type: types.ADD_COURSE_WORD_TO_LIST, payload: { listId, wordId } });
};
