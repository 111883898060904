import React, {Component} from 'react';
import {connect} from "react-redux";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import colors from '../../../lib/colors';

const Mistakes = ({translate, mistakes, mistakesToRepeat, ratio, onPressCard, onRepeatPressed}) => {

  return (

    mistakesToRepeat && (
      <div>
        <Card
          style={{width: 200,height: 300}}
          className="mistakesWrapper ml-2 mr-2"
          onClick={(e)=>{
            onPressCard({
              id: 0,
              list_name: translate('mistakes_list'),
              favourite : false,
              words : mistakes.words
           }, 0, 'mistake', e)
        }}
        >
          <p
            className="mb-0 listName font-weight-bold"
            style={{fontSize: ratio * 14, whiteSpace: 'pre-line'}}>
            {translate('with_mistakes_simple_lists')}
          </p>

          <Image
            className="mistakesImage"
            src={require("../../../assets/images/home/mistakes.jpg")}
          />

          <div className="flexH mb-3">
            <span
              className="scores"
              style={{fontSize: ratio * 14}}
            >
              {translate('wordss')}:
            </span>
            <span
              className="scoresNum"
              style={{fontSize: ratio * 14}}
            >
              {mistakes.words}
            </span>
          </div>

          <Button
            variant="outline-success"
            onClick={(e) => onRepeatPressed(mistakes,'mistakes', e)}
          >
            {translate('repeat')}
          </Button>
        </Card>
        </div>
    ) || null

  )
};

export default connect(null, null)(Mistakes);
