import * as types from '../actions/types';

const INITIAL_STATE = {
  everydayPhrase: false,
  everydayWord: false,
  gettingWord: false,
  gettingPhrase: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_EVERYDAY_WORD:
      return Object.assign({}, state, {
        everydayWord: action.payload,
      });

    case types.SET_EVERYDAY_PHRASE:
      return Object.assign({}, state, {
        everydayPhrase: action.payload,
      });
    
    case types.SET_GETTING_PHRASE:
      return Object.assign({}, state, {
        gettingPhrase: action.payload,
      });
    
    case types.SET_GETTING_WORD:{
      return Object.assign({}, state, {
        gettingWord: action.payload,
      });}

    default:
      return state;
  }
}