import React, {Component} from 'react';
import {connect} from "react-redux";
import {Translate, getTranslate} from 'react-localize-redux';
import {toastr} from 'react-redux-toastr';
import {withRouter, Link} from 'react-router-dom';
import {CircularProgressbar} from 'react-circular-progressbar';

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import Badge from '../Badge/index';
import BadgeModal from '../BadgeModal/index';
import Loader from "../../../components/Custom/Loader/index";

import {getUserBadges, resetBadges} from '../../../actions/badgeActions';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';

class UserBadges extends Component {

  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      badgeModalVisible: false,
      badge: {},
      badgeImage: require('../../../assets/badges/unknown-badge.png'),
      tablet: false,
      from: false,
      course: false,
      back: false
    };

    this.timeOut = null;
    this.timeOut2 = null;
  }

  componentDidMount() {

    if (this.props.history.location && this.props.history.location.state) {
      this.setState({
        from: this.props.history.location.state.from,
        course: this.props.history.location.state.course,
        back: this.props.history.location.state.back
      })
    }

    this.timeOut = setTimeout(() => {
      this.getBadges();
    }, 100);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.isConnected !== prevProps.settings.isConnected && this.props.settings.isConnected === true) {
      this.getBadges();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
    clearTimeout(this.timeOut2);

    this.props.resetBadges();
  }

  getBadges = () => {
    const {isConnected} = this.props.settings;
    const {course} = this.state;

    if (isConnected) {
      this.props.fetchingData(true);

      this.props.getUserBadges(course);
    }
  };

  _onPressItem = (badge, badgeImage, e) => {
    e.preventDefault();

    this.setState({
      badge,
      badgeImage,
      badgeModalVisible: true
    });
  };

  _renderItem = (item, index) => {
    const {fontSizeRation} = this.props.settings;
    const {tablet} = this.state;
    const {translate} = this.props;

    return (
      <Badge
        key={index}
        tablet={tablet}
        badge={item}
        ratio={fontSizeRation}
        index={index}
        translate={translate}
        onItemPressed={this._onPressItem}
      />
    )
  };

  toggleModal = (status) => {
    this.setState({
      badgeModalVisible: status
    });

    if (!status) {
      this.setState({
        badge: {}
      })
    }
  };

  _navigateTo = (segue, e) => {
    e.preventDefault();

    this.toggleModal(false);

    this._makeLog(segue);

    if (segue == 'ShareApp') {
      this._openShareDialog()
    } else if (segue == 'LeaveReview') {
      // let appUrl = Platform.OS == 'android' ? 'market://details?id=com.mnemonicrn'
      //   : 'itms://itunes.apple.com/app/apple-store/id1438980777?mt=8';
      // let webUrl = Platform.OS == 'android' ? 'http://play.google.com/store/apps/details?id=com.mnemonicrn' : 'https://itunes.apple.com/app/id1438980777';

      /*Linking.canOpenURL(appUrl)
       .then((supported) => {
       if (!supported) {
       return Linking.openURL(webUrl);
       } else {
       return Linking.openURL(appUrl);
       }
       })
       .catch((err) => console.error('An error occurred', err));*/
    } else if (segue == 'Instagram') {
      let appUrl = 'instagram://user?username=mnemonicwords';
      let webUrl = 'https://instagram.com/mnemonicwords';

      /* Linking.canOpenURL(appUrl)
       .then((supported) => {
       if (!supported) {
       return Linking.openURL(webUrl);
       } else {
       return Linking.openURL(appUrl);
       }
       })
       .catch((err) => console.error('An error occurred', err));*/
    } else if (segue == 'Facebook') {
      let appUrl = 'fb://profile/mnemonicwords';
      let webUrl = 'https://www.facebook.com/Mnemonicwords';

      /* Linking.canOpenURL(appUrl)
       .then((supported) => {
       if (!supported) {
       return Linking.openURL(webUrl);
       } else {
       return Linking.openURL(appUrl);
       }
       })
       .catch((err) => console.error('An error occurred', err));*/
    } else if (segue == 'SubscriptionPlans') {
      this.props.history.push(`/products`, {product: 'yearly_subscription'})

    } else if (segue == 'BuyGlossaryCards') {
      this.props.history.push(`/products`,  {product: 'glossary_cards'})

    } else if (segue == 'BuyListCards') {
      this.props.history.push(`/products`,  {product: 'list_cards'})

    } else {
      this.props.history.push(`/${segue}`)
    }

    this.timeOut2 = setTimeout(() => {
      this.getBadges();
    }, 1000);
  };

  _makeLog = (segue) => {
    let activityId = false;

    if (segue == 'ShareApp') {
      activityId = 99;
    } else if (segue == 'LeaveReview') {
      activityId = 206;
    } else if (segue == 'CourseRate') {
      activityId = 288;
    } else if (segue == 'Instagram') {
      activityId = 170;
    } else if (segue == 'Facebook') {
      activityId = 169;
    } else if (segue == 'PromoCode') {
      activityId = 261;
    } else if (segue == 'SubscriptionPlans') {
      activityId = 195;
    } else if (segue == 'BuyListCards') {
      activityId = 244;
    } else if (segue == 'BuyGlossaryCards') {
      activityId = 264;
    } else if (segue == 'UserProfile') {
      activityId = 67;
    }

    if (activityId) {
      this.props.logActivity({activityId});
    }
  };

  _openShareDialog() {
    /*const shareOptions = {
     title: translate('share_via'),
     message: translate('share_message'),
     url: Platform.OS == 'android' ? 'https://play.google.com/store/apps/details?id=com.mnemonicrn' : 'https://itunes.apple.com/app/id1438980777',
     subject: "Mnemonic Words"
     };

     Share.open(shareOptions)
     .then((res) => {
     this.props.logActivity({activityId: 101, comment: res.app});
     })
     .catch((err) => {
     this.props.logActivity({activityId: 100});
     err && console.log(err);
     }
     );*/
  }

  render() {
    const {badgeModalVisible, badge, badgeImage, tablet, from, back} = this.state;
    const {badges, opened, fetched} = this.props.badges;
    const {fontSizeRation, deviceWidth, deviceHeight} = this.props.settings;
    const {translate} = this.props;

    const {fetchingData} = this.props.activity;

    return (
      <div className="userbadges">
        <Breadcrumb style={{marginTop: 50}}>
          {
            !back ? (
              <React.Fragment>
                <li className="breadcrumb-item">
                  <Link to="/home">
                    {translate('home')}
                  </Link>
                </li>

                <Breadcrumb.Item active>
                  {translate(from == 'course' ? "course_badges_title" : "general_badges_title")}
                </Breadcrumb.Item>
              </React.Fragment>
            ) : (
              <li className="breadcrumb-item">
                <a href="#" onClick={(e)=>{
                  e.preventDefault();
                  this.props.history.goBack()
                }}>
                  {translate('cancel_back')}
                </a>
              </li>
            )
          }
        </Breadcrumb>

        <Container className="pageWrapper">

          {
            fetched && (
              <p className="opened" style={{fontSize: fontSizeRation * 16}}>
                {`${translate('got_badges')} ${opened} ${translate('from')} ${badges.length}`} {translate('badges')}
              </p>
            )
          }

          <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
            {
              badges.map((item, index) => {
                return this._renderItem(item, index);
              })
            }
          </div>

          {
            badgeModalVisible && (
              <BadgeModal
                isVisible={badgeModalVisible}
                ratio={fontSizeRation}
                badge={badge}
                tablet={tablet}
                badgeImage={badgeImage}
                onClose={this.toggleModal}
                toSegue={this._navigateTo}
                deviceWidth={deviceWidth}
                deviceHeight={deviceHeight}
                translate={translate}
              />
            )
          }
        </Container>
        <Loader fetchingData={fetchingData}/>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  badges: state.badges,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize)
});

function bindAction(dispatch) {
  return {
    logActivity: (data) => dispatch(logActivity(data)),
    resetBadges: () => dispatch(resetBadges()),
    fetchingData: (status) => dispatch(fetchingData(status)),
    getUserBadges: (course) => dispatch(getUserBadges(course))
  };
}

export default connect(mapStateToProps, bindAction)(UserBadges);
