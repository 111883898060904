import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Translate} from 'react-localize-redux';

const CourseWordSettings = ({
  isVisible,
  ratio,
  onClose,
  word,
  deleteCourseWord,
  toggleInVocabulary,
  showLists
}) => (
  <Modal
    className="wordsettings"
    size="sm"
    show={isVisible}
    onHide={()=>onClose(false)}
    keyboard={false}
  >
    <Modal.Header closeButton>
      <Modal.Title as="h5">
        {word.word}
      </Modal.Title>
    </Modal.Header>

    <Modal.Body className="body">
      <ListGroup variant="flush">
        <ListGroup.Item as="button"  onClick={toggleInVocabulary}>
          <Translate id={word.vocabulary ? 'delete_from_course_vocabulary' : 'add_to_course_vocabulary'}/>
        </ListGroup.Item>

        <ListGroup.Item as="button"  onClick={showLists}>
          <Translate id={'add_course_word_to_list'}/>
        </ListGroup.Item>

        <ListGroup.Item as="button" className="text-danger"  onClick={deleteCourseWord}>
          <Translate id={'delete_word'}/>
        </ListGroup.Item>


      </ListGroup>
    </Modal.Body>
  </Modal>
);

export default CourseWordSettings;