import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logUserList, logCourseList} from './logSagas';
import {logTrainingLimits, getRegistrationDate} from './purchasesSagas';
import {getOnlyMistakes} from './lists';
import {logApiError} from './logSagas';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* getUpdatedWordForTraining(data) {
  const {userId} = yield select(state => state.user);

  const params = data.payload;

  try {
    const result = yield request
    .get(API_URL + "getUpdatedTranslations")
    // .get(API_URL + "getUpdatedWordForTraining")
    .query({
      user_id: userId,
      word_id: params.wordId,
      translations_limit: 5
    });

    // console.log("getUpdatedWordForTraining",result.body, params)
    
    if(result.body.mainTranslation) {
      yield put({
        type: types.UPDATE_WORD_TRANSLATIONS_FOR_TRAINING,
        payload: {
          wordId: params.wordId,
          mainTranslation: result.body.mainTranslation,
          otherTranslationsString: result.body.otherTranslationsString,
          // translations: result.body.translations
        }
      })

      if(params.listId) {
        yield put({
          type: types.UPDATE_CACHED_TRAINING_WORD_TRANSLATIONS,
          payload: {
            listId: params.listId,
            wordId: params.wordId,
            mainTranslation: result.body.mainTranslation,
            otherTranslationsString: result.body.otherTranslationsString,
          }
        })
      }


      if(params.courseListId) {
        yield put({
          type: types.UPDATE_CACHED_TRAINING_WORD_TRANSLATIONS_COURSE,
          payload: {
            listId: params.courseListId,
            wordId: params.wordId,
            mainTranslation: result.body.mainTranslation,
            otherTranslationsString: result.body.otherTranslationsString,
          }
        })
      }
    }
  } catch (error) {
    yield logApiError({payload: {function: 'getUpdatedWordForTraining', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    
  }
}

export function* getWordsForTraining(data) {
  const params = data.payload;

  const page = data.payload.page;

  const {userId, userData} = yield select(state => state.user);
  const {currentCourseId} = yield select(state => state.course);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + params.endpoint)
    // .post(API_URL + "getTrainingWords")
    .send({
      user_id: userId && userId > 0 ? userId : 1,
      training_id : params.trainingId,
      page : params.page,
      list_id: params.listId,
      glossary_id: params.glossaryId,
      phrasebook_id: params.phrasebookId,
      course_list_id: params.courseListId,
      course_id: currentCourseId,
      translations_limit: 5,
      hideKnown: params.listId ? !userData.show_learnt : !userData.show_learnt_course
    });

    // console.log("getWordsForTraining",result.body)

    if(!params.updateWords) {
      yield getRegistrationDate();
    }

    if(!params.updateWords) {
      if (result.body.data.length > 0 && params.page == 1 && params.listId && params.listId > 0) {
        yield logUserList({payload: {trainingId: params.trainingId, listId: params.listId, type: "start"}});
      } else if (result.body.data.length > 0 && params.page == 1 && params.courseListId && params.courseListId > 0) {
        yield logCourseList({payload: {trainingId: params.trainingId, listId: params.courseListId, type: "start"}});
      }
    }

    if (params.trainingId === 8 || params.trainingId === 15) {
      if(params.page == 1 && result.body.data.length == 0) {
        if(!params.updateWords) {

          yield getOnlyMistakes();

          data.payload.history.goBack();
          toastr.warning('', params.trainingId === 8 ? translate('not_enough_words_examples') : translate('not_enough_words_examples_translations'));
        }
      } else {
        yield put({
          type: types.SET_WORDS_FOR_TRAINING_COLLECT_SENTENCE,
          payload: result.body
        });

        if(params.listId && params.listId > 0) {
          yield put({
            type: types.SET_CACHED_COLLECT_SENTENCE_LIST_WORDS,
            payload: {
              page: page,
              data: result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }

        if(params.courseListId && params.courseListId > 0) {
          yield put({
            type: types.SET_COURSE_CACHED_COLLECT_SENTENCE_LIST_WORDS,
            payload: {
              page: page,
              data: result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }

        if(!params.updateWords) {
          yield logTrainingLimits();
        }
      }
    } else if (params.trainingId === 14) {
      if(params.page == 1 && result.body.data.length == 0) {
        if(!params.updateWords) {

          yield getOnlyMistakes();

          data.payload.history.goBack();
          toastr.warning('', translate('not_enough_words'));
        }
      } else {
        yield put({
          type: types.SET_WORDS_FOR_TRAINING_LISTENING,
          payload: result.body
        });

        if(params.listId && params.listId > 0) {
          yield put({
            type: types.SET_CACHED_LISTENING_LIST_WORDS,
            payload: {
              page: page,
              data: result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }

        if(params.courseListId && params.courseListId > 0) {
          yield put({
            type: types.SET_COURSE_CACHED_LISTENING_LIST_WORDS,
            payload: {
              page: page,
              data: result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }

        if(!params.updateWords) {
          yield logTrainingLimits();
        }
      }
    } else if (params.trainingId === 12) {
      if(params.page == 1 && result.body.data.length == 0) {
        if(!params.updateWords) {

          yield getOnlyMistakes();

          data.payload.history.goBack();
          toastr.warning('', translate('not_enough_words'));
        }
      } else {
        yield put({
          type: types.SET_WORDS_FOR_MISSING_LETTER,
          payload: {data: result.body, language: userData && userData.study_language ? userData.study_language : 'en'}
        });

        if(params.listId && params.listId > 0) {
          yield put({
            type: types.SET_CACHED_MISSING_LETTER_LIST_WORDS,
            payload: {
              page: page,
              data: result.body,
              trainingId: params.trainingId,
              listId: params.listId,
              language: userData && userData.study_language ? userData.study_language : 'en'
            }
          });
        }

        if(params.courseListId && params.courseListId > 0) {
          yield put({
            type: types.SET_COURSE_CACHED_MISSING_LETTER_LIST_WORDS,
            payload: {
              page: page,
              data: result.body,
              trainingId: params.trainingId,
              listId: params.courseListId,
              language: userData && userData.study_language ? userData.study_language : 'en'
            }
          });
        }

        if(!params.updateWords) {
          yield logTrainingLimits();
        }
      }
    } else if(params.trainingId === 9) {
      if(params.page = 1 && result.body.data.length < 5) {
        if(!params.updateWords) {
          yield getOnlyMistakes();

          data.payload.history.goBack();
          toastr.warning('', translate('not_enough_words'));
        }
      } else {
        yield put({
          type: types.SET_WORDS_FOR_TRAINING_MEMORY_CARDS,
          payload: result.body
        });

        if(params.listId && params.listId > 0) {
          yield put({
            type: types.SET_CACHED_MEMORY_CARD_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }
        
        if(params.courseListId && params.courseListId > 0) {
          yield put({
            type: types.SET_COURSE_CACHED_MEMORY_CARD_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }

        yield put({
          type: types.TOGGLE_SHOW_MEMORY_CARDS,
          payload: true
        });

        if(!params.updateWords) {
          yield logTrainingLimits();
        }
      }
    }
    else {
      if(params.page == 1 && result.body.data.length == 0) {
        if(!params.updateWords) {
          yield getOnlyMistakes();

          data.payload.history.goBack();
          toastr.warning('', translate('not_enough_words'));
        }
      } else {
        yield put({
          type: types.SET_WORDS_FOR_TRAINING,
          payload: result.body
        });

        if(params.listId && params.listId > 0 && params.trainingId == 1) {
          yield put({
            type: types.SET_CACHED_PREVIEW_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }

        if(params.listId && params.listId > 0 && params.trainingId == 3) {
          yield put({
            type: types.SET_CACHED_COLLECT_WORD_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }

        if(params.listId && params.listId > 0 && params.trainingId == 5) {
          yield put({
            type: types.SET_CACHED_CHECK_SPELLING_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }
        
        // COURSE
        if(params.courseListId && params.courseListId > 0 && params.trainingId == 1) {
          yield put({
            type: types.SET_COURSE_CACHED_PREVIEW_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }

        if(params.courseListId && params.courseListId > 0 && params.trainingId == 3) {
          yield put({
            type: types.SET_COURSE_CACHED_COLLECT_WORD_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }

        if(params.courseListId && params.courseListId > 0 && params.trainingId == 5) {
          yield put({
            type: types.SET_COURSE_CACHED_CHECK_SPELLING_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }
        
        if(params.listId && params.listId > 0 && params.trainingId == 2) {
          yield put({
            type: types.SET_CACHED_SELECT_TRANSLATION_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }

        // COURSE
        if(params.courseListId && params.courseListId > 0 && params.trainingId == 2) {
          yield put({
            type: types.SET_COURSE_CACHED_SELECT_TRANSLATION_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }
        
        if(params.listId && params.listId > 0 && params.trainingId == 4) {
          yield put({
            type: types.SET_CACHED_SELECT_WORD_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }

        // COURSE
        if(params.courseListId && params.courseListId > 0 && params.trainingId == 4) {
          yield put({
            type: types.SET_COURSE_CACHED_SELECT_WORD_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }

        if(params.listId && params.listId > 0 && params.trainingId == 6) {
          yield put({
            type: types.SET_CACHED_DRAG_IMAGE_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }

        // COURSE
        if(params.courseListId && params.courseListId > 0 && params.trainingId == 6) {
          yield put({
            type: types.SET_COURSE_CACHED_DRAG_IMAGE_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }

        if(params.listId && params.listId > 0 && params.trainingId == 10) {
          yield put({
            type: types.SET_CACHED_DEFINITION_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.listId
            }
          });
        }

        // COURSE
        if(params.courseListId && params.courseListId > 0 && params.trainingId == 10) {
          yield put({
            type: types.SET_COURSE_CACHED_DEFINITION_LIST_WORDS,
            payload: {
              page: page,
              data:result.body,
              trainingId: params.trainingId,
              listId: params.courseListId
            }
          });
        }

        if(!params.updateWords) {
          yield logTrainingLimits();
        }
      }
    }
  } catch (error) {
    yield logApiError({payload: {function: `${params.endpoint}`, payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getGoogleExampleTranslation(data) {
  const params = data.payload;

  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'getGoogleExampleTranslation')
    .send({
      user_id: userId,
      example_id : params.exampleId,
      word_id : params.wordId,
    });
    
    if(result.body.data) {
      yield put({
        type: types.SET_NEW_GOOGLE_EXAMPLE_TRANSLATION,
        payload: {translation: result.body.data, wordId: params.wordId, exampleId: params.exampleId}
      });
    }
  } catch (error) {
    yield logApiError({payload: {function: 'getGoogleExampleTranslation', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* addUserExampleTranslation(data) {
  const params = data.payload;

  const {userId} = yield select(state => state.user);
  const translate = yield select(state => getTranslate(state.localize));

  try {
    const result = yield request
    .post(API_URL + 'addUserExampleTranslation')
    .send({
      user_id: userId,
      example_id : params.exampleId,
      word_id : params.wordId,
      translation: params.translation,
    });

    // console.log("addUserExampleTranslation",result.body);
    if(result.body.result) {
      yield put({
        type: types.SET_NEW_GOOGLE_EXAMPLE_TRANSLATION,
        payload: {translation: params.translation.trim(), wordId: params.wordId, exampleId: params.exampleId}
      });
      
      toastr.info('', translate(result.body.result == 2 ? 'suggest_translation_already' : 'suggest_translation_added'));
    } else {
      toastr.error('', translate('error'));
    }
  } catch (error) {
    yield logApiError({payload: {function: 'addUserExampleTranslation', payload: data.payload, error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

