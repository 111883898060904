import * as types from './types';

export const logListWord = (data) => (dispatch) => {
    dispatch({ type: types.LOG_LIST_WORD, payload: data });
};

export const logSharedLinkVisit = (data) => (dispatch) => {
    dispatch({ type: types.LOG_SHARED_LINK_VISIT, payload: data });
};

export const logUserList = (data) => (dispatch) => {
    dispatch({ type: types.LOG_USER_LIST, payload: data });
};

export const logPhrase = (data) => (dispatch) => {
    dispatch({ type: types.LOG_PHRASE, payload: data });
};

export const logGlossaryWord = (data) => (dispatch) => {
    dispatch({ type: types.LOG_GLOSSARY_WORD, payload: data });
};

export const logActivity = (data) => (dispatch) => {
    dispatch({ type: types.LOG_ACTIVITY, payload: data });
};

export const logScores = (scores) => (dispatch) => {
    dispatch({ type: types.LOG_SCORES, payload: scores });
};

export const logPurchase = (data) => (dispatch) => {
    dispatch({ type: types.LOG_PURCHASE, payload: data });
};

export const logCourseList = (data) => (dispatch) => {
    dispatch({ type: types.LOG_COURSE_LIST, payload: data });
};

export const logCourseListWord = (data) => (dispatch) => {
    dispatch({ type: types.LOG_COURSE_LIST_WORD, payload: data });
};

export const logApiError = (data) => (dispatch) => {
    dispatch({ type: types.LOG_API_ERROR, payload: data });
};

export const logLinksPage = (ip, source) => (dispatch) => {
    dispatch({ type: types.LOG_LINKS_PAGE, payload: {ip, source} });
};
