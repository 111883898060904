import * as types from './types';

export const setUserId = (id) => (dispatch) => {
    dispatch({ type: types.SET_USER_ID, payload: id });
};

export const setNewUser = (status) => (dispatch) => {
  dispatch({ type: types.SET_NEW_USER, payload: status });
};

export const getAssetsSource = () => (dispatch) => {
    dispatch({ type: types.GET_ASSETS_SOURCE });
};

export const togglePostpone = () => (dispatch) => {
    dispatch({ type: types.TOGGLE_AUTO_POSTPONE });
};

export const toggleCoursePostpone = () => (dispatch) => {
    dispatch({ type: types.TOGGLE_COURSE_AUTO_POSTPONE });
};

export const setOriginalUserId = (id) => (dispatch) => {
    dispatch({ type: types.SET_ORIGINAL_USER_ID, payload: id });
};

export const setUserData = (data) => (dispatch) => {
    dispatch({ type: types.SET_USER_DATA, payload: data });
};

export const getUserScores = (userId) => (dispatch) => {
    dispatch({ type: types.GET_USER_SCORES, payload: userId });
};

export const change_list_size = (listSize) => (dispatch) => {
    dispatch({ type: types.SET_LIST_SIZE, payload: listSize });
};

export const change_password = (currentPassword, newPassword) => (dispatch) => {
    dispatch({ type: types.CHANGE_PASSWORD, payload: {currentPassword, newPassword} });
};

export const getStudyLanguages = () => (dispatch) => {
    dispatch({ type: types.GET_STUDY_LANGUAGES });
};

export const getTranslationLanguages = () => (dispatch) => {
    dispatch({ type: types.GET_TRANSLATION_LANGUAGES });
};

export const change_study_language = (studyLanguage) => (dispatch) => {
    dispatch({ type: types.STUDY_LANGUAGE_CHANGE, payload: studyLanguage });
};

export const change_translation_language = (translationLanguage) => (dispatch) => {
    dispatch({ type: types.TRANSLATION_LANGUAGE_CHANGE, payload: translationLanguage });
};

export const changeInterfaceLanguage = (language) => (dispatch) => {
    dispatch({ type: types.CHANGE_INTERFACE_LANGUAGE, payload: language });
};

export const changeTranslationLanguage = (translationLanguage) => (dispatch) => {
    dispatch({ type: types.TRANSLATION_LANGUAGE_CHANGE_WIZARD, payload: translationLanguage });
};

export const getUserAppProfile = () => (dispatch) => {
    dispatch({ type: types.GET_USER_PROFILE});
};

export const getUserAppProfileById = (userId) => (dispatch) => {
    dispatch({ type: types.GET_USER_PROFILE_BY_ID, payload: userId});
};

export const update_profile = (data) => (dispatch) => {
    dispatch({ type: types.CHANGE_USER_PROFILE, payload: data});
};

export const changeWordLevel = (level) => (dispatch) => {
    dispatch({ type: types.CHANGE_USER_WORD_LEVEL, payload: level});
};

export const update_avatar = (data) => (dispatch) => {
    dispatch({ type: types.UPLOAD_AVATAR, payload: data});
};

export const contact_us = (subject, message, email) => (dispatch) => {
    dispatch({ type: types.SEND_CONTACT_US_MESSAGE, payload: {subject, message, email}});
};

export const informAboutPaymentError = (subject, message) => (dispatch) => {
    dispatch({ type: types.SEND_ERROR_MESSAGE, payload: {subject, message}});
};

export const sync_data = (subject, message) => (dispatch) => {
    dispatch({ type: types.SYNC_WEB_ACCOUNT_DATA });
};

export const setUserToken = (token) => (dispatch) => {
    dispatch({ type: types.SET_USER_TOKEN, payload: token });
};

export const updateUserToken = (token, userId) => (dispatch) => {
    dispatch({ type: types.UPDATE_USER_TOKEN, payload: {token, userId} });
};

export const syncProgress = () => (dispatch) => {
    dispatch({ type: types.SYNC_WEB_PROGRESS, payload: true });
};

export const change_notification_time = (type, time) => (dispatch) => {
    dispatch({ type: types.CHANGE_NOTIFICATION_TIME, payload: {type, time} });
};

export const change_notification_status = (type, status) => (dispatch) => {
    dispatch({ type: types.CHANGE_NOTIFICATION_STATUS, payload: {type, status} });
};

export const getSlides = (type, status) => (dispatch) => {
    dispatch({ type: types.GET_INTRODUCTION_SLIDES });
};

export const toggleReviewModal = (type, status) => (dispatch) => {
    dispatch({ type: types.TOGGLE_REVIEW_MODAL, status });
};

export const getUserData = (userId) => (dispatch) => {
    dispatch({ type: types.GET_USER_DATA, payload: userId });
};

export const navigateToCourse = (status) => (dispatch) => {
    dispatch({ type: types.SET_NAVIGATE_TO_COURSE, payload: status });
};

export const navigateToSegue = (segue) => (dispatch) => {
    dispatch({ type: types.NAVIGATE_TO_SEGUE, payload: segue });
};

export const contactAboutRegistration = (email) => (dispatch) => {
    dispatch({ type: types.CONTACT_ABOUT_REGISTRATION, payload: email });
};

export const changeAudioLanguage = (language) => (dispatch) => {
    dispatch({ type: types.CHANGE_AUDIO_LANGUAGE, payload: language });
};

export const setUserIpAddress = (ip) => (dispatch) => {
    dispatch({ type: types.SET_IP_ADDRESS, payload: ip });
};

export const getUserCountry = (ip) => (dispatch) => {
    dispatch({ type: types.GET_USER_COUNTRY, payload: ip });
};

export const addToFrauds = (data) => (dispatch) => {
    dispatch({ type: types.ADD_TO_FRAUDS, payload: data });
};

export const changeVocabularyLevel = (level) => (dispatch) => {
    dispatch({ type: types.CHANGE_VOCABULARY_LEVEL, payload: level });
};

export const resetUserData = () => (dispatch) => {
    dispatch({ type: types.RESET_USER_DATA});
};

export const toggleShowLearntWords = () => (dispatch) => {
    dispatch({ type: types.TOGGLE_SHOW_LEARNT_WORDS});
};

export const toggleShowLearntCourseWords = () => (dispatch) => {
    dispatch({ type: types.TOGGLE_SHOW_LEARNT_WORDS_COURSE});
};

export const toggleShowArchiveLists = () => (dispatch) => {
    dispatch({ type: types.TOGGLE_SHOW_ARCHIVED_USER_LISTS});
};

export const updateTrainingsSet = (trainingsSet) => (dispatch) => {
    dispatch({ type: types.UPDATE_TRAININGS_SET, payload: trainingsSet});
};

export const deleteAccount = (history, password) => (dispatch) => {
    dispatch({ type: types.DELETE_ACCOUNT, payload: {password, history}});
};

export const activateTrainingsSet = () => (dispatch) => {
    dispatch({ type: types.ACTIVATE_TRAININGS_SET});
};

