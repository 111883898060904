import React, {Component} from 'react';

import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import colors from '../../../lib/colors';

class RelaxCell extends Component {

  render() {
    const {
      item,
      ratio,
      onItemPressed,
      tablet,
      deviceWidth,
      deviceHeight
    } = this.props;

    var imageUrl = false;

    if (item.segue == 'glossaries') {
      imageUrl = require("../../../assets/images/home/selection.jpg");
    } else if (item.segue == 'Course1000') {
      imageUrl = require("../../../assets/images/level-beginner.png");
    } else if (item.segue == 'Course2000') {
      imageUrl = require("../../../assets/images/level-elementary.png");
    } else if (item.segue == 'Games') {
      imageUrl = require("../../../assets/images/home/games.jpg");
    } else if (item.segue == 'WordTest') {
      imageUrl = require("../../../assets/images/home/test.jpg");
    } else if (item.segue == 'VideoInstructions') {
      imageUrl = require("../../../assets/images/home/instructions.jpg");
    } else if (item.segue == 'SelectLevel') {
      imageUrl = require("../../../assets/images/home/glossaries.jpg");
    } else if (item.segue == 'Socials') {
      imageUrl = require("../../../assets/images/home/socials.jpg");
    } else if (item.segue == 'SubscriptionPlans') {
      imageUrl = require("../../../assets/images/home/subscriptions.jpg");
    }

    return (
      <Card
         className="relaxcell"
         onClick={(e) => onItemPressed(item, e)}
      >
        <div
          className="listWrapper"
          style={{
           width: 200,
            height: 210,
            marginTop: !tablet ? 10 : 15,
            marginBottom: !tablet ? 5 : 10}}
        >
          <div className="listContainer">
            <span className="listName" style={{fontSize: ratio * (!tablet ? 14 : deviceWidth > 650 ? 18 : 16)}}>
              {item.name}
            </span>

            {
              item.description && false && (
                <span
                  className="description"
                  style={{backgroundColor: colors.lightGrey, minHeight: !tablet ? 75 : 130,
                    fontSize: ratio * (!tablet ? 12 : deviceWidth > 650 ? 16 : 14)}}
                >
                  {item.description}
                </span>
              ) || null
            }

            <Image
              style={{
                  // width: tablet ? (deviceHeight > 950 ? 180 : 150) : (deviceWidth > 650 ? 180 : 100),
                  height: tablet ? (deviceHeight > 950 ? 180 : 150) : (deviceWidth > 650 ? 130 : 100),
                  marginVertical: !tablet ? 10 : 20
                  }}
              src={imageUrl}
            />

          </div>
        </div>
      </Card>
    );
  }
}

export default RelaxCell;
