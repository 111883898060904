import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeUp, faPlusCircle, faCheckCircle, faCircle} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import Loader from "../../../components/Custom/Loader/index";

import {changeInterfaceLanguage} from '../../../actions/userActions';
import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import {setLocale} from '../../../actions/settingsActions';

class ChangeInterfaceLanguage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tablet: false,
      modalVisible: false,
      languages: [
        {code: 'ru', language: 'Russian'},
        {code: 'uk', language: 'Ukrainian'},
        {code: 'en', language: 'English'},
      ],
      currentLanguage: false
    };
  }

  componentDidMount() {
    this._getCurrentLanguage();

  }

  _getCurrentLanguage = () => {
    const {userData} = this.props.user;

    const currentLanguage = userData && userData.interface_language ? userData.interface_language : 'ru';

    if (currentLanguage != null && currentLanguage != undefined) {
      this.setState({
        currentLanguage
      });

      localStorage.setItem(`interfaceLanguage`, currentLanguage)
    } else {
      var userLang = navigator.language || navigator.userLanguage;
      let locale = process.env.REACT_APP_LANGUAGE;

      if (userLang) {
        let ar = userLang.split('-');

        if (ar.length === 2 && ['ru', 'uk', 'en'].indexOf(ar[0]) !== -1 || userLang === 'uk' || userLang === 'ru') {
          locale = ar[0];
        }
      }

      this.setState({
        currentLanguage: locale
      });

    }
  };

  _changeInterfaceLanguage = (language) => {
    const {history} = this.props
    this.props.fetchingData(true);

    this.setState({
      currentLanguage: language
    });

    this.props.changeInterfaceLanguage(language);
    this.props.logActivity({activityId: 347, comment: language});

    localStorage.setItem(`interfaceLanguage`, language);

    this.props.setActiveLanguage(language);
    this.props.setLocale(language);
    this.props.onClose('interfaceLanguageModal', false);

    if(history && history.location && history.location.pathname) {
      let curLocale = history.location.pathname.substr(0,4);
      let subPath = history.location.pathname.substr(4);
      
      if(curLocale == '/en/' || curLocale == '/uk/' || curLocale == '/ru/') {
        let newPath = `/${language}/${subPath}`;
        history.replace(newPath)
      }
    }
  };

  render() {
    const {fetchingData} = this.props.activity;
    const {translate, onClose, isVisible} = this.props;
    const {languages, currentLanguage} = this.state;

    return (
      <Modal
        size="sm"
        className="changeinterfacelanguage"
        show={isVisible}
        onHide={()=>onClose('interfaceLanguageModal',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="interface_language"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {
            languages.map((item, i) => {
              return (
                <Button
                  key={i}
                  block
                  active={currentLanguage == item.code}
                  variant="outline-info"
                  onClick={()=>{
                    if(item.code != currentLanguage) {
                      this._changeInterfaceLanguage(item.code);
                    }
                  }}
                >
                  {translate(item.language)}
                </Button>
              )
            })
          }

        </Modal.Body>

        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    changeInterfaceLanguage: (language) => dispatch(changeInterfaceLanguage(language)),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
    setActiveLanguage: (language) => dispatch(setActiveLanguage(language)),
    setLocale: (locale) => dispatch(setLocale(locale))
  };
}

export default connect(mapStateToProps, bindAction)(ChangeInterfaceLanguage);
