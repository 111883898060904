import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import Loader from "../../../components/Custom/Loader/index";

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';
import {change_list_size} from '../../../actions/userActions';

class ChangeListSize extends Component {

  changeListSize = (size) => {

    if(!this.props.tour) {

      this.props.fetchingData(true);
      this.props.change_list_size(size);
      this.props.logActivity({activityId: 185, comment: `${size}`});
      this.props.onClose('listSizeModalVisible',false);

    } else {
      this.props.onClose('listSizeModalVisible',false);

      this.props.toRegister(true);
    }
  };

  render() {
    const {fetchingData} = this.props.activity;
    const {translate, onClose, isVisible} = this.props;
    const {userData} = this.props.user;
    const {fontSizeRation} = this.props.settings;
    const size = userData && userData.list_size ? userData.list_size : 10;

    return (
      <Modal
        className="changelevel"
        show={isVisible}
        onHide={()=>onClose('listSizeModalVisible',false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6">
            <Translate id="list_size"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p style={{fontSize: fontSizeRation * 14, whiteSpace: "pre-wrap"}}>
            {translate('list_size_description')}
          </p>

          {
            [10, 15, 20, 25, 30].map((item, i) => {
              return (
                <Button
                  block
                  variant="outline-info"
                  key={i}
                  active={size == item}
                  onClick={(e)=>this.changeListSize(item)}
                >
                  {item}
                </Button>
              )
            })
          }

        </Modal.Body>
        <Loader fetchingData={fetchingData}/>
      </Modal>
    );
  }
}


const mapStateToProps = (state, dispatch) => ({
  user: state.user,
  settings: state.settings,
  activity: state.activity,
  translate: getTranslate(state.localize),

});

function bindAction(dispatch) {
  return {
    change_list_size: (listSize)=> dispatch(change_list_size(listSize)),
    logActivity: (data) => dispatch(logActivity(data)),
    fetchingData: (status) => dispatch(fetchingData(status)),
  };
}

export default connect(mapStateToProps, bindAction)(ChangeListSize);
