import React, {Component} from 'react';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import moment from 'moment';

import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WeiboShareButton,
  WorkplaceShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";

import {Translate, getTranslate, setActiveLanguage} from 'react-localize-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faCircle, faStar} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';

import LogoBoy from "../../../assets/images/logo-boy-prepared.png";

import {logActivity} from '../../../actions/logActions';
import {fetchingData} from '../../../actions/activity';



class ShareButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tablet: false,
      url: 'https://mnemonicwords.com',
      webUrl: 'https://mnemonicwords.com',
      appUrl: 'https://itunes.apple.com/app/id1438980777',
      androidUrl: 'http://play.google.com/store/apps/details?id=com.mnemonicrn',
    };
  }

  _logButton = (button) => {
    this.props.logActivity({activityId: 355, comment: button});
  };

  _changeUrl = (url) => {
    this.setState({
      url
    })
  };
  
  render() {
    const {
      translate,
      isVisible,
      onClose,
    } = this.props;

    const {url, webUrl, appUrl, androidUrl} = this.state;

    return (
      <Modal
        className="sharebuttons"
        show={isVisible}
        onHide={() => {
          onClose('shareModalVisible', false);
        }}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5" style={{whiteSpace: 'pre-line'}}>
            <Translate id="share_with_your_friends"/>
          </Modal.Title>

        </Modal.Header>

        <Modal.Body className="text-center">
          <Image
            className="m-auto"
            style={{width: 80}}
            src={LogoBoy}
          />


          <p className="mt-2 mb-1 desc2">
            {translate('share_link_on')}
          </p>
          <div>
            <Form.Check
              checked={url == 'https://mnemonicwords.com'}
              onChange={()=>this._changeUrl(webUrl)}
              name="url"
              inline
              label={translate('web_url')}
              type={'radio'}
              id={`inline-1`}
              className="hover"
            />
            <Form.Check
              checked={url == 'https://itunes.apple.com/app/id1438980777'}
              onChange={()=>this._changeUrl(appUrl)}
              name="url"
              inline
              label={translate('app_url')}
              type={'radio'}
              id={`inline-2`}
              className="hover"
            />
            <Form.Check
              checked={url == 'http://play.google.com/store/apps/details?id=com.mnemonicrn'}
              onChange={()=>this._changeUrl(androidUrl)}
              name="url"
              inline
              label={translate('android_url')}
              type={'radio'}
              id={`inline-3`}
              className="hover"
            />
          </div>

          <div className="d-flex flex-wrap justify-content-center icons mt-3 buttons">
            <EmailShareButton
              beforeOnClick={()=>{
                this._logButton('email')
              }}
              subject={translate('app_title')}
              body={translate('app_description')}
              url={url}
            >
              <EmailIcon size={32} round={true} />
            </EmailShareButton>

            <WhatsappShareButton
              beforeOnClick={()=>{
                this._logButton('wa')
              }}
              url={url}
            >
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>

            <TwitterShareButton
              beforeOnClick={()=>{
                this._logButton('twitter')
              }}
              title={translate('app_title')}
              url={url}
            >
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>

            <LinkedinShareButton
              beforeOnClick={()=>{
                this._logButton('linkedin')
              }}
              url={url}
              title={translate('app_title')}
              summary={translate('app_description')}

            >
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>

            <FacebookShareButton
              beforeOnClick={()=>{
                this._logButton('fb')
              }}
              url={url}
              quote={translate('app_title')}
            >
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>

            <PinterestShareButton
              beforeOnClick={()=>{
                this._logButton('pinterest')
              }}
              url={url}
              description={translate('app_description')}
              media="https://mnemonicwords.com/public/images/mnemonicwords.jpg"
            >
              <PinterestIcon size={32} round={true} />
            </PinterestShareButton>

            <TelegramShareButton
              beforeOnClick={()=>{
                this._logButton('telegram')
              }}
              url={url}
              title={translate('app_title')}
            >
              <TelegramIcon size={32} round={true} />
            </TelegramShareButton>

            <ViberShareButton
              beforeOnClick={()=>{
                this._logButton('viber')
              }}
              url={url}
              title={translate('app_title')}
            >
              <ViberIcon size={32} round={true} />
            </ViberShareButton>
            <VKShareButton
              beforeOnClick={()=>{
                this._logButton('vk')
              }}
              url={url}
              title={translate('app_title')}
              image="https://mnemonicwords.com/public/images/mnemonicwords.jpg"
            >
              <VKIcon size={32} round={true} />
            </VKShareButton>
            <MailruShareButton
              beforeOnClick={()=>{
                this._logButton('mail')
              }}
              url={url}
              title={translate('app_title')}
              description={translate('app_description')}
              imageUrl="https://mnemonicwords.com/public/images/mnemonicwords.jpg"
            >
              <MailruIcon size={32} round={true} />
            </MailruShareButton>
            <OKShareButton
              beforeOnClick={()=>{
                this._logButton('ok')
              }}
              url={url}
              title={translate('app_title')}
              description={translate('app_description')}
              image="https://mnemonicwords.com/public/images/mnemonicwords.jpg"
            >
              <OKIcon size={32} round={true} />
            </OKShareButton>

            <WeiboShareButton
              beforeOnClick={()=>{
                this._logButton('weibo')
              }}
              url={url}
              title={translate('app_title')}
              image="https://mnemonicwords.com/public/images/mnemonicwords.jpg"
            >
              <WeiboIcon size={32} round={true} />
            </WeiboShareButton>
            <TumblrShareButton
              beforeOnClick={()=>{
                this._logButton('tumblr')
              }}
              url={url}
              title={translate('app_title')}
              caption={translate('app_description')}
            >
              <TumblrIcon size={32} round={true} />
            </TumblrShareButton>

            <RedditShareButton
              beforeOnClick={()=>{
                this._logButton('reddit')
              }}
              url={url}
              title={translate('app_title')}
            >
              <RedditIcon size={32} round={true} />
            </RedditShareButton>
          </div>
          
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  settings: state.settings,
  activity: state.activity,

});

function bindAction(dispatch) {
  return {
    fetchingData: (status) => dispatch(fetchingData(status)),
    logActivity: (data) => dispatch(logActivity(data)),
  };
}

export default connect(mapStateToProps, bindAction)(ShareButtons);
