import request from 'superagent';
import {select, put} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {Translate, getTranslate} from 'react-localize-redux';

import {logApiError} from './logSagas';
import {getListsByLang} from './lists';

import * as constants from '../../lib/constants';
import * as types from '../../actions/types';

const API_URL = constants.API_URL;

export function* getTags() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getTags')
    .query({
      user_id: userId && userId > 0 ? userId : 1,
    });

    // console.log("getTags", result.body)

    yield put({
      type: types.SET_TAGS,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({payload: {function: 'getTags', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* getConnectedTags() {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .get(API_URL + 'getConnectedTags')
    .query({
      user_id: userId && userId > 0 ? userId : 1,
    });

    // console.log("getConnectedTags", result.body)

    yield put({
      type: types.SET_CONNECTED_TAGS,
      payload: result.body.data
    });

  } catch (error) {
    yield logApiError({payload: {function: 'getConnectedTags', error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


export function* createTag(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'createTag')
    .send({
      user_id: userId,
      tag_name: data.payload.tagName,
      color: data.payload.color
    });

    // console.log("createTag", result.body);

    if(result.body.result) {
      yield getTags();
      yield getConnectedTags();
      // yield createTag({payload: {listId: data.payload.listId, tagId: result.body.tag_id}})
    }
  } catch (error) {
    yield logApiError({payload: {function: 'createTag', payload: data.payload,error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* connectTag(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'connectTag')
    .send({
      user_id: userId,
      list_id: data.payload.listId,
      tag_id: data.payload.tag.id,
    });

    // console.log("ConnectTag", result.body)
    
    if(result.body.result) {
      yield getConnectedTags();
      if(data.payload.lists) {
        yield getListsByLang({payload: {filter: "by_tag", order: "asc"}});
      } else {
        yield put({
          type: types.UPDATE_LIST_TAG,
          payload: data.payload.tag
        })
      }
    }
  } catch (error) {
    yield logApiError({payload: {function: 'createTag', payload: data.payload,error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* deleteTag(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'deleteTag')
    .send({
      user_id: userId,
      tag_id: data.payload,
    });

    if(result.body.result) {
      yield put({
        type: types.REMOVE_TAG_FROM_CURRENT_LIST,
      });
      yield getTags();
      yield getConnectedTags();
      yield getListsByLang({payload: {filter: "by_tag", order: "asc"}});
    }
  } catch (error) {
    yield logApiError({payload: {function: 'deleteTag', payload: data.payload,error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}

export function* disconnectTag(data) {
  const {userId} = yield select(state => state.user);

  try {
    const result = yield request
    .post(API_URL + 'disconnectTag')
    .send({
      user_id: userId,
      list_id: data.payload,
    });

    // console.log("disconnectTag", result.body)
    if(result.body.result) {
      yield getConnectedTags();

      yield put({
        type: types.REMOVE_TAG_FROM_CURRENT_LIST,
      });
      yield getListsByLang({payload: {filter: "by_tag", order: "asc"}});
    }
  } catch (error) {
    yield logApiError({payload: {function: 'disconnectTag', payload: data.payload,error: error && error.message ? error.message : (error.response ? error.response : 'error')}})
  } finally {
    yield put({
      type: types.FETCHING_DATA,
      payload: false
    });
  }
}


